import { Dispatch } from 'react';
import * as React from 'react';

import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, FilterValue } from 'antd/es/table/interface';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { push } from 'react-router-redux';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Input } from 'components/common/forms/FormElements';
import Table from 'common/Table/Table';
import {
  InventoryOrder,
  InventoryItem,
  InventoryIdentifierType,
  BasicInventoryOrderSearchCriteria,
} from 'components/Inventory/models';
import { useInventoryItems } from 'components/shipmentDetails/common/hooks/inventoryItems';
import { SortDirection } from 'models/SortDirection';
import { MAX_PAGE_QUANTITY } from 'common/AppConstants';
import { getInventoryIdentifierValue } from './inventoryHelpers';
import { BasicInventoryItemSearchCriteria } from './models/InventoryItemSearch';
const ITEMS_PAGE_SIZE = 30;

interface InventoryItemsTableProps {
  setTotalItemsCount?: Dispatch<React.SetStateAction<number>>;
  masterShipmentId?: string;
  setSearchText?: Dispatch<React.SetStateAction<string>>;
  setShowPopover?: Dispatch<React.SetStateAction<boolean>>;
  orderId?: string;
}

const InventoryItemsTable: React.FC<InventoryItemsTableProps> = ({
  setTotalItemsCount,
  masterShipmentId,
  setSearchText,
  setShowPopover,
  orderId,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { state = { searchText: '' } } = useLocation();

  const [currentFilter, setCurrentFilter] = React.useState<BasicInventoryOrderSearchCriteria>({});
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const { dataLoading, searchItems, inventoryItems } = useInventoryItems({
    hasPermission: true,
    masterShipmentId,
    defaultPageSize: ITEMS_PAGE_SIZE,
    searchText: get(state, 'searchText', ''),
    orderId,
  });

  if (setSearchText) {
    setSearchText(get(state, 'searchText', ''));
  }

  if (setTotalItemsCount && inventoryItems) {
    setTotalItemsCount(inventoryItems.paginationInfo.total);
  }

  const tableReadyInventoryItems =
    inventoryItems &&
    inventoryItems.results.reduce((acc: Array<InventoryItem>, item: InventoryItem) => {
      const itemWithExtraData = {
        ...item,
        departmentName: getInventoryIdentifierValue(InventoryIdentifierType.DEPARTMENT, item),
        categoryName: getInventoryIdentifierValue(InventoryIdentifierType.CATEGORY, item),
        familyName: getInventoryIdentifierValue(InventoryIdentifierType.FAMILY, item),
        promotionalName: getInventoryIdentifierValue(InventoryIdentifierType.PROMOTION, item),
        vendorName: getInventoryIdentifierValue(InventoryIdentifierType.VENDOR, item),
      };
      acc.push(itemWithExtraData);
      return acc;
    }, []);

  const columnFilterRequest = (fields: string[], dataIndex: string) => {
    setCurrentFilter({ [dataIndex]: fields });
    const requestBody: BasicInventoryItemSearchCriteria = {
      [dataIndex]: fields,
      searchText: '',
    };
    setCurrentPage(1);
    searchItems(requestBody, 1, ITEMS_PAGE_SIZE, []);
  };

  const getFilterDropdowns = (dataIndex: string) => ({
    filterDropdown: function filterDropdownComponent({
      selectedKeys,
      setSelectedKeys,
    }: {
      selectedKeys: string[];
      setSelectedKeys: (keys: string[]) => void;
    }) {
      return (
        <div style={{ width: '140px' }}>
          <Input
            className="borderlessInput"
            placeholder={`Filter`}
            value={selectedKeys[0]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onSearch={() => columnFilterRequest(selectedKeys, dataIndex)}
            onPressEnter={() => columnFilterRequest(selectedKeys, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            search
          />
        </div>
      );
    },
  });

  const columns = [
    {
      dataIndex: 'stockKeepingUnit',
      key: 'sku',
      className: 'data-locator-sku',
      sorter: true,
      title: intl.formatMessage({ id: 'inventory.itemsList.columnHeaders.sku', defaultMessage: 'SKU' }),
      width: 175,
      ...getFilterDropdowns('skus'),
    },
    {
      dataIndex: 'description',
      key: 'description',
      sorter: false,
      title: intl.formatMessage({ id: 'inventory.itemsList.columnHeaders.description', defaultMessage: 'Description' }),
      width: 175,
    },
    {
      dataIndex: 'universalProductCode',
      key: 'universalProductCode',
      sorter: true,
      title: intl.formatMessage({ id: 'inventory.itemsList.columnHeaders.upc', defaultMessage: 'UPC' }),
      width: 175,
      ...getFilterDropdowns('upcs'),
    },
    {
      dataIndex: ['perUnitCost', 'amount'],
      key: 'perUnitCost',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.perUnitCost',
        defaultMessage: 'Per Unit Cost',
      }),
      render: function renderCol(text: string, record: InventoryItem) {
        if (text === undefined || text === null) return '';

        return parseFloat(text).toLocaleString(undefined, {
          style: 'currency',
          currency: get(record, 'perUnitCost.currency', 'USD'),
        });
      },
      width: 175,
    },
    {
      dataIndex: ['inventoryDimensionalWeight', 'quantity'],
      key: 'quantity',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.quantity',
        defaultMessage: 'Quantity',
      }),
      width: 175,
    },
    {
      dataIndex: ['perUnitRetailValue', 'amount'],
      key: 'perUnitRetailValue',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.perUnitRetailValue',
        defaultMessage: 'Retail Value/unit',
      }),
      render: function renderCol(text: string, record: InventoryItem) {
        if (text === undefined || text === null) return '';

        return parseFloat(text).toLocaleString(undefined, {
          style: 'currency',
          currency: get(record, 'perUnitRetailValue.currency', 'USD'),
        });
      },
      width: 175,
    },

    {
      dataIndex: ['inventoryDimensionalWeight', 'packageType'],
      key: 'uofm',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.uofm',
        defaultMessage: 'UoM',
      }),
      width: 175,
    },

    {
      dataIndex: ['lineItemHazmatDetail', 'hazardClass'],
      key: 'hazardClass',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.hazardClass',
        defaultMessage: 'Hazard Class',
      }),
      width: 175,
      ...getFilterDropdowns('hazardClasses'),
    },
    {
      dataIndex: 'perishable',
      key: 'perishable',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.perishable',
        defaultMessage: 'Perishable',
      }),
      width: 175,
    },
    {
      dataIndex: 'departmentName',
      key: 'departmentName',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.departmentName',
        defaultMessage: 'Department Name',
      }),
      width: 175,
    },
    {
      dataIndex: 'categoryName',
      key: 'categoryName',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.categoryName',
        defaultMessage: 'Category Name',
      }),
      width: 175,
    },
    {
      dataIndex: 'groupName',
      key: 'groupName',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.groupName',
        defaultMessage: 'Group Name',
      }),
      width: 175,
    },
    {
      dataIndex: 'familyName',
      key: 'familyName',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.familyName',
        defaultMessage: 'Family Name',
      }),
      width: 175,
    },
    {
      dataIndex: 'promotionalName',
      key: 'promotionalName',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.promotionalName',
        defaultMessage: 'Promotional Name',
      }),
      width: 175,
    },
    {
      dataIndex: 'vendorName',
      key: 'vendorName',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.vendorName',
        defaultMessage: 'Vendor Name',
      }),
      width: 175,
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<InventoryOrder> | SorterResult<InventoryOrder>[]
  ) => {
    let fieldName = '';
    if (Array.isArray(sorter)) {
      fieldName = `${sorter[0].field}`;
    } else if (sorter.field) {
      fieldName = `${sorter.field}`;
    }
    const sorterOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
    const sortFields = fieldName
      ? { fieldName, sortDirection: sorterOrder === 'ascend' ? SortDirection.ASC : SortDirection.DESC }
      : '';
    const requestBody: BasicInventoryItemSearchCriteria = {
      searchText: get(state, 'searchText', ''),
      ...currentFilter,
    };
    setCurrentPage(pagination.current || 1);
    searchItems(
      requestBody,
      pagination.current || 1,
      pagination.pageSize || ITEMS_PAGE_SIZE,
      sortFields ? [sortFields] : []
    );
  };

  const handlePaginationSize = (): number => {
    if (get(inventoryItems, 'paginationInfo.total', 0) / ITEMS_PAGE_SIZE > MAX_PAGE_QUANTITY) {
      if (setShowPopover) setShowPopover(true);
      return ITEMS_PAGE_SIZE * MAX_PAGE_QUANTITY;
    }
    if (setShowPopover) setShowPopover(false);
    return inventoryItems?.paginationInfo.total || 0;
  };
  return (
    <Table
      isDraggable
      loading={dataLoading}
      rowKey={(record) => record.id}
      dataSource={tableReadyInventoryItems}
      columns={columns}
      scroll={{ x: 1200, y: 640 }}
      onChange={handleTableChange}
      rowClassName={() => 'cursor-pointer'}
      onRow={(record: InventoryItem) => {
        return {
          onClick: () => {
            dispatch(push(`/inventory/items/${record.id}`));
          },
        };
      }}
      pagination={{
        pageSize: ITEMS_PAGE_SIZE,
        total: handlePaginationSize(),
        showSizeChanger: false,
        current: currentPage,
      }}
      size="middle"
      showSorterTooltip={false}
    />
  );
};

export default InventoryItemsTable;
