import { useEffect, useMemo } from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { SVGIcon } from 'ui-components';

import HeaderLabel from '../HeaderLabel';
import ModeFilters from '../ModeFilters';
import { ModeType } from '../../shipmentListComponent/verticalFilterComponent/utils';
import * as styles from './styles.module.scss';
import { composeFilters } from './utils';
import { QuickviewEntityType } from '../constants';
import EncapsulatedOrdersFilters from '../../orders/EncapsulatedOrdersFilters';
import EncapsulatedInventoryItemsFilters from '../../inventory-v2/EncapsulatedInventoryItemsFilters';

interface FilteringCriteriaComponentProps {
  modeFilterOptions: Array<{
    value: string;
    label: string;
    id: number;
    checked: boolean;
    displayValue: string;
  }>;
  toggleModeFilterSelectOneFn: (mode: string) => void;
  modeFilters: Array<ModeType>;
  entityType: QuickviewEntityType | string;
  orderFilter: { [key: string]: any };
  setOrderFilter: (filter: any) => void;
  inventoryFilter: { [key: string]: any };
  setInventoryFilter: (filter: { [key: string]: any }) => void;
  handleDatePickerChange: (date: string | undefined, target: string) => void;
  match: any;
}

interface FormFields {
  mode: ModeType;
}

const StyledSVGIcon = styled(SVGIcon)`
  font-size: 14px;
  margin-right: 5px;
`;

const FilteringCriteriaComponent = ({
  modeFilterOptions,
  toggleModeFilterSelectOneFn,
  modeFilters,
  entityType,
  orderFilter,
  setOrderFilter,
  inventoryFilter,
  setInventoryFilter,
  ...props
}: FilteringCriteriaComponentProps) => {
  const { setFieldValue } = useFormikContext<FormFields>();
  const currentMode = modeFilters.length > 0 ? modeFilters[0] : 'ALL';
  const memoizedModeFilters = useMemo(
    () => composeFilters(currentMode, { ...props, modeFilterOptions }),
    [currentMode, modeFilterOptions, props]
  );

  const handleModeChange = (e: RadioChangeEvent) => {
    toggleModeFilterSelectOneFn(e.target.value);
  };

  useEffect(() => {
    setFieldValue('pickupPresetDate', null);
    setFieldValue('deliveryPresetDate', null);
    setFieldValue('lastFreePresetDate', null);
  }, [currentMode, setFieldValue]);

  let filterComponents;

  if (entityType === QuickviewEntityType.SHIPMENTS) {
    filterComponents = (
      <>
        <div className={cn('align-self-stretch flex-grow-0', styles['filtering-criteria-content'])}>
          <HeaderLabel>
            <FormattedMessage id="quickViewConfig.filterCriteria.title" defaultMessage="Filtering Criteria" />
          </HeaderLabel>
          <p className="mb-3">
            <FormattedMessage
              id="quickViewConfig.filterCriteria.addFilterMessage"
              defaultMessage="Refine the results shown in the subtotal by using the filtering options below."
            />
          </p>
          <Radio.Group
            onChange={handleModeChange}
            value={currentMode}
            className={cn('d-flex flex-row flex-wrap', styles['modes-list'])}
          >
            {modeFilterOptions.map(({ value, label, id }) => (
              <Radio className="d-flex flex-row align-items-center" key={id} value={value}>
                <span className="d-flex align-items-center">
                  <StyledSVGIcon name={value} />
                  {label}
                </span>
              </Radio>
            ))}
          </Radio.Group>
        </div>
        <ModeFilters modeFilters={memoizedModeFilters} {...props} />
      </>
    );
  } else if (entityType === QuickviewEntityType.ORDERS) {
    filterComponents = <EncapsulatedOrdersFilters filter={orderFilter} setFilter={setOrderFilter} />;
  } else if (entityType === QuickviewEntityType.INVENTORY) {
    filterComponents = <EncapsulatedInventoryItemsFilters filter={inventoryFilter} setFilter={setInventoryFilter} />;
  } else {
    console.error('Error, invalid entity type', entityType);
  }

  return <>{filterComponents}</>;
};

export default FilteringCriteriaComponent;
