import LTLDetailsContainer from './LTL/LTLDetailsContainer';
import TLDetailsContainer from './TL/TLDetailsContainer';
import OCEANDetailsContainer from './OCEAN/OCEANDetailsContainer';

const ShipmentDetailsComponent = (props) => {
  const shipmentMode = props.match.params.shipmentMode;
  const shouldUseRecentSearch = props.location?.state?.shouldUseRecentSearch;
  let recentSSS;
  if (props.shouldUseRecentSSS === shouldUseRecentSearch) {
    recentSSS = shouldUseRecentSearch;
  } else if (props.shouldUseRecentSSS === true && shouldUseRecentSearch === false) {
    recentSSS = false;
  } else {
    recentSSS = props.shouldUseRecentSSS;
  }
  return (
    <div className="shipment-details-wrapper" style={{ zIndex: 2 }}>
      {(() => {
        switch (shipmentMode) {
          case 'ltl':
            return (
              <LTLDetailsContainer
                history={props.history}
                match={props.match}
                shipmentModel={props.shipmentModel}
                shouldUseRecentSearch={recentSSS}
              />
            );
          case 'ocean':
            return (
              <OCEANDetailsContainer history={props.history} match={props.match} shipmentModel={props.shipmentModel} />
            );
          default:
            return (
              <TLDetailsContainer
                history={props.history}
                match={props.match}
                shipmentModel={props.shipmentModel}
                // Will use this flag in the next PR
                shouldUseRecentSearch={recentSSS}
              />
            );
        }
      })()}
    </div>
  );
};

export default ShipmentDetailsComponent;
