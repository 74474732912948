import { useState, useEffect } from 'react';
import * as React from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import has from 'lodash/has';
import { Formik, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router-dom';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { AxiosError, AxiosResponse } from 'axios';
import some from 'lodash/some';
import isEqual from 'lodash/isEqual';
import { API_PATH, LONG_DAYS_OF_WEEK } from 'common/AppConstants';
import { Alert } from 'ui-components';
import axios from 'util/paxios';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { ComponentErrorProps } from 'components/common/errorBoundary/models';
import { trackEvent } from 'common/eventTracker';
import Form from './LocationDetailsForm';
import {
  updateLocation,
  mapStateToApi,
  locationAddressFactory,
  locationDetailsFactory,
  createLocation,
  fetchAnalyticsByLocationId,
} from './location.service';

import {
  AnalyticsLocationProps,
  LocationDetails,
  FormValues,
  Contact,
  FenceType,
  DistanceUnitsEnum,
  GeometryTypes,
  GeofenceSourceType,
  TimeMeasurementEnum,
} from './models';

const messages = defineMessages({
  locationInformation: {
    id: 'locationDetails.locationDetailsAddressHeader.locationInformation',
    defaultMessage: 'Location Information',
  },
  addNewLocation: {
    id: 'locationDetails.locationDetailsAddressHeader.addNewLocation',
    defaultMessage: 'Add New Location',
  },
  locationDetails: {
    id: 'locationDetails.locationDetailsAddressHeader.locationDetails',
    defaultMessage: 'Location Details',
  },
  missingName: {
    id: 'locationDetails.missingName',
    defaultMessage: 'Location Name Not Defined',
  },
});

interface LocationDetailsComponentProps
  extends WrappedComponentProps,
    RouteComponentProps<{ locationDetailId: string }> {
  locationTitle: string;
  onSubmit: (data: any) => void;
  noNav: boolean;
  canCreateLocation: boolean;
  canEditLocation: boolean;
}

const LocationDetailsComponent = (props: LocationDetailsComponentProps & WrappedComponentProps) => {
  const { intl } = props;
  const newLocation = props.match.params.locationDetailId === 'new';
  const [isEditMode, setIsEditMode] = useState<boolean>(newLocation);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [locationDetails, setLocationDetails] = useState<LocationDetails>(locationDetailsFactory());
  const [formattedAddress, updateFormattedAddress] = React.useState<string | undefined>();
  const [address2Units, updateAddress2Units] = React.useState<string | undefined>();
  const [customGeofenceCoords, setCustomGeofenceCoords] = React.useState(undefined);
  const [geofenceRangeInMinutes, setGeofenceRangeInMinutes] = React.useState<number>(0);
  const [analyticsData, setAnalyticsData] = React.useState<AnalyticsLocationProps | undefined>();
  const principalContext = React.useContext(PrincipalContext);
  const [componentError, setComponentError] = React.useState<ComponentErrorProps>({ errorsList: [] });
  const [latLngInputValue, updatelatLngInputValue] = React.useState<string | undefined>();

  const defaultFenceType = get(principalContext, 'customerProperties.TRUCKLOAD_DISTANCE_BASED_GEOFENCE_ENABLED')
    ? FenceType.DISTANCE
    : FenceType.TIME;
  const defaultDistanceValue = get(
    principalContext,
    'customerProperties.TRUCKLOAD_DISTANCE_BASED_GEOFENCE_METERS_THRESHOLD',
    1500
  ) as number;

  const address2 = !isNil(get(locationDetails, 'address.address2')) ? get(locationDetails, 'address.address2', '') : '';
  const unitValue = address2.split(' ')[0];
  const addressLine2SplitValue = address2.replace(unitValue, '').trim();
  const DISTANCE_MILES_TO_KM = 1609.344;
  const country = get(locationDetails, 'address.country', 'ROW');
  const isChinaAsCountry = country === 'CN';

  React.useEffect(() => {
    if (has(locationDetails, 'address.state') || has(locationDetails, 'address.country')) {
      const requiredKeyList = ['country', 'state', 'postalCode', 'city', 'address1'];
      updateFormattedAddress(
        requiredKeyList.reduce((acc, addressKey) => {
          const value = get(locationDetails, `address.${addressKey}`);
          return `${acc}${acc && value ? ', ' : ''}${value ? value : ''}`;
        }, '')
      );
    }
    setCustomGeofenceCoords(get(locationDetails, 'geofence.geometry'));

    if (get(locationDetails, 'geofence.type') === FenceType.TIME) {
      setGeofenceRangeInMinutes(get(locationDetails, 'geofence.value', 15));
    } else if (newLocation && get(locationDetails, 'geofence.type') !== FenceType.TIME) {
      setGeofenceRangeInMinutes(15);
    } else {
      setGeofenceRangeInMinutes(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(locationDetails)]);

  const handleFetchErrors = React.useCallback(
    function handleFetchErrorsCallback(key: string, error: any, errorIsNew: boolean) {
      if (error.response && errorIsNew) {
        const errorsArray = error.response.data.errors;
        const errorsList = [
          ...componentError.errorsList,
          {
            error: errorsArray.map((err: any) => err.message),
            supportRefId: error.response.data.supportReferenceId,
            id: key,
          },
        ];
        setComponentError({ errorsList });
      }
    },
    [componentError.errorsList]
  );

  async function fetchAnalytics(id: string) {
    const errorKey = 'fetch-analytics-error';
    const previousErrorIndex = componentError.errorsList.find((elem) => elem.id === errorKey);
    try {
      const { data } = await fetchAnalyticsByLocationId(id);
      setAnalyticsData(data);

      if (previousErrorIndex) {
        const updatedErrorsList = componentError.errorsList.filter((elem) => elem.id !== errorKey);
        setComponentError({ errorsList: updatedErrorsList });
      }
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status !== 404) {
        handleFetchErrors(errorKey, error, !previousErrorIndex);
      }
    }
  }

  useEffect(
    function loadDetailsData() {
      const url = process.env.REACT_APP_JSON_SERVER ? 'http://localhost:3001/api/portal/v2' : API_PATH;
      async function fetchDetails(id: string) {
        const errorKey = 'fetch-location-details-error';
        const previousErrorIndex = componentError.errorsList.find((elem) => elem.id === errorKey);
        try {
          const response: AxiosResponse<LocationDetails> = await axios.get(`${url}/location/v2/locations/${id}`, {
            withCredentials: true,
          });
          const locationDetails = response.data;
          setLocationDetails(locationDetails);
          const { latitude, longitude } = locationDetails?.address?.locationCoordinatesDto || {};
          if (latitude && longitude) {
            updatelatLngInputValue(`${latitude}, ${longitude}`);
          }

          if (previousErrorIndex) {
            const updatedErrorsList = componentError.errorsList.filter((elem) => elem.id !== errorKey);
            setComponentError({ errorsList: updatedErrorsList });
          }
          return;
        } catch (e) {
          const error = e as AxiosError;
          handleFetchErrors(errorKey, error, !previousErrorIndex);
          return error;
        }
      }

      if (!isEditMode && !newLocation) {
        fetchDetails(props.match.params.locationDetailId);
        fetchAnalytics(props.match.params.locationDetailId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.match.params.locationDetailId, isEditMode, newLocation, componentError.errorsList, handleFetchErrors]
  );

  const [userEnteredAddress, updateUserEnteredAddress] = React.useState();
  const [useAddressLatLngInMap, updateUseAddressLatLngInMap] = React.useState(false);

  const handleCustomGeofenceCoords = React.useCallback((coords: any) => {
    setCustomGeofenceCoords(coords);
  }, []);

  useEffect(() => {
    if (newLocation && !props.canCreateLocation) {
      // * redirect back to home
      props.history.push('/');
    }
  }, [newLocation, props.canCreateLocation, props.history]);

  useEffect(() => {
    if (get(locationDetails, 'id')) {
      trackEvent('LOCATIONS_DETAIL_PAGE_LOAD', {
        id: get(locationDetails, 'id') || '',
        name: get(locationDetails, 'name') || '',
      });
    }
  }, [locationDetails]);

  const toggleEditMode = (value: boolean) => {
    if (value) {
      trackEvent('LOCATIONS_DETAIL_PAGE_EDIT_CLICK');
    }
    setIsEditMode(value);
    if (newLocation) {
      // * redirect back to location list
      props.history.push('/location-list');
    }
  };
  const toggleIsResetting = (value: boolean) => {
    setIsResetting(value);
  };
  const editFormSubmit = (values: FormValues) => {
    const { address, address2 } = values;
    const locationCoordinatesDto = values.address.locationCoordinatesDto;
    if (!isNil(address2) && address2Units) {
      address.address2 = `${address2Units} ${address2}`;
    }
    if (!address2) {
      address.address2 = undefined;
    }
    if (!isNil(customGeofenceCoords)) {
      values.geofence.geometry = customGeofenceCoords;
    }
    if (isNil(values.geofence.geometry) && values.geofence.source === GeofenceSourceType.LOCATION) {
      values.geofence.geometry = {
        type: GeometryTypes.POINT,
        coordinates: [
          locationCoordinatesDto ? locationCoordinatesDto.longitude : 0,
          locationCoordinatesDto ? locationCoordinatesDto.latitude : 0,
        ],
      };
    }

    if (values.distanceUnits === DistanceUnitsEnum.MILES) {
      /*  We are still saving the value to the database as Meters. That is why we need to convert the Miles entered
          to meters. This conversion will only happen if the user selected the MILES units on the Edit Geofence modal.
          1609.344 meters === 1 mile
      */
      values.distanceValue = values.distanceValue * DISTANCE_MILES_TO_KM;
    }

    const detailsToSave: Partial<FormValues> = pick(values, [
      ...Object.keys(locationDetails || {}),
      'hours',
      'contacts',
      'specifiedId',
    ]);

    const hasUpdatedGeofenceValues =
      locationDetails.geofence.type !== values.fenceType ||
      (locationDetails.geofence.type === FenceType.DISTANCE &&
        locationDetails.geofence.value !== values.distanceValue) ||
      (locationDetails.geofence.type === FenceType.TIME && locationDetails.geofence.value !== values.timeValue) ||
      !isEqual(locationDetails.geofence.geometry, values.geofence);

    const geofenceModel = {
      ...locationDetails.geofence,
      type: values.fenceType,
      value:
        values.fenceType === FenceType.DISTANCE
          ? values.distanceValue
          : values.fenceType === FenceType.POLYGON
          ? 0
          : values.timeValue,
      units:
        values.fenceType === FenceType.DISTANCE
          ? DistanceUnitsEnum.METERS
          : values.fenceType === FenceType.POLYGON
          ? DistanceUnitsEnum.METERS
          : TimeMeasurementEnum.MINUTES,
      geometry: values.geofence.geometry || null,
      source: hasUpdatedGeofenceValues ? GeofenceSourceType.LOCATION : locationDetails.geofence.source,
    };
    const updatedObj = mapStateToApi({
      ...locationDetails,
      ...detailsToSave,
      geofence: geofenceModel,
      ...(get(detailsToSave, 'contacts', []).length > 0 && { contacts: detailsToSave.contacts }),
    });

    if (newLocation) {
      const errorKey = 'create-location-error';
      const previousErrorIndex = componentError.errorsList.find((elem) => elem.id === errorKey);
      createLocation(updatedObj)
        .then(() => {
          toggleEditMode(false);
          if (previousErrorIndex) {
            const updatedErrorsList = componentError.errorsList.filter((elem) => elem.id !== errorKey);
            setComponentError({ errorsList: updatedErrorsList });
          }
        })
        .catch((error: AxiosError) => {
          // eslint-disable-next-line no-console
          console.error(error);
          handleFetchErrors(errorKey, error, !previousErrorIndex);
        });
    } else {
      const errorKey = 'update-location-error';
      const previousErrorIndex = componentError.errorsList.find((elem) => elem.id === errorKey);
      updateLocation(updatedObj)
        .then(() => {
          toggleEditMode(false);
          if (previousErrorIndex) {
            const updatedErrorsList = componentError.errorsList.filter((elem) => elem.id !== errorKey);
            setComponentError({ errorsList: updatedErrorsList });
          }
        })
        .catch((error: AxiosError) => {
          console.error(error);
          handleFetchErrors(errorKey, error, !previousErrorIndex);
        });
    }
  };

  const formValidation = (values: any) => {
    let hoursErrors = '';

    LONG_DAYS_OF_WEEK.forEach((day: { id: string; defaultMessage: string }, idx: number) => {
      const lowercaseDay = day.defaultMessage.toLowerCase();
      const openTime = get(values, ['hours', `${lowercaseDay}From`]);
      const closeTime = get(values, ['hours', `${lowercaseDay}To`]);
      if ((openTime && !closeTime) || (!openTime && closeTime)) {
        hoursErrors = 'locationDetails.edit.errorMessages.missingOpenOrCloseTime';
      }
    });
    const contactKeys: string[] = ['contactName', 'email', 'phoneNumber'];
    const emptyContacts = values.contacts.map((contact: Contact) => {
      return !contactKeys.map((key) => contact[key as keyof Contact]).every((value) => value);
    });
    const inputErrors: boolean = some([!values.name, some([...emptyContacts])]);
    const hasInvalidMinutesValue =
      (values.fenceType === FenceType.TIME && parseInt(values.timeValue, 10) === 0) ||
      /^\d+$/.test(values.timeValue) === false;
    const hasInvalidRadiusValue =
      (values.fenceType === FenceType.DISTANCE && parseInt(values.distanceValue, 10) === 0) ||
      /^[+-]?(\d*\.)?\d+$/.test(values.distanceValue) === false;
    const errors: any = {
      ...(hoursErrors && { hours: hoursErrors }),
      ...(inputErrors && { inputErrors }),
      ...(hasInvalidMinutesValue && { timeValue: true }),
      ...(hasInvalidRadiusValue && { distanceValue: values.distanceValue }),
    };
    return errors;
  };

  return (
    <>
      {componentError.errorsList.length > 0 && (
        <Alert
          messageIntlId={'global.alert.error'}
          messageDefaultText="Uh oh! Looks like there was a problem."
          alertType="error"
          alertItems={componentError.errorsList}
        />
      )}
      <Formik
        initialValues={
          {
            name: get(locationDetails, 'name', intl.formatMessage(messages.missingName)),
            companyName: get(locationDetails, 'companyName'),
            type: get(locationDetails, 'type'),
            specifiedId: get(locationDetails, 'specifiedId'),
            tenantId: get(locationDetails, 'tenantId'),
            hours: get(locationDetails, 'hours'),
            mondayClosed: !has(locationDetails, 'hours.mondayFrom'),
            tuesdayClosed: !has(locationDetails, 'hours.tuesdayFrom'),
            wednesdayClosed: !has(locationDetails, 'hours.wednesdayFrom'),
            thursdayClosed: !has(locationDetails, 'hours.thursdayFrom'),
            fridayClosed: !has(locationDetails, 'hours.fridayFrom'),
            saturdayClosed: !has(locationDetails, 'hours.saturdayFrom'),
            sundayClosed: !has(locationDetails, 'hours.sundayFrom'),
            geofence: get(locationDetails, 'geofence'),
            address: get(locationDetails, 'address', locationAddressFactory()),
            address2: addressLine2SplitValue,
            contacts: get(locationDetails, 'contacts', []),
            distanceValue: get(locationDetails, 'geofence.value', defaultDistanceValue),
            timeValue: geofenceRangeInMinutes,
            fenceType: get(locationDetails, 'geofence.type', FenceType.TIME),
            distanceUnits: DistanceUnitsEnum.METERS,
            isChinaAsCountry: isChinaAsCountry,
          } as FormValues
        }
        isInitialValid
        enableReinitialize
        validate={formValidation}
        validateOnBlur
        validateOnChange
        onSubmit={editFormSubmit}
      >
        {(formProps: FormikProps<FormValues>) => (
          <Form
            noNav={props.noNav}
            isNewLocation={newLocation}
            canEditLocation={props.canEditLocation}
            isEditMode={isEditMode}
            isResetting={isResetting}
            toggleEditMode={toggleEditMode}
            toggleIsResetting={toggleIsResetting}
            formProps={formProps}
            locationDetails={locationDetails}
            geofenceRangeInMinutes={geofenceRangeInMinutes}
            userEnteredAddress={userEnteredAddress}
            updateUserEnteredAddress={updateUserEnteredAddress}
            latLngInputValue={latLngInputValue}
            updatelatLngInputValue={updatelatLngInputValue}
            useAddressLatLngInMap={useAddressLatLngInMap}
            updateUseAddressLatLngInMap={updateUseAddressLatLngInMap}
            formattedAddress={formattedAddress}
            mapData={get(locationDetails, 'id') === props.match.params.locationDetailId ? locationDetails : {}}
            address2Units={address2Units}
            updateAddress2Units={updateAddress2Units}
            handleCustomGeofenceCoords={handleCustomGeofenceCoords}
            intl={intl}
            analyticsData={analyticsData}
            defaultFenceType={defaultFenceType}
            defaultDistanceValue={defaultDistanceValue}
            defaultDistanceUnits={formProps.values.distanceUnits}
          />
        )}
      </Formik>
    </>
  );
};

export default injectIntl(LocationDetailsComponent);
