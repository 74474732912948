export const GET_NOTIFICATIONS_SUCCESS = 'notifications/manage/GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'notifications/manage/GET_NOTIFICATIONS_FAIL';
export const GET_NOTIFICATIONS = 'notifications/manage/GET_NOTIFICATIONS';
export const EXPAND_COLLAPSE_NOTIFICATION = 'notifications/manage/EXPAND_COLLAPSE_NOTIFICATION';
export const GET_NOTIFICATION = 'notifications/addEdit/GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'notifications/addEdit/GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAIL = 'notifications/addEdit/GET_NOTIFICATION_FAIL';

export const GET_CONFIG_IDENTITIES = 'notifications/addEdit/GET_CONFIG_IDENTITIES';
export const GET_CONFIG_IDENTITIES_SUCCESS = 'notifications/addEdit/GET_CONFIG_IDENTITIES_SUCCESS';
export const GET_CONFIG_IDENTITIES_FAIL = 'notifications/addEdit/GET_CONFIG_IDENTITIES_FAIL';

export const SET_NOTIFICATION_MODE = 'notifications/addEdit/SET_NOTIFICATION_MODE';

export const UPDATE_MANAGE_NOTIFICATIONS_SHIPMENT_ACCESS_LEVEL =
  'notifications/addEdit/UPDATE_MANAGE_NOTIFICATIONS_SHIPMENT_ACCESS_LEVEL';
export const UPDATE_MANAGE_NOTIFICATIONS_SHIPMENT_ACCESS_LEVEL_SUCCESS =
  'notifications/addEdit/UPDATE_MANAGE_NOTIFICATIONS_SHIPMENT_ACCESS_LEVEL_SUCCESS';
export const UPDATE_NOTIFICATION_SHIPMENT_ACCESS_LEVEL =
  'notifications/addEdit/UPDATE_NOTIFICATION_SHIPMENT_ACCESS_LEVEL';
export const UPDATE_NOTIFICATION_SHIPMENT_ACCESS_LEVEL_SUCCESS =
  'notifications/addEdit/UPDATE_NOTIFICATION_SHIPMENT_ACCESS_LEVEL_SUCCESS';
export const UPDATE_SUBSCRIBER_SUGGESTIONS = 'notifications/addEdit/UPDATE_SUBSCRIBER_SUGGESTIONS';
export const UPDATE_SUBSCRIBER_SUGGESTIONS_SUCCESS = 'notifications/addEdit/UPDATE_SUBSCRIBER_SUGGESTIONS_SUCCESS';
export const SAVE_NOTIFICATION = 'notifications/addEdit/SAVE_NOTIFICATION';
export const SAVE_NOTIFICATION_SUCCESS = 'notifications/addEdit/SAVE_NOTIFICATION_SUCCESS';
export const SAVE_NOTIFICATION_FAIL = 'notifications/addEdit/SAVE_NOTIFICATION_FAIL';

export const DELETE_NOTIFICATION = 'notifications/addEdit/DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'notifications/addEdit/DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAIL = 'notifications/addEdit/DELETE_NOTIFICATION_FAIL';

export const RESET_NOTIFICATION_FORM = 'notifications/addEdit/resetForm';

export default {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  GET_CONFIG_IDENTITIES,
  GET_CONFIG_IDENTITIES_SUCCESS,
  GET_CONFIG_IDENTITIES_FAIL,
  EXPAND_COLLAPSE_NOTIFICATION,
  UPDATE_MANAGE_NOTIFICATIONS_SHIPMENT_ACCESS_LEVEL,
  UPDATE_MANAGE_NOTIFICATIONS_SHIPMENT_ACCESS_LEVEL_SUCCESS,
  UPDATE_NOTIFICATION_SHIPMENT_ACCESS_LEVEL,
  UPDATE_NOTIFICATION_SHIPMENT_ACCESS_LEVEL_SUCCESS,
  UPDATE_SUBSCRIBER_SUGGESTIONS,
  UPDATE_SUBSCRIBER_SUGGESTIONS_SUCCESS,
  SAVE_NOTIFICATION,
  SAVE_NOTIFICATION_SUCCESS,
  SAVE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  RESET_NOTIFICATION_FORM,
  SET_NOTIFICATION_MODE,
};
