import { fork, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import get from 'lodash/get';
import * as actions from './actions';
import { HereMapsConfig } from '../models/HereMapsConfig';

export function* fetchConfigAsync() {
  try {
    const config: HereMapsConfig = {
      hereMapsApiKey: `${get(window, 'portalGlobal.heremapsApiKey')}`,
      hereMapsCode: `${get(window, 'portalGlobal.heremapsAppCode')}`,
      hereMapsId: `${get(window, 'portalGlobal.heremapsAppID')}`,
    };
    if (config != null) {
      yield put(actions.fetch.success(config));
    }
  } catch (error) {
    yield put(actions.fetch.failure());
  }
}

export function* watchConfigAsync() {
  yield takeEvery(getType(actions.fetch.request), fetchConfigAsync);
}

export default function* hereMapsConfigOperations() {
  yield fork(watchConfigAsync);
}
