import { fork } from 'redux-saga/effects';
import addEditShipmentReducer, {
  availableTrackingMethods,
  carrierTypeIdentifiers,
  shipmentTypeIdentifiers,
  addEditShipmentFormReducer,
} from './reducers';

import AddEditShipmentContainer from '../AddEditShipmentContainer';

import { watchTransitions } from './addEditFormActionTransitionsSaga';
import { watchPostalCodeLookupSuccess } from './addEditFormAutoPopulationSaga';
import {
  watchCarrierValidation,
  watchPostalCodeEntered,
  watchSaveShipment,
  watchEditStop,
} from './addEditShipmentSaga';
import * as addEditShipmentTypes from './types';
import EditShipmentMapper from './mappers/editShipmentMapper';

function* addEditShipmentOperations() {
  yield fork(watchTransitions);
  yield fork(watchPostalCodeLookupSuccess);
  yield fork(watchCarrierValidation);
  yield fork(watchPostalCodeEntered);
  yield fork(watchSaveShipment);
  yield fork(watchEditStop);
}

export {
  AddEditShipmentContainer,
  addEditShipmentOperations,
  availableTrackingMethods,
  carrierTypeIdentifiers,
  shipmentTypeIdentifiers,
  addEditShipmentFormReducer,
  addEditShipmentTypes,
  EditShipmentMapper,
};

export default addEditShipmentReducer;
