import { Row, Col } from 'ui-components';
import './Group.scss';

const Group = (props) => {
  if (!props.expanded) {
    return (
      <Row type="flex" className="route-group animated fadeIn">
        <Row type="flex" className="route-update">
          <Col className="route-update__date" />
          <Col className="route-update__line">
            <span className="vertical-line" />
          </Col>
          <Col className="route-update__label">{props.text}</Col>
          <Col className="route-update__expand" onClick={() => props.toggleGroupedUpdates(props.updateIndex)}>
            Show
          </Col>
        </Row>
      </Row>
    );
  } else {
    return (
      <Row type="flex" className="route-group expanded animated fadeIn">
        <Col>
          {props.updates.map((update) => (
            <Row key={`${update.date}${update.derivedStatus}${update.tertiary}`} type="flex" className="route-update">
              <Col className="route-update__date">{update.date}</Col>
              <Col className="route-update__line">
                <span className="vertical-line" />
              </Col>
              <Row type="flex" className="route-update__info">
                <Col className="route-update__label">{update.derivedStatus}</Col>
                {update.derivedStatus && update.tertiary && <Col className="route-update__divider">|</Col>}
                {update.derivedStatus && update.tertiary && (
                  <Col className="route-update__tertiary">{update.tertiary}</Col>
                )}
              </Row>
            </Row>
          ))}
        </Col>
        <Col className="route-update__vertical-divider" />
        <Col className="route-update__expand" onClick={() => props.toggleGroupedUpdates(props.updateIndex)}>
          Hide
        </Col>
      </Row>
    );
  }
};

export default Group;
