import MapMarker from '../mapMarker/MapMarker';
import './ShipmentStatus.scss';

const ShipmentStatus = (props) => {
  const percentComplete = +props.percentComplete;
  const statusIconClasses = `status-icon ${percentComplete}`;
  let markerDimensions = {
    width: 38,
    height: 42,
  };

  const coloredLineStyle = {
    background: `var(${props.statusColor})`,
    width: `${percentComplete * 100}%`,
  };
  const baseLineStyle = {
    width: '100%',
  };
  const iconPosition = {
    left: `${percentComplete * 100}%`,
  };

  return (
    <div className="ShipmentStatus">
      <div className={statusIconClasses} style={iconPosition}>
        <MapMarker width={markerDimensions.width} height={markerDimensions.height} fill={coloredLineStyle.background} />
      </div>
      <div className="colored-status-line" style={coloredLineStyle} />
      <div className="status-line" style={baseLineStyle} />
      {props.statusText && (
        <div className="small-text bold text-uppercase">
          {
            props.statusText // this is displayed on shipment list page
          }
        </div>
      )}

      {props.statusDetails &&
        props.statusDetails.map(
          (
            status // this is displayed on shipment detail page
          ) => (
            <p className="small-text" key={status}>
              {status}&nbsp;
            </p>
          )
        )}
    </div>
  );
};

export default ShipmentStatus;
