import moment from 'moment-timezone';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import CalendarRange from '../../common/calendarRange/CalendarRange';

const getOptionalQuickViewTileDateRangeSelection = (propsChildren) => {
  let option = null;
  if (propsChildren && propsChildren.length) {
    propsChildren.forEach((child) => {
      if (child.props.id === 'Vessel Arrival Date') {
        option = child;
        return;
      }
    });
  }
  return option;
};

const OceanArrivalFilter = (props) => (
  <>
    {getOptionalQuickViewTileDateRangeSelection(props.children)}
    <Col>
      <FormattedMessage
        id="shipmentList.oceanArrivalFilter.arrivalDateRangeWithTimezone"
        defaultMessage="ARRIVAL DATE RANGE ({timezone})"
        tagName="label"
        values={{
          timezone: moment.tz(moment.tz.guess()).zoneAbbr(),
        }}
      />
      <CalendarRange
        datepickerStartChangeFn={(date) => props.handleDatePickerChange(date, 'deliveryDateStart')}
        datepickerEndChangeFn={(date) => props.handleDatePickerChange(date, 'deliveryDateEnd')}
        boundStartDateValue={props.filter.deliveryDateStart}
        boundEndDateValue={props.filter.deliveryDateEnd}
      />
    </Col>
  </>
);

export default OceanArrivalFilter;
