export const ltlPickupText = {
  SCHEDULED: 'By {date} at {time}',
  ATTEMPTING_TO_TRACK: 'By {date} at {time}',
  PICKUP_MISSED: 'By {date} at {time}',
  INTERLINE_MISSED: 'By {date} at {time}',
  AT_PICKUP: 'Arrived on {date} at {time}',
  PICKED_UP: 'Picked up on {date} at {time}',
  DEPARTURE_MISSED: 'Picked up on {date} at {time}',
  DELIVERY_MISSED: 'Picked up on {date} at {time}',
  HELD: 'Picked up on {date} at {time}',
  DELAYED: 'Picked up on {date} at {time}',
  AT_TERMINAL: 'Picked up on {date} at {time}',
  AT_DELIVERY: 'Picked up on {date} at {time}',
  IN_TRANSIT: 'Picked up on {date} at {time}',
  OUT_FOR_DELIVERY: 'Picked up on {date} at {time}',
  DELIVERED: 'Picked up on {date} at {time}',
  EXCEPTION: 'By {date} at {time}',
  CANCELED: 'By {date} at {time}',
  TIMED_OUT: 'By {date} at {time}',
  UNKNOWN: 'By {date} at {time}',
};
