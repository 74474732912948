import styled from 'styled-components';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import filterFunc from 'lodash/filter';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import replace from 'lodash/replace';
import lowerCase from 'lodash/lowerCase';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import compact from 'lodash/compact';
import { primaryGreyTwenty, primaryBlue } from 'styles/colors';

import EventTypeFilter from 'components/filter/types/EventTypeFilter';
import ReferenceKeyFilterComponent from './ReferenceKeyFilter';
import LocationTypeComponent from './LocationTypeFilter';

import filterMessages from '../../../filter/modes/filterMessages.json';
import CheckList from '../../../common/checkList/CheckList';

import { messages } from '../utils';

const StyledRow = styled('div')`
  &:not(.no-gutters) {
    margin-left: -24px;
    margin-right: -24px;
  }

  @media (max-width: 1070px) {
    flex-direction: column;
    border-left: 0 none;

    & > .col {
      margin-bottom: 20px;
    }
  }
`;

const StyledCol = styled('div')`
  border-left: 1px solid ${primaryGreyTwenty};
  padding-left: 24px;
  padding-right: 24px;

  &:first-child {
    border-left: 0 none;
  }

  .btn-inprogress-mobile {
    display: none;
  }

  @media (max-width: 1070px) {
    flex-direction: column;
    border-left: 0 none;
    border-bottom: 1px solid ${primaryGreyTwenty};
    margin-bottom: 20px;

    &:last-child {
      border-bottom: 1px solid transparent;
      margin-bottom: 0;
    }

    .btn-inprogress-desktop {
      display: none;
    }

    .btn-inprogress-mobile {
      display: block;
      margin-bottom: -17px;
      text-align: center;

      .ant-btn {
        background: white;
        width: auto !important;
      }
    }

    .hide-empty-title-on-mobile {
      display: block;
      margin-top: -12px;
      height: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  border: 1px solid ${primaryBlue};
  color: ${primaryBlue};
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  padding: 4px 16px;
  text-transform: uppercase;
  width: 100%;
`;

interface CheckListItemProps {
  id: string;
  dataKey: string;
  labelText: JSX.Element | string;
  propertyName: string;
  checked: boolean;
}

interface ListsProps {
  id: number;
  title?: any;
  subtitle?: any;
  hasShipmentInProgressButton: boolean;
  hasSelectAndClearAllOption: boolean;
  list: CheckListItemProps[] | [];
}

interface StatusFiltersProps extends ListsProps {
  lists?: ListsProps[] | undefined;
}

interface ShipmentStatusProps {
  filter: {
    status: string[];
  };
  handleCheckboxGroupUpdate: (obj: CheckListItemProps, filter: string) => void;
  checkboxTheme: any;
}

const ShipmentStatus = ({ filter, handleCheckboxGroupUpdate, checkboxTheme }: ShipmentStatusProps) => {
  const statusFilters = [
    {
      id: 1,
      title: (
        <span className="uppercase">
          <FormattedMessage {...messages.trackingPending} />
        </span>
      ),
      hasShipmentInProgressButton: false,
      hasSelectAndClearAllOption: true,
      list: [
        {
          id: filterMessages.scheduled.id,
          dataKey: filterMessages.scheduled.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.scheduled} />,
          propertyName: 'scheduled',
          checked: false,
        },
        {
          id: filterMessages.flightBooking.id,
          dataKey: filterMessages.flightBooking.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.flightBooking} />,
          propertyName: 'flightBooking',
          checked: false,
        },
        {
          id: filterMessages.trackingInitiate.id,
          dataKey: filterMessages.trackingInitiate.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.trackingInitiate} />,
          propertyName: 'trackingInitiate',
          checked: false,
        },
        {
          id: filterMessages.trackingStart.id,
          dataKey: filterMessages.trackingStart.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.trackingStart} />,
          propertyName: 'trackingStart',
          checked: false,
        },
      ],
      lists: [],
    },
    {
      id: 2,
      hasShipmentInProgressButton: true,
      hasSelectAndClearAllOption: false,
      list: [],
      lists: [
        {
          id: 2.1,
          title: (
            <span className="uppercase">
              <FormattedMessage {...messages.atStop} />
            </span>
          ),
          hasShipmentInProgressButton: false,
          hasSelectAndClearAllOption: true,
          list: [
            {
              id: filterMessages.arrivalAtStop.id,
              dataKey: filterMessages.arrivalAtStop.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.arrivalAtStop} />,
              propertyName: 'arrivalAtStop',
              checked: false,
            },
            {
              id: filterMessages.receiveFromShipper.id,
              dataKey: filterMessages.receiveFromShipper.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.receiveFromShipper} />,
              propertyName: 'receiveFromShipper',
              checked: false,
            },
            {
              id: filterMessages.loadOntoVehicle.id,
              dataKey: filterMessages.loadOntoVehicle.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.loadOntoVehicle} />,
              propertyName: 'loadOntoVehicle',
              checked: false,
            },
            {
              id: filterMessages.unloadFromVehicle.id,
              dataKey: filterMessages.unloadFromVehicle.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.unloadFromVehicle} />,
              propertyName: 'unloadFromVehicle',
              checked: false,
            },
          ],
        },
        {
          id: 2.2,
          title: (
            <span className="uppercase">
              <FormattedMessage {...messages.inTransit} />
            </span>
          ),
          hasShipmentInProgressButton: false,
          hasSelectAndClearAllOption: true,
          list: [
            {
              id: filterMessages.manifest.id,
              dataKey: filterMessages.manifest.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.manifest} />,
              propertyName: 'manifest',
              checked: false,
            },
            {
              id: filterMessages.departureFromStop.id,
              dataKey: filterMessages.departureFromStop.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.departureFromStop} />,
              propertyName: 'departureFromStop',
              checked: false,
            },
            {
              id: filterMessages.consigneeNotify.id,
              dataKey: filterMessages.consigneeNotify.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.consigneeNotify} />,
              propertyName: 'consigneeNotify',
              checked: false,
            },
          ],
        },
        {
          id: 2.3,
          title: (
            <span className="uppercase">
              <FormattedMessage {...messages.exceptions} />
            </span>
          ),
          hasShipmentInProgressButton: false,
          hasSelectAndClearAllOption: true,
          list: [
            {
              id: filterMessages.warning.id,
              dataKey: filterMessages.warning.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.warning} />,
              propertyName: 'warning',
              checked: false,
            },
            {
              id: filterMessages.exception.id,
              dataKey: filterMessages.exception.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.exception} />,
              propertyName: 'exception',
              checked: false,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      title: (
        <span className="uppercase">
          <FormattedMessage {...messages.trackingEnded} />
        </span>
      ),
      hasShipmentInProgressButton: false,
      hasSelectAndClearAllOption: true,
      list: [
        {
          id: filterMessages.delivery.id,
          dataKey: filterMessages.delivery.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.delivery} />,
          propertyName: 'delivery',
          checked: false,
        },
        {
          id: filterMessages.trackingComplete.id,
          dataKey: filterMessages.trackingComplete.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.trackingComplete} />,
          propertyName: 'trackingComplete',
          checked: false,
        },
        {
          id: filterMessages.trackingEndByUser.id,
          dataKey: filterMessages.trackingEndByUser.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.trackingEndByUser} />,
          propertyName: 'trackingEndByUser',
          checked: false,
        },
        {
          id: filterMessages.trackingEndDueToTimeout.id,
          dataKey: filterMessages.trackingEndDueToTimeout.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.trackingEndDueToTimeout} />,
          propertyName: 'trackingEndDueToTimeout',
          checked: false,
        },
      ],
      lists: [],
    },
  ];

  const onCheck = (checkboxItem: CheckListItemProps) =>
    handleCheckboxGroupUpdate({ ...checkboxItem, labelText: checkboxItem.dataKey }, 'status');

  const onSelectShipmentsInProgress = () => {
    const shipmentsInProgressOptions = filterFunc(
      statusFilters,
      (item: StatusFiltersProps) => !!item.hasShipmentInProgressButton
    );
    const lists = get(shipmentsInProgressOptions, ['0', 'lists'], []);

    const list = reduce(
      lists,
      (result: CheckListItemProps[], value) => {
        return [...result, ...value.list];
      },
      []
    );

    return forEach(list, (item: CheckListItemProps) => {
      if (!filter.status.includes(item.dataKey)) {
        return onCheck(item);
      }
    });
  };

  const formatDataLocator = (text: string | undefined) => {
    const result = replace(lowerCase(text), /\s/g, '-');
    return `${result}-status-filter`;
  };

  return (
    <>
      <StyledRow className="row">
        {statusFilters.map(
          ({ id, title, hasShipmentInProgressButton, hasSelectAndClearAllOption, list, lists }: StatusFiltersProps) => {
            if (isEmpty(lists)) {
              const checkListData = list.map((item: CheckListItemProps) => ({
                ...item,
                checked: filter.status.includes(item.dataKey),
              }));

              return (
                <StyledCol className="col-auto" key={id}>
                  <StyledRow className="row no-gutters">
                    <CheckList
                      mode={checkboxTheme}
                      listTitle={title}
                      checkListData={checkListData}
                      checkFn={onCheck}
                      data-locator={formatDataLocator(title)}
                      hasSelectAndClearAllOption={hasSelectAndClearAllOption}
                    />
                  </StyledRow>
                </StyledCol>
              );
            }

            return (
              <StyledCol className="col-auto" key={id}>
                <StyledRow className="row">
                  {lists?.map((subList: StatusFiltersProps) => {
                    const checkListData = subList.list.map((item: CheckListItemProps) => ({
                      ...item,
                      checked: filter.status.includes(item.dataKey),
                    }));
                    const showMobileBtn = hasShipmentInProgressButton && subList.id === 2.1;

                    return (
                      <StyledCol className={`col-auto col-subtitle ${showMobileBtn ? 'mb-5' : ''}`} key={subList.id}>
                        <StyledRow className="row no-gutters">
                          <CheckList
                            mode={checkboxTheme}
                            listTitle={subList.title}
                            listSubtitle={subList.subtitle}
                            checkListData={checkListData}
                            checkFn={onCheck}
                            data-locator={formatDataLocator(subList.title)}
                            hasSelectAndClearAllOption={subList.hasSelectAndClearAllOption}
                          />
                        </StyledRow>
                        {showMobileBtn && (
                          <div className="btn-inprogress-mobile">
                            <div className="d-inline-block px-3 bg-white">
                              <StyledButton onClick={onSelectShipmentsInProgress}>
                                <FormattedMessage
                                  id="quickView.filters.shipmentsInProgress"
                                  defaultMessage="Shipments in Progress"
                                />
                              </StyledButton>
                            </div>
                          </div>
                        )}
                      </StyledCol>
                    );
                  })}
                </StyledRow>
                {hasShipmentInProgressButton && (
                  <StyledRow className="row btn-inprogress-desktop">
                    <StyledCol className="col">
                      <StyledButton onClick={onSelectShipmentsInProgress} className="mt-4">
                        <FormattedMessage
                          id="quickView.filters.shipmentsInProgress"
                          defaultMessage="Shipments in Progress"
                        />
                      </StyledButton>
                    </StyledCol>
                  </StyledRow>
                )}
              </StyledCol>
            );
          }
        )}
      </StyledRow>
    </>
  );
};

const ReferenceKeyFilter = (props: any) => <ReferenceKeyFilterComponent {...props} />;

const LocationTypeFilter = (props: any) => <LocationTypeComponent {...props} />;

export default (props: any) => {
  const airFilters = [
    {
      name: <FormattedMessage {...messages.shipmentStatus} />,
      component: ShipmentStatus,
      modalId: 'shipmentStatus',
    },
    {
      name: <FormattedMessage {...messages.event} />,
      component: EventTypeFilter,
      modalId: 'event',
      classNameContent: 'p-0',
    },
    {
      name: <FormattedMessage {...messages.location} />,
      component: LocationTypeFilter,
      modalId: 'locationType',
      classNameContent: 'p-0',
    },
    {
      name: <FormattedMessage {...messages.referenceKey} />,
      component: ReferenceKeyFilter,
      modalId: 'attributeKeyValuePairs',
      customHeader: true,
    },
  ];

  return compact(airFilters);
};
