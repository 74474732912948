import types from './types';

export const getConfig = () => ({
  type: types.CONFIG_GET,
});

export const configSuccess = (hereMapsConfig) => ({
  type: types.CONFIG_GET_SUCCESS,
  payload: { hereMapsConfig },
});

export const configFailure = (error) => ({
  type: types.CONFIG_GET_FAILURE,
  error,
});
