import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import axios from 'util/paxios';
import { Page, SortField } from 'models';
import { buildUrlWithPaginationParams } from 'models/page-helper';
import { buildUrlWithSortParams } from 'models/sort-helper';
import { BasicInventoryOrderSearchCriteria, InventoryOrder } from 'components/Inventory/models';
import { API_PATH } from 'common/AppConstants';
import endpoints from 'common/endpoints';

interface InventoryOrdersHook {
  hasPermission: boolean;
  masterShipmentId?: string;
  defaultPageSize?: number;
  searchText?: string;
  inventoryOrderIds?: string[];
  filters?: string;
  page?: number;
  sort?: SortField<string>[];
  isMultiValue?: boolean;
}

export enum DataLoadingEnum {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR_LOADING = 'ERROR_LOADING',
}

export function useInventoryOrders({
  hasPermission,
  masterShipmentId,
  defaultPageSize = 30,
  searchText = '',
  inventoryOrderIds,
  filters,
  page = 1,
  sort = [],
  isMultiValue = false,
}: InventoryOrdersHook) {
  const initialPageState = {
    paginationInfo: {
      total: 0,
      pageSize: 0,
      pageNumber: 0,
    },
    results: [],
  };

  const [orders, setOrders] = useState<Page<InventoryOrder>>(initialPageState);
  const [ordersDataLoading, setOrdersDataLoading] = useState<DataLoadingEnum>(DataLoadingEnum.LOADING);

  const getOrders = useCallback(async function getOrders(
    requestBody: BasicInventoryOrderSearchCriteria,
    pageNumber: number,
    pageSize: number,
    sortFields?: SortField<string>[]
  ) {
    try {
      let url = new URL(`${API_PATH}${endpoints.ORDERS_SEARCH}`, window.location.origin);
      url = buildUrlWithPaginationParams(url, { pageNumber, pageSize });
      url = buildUrlWithSortParams(url, sortFields);

      const response: AxiosResponse<Page<InventoryOrder>> = await axios.post(url.toString(), requestBody, {
        withCredentials: true,
      });

      setOrders(response.data);
      setOrdersDataLoading(DataLoadingEnum.LOADED);
    } catch (error) {
      setOrdersDataLoading(DataLoadingEnum.ERROR_LOADING);
    }
  },
  []);

  useEffect(() => {
    if (hasPermission) {
      setOrdersDataLoading(DataLoadingEnum.LOADING);

      const prebuiltBody = {
        ...(masterShipmentId && { shipmentIds: [masterShipmentId] }),
        ...(inventoryOrderIds &&
          inventoryOrderIds.length &&
          inventoryOrderIds.length > 0 && { ids: inventoryOrderIds }),
        ...(filters && JSON.parse(filters)),
      };

      let body: {};
      // If modal is used, values will be split up to be sent in the orderIdentifiers array
      if (isMultiValue) {
        body = {
          ...prebuiltBody,
          orderIdentifiers: Array.from(searchText.split(' ')),
        };
      } else {
        body = {
          ...prebuiltBody,
          searchText,
        };
      }

      getOrders(body, page, defaultPageSize, sort);
    }
  }, [
    getOrders,
    hasPermission,
    masterShipmentId,
    defaultPageSize,
    searchText,
    inventoryOrderIds,
    filters,
    page,
    sort,
    isMultiValue,
  ]);

  return {
    isLoading: DataLoadingEnum.LOADING === ordersDataLoading,
    orders,
  };
}
