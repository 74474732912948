import { useEffect, useState } from 'react';
import ReactMapboxGl from 'react-mapbox-gl';
import { AxiosResponse } from 'axios';
import { Subtract } from 'utility-types';

import { FactoryParameters, Props as MapboxProps } from 'react-mapbox-gl/lib/map';
import { Events as MapboxEvents } from 'react-mapbox-gl/lib/map-events';
import axios from 'util/paxios';
import { API_PATH } from 'common/AppConstants';

export type MapboxMapOptions = Subtract<FactoryParameters, { accessToken: string }>;

export const useMapboxMap = (options: MapboxMapOptions): [React.ComponentType<MapboxProps & MapboxEvents>, boolean] => {
  const [map, setMap] = useState<undefined | any>(undefined);
  const [errorLoadingMapConfig, setErrorLoadingMapConfig] = useState<boolean>(false);

  useEffect(() => {
    async function fetchMapboxConfig() {
      try {
        const url = `${API_PATH}/profile/mapboxConfig`;
        const response: AxiosResponse<{ apiKey: string }> = await axios.get(url, { withCredentials: true });

        const MapboxMap = ReactMapboxGl({
          accessToken: response.data.apiKey,
          ...options,
        });

        // Function is required to set a component as the state value otherwise react tries to call the component as a function
        setErrorLoadingMapConfig(false);
        setMap(() => MapboxMap);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
        setErrorLoadingMapConfig(true);
      }
    }
    fetchMapboxConfig();
  }, [options]);
  return [map, errorLoadingMapConfig];
};
