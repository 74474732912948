import { useState } from 'react';
import split from 'lodash/split';
import join from 'lodash/join';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import set from 'lodash/set';
import get from 'lodash/get';

import KeysStep from './KeysStep';
import ValuesStep from './ValuesStep';

const generateAttributeKeyValuePairs = (attributeKeyValuePairs) => {
  return attributeKeyValuePairs.reduce((acc, attribute) => {
    const [attrName, ...rest] = split(attribute, ':');
    const value = join(rest, ':');
    const currentObj = find(acc, (o) => o.name === attrName);
    if (!currentObj) return [...acc, { name: attrName, values: [value] }];
    const index = findIndex(acc, (o) => o.name === attrName);
    return set(acc, [index], { ...currentObj, values: [...currentObj.values, value] });
  }, []);
};

const ReferenceKeyFilter = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentKey, setCurrentKey] = useState({});
  const defaultFilters = get(props, 'filter.attribute', []);
  const attributeKeyValuePairs = generateAttributeKeyValuePairs(defaultFilters);
  const onSelect = (filterItem) => props.handleAutocompleteUpdate(filterItem, 'attribute');
  const isQuickViewSetup = get(props, 'isQuickViewSetup', false);

  return (
    <div>
      {currentStep === 0 && (
        <KeysStep
          setCurrentStep={setCurrentStep}
          setCurrentKey={setCurrentKey}
          modalName={props.modalName}
          closeModal={props.closeModal}
          attributeKeyValuePairs={attributeKeyValuePairs}
          isQuickViewSetup={isQuickViewSetup}
        />
      )}
      {currentStep === 1 && (
        <ValuesStep
          currentKey={currentKey}
          setCurrentStep={setCurrentStep}
          onSelect={onSelect}
          attributeKeyValuePairs={attributeKeyValuePairs}
          isQuickViewSetup={isQuickViewSetup}
        />
      )}
    </div>
  );
};

export default ReferenceKeyFilter;
