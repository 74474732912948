import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

import { useIntl, IntlShape } from 'react-intl';
import mapThemeResponse from 'components/settings/BrandTheming/utils/mapThemeResponse';
import { isDemoShipment } from 'components/landing/LandingMap/demoShipments';
import {
  ShipmentModeEnum,
  Shipment,
  ShipmentShareResponse,
  Theme,
  HereMapsConfig,
  ShipmentRawResponse,
  ShipmentLeg,
} from 'models';
import { API_PATH } from '../../../common/AppConstants';
import axios from '../../../util/paxios';
import { getUnknownStopText } from '../utils';

export const useShipment = (
  setHereMapsConfig: (hereMapsConfig: HereMapsConfig) => void,
  setCustomTheme: (theme: Theme) => void,
  setCustomerProperties: (customerProperties: any) => void,
  shipmentId?: string,
  masterShipmentId?: string,
  shipmentMode?: ShipmentModeEnum,
  publicShareToken?: string
): [boolean, Shipment | undefined, boolean, any | undefined] => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [shipment, setShipment] = useState<Shipment | undefined>(undefined);
  const intl = useIntl();

  const hasShipmentIdAndMode = !isNil(shipmentId) && !isNil(shipmentMode);
  const hasRequiredParams = hasShipmentIdAndMode || !isNil(masterShipmentId) || !isNil(publicShareToken);

  useEffect(() => {
    async function fetchShipment() {
      try {
        setIsLoading(true);
        setError(undefined);
        let url = '';
        if (isDemoShipment(masterShipmentId)) {
          url = `${API_PATH}/shipments?masterShipmentId=${masterShipmentId}&mock=true`;
        } else if (masterShipmentId !== undefined) {
          url = `${API_PATH}/shipments?masterShipmentId=${masterShipmentId}`;
        } else {
          url = `${API_PATH}/shipments?mode=${shipmentMode}&shipmentId=${shipmentId}`;
        }
        const response: AxiosResponse<ShipmentRawResponse> = await axios.get(url, { withCredentials: true });
        setIsLoading(false);
        const sanitizedShipment = sanitizeShipmentRawResponse(response.data, intl);
        setShipment(sanitizedShipment);
      } catch (error) {
        setIsLoading(false);
        setError(error as Error);
        setShipment(undefined);
      }
    }

    async function fetchPublicShipment() {
      try {
        setIsLoading(true);
        setError(undefined);
        const url = `${API_PATH}/shipment/share/public/intermodal/${publicShareToken}`;
        const response: AxiosResponse<ShipmentShareResponse> = await axios.get(url, { withCredentials: true });
        setIsLoading(false);
        const sanitizedShipment = sanitizeShipmentRawResponse(get(response, 'data.shipment'), intl);
        setShipment(sanitizedShipment);
        setCustomTheme(mapThemeResponse(get(response, 'data.brandingDto')));
        setHereMapsConfig(get(response, 'data.hereMapsConfig'));
        setCustomerProperties(get(response, 'data.customerProperties'));
      } catch (error) {
        setIsLoading(false);
        setError(error as Error);
        setShipment(undefined);
      }
    }

    if (!isNil(publicShareToken)) {
      fetchPublicShipment();
    } else if (hasRequiredParams) {
      fetchShipment();
    }
  }, [
    shipmentId,
    masterShipmentId,
    shipmentMode,
    publicShareToken,
    hasRequiredParams,
    setCustomTheme,
    setHereMapsConfig,
    intl,
    setCustomerProperties,
  ]);

  return [hasRequiredParams, shipment, isLoading, error];
};

const sanitizeShipmentRawResponse = (rawShipment: ShipmentRawResponse, intl: IntlShape): Shipment => {
  const currentStatusDateTime = (get(rawShipment, 'currentStatusDateTime.dateTimeUtc') ||
    get(rawShipment, 'currentStatusDateTime')) as string;
  return { ...rawShipment, legs: populateUnknownStopNames(rawShipment.legs, intl), currentStatusDateTime };
};

export const populateUnknownStopNames = (legs: ShipmentLeg[], intl: IntlShape): ShipmentLeg[] => {
  let prevStopName = '';
  return legs.map((leg, legIndex) => {
    return {
      ...leg,
      stops: leg.stops.map((stop, stopIndex) => {
        let stopName = stop.stopName;
        if (!get(stop, 'location.city') && !get(stop, 'location.state')) {
          if (stopIndex === 0 && legIndex > 0) {
            // first stop of a leg should be the same as the last stop of the previous leg
            stopName = prevStopName;
          } else {
            stopName = stop.stopName ? stop.stopName : getUnknownStopText(stop, intl, leg.mode);
          }
        }
        prevStopName = stopName;
        return {
          ...stop,
          stopName: stopName,
        };
      }),
    };
  });
};
