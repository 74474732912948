import styled from 'styled-components';

export interface SVGIconProps {
  name: string;
  className?: string;
}

const StyledSVG = styled('svg')`
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
`;

const SVGIcon = ({ name, className = '' }: SVGIconProps) => (
  <StyledSVG className={`icon-${name} ${className}`}>
    <use xlinkHref={`#icon-${name}`} />
  </StyledSVG>
);

export default SVGIcon;
