import * as React from 'react';
import { IntlProvider } from 'react-intl';
import get from 'lodash/get';
import axios from 'util/paxios';
import { messages } from 'i18n/configurei18n';
import { trackEvent } from 'common/eventTracker';
import { useLocaleDispatch, useLocaleState } from './SimpleLocalizeContext';

enum SimpleLocalizeLoadingStatus {
  LOADING,
  LOADING_FALLBACK,
  LOADED,
  ERROR,
}

interface SimpleLocalizeProps {
  projectToken: string;
  snapshot: string;
  tenantId: string;
}

// list of language keys in SimpleLocalize that don't have a shortLocale for a key
const localeOverridesForSL = {
  es: 'es-es',
};

const fullLocales = ['pt-br', 'es-mx'];

const applyShortLocaleOverrides = (shortLocale: string) => {
  return get(localeOverridesForSL, shortLocale, shortLocale);
};

const SimpleLocalize: React.FC<SimpleLocalizeProps> = ({ projectToken, snapshot = '_latest', children, tenantId }) => {
  const [currentLoadingStatus, setCurrentLoadingStatus] = React.useState<SimpleLocalizeLoadingStatus>(
    SimpleLocalizeLoadingStatus.LOADING
  );
  const [messagesFromCdn, setMessages] = React.useState(messages);
  const { locale } = useLocaleState();
  const dispatch = useLocaleDispatch();
  const useShortenedLocale = !fullLocales.includes(locale.toLowerCase());
  const simpleLocalizeLocale = useShortenedLocale
    ? applyShortLocaleOverrides(locale.toLowerCase().split(/[_-]+/)[0])
    : locale.toLowerCase();

  const customId = `${(window as any).portalGlobal.environmentName}-${tenantId}`;

  const isCustomIdValid = async () => {
    try {
      const { data } = await axios.get(`https://cdn.simplelocalize.io/${projectToken}/${snapshot}/_customers`);

      const listOfCustomIds = data.map((customer: { id: string }) => customer.id);
      return listOfCustomIds.indexOf(customId) !== -1;
    } catch (error) {
      return false;
    }
  };

  React.useEffect(() => {
    async function fetchMessages() {
      try {
        const validCustomIdWithMessages = await isCustomIdValid();
        if (validCustomIdWithMessages) {
          const { data } = await axios.get(
            `https://cdn.simplelocalize.io/${projectToken}/${snapshot}/${simpleLocalizeLocale}_${customId}`
          );
          setMessages(data);
          setCurrentLoadingStatus(SimpleLocalizeLoadingStatus.LOADED);
        } else {
          const { data } = await axios.get(
            `https://cdn.simplelocalize.io/${projectToken}/${snapshot}/${simpleLocalizeLocale}`
          );
          setMessages(data);
          setCurrentLoadingStatus(SimpleLocalizeLoadingStatus.LOADED);
        }
      } catch (error) {
        setCurrentLoadingStatus(SimpleLocalizeLoadingStatus.LOADING_FALLBACK);
        trackEvent(`Error fetching locale => ${simpleLocalizeLocale}. Falling back to english`);
      }
    }

    if (projectToken) {
      fetchMessages();
    } else {
      setCurrentLoadingStatus(SimpleLocalizeLoadingStatus.ERROR);
      console.error('No project token set for Simple Localize Translations');
    }
  }, [locale, simpleLocalizeLocale, projectToken, snapshot, tenantId, customId]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    async function fetchMessages() {
      try {
        const { data } = await axios.get(`https://cdn.simplelocalize.io/${projectToken}/${snapshot}/en`);
        setMessages(data);
        setCurrentLoadingStatus(SimpleLocalizeLoadingStatus.LOADED);
      } catch (error) {
        setCurrentLoadingStatus(SimpleLocalizeLoadingStatus.ERROR);
        trackEvent('Error fetching English Language fallback from CDN');
      }
    }
    if (currentLoadingStatus === SimpleLocalizeLoadingStatus.LOADING_FALLBACK) {
      fetchMessages();
    }
  }, [currentLoadingStatus, dispatch, projectToken, simpleLocalizeLocale, snapshot]);

  if (
    currentLoadingStatus === SimpleLocalizeLoadingStatus.LOADING ||
    currentLoadingStatus === SimpleLocalizeLoadingStatus.LOADING_FALLBACK
  ) {
    return <div />;
  }

  return (
    <>
      <IntlProvider key="messageFromCdn" locale={simpleLocalizeLocale} messages={messagesFromCdn}>
        {children}
      </IntlProvider>
    </>
  );
};

export default SimpleLocalize;
