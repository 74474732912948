import { useState, useEffect, useContext } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { AxiosResponse } from 'axios';
import { Modal } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import styled from 'styled-components';
import phone from 'phone';
import { API_PATH } from 'common/AppConstants';
import { trackEvent } from 'common/eventTracker';
import { Button, Input, Radio, Select } from 'ui-components';
import axios from 'util/paxios';
import { PrincipalAuthorizations } from 'common/authorizations';
import { PrincipalContext } from 'contexts/PrincipalContext';

import { ThemeContext } from 'contexts/ThemeContext';
import colors from 'styles/colors';
import { Theme, EquipmentIdentifier } from 'models';
import { EquipmentIdentifierTypeEnum } from 'components/newShipmentDetails/models/EquipmentIdentifier';
import { filterEuIdentifierTypes, filterNaIdentifierTypes } from 'components/newShipmentDetails/utils/shipmentUtils';
import * as styles from './AddEquipmentIdentifier.module.scss';

const StyledButton = styled.button<{ theme?: Theme }>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.theme !== undefined ? props.theme.primaryColor : colors.primaryTextColor)};
`;

const StyledDatePicker: React.CSSProperties = {
  marginTop: '10px',
  width: '100%',
  height: '36px',
  border: '1px solid #d5d4d4',
};

const StyledRadioContainer: React.CSSProperties = {
  marginBottom: '10px',
};

export interface AddEquipmentIdentifierProps extends WrappedComponentProps {
  identifiers?: any;
  capacityProviderId?: number;
  shipmentId?: string;
  onAdd: (equipmentIdentifier: EquipmentIdentifier) => void;
}

const AddEquipmentIdentifier: React.FC<AddEquipmentIdentifierProps> = (props) => {
  const [isAddEquipIdModalVisible, setIsAddEquipIdModalVisible] = useState<boolean>(false);
  const [availableEquipIdTypes, setAvailableEquipIdTypes] = useState<EquipmentIdentifierTypeEnum[]>([]);
  const [selectedEquipIdType, setSelectedEquipIdType] = useState<string | undefined>();
  const [selectedEquipIdValue, setSelectedEquipIdValue] = useState<string>('');
  const [isSavingEquipId, setIsSavingEquipId] = useState<boolean>(false);
  const [equipmentIdentifiersError, setEquipmentIdentifiersError] = useState<string[]>([]);
  const theme = useContext(ThemeContext);
  const principalContext = React.useContext(PrincipalContext);
  const authorizations = new PrincipalAuthorizations({ authorities: [], principal: principalContext });
  const hasCarrierNetworkRole = authorizations.hasTenantCarrierRole(principalContext);
  const renderScheduledTruckChange = authorizations.renderScheduledTruckChange();

  const [EQUIPMENT_IDENTIFIER, SCHEDULE_TRUCK_CHANGE] = ['EQUIPMENT_IDENTIFIER', 'SCHEDULE_TRUCK_CHANGE'];
  const [fuctionalityType, setFuctionalityType] = useState(EQUIPMENT_IDENTIFIER);
  const [inputDate, setInputDate] = useState<string>('');

  useEffect(() => {
    async function fetchAvailableEquipIdTypes() {
      try {
        const url = `${API_PATH}/tl-shipment/equipmentIdentifierTypes`;
        const response: AxiosResponse<EquipmentIdentifierTypeEnum[]> = await axios.get(url, {
          withCredentials: true,
        });
        const filteredTypes = hasCarrierNetworkRole
          ? authorizations.isEUEnvironment()
            ? filterEuIdentifierTypes(response.data)
            : filterNaIdentifierTypes(response.data)
          : response.data;
        setAvailableEquipIdTypes(filteredTypes);
      } catch (error) {
        setEquipmentIdentifiersError([
          props.intl.formatMessage({
            id: 'shipmentDetails.equipmentIdentifiers.errorTypes',
            defaultMessage: 'Error loading equipment identifier types.',
          }),
        ]);
      }
    }
    fetchAvailableEquipIdTypes();
    // eslint complains about intl being in dependency array
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveEquipmentIdentifier = () => {
    let value: string = selectedEquipIdValue;
    const scheduledTruckChangeDateTime = fuctionalityType === SCHEDULE_TRUCK_CHANGE ? new Date(inputDate) : null;

    if (selectedEquipIdType === EquipmentIdentifierTypeEnum.MOBILE_PHONE_NUMBER) {
      const phoneValue = phone(selectedEquipIdValue).phoneNumber;
      if (!phoneValue) {
        setEquipmentIdentifiersError([
          props.intl.formatMessage({
            id: 'shipmentDetails.equipmentIdentifiers.phoneError',
            defaultMessage: 'Invalid phone number.',
          }),
        ]);
        return;
      } else {
        value = phoneValue;
      }
    }
    setEquipmentIdentifiersError([]);
    setIsSavingEquipId(true);
    const urlPrefix = `${API_PATH}/tl-shipment`;
    const path = hasCarrierNetworkRole
      ? `/${props.shipmentId}/equipmentIdentifierAssignment`
      : `/id/${props.shipmentId}/equipmentIdentifiers`;

    const body = {
      equipmentIdentifierType: selectedEquipIdType,
      equipmentIdentifierValue: value,
      shipmentId: props.shipmentId,
      scheduledTruckChangeDateTime,
    };
    const request = hasCarrierNetworkRole
      ? axios.post(`${urlPrefix}${path}`, body, {
          withCredentials: true,
          params: {
            shipmentIdentifiers: encodeURIComponent(JSON.stringify(props.identifiers)),
            capacityProviderId: encodeURIComponent(JSON.stringify(props.capacityProviderId)),
          },
        })
      : axios.put(`${urlPrefix}${path}`, body, {
          withCredentials: true,
        });
    request
      .then(() => {
        trackEvent('ADDED_EQUIPMENT_IDENTIFIER');
        props.onAdd({
          // @ts-ignore ignoring potential undefined since save is disabled if undefined
          type: selectedEquipIdType,
          value: selectedEquipIdValue,
        });
        setIsSavingEquipId(false);
        setIsAddEquipIdModalVisible(false);
      })
      .catch((error) => {
        setIsSavingEquipId(false);
        let errorResponses: string[] = [];
        if (!isNil(get(error, 'response.data.errors'))) {
          errorResponses = error.response.data.errors.map(
            (errorResponse: { message: string }) => errorResponse.message
          );
        }
        const defaultErrorMessage: string = props.intl.formatMessage({
          id: 'shipmentDetails.equipmentIdentifiers.errorSaving',
          defaultMessage: 'Error adding equipment identifier.',
        });
        setEquipmentIdentifiersError([defaultErrorMessage, ...errorResponses]);
      });
  };

  if (props.shipmentId === undefined) {
    return null;
  }

  return (
    <>
      <div className="d-flex justify-content-end" key="add-equip-id" data-locator="add-equipment-identifier">
        <StyledButton onClick={() => setIsAddEquipIdModalVisible(true)} theme={theme}>
          <i className={classNames('material-icons', 'left', styles.editIcon)}>edit</i>
          <FormattedMessage
            id="shipmentDetails.equipmentIdentifiers.add"
            defaultMessage="ADD NEW EQUIPMENT IDENTIFIER"
          />
        </StyledButton>
      </div>
      <Modal
        visible={isAddEquipIdModalVisible}
        title={props.intl.formatMessage({
          id: 'shipmentDetails.equipmentIdentifiers.modalTitle',
          defaultMessage: 'Add New Equipment Identifier',
        })}
        onCancel={() => setIsAddEquipIdModalVisible(false)}
        onOk={saveEquipmentIdentifier}
        footer={
          <div className="d-flex justify-content-end">
            <Button type="secondary" clickFn={() => setIsAddEquipIdModalVisible(false)}>
              <FormattedMessage id="shipmentDetails.equipmentIdentifiers.cancel" defaultMessage="CANCEL" />
            </Button>
            <Button
              type="primary"
              disabled={
                selectedEquipIdType === undefined ||
                selectedEquipIdValue === '' ||
                (fuctionalityType === SCHEDULE_TRUCK_CHANGE && inputDate === '')
              }
              clickFn={saveEquipmentIdentifier}
              loading={isSavingEquipId}
            >
              <FormattedMessage id="shipmentDetails.equipmentIdentifiers.save" defaultMessage="SAVE" />
            </Button>
          </div>
        }
      >
        <>
          <div className={styles.description}>
            <FormattedMessage
              id="shipmentDetails.equipmentIdentifiers.addEquipmentIdentifierDescription"
              defaultMessage="Adding a new equipment identifier will update the equipment identifier used for tracking this shipment."
            />
          </div>
          {renderScheduledTruckChange && (
            <div className="radioContainer" style={StyledRadioContainer}>
              <Radio
                value={fuctionalityType}
                onChange={(e: React.ChangeEvent<any>) => {
                  setFuctionalityType(e.target.value);
                }}
                radioData={[
                  {
                    value: EQUIPMENT_IDENTIFIER,
                    label: props.intl.formatMessage({
                      id: 'shipmentDetails.equipmentIdentifiers.equipmentIdentifier',
                      defaultMessage: 'Equipment Identifiers',
                    }),
                  },
                  {
                    value: SCHEDULE_TRUCK_CHANGE,
                    label: props.intl.formatMessage({
                      id: 'shipmentDetails.equipmentIdentifiers.scheduleTruckChange',
                      defaultMessage: 'Schedule Truck Change',
                    }),
                  },
                ]}
              />
            </div>
          )}
          <div className="d-flex">
            <Select
              data-testid="select-identifier-type"
              value={selectedEquipIdType}
              onChange={(equipId: string) => setSelectedEquipIdType(equipId)}
              placeholder={props.intl.formatMessage({
                id: 'shipmentDetails.equipmentIdentifiers.selectTypePlaceholder',
                defaultMessage: 'Select type',
              })}
              dataSource={availableEquipIdTypes.map((equipId) => ({
                value: equipId,
                displayValue: props.intl.formatMessage({
                  id: `shipmentDetails.equipmentIdentifiers.types.${equipId}`,
                  defaultMessage: equipId,
                }),
              }))}
            />
            <Input
              value={selectedEquipIdValue}
              data-testid="eq-identifier-input"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedEquipIdValue(event.target.value)}
              placeholder={props.intl.formatMessage({
                id: 'shipmentDetails.equipmentIdentifiers.valuePlaceholder',
                defaultMessage: 'Input value',
              })}
              className="pl-4"
            />
          </div>
          {renderScheduledTruckChange && fuctionalityType === SCHEDULE_TRUCK_CHANGE ? (
            <div className="d-flex">
              <input
                style={StyledDatePicker}
                type="datetime-local"
                value={inputDate}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputDate(event.target.value)}
              />
            </div>
          ) : null}
          <div data-locator="equip-identifier-errors">
            {equipmentIdentifiersError.map((error) => (
              <span key={error} className={styles.errorMessage}>
                {error}
              </span>
            ))}
          </div>
        </>
      </Modal>
    </>
  );
};

export default injectIntl(AddEquipmentIdentifier);
