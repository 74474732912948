import { CodeInlItem } from 'models';

export enum CommonStatusCodeEnum {
  IN_TRANSIT = 'IN_TRANSIT',
  AT_STOP = 'AT_STOP',
  COMPLETED = 'COMPLETED',
  UNKNOWN = 'UNKNOWN',
}

const commonMessages = {
  inTransit: { id: 'shipment.statusCode.common.inTransit', defaultMessage: 'In transit' },
  atStop: { id: 'shipment.statusCode.common.atStop', defaultMessage: 'At stop' },
  completed: { id: 'shipment.statusCode.parcel.completed', defaultMessage: 'Shipment complete' },
  unknown: { id: 'shipment.statusCode.common.unknown', defaultMessage: 'Unknown' },
  info: { id: 'shipment.statusCode.ltl.info', defaultMessage: 'Info' },
};

const CommonStatusCodeIntlKeys: { [P in CommonStatusCodeEnum]: CodeInlItem } = {
  [CommonStatusCodeEnum.IN_TRANSIT]: { past: commonMessages.inTransit },
  [CommonStatusCodeEnum.AT_STOP]: { past: commonMessages.atStop },
  [CommonStatusCodeEnum.COMPLETED]: { past: commonMessages.completed },
  [CommonStatusCodeEnum.UNKNOWN]: { past: commonMessages.unknown },
};

/*****************
 * Rail
 *****************/
export enum RailStatusCodeEnum {
  DISPATCHED = 'DISPATCHED',
  IN_TRANSIT = 'IN_TRANSIT',
  AT_STOP = 'AT_STOP',
  COMPLETED = 'COMPLETED',
  UNKNOWN = 'UNKNOWN',
}

const railMessages = {
  dispatched: { id: 'shipment.statusCode.rail.dispatched', defaultMessage: 'Dispatched' },
};

export const RailStatusCodeIntlKeys: { [P in RailStatusCodeEnum]: CodeInlItem } = {
  ...CommonStatusCodeIntlKeys,
  [RailStatusCodeEnum.DISPATCHED]: { past: railMessages.dispatched },
};

/*****************
 * Ocean
 *****************/
export enum OceanStatusCodeEnum {
  UNKNOWN = 'UNKNOWN',
  IN_TRANSIT = 'IN_TRANSIT',
  IN_YARD = 'IN_YARD',
  ON_SHIP = 'ON_SHIP',
  AVAILABLE = 'AVAILABLE',
  ARRIVED = 'ARRIVED',
  DEPARTED_TERMINAL = 'DEPARTED_TERMINAL',
  PRE_TRANSIT = 'PRE_TRANSIT',
  AT_STOP = 'AT_STOP',
  AT_TRANSFER = 'AT_TRANSFER',
  POST_TRANSIT = 'POST_TRANSIT',
  COMPLETED = 'COMPLETED',
  OUT_OF_NETWORK = 'OUT_OF_NETWORK',
  EQUIPMENT_ALERT = 'EQUIPMENT_ALERT',
  GATE_OUT = 'GATE_OUT',
  GATE_OUT_EMPTY = 'GATE_OUT_EMPTY',
  GATE_IN_EMPTY = 'GATE_IN_EMPTY',
  GATE_IN_FULL = 'GATE_IN_FULL',
  LOADED = 'LOADED',
  EMBARKED = 'EMBARKED',
  DISCHARGED = 'DISCHARGED',
  CUSTOMS_HOLD_INITIATED = 'CUSTOMS_HOLD_INITIATED',
  LINE_HOLD_INITIATED = 'LINE_HOLD_INITIATED',
  OTHER_HOLD_INITIATED = 'OTHER_HOLD_INITIATED',
  CUSTOMS_HOLD_RELEASED = 'CUSTOMS_HOLD_RELEASED',
  LINE_HOLD_RELEASED = 'LINE_HOLD_RELEASED',
  OTHER_HOLD_RELEASED = 'OTHER_HOLD_RELEASED',
}

const oceanMessages = {
  inYard: { id: 'shipment.statusCode.ocean.inYard', defaultMessage: 'In yard' },
  onShip: { id: 'shipment.statusCode.ocean.onShip', defaultMessage: 'On ship' },
  available: { id: 'shipment.statusCode.ocean.available', defaultMessage: 'Available' },
  arrived: { id: 'shipment.statusCode.ocean.arrived', defaultMessage: 'Arrived' },
  departedTerminal: { id: 'shipment.statusCode.ocean.departedTerminal', defaultMessage: 'Departed terminal' },
  preTransit: { id: 'shipment.statusCode.ocean.preTransit', defaultMessage: 'Pre transit' },
  atTransfer: {
    id: 'shipment.statusCode.ocean.atTransfer',
    defaultMessage: 'At transfer',
  },
  postTransit: { id: 'shipment.statusCode.ocean.postTransit', defaultMessage: 'Post transit' },
  outOfNetwork: { id: 'shipment.statusCode.ocean.outOfNetwork', defaultMessage: 'Out of network' },
  equipmentAlert: { id: 'shipment.statusCode.ocean.equipmentAlert', defaultMessage: 'Equipment alert' },
  gateOut: { id: 'shipment.statusCode.ocean.gateOut', defaultMessage: 'Gate out' },
  gateOutEmpty: { id: 'shipment.statusCode.ocean.gateOutEmpty', defaultMessage: 'Gate out empty' },
  gateInEmpty: { id: 'shipment.statusCode.ocean.gateInEmpty', defaultMessage: 'Gate in empty' },
  gateInFull: { id: 'shipment.statusCode.ocean.gateInFull', defaultMessage: 'Gate in full' },
  loaded: { id: 'shipment.statusCode.ocean.loaded', defaultMessage: 'Loaded' },
  embarked: { id: 'shipment.statusCode.ocean.embarked', defaultMessage: 'Embarked' },
  discharged: { id: 'shipment.statusCode.ocean.discharged', defaultMessage: 'Discharged' },
  customsHoldInitiated: {
    id: 'shipment.statusCode.ocean.customsHoldInitiated',
    defaultMessage: 'Customs hold initiated',
  },
  lineHoldInitiated: { id: 'shipment.statusCode.ocean.lineHoldInitiated', defaultMessage: 'Line hold initiated' },
  otherHoldInitiated: { id: 'shipment.statusCode.ocean.otherHoldInitiated', defaultMessage: 'Other hold initiated' },
  customsHoldReleased: { id: 'shipment.statusCode.ocean.customsHoldReleased', defaultMessage: 'Customs hold released' },
  lineHoldReleased: { id: 'shipment.statusCode.ocean.lineHoldReleased', defaultMessage: 'Line hold released' },
  otherHoldReleased: { id: 'shipment.statusCode.ocean.otherHoldReleased', defaultMessage: 'Other hold released' },
  embarking: { id: 'shipment.statusCode.ocean.embarking', defaultMessage: 'Embarking' },
  arrival: { id: 'shipment.statusCode.ocean.arrival', defaultMessage: 'Arrival' },
};

export const OceanStatusCodeIntlKeys: {
  [P in OceanStatusCodeEnum]: {
    past: { id: string; defaultMessage: string };
    future?: { id: string; defaultMessage: string };
  };
} = {
  ...CommonStatusCodeIntlKeys,
  [OceanStatusCodeEnum.IN_YARD]: { past: oceanMessages.inYard },
  [OceanStatusCodeEnum.ON_SHIP]: { past: oceanMessages.onShip },
  [OceanStatusCodeEnum.AVAILABLE]: { past: oceanMessages.available },
  [OceanStatusCodeEnum.ARRIVED]: { past: oceanMessages.arrived, future: oceanMessages.arrival },
  [OceanStatusCodeEnum.DEPARTED_TERMINAL]: { past: oceanMessages.departedTerminal },
  [OceanStatusCodeEnum.PRE_TRANSIT]: { past: oceanMessages.preTransit },
  [OceanStatusCodeEnum.AT_TRANSFER]: { past: oceanMessages.atTransfer },
  [OceanStatusCodeEnum.POST_TRANSIT]: { past: oceanMessages.postTransit },
  [OceanStatusCodeEnum.OUT_OF_NETWORK]: { past: oceanMessages.outOfNetwork },
  [OceanStatusCodeEnum.EQUIPMENT_ALERT]: { past: oceanMessages.equipmentAlert },
  [OceanStatusCodeEnum.GATE_OUT_EMPTY]: { past: oceanMessages.gateOutEmpty },
  [OceanStatusCodeEnum.GATE_OUT]: { past: oceanMessages.gateOut },
  [OceanStatusCodeEnum.GATE_IN_EMPTY]: { past: oceanMessages.gateInEmpty },
  [OceanStatusCodeEnum.GATE_IN_FULL]: { past: oceanMessages.gateInFull },
  [OceanStatusCodeEnum.LOADED]: { past: oceanMessages.loaded },
  [OceanStatusCodeEnum.EMBARKED]: { past: oceanMessages.embarked, future: oceanMessages.embarking },
  [OceanStatusCodeEnum.DISCHARGED]: { past: oceanMessages.discharged },
  [OceanStatusCodeEnum.CUSTOMS_HOLD_INITIATED]: { past: oceanMessages.customsHoldInitiated },
  [OceanStatusCodeEnum.LINE_HOLD_INITIATED]: { past: oceanMessages.lineHoldInitiated },
  [OceanStatusCodeEnum.OTHER_HOLD_INITIATED]: { past: oceanMessages.otherHoldInitiated },
  [OceanStatusCodeEnum.CUSTOMS_HOLD_RELEASED]: { past: oceanMessages.customsHoldReleased },
  [OceanStatusCodeEnum.LINE_HOLD_RELEASED]: { past: oceanMessages.lineHoldReleased },
  [OceanStatusCodeEnum.OTHER_HOLD_RELEASED]: { past: oceanMessages.otherHoldReleased },
};

/*****************
 * Parcel
 *****************/
export enum ParcelStatusCodeEnum {
  INFO = 'INFO',
  PRE_TRANSIT = 'PRE_TRANSIT',
  AT_STOP = 'AT_STOP',
  IN_TRANSIT = 'IN_TRANSIT',
  OUT_TO_STOP = 'OUT_TO_STOP',
  COMPLETED = 'COMPLETED',
}

const parcelMessages = {
  preTransit: { id: 'shipment.statusCode.parcel.preTransit', defaultMessage: 'Pre transit' },
  outToStop: { id: 'shipment.statusCode.parcel.outToStop', defaultMessage: 'Shipment out to stop' },
};

export const ParcelStatusCodeIntlKeys: { [P in ParcelStatusCodeEnum]: CodeInlItem } = {
  ...CommonStatusCodeIntlKeys,
  [ParcelStatusCodeEnum.INFO]: { past: commonMessages.info },
  [ParcelStatusCodeEnum.PRE_TRANSIT]: { past: parcelMessages.preTransit },
  [ParcelStatusCodeEnum.OUT_TO_STOP]: { past: parcelMessages.outToStop },
};

/*****************
 * Truckload
 *****************/
export enum TruckloadStatusCodeEnum {
  DISPATCHED = 'DISPATCHED',
  IN_TRANSIT = 'IN_TRANSIT',
  AT_STOP = 'AT_STOP',
  COMPLETED = 'COMPLETED',
  TRACKING_FAILED = 'TRACKING_FAILED',
  INFO = 'INFO',
  ETA_ERROR = 'WARNING',
  ETA_OUT_OF_DATE = 'ETA_OUT_OF_DATE',
  TRACKING_HAS_BEGUN = 'TRACKING_HAS_BEGUN',
  FAILED_TO_ACQUIRE_LOCATION = 'FAILED_TO_ACQUIRE_LOCATION',
  UNKNOWN_EQUIPMENT_IDENTIFIER = 'UNKNOWN_EQUIPMENT_IDENTIFIER',
  MISSING_EQUIPMENT_IDENTIFIER = 'MISSING_EQUIPMENT_IDENTIFIER',
}

export enum TruckloadDescriptionEnum {
  ETA_OUT_OF_DATE = 'ETA_OUT_OF_DATE',
  MISSING_LOCATION_DATA = 'MISSING_LOCATION_DATA',
}

const truckloadMessages = {
  dispatched: { id: 'shipment.statusCode.truckload.dispatched', defaultMessage: 'Dispatched' },
  trackingFailed: { id: 'shipment.statusCode.truckload.trackingFailed', defaultMessage: 'Tracking failed' },
  etaError: { id: 'shipment.statusCode.truckload.etaError', defaultMessage: 'Error' },
  etaOutOfDate: {
    id: 'shipment.statusCode.truckload.etaOutOfDate',
    defaultMessage: 'Not receiving data',
  },
  trackingHasBegun: { id: 'shipment.statusCode.truckload.trackingHasBegun', defaultMessage: 'Tracking has begun' },
  failedToAcquireLocation: {
    id: 'shipment.statusCode.truckload.failedToAcquireLocation',
    defaultMessage: 'Failed to acquire location',
  },
  unknownEquipmentIdentifer: {
    id: 'shipment.statusCode.truckload.unknownEquipmentIdentifer',
    defaultMessage: 'Provided equipment identifiers are unknown',
  },
  missingEquipmentIdentifier: {
    id: 'shipment.statusCode.truckload.missingEquipmentIdentifier',
    defaultMessage: 'Missing Equipment Identifier. Submit an equipment identifier to begin tracking',
  },
};

export const TruckloadStatusCodeIntlKeys: { [P in TruckloadStatusCodeEnum]: CodeInlItem } = {
  ...CommonStatusCodeIntlKeys,
  [TruckloadStatusCodeEnum.DISPATCHED]: { past: truckloadMessages.dispatched },
  [TruckloadStatusCodeEnum.TRACKING_FAILED]: { past: truckloadMessages.trackingFailed },
  [TruckloadStatusCodeEnum.INFO]: { past: commonMessages.info },
  [TruckloadStatusCodeEnum.ETA_ERROR]: { past: truckloadMessages.etaError },
  [TruckloadStatusCodeEnum.ETA_OUT_OF_DATE]: { past: truckloadMessages.etaOutOfDate },
  [TruckloadStatusCodeEnum.TRACKING_HAS_BEGUN]: { past: truckloadMessages.trackingHasBegun },
  [TruckloadStatusCodeEnum.FAILED_TO_ACQUIRE_LOCATION]: { past: truckloadMessages.failedToAcquireLocation },
  [TruckloadStatusCodeEnum.UNKNOWN_EQUIPMENT_IDENTIFIER]: { past: truckloadMessages.unknownEquipmentIdentifer },
  [TruckloadStatusCodeEnum.MISSING_EQUIPMENT_IDENTIFIER]: { past: truckloadMessages.missingEquipmentIdentifier },
};

/*****************
 * LTL
 *****************/
export enum LTLStatusCodeEnum {
  IN_TRANSIT = 'IN_TRANSIT',
  OUT_TO_STOP = 'OUT_TO_STOP',
  AT_STOP = 'AT_STOP',
  INFO = 'INFO',
  COMPLETED = 'COMPLETED',
}

const ltlMessages = {
  outToStop: { id: 'shipment.statusCode.ltl.outToStop', defaultMessage: 'Shipment out to stop' },
};

export const LTLStatusCodeIntlKeys: { [P in LTLStatusCodeEnum]: CodeInlItem } = {
  ...CommonStatusCodeIntlKeys,
  [LTLStatusCodeEnum.OUT_TO_STOP]: { past: ltlMessages.outToStop },
  [LTLStatusCodeEnum.INFO]: { past: commonMessages.info },
};

/*****************
 * Unified Public API
 *****************/
export enum UnifiedModelStatusCodeEnum {
  TRACKING_INITIATE = 'TRACKING_INITIATE',
  TRACKING_START = 'TRACKING_START',
  ARRIVAL_AT_STOP = 'ARRIVAL_AT_STOP',
  DEPARTURE_FROM_STOP = 'DEPARTURE_FROM_STOP',
  LOAD_ONTO_VEHICLE = 'LOAD_ONTO_VEHICLE', // might be freight prepared
  UNLOAD_FROM_VEHICLE = 'UNLOAD_FROM_VEHICLE', // discharged, received from flight
  TRACKING_END_DUE_TO_TIMEOUT = 'TRACKING_END_DUE_TO_TIMEOUT',
  TRACKING_END_BY_USER = 'TRACKING_END_BY_USER',
  TRACKING_COMPLETE = 'TRACKING_COMPLETE',
  FLIGHT_BOOKING = 'FLIGHT_BOOKING',
  MANIFEST = 'MANIFEST',
  RECEIVE_FROM_SHIPPER = 'RECEIVE_FROM_SHIPPER',
  CONSIGNEE_NOTIFY = 'CONSIGNEE_NOTIFY',
  DELIVERY = 'DELIVERY',
  WARNING = 'WARNING',
  EXCEPTION = 'EXCEPTION',
  TRACKING_STOP = 'TRACKING_STOP',
  EXCEPTION_LATE_DEPARTURE = 'EXCEPTION_LATE_DEPARTURE',
  EXCEPTION_MISSED_CONNECTION = 'EXCEPTION_MISSED_CONNECTION',
}

export const unifiedModelMessages = {
  trackingInitiate: { id: 'shipment.statusCode.unified.trackingInitiate', defaultMessage: 'Tracking Initiate' },
  trackingStart: { id: 'shipment.statusCode.unified.trackingStart', defaultMessage: 'Tracking Start' },
  arrivalAtStop: { id: 'shipment.statusCode.unified.arrivalAtStop', defaultMessage: 'Arrival At Stop' },
  departureFromStop: { id: 'shipment.statusCode.unified.departureFromStop', defaultMessage: 'Departure From Stop' },
  loadOntoVehicle: { id: 'shipment.statusCode.unified.loadOntoVehicle', defaultMessage: 'Load Onto Vehicle' },
  unloadFromVehicle: {
    id: 'shipment.statusCode.unified.unloadFromVehicle',
    defaultMessage: 'Unload From Vehicle',
  },
  trackingEndDueToTimeout: {
    id: 'shipment.statusCode.unified.trackingEndDueToTimeout',
    defaultMessage: 'Tracking End Due to Timeout',
  },
  trackingEndByUser: {
    id: 'shipment.statusCode.unified.trackingEndByUser',
    defaultMessage: 'Tracking End By User',
  },
  trackingComplete: { id: 'shipment.statusCode.unified.trackingComplete', defaultMessage: 'Tracking Complete' },
  flightBooking: { id: 'shipment.statusCode.unified.flightBooking', defaultMessage: 'Flight Booking' },
  manifest: { id: 'shipment.statusCode.unified.manifest', defaultMessage: 'Manifest' },
  receiveFromShipper: {
    id: 'shipment.statusCode.unified.receiveFromShipper',
    defaultMessage: 'Receive From Shipper',
  },
  consigneeNotify: { id: 'shipment.statusCode.unified.consigneeNotify', defaultMessage: 'Consignee Notify' },
  delivery: { id: 'shipment.statusCode.unified.delivery', defaultMessage: 'Delivery' },
  trackingStop: { id: 'shipment.statusCode.unified.trackingStop', defaultMessage: 'Tracking Stop' },
  warning: { id: 'shipment.statusCode.unified.warning', defaultMessage: 'Warning' },
  exception: { id: 'shipment.statusCode.unified.exception', defaultMessage: 'Exception' },
  exceptionLateDeparture: {
    id: 'shipment.statusCode.unified.exceptionLateDeparture',
    defaultMessage: 'Late Departure',
  },
  exceptionMissedConnection: {
    id: 'shipment.statusCode.unified.exceptionMissedConnection',
    defaultMessage: 'Potential Missed Connection',
  },
};

export const UnifiedModelStatusCodeIntlKeys: {
  [P in UnifiedModelStatusCodeEnum]: CodeInlItem;
} = {
  [UnifiedModelStatusCodeEnum.TRACKING_INITIATE]: { past: unifiedModelMessages.trackingInitiate },
  [UnifiedModelStatusCodeEnum.TRACKING_START]: { past: unifiedModelMessages.trackingStart },
  [UnifiedModelStatusCodeEnum.ARRIVAL_AT_STOP]: { past: unifiedModelMessages.arrivalAtStop },
  [UnifiedModelStatusCodeEnum.DEPARTURE_FROM_STOP]: { past: unifiedModelMessages.departureFromStop },
  [UnifiedModelStatusCodeEnum.LOAD_ONTO_VEHICLE]: { past: unifiedModelMessages.loadOntoVehicle },
  [UnifiedModelStatusCodeEnum.UNLOAD_FROM_VEHICLE]: { past: unifiedModelMessages.unloadFromVehicle },
  [UnifiedModelStatusCodeEnum.TRACKING_END_DUE_TO_TIMEOUT]: { past: unifiedModelMessages.trackingEndDueToTimeout },
  [UnifiedModelStatusCodeEnum.TRACKING_END_BY_USER]: { past: unifiedModelMessages.trackingEndByUser },
  [UnifiedModelStatusCodeEnum.TRACKING_COMPLETE]: { past: unifiedModelMessages.trackingComplete },
  [UnifiedModelStatusCodeEnum.FLIGHT_BOOKING]: { past: unifiedModelMessages.flightBooking },
  [UnifiedModelStatusCodeEnum.MANIFEST]: { past: unifiedModelMessages.manifest },
  [UnifiedModelStatusCodeEnum.RECEIVE_FROM_SHIPPER]: { past: unifiedModelMessages.receiveFromShipper },
  [UnifiedModelStatusCodeEnum.CONSIGNEE_NOTIFY]: { past: unifiedModelMessages.consigneeNotify },
  [UnifiedModelStatusCodeEnum.DELIVERY]: { past: unifiedModelMessages.delivery },
  [UnifiedModelStatusCodeEnum.WARNING]: { past: unifiedModelMessages.warning },
  [UnifiedModelStatusCodeEnum.EXCEPTION]: { past: unifiedModelMessages.exception },
  [UnifiedModelStatusCodeEnum.TRACKING_STOP]: { past: unifiedModelMessages.trackingStop },
  [UnifiedModelStatusCodeEnum.EXCEPTION_LATE_DEPARTURE]: { past: unifiedModelMessages.exceptionLateDeparture },
  [UnifiedModelStatusCodeEnum.EXCEPTION_MISSED_CONNECTION]: { past: unifiedModelMessages.exceptionMissedConnection },
};

/*****************
 * All Modes
 *****************/
export type ShipmentStatusCode =
  | CommonStatusCodeEnum
  | RailStatusCodeEnum
  | OceanStatusCodeEnum
  | ParcelStatusCodeEnum
  | TruckloadStatusCodeEnum
  | LTLStatusCodeEnum
  | UnifiedModelStatusCodeEnum;

export const ShipmentStatusCodeIntlKeys: { [P in ShipmentStatusCode]: CodeInlItem } = {
  ...RailStatusCodeIntlKeys,
  ...OceanStatusCodeIntlKeys,
  ...ParcelStatusCodeIntlKeys,
  ...TruckloadStatusCodeIntlKeys,
  ...LTLStatusCodeIntlKeys,
  ...UnifiedModelStatusCodeIntlKeys,
};
