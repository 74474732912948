import { useHistory, useLocation, generatePath } from 'react-router';

import { STEPS } from '../constants';
import { IRouteParams } from '../types';
import { getCurrentStepIndex } from '../utils';

interface IHistoryState {
  parentPath: string;
  parentParams: IRouteParams;
}

export const useHandleNextStep = () => {
  const { state, search } = useLocation<IHistoryState>();
  const history = useHistory();
  const currentStep = getCurrentStepIndex(state.parentParams?.step);

  const handleNextStep = () => {
    const nextStep = STEPS[currentStep + 1].id;
    const nextStepPath = generatePath(state.parentPath, { step: nextStep, tileId: state.parentParams?.tileId });
    history.push({ pathname: nextStepPath, search });
  };

  return handleNextStep;
};
