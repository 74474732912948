import get from 'lodash/get';

import { ShipmentSearchResult, ShipmentSearchCardData, ShipmentDerivedStatus } from 'models';
import { entityPageUrls, intl } from 'common/AppConstants';
import * as mapShipmentUtils from '../utils/mapShipmentUtils';
import * as shipmentUtils from '../../newShipmentDetails/utils/shipmentUtils';
import PercentCompleteUtil from '../../shipment/common/utils/percentCompleteUtil';

export const mapShipmentUnified = (
  shipmentSearchResult: ShipmentSearchResult,
  useNewEntityPage = false
): ShipmentSearchCardData => {
  const { masterShipmentId, shipmentId } = shipmentSearchResult;

  const url = useNewEntityPage
    ? entityPageUrls.entityPageUiUrl(masterShipmentId)
    : mapShipmentUtils.getShipmentUrlFromMasterShipmentId(masterShipmentId);

  const mode = get(shipmentSearchResult, 'mode');
  const identifiers = get(shipmentSearchResult, 'shipmentIdentifiers').filter((id) => id.type || id.name);
  const carrierName = get(shipmentSearchResult, 'carrier.name');
  const currentEtaDescription = mapShipmentUtils.getCurrentEtaDescription(shipmentSearchResult);
  const lastUpdate = mapShipmentUtils.getLastUpdatedText(shipmentSearchResult);
  const { pickupStopInfo, deliveryStopInfo } = mapShipmentUtils.getPickupAndDeliveryStopInfo(shipmentSearchResult);
  const { flagText, flagColor, shouldInvertFlagColor } = getStatusFlagInfo(shipmentSearchResult);
  const { statusText, statusDescription } = getStatusTextInfo(shipmentSearchResult);
  const multimodalDerivedStatus = get(shipmentSearchResult, 'multiModalDerivedStatus');
  const percentComplete = PercentCompleteUtil.getPercentCompleteBasedOnMultiModalDerivedStatus(multimodalDerivedStatus);
  const tenantCustomAttributes = get(shipmentSearchResult, 'tenantCustomAttributes', []);

  return {
    shipmentId: shipmentId || masterShipmentId,
    url,
    mode,
    identifiers,
    carrierName,
    currentEtaDescription,
    lastUpdate,
    pickupStopInfo,
    deliveryStopInfo,
    flagText,
    flagColor,
    shouldInvertFlagColor,
    statusDescription,
    statusText,
    entitlementInfo: shipmentSearchResult.entitlementInfo,
    percentComplete,
    tenantCustomAttributes,
    shipmentStopsCount: get(shipmentSearchResult, 'shipmentStops', []).length,
  };
};

const getStatusFlagInfo = (
  shipmentSearchResult: ShipmentSearchResult
): {
  flagText: { id: string; defaultMessage: string; values?: any };
  flagColor: string;
  shouldInvertFlagColor: boolean;
} => {
  const latestStatusUpdateCode = get(shipmentSearchResult, 'latestStatusUpdate.statusCode');
  const latestStatusUpdateReason = get(shipmentSearchResult, 'latestStatusUpdate.statusReasonCode');

  return shipmentUtils.getStatusFlagInfo(latestStatusUpdateCode, latestStatusUpdateReason);
};

const getStatusTextInfo = (
  shipmentSearchResult: ShipmentSearchResult
): { statusText: string; statusDescription: string | undefined } => {
  let statusText = '';
  let statusDescription: string | undefined;
  if (get(shipmentSearchResult, 'derivedStatusCodes[0].value')) {
    statusText =
      shipmentUtils.getDerivedStatusText(intl, get(shipmentSearchResult, 'derivedStatusCodes[0].value')) || '';
  } else if (get(shipmentSearchResult, 'derivedStatusCodes[0]')) {
    statusText =
      shipmentUtils.getDerivedStatusText(
        intl,
        get(shipmentSearchResult, 'derivedStatusCodes[0]') as ShipmentDerivedStatus
      ) || '';
  } else if (shipmentSearchResult.latestStatusUpdate !== undefined) {
    statusText = shipmentUtils.getStatusCodeText(intl, shipmentSearchResult.latestStatusUpdate.statusCode) || '';
    statusDescription = shipmentUtils.getStatusReasonCodeText(
      intl,
      shipmentSearchResult.latestStatusUpdate.statusReasonCode
    );
  }

  return { statusText: statusText, statusDescription };
};
