import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { FormattedMessage, injectIntl, defineMessages, WrappedComponentProps, IntlShape } from 'react-intl';
import { reduxForm, Field, FormSection, InjectedFormProps } from 'redux-form';

import { intl } from 'common/AppConstants';
import {
  LTLEventTypeKeyEnum,
  NotificationAccessLevelEnum,
  NotificationStopTypeEnum,
  ParcelEventTypeKeyEnum,
  ShipmentModeEnum,
  TLEventTypeKeyEnum,
  OceanEventTypeKeyEnum,
} from 'models';
import { requiredValidation } from 'common/FormValidations';
import { renderInput } from 'components/common/forms/FormInputs';
import CustomButton from 'themes/CustomButton';
import './AddEditNotificationForm.scss';
import { Button } from 'ui-components';
import EventRuleFormSection from './eventRules/EventRuleFormSection';
import ShipmentRuleFormSection from './shipmentRules/ShipmentRuleFormSection';
import RecipientFormSection from './recipientRules/RecipientFormSection';
import { createAccessLevelObject } from '../NotificationViewUtil';
import { renderModeSelect } from './AddEditFormInputs';

const messages = defineMessages({
  addEditNotificationFormName: {
    id: 'notifications.addEditNotificationForm.addEditNotificationFormName',
    defaultMessage: 'Create a name',
  },
});

interface ModeDisplayOption {
  label: string;
  value: ShipmentModeEnum;
}

interface ModeOption {
  value: ShipmentModeEnum;
  label: {
    id: string;
    defaultMessage?: string;
  };
}

interface AddEditNotificationFormValues {
  title: string;
  mode: ShipmentModeEnum;
  filters: {
    operator: string;
    fields: any[];
  };
  notificationEvents: Array<{
    eventTypeKey: TLEventTypeKeyEnum | LTLEventTypeKeyEnum | ParcelEventTypeKeyEnum | OceanEventTypeKeyEnum;
    offsetDirection: string;
    stopParameter: { type: NotificationStopTypeEnum };
    timeParameter: { value: number; units: 'Minutes' | 'Hours' };
  }>;
  notificationRecipients: Array<{
    input: string;
    type: 'PUSH' | 'EMAIL';
    values: string[];
  }>;
}

const getSelectedMode = (
  modeOptions: ModeDisplayOption[] = [],
  { formValues }: { formValues: AddEditNotificationFormValues }
): ShipmentModeEnum => {
  if (get(formValues, 'mode') && modeOptions.findIndex((opt) => opt.value === get(formValues, 'mode')) > -1) {
    return get(formValues, 'mode');
  }
  return modeOptions[0].value;
};

const getFilteredModeOptions = ({
  hasLtlMode,
  hasTruckloadMode,
  hasParcelMode,
  hasAirMode,
  hasOceanMode,
  mode,
  modeOptions,
  intl,
}: {
  hasLtlMode: boolean;
  hasTruckloadMode: boolean;
  hasParcelMode: boolean;
  hasAirMode: boolean;
  hasOceanMode: boolean;
  mode: string;
  modeOptions: ModeOption[];
  intl: IntlShape;
}): ModeDisplayOption[] => {
  if (mode) {
    return modeOptions
      .filter((option) => option.value === mode)
      .map((option) => {
        return {
          value: get(option, 'value', ShipmentModeEnum.TL),
          label: intl.formatMessage({
            id: get(option, 'label.id', ''),
            defaultMessage: get(option, 'label.defaultMessage'),
          }),
        };
      });
  }

  const filteredModeOptions: ModeOption[] = [];
  const addToFilteredModeOptions = (modeValue: ShipmentModeEnum) => {
    filteredModeOptions.push(
      modeOptions.find((option) => get(option, 'value', ShipmentModeEnum.TL) === modeValue) as ModeOption
    );
  };

  if (hasLtlMode) {
    addToFilteredModeOptions(ShipmentModeEnum.LTL);
  }
  if (hasTruckloadMode) {
    addToFilteredModeOptions(ShipmentModeEnum.TL);
  }
  if (hasParcelMode) {
    addToFilteredModeOptions(ShipmentModeEnum.PARCEL);
  }
  if (hasAirMode) {
    addToFilteredModeOptions(ShipmentModeEnum.AIR);
  }
  if (hasOceanMode) {
    addToFilteredModeOptions(ShipmentModeEnum.OCEAN);
  }

  const translatedModeOptions = filteredModeOptions.map((option: ModeOption | undefined) => {
    return {
      value: get(option, 'value', ShipmentModeEnum.TL),
      label: intl.formatMessage({
        id: get(option, 'label.id', ''),
        defaultMessage: get(option, 'label.defaultMessage'),
      }),
    };
  });

  return translatedModeOptions;
};

interface AddEditNotificationFormProps extends InjectedFormProps, WrappedComponentProps {
  accessLevel: string;
  shipmentId: string;
  masterShipmentId: string;
  mode: ShipmentModeEnum;
  initialValues: any;
  modeOptions: ModeOption[];
  formValues: AddEditNotificationFormValues;
  locationSuggestions: string[];
  carrierSuggestions: string[];
  companySuggestions: string[];
  subscriberSuggestions: any;
  errors: any;
  subscriptionOptions: any;
  hasEdit: boolean;
  hasTruckloadMode: boolean;
  hasLtlMode: boolean;
  hasParcelMode: boolean;
  hasAirMode: boolean;
  hasOceanMode: boolean;
  newShipmentCriteria: any;
  isNewNotification: boolean;
  deleteNotification: any;
  notificationId: string;
  newEventType: any;
  filterValidationError: { id: string; defaultMessage: string } | undefined;
  onCancel: () => void;
  dispatch: (action: any) => void;
  resetForm: () => void;
  updateNotificationEvent: () => void;
  queryAutocompleteOptions: () => void;
  validateFormAndSubmit: () => void;
}

let AddEditNotificationForm = (props: AddEditNotificationFormProps) => {
  const modeOptions = getFilteredModeOptions(props);
  const selectedMode = getSelectedMode(modeOptions, props);
  if (!isNil(props.formValues) && props.formValues.mode !== selectedMode) {
    props.formValues.mode = selectedMode;
  }
  const hasTenantNotificationAccess = props.accessLevel && props.accessLevel === NotificationAccessLevelEnum.TENANT;

  return (
    <form className="add-edit-notification-form row" onSubmit={props.handleSubmit}>
      {modeOptions.length > 1 && (
        <div className="col-24 d-flex flex-col mb-5">
          <p className="text-black bold">
            <FormattedMessage
              id="notifications.addEditNotificationForm.addEditNotificationFormSelect"
              defaultMessage="Choose a mode"
              tagName="span"
            />
          </p>
          <Field
            type="radio"
            label="Select Mode"
            component={renderModeSelect}
            value={selectedMode}
            name="mode"
            modeOptions={modeOptions}
            resetForm={props.resetForm}
          />
        </div>
      )}

      <div className="col-24 d-flex flex-col mb-2">
        <p className="row text-black bold">
          <span className="col-24">
            <FormattedMessage
              id="notifications.addEditNotificationForm.addEditSendNotification"
              defaultMessage="Send notifications when:"
            />
          </span>
        </p>
        <EventRuleFormSection
          updateNotificationEvent={props.updateNotificationEvent}
          accessLevel={props.accessLevel}
          initialValues={props.initialValues}
          formValues={props.formValues}
          dispatch={props.dispatch}
          newEventType={props.newEventType}
          selectedMode={selectedMode}
          subscriptionOptions={props.subscriptionOptions}
        />
      </div>

      {hasTenantNotificationAccess && (
        <div className="col-24 d-flex flex-col mb-2">
          <div className="row">
            <p className="col-24 bold text-black">
              <FormattedMessage
                id="notifications.addEditNotificationForm.addEditIncludeShipments"
                defaultMessage="Include shipments that match all criteria"
              />
            </p>
          </div>
          {props.filterValidationError && (
            <div className="add-edit-notification-error" data-locator="add-edit-notification-filter-validation-error">
              <FormattedMessage
                id={props.filterValidationError.id}
                defaultMessage={props.filterValidationError.defaultMessage}
              />
            </div>
          )}
          <FormSection className="row" name="filters">
            <ShipmentRuleFormSection
              initialValues={props.initialValues}
              formValues={props.formValues}
              dispatch={props.dispatch}
              queryAutocompleteOptions={props.queryAutocompleteOptions}
              locationSuggestions={props.locationSuggestions}
              carrierSuggestions={props.carrierSuggestions}
              companySuggestions={props.companySuggestions}
              newShipmentCriteria={props.newShipmentCriteria}
            />
          </FormSection>
        </div>
      )}

      <div className="col-24 d-flex flex-col mb-2">
        <div className="row">
          <label htmlFor="notification-title-input" className="bold text-black col-24">
            {
              <FormattedMessage
                id="notifications.addEditNotificationForm.addEditNotificationFormTitle"
                defaultMessage="Name your notification"
              />
            }
          </label>
        </div>
        <div className="row">
          <div className="col-20">
            <Field
              type="text"
              component={renderInput}
              id="notification-title-input"
              data-locator="notification-title-input"
              validate={[requiredValidation]}
              currentParameters={get(props, 'formValues.title')}
              intl={intl}
              name="title"
              placeholder={intl.formatMessage(messages.addEditNotificationFormName)}
            />
          </div>
        </div>
      </div>

      <div className="col-24 d-flex flex-col mb-2 mt-10">
        <div className="row">
          <p className="col-20 bold text-black">
            <FormattedMessage
              id="notifications.addEditNotificationForm.addEditNotificationToRecipients"
              defaultMessage="To these recipients:"
            />
          </p>
        </div>
        <RecipientFormSection
          initialValues={props.initialValues}
          formValues={props.formValues}
          dispatch={props.dispatch}
          isNewNotification={props.isNewNotification}
          subscriberSuggestions={props.subscriberSuggestions}
          queryAutocompleteOptions={props.queryAutocompleteOptions}
          subscriptionOptions={props.subscriptionOptions}
          selectedMode={selectedMode}
        />
      </div>
      <div className="col-20 d-flex justify-content-end align-items-center mb-4">
        <div className="d-flex justify-content-end align-items-center mr-3">
          <Button type="secondary" clickFn={props.onCancel}>
            <FormattedMessage
              id="notifications.addEditNotificationComponent.addEditNotificationCancelButton"
              defaultMessage="CANCEL"
            />
          </Button>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <Button
            data-locator="notification-form-save-notification-button"
            type="primary"
            // @ts-ignore
            clickFn={props.validateFormAndSubmit}
          >
            <FormattedMessage
              id="notifications.addEditNotificationComponent.addEditNotificationSaveButton"
              defaultMessage="SAVE NOTIFICATION"
            />
          </Button>
        </div>
        {!props.isNewNotification && (
          <CustomButton
            classes="dangerous medium ml-3"
            data-locator="delete-notification-button"
            clickFn={() =>
              props.deleteNotification(
                props.notificationId,
                createAccessLevelObject(props.accessLevel, props.shipmentId, props.masterShipmentId, props.mode)
              )
            }
          >
            <FormattedMessage
              id="notifications.addEditNotificationForm.addEditNotificationDelete"
              defaultMessage="Delete"
            />
          </CustomButton>
        )}
      </div>
    </form>
  );
};
// @ts-ignore - it's not happy about additional props on the form component
AddEditNotificationForm = reduxForm({
  form: 'AddEditNotificationForm',
  enableReinitialize: true,
  // @ts-ignore - it's not happy about additional props on the form component
})(AddEditNotificationForm);

export default injectIntl<'intl', AddEditNotificationFormProps>(AddEditNotificationForm);
