import { ActionType, getType } from 'typesafe-actions';
import { Reducer } from 'redux';

import { ShipmentModeEnum } from 'models';
import endpoints, { shipmentShareTokenQueryParam } from '../../../common/endpoints';
import * as shipmentShareActions from './actions';

export type ShipmentShareAction = ActionType<typeof shipmentShareActions>;

export interface ShipmentShareState {
  shareLink?: string;
  isFetchingLink: boolean;
  fetchingError?: Error;
  shipmentMode?: ShipmentModeEnum;
  shipmentId?: string;
  masterShipmentId?: string;
  isSendingEmail: boolean;
  showShareWindow: boolean;
}

const initialState: ShipmentShareState = {
  showShareWindow: false,
  shareLink: undefined,
  isFetchingLink: false,
  fetchingError: undefined,
  shipmentMode: undefined,
  shipmentId: undefined,
  masterShipmentId: undefined,
  isSendingEmail: false,
};

const shipmentShareReducer: Reducer<ShipmentShareState, ShipmentShareAction> = (state = initialState, action) => {
  switch (action.type) {
    case getType(shipmentShareActions.fetchShareLink.request): {
      return {
        ...state,
        shareLink: undefined,
        isFetchingLink: true,
        shipmentId: action.payload.shipmentId,
        shipmentMode: action.payload.shipmentMode,
        masterShipmentId: action.payload.masterShipmentId,
        fetchingError: undefined,
      };
    }
    case getType(shipmentShareActions.fetchShareLink.success): {
      let shareLink;
      if (state.shipmentMode !== undefined && state.shipmentId !== undefined) {
        shareLink = endpoints.PUBLIC_TRACKING_DETAILS.replace(
          ':shipmentMode',
          state.shipmentMode.toLowerCase()
        ).replace(':shipmentShareToken', action.payload.shareToken);
        if (
          action.payload !== undefined &&
          action.payload.authorizations !== undefined &&
          action.payload.authorizations.customerProperties !== undefined &&
          action.payload.authorizations.customerProperties.USE_AUTHENTICATED_URL_IN_SHARE !== undefined &&
          action.payload.authorizations.customerProperties.USE_AUTHENTICATED_URL_IN_SHARE
        ) {
          shareLink = endpoints.TRACKING_DETAILS.replace(':shipmentMode', state.shipmentMode.toLowerCase()).replace(
            ':shipmentDetailId',
            action.payload.shipmentId
          );
        }
      } else if (state.masterShipmentId !== undefined) {
        shareLink = `${endpoints.PUBLIC_MA_TRACKING_DETAILS}?${shipmentShareTokenQueryParam}=${action.payload.shareToken}`;
        if (
          action.payload !== undefined &&
          action.payload.authorizations !== undefined &&
          action.payload.authorizations.customerProperties !== undefined &&
          action.payload.authorizations.customerProperties.USE_AUTHENTICATED_URL_IN_SHARE !== undefined &&
          action.payload.authorizations.customerProperties.USE_AUTHENTICATED_URL_IN_SHARE
        ) {
          shareLink = endpoints.TRACKING_DETAILS_MASTER_SHIPMENT_ID.replace(
            ':masterShipmentId',
            state.masterShipmentId
          );
        }
      }

      return {
        ...state,
        shareLink,
        isFetchingLink: false,
      };
    }
    case getType(shipmentShareActions.fetchShareLink.failure): {
      return {
        ...state,
        isFetchingLink: false,
        fetchingError: action.payload,
      };
    }
    case getType(shipmentShareActions.sendShareEmail.request): {
      return {
        ...state,
        isSendingEmail: true,
      };
    }
    case getType(shipmentShareActions.sendShareEmail.success): {
      return {
        ...state,
        isSendingEmail: false,
        showShareWindow: false,
      };
    }
    case getType(shipmentShareActions.sendShareEmail.failure): {
      return {
        ...state,
        isSendingEmail: false,
      };
    }
    case getType(shipmentShareActions.toggleShareWindow): {
      return {
        ...state,
        showShareWindow: !state.showShareWindow,
      };
    }
    case getType(shipmentShareActions.reset): {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default shipmentShareReducer;
