import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import types from 'components/shipmentCounts/ducks/types';
import DynamicDateFilter from '../types/DynamicDateFilter';
import ModeFilter from '../types/ModeFilter';
import StatusFilter from '../types/StatusFilter';
import HappeningTodayFilter from '../types/HappeningTodayFilter';
import DeliveryFilter from '../types/DeliveryFilter';
import LocationFilter from '../types/LocationFilter';
import CarrierFilter from '../types/CarrierFilter';
import TenantAttributeFilter from '../types/TenantAttributeFilter';
import { parcel as parcelStatusFilterList } from './statusFilters';

const ParcelModeFiltersComponent: React.FC<any> = (props) => {
  return (
    <React.Fragment>
      <Row className="mb-4 d-lg-none">
        <ModeFilter {...props} />
      </Row>
      <Row className="mb-4">
        <StatusFilter {...props} menuItems={parcelStatusFilterList} />
      </Row>
      <Row className="mb-4">
        <HappeningTodayFilter
          {...props}
          toolTipContent={
            <FormattedMessage
              id="shipmentsInTransit.tooltip.parcel"
              defaultMessage="The shipment is either at a planned stop or moving towards a planned destination along the route"
            />
          }
        />
      </Row>
      <Row className="mb-4">
        {props.quickView ? (
          <Col>
            <DynamicDateFilter
              dateType="deliveryDate"
              type={types.QUICK_VIEW_CHANGE_DELIVERY_DATE as keyof typeof types}
            />
          </Col>
        ) : (
          <DeliveryFilter {...props} />
        )}
      </Row>
      <Row className="mb-4">
        <LocationFilter {...props} />
      </Row>
      <Row className="mb-4">
        <CarrierFilter {...props} />
      </Row>
      <Row className="mb-4">
        <TenantAttributeFilter {...props} />
      </Row>
    </React.Fragment>
  );
};

export default ParcelModeFiltersComponent;
