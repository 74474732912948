const LOCATION_DETAIL_GET = 'locationManagement/duck/LOCATION_DETAIL_GET';
const LOCATION_DETAIL_GET_SUCCESS = 'locationManagement/duck/LOCATION_DETAIL_GET_SUCCESS';
const LOCATION_DETAIL_GET_FAILURE = 'locationManagement/duck/LOCATION_DETAIL_GET_FAILURE';
const LOCATION_DETAIL_NEW = 'locationManagement/duck/LOCATION_DETAIL_NEW';
const LOCATION_DETAIL_RESET = 'locationManagement/duck/LOCATION_DETAIL_RESET';
const LOCATION_LIST_GET = 'locationManagement/duck/LOCATION_LIST_GET';
const LOCATION_LIST_GET_SUCCESS = 'locationManagement/duck/LOCATION_LIST_GET_SUCCESS';
const LOCATION_LIST_GET_FAILURE = 'locationManagement/duck/LOCATION_LIST_GET_FAILURE';
const LOCATION_LIST_SORT = 'locationManagement/duck/LOCATION_SORT';
const LOCATION_CREATE = 'locationManagement/duck/LOCATION_CREATE';
const LOCATION_CREATE_SUCCESS = 'locationManagement/duck/LOCATION_CREATE_SUCCESS';
const LOCATION_CREATE_FAILURE = 'locationManagement/duck/LOCATION_CREATE_FAILURE';
const LOCATION_UPDATE = 'locationManagement/duck/LOCATION_UPDATE';
const LOCATION_UPDATE_SUCCESS = 'locationManagement/duck/LOCATION_UPDATE_SUCCESS';
const LOCATION_UPDATE_FAILURE = 'locationManagement/duck/LOCATION_UPDATE_FAILURE';
const LOCATION_SUGGESTIONS_CLEAR = 'locationManagement/duck/LOCATION_SUGGESTIONS_CLEAR';
const LOCATION_SUGGESTIONS_GET = 'locationManagement/duck/LOCATION_SUGGESTIONS_GET';
const LOCATION_SUGGESTIONS_GET_SUCCESS = 'locationManagement/duck/LOCATION_SUGGESTIONS_GET_SUCCESS';
const LOCATION_SUGGESTIONS_GET_FAILURE = 'locationManagement/duck/LOCATION_SUGGESTIONS_GET_FAILURE';
const LOCATION_LIST_CLOSE_SNACKBAR = 'locationManagement/duck/LOCATION_LIST_CLOSE_SNACKBAR';
const SET_SEARCH_TERM = 'locationManagement/duck/SET_SEARCH_TERM';
const SET_CONTACT_SEARCH_TERM = 'locationManagement/duck/SET_SEARCH_TERM';

export default {
  LOCATION_CREATE,
  LOCATION_CREATE_FAILURE,
  LOCATION_CREATE_SUCCESS,
  LOCATION_DETAIL_GET,
  LOCATION_DETAIL_GET_FAILURE,
  LOCATION_DETAIL_GET_SUCCESS,
  LOCATION_DETAIL_NEW,
  LOCATION_DETAIL_RESET,
  LOCATION_LIST_CLOSE_SNACKBAR,
  LOCATION_LIST_GET,
  LOCATION_LIST_GET_FAILURE,
  LOCATION_LIST_GET_SUCCESS,
  LOCATION_LIST_SORT,
  LOCATION_SUGGESTIONS_CLEAR,
  LOCATION_SUGGESTIONS_GET,
  LOCATION_SUGGESTIONS_GET_FAILURE,
  LOCATION_SUGGESTIONS_GET_SUCCESS,
  LOCATION_UPDATE,
  LOCATION_UPDATE_FAILURE,
  LOCATION_UPDATE_SUCCESS,
  SET_SEARCH_TERM,
  SET_CONTACT_SEARCH_TERM,
};
