import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ColorPicker } from 'ui-components';

import * as styles from './Colors.module.scss';

export interface ColorsProps {
  primaryColor: string;
  onPrimaryColorChange: (color: string) => void;
}
const Colors: React.FunctionComponent<ColorsProps> = (props) => {
  return (
    <React.Fragment>
      <div className={styles.title}>
        <FormattedMessage id="settings.brandingTheming.colors.colorsTitle" defaultMessage="Colors" />
      </div>
      <div className={styles.pickerWrapper}>
        <ColorPicker color={props.primaryColor} onChange={props.onPrimaryColorChange} placement={'bottomRight'} />
        <div className={styles.pickerText}>
          <FormattedMessage
            id="settings.brandingTheming.colors.colorsDescription"
            defaultMessage="Primary (background)"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Colors;
