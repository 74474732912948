import get from 'lodash/get';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import { shipmentsToRecordsMapper, pingsToRecordsMapper, currentStopToRecordsMapper } from './recordsMapper';

/**
 * Gets the current location from the given route and latest stop status
 *
 * @param fullRoute an array of routes containing stop data
 * @param currentStop
 * @returns object the current stop
 */
export const getCurrentLocation = (fullRoute, currentStop) => {
  if (fullRoute && currentStop) {
    return find(fullRoute, (stop) => stop.stopNumber === currentStop.stopNumber);
  }
};

/**
 * Gets the remaining stops
 *
 * @param fullRoute an array of stops
 * @param currentStop
 * @returns array the remaining stops
 */
export const remainingRoutes = (fullRoute, currentStop) => {
  let remainingRoutes;

  if (fullRoute && currentStop) {
    // If currentStop.stopNumber is null that's considered `0` - Mark Serrano
    remainingRoutes = filter(fullRoute, (stop) => stop.stopNumber >= currentStop.stopNumber);
  }
  return remainingRoutes;
};

/**
 * Helper function to determine if we should render the map
 *
 * @param hasData the data
 * @param mapInstance a reference to the here maps instance
 * @param propToMatch the property to check if it exists
 * @param valueToMatch the value to check if it exists
 * @returns {*|boolean} true if the conditions are met
 */
export const shouldRenderMap = (hereMapsConfig, hasData, mapInstance, propToMatch, valueToMatch) => {
  return (
    hasData &&
    hereMapsConfig &&
    Object.prototype.hasOwnProperty.call(mapInstance, propToMatch) &&
    mapInstance[propToMatch] !== valueToMatch
  );
};

/**
 * Initializes the map with ping data.
 * This doesn't render the pings.
 * @param receivedProps
 */
export const initializeMapWithPingData = (receivedProps) => {
  /**
   * shipmentDetails is an instance of the ShipmentDetail model.
   * This is not the raw shipment detail backend response - Mark Serrano
   */
  const shipmentDetails = get(receivedProps, 'shipmentDetails');

  /**
   * pings are an instance of ShipmentPing model.
   * These are not raw JSON response from the backend - Mark Serrano
   */
  const pingsAsRecords = pingsToRecordsMapper(shipmentDetails);

  if (pingsAsRecords) {
    receivedProps.mapInstance.options.pings.data = pingsAsRecords;
  }
};

/**
 * Initializes the map with route data.
 * This doesn't render the stops.
 * @param receivedProps
 */
export const initializeMapWithRouteData = (receivedProps) => {
  /**
   * shipmentDetails is an instance of the ShipmentDetail model.
   * This is not the raw shipment detail backend response - Mark Serrano
   */
  const shipmentDetails = get(receivedProps, 'shipmentDetails');
  const allStopsData = shipmentsToRecordsMapper(shipmentDetails);
  const allPingsData = pingsToRecordsMapper(shipmentDetails);

  initializeCurrentStop(receivedProps, shipmentDetails, allStopsData, allPingsData);
  initializeFullRoute(receivedProps, allStopsData);
};

const initializeCurrentStop = (receivedProps, shipmentDetails, allStopsData, allPingsData) => {
  let isDelivered = shipmentDetails.delivery && shipmentDetails.delivery.isComplete;
  let isPickedUp = shipmentDetails.pickup && shipmentDetails.pickup.isComplete;

  if (shipmentDetails.currentStop && !shipmentDetails.currentStop.locationCoordinatesDto) {
    receivedProps.mapInstance.options.currentStopMarker.show = false;
  } else if (isDelivered || !isPickedUp) {
    // NOTE: If the shipment is delivered or not picked up yet, hide the location marker
    receivedProps.mapInstance.options.currentStopMarker.show = false;
  }

  if (shipmentDetails.currentStop) {
    const currentStopRecord = currentStopToRecordsMapper(shipmentDetails.currentStop, shipmentDetails);

    if (currentStopRecord) {
      receivedProps.mapInstance.options.currentStopMarker.data = currentStopRecord;
    }

    initializeRemainingRoute(receivedProps, currentStopRecord, shipmentDetails.currentStop, allStopsData);
    initializeTravelledRoute(receivedProps, currentStopRecord, shipmentDetails.currentStop, allPingsData);
  }
};

const initializeTravelledRoute = (receivedProps, currentStopRecord, currentStop, allPingsData) => {
  if (currentStop && currentStop.stopNumber && allPingsData) {
    const records = flatten(allPingsData, currentStopRecord);
    receivedProps.mapInstance.options.travelledRoute.data = filter(records, (record) => {
      return !isNil(record.lat) && !isNil(record.lng);
    });
  }
};

const initializeRemainingRoute = (receivedProps, currentStopRecord, currentStop, allStopsData) => {
  if (currentStopRecord) {
    const untravelledRoute = remainingRoutes(allStopsData, currentStop)
      ? remainingRoutes(allStopsData, currentStop).slice()
      : null;
    if (untravelledRoute) {
      const records = flatten([currentStopRecord, remainingRoutes(allStopsData, currentStop)]);

      receivedProps.mapInstance.options.remainingRoute.data = filter(records, (record) => {
        return !isNil(record.lat) && !isNil(record.lng);
      });
    }
  }
};

const initializeFullRoute = (receivedProps, routes) => {
  if (routes) {
    receivedProps.mapInstance.options.allStopMarkers.data = routes;
  }
};
