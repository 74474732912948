import * as React from 'react';
import FlagIconFactory from 'react-flag-icon-css';
import { countryPhoneData } from 'phone';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

interface FlagProps {
  lang: string;
  code: string;
}

interface FlagWithLabelProps extends FlagProps {
  label: string;
}

const FlagWithLabel: React.FC<FlagWithLabelProps> = ({ lang, label, code }) => (
  <div className="d-flex justify-content-between">
    <div>
      <FlagIcon code={lang} />
      &nbsp;{label}
    </div>
    <span>+{code}</span>
  </div>
);

const Flag: React.FC<FlagProps> = ({ lang, code }) => (
  <div className="d-flex justify-content-between w-100 pr-1">
    <FlagIcon code={lang} />
    <span>+{code}</span>
  </div>
);

export interface Countries {
  value: string;
  displayName: React.ReactElement<any, any> | null;
  icon: React.ReactElement<any, any> | null;
  countryCode: string;
  countryName: string;
  alpha2: string;
}

export default () => {
  const finalCountries: Countries[] = countryPhoneData.map(
    ({ country_name: countryName, country_code: countryCode, alpha2, alpha3 }) => {
      const lang = alpha2.toLowerCase();
      return {
        value: alpha3,
        displayName: FlagWithLabel({ lang, label: countryName, code: countryCode }),
        icon: Flag({ lang, code: countryCode }),
        countryCode: countryCode,
        countryName,
        alpha2,
      };
    }
  );

  return finalCountries;
};
