import { useState, memo } from 'react';
import { FormikProps } from 'formik';
import get from 'lodash/get';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { IntlShape } from 'react-intl';

import LocationMap from 'components/LocationDetails/LocationMap/LocationMap';

import LocationShipments from './LocationShipments/LocationShipments';

import LocationKPIs from './LocationKPIs';
import OpenCloseTimes from './OpenCloseTimes';
import ContactInfoForm from './ContactInfoForm';
import ViewModeDetails from './ViewModeDetails';
import EditModeDetails from './EditModeDetails';

import { LocationDetails, FormValues, FenceType, ShipmentDirection, DistanceUnitsEnum } from './models';
import Header from './Header';

interface FormProps {
  isNewLocation: boolean;
  canEditLocation: boolean;
  analyticsData: any;
  isEditMode: boolean;
  isResetting: boolean;
  formProps: FormikProps<FormValues>;
  locationDetails: LocationDetails | undefined;
  toggleEditMode: (val: boolean) => void;
  toggleIsResetting: (val: boolean) => void;
  geofenceRangeInMinutes: number;
  userEnteredAddress: any;
  mapData: LocationDetails | {} | undefined;
  formattedAddress: string | undefined;
  updateUserEnteredAddress: (address: any) => void;
  latLngInputValue: string | undefined;
  updatelatLngInputValue: (latLng: string | undefined) => void;
  useAddressLatLngInMap: boolean;
  updateUseAddressLatLngInMap: (useAddressLatLng: boolean) => void;
  address2Units: string | undefined;
  updateAddress2Units: any;
  handleCustomGeofenceCoords: (coords: any) => void;
  intl: IntlShape;
  defaultFenceType: FenceType;
  defaultDistanceValue: number;
  defaultDistanceUnits: DistanceUnitsEnum;
  noNav: boolean;
}

function Form({
  isNewLocation,
  canEditLocation,
  analyticsData,
  formProps,
  locationDetails,
  isEditMode,
  isResetting,
  toggleEditMode,
  toggleIsResetting,
  geofenceRangeInMinutes,
  mapData,
  userEnteredAddress,
  formattedAddress,
  updateUserEnteredAddress,
  latLngInputValue,
  updatelatLngInputValue,
  useAddressLatLngInMap,
  updateUseAddressLatLngInMap,
  address2Units,
  updateAddress2Units,
  handleCustomGeofenceCoords,
  intl,
  defaultFenceType,
  defaultDistanceValue,
  defaultDistanceUnits,
  noNav,
}: FormProps) {
  const [isEditGeofenceButtonVisible, setIsEditGeofenceButtonVisible] = useState<boolean>(false);
  const { name, specifiedId, type, companyName } = formProps.values;
  const { handleSubmit, isValid } = formProps;
  return (
    <>
      <Header
        updateUserEnteredAddress={updateUserEnteredAddress}
        updatelatLngInputValue={updatelatLngInputValue}
        updateUseAddressLatLngInMap={updateUseAddressLatLngInMap}
        toggleIsResetting={toggleIsResetting}
        intl={intl}
        toggleEditMode={toggleEditMode}
        name={name}
        type={type}
        companyName={companyName}
        specifiedId={specifiedId}
        handleSubmit={handleSubmit}
        isValid={isValid}
        isEditMode={isEditMode}
        canEditLocation={canEditLocation}
      />
      <Container fluid>
        <Container>
          <Row>
            <Col lg={24}>
              <Row noGutters style={{ marginTop: '30px', marginBottom: '20px' }}>
                <Col xs={24} lg={12}>
                  {!isEditMode && <ViewModeDetails locationDetails={locationDetails} />}
                  {isEditMode && (
                    <>
                      <EditModeDetails
                        isNewLocation={isNewLocation}
                        formProps={formProps}
                        setFieldValue={formProps.setFieldValue}
                        hours={get(formProps, 'values.hours')}
                        address={formattedAddress}
                        updateUserEnteredAddress={updateUserEnteredAddress}
                        latLngInputValue={latLngInputValue}
                        updatelatLngInputValue={updatelatLngInputValue}
                        updateUseAddressLatLngInMap={updateUseAddressLatLngInMap}
                        addressObject={get(locationDetails, 'address')}
                        address2Units={address2Units}
                        updateAddress2Units={updateAddress2Units}
                      />
                      <OpenCloseTimes
                        intl={intl}
                        setFieldValue={formProps.setFieldValue}
                        formProps={formProps}
                        handleChange={formProps.handleChange}
                        hours={get(formProps, 'values.hours') as LocationDetails['hours']}
                      />
                    </>
                  )}
                </Col>

                <Col lg={12}>
                  <LocationMap
                    defaultFenceType={defaultFenceType}
                    defaultDistanceValue={defaultDistanceValue}
                    defaultDistanceUnits={defaultDistanceUnits}
                    isResetting={isResetting}
                    toggleIsResetting={toggleIsResetting}
                    isEditMode={isEditMode}
                    geofenceRangeInMinutes={geofenceRangeInMinutes}
                    userEnteredAddress={userEnteredAddress}
                    latLngInputValue={latLngInputValue}
                    updatelatLngInputValue={updatelatLngInputValue}
                    useAddressLatLngInMap={useAddressLatLngInMap}
                    setFieldValue={formProps.setFieldValue}
                    handleChange={formProps.handleChange}
                    formProps={formProps}
                    data={mapData}
                    handleCustomGeofenceCoords={handleCustomGeofenceCoords}
                    setIsEditGeofenceButtonVisible={setIsEditGeofenceButtonVisible}
                    isEditGeofenceButtonVisible={isEditGeofenceButtonVisible}
                    analyticsData={analyticsData}
                  />
                </Col>
              </Row>
              {isEditMode && (
                <Row noGutters style={{ marginTop: '30px' }}>
                  <Col>
                    <ContactInfoForm formProps={formProps} />
                  </Col>
                </Row>
              )}
              <hr />
            </Col>
          </Row>
          <LocationKPIs
            intl={intl}
            isEditMode={isEditMode}
            analyticsData={analyticsData}
            locationDetails={locationDetails}
            setIsEditGeofenceButtonVisible={setIsEditGeofenceButtonVisible}
            noNav={noNav}
          />
          {get(locationDetails, 'id') && (
            <>
              <Row style={{ marginTop: '20px', marginBottom: '80px' }}>
                <Col>
                  <LocationShipments
                    locationId={get(locationDetails, 'id', '')}
                    direction={ShipmentDirection.INBOUND}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: '20px', marginBottom: '80px' }}>
                <Col>
                  <LocationShipments
                    locationId={get(locationDetails, 'id', '')}
                    direction={ShipmentDirection.OUTBOUND}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </Container>
    </>
  );
}

export default memo(Form);
