import qs from 'querystring';
import { useContext, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import get from 'lodash/get';
import head from 'lodash/head';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { Autosuggest } from 'ui-components';

import { PrincipalContext } from 'contexts/PrincipalContext';
import { FILTER_RESULT_DELAY } from 'common/AppConstants';
import { getPrincipalAuthorizations } from 'common/authorizations';
import useDebounce from 'hooks/useDebounce';
import useSuggestions from '../util/useSuggestions';
import { SUGGESTIONS_MAP } from '../ducks/types';
import AutocompleteFilter from '../../common/autocompleteFilter/AutocompleteFilter';

const INITIAL_AMOUNT_VISIBLE_STATUS_CARRIER = 3;

const messages = defineMessages({
  filterByAnyCarrier: {
    id: 'quickView.carrierFilter.filterByAnyCarrier',
    defaultMessage: 'Filter by Any Carrier',
  },
});

const CarrierFilter = (props) => {
  const [currentValue, setCurrentValue] = useState('');
  const debouncedValue = useDebounce(currentValue, FILTER_RESULT_DELAY);
  const modes = get(props, 'modeFilters', []);
  const isAllMode = isEqual(head(modes), 'ALL');
  const listData = get(props, 'filter.carrier', []);

  const params = qs.stringify(isAllMode || isEmpty(modes) ? { q: debouncedValue } : { modes, q: debouncedValue });
  const suggestedLocations = useSuggestions(SUGGESTIONS_MAP.carrier, params);

  const principalContext = useContext(PrincipalContext);
  const authorizations = getPrincipalAuthorizations(principalContext);
  const isShipmentListFiltersHorizontalEnabled = authorizations.shipmentListFiltersHorizontalEnabled();

  if (isShipmentListFiltersHorizontalEnabled) {
    return (
      <Autosuggest
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        placeholder="Try a carrier name or ID"
        suggestions={suggestedLocations}
        listData={listData}
        handleAutocompleteUpdate={props.handleAutocompleteUpdate}
        name="carrier"
      />
    );
  }

  return (
    <Col>
      <label htmlFor="carrier-input-filter">
        {<FormattedMessage id="quickView.carrierFilter.carrier" defaultMessage="CARRIER" />}
      </label>
      <AutocompleteFilter
        idProp="carrier-input-filter"
        placeholder={props.intl.formatMessage(messages.filterByAnyCarrier)}
        currentValue={currentValue}
        dataSource={suggestedLocations}
        updateFn={(inputValue) => setCurrentValue(inputValue)}
        newRequestFn={(filterItem) => props.handleAutocompleteUpdate(filterItem, 'carrier')}
        listData={listData}
        showAllChips={get(props, 'showSelections.showAllCarrier')}
        passedInDeleteFn={(filterItem) => props.handleAutocompleteUpdate(filterItem, 'carrier')}
        initialAmountVisible={INITIAL_AMOUNT_VISIBLE_STATUS_CARRIER}
        showHideAllFn={() => props.toggleShowSelections('Carrier')}
        dataLocator="carrier-filter"
      />
    </Col>
  );
};

export default injectIntl(CarrierFilter);
