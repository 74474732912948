export const isTempWithinRange = ({
  currentTemp,
  tempMax,
  tempMin,
}: {
  currentTemp: number;
  tempMax: number;
  tempMin: number;
}): boolean => {
  return (currentTemp < tempMax && currentTemp > tempMin) || currentTemp === tempMax || currentTemp === tempMin;
};

export const getTempTooltipText = (tempIsWithinRange: boolean): { id: string; defaultMessage: string } => {
  return tempIsWithinRange
    ? {
        id: 'shipmentDetails.tempMonitoring.vehicleTempWithinRange',
        defaultMessage: 'Vehicle temperature is within specified range.',
      }
    : {
        id: 'shipmentDetails.tempMonitoring.vehicleTempOutsideOfRange',
        defaultMessage: 'Vehicle temperature is outside of specified range.',
      };
};
