import styled from 'styled-components';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import filterFunc from 'lodash/filter';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import replace from 'lodash/replace';
import lowerCase from 'lodash/lowerCase';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import compact from 'lodash/compact';
import { primaryGreyTwenty, primaryBlue } from 'styles/colors';

import filterMessages from '../../../filter/modes/filterMessages.json';
import CheckList from '../../../common/checkList/CheckList';
import LocationTypeComponent from './LocationTypeFilter';

import { messages } from '../utils';

const StyledRow = styled('div')`
  &:not(.no-gutters) {
    margin-left: -24px;
    margin-right: -24px;
  }

  @media (max-width: 850px) {
    flex-direction: column;
    border-left: 0 none;

    & > .col {
      margin-bottom: 20px;
    }
  }

  &.row-subtitle {
    .col-subtitle:nth-child(2) {
      border-left: 1px solid transparent;
    }
  }
`;

const StyledCol = styled('div')`
  border-left: 1px solid ${primaryGreyTwenty};
  padding-left: 24px;
  padding-right: 24px;

  &:first-child {
    border-left: 0 none;
  }

  .btn-inprogress-mobile {
    display: none;
  }

  @media (max-width: 850px) {
    flex-direction: column;
    border-left: 0 none;
    border-bottom: 1px solid ${primaryGreyTwenty};
    margin-bottom: 20px;

    &:last-child {
      border-bottom: 1px solid transparent;
      margin-bottom: 0;
    }

    .btn-inprogress-desktop {
      display: none;
    }

    .btn-inprogress-mobile {
      display: block;
      margin-bottom: -17px;
      text-align: center;

      .ant-btn {
        background: white;
        width: auto !important;
      }
    }

    .hide-empty-title-on-mobile {
      display: block;
      margin-top: -12px;
      height: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  border: 1px solid ${primaryBlue};
  color: ${primaryBlue};
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  padding: 4px 16px;
  text-transform: uppercase;
  width: 100%;
`;

interface CheckListItemProps {
  id: string;
  dataKey: string;
  labelText: JSX.Element | string;
  propertyName: string;
  checked: boolean;
}

interface ListsProps {
  id: number;
  title?: any;
  subtitle?: any;
  hasShipmentInProgressButton: boolean;
  hasSelectAndClearAllOption: boolean;
  list: CheckListItemProps[] | [];
}

interface StatusFiltersProps extends ListsProps {
  lists?: ListsProps[] | undefined;
}

interface ShipmentStatusProps {
  filter: {
    status: string[];
  };
  handleCheckboxGroupUpdate: (obj: CheckListItemProps, filter: string) => void;
  checkboxTheme: any;
}

const ShipmentStatus = ({ filter, handleCheckboxGroupUpdate, checkboxTheme }: ShipmentStatusProps) => {
  const statusFilters = [
    {
      id: 1,
      title: (
        <span className="uppercase">
          <FormattedMessage {...messages.trackingPending} />
        </span>
      ),
      hasShipmentInProgressButton: false,
      hasSelectAndClearAllOption: false,
      list: [
        {
          id: filterMessages.scheduled.id,
          dataKey: filterMessages.scheduled.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.scheduled} />,
          propertyName: 'scheduled',
          checked: false,
        },
      ],
      lists: [],
    },
    {
      id: 2,
      hasShipmentInProgressButton: true,
      hasSelectAndClearAllOption: false,
      list: [],
      lists: [
        {
          id: 2.1,
          title: (
            <span className="uppercase">
              <FormattedMessage {...messages.atStop} />
            </span>
          ),
          subtitle: (
            <span className="uppercase">
              <FormattedMessage {...messages.originYard} />
            </span>
          ),
          hasShipmentInProgressButton: false,
          hasSelectAndClearAllOption: true,
          list: [
            {
              id: filterMessages.inGate.id,
              dataKey: filterMessages.inGate.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.inGate} />,
              propertyName: 'inGate',
              checked: false,
            },
            {
              id: filterMessages.ramped.id,
              dataKey: filterMessages.ramped.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.ramped} />,
              propertyName: 'ramped',
              checked: false,
            },
          ],
        },
        {
          id: 2.2,
          title: <span className="hide-empty-title-on-mobile">&nbsp;</span>,
          subtitle: (
            <span className="uppercase">
              <FormattedMessage {...messages.destinationYard} />
            </span>
          ),
          hasShipmentInProgressButton: false,
          hasSelectAndClearAllOption: true,
          list: [
            {
              id: filterMessages.arrived.id,
              dataKey: filterMessages.arrived.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.arrived} />,
              propertyName: 'arrived',
              checked: false,
            },
            {
              id: filterMessages.deramped.id,
              dataKey: filterMessages.deramped.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.deramped} />,
              propertyName: 'deramped',
              checked: false,
            },
            {
              id: filterMessages.notified.id,
              dataKey: filterMessages.notified.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.notified} />,
              propertyName: 'notified',
              checked: false,
            },
            {
              id: filterMessages.outGate.id,
              dataKey: filterMessages.outGate.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.outGate} />,
              propertyName: 'outGate',
              checked: false,
            },
          ],
        },
        {
          id: 2.3,
          title: (
            <span className="uppercase">
              <FormattedMessage {...messages.inTransit} />
            </span>
          ),
          hasShipmentInProgressButton: false,
          hasSelectAndClearAllOption: false,
          list: [
            {
              id: filterMessages.inTransit.id,
              dataKey: filterMessages.inTransit.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.enRoute} />,
              propertyName: 'inTransit',
              checked: false,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      title: (
        <span className="uppercase">
          <FormattedMessage {...messages.trackingEnded} />
        </span>
      ),
      hasShipmentInProgressButton: false,
      hasSelectAndClearAllOption: true,
      list: [
        {
          id: filterMessages.departedStop.id,
          dataKey: filterMessages.departedStop.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.departedStop} />,
          propertyName: 'departedStop',
          checked: false,
        },
        {
          id: filterMessages.timedOut.id,
          dataKey: filterMessages.timedOut.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.timedOut} />,
          propertyName: 'timedOut',
          checked: false,
        },
        {
          id: filterMessages.canceled.id,
          dataKey: filterMessages.canceled.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.canceled} />,
          propertyName: 'canceled',
          checked: false,
        },
      ],
      lists: [],
    },
  ];

  const onCheck = (checkboxItem: CheckListItemProps) =>
    handleCheckboxGroupUpdate({ ...checkboxItem, labelText: checkboxItem.dataKey }, 'status');

  const onSelectShipmentsInProgress = () => {
    const shipmentsInProgressOptions = filterFunc(
      statusFilters,
      (item: StatusFiltersProps) => !!item.hasShipmentInProgressButton
    );
    const lists = get(shipmentsInProgressOptions, ['0', 'lists'], []);

    const list = reduce(
      lists,
      (result: CheckListItemProps[], value) => {
        return [...result, ...value.list];
      },
      []
    );

    return forEach(list, (item: CheckListItemProps) => {
      if (!filter.status.includes(item.dataKey)) {
        return onCheck(item);
      }
    });
  };

  const formatDataLocator = (text: string | undefined) => {
    const result = replace(lowerCase(text), /\s/g, '-');
    return `${result}-status-filter`;
  };

  return (
    <>
      <StyledRow className="row">
        {statusFilters.map(
          ({ id, title, hasShipmentInProgressButton, hasSelectAndClearAllOption, list, lists }: StatusFiltersProps) => {
            if (isEmpty(lists)) {
              const checkListData = list.map((item: CheckListItemProps) => ({
                ...item,
                checked: filter.status.includes(item.dataKey),
              }));

              return (
                <StyledCol className="col-auto" key={id}>
                  <StyledRow className="row no-gutters">
                    <CheckList
                      mode={checkboxTheme}
                      listTitle={title}
                      checkListData={checkListData}
                      checkFn={onCheck}
                      data-locator={formatDataLocator(title)}
                      hasSelectAndClearAllOption={hasSelectAndClearAllOption}
                    />
                  </StyledRow>
                </StyledCol>
              );
            }

            return (
              <StyledCol className="col-auto" key={id}>
                <StyledRow className="row row-subtitle">
                  {lists?.map((subList: StatusFiltersProps) => {
                    const checkListData = subList.list.map((item: CheckListItemProps) => ({
                      ...item,
                      checked: filter.status.includes(item.dataKey),
                    }));
                    const showMobileBtn = hasShipmentInProgressButton && subList.id === 2.1;

                    return (
                      <StyledCol className={`col-auto col-subtitle ${showMobileBtn ? 'mb-5' : ''}`} key={subList.id}>
                        <StyledRow className="row no-gutters">
                          <CheckList
                            mode={checkboxTheme}
                            listTitle={subList.title}
                            listSubtitle={subList.subtitle}
                            checkListData={checkListData}
                            checkFn={onCheck}
                            data-locator={formatDataLocator(subList.title)}
                            hasSelectAndClearAllOption={subList.hasSelectAndClearAllOption}
                          />
                        </StyledRow>
                        {showMobileBtn && (
                          <div className="btn-inprogress-mobile">
                            <div className="d-inline-block px-3 bg-white">
                              <StyledButton onClick={onSelectShipmentsInProgress}>
                                <FormattedMessage
                                  id="quickView.filters.shipmentsInProgress"
                                  defaultMessage="Shipments in Progress"
                                />
                              </StyledButton>
                            </div>
                          </div>
                        )}
                      </StyledCol>
                    );
                  })}
                </StyledRow>
                {hasShipmentInProgressButton && (
                  <StyledRow className="row btn-inprogress-desktop">
                    <StyledCol className="col">
                      <StyledButton onClick={onSelectShipmentsInProgress} className="mt-4">
                        <FormattedMessage
                          id="quickView.filters.shipmentsInProgress"
                          defaultMessage="Shipments in Progress"
                        />
                      </StyledButton>
                    </StyledCol>
                  </StyledRow>
                )}
              </StyledCol>
            );
          }
        )}
      </StyledRow>
    </>
  );
};

const LocationTypeFilter = (props: any) => <LocationTypeComponent {...props} />;

export default (props: any) => {
  const railFilters = [
    {
      name: <FormattedMessage {...messages.shipmentStatus} />,
      component: ShipmentStatus,
      modalId: 'shipmentStatus',
    },
    {
      name: <FormattedMessage {...messages.location} />,
      component: LocationTypeFilter,
      modalId: 'locationType',
      classNameContent: 'p-0',
    },
  ];

  return compact(railFilters);
};
