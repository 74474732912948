import get from 'lodash/get';
import { extractErrorDetails } from 'common/errorUtils';

const getCarrierId = (carrier) => get(carrier, 'id');

class AddEditShipmentApiMapper {
  static mapStateToApi(state) {
    if (!state) {
      return null;
    }

    const dto = {};
    dto.carrierId = getCarrierId(state.carrier);
    dto.shipmentIdentifiers = state.shipmentIdentifiers;
    if (state.carrier && state.carrier.entered && state.carrier.entered.trackingMethod) {
      dto.equipmentIdentifiers = state.carrier.entered.trackingMethod;
    }
    dto.stops = state.stops;
    dto.temperatureSettings = state.temperatureSettings;
    dto.scheduledTruckChanges = state.scheduledTruckChanges;
    return dto;
  }

  static mapEditStateToApi(state) {
    return state;
  }

  static mapAddApiResponseToState(responseData) {
    // contains only shipmentId currently
    return responseData;
  }

  static mapApiErrorResponseToState(error) {
    return extractErrorDetails(error);
  }
}

export default AddEditShipmentApiMapper;
