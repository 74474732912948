import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { put, takeEvery, call, all, select } from 'redux-saga/effects';
import {
  getPrincipalAuthoritiesFromState,
  getPrincipalAuthorizations,
  shouldUseRecentSSS,
} from 'common/authorizations';

import { trackEvent } from 'common/eventTracker';
import { API_PATH, DEFAULT_SHIPMENT_SOURCES } from '../../../common/AppConstants';
import axios from '../../../util/paxios';
import types from './types';
import constants from './constants';
import { mapShipmentConfig, setUserDetailsToShipmentConfig } from './shipmentCountsMapper';
import { mapStateToApi } from './stateToApiMapper';
import FilterUtil from '../../filter/util/filterUtil';
import { CLEAR_SHIPMENTS } from '../../shipmentListComponent/ducks/searchReducer';
import endpoints from '../../../common/endpoints';

export function getShipmentConfigs() {
  return axios({
    method: 'GET',
    url: API_PATH + '/user-landing/filter-preference',
    withCredentials: true,
  });
}

export function saveShipmentConfigs(quickViewConfigs) {
  return axios({
    method: 'PUT',
    url: API_PATH + '/user-landing/filter-preference',
    withCredentials: true,
    data: quickViewConfigs,
  });
}

export const deleteShipmentConfig = (configId) => {
  return axios({
    method: 'DELETE',
    url: API_PATH + '/user-landing/filter-preference/id/' + configId,
    withCredentials: true,
  });
};

// Since not all quickviews have a mode associated with them, we default to using the
// permissions the user has. Since these permissions are not guaranteed to be available from
// the login reducer when the ShipmentCountsComponent mounts, we must fetch the principal from
// here instead
// - Matt Mariutto
export function getPrincipal() {
  return axios({
    method: 'GET',
    url: API_PATH + '/principal',
    withCredentials: true,
  });
}

export function* countInventoryAsync(config) {
  // API will throw a 400 if we don't include `page` and `size` parameters
  const url = new URL(`${API_PATH}${endpoints.INVENTORY_ITEMS_SEARCH}?page=1&size=30`, window.location.origin);

  const searchQuery = {
    ...config.inventoryFilter,
    searchText: config.searchKeywords,
  };

  const response = yield call(axios, {
    method: 'POST',
    url,
    data: searchQuery,
    withCredentials: true,
  });
  config.inventory = response.data.paginationInfo.total;

  if (!isEmpty(config.totalInventoryFilter)) {
    const totalSearchQuery = {
      ...config.totalInventoryFilter,
      searchText: config.searchKeywords,
    };

    const response = yield call(axios, {
      method: 'POST',
      url,
      data: totalSearchQuery,
      withCredentials: true,
    });
    config.totalInventory = response.data.paginationInfo.total;
  }
}

export function* countOrdersAsync(config) {
  const url = new URL(`${API_PATH}${endpoints.ORDERS_SEARCH}`, window.location.origin);

  const searchQuery = {
    ...config.orderFilter,
    searchText: config.searchKeywords,
  };

  const response = yield call(axios, {
    method: 'POST',
    url,
    data: searchQuery,
    withCredentials: true,
  });
  config.orders = response.data.paginationInfo.total;

  if (!isEmpty(config.totalOrderFilter)) {
    const totalSearchQuery = {
      ...config.totalOrderFilter,
      searchText: config.searchKeywords,
    };

    const response = yield call(axios, {
      method: 'POST',
      url,
      data: totalSearchQuery,
      withCredentials: true,
    });
    config.totalOrders = response.data.paginationInfo.total;
  }
}

export function* countShipments(config, principal) {
  const searchQuery = buildShipmentSearchQuery(config.searchKeywords, config.filter, config.mode, 0, principal);

  // On dashboard banner calling search with multiModal flag to get derived filters.
  const searchQueryWithMultiModalFlag = config.multiModal;

  if (isEmpty(searchQuery.modes)) {
    config.shipments = 0;
  } else {
    const response = yield call(axios, {
      method: 'POST',
      url: API_PATH + '/shipment/search',
      data: searchQueryWithMultiModalFlag ? { ...searchQuery, multiModal: true } : searchQuery,
      withCredentials: true,
    });
    config.shipments = response.data.totalResults;
  }

  if (!isEmpty(config.totalFilter)) {
    const searchQueryTotalFilter = buildShipmentSearchQuery(
      config.searchKeywords,
      config.totalFilter,
      config.totalMode,
      0,
      principal
    );

    if (isEmpty(searchQueryTotalFilter.modes)) {
      config.totalShipments = 0;
    } else {
      const response = yield call(axios, {
        method: 'POST',
        url: API_PATH + '/shipment/search',
        data: searchQueryTotalFilter,
        withCredentials: true,
      });
      config.totalShipments = response.data.totalResults;
    }
  }
}

export function* shipmentCountsAsync() {
  try {
    yield put({ type: types.SHIPMENT_COUNTS_LOADING_PENDING });

    const quickViewConfigResponse = yield call(getShipmentConfigs);
    const mappedConfigs = mapShipmentConfig(quickViewConfigResponse.data);
    const principalData = yield call(getPrincipal);
    const authorizations = getPrincipalAuthorizations(principalData);
    const isQuickviewV2Enabled = authorizations.quickviewV2Enabled();

    yield all(
      mappedConfigs.config.map((config) => {
        if (config.filter) {
          return call(countShipments, config, principalData.data);
        } else if (config.orderFilter) {
          return call(countOrdersAsync, config);
        } else {
          return call(countInventoryAsync, config);
        }
      })
    );
    // HAPPENING_TODAY_FILTER is legacy code that will derive shipment status for In Transit and At Stop per mode with the exception of parcel and rail
    // multiModal flag will do the same however it will derive status for every mode
    const dashboardInprogressConfig = {
      isQuickviewV2Enabled,
      modes: FilterUtil.getAuthorizedModes(principalData.data),
      inProgressFilter: constants.ALL_MODE_IN_PROGRESS_FILTER,
      happeningTodayFilter: constants.HAPPENING_TODAY_FILTER,
    };
    const happeningTodayCountConfig = FilterUtil.getDashboardInProgressConfig(dashboardInprogressConfig);

    yield call(countShipments, happeningTodayCountConfig, principalData.data);

    mappedConfigs.config = setUserDetailsToShipmentConfig(mappedConfigs.config, principalData.data);

    yield put({
      type: types.SHIPMENT_COUNTS_GET_SUCCESS,
      quickViewConfig: mappedConfigs,
      happeningTodayCounts: happeningTodayCountConfig.shipments,
    });

    // clear shipments from search reducer when finished
    yield put({ type: CLEAR_SHIPMENTS });

    yield put({ type: types.SHIPMENT_COUNTS_LOADING_SUCCESS });
  } catch (error) {
    yield put({
      type: types.SHIPMENT_COUNTS_GET_FAILURE,
      payload: error,
    });

    yield put({ type: types.SHIPMENT_COUNTS_LOADING_ERROR });
  }
}

export function* shipmentCountsSaveAsync(action) {
  try {
    yield all(
      action.config.deletedIds.map((deletedId) => {
        return call(deleteShipmentConfig, deletedId);
      })
    );

    const quickViewConfigResponse = yield call(saveShipmentConfigs, mapStateToApi(action.config));
    const mappedConfigs = mapShipmentConfig(quickViewConfigResponse.data);
    const state = yield select(getState);
    yield all(
      mappedConfigs.config.map((config) => {
        if (config.filter) {
          return call(countShipments, config, state.authorities);
        } else if (config.orderFilter) {
          return call(countOrdersAsync, config);
        } else {
          return call(countInventoryAsync, config);
        }
      })
    );

    trackEvent('UPDATED_QUICK_VIEW_CARDS');
    yield put({
      type: types.QUICK_VIEW_SAVE_SUCCESS,
      quickViewConfig: mappedConfigs,
    });
  } catch (error) {
    // TODO: add error state
  }
}

export const getState = (state) => ({
  authorities: getPrincipalAuthoritiesFromState(state),
});

export function buildShipmentSearchQuery(
  searchText = '',
  filter = {},
  mode,
  resultsPerPage = 0,
  principal,
  shipmentSources = DEFAULT_SHIPMENT_SOURCES
) {
  const attribute = get(filter, 'attribute');
  const attributeKeyValuePairs = isEmpty(attribute)
    ? get(filter, 'attributeKeyValuePairs')
    : FilterUtil.generateAttributeKeyValuePairs(attribute);
  let modesForQuery = [];
  const modes = FilterUtil.getModesForQuery([mode], filter, principal);

  if (isEmpty(modes)) {
    modesForQuery = FilterUtil.getAuthorizedModes(principal);
  }

  return {
    search: searchText,
    filter: {
      ...filter,
      attribute: [],
      attributeKeyValuePairs,
    },
    modes: isEmpty(modes) ? modesForQuery : modes,
    shipmentSources,
    resultsPerPage: resultsPerPage,
    recent: shouldUseRecentSSS,
  };
}

export function* watchShipmentCountsAsync() {
  yield takeEvery(types.SHIPMENT_COUNTS_GET, shipmentCountsAsync);
}

export function* watchShipmentCountsSaveAsync() {
  yield takeEvery(types.QUICK_VIEW_SAVE_LAYOUT, shipmentCountsSaveAsync);
}
