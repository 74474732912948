import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import * as styles from './styles.module.scss';
import TimeSelect from './TimeSelect';
import { useDatePicker } from './context/DatePickerContext';
import { antdMoment, updateTime } from './utils';
import { AntdMoment } from './types';

interface NewDatePickerProps {
  tabs: React.ReactNode;
  sidebar: React.ReactNode;
  label: React.ReactNode;
  radios: React.ReactNode;
  datePickerInput: React.ReactNode;
  popupRef: React.RefObject<HTMLDivElement>;
  className?: string;
  popupClassName?: string;
  headerClassName?: string;
}

const NewDatePicker = ({
  tabs,
  sidebar,
  label,
  radios,
  datePickerInput,
  popupRef,
  className,
  popupClassName,
  headerClassName,
}: NewDatePickerProps) => {
  const { state, dispatch } = useDatePicker();

  const handleChangeStartTime = (value: AntdMoment) => {
    const startDate = state[state.eventType].start;
    if (startDate) {
      dispatch({ type: 'SET_START_DATE', payload: updateTime(startDate, value) });
    }
  };

  const handleChangeEndTime = (value: AntdMoment) => {
    const endDate = state[state.eventType].end;
    if (endDate) {
      dispatch({ type: 'SET_END_DATE', payload: updateTime(endDate, value) });
    }
  };

  return (
    <div data-testid="container" className={cn('d-flex', styles['container'], className)}>
      {sidebar}
      <div className="px-5 pt-5 pb-4 flex-1">
        <div className="mb-4">
          {tabs}
          {label}
          <div className={cn('d-flex align-items-center flex-wrap', headerClassName)}>
            {radios}
            {datePickerInput}
          </div>
        </div>
        <div
          data-testid="popup-container"
          className={cn(styles['popup-container'], popupClassName)}
          ref={popupRef}
        ></div>
        <div
          data-testid="date-picker-footer"
          className={cn('d-flex justify-content-center pt-3', styles['date-picker-footer'])}
        >
          <TimeSelect
            className={cn('pr-4', styles['time-select'])}
            id="time-select-start-date"
            label={<FormattedMessage id="datePicker.label.startTime" defaultMessage="Start Time" />}
            disabled={!state[state.eventType].start}
            onChange={handleChangeStartTime}
            value={state[state.eventType].start || antdMoment().startOf('day')}
          />
          <TimeSelect
            className="ml-4"
            id="time-select-end-date"
            label={<FormattedMessage id="datePicker.label.endTime" defaultMessage="End Time" />}
            disabled={!state[state.eventType].end}
            onChange={handleChangeEndTime}
            value={state[state.eventType].end || antdMoment().endOf('day')}
          />
        </div>
      </div>
    </div>
  );
};

export default NewDatePicker;
