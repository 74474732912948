// * This is imported in this way, because antd is using a different version of moment than we have.
import antdMoment from 'antd/node_modules/moment';
import { RadioChangeEvent } from 'antd/lib/radio';
import { AntdMoment } from './types';

const buildRadioHandler = (callbackFn: Function) => (event: RadioChangeEvent) => {
  callbackFn(event.target.value);
};

const updateTime = (date: AntdMoment, nextTime: AntdMoment) => {
  return date.clone().set({
    hours: nextTime.get('hours'),
    minutes: nextTime.get('minutes'),
    seconds: nextTime.get('seconds'),
  });
};

export { buildRadioHandler, updateTime, antdMoment };
