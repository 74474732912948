export default {
  outgoing: {
    totalResults: 0,
    results: [
      {
        entitlementInfo: {
          isOwner: true,
          hasGrantedAccessToOthers: false,
          entitlees: [],
          grantor: null,
        },
        shipmentStopGeofences: [null, null, null, null],
        mode: 'TL',
        shipmentId: 500000279480,
        latestStopStatuses: [],
        shipmentStops: [
          {
            stopNumber: 1,
            stopName: 'Origin',
            location: {
              address: {
                addressLines: ['580 Marketplace Dr', null, null],
                city: 'Bel Air',
                state: 'MD',
                cityState: 'Bel Air, MD, US',
                postalCode: '21014',
                countryCodeDto: 'US',
              },
              locationId: '47a23ce1-c15c-41ea-aaba-fa59f0bc8fd8',
              masterLocationId: '4acc0617-07ab-4e3a-8ee9-ecce3fc3fb4d',
              locationVersion: 0,
              normalizedAddress: {
                addressLines: ['580 Marketplace Dr', null, null],
                city: 'Bel Air',
                state: 'MD',
                cityState: 'Bel Air, MD, US',
                postalCode: '21014',
                countryCodeDto: 'US',
              },
            },
          },
          {
            stopNumber: 2,
            stopName: 'Stop 2 of 4',
            location: {
              address: {
                addressLines: ['100 Ponds Edge Dr', null, null],
                city: 'Nottingham',
                state: 'PA',
                cityState: 'Nottingham, PA, US',
                postalCode: '19362',
                countryCodeDto: 'US',
              },
              locationId: 'f1ff9f13-fb0c-454b-a1c4-1652017cb8e6',
              masterLocationId: '9359abe9-4f20-4b7e-80ec-2e6abf144b45',
              locationVersion: 0,
              normalizedAddress: {
                addressLines: ['100 Ponds Edge Dr', null, null],
                city: 'Nottingham',
                state: 'PA',
                cityState: 'Nottingham, PA, US',
                postalCode: '19362',
                countryCodeDto: 'US',
              },
            },
          },
          {
            stopNumber: 3,
            stopName: 'Stop 3 of 4',
            location: {
              address: {
                addressLines: ['500 Apple Tree Ln', null, null],
                city: 'Oxford',
                state: 'PA',
                cityState: 'Oxford, PA, US',
                postalCode: '19363',
                countryCodeDto: 'US',
              },
              locationId: '3005e1cb-dcb5-40e5-9ad9-5ef39a65dcda',
              masterLocationId: 'f6116bde-68da-4f81-a20e-a78cc3814c4e',
              locationVersion: 0,
              normalizedAddress: {
                addressLines: ['500 Apple Tree Ln', null, null],
                city: 'Oxford',
                state: 'PA',
                cityState: 'Oxford, PA, US',
                postalCode: '19363',
                countryCodeDto: 'US',
              },
            },
          },
          {
            stopNumber: 4,
            stopName: 'Destination',
            location: {
              address: {
                addressLines: ['802 Far Hills Dr', null, null],
                city: 'New Freedom',
                state: 'PA',
                cityState: 'New Freedom, PA, US',
                postalCode: '17349',
                countryCodeDto: 'US',
              },
              locationId: 'b43c0c93-dfa2-4652-90a6-31c52500cb18',
              masterLocationId: '3a50ffef-a36b-410b-b7b6-a1fce74262d3',
              locationVersion: 0,
              normalizedAddress: {
                addressLines: ['802 Far Hills Dr', null, null],
                city: 'New Freedom',
                state: 'PA',
                cityState: 'New Freedom, PA, US',
                postalCode: '17349',
                countryCodeDto: 'US',
              },
            },
          },
        ],
        shipmentIdentifiers: [
          {
            type: 'BILL_OF_LADING',
            value: '4647POQKWT',
          },
        ],
        tenantId: 1042,
        latestStatusUpdate: {
          stopNumber: 3,
          statusCode: 'IN_TRANSIT',
          statusReasonCode: 'IN_MOTION',
          utcTimestamp: '2019-12-10T23:10:31',
          localTimestamp: '2019-12-10T18:10:31',
          locationCoordinatesDto: {
            latitude: 39.72704,
            longitude: -76.435,
            accuracyInMeters: 0,
          },
        },
        lastVisitedStopInfo: {
          arrivalOrDepartureUtcTimestamp: '2019-12-10T23:10:31',
          locationId: 'f1ff9f13-fb0c-454b-a1c4-1652017cb8e6',
          masterLocationId: '9359abe9-4f20-4b7e-80ec-2e6abf144b45',
        },
        nextStopArrivalInfo: {
          utcEarliestEstimatedArrivalDateTime: '2019-12-11T02:11:31',
          locationId: '3005e1cb-dcb5-40e5-9ad9-5ef39a65dcda',
          masterLocationId: 'f6116bde-68da-4f81-a20e-a78cc3814c4e',
        },
      },
    ],
  },
  incoming: {
    totalResults: 8,
    results: [
      {
        entitlementInfo: {
          isOwner: true,
          hasGrantedAccessToOthers: false,
          entitlees: [],
          grantor: null,
        },
        shipmentStopGeofences: [null, null, null, null],
        mode: 'TL',
        shipmentId: 500000249945,
        latestStopStatuses: [
          {
            stopId: 577035,
            stopNumber: 1,
            statusCode: 'UNKNOWN',
            recordedArrivalCode: 'UNKNOWN',
          },
          {
            stopId: 577037,
            stopNumber: 2,
            statusCode: 'EN_ROUTE',
            recordedArrivalCode: 'UNKNOWN',
            travelRouteDto: {
              distanceInMeters: 23680,
              timeInSeconds: 1764,
            },
            utcEarliestEstimatedArrivalDateTime: '2019-11-23T05:58:03',
          },
          {
            stopId: 577041,
            stopNumber: 3,
            statusCode: 'UNKNOWN',
            recordedArrivalCode: 'UNKNOWN',
            travelRouteDto: {
              distanceInMeters: 31965,
              timeInSeconds: 2681,
            },
            utcEarliestEstimatedArrivalDateTime: '2019-11-23T21:08:03',
          },
          {
            stopId: 577044,
            stopNumber: 4,
            statusCode: 'UNKNOWN',
            recordedArrivalCode: 'UNKNOWN',
            travelRouteDto: {
              distanceInMeters: 120372,
              timeInSeconds: 8885,
            },
            utcEarliestEstimatedArrivalDateTime: '2019-11-25T19:38:05',
          },
        ],
        shipmentStops: [
          {
            stopNumber: 1,
            stopName: 'Origin',
            localAppointmentStartDateTime: '2019-11-21T13:38:05',
            localAppointmentEndDateTime: '2019-11-21T14:38:05',
            utcAppointmentStartDateTime: '2019-11-21T18:38:05',
            utcAppointmentEndDateTime: '2019-11-21T19:38:05',
            location: {
              address: {
                addressLines: ['580 Marketplace Dr', null, null],
                city: 'Bel Air',
                state: 'MD',
                cityState: 'Bel Air, MD, US',
                postalCode: '21014',
                countryCodeDto: 'US',
                locationCoordinatesDto: {
                  latitude: 39.52547,
                  longitude: -76.35028,
                  localTimeZoneIdentifier: 'America/New_York',
                  localTimeZoneIdentifierShort: 'EST',
                },
              },
              locationId: '47a23ce1-c15c-41ea-aaba-fa59f0bc8fd8',
              masterLocationId: '4acc0617-07ab-4e3a-8ee9-ecce3fc3fb4d',
              locationVersion: 0,
              normalizedAddress: {
                addressLines: ['580 Marketplace Dr', null, null],
                city: 'Bel Air',
                state: 'MD',
                cityState: 'Bel Air, MD, US',
                postalCode: '21014',
                countryCodeDto: 'US',
                locationCoordinatesDto: {
                  latitude: 39.52547,
                  longitude: -76.35028,
                  localTimeZoneIdentifier: 'America/New_York',
                  localTimeZoneIdentifierShort: 'EST',
                },
              },
              contactDto: {},
            },
          },
          {
            stopNumber: 2,
            stopName: 'Stop 2 of 4',
            localAppointmentStartDateTime: '2019-11-24T13:38:05',
            localAppointmentEndDateTime: '2019-11-24T14:38:05',
            utcAppointmentStartDateTime: '2019-11-24T18:38:05',
            utcAppointmentEndDateTime: '2019-11-24T19:38:05',
            location: {
              address: {
                addressLines: ['100 Ponds Edge Dr', null, null],
                city: 'Nottingham',
                state: 'PA',
                cityState: 'Nottingham, PA, US',
                postalCode: '19362',
                countryCodeDto: 'US',
                locationCoordinatesDto: {
                  latitude: 39.75046,
                  longitude: -76.02246,
                  localTimeZoneIdentifier: 'America/New_York',
                  localTimeZoneIdentifierShort: 'EST',
                },
              },
              locationId: 'f1ff9f13-fb0c-454b-a1c4-1652017cb8e6',
              masterLocationId: '9359abe9-4f20-4b7e-80ec-2e6abf144b45',
              locationVersion: 0,
              normalizedAddress: {
                addressLines: ['100 Ponds Edge Dr', null, null],
                city: 'Nottingham',
                state: 'PA',
                cityState: 'Nottingham, PA, US',
                postalCode: '19362',
                countryCodeDto: 'US',
                locationCoordinatesDto: {
                  latitude: 39.75046,
                  longitude: -76.02246,
                  localTimeZoneIdentifier: 'America/New_York',
                  localTimeZoneIdentifierShort: 'EST',
                },
              },
              contactDto: {
                companyName: 'Test company',
                contactName: 'Test name',
                email: 'test@test.com',
                phoneNumber1: '555-555-5555',
                phoneNumber2: '555-555-5555',
                faxNumber: '555-555-5555',
              },
            },
          },
          {
            stopNumber: 3,
            stopName: 'Stop 3 of 4',
            localAppointmentStartDateTime: '2019-11-24T13:38:05',
            localAppointmentEndDateTime: '2019-11-24T14:38:05',
            utcAppointmentStartDateTime: '2019-11-24T18:38:05',
            utcAppointmentEndDateTime: '2019-11-24T19:38:05',
            location: {
              address: {
                addressLines: ['500 Apple Tree Ln', null, null],
                city: 'Oxford',
                state: 'PA',
                cityState: 'Oxford, PA, US',
                postalCode: '19363',
                countryCodeDto: 'US',
                locationCoordinatesDto: {
                  latitude: 39.77871,
                  longitude: -75.9639,
                  localTimeZoneIdentifier: 'America/New_York',
                  localTimeZoneIdentifierShort: 'EST',
                },
              },
              locationId: '3005e1cb-dcb5-40e5-9ad9-5ef39a65dcda',
              masterLocationId: 'f6116bde-68da-4f81-a20e-a78cc3814c4e',
              locationVersion: 0,
              normalizedAddress: {
                addressLines: ['500 Apple Tree Ln', null, null],
                city: 'Oxford',
                state: 'PA',
                cityState: 'Oxford, PA, US',
                postalCode: '19363',
                countryCodeDto: 'US',
                locationCoordinatesDto: {
                  latitude: 39.77871,
                  longitude: -75.9639,
                  localTimeZoneIdentifier: 'America/New_York',
                  localTimeZoneIdentifierShort: 'EST',
                },
              },
              contactDto: {
                companyName: 'Test company',
                contactName: 'Test name',
                email: 'test@test.com',
                phoneNumber1: '555-555-5555',
                phoneNumber2: '555-555-5555',
                faxNumber: '555-555-5555',
              },
            },
          },
          {
            stopNumber: 4,
            stopName: 'Destination',
            localAppointmentStartDateTime: '2019-11-25T13:38:05',
            localAppointmentEndDateTime: '2019-11-25T14:38:05',
            utcAppointmentStartDateTime: '2019-11-25T18:38:05',
            utcAppointmentEndDateTime: '2019-11-25T19:38:05',
            location: {
              address: {
                addressLines: ['802 Far Hills Dr', null, null],
                city: 'New Freedom',
                state: 'PA',
                cityState: 'New Freedom, PA, US',
                postalCode: '17349',
                countryCodeDto: 'US',
                locationCoordinatesDto: {
                  latitude: 39.77652,
                  longitude: -76.66289,
                  localTimeZoneIdentifier: 'America/New_York',
                  localTimeZoneIdentifierShort: 'EST',
                },
              },
              locationId: 'b43c0c93-dfa2-4652-90a6-31c52500cb18',
              masterLocationId: '3a50ffef-a36b-410b-b7b6-a1fce74262d3',
              locationVersion: 0,
              normalizedAddress: {
                addressLines: ['802 Far Hills Dr', null, null],
                city: 'New Freedom',
                state: 'PA',
                cityState: 'New Freedom, PA, US',
                postalCode: '17349',
                countryCodeDto: 'US',
                locationCoordinatesDto: {
                  latitude: 39.77652,
                  longitude: -76.66289,
                  localTimeZoneIdentifier: 'America/New_York',
                  localTimeZoneIdentifierShort: 'EST',
                },
              },
              contactDto: {
                companyName: 'Test company',
                contactName: 'Test name',
                email: 'test@test.com',
                phoneNumber1: '555-555-5555',
                phoneNumber2: '555-555-5555',
                faxNumber: '555-555-5555',
              },
            },
          },
        ],
        shipmentIdentifiers: [
          {
            type: 'BILL_OF_LADING',
            value: 'A12EFO9CC2',
          },
        ],
        tenantId: 1042,
        latestStatusUpdate: {
          stopNumber: 2,
          statusCode: 'IN_TRANSIT',
          statusReasonCode: 'IN_MOTION',
          utcTimestamp: '2019-11-22T18:40:03',
          localTimestamp: '2019-11-22T13:40:03',
          locationCoordinatesDto: {
            latitude: 39.652424,
            longitude: -76.22767,
            accuracyInMeters: 0,
          },
          address: {
            addressLines: ['3448 Cedar Church Rd', null, null],
            city: 'Darlington',
            state: 'MD',
            cityState: 'Darlington, MD, US',
            postalCode: '21034',
            countryCodeDto: 'US',
            locationCoordinatesDto: {
              latitude: 39.652424,
              longitude: -76.22767,
              accuracyInMeters: 20.9,
              localTimeZoneIdentifier: 'America/New_York',
              localTimeZoneIdentifierShort: 'EST',
            },
          },
        },
        lastVisitedStopInfo: {
          arrivalOrDepartureUtcTimestamp: null,
          locationId: '47a23ce1-c15c-41ea-aaba-fa59f0bc8fd8',
          masterLocationId: '4acc0617-07ab-4e3a-8ee9-ecce3fc3fb4d',
        },
        nextStopArrivalInfo: {
          utcEarliestEstimatedArrivalDateTime: '2019-11-23T05:58:03',
          locationId: 'f1ff9f13-fb0c-454b-a1c4-1652017cb8e6',
          masterLocationId: '9359abe9-4f20-4b7e-80ec-2e6abf144b45',
        },
      },
    ],
  },
};
