import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import axios from 'util/paxios';
import { Page, SortField } from 'models';
import { buildUrlWithPaginationParams } from 'models/page-helper';
import { buildUrlWithSortParams } from 'models/sort-helper';
import { InventoryItem } from 'components/Inventory/models/inventory';
import { API_PATH } from 'common/AppConstants';
import endpoints from 'common/endpoints';
import { ComponentErrorProps } from 'components/common/errorBoundary/models';
import { BasicInventoryItemSearchCriteria } from 'components/Inventory/models/InventoryItemSearch';

interface InventoryItemsHook {
  hasPermission: boolean;
  masterShipmentId?: string;
  defaultPageSize?: number;
  searchText?: string;
  orderId?: string;
}

export function useInventoryItems({
  hasPermission,
  masterShipmentId,
  defaultPageSize = 30,
  searchText,
  orderId,
}: InventoryItemsHook) {
  const [items, setItems] = useState<Page<InventoryItem>>();
  const [ordersDataLoading, setOrdersDataLoading] = useState(false);
  const [componentError, setComponentError] = useState<ComponentErrorProps>({ errorsList: [] });

  const searchItems = (
    body: BasicInventoryItemSearchCriteria,
    page: number,
    size: number,
    sortFields: SortField<string>[]
  ) => {
    setOrdersDataLoading(true);
    getItems(
      {
        ...body,
        ...(masterShipmentId && { shipmentIds: [masterShipmentId] }),
      },
      page,
      size,
      sortFields
    );
  };

  const handleFetchErrors = useCallback(
    function handleFetchErrorsCallback(key: string, error: any, errorIsNew: boolean) {
      if (error.response && errorIsNew) {
        const errorsArray =
          error.response.status === 404 ? [{ message: error.response.data.message }] : error.response.data.errors;
        const errorsList = [
          ...componentError.errorsList,
          {
            error: errorsArray.map((err: any) => err.message),
            supportRefId: error.response.data.supportReferenceId,
            id: key,
          },
        ];
        setComponentError({ errorsList });
      }
    },
    [componentError.errorsList]
  );

  const getItems = useCallback(
    async function getItems(
      requestBody: BasicInventoryItemSearchCriteria,
      pageNumber: number,
      pageSize: number,
      sortFields: SortField<string>[]
    ) {
      const errorKey = 'inventory-items-hook';
      const previousErrorIndex = componentError.errorsList.find((elem) => elem.id === errorKey);
      try {
        let url = new URL(`${API_PATH}${endpoints.INVENTORY_ITEMS_SEARCH}`, window.location.origin);
        url = buildUrlWithPaginationParams(url, { pageNumber, pageSize });
        url = buildUrlWithSortParams(url, sortFields);
        const response: AxiosResponse<Page<InventoryItem>> = await axios.post(url.toString(), requestBody, {
          withCredentials: true,
        });
        if (previousErrorIndex) {
          const updatedErrorsList = componentError.errorsList.filter((elem) => elem.id !== errorKey);
          setComponentError({ errorsList: updatedErrorsList });
        }
        setItems(response.data);
        setOrdersDataLoading(false);
      } catch (error) {
        setOrdersDataLoading(false);
        handleFetchErrors('inventory-items-hook', error, true);
      }
    },
    [componentError.errorsList, handleFetchErrors]
  );
  useEffect(() => {
    if (hasPermission) {
      setOrdersDataLoading(true);
      getItems(
        {
          ...(masterShipmentId && { shipmentIds: [masterShipmentId] }),
          ...(orderId && { orderIds: [orderId] }),
          searchText,
        },
        1,
        defaultPageSize,
        []
      );
    }
  }, [getItems, hasPermission, masterShipmentId, defaultPageSize, searchText, orderId]);

  return {
    dataLoading: ordersDataLoading,
    inventoryItems: items,
    searchItems,
    componentError,
  };
}
