import { FormattedMessage } from 'react-intl';
import { EventTypeKey, FilterableByDateShipmentMode, PresetDateName, AntdMoment } from './types';
import { antdMoment } from './utils';

const PRESET_DATE_LABEL = {
  specificDay: {
    id: 'datePicker.label.day',
    defaultMessage: 'Day',
  },
  rangeDate: {
    id: 'datePicker.label.date',
    defaultMessage: 'Date',
  },
};

const PRESET_DATE_RADIO_OPTIONS = {
  specificDay: [
    {
      value: 'custom',
      label: {
        id: 'datePicker.presetDates.custom',
        defaultMessage: 'Custom',
      },
    },
    {
      value: 'today',
      label: {
        id: 'datePicker.presetDates.today',
        defaultMessage: 'Today',
      },
    },
    {
      value: 'tomorrow',
      label: {
        id: 'datePicker.presetDates.tomorrow',
        defaultMessage: 'Tomorrow',
      },
    },
  ],
  rangeDate: [
    {
      value: 'custom',
      label: {
        id: 'datePicker.presetDates.custom',
        defaultMessage: 'Custom',
      },
    },
    {
      value: 'thisWeek',
      label: {
        id: 'datePicker.presetDates.thisWeek',
        defaultMessage: 'This Week',
      },
    },
    {
      value: 'nextWeek',
      label: {
        id: 'datePicker.presetDates.nextWeek',
        defaultMessage: 'Next Week',
      },
    },
  ],
};

type PresetDateValueType = [AntdMoment, AntdMoment];

const PRESET_DATE_VALUE: Record<Exclude<PresetDateName, 'custom'>, PresetDateValueType> = {
  today: [antdMoment().startOf('day'), antdMoment().endOf('day')],
  tomorrow: [antdMoment().add(1, 'day').startOf('day'), antdMoment().add(1, 'day').endOf('day')],
  thisWeek: [antdMoment().startOf('week'), antdMoment().endOf('week')],
  nextWeek: [antdMoment().add(1, 'week').startOf('week'), antdMoment().add(1, 'week').endOf('week')],
};

const DATE_FORMAT = 'YYYY/MM/DD HH:ss';

const CALENDAR_DATE_FORMAT = 'MM/DD/YYYY';

const EVENT_TYPE_LABEL = {
  delivery: <FormattedMessage id="datePicker.label.scheduledDelivery" defaultMessage="Scheduled Delivery" />,
  pickup: <FormattedMessage id="datePicker.label.scheduledPickup" defaultMessage="Scheduled Pickup" />,
  arrival: <FormattedMessage id="datePicker.label.scheduledArrival" defaultMessage="Scheduled Arrival" />,
  lastFreeDate: <FormattedMessage id="datePicker.label.scheduledDay" defaultMessage="Last Free Day" />,
};

const SHIPMENT_MODE_EVENT_TYPES: Record<FilterableByDateShipmentMode, Array<EventTypeKey>> = {
  ALL: ['delivery'],
  TL: ['pickup', 'delivery'],
  LTL: ['pickup', 'delivery'],
  OCEAN: ['arrival', 'lastFreeDate'],
  PARCEL: ['pickup', 'delivery'],
  AIR: ['pickup', 'delivery'],
};

export {
  PRESET_DATE_VALUE,
  PRESET_DATE_LABEL,
  PRESET_DATE_RADIO_OPTIONS,
  DATE_FORMAT,
  CALENDAR_DATE_FORMAT,
  EVENT_TYPE_LABEL,
  SHIPMENT_MODE_EVENT_TYPES,
};
