import { useContext } from 'react';
import '../Footer.scss';
import { PrincipalContext } from 'contexts/PrincipalContext';
import generateLinks from './generateLinks';
import FooterLinkGroup from './FooterLinkGroup';

const FooterLinksWrapper = ({ isLoggedIn, principal }) => {
  const principalContext = useContext(PrincipalContext);

  const userPermissions = (principal?.authorities || []).map(({ authority }) => authority);

  const footerConfig = generateLinks(principalContext);
  const groups = footerConfig.map((groupConfig) => (
    <FooterLinkGroup
      key={groupConfig.groupName.id}
      isLoggedIn={isLoggedIn}
      userPermissions={userPermissions}
      groupConfig={groupConfig}
    />
  ));

  return <>{groups}</>;
};

export default FooterLinksWrapper;
