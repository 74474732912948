const SVGIconsLibrary = () => (
  <svg style={{ display: 'none' }}>
    <symbol id="icon-TL" viewBox="0 0 43 32">
      <path d="M30.476 7.619h5.714l5.714 7.619v9.524h-3.81c0 3.162-2.552 5.714-5.714 5.714s-5.714-2.552-5.714-5.714h-11.429c0 3.162-2.552 5.714-5.714 5.714s-5.714-2.552-5.714-5.714h-3.81v-20.952c0-2.095 1.714-3.81 3.81-3.81h26.667v7.619zM7.619 24.762c0 1.048 0.857 1.905 1.905 1.905s1.905-0.857 1.905-1.905c0-1.048-0.857-1.905-1.905-1.905s-1.905 0.857-1.905 1.905zM35.238 10.476h-4.762v4.762h8.495l-3.733-4.762zM30.476 24.762c0 1.048 0.857 1.905 1.905 1.905s1.905-0.857 1.905-1.905c0-1.048-0.857-1.905-1.905-1.905s-1.905 0.857-1.905 1.905z"></path>
    </symbol>
    <symbol id="icon-PARCEL" viewBox="0 0 51 32">
      <path d="M4.444 0h31.111l13.333 13.333v11.111h-4.445c0 3.689-2.978 6.667-6.667 6.667s-6.667-2.978-6.667-6.667h-13.333c0 3.689-2.978 6.667-6.667 6.667s-6.667-2.978-6.667-6.667h-4.444v-20c0-2.467 2-4.444 4.444-4.444zM4.444 4.444v8.889h8.889v-8.889h-8.889zM11.111 27.222c1.533 0 2.778-1.244 2.778-2.778s-1.244-2.778-2.778-2.778c-1.533 0-2.778 1.244-2.778 2.778s1.244 2.778 2.778 2.778zM17.778 13.333h8.889v-8.889h-8.889v8.889zM37.778 27.222c1.533 0 2.778-1.244 2.778-2.778s-1.245-2.778-2.778-2.778c-1.533 0-2.778 1.244-2.778 2.778s1.244 2.778 2.778 2.778zM31.111 4.444v8.889h11.111l-8.889-8.889h-2.222z"></path>
    </symbol>
    <symbol id="icon-OCEAN" viewBox="0 0 29 32">
      <path d="M2.74 25.263l-2.667-9.376c-0.126-0.365-0.084-0.758 0.084-1.095s0.477-0.589 0.842-0.702l1.811-0.589v-6.484c0-1.544 1.263-2.807 2.807-2.807h4.211v-4.211h8.421v4.211h4.211c1.544 0 2.807 1.263 2.807 2.807v6.484l1.797 0.589c0.365 0.112 0.674 0.365 0.842 0.702s0.197 0.73 0.084 1.095l-2.653 9.376h-0.070c-2.246 0-4.238-1.235-5.614-2.807-1.375 1.572-3.368 2.807-5.614 2.807s-4.239-1.235-5.614-2.807c-1.375 1.572-3.368 2.807-5.614 2.807h-0.070zM25.266 28.070h2.807v2.807h-2.807c-1.937 0-3.846-0.477-5.614-1.389-3.537 1.811-7.691 1.811-11.228 0-1.768 0.898-3.677 1.389-5.614 1.389h-2.807v-2.807h2.807c1.951 0 3.902-0.66 5.614-1.853 3.425 2.4 7.804 2.4 11.228 0 1.712 1.193 3.663 1.853 5.614 1.853zM22.459 7.018h-16.842v5.572l8.421-2.765 8.421 2.765v-5.572z"></path>
    </symbol>
    <symbol id="icon-LTL" viewBox="0 0 32 32">
      <path d="M22.456 9.825v-5.614h-19.649c-1.544 0-2.807 1.263-2.807 2.807v15.439h2.807c0 2.33 1.881 4.211 4.211 4.211s4.211-1.881 4.211-4.211h8.421c0 2.33 1.881 4.211 4.211 4.211s4.211-1.881 4.211-4.211h2.807v-7.018l-4.211-5.614h-4.211zM5.614 22.456c0-0.772 0.632-1.403 1.404-1.403s1.403 0.632 1.403 1.403c0 0.772-0.632 1.404-1.404 1.404s-1.404-0.632-1.404-1.404zM25.965 11.93l2.751 3.509h-6.26v-3.509h3.509zM22.456 22.456c0-0.772 0.632-1.403 1.403-1.403s1.404 0.632 1.404 1.403c0 0.772-0.632 1.404-1.404 1.404s-1.403-0.632-1.403-1.404zM3.649 8.702c0-0.93 0.754-1.684 1.684-1.684h13.474l-15.158 11.789v-10.105z"></path>
    </symbol>
    <symbol id="icon-ALL" viewBox="0 0 32 32">
      <path d="M0 15.873c0-8.762 7.095-15.873 15.857-15.873 8.778 0 15.889 7.111 15.889 15.873s-7.111 15.873-15.889 15.873c-8.762 0-15.857-7.111-15.857-15.873zM22.175 9.524h4.683c-1.524-2.619-3.952-4.651-6.873-5.651 0.952 1.762 1.683 3.667 2.191 5.651zM15.873 3.238c-1.317 1.905-2.349 4.016-3.032 6.286h6.063c-0.683-2.27-1.714-4.381-3.032-6.286zM3.175 15.873c0 1.095 0.159 2.159 0.413 3.175h5.365c-0.127-1.048-0.222-2.095-0.222-3.175s0.095-2.127 0.222-3.175h-5.365c-0.254 1.016-0.413 2.079-0.413 3.175zM4.889 22.222c1.524 2.635 3.952 4.651 6.873 5.651-0.952-1.762-1.683-3.667-2.19-5.651h-4.683zM4.889 9.524h4.683c0.508-1.984 1.238-3.889 2.19-5.651-2.921 1-5.349 3.016-6.873 5.651zM15.873 28.508c1.317-1.905 2.349-4.016 3.032-6.286h-6.063c0.683 2.27 1.714 4.381 3.032 6.286zM12.159 19.048h7.429c0.143-1.048 0.254-2.095 0.254-3.175s-0.111-2.143-0.254-3.175h-7.429c-0.143 1.032-0.254 2.095-0.254 3.175s0.111 2.127 0.254 3.175zM19.984 27.873c2.921-1 5.349-3.032 6.873-5.651h-4.683c-0.508 1.984-1.238 3.889-2.191 5.651zM23.016 15.873c0 1.079-0.095 2.127-0.222 3.175h5.365c0.254-1.016 0.413-2.079 0.413-3.175s-0.159-2.159-0.413-3.175h-5.365c0.127 1.048 0.222 2.095 0.222 3.175z"></path>
    </symbol>
    <symbol id="icon-AIR" viewBox="0 0 32 32">
      <path d="M31.373 21.961v-3.137l-12.549-7.843v-8.627c0-1.302-1.835-2.353-3.137-2.353s-3.137 1.051-3.137 2.353v8.627l-12.549 7.843v3.137l12.549-3.922v8.628l-3.137 2.353v2.353l6.274-1.569 6.274 1.569v-2.353l-3.137-2.353v-8.628l12.549 3.922z"></path>
    </symbol>
    <symbol id="icon-RAIL" viewBox="0 0 32 32">
      <path d="M15.333 0c6.667 0 13.333 0.833 13.333 6.667v15.833c0 3.217-2.617 5.833-5.833 5.833l2.5 2.5v0.833h-3.333l-3.333-3.333h-6.667l-3.333 3.333h-3.333v-0.833l2.5-2.5c-3.217 0-5.833-2.617-5.833-5.833v-15.833c0-5.833 6.667-6.667 13.333-6.667zM7.833 25c1.383 0 2.5-1.117 2.5-2.5s-1.117-2.5-2.5-2.5c-1.383 0-2.5 1.117-2.5 2.5s1.117 2.5 2.5 2.5zM5.333 13.333h8.333v-6.667h-8.333v6.667zM22.833 25c1.383 0 2.5-1.117 2.5-2.5s-1.117-2.5-2.5-2.5c-1.383 0-2.5 1.117-2.5 2.5s1.117 2.5 2.5 2.5zM17 13.333h8.333v-6.667h-8.333v6.667z"></path>
    </symbol>
    <symbol id="icon-bookmark" viewBox="0 0 25 32">
      <path d="M21.333 0h-17.778c-1.956 0-3.556 1.6-3.556 3.556v28.444l12.444-5.333 12.444 5.333v-28.444c0-1.956-1.6-3.556-3.556-3.556z"></path>
    </symbol>
    <symbol id="icon-close" viewBox="0 0 14 14">
      <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"></path>
    </symbol>
    <symbol id="icon-add-circle" viewBox="0 0 32 32">
      <path d="M0.762 16c0-8.411 6.827-15.238 15.238-15.238s15.238 6.827 15.238 15.238-6.826 15.238-15.238 15.238c-8.411 0-15.238-6.827-15.238-15.238zM17.524 17.524h6.095v-3.048h-6.095v-6.095h-3.048v6.095h-6.095v3.048h6.095v6.095h3.048v-6.095z"></path>
    </symbol>
    <symbol id="icon-warning" viewBox="0 0 37 32">
      <path d="M37.053 32h-37.053l18.526-32 18.526 32zM16.842 26.947h3.368v-3.368h-3.368v3.368zM16.842 20.211h3.368v-6.737h-3.368v6.737z"></path>
    </symbol>
    <symbol id="icon-error" viewBox="0 0 32 32">
      <path d="M16 0c8.832 0 16 7.168 16 16s-7.168 16-16 16c-8.832 0-16-7.168-16-16s7.168-16 16-16zM14.4 24h3.2v-3.2h-3.2v3.2zM14.4 8v9.6h3.2v-9.6h-3.2z"></path>
    </symbol>
    <symbol id="icon-thumb-up" viewBox="0 0 35 32">
      <path d="M32 11.2c1.76 0 3.2 1.44 3.2 3.2l-0.016 0.128 0.016 0.016v3.056c0 0.416-0.080 0.8-0.224 1.168l-4.832 11.28c-0.48 1.152-1.616 1.952-2.944 1.952h-14.4c-1.76 0-3.2-1.44-3.2-3.2v-16c0-0.88 0.352-1.68 0.944-2.256l10.528-10.544 1.696 1.68c0.432 0.432 0.704 1.040 0.704 1.696l-0.048 0.512-1.52 7.312h10.096zM6.4 32h-6.4v-19.2h6.4v19.2z"></path>
    </symbol>
    <symbol id="icon-check-circle" viewBox="0 0 32 32">
      <path d="M0 16c0-8.832 7.168-16 16-16s16 7.168 16 16c0 8.832-7.168 16-16 16s-16-7.168-16-16zM4.8 16l8 8 14.4-14.4-2.256-2.272-12.144 12.144-5.744-5.728-2.256 2.256z"></path>
    </symbol>
    <symbol id="icon-skip-next" viewBox="0 0 30 32">
      <path d="M20.923 16l-20.923 14.769v-29.538l20.923 14.769zM29.538 30.769h-4.923v-29.538h4.923v29.538z"></path>
    </symbol>
    <symbol id="icon-edit" viewBox="0 0 32 32">
      <path d="M30.517 5.384c0.65 0.65 0.65 1.7 0 2.35l-3.050 3.050-6.25-6.25 3.050-3.050c0.65-0.65 1.7-0.65 2.35 0l3.9 3.9zM1 31v-6.25l18.433-18.433 6.25 6.25-18.433 18.433h-6.25z"></path>
    </symbol>
    <symbol id="icon-delete" viewBox="0 0 25 32">
      <path d="M18.667 1.778h6.222v3.556h-24.889v-3.556h6.222l1.778-1.778h8.889l1.778 1.778zM5.333 32c-1.956 0-3.556-1.6-3.556-3.556v-21.333h21.333v21.333c0 1.956-1.6 3.556-3.556 3.556h-14.222z"></path>
    </symbol>
    <symbol id="icon-settings" viewBox="0 0 32 32">
      <path d="M27.111 16c0 0.504-0.044 0.978-0.104 1.452l3.126 2.444c0.281 0.222 0.356 0.622 0.178 0.948l-2.963 5.126c-0.178 0.326-0.563 0.459-0.904 0.326l-3.689-1.481c-0.77 0.578-1.6 1.081-2.504 1.452l-0.563 3.926c-0.044 0.356-0.356 0.622-0.726 0.622h-5.926c-0.37 0-0.681-0.267-0.726-0.622l-0.563-3.926c-0.904-0.37-1.733-0.859-2.504-1.452l-3.689 1.481c-0.326 0.119-0.726 0-0.904-0.326l-2.963-5.126c-0.178-0.326-0.104-0.726 0.178-0.948l3.126-2.444c-0.059-0.474-0.104-0.963-0.104-1.452s0.044-0.978 0.104-1.452l-3.126-2.444c-0.281-0.222-0.37-0.622-0.178-0.948l2.963-5.126c0.178-0.326 0.563-0.459 0.904-0.326l3.689 1.482c0.77-0.578 1.6-1.082 2.504-1.452l0.563-3.926c0.044-0.356 0.356-0.622 0.726-0.622h5.926c0.37 0 0.681 0.267 0.726 0.622l0.563 3.926c0.904 0.37 1.733 0.859 2.504 1.452l3.689-1.482c0.326-0.119 0.726 0 0.904 0.326l2.963 5.126c0.178 0.326 0.104 0.726-0.178 0.948l-3.126 2.444c0.059 0.474 0.104 0.948 0.104 1.452zM10.815 16c0 2.859 2.326 5.185 5.185 5.185s5.185-2.326 5.185-5.185-2.326-5.185-5.185-5.185c-2.859 0-5.185 2.326-5.185 5.185z"></path>
    </symbol>
    <symbol id="icon-chevron-right" viewBox="0 0 32 32">
      <path d="M10.667 0l-3.76 3.76 12.213 12.24-12.213 12.24 3.76 3.76 16-16-16-16z"></path>
    </symbol>
    <symbol id="icon-chevron-left" viewBox="0 0 21 32">
      <path d="M17.573 0l3.76 3.76-12.213 12.24 12.213 12.24-3.76 3.76-16-16 16-16z"></path>
    </symbol>
    <symbol id="icon-duplicate" viewBox="0 0 30 32">
      <path d="M21.926 1.185h-17.778c-1.63 0-2.963 1.333-2.963 2.963v20.741h2.963v-20.741h17.778v-2.963zM20.445 7.111l8.889 8.889v11.852c0 1.63-1.333 2.963-2.963 2.963h-16.311c-1.63 0-2.948-1.333-2.948-2.963l0.015-17.778c0-1.63 1.319-2.963 2.948-2.963h10.37zM27.111 17.482h-8.148v-8.148l8.148 8.148z"></path>
    </symbol>
  </svg>
);

export default SVGIconsLibrary;
