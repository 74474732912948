import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { Button } from 'ui-components';
import { addEditFormConstants } from '../AddEditShipmentConstants';
import * as types from '../ducks/types';
import { withTheme } from '../../../contexts/ThemeContext';

import './AddEditShipmentButtons.scss';

let AddEditShipmentButtons = (props) => {
  const currentStep = get(props.step, 'id');
  const isAdd = props.step.selected; // props.step.selected is false if editing a shipment

  return (
    <div className="add-edit-shipment-actions">
      {currentStep !== addEditFormConstants.LANE_DETAIL.id &&
        isAdd && ( // need margin-right auto
          <Button
            type="secondary"
            clickFn={() => props.navigateStep(types.PREVIOUS_STEP)} //eslint-disable-line react/jsx-no-bind
          >
            <FormattedMessage id="addShipment.addEditShipmentButtons.previous" defaultMessage="Previous" />
          </Button>
        )}
      <Button type="secondary" clickFn={props.cancel}>
        <FormattedMessage id="addShipment.addEditShipmentButtons.cancel" defaultMessage="Cancel" />
      </Button>
      {props.step.id === addEditFormConstants.LANE_DETAIL.id &&
        get(props, 'step.subSteps.currentStep') === addEditFormConstants.LANE_DETAIL.carrierTracking && (
          <div className="spacer" />
        )}
      {props.step.id === types.TEMPERATURE_TRACKING && (
        <Button
          type="secondary"
          clickFn={() => {
            //eslint-disable-line react/jsx-no-bind
            props.skipTempTracking(props.formValues);
            props.navigateStep(types.NEXT_STEP, currentStep, props.values, props.initialValues);
          }}
        >
          <FormattedMessage id="addShipment.addEditShipmentButtons.skip" defaultMessage="Skip" />
        </Button>
      )}
      {props.step.id === types.DROPOFF_DETAIL && isAdd && (
        <Button
          type="secondary"
          clickFn={() => {
            //eslint-disable-line react/jsx-no-bind
            props.navigateStep(types.NEXT_STEP, currentStep, props.values, props.initialValues);
          }}
        >
          <FormattedMessage id="addShipment.addEditShipmentButtons.addStop" defaultMessage="Add Stop" />
        </Button>
      )}
      <Button type="primary" htmlType="submit" style={{ backgroundColor: props.theme.primaryColor }}>
        {isAdd ? (
          <FormattedMessage
            id={addEditFormConstants[props.step.id].buttonLabel?.id}
            defaultMessage={addEditFormConstants[props.step.id].buttonLabel?.defaultMessage}
          />
        ) : (
          'Save'
        )}
      </Button>
    </div>
  );
};

export default withTheme(AddEditShipmentButtons);
