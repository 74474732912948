import { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import cn from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router';
import FilterUtil from 'components/filter/util/filterUtil';
import QuickViewCardTile from '../QuickViewCardTile';
import FilteringCriteriaComponent from '../FilteringCriteriaComponent';
import StepPageComponent from '../StepPageComponent';
import { useIntersectionObserver, useHandleNextStep } from '../hooks';
import {
  QUICKVIEW_SUBTOTAL_FILTERS,
  QUICKVIEW_SUBTOTAL_SEARCHTERM,
  QUICKVIEW_SUBTOTAL_MODE,
  QUICKVIEW_TOTAL_COUNT,
  QUICKVIEW_TOTAL_FILTERS,
  QUICKVIEW_TOTAL_SEARCHTERM,
  QUICKVIEW_TOTAL_MODE,
  QUICKVIEW_SUBTOTAL_COUNT,
  QuickviewEntityType,
} from '../constants';

import * as styles from './styles.module.scss';
import {
  countInventory,
  countOrders,
  countShipments,
  getEntityTypeFromSearch,
  getEntityTypeMessages,
  onKeyDownBlockEnter,
} from '../utils';
import { getStoredNumber } from '../TitleAndSettingsStep/utils';
import { useWizardForm } from '../context/WizardFormContext';

const slideInAnimation = (entry: IntersectionObserverEntry) => {
  const slideInQuickView = document.querySelector('#slide-in-quickview') as HTMLDivElement;
  if (!entry.isIntersecting) {
    slideInQuickView.classList.add(styles['slide-in']);
  } else {
    slideInQuickView.classList.remove(styles['slide-in']);
  }
};

// The props here coming from the container may be updated in the future therefore no types will be added now WIP
const TotalStep = ({
  modeFilterOptions,
  allFilterState,
  searchTerm,
  principal,
  modeFilters,
  clearFilterNoSearch,
  toggleLoadFilter,
  ...props
}: any) => {
  const quickviewCardRef = useRef<HTMLDivElement | null>(null);
  const [subtotalCount, setSubTotalCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>();
  const subTotal = getStoredNumber(QUICKVIEW_SUBTOTAL_COUNT);

  const intl = useIntl();

  const location = useLocation();

  const { state: tileToEdit } = useWizardForm();

  // same convention as SubtotalStep. see similar declaration in SubtotalStep for discussion of rationale
  // however, here, we default to selecting everything by sending the same search string as when you initially
  // load the orders/inventory list pages
  const [orderFilter, setOrderFilter] = useState({ searchText: '' });
  const [inventoryFilter, setInventoryFilter] = useState({ searchText: '' });

  const entityType = getEntityTypeFromSearch(location.search);

  // TODO as a followup we should refactor orders component to store state in Redux, making this unnecessary
  useEffect(() => {
    if (
      entityType === QuickviewEntityType.ORDERS &&
      // putting constant here to avoid a React hooks warning that would require us to add a useMemo() call
      isEqual(orderFilter, { searchText: '' }) &&
      tileToEdit &&
      !isEmpty(tileToEdit.totalOrderFilter)
    ) {
      setOrderFilter(tileToEdit.totalOrderFilter);
    }
  }, [entityType, orderFilter, tileToEdit]);

  useEffect(() => {
    if (
      entityType === QuickviewEntityType.INVENTORY &&
      isEqual(inventoryFilter, { searchText: '' }) &&
      tileToEdit &&
      !isEmpty(tileToEdit.totalInventoryFilter)
    ) {
      setInventoryFilter(tileToEdit.totalInventoryFilter);
    }
  }, [entityType, inventoryFilter, tileToEdit]);

  useIntersectionObserver(
    quickviewCardRef,
    {
      root: null,
      rootMargin: '-75px 0px 0px 0px',
      threshold: [0.3],
      freezeOnceVisible: false,
    },
    slideInAnimation
  );

  const handleNextStep = useHandleNextStep();

  // Get total values from session storage and hydrate redux
  useEffect(() => {
    const totalFilters = window.sessionStorage.getItem(QUICKVIEW_TOTAL_FILTERS);
    const parsedTotalFilters = totalFilters && JSON.parse(totalFilters);
    const persisitedTotalMode = window.sessionStorage.getItem(QUICKVIEW_TOTAL_MODE);
    const totalModeFilters: Array<string> = [];
    persisitedTotalMode && totalModeFilters.push(persisitedTotalMode);
    const updatedState = { filter: parsedTotalFilters, modeFilters: totalModeFilters };
    if (window.sessionStorage.getItem(QUICKVIEW_TOTAL_FILTERS)) {
      toggleLoadFilter(updatedState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subtotalFiltersFromSessionStorage = window.sessionStorage.getItem(QUICKVIEW_SUBTOTAL_FILTERS);
  const subtotalSearchTerm = window.sessionStorage.getItem(QUICKVIEW_SUBTOTAL_SEARCHTERM);
  const subtotalFilters = subtotalFiltersFromSessionStorage && JSON.parse(subtotalFiltersFromSessionStorage);
  const subtotalMode = window.sessionStorage.getItem(QUICKVIEW_SUBTOTAL_MODE);

  // This call will be made once when the page is loaded to get the search count for the subtotal filters
  useEffect(() => {
    if (entityType === QuickviewEntityType.SHIPMENTS) {
      const oceanDemurrageEligibleFilter = get(subtotalFilters, 'oceanDemurrageEligible');
      const oceanDemurrageEligible = FilterUtil.getOceanDemurrageEligibleFilterValue(oceanDemurrageEligibleFilter);
      const config = {
        searchKeywords: subtotalSearchTerm,
        filter: { ...FilterUtil.getValidOrBlank(subtotalFilters), oceanDemurrageEligible },
        mode: subtotalMode,
      };
      countShipments(config, principal, setSubTotalCount);
      // The initial render still has a mode set this is set to make sure we have no mode until a user selects
      modeFilters[0] = undefined;
    } else if (entityType === QuickviewEntityType.ORDERS) {
      const config = {
        ...subtotalFilters,
        searchText: subtotalSearchTerm,
      };
      countOrders(config, setSubTotalCount);
    } else if (entityType === QuickviewEntityType.INVENTORY) {
      const config = {
        ...subtotalFilters,
        searchText: searchTerm,
      };
      countInventory(config, setSubTotalCount);
    } else {
      console.error('Error, invalid entity type', entityType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //This will be triggered for any filter state change and call /search to get the total entity count
  useEffect(() => {
    if (entityType === QuickviewEntityType.SHIPMENTS) {
      const mode = modeFilters[0] === 'ALL' ? undefined : modeFilters[0];
      const oceanDemurrageEligibleFilter = get(allFilterState, 'filter.oceanDemurrageEligible', []);
      const oceanDemurrageEligible = FilterUtil.getOceanDemurrageEligibleFilterValue(oceanDemurrageEligibleFilter);
      const config = {
        searchKeywords: searchTerm,
        filter: { ...FilterUtil.getValidOrBlank(allFilterState?.filter), oceanDemurrageEligible },
        mode: mode,
      };
      if (modeFilters[0] !== undefined) {
        countShipments(config, principal, setTotalCount);
        countShipments(config, principal, setTotalCount);
      }
    } else if (entityType === QuickviewEntityType.ORDERS && !isEqual(orderFilter, {})) {
      const config = {
        ...orderFilter,
        searchText: searchTerm,
      };
      countOrders(config, setTotalCount);
    } else if (entityType === QuickviewEntityType.INVENTORY && !isEqual(inventoryFilter, {})) {
      const config = {
        ...inventoryFilter,
        searchText: searchTerm,
      };
      countInventory(config, setTotalCount);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [
    allFilterState.filters,
    orderFilter,
    inventoryFilter,
    searchTerm,
    modeFilterOptions,
    principal,
    entityType,
    modeFilters,
    allFilterState,
  ]);

  const totalMode = modeFilters[0] !== undefined ? modeFilters[0] : 'ALL';

  const handleSubmit = () => {
    let stringifiedTotalFilters: string = '';

    if (entityType === QuickviewEntityType.SHIPMENTS) {
      stringifiedTotalFilters = JSON.stringify(allFilterState.filter);
    } else if (entityType === QuickviewEntityType.ORDERS) {
      stringifiedTotalFilters = JSON.stringify(orderFilter);
    } else if (entityType === QuickviewEntityType.INVENTORY) {
      stringifiedTotalFilters = JSON.stringify(inventoryFilter);
    } else {
      console.error('Error, invalid entity type', entityType);
    }

    window.sessionStorage.setItem(QUICKVIEW_TOTAL_FILTERS, stringifiedTotalFilters);
    window.sessionStorage.setItem(QUICKVIEW_TOTAL_SEARCHTERM, searchTerm);
    window.sessionStorage.setItem(QUICKVIEW_TOTAL_COUNT, String(totalCount));
    window.sessionStorage.setItem(QUICKVIEW_TOTAL_MODE, totalMode);
    clearFilterNoSearch();
    handleNextStep();
  };

  const initialValues = {
    initialValues: {
      mode: null,
      title: '',
      numberOfResults: intl.formatMessage({
        id: 'quickViewConfig.quickViewCard.count',
        defaultMessage: '# of Results',
      }),
    },
  };

  const entityTypeMessages = getEntityTypeMessages(entityType);

  const history = useHistory();

  useEffect(() => {
    // avoid !subtotal check which would reject 0 values
    if (subTotal === null || subTotal === undefined) {
      history.push(`/configure-quickview/subtotal?entityType=${entityType}`);
    }
  }, [entityType, history, subTotal]);

  return (
    <div className="m-6" id="total-container">
      <StepPageComponent
        step="total"
        title={<FormattedMessage id="quickViewConfig.totalConfiguration.header" defaultMessage="Total Configuration" />}
        description={
          <FormattedMessage
            id={entityTypeMessages.totalStepInstructionMessageId}
            defaultMessage={entityTypeMessages.totalDefaultStepInstructionMessage}
          />
        }
      >
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form onKeyDown={onKeyDownBlockEnter}>
            <div ref={quickviewCardRef} id="main-quickview-card">
              <QuickViewCardTile
                title={tileToEdit.title}
                subTotal={subtotalCount}
                total={totalCount}
                modeFilterOptions={modeFilterOptions}
                modeFilters={modeFilters}
                entityType={entityType}
                className="mb-5"
              />
            </div>
            <FilteringCriteriaComponent
              modeFilterOptions={modeFilterOptions}
              entityType={entityType}
              modeFilters={modeFilters}
              orderFilter={orderFilter}
              setOrderFilter={setOrderFilter}
              inventoryFilter={inventoryFilter}
              setInventoryFilter={setInventoryFilter}
              {...props}
            />
            <div className={cn('mt-5', styles['button-container'])}>
              <Button htmlType="submit" type="primary" className="text-uppercase">
                <FormattedMessage id="quickViewConfig.navigationButton.next" defaultMessage="Next" />
              </Button>
            </div>
            <div id="slide-in-quickview" className={styles['slide-quickview-card-tile']}>
              <QuickViewCardTile
                title={tileToEdit.title}
                subTotal={subtotalCount}
                total={totalCount}
                modeFilterOptions={modeFilterOptions}
                modeFilters={modeFilters}
                entityType={entityType}
                className="mb-5"
              />
            </div>
          </Form>
        </Formik>
      </StepPageComponent>
    </div>
  );
};

export default TotalStep;
