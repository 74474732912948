import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import { defineMessages } from 'react-intl';
import { MODE_FILTERS } from 'common/modeFilters';
import { useEUVerbiage } from 'i18n/configurei18n';
import types from './types';
import { mapShipmentConfig } from './shipmentCountsMapper';
import DateRangeCodes from '../enums/dateRangeCodes';
import FilterUtil from '../../filter/util/filterUtil';

const message = defineMessages({
  allModes: {
    id: 'quickView.reducers.allModes',
    defaultMessage: 'All Modes',
  },
  truckload: {
    id: 'quickView.reducers.truckload',
    defaultMessage: 'Truckload',
  },
  ltlVltl: {
    id: 'quickView.reducers.ltlVltl',
    defaultMessage: 'LTL/VLTL',
  },
  ltlEU: {
    id: 'quickView.reducers.ltlEU',
    defaultMessage: 'Groupage',
  },
  ocean: {
    id: 'quickView.reducers.ocean',
    defaultMessage: 'Ocean',
  },
  parcel: {
    id: 'quickView.reducers.parcel',
    defaultMessage: 'Parcel',
  },
  rail: {
    id: 'quickView.reducers.rail',
    defaultMessage: 'Rail',
  },
  air: {
    id: 'quickView.reducers.air',
    defaultMessage: 'Air',
  },
});

export const initialState = {
  quickViewConfig: {
    layouts: {
      xs: [],
      xxs: [],
    },
    config: [],
    deletedIds: [],
    loading: false,
  },
  tileIndex: 0,
  isDraggable: false,
  editing: false,
  dialogOpen: false,
  snackbarOpen: false,
  mode: '',
  happeningTodayCounts: 0,
  currentTile: {
    width: 2,
    icon: 'genericIcon',
    title: '',
    shipments: 0,
    searchKeywords: '',
    pickupDate: 'ANY',
    deliveryDate: 'ANY',
    filter: {
      // matches filter object in shipment list
      arrivalForecast: [],
      carrier: [],
      company: [],
      createdBy: [],
      attribute: [],
      deliveryDateEnd: null,
      deliveryDateStart: null,
      deliveryStatus: [],
      location: [],
      pickupDateEnd: null,
      pickupDateStart: null,
      status: [],
      temperatureTracking: [],
      happeningCode: [],
    },
  },
  modeOptions: [
    {
      value: MODE_FILTERS.ALL,
      label: message.allModes,
    },
    {
      value: MODE_FILTERS.TRUCKLOAD,
      label: message.truckload,
    },
    {
      value: MODE_FILTERS.LTL,
      label: useEUVerbiage ? message.ltlEU : message.ltlVltl,
    },
    {
      value: MODE_FILTERS.OCEAN,
      label: message.ocean,
    },
    {
      value: MODE_FILTERS.PARCEL,
      label: message.parcel,
    },
    {
      value: MODE_FILTERS.RAIL,
      label: message.rail,
    },
    {
      value: MODE_FILTERS.AIR,
      label: message.air,
    },
  ],
  errors: {
    required: false,
    uniqueRequired: false,
  },
};

const shipmentCountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHIPMENT_COUNTS_GET_SUCCESS:
      return {
        ...state,
        quickViewConfig: get(action, 'quickViewConfig'),
        previousQuickViewConfig: cloneDeep(get(action, 'quickViewConfig')),
        happeningTodayCounts: get(action, 'happeningTodayCounts'),
        happeningTodayCountsLoading: false,
      };
    case types.SHIPMENT_COUNTS_EDIT:
      return {
        ...state,
        editing: true,
        isDraggable: true,
      };
    case types.QUICK_VIEW_OPEN:
      // only do this for shipment filters
      let selectedMode = action.config.mode; //eslint-disable-line no-case-declarations

      if (action.config.filter) {
        if (isNil(action.config.pickupDate)) {
          action.config.pickupDate = DateRangeCodes.ANY;
        }
        if (isNil(action.config.deliveryDate)) {
          action.config.deliveryDate = DateRangeCodes.ANY;
        }
        if (isNil(action.config.lastFreeDate)) {
          action.config.lastFreeDate = DateRangeCodes.ANY;
        }

        if (isNil(action.config.mode) || action.config.mode === MODE_FILTERS.ALL) {
          selectedMode = FilterUtil.modesBasedOnStatusFilters(action.config.filter);
          if (selectedMode.includes(MODE_FILTERS.ALL)) {
            selectedMode = MODE_FILTERS.ALL;
          } else {
            selectedMode = selectedMode[0];
          }
        }
      }

      return {
        ...state,
        dialogOpen: action.isQuickviewV2Enabled ? false : true,
        mode: action.mode,
        currentTile: action.mode === 'Add' ? initialState.currentTile : action.config,
        tileIndex: action.index,
        selectedMode: selectedMode ? selectedMode : MODE_FILTERS.ALL,
        errors: {
          ...state.errors,
          required: false,
          uniqueRequired: false,
        },
      };
    case types.QUICK_VIEW_CANCEL:
      return {
        ...state,
        dialogOpen: false,
        currentTile: initialState.currentTile,
      };
    case types.QUICK_VIEW_CHANGE_ICON:
      return {
        ...state,
        currentTile: {
          ...state.currentTile,
          icon: action.icon,
        },
      };
    case types.QUICK_VIEW_CHANGE_PICKUP_DATE:
      return {
        ...state,
        currentTile: {
          ...state.currentTile,
          pickupDate: action.range,
        },
      };
    case types.QUICK_VIEW_CHANGE_DELIVERY_DATE:
      return {
        ...state,
        currentTile: {
          ...state.currentTile,
          deliveryDate: action.range,
        },
      };
    case types.QUICK_VIEW_CHANGE_LAST_FREE_DATE:
      return {
        ...state,
        currentTile: {
          ...state.currentTile,
          lastFreeDate: action.range,
        },
      };
    case types.QUICK_VIEW_CHANGE_TOTAL_PICKUP_DATE:
      return {
        ...state,
        currentTile: {
          ...state.currentTile,
          totalPickupDate: action.range,
        },
      };
    case types.QUICK_VIEW_CHANGE_TOTAL_DELIVERY_DATE:
      return {
        ...state,
        currentTile: {
          ...state.currentTile,
          totalDeliveryDate: action.range,
        },
      };
    case types.QUICK_VIEW_CHANGE_TOTAL_LAST_FREE_DATE:
      return {
        ...state,
        currentTile: {
          ...state.currentTile,
          totalLastFreeDate: action.range,
        },
      };
    case types.QUICK_VIEW_SAVE_ADD: {
      action.config.filter = action.updatedFilter;
      // Don't save a mode if 'ALL' is selected. That way when this quickview's search is run, it will
      // search for all modes the user has permission for (in this case, that's all of them)
      action.config.mode = action.selectedMode;
      let newAddConfig = [...state.quickViewConfig.config, action.config];
      newAddConfig.deletedIds = state.quickViewConfig.deletedIds;

      return !action.config.title.length || some(state.quickViewConfig.config, ['title', action.config.title])
        ? {
            // check if form errors exist
            ...state,
            errors: {
              ...state.errors,
              required: !action.config.title.length,
              uniqueRequired: some(state.quickViewConfig.config, ['title', action.config.title]),
            },
          }
        : {
            ...state,
            dialogOpen: false,
            quickViewConfig: mapShipmentConfig(newAddConfig, state.quickViewConfig.layouts, true),
            currentTile: initialState.currentTile,
            errors: {
              ...state.errors,
              required: false,
              uniqueRequired: false,
            },
          };
    }
    case types.QUICK_VIEW_SET_LAYOUT:
      return {
        ...state,
        quickViewConfig: {
          ...state.quickViewConfig,
          layouts: action.layouts,
        },
      };
    case types.QUICK_VIEW_SAVE_EDIT: {
      action.config.filter = action.updatedFilter;
      // Don't save a mode if 'ALL' is selected. That way when this quickview's search is run, it will
      // search for all modes the user has permission for (in this case, that's all of them)
      action.config.mode = action.selectedMode;
      let newConfig = [...state.quickViewConfig.config];
      newConfig[state.tileIndex] = action.config;

      return !action.config.title.length
        ? {
            // check if form errors exist
            ...state,
            errors: {
              ...state.errors,
              required: !action.config.title.length,
              uniqueRequired: some(state.quickViewConfig.config, ['title', action.config.title]),
            },
          }
        : {
            ...state,
            dialogOpen: false,
            snackbarOpen: true,
            quickViewConfig: {
              ...state.quickViewConfig,
              config: newConfig,
            },
            errors: {
              ...state.errors,
              required: false,
              uniqueRequired: false,
            },
          };
    }
    case types.QUICK_VIEW_SET_INPUT:
      return {
        ...state,
        currentTile: {
          ...state.currentTile,
          [action.input]: action.value,
        },
        errors: {
          ...state.errors,
          required: action.input === 'title' && !action.value.length,
        },
      };
    case types.QUICK_VIEW_CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
      };
    case types.QUICK_VIEW_SAVE_SUCCESS:
      return {
        ...state,
        snackbarOpen: true,
        editing: false,
        isDraggable: false,
        quickViewConfig: get(action, 'quickViewConfig'),
        previousQuickViewConfig: cloneDeep(get(action, 'quickViewConfig')),
      };
    case types.QUICK_VIEW_DELETE: {
      let deletedId = state.quickViewConfig.config[action.tile].id;

      let newDeleteConfig = [
        ...state.quickViewConfig.config.slice(0, action.tile),
        ...state.quickViewConfig.config.slice(action.tile + 1),
      ];
      newDeleteConfig.deletedIds = state.quickViewConfig.deletedIds;

      if (deletedId) {
        newDeleteConfig.deletedIds = [...newDeleteConfig.deletedIds, deletedId];
      }

      return {
        ...state,
        quickViewConfig: mapShipmentConfig(newDeleteConfig, state.quickViewConfig.layouts),
        dialogOpen: false,
      };
    }
    case types.SHIPMENT_COUNTS_CANCEL_EDIT:
      return {
        ...state,
        editing: false,
        isDraggable: false,
        quickViewConfig: state.previousQuickViewConfig,
      };
    case types.SHIPMENT_COUNTS_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case types.QUICK_VIEW_SET_MODE:
      return {
        ...state,
        selectedMode: action.payload,
      };
    case types.SHIPMENT_COUNTS_LOADING_PENDING:
      return {
        ...state,
        quickViewConfig: {
          ...state.quickViewConfig,
          loading: true,
        },
        happeningTodayCountsLoading: true,
      };
    case types.SHIPMENT_COUNTS_LOADING_SUCCESS:
    case types.SHIPMENT_COUNTS_LOADING_ERROR:
      return {
        ...state,
        quickViewConfig: {
          ...state.quickViewConfig,
          loading: false,
        },
      };
    case types.QUICK_VIEW_DUPLICATE: {
      return {
        ...state,
        quickViewConfig: mapShipmentConfig(action.payload, state.quickViewConfig.layouts, true),
      };
    }
    default:
      return state;
  }
};

export default shipmentCountsReducer;
