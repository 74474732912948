import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import FilterUtil from 'components/filter/util/filterUtil';
import { getPrincipalAuthoritiesFromState, getPrincipalAuthorizationsFromState } from 'common/authorizations';
import { shipmentCountsActions } from './ducks';
import ShipmentCountsComponent from './ShipmentCounts';
import endpoints from '../../common/endpoints';
import { buildApplyFilterFieldAction, setModeFilter, toggleModeFilterSelectOneFn } from '../filter/ducks/actions';
import { buildSetSearchTerm } from '../shipmentListComponent/ducks/searchReducer';
import { setQuickViewMode } from './ducks/actions';
import { QuickviewEntityType } from '../QuickViewCardConfig/constants';

const componentWithFetchedData = compose(
  lifecycle({
    componentDidMount() {
      this.props.getShipmentCounts();
    },
  })
)(ShipmentCountsComponent);

const mapStateToProps = (state) => {
  const authorities = getPrincipalAuthoritiesFromState(state);
  return {
    quickViewConfig: state.shipmentCountsReducer.quickViewConfig,
    isDraggable: state.shipmentCountsReducer.isDraggable,
    editing: state.shipmentCountsReducer.editing,
    dialogOpen: state.shipmentCountsReducer.dialogOpen,
    mode: state.shipmentCountsReducer.mode,
    selectedMode: state.shipmentCountsReducer.selectedMode,
    modeOptions: FilterUtil.getFilterOptionsBasedOnAuthorities(state.shipmentCountsReducer.modeOptions, authorities),
    snackbarOpen: state.shipmentCountsReducer.snackbarOpen,
    currentTile: state.shipmentCountsReducer.currentTile,
    updatedFilter: state.filterReducer.filter,
    tileIndex: state.shipmentCountsReducer.tileIndex,
    errors: state.shipmentCountsReducer.errors,
    happeningTodayCounts: state.shipmentCountsReducer.happeningTodayCounts,
    happeningTodayCountsLoading: state.shipmentCountsReducer.happeningTodayCountsLoading,
    authorities: authorities,
    authorizations: getPrincipalAuthorizationsFromState(state),
    principal: state.authReducer.principal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getShipmentCounts: () => dispatch(shipmentCountsActions.getShipmentCounts()),
  editConfig: () => dispatch(shipmentCountsActions.editConfig()),
  executeWidgetLinkClickFn: (authorities, filter, mode, searchTerm, entityType) => {
    // TODO does this need to handle all entity types?
    if (!entityType || entityType === QuickviewEntityType.SHIPMENTS) {
      dispatch(buildSetSearchTerm(searchTerm));
      dispatch(setModeFilter(mode, filter));
      dispatch(buildApplyFilterFieldAction(filter, authorities, 'quickView'));
      dispatch(push(endpoints.SHIPMENT_LIST));
    }
  },
  cancelEditConfig: () => dispatch(shipmentCountsActions.cancelEditConfig()),
  openQuickViewDialog: (mode, config, isQuickviewV2Enabled, index) => {
    dispatch(shipmentCountsActions.openQuickViewDialog(mode, config, isQuickviewV2Enabled, index));
    if (config) {
      dispatch(setModeFilter(config.mode, config.filter));
      dispatch(buildApplyFilterFieldAction(config.filter));
    }
  },
  cancelQuickViewDialog: () => dispatch(shipmentCountsActions.cancelQuickViewDialog()),
  saveQuickViewDialog: (data, filter, updatedFilter, selectedMode) => {
    dispatch(shipmentCountsActions.saveQuickViewDialog(data, filter, updatedFilter, selectedMode));
  },
  closeSnackbar: () => dispatch(shipmentCountsActions.closeSnackbar()),
  deleteQuickViewTile: (tile) => dispatch(shipmentCountsActions.deleteQuickViewTile(tile)),
  changeSelectedIcon: (icon) => dispatch(shipmentCountsActions.changeSelectedIcon(icon)),
  changeSelectedDate: (type, range) => dispatch(shipmentCountsActions.changeSelectedDate(type, range)),
  setInputValue: (value, input) => dispatch(shipmentCountsActions.setInputValue(value, input)),
  saveUpdatedLayout: (config) => dispatch(shipmentCountsActions.saveUpdatedLayout(config)),
  quickViewDuplicate: (data) => dispatch(shipmentCountsActions.setQuickViewDuplicate(data)),
  setUpdatedLayout: (layout, layouts) => dispatch(shipmentCountsActions.setUpdatedLayout(layout, layouts)),
  setQuickViewMode: (selection) => {
    dispatch(setQuickViewMode(selection));
    dispatch(toggleModeFilterSelectOneFn(selection));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(componentWithFetchedData);
