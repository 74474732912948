import { ReactElement } from 'react';
import { defineMessages } from 'react-intl';
import { UnorderedListOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Layers } from 'ui-components';
import { CarrierNetworkRoles } from 'models/CarrierNetworkRoles';
import endpoints from '../../common/endpoints';
import * as styles from './Settings.module.scss';

const message = defineMessages({
  brandTheming: {
    id: 'settings.settingsRoutes.brandTheming',
    defaultMessage: 'Brand Theming',
  },
  referenceData: {
    id: 'settings.settingsRoutes.referenceData',
    defaultMessage: 'Reference Data Keys',
  },
  entitlementRules: {
    id: 'settings.settingsRoutes.entitlementRules',
    defaultMessage: 'Sharing Rules',
  },
});

interface Route {
  [key: string]: {
    label: { id: string; defaultMessage: string } | undefined;
    route: string;
    icon?: ReactElement<any>;
    rolesWithAccess: CarrierNetworkRoles[];
  };
}

const settingsRoutes: Route = {
  baseRoute: {
    label: undefined,
    route: endpoints.SETTINGS,
    rolesWithAccess: [CarrierNetworkRoles.SHIPPER, CarrierNetworkRoles.UNKNOWN, CarrierNetworkRoles.CARRIER],
  },
  brandTheming: {
    label: message.brandTheming,
    route: `${endpoints.SETTINGS}/brand-theming`,
    icon: <Layers />,
    rolesWithAccess: [CarrierNetworkRoles.SHIPPER, CarrierNetworkRoles.UNKNOWN, CarrierNetworkRoles.CARRIER],
  },
  referenceData: {
    label: message.referenceData,
    route: `${endpoints.SETTINGS}/reference-data`,
    icon: <UnorderedListOutlined className={styles.referenceDataIcon} />,
    rolesWithAccess: [CarrierNetworkRoles.SHIPPER, CarrierNetworkRoles.UNKNOWN],
  },
  entitlementRules: {
    label: message.entitlementRules,
    route: `${endpoints.SETTINGS}/sharing-rules`,
    icon: <ShareAltOutlined className={styles.referenceDataIcon} />,
    rolesWithAccess: [CarrierNetworkRoles.SHIPPER, CarrierNetworkRoles.UNKNOWN],
  },
};

export default settingsRoutes;
