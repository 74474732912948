import { black } from './colors';

// Typography
//
// Font, line-height, and color for body text, headings, and more.
export const fontFamilyBase = 'Roboto, sans-serif !default';

// Roboto font weights :  100,300,400,500,700,900
export const fontWeightLightest = 100;
export const fontWeightLighter = 200;
export const fontWeightLight = 300;
export const fontWeightNormal = 400;
export const fontWeightSemibold = 500;
export const fontWeightSemibolder = 600;
export const fontWeightBold = 700;
export const fontWeightBolder = 900;

// Box shadow for cards
export const boxShadow = `0 1px 2px 0 rgba(${black}, 0.5)`;
