import cn from 'classnames';
import * as styles from './styles.module.scss';

interface HeaderLabelProps {
  children?: React.ReactNode;
}

const HeaderLabel = ({ children }: HeaderLabelProps) => {
  return (
    <label data-testid="field-label" className={cn('mb-3', styles['field-label'])}>
      {children}
    </label>
  );
};

export default HeaderLabel;
