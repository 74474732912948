import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import get from 'lodash/get';
import './AddEditShipmentForm.scss';
import AddEditShipmentButtons from '../AddEditShipmentButtons/AddEditShipmentButtons';
import { addEditFormConstants } from './../AddEditShipmentConstants';
import ContactInfoFormSection from '../../common/forms/ContactInfoFormSection';
import AddressFormSection from '../../common/forms/AddressFormSection';
import ShipmentDateFormSection from './AddEditShipmentDates';
import { validateDateRangeWindows } from '../formValidators';

const renderAddressFormSection = (props) => {
  return (
    <AddressFormSection
      // eslint-disable-next-line react/jsx-boolean-value
      autocomplete={true}
      formName={props.formName}
      contactFormName={`${props.formName}.${props.formName}Contact`}
      // eslint-disable-next-line react/jsx-no-bind
      zipEntered={(zip) => props.zipEntered(props.formName, zip)}
      addressHeader={addEditFormConstants[props.step.id].addressHeader}
      carrierTypes={get(props.initialValues.carrierInfo, 'typeIdentifiers', [])}
    >
      <ShipmentDateFormSection sectionHeader={addEditFormConstants[props.step.id].timeHeader} />
      <div className="shipment-contact-info">
        <div className="toggle-contact-info">
          <h4 className="form-header">
            <FormattedMessage
              id="addShipment.addEditShipmentForm.contactInformation"
              defaultMessage="Contact Information"
            />
            <span className="form-header-help">
              (<FormattedMessage id="addShipment.addEditShipmentForm.optional" defaultMessage="optional" />)
            </span>
          </h4>
        </div>
        <ContactInfoFormSection
          formName={`${props.formName}Contact`}
          carrierTypes={get(props.initialValues.carrierInfo, 'typeIdentifiers', [])}
        />
      </div>
    </AddressFormSection>
  );
};

let AddEditShipmentForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} className="add-edit-shipment-form">
      {renderAddressFormSection(props)}
      <AddEditShipmentButtons
        step={props.step}
        navigateStep={props.navigateStep}
        onSubmit={props.onSubmit}
        cancel={props.cancel}
      />
    </form>
  );
};

AddEditShipmentForm = reduxForm({
  form: 'AddEditShipment',
  destroyOnUnmount: false,
  validate: validateDateRangeWindows,
})(AddEditShipmentForm);

export default AddEditShipmentForm;
