import endpoints from '../../../../common/endpoints';

const namespace = (x) => 'login/' + x;

export const LOGOUT = namespace('LOGOUT');
export const LOGIN_SUCCESS = namespace('LOGIN_SUCCESS');
export const LOGOUT_SUCCESS = namespace('LOGOUT_SUCCESS');
export const LOGOUT_FAILURE = namespace('LOGOUT_FAILURE');
export const SWITCH_ACCOUNTS = namespace('SWITCH_ACCOUNTS');
export const CHECK_SESSION = namespace('CHECK_SESSION');
export const SESSION_RENEW_SUCCESS = namespace('SESSION_RENEW_SUCCESS');
export const SESSION_FAILURE = namespace('SESSION_FAILURE');
export const RENEW_SESSION = namespace('RENEW_SESSION');
export const SET_CUSTOMER_PROPERTIES = namespace('SET_CUSTOMER_PROPERTIES');
export const PURGE = namespace('PURGE_SESSION');

// add public routes here
export const PUBLIC_ROUTES = [
  endpoints.PUBLIC_TRACKING_DETAILS_LEGACY,
  endpoints.PUBLIC_TRACKING_DETAILS,
  endpoints.PUBLIC_MA_TRACKING_DETAILS,
  endpoints.MISSING_SHIPMENT,
  endpoints.CURRENT_VERSION,
  ...Object.values(endpoints.public),
];
