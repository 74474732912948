import get from 'lodash/get';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col, ShipmentModeIcon } from 'ui-components';
import { formatNestedIntlValues } from 'i18n/utils';
import Update from './Update';
import NewDay from './NewDay';
import './Stop.scss';
import ORIGIN from '../../../../common/assets/pickup.svg';
import TERMINAL from '../../../../common/assets/stop.svg';
import DESTINATION from '../../../../common/assets/delivery.svg';

const Stop = (props) => {
  const intl = useIntl();
  const stopIcons = {
    ORIGIN: ORIGIN,
    TERMINAL: TERMINAL,
    DESTINATION: DESTINATION,
  };
  const stopIcon = stopIcons[props.update.stopType];

  return (
    <div className="route-stop-container animated fadeIn">
      <div className="route-stop card-container">
        <Row type="flex" align="middle" className="route-stop__info">
          <Col className="route-stop__mode-icon">
            <ShipmentModeIcon mode={props.mode} />
          </Col>
          <Col className="route-stop__line">
            <img src={stopIcon} alt={props.update.stopType} />
            <span className="label">
              <FormattedMessage
                id={props.update.info.stopLabel.id}
                defaultMessage={props.update.info.stopLabel.defaultMessage}
              />
            </span>
          </Col>
          <Col className="route-stop__stop-info">
            <span className="appointment-info">
              <span
                className="appointment-info__company"
                data-locator={`stop-company-name-${props.update.info.contact.companyName}`}
              >
                {props.update.info.contact.companyName}
              </span>
              {props.update.info.contact.companyName && props.update.info.cityState && (
                <span className="appointment-info__divider">|</span>
              )}
              <span className="appointment-info__location" data-locator={`stop-city-state`}>
                {props.update.info.cityState}
              </span>
            </span>
            {props.update.info.appointmentRange && (
              <div className="appointment-window">
                <div className="appointment-window__label">
                  <FormattedMessage
                    id={props.update.info.appointmentLabel.id}
                    defaultMessage={props.update.info.appointmentLabel.defaultMessage}
                  />
                </div>
                <div className="appointment-window__range">{props.update.info.appointmentRange}</div>
              </div>
            )}
          </Col>
          <Col className="route-stop__additional-info">
            <div className="stop-tertiary-info">
              {get(props, 'statusStop.tertiaryText.id') && (
                <FormattedMessage
                  id={get(props, 'statusStop.tertiaryText.id')}
                  defaultMessage={get(props, 'statusStop.tertiaryText.defaultMessage')}
                  values={formatNestedIntlValues(get(props, 'statusStop.tertiaryText.values', {}), intl)}
                />
              )}
            </div>
            <div className="stop-contact-info">
              <span className="stop-contact-info__name">{props.update.info.contact.contactName}</span>
              {props.update.info.contact.contactName && props.update.info.contact.phoneNumber && (
                <span className="stop-contact-info__divider">|</span>
              )}
              <span className="stop-contact-info__phone">
                <a href={`tel:${props.update.info.contact.phoneNumber}`}>{props.update.info.contact.phoneNumber}</a>
              </span>
              {props.update.info.contact.phoneNumber && props.update.info.contact.email && (
                <span className="stop-contact-info__divider">|</span>
              )}
              <span className="stop-contact-info__email">
                <a href={`mailto:${props.update.info.contact.email}`}>{props.update.info.contact.email}</a>
              </span>
            </div>
          </Col>
        </Row>
        {props.update.updates.length > -1 && (
          <div className="route-stop__updates">
            {props.update.updates.map((update, index) => {
              switch (update.type) {
                case 'INFO':
                  return (
                    <Update
                      key={index} // eslint-disable-line react/no-array-index-key
                      description={update.description}
                      date={update.date}
                      tertiary={update.tertiary}
                      label={update.derivedStatus || update.label}
                      latestStatusUpdate={update.latestStatusUpdate}
                      markerColor={props.markerColor}
                    />
                  );
                case 'NEW_DAY':
                  return (
                    <NewDay
                      key={index} // eslint-disable-line react/no-array-index-key
                      date={update.date}
                    />
                  );
                default:
                  return null;
              }
            })}
          </div>
        )}
      </div>
      {props.update.displayLine && (
        <Row type="flex" className="empty-update">
          <div className="empty-update__spacer" />
          <div className="empty-update__line">
            <div className="vertical-line-dotted" />
            <div className="vertical-line-dotted" />
          </div>
        </Row>
      )}
    </div>
  );
};

export default Stop;
