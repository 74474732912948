import types from './types';

export const getShipmentDetails = (shipmentId, shouldUseRecentSearch) => ({
  type: types.SHIPMENT_DETAIL_GET,
  payload: { shipmentId, shouldUseRecentSearch },
});

export const getShipmentDetailsPublic = (shipmentShareToken) => ({
  type: types.SHIPMENT_DETAIL_GET_PUBLIC,
  shipmentShareToken,
});

export const getMilesData = (shipmentDetails) => ({
  type: types.MILES_DATA_GET,
  payload: { shipmentDetails },
});

export const shipmentDetailsSuccess = (
  shipmentDetails,
  modifiableDetails,
  sensorHistory,
  hereMapsConfig,
  nudgeData,
  rawResponseData,
  mapGeofenceConfig,
  customerProperties
) => ({
  type: types.SHIPMENT_DETAIL_GET_SUCCESS,
  payload: {
    shipmentDetails,
    modifiableDetails,
    sensorHistory,
    hereMapsConfig,
    nudgeData,
    rawResponseData,
    mapGeofenceConfig,
    customerProperties,
  },
});

export const shipmentDetailsFailure = (error) => ({
  type: types.SHIPMENT_DETAIL_GET_FAILURE,
  error,
});

export const getModifiableDetails = (shipmentId) => ({
  type: types.MODIFIABLE_DETAIL_GET,
  payload: { shipmentId },
});

export const setShipmentDetailMilesRemaining = (milesTraveled, milesRemaining) => ({
  type: types.SET_SHIPMENT_DETAIL_MILES_REMAINING,
  milesTraveled,
  milesRemaining,
});

export const deleteShipment = (shipmentId) => ({
  type: types.DELETE_SHIPMENT,
  shipmentId,
});

export const deleteShipmentSuccess = () => ({
  type: types.DELETE_SHIPMENT_SUCCESS,
});

export const resetShipment = () => ({
  type: types.RESET_SHIPMENT,
});

export const openNudgeDialog = (shipmentId) => ({
  type: types.OPEN_NUDGE_DIALOG,
  shipmentId,
});

export const closeNudgeDialog = () => ({
  type: types.CLOSE_NUDGE_DIALOG,
});

export const sendNudge = (shipmentId) => ({
  type: types.SEND_NUDGE,
  shipmentId,
});

export const toggleDeleteDialog = (isDeleteDialogOpen) => ({
  type: types.TOGGLE_DELETE_DIALOG,
  isDeleteDialogOpen,
});

export const closeSnackbar = () => ({
  type: types.CLOSE_SNACKBAR_FOR_DELETE,
});

export const deleteSnackbarSuccess = () => ({
  type: types.OPEN_SNACKBAR_FOR_DELETE,
});
