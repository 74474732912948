import { Field, FormSection } from 'redux-form';
import { injectIntl, defineMessages } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { emailValidation, phoneWithNumberCountryCodeValidation } from '../../../common/FormValidations';
import { renderInput } from './FormInputs';
import './ContactInfoFormSection.scss';
import CarrierType from '../../shipment/common/enums/carrierType';

const messages = defineMessages({
  firstName: {
    id: 'locationDetails.conditionallyRenderFormSection.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'locationDetails.conditionallyRenderFormSection.lastName',
    defaultMessage: 'Last Name',
  },
  phoneNumber: {
    id: 'locationDetails.conditionallyRenderFormSection.phoneNumber',
    defaultMessage: 'Phone Number',
  },
  eMail: {
    id: 'locationDetails.conditionallyRenderFormSection.eMail',
    defaultMessage: 'E-mail',
  },
  enterFirstName: {
    id: 'locationDetails.conditionallyRenderFormSection.enterFirstName',
    defaultMessage: 'Enter First Name',
  },
  enterLastName: {
    id: 'locationDetails.conditionallyRenderFormSection.enterLastName',
    defaultMessage: 'Enter Last Name',
  },
  enterEmailAddress: {
    id: 'locationDetails.conditionallyRenderFormSection.enterEmailAddress',
    defaultMessage: 'Enter Email Address',
  },
  enterPhoneNumber: {
    id: 'locationDetails.conditionallyRenderFormSection.enterPhoneNumber',
    defaultMessage: 'Enter Phone Number',
  },
});

const conditionallyRenderFormSection = (props, contentFn) => {
  if (props.formName) {
    return <FormSection name={props.formName}>{contentFn()}</FormSection>;
  } else {
    return contentFn();
  }
};

const ContactInfoFormSection = (props) => {
  return conditionallyRenderFormSection(props, () => (
    <div className="contact-info">
      <div className="form-row contact-info-row">
        <Field
          type="text"
          label={props.intl.formatMessage(messages.firstName)}
          component={renderInput}
          classes="col-12"
          name="firstName"
          formName={props.formName}
          placeholder="John"
        />
        <Field
          type="text"
          label={props.intl.formatMessage(messages.lastName)}
          classes="col-12"
          component={renderInput}
          name="lastName"
          formName={props.formName}
          placeholder="Smith"
        />
      </div>
      <div className="form-row contact-info-row">
        <Field
          type="tel"
          label={props.intl.formatMessage(messages.phoneNumber)}
          classes="col-12"
          validate={[phoneWithNumberCountryCodeValidation]}
          component={renderInput}
          name="phoneNumber"
          placeholder="+12345678910"
        />
        <Field
          type="email"
          label={props.intl.formatMessage(messages.eMail)}
          classes="col-12"
          validate={[emailValidation]}
          component={renderInput}
          name="email"
          placeholder="john.smith@email.com"
        />
      </div>
    </div>
  ));
};

const EUContactInfoFormSection = (props) => {
  return conditionallyRenderFormSection(props, () => (
    <div className="contact-info">
      <div className="form-row contact-info-row">
        <Field
          type="text"
          label={props.intl.formatMessage(messages.firstName)}
          component={renderInput}
          classes="col-12"
          name="firstName"
          formName={props.formName}
          placeholder={props.intl.formatMessage(messages.enterFirstName)}
        />
        <Field
          type="text"
          label={props.intl.formatMessage(messages.lastName)}
          classes="col-12"
          component={renderInput}
          name="lastName"
          formName={props.formName}
          placeholder={props.intl.formatMessage(messages.enterLastName)}
        />
      </div>
      <div className="form-row contact-info-row">
        <Field
          type="tel"
          label={props.intl.formatMessage(messages.phoneNumber)}
          classes="col-12"
          validate={[phoneWithNumberCountryCodeValidation]}
          component={renderInput}
          name="phoneNumber"
          placeholder={props.intl.formatMessage(messages.enterPhoneNumber)}
        />
        <Field
          type="email"
          label={props.intl.formatMessage(messages.eMail)}
          classes="col-12"
          validate={[emailValidation]}
          component={renderInput}
          name="email"
          placeholder={props.intl.formatMessage(messages.enterEmailAddress)}
        />
      </div>
    </div>
  ));
};

const renderContactInfoFormSection = (props) => {
  if (!isEmpty(props.carrierTypes) && props.carrierTypes[0].type === CarrierType.EU) {
    return EUContactInfoFormSection(props);
  } else {
    return ContactInfoFormSection(props);
  }
};

export default injectIntl(renderContactInfoFormSection);
