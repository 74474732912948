// Used for common form validations

import get from 'lodash/get';
import find from 'lodash/find';
import { defineMessages } from 'react-intl';
import phone from 'phone';
import * as DateUtils from './dateUtils';

const message = defineMessages({
  thisFieldRequired: {
    id: 'notifications.formValidations.thisFieldRequired',
    defaultMessage: 'This field is required',
  },
});

export const requiredValidation = (value) => (value ? undefined : message.thisFieldRequired);
export const emailValidation = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value) ? 'Invalid email address' : undefined;
export const phoneValidation = (value) =>
  value && phoneWithCountryCodeValidation(value, '') // eslint-disable-line no-labels, no-useless-escape
    ? 'Invalid phone number'
    : undefined;
export const phoneWithNumberCountryCodeValidation = (value) =>
  value && !/^[[+]{0,1}]*[[0-9]{10,}]*$/i.test(value) ? 'Invalid phone number' : undefined;

export const phoneWithCountryCodeValidation = (inputValue, countryCode = 'USA') => {
  // Normalize the phone number
  const phoneNumber = phone(inputValue, { country: countryCode }).phoneNumber;

  // phoneNumber will be `undefined` if the countrycode + number is not valid
  return phoneNumber ? undefined : 'Invalid phone number';
};

export const phoneSMSNotificationValidation = (inputValue, formValues) => {
  if (!inputValue) {
    return undefined;
  }
  const notificationRecipients = get(formValues, 'notificationRecipients');
  const countryCode = get(
    find(notificationRecipients, (recipient) => recipient.phoneNumber === inputValue),
    'countryCode'
  );

  return phoneWithCountryCodeValidation(inputValue, countryCode);
};

// redux-form currently does not support an array of messages.  There is an object hack but better to just separate in a single string
// https://github.com/erikras/redux-form/issues/639
const applyMessage = (errorField, message) => (errorField ? errorField + ', ' + message : message);

/* rangeContexts is an array of date range objects with an expected format of
    startDate: date string: yyyy-MM-dd
    startTime: hh:mm
    endDate: date string: yyyy-MM-dd
    endTime: hh:mm
    errors: error context for parent such that if an error occurs can attach errors to errorParent.startDate for example
*/
export const validateAppointmentWindows = (rangeContexts) => {
  let previousRangeErrors,
    previousMomentStart,
    previousMomentEnd = null;

  for (let i = 0; i < rangeContexts.length; ++i) {
    const currentRangeContext = rangeContexts[i];

    const currentMomentStart = DateUtils.convertToMoment(currentRangeContext.startDate, currentRangeContext.startTime);
    const currentMomentEnd = DateUtils.convertToMoment(currentRangeContext.endDate, currentRangeContext.endTime);
    const currentRangeErrors = currentRangeContext.errors;

    if (currentMomentStart.isAfter(currentMomentEnd)) {
      const message = 'start must not be after end';
      currentRangeErrors.startDate = currentRangeErrors.startTime = message;
      currentRangeErrors.endDate = currentRangeErrors.endTime = message;
    }

    if (i > 0) {
      if (currentMomentStart.isSameOrBefore(previousMomentStart)) {
        const currentMessage = 'Current window start must happen after previous start';
        currentRangeErrors.startDate = applyMessage(currentRangeErrors.startDate, currentMessage);
        currentRangeErrors.startTime = applyMessage(currentRangeErrors.startTime, currentMessage);

        const previousMessage = 'Current window start must happen before next start';
        previousRangeErrors.startDate = applyMessage(previousRangeErrors.startDate, previousMessage);
        previousRangeErrors.startTime = applyMessage(previousRangeErrors.startTime, previousMessage);
      }
      if (currentMomentEnd.isSameOrBefore(previousMomentEnd)) {
        const currentMessage = 'Current window end must happen after previous end';
        currentRangeErrors.endDate = applyMessage(currentRangeErrors.endDate, currentMessage);
        currentRangeErrors.endTime = applyMessage(currentRangeErrors.endTime, currentMessage);

        const previousMessage = 'Current window end must happen before next end';
        previousRangeErrors.endDate = applyMessage(previousRangeErrors.endDate, previousMessage);
        previousRangeErrors.endTime = applyMessage(previousRangeErrors.endTime, previousMessage);
      }
    }

    previousMomentStart = currentMomentStart;
    previousMomentEnd = currentMomentEnd;
    previousRangeErrors = currentRangeErrors;
  }
};

export const validateNonEmptyList = (value) => {
  const isValid = value?.flatMap((value) => get(value, 'values', [])).some((entry) => !!entry);
  return isValid ? undefined : message.thisFieldRequired;
};
