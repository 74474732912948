import { PRESET_DATES } from 'components/QuickViewCardConfig/PresetDateSelect/constants';
import { UserDetails } from 'components/QuickViewCardConfig/TitleAndSettingsStep/utils';

export const mapShipmentConfig = (config, layout, recalculate) => {
  const layoutData = {
    lg: recalculate ? setNewGridObject(layout.lg) : setGridObject(config),
    md: recalculate ? setNewGridObject(layout.md) : setGridObject(config),
    sm: recalculate ? setNewGridObject(layout.sm) : setGridObject(config),
    xs: recalculate ? setNewGridObject(layout.xs) : setGridObject(config),
  };

  return {
    config: setTileConfig(config),
    layouts: layout && !recalculate ? layout : layoutData,
    deletedIds: config.deletedIds || [],
  };
};

export const setUserDetailsToShipmentConfig = (config, principalData) => {
  const tenantId = config.tenantId ?? principalData.tenantId;
  const createdById = config.createdById ?? principalData.id;
  const userDetails = new UserDetails(tenantId, createdById);
  return config.map((tile) => ({ ...tile, ...userDetails }));
};

const setGridObject = (config) => {
  return config.map((tile, index) => {
    return {
      i: index.toString(),
      x: tile.xPosition,
      y: tile.yPosition,
      w: tile.width < 2 ? 2 : tile.width,
      h: 1,
      minW: 2,
      maxH: 1,
    };
  });
};

const setNewGridObject = (layout) => {
  const columns = 6;
  let columnCounter = 0;
  let rowCounter = 0;
  let layoutCopy = layout;

  layoutCopy.forEach((tile, index) => {
    columnCounter += tile.w;
    rowCounter = Math.floor(columnCounter / columns);

    if (index === layoutCopy.length - 1) {
      layoutCopy = layoutCopy.concat({
        i: (index + 1).toString(),
        x: 0,
        y: rowCounter,
        w: 2,
        h: 1,
        maxH: 1,
        minW: 2,
        add: true,
      });
    }
  });

  return layoutCopy;
};

const setTileConfig = (config) => {
  return config.map((tile) => {
    // handle order/inventory filters with null `filter`

    const filter = tile.filter
      ? {
          filter: {
            ...tile.filter,
            pickupDateStart: setDateOption(tile.pickupDate, 'start'),
            pickupDateEnd: setDateOption(tile.pickupDate, 'end'),
            deliveryDateStart: setDateOption(tile.deliveryDate, 'start'),
            deliveryDateEnd: setDateOption(tile.deliveryDate, 'end'),
            oceanLastFreeDateStart: setDateOption(tile.lastFreeDate, 'start'),
            oceanLastFreeDateEnd: setDateOption(tile.lastFreeDate, 'end'),
            attribute: tile.filter.attribute || [],
          },
        }
      : {};

    const totalFilter = tile.totalFilter
      ? {
          totalFilter: {
            ...tile.totalFilter,
            pickupDateStart: setDateOption(tile.totalPickupDate, 'start'),
            pickupDateEnd: setDateOption(tile.totalPickupDate, 'end'),
            deliveryDateStart: setDateOption(tile.totalDeliveryDate, 'start'),
            deliveryDateEnd: setDateOption(tile.totalDeliveryDate, 'end'),
            oceanLastFreeDateStart: setDateOption(tile.totalLastFreeDate, 'start'),
            oceanLastFreeDateEnd: setDateOption(tile.totalLastFreeDate, 'end'),
            attribute: tile.totalFilter.attribute || [],
          },
        }
      : {};

    const result = {
      ...tile,
      shipments: tile.shipments || 0,
      searchKeywords: tile.searchKeywords || '',
      ...filter,
      ...totalFilter,
    };

    return result;
  });
};

const setDateOption = (date, type) => {
  const presetRangeDates = PRESET_DATES[date];
  return presetRangeDates ? presetRangeDates[type === 'start' ? 'startDate' : 'endDate'] : null;
};
