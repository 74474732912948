import moment from 'moment';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ShipmentLeg, ShipmentDerivedStatus } from 'models';
import { MONTH_DAY_YEAR_TIME_FORMAT } from 'i18n/configurei18n';
import { browserTimezone, formatUtcTimeInTimeZoneOrDefaultToBrowserZone } from 'common/dateUtils';
import * as styles from './Status.module.scss';
import Stops from './Stops/Stops';
import { getStatusFlagInfo, getDerivedStatusText, getNextStopEta, getStopLocationText } from '../utils';
import StatusColorTag from '../../../components/common/statusColorTag/StatusColorTag';

export interface StatusProps {
  legs: ShipmentLeg[];
  shipmentStatus: ShipmentDerivedStatus;
  currentStatusDateTime: string;
  showDemoCurrentStatusDateTime?: boolean;
  shipmentUpdatedDate?: string;
}

const FormattedDateTimeMessage: React.FunctionComponent<{
  messageId: string;
  defaultMessage: string;
  timeValue: string;
  dataLocator: string;
}> = ({ messageId, defaultMessage, timeValue, dataLocator }) => {
  const timestampInBrowserTimezone = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(
    timeValue,
    browserTimezone()
  )?.format(MONTH_DAY_YEAR_TIME_FORMAT) as string;

  return (
    <span data-locator={dataLocator}>
      <FormattedMessage
        id={messageId}
        defaultMessage={defaultMessage}
        values={{
          timestamp: timestampInBrowserTimezone,
        }}
      >
        {(...text) => <span className={styles.lastUpdatedText}>{text}</span>}
      </FormattedMessage>
    </span>
  );
};

const Status: React.FunctionComponent<StatusProps> = (props) => {
  const { shipmentStatus, legs } = props;
  const intl = useIntl();

  const showStatusTag = () => {
    const { flagText, flagColor, shouldInvertFlagColor } = getStatusFlagInfo(shipmentStatus);

    return (
      !!flagText && (
        <span className={styles.tag}>
          <StatusColorTag messageText={flagText} colorValue={flagColor} inverted={shouldInvertFlagColor} />
        </span>
      )
    );
  };

  const showStatusText = () => {
    const statusText = getDerivedStatusText(intl, shipmentStatus);

    return (
      <span data-locator="status-header">
        {!!statusText && <span className={styles.currentStatusHeader}>{statusText}</span>}
      </span>
    );
  };

  const showCurrentStatusDateTime = () => {
    const demoDateTime = moment().subtract(5, 'minutes').toISOString();
    const currentStatusDateTimeToDisplay = props.showDemoCurrentStatusDateTime
      ? demoDateTime
      : props.currentStatusDateTime;

    if (currentStatusDateTimeToDisplay) {
      return (
        <FormattedDateTimeMessage
          messageId="shipmentDetails.status.lastPositionUpdate"
          defaultMessage="Last position update received {timestamp}"
          timeValue={currentStatusDateTimeToDisplay}
          dataLocator="current-status-date-time"
        />
      );
    }
  };

  const showNextStopEta = () => {
    const nextStopEta = getNextStopEta(legs);
    if (nextStopEta && nextStopEta.earliestEstimatedArrivalDateTime) {
      const stopNameToDisplay = nextStopEta.stopName ? nextStopEta.stopName : getStopLocationText(nextStopEta);
      const dateTime = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(
        nextStopEta.earliestEstimatedArrivalDateTime.dateTimeUtc,
        nextStopEta.earliestEstimatedArrivalDateTime.timezone
      );

      if (stopNameToDisplay && dateTime) {
        return (
          <FormattedMessage
            id="shipmentDetails.status.nextStopEta"
            defaultMessage="ETA to {stopName}: {dateTime}"
            values={{ stopName: stopNameToDisplay, dateTime: dateTime.format(MONTH_DAY_YEAR_TIME_FORMAT) }}
          />
        );
      }
    }
  };

  return (
    <div className={styles.statusHeaderContainer} data-locator="status">
      <div className={styles.statusLineContainer}>
        <div>
          <div className={styles.statusLine}>
            {showStatusTag()}
            {showStatusText()}
          </div>
          <div className={styles.eta}>{showNextStopEta()}</div>
        </div>
        <div className={styles.updateTimestampsContainer}>
          {showCurrentStatusDateTime()}
          {props.shipmentUpdatedDate && (
            <FormattedDateTimeMessage
              messageId="shipmentDetails.status.lastShipmentUpdate"
              defaultMessage="Last shipment update {timestamp}"
              timeValue={props.shipmentUpdatedDate}
              dataLocator="shipment-updated-date"
            />
          )}
        </div>
      </div>
      <Stops legs={props.legs} shipmentStatus={props.shipmentStatus} />
    </div>
  );
};

export default Status;
