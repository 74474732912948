import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import debounce from 'lodash/debounce';
import { RootState } from 'reducers';
import {
  getPrincipalAuthoritiesFromState,
  getPrincipalAuthorizations,
  getPrincipalAuthorizationsFromState,
  getPrincipalFromState,
} from 'common/authorizations';

import { FILTER_RESULT_DELAY } from 'common/AppConstants';

import FilterUtil from 'components/filter/util/filterUtil';

import {
  buildApplyFilterFieldAction,
  updateSuggestions,
  setSuggestions,
  handleCheckboxGroupUpdate,
  handleCheckboxSingleUpdate,
  handleAutocompleteUpdate,
  handleStatusDropdownItemSelect,
  handleStatusDropdownItemRemove,
  toggleShowSelections,
  handleDatePickerChange,
  handleFilterInputs,
  getTenantAttributes,
  toggleModeFilterSelectOneFn,
  clearFiltersNoSearch,
  clearFilters,
  toggleLoadFilter,
} from '../filter/ducks/actions';

import { shipmentCountsActions } from '../shipmentCounts/ducks';
import QuickViewCardConfig from './layout';
import { setQuickViewMode } from '../shipmentCounts/ducks/actions';

const mapStateToProps = (state: RootState) => {
  const { filterReducer } = state;
  const authorities = getPrincipalAuthoritiesFromState(state);
  const authorizations = getPrincipalAuthorizationsFromState(state);
  return {
    quickViewConfig: state.shipmentCountsReducer.quickViewConfig,
    currentTile: state.shipmentCountsReducer.currentTile,
    searchTerm: state.searchReducer.searchTerm,
    sortFields: state.sortResultsReducer.sortFields,
    modeFilterOptions: FilterUtil.getFilterOptionsBasedOnAuthorities(filterReducer.allModeFilterOptions, authorities),
    arrivalForecastFilters: filterReducer.arrivalForecastFilters,
    deliveryStatusFilters: filterReducer.deliveryStatusFilters,
    temperatureTrackingFilters: filterReducer.temperatureTrackingFilters,
    filter: filterReducer.filter,
    customersListData: filterReducer.customersListData,
    locationSuggestions: filterReducer.locationSuggestions,
    portOfLoadingSuggestions: filterReducer.portOfLoadingSuggestions,
    transshipmentPortSuggestions: filterReducer.transshipmentPortSuggestions,
    carrierSuggestions: filterReducer.carrierSuggestions,
    companySuggestions: filterReducer.companySuggestions,
    customerSuggestions: filterReducer.customerSuggestions,
    attributeSuggestions: filterReducer.attributeSuggestions,
    createdBySuggestions: filterReducer.createdBySuggestions,
    showSelections: filterReducer.showSelections,
    filterInputs: filterReducer.filterInputs,
    modeFilters: filterReducer.modeFilters,
    authorities: getPrincipalAuthoritiesFromState(state),
    tenantAttributes: filterReducer.tenantAttributes,
    oceanActiveHoldsFilters: filterReducer.oceanActiveHoldsFilters,
    oceanDemurrageEligibleFilters: filterReducer.oceanDemurrageEligibleFilters,
    authorizations,
    principal: getPrincipalFromState(state),
    allFilterState: filterReducer,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getShipmentCounts: () => dispatch(shipmentCountsActions.getShipmentCounts()),
  handleApplyFilter: (filter: any, authorities: any) => dispatch(buildApplyFilterFieldAction(filter, authorities)),
  handleCheckboxGroupUpdate: (checkboxItem: any, checkboxGroup: any) =>
    dispatch(handleCheckboxGroupUpdate(checkboxItem, checkboxGroup)),
  handleCheckboxSingleUpdate: (checkboxItem: any, checkboxGroup: any) =>
    dispatch(handleCheckboxSingleUpdate(checkboxItem, checkboxGroup)),
  handleAutocompleteUpdate: (filterItem: any, filterItemGroup: any) =>
    dispatch(handleAutocompleteUpdate(filterItem, filterItemGroup)),
  handleStatusDropdownItemSelect: (selectedValues: any) => dispatch(handleStatusDropdownItemSelect(selectedValues)),
  handleStatusDropdownItemRemove: (selectedValues: any) => dispatch(handleStatusDropdownItemRemove(selectedValues)),
  toggleShowSelections: (group: any) => dispatch(toggleShowSelections(group)),
  clearFilters: (searchTerm: any, authorities: any) => {
    // To set modeFilter as authorized mode when only one mode is available
    const authorizedModes = getPrincipalAuthorizations(authorities).getAuthorizedModes();
    let modeFilters = null;
    if (authorizedModes.length === 1) {
      modeFilters = authorizedModes;
    }
    dispatch(clearFilters(authorities, modeFilters));
  },
  handleDatePickerChange: (date: any, dateType: any) => dispatch(handleDatePickerChange(date, dateType)),
  handleFilterInputs: (inputValue: any, filterGroup: any, params: any) => {
    const handleInput = (inputValue: any, filterGroup: any) => {
      dispatch(handleFilterInputs(inputValue, filterGroup));
      dispatch(updateSuggestions(inputValue, filterGroup, params));
    };
    const debounceHandleInput = debounce(handleInput, FILTER_RESULT_DELAY);
    dispatch(handleFilterInputs(inputValue, filterGroup));

    // Reset the suggestions when the input is less than 2
    if (inputValue.length <= 1) {
      dispatch(setSuggestions(filterGroup, []));
    } else {
      // Else dispatch an action like normal
      debounceHandleInput(inputValue, filterGroup);
      // dispatch(updateSuggestions(inputValue, filterGroup));
    }
  },
  getTenantAttributes: () => dispatch(getTenantAttributes()),
  toggleModeFilterSelectOneFn: (mode: any) => {
    dispatch(setQuickViewMode(mode));
    dispatch(clearFiltersNoSearch());
    dispatch(toggleModeFilterSelectOneFn(mode));
  },
  setModeFn: (mode: any) => {
    dispatch(setQuickViewMode(mode));
  },
  toggleLoadFilter: (obj: any) => dispatch(toggleLoadFilter(obj)),
  cancelQuickViewDialog: () => dispatch(shipmentCountsActions.cancelQuickViewDialog()),
  clearFilterNoSearch: () => {
    dispatch(clearFiltersNoSearch());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickViewCardConfig);
