import { APILoader, Circle, Map, Marker, Polygon, Provider, useMapContext } from '@uiw/react-baidu-map';
import get from 'lodash/get';
import { useMemo } from 'react';
import { convertSvgToUrl, mapMarkerSvg } from 'components/shipmentDetails/TL/MapDetail/MapDetailChinaUtils';
import { DistanceUnitsEnum, FenceType, LatLng, LocationDetails } from '../models';

export interface State {
  fenceType: FenceType;
  timeBasedValue: number;
  distanceBasedValue: number;
  polygonClosed: boolean;
  customGeofence: LocationDetails['geofence'];
}

export interface BaiduLocationMapProps {
  latLng: LatLng;
  state: State;
  defaultDistanceUnits: DistanceUnitsEnum;
}

const BaiduLocationMap = ({ latLng, state, defaultDistanceUnits }: BaiduLocationMapProps) => {
  const baiduMapsChinaApiKey = get(window, 'portalGlobal.baiduMapsChinaApiKey') as unknown as string;
  const baiduPoint = { lat: latLng.latitude as number, lng: latLng.longitude as number };
  return (
    <APILoader akay={baiduMapsChinaApiKey}>
      <Provider>
        <Map center={baiduPoint} zoom={12} enableScrollWheelZoom widget={['NavigationControl', 'GeolocationControl']}>
          <BaiduLocationMarker baiduPoint={baiduPoint} state={state} defaultDistanceUnits={defaultDistanceUnits} />
        </Map>
      </Provider>
    </APILoader>
  );
};

export default BaiduLocationMap;

const styleOptions = {
  strokeColor: '#00558b',
  fillColor: '#00558b',
  strokeWeight: 2,
  strokeOpacity: 0.5,
  fillOpacity: 0.6,
  strokeStyle: 'solid',
};

const DISTANCE_MILES_TO_KM = 1609.344;

interface BaiduLocationMarkerProps {
  baiduPoint: { lat: number; lng: number };
  state: State;
  defaultDistanceUnits: DistanceUnitsEnum;
}

const BaiduLocationMarker = ({ baiduPoint, state, defaultDistanceUnits }: BaiduLocationMarkerProps) => {
  const { BMap } = useMapContext();
  const {
    fenceType,
    distanceBasedValue,
    customGeofence: { geometry: { coordinates: [polygonPaths = []] = [] } = {} } = {},
  } = state;

  const baiduCustomPoints = useMemo(
    () => (Array.isArray(polygonPaths) ? polygonPaths.map(([lng, lat]: [number, number]) => ({ lat, lng })) : []),
    [polygonPaths]
  );

  if (!BMap) return null;

  const distanceValue =
    defaultDistanceUnits === DistanceUnitsEnum.MILES ? distanceBasedValue * DISTANCE_MILES_TO_KM : distanceBasedValue;

  return (
    <>
      <Marker position={baiduPoint} icon={new BMap.Icon(convertSvgToUrl(mapMarkerSvg), new BMap.Size(38, 47))} />

      <Circle
        fillOpacity={0.5}
        strokeColor="#00558b"
        fillColor="#00558b"
        visiable={fenceType === 'DISTANCE'}
        strokeWeight={2}
        center={baiduPoint}
        radius={distanceValue}
      />

      {fenceType === 'POLYGON' && <Polygon path={baiduCustomPoints} {...styleOptions} />}
    </>
  );
};
