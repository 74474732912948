import * as React from 'react';
import AntForm, { FormInstance } from 'antd/lib/form';
import get from 'lodash/get';
import { ComponentErrorProps } from 'components/common/errorBoundary/models';

import { InvitationDataModel, CompanyEvergreenModel } from './models';
import { flagDefaults, FlagsType } from './OnboardingFeatureFlags';
import { getVATPrefix } from './mappers';

export enum OnboardingActions {
  SET_ERRORS = 'SET_ERRORS',
  SET_COUNTRY = 'SET_COUNTRY',
  SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN',
  SET_IS_DOT_PREVIOUSLY_DEFINED = 'SET_IS_DOT_PREVIOUSLY_DEFINED',
  SET_INVITATION_DATA = 'SET_INVITATION_DATA',
  SET_EVERGREEN_COMPANY_DATA = 'SET_EVERGREEN_COMPANY_DATA',
  SET_COMPONENT_ERROR = 'SET_COMPONENT_ERROR',
  SET_SUBMIT_LOADING_STATE = 'SET_SUBMIT_LOADING_STATE',
  SET_IS_INVALID_TOKEN = 'SET_IS_INVALID_TOKEN',
  SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS',
  SET_IS_VAT_REQUIRED = 'SET_IS_VAT_REQUIRED',
}

export enum SubmitLoadingState {
  IDLE = 'IDLE',
  IS_LOADING = 'IS_LOADING',
  IS_SENDING = 'IS_SENDING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}

type Action =
  | { type: OnboardingActions.SET_ERRORS; payload: any }
  | { type: OnboardingActions.SET_IS_DOT_PREVIOUSLY_DEFINED; payload: boolean }
  | { type: OnboardingActions.SET_IS_LOGGED_IN; payload: boolean }
  | { type: OnboardingActions.SET_COUNTRY; payload: string }
  | { type: OnboardingActions.SET_COMPONENT_ERROR; payload: ComponentErrorProps }
  | { type: OnboardingActions.SET_INVITATION_DATA; payload: any }
  | { type: OnboardingActions.SET_EVERGREEN_COMPANY_DATA; payload: CompanyEvergreenModel }
  | { type: OnboardingActions.SET_IS_INVALID_TOKEN; payload: boolean }
  | { type: OnboardingActions.SET_FEATURE_FLAGS; payload: FlagsType }
  | { type: OnboardingActions.SET_SUBMIT_LOADING_STATE; payload: SubmitLoadingState }
  | { type: OnboardingActions.SET_IS_VAT_REQUIRED; payload: boolean };

type Dispatch = (action: Action) => void;

export type OnboardingContextProps = {
  errors: any;
  isCountryUS: boolean;
  isLoggedIn: boolean;
  countryCode: string | undefined; // alpha2 country code
  carrierIdentifierPrefix: string | undefined;
  isDOTPreviouslyDefined: boolean;
  componentError: ComponentErrorProps;
  invitationData: InvitationDataModel | undefined;
  companyEvergreenData: CompanyEvergreenModel | undefined;
  form: FormInstance;
  isEuHost: boolean;
  isInvalidToken: boolean;
  submitLoadingState: SubmitLoadingState;
  featureFlags: FlagsType;
  isVATRequired: boolean;
};

function onboardingReducer(state: OnboardingContextProps, action: any) {
  switch (action.type) {
    case OnboardingActions.SET_ERRORS:
      return {
        ...state,
        errors: true,
      };
    case OnboardingActions.SET_COMPONENT_ERROR:
      return {
        ...state,
        componentError: action.payload,
      };
    case OnboardingActions.SET_INVITATION_DATA:
      return {
        ...state,
        invitationData: action.payload,
      };
    case OnboardingActions.SET_EVERGREEN_COMPANY_DATA:
      return {
        ...state,
        companyEvergreenData: action.payload,
      };
    case OnboardingActions.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case OnboardingActions.SET_IS_DOT_PREVIOUSLY_DEFINED:
      return {
        ...state,
        isDOTPreviouslyDefined: action.payload,
      };
    case OnboardingActions.SET_COUNTRY:
      return {
        ...state,
        countryCode: action.payload,
        isCountryUS: action.payload === 'US',
        carrierIdentifierPrefix: state.isEuHost ? getVATPrefix(action.payload)?.prefix : undefined,
      };
    case OnboardingActions.SET_IS_INVALID_TOKEN:
      return {
        ...state,
        isInvalidToken: action.payload,
      };
    case OnboardingActions.SET_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: action.payload,
      };
    case OnboardingActions.SET_SUBMIT_LOADING_STATE:
      return {
        ...state,
        submitLoadingState: action.payload,
      };
    case OnboardingActions.SET_IS_VAT_REQUIRED:
      return {
        ...state,
        isVATRequired: action.payload,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// logic added to support switching flowType when on qa-integration TODO remove when ready for prod (or exclude from prod)
const searchParams = new URLSearchParams(window.location.search);
const isflowTypeEU = searchParams.get('onboardingflowRegion') === 'EU';

export const defaultOnboardingProps: OnboardingContextProps = {
  errors: false,
  isCountryUS: false,
  isLoggedIn: false,
  countryCode: undefined,
  carrierIdentifierPrefix: undefined,
  isDOTPreviouslyDefined: true,
  componentError: { errorsList: [] },
  invitationData: undefined,
  companyEvergreenData: undefined,
  isInvalidToken: false,
  isVATRequired: true,
  form: {} as FormInstance,
  isEuHost: false,
  submitLoadingState: SubmitLoadingState.IDLE,
  featureFlags: flagDefaults,
};

export const OnboardingContext = React.createContext<OnboardingContextProps>(defaultOnboardingProps);
export const OnboardingDispatchContext = React.createContext<Dispatch | undefined>(undefined);

export function OnboardingProvider({
  children,
  overrides,
}: {
  overrides?: { [x: string]: any };
  children: React.ReactNode;
}) {
  const [form] = AntForm.useForm();
  const [state, dispatch] = React.useReducer(onboardingReducer, {
    ...defaultOnboardingProps,
    form,
    isEuHost: isflowTypeEU || (get(window, 'portalGlobal.isEu', false) as unknown as boolean),
    ...(overrides ? overrides : {}),
  });
  return (
    <OnboardingContext.Provider value={state}>
      <OnboardingDispatchContext.Provider value={dispatch}>{children}</OnboardingDispatchContext.Provider>
    </OnboardingContext.Provider>
  );
}
// TODO maybe remove this as is a duplicate of useOnboardingContext
export function useOnboardingState() {
  const context = React.useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error('useOnboardingState must be used within a OnboardingContext.Provider ');
  }
  return context;
}
export function useOnboardingDispatch() {
  const context = React.useContext(OnboardingDispatchContext);
  if (context === undefined) {
    throw new Error('useOnboardingDispatch must be used within a OnboardingDispatchContextProvider');
  }
  return context;
}
export function useOnboardingContext() {
  const context = React.useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error('useOnboardingContext must be used within a OnboardingProvider');
  }
  return context;
}
