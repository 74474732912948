import { messages } from 'components/shipment/common/ltl/enums/ltlStatusMap';
export const statusOptions = {
  DERIVED: {
    SCHEDULED: 'SCHEDULED',
    ATTEMPTING_TO_TRACK: 'ATTEMPTING_TO_TRACK',
    PICKUP_MISSED: 'PICKUP_MISSED',
    INTERLINE_MISSED: 'INTERLINE_MISSED',
    DEPARTURE_MISSED: 'DEPARTURE_MISSED',
    DELIVERY_MISSED: 'DELIVERY_MISSED',
    HELD: 'HELD',
    DELAYED: 'DELAYED',
    AT_PICKUP: 'AT_PICKUP',
    AT_TERMINAL: 'AT_TERMINAL',
    AT_DELIVERY: 'AT_DELIVERY',
    PICKED_UP: 'PICKED_UP',
    IN_TRANSIT: 'IN_TRANSIT',
    OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
    DELIVERED: 'DELIVERED',
    DELIVERED_WITH_EXCEPTIONS: 'DELIVERED_WITH_EXCEPTIONS',
    EXCEPTION: 'EXCEPTION',
    CANCELED: 'CANCELED',
    TIMED_OUT: 'TIMED_OUT',
    UNKNOWN: 'UNKNOWN',
    NOT_RECEIVING_DATA_12_TO_23: 'NOT_RECEIVING_DATA_12_TO_23',
    NOT_RECEIVING_DATA_24_TO_47: 'NOT_RECEIVING_DATA_24_TO_47',
    NOT_RECEIVING_DATA_48_TO_71: 'NOT_RECEIVING_DATA_48_TO_71',
    NOT_RECEIVING_DATA_MORE_THAN_72: 'NOT_RECEIVING_DATA_MORE_THAN_72',
  },
  ORIGINAL: {
    SCHEDULED: 'SCHEDULED',
    INFO: 'INFO',
    COMPLETED: 'COMPLETED',
    IN_TRANSIT: 'IN_TRANSIT',
    OUT_TO_STOP: 'OUT_TO_STOP',
    AT_STOP: 'AT_STOP',
  },
  DISPLAY: {
    SCHEDULED: messages.scheduled,
    ATTEMPTING_TO_TRACK: messages.attemptingToTrack,
    PICKUP_MISSED: messages.pickupMissed,
    INTERLINE_MISSED: messages.interlineMissed,
    DEPARTURE_MISSED: messages.departureMissed,
    DELIVERY_MISSED: messages.deliveryMissed,
    HELD: messages.held,
    DELAYED: messages.delayed,
    AT_PICKUP: messages.atPickup,
    AT_TERMINAL: messages.atTerminal,
    AT_DELIVERY: messages.atDelivery,
    PICKED_UP: messages.pickedUp,
    IN_TRANSIT: messages.inTransit,
    OUT_FOR_DELIVERY: messages.outForDelivery,
    DELIVERED: messages.delivered,
    DELIVERED_WITH_EXCEPTIONS: messages.deliveredWithExceptions,
    EXCEPTION: messages.exception,
    CANCELED: messages.canceled,
    TIMED_OUT: messages.timedOut,
    UNKNOWN: messages.unknown,
    NOT_RECEIVING_DATA_12_TO_23: messages.notReceivingData12To23,
    NOT_RECEIVING_DATA_24_TO_47: messages.notReceivingData24To47,
    NOT_RECEIVING_DATA_48_TO_71: messages.notReceivingData48To71,
    NOT_RECEIVING_DATA_MORE_THAN_72: messages.notReceivingDataMoreThan72,
  },
  STOP_TYPES: {
    ORIGIN: 'ORIGIN',
    TERMINAL: 'TERMINAL',
    DESTINATION: 'DESTINATION',
  },
};
