import qs from 'querystring';
import get from 'lodash/get';
import { AxiosError } from 'axios';
import { useState, useEffect } from 'react';
import paxios from 'util/paxios';

import { API_PATH } from 'common/AppConstants';
import endpoints from 'common/endpoints';

const useAttributeKeys = (parameters: string) => {
  const [defaultKeys, setValues] = useState([]);

  useEffect(() => {
    const defaultParams = { size: 10, page: 1 };
    const params = { ...defaultParams, ...qs.parse(parameters) };

    paxios({
      method: 'GET',
      url: `${API_PATH}${endpoints.TENANT_ATTRIBUTES_KEYS}`,
      withCredentials: true,
      params,
    })
      .then(({ data = [] }) => {
        const attributeKeys = get(data, 'results', []);
        return setValues(attributeKeys);
      })
      .catch((error: AxiosError) => {
        console.error(error);
      });
  }, [parameters]);

  return defaultKeys;
};

export default useAttributeKeys;
