import { FormattedMessage } from 'react-intl';
import { P44LinkProps, LocationsManagementMenuItems } from './interface';
import { trackEvent } from '../../../../../common/eventTracker';
import endpoints from '../../../../../common/endpoints';
import { LocationsEnum, LocationsManagementEnum, MindlogicEnum, MindlogicStarbucksEnum } from './enums';

const locationsLinkBaseConfig = {
  urlPath: endpoints.LOCATION_LIST,
  locator: 'navigation-locations',
  trackEvents: {
    onClick: LocationsEnum.NAV_LOCATIONS_CLICK,
  },
  intlConfig: {
    id: 'header.mainMenu.locations',
    defaultMessage: 'Locations',
  },
};

const mindlogicLinkBaseConfig = {
  urlPath: endpoints.MINDLOGIC_APP,
  locator: 'navigation-mindlogic',
  trackEvents: {
    onClick: MindlogicEnum.NAV_MINDLOGIC_CLICK,
  },
  intlConfig: {
    id: 'header.mainMenu.locationsManagement.mindlogic',
    defaultMessage: 'Yard Visibility',
  },
};

export const mindlogicStarbucksLinkBaseConfig = {
  urlPath: endpoints.MINDLOGIC_STARBUCKS_APP,
  locator: 'navigation-mindlogic-starbucks',
  trackEvents: {
    onClick: MindlogicStarbucksEnum.NAV_MINDLOGIC_STARBUCKS_CLICK,
  },
  intlConfig: {
    id: 'header.mainMenu.locationsManagement.mindlogicStarbucks',
    defaultMessage: 'Yard Visibility',
  },
};

export const locationsManagementBaseConfig = {
  locator: 'navigation-management-dropdown',
  trackEvents: {
    onClick: LocationsManagementEnum.NAV_LOCATIONS_MANAGEMENT_CLICK,
    onHover: LocationsManagementEnum.NAV_LOCATIONS_MANAGEMENT_HOVER,
  },
  intlConfig: {
    id: 'header.mainMenu.locationsManagement.dropdownTitle',
    defaultMessage: 'Location Management',
  },
};

export const locationsSmallScreenMenuItemProps: P44LinkProps = {
  to: locationsLinkBaseConfig.urlPath,
  'data-locator': locationsLinkBaseConfig.locator,
  children: (
    <FormattedMessage
      id={locationsLinkBaseConfig.intlConfig.id}
      defaultMessage={locationsLinkBaseConfig.intlConfig.defaultMessage}
    />
  ),
};

export const mindlogicSmallScreenMenuItemProps: P44LinkProps = {
  to: mindlogicLinkBaseConfig.urlPath,
  'data-locator': mindlogicLinkBaseConfig.locator,
  children: (
    <FormattedMessage
      id={mindlogicLinkBaseConfig.intlConfig.id}
      defaultMessage={mindlogicLinkBaseConfig.intlConfig.defaultMessage}
    />
  ),
};

export const mindlogicStarbucksSmallScreenMenuItemProps: P44LinkProps = {
  to: mindlogicStarbucksLinkBaseConfig.urlPath,
  'data-locator': mindlogicStarbucksLinkBaseConfig.locator,
  children: (
    <FormattedMessage
      id={mindlogicStarbucksLinkBaseConfig.intlConfig.id}
      defaultMessage={mindlogicStarbucksLinkBaseConfig.intlConfig.defaultMessage}
    />
  ),
};

export const locationsManagementMenuItems: LocationsManagementMenuItems = ({
  intl,
  isLocationsEnabled,
  isMindlogicEnabled,
  isMindlogicStarbucksEnabled,
}) => {
  return [
    {
      path: locationsLinkBaseConfig.urlPath,
      key: 'locations',
      message: intl.formatMessage({
        id: locationsLinkBaseConfig.intlConfig.id,
        defaultMessage: locationsLinkBaseConfig.intlConfig.defaultMessage,
      }),
      enabled: isLocationsEnabled,
      callback: () => {
        trackEvent(locationsLinkBaseConfig.trackEvents.onClick);
      },
    },
    {
      path: mindlogicLinkBaseConfig.urlPath as string,
      target: '_blank',
      badge: intl.formatMessage({
        // Reuse existing ocean badge for 'NEW' to keep translations under one id
        id: 'global.navigation.planning.submenu.ocean.css.badge',
        defaultMessage: 'NEW',
      }),
      key: 'yard-visibility',
      message: intl.formatMessage({
        id: mindlogicLinkBaseConfig.intlConfig.id,
        defaultMessage: mindlogicLinkBaseConfig.intlConfig.defaultMessage,
      }),
      enabled: isMindlogicEnabled,
      callback: () => {
        trackEvent(mindlogicLinkBaseConfig.trackEvents.onClick);
      },
    },
    {
      path: mindlogicStarbucksLinkBaseConfig.urlPath as string,
      target: '_blank',
      badge: intl.formatMessage({
        // Reuse existing ocean badge for 'NEW' to keep translations under one id
        id: 'global.navigation.planning.submenu.ocean.css.badge',
        defaultMessage: 'NEW',
      }),
      key: 'yard-visibility-starbucks',
      message: intl.formatMessage({
        id: mindlogicStarbucksLinkBaseConfig.intlConfig.id,
        defaultMessage: mindlogicStarbucksLinkBaseConfig.intlConfig.defaultMessage,
      }),
      enabled: isMindlogicStarbucksEnabled,
      callback: () => {
        trackEvent(mindlogicStarbucksLinkBaseConfig.trackEvents.onClick);
      },
    },
  ];
};
