import cn from 'classnames';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import { SVGIcon } from 'ui-components';

import { useHandleNextStep } from '../hooks';

import { clearTotalSessionStorage } from '../utils';

import * as styles from './styles.module.scss';

type Title = string | JSX.Element;
interface StepPageComponentProps {
  children: React.ReactNode;
  title: Title;
  description: Title;
  step?: string;
}

const StepPageComponent = ({ children, title, description, step }: StepPageComponentProps) => {
  const handleNextStep = useHandleNextStep();

  const onHandleNextStep = () => {
    clearTotalSessionStorage();
    handleNextStep();
  };

  return (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <div className={cn('d-flex flex-column align-items-stretch pt-5 pb-24', styles['container'])}>
        <div className="mb-5">
          {step && step === 'total' && (
            <div className={'d-flex flex-row justify-content-between'}>
              <h2 className={cn('mb-4', styles['title'])}>{title}</h2>
              <div className={styles['icon-skip-next-wrapper']}>
                <Button
                  onClick={onHandleNextStep}
                  type="text"
                  className={cn(styles['skip-button'], 'text-uppercase d-flex align-items-center')}
                >
                  <span>
                    <FormattedMessage id="quickViewConfig.navigationButton.skip" defaultMessage="SKIP" />
                  </span>
                  <SVGIcon className={cn(styles['svg-icon-skip'], 'ml-3')} name="skip-next" />
                </Button>
              </div>
            </div>
          )}
          {step !== 'total' && <h2 className={cn('mb-4', styles['title'])}>{title}</h2>}
          <p>{description}</p>
        </div>
        {children}
      </div>
    </div>
  );
};

export default StepPageComponent;
