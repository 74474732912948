/* eslint-disable @typescript-eslint/no-namespace */
type ListFormatOptions = {
  // reference MDN for what these mean: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat
  type?: 'conjunction' | 'disjunction' | 'unit';
  style?: 'long' | 'short' | 'narrow';
  localeMatcher?: 'lookup' | 'best fit';
};
declare namespace Intl {
  class ListFormat {
    constructor(locale: string, options: ListFormatOptions);
    public format: (items: Array<string>) => string;
  }
}
type ListifyOptions<ItemType> = {
  type?: ListFormatOptions['type'];
  style?: ListFormatOptions['style'];
  stringify?: (item: ItemType) => string;
};

export function listify<ItemType extends object | string>(
  array: Array<ItemType>,
  { type = 'conjunction', style = 'long', stringify = (thing) => thing.toString() }: ListifyOptions<ItemType> = {}
) {
  const stringified = array.map((item) => stringify(item));
  const formatter = new Intl.ListFormat('en', { style, type });
  return formatter.format(stringified);
}
