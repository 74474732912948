import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Field, FieldProps, Form, useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'antd';
import cn from 'classnames';
import { useHistory } from 'react-router';

import { Input, Radio } from 'ui-components';

import { QUICKVIEW_SUBTOTAL_COUNT, QUICKVIEW_SUBTOTAL_MODE, QUICKVIEW_TOTAL_COUNT } from '../constants';
import QuickViewCardTile from '../QuickViewCardTile';
import { HealthRanges as HealthRangesType, ModeFilterOptions, TitleAndSettingsForm, TNumberFormat } from '../types';
import HealthRanges from '../HealthScoreConfig';
import * as styles from './styles.module.scss';
import { getHealthRange, getStoredNumber } from './utils';
import { getEntityTypeFromSearch, getEntityTypeMessages, onKeyDownBlockEnter } from '../utils';

const HealthScoreConfigField = ({ field, form }: FieldProps) => {
  const handleChange = (isEnabled: boolean, ranges: HealthRangesType, numberFormat: TNumberFormat) => {
    form.setFieldValue(field.name, { isEnabled, ranges, numberFormat });
  };
  return <HealthRanges value={field.value} onChange={handleChange} />;
};

interface TitleAndSettingsLayoutProps {
  quickviewCardRef: React.RefObject<HTMLDivElement>;
  modeFilterOptions: ModeFilterOptions;
}

const TitleAndSettingsLayout = ({ quickviewCardRef, modeFilterOptions }: TitleAndSettingsLayoutProps) => {
  const subTotal = getStoredNumber(QUICKVIEW_SUBTOTAL_COUNT);
  const total = getStoredNumber(QUICKVIEW_TOTAL_COUNT);
  const subTotalMode = sessionStorage.getItem(QUICKVIEW_SUBTOTAL_MODE);
  const labelClassName = cn('text-uppercase mb-3', styles['field-label']);
  const { values, isValid, setFieldValue } = useFormikContext<TitleAndSettingsForm>();
  const { title, healthScoreConfig } = values;
  const intl = useIntl();
  const history = useHistory();
  const isPercentageDisabled = !total;

  const location = useLocation();

  const entityType = getEntityTypeFromSearch(location.search);
  const goToTotalStep = () => history.push(`/configure-quickview/total?entityType=${entityType}`);

  const quickViewCardTile = (
    <QuickViewCardTile
      title={title}
      subTotal={subTotal}
      total={total}
      modeFilterOptions={modeFilterOptions}
      modeFilters={[subTotalMode]}
      className="mb-5"
      entityType={entityType}
      healthRange={getHealthRange(healthScoreConfig, subTotal, total)}
      numberFormat={values.tileNumberFormat}
    />
  );

  const entityTypeMessages = getEntityTypeMessages(entityType);

  useEffect(() => {
    if (isPercentageDisabled) {
      setFieldValue('tileNumberFormat', 'COUNT');
    }
  }, [isPercentageDisabled, setFieldValue]);

  useEffect(() => {
    // avoid !subtotal check which would reject 0 values
    if (subTotal === null || subTotal === undefined) {
      history.push(`/configure-quickview/subtotal?entityType=${entityType}`);
    }
  }, [entityType, history, subTotal]);

  const validateTitle = (value: string) => {
    let error;
    if (!value) {
      error = 'Required!';
    }
    return error;
  };

  const gotoButton = (chunks: string) => (
    <button className={cn(styles['btn-link-step'])} onClick={goToTotalStep}>
      {chunks}
    </button>
  );

  return (
    <Form onKeyDown={onKeyDownBlockEnter}>
      <div ref={quickviewCardRef} id="main-quickview-card">
        {quickViewCardTile}
      </div>
      <div className="mb-5">
        <label htmlFor="quickview-title" className={labelClassName}>
          {<FormattedMessage id="quickViewConfig.label.title" defaultMessage="Title" />}
        </label>
        <Field
          id="quickview-title"
          name="title"
          placeholder={intl.formatMessage({
            id: entityTypeMessages.placeholderMessageId,
            defaultMessage: entityTypeMessages.placeholderDefaultMessage,
          })}
          isv2="true"
          as={Input}
          validate={validateTitle}
        />
      </div>
      <div className="mb-5">
        <label htmlFor="quickview-number-format" className={labelClassName}>
          {<FormattedMessage id="quickViewConfig.label.numberFormat" defaultMessage="Number Format" />}
        </label>
        {isPercentageDisabled ? (
          <p className={cn('mb-4')}>
            <FormattedMessage
              id="quickViewConfig.percentageDisabled.displayQuickViewCountPercentage"
              defaultMessage="To display the Quick View count as a percentage, complete <button>step 2</button>."
              values={{
                button: gotoButton,
              }}
            />
          </p>
        ) : (
          <p>
            <FormattedMessage
              id="quickViewConfig.description.numberFormat"
              defaultMessage="Choose the format of the Quick View’s subtotal."
            />
          </p>
        )}
        <Field
          id="quickview-number-format"
          name="tileNumberFormat"
          radioData={[
            {
              value: 'COUNT',
              label: intl.formatMessage({ id: 'quickViewConfig.numberFormat.count', defaultMessage: 'Count' }),
            },
            {
              value: 'PERCENTAGE',
              label: intl.formatMessage({
                id: 'quickViewConfig.numberFormat.percentage',
                defaultMessage: 'Percentage',
              }),
            },
          ]}
          as={Radio}
          disabled={isPercentageDisabled}
        />
      </div>
      <Field name="healthScoreConfig" component={HealthScoreConfigField} />
      <div className="d-flex align-items-center justify-content-end">
        {!isValid && (
          <span className="mr-4">
            <FormattedMessage
              id="quickViewConfig.description.submitDisabled"
              defaultMessage="Name for your Quick View to continue."
            />
          </span>
        )}
        <Button className="text-uppercase" type="primary" htmlType="submit" disabled={!isValid}>
          <FormattedMessage id="quickViewConfig.navigationButton.saveAndFinish" defaultMessage="Save & Finish" />
        </Button>
      </div>
      <div id="slide-in-quickview" className={styles['slide-quickview-card-tile']}>
        {quickViewCardTile}
      </div>
    </Form>
  );
};

export default TitleAndSettingsLayout;
