import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { GroupChannelType } from 'sendbird-uikit';
import styled from 'styled-components';
import { primaryGreyTwenty } from 'styles/colors';
const StyledRow = styled(Row)`
  font-size: 2rem;
`;

const StyledContainer = styled(Container)`
  background-color: ${primaryGreyTwenty};
`;

interface ConversationHeaderProps {
  channel?: GroupChannelType;
  setIsChatMinimized: (arg0: boolean) => void;
  isChatMinimized: boolean;
}

const ConversationHeader: React.FC<ConversationHeaderProps> = ({ channel, isChatMinimized, setIsChatMinimized }) => {
  return (
    <StyledContainer className="py-3" onClick={() => setIsChatMinimized(!isChatMinimized)}>
      <StyledRow>
        <Col sm={24}>{channel?.name || 'Open Chat'}</Col>
      </StyledRow>
    </StyledContainer>
  );
};

export default ConversationHeader;
