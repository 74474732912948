import qs from 'querystring';
import { useState } from 'react';
import styled from 'styled-components';
import { RadioChangeEvent } from 'antd/lib/radio';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import { Radio, Autosuggest } from 'ui-components';

import useDebounce from 'hooks/useDebounce';
import useSuggestions from 'components/filter/util/useSuggestions';
import { FILTER_RESULT_DELAY } from 'common/AppConstants';
import { SUGGESTIONS_MAP } from 'components/filter/ducks/types';
import { primaryGreyFive } from 'styles/colors';
import { messages } from '../../utils';

const eventRadioOptions = [
  {
    label: 'City/State',
    value: 'location',
  },
  {
    label: 'Country',
    value: 'country',
  },
];

const placeholderMessages = defineMessages({
  countryCode: {
    id: 'filter.location.country',
    defaultMessage: 'Try the country code of a stop location',
  },
  cityState: {
    id: 'filter.location.cityState',
    defaultMessage: 'Try the city/state of a stop location',
  },
});

const StyledLabel = styled('label')`
  font-size: 13px;
  line-height: 13px;
`;

const StyledSidebar = styled('div')`
  background-color: ${primaryGreyFive};
  min-width: 200px;
`;

const StyledRadio = styled(Radio)`
  .ant-radio-wrapper {
    margin: 0;
  }
`;

type LocationType = keyof typeof SUGGESTIONS_MAP;

interface CountryCodeFilterProps {
  filter: Array<string>;
  handleAutocompleteUpdate: (value: string, name: string) => void;
}

const CountryCodeFilter = (props: CountryCodeFilterProps) => {
  const intl = useIntl();
  const [locationType, setLocationType] = useState<LocationType>('location');
  const [currentValue, setCurrentValue] = useState('');

  const debouncedValue = useDebounce(currentValue, FILTER_RESULT_DELAY);
  const modes = get(props, 'modeFilters', []);
  const isAllMode = isEqual(head(modes), 'ALL');
  const params = qs.stringify(isAllMode || isEmpty(modes) ? { q: debouncedValue } : { modes, q: debouncedValue });
  //@ts-ignore
  const resourceName = SUGGESTIONS_MAP[locationType];
  const suggestions = useSuggestions(resourceName, params, { resetStateOnFetch: true });
  const listData = get(props.filter, locationType, []);
  const placeholderMsgKey = locationType === 'country' ? 'countryCode' : 'cityState';

  const handleChangeLocationType = (event: RadioChangeEvent) => {
    setLocationType(event.target.value);
    setCurrentValue('');
  };

  return (
    <div className="d-flex">
      <StyledSidebar className="d-flex flex-column p-5">
        <StyledLabel htmlFor="event-type" className="text-uppercase font-weight-bold mb-4">
          <FormattedMessage {...messages.locationType} />
        </StyledLabel>
        <StyledRadio
          onChange={handleChangeLocationType}
          value={locationType}
          layout="column"
          radioData={eventRadioOptions}
        />
      </StyledSidebar>
      <div className="px-5 pt-5 pb-4 flex-1">
        <Autosuggest
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
          placeholder={intl.formatMessage(placeholderMessages[placeholderMsgKey])}
          suggestions={suggestions}
          listData={listData}
          handleAutocompleteUpdate={props.handleAutocompleteUpdate}
          name={locationType}
        />
      </div>
    </div>
  );
};

export default CountryCodeFilter;
