import moment from 'moment-timezone';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker as AntDatePicker } from 'antd';
import isNil from 'lodash/isNil';
import range from 'lodash/range';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { HOURS_MINUTES_FORMAT, MONTH_DAY_YEAR_FORMAT, MONTH_DAY_YEAR_TIME_FORMAT } from 'i18n/configurei18n';

const StyledDateRange = styled('div')(
  (props) => `
  width: 100%;
  flex-direction: ${props.stacked ? 'column' : 'row'}
  .ant-picker-suffix {
    color: rgba(0, 0, 0, 0.6);
  }
  .ant-picker-suffix i {
    display: flex;
    align-self: center;
  }

  .ant-picker-clear {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
  }
`
);

export default class DateRange extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    startLabel: PropTypes.string,
    endLabel: PropTypes.string,
    format: PropTypes.string,
    boundStartDateValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    boundEndDateValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    datepickerStartChangeFn: PropTypes.func,
    datepickerEndChangeFn: PropTypes.func,
    showTime: PropTypes.bool,
    stacked: PropTypes.bool,
    timezone: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      endOpen: false,
    };
  }

  disabledStartDate = (startValue) => {
    const endValue = this.formatDateValue(this.props.boundEndDateValue);
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.clone().startOf('day').valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const startValue = this.formatDateValue(this.props.boundStartDateValue);
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.clone().endOf('day').valueOf() <= startValue.valueOf();
  };

  disabledStartTime = () => {
    const startValue = this.formatDateValue(this.props.boundStartDateValue);
    const endValue = this.formatDateValue(this.props.boundEndDateValue);

    let disabledHours = () => [];
    let disabledMinutes = () => [];
    let disabledSeconds = () => [];

    if (!endValue || !startValue || !startValue.isSame(endValue, 'day')) {
      return {
        disabledHours,
        disabledMinutes,
        disabledSeconds,
      };
    }

    disabledHours = () => range(endValue.minute() === 0 ? endValue.hour() : endValue.hour() + 1, 24);
    disabledMinutes = (hour) => (hour === endValue.hour() ? range(endValue.minute(), 60) : []);

    return {
      disabledHours,
      disabledMinutes,
      disabledSeconds,
    };
  };

  disabledEndTime = () => {
    const startValue = this.formatDateValue(this.props.boundStartDateValue);
    const endValue = this.formatDateValue(this.props.boundEndDateValue);

    let disabledHours = () => [];
    let disabledMinutes = () => [];
    let disabledSeconds = () => [];

    if (!endValue || !startValue || !startValue.isSame(endValue, 'day')) {
      return {
        disabledHours,
        disabledMinutes,
        disabledSeconds,
      };
    }

    disabledHours = () => range(0, startValue.minute() === 59 ? startValue.hour() + 1 : startValue.hour());
    disabledMinutes = (hour) => (hour === startValue.hour() ? range(0, startValue.minute() + 1) : []);

    return {
      disabledHours,
      disabledMinutes,
      disabledSeconds,
    };
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  formatDateValue = (date) => {
    if (typeof date === 'string' || moment.isMoment(date)) {
      return moment.tz(date, this.props.timezone ? this.props.timezone : moment.tz.guess());
    } else {
      return null;
    }
  };

  render() {
    const {
      startLabel,
      endLabel,
      datepickerStartChangeFn,
      datepickerEndChangeFn,
      boundStartDateValue,
      boundEndDateValue,
      stacked = false,
      showTime = false,
      id = '',
    } = this.props;

    const format = showTime ? MONTH_DAY_YEAR_TIME_FORMAT : MONTH_DAY_YEAR_FORMAT;
    const placeholder = showTime ? MONTH_DAY_YEAR_TIME_FORMAT : MONTH_DAY_YEAR_FORMAT;
    const use12hours = HOURS_MINUTES_FORMAT.toLowerCase().includes('a');

    const startValueBound = this.formatDateValue(boundStartDateValue);
    const endValueBound = this.formatDateValue(boundEndDateValue);

    const calendarIcon = <i className="material-icons">calendar_today</i>;

    return (
      <StyledDateRange className="date-range flex" stacked={stacked}>
        <div className="ant-form-vertical">
          {startLabel ? (
            <div className="ant-form-item-label">
              <label title={startLabel}>{startLabel}</label>
            </div>
          ) : (
            <label className="sr-only" htmlFor={`${id}-start`}>
              {<FormattedMessage id="labels.startDateRange" defaultMessage="Start Date Range" />}
            </label>
          )}
          <AntDatePicker
            id={`${id}-start`}
            placeholder={placeholder}
            popupStyle={{ zIndex: 9999 }}
            style={stacked ? { width: '100%' } : { marginRight: 5 }}
            disabledDate={this.disabledStartDate}
            disabledTime={this.disabledStartTime}
            format={format}
            value={startValueBound}
            showTime={
              !showTime
                ? undefined
                : { format: 'HH:mm a', use12Hours: use12hours, defaultValue: moment().startOf('day') }
            }
            onChange={(value) => {
              if (datepickerStartChangeFn) {
                if (isNil(value)) {
                  datepickerStartChangeFn(undefined);
                } else if (showTime) {
                  datepickerStartChangeFn(value.toISOString());
                } else {
                  datepickerStartChangeFn(value.clone().startOf('day').toISOString());
                }
              }
            }}
            onOpenChange={this.handleStartOpenChange}
            suffixIcon={calendarIcon}
          />
        </div>
        <div className="ant-form-vertical">
          {endLabel ? (
            <div className="ant-form-item-label">
              <label title={endLabel}>{endLabel}</label>
            </div>
          ) : (
            <label className="sr-only" htmlFor={`${id}-end`}>
              {<FormattedMessage id="labels.endDateRange" defaultMessage="End Date Range" />}
            </label>
          )}
          <AntDatePicker
            id={`${id}-end`}
            placeholder={placeholder}
            className={!stacked ? 'mt-2' : ''}
            popupStyle={{ zIndex: 9999 }}
            style={stacked ? { width: '100%' } : {}}
            disabledDate={this.disabledEndDate}
            disabledTime={this.disabledEndTime}
            format={format}
            value={endValueBound}
            showTime={
              !showTime
                ? undefined
                : { format: HOURS_MINUTES_FORMAT, use12Hours: true, defaultValue: moment().endOf('day') }
            }
            onChange={(value) => {
              if (datepickerEndChangeFn) {
                if (isNil(value)) {
                  datepickerEndChangeFn(undefined);
                } else if (showTime) {
                  datepickerEndChangeFn(value.toISOString());
                } else {
                  datepickerEndChangeFn(value.clone().endOf('day').toISOString());
                }
              }
            }}
            open={this.state.endOpen}
            onOpenChange={this.handleEndOpenChange}
            suffixIcon={calendarIcon}
            boundEndDateValue={boundEndDateValue}
          />
        </div>
      </StyledDateRange>
    );
  }
}
