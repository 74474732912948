import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Popover } from 'ui-components';
import ShareBadge from 'components/common/shareBadge/ShareBadge';
import { formatNestedIntlValues } from 'i18n/utils';
import StatusColorTag from 'components/common/statusColorTag/StatusColorTag';
import Stops from './Stops/Stops';
import './Status.scss';

const Status = (props) => {
  const intl = useIntl();
  return (
    <Col xs={24} md={16}>
      <div className="shipment-status p44Card">
        <header>
          <Row className="justify-between">
            <Col xs={'auto'}>
              <h2 className="m-0">
                {get(props, 'shipmentStatus.transitStatus.id') ? (
                  <FormattedMessage
                    id={props.shipmentStatus.transitStatus.id}
                    defaultMessage={props.shipmentStatus.transitStatus.defaultMessage}
                  />
                ) : (
                  props.shipmentStatus.transitStatus
                )}
              </h2>
              {showHolds(props.shipmentStatus.holds)}
            </Col>
            <Col xs={'auto'} className="text-right d-none d-sm-flex justify-content-end align-items-center">
              {showDemurrageRisk(props.shipmentStatus.demurrageRisk)}
              <StatusColorTag
                messageText={get(props, 'shipmentStatus.flag.text')}
                colorValue={get(props, 'shipmentStatus.flag.color')}
              />
              {props.shipmentStatus.arrivalForecast && (
                <div className="shipment-status__forecast-text">
                  {get(props, 'shipmentStatus.arrivalForecast.id') ? (
                    <FormattedMessage
                      id={get(props, 'shipmentStatus.arrivalForecast.id')}
                      defaultMessage={get(props, 'shipmentStatus.arrivalForecast.defaultMessage')}
                      values={formatNestedIntlValues(get(props, 'shipmentStatus.arrivalForecast.values', {}), intl)}
                    />
                  ) : (
                    props.shipmentStatus.arrivalForecast
                  )}
                </div>
              )}
            </Col>
          </Row>
        </header>
        <main>
          <Row>
            <Col>
              <div className="shipment-status__stops">
                <Stops
                  stops={props.shipmentStatus.stops}
                  progress={props.shipmentStatus.progress}
                  color={props.shipmentStatus?.flag?.color}
                />
              </div>
            </Col>
          </Row>
        </main>
        <footer>
          <Row className={'shipment-status__tracking-methods'}>
            <Col xs={24} sm={12} className="shipment-status__tracking">
              <div className="shipment-status__tracking-method">{props.shipmentStatus.trackingMethod}</div>
              <div className="shipment-status__tracking-connection">{props.shipmentStatus.trackingInfo}</div>
            </Col>
            {get(props, 'shipmentStatus.lastUpdated.id') !== undefined && (
              <Col xs={24} sm={12} className="shipment-status__last-update justify-content-end d-none d-sm-flex">
                <FormattedMessage
                  id={props.shipmentStatus.lastUpdated.id}
                  defaultMessage={props.shipmentStatus.lastUpdated.defaultMessage}
                  values={formatNestedIntlValues(get(props, 'shipmentStatus.lastUpdated.values', {}), intl)}
                />
              </Col>
            )}
          </Row>
          <Row>
            <ShareBadge
              className="shipment-status__share-badge"
              entitlementInfo={props.entitlementInfo}
              showMultipleEntitlees
            />
          </Row>
        </footer>
      </div>
    </Col>
  );
};

const showHolds = (holds) => {
  if (holds && holds.length > 0) {
    return (
      <div className="m-0 d-flex align-items-center">
        <span className="active-holds-label">Holds</span>
        <Popover content={renderHoldsPopover(holds)} trigger="click" placement="bottom">
          <span className="active-holds-count-bubble">{holds.length}</span>
        </Popover>
      </div>
    );
  } else {
    return null;
  }
};

const showDemurrageRisk = (risk) => {
  if (risk) {
    return <p className="m-0 d-flex align-items-center status-demurrage-risk">Demurrage Risk</p>;
  } else {
    return null;
  }
};

const renderHoldsPopover = (holds) => {
  return holds.map((hold) => (
    <div key={hold.type} className="shipment-holds">
      <div className="shipment-holds__type">{hold.type}</div>
      <div className="shipment-holds__detail">{hold.detail}</div>
      <div className="shipment-holds__timestamp">{hold.lastUpdateUtc}</div>
    </div>
  ));
};

Status.propTypes = {
  isOwner: PropTypes.bool,
  shipmentStatus: PropTypes.object,
  entitlementInfo: PropTypes.object,
};

export default Status;
