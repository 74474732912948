//TODO: replace it with codes when available while integration - Bhawesh
export const TEMPERATURE_STATUS = {
  OVER_MAX: 'OVER_MAX',
  UNDER_MIN: 'UNDER_MIN',
  NORMAL: 'NORMAL',
  UNKNOWN: 'UNKNOWN',
};

export const TEMPERATURE_INVALID_TEXT = {
  TEMP_TRACKING_ENABLED: {
    list: 'TEMP TRACKING ENABLED',
    details: 'Temperature Tracking Enabled',
  },
  TEMP_TRACKING_NOT_ACCESSIBLE: {
    list: 'TEMP TRACKING NOT ACCESSIBLE',
    details: 'Temperature Tracking Not Accessible',
  },
  TEMP_TRACKING_COMPLETED: {
    list: 'TEMP TRACKING COMPLETED',
    details: 'Temperature Tracking Completed',
  },
};

export const SCHEDULE_TYPES = {
  SCHEDULED: TEMPERATURE_INVALID_TEXT.TEMP_TRACKING_ENABLED,
  PENDING_APPROVAL: TEMPERATURE_INVALID_TEXT.TEMP_TRACKING_ENABLED,
  ACQUIRING_LOCATION: TEMPERATURE_INVALID_TEXT.TEMP_TRACKING_ENABLED,
  TIMED_OUT: TEMPERATURE_INVALID_TEXT.TEMP_TRACKING_NOT_ACCESSIBLE,
  CANCELED: TEMPERATURE_INVALID_TEXT.TEMP_TRACKING_NOT_ACCESSIBLE,
  APPROVAL_DENIED: TEMPERATURE_INVALID_TEXT.TEMP_TRACKING_NOT_ACCESSIBLE,
  DEPARTED_FINAL_STOP: TEMPERATURE_INVALID_TEXT.TEMP_TRACKING_COMPLETED,
};
