import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AimOutlined, EnvironmentFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Field, FieldProps, FormikProps } from 'formik';
import { defineMessages, injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import trim from 'lodash/trim';

import { rgba } from 'polished';
import { ThemeContext } from 'contexts/ThemeContext';
import { Label, Input, InputErrorMessage } from 'components/common/forms/FormElements';
import { AddressLine2Units } from 'common/LocationUtils/LocationUtils';
import { LocationDetails, FormValues } from 'models';
import AutocompleteAddress from 'components/common/forms/AutocompleteAddress';
import { Radio, Select } from 'ui-components';
import LatLngFieldComponent from './LatLongEdit';

const StyledAddressChangeMessage = styled.p<{ primaryColor: string }>`
  background: ${(props) => rgba(props.primaryColor, 0.2)};
  padding: 10px;
  border: 1px solid ${(props) => props.primaryColor};
  margin-top: 10px;
`;

export interface EditModeDetailsProps extends WrappedComponentProps {
  address: string | undefined;
  hours: any;
  setFieldValue: (field: string, value: any) => void;
  formProps: FormikProps<FormValues>;
  addressObject: LocationDetails['address'] | undefined;
  latLngInputValue: string | undefined;
  updateUserEnteredAddress: (address: any) => void;
  updatelatLngInputValue: (latLng: string | undefined) => void;
  updateUseAddressLatLngInMap: (useAddressLatLng: boolean) => void;
  address2Units: string | undefined;
  updateAddress2Units: React.Dispatch<string>;
  isNewLocation: boolean;
}

const messages = defineMessages({
  locationInformation: {
    id: 'locationDetails.locationDetailsAddressHeader.locationInformation',
    defaultMessage: 'Location Information',
  },
  addNewLocation: {
    id: 'locationDetails.locationDetailsAddressHeader.addNewLocation',
    defaultMessage: 'Add New Location',
  },
  locationDetails: {
    id: 'locationDetails.locationDetailsAddressHeader.locationDetails',
    defaultMessage: 'Location Details',
  },
  acmeInc: {
    id: 'locationDetails.addressFormSection.acmeInc',
    defaultMessage: 'ACME, INC',
  },
  company: {
    id: 'locationDetails.addressFormSection.company',
    defaultMessage: 'Company',
  },
  addressLine2: {
    id: 'locationDetails.edit.labels.addressLine2',
    defaultMessage: 'Unit',
  },
  suite: {
    id: 'locationDetails.addressFormSection.optional',
    defaultMessage: '(optional)',
  },
});

function EditModeDetails(props: EditModeDetailsProps) {
  const {
    address,
    address2Units,
    updateAddress2Units,
    addressObject,
    setFieldValue,
    latLngInputValue,
    updateUserEnteredAddress,
    updatelatLngInputValue,
    updateUseAddressLatLngInMap,
    formProps,
    intl,
  } = props;
  const [showChangedAddressMessage, updateShowChangedAddressMessage] = React.useState<boolean>(false);
  const [showLatLngErrorMessage, updateShowLatLngErrorMessage] = React.useState<boolean>(false);
  const [userSelectedAddress, updateUserSelectedAddress] = React.useState<boolean>(false);
  const context = React.useContext(ThemeContext);
  const [addressLine2SplitUnit = ''] = !isNil(get(addressObject, 'address2'))
    ? get(addressObject, 'address2', '').split(' ')
    : ['', undefined];

  const lat = addressObject?.locationCoordinatesDto?.latitude;
  const lng = addressObject?.locationCoordinatesDto?.longitude;
  const defaultLatLng = lat && lng ? `${lat}, ${lng}` : undefined;
  const { isChinaAsCountry } = formProps.values;
  const [addressTitle, setAddressTitle] = React.useState(address);

  React.useEffect(() => {
    updateAddress2Units(addressLine2SplitUnit.toUpperCase());

    const formattedInputValues: string[] | undefined = latLngInputValue?.split(',').map((str: string) => trim(str));
    if (formattedInputValues?.length === 2) {
      setFieldValue('address.locationCoordinatesDto.latitude', formattedInputValues[0]);
      setFieldValue('address.locationCoordinatesDto.longitude', formattedInputValues[1]);
    }
  }, [addressLine2SplitUnit, updateAddress2Units, latLngInputValue, setFieldValue]);

  const memoChangeEvent = React.useCallback(
    (unit: string) => {
      updateAddress2Units(unit);
    },
    [updateAddress2Units]
  );

  // TODO: Have to be able to save address line 2. Right now it won't save.
  const AddressLine2Component = ({ field, form }: FieldProps) => {
    return (
      <>
        <Label>{intl.formatMessage(messages.addressLine2)}</Label>
        <div>{form.errors[field.name]}</div>
        <Row>
          <Col xs={10} style={{ paddingRight: 0 }}>
            <Select
              style={{ fontSize: '1.4rem', width: '100%' }}
              placeholder={intl.formatMessage({
                id: 'locationDetails.edit.address2units.selectOne',
                defaultMessage: 'Select One',
              })}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={memoChangeEvent}
              value={address2Units}
              dataSource={[
                {
                  value: '',
                  displayValue: intl.formatMessage({
                    id: 'locationDetails.edit.address2units.selectOne',
                    defaultMessage: 'Select One',
                  }),
                },
                ...Object.keys(AddressLine2Units).map((value: string) => ({
                  value,
                  displayValue: intl.formatMessage({
                    id: `locationDetails.edit.address2units.${value}`,
                    defaultMessage: AddressLine2Units[value as keyof typeof AddressLine2Units],
                  }),
                })),
              ]}
            />
          </Col>
          <Col xs={14} style={{ paddingLeft: '5px' }}>
            <Input
              type="text"
              name="address2"
              disabled={!address2Units}
              value={field.value}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={field.onChange}
              style={{ fontSize: '1.4rem' }}
              placeholder={props.intl.formatMessage(messages.suite)}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Row style={{ marginBottom: '3rem' }}>
        <Col lg={2} className="text-center" style={{ maxWidth: 'calc(5%)' }}>
          <EnvironmentFilled style={{ fontSize: '2rem', lineHeight: '3.3rem' }} />
        </Col>
        <Col lg={22}>
          <b>
            <FormattedMessage
              id="locationDetails.edit.labels.countryChoice.helpText"
              defaultMessage="Please select an option to ensure accurate results:"
            />
          </b>
          <Radio
            radioData={[
              {
                label: <FormattedMessage id="locationDetails.edit.labels.countryChoice.china" defaultMessage="China" />,
                value: 1,
              },
              {
                label: (
                  <FormattedMessage
                    id="locationDetails.edit.labels.countryChoice.restOfWorld"
                    defaultMessage="Rest of World"
                  />
                ),
                value: 0,
              },
            ]}
            onChange={({ target: { value } }: any) => {
              setFieldValue('isChinaAsCountry', Number(value) > 0);
            }}
            value={Number(isChinaAsCountry)}
            style={{ marginBottom: 4 }}
          />
          <Label>
            <FormattedMessage id="locationDetails.edit.labels.shortAddress" defaultMessage="Address" />
          </Label>

          <AutocompleteAddress
            defaultValue={address}
            value={addressTitle}
            placeholder={intl.formatMessage({
              id: 'locationDetails.edit.placeholders.fullAddress',
              defaultMessage: 'Start typing address here...',
            })}
            address={address}
            onChange={(value) => setAddressTitle(value)}
            defaultActiveFirstOption={false}
            isChinaAsCountry={isChinaAsCountry}
            updatelatLngInputValue={updatelatLngInputValue}
            // eslint-disable-next-line react/jsx-no-bind
            updateAddress={(addr: any) => {
              // tslint:disable-next-line: jsx-no-lambda
              if (!isNil(addr)) {
                updateUserEnteredAddress(addr);
                setAddressTitle(addr.title);
                const houseNumber = get(addr, 'address.houseNumber');
                const street = get(addr, 'address.street');
                const hasAnAddress = houseNumber && street;
                const country = get(addr, 'address.countryCode', '');
                let line1 = hasAnAddress
                  ? `${get(addr, 'address.houseNumber', '')} ${get(addr, 'address.street', '')}`
                  : null;
                if (country === 'CN') line1 = `${street}${houseNumber}`;
                setFieldValue('address.address1', line1);
                setFieldValue('address.city', get(addr, 'address.city', ''));
                setFieldValue('address.state', get(addr, 'address.state', ''));
                setFieldValue('address.postalCode', get(addr, 'address.postalCode', ''));
                setFieldValue('address.country', get(addr, 'address.countryCode', ''));
                updateUseAddressLatLngInMap(true);
                updateUserSelectedAddress(true);
                updateShowLatLngErrorMessage(false);
                if (!props.isNewLocation) {
                  updateShowChangedAddressMessage(true);
                }
              }
            }}
          />
          {formProps.errors.address && (
            <InputErrorMessage
              errorMessage={intl.formatMessage({
                id: 'locationDetails.edit.errorMessages.invalidAddress',
                defaultMessage: 'Please select a valid address, including a street name and number',
              })}
            />
          )}
          {showChangedAddressMessage && (
            <StyledAddressChangeMessage primaryColor={get(context, 'primaryColor', '')}>
              <InfoCircleOutlined />{' '}
              <FormattedMessage
                id="locationDetails.edit.infoMessages.addressChange"
                defaultMessage={`Changing the address will create a new copy of this location. The old location will be renamed "${props.formProps.values.name} (1)".`}
                values={{ locationName: props.formProps.values.name }}
              />
            </StyledAddressChangeMessage>
          )}
        </Col>
      </Row>
      <Row style={{ marginBottom: '3rem' }}>
        <Col lg={2} className="text-center" style={{ maxWidth: 'calc(5%)' }} />
        <Col lg={22}>
          <Field type="select" name="address2" render={AddressLine2Component} />
        </Col>
      </Row>
      <Row style={{ marginBottom: '3rem' }}>
        <Col lg={2} className="text-center" style={{ maxWidth: 'calc(5%)' }}>
          <AimOutlined style={{ fontSize: '2rem', lineHeight: '3.3rem' }} />
        </Col>
        <Col lg={22}>
          <Label inputid="latLng">
            <FormattedMessage id="locationDetails.edit.labels.latitudeLongitude" defaultMessage="Latitude, Longitude" />
          </Label>
          <Field
            type="text"
            name="latLng"
            render={(fieldProps: FieldProps) => (
              <LatLngFieldComponent
                fieldProps={fieldProps}
                userSelectedAddress={userSelectedAddress}
                latLngInputValue={latLngInputValue}
                defaultLatLng={defaultLatLng}
                updateUseAddressLatLngInMap={updateUseAddressLatLngInMap}
                updateUserSelectedAddress={updateUserSelectedAddress}
                updatelatLngInputValue={updatelatLngInputValue}
                setFieldValue={setFieldValue}
                showLatLngErrorMessage={showLatLngErrorMessage}
                updateShowLatLngErrorMessage={updateShowLatLngErrorMessage}
              />
            )}
          />
          <p className="mt-2">
            <FormattedMessage
              id="locationDetails.edit.labels.latitudeLongitudeFormatNote"
              defaultMessage="Separate latitude and longitude with a comma."
            />
          </p>
        </Col>
      </Row>
    </>
  );
}

export default injectIntl(EditModeDetails);
