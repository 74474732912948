import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import * as React from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import phone from 'phone';
import { Button, Input, Select } from 'ui-components';
import axios from 'util/paxios';

import { API_PATH } from 'common/AppConstants';
import { trackEvent } from 'common/eventTracker';
import ErrorBoundary from 'components/common/errorBoundary/ErrorBoundary';
import { CarrierFacingEquipmentIdsEnum, ShipmentModeEnum, Identifier, ShipmentStopSmall } from 'models';
import { green, errorRed } from 'styles/colors';
import { PrincipalAuthorizations } from 'common/authorizations';
import * as styles from './CarrierShipmentListCard.module.scss';
import {
  ShipmentIdentifiers,
  ShipmentStopInfo,
} from '../../shipmentListComponent/shipmentSearchResultCard/components/ShipmentSearchResultCardComponents';
import arrowBack from '../../../assets/images/arrow-back.svg';

const messages = defineMessages({
  genericError: {
    id: 'carrierShipmentList.equipmentIdentifiers.genericError',
    defaultMessage: 'Oops, something went wrong',
  },
  errorInvalidToken: {
    id: 'carrierShipmentList.equipmentIdentifiers.errorInvalidToken',
    defaultMessage: 'This page is no longer valid',
  },
  errorPhone: {
    id: 'carrierShipmentList.equipmentIdentifiers.errorPhone',
    defaultMessage: 'Invalid phone number',
  },
  errorInvalidEquipId: {
    id: 'carrierShipmentList.equipmentIdentifiers.errorInvalidEquipId',
    defaultMessage: 'Invalid equipment identifier',
  },
  errorShipmentNotUpdated: {
    id: 'carrierShipmentList.equipmentIdentifiers.errorShipmentNotUpdated',
    defaultMessage: 'Could not update shipment with equipment identifier',
  },
});

const CarrierShipmentListCard = ({
  resultItem,
  carrierListShareToken,
}: {
  resultItem: {
    shipmentId: string;
    shipmentIdentifiers: Identifier[];
    pickupStopInfo: ShipmentStopSmall;
    deliveryStopInfo: ShipmentStopSmall;
  };
  carrierListShareToken: string;
}) => {
  const intl = useIntl();
  const equipmentIdTypes = Object.keys(CarrierFacingEquipmentIdsEnum);
  const getCarrierFacingEquipmentId = () => {
    const authorizations = new PrincipalAuthorizations({
      authorities: [],
      principal: [],
    });

    return authorizations.isEUEnvironment()
      ? CarrierFacingEquipmentIdsEnum.LICENSE_PLATE
      : CarrierFacingEquipmentIdsEnum.VEHICLE_ID;
  };

  const [selectedEquipIdType, setSelectedEquipIdType] = useState<string>(getCarrierFacingEquipmentId());
  const [equipIdValue, setEquipIdValue] = useState<string>('');
  const [isSavingEquipId, setIsSavingEquipId] = useState<boolean>(false);
  const [equipIdValidated, setEquipIdValidated] = useState<boolean>(false);
  const [equipIdValidationError, setEquipIdValidationError] = useState<
    { id: string; defaultMessage: string } | undefined
  >();

  const saveEquipmentIdentifier = async () => {
    let value: string = equipIdValue;
    if (selectedEquipIdType === CarrierFacingEquipmentIdsEnum.MOBILE_PHONE_NUMBER) {
      const phoneValue = phone(equipIdValue).phoneNumber;
      if (!phoneValue) {
        setEquipIdValidationError(messages.errorPhone);
        return;
      } else {
        value = phoneValue;
      }
    }

    setEquipIdValidationError(undefined);
    setIsSavingEquipId(true);
    try {
      const url = `${API_PATH}/shipment/carrier/share/public/${carrierListShareToken}/TL/equipment`;
      const response: AxiosResponse<{ equipmentValid: boolean; shipmentUpdated: boolean }> = await axios.post(
        url,
        {
          shipmentId: resultItem.shipmentId,
          equipmentIdentifierType: selectedEquipIdType,
          equipmentIdentifierValue: value,
        },
        {
          withCredentials: true,
        }
      );

      setIsSavingEquipId(false);
      if (response.data?.equipmentValid === false) {
        setEquipIdValidationError(messages.errorInvalidEquipId);
        trackEvent('CARRIER_LIST_ERROR_INVALID_EQUIPMENT_IDENTIFIER');
      } else if (response.data?.shipmentUpdated === false) {
        setEquipIdValidationError(messages.errorShipmentNotUpdated);
        trackEvent('CARRIER_LIST_ERROR_SHIPMENT_NOT_UPDATED');
      } else if (response.data?.equipmentValid === true && response.data?.shipmentUpdated === true) {
        setEquipIdValidated(true);
        trackEvent('CARRIER_LIST_ADDED_EQUIPMENT_IDENTIFIER');
      } else {
        setEquipIdValidationError(messages.genericError);
      }
    } catch (e) {
      const error = e as AxiosError;
      setIsSavingEquipId(false);
      const responseStatus = error.response?.status;
      const isAnInvalidTokenError = responseStatus && [410, 400].includes(responseStatus);
      if (isAnInvalidTokenError) {
        setEquipIdValidationError(messages.errorInvalidToken);
        trackEvent('CARRIER_LIST_ERROR_INVALID_TOKEN');
      } else {
        setEquipIdValidationError(messages.genericError);
        trackEvent('CARRIER_LIST_ERROR');
      }
    }
  };

  return (
    <div className={styles.card} data-locator={`carrier-shipment-result-card-${resultItem.shipmentId}`}>
      <ErrorBoundary message={intl.formatMessage(messages.genericError)}>
        <Row className={styles.cardBody}>
          <Col xs={24} md={10}>
            <Row>
              <Col xs={2} sm={1} md={3} className="d-flex justify-content-center pr-1">
                {equipIdValidated ? (
                  <CheckCircleOutlined
                    data-locator={`validated-icon-${resultItem.shipmentId}`}
                    style={{ fontSize: '3rem', color: green }}
                  />
                ) : (
                  <ExclamationCircleOutlined
                    data-locator={`needs-validation-icon-${resultItem.shipmentId}`}
                    style={{ fontSize: '3rem', color: errorRed }}
                  />
                )}
              </Col>
              <Col className="d-flex flex-column">
                <Select
                  data-locator={`equipment-id-type-selector-${resultItem.shipmentId}`}
                  className={styles.equipmentIdSelect}
                  value={selectedEquipIdType}
                  onChange={(equipIdType: string) => setSelectedEquipIdType(equipIdType)}
                  dataSource={equipmentIdTypes.map((equipId) => ({
                    value: equipId,
                    displayValue: intl.formatMessage({
                      id: `carrierShipmentList.equipmentIdentifiers.types.${equipId}`,
                      defaultMessage: equipId,
                    }),
                  }))}
                />
                <Input
                  data-locator={`equipment-id-input-${resultItem.shipmentId}`}
                  value={equipIdValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    !equipIdValidated && setEquipIdValue(event.target.value);
                  }}
                  placeholder={intl.formatMessage({
                    id: 'shipmentDetails.equipmentIdentifiers.valuePlaceholder',
                    defaultMessage: 'Enter value',
                  })}
                  hasError={!!equipIdValidationError}
                  errorMessage={equipIdValidationError ? intl.formatMessage(equipIdValidationError) : undefined}
                  errorColor={errorRed}
                  errorDataLocator={`equipment-id-error-${resultItem.shipmentId}`}
                />
                <Button
                  data-locator={`save-equipment-id-button-${resultItem.shipmentId}`}
                  className={styles.saveButton}
                  type="secondary"
                  size="lg"
                  disabled={equipIdValue === '' || equipIdValidated}
                  clickFn={saveEquipmentIdentifier}
                  loading={isSavingEquipId}
                >
                  <FormattedMessage id="shipmentDetails.equipmentIdentifiers.save" defaultMessage="SAVE" />
                </Button>
              </Col>
            </Row>
          </Col>

          <Col xs={24} className={styles.divider}></Col>

          <Col className={styles.shipmentInfoContainer}>
            <Row className={styles.identifiers}>
              <Col>
                <ShipmentIdentifiers mode={ShipmentModeEnum.TL} identifiers={resultItem.shipmentIdentifiers} />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <div className={styles.stopInfoContainer}>
                  <ShipmentStopInfo
                    stopInfo={resultItem.pickupStopInfo}
                    shipmentId={resultItem.shipmentId}
                    shipmentIdentifiers={[]}
                    carrierIdentifiers={[]}
                  />
                  <div className="d-flex align-items-center">
                    <img
                      src={arrowBack}
                      alt={intl.formatMessage({
                        id: 'shipmentList.listCardAltText.toArrow',
                        defaultMessage: 'To arrow',
                      })}
                      className={styles.arrow}
                    />
                  </div>
                  <ShipmentStopInfo
                    stopInfo={resultItem.deliveryStopInfo}
                    shipmentId={resultItem.shipmentId}
                    shipmentIdentifiers={[]}
                    carrierIdentifiers={[]}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ErrorBoundary>
    </div>
  );
};

export default CarrierShipmentListCard;
