import ErrorBoundary from 'components/common/errorBoundary/ErrorBoundary';
import './RouteExpanded.scss';
import Group from './updates/Group.js';
import Update from './updates/Update.js';
import NewDay from './updates/NewDay';
import Stop from './updates/Stop';

const RouteExpanded = (props) => {
  return (
    <div className="shipment-route__expanded">
      {props.routeDetails.map((update, index) => {
        switch (update.type) {
          case 'INFO':
            return (
              <Update
                key={update.lastRecordedDateValue}
                date={update.date}
                description={update.description}
                tertiary={update.tertiary}
                label={update.derivedStatus || update.label}
                latestStatusUpdate={update.latestStatusUpdate}
                markerColor={props.markerColor}
              />
            );
          case 'GROUP':
            return (
              <Group
                key={`${update.text}${update.type}`}
                updateIndex={index}
                text={update.text}
                updates={update.updates}
                expanded={update.expanded}
                toggleGroupedUpdates={props.toggleGroupedUpdates}
              />
            );
          case 'NEW_DAY':
            return <NewDay key={`${update.type}${update.date}`} date={update.date} />;
          case 'STOP':
            return (
              <ErrorBoundary message={'An error occurred rendering the route details'}>
                <Stop
                  mode={props.mode}
                  key={`${update.type}${update.stopNumber}`}
                  markerColor={props.markerColor}
                  update={update}
                  statusStop={props.statusStops[update.stopNumber - 1]}
                />
              </ErrorBoundary>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default RouteExpanded;
