import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import find from 'lodash/find';

import { LONG_MONTH_DAY_YEAR_TIME_FORMAT } from 'i18n/configurei18n';
import { allModeFilterOptions } from '../../filter/ducks/reducers';

const getDateRangeText = (prefixString: string, fromString: string, toString: string) => {
  return (
    <span>
      <strong>
        {prefixString}&nbsp;{`${fromString}`}
      </strong>
      &nbsp;to&nbsp;
      <strong>{`${toString}`}</strong>
    </span>
  );
};

const mergeListIntoElement = (itemArray: any[]) => {
  return itemArray.map((item: any, index: number, fullList: any) => {
    if (index < fullList.length - 1) {
      return (
        <span key={item.displayName}>
          <strong>{item.displayName}</strong>&nbsp;and&nbsp;
        </span>
      );
    } else {
      return <strong key={item.displayName}>{item.displayName}&nbsp;</strong>;
    }
  });
};

export const generateFilterDescription = (filterObj: any, modeFilters?: any[]) => {
  let appliedFilterText;
  let arrivalForecastText;
  let happeningCodeText;
  let temperatureTrackingText;
  let deliveryStatusText;
  let pickupDateText;
  let deliveryDateText;
  let oceanLastFreeDateText;
  let oceanDemurrageEligibleText;
  let locationText;
  let carrierText;
  let companyText;
  let createdByText;
  let attributeText;
  let modeFilterText;

  if (!filterObj && !modeFilters) {
    modeFilterText = '';
    appliedFilterText = '';
    arrivalForecastText = '';
    temperatureTrackingText = '';
    deliveryStatusText = '';
    happeningCodeText = '';
    return <span />;
  }
  if (Object.keys(filterObj).length === 0) {
    return <span />;
  }
  if (filterObj.status.length > 0 && filterObj.status.length < 4) {
    appliedFilterText = mergeListIntoElement(filterObj.status);
  }
  if (filterObj.status.length > 3) {
    appliedFilterText = <strong>{`Status: ${filterObj.status.length} Selected`}&nbsp;</strong>;
  }
  if (filterObj.arrivalForecast.length > 0) {
    arrivalForecastText = mergeListIntoElement(filterObj.arrivalForecast);
  }
  if (filterObj.temperatureTracking.length > 0) {
    temperatureTrackingText = mergeListIntoElement(filterObj.temperatureTracking);
  }
  if (filterObj.happeningCode.length > 0) {
    happeningCodeText = mergeListIntoElement(filterObj.happeningCode);
  }
  if (filterObj.oceanDemurrageEligible) {
    oceanDemurrageEligibleText = mergeListIntoElement(['Demurrage Risk']);
  }
  if (filterObj.deliveryStatus.length > 0) {
    deliveryStatusText = mergeListIntoElement(filterObj.deliveryStatus);
  }
  const timezone = moment.tz.guess();
  if (filterObj.pickupDateStart && filterObj.pickupDateEnd) {
    const pickupDateStart = moment.tz(filterObj.pickupDateStart, timezone);
    const pickupDateEnd = moment.tz(filterObj.pickupDateEnd, timezone);

    pickupDateText = getDateRangeText(
      'Pickup Date Range:',
      pickupDateStart.format(LONG_MONTH_DAY_YEAR_TIME_FORMAT),
      pickupDateEnd.format(LONG_MONTH_DAY_YEAR_TIME_FORMAT)
    );
  }
  if (filterObj.deliveryDateStart && filterObj.deliveryDateEnd) {
    const deliveryDateStart = moment.tz(filterObj.deliveryDateStart, timezone);
    const deliveryDateEnd = moment.tz(filterObj.deliveryDateEnd, timezone);

    deliveryDateText = getDateRangeText(
      'Delivery Date Range:',
      deliveryDateStart.format(LONG_MONTH_DAY_YEAR_TIME_FORMAT),
      deliveryDateEnd.format(LONG_MONTH_DAY_YEAR_TIME_FORMAT)
    );
  }
  if (filterObj.oceanLastFreeDateStart && filterObj.oceanLastFreeDateEnd) {
    const lastFreeDateStart = moment.tz(filterObj.oceanLastFreeDateStart, timezone);
    const lastFreeDateEnd = moment.tz(filterObj.oceanLastFreeDateEnd, timezone);

    oceanLastFreeDateText = getDateRangeText(
      'Last Free Date Range:',
      lastFreeDateStart.format(LONG_MONTH_DAY_YEAR_TIME_FORMAT),
      lastFreeDateEnd.format(LONG_MONTH_DAY_YEAR_TIME_FORMAT)
    );
  }

  if (modeFilters && modeFilters.length > 0) {
    const obj: any = find(allModeFilterOptions, { value: modeFilters[0] }) || {
      label: '',
    };
    const modeString = obj.label;
    modeFilterText = <strong>{`${modeString}`}&nbsp;</strong>;
  }
  if (filterObj.location.length > 0) {
    const filterString = filterObj.location.join(', ');
    locationText = <strong>{`Location: ${filterString}`}&nbsp;</strong>;
  }
  if (filterObj.carrier.length > 0) {
    const filterString = filterObj.carrier.join(', ');
    carrierText = <strong>{`Carrier: ${filterString}`}&nbsp;</strong>;
  }
  if (filterObj.company.length > 0) {
    const filterString = filterObj.company.join(', ');
    companyText = <strong>{`Company: ${filterString}`}&nbsp;</strong>;
  }
  if (filterObj.createdBy.length > 0) {
    const filterString = filterObj.createdBy.join(', ');
    createdByText = <strong>{`Created By: ${filterString}`}&nbsp;</strong>;
  }
  if (filterObj.attribute.length > 0) {
    const filterString = filterObj.attribute.join(', ');
    attributeText = <strong>{`Attribute: ${filterString}`}&nbsp;</strong>;
  }
  const fullFilterDescription = (
    <span>
      {[
        modeFilterText,
        appliedFilterText,
        arrivalForecastText,
        temperatureTrackingText,
        happeningCodeText,
        oceanDemurrageEligibleText,
        deliveryStatusText,
        pickupDateText,
        deliveryDateText,
        oceanLastFreeDateText,
        locationText,
        carrierText,
        companyText,
        attributeText,
        createdByText,
      ].reduce((aggregator: any[], item, index: number) => {
        if (aggregator.length === 0 && item) {
          aggregator.push(<span key={index.toString()}>{item}</span>);
          return aggregator;
        }
        if (aggregator && item) {
          aggregator.push(
            <span key={index.toString()}>
              &nbsp;
              <FormattedMessage id="appliedFilterList.andText" defaultMessage="and" />
              &nbsp;{item}
            </span>
          );
          return aggregator;
        } else {
          return aggregator;
        }
      }, [])}
    </span>
  );
  return fullFilterDescription;
};

export default generateFilterDescription;
