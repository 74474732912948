import moment from 'moment-timezone';
import { defineMessages, createIntl, createIntlCache } from 'react-intl';
import LocationUtils from '../../../../../common/LocationUtils/LocationUtils';
import {
  locale,
  messages,
  MONTH_DAY_YEAR_FORMAT,
  HOURS_MINUTES_TIMEZONE_FORMAT,
} from '../../../../.././i18n/configurei18n';

const cache = createIntlCache();
export const intl = createIntl({ locale, messages }, cache);

const messagesIntl = defineMessages({
  nearLocation: {
    id: 'shipmentDetails.pingPopupHtml.nearLocation',
    defaultMessage: 'Near {locationUtils}',
  },
  pingDateAtPingTime: {
    id: 'shipmentDetails.pingPopupHtml.pingDateAtPingTime',
    defaultMessage: '{pingDate} at {pingTime}',
  },
});

const DATE_FORMAT = MONTH_DAY_YEAR_FORMAT;
const TIME_FORMAT = HOURS_MINUTES_TIMEZONE_FORMAT;
const UTC = 'UTC';

const formatPingDate = (timestamp, timezone) => {
  if (timestamp && timezone) {
    const date = new moment.tz(timestamp, UTC).tz(timezone).format(DATE_FORMAT);

    return `${date}`;
  }
};

const formatPingTime = (timestamp, timezone) => {
  if (timestamp && timezone) {
    const time = new moment.tz(timestamp, UTC).tz(timezone).format(TIME_FORMAT);

    return `${time}`;
  }
};

/**
 * Popup html for ping updates
 *
 * @param record object that matches the `recordsMapper.pingsToRecordsMapper()` format
 * @returns {HTML}
 */
const pingPopupHtml = (record) => {
  let { city, state, country, pingDateTime, timezone } = record;

  let markup = `
    <div class="map-location-details">

      ${
        city && state
          ? `<p class="map-location-details__city-state">
          <span>${intl.formatMessage(messagesIntl.nearLocation, {
            locationUtils: `<b>${LocationUtils.formatCityStateCountry(city, state, country)}</b>`,
          })}</span><br/>
        </p>`
          : ``
      }

      ${
        pingDateTime && timezone
          ? `<p class="map-location-details__date-time">
          <span>${intl.formatMessage(messagesIntl.pingDateAtPingTime, {
            pingDate: formatPingDate(pingDateTime, timezone),
            pingTime: `<b>${formatPingTime(pingDateTime, timezone)}</b>`,
          })}</span><br/>
        </p>`
          : ``
      }

    </div>
  `;

  return markup;
};

export default pingPopupHtml;
