import concat from 'lodash/concat';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import replace from 'lodash/replace';
import moment from 'moment-timezone';

import { HOURS_MINUTES_FORMAT, MONTH_DAY_YEAR_FORMAT } from 'i18n/configurei18n';
import { isSameDay } from '../../../../common/dateUtils';
import { routeDateKeys, routeDetailsObjectTypes, routeDetailTypes } from '../enums/routeMappingEnums';
import { createTextString, getDate } from './mappingUtils';

const ROUTE_GROUPING_THRESHOLD = 5;

/**
 * Concat mapped arrays together.  Last element of ...lists
 * @param lists - array of all the formatted updates
 * @returns [array] updated array of all the stops
 */
export const concatMappedUpdates = (...lists: any[]): any[] => {
  const targetList = flatten(lists.splice(-1)).filter((x) => !!x);
  const toConcat = flatten(lists).filter((x) => !!x);
  return concat(targetList, toConcat);
};

const sortUpdateByDate = (a: Update, b: Update, comparingStopAndUpdate: boolean): number => {
  if (moment(a.sortDate).isSame(b.sortDate)) {
    if (comparingStopAndUpdate) {
      return a.type === 'UPDATE' && b.type === 'STOP' ? 1 : -1;
    }
    return 0;
  }
  return moment(a.sortDate).isBefore(b.sortDate) ? -1 : 1;
};

export interface UpdateWithStopNumber {
  type: string;
  stopNumber: number;
}
const sortUpdateByStopNumber = (
  a: UpdateWithStopNumber,
  b: UpdateWithStopNumber,
  comparingStopAndUpdate: boolean
): number => {
  const sameStopNumber = a.stopNumber === b.stopNumber;
  if (sameStopNumber && comparingStopAndUpdate) {
    return a.type === 'UPDATE' && b.type === 'STOP' ? 1 : -1;
  }
  return a.stopNumber - b.stopNumber;
};

/**
 * Sorts routes by date, if no date it sorts by stop number. It places items with no stop number or date at end
 * @param updatedRoute - array of all the formatted updates
 * @param sort - sort value
 * @returns [array] updated sorted array
 */
export interface Update {
  type: string;
  sortDate?: string;
  stopNumber?: number;
}

export const sortRoute = (updatedRoute: Update[]): any[] => {
  const arrayToSort = [...updatedRoute];
  const sortedRoute = arrayToSort.sort((a, b) => {
    const comparingStopAndUpdate =
      (a.type === 'UPDATE' && b.type === 'STOP') || (a.type === 'STOP' && b.type === 'UPDATE');

    // Check if we can sort by sortDate then stopNumber
    if (a.sortDate !== undefined && b.sortDate !== undefined) {
      return sortUpdateByDate(a, b, comparingStopAndUpdate);
    } else if (a.stopNumber !== undefined && b.stopNumber !== undefined) {
      return sortUpdateByStopNumber(a as UpdateWithStopNumber, b as UpdateWithStopNumber, comparingStopAndUpdate);
    }

    // Items with no stop number or date go to end
    if (a.stopNumber === undefined && b.stopNumber !== undefined) {
      return 1;
    } else if (a.stopNumber !== undefined && b.stopNumber === undefined) {
      return -1;
    } else if (a.sortDate === undefined && b.sortDate !== undefined) {
      return 1;
    } else if (a.sortDate !== undefined && b.sortDate === undefined) {
      return -1;
    }
    return 0; // default to 0
  });
  return sortedRoute;
};

/**
 * Removes any stops that don't have a date
 * @param updatedRoute - array of all the formatted updates
 * @returns [array] filtered array of stops
 */
export const removeInvalidStops = (checkDateValue: boolean, updatedRoute: any[]): any[] => {
  return filter(
    updatedRoute,
    (route) =>
      route.type === 'UPDATE' ||
      (route.type === 'STOP' && !(checkDateValue && isNil(route.dateValue))) ||
      (route.type === 'STOP' && route.stopType === 'DESTINATION') ||
      (route.type === 'STOP' && route.stopType === 'ORIGIN')
  );
};

/**
 * Removes any entries that are adjacent new days without any other type in between
 * @param originalRoute - array of all the formatted updates
 * @returns [array] filtered array
 */
export const removeAdjacentNewDays = (originalRoute: any[]): any[] => {
  if (isEmpty(originalRoute)) {
    return originalRoute;
  }
  const updatedRoute = originalRoute.slice(0);

  let lastNewDay = -1;
  for (let i = originalRoute.length - 1; i >= 0; --i) {
    const route = updatedRoute[i];
    if (route.type === 'NEW_DAY' && lastNewDay === -1) {
      lastNewDay = i;
    } else if (route.type !== 'NEW_DAY') {
      // remove all trailing NEW_DAY entries
      if (lastNewDay === originalRoute.length - 1) {
        updatedRoute.splice(i + 1, lastNewDay - i);
      }
      // keep the latest NEW_DAY entry
      else if (lastNewDay > 0 && lastNewDay - i > 1) {
        updatedRoute.splice(i + 1, lastNewDay - i - 1);
      }
      lastNewDay = -1;
    }

    // remove the last block
    if (i === 0 && lastNewDay !== -1) {
      if (lastNewDay === originalRoute.length - 1) {
        // clear
        updatedRoute.length = 0;
      } else if (lastNewDay > 0) {
        updatedRoute.splice(i + 1, lastNewDay - i + 1);
      }
    }
  } // for

  return updatedRoute;
};

/**
 * Updates object structure for statuses and stops
 * @param updatedRoute - array of all the formatted updates
 * @returns [array] updated route array with mapped stop and update objects
 */
export const mapStatusValues = (updatedRoute: any[]): any[] => {
  return updatedRoute.map((update, index) => {
    let lastRecordedDateValue;
    // check array of timekeys for match, ordered by priority
    const timeKey = find(routeDateKeys, (key) => !isEmpty(update[key])) as string;
    let dateValue = update[timeKey];
    let sortDate = dateValue;

    if (get(dateValue, 'localDate')) {
      const isUpdate =
        update.type === routeDetailsObjectTypes.TYPES.UPDATE || update.type === routeDetailsObjectTypes.TYPES.INFO;
      if (isUpdate) {
        dateValue = update[timeKey].localTime ? `${update[timeKey].localDate}T${update[timeKey].localTime}` : undefined;
        sortDate = dateValue ? dateValue : `${update[timeKey].localDate}`;
      } else {
        // create timestamp from local split date
        dateValue = update[timeKey].localTime
          ? `${update[timeKey].localDate}T${update[timeKey].localTime}`
          : `${update[timeKey].localDate}`;
        sortDate = dateValue;
      }
    }

    if (dateValue || sortDate) {
      lastRecordedDateValue = dateValue || sortDate; // need to to determine if next day update needs to be added if dateValue is undefined
    }

    // update.stopType is null for ocean so it's following bottom path with utc for status updates (when no time zone)
    // hold updates manually sets the "localTimestamp" to the utcTimestamp
    // Currently hold updates -> ocean route details mappingUtils.mapHoldToUpdate
    return update.stopType // if update is a stop apply different values
      ? {
          ...update,
          sortDate: sortDate || update.utcRecordedArrivalDateTime || update.utcRecordedDepartureDateTime,
          dateValue,
          stopType: update.stopType,
          startDate: update.localAppointmentStartDateTime,
          endDate: update.localAppointmentEndDateTime,
          stopNumber: update.stopNumber,
          lastRecordedDateValue,
          id: index,
        }
      : {
          ...update,
          sortDate: sortDate || update.utcTimestamp,
          dateValue: dateValue || update.utcTimestamp,
          timeKey,
          lastRecordedDateValue,
          latestStatusUpdate: update.latestStatusUpdate || false,
          id: index,
        };
  });
};

const createGroup = (groupUpdates: any[]) => ({
  type: 'GROUP',
  updates: groupUpdates,
  text: createTextString(routeDetailsObjectTypes.STOPS.GROUP_UPDATES, [groupUpdates.length], ['UPDATE_COUNT']),
  expanded: false,
});

const pushUpdatesOrCreateGroup = (route: any[], groupUpdates: any[]) => {
  if (groupUpdates.length >= ROUTE_GROUPING_THRESHOLD) {
    route.push(createGroup(groupUpdates));
  } else {
    // add accumulation as individual updates
    route.push(...groupUpdates);
  }
};

/**
 * Groups repeated updates with the status IN_TRANSIT
 * @param updates - array of all the formatted updates
 * @returns [array] updated route array
 */
export const mapGroupedUpdates = (updates: any[]): any[] => {
  const groupedRoute: any[] = [];
  let groupUpdates: any[] = [];

  forEach(updates, (update, index) => {
    if (update.label === 'In Transit') {
      groupUpdates.push(update);
      // need to check if have unapplied updates on last entry
      if (index === updates.length - 1) {
        pushUpdatesOrCreateGroup(groupedRoute, groupUpdates);
      }
    } else {
      pushUpdatesOrCreateGroup(groupedRoute, groupUpdates);
      groupedRoute.push(update);

      groupUpdates = [];
    }
  });

  return groupedRoute;
};

/**
 * Removes any subsequent duplicates from both the routeDetails list and any nested updates lists.  Iteration order is preserved.  An entry
 * is considered a duplicate if
 * 1) It has the same id as another entry
 * 2) It is of type "NEW_DAY" and has the same date as another new day entry.
 */
export const dedupGroupUpdates = (routeDetails: any[]): any[] => {
  const uniqueCriteriaSet = new Set<string>();
  const outputRouteDetails: any[] = [];

  forEach(routeDetails, (routeDetailEntry) => {
    if (addIfUnique(uniqueCriteriaSet, routeDetailEntry)) {
      const outputRouteDetailEntry = { ...routeDetailEntry };

      if (isArray(outputRouteDetailEntry.updates)) {
        const outputRouteDetailEntryUpdates: any[] = [];
        forEach(outputRouteDetailEntry.updates, (update) => {
          if (addIfUnique(uniqueCriteriaSet, update)) {
            outputRouteDetailEntryUpdates.push(update);
          }
        });

        outputRouteDetailEntry.updates = outputRouteDetailEntryUpdates;
      }

      outputRouteDetails.push(outputRouteDetailEntry);
    }
  });

  return outputRouteDetails;
};

const addIfUnique = (criteriaSet: Set<string>, entry: { id: string; date: string; type: string }): boolean => {
  if (!isNil(entry.id)) {
    if (!criteriaSet.has(entry.id)) {
      criteriaSet.add(entry.id);
      return true;
    }
    return false;
  } else if (entry.type === 'NEW_DAY' && entry.date) {
    if (!criteriaSet.has(entry.date)) {
      criteriaSet.add(entry.date);
      return true;
    }
    return false;
  }
  return true;
};

/**
 * Creates array of updates that are within a stop and adds new day updates
 * @param updates - array of all updates within the stop
 * @param lastNewDay - date string of last new day line
 * @returns [array] updated route array
 */
export const mapStopUpdates = (updates: any[], lastNewDay: string): any[] => {
  const returnRoute: any[] = [];
  forEach(updates, (update, index) => {
    if (update.type === routeDetailsObjectTypes.TYPES.UPDATE) {
      returnRoute.push(createUpdateInfo(update, routeDetailTypes.UPDATE.INFO));
    }
    if (updates[index + 1]) {
      if (displayNewDay(last(returnRoute), update, updates[index + 1], lastNewDay)) {
        returnRoute.push(createUpdateNewDay(updates[index + 1], routeDetailTypes.UPDATE.NEW_DAY));
      }
    }
  });

  return returnRoute;
};

/**
 * Determines if a new day update should be displayed
 * @param lastValue - object with the previous update
 * @param currentValue - object with the current update
 * @param nextValue - object with the next update
 * @param lastNewDay - date string of last new day line
 * @returns boolean
 */
export const displayNewDay = (
  lastValue: any,
  currentValue: any,
  nextValue: any,
  lastNewDay: string
): boolean | undefined => {
  let currentValueMoment: moment.Moment | undefined;
  if (get(currentValue, 'dateValue')) {
    currentValueMoment = moment(currentValue.dateValue);
  } else if (get(currentValue, 'lastRecordedDateValue')) {
    currentValueMoment = moment(currentValue.lastRecordedDateValue);
  }

  const nextValueMoment = get(nextValue, 'dateValue') ? moment(nextValue.dateValue) : undefined;
  return (
    lastValue &&
    currentValue &&
    nextValueMoment &&
    // Do not display if last update was a new day line
    lastValue.type !== routeDetailsObjectTypes.TYPES.NEW_DAY &&
    // Do not display if the last update was a stop
    lastValue.type !== routeDetailsObjectTypes.TYPES.STOP &&
    // Do not display if the next update is a stop and not completed (no date available)
    // Do not display if the current update is on the same day as the next update
    !isSameDay(currentValueMoment, nextValueMoment) &&
    // Do not display if the last new day update is the same as the next
    (!lastNewDay || !isSameDay(moment(lastNewDay), nextValueMoment))
  );
};

/**
 * Creates new day object with formatted date
 * @param date - date timestamp
 * @param format - original info object structure
 * @returns boolean
 */
export const createUpdateNewDay = (
  date: { dateValue: string; timeZone: string },
  format: { dateFormat: string }
): any => {
  let formattedDate = getDate(date.dateValue, null, null, format.dateFormat);
  const timezone = get(date, 'timeZone');
  if (formattedDate && timezone) {
    formattedDate += ' ' + timezone;
  }
  return {
    ...format,
    date: formattedDate,
  };
};

/**
 * Forms update object
 * @param update - merged update object
 * @param format - original info object structure
 * @returns {object} updated update object
 */
export interface CreateUpdateInfoUpdate {
  id: string;
  derivedStatus: string;
  statusDescription: string;
  tertiary: string;
  dateValue: string;
  latestStatusUpdate: string;
  lastRecordedDateValue: string;
  utcTimestamp?: string;
}
export const createUpdateInfo = (update: CreateUpdateInfoUpdate, format: { dateFormat: string }): any => {
  return {
    ...format,
    label: update.derivedStatus,
    description: update.statusDescription,
    tertiary:
      replace(get(update, 'address.cityState', get(update, 'location.address.cityState', '')), 'null', '') ||
      update.tertiary,
    date: getDateValue(update, format),
    latestStatusUpdate: update.latestStatusUpdate,
    derivedStatus: update.derivedStatus,
    id: update.id,
    lastRecordedDateValue: update.lastRecordedDateValue,
  };
};

export const getDateValue = (update: CreateUpdateInfoUpdate, format: { dateFormat: string }): any => {
  if (!isEmpty(update.dateValue)) {
    return getDate(update.dateValue, null, null, format.dateFormat);
  } else if (!isEmpty(update.utcTimestamp)) {
    return getDate(update.utcTimestamp, null, null, format.dateFormat);
  } else {
    return undefined;
  }
};

/**
 * Creates Stop object
 * @param update - merged update object
 * @param format - original info object structure
 * @param updates - array containing all updates
 * @returns {object} updated stop object
 */
export interface CreateStopInfoUpdate {
  stopType: string;
  stopNumber: number;
  statusCode: string;
  dateValue: string;
  localAppointmentStartDateTime?: string;
  localAppointmentEndDateTime?: string;
}
export const createStopInfo = (update: CreateStopInfoUpdate, format: string, updates: any[]): any => {
  const stop: any = (routeDetailTypes as any).STOP[update.stopType];
  const info: any = (routeDetailTypes as any).STOP[update.stopType].info;
  const appointmentRange: any = (routeDetailTypes as any).STOP[update.stopType].info.appointmentRange;
  const contact: any = (routeDetailTypes as any).STOP[update.stopType].info.contact;
  return {
    ...stop,
    type: routeDetailsObjectTypes.TYPES.STOP,
    stopNumber: update.stopNumber,
    updates,
    // only display divider line if stop is departed, has no departure date, or is the destination
    displayLine:
      (update.statusCode !== 'DEPARTED' && update.stopType !== 'DESTINATION') ||
      (update.stopType !== 'DESTINATION' && !update.dateValue),
    info: {
      ...info,
      cityState: replace(get(update, 'location.address.cityState', ''), 'null', ''),
      appointmentRange: getAppointmentWindow(update, appointmentRange),
      contact: {
        ...contact,
        companyName:
          get(update, 'location.contactDto.companyName') ||
          get(update, 'location.address.portName') ||
          get(update, 'location.address.portCode'),
        contactName: get(update, 'location.contactDto.contactName'),
        email: get(update, 'location.contactDto.email'),
        phoneNumber: get(update, 'location.contactDto.phoneNumber1'),
      },
    },
  };
};

/**
 * Creates string with stop appointment window
 * @param stop - object containing stop information
 * @param text - text string with keys to replace
 * @returns {object} updated stop object
 */
export const getAppointmentWindow = (
  stop: { localAppointmentStartDateTime?: string; localAppointmentEndDateTime?: string },
  text: string
): string | undefined => {
  if (stop.localAppointmentStartDateTime && stop.localAppointmentEndDateTime) {
    const startDate = getDate(stop.localAppointmentStartDateTime, null, null, MONTH_DAY_YEAR_FORMAT);
    const startDateTime = moment(stop.localAppointmentStartDateTime).format(HOURS_MINUTES_FORMAT);
    const endDate = getDate(stop.localAppointmentEndDateTime, null, null, MONTH_DAY_YEAR_FORMAT);
    const endDateTime = moment(stop.localAppointmentEndDateTime).format(HOURS_MINUTES_FORMAT);

    return createTextString(
      text,
      [startDate, startDateTime, endDate, endDateTime],
      ['DATE_START', 'TIME_START', 'DATE_END', 'TIME_END']
    );
  }
};

/**
 * Concatenates status update codes to make formatted status
 * @param statusCode - string containing statusCode from response
 * @param statusReasonCode - string containing statusReasonCode from response
 * @returns {string} derived status text
 */
export const formatStatusCodes = (statusCode: string, statusReasonCode: string): string => {
  let formattedCode = '';
  if (statusCode && statusCode !== 'NONE') {
    formattedCode = statusCode;
  }
  if (statusReasonCode && statusReasonCode !== 'NONE') {
    formattedCode = formattedCode.length > 0 ? `${formattedCode} ${statusReasonCode}` : statusReasonCode;
  }
  return formattedCode.replace('_', ' ');
};
