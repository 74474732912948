import DOMPurify from 'dompurify';
import get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Checkbox, Popover, TextArea } from 'ui-components';

import { MONTH_DAY_YEAR_TIME_FORMAT } from 'i18n/configurei18n';
import { formatUnixTimestamp } from '../../../common/dateUtils';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { ShipmentModeEnum, ShipmentModeFullNameMap } from '../../../models';
import { NotificationDialogComponent } from '../../common/notificationDialog/NotificationDialogComponent';
import { Theme } from '../../settings/BrandTheming/models/Theme';
import { Note, NoteRequest, NoteTypeEnum } from '../models/Note';
import * as styles from './NotesCard.module.scss';

export interface StyledLinkProps {
  theme: Theme;
}

export interface NoteCardProps {
  shipmentId?: string;
  shipmentTenantId?: number;
  masterShipmentId?: string;
  mode?: ShipmentModeEnum;
  isCustomerAdmin: boolean;
  isCreatingNote: boolean;
  isUpdatingNote: boolean;
  isDeletingNote: boolean;
  isNewNote: boolean;
  note?: Note;
  createNote: (note: NoteRequest) => void;
  updateNote: (note: NoteRequest) => void;
  deleteNote: (noteId: string) => void;
  principal?: any;
}

export interface NoteCardState {
  noteRequest: NoteRequest;
  createMode: boolean;
  editMode: boolean;
  isDeleteDialogOpen: boolean;
}

class NoteCard extends React.Component<NoteCardProps, NoteCardState> {
  constructor(props: NoteCardProps) {
    super(props);
    this.state = {
      noteRequest: {
        note: '',
        shipmentId: this.props.shipmentId,
        masterShipmentId: this.props.masterShipmentId,
        noteType: NoteTypeEnum.PUBLIC,
        mode: this.props.mode !== undefined ? ShipmentModeFullNameMap[this.props.mode] : undefined,
      },
      createMode: props.note === undefined,
      editMode: false,
      isDeleteDialogOpen: false,
    };
  }

  public static contextType = ThemeContext;

  public editNote = () => {
    if (this.props.note !== undefined) {
      this.setState({
        noteRequest: {
          noteId: this.props.note.id,
          note: this.props.note.note,
          taggedUsers: this.props.note.taggedUsers,
          shipmentId: this.props.shipmentId,
          masterShipmentId: this.props.masterShipmentId,
          noteType: this.props.note.noteType,
          parentNoteId: this.props.note.parentId,
          mode: this.props.mode !== undefined ? ShipmentModeFullNameMap[this.props.mode] : undefined,
        },
        createMode: false,
        editMode: true,
      });
    }
  };

  cancelEdit = () => {
    this.setState({
      noteRequest: {
        note: '',
        shipmentId: this.props.shipmentId,
        masterShipmentId: this.props.masterShipmentId,
        noteType: NoteTypeEnum.PRIVATE,
        mode: this.props.mode !== undefined ? ShipmentModeFullNameMap[this.props.mode] : undefined,
      },
      createMode: false,
      editMode: false,
    });
  };

  public onNotesTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      noteRequest: {
        ...this.state.noteRequest,
        note: event.target.value,
      },
    });
  };

  public onVisibleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      noteRequest: {
        ...this.state.noteRequest,
        noteType: !event.target.checked ? NoteTypeEnum.PRIVATE : NoteTypeEnum.PUBLIC,
      },
    });
  };

  public onPost = () => {
    const sanitizedNoteRequest = {
      ...this.state.noteRequest,
      note: DOMPurify.sanitize(this.state.noteRequest.note, { ALLOWED_TAGS: [] }),
    };
    if (this.state.createMode) {
      this.props.createNote(sanitizedNoteRequest);
      this.setState({
        noteRequest: {
          ...this.state.noteRequest,
          note: '',
        },
      });
    } else {
      this.props.updateNote(sanitizedNoteRequest);
      this.setState({
        editMode: false,
      });
    }
  };

  toggleDeleteDialog = () => {
    this.setState((prevState) => ({
      isDeleteDialogOpen: !prevState.isDeleteDialogOpen,
    }));
  };

  public onDelete = () => {
    if (this.state.noteRequest.noteId !== undefined) {
      this.props.deleteNote(this.state.noteRequest.noteId);
      this.toggleDeleteDialog();
    }
  };

  public getInitials = (name: string) => {
    const matches = name.match(/\b(\w)/g);
    return matches ? matches.slice(0, 2).join('') : '';
  };

  public getCreatedUserFullName = () => {
    if (this.props.note) {
      return this.props.note.createdByFullName;
    } else {
      return this.props.principal ? this.props.principal.firstName + ' ' + this.props.principal.lastName : '';
    }
  };

  public render() {
    const principalTenantId: number | null = get(this.props, ['principal', 'tenantId'], null);
    const noteCreatedByUserId: boolean = get(this.props, ['note', 'isOwner'], false);

    const isOwnNote =
      noteCreatedByUserId || (this.props.isCustomerAdmin && principalTenantId === this.props.shipmentTenantId);

    const checkboxData = [
      {
        key: get(this.props, ['note', 'id']) || 'newNote',
        label: 'VISIBLE TO EVERYONE',
        checked: this.state.noteRequest.noteType === NoteTypeEnum.PUBLIC,
      },
    ];
    return (
      <div
        data-locator="notes-card"
        className={`${!this.props.note ? styles.newNoteCard : ''} ${styles.noteCard} ${
          this.state.editMode || isOwnNote ? styles.isActive : ''
        }`}
      >
        <div className="d-flex">
          <div className={styles.initials}>{this.getInitials(this.getCreatedUserFullName())}</div>
          <div className={`${styles.notesBody}`}>
            <div className={styles.createdUser}>
              <div className={styles.noteTitleLine}>
                {this.getCreatedUserFullName()} {!this.props.isNewNote && <strong>&middot;</strong>}
                {!this.props.isNewNote
                  ? formatUnixTimestamp(get(this.props, ['note', 'createdDateTime']), MONTH_DAY_YEAR_TIME_FORMAT)
                  : ''}
                <div className={styles.additionalNoteInfo}>
                  {get(this.props, ['note', 'noteType']) === NoteTypeEnum.PRIVATE && (
                    <Popover
                      content={
                        <FormattedMessage
                          id="shipmentDetails.notes.posts.editedPopoverContent"
                          defaultMessage="This note is only visible to members of your organization."
                        />
                      }
                      trigger="hover"
                      placement="bottom"
                    >
                      <FormattedMessage
                        id="shipmentDetails.notes.posts.privateNoteCheckboxLabel"
                        defaultMessage="Not Publicly Visible"
                      />
                    </Popover>
                  )}
                  {moment(get(this.props, ['note', 'lastModifiedDateTime'])).format('x') >
                    moment(get(this.props, ['note', 'createdDateTime'])).format('x') && (
                    <div className={styles.additionalNoteInfo}>
                      <Popover
                        content={formatUnixTimestamp(
                          get(this.props, ['note', 'lastModifiedDateTime']) as number,
                          MONTH_DAY_YEAR_TIME_FORMAT
                        )}
                        trigger="hover"
                        placement="bottom"
                      >
                        {get(this.props, ['note', 'noteType']) === NoteTypeEnum.PRIVATE && (
                          <strong>&nbsp;&middot;&nbsp;</strong>
                        )}
                        <FormattedMessage id="shipmentDetails.notes.posts.editedPost" defaultMessage="Edited" />
                      </Popover>
                    </div>
                  )}
                </div>
              </div>
              {!this.state.createMode && !this.state.editMode && isOwnNote && (
                <button onClick={this.editNote} className={`material-icons left ${styles.editIcon}`}>
                  edit
                </button>
              )}
            </div>
            {this.state.createMode || this.state.editMode ? (
              <div className={styles.noteEdit} data-locator="create-note-textarea-container">
                <TextArea
                  placeholder="Type your comment"
                  onChange={this.onNotesTextChange}
                  value={this.state.noteRequest.note}
                  charLimit={1086}
                  autosize={{ minRows: this.state.createMode ? 1 : 4, maxRows: 4 }}
                />
                <div className={styles.visibilityWrapper}>
                  <span className={styles.visibilityLabel}>
                    <Checkbox className={styles.checkbox} onChange={this.onVisibleChange} checkboxData={checkboxData} />
                  </span>
                  {this.state.createMode && (
                    <Button
                      type="primary"
                      clickFn={this.onPost}
                      loading={this.props.isCreatingNote}
                      disabled={this.state.noteRequest.note === ''}
                    >
                      <FormattedMessage id="shipmentDetails.notes.posts.newNoteAction" defaultMessage="Post" />
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div>{this.props.note ? this.props.note.note : ''}</div>
            )}
          </div>
        </div>
        {this.state.editMode && (
          <div className={styles.buttonsWrapper}>
            <React.Fragment>
              <Button type="destructive-text" className={styles.deleteLink} clickFn={this.toggleDeleteDialog}>
                <FormattedMessage id="shipmentDetails.notes.posts.deleteNoteAction" defaultMessage="Delete" />
              </Button>
              <Button type="destructive-text" className={styles.cancelEditNote} clickFn={this.cancelEdit}>
                <FormattedMessage id="shipmentDetails.notes.posts.cancelNoteAction" defaultMessage="Cancel" />
              </Button>
            </React.Fragment>

            {!this.props.isNewNote && (
              <Button
                type="primary"
                size={'med'}
                clickFn={this.onPost}
                loading={this.props.isUpdatingNote}
                disabled={this.state.noteRequest.note === ''}
              >
                <FormattedMessage id="shipmentDetails.notes.posts.editNoteAction" defaultMessage="Update" />
              </Button>
            )}
          </div>
        )}
        <NotificationDialogComponent
          actionText="Delete"
          actionFn={this.onDelete}
          closeDialog={this.toggleDeleteDialog}
          cancelButton
          loading={this.props.isDeletingNote}
          dialogTitle={
            <FormattedMessage id="shipmentDetails.notes.deleteNoteConfirmation.title" defaultMessage="Are you sure?" />
          }
          fullScreen={false}
          open={this.state.isDeleteDialogOpen}
        >
          <div className={styles.messageContent}>
            <FormattedMessage
              id="shipmentDetails.notes.deleteNoteConfirmation.content"
              defaultMessage="You are about to delete this note. This note will not be retrievable after deleting."
            />
          </div>
        </NotificationDialogComponent>
      </div>
    );
  }
}

export default NoteCard;
