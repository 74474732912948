import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { formatNestedIntlValues } from 'i18n/utils';
import { fontWeightBold } from '../../../styles/variables-p44';

interface StyledSpanProps {
  colorValue: string;
  inverted?: boolean;
}

const StyledSpan = styled.span<StyledSpanProps>`
  font-size: 14px;
  color: #444;
  font-weight: ${fontWeightBold};
  line-height: 1;
  padding: 0.15em 0.5em;
  display: inline-block;
  text-transform: uppercase !important;
  border: 2px solid ${(props) => props.colorValue};
`;

interface StatusColorTagProps {
  colorValue?: string;
  messageText?: { id?: string; defaultMessage: string; values?: any; additionalMessage?: any } | string;
  inverted?: boolean;
  displayAdditionalText?: boolean;
}

const StatusColorTag = (props: StatusColorTagProps) => {
  const intl = useIntl();

  if (!props.colorValue || !props.messageText) {
    return null;
  }

  const { displayAdditionalText = true } = props;

  // TODO: remove this annoying colorValue check once all modes are turned over to #intermodal
  const isAHexColorValue = props.colorValue.includes('#');
  const sanitizedColorValue = props.colorValue.replace('--', '');
  const colorValue = isAHexColorValue ? props.colorValue : `var(--${sanitizedColorValue})`;

  if (typeof props.messageText === 'string') {
    return (
      <StyledSpan className="status-color-tag" colorValue={colorValue} inverted={props.inverted}>
        {props.messageText}
      </StyledSpan>
    );
  } else if (get(props, 'messageText.id') !== undefined) {
    return (
      <StyledSpan className="status-color-tag" colorValue={colorValue} inverted={props.inverted}>
        <FormattedMessage
          id={get(props, 'messageText.id')}
          defaultMessage={get(props, 'messageText.defaultMessage')}
          values={formatNestedIntlValues(get(props, 'messageText.values'), intl)}
        />{' '}
        {!isNil(get(props, 'messageText.additionalMessage.id')) && displayAdditionalText && (
          <FormattedMessage
            id={get(props, 'messageText.additionalMessage.id')}
            defaultMessage={get(props, 'messageText.additionalMessage.defaultMessage')}
            values={formatNestedIntlValues(get(props, 'messageText.additionalMessage.values'), intl)}
          />
        )}
      </StyledSpan>
    );
  } else if (get(props, 'messageText.defaultMessage') !== undefined) {
    return (
      <StyledSpan className="status-color-tag" colorValue={colorValue} inverted={props.inverted}>
        {get(props, 'messageText.defaultMessage')}
      </StyledSpan>
    );
  }
  return null;
};

export default StatusColorTag;
