import { Dispatch, SetStateAction } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import CollapsableItem from 'components/common/ListPageLayout/CollapsableItem';
import TypeValueInputWithChips from 'components/common/TypeValueInputWithChips/TypeValueInputWithChips';
import DateRangeInputWithTitle from 'components/common/DateRangeInputWithTitle/DateRangeInputWithTitle';
import SimpleInputWithChips from 'components/common/SimpleInputWithChips/SimpleInputWithChips';
import OrderType from './filters/OrderType';
import StatusCode from './filters/StatusCode';
import ShipmentAssociated from './filters/ShipmentAssociated';

interface OrdersFiltersInput {
  orderTypescheckedValuesRaw: string[];
  orderTypesSetValuesFn: Dispatch<SetStateAction<string[]>>;

  statusCodescheckedValuesRaw: string[];
  statusCodeSetValuesFn: Dispatch<SetStateAction<string[]>>;

  shipmentAssociatedValueRaw: string | null;
  shipmentAssociatedValueFn: Dispatch<SetStateAction<string>>;
  orderHealthCriteria: any | [];
  setOrderHealthCriteria: Dispatch<SetStateAction<object | undefined>>;
  shipmentStatusList: any[];

  orderSubDateRaw: any;
  setOrderSubDate: any;

  supplierReadyDateRaw: any;
  setSupplierReadyDate: any;

  originalDeliveryDateRaw: any;
  setOriginalDeliveryDate: any;

  locationType: string | undefined;
  setLocationType: Dispatch<SetStateAction<string | undefined>>;
  locationTypeList: any[];
  locationValues: string[];
  setLocationValues: Dispatch<SetStateAction<string[]>>;
  setAdditionalOrderIdType: Dispatch<SetStateAction<string | undefined>>;
  additionalOrderIdType: string | undefined;
  additionalOrderTypeList: any[];
  additionalOrderIdValues: string[];
  setAdditionalOrderIdValues: Dispatch<SetStateAction<string[]>>;
  setOrderTagType: Dispatch<SetStateAction<string | undefined>>;
  orderTagType: string | undefined;
  orderTagTypeList: any[];
  orderTagValues: string[];
  setOrderTagValues: Dispatch<SetStateAction<string[]>>;

  specificOrderNumber: string[];
  setSpecificOrderNumber: Dispatch<SetStateAction<string[]>>;
}

const OrdersFilters = ({
  orderTypescheckedValuesRaw = [],
  orderTypesSetValuesFn,

  statusCodescheckedValuesRaw = [],
  statusCodeSetValuesFn,

  shipmentAssociatedValueRaw = null,
  shipmentAssociatedValueFn,
  shipmentStatusList,
  orderHealthCriteria,
  setOrderHealthCriteria,

  orderSubDateRaw = {},
  setOrderSubDate,

  supplierReadyDateRaw = {},
  setSupplierReadyDate,

  originalDeliveryDateRaw = {},
  setOriginalDeliveryDate,

  locationType,
  setLocationType,
  locationTypeList,
  locationValues,
  setLocationValues,

  setAdditionalOrderIdType,
  additionalOrderIdType,
  additionalOrderTypeList,
  additionalOrderIdValues,
  setAdditionalOrderIdValues,

  setOrderTagType,
  orderTagType,
  orderTagTypeList,
  orderTagValues,
  setOrderTagValues,

  specificOrderNumber,
  setSpecificOrderNumber,
}: OrdersFiltersInput) => {
  const intl = useIntl();

  // handle null values when editing an existing filter
  const orderSubDate = orderSubDateRaw || {};
  const supplierReadyDate = supplierReadyDateRaw || {};
  const originalDeliveryDate = originalDeliveryDateRaw || {};
  const orderTypescheckedValues = orderTypescheckedValuesRaw || [];
  const statusCodescheckedValues = statusCodescheckedValuesRaw || [];
  const shipmentAssociatedValue = shipmentAssociatedValueRaw || null;

  const isDateRangesFilterActive =
    orderSubDate.startDateTime ||
    supplierReadyDate.endDateTime ||
    supplierReadyDate.startDateTime ||
    supplierReadyDate.endDateTime ||
    originalDeliveryDate.startDateTime ||
    originalDeliveryDate.endDateTime;

  const isLocationFilterActive = !isEmpty(locationValues);
  const isAdditionalOrderIdFilterActive = !isEmpty(additionalOrderIdValues);
  const isOrderTagFilterActive = !isEmpty(orderTagValues);
  const isSpecificOrderNumberActive = !isEmpty(specificOrderNumber);

  return (
    <>
      {/* order type filter */}
      <OrderType dataKey="0" checkedValues={orderTypescheckedValues} setValuesFn={orderTypesSetValuesFn} />
      {/* status code filter */}
      <StatusCode dataKey="1" checkedValues={statusCodescheckedValues} setValuesFn={statusCodeSetValuesFn} />
      {/* date range filters */}
      <CollapsableItem
        panelTitle={intl.formatMessage({ id: 'select.orderTable.filters.date', defaultMessage: 'Date Ranges' })}
        dataKey="2"
        activeKey={isDateRangesFilterActive ? '2' : undefined}
      >
        <DateRangeInputWithTitle
          filterName={
            <FormattedMessage id="select.orderTable.filters.date.orderSubDate" defaultMessage="Order Submission Date" />
          }
          datepickerChangeFn={setOrderSubDate}
          boundStartDateValue={orderSubDate.startDateTime}
          boundEndDateValue={orderSubDate.endDateTime}
        />
        <DateRangeInputWithTitle
          filterName={
            <FormattedMessage
              id="select.orderTable.filters.date.supplierReadyDate"
              defaultMessage="Supplier Ready Date"
            />
          }
          datepickerChangeFn={setSupplierReadyDate}
          boundStartDateValue={supplierReadyDate.startDateTime}
          boundEndDateValue={supplierReadyDate.endDateTime}
        />
        <DateRangeInputWithTitle
          filterName={
            <FormattedMessage
              id="select.orderTable.filters.date.originalDueDate"
              defaultMessage="Original Delivery Date"
            />
          }
          datepickerChangeFn={setOriginalDeliveryDate}
          boundStartDateValue={originalDeliveryDate.startDateTime}
          boundEndDateValue={originalDeliveryDate.endDateTime}
        />
      </CollapsableItem>
      {/* location filter */}
      <CollapsableItem
        panelTitle={intl.formatMessage({ id: 'select.orderTable.filters.location', defaultMessage: 'Location' })}
        dataKey="3"
        activeKey={isLocationFilterActive ? '3' : undefined}
      >
        <TypeValueInputWithChips
          selectTitleComponent={
            <FormattedMessage id="input.orderTable.filters.locationType" defaultMessage="Location Type" />
          }
          selectPlaceholder={intl.formatMessage({
            id: 'input.orderTable.filters.location.type',
            defaultMessage: 'Select Type',
          })}
          onSelect={setLocationType}
          selectedValue={locationType}
          selectList={locationTypeList}
          listTitleComponent={
            <FormattedMessage id="select.orderTable.filters.location.values.title" defaultMessage="Location Name" />
          }
          data={locationValues}
          setData={setLocationValues}
          inputPlaceholder={intl.formatMessage({
            id: 'input.orderTable.filters.location.values.placeholder',
            defaultMessage: 'Add Location',
          })}
        />
      </CollapsableItem>
      {/* additional order identifiers filter */}
      <CollapsableItem
        panelTitle={intl.formatMessage({
          id: 'select.orderTable.filters.additionalOrderIds',
          defaultMessage: 'Additional Order IDs',
        })}
        dataKey="4"
        activeKey={isAdditionalOrderIdFilterActive ? '4' : undefined}
      >
        <TypeValueInputWithChips
          selectTitleComponent={
            <FormattedMessage id="select.orderTable.filters.additionalOrderIds.category" defaultMessage="ID Category" />
          }
          selectPlaceholder={intl.formatMessage({
            id: 'input.orderTable.filters.additionalOrderIds.type',
            defaultMessage: 'Select Category',
          })}
          onSelect={setAdditionalOrderIdType}
          selectedValue={additionalOrderIdType}
          selectList={additionalOrderTypeList}
          listTitleComponent={
            <FormattedMessage id="select.orderTable.filters.additionalOrderIds.values" defaultMessage="ID Values" />
          }
          data={additionalOrderIdValues}
          setData={setAdditionalOrderIdValues}
          inputPlaceholder={intl.formatMessage({
            id: 'input.orderTable.filters.additionalOrderIds.values',
            defaultMessage: 'Add ID values',
          })}
        />
      </CollapsableItem>
      {/* order tags filter */}
      <CollapsableItem
        panelTitle={intl.formatMessage({
          id: 'select.orderTable.filters.orderTags',
          defaultMessage: 'Tags',
        })}
        dataKey="5"
        activeKey={isOrderTagFilterActive ? '5' : undefined}
      >
        <TypeValueInputWithChips
          selectTitleComponent={
            <FormattedMessage id="select.orderTable.filters.orderTags.category" defaultMessage="Tag Category" />
          }
          selectPlaceholder={intl.formatMessage({
            id: 'input.orderTable.filters.orderTags.type',
            defaultMessage: 'Select Tag Category',
          })}
          onSelect={setOrderTagType}
          selectedValue={orderTagType}
          selectList={orderTagTypeList}
          listTitleComponent={
            <FormattedMessage id="select.orderTable.filters.orderTags.values" defaultMessage="Tag Values" />
          }
          data={orderTagValues}
          setData={setOrderTagValues}
          inputPlaceholder={intl.formatMessage({
            id: 'input.orderTable.filters.orderTags.values',
            defaultMessage: 'Select Tag values',
          })}
        />
      </CollapsableItem>
      {/* shipment associated filter */}
      <ShipmentAssociated
        dataKey="6"
        selectedValue={shipmentAssociatedValue}
        setValueFn={shipmentAssociatedValueFn}
        shipmentStatusList={shipmentStatusList}
        setOrderHealthCriteria={setOrderHealthCriteria}
        orderHealthCriteria={orderHealthCriteria}
      />
      {/* Specific Order Number Filter */}
      <CollapsableItem
        panelTitle={intl.formatMessage({
          id: 'select.orderTable.filters.specificOrderNumber',
          defaultMessage: 'Specific Order Number',
        })}
        dataKey="7"
        activeKey={isSpecificOrderNumberActive ? '7' : undefined}
      >
        <SimpleInputWithChips
          data={specificOrderNumber}
          setData={setSpecificOrderNumber}
          placeholder={intl.formatMessage({
            id: 'select.orderTable.filters.addOrderNumber',
            defaultMessage: 'Add Order Number',
          })}
        />
      </CollapsableItem>
    </>
  );
};

export default OrdersFilters;
