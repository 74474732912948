import { Dispatch, SetStateAction } from 'react';
import * as React from 'react';
import { Input } from 'antd';
import uniq from 'lodash/uniq';
import SelectedChips from 'components/common/forms/selectedChips/SelectedChips';

interface SimpleInputWithChipsInput {
  data: any[];
  setData: Dispatch<SetStateAction<any[]>>;
  placeholder: string;
  isTypeValueInput?: boolean;
  customOnAdd?: Function | undefined;
  customOnDelete?: Function | undefined;
}

const SimpleInputWithChips = ({
  data,
  setData,
  placeholder,
  isTypeValueInput = false,
  customOnAdd,
  customOnDelete,
}: SimpleInputWithChipsInput) => {
  const [currentValue, setCurrentValue] = React.useState('');

  const onAdd = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const { value } = target;

    if (value === '') return null;

    setCurrentValue('');

    if (isTypeValueInput && customOnAdd) return customOnAdd(value);

    const newData = uniq([...data, value]);

    return setData(newData);
  };

  const onDelete = (deletedItem: string) => {
    if (isTypeValueInput && customOnDelete) return customOnDelete(deletedItem);

    const currentData = [...data];
    const newData = currentData.filter((location) => location !== deletedItem);

    return setData(newData);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const { value } = target;

    setCurrentValue(value);
  };

  return (
    <>
      <Input value={currentValue} placeholder={placeholder} onChange={onChange} onPressEnter={onAdd} />
      <SelectedChips listData={data} showAllChips={false} passedInDeleteFn={onDelete} />
    </>
  );
};

export default SimpleInputWithChips;
