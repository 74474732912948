import { useContext } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import isArray from 'lodash/isArray';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { getPrincipalAuthorizations } from 'common/authorizations';

import CheckList from '../../common/checkList/CheckList';
import filterMessages from '../modes/filterMessages.json';

const messages = defineMessages({
  demurrage: {
    id: 'quickView.oceanDemurrageRiskFilter.demurrage',
    defaultMessage: 'DEMURRAGE',
  },
});

const oceanDemurrageEligibleFilters = [
  {
    id: filterMessages.demurrageRisk.id,
    dataKey: filterMessages.demurrageRisk.defaultMessage,
    labelText: <FormattedMessage {...filterMessages.demurrageRisk} />,
    propertyName: 'demurrageRisk',
    checked: false,
  },
];

export const getOceanDemurrageEligibleValue = (val) => {
  if (isArray(val)) {
    return val;
  }
  if (val) {
    return [filterMessages.demurrageRisk.defaultMessage];
  }
  return [];
};

const OceanDemurrageRiskFilter = (props) => {
  const principalContext = useContext(PrincipalContext);
  const authorizations = getPrincipalAuthorizations(principalContext);
  const isShipmentListFiltersHorizontalEnabled = authorizations.shipmentListFiltersHorizontalEnabled();

  const customProps = isShipmentListFiltersHorizontalEnabled
    ? { listTitle: props.intl.formatMessage(messages.demurrage) }
    : {};

  const currentFilter = getOceanDemurrageEligibleValue(props.filter.oceanDemurrageEligible);

  const checkListData = oceanDemurrageEligibleFilters.map((item) => ({
    ...item,
    checked: currentFilter.includes(item.dataKey),
  }));

  const onCheck = (checkboxItem) =>
    props.handleCheckboxGroupUpdate({ ...checkboxItem, labelText: checkboxItem.dataKey }, 'oceanDemurrageEligible');

  return <CheckList mode={props.checkboxTheme} checkListData={checkListData} checkFn={onCheck} {...customProps} />;
};

export default injectIntl(OceanDemurrageRiskFilter);
