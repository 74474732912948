import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import types from 'components/shipmentCounts/ducks/types';
import { oceanShipmentPortFiltersEnabled } from 'common/authorizations';
import DynamicDateFilter from '../types/DynamicDateFilter';
import ModeFilter from '../types/ModeFilter';
import StatusFilter from '../types/StatusFilter';
import HappeningTodayFilter from '../types/HappeningTodayFilter';
import OceanActiveHoldsFilter from '../types/OceanActiveHoldsFilter';
import OceanDemurrageRiskFilter from '../types/OceanDemurrageRiskFilter';
import OceanLastFreeDateFilter from '../types/OceanLastFreeDateFilter';
import TenantAttributeFilter from '../types/TenantAttributeFilter';
import OceanArrivalFilter from '../types/OceanArrivalFilter';
import LocationFilter from '../types/LocationFilter';
import ArrivalForecastFilter from '../types/ArrivalForecastFilter';
import OceanPortNameFilter from '../types/OceanPortNameFilter';
import PortOfLoadingFilter from '../types/PortOfLoadingFilter';
import TransshipmentPortFilter from '../types/TransshipmentPortFilter';
import PortOfDischargeFilter from '../types/PortOfDischargeFilter';

import { ocean as oceanStatusFilterList } from './statusFilters';

const OceanModeFilters = (props) => (
  <>
    <Row className="mb-4 d-lg-none">
      <ModeFilter {...props} />
    </Row>
    <Row className="mb-4">
      <StatusFilter {...props} menuItems={oceanStatusFilterList} />
    </Row>
    <Row className="mb-4">
      <HappeningTodayFilter
        {...props}
        toolTipContent={
          <FormattedMessage
            id="shipmentsInTransit.tooltip.ocean"
            defaultMessage="The shipment is either arriving at or moving towards its planned destination"
          />
        }
      />
    </Row>
    <Row className="mb-4">
      <OceanActiveHoldsFilter {...props} />
    </Row>
    <Row className="mb-4">
      <ArrivalForecastFilter {...props} />
    </Row>
    <Row className="mb-4">
      {props.quickView ? (
        <Col>
          <DynamicDateFilter dateType="deliveryDate" type={types.QUICK_VIEW_CHANGE_DELIVERY_DATE} />
        </Col>
      ) : (
        <OceanArrivalFilter {...props} />
      )}
    </Row>
    <Row className="mb-4">
      {props.quickView ? (
        <Col>
          <DynamicDateFilter dateType="lastFreeDate" type={types.QUICK_VIEW_CHANGE_LAST_FREE_DATE} />
        </Col>
      ) : (
        <OceanLastFreeDateFilter {...props} />
      )}
    </Row>
    <Row className="mb-4">
      <OceanDemurrageRiskFilter {...props} />
    </Row>
    <Row className="mb-4">
      <LocationFilter {...props} />
    </Row>
    <Row className="mb-4">
      <OceanPortNameFilter {...props} />
    </Row>
    <Row className="mb-4">
      <TenantAttributeFilter {...props} />
    </Row>
    {oceanShipmentPortFiltersEnabled() && (
      <>
        <Row className="mb-4">
          <PortOfLoadingFilter {...props} />
        </Row>
        <Row className="mb-4">
          <TransshipmentPortFilter {...props} />
        </Row>
        <Row className="mb-4">
          <PortOfDischargeFilter {...props} />
        </Row>
      </>
    )}
  </>
);

export default OceanModeFilters;
