import FilterUtil from 'components/filter/util/filterUtil';
import filterMessages from 'components/filter/modes/filterMessages.json';

const emptyFilters = FilterUtil.getEmptyFilter();

const HAPPENING_TODAY_FILTER = {
  // matches filter object in shipment list
  ...emptyFilters,
  oceanDemurrageEligible: null,
  happeningCode: ['Happening Today'],
};

const ALL_MODE_IN_PROGRESS_FILTER = {
  ...emptyFilters,
  oceanDemurrageEligible: null,
  status: [filterMessages.inTransit.defaultMessage, filterMessages.atStop.defaultMessage],
};

export default {
  HAPPENING_TODAY_FILTER,
  ALL_MODE_IN_PROGRESS_FILTER,
};
