export interface TaggedUser {
  email: string;
  firstName: string;
  lastName: string;
}

export interface Note {
  note: string;
  id: string;
  taggedUsers?: TaggedUser[];
  createdByFullName: string;
  createdTime: number;
  lastModifiedByFullName?: string;
  lastModifiedDateTime?: number;
  isOwner: boolean;
  parentId?: string;
  noteType: NoteTypeEnum;
  shipmentLegId: string;
  masterShipmentId?: string;
}

export interface NoteRequest {
  masterShipmentId?: string;
  noteId?: string;
  note: string;
  taggedUsers?: TaggedUser[];
  noteType: NoteTypeEnum;
  parentNoteId?: string;
  shipmentLegId?: string;
  shipmentId?: string;
  mode?: string;
}

export enum NoteTypeEnum {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}
