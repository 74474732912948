import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import { Chips } from 'ui-components';
import { IdentifierType } from 'components/shipmentDetails/LTL/Identifiers/Identifiers';
import { ShipmentSearchCardData } from 'models';

import * as styles from './MatchedSearchItems.module.scss';

const formatMatchedSearchTerm = ({ label = '', value = '' } = {}): JSX.Element => {
  return (
    <span>
      {label}{' '}
      <strong>
        {isNaN(+value) ? '' : '#'}
        {value}
      </strong>
    </span>
  );
};

const hasSearchTerm =
  (searchTerm = '') =>
  (data: SearchableItem): boolean => {
    const termsArray = searchTerm
      .split(' ')
      .map((term) => term.toString())
      .map((term) => term.toUpperCase());

    const dataIdentifier = get(data, 'value', '').toUpperCase();

    const isPresent = termsArray.filter((term) => dataIdentifier.includes(term)).length;

    return !!isPresent;
  };

interface SearchableItem {
  // TODO: when attributes are implemented for Parcel, make sure value is coming through and remove the ?
  value?: string;
  label: string;
}

export const matchedSearchItems = (
  { lineItems = [], identifiers = [], tenantCustomAttributes = [] }: ShipmentSearchCardData,
  searchTerm = ''
) => {
  if (lineItems.length + identifiers.length + tenantCustomAttributes.length <= 0) {
    return null;
  }

  const checkSearchTerm = hasSearchTerm(searchTerm);

  // custom tenant attributes
  const customAttributes: SearchableItem[] = tenantCustomAttributes.reduce(
    (allAttributes: SearchableItem[], { attributeName, attributeValues = [] }) => {
      // Flatten the `attributeValues` array into a searchable item array
      const normalizedValues = attributeValues.reduce(
        (acc: SearchableItem[], val) => [...acc, { value: val, label: attributeName }],
        []
      );

      // Combine the flattened array, with previously flattened arrays
      return [...allAttributes, ...normalizedValues];
    },
    []
  ); // normalize

  // TL line items
  const lineItemIdentifiers: SearchableItem[] = lineItems
    .reduce((acc: IdentifierType[], { itemIdentifiers = [] }) => {
      return [...acc, ...itemIdentifiers];
    }, []) // flatten
    .map(({ type, value }) => ({ label: type, value })); // normalize

  const searchMatches = [...customAttributes, ...(identifiers as SearchableItem[]), ...lineItemIdentifiers]
    .filter(checkSearchTerm)
    .map(formatMatchedSearchTerm); // find and format

  return <Chips listData={searchMatches} hideDelete chipClass={styles['chip__matched-search']} />;
};

export default matchedSearchItems;
