import axios from 'axios';
import { put } from 'redux-saga/effects';
import { trackEvent } from 'common/eventTracker';
import { LOGOUT } from '../components/login/LoginForm/ducks/types';

const isError = (response) => response && response.status && (response.status < 200 || response.status >= 300);

// by default axios rejects promises with an object that has the response as key 'response'
const reject = (response) => Promise.reject({ response: response });

/**
 * "P"roject44 "Axios" - Allows P44 to decorate Axios with global interceptors.
 * Note: adding the interceptor replaces the default error handling of axios,
 * so in addition to our custom logic, we have to replicate the default behavior.
 * Custom behavior:
 * putting the LOGOUT action if response status is 401 or there is no response.
 */
axios.interceptors.response.use(
  (r) => r,
  ({ response }) => {
    if (!response || response.status === 401) {
      trackEvent('USER_UNATHORIZED');
      put({ type: LOGOUT });
      return reject(response);
    } else if (isError(response)) {
      return reject(response);
    } else {
      return response;
    }
  }
);

export default axios;
