import { useState, useEffect, cloneElement } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import qs from 'qs';
import get from 'lodash/get';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import { SVGIcon } from 'ui-components';
import { black, white, matisse, primaryGreyTwenty, primaryBlue, primaryGrey, primaryGreyFive } from 'styles/colors';

import { trackEvent } from 'common/eventTracker';
import ALLMODESFilters from './ALLMODESFilters';
import TLFilters from './TLFilters';
import LTLFilters from './LTLFilters';
import OCEANFilters from './OCEANFilters';
import PARCELFilters from './PARCELFilters';
import AIRFilters from './AIRFilters';
import RAILFilters from './RAILFilters';
import Chips from './Chips';

import FilterUtil from '../../../filter/util/filterUtil';

import { messages, ModeType } from '../utils';

interface FiltersProps {
  modeActive: ModeType;
  hasFilters: boolean;
}

interface FilterModalProps {
  showModal: boolean;
  onCancelSearch: () => void;
  filterContent: JSX.Element;
  name: string;
  onSaveSearch: () => void;
  classNameContent?: string;
  customHeader?: boolean;
}

interface FilterButtonProps {
  name: string;
  prefixIcon?: JSX.Element;
  filterContent: JSX.Element;
  showModal: any;
  setShowModal: (obj: object) => void;
  onSearch: () => void;
  modalId: string;
  filters: any;
  onDeleteChips: (obj: object) => void;
  mode: ModeType;
  classNameContent?: string;
  onCancelFilters: () => void;
  customHeader?: boolean;
  customerSuggestions: object[];
}

interface FilterItemProps {
  name: any;
  component: any;
  modalId: string;
  classNameContent?: string;
  customHeader?: boolean;
}

const StyledDropdownContainer = styled('div')`
  & > .ant-btn {
    margin-top: 8px;
    &:first-child {
      margin-top: 0;
    }
  }
`;

const StyledOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledModalContainer = styled('div')`
  background-color: rgba(45, 41, 38, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
`;

const StyledModal = styled('div')`
  background-color: ${white};
  border: 1px solid ${primaryGreyTwenty};
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
`;

const StyledModalWrapper = styled('div')`
  margin-right: 15px;
  margin-left: 15px;
  z-index: 11;
  max-height: 85vh;
  margin: auto;
  min-width: 360px;
`;

const StyledButton = styled(Button)`
  border: 0 none;
  box-shadow: none;
  border-radius: 4px !important;
  color: ${primaryGrey};
  height: 32px;
  padding: 0px 14px 0 8px;
  &:hover,
  &:active,
  &:focus {
    color: ${black};
  }
  &:focus {
    outline: none;
  }
  .icon-add-circle {
    color: ${primaryBlue};
  }
`;

const StyledH4 = styled('h4')`
  color: ${black};
  font-size: 13px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  margin-bottom: 0;
`;

const StyledHeaderContainer = styled('div')`
  margin-bottom: 8px;
`;

const StyledClearButton = styled(Button)`
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  padding: 0 7px;
  text-transform: uppercase;
`;

const StyledButtonClose = styled(Button)`
  background-color: ${primaryGreyFive};
  border: 1px solid transparent;
  border-radius: 100px !important;
  color: ${primaryBlue};
  font-size: 14px;
  padding: 9px;
  height: auto;
  line-height: 0;
`;

const StyledButtonCancel = styled(Button)`
  border-color: ${primaryBlue};
  color: ${primaryBlue};
  height: 32px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 8px;
`;

const StyledButtonSave = styled(Button)`
  height: 32px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;

const StyledModalHeader = styled('div')`
  border-bottom: 1px solid ${primaryGreyTwenty};
  padding: 16px 32px 15px;
`;

const StyledModalContent = styled('div')`
  padding: 16px 32px;
  overflow-y: auto;
  max-height: calc(85vh - 131px);
`;

const StyledModalFooter = styled('div')`
  border-top: 1px solid ${primaryGreyTwenty};
  padding: 16px 32px;
`;

const StyledModalHeading = styled('h1')`
  color: ${primaryGrey};
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 0;
`;

const StyledFilterContainer = styled('div')`
  border: 1px solid ${primaryGreyTwenty};
  border-radius: 4px !important;
  background-color: white;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  margin-bottom: 8px;

  &:hover {
    border-color: ${matisse};
  }

  &:focus {
    outline: 2px solid rgba(0, 85, 139, 0.2);
  }

  &.is-active {
    color: ${black};
    border-color: ${matisse};
  }
`;

const FilterModal = ({
  showModal,
  onCancelSearch,
  filterContent,
  name,
  onSaveSearch,
  classNameContent = '',
  customHeader,
}: FilterModalProps) => {
  if (showModal) {
    return (
      <>
        <StyledModalContainer className="d-flex justify-content-center align-items-start">
          <StyledOverlay onClick={onCancelSearch} />
          <StyledModalWrapper>
            <StyledModal>
              {!customHeader && (
                <StyledModalHeader>
                  <div className="d-flex justify-content-between align-items-center">
                    <StyledModalHeading>{name}</StyledModalHeading>
                    <StyledButtonClose onClick={onCancelSearch} className="ml-16">
                      <SVGIcon name="close" />
                    </StyledButtonClose>
                  </div>
                </StyledModalHeader>
              )}
              <StyledModalContent className={classNameContent}>
                {cloneElement(filterContent, { modalName: name, closeModal: onCancelSearch })}
              </StyledModalContent>
              <StyledModalFooter>
                <div className="d-flex justify-content-end">
                  <StyledButtonCancel type="ghost" onClick={onCancelSearch}>
                    <FormattedMessage {...messages.cancel} />
                  </StyledButtonCancel>
                  <StyledButtonSave type="primary" onClick={onSaveSearch}>
                    <FormattedMessage {...messages.save} />
                  </StyledButtonSave>
                </div>
              </StyledModalFooter>
            </StyledModal>
          </StyledModalWrapper>
        </StyledModalContainer>
      </>
    );
  }

  return null;
};

const FilterButton = ({
  name,
  prefixIcon,
  filterContent,
  showModal,
  setShowModal,
  onSearch,
  modalId,
  filters,
  onDeleteChips,
  mode,
  classNameContent,
  onCancelFilters,
  customHeader,
  customerSuggestions,
}: FilterButtonProps) => {
  const onClickHandler = () => setShowModal({ [modalId]: !showModal[modalId] });

  const onCloseModal = () => setShowModal({ [modalId]: false });

  const onCancelSearch = async () => {
    await onCancelFilters();
    return onCloseModal();
  };
  const onSaveSearch = () => {
    onCloseModal();
    return onSearch();
  };

  const isActive = !!showModal[modalId];

  return (
    <>
      <StyledFilterContainer className={`${isActive ? 'is-active' : ''}`}>
        <StyledButton onClick={onClickHandler} className="ant-btn-block">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {prefixIcon}
              {name}
            </div>
            <SVGIcon name="add-circle" className="icon-add-circle" />
          </div>
        </StyledButton>
        <Chips
          filters={filters}
          modalId={modalId}
          onDeleteChips={onDeleteChips}
          mode={mode}
          customerSuggestions={customerSuggestions}
        />
      </StyledFilterContainer>
      <FilterModal
        name={name}
        showModal={isActive}
        onCancelSearch={onCancelSearch}
        onSaveSearch={() => {
          trackEvent('SHIPMENT_LIST_ADD_FILTER', {
            key: modalId,
          });
          onSaveSearch();
        }}
        filterContent={filterContent}
        classNameContent={classNameContent}
        customHeader={customHeader}
      />
    </>
  );
};

export const renderFiltersByMode = (mode: ModeType | undefined, props: any) => {
  switch (mode) {
    case 'OCEAN':
      return OCEANFilters(props);
    case 'AIR':
      return AIRFilters(props);
    case 'RAIL':
      return RAILFilters(props);
    case 'TL':
      return TLFilters(props);
    case 'LTL':
      return LTLFilters(props);
    case 'PARCEL':
      return PARCELFilters(props);
    default:
      return ALLMODESFilters(props);
  }
};

const buildAttributeState = (attributeKeyValuePairs: any) => {
  return attributeKeyValuePairs.map((item: any) => {
    return item.values.map((val: string) => {
      return `${item.name}:${val}`;
    });
  });
};

const areAllDerivedStatusFiltersApplied = (filters: any, modes: any) => {
  const currenStatusFilters = filters?.status ? filters.status : [];
  const modeSpecificFilters = FilterUtil.translateStatusFiltersByMode(modes[0]);
  if (currenStatusFilters.length !== modeSpecificFilters.length) return false;
  currenStatusFilters.forEach((filter: string) => {
    if (modeSpecificFilters.indexOf(filter) !== -1) return false;
  });
  return true;
};

const Filters = ({ modeActive, hasFilters, ...rest }: FiltersProps) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState({});
  const urlParams = get(history, 'location.search', '');
  const querySearch = qs.parse(urlParams, { ignoreQueryPrefix: true, arrayLimit: 100 });
  const urlFilters = get(querySearch, 'filter', {});
  const querySearchHistory = FilterUtil.getSearchQueryFromURL(history.location.search);
  const { filter, sortFields, modeFilters, searchTerm, toggleLoadFilter, customerSuggestions }: any = rest;

  const triggerSearch = (filters: object, sort: object, modes: string[], searchText: string, multiSearch: boolean) => {
    const shipmentInProgress = areAllDerivedStatusFiltersApplied(filters, modes);
    const queryURL = FilterUtil.buildQueryURl(filters, sort, modes, searchText, multiSearch);
    shipmentInProgress
      ? window.sessionStorage.setItem('isShipmentInProgress', 'true')
      : window.sessionStorage.setItem('isShipmentInProgress', 'false');
    return history.push(`${history.location.pathname}${queryURL}`);
  };

  const onSearch = () =>
    triggerSearch(filter, sortFields, modeFilters, searchTerm, querySearchHistory.enableMultiValueSearch);

  const onClearAll = () => triggerSearch({}, sortFields, modeFilters, '', false);

  const onDeleteChips = (filtersData: object) => triggerSearch(filtersData, sortFields, modeFilters, '', false);

  const onCancelFilters = () => {
    const attributeKeyValuePairs = get(querySearch, 'filter.attributeKeyValuePairs', []);
    const attribute = buildAttributeState(attributeKeyValuePairs).flat();

    return toggleLoadFilter({
      filter: Object.assign({}, FilterUtil.getEmptyFilter(), urlFilters, attribute),
    });
  };

  useEffect(() => {
    if (!isEmpty(showModal) && get(values(showModal), '0', false)) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showModal]);

  const filtersByMode = renderFiltersByMode(modeActive, rest);

  return (
    <>
      <StyledHeaderContainer className="d-flex align-items-center justify-content-between">
        <StyledH4>
          <FormattedMessage {...messages.filters} />
        </StyledH4>
        {hasFilters && (
          <StyledClearButton type="link" onClick={onClearAll}>
            <FormattedMessage {...messages.clearAll} />
          </StyledClearButton>
        )}
      </StyledHeaderContainer>
      <StyledDropdownContainer>
        {filtersByMode.map(
          ({ name, component: Component, modalId, classNameContent, customHeader = false }: FilterItemProps) => (
            <FilterButton
              key={modalId}
              name={name}
              filterContent={<Component {...rest} isShipmentListFiltersHorizontalEnabled />}
              showModal={showModal}
              setShowModal={setShowModal}
              onSearch={onSearch}
              modalId={modalId}
              filters={get(querySearch, 'filter', {})}
              onDeleteChips={(filtersData) => {
                trackEvent('SHIPMENT_LIST_REMOVE_FILTER', {
                  key: get(keys(filtersData), '[0]'),
                });
                onDeleteChips(filtersData);
              }}
              mode={modeActive}
              classNameContent={classNameContent}
              onCancelFilters={onCancelFilters}
              customHeader={customHeader}
              customerSuggestions={customerSuggestions}
            />
          )
        )}
      </StyledDropdownContainer>
    </>
  );
};

export default Filters;
