import moment from 'moment-timezone';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import CalendarRange from '../../common/calendarRange/CalendarRange';

const getOptionalQuickViewTileDateRangeSelection = (propsChildren) => {
  let option = null;
  if (propsChildren && propsChildren.length) {
    propsChildren.forEach((child) => {
      if (child.props.id === 'Pickup Date') {
        option = child;
        return;
      }
    });
  }
  return option;
};

const PickupFilter = (props) => (
  <>
    {getOptionalQuickViewTileDateRangeSelection(props.children)}
    <Col>
      <label htmlFor="pickup-filter">
        {
          <FormattedMessage
            id="shipmentList.pickupFilter.pickUpDateRangeWithTimezoneWithTimezone"
            defaultMessage="PICK-UP DATE RANGE ({timezone})"
            values={{
              timezone: moment.tz(moment.tz.guess()).zoneAbbr(),
            }}
          />
        }
      </label>
      <CalendarRange
        datepickerStartChangeFn={(date) => props.handleDatePickerChange(date, 'pickupDateStart')}
        datepickerEndChangeFn={(date) => props.handleDatePickerChange(date, 'pickupDateEnd')}
        boundStartDateValue={props.filter.pickupDateStart}
        boundEndDateValue={props.filter.pickupDateEnd}
        showTime
        id="pickup-filter"
      />
    </Col>
  </>
);

export default PickupFilter;
