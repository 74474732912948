import { defineMessages, IntlShape } from 'react-intl';
import { ShipmentModeEnum } from 'models';

const message = defineMessages({
  bol: {
    id: 'shipmentList.shipmentIdentifiersMapper.bol',
    defaultMessage: 'BOL',
  },
  container: {
    id: 'shipmentList.shipmentIdentifiersMapper.container',
    defaultMessage: 'Container',
  },
  tracking: {
    id: 'shipmentList.shipmentIdentifiersMapper.tracking',
    defaultMessage: 'Tracking',
  },
  at: {
    id: 'shipmentList.listCardText.at',
    defaultMessage: 'At',
  },
  by: {
    id: 'shipmentList.listCardText.by',
    defaultMessage: 'By',
  },
  airWaybill: {
    id: 'shipmentList.shipmentIdentifiersMapper.airWaybill',
    defaultMessage: 'Air Waybill Number',
  },
});

// Default shipment identifier to show on card per mode
const ShipmentIdentifierByModeMap: { [P in ShipmentModeEnum]: { id: string; defaultMessage: string } } = {
  [ShipmentModeEnum.TL]: message.bol,
  [ShipmentModeEnum.LTL]: message.bol,
  [ShipmentModeEnum.OCEAN]: message.container,
  [ShipmentModeEnum.AIR]: message.airWaybill,
  [ShipmentModeEnum.RAIL]: message.bol,
  [ShipmentModeEnum.INTERMODAL]: message.bol,
  [ShipmentModeEnum.PARCEL]: message.tracking,
};

export const getFormattedShipmentIdentifierByMode = (mode: ShipmentModeEnum, intl: IntlShape): string => {
  return intl.formatMessage(ShipmentIdentifierByModeMap[mode]);
};
