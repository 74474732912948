import get from 'lodash/get';
import find from 'lodash/find';
import * as DateUtils from '../../../../common/dateUtils';

const findStopByStopNumber = (stops, stopNumber) => find(stops, (stop) => stop.stopNumber === stopNumber);

const getAddress = (stop) => get(stop, 'location.address');
const getContact = (stop) => get(stop, 'location.contactDto');

const parsedDateTimeOrEmpty = (localDateTime) => {
  const parsedDateTime = DateUtils.parseLocalDateTime(localDateTime);
  return parsedDateTime ? parsedDateTime : { date: null, time: null };
};

const getStartDateTime = (stop) => parsedDateTimeOrEmpty(stop.localAppointmentStartDateTime);
const getEndDateTime = (stop) => parsedDateTimeOrEmpty(stop.localAppointmentEndDateTime);

const getCountry = (address) => {
  return get(address, 'countryCodeDto', null);
};

const getCompany = (contact) => get(contact, 'companyName', null);
const getAddressLine1 = (address) => {
  const addressLines = get(address, 'addressLines', []);
  return addressLines.length ? addressLines[0] : null;
};

const getAddressLine2 = (address) => {
  const addressLines = get(address, 'addressLines', []);
  return addressLines.length ? addressLines[1] : null;
};

const getZipCode = (address) => get(address, 'postalCode', null);
const getCity = (address) => get(address, 'city', null);
const getState = (address) => get(address, 'state', null);

const getName = (contact) => {
  const nameTokens = (get(contact, 'contactName') || '').split(/\s+/);
  const name = { first: null, last: null };

  if (nameTokens.length >= 2) {
    name.first = nameTokens.slice(0, -1).join(' ');
    name.last = nameTokens[nameTokens.length - 1];
  } else if (nameTokens.length === 1 && nameTokens[0]) {
    name.last = nameTokens[0];
  }

  return name;
};

const getPhoneNumber = (contact) => get(contact, 'phoneNumber1', null);
const getEmailAddress = (contact) => get(contact, 'email', null);

export default class EditShipmentStopStateToFormMapper {
  static mapStopStateWithStopNumberToEditForm(allStopsState, stopNumber) {
    const stop = findStopByStopNumber(allStopsState, stopNumber);
    if (!stop) {
      return null;
    }

    return EditShipmentStopStateToFormMapper.mapStopStateToEditForm(stop);
  }

  static mapStopStateToEditForm(stop) {
    const address = getAddress(stop);
    const contact = getContact(stop);
    const name = getName(contact);
    const startDateTime = getStartDateTime(stop);
    const endDateTime = getEndDateTime(stop);

    return {
      // The structure of the form has a <FormSection> with name "address" nesting the below elements
      address: {
        company: getCompany(contact),
        addressLine1: getAddressLine1(address),
        addressLine2: getAddressLine2(address),
        country: getCountry(address),
        zipCode: getZipCode(address),
        city: getCity(address),
        state: getState(address),
        startDate: startDateTime.date,
        startTime: startDateTime.time,
        endDate: endDateTime.date,
        endTime: endDateTime.time,
      },
      firstName: name.first,
      lastName: name.last,
      phoneNumber: getPhoneNumber(contact),
      email: getEmailAddress(contact),
    };
  }
}
