import { ActionType, getType } from 'typesafe-actions';
import { Reducer } from 'redux';

import { Theme } from '../models/Theme';
import * as brandThemingActions from './actions';
import defaultTheme from '../defaultTheme';

export type BrandThemingAction = ActionType<typeof brandThemingActions>;

export interface BrandThemingState {
  readonly isSaving: boolean;
  readonly isFetching: boolean;
  readonly savedTheme?: Theme;
  readonly selectedTheme?: Theme;
}

const initialState: BrandThemingState = {
  isSaving: false,
  isFetching: false,
  savedTheme: undefined,
  selectedTheme: undefined,
};

const brandThemingReducer: Reducer<BrandThemingState, BrandThemingAction> = (state = initialState, action) => {
  switch (action.type) {
    case getType(brandThemingActions.updateSelectedTheme): {
      return {
        ...state,
        selectedTheme: action.payload,
      };
    }
    case getType(brandThemingActions.save.request): {
      return {
        ...state,
        isSaving: true,
      };
    }
    case getType(brandThemingActions.save.success): {
      return {
        ...state,
        isSaving: false,
        savedTheme: action.payload,
        selectedTheme: action.payload,
      };
    }
    case getType(brandThemingActions.save.failure): {
      return {
        ...state,
        isSaving: false,
      };
    }
    case getType(brandThemingActions.fetch.request): {
      return {
        ...state,
        isFetching: true,
      };
    }
    case getType(brandThemingActions.fetch.success): {
      return {
        ...state,
        isFetching: false,
        savedTheme: action.payload,
        selectedTheme: action.payload,
      };
    }
    case getType(brandThemingActions.fetch.failure): {
      // Defaults to default theme if it can't fetch so the app still loads
      return {
        ...state,
        isFetching: false,
        savedTheme: state.savedTheme || defaultTheme,
        selectedTheme: state.selectedTheme || defaultTheme,
      };
    }
    case getType(brandThemingActions.revertSelectedTheme): {
      return {
        ...state,
        selectedTheme: state.savedTheme,
      };
    }
    default: {
      return state;
    }
  }
};

export default brandThemingReducer;
