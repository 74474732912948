import qs from 'querystring';
import { useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isUndefined from 'lodash/isUndefined';
import ReactAutosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

import { SVGIcon } from 'ui-components';
import { primaryGreyEighty, primaryBlue, primaryGreyFive, primaryGrey, primaryGreyTwenty } from 'styles/colors';

import { FILTER_RESULT_DELAY } from 'common/AppConstants';
import useDebounce from 'hooks/useDebounce';

import useAttributeValues from '../../../../filter/util/useAttributeValues';

const StyledResultsLabel = styled('p')`
  color: ${primaryGreyEighty};
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 14px;
  text-transform: uppercase;
`;

const StyledResultsContainer = styled('div')`
  max-height: 480px;
  overflow-y: auto;
`;

const StyledResultItem = styled('div')`
  border-radius: 4px;
  cursor: pointer;
  padding: 9px 14px 9px 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: rgba(45, 41, 38, 0.05);
  }

  &:active {
    background-color: rgba(0, 85, 139, 0.2);
  }
`;

const StyledButtonBack = styled('button')`
  align-items: center;
  background: none;
  border: 0 none;
  color: ${primaryBlue};
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  text-transform: uppercase;
  margin-bottom: 16px;

  .icon {
    background: ${primaryGreyFive};
    border-radius: 100px;
    font-size: 14px;
    height: 32px;
    margin-right: 8px;
    position: relative;
    width: 32px;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const StyledTitle = styled('p')`
  color: ${primaryGrey};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
`;

const StyledModalHeader = styled('div')`
  border-bottom: 1px solid ${primaryGreyTwenty};
  padding: ${(props) => (props.isQuickViewSetup ? '0 0 15px' : '16px 32px 15px')};
  margin: ${(props) => (props.isQuickViewSetup ? '0 0 16px' : '-16px -32px 16px')};
`;

const theme = {
  container: 'autosuggest__container',
  containerOpen: 'autosuggest__container--open',
  input: 'autosuggest__input',
  inputOpen: 'autosuggest__input--open',
  inputFocused: 'autosuggest__input--focused',
  suggestionsContainer: 'autosuggest__suggestions-container',
  suggestionsContainerOpen: 'autosuggest__suggestions-container--open',
  suggestionsList: 'autosuggest__suggestions-list',
  suggestion: 'autosuggest__suggestion suggestion-content text-tuna',
  suggestionFirst: 'autosuggest__suggestion--first',
  suggestionHighlighted: 'autosuggest__suggestion--highlighted',
  sectionContainer: 'autosuggest__section-container',
  sectionContainerFirst: 'autosuggest__section-container--first',
  sectionTitle: 'autosuggest__section-title',
};

const ValuesStep = ({ currentKey, setCurrentStep, onSelect, attributeKeyValuePairs, isQuickViewSetup }) => {
  const [currentValue, setCurrentValue] = useState('');
  const debouncedValue = useDebounce(currentValue, FILTER_RESULT_DELAY);
  const params = qs.stringify({ q: debouncedValue });
  const attrValues = useAttributeValues(currentKey.id, currentKey.value, params);
  const matchResults = find(attributeKeyValuePairs, (item) => item.name === currentKey.value);

  const renderSuggestion = (suggestion, { query }) => {
    const attributeValue = get(suggestion, 'value');
    const matches = AutosuggestHighlightMatch(attributeValue, query);
    const parts = AutosuggestHighlightParse(attributeValue, matches);
    const isChecked = includes(matchResults?.values, attributeValue);

    return (
      <StyledResultItem>
        <input type="checkbox" checked={isChecked} readOnly className="mr-2" />
        {parts.map((part) => {
          const highlight = get(part, 'highlight');
          const text = get(part, 'text');

          if (highlight)
            return (
              <span key={suggestion.id} className="font-weight-bold">
                {text}
              </span>
            );
          return text;
        })}
      </StyledResultItem>
    );
  };

  const getSuggestionValue = (suggestion) => {
    onSelect(suggestion.id);
  };

  const onChange = (event, { newValue }) => {
    if (!isUndefined(newValue)) {
      setCurrentValue(newValue);
    }
  };

  const inputProps = {
    placeholder: 'Search by name',
    value: currentValue,
    onChange,
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    if (!isUndefined(value)) {
      setCurrentValue(value);
    }
  };

  const renderInputComponent = (inputProps) => (
    <div>
      <input {...inputProps} />
      <StyledResultsLabel>{attrValues.length} Results</StyledResultsLabel>
    </div>
  );

  return (
    <div>
      <StyledModalHeader isQuickViewSetup={isQuickViewSetup}>
        <StyledButtonBack onClick={() => setCurrentStep(0)}>
          <span className="icon">
            <SVGIcon name="chevron-left" />
          </span>
          Back
        </StyledButtonBack>
        <StyledTitle>{currentKey.value}</StyledTitle>
      </StyledModalHeader>
      <StyledResultsContainer>
        <ReactAutosuggest
          suggestions={attrValues}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          inputProps={inputProps}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          alwaysRenderSuggestions
          focusInputOnSuggestionClick={false}
          theme={theme}
        />
      </StyledResultsContainer>
    </div>
  );
};

export default ValuesStep;
