class AddressStateMapper {
  static mapApiToState(data) {
    return (data || []).map((entry) => ({
      postalCode: entry.postalCode,
      city: entry.city,
      state: entry.state,
      country: entry.country || 'US',
      timezone: entry.timezone,
    }));
  }
}

export default AddressStateMapper;
