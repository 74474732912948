import * as React from 'react';
import styled from 'styled-components';
import { Tags } from 'ui-components';
import { dustGray, errorRed } from 'styles/colors';

const StyledLabel = styled('label')`
  align-items: flex-start;
  align-content: flex-start;
  border: 1px solid ${dustGray};
  display: flex;
  flex-wrap: wrap;
  height: 294px;
  padding: 11px;
  overflow-y: auto;
  cursor: text;

  @media (max-width: 767px) {
    height: 243px;
  }

  &.has-error {
    border-color: ${errorRed};
  }
`;

const StyledInput = styled('input')`
  border: 0 none;
  flex-grow: 1;
  display: inline-block;
  min-width: 110px;
  line-height: inherit;
  position: relative;
  white-space: pre-wrap;
  color: inherit;
  box-sizing: inherit;
  padding: 0;
`;

const StyledTags = styled(Tags)`
  margin-bottom: 10px !important;
`;

export interface TagsAreaProps {
  handleRemoveTag: (tag: string) => void;
  onKeyPress: () => void;
  onKeyUp: () => void;
  onKeyDown: () => void;
  onPaste: () => void;
  onChange: () => void;
  value: string;
  tags: any[];
  placeholder: string;
  tagColor: string;
  hasError: boolean;
  dataLocator: string;
}

const TagsArea: React.FunctionComponent<TagsAreaProps> = ({
  handleRemoveTag,
  onKeyPress,
  onKeyUp,
  onKeyDown,
  onPaste,
  onChange,
  value,
  tags,
  placeholder,
  tagColor,
  hasError,
  dataLocator,
}) => {
  const className = hasError ? 'has-error' : '';

  return (
    <StyledLabel htmlFor="multi-value-search" className={className}>
      {Array.from(tags).map((tag) => {
        return (
          <StyledTags
            closable
            color={tagColor}
            key={tag}
            onClose={() => handleRemoveTag(tag)}
            data-locator={`data-locator-${tag}`}
          >
            {tag}
          </StyledTags>
        );
      })}
      <StyledInput
        id="multi-value-search"
        type="text"
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        autoComplete="off"
        data-locator={dataLocator}
      />
    </StyledLabel>
  );
};

export default TagsArea;
