import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AimOutlined, ClockCircleOutlined, EnvironmentFilled, UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import moment from 'moment';
import { LONG_DAYS_OF_WEEK, HOURS_MINUTES_AM_PM_FORMAT } from 'common/AppConstants';
import * as styles from './LocationDetailsComponent.module.scss';
import { LocationDetails, Contact as ContactModel } from './models';
import Contact from './Contact';

const Address = styled('address')`
  font-size: 16px;
  margin-bottom: 60px;
`;

const HoursRow = styled(Row)`
  font-size: 14px;
  padding-bottom: 8px;
`;

const Hours = styled.div`
  h3.hours-title {
    font-size: 21px;
    font-weight: normal;
    text-transform: none;
  }
`;

const LatLng = styled.div`
  font-size: 16px;
`;

interface ViewModeDetailsProps extends WrappedComponentProps {
  locationDetails: LocationDetails | undefined;
}
const ViewModeDetails: React.FC<ViewModeDetailsProps> = (props) => {
  const { intl, locationDetails } = props;
  const hours = get(locationDetails, 'hours');
  const address1 = get(locationDetails, 'address.address1');
  const address2 = get(locationDetails, 'address.address2');
  const city = get(locationDetails, 'address.city');
  const state = get(locationDetails, 'address.state');
  const postalCode = get(locationDetails, 'address.postalCode');
  const country = get(locationDetails, 'address.country');
  const latitude = get(locationDetails, 'address.locationCoordinatesDto.latitude');
  const longitude = get(locationDetails, 'address.locationCoordinatesDto.longitude');

  return (
    <>
      <Row noGutters>
        <Col lg={2} style={{ maxWidth: 'calc(6%)' }}>
          <EnvironmentFilled style={{ fontSize: '2rem', lineHeight: '3.3rem' }} />
        </Col>
        <Col sm={10}>
          <Address>
            {address1 && (
              <>
                <span>{address1}</span>
                <br />
              </>
            )}

            {address2 && (
              <>
                {address2}
                <br />
              </>
            )}

            {!isNil(address2) && <br />}

            {`${!isNil(city) ? city : ''}${!isNil(city) && !isNil(state) ? ', ' : ''}${!isNil(state) ? state : ''}`}

            {(!isNil(city) || !isNil(state)) && <br />}

            {`${country} ${!isNil(postalCode) ? postalCode : ''}`}
            <br />
          </Address>
        </Col>
        <Col lg={2} style={{ maxWidth: 'calc(6%)' }}>
          <ClockCircleOutlined style={{ fontSize: '2rem', lineHeight: '3.3rem' }} />
        </Col>
        <Col sm={10}>
          <Hours>
            {get(locationDetails, 'hours') ? (
              LONG_DAYS_OF_WEEK.reduce((acc, day) => {
                const lowerCaseDay = day.defaultMessage.toLowerCase();
                acc.push(
                  <HoursRow key={lowerCaseDay}>
                    <Col xs={4}>
                      <FormattedMessage defaultMessage={day.defaultMessage} id={`calendar.shortDays.${lowerCaseDay}`} />
                    </Col>
                    <Col xs={20}>{`${
                      hours && hours[`${lowerCaseDay}From`]
                        ? `${moment(hours[`${lowerCaseDay}From`], HOURS_MINUTES_AM_PM_FORMAT).format(
                            HOURS_MINUTES_AM_PM_FORMAT
                          )} - ${moment(hours[`${lowerCaseDay}To`], HOURS_MINUTES_AM_PM_FORMAT).format(
                            HOURS_MINUTES_AM_PM_FORMAT
                          )}`
                        : `${intl.formatMessage({
                            id: 'locationDetails.renderDateSelection.closed',
                            defaultMessage: 'Closed',
                          })}`
                    }`}</Col>
                  </HoursRow>
                );
                return acc;
              }, [] as any)
            ) : (
              <span className={styles.noHoursAvailable}>
                <FormattedMessage
                  id="locationDetails.noSetBusinessHours"
                  defaultMessage="No business hours have been set"
                />
              </span>
            )}
          </Hours>
        </Col>
      </Row>
      {latitude && longitude && (
        <Row noGutters>
          <Col lg={2} style={{ maxWidth: 'calc(6%)' }}>
            <AimOutlined style={{ fontSize: '2rem', lineHeight: '3.3rem' }} />
          </Col>
          <Col>
            <LatLng data-locator="location-details-lat-lng">{`${latitude}, ${longitude}`}</LatLng>
          </Col>
        </Row>
      )}
      {get(locationDetails, 'contacts') && (
        <Row noGutters>
          <Col lg={2} style={{ maxWidth: 'calc(6%)' }}>
            <UserOutlined style={{ fontSize: '2rem', lineHeight: '3.3rem' }} />
          </Col>
          <Col>
            {get(locationDetails, 'contacts') &&
              get(locationDetails, 'contacts', [] as ContactModel[]).map((contact: ContactModel) => (
                <Contact
                  key={contact.id}
                  contactName={contact.contactName}
                  phoneNumber={contact.phoneNumber}
                  email={contact.email}
                />
              ))}
          </Col>
        </Row>
      )}
    </>
  );
};

export default injectIntl(ViewModeDetails);
