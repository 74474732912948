import { FunctionComponent } from 'react';
import { Route, RouteComponentProps, Redirect, Switch } from 'react-router-dom';

import settingsRoutes from './settingsRoutes';
import BrandTheming from './BrandTheming/BrandThemingContainer';
import ReferenceDataContainer from './ReferenceData/ReferenceDataContainer';
import EntitlementRulesContainer from './EntitlementRules/EntitlementRulesContainer';

const Settings: FunctionComponent<RouteComponentProps<{}>> = () => {
  return (
    <Switch>
      <Route path={settingsRoutes.brandTheming.route} component={BrandTheming} />
      <Route path={settingsRoutes.referenceData.route} component={ReferenceDataContainer} />
      <Route path={settingsRoutes.entitlementRules.route} component={EntitlementRulesContainer} />
      <Redirect from={settingsRoutes.baseRoute.route} to={settingsRoutes.brandTheming.route} />
    </Switch>
  );
};

export default Settings;
