import { FormattedMessage } from 'react-intl';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import FlatButton from 'material-ui/FlatButton';
import AddIcon from 'material-ui/svg-icons/content/add';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import PropTypes from 'prop-types';
import './AddEditShipment.scss';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import get from 'lodash/get';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { Button } from 'ui-components';
import AddEditShipmentModal from './AddEditShipmentModal/AddEditShipmentModal';
import * as types from './ducks/types';
import { SNACKBAR_AUTOHIDE_DURATION } from './../../common/AppConstants';
import { ThemeContext } from '../../contexts/ThemeContext';

const AddEditShipment = (props) => {
  const editStopNumber = get(props, 'shipment.stopNumber');

  return (
    <div className="add-edit-shipment-container">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.snackbarOpen}
        autoHideDuration={SNACKBAR_AUTOHIDE_DURATION}
        onClose={props.closeSnackbar}
        className="snackbar-success snackbar-container"
        action={[
          <IconButton key="close" aria-label="Close" onClick={props.closeSnackbar}>
            <i className="material-icons">close</i>
          </IconButton>,
        ]}
        message={
          <span className="snack-message">
            <i className="material-icons">thumb_up</i> Shipment was successfully saved.
          </span>
        }
      />
      {props.type === types.EDIT_SHIPMENT && (
        <MuiThemeProvider>
          <FlatButton
            className="edit-shipment-icon"
            onClick={() => props.openAddEditShipment(props.type, editStopNumber)} //eslint-disable-line react/jsx-no-bind
            style={{
              height: '30px',
              minWidth: '55px',
              backgroundColor: '#FFF',
            }}
            icon={<i className="material-icons">edit</i>}
          />
        </MuiThemeProvider>
      )}
      {props.type === types.ADD_SHIPMENT && props.hasTruckloadMode && (
        <div className="shipment-combo-button">
          <ThemeContext.Consumer>
            {(theme) => (
              <Button
                type="primary"
                className="d-none d-sm-block" // add this class add-shipment-combo-button when we have more options
                size="lg"
                clickFn={() => props.openAddEditShipment(props.type, editStopNumber)} //eslint-disable-line react/jsx-no-bind
                theme={theme}
              >
                <FormattedMessage id="shipmentList.addEditShipment.addShipment" defaultMessage="Add Shipment" />
                {/*<div className="combo-icon-wrapper"><i className="material-icons">arrow_drop_down</i></div> commented out until we add more options*/}
              </Button>
            )}
          </ThemeContext.Consumer>
          <Popper open={get(props, 'dropDownOpen', false)} anchorEl={props.anchorEl} transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper className="add-shipment-paper">
                  <ClickAwayListener onClickAway={props.closeDropDown}>
                    <MenuList className="add-shipment-menu">
                      <MenuItem
                        onClick={() => props.openAddEditShipment(props.type, editStopNumber)} //eslint-disable-line react/jsx-no-bind
                      >
                        Single Shipment
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
      {props.type === types.ADD_SHIPMENT && (
        <MuiThemeProvider>
          <FloatingActionButton
            onClick={() => props.openAddEditShipment(types.ADD_SHIPMENT)} //eslint-disable-line react/jsx-no-bind
            className="add-shipment-button d-block d-sm-none"
          >
            <AddIcon />
          </FloatingActionButton>
        </MuiThemeProvider>
      )}
      {props.open && (
        <AddEditShipmentModal
          steps={props.steps}
          shipmentType={props.shipmentType}
          shipment={props.shipment}
          title={props.title}
          open={props.open}
          navigateStep={props.navigateStep}
          cancelAddEditShipment={props.cancelAddEditShipment}
          saveShipment={props.saveShipment}
          setCarrierType={props.setCarrierType}
          setTrackingMethod={props.setTrackingMethod}
          setTemperatureScale={props.setTemperatureScale}
          toggleStop={props.toggleStop}
          initialValues={props.initialValues}
          values={props.values}
          type={props.type}
          zipEntered={props.zipEntered}
          editShipmentStop={props.editShipmentStop}
          skipTempTracking={props.skipTempTracking}
        />
      )}
    </div>
  );
};

AddEditShipment.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  steps: PropTypes.array.isRequired,
  navigateStep: PropTypes.func.isRequired,
  openAddEditShipment: PropTypes.func.isRequired,
  cancelAddEditShipment: PropTypes.func.isRequired,
  saveShipment: PropTypes.func.isRequired,
  setCarrierType: PropTypes.func.isRequired,
  setTemperatureScale: PropTypes.func.isRequired,
  toggleStop: PropTypes.func.isRequired,
};

export default AddEditShipment;
