import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { getFormValues, reset } from 'redux-form';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { getPrincipalAuthorizationsFromState } from 'common/authorizations';
import AddEditNotificationComponent from './AddEditNotificationComponent';
import { notificationActions, initialNotificationState } from './../ducks';
import {
  determineAccessLevelObjectFromProps,
  determineAccessLevelFromPropsOrState,
  getValueForKeyFromPropsOrState,
} from '../NotificationViewUtil';

import { ShipmentAccessLevelConstants } from '../ducks/constants';

const doInit = (props) => {
  // reset any previous values that were displayed
  props.resetForm();
  props.getPushIdentities();
  if (props.match.params.notificationId !== 'new' && props.match.params.notificationId !== undefined) {
    props.getNotification(
      props.match.params.notificationId,
      determineAccessLevelObjectFromProps(props),
      props.subscriptionOptions
    );
  }
};

const componentWithFetchedData = compose(
  lifecycle({
    componentDidMount() {
      doInit(this.props);
    },
    componentWillReceiveProps(newProps) {
      // refresh on route change
      if (get(newProps, 'location.search') !== get(this.props, 'location.search')) {
        doInit(newProps);
      }
    },
  })
)(AddEditNotificationComponent);

const mapStateToProps = (state, ownProps) => ({
  accessLevel: determineAccessLevelFromPropsOrState(ownProps, state),
  shipmentId: getValueForKeyFromPropsOrState(ownProps, state, 'shipmentId'),
  masterShipmentId: getValueForKeyFromPropsOrState(ownProps, state, 'masterShipmentId'),
  mode: getValueForKeyFromPropsOrState(ownProps, state, 'mode'),
  initialValues: state.manageNotificationsReducer.notificationReducer.initialValues,
  modeOptions: state.manageNotificationsReducer.notificationReducer.modeOptions,
  formValues: getFormValues('AddEditNotificationForm')(state),
  locationSuggestions: state.filterReducer.locationSuggestions,
  carrierSuggestions: state.filterReducer.carrierSuggestions,
  companySuggestions: state.filterReducer.companySuggestions,
  subscriberSuggestions: state.manageNotificationsReducer.notificationReducer.subscriberSuggestions,
  errors: state.manageNotificationsReducer.notificationReducer.errors,
  subscriptionOptions: state.manageNotificationsReducer.notificationReducer.subscriptionOptions,
  hasEdit:
    determineAccessLevelFromPropsOrState(ownProps, state) === ShipmentAccessLevelConstants.Shipment ||
    getPrincipalAuthorizationsFromState(state).canEditTenantLevelNotifications(),
  hasTruckloadMode: getPrincipalAuthorizationsFromState(state).hasTruckloadMode(),
  hasLtlMode: getPrincipalAuthorizationsFromState(state).hasLtlMode(),
  hasParcelMode: getPrincipalAuthorizationsFromState(state).hasParcelMode(),
  hasAirMode: getPrincipalAuthorizationsFromState(state).hasAirMode(),
  hasOceanMode: getPrincipalAuthorizationsFromState(state).hasOceanMode(),
});

const mapDispatchToProps = (dispatch) => ({
  updateShipmentAccessLevel: (accessLevelObject) =>
    dispatch(notificationActions.updateNotificationShipmentAccessLevel(accessLevelObject)),
  getPushIdentities: () => dispatch(notificationActions.getConfigIdentities()),
  getNotification: (id, accessLevelObject, subscriptionOptions) =>
    dispatch(notificationActions.getNotification(id, accessLevelObject, subscriptionOptions)),
  queryAutocompleteOptions: (section, inputText, type, mode) =>
    dispatch(notificationActions.queryAutocompleteOptions(section, inputText, type, mode)),
  saveNotification: (notification, accessLevelObject, subscriptionOptions) =>
    dispatch(notificationActions.saveNotification(notification, accessLevelObject, subscriptionOptions)),
  deleteNotification: (id, accessLevelObject) =>
    dispatch(notificationActions.deleteNotification(id, accessLevelObject)),
  resetForm: () => {
    dispatch(reset('AddEditNotificationForm'));
    dispatch(notificationActions.resetNotificationForm());
  },
  newEventType: () => cloneDeep(initialNotificationState.initialValues.notificationEvents[0]),
  newShipmentCriteria: () => cloneDeep(initialNotificationState.initialValues.filters.fields[0]),
});

export default connect(mapStateToProps, mapDispatchToProps)(componentWithFetchedData);
