import * as React from 'react';
import AntDropdown, { DropDownProps as AntDropdownProps } from 'antd/lib/dropdown';
export interface PlainDropdownProps extends AntDropdownProps {
  dropdownItems: any[];
  overlay: JSX.Element;
}

const PlainDropdown: React.FC<PlainDropdownProps> = (props) => {
  return <AntDropdown {...props}>{props.children}</AntDropdown>;
};

export default PlainDropdown;
