import cn from 'classnames';
import styled from 'styled-components';
import { Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import { SVGIcon } from 'ui-components';

import * as styles from './styles.module.scss';
import { ModeFilterOptions, TNumberFormat } from '../types';
import { getEntityTypeMessages, parseCountToPercent } from '../utils';
import { QuickviewEntityType, HEALTH_RANGES } from '../constants';

interface QuickViewCardTileProps {
  className?: string;
  title: string;
  subTotal: number | null;
  total?: number | null;
  modeFilterOptions?: ModeFilterOptions;
  healthRange?: keyof typeof HEALTH_RANGES;
  numberFormat?: TNumberFormat;
  modeFilters?: any;
  entityType: QuickviewEntityType;
}

const StyledSVGIcon = styled(SVGIcon)`
  font-size: 14px;
  margin-right: 5px;
`;

const QuickViewCardTile = ({
  title,
  subTotal,
  total,
  modeFilters,
  className,
  healthRange,
  numberFormat = 'COUNT',
  modeFilterOptions,
  entityType,
}: QuickViewCardTileProps) => {
  const currentModeValue = modeFilters && (modeFilters.length > 0 ? modeFilters[0] : 'ALL');
  const currentModes = modeFilterOptions?.filter((mode) => mode.value === currentModeValue);
  const isPercentage = numberFormat === 'PERCENTAGE';

  let subTotalText: string | JSX.Element = '';
  let totalText: string | JSX.Element = '';

  const entityTypeMessages = getEntityTypeMessages(entityType);

  if (subTotal !== null) {
    if (total) {
      if (isPercentage) {
        subTotalText = `${parseCountToPercent(subTotal, total).toFixed(2)}%`;
        totalText = (
          <FormattedMessage
            id={entityTypeMessages.subTotalofTotalMessageID}
            defaultMessage={entityTypeMessages.subTotalOfTotalDefaultMessage}
            values={{
              total: total.toLocaleString(navigator.language),
              subTotal: subTotal.toLocaleString(navigator.language),
            }}
          />
        );
      } else {
        subTotalText = subTotal.toLocaleString(navigator.language);
        totalText = (
          <FormattedMessage
            id={entityTypeMessages.subTotalofTotalMessageID}
            defaultMessage={entityTypeMessages.subTotalOfTotalDefaultMessage}
            values={{ total: total.toLocaleString(navigator.language), subTotal: null }}
          />
        );
      }
    } else {
      subTotalText = subTotal.toLocaleString(navigator.language);
      totalText = (
        <FormattedMessage
          id={entityTypeMessages.noEntityCountDefaultMessageId}
          defaultMessage={entityTypeMessages.noEntityCountDefaultMessage}
        />
      );
    }
  } else {
    subTotalText = <FormattedMessage id="quickViewConfig.quickViewCard.count" defaultMessage="# of Results" />;
    totalText = (
      <FormattedMessage
        id={entityTypeMessages.noEntityCountDefaultMessageId}
        defaultMessage={entityTypeMessages.noEntityCountDefaultMessage}
      />
    );
  }

  const { text, iconName, accentColor } = healthRange
    ? HEALTH_RANGES[healthRange]
    : { text: '', iconName: '', accentColor: '' };

  return (
    <div className={cn(styles['quickview-card-container'], className)}>
      <div className={styles['quickview-card']}>
        <div className={styles[title ? 'title-entered' : 'title']}>
          {title || <FormattedMessage id="quickViewCard.tile.title" defaultMessage="New Quick View" />}
        </div>
        <span>
          <p className={cn(styles['subtotal-shipments'], { [styles[`text-${accentColor}`]]: !!healthRange })}>
            {subTotalText}
          </p>
          <p className={cn(styles['total-shipments'])}>{totalText}</p>
        </span>
        {healthRange && (
          <div className={cn('d-flex align-items-center', styles[`text-${accentColor}`])}>
            <SVGIcon name={iconName} className="mr-2" />
            <span className={styles['health-range-title']}>{text}</span>
          </div>
        )}
        {entityType === QuickviewEntityType.SHIPMENTS &&
          currentModes?.map(({ value, label, id }) => (
            <Tag className="d-flex align-items-center mode-tag mt-5" key={id}>
              <StyledSVGIcon name={value} />
              {label}
            </Tag>
          ))}
      </div>
    </div>
  );
};

export default QuickViewCardTile;
