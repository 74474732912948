import * as React from 'react';
import AntForm from 'antd/lib/form';
import { useIntl, FormattedMessage } from 'react-intl';
import { rgba } from 'polished';
import styled from 'styled-components';
import every from 'lodash/every';
import classNames from 'classnames';
import { AlertOutlined, InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { EquipmentIdentifierTypeEnum } from 'models';
import { Input, InputErrorMessage } from 'components/common/forms/FormElements';
import { Button, Select } from 'ui-components';
import { errorRedTwo, primaryGreyEighty, green } from 'styles/colors';
import { useSaveEquipmentIdentifier } from 'components/shipmentDetails/common/AddEquipmentIdentifier/equipmentIdentifierHooks';
const StyledForm = styled('div')<{ complete: boolean }>`
  padding: 16px 16px 30px;
  background-color: ${(props) => (props.complete ? rgba(0, 133, 74, 0.1) : rgba(202, 66, 54, 0.1))};
  margin-top: -10px;
`;

interface ResultCardEquipmentIdFormProps {
  equipmentIdentifierTypes: EquipmentIdentifierTypeEnum[];
  shipmentId: string;
  shipmentIdentifiers: any;
  capacityProviderId: string | undefined;
}

enum SubmitStateEnum {
  IDLE = 'IDLE',
  SUBMITTING = 'SUBMITTING',
  SUBMITTED = 'SUBMITTED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

const ResultCardEquipmentIdForm: React.FC<ResultCardEquipmentIdFormProps> = ({
  equipmentIdentifierTypes = [],
  shipmentId,
  shipmentIdentifiers,
  capacityProviderId,
}) => {
  const [selectedEquipIdType, setSelectedEquipIdType] = React.useState<string | undefined>();
  const [, dumbForceUpdateToDisableSubmitButtonOnLoad] = React.useState<any>();
  const [submitState, setSubmitState] = React.useState<SubmitStateEnum>(SubmitStateEnum.IDLE);
  const intl = useIntl();
  const { saveEquipmentIdentifier, equipmentIdentifiersError } = useSaveEquipmentIdentifier({ shipmentId, intl });
  const [form] = AntForm.useForm();
  React.useEffect(() => {
    dumbForceUpdateToDisableSubmitButtonOnLoad({});
  }, []);
  return (
    <StyledForm complete={submitState === SubmitStateEnum.SUCCESS}>
      <AntForm
        form={form}
        layout={'vertical'}
        initialValues={{ idType: undefined, idName: undefined }}
        onFinish={async (values) => {
          try {
            setSubmitState(SubmitStateEnum.SUBMITTING);
            await saveEquipmentIdentifier({
              selectedEquipIdType: values.idType,
              selectedEquipIdValue: values.idName,
              capacityProviderId,
              shipmentIdentifiers,
            });
            setSubmitState(SubmitStateEnum.SUCCESS);
          } catch (error) {
            console.error(error);
            setSubmitState(SubmitStateEnum.FAILURE);
          }
        }}
      >
        {(props) => (
          <div className="d-flex justify-content-between align-items-center" onSubmit={props.handleSubmit}>
            <div className="d-flex flex-nowrap align-items-start" style={{ width: '75%' }}>
              {submitState === SubmitStateEnum.SUCCESS ? (
                <CheckCircleOutlined
                  data-locator={`successfully-added-identifier-${shipmentId}`}
                  style={{
                    fontSize: '32px',
                    color: green,
                    paddingBottom: '4px',
                    ...(equipmentIdentifiersError.length > 0 && { marginTop: '24px' }),
                  }}
                  className={classNames(
                    {
                      'align-self-start': equipmentIdentifiersError.length > 0,
                      'align-self-end': !equipmentIdentifiersError.length,
                    },
                    'mx-4 '
                  )}
                />
              ) : (
                <AlertOutlined
                  data-locator={`failure-adding-identifier-${shipmentId}`}
                  style={{
                    fontSize: '32px',
                    color: errorRedTwo,
                    paddingBottom: '4px',
                    ...(equipmentIdentifiersError.length > 0 && { marginTop: '24px' }),
                  }}
                  className={classNames(
                    {
                      'align-self-start': equipmentIdentifiersError.length > 0,
                      'align-self-end': !equipmentIdentifiersError.length,
                    },
                    'mx-4'
                  )}
                />
              )}
              <div className="flex-column">
                <AntForm.Item
                  name={`idType`}
                  style={{ marginBottom: 0 }}
                  label={
                    <FormattedMessage
                      defaultMessage="Identifier Type"
                      id="shipmentList.equipmentIdentifier.label.type"
                    />
                  }
                >
                  <Select
                    placeholder="Please Select"
                    id={`identifier-type-${shipmentId}`}
                    data-locator={`identifier-type-${shipmentId}`}
                    value={selectedEquipIdType}
                    onChange={(equipId: string) => setSelectedEquipIdType(equipId)}
                    dataSource={equipmentIdentifierTypes.map((equipId) => ({
                      value: equipId,
                      displayValue: intl.formatMessage({
                        id: `shipmentDetails.equipmentIdentifiers.types.${equipId}`,
                        defaultMessage: equipId,
                      }),
                    }))}
                    style={{ width: '85%' }}
                  />
                </AntForm.Item>
              </div>
              <div className="flex-column w-100 mx-3">
                <AntForm.Item
                  label={
                    <FormattedMessage
                      defaultMessage="Identifier Number"
                      id="shipmentList.equipmentIdentifier.label.number "
                    />
                  }
                  validateStatus={equipmentIdentifiersError.length > 0 ? 'error' : ''}
                  name="idName"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    type="text"
                    data-locator={`identifier-name-${shipmentId}`}
                    id={`identifier-name-${shipmentId}`}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder={intl.formatMessage({
                      id: 'shipmentList.equipmentIdentifier.placeholder.name',
                      defaultMessage: 'Enter Number',
                    })}
                  />
                </AntForm.Item>
                {equipmentIdentifiersError.length && submitState === SubmitStateEnum.FAILURE ? (
                  <div>
                    {equipmentIdentifiersError.map((errorMessage: string) => (
                      <InputErrorMessage key={errorMessage} errorMessage={errorMessage} />
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
            <AntForm.Item noStyle shouldUpdate>
              {() => {
                const formValues = Object.values(form.getFieldsValue(['idType', 'idName']));
                const fieldsUntouchedAndValuesEmpty =
                  !form.isFieldsTouched(['idType', 'idName'], true) && !every(formValues);
                const disabledButton = fieldsUntouchedAndValuesEmpty && submitState !== SubmitStateEnum.SUBMITTING;
                return (
                  <Button
                    disabled={disabledButton || submitState === SubmitStateEnum.SUBMITTING}
                    className={classNames('align-self-start')}
                    htmlType="submit"
                    type="primary"
                    data-locator={`submit-add-identifier-${shipmentId}`}
                    style={{ height: '36px', marginTop: '24px' }}
                    loading={submitState === SubmitStateEnum.SUBMITTING}
                  >
                    Save
                  </Button>
                );
              }}
            </AntForm.Item>
            <div
              className="d-flex flex-row align-self-start"
              style={{ lineHeight: '19px', width: '20%', color: primaryGreyEighty, fontSize: '14px' }}
            >
              <InfoCircleOutlined className="mx-2" />
              <FormattedMessage
                id="shipmentList.equipmentIdentifiers.helpText"
                defaultMessage="Provide equipment identifier to start tracking"
              />
            </div>
          </div>
        )}
      </AntForm>
    </StyledForm>
  );
};

export default ResultCardEquipmentIdForm;
