import { DESCENDING, ASCENDING } from '../../../common/AppConstants';

// using for text strings and stub data
export const locationListConstants = {
  sortOptions: [
    {
      label: 'Company Name (A-Z)',
      id: 'company_ascending',
    },
    {
      label: 'Company Name (Z-A)',
      id: 'company_descending',
    },
    {
      label: 'Contact Last Name (A-Z)',
      id: 'last_name_ascending',
    },
    {
      label: 'Contact Last Name (Z-A)',
      id: 'last_name_descending',
    },
    {
      label: 'Contact First Name (A-Z)',
      id: 'first_name_ascending',
    },
    {
      label: 'Contact First Name (Z-A)',
      id: 'first_name_descending',
    },
    {
      label: '———————',
      id: 'default',
    },
  ],
  availableSortFields: [
    {
      displayName: 'Company Name (A-Z)',
      request: [{ fieldName: 'addressInfo.companyName', direction: ASCENDING }],
    },
    {
      displayName: 'Company Name (Z-A)',
      request: [{ fieldName: 'addressInfo.companyName', direction: DESCENDING }],
    },
    {
      displayName: 'Contact Last Name (A-Z)',
      request: [{ fieldName: 'addressInfo.lastName', direction: ASCENDING }],
    },
    {
      displayName: 'Contact Last Name (Z-A)',
      request: [{ fieldName: 'addressInfo.lastName', direction: DESCENDING }],
    },
  ],
  mockLocations: [
    {
      id: '00001',
      companyName: 'Company Name',
      contact: {
        firstName: 'Cledus',
        lastName: 'Snow',
        phoneNumber: '312-456-9876',
        email: 'cleadus.snow@jmanuf.com',
      },
      address: {
        addressStreet: '1234 Main St.',
        city: 'City',
        state: 'ST',
        postalCode: '12345',
        countryCode: 'USA',
      },
      performance: 98,
    },
    {
      id: '00002',
      companyName: 'Company Name',
      contact: {
        firstName: 'Cledus',
        lastName: 'Snow',
        phoneNumber: '312-456-9876',
        email: 'cleadus.snow@jmanuf.com',
      },
      address: {
        addressStreet: '1234 Main St.',
        city: 'City',
        state: 'ST',
        postalCode: '12345',
        countryCode: 'USA',
      },
      performance: 98,
    },
    {
      id: '00003',
      companyName: 'Company Name',
      contact: {
        firstName: 'Cledus',
        lastName: 'Snow',
        phoneNumber: '312-456-9876',
        email: 'cleadus.snow@jmanuf.com',
      },
      address: {
        addressStreet: '1234 Main St.',
        city: 'City',
        state: 'ST',
        postalCode: '12345',
        countryCode: 'USA',
      },
      performance: 98,
    },
    {
      id: '00004',
      companyName: 'Company Name',
      contact: {
        firstName: 'Cledus',
        lastName: 'Snow',
        phoneNumber: '312-456-9876',
        email: 'cleadus.snow@jmanuf.com',
      },
      address: {
        addressStreet: '1234 Main St.',
        city: 'City',
        state: 'ST',
        postalCode: '12345',
        countryCode: 'USA',
      },
      performance: 98,
    },
  ],
};

export const locationDetailsConstants = {
  subHeader: {
    pageName: 'Location Details',
    backButtonRoute: '/location-list',
    subTitle: null,
  },
  boundarySelections: ['15 Minutes', '30 Minutes', '45 Minutes', '60 Minutes'],
  recentShipments: [
    {
      date: '05/13/2018',
      shipmentId: 'BOL# 9817264738',
      city: 'Dallas',
      state: 'TX',
      link: '/tracking-details',
    },
    {
      date: '05/12/2018',
      shipmentId: 'BOL# 9817264738',
      city: 'Dallas',
      state: 'TX',
      link: '/tracking-details',
    },
    {
      date: '05/09/2018',
      shipmentId: 'BOL# 9817264738',
      city: 'Dallas',
      state: 'TX',
      link: '/tracking-details',
    },
    {
      date: '05/06/2018',
      shipmentId: 'BOL# 9817264738',
      city: 'Dallas',
      state: 'TX',
      link: '/tracking-details',
    },
    {
      date: '05/01/2018',
      shipmentId: 'BOL# 9817264738',
      city: 'Dallas',
      state: 'TX',
      link: '/tracking-details',
    },
  ],
};

export const RESULTS_PER_PAGE = 25;
