import { Component } from 'react';
import MapContainer from './Map/MapContainer';
import { Location } from './models/Location';
import { calculateMidpoint } from './Map/Markers/MidPointCalculator';

export interface MapRouteProps {
  locations: Location[];
  calculateCenter?: (locations: Location[]) => Location;
}

class MapRoute extends Component<MapRouteProps, {}> {
  private constantZoom() {
    return 5;
  }
  public render() {
    const calcCenter = this.props.calculateCenter || calculateMidpoint;
    return (
      <MapContainer locations={this.props.locations} calculateCenter={calcCenter} calculateZoom={this.constantZoom} />
    );
  }
}

export default MapRoute;
