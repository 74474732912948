import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import { AddEditShipmentContainer } from '../../addEditShipment/ducks';
import * as addEditShipmentTypes from '../../addEditShipment/ducks/types';
import './ShipmentListHeaderComponent.scss';

const ShipmentListHeaderComponent = ({ showAddShipments }) => {
  return (
    <Row as={'header'} noGutters className="ShipmentListHeaderComponent align-items-center">
      <Col>
        <h1 className="mb-0">
          <FormattedMessage id="shipmentList.shipmentListHeader.listheader" defaultMessage="Shipments" />
        </h1>
      </Col>
      <Col className="d-flex align-items-end justify-content-end">
        {showAddShipments ? (
          <div key="AddShipmentContainer" className="shipment-list-header-button-container d-none d-lg-block">
            <AddEditShipmentContainer type={addEditShipmentTypes.ADD_SHIPMENT} />
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default ShipmentListHeaderComponent;
