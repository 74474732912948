import * as React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { push } from 'react-router-redux';
import { useDispatch } from 'react-redux';
import Table from 'common/Table/Table';
import { ShipmentSearchCardData } from 'models';
import { MONTH_DAY_YEAR_FORMAT, HOURS_MINUTES_SECONDS_FORMAT } from 'i18n/configurei18n';
import { useInventoryShipments, getShipmentIdentifierFromShipment, LoadingStatus } from './hooks/useInventoryShipments';

interface ShipmentsTableComponentProps {
  masterShipmentIds: string[];
}

const ShipmentsTableComponent: React.FC<ShipmentsTableComponentProps> = ({ masterShipmentIds }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { currentLoadingStatus, shipments } = useInventoryShipments(masterShipmentIds);
  const tableReadyShipments =
    shipments &&
    shipments?.results.reduce(
      (
        acc: Array<
          ShipmentSearchCardData & {
            shipmentIdentifierValue: string | undefined;
          }
        >,
        shipment: ShipmentSearchCardData
      ) => {
        const shipmentWithExtraData = {
          ...shipment,
          shipmentIdentifierValue: getShipmentIdentifierFromShipment(shipment),
        };
        acc.push(shipmentWithExtraData);
        return acc;
      },
      []
    );
  const columns = [
    {
      dataIndex: 'shipmentIdentifierValue',
      key: 'bol',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.shipmentIdentifier',
        defaultMessage: 'Shipment ID',
      }),
      width: 175,
    },
    {
      dataIndex: 'mode',
      key: 'mode',
      title: intl.formatMessage({ id: 'inventory.shipmentList.columnHeaders.mode', defaultMessage: 'Mode' }),
      width: 175,
    },
    {
      dataIndex: 'currentEta',
      key: 'etaStatus',
      render: function renderFunc(text: string) {
        return text
          ? moment.utc(text, moment.ISO_8601).format(`${MONTH_DAY_YEAR_FORMAT} ${HOURS_MINUTES_SECONDS_FORMAT}`)
          : '';
      },
      title: intl.formatMessage({ id: 'inventory.shipmentList.columnHeaders.etaStatus', defaultMessage: 'ETA Status' }),
      width: 175,
    },
    {
      dataIndex: 'statusText',
      key: 'latestStatusUpdate',
      title: intl.formatMessage({
        id: 'inventory.shipmentList.columnHeaders.latestStatusUpdate',
        defaultMessage: 'Latest Status Update',
      }),
      width: 175,
    },
    {
      dataIndex: ['pickupStopInfo', 'stopName'],
      key: 'originStopName',
      title: intl.formatMessage({
        id: 'inventory.shipmentList.columnHeaders.originStopName',
        defaultMessage: 'Origin Stop Name',
      }),
      width: 175,
    },
    {
      dataIndex: ['pickupStopInfo', 'city'],
      key: 'originStopCity',
      title: intl.formatMessage({
        id: 'inventory.shipmentList.columnHeaders.originCity',
        defaultMessage: 'Origin City',
      }),
      width: 175,
    },
    {
      dataIndex: ['pickupStopInfo', 'state'],
      key: 'originStopState',
      title: intl.formatMessage({
        id: 'inventory.shipmentList.columnHeaders.originState',
        defaultMessage: 'Origin State',
      }),
      width: 175,
    },
    {
      dataIndex: ['deliveryStopInfo', 'stopName'],
      key: 'destinationName',
      title: intl.formatMessage({
        id: 'inventory.shipmentList.columnHeaders.destinationStopName',
        defaultMessage: 'Destination Stop Name',
      }),
      width: 175,
    },
    {
      dataIndex: ['deliveryStopInfo', 'city'],
      key: 'destinationCity',
      title: intl.formatMessage({
        id: 'inventory.shipmentList.columnHeaders.destinationCity',
        defaultMessage: 'Destination City',
      }),
      width: 175,
    },
    {
      dataIndex: ['deliveryStopInfo', 'state'],
      key: 'destinationState',
      title: intl.formatMessage({
        id: 'inventory.shipmentList.columnHeaders.destinationState',
        defaultMessage: 'Destination State',
      }),
      width: 175,
    },
    {
      dataIndex: 'shipmentStopsCount',
      key: 'numberOfStops',
      title: intl.formatMessage({
        id: 'inventory.shipmentList.columnHeaders.numberOfStops',
        defaultMessage: 'Number of Stops',
      }),
      width: 175,
    },
  ];
  return (
    <Table
      loading={currentLoadingStatus === LoadingStatus.LOADING ? true : false}
      columns={columns}
      rowKey={(record: ShipmentSearchCardData) => record.shipmentId}
      dataSource={tableReadyShipments}
      rowClassName={() => 'cursor-pointer'}
      onRow={(record: ShipmentSearchCardData) => {
        return {
          onClick: () => {
            dispatch(push(`${record.url}`));
          },
        };
      }}
      scroll={{ x: 1200, y: 640 }}
      size="middle"
    />
  );
};

export default ShipmentsTableComponent;
