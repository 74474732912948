import * as colors from 'styles/colors';
import { DateTimeWithTimezone } from 'models';
import { ShipmentModeEnum, ShipmentEvent } from './';
import { ShipmentStopStatusEnum } from './ShipmentStopStatus.enum';

export interface StopIdentifier {
  type: string;
  value: string;
}
export interface ShipmentStop {
  stopNumber: number;
  stopId?: string;
  uuid?: string;
  prevLegMode?: ShipmentModeEnum;
  stopName: string;
  location?: {
    city?: string;
    state?: string;
    address?: string;
    lat?: number;
    lng?: number;
  };
  locationId?: string | null;
  status: ShipmentStopStatusEnum;
  statusMessage?: { id: string; defaultMessage: string; values?: any };
  timelinessMessage?: { id: string; defaultMessage: string; values?: any };
  statusTagColorValue?: string;
  appointmentWindow?: {
    startDate: string;
    endDate: string;
    localTimeZoneIdentifier?: string | null;
    localTimeZoneIdentifierShort?: string | null;
  };
  appointmentWindows?: Array<ShipmentStopAppointmentWindow>;
  type?: ShipmentStopTypeEnum;
  events: ShipmentEvent[];
  mode?: ShipmentModeEnum;
  contactName?: string;
  contactPhone?: string;
  editable?: boolean;
  isComplete?: boolean;
  earliestEstimatedArrivalDateTime?: DateTimeWithTimezone;
  recordedArrivalDateTime?: DateTimeWithTimezone;
  recordedDepartureDateTime?: DateTimeWithTimezone;
  identifiers?: StopIdentifier[];
  hasETAError?: boolean;
}

export interface ShipmentStopAppointmentWindow {
  stopNumber: number;
  windowType: ShipmentStopAppointmentWindowTypeEnum;
  localAppointmentStartDateTime: string;
  localAppointmentEndDateTime: string;
  utcAppointmentStartDateTime: string;
  utcAppointmentEndDateTime: string;
  localTimeZoneIdentifier?: string | null;
  localTimeZoneIdentifierShort?: string | null;
  statusMessage?: { id: string; defaultMessage: string };
  statusTagColorValue?: string;
  additionalAppointmentUtcScanDateTime?: string;
  appointmentStatus?: ShipmentStopAppointmentStatusEnum;
  identifiers?: ShipmentStopAppointmentWindowIdentifiers[];
}

export enum ShipmentStopAppointmentWindowTypeEnum {
  DEFAULT = 'DEFAULT',
  STRATEGIC = 'STRATEGIC',
  SLOT_BOOKING_PICKUP = 'SLOT_BOOKING_PICKUP',
  SLOT_BOOKING_DELIVERY = 'SLOT_BOOKING_DELIVERY',
  SLOT_BOOKING_PICKUP_DELIVERY = 'SLOT_BOOKING_PICKUP_DELIVERY',
}

export interface ShipmentStopAppointmentWindowIdentifiers {
  type: ShipmentStopAppointmentWindowIdentifierTypeEnum;
  value: string;
}

export enum ShipmentStopAppointmentWindowIdentifierTypeEnum {
  BOOKING_ID = 'BOOKING_ID',
  DOCK = 'DOCK',
}

export enum ShipmentStopAppointmentStatusEnum {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  BOOKED = 'BOOKED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  PENDING_CARRIER = 'PENDING_CARRIER',
  PENDING_INTERNAL = 'PENDING_INTERNAL',
  NONE_AVAILABLE = 'NONE_AVAILABLE',
}

export enum ShipmentStopTypeEnum {
  INTERMEDIATE = 'INTERMEDIATE',
  ORIGIN = 'ORIGIN',
  TERMINAL = 'TERMINAL',
  DESTINATION = 'DESTINATION',
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
  TRANSFER = 'TRANSFER',
  UNKNOWN = 'UNKNOWN',
  OTHER = 'OTHER',
  ANY = 'ANY',
}

// AppointmentWindowStatusEnum are statuses we support for displaying a status tag
export enum AppointmentWindowStatusEnum {
  EARLY = 'EARLY',
  ON_TIME = 'ON_TIME',
  LATE = 'LATE',
}

export const AppointmentWindowStatusIntlKeys: {
  [P in AppointmentWindowStatusEnum]: { id: string; defaultMessage: string };
} = {
  [AppointmentWindowStatusEnum.ON_TIME]: {
    id: 'shipmentDetails.stopCards.apptWindows.onTime',
    defaultMessage: 'On Time',
  },
  [AppointmentWindowStatusEnum.EARLY]: { id: 'shipmentDetails.stopCards.apptWindows.early', defaultMessage: 'Early' },
  [AppointmentWindowStatusEnum.LATE]: { id: 'shipmentDetails.stopCards.apptWindows.late', defaultMessage: 'Late' },
};

export const AppointmentWindowStatusColors: {
  [P in AppointmentWindowStatusEnum]: string;
} = {
  [AppointmentWindowStatusEnum.ON_TIME]: colors.green,
  [AppointmentWindowStatusEnum.EARLY]: colors.primaryBlueSeventyFive,
  [AppointmentWindowStatusEnum.LATE]: colors.newFruit,
};
