import { FormattedMessage } from 'react-intl';
import DISSATISFIED from '../assets/baseline-sentiment_dissatisfied-24px.svg';
import './PageNotFound.scss';

const PageNotFound = () => {
  return (
    <div className="PageNotFound flex-row">
      <img className="Shape" src={DISSATISFIED} alt={'dissatisfied icon'} />
      <br />
      <br />
      <br />
      <span className="Oops-We-cant-find">
        <FormattedMessage id="pageNotFound.oops" defaultMessage="Oops!" />
      </span>
      <br />
      <span className="Oops-We-cant-find">
        <FormattedMessage
          id="pageNotFound.weCantFindPageText"
          defaultMessage="We can’t find the page you are looking for."
        />
      </span>
      <br />
      <span className="Error-code-404">
        <FormattedMessage id="pageNotFound.errorCode" defaultMessage="Error Code: 404" />
      </span>
    </div>
  );
};

export default PageNotFound;
