import { FormattedMessage } from 'react-intl';
import { primaryGrey100 } from 'styles/colors';
import { RuleProperty } from './models';

export const AndDivider = ({ index, shipmentProperties }: { index: number; shipmentProperties: RuleProperty[] }) => {
  if (
    index > 0 &&
    shipmentProperties[index - 1].shipmentPropertyName !== shipmentProperties[index].shipmentPropertyName
  )
    return (
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: '1em' }}>
        <div style={{ width: '100%', display: 'block', height: '50%', borderTop: `solid 1px ${primaryGrey100}` }} />
        <p style={{ marginBottom: 0, marginInline: '1em ', fontWeight: 'bold' }}>
          <FormattedMessage defaultMessage="AND" id="entitlementRules.rules.modals.add.dividers.and" />
        </p>
        <div style={{ width: '100%', display: 'block', height: '50%', borderTop: `solid 1px ${primaryGrey100}` }} />
      </div>
    );
  else return null;
};
export const OrDivider = ({ index, shipmentProperties }: { index: number; shipmentProperties: RuleProperty[] }) => {
  if (
    index < shipmentProperties.length - 1 &&
    shipmentProperties[index + 1].shipmentPropertyName === shipmentProperties[index].shipmentPropertyName
  )
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          marginTop: '1em',
          zIndex: 100,
          marginBlock: '-0.1em',
        }}
      >
        <div
          style={{
            width: '1px',
            display: 'block',
            height: '1.5em',
            borderLeft: `solid 1px ${primaryGrey100}`,
          }}
        />
        <p
          style={{
            marginBottom: 0,
            marginInline: '1em',
            fontWeight: 'bold',
            border: `solid 1px ${primaryGrey100}`,
            paddingInline: '0.5em',
            paddingBlock: '0.25em',
          }}
        >
          <FormattedMessage defaultMessage="OR" id="entitlementRules.rules.modals.add.dividers.or" />
        </p>
        <div
          style={{
            width: '1px',
            display: 'block',
            height: '1.75em',
            zIndex: 100,
            borderLeft: `solid 1px ${primaryGrey100}`,
            marginBottom: '-1.75em',
          }}
        />
      </div>
    );
  else return null;
};
