const namespace = (actionType) => 'shipment/addEdit/' + actionType;

export const CARRIER_VALIDATION = namespace('CARRIER_VALIDATION');
export const CARRIER_SEARCH = namespace('CARRIER_SEARCH');
export const CARRIER_SEARCH_SUCCESS = namespace('CARRIER_SEARCH_SUCCESS');
export const CARRIER_SEARCH_FAIL = namespace('CARRIER_SEARCH_FAIL');
export const TRACKING_METHOD_CHOSEN = namespace('TRACKING_NMETHOD_CHOSEN');
export const POSTAL_CODE_ENTERED = namespace('POSTAL_CODE_ENTERED');
export const POSTAL_CODE_LOOKUP = namespace('POSTAL_CODE_LOOKUP');
export const POSTAL_CODE_LOOKUP_SUCCESS = namespace('POSTAL_CODE_LOOKUP_SUCCESS');
export const POSTAL_CODE_LOOKUP_FAIL = namespace('POSTAL_CODE_LOOKUP_FAIL');
export const SAVE_SHIPMENT = namespace('SAVE_SHIPMENT');
export const SAVE_SHIPMENT_STOP_EDIT = namespace('SAVE_SHIPMENT_STOP_EDIT');
export const SAVE_SHIPMENT_STOP_EDIT_STARTED = namespace('SAVE_SHIPMENT_STOP_STARTED');
export const SAVE_SHIPMENT_STOP_EDIT_SUCCESS = namespace('SAVE_SHIPMENT_STOP_EDIT_SUCCESS');
export const SAVE_SHIPMENT_STOP_EDIT_FAILED = namespace('SAVE_SHIPMENT_STOP_EDIT_FAILED');
export const SET_TEMPERATURE_SCALE = namespace('SET_TEMPERATURE_SCALE');
export const SAVE_SHIPMENT_STARTED = namespace('SAVE_SHIPMENT_STARTED');
export const SAVE_SHIPMENT_SUCCESS = namespace('SAVE_SHIPMENT_SUCCESS');
export const SAVE_SHIPMENT_FAILED = namespace('SAVE_SHIPMENT_FAILED');
export const CANCEL_SHIPMENT = namespace('CANCEL_SHIPMENT');
export const ADD_SHIPMENT = namespace('ADD_SHIPMENT');
export const EDIT_SHIPMENT = namespace('EDIT_SHIPMENT');
export const SET_CARRIER = namespace('SET_CARRIER');
export const TOGGLE_CONTACT = namespace('TOGGLE_CONTACT');
export const TOGGLE_STOP = namespace('TOGGLE_STOP');
export const NEXT_STEP = namespace('NEXT_STEP');
export const PREVIOUS_STEP = namespace('PREVIOUS_STEP');
export const NAVIGATE_STEP = namespace('NAVIGATE_STEP');
export const SKIP_TEMP_TRACKING = namespace('SKIP_TEMP_TRACKING');
export const ADD_EDIT_CLOSE_SNACKBAR = namespace('ADD_EDIT_CLOSE_SNACKBAR');
export const OPEN_DROPDOWN = namespace('OPEN_DROPDOWN');
export const CLOSE_DROPDOWN = namespace('CLOSE_DROPDOWN');

//these match the constants in the array from AddEditShipmentConstants.js
export const DROPOFF_DETAIL = 'DROPOFF_DETAIL';
export const TEMPERATURE_TRACKING = 'TEMPERATURE_TRACKING';
export const LANE_DETAIL = 'LANE_DETAIL';
export const PICKUP_DETAIL = 'PICKUP_DETAIL';
export const ADD_STOPS = 'ADD_STOPS';
export const SUMMARY = 'SUMMARY';
