import { MODE_FILTERS } from 'common/modeFilters';

export const UI_AUTHORITIES = {
  TL: 'TL_VISIBILITY_UI',
  LTL: 'LTL_VISIBILITY_UI',
  OCEAN: 'OCEAN_VISIBILITY_UI',
  PARCEL: 'PARCEL_VISIBILITY_UI',
  RAIL: 'RAIL_VISIBILITY_API',
  AIR: 'AIR_VISIBILITY',
};

export const MODE_AUTHORITY_TO_MODE_MAP = new Map([
  [UI_AUTHORITIES.TL, MODE_FILTERS.TRUCKLOAD],
  [UI_AUTHORITIES.LTL, MODE_FILTERS.LTL],
  [UI_AUTHORITIES.OCEAN, MODE_FILTERS.OCEAN],
  [UI_AUTHORITIES.PARCEL, MODE_FILTERS.PARCEL],
  [UI_AUTHORITIES.RAIL, MODE_FILTERS.RAIL],
  [UI_AUTHORITIES.AIR, MODE_FILTERS.AIR],
]);
