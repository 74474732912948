import { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown as AntDropdown } from 'antd';

import './Dropdown.scss';

export default class Dropdown extends Component {
  static propTypes = {
    handleChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dropdownItems: PropTypes.array,
    selectedColor: PropTypes.string,
    icon: PropTypes.string,
    origin: PropTypes.string,
    className: PropTypes.string,
  };

  renderMenu = () => {
    const { handleChange, value, dropdownItems } = this.props;

    return (
      <Menu selectedKeys={[`${value}`]} onClick={handleChange}>
        {dropdownItems.map((sortField, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Menu.Item key={index} value={index}>
            {sortField.displayName}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  render() {
    const { dropdownItems, value, origin, icon = 'arrow_drop_down', selectedColor, className = '' } = this.props;

    return (
      <AntDropdown overlay={this.renderMenu()} trigger={['click']} placement={origin}>
        <div
          className={`ant-dropdown-link flex flex-row-reverse items-center ${className}`}
          style={{
            color: `var(${selectedColor})`,
          }}
        >
          <i className={`material-icons`}>{icon}</i>
          <span>{dropdownItems[value].displayName}</span>
        </div>
      </AntDropdown>
    );
  }
}
