export enum OnboardingStepsEnum {
  WELCOME_STEP = 0,
  ACCOUNT_DETAILS_STEP = 1,
  DOT_VAT_SEARCH_STEP = 2,
  COMPANY_DETAILS_STEP = 2.1,
  COMPANY_DETAILS_MATCH_FOUND_STEP = 2.2,
  COMPANY_DETAILS_ALREADY_CONNECTED = 2.4,
  COMPANY_DETAILS_UNKNOWN_DOT = 2.5,
  PHONE = 2.6,
  BUSINESS_TYPE_STEP = 3,
  SUPPORT_SUBCONTRACTORS_ONLY_US = 3.1,
  CHOOSE_PROVIDER_STEP = 4,
  NEW_PROVIDER_DETAILS_STEP = 4.2,
  TELEMATIC_WIZARD_STEP = 4.4,
  SUPPORT_TELEMATIC_WIZARD_SIGNATURE = 4.6,
  SUCCESS_SUBCONTRACTORS_ONLY_EU = 5.1,
  SUCCESS_NEW_PROVIDER = 5.2,
  SUCCESS_NEW_PROVIDER_EU = 5.3,
  SUCCESS_TELEMATIC_WIZARD = 5.4,
  SUCCESS_OWN_FLEET_SUBCONTRACTORS_EU = 5.5,
}
