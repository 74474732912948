import { connect } from 'react-redux';
import { getPrincipalAuthorizationsFromState } from '../../../../common/authorizations';
import HereMapWrapper from './Map';
import { RootState } from '../../../../reducers';

const mapStateToProps = (state: RootState) => ({
  hereMapConfig: state.hereMapsConfigReducer.config,
  aerisWeatherConfig: state.aerisWeatherConfigReducer.config,
  authorizations: getPrincipalAuthorizationsFromState(state),
});

export default connect(mapStateToProps, null)(HereMapWrapper);
