import { defineMessages } from 'react-intl';

export enum ShipmentStatusReasonCodeEnum {
  ARRIVED = 'ARRIVED',
  TIMED_OUT = 'TIMED_OUT',
  CANCELED = 'CANCELED',
  NONE = 'NONE',

  // Parcel Specific
  AVAILABLE_FOR_PICKUP = 'AVAILABLE_FOR_PICKUP',
  DELAYED = 'DELAYED',
  DELIVERED = 'DELIVERED',
  DELIVERY_MISSED = 'DELIVERY_MISSED',
  DELIVERY_APPOINTMENT = 'DELIVERY_APPOINTMENT',
  DEPARTED = 'DEPARTED',
  EXCEPTION = 'EXCEPTION',
  HELD = 'HELD',
  LOADED_FOR_DELIVERY = 'LOADED_FOR_DELIVERY',
  NOT_FOUND = 'NOT_FOUND',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  PICKED_UP = 'PICKED_UP',
  RECEIVED = 'RECEIVED',
  RETURN_TO_SENDER = 'RETURN_TO_SENDER',
  SHIPMENT_CREATED = 'SHIPMENT_CREATED',
  UNKNOWN = 'UNKNOWN',

  // Rail Specific
  SCHEDULED = 'SCHEDULED',
  ACQUIRING_LOCATION = 'ACQUIRING_LOCATION',
  LOADED_ONTO_TRAIN = 'LOADED_ONTO_TRAIN',
  WAITING_TO_BE_LOADED = 'WAITING_TO_BE_LOADED',
  UNLOADED = 'UNLOADED',
  IN_MOTION = 'IN_MOTION',
  IDLE = 'IDLE',
  IN_GATE = 'IN_GATE',
  RAMPED = 'RAMPED',
  DERAMPED = 'DERAMPED',
  NOTIFIED = 'NOTIFIED',
  OUT_GATE = 'OUT_GATE',
  DEPARTED_STOP = 'DEPARTED_STOP',

  // TL Specific
  INFO = 'INFO',
  ETA_OUT_OF_DATE = 'ETA_OUT_OF_DATE',
  DEPARTED_FINAL_STOP = 'DEPARTED_FINAL_STOP',
}

const message = defineMessages({
  arrived: { id: 'shipmentList.statusUpdateReasonDescription.arrived', defaultMessage: 'Arrived' },
  timedOut: { id: 'shipmentList.statusUpdateReasonDescription.timedOut', defaultMessage: 'Timed Out' },
  availableForPickup: {
    id: 'shipmentList.statusUpdateReasonDescription.availableForPickup',
    defaultMessage: 'Available for Pickup',
  },
  canceled: { id: 'shipmentList.statusUpdateReasonDescription.canceled', defaultMessage: 'Canceled' },
  delayed: { id: 'shipmentList.statusUpdateReasonDescription.delayed', defaultMessage: 'Delayed' },
  delivered: { id: 'shipmentList.statusUpdateReasonDescription.delivered', defaultMessage: 'Delayed' },
  deliveryMissed: {
    id: 'shipmentList.statusUpdateReasonDescription.deliveryMissed',
    defaultMessage: 'Delivery Missed',
  },
  departed: { id: 'shipmentList.statusUpdateReasonDescription.departed', defaultMessage: 'Departed' },
  exception: { id: 'shipmentList.statusUpdateReasonDescription.exception', defaultMessage: 'Exception' },
  held: { id: 'shipmentList.statusUpdateReasonDescription.held', defaultMessage: 'Held' },
  loadedForDelivery: {
    id: 'shipmentList.statusUpdateReasonDescription.loadedForDelivery',
    defaultMessage: 'Loaded for Delivery',
  },
  notFound: { id: 'shipmentList.statusUpdateReasonDescription.notFound', defaultMessage: 'Not found' },
  outForDelivery: {
    id: 'shipmentList.statusUpdateReasonDescription.outForDelivery',
    defaultMessage: 'Out for delivery',
  },
  pickedUp: { id: 'shipmentList.statusUpdateReasonDescription.pickedUp', defaultMessage: 'Picked Up' },
  received: { id: 'shipmentList.statusUpdateReasonDescription.received', defaultMessage: 'Received' },
  returnToSender: {
    id: 'shipmentList.statusUpdateReasonDescription.returnToSender',
    defaultMessage: 'Return to Sender',
  },
  shipmentCreated: {
    id: 'shipmentList.statusUpdateReasonDescription.shipmentCreated',
    defaultMessage: 'Shipment Created',
  },
  scheduled: { id: 'shipmentList.statusUpdateReasonDescription.scheduled', defaultMessage: 'Scheduled' },
  acquiringLocation: {
    id: 'shipmentList.statusUpdateReasonDescription.acquiringLocation',
    defaultMessage: 'Acquiring Location',
  },
  loadedOntoTrain: {
    id: 'shipmentList.statusUpdateReasonDescription.loadedOntoTrain',
    defaultMessage: 'Loaded Onto Train',
  },
  waitingToBeLoaded: {
    id: 'shipmentList.statusUpdateReasonDescription.waitingToBeLoaded',
    defaultMessage: 'Waiting To Be Loaded',
  },
  unloaded: {
    id: 'shipmentList.statusUpdateReasonDescription.unloaded',
    defaultMessage: 'Unloaded',
  },
  inMotion: {
    id: 'shipmentList.statusUpdateReasonDescription.inMotion',
    defaultMessage: 'In Motion',
  },
  idle: {
    id: 'shipmentList.statusUpdateReasonDescription.idle',
    defaultMessage: 'Idle',
  },
  inGate: {
    id: 'shipmentList.statusUpdateReasonDescription.inGate',
    defaultMessage: 'In Gate',
  },
  ramped: {
    id: 'shipmentList.statusUpdateReasonDescription.ramped',
    defaultMessage: 'Ramped',
  },
  deramped: {
    id: 'shipmentList.statusUpdateReasonDescription.deramped',
    defaultMessage: 'Deramped',
  },
  notified: { id: 'shipmentList.statusUpdateReasonDescription.notified', defaultMessage: 'Notified' },
  outGate: { id: 'shipmentList.statusUpdateReasonDescription.outGate', defaultMessage: 'Out Gate' },
  departedStop: { id: 'shipmentList.statusUpdateReasonDescription.departedStop', defaultMessage: 'Departed Stop' },
});

export const ShipmentStatusReasonCodeIntlText: {
  [P in ShipmentStatusReasonCodeEnum]: { id: string; defaultMessage: string } | undefined;
} = {
  [ShipmentStatusReasonCodeEnum.ARRIVED]: message.arrived,
  [ShipmentStatusReasonCodeEnum.TIMED_OUT]: message.timedOut,
  [ShipmentStatusReasonCodeEnum.AVAILABLE_FOR_PICKUP]: message.availableForPickup,
  [ShipmentStatusReasonCodeEnum.CANCELED]: message.canceled,
  [ShipmentStatusReasonCodeEnum.NONE]: undefined,
  [ShipmentStatusReasonCodeEnum.DELAYED]: message.delayed,
  [ShipmentStatusReasonCodeEnum.DELIVERED]: message.delivered,
  [ShipmentStatusReasonCodeEnum.DELIVERY_MISSED]: message.deliveryMissed,
  [ShipmentStatusReasonCodeEnum.DEPARTED]: message.departed,
  [ShipmentStatusReasonCodeEnum.EXCEPTION]: message.exception,
  [ShipmentStatusReasonCodeEnum.HELD]: message.held,
  [ShipmentStatusReasonCodeEnum.LOADED_FOR_DELIVERY]: message.loadedForDelivery,
  [ShipmentStatusReasonCodeEnum.NOT_FOUND]: message.notFound,
  [ShipmentStatusReasonCodeEnum.OUT_FOR_DELIVERY]: message.outForDelivery,
  [ShipmentStatusReasonCodeEnum.PICKED_UP]: message.pickedUp,
  [ShipmentStatusReasonCodeEnum.RECEIVED]: message.received,
  [ShipmentStatusReasonCodeEnum.RETURN_TO_SENDER]: message.returnToSender,
  [ShipmentStatusReasonCodeEnum.SHIPMENT_CREATED]: message.shipmentCreated,
  [ShipmentStatusReasonCodeEnum.DELIVERY_APPOINTMENT]: undefined,
  [ShipmentStatusReasonCodeEnum.UNKNOWN]: undefined,
  [ShipmentStatusReasonCodeEnum.SCHEDULED]: message.scheduled,
  [ShipmentStatusReasonCodeEnum.ACQUIRING_LOCATION]: message.acquiringLocation,
  [ShipmentStatusReasonCodeEnum.LOADED_ONTO_TRAIN]: message.loadedOntoTrain,
  [ShipmentStatusReasonCodeEnum.WAITING_TO_BE_LOADED]: message.waitingToBeLoaded,
  [ShipmentStatusReasonCodeEnum.UNLOADED]: message.unloaded,
  [ShipmentStatusReasonCodeEnum.IN_MOTION]: message.inMotion,
  [ShipmentStatusReasonCodeEnum.IDLE]: message.idle,
  [ShipmentStatusReasonCodeEnum.IN_GATE]: message.inGate,
  [ShipmentStatusReasonCodeEnum.RAMPED]: message.ramped,
  [ShipmentStatusReasonCodeEnum.DERAMPED]: message.deramped,
  [ShipmentStatusReasonCodeEnum.NOTIFIED]: message.notified,
  [ShipmentStatusReasonCodeEnum.OUT_GATE]: message.outGate,
  [ShipmentStatusReasonCodeEnum.DEPARTED_STOP]: message.departedStop,
  [ShipmentStatusReasonCodeEnum.INFO]: undefined,
  [ShipmentStatusReasonCodeEnum.ETA_OUT_OF_DATE]: undefined,
  [ShipmentStatusReasonCodeEnum.DEPARTED_FINAL_STOP]: undefined,
};
