import * as React from 'react';
import { Button } from 'ui-components';

import { Theme } from '../../models/Theme';

import * as styles from './EmailPreview.module.scss';

export interface EmailPreviewProps {
  selectedTheme: Theme;
}
const EmailPreview: React.FunctionComponent<EmailPreviewProps> = ({ selectedTheme }) => {
  return (
    <div id="emailPreview" className={styles.emailPreview} style={{ borderColor: selectedTheme.primaryColor }}>
      {!selectedTheme.isDefaultLogo && <img src={selectedTheme.logoUrl} alt="Email Logo" className={styles.logo} />}
      <div className={styles.emailHeader} style={{ color: selectedTheme.primaryColor }}>
        Shipment Arriving to Pickup
      </div>
      <div className={styles.emailSubHeader}>within 2 hours, 23 minutes</div>
      <div className={styles.divider} />
      <table className={styles.emailDetails}>
        <tbody>
          <tr>
            <td>BOL# | ORDER#</td>
            <td>12345678 | 12345678</td>
          </tr>
          <tr>
            <td>Origin</td>
            <td>Capco Mfg // Detroit, MI</td>
          </tr>
          <tr>
            <td>Destination</td>
            <td>Napolean Goods // Chicago, IL</td>
          </tr>
          <tr>
            <td>Provider</td>
            <td>HIJK Trucking</td>
          </tr>
          <tr>
            <td>Estimated Pickup</td>
            <td>02/01/18: 4:23PM CST</td>
          </tr>
          <tr>
            <td>Scheduled Pickup</td>
            <td>02/01/18, 10:30AM CST - 02/01/18, 2:00PM</td>
          </tr>
          <tr>
            <td>Most Recent Location</td>
            <td>Kalamazoo, MI</td>
          </tr>
          <tr>
            <td>Last Updated</td>
            <td>02/01/18 at 11:02am</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.divider} />
      <Button type="primary" className={styles.viewButton} style={{ backgroundColor: selectedTheme.primaryColor }}>
        VIEW SHIPMENT
      </Button>
      <div className={styles.footer}>{selectedTheme.emailFooter}</div>
    </div>
  );
};

export default EmailPreview;
