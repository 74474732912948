import { injectIntl, FormattedMessage } from 'react-intl';

import CheckList from '../../common/checkList/CheckList';

import filterMessages from '../modes/filterMessages.json';

const TemperatureTrackingFilter = (props) => {
  const isShipmentListFiltersHorizontalEnabled = props.isShipmentListFiltersHorizontalEnabled;

  const listTitle = isShipmentListFiltersHorizontalEnabled
    ? ''
    : props.intl.formatMessage(filterMessages.temperatureTracking);

  const temperatureTrackingFilters = [
    {
      id: filterMessages.withinRange.id,
      dataKey: filterMessages.withinRange.defaultMessage,
      labelText: <FormattedMessage {...filterMessages.withinRange} />,
      propertyName: 'withinRange',
      checked: false,
    },
    {
      id: filterMessages.belowLowBoundSettings.id,
      dataKey: filterMessages.belowLowBoundSettings.defaultMessage,
      labelText: isShipmentListFiltersHorizontalEnabled ? (
        <FormattedMessage {...filterMessages.belowLowBound} />
      ) : (
        <FormattedMessage {...filterMessages.belowLowBoundSettings} />
      ),
      propertyName: 'belowLowBoundSettings',
      checked: false,
    },
    {
      id: filterMessages.aboveHighBoundSettings.id,
      dataKey: filterMessages.aboveHighBoundSettings.defaultMessage,
      labelText: isShipmentListFiltersHorizontalEnabled ? (
        <FormattedMessage {...filterMessages.aboveHighBound} />
      ) : (
        <FormattedMessage {...filterMessages.aboveHighBoundSettings} />
      ),
      propertyName: 'aboveHighBoundSettings',
      checked: false,
    },
    {
      id: filterMessages.viewAll.id,
      dataKey: filterMessages.viewAll.defaultMessage,
      labelText: <FormattedMessage {...filterMessages.viewAll} />,
      propertyName: 'viewAll',
      checked: false,
    },
  ];

  const checkListData = temperatureTrackingFilters.map((item) => ({
    ...item,
    checked: props.filter.temperatureTracking.includes(item.dataKey),
  }));

  const onCheck = (checkboxItem) =>
    props.handleCheckboxGroupUpdate({ ...checkboxItem, labelText: checkboxItem.dataKey }, 'temperatureTracking');

  return <CheckList mode={props.checkboxTheme} listTitle={listTitle} checkListData={checkListData} checkFn={onCheck} />;
};

export default injectIntl(TemperatureTrackingFilter);
