import HereMapsBehavior from '../../../../../common/hereMaps/hereMapsBehavior';
import currentLocationPopupHtml from '../popup/currentStopPopupHtml';

/**
 * Custom html marker and popup styling for the current location.
 *
 * @param mapRef reference to the map instance
 * @param record an object containing location data
 */
const currentStopHtmlMarker = (mapRef, record) => {
  let fillColor = (record && record.statusColor) || `--primary-blue`;

  let currentStopMarker = `
      <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 28 40" width="36" class="current-stop-marker">
        <path fill="var(${fillColor})" d="M14,0A14,14,0,0,0,0,14C0,24.5,14,40,14,40S28,24.5,28,14A14,14,0,0,0,14,0Z"/>
        <circle fill="#fff" cx="14" cy="14" r="5"/>
      </svg>
    `;
  // Example below was copied from HERE Maps docs and translated to ES6 - Mark Serrano
  let outerElement = document.createElement('div');
  let innerElement = document.createElement('div');
  outerElement.style.userSelect = 'none';
  outerElement.style.webkitUserSelect = 'none';
  outerElement.style.msUserSelect = 'none';
  outerElement.style.mozUserSelect = 'none';
  outerElement.style.cursor = 'default';
  innerElement.style.color = 'red';
  innerElement.style.backgroundColor = 'transparent';
  innerElement.style.border = 'none';
  innerElement.style.font = 'normal 12px arial';
  innerElement.style.lineHeight = '12px';
  innerElement.style.paddingTop = '0';
  innerElement.style.paddingLeft = '0';
  innerElement.style.width = '24px';
  innerElement.style.height = '24px';
  innerElement.style.marginTop = '-36px';
  innerElement.style.marginLeft = '-18px';

  innerElement.innerHTML = currentStopMarker;
  outerElement.appendChild(innerElement);

  // Logic to remove the bubble. A little bit buggy when using a custom html marker - Mark Serrano
  const onEnter = (evt) => {
    if (
      mapRef.bubble &&
      mapRef.bubble.currentPointer &&
      mapRef.bubble.currentPointer.viewportX !== evt.screenX &&
      mapRef.bubble.currentPointer.viewportY !== evt.screenY
    ) {
      mapRef.bubble.close();
      mapRef.bubble = null;
    } else if (mapRef.bubble === null) {
      const position = { lat: record.lat, lng: record.lng };
      const content = currentLocationPopupHtml(record);
      const currentPointer = { viewportX: evt.screenX, viewPortY: evt.screenY };
      HereMapsBehavior.addBubble(mapRef, position, content, currentPointer);
      HereMapsBehavior.setCenter(mapRef, position);
    }
  };

  let domIcon = new H.map.DomIcon(outerElement, {
    onAttach: function (clonedElement, domIcon, domMarker) {
      clonedElement.addEventListener('click', onEnter);
    },
    onDetach: function (clonedElement, domIcon, domMarker) {
      clonedElement.removeEventListener('click', onEnter);
    },
  });

  return domIcon;
};

export default currentStopHtmlMarker;
