import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import get from 'lodash/get';
import { Textfit } from 'react-textfit';
import { SVGIcon } from 'ui-components';
import {
  darkGreen,
  orange,
  errorRed,
  blueChill,
  white,
  primaryBlue,
  primaryGreyFive,
  primaryGreyEighty,
} from 'styles/colors';

import {
  getHealthRange,
  deserializeHealthScoreConfig,
} from 'components/QuickViewCardConfig/TitleAndSettingsStep/utils';
import { parseCountToPercent } from 'components/QuickViewCardConfig/utils';
import { HEALTH_RANGES } from 'components/QuickViewCardConfig/constants.ts';

import './Widget.scss';

import { withTheme } from '../../../contexts/ThemeContext';

const StyledShipmentContainer = styled('div')`
  background: ${white};
  box-shadow: 0px 1px 2px rgba(45, 41, 38, 0.5);
  padding: 32px;

  &.is-editing {
    position: relative;

    &::after {
      background-color: rgba(45, 41, 38, 0.5);
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
`;

const StyledShipmentHeading = styled('div')`
  margin-bottom: 24px;
`;

const StyledShipmentTitle = styled('div')`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`;

const StyledButtonIcon = styled('button')`
  background: none;
  border: 0 none;
  color: ${primaryBlue};
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: 500;
  padding: 0;

  & + & {
    margin-top: 8px;
  }

  .icon {
    background: ${primaryGreyFive};
    border-radius: 100px;
    font-size: 16px;
    height: 32px;
    width: 32px;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &[data-option] {
    position: relative;

    &::before {
      transition: 0.15s ease-in-out;
      pointer-events: none;
      background: rgba(45, 41, 38, 0.92);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      color: white;
      content: attr(data-option);
      font-size: 14px;
      padding: 6px 10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
      opacity: 0;
    }

    &:hover {
      &::before {
        right: calc(100% + 8px);
        opacity: 1;
      }
    }
  }
`;

const StyledCount = styled('div')`
  color: ${primaryGreyEighty};
  font-weight: 500;
  font-size: 56px;
  line-height: 48px;
  margin-bottom: 4px;
`;

const StyledTotalCount = styled('div')`
  color: ${primaryGreyEighty};
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
`;

const StyledHealthRange = styled('div')`
  ${({ matchHealthRange }) => {
    switch (matchHealthRange) {
      case 'excellent':
        return { color: darkGreen };
      case 'acceptable':
        return { color: blueChill };
      case 'concerning':
        return { color: orange };
      case 'problematic':
        return { color: errorRed };
      default:
        return { color: 'inherit' };
    }
  }}
`;

const StyledHealthRangeIcon = styled(SVGIcon)`
  font-size: 20px;
  margin-right: 6px;
`;

const StyledHealthRangeTitle = styled('span')`
  font-size: 16px;
  font-weight: 500;
`;

const StyledConfigureMenu = styled('div')`
  position: absolute;
  z-index: 1;
  top: 32px;
  right: 32px;
`;

export const ConfigureMenu = ({ onEdit, onDuplicate, onDelete }) => {
  return (
    <StyledConfigureMenu className="d-flex flex-column">
      <div className="position-relative">
        <StyledButtonIcon onClick={onEdit} data-option="Edit" data-testid="widget-edit-btn">
          <div className="d-flex align-items-center justify-content-end">
            <div className="icon">
              <SVGIcon name="edit" />
            </div>
          </div>
        </StyledButtonIcon>
        <StyledButtonIcon onClick={onDuplicate} data-option="Duplicate" data-testid="widget-duplicate-btn">
          <div className="d-flex align-items-center justify-content-end">
            <div className="icon">
              <SVGIcon name="duplicate" />
            </div>
          </div>
        </StyledButtonIcon>
        <StyledButtonIcon onClick={onDelete} data-option="Delete" data-testid="widget-delete-btn">
          <div className="d-flex align-items-center justify-content-end">
            <div className="icon">
              <SVGIcon name="delete" />
            </div>
          </div>
        </StyledButtonIcon>
      </div>
    </StyledConfigureMenu>
  );
};

const renderAvailableIcon = (props) => {
  if (props.icon && props.icon.length > 0) {
    return (
      <Col xs={8} className="widget-icon-column">
        <img draggable="false" src={props.icon} alt="pre-defined search" className="widget-icon" />
      </Col>
    );
  } else {
    return '';
  }
};

const renderIconAndInformation = (props) => {
  return renderInformationVertical(props);
};

const renderInformationVertical = (props) => {
  function onSavedClick() {
    props.onSavedSearchClick({
      //eslint-disable-line react/jsx-no-bind
      searchId: props.searchId,
      searchLink: props.searchLink,
    });
  }

  const isQuickviewV2Enabled = get(props, 'isQuickviewV2Enabled', false);
  const editing = get(props, 'editing', false);
  const onDelete = () => props.deleteQuickViewTile();
  const isHealthScoreEnabled = get(props, 'healthScoreConfig.enabled', false);
  const numberFormat = get(props, 'numberFormat', 'COUNT');
  const healthRanges = get(props, 'healthScoreConfig.ranges', []);
  const isPercentage = numberFormat === 'PERCENTAGE';
  const shouldParseToPercentage = isPercentage && props.totalCount;
  const matchHealthRange = getHealthRange(
    {
      isEnabled: isHealthScoreEnabled,
      numberFormat: props.healthScoreConfig?.numberFormat || 'COUNT',
      ranges: deserializeHealthScoreConfig(healthRanges),
    },
    props.headingCount,
    props.totalCount
  );

  const hasColorScheme = isHealthScoreEnabled && matchHealthRange;

  if (isQuickviewV2Enabled) {
    return (
      <StyledShipmentContainer className={`h-100 ${editing ? 'is-editing' : ''}`}>
        <StyledShipmentHeading className="d-flex justify-content-between align-items-start">
          <StyledShipmentTitle>{props.subHeading.substring(0, 50)}</StyledShipmentTitle>
          {editing ? (
            <ConfigureMenu onEdit={props.onEdit} onDuplicate={props.onDuplicate} onDelete={onDelete} />
          ) : (
            <StyledButtonIcon onClick={onSavedClick}>
              <div className="icon">
                <SVGIcon name="chevron-right" />
              </div>
            </StyledButtonIcon>
          )}
        </StyledShipmentHeading>
        <div>
          <StyledCount>
            <StyledHealthRange matchHealthRange={hasColorScheme ? matchHealthRange : ''}>
              <Textfit mode="single" forceSingleModeWidth min={36} max={56}>
                {shouldParseToPercentage
                  ? `${parseCountToPercent(props.headingCount, props.totalCount).toFixed(2)}% `
                  : props.headingCount.toLocaleString()}
              </Textfit>
            </StyledHealthRange>
          </StyledCount>
          <StyledTotalCount>
            {props.totalCount ? (
              <span className="text-lowercase">
                {/* TODO this is not i18n'd */}
                {isPercentage && `${props.headingCount.toLocaleString()}`} of {props.totalCount.toLocaleString()}{' '}
                {props.heading}
              </span>
            ) : (
              <span className="text-lowercase">{props.heading}</span>
            )}
          </StyledTotalCount>
          {hasColorScheme && (
            <StyledHealthRange className="d-flex align-items-center mt-2" matchHealthRange={matchHealthRange}>
              <StyledHealthRangeIcon name={HEALTH_RANGES[matchHealthRange].iconName} />
              <StyledHealthRangeTitle>{HEALTH_RANGES[matchHealthRange].text}</StyledHealthRangeTitle>
            </StyledHealthRange>
          )}
        </div>
      </StyledShipmentContainer>
    );
  }

  return (
    <>
      <Row noGutters>
        <Col xs={16} className="widget-sub-heading">
          {props.subHeading.substring(0, 50)}
        </Col>
        {renderAvailableIcon(props)}
      </Row>

      <Row noGutters>
        <Col xs={16} className="widget-info-column">
          <span className="widget-count">{props.headingCount}</span>
          {props.heading}
        </Col>
        <Col xs={8} className="widget-search-link" onClick={onSavedClick}>
          {props.searchLinkLabel}
        </Col>
      </Row>
    </>
  );
};

const getWidgetContainerClass = (props) => {
  if (props.icon && props.icon.length > 0) {
    return 'widget-container no-gutters';
  } else {
    return 'widget-container span-horizontal row no-gutters align-items-center';
  }
};

/**
 * Widget component will be pre-defined/saved search result panels
 */

const Widget = function WidgetDeclaration(props) {
  function onSavedClick(e) {
    props.onSavedSearchClick(
      {
        searchId: props.searchId,
        searchLink: props.searchLink,
      },
      e
    );
  }

  const isQuickviewV2Enabled = get(props, 'isQuickviewV2Enabled', false);

  const style = isQuickviewV2Enabled
    ? {}
    : {
        boxShadow: props.editing ? `0 1px 2px 0 rgba(0, 0, 0, 0.5), 0 0 10px 0 ${props.theme.primaryColor} ` : '',
      };

  return (
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      data-tilefilter={props['data-tilefilter']}
      data-tilemode={props['data-tilemode']}
      data-tilesearchkeywords={props['data-tilesearchkeywords']}
      data-testid="quick-view-widget"
      className={props.isQuickviewV2Enabled ? 'h-100' : getWidgetContainerClass(props)}
      onClick={onSavedClick}
      style={style}
    >
      {renderIconAndInformation(props)}
    </div>
  );
};

Widget.defaultProps = {
  editing: false,
};

Widget.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  headingCount: PropTypes.number.isRequired,
  subHeading: PropTypes.string,
  searchLinkLabel: PropTypes.string.isRequired,
  searchLink: PropTypes.string.isRequired,
  onSavedSearchClick: PropTypes.func.isRequired,
  editing: PropTypes.bool,
};

export const WidgetComponent = Widget;
export default withTheme(Widget);
