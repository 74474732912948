import { Reducer } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { TenantAttribute, ShipmentTenantAttribute } from 'models';
import * as tenantAttributesActions from './actions';

export type TenantAttributesAction = ActionType<typeof tenantAttributesActions>;

export interface TenantAttributesState {
  shipmentId?: number;
  tenantAttributes?: TenantAttribute[];
  shipmentTenantAttributes?: ShipmentTenantAttribute[];
  fetchingTenantAttributesError?: Error;
  fetchingShipmentTenantAttributesError?: Error;
  creatingTenantAttributesError?: Error;
  creatingOrUpdatingShipmentTenantAttributesError?: Error;
  isFetchingTenantAttributes: boolean;
  isCreatingTenantAttributes: boolean;
  isFetchingShipmentTenantAttributes: boolean;
  isCreatingOrUpdatingShipmentTenantAttributes: boolean;
  shipmentTenantAttributesEditMode: boolean;
}

const initialState: TenantAttributesState = {
  shipmentId: undefined,
  tenantAttributes: [],
  shipmentTenantAttributes: [],
  fetchingTenantAttributesError: undefined,
  creatingTenantAttributesError: undefined,
  fetchingShipmentTenantAttributesError: undefined,
  creatingOrUpdatingShipmentTenantAttributesError: undefined,
  isFetchingTenantAttributes: false,
  isCreatingTenantAttributes: false,
  isFetchingShipmentTenantAttributes: false,
  isCreatingOrUpdatingShipmentTenantAttributes: false,
  shipmentTenantAttributesEditMode: false,
};

const tenantAttributesReducer: Reducer<TenantAttributesState, TenantAttributesAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(tenantAttributesActions.fetchTenantAttributes.request): {
      return {
        ...state,
        tenantAttributes: [],
        isFetchingTenantAttributes: true,
        fetchingTenantAttributesError: undefined,
      };
    }
    case getType(tenantAttributesActions.fetchTenantAttributes.success): {
      return {
        ...state,
        tenantAttributes: action.payload,
        isFetchingTenantAttributes: false,
      };
    }
    case getType(tenantAttributesActions.fetchTenantAttributes.failure): {
      return {
        ...state,
        isFetchingTenantAttributes: false,
        fetchingTenantAttributesError: action.payload,
      };
    }
    case getType(tenantAttributesActions.createTenantAttributes.request): {
      return {
        ...state,
        isCreatingTenantAttributes: true,
      };
    }
    case getType(tenantAttributesActions.createTenantAttributes.success): {
      action.payload.forEach((attr) => {
        attr.isNew = true;
      });
      const tenantAttributes = [...(state.tenantAttributes || [])].concat(action.payload);
      return {
        ...state,
        tenantAttributes,
        isCreatingTenantAttributes: false,
      };
    }
    case getType(tenantAttributesActions.createTenantAttributes.failure): {
      return {
        ...state,
        isCreatingTenantAttributes: false,
        creatingTenantAttributesError: action.payload,
      };
    }
    case getType(tenantAttributesActions.fetchShipmentTenantAttributes.request): {
      return {
        ...state,
        shipmentTenantAttributes: [],
        isFetchingShipmentTenantAttributes: true,
        fetchingShipmentTenantAttributesError: undefined,
      };
    }
    case getType(tenantAttributesActions.fetchShipmentTenantAttributes.success): {
      return {
        ...state,
        shipmentTenantAttributes: action.payload,
        isFetchingShipmentTenantAttributes: false,
      };
    }
    case getType(tenantAttributesActions.fetchShipmentTenantAttributes.failure): {
      return {
        ...state,
        isFetchingShipmentTenantAttributes: false,
        fetchingShipmentTenantAttributesError: action.payload,
      };
    }
    case getType(tenantAttributesActions.createOrUpdateShipmentTenantAttributes.request): {
      return {
        ...state,
        isCreatingOrUpdatingShipmentTenantAttributes: true,
      };
    }
    case getType(tenantAttributesActions.createOrUpdateShipmentTenantAttributes.success): {
      return {
        ...state,
        isCreatingOrUpdatingShipmentTenantAttributes: false,
        shipmentTenantAttributesEditMode: false,
      };
    }
    case getType(tenantAttributesActions.createOrUpdateShipmentTenantAttributes.failure): {
      return {
        ...state,
        isCreatingOrUpdatingShipmentTenantAttributes: false,
        creatingOrUpdatingShipmentTenantAttributesError: action.payload,
      };
    }
    case getType(tenantAttributesActions.reset): {
      return {
        ...initialState,
      };
    }
    case getType(tenantAttributesActions.toggleTenantAttributesMode): {
      return {
        ...state,
        shipmentTenantAttributesEditMode: !state.shipmentTenantAttributesEditMode,
      };
    }
    default:
      return state;
  }
};

export default tenantAttributesReducer;
