import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { ShipmentModeEnum } from '../../../models';
import { Note, NoteRequest } from '../models/Note';

export enum Types {
  FETCH_NOTES = 'NOTE/FETCH_NOTES',
  FETCH_NOTES_SUCCESS = 'NOTE/FETCH_NOTES_SUCCESS',
  FETCH_NOTES_FAILURE = 'NOTE/FETCH_NOTES_FAILURE',
  FETCH_PUBLIC_NOTES = 'NOTE/FETCH_PUBLIC_NOTES',
  FETCH_PUBLIC_NOTES_SUCCESS = 'NOTE/FETCH_PUBLIC_NOTES_SUCCESS',
  FETCH_PUBLIC_NOTES_FAILURE = 'NOTE/FETCH_PUBLIC_NOTES_FAILURE',
  CREATE_NOTE = 'NOTE/CREATE_NOTE',
  CREATE_NOTE_SUCCESS = 'NOTE/CREATE_NOTE_SUCCESS',
  CREATE_NOTE_FAILURE = 'NOTE/CREATE_NOTE_FAILURE',
  UPDATE_NOTE = 'NOTE/UPDATE_NOTE',
  UPDATE_NOTE_SUCCESS = 'NOTE/UPDATE_NOTE_SUCCESS',
  UPDATE_NOTE_FAILURE = 'NOTE/UPDATE_NOTE_FAILURE',
  DELETE_NOTE = 'NOTE/DELETE_NOTE',
  DELETE_NOTE_SUCCESS = 'NOTE/DELETE_NOTE_SUCCESS',
  DELETE_NOTE_FAILURE = 'NOTE/DELETE_NOTE_FAILURE',
  RESET = 'NOTE/RESET',
  TOGGLE_NOTE_WINDOW = 'NOTE/TOGGLE_NOTE_WINDOW',
}

export const fetchNotes = createAsyncAction(Types.FETCH_NOTES, Types.FETCH_NOTES_SUCCESS, Types.FETCH_NOTES_FAILURE)<
  { shipmentId?: string; mode?: ShipmentModeEnum; masterShipmentId?: string },
  Note[],
  Error
>();

export const fetchPublicNotes = createAsyncAction(
  Types.FETCH_PUBLIC_NOTES,
  Types.FETCH_PUBLIC_NOTES_SUCCESS,
  Types.FETCH_PUBLIC_NOTES_FAILURE
)<{ shareToken: string; useIntermodalEndpoint?: boolean }, Note[], Error>();

export const createNote = createAsyncAction(Types.CREATE_NOTE, Types.CREATE_NOTE_SUCCESS, Types.CREATE_NOTE_FAILURE)<
  NoteRequest,
  Note,
  Error
>();

export const updateNote = createAsyncAction(Types.UPDATE_NOTE, Types.UPDATE_NOTE_SUCCESS, Types.UPDATE_NOTE_FAILURE)<
  NoteRequest,
  Note,
  Error
>();

export const deleteNote = createAsyncAction(Types.DELETE_NOTE, Types.DELETE_NOTE_SUCCESS, Types.DELETE_NOTE_FAILURE)<
  string,
  string,
  Error
>();

export const reset = createStandardAction(Types.RESET)<void>();
export const toggleNotesWindow = createStandardAction(Types.TOGGLE_NOTE_WINDOW)<void>();
