import qs from 'querystring';
import { useState, useContext } from 'react';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import get from 'lodash/get';
import head from 'lodash/head';
import isEqual from 'lodash/isEqual';
import { Autosuggest } from 'ui-components';

import useDebounce from 'hooks/useDebounce';
import { FILTER_RESULT_DELAY } from 'common/AppConstants';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { getPrincipalAuthorizations } from 'common/authorizations';
import AutocompleteFilter from '../../common/autocompleteFilter/AutocompleteFilter';
import { SUGGESTIONS_MAP } from '../ducks/types';
import useSuggestions from '../util/useSuggestions';

const INITIAL_AMOUNT_VISIBLE_PORT_NAME = 3;

const messages = defineMessages({
  filterByPortName: {
    id: 'quickView.oceanPortNameFilter.filterByPortName',
    defaultMessage: 'Filter by Port Name',
  },
});

// Currently we are mapping port by code
const OceanPortNameFilter = (props) => {
  const [currentValue, setCurrentValue] = useState('');
  const debouncedValue = useDebounce(currentValue, FILTER_RESULT_DELAY);
  const modes = get(props, 'modeFilters', []);
  const isAllMode = isEqual(head(modes), 'ALL');
  const params = isAllMode ? qs.stringify({ q: debouncedValue }) : qs.stringify({ q: debouncedValue, modes: modes });

  const suggestedLocations = useSuggestions(SUGGESTIONS_MAP.locode, params);
  const listData = get(props, 'filter.stopLocodes', []);

  const principalContext = useContext(PrincipalContext);
  const authorizations = getPrincipalAuthorizations(principalContext);
  const isShipmentListFiltersHorizontalEnabled = authorizations.shipmentListFiltersHorizontalEnabled();

  if (isShipmentListFiltersHorizontalEnabled) {
    return (
      <Autosuggest
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        placeholder="Search by port code"
        suggestions={suggestedLocations}
        listData={listData}
        handleAutocompleteUpdate={props.handleAutocompleteUpdate}
        name="stopLocodes"
      />
    );
  }

  return (
    <Col>
      <FormattedMessage id="quickView.oceanPortNameFilter.portName" defaultMessage="PORT NAME" tagName="label" />
      <AutocompleteFilter
        idProp="portname-input-filter"
        placeholder={props.intl.formatMessage(messages.filterByPortName)}
        currentValue={currentValue}
        dataSource={suggestedLocations}
        updateFn={(inputValue) => setCurrentValue(inputValue)}
        newRequestFn={(filterItem) => props.handleAutocompleteUpdate(filterItem, 'stopLocodes')}
        listData={listData}
        showAllChips={get(props, 'showSelections.showAllHappeningCode')}
        passedInDeleteFn={(filterItem) => props.handleAutocompleteUpdate(filterItem, 'stopLocodes')}
        initialAmountVisible={INITIAL_AMOUNT_VISIBLE_PORT_NAME}
        showHideAllFn={() => props.toggleShowSelections('StopLocodes')}
        dataLocator="ocean-port-name-filter"
      />
    </Col>
  );
};

export default injectIntl(OceanPortNameFilter);
