import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { entityPageUrls } from 'common/AppConstants';
import { statusTextMap } from '../../shipment/common/ltl/enums/ltlStatusMap';
import PercentCompleteUtil from '../../shipment/common/utils/percentCompleteUtil';
import { mapStatus } from '../../shipmentDetails/LTL/ducks/mappers/status/mapStatus';
import {
  mapShipmentIdentifiers,
  ltlIdentifierTypes,
} from '../../shipmentDetails/common/mappers/shipmentIdentifiersMapper';
import { mapCarrierInfo } from '../../shipmentDetails/LTL/ducks/mappers/carrier/mapCarrier';
import shipmentDetailsModel from '../../shipmentDetails/ShipmentDetailsModel';
import LocationUtils from '../../../common/LocationUtils/LocationUtils';

export const mapShipmentLTL = (shipment, intl, useNewEntityPage = false) => {
  if (shipment) {
    const statusCardInfo = getStatusCardInfo(shipment);
    const derivedStatus = getDerivedStatus(shipment);

    const url = useNewEntityPage
      ? entityPageUrls.entityPageUiUrl(shipment.masterShipmentId)
      : '/tracking-details/ltl/' + get(shipment, 'shipmentId', -1);

    const mode = get(shipment, 'mode', false);
    const percentComplete = shipment.multiModalDerivedStatus
      ? PercentCompleteUtil.getPercentCompleteBasedOnMultiModalDerivedStatus(shipment.multiModalDerivedStatus)
      : PercentCompleteUtil.getPercentComplete_FallbackForLTL(derivedStatus);

    const originStopName = getStopName(get(shipment, 'shipmentStops'), 0);
    const destinationStopName = getStopName(get(shipment, 'shipmentStops'), shipment.shipmentStops.length - 1);
    const tenantCustomAttributes = get(shipment, 'tenantCustomAttributes', []);

    return {
      shipmentId: shipment.shipmentId,
      url: url,
      mode: mode,
      identifiers: statusCardInfo.identifiers,
      derivedStatus: derivedStatus,
      currentEta: false,
      currentTimezone: false,
      lastUpdate: statusCardInfo.status.lastUpdated,
      convertedDateTime: false,
      dwellTime: false,
      tenantCustomAttributes,
      pickupStopInfo: {
        ...updateStopInfo(
          get(shipment, 'pickupStopInfo'),
          statusCardInfo.status.stops[0],
          LocationUtils.getCountryCodeForLocation(
            get(shipment, 'pickupStopInfo'),
            get(shipment, 'pickupStopInfo.stopName'),
            shipment.shipmentStops
          )
        ),
        stopName: isNil(originStopName)
          ? intl.formatMessage({ id: 'shipmentList.shipment.pickup', defaultMessage: 'Pickup' })
          : originStopName,
      },
      deliveryStopInfo: {
        ...updateStopInfo(
          get(shipment, 'deliveryStopInfo'),
          statusCardInfo.status.stops[statusCardInfo.status.stops.length - 1],
          LocationUtils.getCountryCodeForLocation(
            get(shipment, 'deliveryStopInfo'),
            get(shipment, 'deliveryStopInfo.stopName'),
            shipment.shipmentStops
          )
        ),
        stopName: isNil(destinationStopName)
          ? intl.formatMessage({ id: 'shipmentList.shipment.delivery', defaultMessage: 'Delivery' })
          : destinationStopName,
      },
      carrierName: get(statusCardInfo, 'carrier.name'),
      flagText: statusCardInfo?.status?.flag?.text,
      flagColor: '--' + statusCardInfo?.status?.flag?.color,
      statusText: statusTextMap(intl)[derivedStatus].flagText,
      statusColor: '--' + statusCardInfo.status.progress.markerColor,
      percentComplete: percentComplete,
      statusDescription: statusCardInfo.status.arrivalForecast,
      sensorTracking: false,
      entitlementInfo: shipment.entitlementInfo,
      shipmentStopsCount: get(shipment, 'shipmentStops', []).length,
    };
  }
};

const updateStopInfo = (rawStop, formattedStop, countryCode) => {
  return isNil(rawStop) || isNil(formattedStop)
    ? false
    : {
        cityState: get(rawStop, 'cityState', false),
        formatedDateTimeText: get(formattedStop, 'tertiaryText', false),
        city: get(rawStop, 'city'),
        state: get(rawStop, 'state'),
        country: countryCode,
      };
};

const getStatusCardInfo = (shipment) => {
  return {
    identifiers: mapShipmentIdentifiers(shipment.shipmentIdentifiers, ltlIdentifierTypes),
    status: mapStatus(shipment, shipmentDetailsModel.status),
    carrier: mapCarrierInfo(shipment.carrier, shipmentDetailsModel.carrier),
  };
};

const getDerivedStatus = (shipment) => {
  let derivedStatus;
  if (shipment.derivedStatusCodes) {
    shipment.derivedStatusCodes.forEach((e) => {
      derivedStatus = e.value ? e.value : 'UNKNOWN';
    });
  } else {
    derivedStatus = 'UNKNOWN';
  }

  return derivedStatus;
};

const getStopName = (shipmentStops, stopNumber) => {
  return get(shipmentStops[stopNumber], 'location.contactDto.companyName');
};
