import * as React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import endpoints from './common/endpoints';

export interface PrivateRouteProps extends RouteProps {
  isLoggedIn: boolean;
  currentPath: string;
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = (props) => {
  if (props.isLoggedIn) {
    return <Route {...props} />;
  }
  return (
    <Redirect
      to={{
        pathname: endpoints.LOGIN,
        state: { successRedirectUrlOverride: props.currentPath },
      }}
    />
  );
};

export default PrivateRoute;
