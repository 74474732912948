import { Location } from '../../models/Location';

export const calculateMidpoint = (locations: Location[]): Location => {
  const total = locations.length;
  if (total === 1) {
    return locations[0];
  }

  let x: number = 0;
  let y: number = 0;
  let z: number = 0;
  locations.forEach((location, index) => {
    const latRads = toRads(location.latitude);
    const lngRads = toRads(location.longitude);
    x += Math.cos(latRads) * Math.cos(lngRads);
    y += Math.cos(latRads) * Math.sin(lngRads);
    z += Math.sin(latRads);
  });

  x = x / total;
  y = y / total;
  z = z / total;

  const centralLongitude: number = Math.atan2(y, x);
  const centralSquareRoot: number = Math.sqrt(x * x + y * y);
  const centralLatitude: number = Math.atan2(z, centralSquareRoot);

  return {
    latitude: (centralLatitude * 180) / Math.PI,
    longitude: (centralLongitude * 180) / Math.PI,
  };
};

const toRads = (val: number) => (val * Math.PI) / 180;
