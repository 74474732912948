import { put, takeLatest, all } from 'redux-saga/effects';
import { untouch, clearAsyncError, stopSubmit } from 'redux-form';
import get from 'lodash/get';
import find from 'lodash/find';
import keys from 'lodash/keys';
import isPlainObject from 'lodash/isPlainObject';
import difference from 'lodash/difference';

import { addEditFormConstants } from '../AddEditShipmentConstants';
import * as actions from './actions';
import * as types from './types';
import { navigateByDirection, navigateById } from './reducers';

const intermediateStopPrefix = 'addStops';
const stopFormFields = [
  'company',
  'address',
  'zipCode',
  'city',
  'state',
  'country',
  'startDate',
  'startTime',
  'endDate',
  'endTime',
];
const carrierFormFields = ['carrierType', 'carrierId'];
const trackingFormFields = ['driverMobileNumber', 'truckNumber', 'licensePlateNumber', 'shipmentOrder', 'shipmentBOL'];

export function* carrierSearchSuccess() {
  yield put(clearAsyncError(addEditFormConstants.mainFormName, 'carrierId'));
  yield put(actions.navigateStep(types.NEXT_STEP, addEditFormConstants.LANE_DETAIL.trackingInformation));
}

export function* carrierSearchFail() {
  const errors = {
    carrierId: 'Invalid carrier ID',
  };

  yield put(stopSubmit(addEditFormConstants.mainFormName, errors));
}

const getSelectedStepId = (steps) => get(find(steps, 'selected'), 'id');

export function* nextStep(action) {
  if (action.steps) {
    const nextStepId = getSelectedStepId(navigateByDirection(action.steps, types.NEXT_STEP));
    yield untouchFormFieldsByStepId(nextStepId, action.formData);
  } else if (action.id) {
    yield navigateStep(action);
  }
}

const getNextStepById = (action) => {
  let nextStepId;
  if (action.steps) {
    nextStepId = getSelectedStepId(navigateById(action.steps, action.id));
  } else {
    nextStepId = 'TRACKING_INFORMATION';
  }

  return nextStepId;
};

export function* navigateStep(action) {
  if (action.id) {
    yield untouchFormFieldsByStepId(getNextStepById(action), action.formData);
  }
}

function* untouchFormFieldsByStepId(id, formData) {
  const formFieldNames = getFormFieldNames(id, formData);
  if (formFieldNames) {
    yield put(untouch(addEditFormConstants.mainFormName, ...formFieldNames));
  }
}

function getFormFieldNames(id, formData) {
  let parent = '',
    formFields = [];

  if (id === 'CARRIER_TRACKING') {
    formFields = carrierFormFields;
  } else if (id === 'TRACKING_INFORMATION') {
    formFields = trackingFormFields;
  } else if (id === 'PICKUP_DETAIL') {
    parent = addEditFormConstants.PICKUP_STOP_ID;
    formFields = stopFormFields;
  } else if (id === 'DROPOFF_DETAIL') {
    parent = addEditFormConstants.DROP_OFF_STOP_ID;
    formFields = stopFormFields;
  } else if (id === 'ADD_STOPS') {
    parent = intermediateStopPrefix;
    formFields = stopFormFields;
  }

  const allFields = formFields.map((field) => (parent ? parent + '.' + field : field));
  const nonBlankFields = getNonBlankFormFields(parent, formData);

  return difference(allFields, nonBlankFields);
}

function getNonBlankFormFields(parentName, formData) {
  let parentObject;
  if (parentName) {
    parentObject = get(formData, parentName);
  } else {
    parentObject = formData;
  }

  if (isPlainObject(parentObject)) {
    return keys(parentObject).map((key) => parentName + '.' + key);
  }
  return [];
}

export function* watchTransitions() {
  yield all([
    takeLatest(types.CARRIER_SEARCH_SUCCESS, carrierSearchSuccess),
    takeLatest(types.CARRIER_SEARCH_FAIL, carrierSearchFail),
    takeLatest(types.NEXT_STEP, nextStep),
    takeLatest(types.NAVIGATE_STEP, navigateStep),
  ]);
}
