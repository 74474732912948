import get from 'lodash/get';
import isNil from 'lodash/isNil';
import find from 'lodash/find';

export const mapCarrierInfo = (carrierInfo) => {
  if (!isNil(carrierInfo)) {
    const identifiers = get(carrierInfo, 'vesselInfo.identifiers', []);
    return {
      vesselName: get(carrierInfo, 'vesselInfo.name'),
      mmsi: get(find(identifiers, { type: 'MMSI' }), 'value'),
      callSign: get(find(identifiers, { type: 'CALL_SIGN' }), 'value'),
      imo: get(find(identifiers, { type: 'IMO' }), 'value'),
    };
  }
};
