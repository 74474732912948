import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const CustomButton = (props) => {
  const {
    classes,
    link,
    upload,
    href,
    clickFn,
    disabled = false,
    blurFn,
    type = 'button',
    download = false,
    'data-locator': dataLocator = '',
  } = props;
  if (link) {
    return (
      <Link to={link} className="custom-button-link">
        <Button
          className={`custom-button ${classes}`}
          disabled={disabled}
          onClick={clickFn}
          onBlur={blurFn}
          type={type}
          download={download}
          data-locator={dataLocator}
        >
          {props.children}
        </Button>
      </Link>
    );
  } else if (upload) {
    return (
      <label htmlFor={upload}>
        <Button
          className={`custom-button ${classes}`}
          disabled={disabled}
          onClick={clickFn}
          onBlur={blurFn}
          type={type}
          variant="raised"
          component="span"
          data-locator={dataLocator}
        >
          {props.children}
        </Button>
      </label>
    );
  } else {
    return (
      <Button
        className={`custom-button ${classes}`}
        disabled={disabled}
        onClick={clickFn}
        onBlur={blurFn}
        type={type}
        download={download}
        component={download ? 'a' : 'button'}
        href={href}
        data-locator={dataLocator}
      >
        {props.children}
      </Button>
    );
  }
};

export default CustomButton;
