import { useState } from 'react';
import { useIntl } from 'react-intl';

import isEmpty from 'lodash/isEmpty';
import { formatCriteriaFilter, getDefaultTypeValues } from 'components/orders/OrdersList';
import { trackEvent } from 'common/eventTracker';
import InventoryItemsFilters from './InventoryItemsFilter';
import InventoryIdMessages from './filters/InventoryIdMessages.json';

interface EncapsulatedInventoryItemsFiltersProps {
  filter: { [key: string]: any };
  setFilter: (filter: { [key: string]: any }) => void;
}

const EncapsulatedInventoryItemsFilters = ({ filter, setFilter }: EncapsulatedInventoryItemsFiltersProps) => {
  const setFilterWrapper = (obj: { [key: string]: any }) => {
    const filterKeys = Object.keys(obj);
    if (filterKeys.length > 0) {
      trackEvent('INVENTORY_LIST_ADD_FILTER', {
        key: filterKeys[0] || '', // explicitly extract the key (this will make it searchable in Mixpanel, Pendo, etc
        value: obj[filterKeys[0]], // explicitly extract the value (this will make it searchable in Mixpanel, Pendo, etc
      });
    }
    setFilter((prevState: { [key: string]: any }) => {
      return {
        ...prevState,
        ...obj,
      };
    });
  };

  const setTypeValueFilter = (filterName: string, values: ((prevState: string[]) => string[]) | string[]) => {
    const filterValue = formatCriteriaFilter(values);
    return setFilterWrapper({ [filterName]: filterValue });
  };

  const [inventoryIdType, setInventoryIdType] = useState<string | undefined>(undefined);

  const intl = useIntl();

  const inventoryIdTypeList = [
    InventoryIdMessages.department,
    InventoryIdMessages.subDepartment,
    InventoryIdMessages.category,
    InventoryIdMessages.group,
    InventoryIdMessages.family,
    InventoryIdMessages.vendor,
    InventoryIdMessages.manufacturer,
    InventoryIdMessages.promotion,
    InventoryIdMessages.sector,
    InventoryIdMessages.division,
    InventoryIdMessages.class,
    InventoryIdMessages.subClass,
    InventoryIdMessages.code,
  ].map((item) => ({ value: item.value, displayValue: intl.formatMessage(item.message) }));

  const inventoryIdFilterName = 'inventoryIdentifiers';

  const inventoryIdValues = getDefaultTypeValues(inventoryIdFilterName, filter);

  return (
    <InventoryItemsFilters
      //@ts-ignore
      hazardClasses={filter.hazardClasses || []}
      setHazardClasses={(values) => setFilterWrapper({ hazardClasses: isEmpty(values) ? undefined : values })}
      inventoryIdType={inventoryIdType}
      setInventoryIdType={setInventoryIdType}
      inventoryIdTypeList={inventoryIdTypeList}
      inventoryIdValues={inventoryIdValues}
      setInventoryIdValues={(values: ((prevState: string[]) => string[]) | string[]) => {
        setTypeValueFilter(inventoryIdFilterName, values);
      }}
    />
  );
};

export default EncapsulatedInventoryItemsFilters;
