import { ConnectedRouter } from 'connected-react-router';
import get from 'lodash/get';
import some from 'lodash/some';
import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, matchPath } from 'react-router-dom';
import qs from 'qs';
import { useState } from 'react';
import CurrentVersion from 'components/currentVersion/CurrentVersion';
import QuickViewCardConfigContainer from 'components/QuickViewCardConfig';
import { getPrincipalAuthorizations, portCongestionAppEnabled } from 'common/authorizations';
import endpoints, { noHeaderOrFooterEndpoints } from './common/endpoints';
import Analytics from './components/analytics/Analytics';
import InventoryItemsList from './components/Inventory/InventoryItemsList';
import CarrierShipmentList from './components/carrierShipmentList/CarrierShipmentListComponent';
import OrdersList from './components/ordersList/OrdersList';
import NewOrdersList from './components/orders/OrdersList';
import NewInventoryItemsList from './components/inventory-v2/InventoryItemsList';
import OrderDetails from './components/orderDetails/OrderDetails';
import InventoryItemDetails from './components/Inventory/InventoryItemDetails';
import ErrorBoundary from './components/common/errorBoundary/ErrorBoundary';
import FooterContainer from './components/common/footer/FooterContainer';
import HeaderContainer from './components/common/header/HeaderContainer';
import Progress from './components/common/progress/Progress';
import SessionWrapper from './components/common/sessionWrapper/SessionWrapperContainer';
import LandingContainer from './components/landing/LandingContainer';
import NewShipmentDetailsContainer from './components/newShipmentDetails/ShipmentDetailsContainer';
import AddEditNotificationContainer from './components/notifications/addEditNotification/AddEditNotificationContainer';
import NotificationListContainer from './components/notifications/NotificationsContainer';
import Settings from './components/settings/Settings';
import history from './configureHistory';
import PrivateRoute from './PrivateRoute';
import ScrollToTopRoute from './ScrollToTopRoute';
import LocationListContainer from './components/locationManagement/locationList/LocationListContainer';
import FleetListContainer from './components/fleetListComponent/FleetListContainer';
import CarrierFleetListContainer from './components/carrierFleetListComponent/CarrierFleetListContainer';

import ShipmentDetailsContainer from './components/shipmentDetails/ShipmentDetailsContainer';
import ShipmentListContainer from './components/shipmentListComponent/ShipmentListContainer';

import LocationDetailsComponent from './components/LocationDetails/LocationDetailsComponent';
import PageNotFound from './components/common/PageNotFound/PageNotFound';
import { LaunchDarklyFeatureFlagProvider } from './components/onboarding/OnboardingFeatureFlags';
const OnboardingFlowSwitcher = loadable(() => import('./components/onboarding/OnboardingFlowSwitcher'));
const OnboardingOAuth2Authorized = loadable(() => import('./components/onboarding/OnboardingOAuth2Authorized'));
const HiddenFeature = loadable(() => import('./components/HiddenFeature/HiddenFeature'));

const hideHeaderFooter = (theme, pathname) => {
  const noHeaderRouteMatch = matchPath(pathname, { path: [...Object.values(endpoints.public)] });
  return some(noHeaderOrFooterEndpoints, pathname) || noHeaderRouteMatch || theme === undefined;
};

const Routes = (props) => {
  const pathname = get(history, 'location.pathname');

  const authorizations = getPrincipalAuthorizations(props.principal);
  const isQuickviewV2Enabled =
    authorizations.customerProperties.QUICKVIEW_V2_ENABLED || window.portalGlobal.quickviewV2Enabled;

  const queryParams = qs.parse(window.location.search.replace('?', ''));
  const noNav = queryParams['no-nav'] === 'true';
  const [noNavValue] = useState(noNav);
  const hasLocationAccess =
    !authorizations.hasTenantCarrierRole(props.principal) && authorizations.hasLocationReadAccess();
  const canCreateLocation = authorizations.hasLocationCreateAccess();
  const canEditLocation = authorizations.hasLocationUpdateAccess();

  return (
    <ConnectedRouter history={history}>
      <ScrollToTopRoute>
        <div>
          <SessionWrapper />
          {!hideHeaderFooter(props.theme, pathname) && !noNavValue && <HeaderContainer />}
          <ErrorBoundary pathname={pathname}>
            <Switch>
              <Route path={endpoints.CURRENT_VERSION} component={CurrentVersion} />
              <Route exact path={endpoints.PUBLIC_TRACKING_DETAILS} component={ShipmentDetailsContainer} />
              <Route exact path={endpoints.PUBLIC_TRACKING_DETAILS_LEGACY} component={ShipmentDetailsContainer} />
              <Route path={endpoints.PUBLIC_MA_TRACKING_DETAILS} component={NewShipmentDetailsContainer} />
              <Route
                path={[
                  endpoints.public.CARRIER_REGISTRATION_V2,
                  endpoints.public.CARRIER_INVITE_REGISTRATION_V2,
                  endpoints.public.CARRIER_EVERGREEN_REGISTRATION,
                ]}
                render={() => {
                  return (
                    <LaunchDarklyFeatureFlagProvider>
                      <OnboardingFlowSwitcher />
                    </LaunchDarklyFeatureFlagProvider>
                  );
                }}
              />
              <Route path={endpoints.public.CARRIER_REGISTRATION_OAUTH2_PAGE} component={OnboardingOAuth2Authorized} />
              <Route path={endpoints.public.CARRIER_SHIPMENT_LIST} component={CarrierShipmentList} />
              {/* Place Routes that don't require branding to be loaded above here*/}
              {
                props.theme === undefined && (
                  <Route
                    /*eslint-disable-next-line*/
                    render={() => <Progress isLoading={true} />}
                  />
                ) /*Stops switch until theming is loaded */
              }
              <PrivateRoute
                path={endpoints.DASHBOARD}
                component={LandingContainer}
                isLoggedIn={props.isLoggedIn}
                currentPath={pathname}
              />
              {isQuickviewV2Enabled && (
                <PrivateRoute
                  path={endpoints.CONFIGURE_QUICKVIEW}
                  component={QuickViewCardConfigContainer}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}
              <PrivateRoute
                path={endpoints.SHIPMENT_LIST}
                component={ShipmentListContainer}
                isLoggedIn={props.isLoggedIn}
                currentPath={pathname}
              />
              {hasLocationAccess ? (
                <PrivateRoute
                  path={endpoints.LOCATION_LIST}
                  component={LocationListContainer}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              ) : (
                <PrivateRoute
                  path={endpoints.LOCATION_LIST}
                  component={HiddenFeature}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}
              {hasLocationAccess ? (
                <PrivateRoute
                  path={endpoints.LOCATION_DETAILS}
                  render={(props) => (
                    <LocationDetailsComponent
                      {...props}
                      canCreateLocation={canCreateLocation}
                      canEditLocation={canEditLocation}
                      noNav={noNavValue}
                    />
                  )}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              ) : (
                <PrivateRoute
                  path={endpoints.LOCATION_DETAILS}
                  component={HiddenFeature}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}

              <PrivateRoute
                path={endpoints.TRACKING_DETAILS}
                component={ShipmentDetailsContainer}
                isLoggedIn={props.isLoggedIn}
                currentPath={pathname}
              />
              <PrivateRoute
                path={endpoints.NOTIFICATIONS}
                component={NotificationListContainer}
                isLoggedIn={props.isLoggedIn}
                currentPath={pathname}
              />
              <PrivateRoute
                path={endpoints.NOTIFICATION_DETAILS}
                component={AddEditNotificationContainer}
                isLoggedIn={props.isLoggedIn}
                currentPath={pathname}
              />
              <PrivateRoute
                path={endpoints.MA_TRACKING_DETAILS}
                component={NewShipmentDetailsContainer}
                isLoggedIn={props.isLoggedIn}
              />

              {authorizations.hasOceanAnalytics() && (
                <PrivateRoute
                  path={endpoints.ANALYTICS_OCEAN_OVERVIEW}
                  component={Analytics}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}

              {authorizations.hasOceanAnalytics() && portCongestionAppEnabled() && (
                <PrivateRoute
                  path={endpoints.ANALYTICS_OCEAN_PORT_CONGESTION}
                  component={Analytics}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}

              {authorizations.hasAnalytics(props.principal) && (
                <PrivateRoute
                  path={endpoints.ANALYTICS_SCORECARD}
                  component={Analytics}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}
              {authorizations.hasAnalytics(props.principal) && (
                <PrivateRoute
                  path={endpoints.ANALYTICS_CARRIER_OVERVIEW}
                  component={Analytics}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}
              {authorizations.hasOceanInsightsCTT() && (
                <PrivateRoute
                  path={endpoints.ANALYTICS_OCEAN_INSIGHTS}
                  component={Analytics}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}
              {authorizations.hasAnalytics(props.principal) && authorizations.hasNetworkLink() && (
                <PrivateRoute
                  path={endpoints.ANALYTICS_CARRIER_ONBOARDING_REPORT}
                  component={Analytics}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}
              {authorizations.hasAnalytics(props.principal) && (
                <PrivateRoute
                  path={endpoints.ANALYTICS_CARRIER_PROCUREMENT}
                  component={Analytics}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}
              {authorizations.hasAnalytics(props.principal) && (
                <PrivateRoute
                  path={endpoints.ANALYTICS_LOCATION_ANALYTICS}
                  component={Analytics}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}

              {authorizations.hasAdvancedTrackingLinking() && (
                <PrivateRoute
                  exact
                  path={endpoints.ORDERSLIST}
                  component={authorizations.hasEnableOrderInventoryV2() ? NewOrdersList : OrdersList}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}

              {authorizations.hasAdvancedTrackingLinking() && (
                <PrivateRoute
                  exact
                  path={endpoints.ORDERS_DETAILS}
                  component={OrderDetails}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}

              {authorizations.hasAdvancedTrackingLinking() && (
                <PrivateRoute
                  exact
                  path={endpoints.INVENTORYITEMSLIST}
                  component={authorizations.hasEnableOrderInventoryV2() ? NewInventoryItemsList : InventoryItemsList}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}
              {authorizations.hasAdvancedTrackingLinking() && (
                <PrivateRoute
                  exact
                  path={endpoints.INVENTORY_ITEM_DETAILS}
                  component={InventoryItemDetails}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}
              {authorizations.hasAnalytics(props.principal) && (
                <PrivateRoute
                  path={endpoints.ANALYTICS_LOCATION_DETAILS_ANALYTICS}
                  component={Analytics}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}
              {authorizations.hasEditCustomBrandingAuthorization() && (
                <PrivateRoute path={endpoints.SETTINGS} component={Settings} isLoggedIn={props.isLoggedIn} />
              )}
              {authorizations.hasCarrierFleetViewAuthorization() && (
                <PrivateRoute
                  path={endpoints.FLEET_LIST}
                  component={FleetListContainer}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}
              {authorizations.hasCarrierFleetViewAuthorization() && (
                <PrivateRoute
                  path={endpoints.CARRIER_SEARCH_LIST}
                  component={CarrierFleetListContainer}
                  isLoggedIn={props.isLoggedIn}
                  currentPath={pathname}
                />
              )}
              <Route path={endpoints.MISSING_SHIPMENT} component={PageNotFound} />
              <Redirect exact from="/" to={endpoints.DASHBOARD} />
              <Redirect exact from={endpoints.LANDING} to={endpoints.DASHBOARD} />
              <Route component={PageNotFound} />
            </Switch>
          </ErrorBoundary>
          {!hideHeaderFooter(props.theme, pathname) && !noNavValue && <FooterContainer />}
        </div>
      </ScrollToTopRoute>
    </ConnectedRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.authReducer.isLoggedIn,
    isLoggingIn: state.loginReducer.isLoggingIn,
    location: state.router.location,
    redirectingLocation: get(state, 'router.location.state.successRedirectUrlOverride'),
    principal: state.authReducer.principal,
    theme: state.brandThemingReducer.savedTheme,
  };
};

export default connect(mapStateToProps, null)(Routes);
