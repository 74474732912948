import get from 'lodash/get';
import { availableTrackingMethods, carrierTypeIdentifiers } from '../reducers';

const getProvider = (apiResponse) => get(apiResponse, 'provider', {});

const getProviderId = (apiResponse) => getProvider(apiResponse).id;

const getProviderName = (apiResponse) => getProvider(apiResponse).name;

const getTrackingTypeSortOrdinal = (type) => {
  if (type.id === availableTrackingMethods.api.id) {
    return 1;
  } else if (type.id === availableTrackingMethods.truckNumber.id) {
    return 2;
  } else {
    // availableTrackingMethods.truckNumber.id
    return 3;
  }
};

const mapTrackingMethod = (apiType) => {
  if (!apiType) {
    return null;
  }
  if (apiType === 'MOBILE_PHONE') {
    return availableTrackingMethods.phone;
  } else if (apiType === 'TRUCK_NUMBER') {
    return availableTrackingMethods.truckNumber;
  } else if (apiType === 'LICENSE_PLATE') {
    return availableTrackingMethods.licensePlate;
  } else if (apiType === 'API') {
    return availableTrackingMethods.api;
  } else {
    console.warn(`Invalid apiType=${apiType}`); // eslint-disable-line no-console
    return null;
  }
};

const mapCarrierTypeIdentifier = (typeIdentifier) => {
  if (!typeIdentifier) {
    return null;
  }

  if (typeIdentifier === 'SCAC') {
    return carrierTypeIdentifiers.scac.id;
  } else if (typeIdentifier === 'MC_NUMBER') {
    return carrierTypeIdentifiers.mc.id;
  } else if (typeIdentifier === 'DOT_NUMBER') {
    return carrierTypeIdentifiers.dot.id;
  } else if (typeIdentifier === 'P44_EU') {
    return carrierTypeIdentifiers.eu.id;
  } else {
    console.warn(`Invalid carrierTypeIdentifier=${typeIdentifier}`); // eslint-disable-line no-console
    return null;
  }
};

const getTrackingMethods = (apiResponse) => {
  const sortedMethods = get(apiResponse, 'trackingMethods', [])
    .map((method) => mapTrackingMethod(method.type))
    .filter((methodType) => !!methodType)
    .sort((a, b) => getTrackingTypeSortOrdinal(a) - getTrackingTypeSortOrdinal(b));

  return sortedMethods;
};

const getTypeIdentifiers = (apiResponse) =>
  get(getProvider(apiResponse), 'capacityProviderIdentifiers', [])
    .map((idObject) => idObject.identifier)
    .filter((subIdObj) => !!subIdObj)
    .map((subIdObj) => ({
      type: mapCarrierTypeIdentifier(subIdObj.type),
      value: subIdObj.value,
    }));

class CarrierDetailsMapper {
  static mapApiToState(apiResponse) {
    return {
      id: getProviderId(apiResponse),
      name: getProviderName(apiResponse),
      typeIdentifiers: getTypeIdentifiers(apiResponse),
      trackingMethods: getTrackingMethods(apiResponse),
      canRefrigerate: false, //TODO: Will get this data somehow from backend. This to to track if carrier has refrigeration capacity or not.
    };
  }
}

export default CarrierDetailsMapper;
