export interface CountryCodeItemType {
  prefix: string;
  countryCode: string;
}

export const prefixMapVAT: Array<CountryCodeItemType> = [
  {
    prefix: 'CY',
    countryCode: 'CY',
  },
  {
    prefix: 'RS',
    countryCode: 'RS',
  },
  {
    prefix: 'AL',
    countryCode: 'AL',
  },
  {
    prefix: 'BY',
    countryCode: 'BY',
  },
  {
    prefix: 'BA',
    countryCode: 'BA',
  },
  {
    prefix: 'GB',
    countryCode: 'GB',
  },
  {
    prefix: 'GE',
    countryCode: 'GE',
  },
  {
    prefix: 'IS',
    countryCode: 'IS',
  },
  {
    prefix: 'KZ',
    countryCode: 'KZ',
  },
  {
    prefix: 'MK',
    countryCode: 'MK',
  },
  {
    prefix: 'MD',
    countryCode: 'MD',
  },
  {
    prefix: 'ME',
    countryCode: 'ME',
  },
  {
    prefix: 'NO',
    countryCode: 'NO',
  },
  {
    prefix: 'RU',
    countryCode: 'RU',
  },
  {
    prefix: 'CH',
    countryCode: 'CH',
  },
  {
    prefix: 'UA',
    countryCode: 'UA',
  },
  {
    prefix: 'BG',
    countryCode: 'BG',
  },
  {
    prefix: 'BE',
    countryCode: 'BE',
  },
  {
    prefix: 'AT',
    countryCode: 'AT',
  },
  {
    prefix: 'HR',
    countryCode: 'HR',
  },
  {
    prefix: 'CZ',
    countryCode: 'CZ',
  },
  {
    prefix: 'DK',
    countryCode: 'DK',
  },
  {
    prefix: 'EE',
    countryCode: 'EE',
  },
  {
    prefix: 'FI',
    countryCode: 'FI',
  },
  {
    prefix: 'FR',
    countryCode: 'FR',
  },
  {
    prefix: 'DE',
    countryCode: 'DE',
  },
  {
    prefix: 'EL',
    countryCode: 'GR',
  },
  {
    prefix: 'HU',
    countryCode: 'HU',
  },
  {
    prefix: 'IE',
    countryCode: 'IE',
  },
  {
    prefix: 'IT',
    countryCode: 'IT',
  },
  {
    prefix: 'LV',
    countryCode: 'LV',
  },
  {
    prefix: 'LT',
    countryCode: 'LT',
  },
  {
    prefix: 'LU',
    countryCode: 'LU',
  },
  {
    prefix: 'MT',
    countryCode: 'MT',
  },
  {
    prefix: 'NL',
    countryCode: 'NL',
  },
  {
    prefix: 'PL',
    countryCode: 'PL',
  },
  {
    prefix: 'PT',
    countryCode: 'PT',
  },
  {
    prefix: 'RO',
    countryCode: 'RO',
  },
  {
    prefix: 'SK',
    countryCode: 'SK',
  },
  {
    prefix: 'SI',
    countryCode: 'SI',
  },
  {
    prefix: 'ES',
    countryCode: 'ES',
  },
  {
    prefix: 'SE',
    countryCode: 'SE',
  },
];
