import '@project44-lib/here-maps-api-js';

import { useState, useRef, useEffect } from 'react';
import { useFetchConfig } from '../../../../common/hereMapsConfig/hooks';
import { buildMapInstance, buildPlatform, MapBehavior } from '../../../../common/mapRoute/Map/HereMapUtils';
import { buildMapMarker, displayBubbleInformation } from './Markers/MarkerUtil';
import { Vessel } from '../../models/Vessel';
import './PortCongestionMap.scss';

interface PortCongestionMapProps {
  markers?: Vessel[];
}
const INITIAL_COORDINATES = {
  lat: 33.6650824,
  lng: -118.0750867,
};

const PortCongestionMap = ({ markers }: PortCongestionMapProps) => {
  const mapConfigResponse = useFetchConfig();
  const [mapInstance, setMapInstance] = useState<any>(undefined);
  const [mapPlatform, setMapPlatform] = useState<any>(undefined);
  const mapRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    initializeMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mapInstance) {
      initializeMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstance, mapConfigResponse, mapPlatform]);

  const initializePlatform = () => {
    if (!mapPlatform && mapConfigResponse) {
      setMapPlatform(buildPlatform(mapConfigResponse));
    }
  };
  const initializeMap = () => {
    initializePlatform();
    if (mapPlatform && mapRef.current) {
      const mapInstances = buildMapInstance(mapPlatform, mapRef.current);
      const instance = mapInstances.mapInstance;
      mapInstances.behavior.disable(MapBehavior.WHEEL_ZOOM);
      if (markers) {
        const group = mapInstances.group;
        instance.addObject(group);
        group.addObjects(
          markers.map((vessel) =>
            buildMapMarker({ latitude: parseFloat(vessel.lat), longitude: parseFloat(vessel.lon) }, vessel.meta)
          )
        );
        group.addEventListener(
          'tap',
          function (evt: Event) {
            displayBubbleInformation(mapInstances.ui, evt);
          },
          false
        );
      }
      instance.setCenter(INITIAL_COORDINATES);
      instance.setZoom(11);
      setMapInstance(instance);
    }
  };
  return <div className="port-congestion-map" ref={mapRef} />;
};

export default PortCongestionMap;
