import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import get from 'lodash/get';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { push } from 'react-router-redux';
import { useDispatch } from 'react-redux';
// @ts-ignore
import PostalAddress from 'i18n-postal-address';
import { HOURS_MINUTES_SECONDS_FORMAT, MONTH_DAY_YEAR_FORMAT } from 'i18n/configurei18n';

import {
  DerivedOrderHealth,
  intlOrderAdditionalIdentifierList,
  intlOrderTaDescriptiongList,
  intlOrderTagList,
  InventoryOrder,
  InventoryOrderStatusCode,
  InventoryOrderStatusCodeIntlKeys,
  InventoryOrderType,
  InventoryOrderTypeIntlKeys,
} from 'components/Inventory/models';

import { mapShipment } from 'components/shipmentListComponent/mappers/mapShipment';
import ConfigurableTable from 'common/ConfigurableTable/ConfigurableTable';
import { getOrderDeliveryStatusDisplay } from 'components/orders/utils/OrderDeliveryStatusUtil';
import { microWebappsUrls } from 'common/AppConstants';
import { trackEvent } from 'common/eventTracker';
import { OrderListFeatureFlags } from './orderFeatureFlags';

interface OrdersTableProps {
  dataResults: InventoryOrder[];
  isLoading: boolean;
  onPageChangeFn: Dispatch<SetStateAction<number>>;
  totalResults: number | undefined;
  isDraggable?: boolean;
  featureFlags: OrderListFeatureFlags;
}

const ORDERS_ENTITY_TYPE = 'ORDERS';

const OrdersTable: React.FC<OrdersTableProps> = ({
  dataResults,
  isLoading,
  onPageChangeFn,
  totalResults,
  featureFlags,
}) => {
  const intl = useIntl();

  const tagDescriptionColumns = intlOrderTaDescriptiongList
    .map((item) => ({ ...item, dataIndex: 'orderTags' }))
    .map((item) => {
      return {
        dataIndex: item.dataIndex,
        render: function render(orderTags: any[]) {
          if (!orderTags) return null;

          return orderTags
            .filter((orderTag) => orderTag.type === item.value)
            .map((orderTag) => orderTag.description)
            .join(', ');
        },
        title: intl.formatMessage(item.message),
        width: 130,
        visible: false,
      };
    });

  const mergedTypeList = [
    ...intlOrderAdditionalIdentifierList.map((item) => ({ ...item, dataIndex: 'additionalOrderIdentifiers' })),
    ...intlOrderTagList.map((item) => ({ ...item, dataIndex: 'orderTags' })),
  ];

  const typeColumns = mergedTypeList.map((item) => {
    return {
      dataIndex: item.dataIndex,
      render: function render(orderTags: any[]) {
        if (!orderTags) return null;

        return orderTags
          .filter((orderTag) => orderTag.type === item.value)
          .map((orderTag) => orderTag.value)
          .join(', ');
      },
      title: intl.formatMessage(item.message),
      width: 130,
      visible: false,
    };
  });

  const dateColumns = [
    {
      dataIndex: 'orderSubmissionDateTime',
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.orderSubmissionDate',
        defaultMessage: 'Order Submission Date',
      }),
    },
    {
      dataIndex: ['supplierReadyDateTimeWindow', 'startDateTime'],
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.supplierReadyDate',
        defaultMessage: 'Supplier Ready Date',
      }),
    },
    {
      dataIndex: ['originalDeliveryDateTimeWindow', 'endDateTime'],
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.originalDueDate',
        defaultMessage: 'Original Due Date',
      }),
    },
    {
      dataIndex: ['derivedOrderHealth', 'estimatedTimeOfArrival'],
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.estimatedTimeOfArrival',
        defaultMessage: 'Order Delivery ETA',
      }),
    },
  ].map((item: any) => {
    return {
      dataIndex: item.dataIndex,
      render: function renderFunc(text: string) {
        if (text === undefined || text === null) return '';

        return moment(text).format(`${MONTH_DAY_YEAR_FORMAT} ${HOURS_MINUTES_SECONDS_FORMAT}`);
      },
      title: item.title,
      width: 130,
      visible: false,
    };
  });

  const locationColumns = [
    {
      attributeName: 'billToLocation',
      nameTitle: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.billToName',
        defaultMessage: 'Bill To Location Name',
      }),
      idTitle: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.billToId',
        defaultMessage: 'Bill To Location Id',
      }),
      addressTitle: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.billToAddress',
        defaultMessage: 'Bill To Location Address',
      }),
    },
    {
      attributeName: 'destinationLocation',
      nameTitle: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.destinationName',
        defaultMessage: 'Destination Name',
      }),
      idTitle: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.destinationId',
        defaultMessage: 'Destination Id',
      }),
      addressTitle: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.destinationAddress',
        defaultMessage: 'Destination Address',
      }),
    },
    {
      attributeName: 'originLocation',
      nameTitle: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.originName',
        defaultMessage: 'Origin Name',
      }),
      idTitle: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.originId',
        defaultMessage: 'Origin Id',
      }),
      addressTitle: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.originAddress',
        defaultMessage: 'Origin Address',
      }),
    },
    {
      attributeName: 'vendorLocation',
      nameTitle: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.vendorName',
        defaultMessage: 'Vendor Name',
      }),
      idTitle: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.vendorId',
        defaultMessage: 'Vendor Id',
      }),
      addressTitle: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.vendorAddress',
        defaultMessage: 'Vendor Address',
      }),
    },
  ]
    .map((item: any) => {
      return [
        {
          dataIndex: [item.attributeName, 'name'],
          title: item.nameTitle,
          width: 130,
          visible: false,
        },
        {
          dataIndex: [item.attributeName, 'suppliedId'],
          title: item.idTitle,
          width: 130,
          visible: false,
        },
        {
          dataIndex: [item.attributeName, 'address'],
          title: item.addressTitle,
          render: function renderCol(value: any) {
            const shouldDisplayAddress = value && value.addressLines;

            if (!shouldDisplayAddress) return null;

            const currentAddress = new PostalAddress();

            currentAddress
              .setAddress1(value.addressLines[0])
              .setAddress2(value.addressLines[1])
              .setState(value.state)
              .setCity(value.city)
              .setCountry(value.country)
              .setPostalCode(value.postalCode);

            return currentAddress.toString();
          },
          width: 130,
          visible: false,
        },
      ];
    })
    .flat();

  const columns = [
    {
      dataIndex: 'orderIdentifier',
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.orderId', defaultMessage: 'Order ID' }),
      width: 130,
      visible: true,
      locked: true,
    },
    {
      dataIndex: 'statusCode',
      render: function render(inventoryOrderStatusCode: InventoryOrderStatusCode) {
        return (
          <FormattedMessage
            id={get(InventoryOrderStatusCodeIntlKeys, `[${inventoryOrderStatusCode}].id`, ' ')}
            defaultMessage={get(InventoryOrderStatusCodeIntlKeys, `[${inventoryOrderStatusCode}].defaultMessage`, ' ')}
          />
        );
      },
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.status', defaultMessage: 'Status' }),
      width: 130,
      visible: true,
    },
    {
      dataIndex: 'orderType',
      className: 'data-locator-orderType',
      render: function render(inventoryOrderType: InventoryOrderType) {
        return (
          <FormattedMessage
            id={get(InventoryOrderTypeIntlKeys, `[${inventoryOrderType}].id`, ' ')}
            defaultMessage={get(InventoryOrderTypeIntlKeys, `[${inventoryOrderType}].defaultMessage`, ' ')}
          />
        );
      },
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.orderType', defaultMessage: 'Order Type' }),
      width: 130,
      visible: true,
    },
    {
      dataIndex: 'shipmentIds',
      render: function render(shipmentIds: string[], record: InventoryOrder) {
        if (shipmentIds === null || shipmentIds.length === 0) {
          return (
            <FormattedMessage id={'inventory.ordersList.shipmentsStatus.noShipments'} defaultMessage="No Shipments" />
          );
        }

        if (shipmentIds.length > 1) {
          return (
            <FormattedMessage
              id={'inventory.ordersList.shipmentsStatus.manyShipments'}
              defaultMessage={'Many Shipments'}
            />
          );
        }

        if (shipmentIds.length === 1 && record.shipments && record.shipments.length > 0) {
          const shipment = mapShipment(record.shipments[0], intl);

          if (!shipment?.statusText) return null;

          return <FormattedMessage id={shipment.statusText} defaultMessage={shipment.statusText} />;
        }

        return <FormattedMessage id={'inventory.ordersList.shipmentsStatus.noStatus'} defaultMessage={'No Status'} />;
      },
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.shipmentsStatus',
        defaultMessage: 'Transportation Status',
      }),
      width: 130,
      visible: false,
    },
    {
      dataIndex: 'derivedOrderHealth',
      render: function render(derivedOrderHealth: DerivedOrderHealth, record: InventoryOrder) {
        return getOrderDeliveryStatusDisplay(derivedOrderHealth);
      },
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.orderDeliveryStatus',
        defaultMessage: 'Order Delivery Status',
      }),
      width: 130,
      visible: false,
    },
    ...dateColumns,
    ...locationColumns,
    ...typeColumns,
    ...tagDescriptionColumns,
  ];

  const tableType = 'orderTable';

  const dispatch = useDispatch();

  const onRowClick = (record: InventoryOrder) => {
    return {
      onClick: () => {
        trackEvent('ORDERS_LIST_ROW_CLICK', {
          id: record.id || '',
          orderType: record.orderType || '',
          orderIdentifier: record.orderIdentifier || '',
          statusCode: record.statusCode || '',
        });
        const newOrderPageUrl = `${microWebappsUrls.baseUiUrl}/order/${record.id}`;
        if (featureFlags.openBothOrderPages) {
          // intentionally open in new tab for convenience to avoid removing current table and work around middle click
          // not working
          window.open(newOrderPageUrl);
          const currentPageUrl = `${window.location.origin}/portal/v2/inventory/orders/${record.id}`;
          window.open(currentPageUrl);
        } else if (featureFlags.useNewOrderPage) {
          window.location.assign(newOrderPageUrl);
        } else {
          dispatch(push(`/inventory/orders/${record.id}`));
        }
      },
    };
  };

  return (
    <ConfigurableTable
      entityType={ORDERS_ENTITY_TYPE}
      tableType={tableType}
      dataResults={dataResults}
      isLoading={isLoading}
      onPageChangeFn={onPageChangeFn}
      totalResults={totalResults}
      columns={columns}
      onRowClick={onRowClick}
    />
  );
};

export default OrdersTable;
