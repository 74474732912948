import { defineMessages } from 'react-intl';
import * as Validators from '../../common/FormValidations';

const messagefield = defineMessages({
  requiredField: {
    id: 'addShipment.formValidators.requiredField',
    defaultMessage: 'Required field',
  },
});

export const validateLaneDetailCarrierInfo = (values) => {
  const errors = {};

  // At least one of BOL or order # must be defined
  if (!values.shipmentBOL && !values.shipmentOrder) {
    const message = messagefield.requiredField;
    errors.shipmentBOL = message;
    errors.shipmentOrder = message;
  }

  if (!values.licensePlateNumber) {
    const message = messagefield.requiredField;
    errors.licensePlateNumber = message;
  }

  return errors;
};

export const validateShipmentDetailCarrierInfo = (values) => {
  const errors = {};

  // At least one of BOL or order # must be defined
  if (!values.shipmentBOL && !values.shipmentOrder) {
    const message = messagefield.requiredField;
    errors.shipmentBOL = message;
    errors.shipmentOrder = message;
  }

  if (values.carrierType !== 'EU' && !values.licensePlateNumber) {
    const message = messagefield.requiredField;
    errors.licensePlateNumber = message;
  }

  return errors;
};

const addWindowIfValid = (windowContext, rangeContexts, errors) => {
  if (
    windowContext &&
    windowContext.startDate &&
    windowContext.startTime &&
    windowContext.endDate &&
    windowContext.endTime
  ) {
    rangeContexts.push({
      startDate: windowContext.startDate,
      startTime: windowContext.startTime,
      endDate: windowContext.endDate,
      endTime: windowContext.endTime,
      errors: errors,
    });
  }
};

export const validateDateRangeWindows = (values) => {
  const errors = {};
  const rangeContexts = [];

  errors.pickupForm = {};
  addWindowIfValid(values.pickupForm, rangeContexts, errors.pickupForm);

  if (values.addStops) {
    errors.addStops = [];
    for (let stop of values.addStops) {
      const stopErrors = {};
      errors.addStops.push(stopErrors);
      addWindowIfValid(stop, rangeContexts, stopErrors);
    }
  }

  errors.dropOffForm = {};
  addWindowIfValid(values.dropOffForm, rangeContexts, errors.dropOffForm);

  Validators.validateAppointmentWindows(rangeContexts);

  return errors;
};
