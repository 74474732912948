import { useState } from 'react';
import * as React from 'react';
import { Steps, Spin } from 'antd';
import { Col, Row } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import map from 'lodash/map';
import difference from 'lodash/difference';
import filter from 'lodash/filter';
import eq from 'lodash/eq';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import head from 'lodash/head';
import flatten from 'lodash/flatten';
import axios from 'util/paxios';

import { Modal, Tags, Button, TagsArea, message } from 'ui-components';
import {
  primaryGreyEighty,
  errorRedTwo,
  primaryBlue,
  primaryGrey500,
  lightGrey,
  primaryGrey,
  errorRed,
} from 'styles/colors';

import { API_PATH } from 'common/AppConstants';
import endpoints from 'common/endpoints';
import { trackEvent } from 'common/eventTracker';
import { LoadingStatus, IMultiValueSearchModal, ReadableSearchOptionsIntlKeys, SearchServiceTypeEnum } from './models';

const StyledMultiFieldButton = styled('button')`
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  padding: 0;
  color: ${primaryBlue};
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:disabled {
    color: ${primaryGrey500};
    cursor: default;
  }
`;

const StyledModal = styled(Modal)`
  max-width: 1040px !important;

  @media (max-width: 1060px) {
    max-width: calc(100vw - 20px);
    margin: 10px auto;
  }
`;

const StyledNonMatch = styled('div')`
  background-color: ${lightGrey};
  align-items: flex-start;
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  height: 294px;
  padding: 11px;
  overflow-y: auto;

  @media (max-width: 767px) {
    height: 243px;
  }
`;

const StyledH1 = styled('h1')`
  font-size: 18px;
  margin-bottom: 24px;
`;

const StyledSpan = styled('span')`
  color: ${primaryGrey};
  font-weight: 500;
  width: 50%;
`;

const StyledSpinner = styled('div')`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: 1;

  .ant-spin-dot {
    margin-bottom: 10px;
  }

  .ant-spin-text {
    font-size: 18px;
    font-weight: 500;
  }
`;

const StyledSteps = styled(Steps)`
  &.ant-steps-horizontal:not(.ant-steps-label-vertical) {
    .ant-steps-item {
      @media (max-width: 480px) {
        padding-top: 0 !important;
        padding-left: 0 !important;
      }
    }
  }
`;

const StyledError = styled('div')`
  color: ${errorRed};
`;

const StyledTagsContainer = styled('div')`
  margin-bottom: 24px;
`;
const SpacedCheckbox = styled('input')`
  margin-inline: 1em;
`;
const StyledAdvanceButtons = styled('div')`
  display: flex;
  justify-content: flex-end;
`;
const StyledHalfButton = styled('div')`
  width: 50%;
`;
const MultiValueSearchModal = ({
  isOpen,
  modes,
  tags,
  text,
  multiSearchTerm,
  setMultiSearchTerm,
  handleCancel,
  handleRemoveTag,
  handleKeyPress,
  handlePaste,
  handleTextAreaChange,
  handleClearAll,
  handleSubmitSearch,
  hasAdvancedLinking,
  dataLocator,
  unmatchedTags,
  currentStep,
  setCurrentStep,
  termAfterFind,
  setTermAfterFind,
  errorMessage,
  setErrorMessage,
  setSearchTerm,
  serviceType,
  setServiceType,
}: IMultiValueSearchModal) => {
  const [currentLoadingStatus, setCurrentLoadingStatus] = useState<LoadingStatus>();

  const { Step } = Steps;
  const intl = useIntl();

  const removeErrorCheck = () => {
    const validTagsSize = () => {
      switch (serviceType) {
        case SearchServiceTypeEnum.SHIPMENT_SEARCH:
          return tags.size > 0 && tags.size <= 200;
        case SearchServiceTypeEnum.ORDERS_SEARCH:
          return tags.size > 0 && tags.size <= 2000;
        default:
          return false;
      }
    };
    if (validTagsSize() && !isEmpty(errorMessage)) setErrorMessage('');
  };

  const getErrorMessage = (error: any): string => {
    const errors = get(error, ['response', 'data', 'errors'], ['']);
    const errorData = head(errors);
    return get(errorData, 'message', '');
  };

  const steps = [
    {
      title: intl.formatMessage({
        id: 'searchBar.multiValue.enterSearchValues',
        defaultMessage: 'Enter search values',
      }),
    },
    {
      title: intl.formatMessage({ id: 'searchBar.multiValue.previewMatches', defaultMessage: 'Preview matches' }),
    },
  ];

  const showLinkCopiedToast = () => {
    message.success({
      content: 'Copied to clipboard',
      duration: 10,
    });
  };

  const onRemoveTag = (tag: any) => {
    handleRemoveTag(tag);
    if (!tags.size) setTermAfterFind('');
    removeErrorCheck();
  };

  const handleRemoveUnmatchedTag = (tag: any) => unmatchedTags.delete(tag);

  const getLastValueOfSet = (set: any) => Array.from(set).pop();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    removeErrorCheck();
    if (['Backspace'].includes(e.key) && isEmpty(text)) {
      const lastValue = getLastValueOfSet(tags);
      tags.delete(lastValue);
      setMultiSearchTerm(Array.from(tags).join(' '));
    }
  };

  const onKeyPress = (e: any) => {
    removeErrorCheck();
    handleKeyPress(e);
  };

  const onKeyUp = () => removeErrorCheck();

  const onPaste = (e: any) => {
    removeErrorCheck();
    handlePaste(e);
  };
  const setAPIUrl = () => {
    let url = '';
    switch (serviceType) {
      case SearchServiceTypeEnum.SHIPMENT_SEARCH:
        url = `${API_PATH}${endpoints.FIND_UNMATCHED_SEARCH_TERM}`;
        break;
      case SearchServiceTypeEnum.ORDERS_SEARCH:
        url = `${API_PATH}${endpoints.ORDERS_FIND_UNMATCHED_SEARCH_TERM}`;
        break;
      default:
        break;
    }
    return url;
  };
  const createRequestBody = () => {
    const selectedModes = [...modes];
    const filteredModes = selectedModes.filter((mode: any) => mode !== null && mode !== undefined);
    const isAllMode = isEqual(head(filteredModes), 'ALL');
    const _modes = isAllMode ? [] : filteredModes;
    let body;
    switch (serviceType) {
      case SearchServiceTypeEnum.SHIPMENT_SEARCH:
        body = {
          modes: flatten(_modes),
          searchTermList: Array.from(tags),
        };
        break;
      case SearchServiceTypeEnum.ORDERS_SEARCH:
        body = [...tags];
        break;
      default:
        break;
    }
    return body;
  };
  const handleCheckMatches = async () => {
    let response;

    try {
      setCurrentLoadingStatus(LoadingStatus.LOADING);
      handleSearchTracker();
      response = await axios.post(setAPIUrl(), createRequestBody(), { withCredentials: true });
      const noMatches = filter(response?.data, (item) => eq(item.count, 0));
      const noMatchesStrings = map(noMatches, (item) => item.searchTerm);
      noMatchesStrings.forEach((val) => tags.delete(val));
      noMatchesStrings.forEach((val) => unmatchedTags.add(val));
      setCurrentStep(1);
      setMultiSearchTerm(Array.from(tags).join(' '));
      setTermAfterFind(Array.from(tags).join(' '));
      setCurrentLoadingStatus(LoadingStatus.LOADED);
      setErrorMessage('');
      handleViewResultsTracker();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      setCurrentLoadingStatus(LoadingStatus.ERROR);
    }
  };
  const handleSearchTracker = () => {
    switch (serviceType) {
      case SearchServiceTypeEnum.SHIPMENT_SEARCH:
        trackEvent('MULTI_VALUE_SHIPMENTS_SEARCH', { label: tags.size.toString() });
        break;
      case SearchServiceTypeEnum.ORDERS_SEARCH:
        trackEvent('MULTI_VALUE_ORDER_SEARCH', { label: tags.size.toString() });
        break;
      default:
        break;
    }
  };
  const handleViewResultsTracker = () => {
    switch (serviceType) {
      case SearchServiceTypeEnum.SHIPMENT_SEARCH:
        trackEvent('MULTI_VALUE_SHIPMENTS_RESULTS_MISSES', { label: unmatchedTags.size.toString() });
        break;
      case SearchServiceTypeEnum.ORDERS_SEARCH:
        trackEvent('MULTI_VALUE_ORDER_RESULTS_MISSES', { label: unmatchedTags.size.toString() });
        break;
      default:
        break;
    }
  };

  const onClearAll = async () => {
    await setSearchTerm('');
    handleClearAll();
  };

  const previewStep = currentStep !== 0;
  const isLoading = currentLoadingStatus === LoadingStatus.LOADING;
  const hasError = !isEmpty(errorMessage);
  const searchDifference = difference(multiSearchTerm.split(' '), termAfterFind.split(' '));
  const showViewResultsBtn = previewStep && isEmpty(searchDifference);
  const Stepper = () => (
    <Row className="mb-2 qmb-sm-4 pb-sm-3">
      <Col md={14}>
        <StyledSteps size="small" current={currentStep} className="mb-0 mb-md-3">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </StyledSteps>
      </Col>
    </Row>
  );
  const SearchTypeSelector = () => (
    <form>
      {Object.entries(SearchServiceTypeEnum).map(([enumKey, enumValue]) => {
        if (!hasAdvancedLinking && enumValue === SearchServiceTypeEnum.ORDERS_SEARCH) {
          return null;
        }
        if (enumValue !== SearchServiceTypeEnum.INVENTORY_SEARCH) {
          return (
            <Row key={enumValue}>
              <label htmlFor={enumValue}>
                <SpacedCheckbox
                  name={enumValue}
                  id={enumValue}
                  type="radio"
                  checked={serviceType === enumValue}
                  onChange={() => {
                    setServiceType(enumValue);
                  }}
                />
                {/*@ts-ignore*/}
                <FormattedMessage {...ReadableSearchOptionsIntlKeys[enumValue]} />
              </label>
            </Row>
          );
        } else {
          return null;
        }
      })}
    </form>
  );
  return (
    <StyledModal visible={isOpen} onCancel={handleCancel} footer={null} width="100%">
      <StyledH1 data-locator={dataLocator}>
        <FormattedMessage defaultMessage="Multi-value Search" id="searchBar.multiValue.modalTitle" />
      </StyledH1>
      <div className="position-relative">
        {isLoading && (
          <StyledSpinner>
            <Spin size="large" spinning={isLoading} tip="Searching for your matches" />
          </StyledSpinner>
        )}
        <Stepper />
        <Row>
          {!previewStep && (
            <Col md={4}>
              <StyledSpan className="mb-0">
                <FormattedMessage defaultMessage="Refine by" id="searchBar.multiValue.refineBy" />
              </StyledSpan>
              <SearchTypeSelector />
            </Col>
          )}
          <Col md={previewStep ? 14 : 20}>
            {!previewStep && (
              <Row>
                <Col>
                  <StyledSpan className="mb-0">
                    {serviceType === SearchServiceTypeEnum.ORDERS_SEARCH ? (
                      <FormattedMessage defaultMessage="Enter up to 2000 values" id="searchBar.multiValue.inputTitle" />
                    ) : (
                      <FormattedMessage defaultMessage="Enter up to 200 values" id="searchBar.multiValue.inputTitle" />
                    )}
                  </StyledSpan>
                </Col>
                <Col>
                  <StyledMultiFieldButton disabled={isEmpty(Array.from(tags))} onClick={onClearAll}>
                    <FormattedMessage defaultMessage="Clear all" id="searchBar.multiValue.clearAll" />
                  </StyledMultiFieldButton>
                </Col>
              </Row>
            )}
            <div className="d-flex align-items-center justify-content-between mb-1">
              <div>
                {previewStep && (
                  <StyledSpan className="mb-0">
                    <FormattedMessage
                      defaultMessage="Values with matches"
                      id="searchBar.multiValue.valuesWithMatches"
                    />
                  </StyledSpan>
                )}
              </div>
            </div>
            <StyledTagsContainer>
              <FormattedMessage
                defaultMessage="Enter or paste up to 200 values, separated by spaces"
                id="searchBar.multiValue.placeholder"
              >
                {(formattedString: string) => (
                  <TagsArea
                    handleRemoveTag={onRemoveTag}
                    onKeyPress={onKeyPress}
                    onKeyUp={onKeyUp}
                    onKeyDown={handleKeyDown}
                    onPaste={onPaste}
                    onChange={handleTextAreaChange}
                    value={text}
                    tags={tags}
                    placeholder={isEmpty(Array.from(tags)) ? formattedString : ''}
                    tagColor={primaryGreyEighty}
                    hasError={hasError}
                    dataLocator="multi-value-search-input"
                  />
                )}
              </FormattedMessage>
              {hasError && (
                <StyledError className="mt-1 text-right" data-locator="multi-value-search-error-message">
                  {errorMessage}
                </StyledError>
              )}
            </StyledTagsContainer>
          </Col>
          {previewStep && (
            <Col md={10} className="mt-4 mt-md-0">
              <div className="d-flex align-items-center mb-1">
                <StyledSpan className="mb-0">
                  <FormattedMessage
                    defaultMessage="Values with no matches"
                    id="searchBar.multiValue.valuesWithNoMatches"
                  />
                </StyledSpan>
                <StyledHalfButton>
                  <CopyToClipboard text={Array.from(unmatchedTags).join(' ')} onCopy={showLinkCopiedToast}>
                    <StyledMultiFieldButton disabled={!unmatchedTags.size}>
                      <svg
                        width="14"
                        height="16"
                        viewBox="0 0 14 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.3332 0.666626H1.83317C1.054 0.666626 0.416504 1.26663 0.416504 1.99996V11.3333H1.83317V1.99996H10.3332V0.666626ZM12.4582 3.33329H4.6665C3.88734 3.33329 3.24984 3.93329 3.24984 4.66663V14C3.24984 14.7333 3.88734 15.3333 4.6665 15.3333H12.4582C13.2373 15.3333 13.8748 14.7333 13.8748 14V4.66663C13.8748 3.93329 13.2373 3.33329 12.4582 3.33329ZM12.4582 14H4.6665V4.66663H12.4582V14Z"
                          fill="currentColor"
                        />
                      </svg>
                      <span className="ml-1">
                        <FormattedMessage defaultMessage="Copy" id="searchBar.multiValue.copy" />
                      </span>
                    </StyledMultiFieldButton>
                  </CopyToClipboard>
                </StyledHalfButton>
              </div>
              <StyledNonMatch>
                {Array.from(unmatchedTags).map((tag: any) => (
                  <Tags
                    className="mb-3"
                    closable
                    color={errorRedTwo}
                    key={tag}
                    onClose={() => handleRemoveUnmatchedTag(tag)}
                  >
                    {tag}
                  </Tags>
                ))}
              </StyledNonMatch>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Button clickFn={() => setCurrentStep(0)} disabled={!currentStep} className="d-inline-block mr-3">
              <FormattedMessage id="searchBar.multiValue.goBack" defaultMessage="Go back" />
            </Button>
          </Col>
          <Col>
            <StyledAdvanceButtons>
              <Button clickFn={handleCancel} className="d-inline-block mr-3">
                <FormattedMessage id="searchBar.multiValue.cancel" defaultMessage="Cancel" />
              </Button>
              {showViewResultsBtn ? (
                <Button
                  type="primary"
                  clickFn={() => {
                    handleSubmitSearch(serviceType);
                  }}
                  className="d-inline-block"
                >
                  <FormattedMessage id="searchBar.multiValue.viewResults" defaultMessage="View results" />
                </Button>
              ) : (
                <Button
                  type="primary"
                  clickFn={handleCheckMatches}
                  className="d-inline-block"
                  data-locator="data-locator-search-button"
                  disabled={!tags.size}
                >
                  <FormattedMessage id="searchBar.multiValue.search" defaultMessage="Search" />
                </Button>
              )}
            </StyledAdvanceButtons>
          </Col>
        </Row>
      </div>
    </StyledModal>
  );
};

export default MultiValueSearchModal;
