import { useState, useEffect } from 'react';
import get from 'lodash/get';

export const useFetchConfig = () => {
  const [config, setConfig] = useState<any>();
  useEffect(() => {
    async function fetchConfig() {
      try {
        const configuration = {
          hereMapsApiKey: `${get(window, 'portalGlobal.heremapsApiKey')}`,
          hereMapsCode: `${get(window, 'portalGlobal.heremapsAppCode')}`,
          hereMapsId: `${get(window, 'portalGlobal.heremapsAppID')}`,
        };
        setConfig(configuration);
      } catch (error) {
        console.error(error);
      }
    }
    fetchConfig();
  }, []);
  return config;
};
