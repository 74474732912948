import isArrayLike from 'lodash/isArrayLike';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import find from 'lodash/find';
import xor from 'lodash/xor';
import { intl } from 'common/AppConstants';
import { MODE_FILTERS } from 'common/modeFilters';
import { useEUVerbiage } from 'i18n/configurei18n';
import message from '../modes/filterMessages.json';
import FilterUtil from '../util/filterUtil';
import types from './types';

const mapListWithIdAsIndex = (list) => list.map((x, index) => ({ ...x, id: index }));
export const allModeFilterOptions = [
  {
    label: intl.formatMessage(message.allModes),
    displayValue: intl.formatMessage(message.allModes),
    value: MODE_FILTERS.ALL,
    checked: true,
  },
  {
    label: intl.formatMessage(message.truckload),
    displayValue: intl.formatMessage(message.truckload),
    value: MODE_FILTERS.TRUCKLOAD,
    checked: false,
  },
  {
    label: useEUVerbiage ? intl.formatMessage(message.ltlEU) : intl.formatMessage(message.ltlVltl),
    displayValue: useEUVerbiage ? intl.formatMessage(message.ltlEU) : intl.formatMessage(message.ltlVltl),
    value: MODE_FILTERS.LTL,
    checked: false,
  },
  {
    label: intl.formatMessage(message.ocean),
    displayValue: intl.formatMessage(message.ocean),
    value: MODE_FILTERS.OCEAN,
    checked: false,
  },
  {
    label: intl.formatMessage(message.parcel),
    displayValue: intl.formatMessage(message.parcel),
    value: MODE_FILTERS.PARCEL,
    checked: false,
  },
  {
    label: intl.formatMessage(message.rail),
    displayValue: intl.formatMessage(message.rail),
    value: MODE_FILTERS.RAIL,
    checked: false,
  },
  {
    label: intl.formatMessage(message.air),
    displayValue: intl.formatMessage(message.air),
    value: MODE_FILTERS.AIR,
    checked: false,
  },
];

export const initialState = () => {
  const state = {
    // filter that will be placed on the search request directly. This is kept up to date through the reducer logic.
    filter: FilterUtil.getEmptyFilter(),

    allModeFilterOptions: mapListWithIdAsIndex(allModeFilterOptions),
    filterInputs: {
      locationFilterValue: '',
      carrierFilterValue: '',
      companyFilterValue: '',
      customerTenantsFilterValue: '',
      createdByFilterValue: '',
      attributeFilterValue: '',
      portOfLoadingFilterValue: '',
      transshipmentPortValue: '',
      portOfDischargeValue: '',
      sharedToFilterValue: '',
      sharedByFilterValue: '',
    },

    showSelections: {
      showAllStatus: false,
      showAllLocation: false,
      showAllCarrier: false,
      showAllCustomers: false,
      showAllCompany: false,
      showAllCreatedBy: false,
      showAllAttribute: false,
      showAllPortOfLoading: false,
      showAllTransshipmentPort: false,
      showAllPortOfDischarge: false,
      showAllHappeningCode: false,
      showAllSharedTo: false,
      showAllSharedBy: false,
    },

    displayedFilterOptions: [],

    // Temperature tracking filters, used to create checkboxes
    temperatureTrackingFilters: createCheckboxLabelProperyPairs(
      intl.formatMessage(message.withinRange),
      'withinRange',
      intl.formatMessage(message.belowLowBoundSettings),
      'belowLowBoundSettings',
      intl.formatMessage(message.aboveHighBoundSettings),
      'aboveHighBoundSettings',
      intl.formatMessage(message.viewAll),
      'viewAll'
    ),

    oceanActiveHoldsFilters: createCheckboxLabelProperyPairs(
      intl.formatMessage(message.customsHold),
      'customsHold',
      intl.formatMessage(message.lineHolds),
      'lineHolds',
      intl.formatMessage(message.otherHolds),
      'otherHolds'
    ),

    oceanDemurrageEligibleFilters: createCheckboxLabelProperyPairs(
      intl.formatMessage(message.demurrageRisk),
      'demurrageRisk'
    ),

    // items to be set via API call to service
    locationSuggestions: [],

    portOfLoadingSuggestions: [],

    transshipmentPortSuggestions: [],

    portOfDischargeSuggestions: [],

    carrierSuggestions: [],

    companySuggestions: [],

    customerSuggestions: [],

    createdBySuggestions: [],

    attributeSuggestions: [],

    tenantAttributes: [],

    modeFilters: [],

    customersListData: [],
  };

  // Set IDs to their index
  state.temperatureTrackingFilters = mapListWithIdAsIndex(state.temperatureTrackingFilters);

  state.oceanActiveHoldsFilters = mapListWithIdAsIndex(state.oceanActiveHoldsFilters);
  state.oceanDemurrageEligibleFilters = mapListWithIdAsIndex(state.oceanDemurrageEligibleFilters);

  return state;
};

export const setModeFilters = (modeFilter, filter) => {
  // this will happen when doing orders/inventory. this gets called amidst generic code so this seems like the
  // easiest way to handle this
  if (!filter) {
    return [];
  }
  let modeFilters = [modeFilter];
  if (modeFilter === null || modeFilter === MODE_FILTERS.ALL) {
    modeFilters = FilterUtil.modesBasedOnStatusFilters(filter);
    if (modeFilters.includes(MODE_FILTERS.ALL)) {
      modeFilters = [MODE_FILTERS.ALL];
    }
  }
  return modeFilters;
};

const createCheckboxLabelProperyPairs = (...labelPropertyPairs) => {
  if (!isArrayLike(labelPropertyPairs) || isEmpty(labelPropertyPairs)) {
    throw new Error('Invalid labelPropertyPairs:$labelPropertyPairs');
  }

  const labels = [];
  for (let i = 0; i < labelPropertyPairs.length; i += 2) {
    labels.push({
      labelText: labelPropertyPairs[i],
      propertyName: labelPropertyPairs[i + 1],
      checked: false,
    });
  }

  return labels;
};

// Simple reducer that just sets the filter received,
const filterReducer = (state = initialState(), action = {}) => {
  switch (action.type) {
    case types.CLEAR_FILTER:
    case types.CLEAR_FILTER_NO_SEARCH: {
      // If there are tenant attributes, preserve them so the filter item
      // remains visible and another call to Custom Reference Service doesn't
      // have to be made
      const filter = FilterUtil.getEmptyFilter();
      return {
        ...initialState(),
        filter: filter,
        modeFilters: setModeFilters(action.modeFilter, filter),
        displayedFilterOptions: [],
        tenantAttributes: state.tenantAttributes,
      };
    }
    case types.APPLY_FILTER_FIELDS:
      return updateStateFromFilter({ ...state }, FilterUtil.getValidOrBlank(action.filter));
    case types.SET_LOCATION_SUGGESTIONS:
      return {
        ...state,
        locationSuggestions: action.suggestions,
        locationFilterValue: null,
      };
    case types.SET_PORT_OF_LOADING_SUGGESTIONS:
      return {
        ...state,
        portOfLoadingSuggestions: action.suggestions,
        portOfLoadingFilterValue: null,
      };
    case types.SET_TRANSSHIPMENT_PORT_SUGGESTIONS:
      return {
        ...state,
        transshipmentPortSuggestions: action.suggestions,
        transshipmentPortValue: null,
      };
    case types.SET_PORT_OF_DISCHARGE_SUGGESTIONS:
      return {
        ...state,
        portOfDischargeSuggestions: action.suggestions,
        portOfDischargeValue: null,
      };
    case types.SET_CARRIER_SUGGESTIONS:
      return {
        ...state,
        carrierSuggestions: action.suggestions,
      };
    case types.SET_COMPANY_SUGGESTIONS:
      return {
        ...state,
        companySuggestions: action.suggestions,
      };
    case types.SET_CUSTOMER_SUGGESTIONS:
      return {
        ...state,
        customerSuggestions: action.suggestions,
      };
    case types.SET_CREATEDBY_SUGGESTIONS:
      return {
        ...state,
        createdBySuggestions: action.suggestions,
      };
    case types.SET_ATTRIBUTE_SUGGESTIONS:
      return {
        ...state,
        attributeSuggestions: action.suggestions,
      };
    case types.SET_CHECKBOX_ITEMS_SINGLE:
      return {
        ...state,
        [`${action.checkboxGroup}Filters`]: map(state[`${action.checkboxGroup}Filters`], (item) => {
          item.checked = item === action.checkboxItem ? !item.checked : item.checked;
          return item;
        }),
        filter: {
          ...state.filter,
          [action.checkboxGroup]: action.checkboxItem.checked,
        },
      };
    case types.SET_CHECKBOX_ITEMS_GROUP:
      return {
        ...state,
        [`${action.checkboxGroup}Filters`]: map(state[`${action.checkboxGroup}Filters`], (item) => {
          item.checked = item === action.checkboxItem ? !item.checked : item.checked;
          return item;
        }),
        filter: {
          ...state.filter,
          [action.checkboxGroup]: xor([action.checkboxItem.labelText], state.filter[action.checkboxGroup]),
        },
      };
    case types.SET_AUTOCOMPLETE_ITEMS: {
      const isCustomerTenantFilter = action.filterItemGroup === 'customerTenants';
      let matchingCustomerSuggestion;
      if (isCustomerTenantFilter) {
        // action.filterItem is tenantId
        // Get the tenant Name from the customerSuggestions list
        matchingCustomerSuggestion = find(state.customerSuggestions, (item) => item.tenantId === action.filterItem);
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.filterItemGroup]: xor(
            [isCustomerTenantFilter ? action.filterItem : action.filterItem],
            state.filter[action.filterItemGroup]
          ),
        },
        ...(isCustomerTenantFilter && {
          customersListData: xor(
            [matchingCustomerSuggestion.name],
            [
              ...state.customersListData,
              ...state.customersListData.filter((item) => item === matchingCustomerSuggestion.name),
            ]
          ),
        }),
        filterInputs: {
          ...state.filterInputs,
          [`${action.filterItemGroup}FilterValue`]: '',
        },
      };
    }
    case types.SET_STATUS_ITEMS:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.selectedValues,
        },
      };
    case types.REMOVE_STATUS_ITEM:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: state.filter.status.filter((status) => status !== action.selectedValue),
        },
      };
    case types.SET_FILTER_INPUT:
      return {
        ...state,
        filterInputs: {
          ...state.filterInputs,
          [`${action.filterGroup}FilterValue`]: action.inputValue,
        },
      };
    case types.SET_SAVED_FILTER:
      return {
        ...state,
        filter: action.mapFilter,
      };
    case types.SET_MODE_FILTER: {
      return {
        ...state,
        modeFilters: setModeFilters(action.modeFilter, action.filter),
      };
    }
    case types.SET_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.dateType]: isNil(action.date) ? null : action.date,
        },
      };
    case types.TOGGLE_SHOW_SELECTIONS:
      return {
        ...state,
        showSelections: {
          ...state.showSelections,
          [`showAll${action.group}`]: !state.showSelections[`showAll${action.group}`],
        },
      };

    // Used when the user can select multiple modes, such as on the shipment list page
    case types.TOGGLE_MODE_FILTER_OPTIONS: {
      if (!action.option) {
        return state;
      }
      const { value } = action.option.target;

      return {
        ...state,
        modeFilters: [value],
      };
    }

    // Used when the user can only select one mode, such as on the Add/EditQuickview modal
    case types.TOGGLE_MODE_FILTER_OPTIONS_SELECT_ONE:
      if (!action.option) {
        return state;
      }

      return {
        ...initialState(),
        filter: FilterUtil.getEmptyFilter(),
        displayedFilterOptions: [],
        tenantAttributes: state.tenantAttributes,
        modeFilters: [action.option],
      };

    case types.GET_TENANT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        tenantAttributes: action.response.data,
      };

    case types.SET_FILTER_CARRIER_MODE: {
      if (!action.value) {
        return state;
      }

      return {
        ...state,
        modeFilters: [action.value],
      };
    }

    case types.TOGGLE_LOAD_FILTERS: {
      return {
        ...state,
        ...action,
      };
    }

    default:
      return state;
  }
};

const setFilterCheckedStateOnUpdate = (stateValues, updatedFormValues) => {
  return map(stateValues, (value) => {
    const newValue = { ...value };
    newValue.checked = includes(updatedFormValues, value.labelText);
    return newValue;
  });
};

const updateStateFromFilter = (state, filter) => {
  state.temperatureTrackingFilters = setFilterCheckedStateOnUpdate(
    state.temperatureTrackingFilters,
    filter.temperatureTracking
  );
  state.happeningCodeFilters = setFilterCheckedStateOnUpdate(state.happeningCodeFilters, filter.happeningCode);
  state.oceanActiveHoldsFilters = setFilterCheckedStateOnUpdate(state.oceanActiveHoldsFilters, filter.oceanActiveHolds);

  // oceanDemurrageEligible is treated as a simple boolean since it's not part of a group of check boxes
  // like oceanActiveHoldsFilters, or deliveryStatusFilters
  state.oceanDemurrageEligibleFilters[0].checked = filter.oceanDemurrageEligible;

  state.locationSuggestions = filter.location;
  state.portOfLoadingSuggestions = filter.portOfLoading;
  state.transshipmentPortSuggestions = filter.transshipmentPort;
  state.portOfDischargeSuggestions = filter.portOfDischarge;
  state.carrierSuggestions = filter.carrier;
  state.companySuggestions = filter.company;
  state.createdBySuggestions = filter.createdBy;
  state.attributeSuggestions = filter.attribute;
  state.displayedFilterOptions = filter;

  state.filter = filter;

  return state;
};

export default filterReducer;
