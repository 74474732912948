import { autofill, Field, FormSection } from 'redux-form';
import MenuItem from 'material-ui/MenuItem';
import { injectIntl, defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import AutoComplete from 'material-ui/AutoComplete';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { COUNTRIES_SELECTION, STATES_SELECTION, EU_COUNTRIES_SELECTION } from '../../../common/AppConstants';
import { requiredValidation } from '../../../common/FormValidations';
import { renderInput, renderSingleSelectDropdown } from './FormInputs';
import './AddressFormSection.scss';
import { locationListActions } from '../../locationManagement/ducks/actions';
import CarrierType from '../../shipment/common/enums/carrierType';

let intl = '';

const message = defineMessages({
  company: {
    id: 'locationDetails.addressFormSection.company',
    defaultMessage: 'Company',
  },
  addressLine1: {
    id: 'locationDetails.addressFormSection.addressLine1',
    defaultMessage: 'Address Line 1',
  },
  addressLine2: {
    id: 'locationDetails.addressFormSection.addressLine2',
    defaultMessage: 'Address Line 2',
  },
  addressLine2Optional: {
    id: 'locationDetails.addressFormSection.addressLine2Optional',
    defaultMessage: 'Address Line 2 (optional)',
  },
  zip: {
    id: 'locationDetails.addressFormSection.zip',
    defaultMessage: 'Zip',
  },
  city: {
    id: 'locationDetails.addressFormSection.city',
    defaultMessage: 'City',
  },
  state: {
    id: 'locationDetails.addressFormSection.state',
    defaultMessage: 'State',
  },
  country: {
    id: 'locationDetails.addressFormSection.country',
    defaultMessage: 'Country',
  },
  acmeInc: {
    id: 'locationDetails.addressFormSection.acmeInc',
    defaultMessage: 'ACME, INC',
  },
  streetnameBlvd: {
    id: 'locationDetails.addressFormSection.streetnameBlvd',
    defaultMessage: '1234 Streetname Blvd.',
  },
  suite: {
    id: 'locationDetails.addressFormSection.suite',
    defaultMessage: 'Suite 123',
  },
  pleasantville: {
    id: 'locationDetails.addressFormSection.pleasantville',
    defaultMessage: 'Pleasantville',
  },
  enterCompanyName: {
    id: 'locationDetails.addressFormSection.enterCompanyName',
    defaultMessage: 'Enter Company Name',
  },
  enterStreetName: {
    id: 'locationDetails.addressFormSection.enterStreetName',
    defaultMessage: 'Enter Street Name and Number',
  },
  enterAptSuiteUnitNumber: {
    id: 'locationDetails.addressFormSection.enterAptSuiteUnitNumber',
    defaultMessage: 'Enter Apt, Suite, Unit Number',
  },
  enterCity: {
    id: 'locationDetails.addressFormSection.enterCity',
    defaultMessage: 'Enter City',
  },
  stateProvinceRegion: {
    id: 'locationDetails.addressFormSection.stateProvinceRegion',
    defaultMessage: 'State/Province/Region (optional)',
  },
  enterStateProvinceRegion: {
    id: 'locationDetails.addressFormSection.enterStateProvinceRegion',
    defaultMessage: 'Enter State, Province or Region',
  },
  postalZipCode: {
    id: 'locationDetails.addressFormSection.postalZipCode',
    defaultMessage: 'Postal/Zip Code (optional)',
  },
  enterZip: {
    id: 'locationDetails.addressFormSection.enterZip',
    defaultMessage: 'Enter Zip',
  },
});

const ALL_COUNTRIES_SELECTION = [...COUNTRIES_SELECTION, ...EU_COUNTRIES_SELECTION].sort(function (a, b) {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
});

const renderAutocompleteInput = ({
  input,
  label,
  placeholder,
  formName,
  classes,
  meta: { touched, error, dispatch, form },
  handleGetSuggestions,
  dataSource,
  addressFormName,
  contactFormName,
}) => {
  const name = formName ? formName + input.name : input.name;
  return (
    <div className={`form-group ${classes}`}>
      <label>{label}</label>
      <AutoComplete
        name={name}
        {...input}
        id="auto-foo"
        className={`autocomplete-input-filter form-control ${touched && error ? 'is-invalid' : ''}`}
        dataSource={dataSource}
        // eslint-disable-next-line
        filter={(searchText, key) => true}
        style={{
          width: '100%',
          height: 36,
        }}
        hintText={placeholder}
        hintStyle={{
          padding: '0 0 0 .5em',
          position: 'static',
        }}
        inputStyle={{
          padding: '0',
          color: '#575451',
          fontWeight: 500,
          lineHeight: '36px',
          height: '34px',
          paddingLeft: '8px',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        searchText={input.value}
        underlineStyle={{
          display: 'none',
        }}
        listStyle={{
          lineHeight: 1,
          padding: 0,
          width: '500px',
        }}
        textFieldStyle={{
          fontSize: '12px',
          color: '#575451',
          fontWeight: 500,
          lineHeight: '36px',
          height: '34px',
          width: '100%',
          overflow: 'hidden',
          paddingLeft: 0,
          paddingRight: 0,
        }}
        maxSearchResults={6}
        // eslint-disable-next-line react/jsx-no-bind
        onUpdateInput={(value, ds, { source }) => {
          if (source !== 'click') {
            //do not change input when clicking an option.
            //that will be done onNewRequest. Otherwise duplicate searches are done.
            input.onChange(value);
            if (value && value.length && value.length >= 2) {
              handleGetSuggestions(value);
            }
          }
        }}
        // eslint-disable-next-line react/jsx-no-bind
        onNewRequest={({ value: l }) => {
          if (!l) {
            return;
          }
          const afn = isEmpty(addressFormName) ? '' : addressFormName + '.';
          const cfn = isEmpty(contactFormName) ? '' : contactFormName + '.';
          dispatch(autofill(form, afn + 'company', l.companyName));
          dispatch(autofill(form, afn + 'addressLine1', get(l, 'address.addressLine1')));
          dispatch(autofill(form, afn + 'addressLine2', get(l, 'address.addressLine2')));
          dispatch(autofill(form, afn + 'zipCode', get(l, 'address.zip')));
          dispatch(autofill(form, afn + 'city', get(l, 'address.city')));
          dispatch(autofill(form, afn + 'state', get(l, 'address.state')));
          dispatch(autofill(form, afn + 'country', get(l, 'address.country')));

          dispatch(autofill(form, cfn + 'firstName', get(l, 'contact.firstName')));
          dispatch(autofill(form, cfn + 'lastName', get(l, 'contact.lastName')));
          dispatch(autofill(form, cfn + 'phoneNumber', get(l, 'contact.phoneNumber')));
          dispatch(autofill(form, cfn + 'email', get(l, 'contact.email')));
        }}
        // eslint-disable-next-line react/jsx-no-bind
        onClose={() => dispatch(locationListActions.clearLocationSuggestions())}
      />
      {touched && error && error.id && (
        <span className="invalid-feedback">
          <FormattedMessage id={error.id} defaultMessage={error.defaultMessage} />
        </span>
      )}
      {touched && error && typeof error === 'string' && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

const locationToSuggestion = (l) => {
  return `${l.companyName} - ${l.address.addressLine1}, ${l.address.city}, ${l.address.state}, ${l.address.zip}`;
};

let countrySelected = {};

const setCurrentCountry = (item) => {
  countrySelected = COUNTRIES_SELECTION.find((country) => {
    return country.value === item || country.name === item;
  });
};

const setCurrentCountryEU = (item) => {
  countrySelected = ALL_COUNTRIES_SELECTION.find((country) => {
    return country.value === item || country.name === item;
  });
};

const isCountrySelected = (state) => {
  return countrySelected !== undefined && state.country === countrySelected.value;
};

const autoFillCountry = (props, setCurrentCountry) => {
  const { initialValues } = props;
  if (initialValues && initialValues.address && initialValues.address.country && !countrySelected) {
    setCurrentCountry(initialValues.address.country);
  }
};

const AddressFormSection = (props) => {
  const { zipEntered, autocomplete, handleGetSuggestions, locationSuggestions, formName, contactFormName } = props;
  const companyComponent = autocomplete ? renderAutocompleteInput : renderInput;

  return (
    <FormSection name={formName}>
      <div className="address-form">
        <p className="form-header">
          <FormattedMessage id={props.addressHeader?.id} defaultMessage={props.addressHeader?.defaultMessage} />
        </p>
        <Field
          type="text"
          label={intl.formatMessage(message.company)}
          component={companyComponent}
          handleGetSuggestions={handleGetSuggestions}
          dataSource={locationSuggestions.map(({ location }) => ({
            text: locationToSuggestion(location),
            value: location,
          }))}
          addressFormName={formName}
          contactFormName={contactFormName}
          validate={[requiredValidation]}
          name="company"
          placeholder={intl.formatMessage(message.acmeInc)}
        />
        <Field
          type="text"
          label={intl.formatMessage(message.addressLine1)}
          component={renderInput}
          validate={[requiredValidation]}
          name="addressLine1"
          placeholder={intl.formatMessage(message.streetnameBlvd)}
        />
        <Field
          type="text"
          formName={formName}
          label={intl.formatMessage(message.addressLine2)}
          component={renderInput}
          name="addressLine2"
          placeholder={intl.formatMessage(message.suite)}
        />
        <div className="address-row form-row">
          <Field
            type="text"
            label={intl.formatMessage(message.zip)}
            component={renderInput}
            validate={[requiredValidation]}
            // eslint-disable-next-line react/jsx-no-bind
            onBlur={(e) => zipEntered(e.target.value)}
            name="zipCode"
            classes="col-6"
            placeholder="12345"
          />
          <Field
            type="text"
            label={intl.formatMessage(message.city)}
            component={renderInput}
            validate={[requiredValidation]}
            name="city"
            classes="col-8"
            placeholder={intl.formatMessage(message.pleasantville)}
          />
          <Field
            name="state"
            label={props.intl.formatMessage(message.state)}
            classes="col-2"
            validate={[requiredValidation]}
            component={renderSingleSelectDropdown}
          >
            {STATES_SELECTION.map(
              (stateItem) =>
                isCountrySelected(stateItem) && (
                  <MenuItem value={stateItem.state} primaryText={stateItem.state} key={stateItem.state} />
                )
            )}
          </Field>
          <Field
            id="country"
            name="country"
            label={props.intl.formatMessage(message.country)}
            classes="col-6"
            validate={[requiredValidation]}
            component={renderSingleSelectDropdown}
            onChange={setCurrentCountry}
          >
            {COUNTRIES_SELECTION.map((country) => (
              <MenuItem value={country.value} primaryText={country.name} key={country.value} />
            ))}
          </Field>
        </div>
      </div>
      {props.children}
    </FormSection>
  );
};

const EUAddressFormSection = (props) => {
  const { autocomplete, handleGetSuggestions, locationSuggestions, formName, contactFormName } = props;
  const companyComponent = autocomplete ? renderAutocompleteInput : renderInput;

  return (
    <FormSection name={formName}>
      <div className="address-form">
        <p className="form-header">
          <FormattedMessage id={props.addressHeader?.id} defaultMessage={props.addressHeader?.defaultMessage} />
        </p>
        <Field
          type="text"
          label={intl.formatMessage(message.company)}
          component={companyComponent}
          handleGetSuggestions={handleGetSuggestions}
          dataSource={locationSuggestions.map(({ location }) => ({
            text: locationToSuggestion(location),
            value: location,
          }))}
          addressFormName={formName}
          contactFormName={contactFormName}
          validate={[requiredValidation]}
          name="company"
          placeholder={intl.formatMessage(message.enterCompanyName)}
        />
        <Field
          name="country"
          label={intl.formatMessage(message.country)}
          validate={[requiredValidation]}
          onChange={setCurrentCountryEU}
          component={renderSingleSelectDropdown}
        >
          {ALL_COUNTRIES_SELECTION.map((country) => (
            <MenuItem value={country.value} primaryText={country.name} key={country.value} />
          ))}
        </Field>
        <Field
          type="text"
          label={intl.formatMessage(message.addressLine1)}
          component={renderInput}
          validate={[requiredValidation]}
          name="addressLine1"
          placeholder={intl.formatMessage(message.enterStreetName)}
        />
        <Field
          type="text"
          formName={formName}
          label={intl.formatMessage(message.addressLine2Optional)}
          component={renderInput}
          name="addressLine2"
          placeholder={intl.formatMessage(message.enterAptSuiteUnitNumber)}
        />
        {validateEUCountries() ? formAddressEUCarrierEU(props) : formAddressNACarrierEU(props)}
      </div>
      {props.children}
    </FormSection>
  );
};

const validateEUCountries = () => {
  return (
    countrySelected &&
    countrySelected.value !== 'US' &&
    countrySelected.value !== 'CA' &&
    countrySelected.value !== 'MX'
  );
};

const formAddressEUCarrierEU = (props) => {
  const { formName } = props;

  return (
    <div className="address-row form-row">
      <Field
        type="text"
        label={intl.formatMessage(message.city)}
        component={renderInput}
        validate={[requiredValidation]}
        name="city"
        classes="col-8"
        placeholder={intl.formatMessage(message.enterCity)}
      />

      <Field
        type="text"
        formName={formName}
        label={intl.formatMessage(message.stateProvinceRegion)}
        component={renderInput}
        name="state"
        classes="col-10"
        placeholder={intl.formatMessage(message.enterStateProvinceRegion)}
      />

      <Field
        type="text"
        formName={formName}
        label={intl.formatMessage(message.postalZipCode)}
        component={renderInput}
        name="zipCode"
        classes="col-6"
        placeholder={intl.formatMessage(message.enterZip)}
      />
    </div>
  );
};

const formAddressNACarrierEU = (props) => {
  const { zipEntered, formName } = props;
  return (
    <div className="address-row form-row">
      <Field
        type="text"
        label={intl.formatMessage(message.zip)}
        component={renderInput}
        validate={[requiredValidation]}
        // eslint-disable-next-line react/jsx-no-bind
        onBlur={(e) => zipEntered(e.target.value)}
        name="zipCode"
        classes="col-6"
        placeholder="12345"
      />
      <Field
        type="text"
        label={intl.formatMessage(message.city)}
        component={renderInput}
        validate={[requiredValidation]}
        name="city"
        classes="col-8"
        placeholder={intl.formatMessage(message.pleasantville)}
      />
      <Field
        name="state"
        formName={formName}
        label={intl.formatMessage(message.stateProvinceRegion)}
        classes="col-10"
        validate={[requiredValidation]}
        component={renderSingleSelectDropdown}
      >
        {STATES_SELECTION.map(
          (stateItem) =>
            isCountrySelected(stateItem) && (
              <MenuItem value={stateItem.state} primaryText={stateItem.state} key={stateItem.state} />
            )
        )}
      </Field>
    </div>
  );
};

const isEuCountry = (props) => {
  return EU_COUNTRIES_SELECTION.find((country) => country.value === props.initialValues?.address?.country);
};

const RenderAddressFormSection = (props) => {
  intl = useIntl();

  const isEU = (!isEmpty(props.carrierTypes) && props.carrierTypes[0].type === CarrierType.EU) || isEuCountry(props);
  autoFillCountry(props, isEU ? setCurrentCountryEU : setCurrentCountry);
  useEffect(() => {
    if (isEU) {
      setCurrentCountryEU(props.countryValue);
    } else {
      setCurrentCountry(props.countryValue);
    }
  }, [isEU, props.countryValue]);

  return isEU ? EUAddressFormSection(props) : AddressFormSection(props);
};

const mapStateToProps = (state, props) => {
  let countryValue = '';
  if (state.form) {
    const forms = Object.keys(state.form);
    if (forms.length > 0) {
      const formValues = state.form[forms[0]].values;
      if (props.formName?.includes('[')) {
        const formNameTemp = props.formName;
        const [keyName, index] = formNameTemp.replace(']', '').split('[');
        countryValue = formValues?.[keyName]?.[index]?.country || '';
      } else if (formValues && props.formName in formValues && formValues[props.formName].country) {
        countryValue = formValues[props.formName].country;
      }
    }
  }

  return {
    locationSuggestions: state.locationManagementReducer.locationListReducer.locationSuggestions,
    countryValue: countryValue,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleGetSuggestions: (search) => dispatch(locationListActions.getLocationSuggestions({ search })),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RenderAddressFormSection));
