import get from 'lodash/get';

import FilterUtil from 'components/filter/util/filterUtil';
import { shouldUseRecentSSS } from 'common/authorizations.ts';
import { SEARCH_RESULTS_PER_PAGE, DEFAULT_SHIPMENT_SOURCES } from '../../../common/AppConstants';

// ++=========++
// || Actions ||
// ++=========++

const namespace = (actionType) => 'shipment/search/' + actionType;

export const SEARCH_MAP_SHIPMENTS_TYPE = namespace('SEARCH_MAP_SHIPMENTS_TYPE');
export const RESET_SEARCH_QUERY_TYPE = namespace('RESET_SEARCH_QUERY');
export const SET_SEARCH_TERM_TYPE = namespace('SET_SEARCH_TERM');
export const SEARCH_SHIPMENTS_TYPE = namespace('SEARCH_SHIPMENTS');
export const SET_SHIPMENTS_TYPE = namespace('SET_SEARCHED_SHIPMENTS');
export const ADD_SHIPMENTS_TYPE = namespace('ADD_SEARCHED_SHIPMENTS');
export const SHIPMENT_SEARCH_FAILURE_TYPE = namespace('SHIPMENT_SEARCH_FAILURE');
export const GET_NEXT_SHIPMENT_PAGE = namespace('GET_NEXT_SHIPMENT_PAGE');
export const CLEAR_SHIPMENTS = namespace('CLEAR_SHIPMENTS');
export const GET_TL_EQUIPMENT_IDENTIFIER_TYPES = namespace('GET_TL_EQUIPMENT_IDENTIFIER_TYPES');
export const GET_TL_EQUIPMENT_IDENTIFIER_TYPES_SUCCESS = namespace('GET_TL_EQUIPMENT_IDENTIFIER_TYPES_SUCCESS');
export const GET_TL_EQUIPMENT_IDENTIFIER_TYPES_ERROR = namespace('GET_TL_EQUIPMENT_IDENTIFIER_TYPES_ERROR');
export const TOOGLE_MODAL_MULTI_VALUE_SEARCH = namespace('TOOGLE_MODAL_MULTI_VALUE_SEARCH');

export const SUBSET_SEARCH_TYPE_LIST = 'SHIPMENT_LIST';

// ++=========++
// || Reducer ||
// ++=========++

export const initialState = () => ({
  // Current UI state that will be used for a new search query.
  searchTerm: '',
  filter: {},
  modeFilters: [],
  pageNumber: 0,
  isMuliValueSearchModalOpen: false,

  // Results of the most recent search being displayed.
  shipments: [],
  totalSearchResults: 0,
  isLoading: false,
  searchError: null,

  // Previous query stored for the show more pagination functionality.
  previousSearchQuery: {
    search: '',
    sortBy: [],
    resultStartIndex: 0,
    resultsPerPage: SEARCH_RESULTS_PER_PAGE,
  },

  equipmentIdentifierTypes: [],
});

const searchReducer = (state = initialState(), action = {}) => {
  switch (action.type) {
    case RESET_SEARCH_QUERY_TYPE:
      return { ...state, ...initialState() };

    case SET_SEARCH_TERM_TYPE:
      return { ...state, searchTerm: action.searchTerm };

    case TOOGLE_MODAL_MULTI_VALUE_SEARCH:
      return { ...state, isMuliValueSearchModalOpen: action.isMuliValueSearchModalOpen };

    case SEARCH_SHIPMENTS_TYPE:
      return { ...state, previousSearchQuery: action.searchQuery, isLoading: true };

    case SEARCH_MAP_SHIPMENTS_TYPE:
      return { ...state, isLoading: true };

    case GET_NEXT_SHIPMENT_PAGE:
      return { ...state, previousSearchQuery: action.searchQuery };

    case SET_SHIPMENTS_TYPE:
      return {
        ...state,
        shipments: action.shipments,
        totalSearchResults: action.totalShipments,
        isLoading: false,
      };

    case ADD_SHIPMENTS_TYPE:
      return {
        ...state,
        shipments: state.shipments.concat(action.shipments),
      };

    case SHIPMENT_SEARCH_FAILURE_TYPE:
      return { ...state, error: action.error, searchError: action.error, isLoading: false };

    case CLEAR_SHIPMENTS:
      return { ...state, shipments: [] };

    case GET_TL_EQUIPMENT_IDENTIFIER_TYPES_SUCCESS:
      return { ...state, equipmentIdentifierTypes: action.results };

    default:
      return state;
  }
};

export default searchReducer;

// ++=================++
// || Action builders ||
// ++=================++

export const defaultSearchQuery = {
  search: '',
  sortBy: [],
  resultStartIndex: 0,
  resultsPerPage: SEARCH_RESULTS_PER_PAGE,
};

export const buildResetSearchQueryAction = () => ({ type: RESET_SEARCH_QUERY_TYPE });

export const clearShipments = () => ({ type: CLEAR_SHIPMENTS });

export const buildSetSearchTerm = (searchTerm) => ({
  type: SET_SEARCH_TERM_TYPE,
  searchTerm,
});

export const toogleModalMultiValueSearch = (isMuliValueSearchModalOpen) => ({
  type: TOOGLE_MODAL_MULTI_VALUE_SEARCH,
  isMuliValueSearchModalOpen,
});

export const getTLEquipmentIdentifierTypes = () => ({ type: GET_TL_EQUIPMENT_IDENTIFIER_TYPES });

export const buildShipmentSearchQueryAction = ({
  authorities = [],
  searchText = '',
  filter = {},
  modes = [],
  sortBy = [],
  resultStartIndex = 0,
  resultsPerPage = SEARCH_RESULTS_PER_PAGE,
  shipmentSources = DEFAULT_SHIPMENT_SOURCES,
  enableMultiValueSearch = false,
  recent,
} = {}) => {
  const oceanDemurrageEligibleFilter = get(filter, 'oceanDemurrageEligible', []);
  const oceanDemurrageEligible = FilterUtil.getOceanDemurrageEligibleFilterValue(oceanDemurrageEligibleFilter);
  let searchRecent;
  if (recent !== undefined && recent === 'false') {
    searchRecent = false;
  } else {
    searchRecent = shouldUseRecentSSS;
  }

  return {
    type: SEARCH_SHIPMENTS_TYPE,
    searchQuery: {
      search: searchText,
      filter: {
        ...filter,
        oceanDemurrageEligible,
      },
      enableMultiValueSearch,
      modes,
      sortBy,
      resultSubsetType: SUBSET_SEARCH_TYPE_LIST,
      resultStartIndex: resultStartIndex,
      resultsPerPage: resultsPerPage,
      recent: searchRecent,
      shipmentSources,
    },
    authorities: authorities,
  };
};

export const buildSetShipmentAction = (shipments = [], totalShipments = 0) => ({
  type: SET_SHIPMENTS_TYPE,
  shipments,
  totalShipments,
});

export const buildAddShipmentAction = (shipments = []) => ({
  type: ADD_SHIPMENTS_TYPE,
  shipments,
});

export const buildShipmentSearchFailureAction = (error) => ({
  type: SHIPMENT_SEARCH_FAILURE_TYPE,
  error,
});

export const buildGetNextShipmentPageAction = (previousSearchQuery = defaultSearchQuery, authorities = []) => ({
  type: GET_NEXT_SHIPMENT_PAGE,
  searchQuery: {
    ...previousSearchQuery,
    resultStartIndex: previousSearchQuery.resultStartIndex + previousSearchQuery.resultsPerPage,
  },
  authorities,
});

export const buildExecuteLastSearchQuery = (previousSearchQuery = defaultSearchQuery, authorities = []) => {
  return {
    type: SEARCH_SHIPMENTS_TYPE,
    searchQuery: {
      ...previousSearchQuery,
      resultStartIndex: 0,
    },
    authorities,
  };
};
