import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, Col, Row } from 'react-bootstrap';
import { useParams, RouteComponentProps } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { SubHeader } from 'ui-components';
import axios from 'util/paxios';

import { API_PATH } from 'common/AppConstants';
import * as brandThemingActions from 'components/settings/BrandTheming/ducks/actions';
import { Identifier, ShipmentStopSmall } from 'models';
import defaultTheme from 'components/settings/BrandTheming/defaultTheme';
import Progress from '../common/progress/Progress';
import ErrorMessage from '../common/errorBoundary/ErrorMessage/ErrorMessage';
import CarrierShipmentListCard from './carrierShipmentListCard/CarrierShipmentListCard';
import * as styles from './CarrierShipmentList.module.scss';
import CustomButton from '../../themes/CustomButton';

interface CarrierShipmentListResponseDto {
  tenantId: string;
  companyName: string;
  capacityProviderId: string;
  totalResults: number;
  shipmentsDto: Array<{
    shipmentId: string;
    shipmentIdentifiers: Identifier[];
    pickupStopInfo: ShipmentStopSmall;
    deliveryStopInfo: ShipmentStopSmall;
  }>;
}

enum LoadingStatus {
  LOADING,
  LOADED,
  INVALID_TOKEN_ERROR,
  GENERIC_ERROR,
}

const CarrierShipmentList: React.FC<RouteComponentProps> = () => {
  const params = useParams<{ carrierListShareToken: string }>();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [companyName, setCompanyName] = useState<string>('');
  const [carrierShipments, setCarrierShipments] = useState<CarrierShipmentListResponseDto['shipmentsDto']>([]);
  const [currentLoadingStatus, setCurrentLoadingStatus] = useState<LoadingStatus>();
  const [startIndex, setStartIndex] = useState<number>(0);
  const [totalresults, setTotalResults] = useState<CarrierShipmentListResponseDto['totalResults']>(0);

  const getPublicShareShipment = (startIdx: string): Promise<AxiosResponse<CarrierShipmentListResponseDto>> => {
    let baseRequest = `${API_PATH}/shipment/carrier/share/public/${params.carrierListShareToken}`;
    if (startIdx !== '') {
      baseRequest = baseRequest.concat(`?startIndex=${startIdx}`);
    }
    const response: Promise<AxiosResponse<CarrierShipmentListResponseDto>> = axios.get(baseRequest);
    return response;
  };

  useEffect(() => {
    const getCarrierShipments = async () => {
      dispatch(brandThemingActions.fetch.success(defaultTheme));
      try {
        setCurrentLoadingStatus(LoadingStatus.LOADING);
        const response: AxiosResponse<CarrierShipmentListResponseDto> = await getPublicShareShipment('');

        setCompanyName(response.data?.companyName || '');
        setCarrierShipments(response.data?.shipmentsDto || []);
        setCurrentLoadingStatus(LoadingStatus.LOADED);
        setTotalResults(response.data?.totalResults);
      } catch (e) {
        const error = e as AxiosError;
        const responseStatus = error.response?.status;
        const isAnInvalidTokenError = responseStatus && [410, 400].includes(responseStatus);
        if (isAnInvalidTokenError) {
          setCurrentLoadingStatus(LoadingStatus.INVALID_TOKEN_ERROR);
        } else {
          setCurrentLoadingStatus(LoadingStatus.GENERIC_ERROR);
        }
      }
    };
    if (params.carrierListShareToken) {
      getCarrierShipments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, intl, params.carrierListShareToken]);

  const noCarrierShipments = () =>
    currentLoadingStatus === LoadingStatus.LOADED && companyName && isEmpty(carrierShipments);

  useEffect(() => {
    async function fetchPublicShareShipmentData() {
      try {
        if (startIndex !== 0) {
          const response: AxiosResponse<CarrierShipmentListResponseDto> = await getPublicShareShipment(
            startIndex.toString()
          );
          setCarrierShipments(carrierShipments.concat(response.data?.shipmentsDto) || []);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchPublicShareShipmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startIndex]);

  const handleMoreResults = () => {
    setStartIndex((prevstate) => prevstate + 25);
  };

  const shouldDisplayMoreResultsButton = () => {
    if (!carrierShipments.length || !totalresults) return null;
    const remainingResults = totalresults - carrierShipments.length;
    if (!remainingResults) return null;
    return (
      <CustomButton classes="medium full-width" clickFn={handleMoreResults}>
        <FormattedMessage id="shipmentList.loadMoreButton.loadMore" defaultMessage="Load More" />
      </CustomButton>
    );
  };

  return (
    <div>
      {currentLoadingStatus === LoadingStatus.INVALID_TOKEN_ERROR && (
        <ErrorMessage
          data-locator="carrier-shipment-list-invalid-error"
          message={intl.formatMessage({
            id: 'carrierShipmentList.equipmentIdentifiers.errorInvalidToken',
            defaultMessage: 'This page is no longer valid',
          })}
        />
      )}
      {currentLoadingStatus === LoadingStatus.GENERIC_ERROR && (
        <ErrorMessage
          data-locator="carrier-shipment-list-generic-error"
          message={intl.formatMessage({
            id: 'carrierShipmentList.equipmentIdentifiers.genericError',
            defaultMessage: 'Oops, something went wrong',
          })}
        />
      )}

      {currentLoadingStatus === LoadingStatus.LOADED && companyName && (
        <SubHeader>
          <Row>
            <Col lg={3} className="d-none d-lg-block"></Col>
            <Col className={styles.subHeaderContainer}>
              <div>
                <h1 data-locator="carrier-shipment-list-company-name">
                  <FormattedMessage
                    id="carrierShipmentList.tenantShipment"
                    defaultMessage="{companyName} Shipments"
                    values={{ companyName: companyName }}
                  />
                </h1>
              </div>
            </Col>
          </Row>
        </SubHeader>
      )}
      <Container className={styles.listContainer}>
        {currentLoadingStatus === LoadingStatus.LOADING && (
          <Progress isLoading={currentLoadingStatus === LoadingStatus.LOADING} />
        )}
        {currentLoadingStatus === LoadingStatus.LOADED &&
          (noCarrierShipments() ? (
            <div data-locator="no-carrier-shipments-message" className={styles.title}>
              <h4>
                <FormattedMessage id="carrierShipmentList.noCarrierShipments" defaultMessage="You are all set!" />
              </h4>
            </div>
          ) : (
            <Row>
              <Col sm={3} className="d-none d-sm-flex"></Col>
              <Col xs={24} lg={18}>
                <div className={styles.title}>
                  <h4 data-locator="number-of-carrier-shipments">
                    <FormattedMessage
                      id="carrierShipmentList.truckNumberRequired"
                      defaultMessage="{numberOfIdenfiers} Equipment Identifiers Needed"
                      values={{ numberOfIdenfiers: totalresults }}
                    />
                  </h4>
                  <span>
                    <FormattedMessage
                      id="carrierShipmentList.subtitle"
                      defaultMessage="Provide an equipment identifier for each shipment so your customer can initiate tracking"
                    />
                  </span>
                </div>

                {carrierShipments.map((shipment) => (
                  <CarrierShipmentListCard
                    key={`carrier-list-card-${shipment.shipmentId}`}
                    resultItem={shipment}
                    carrierListShareToken={params.carrierListShareToken}
                  />
                ))}
                {shouldDisplayMoreResultsButton()}
              </Col>
            </Row>
          ))}
      </Container>
    </div>
  );
};

export default CarrierShipmentList;
