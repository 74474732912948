import axios from 'axios';
import { fork, put, takeEvery, select } from 'redux-saga/effects';
import { API_PATH } from '../../../../common/AppConstants';
import types from './types';
import * as actions from './actions';
import * as hereMapsActions from '../../../../common/typedHereMaps/ducks/actions';
import { mapShipmentDetails } from './mappers/shipmentDetailMapper';
import * as brandThemingActions from '../../../settings/BrandTheming/ducks/actions';
import mapThemeResponse from '../../../settings/BrandTheming/utils/mapThemeResponse';

// Get shipment details operations

export const fetchShipmentData = (shipmentId) => {
  return axios({
    method: 'GET',
    url: `${API_PATH}/shipment/search/OCEAN/${shipmentId}`,
    withCredentials: true,
  });
};

export function* fetchShipmentDataAsync({ id, shipmentModel }) {
  try {
    const shipmentResponse = yield fetchShipmentData(id);
    const mappedShipment = mapShipmentDetails(shipmentResponse.data, shipmentModel);

    yield put(actions.shipmentDetailsSuccess(mappedShipment));
  } catch (error) {
    yield put(actions.shipmentDetailsFailure(error));
  }
}

export function* watchFetchShipmentData() {
  yield takeEvery(types.FETCH_SHIPMENT_DATA, fetchShipmentDataAsync);
}

// Get public shipment details operations

export const fetchPublicShipmentData = (shipmentShareToken) => {
  return axios({
    method: 'GET',
    url: `${API_PATH}/shipment/share/public/${shipmentShareToken}`,
    withCredentials: true,
  });
};

export function* fetchPublicShipmentDataAsync({ shipmentShareToken, shipmentModel }) {
  const isLoggedIn = yield select((state) => state.loginReducer.isLoggedIn);
  try {
    const shipmentResponse = yield fetchPublicShipmentData(shipmentShareToken);
    const { shipment, brandingDto, hereMapsConfig } = shipmentResponse.data;
    if (hereMapsConfig && hereMapsConfig.hereMapsCode && hereMapsConfig.hereMapsId) {
      yield put(hereMapsActions.fetch.success(hereMapsConfig));
    }
    const mappedShipment = mapShipmentDetails(shipment, shipmentModel);
    if (!isLoggedIn) {
      yield put(brandThemingActions.fetch.success(mapThemeResponse(brandingDto)));
    }
    yield put(actions.publicShipmentDetailsSuccess(mappedShipment));
  } catch (error) {
    if (!isLoggedIn) {
      yield put(brandThemingActions.fetch.failure());
    }
    yield put(actions.publicShipmentDetailsFailure(error));
  }
}

export function* watchFetchPublicShipmentData() {
  yield takeEvery(types.FETCH_PUBLIC_SHIPMENT_DATA, fetchPublicShipmentDataAsync);
}

// Watch operation list

export default function* ltlShipmentDetailsOperations() {
  yield fork(watchFetchShipmentData);
  yield fork(watchFetchPublicShipmentData);
}
