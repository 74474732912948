import get from 'lodash/get';
import reduce from 'lodash/reduce';
import omit from 'lodash/omit';
import FilterUtil from 'components/filter/util/filterUtil';

import { Nullable } from 'typesUtils';

import { HealthRanges, HealthScoreConfig, TitleAndSettingsForm } from '../types';
import {
  HEALTH_RANGES,
  QUICKVIEW_SUBTOTAL_FILTERS,
  QUICKVIEW_SUBTOTAL_MODE,
  QUICKVIEW_TOTAL_FILTERS,
  QUICKVIEW_TOTAL_MODE,
  QuickviewEntityType,
} from '../constants';
import { initialFormValues } from './constants';
import { parseCountToPercent } from '../utils';

type HealthRangeKey = keyof typeof HEALTH_RANGES;

export const getHealthRange = (
  { isEnabled, ranges, numberFormat }: HealthScoreConfig,
  subTotal: Nullable<number>,
  total: Nullable<number>
): HealthRangeKey | undefined => {
  if (isEnabled && subTotal) {
    const count = numberFormat === 'PERCENTAGE' && total ? parseCountToPercent(subTotal, total) : subTotal;
    const rangesEntries = Object.entries(ranges);

    const classifiedRanges = rangesEntries.reduce<{
      endRanges: Array<HealthRangeKey>;
      middleRanges: Array<HealthRangeKey>;
    }>(
      (result, [key, value]) => {
        const isEndRange = !value.lowLimit || !value.highLimit;
        if (isEndRange) {
          result.endRanges.push(key as HealthRangeKey);
        } else {
          result.middleRanges.push(key as HealthRangeKey);
        }
        return result;
      },
      { endRanges: [], middleRanges: [] }
    );

    const middleRangeMatched = classifiedRanges.middleRanges.find((target) => {
      const range = ranges[target as HealthRangeKey];
      return range.isEnabled && count >= (range.lowLimit as number) && count <= (range.highLimit as number);
    });

    const endRangeMatched = classifiedRanges.endRanges.find((target) => {
      const range = ranges[target as HealthRangeKey];
      const isLower = !!range.lowLimit;
      return range.isEnabled && (isLower ? count <= (range.lowLimit as number) : count >= (range.highLimit as number));
    });

    return middleRangeMatched || endRangeMatched;
  }
};

interface ServerHealthRange {
  enabled: boolean;
  from: Nullable<number>;
  to: Nullable<number>;
  type: string;
}

export const deserializeHealthScoreConfig = (healtRanges: Array<ServerHealthRange>): HealthRanges => {
  return healtRanges.reduce((result: any, { enabled, from, to, type }) => {
    const rangeKey = type.toLowerCase();
    return Object.assign(result, {
      [rangeKey]: {
        isEnabled: enabled,
        lowLimit: from,
        highLimit: to,
      },
    });
  }, {});
};

export const deserializeEditTile = ({ healthScoreConfig, title, numberFormat }: any): TitleAndSettingsForm => {
  return {
    title,
    tileNumberFormat: numberFormat,
    healthScoreConfig: {
      isEnabled: healthScoreConfig.enabled,
      numberFormat: healthScoreConfig.numberFormat,
      ranges: healthScoreConfig.ranges.length
        ? deserializeHealthScoreConfig(healthScoreConfig.ranges)
        : initialFormValues.healthScoreConfig.ranges,
    },
  };
};

const mapFilter = (filter: any) => {
  const oceanDemurrageEligibleFilter = get(filter, 'oceanDemurrageEligible');
  const oceanDemurrageEligible = FilterUtil.getOceanDemurrageEligibleFilterValue(oceanDemurrageEligibleFilter);

  return {
    ...omit(filter, ['pickupDate', 'deliveryDate', 'lastFreeDate']),
    oceanDemurrageEligible: oceanDemurrageEligible,
  };
};

export const serializeHealthScoreConfig = ({ isEnabled, numberFormat, ranges }: HealthScoreConfig) => {
  const nextRanges = Object.entries(ranges).map(([key, value]) => {
    return {
      enabled: value.isEnabled,
      type: key.toUpperCase(),
      from: value.lowLimit,
      to: value.highLimit,
    };
  });

  return {
    enabled: isEnabled,
    numberFormat: numberFormat,
    ranges: nextRanges,
  };
};

export const getNextYPosition = (baseFilterPreferences: any) => {
  const rowCounter = reduce(
    baseFilterPreferences,
    (sum, item) => {
      return sum + item.width;
    },
    0
  );

  return Math.floor(rowCounter / 6);
};

export class UserDetails {
  createdById: number | undefined;
  tenantId: number | undefined;
  constructor(tenantId: number | undefined, userId: number | undefined) {
    this.createdById = userId;
    this.tenantId = tenantId;
  }
}

export const serializeTile = (
  filterPreferences: any,
  formValues: TitleAndSettingsForm,
  entityType: QuickviewEntityType,
  currentTile: any,
  userDetails: UserDetails,
  editTile?: any
) => {
  const storedSubTotalFilter = sessionStorage.getItem(QUICKVIEW_SUBTOTAL_FILTERS);
  const storedTotalFilter = sessionStorage.getItem(QUICKVIEW_TOTAL_FILTERS);
  const subTotalFilter = storedSubTotalFilter && JSON.parse(storedSubTotalFilter);
  const totalFilter = storedTotalFilter && JSON.parse(storedTotalFilter);
  const nextYPosition = getNextYPosition(filterPreferences);

  // TODO: Properties `xPosition`, `yPosition`, `width`, `icon` should be replaced
  // in a future ticket
  let newFilterPreference = {
    ...userDetails,
    ...(editTile.id && { id: editTile.id }),
    ...(editTile.version && { version: editTile.version }),
    title: formValues.title,
    numberFormat: formValues.tileNumberFormat,
    healthScoreConfig: serializeHealthScoreConfig(formValues.healthScoreConfig),
    xPosition: editTile.xPosition || 0,
    yPosition: editTile.yPosition || nextYPosition,
    width: 2,
    icon: 'genericIcon',
    mode: sessionStorage.getItem(QUICKVIEW_SUBTOTAL_MODE),
    totalMode: sessionStorage.getItem(QUICKVIEW_TOTAL_MODE),
    pickupDate: currentTile.pickupDate,
    deliveryDate: currentTile.deliveryDate,
    lastFreeDate: currentTile.lastFreeDate,
    totalPickupDate: currentTile.totalPickupDate,
    totalDeliveryDate: currentTile.totalDeliveryDate,
    totalLastFreeDate: currentTile.totalLastFreeDate,
  };

  if (entityType === QuickviewEntityType.SHIPMENTS) {
    newFilterPreference = {
      ...newFilterPreference,
      filter: mapFilter(subTotalFilter),
      totalFilter: totalFilter && mapFilter(totalFilter),
    };
  } else if (entityType === QuickviewEntityType.ORDERS) {
    newFilterPreference = {
      ...newFilterPreference,
      orderFilter: subTotalFilter,
      totalOrderFilter: totalFilter,
    };
  } else if (entityType === QuickviewEntityType.INVENTORY) {
    newFilterPreference = {
      ...newFilterPreference,
      inventoryFilter: subTotalFilter,
      totalInventoryFilter: totalFilter,
    };
  }

  return [...filterPreferences, newFilterPreference];
};

export const getStoredNumber = (key: string) => {
  const storedValue = sessionStorage.getItem(key);
  return storedValue !== null ? Number(storedValue) : null;
};
