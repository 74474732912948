import { Vessel } from '../../models/Vessel';
import * as styles from './sideMenu.module.scss';

interface PortCongestionSideMenuProps {
  vessels?: Vessel[];
}

const PortCongestionSideMenu = ({ vessels = [] }: PortCongestionSideMenuProps) => {
  return (
    <aside className={styles.asideContainer}>
      <h1 className={styles.asideTitle}>Anchored vessels near USLAX/USLGB</h1>
      <ul className={styles.asideBullets}>
        <li>
          <b>Average port dwell (days):</b> 9.25
        </li>
        <li>
          <b>Average vessel dwell (days):</b> 5.2
        </li>
        <li>
          <b>Number of vessels anchored:</b> {vessels.length}
        </li>
      </ul>
      <br></br>
      <hr></hr>
      <h2 className={styles.asideTitle}>Vessels anchored:</h2>
      <ul className={styles.asideBullets}>
        {vessels?.map((vessel: Vessel) => {
          return <li key={vessel.vesselId}>{vessel.meta.name}</li>;
        })}
      </ul>
    </aside>
  );
};

export default PortCongestionSideMenu;
