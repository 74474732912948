import qs from 'querystring';
import * as React from 'react';
import get from 'lodash/get';
import head from 'lodash/head';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SubHeader, Button } from 'ui-components';
import { trackEvent } from 'common/eventTracker';
import useDebounce from 'hooks/useDebounce';
import { FILTER_RESULT_DELAY, SNACKBAR_AUTOHIDE_DURATION } from 'common/AppConstants';
import ListItem from './ListItem';

import { getPrincipalAuthorizations } from '../../../common/authorizations';
import SortResultsContainer from '../../common/sortResults/SortResultsContainer';
import SearchContainer from '../../common/search/SearchContainer';
import sortTypes from '../../common/sortResults/ducks/types';
import searchTypes from '../../common/search/ducks/types';
import earthIcon from './../../common/assets/earth.svg';
import Progress from '../../common/progress/Progress';
import CustomButton from '../../../themes/CustomButton';
import './LocationListComponent.scss';
import { withTheme, WithThemeProps } from '../../../contexts/ThemeContext';
import { PrincipalContext } from '../../../contexts/PrincipalContext';
import useSuggestions from '../../../components/filter/util/useSuggestions';
import { SUGGESTIONS_MAP, LOCATION_SEARCH_SUGGESTIONS_PREFIX } from '../../../components/filter/ducks/types';
import { handleAutocompleteUpdate, toggleShowSelections } from '../../../components/filter/ducks/actions';

const shouldDisplayMoreResultsButton = (props: LocationListProps) => {
  if (!props.locations || !props.totalResults) {
    return null;
  }

  if (props.locations && props.totalResults) {
    const remainingResults = props.totalResults - props.locations.length;

    if (!remainingResults) {
      return null;
    }

    return (
      <CustomButton
        classes="muted medium full-width"
        // eslint-disable-next-line react/jsx-no-bind
        clickFn={() =>
          props.getLocations(props.locations.length, { sortBy: props.sortBy, searchPhrase: props.searchTerm })
        }
      >
        <FormattedMessage id="locationListComponent.loadMore" defaultMessage="Load More" />
      </CustomButton>
    );
  }

  return '';
};

interface LocationListProps extends WithThemeProps {
  initialLoading?: boolean;
  isLoading?: boolean;
  searchTerm: string;
  contactSearchTerm: string;
  totalResults: number;
  snackbarOpen: boolean;
  closeSnackbar: any;
  snackbarContent: any;
  locations: any[];
  getLocations: any;
  sortBy: any;
  selectedSortFieldId: number;
  setStateSearchTerm: any;
  setStateContactSearchTerm: any;
}

const LocationsList: React.FC<LocationListProps> = (props) => {
  const principalContext = React.useContext(PrincipalContext);
  const authorizations = getPrincipalAuthorizations(principalContext);
  const canCreateLocation = authorizations.hasLocationCreateAccess();
  const isLoading = props.initialLoading || props.isLoading;

  const [currentSearchTerm, setCurrentSearchTerm] = React.useState<React.SetStateAction<null | string>>(null);
  const [currentContactSearchTerm, setCurrentContactSearchTerm] =
    React.useState<React.SetStateAction<null | string>>(null);

  const [currentValue, setCurrentValue] = React.useState('');
  const debouncedValue = useDebounce(currentValue, FILTER_RESULT_DELAY);
  const modes = get(props, 'modeFilters', []);
  const isAllMode = isEqual(head(modes), 'ALL');
  const listData = get(props, 'filter.company', []);

  const params = qs.stringify(isAllMode || isEmpty(modes) ? { q: debouncedValue } : { modes, q: debouncedValue });

  const suggestedLocations = useSuggestions(SUGGESTIONS_MAP.contacts, params, {
    endpointBody: LOCATION_SEARCH_SUGGESTIONS_PREFIX,
  });

  const title = isLoading ? (
    <FormattedMessage id="locationsList.isLoadingMessage.searching" defaultMessage="Searching.." />
  ) : currentSearchTerm || currentContactSearchTerm ? (
    <FormattedMessage
      id="locationsList.totalResultsWithSearchTerm.resultsPluralForSearchTerm"
      defaultMessage="{totalResults} {totalResults,plural, one {Result} other {Results}} for {searchTerm}"
      values={{
        totalResults: props.totalResults,
        searchTerm:
          (currentSearchTerm ? '"' + currentSearchTerm + '"' : '') +
          (currentSearchTerm && currentContactSearchTerm ? ' and ' : '') +
          (currentContactSearchTerm ? '"' + currentContactSearchTerm + '"' : ''),
      }}
    />
  ) : (
    <FormattedMessage
      id="locationsList.totalResultsWithoutSearchTerm.resultsPlural"
      defaultMessage="{totalResults} {totalResults,plural, one {Result} other {Results}}"
      values={{ totalResults: props.totalResults }}
    />
  );

  return (
    <div className="locations-list">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={SNACKBAR_AUTOHIDE_DURATION}
        open={props.snackbarOpen}
        onClose={props.closeSnackbar}
        className="snackbar-success snackbar-container"
        action={[
          <IconButton key="close" aria-label="Close" onClick={props.closeSnackbar}>
            <i className="material-icons">close</i>
          </IconButton>,
        ]}
        message={
          <span className="snack-message">
            <i className="material-icons">thumb_up</i> {props.snackbarContent}
          </span>
        }
      />
      <SubHeader>
        <div className="header-wrapper justify-content-between">
          <FormattedMessage id="locationsList.locationsListHeading.locations" defaultMessage="Locations" tagName="h1" />

          {canCreateLocation && (
            <Link
              to={'location-details/new'}
              onClick={() => {
                trackEvent('LOCATIONS_LIST_NEW_CLICK');
              }}
            >
              <Button type="primary" size="lg" theme={props.theme}>
                <FormattedMessage id="locationsList.locationsListHeading.newLocation" defaultMessage="New Location" />
              </Button>
            </Link>
          )}
        </div>
      </SubHeader>
      <section className="locations-results container section-content">
        <Row>
          <Col sm={6}>
            <SearchContainer
              searchConfig={searchTypes.SEARCH_LOCATION_CONFIG}
              setStateSearchTerm={(value: string): void => {
                props.setStateSearchTerm(value);
                setCurrentSearchTerm(value);
              }}
              setStateContactSearchTerm={(value: string): void => {
                props.setStateContactSearchTerm(value);
                setCurrentContactSearchTerm(value);
              }}
              suggestedLocations={suggestedLocations}
              setCurrentValue={setCurrentValue}
              listData={listData}
              handleAutocompleteUpdate={handleAutocompleteUpdate}
              toggleShowSelections={toggleShowSelections}
            />
          </Col>

          <Col>
            <div className="results-row d-flex flex-row">
              <div className="results-summary">{title}</div>
              <div className="results-sort">
                <SortResultsContainer
                  sortFields={sortTypes.LOCATION_SORT_FIELDS}
                  sortType={sortTypes.LOCATION_LIST_SORT}
                  selectedSortFieldId={props.selectedSortFieldId}
                />
              </div>
            </div>
            {!props.locations.length && !isLoading && (
              <div className="no-results-message col-24">
                <h4>
                  <FormattedMessage
                    id="locationListComponent.noResultsMessage"
                    defaultMessage="There are no locations with this criteria."
                  />
                </h4>
                <img src={earthIcon} className="no-results-image" alt="no results" />
                <p className="medium-text">
                  <FormattedMessage
                    id="locationListComponent.tryNewSearch"
                    defaultMessage="Please try a new search or add a new location."
                  />
                </p>
              </div>
            )}
            {isLoading && (
              <div className="col-24">
                <Progress isLoading={isLoading} />
              </div>
            )}
            {!isLoading && (
              <div>
                {props.locations.map((location: any) => (
                  <ListItem key={location.location.id} locationData={location} />
                ))}
              </div>
            )}
            {shouldDisplayMoreResultsButton(props)}
          </Col>
        </Row>
      </section>
    </div>
  );
};

LocationsList.propTypes = {
  locations: PropTypes.array.isRequired,
};

export default withTheme(LocationsList);
