import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { PrincipalContextProps } from 'contexts/PrincipalContext';
import { ThemeContext } from 'contexts/ThemeContext';
import endpoints from 'common/endpoints';
import p44Logo from '../assets/p44-logo-white.svg';
import poweredByP44Logo from '../assets/powered-by-p44.svg';
import FooterLinksWrapper from './links/FooterLinksWrapper';
import './Footer.scss';

const messages = defineMessages({
  logo: {
    id: 'footer.imgAltText.logo',
    defaultMessage: 'logo',
  },
  haveAnIssue: {
    id: 'footer.footerHeader.haveAnIssue',
    defaultMessage: 'Have an Issue?',
  },
});

/**
 * Footer component
 **/
interface FooterComponentProps {
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  principal: PrincipalContextProps;
  error: any;
}
const Footer: React.FC<FooterComponentProps> = (props) => {
  const intl = useIntl();
  const context = React.useContext(ThemeContext);

  const showContactColumn = () => {
    return (
      <Col as={'ul'} xs={24} sm={12} md className={`footer-column`}>
        <li>{intl.formatMessage(messages.haveAnIssue)}</li>
        <li>
          <FormattedMessage id="footer.links.contactUsAt" defaultMessage="Contact us at" />
          &nbsp;
          <a href="mailto:support@project44.com">support@project44.com</a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href={endpoints.SUPPORT_PORTAL}>
            <FormattedMessage id="footer.links.goToSupportPortal" defaultMessage="Go To Support Portal" />
          </a>
        </li>
      </Col>
    );
  };

  // static contextType = ThemeContext;
  const containerSize = props.isLoggedIn ? 'footer-container' : 'footer-container-public';
  const logoContainer = props.isLoggedIn ? 'footer-logo-private' : 'footer-logo-public';

  return (
    <footer
      id="footer"
      className={`contrast-background ${containerSize} ${!props.isLoggedIn && 'pt-5 pb-5'}`}
      style={context && !props.isLoggingIn ? { backgroundColor: context.footerColor } : {}}
    >
      <Container>
        <Row className="jest">
          <Col
            className={`d-flex align-self-center justify-content-center ${logoContainer} ${
              !props.isLoggedIn && 'mb-5'
            }`}
          >
            <img
              src={context && !context.isDefaultLogo && !props.isLoggingIn ? poweredByP44Logo : p44Logo}
              alt={intl.formatMessage(messages.logo)}
            />
          </Col>
          <FooterLinksWrapper isLoggedIn={props.isLoggedIn} principal={props.principal} />

          {props.isLoggedIn && showContactColumn()}
        </Row>

        <Row className="jest">
          <Col as={'ul'} className="legal-list">
            <li>&copy; {new Date().getFullYear()} project44</li>
            <li>
              <a href="https://project44.com/privacy-policy/" title="Privacy Policy">
                <FormattedMessage id="footer.links.privacyPolicy" defaultMessage="Privacy Policy" />
              </a>
            </li>
            <li>
              <a href="https://project44.com/terms/" title="Terms & Conditions">
                <FormattedMessage id="footer.links.termsAndConditions" defaultMessage="Terms & Conditions" />
              </a>
            </li>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
