import Col from 'react-bootstrap/Col';
import get from 'lodash/get';
import { Select as ModeSelect } from 'ui-components';

const ModeFilter = (props) => {
  const selectedValue = get(props, 'modeFilters[0]', 'ALL');
  const menuItems = get(props, 'modeFilterOptions', []);

  return (
    <Col>
      <label htmlFor="modeFilterSelect">MODE</label>
      <ModeSelect
        id="modeFilterSelect"
        placeholder="Filter by Mode"
        value={selectedValue}
        onChange={(selection) => props.toggleModeFilterSelectOneFn(selection)}
        dataSource={menuItems}
      />
    </Col>
  );
};

export default ModeFilter;
