import { Chart } from 'react-google-charts';

const LineChart = ({ chartEvents, dataSource, height }) => {
  return (
    <Chart
      chartType="LineChart"
      data={dataSource.data}
      options={dataSource.chartOptions}
      width="100%"
      height={height}
      chartEvents={chartEvents(dataSource)}
    />
  );
};

export default LineChart;
