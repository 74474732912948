import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'ui-components';

import './NotificationDialogComponent.scss';

export const NotificationDialogComponent = (props) => {
  return (
    <Dialog
      className="notification-dialog"
      fullScreen={props.fullScreen}
      open={props.open}
      onClose={props.closeDialog}
      aria-labelledby="responsive-dialog-title"
      id="notification-dialog"
    >
      <DialogTitle id="responsive-dialog-title">
        {props.dialogTitle}
        <div className="close-dialog-icon">
          {/* eslint-disable-next-line */}
          <i className="material-icons" onClick={props.closeDialog}>
            close
          </i>
        </div>
      </DialogTitle>
      <DialogContent className="notification-dialog-content-container">{props.children}</DialogContent>
      <DialogActions className="notification-dialog-actions">
        {props.cancelButton && (
          <Button type="secondary" clickFn={props.closeDialog}>
            Cancel
          </Button>
        )}
        <Button type="primary" clickFn={props.actionFn} loading={props.loading}>
          {props.actionText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NotificationDialogComponent.propTypes = {
  actionText: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  cancelButton: PropTypes.bool,
  actionFn: PropTypes.func.isRequired,
  dialogTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  closeDialog: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  open: PropTypes.bool,
};

export default withMobileDialog()(NotificationDialogComponent);
