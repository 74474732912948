import { useState, useEffect, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import qs from 'qs';
import { SortField } from 'models';
import OrdersTable from 'components/orders/OrdersTable';

import { PrincipalContext } from 'contexts/PrincipalContext';
import { useInventoryOrders } from 'components/orders/hooks/useInventoryOrders';
import ListPageLayout, { SortItem } from 'components/common/ListPageLayout/ListPageLayout';
import { SortDirection } from 'models/SortDirection';
import { orderInventoryListFiltersUrlEnabled } from 'common/authorizations';
import { trackEvent } from 'common/eventTracker';
import OrdersExport from './OrdersExport';
import EncapsulatedOrdersFilters from './EncapsulatedOrdersFilters';
import { useOrderFeatureFlags } from './utils/OrderFeatureFlags';

const ORDERS_ENTITY_TYPE = 'ORDERS';

export const formatCriteriaFilter = (value: any) => {
  if (isEmpty(value)) return undefined;

  return value.reduce((acc: any[], v: any) => {
    const type = v.split(':')[0];
    const value = v.split(':')[1];

    return [...acc, { type, value }];
  }, []);
};

export const getDefaultTypeValues = (filterName: string, data: any) => {
  const filterValues = data[filterName];

  if (!filterValues) return [];

  return filterValues.map((value: any) => `${value.type}:${value.value}`);
};

const OrdersList = (props: any) => {
  const shouldUseUrlFilter = orderInventoryListFiltersUrlEnabled();

  const intl = useIntl();

  const principalContext = useContext(PrincipalContext);

  const orderFeatureFlags = useOrderFeatureFlags(principalContext);

  const [currentPage, setCurrentPage] = useState(1);

  const parsedSearch = qs.parse(props.history.location.search.replace('?', ''));
  const urlFilter = shouldUseUrlFilter ? omit(parsedSearch, 'searchText') : {};
  const searchText = get(parsedSearch, 'searchText', '') as string;
  const isMultiValue = (get(parsedSearch, 'isMultiValue', '') as string) === 'true';
  // TODO keeping this as React state causes the Back button to not work after applying a filter
  const [filter, setFilter] = useState(urlFilter || {});

  // sorting
  const [sortAttr, setSortAttr] = useState(0);
  const [sortArr, setSortArr] = useState<SortField<string>[]>([]);

  const sortItems: SortItem[] = [
    {
      displayName: '',
      fieldName: '',
      sortDirection: SortDirection.DESC,
    },
    {
      displayName: intl.formatMessage({
        id: 'orderList.sort.orderSubmissionDateTime.asc',
        defaultMessage: 'Original Submission Date (Earliest)',
      }),
      fieldName: 'orderSubmissionDateTime',
      sortDirection: SortDirection.ASC,
    },
    {
      displayName: intl.formatMessage({
        id: 'orderList.sort.orderSubmissionDateTime.desc',
        defaultMessage: 'Original Submission Date (Latest)',
      }),
      fieldName: 'orderSubmissionDateTime',
      sortDirection: SortDirection.DESC,
    },
    {
      displayName: intl.formatMessage({
        id: 'orderList.sort.originalDeliveryDateTimeWindow.endDateTime.asc',
        defaultMessage: 'Original Due Date (Earliest)',
      }),
      fieldName: 'originalDeliveryDateTimeWindow.endDateTime',
      sortDirection: SortDirection.ASC,
    },
    {
      displayName: intl.formatMessage({
        id: 'orderList.sort.originalDeliveryDateTimeWindow.endDateTime.desc',
        defaultMessage: 'Original Due Date (Latest)',
      }),
      fieldName: 'originalDeliveryDateTimeWindow.endDateTime',
      sortDirection: SortDirection.DESC,
    },
    {
      displayName: intl.formatMessage({
        id: 'orderList.sort.associatedShipmentStatuses.estimatedTimeOfArrival.asc',
        defaultMessage: 'Order Delivery ETA (Earliest)',
      }),
      fieldName: 'derivedOrderHealth.estimatedTimeOfArrival',
      sortDirection: SortDirection.ASC,
    },
    {
      displayName: intl.formatMessage({
        id: 'orderList.sort.associatedShipmentStatuses.estimatedTimeOfArrival.desc',
        defaultMessage: 'Order Delivery ETA (Latest)',
      }),
      fieldName: 'derivedOrderHealth.estimatedTimeOfArrival',
      sortDirection: SortDirection.DESC,
    },
  ];

  const { orders, isLoading } = useInventoryOrders({
    hasPermission: true,
    defaultPageSize: 30,
    searchText: searchText,
    filters: JSON.stringify(filter),
    page: currentPage,
    sort: sortArr,
    isMultiValue,
  });

  const breadcrumbComponent = <FormattedMessage id="inventory.ordersList.breadCrumb.orders" defaultMessage="Orders" />;

  useEffect(() => {
    const searchObject = searchText
      ? {
          ...filter,
          // if you change this variable, also update blacklistTransform() in configureStore.js
          searchText: searchText,
        }
      : filter;
    // only update URL if query params differ from current ones. qs.stringify handles edge cases like a key with an empty
    // array
    const newSearchDiffersFromCurrentSearch = !isEqual(qs.stringify(searchObject), qs.stringify(parsedSearch));
    // need to use isEqual() or similar here since X !== {} will return true for all x, including {} ({} !== {})
    if (!isEqual(searchObject, {}) && shouldUseUrlFilter && newSearchDiffersFromCurrentSearch) {
      props.history.push({
        search: qs.stringify(searchObject),
        // if we don't copy this, we will lose state and introduce bugs where searching causes search input to be lost.
        // see VORD-1152 for an example of such behavior. pushing this data into Redux and omitting use of location
        // state is an option, but there are generically named
        state: props.history.location.state,
      });
    }
  }, [filter, props.history, shouldUseUrlFilter, searchText, parsedSearch]);

  const filterComponent = <EncapsulatedOrdersFilters filter={filter} setFilter={setFilter} />;

  const countTitleComponent = (
    <FormattedMessage
      id="inventory.ordersList.header"
      defaultMessage="{count} Orders"
      values={{ count: get(orders, 'paginationInfo.total') }}
    />
  );

  const tableComponent = (
    <OrdersTable
      dataResults={orders?.results}
      isLoading={isLoading}
      onPageChangeFn={(page) => {
        trackEvent('ORDER_LIST_PAGINATE', {
          page: page.toString(),
        });
        setCurrentPage(page);
      }}
      totalResults={orders?.paginationInfo?.total}
      featureFlags={orderFeatureFlags}
    />
  );

  const exportComponent = (
    <OrdersExport
      filter={filter}
      sortItems={sortItems}
      sortAttr={sortAttr}
      ordersCount={get(orders, 'paginationInfo.total')}
      searchText={searchText}
    />
  );

  return (
    <ListPageLayout
      entityType={ORDERS_ENTITY_TYPE}
      breadcrumbComponent={breadcrumbComponent}
      filterComponent={filterComponent}
      countTitleComponent={countTitleComponent}
      exportComponent={exportComponent}
      tableComponent={tableComponent}
      sortItems={sortItems}
      sortAttr={sortAttr}
      setSortAttr={setSortAttr}
      setSortArr={setSortArr}
    />
  );
};

export default OrdersList;
