import get from 'lodash/get';
import isNil from 'lodash/isNil';
import uniqueWith from 'lodash/uniqWith';
import round from 'lodash/round';
import { defineMessages, IntlShape } from 'react-intl';

import {
  Identifier,
  Shipment,
  ShipmentIdentifierIntlKeys,
  ShipmentIdentifierTypeEnum,
  ShipmentETAStatusColor,
  ShipmentETAStatusIntlKeys,
  ShipmentStop,
  ShipmentEvent,
  ShipmentStopTypeEnum,
  ShipmentTenantAttribute,
  ShipmentStatusReasonCodeEnum,
  ShipmentStatusReasonCodeIntlText,
  TenantCustomAttribute,
  ShipmentModeEnum,
  ShipmentDerivedStatus,
  ShipmentDerivedStatusIntlKeys,
  ParcelDerivedStatusEnum,
  ShipmentStatusCode,
  ShipmentStatusCodeIntlKeys,
  ShipmentETAStatusEnum,
  CommonStatusCodeEnum,
  RailDerivedStatusEnum,
  OceanDerivedStatusEnum,
  ShipmentLeg,
  ShipmentStopStatusEnum,
  UnifiedModelStatusCodeEnum,
  DateTimeWithTimezone,
  CodeInlItemEnum,
  EuropeCarrierEquipmentIdsEnum,
  NorthAmericaCarrierEquipmentIdsEnum,
  EquipmentIdentifierTypeEnum,
} from 'models';
import { Location, MarkerType } from '../../common/mapRoute/models/Location';

const message = defineMessages({
  acquiring: {
    id: 'shipmentDetails.stops.acquiring',
    defaultMessage: 'Acquiring',
  },
  origin: {
    id: 'shipmentDetails.stops.origin',
    defaultMessage: 'Origin',
  },
  destination: {
    id: 'shipmentDetails.stops.destination',
    defaultMessage: 'Destination',
  },
  oceanDestination: {
    id: 'shipmentDetails.stops.portOfDischarge',
    defaultMessage: 'Port of Discharge',
  },
  defaultStopName: {
    id: 'shipmentDetails.collapsedCards.defaultStopName',
    defaultMessage: 'Stop',
  },
});

export const buildMapLocations = (shipment: Shipment): Location[] => {
  const locations: Location[] = [];

  // Add Positions
  shipment.legs.forEach((leg, legIndex) => {
    if (!isNil(leg.positions)) {
      leg.positions
        .filter((position) => !isNil(position.location))
        .forEach((pos) => {
          locations.push({
            latitude: pos.location.lat,
            longitude: pos.location.lng,
            markerType: MarkerType.PING,
          });
        });
    }
  });

  if (locations.length > 0) {
    locations[locations.length - 1] = {
      ...locations[locations.length - 1],
      markerType: MarkerType.CURRENT_LOCATION,
    };
  }

  // Add Stops
  shipment.legs.forEach((leg) => {
    leg.stops.forEach((stop) => {
      const latitude = get(stop, 'location.lat');
      const longitude = get(stop, 'location.lng');
      if (latitude && longitude) {
        locations.push({
          latitude,
          longitude,
          markerType: stop.type === 'DESTINATION' ? MarkerType.DESTINATION : MarkerType.STOP,
        });
      }
    });
  });

  return locations.filter((location) => !isNil(location.latitude) && !isNil(location.longitude));
};

export const getUnknownStopText = (stop: ShipmentStop, intl: IntlShape, mode?: ShipmentModeEnum): string => {
  const isOrigin = stop.type === ShipmentStopTypeEnum.ORIGIN;
  const isDestination = stop.type === ShipmentStopTypeEnum.DESTINATION;
  const isTransfer = stop.type === ShipmentStopTypeEnum.TRANSFER;
  const acquiringText = intl.formatMessage(message.acquiring);
  if (isOrigin) {
    return `${acquiringText} ${intl.formatMessage(message.origin)}`;
  } else if ((isDestination || isTransfer) && mode === ShipmentModeEnum.OCEAN) {
    return intl.formatMessage(message.oceanDestination);
  } else if (isDestination) {
    return `${acquiringText} ${intl.formatMessage(message.destination)}`;
  }
  return `${acquiringText} ${intl.formatMessage(message.defaultStopName)}`;
};

export const filterEuIdentifierTypes = (identifierTypes: EquipmentIdentifierTypeEnum[]) => {
  return identifierTypes.filter((equipId: any) => {
    return Object.keys(EuropeCarrierEquipmentIdsEnum).includes(equipId) === true;
  });
};

export const filterNaIdentifierTypes = (identifierTypes: EquipmentIdentifierTypeEnum[]) => {
  return identifierTypes.filter((equipId: any) => {
    return Object.keys(NorthAmericaCarrierEquipmentIdsEnum).includes(equipId) === true;
  });
};

export const getShipmentIdentifiersWithLabels = (shipmentIdentifiers: Identifier[], intl: IntlShape): Identifier[] => {
  const mappedIdentifers = shipmentIdentifiers
    .filter((identifier) => identifier.name || identifier.type || identifier.label)
    .map((identifier) => {
      if (!identifier.label) {
        const identifierType = (identifier.name || identifier.type) as ShipmentIdentifierTypeEnum;
        return { ...identifier, label: getShipmentIdentifierLabel(identifierType, intl) };
      }
      return identifier;
    });

  return uniqueWith(
    mappedIdentifers,
    (identifierOne: Identifier, identifierTwo: Identifier) =>
      identifierOne.value === identifierTwo.value && identifierOne.label === identifierTwo.label
  );
};

const getShipmentIdentifierLabel = (
  shipmentIdentifierTypeEnum: ShipmentIdentifierTypeEnum,
  intl: IntlShape
): undefined | string => {
  if (isNil(shipmentIdentifierTypeEnum)) {
    return undefined;
  }
  if (Object.keys(ShipmentIdentifierIntlKeys).includes(shipmentIdentifierTypeEnum)) {
    return intl.formatMessage(ShipmentIdentifierIntlKeys[shipmentIdentifierTypeEnum]);
  }
  return shipmentIdentifierTypeEnum.replace(/_/g, ' ');
};

const isShipmentCompleted = (status: ShipmentStatusCode | ShipmentDerivedStatus) => {
  return (
    OceanDerivedStatusEnum.COMPLETED === status ||
    [ParcelDerivedStatusEnum.DELIVERED, ParcelDerivedStatusEnum.CANCELLED].includes(
      status as ParcelDerivedStatusEnum
    ) ||
    [
      RailDerivedStatusEnum.COMPLETED_CANCELED,
      RailDerivedStatusEnum.COMPLETED_DEPARTED_STOP,
      RailDerivedStatusEnum.COMPLETED_TIMED_OUT,
    ].includes(status as RailDerivedStatusEnum) ||
    [
      UnifiedModelStatusCodeEnum.TRACKING_COMPLETE,
      UnifiedModelStatusCodeEnum.TRACKING_END_BY_USER,
      UnifiedModelStatusCodeEnum.TRACKING_END_DUE_TO_TIMEOUT,
    ].includes(status as UnifiedModelStatusCodeEnum)
  );
};

export const getStatusFlagInfo = (
  statusCode?: ShipmentStatusCode | ShipmentDerivedStatus,
  statusReasonCode?: string | ShipmentETAStatusEnum
): {
  flagText: { id: string; defaultMessage: string; values?: any };
  flagColor: string;
  shouldInvertFlagColor: boolean;
} => {
  /* Note: As of 11.2019, parcel is the only mode that uses this method,
      and the only eta/timeliness-status has is DELAYED */
  const isDelayed = statusReasonCode === ShipmentETAStatusEnum.DELAYED;
  const shipmentIsComplete: boolean =
    statusCode !== undefined &&
    (CommonStatusCodeEnum.COMPLETED === statusCode || isShipmentCompleted(statusCode as ShipmentDerivedStatus));

  const { flagText, flagColor } = getStatusFlagAndColor({ isDelayed, shipmentIsComplete });

  const shouldInvertFlagColor = shipmentIsComplete;
  return { flagText, flagColor, shouldInvertFlagColor };
};

const getStatusFlagAndColor = ({
  isDelayed,
  shipmentIsComplete,
}: {
  isDelayed: boolean;
  shipmentIsComplete: boolean;
}): { flagText: { id: string; defaultMessage: string; values?: any }; flagColor: string } => {
  let flagText;
  let flagColor = '';

  if (shipmentIsComplete) {
    flagText = ShipmentETAStatusIntlKeys[ShipmentETAStatusEnum.INACTIVE];
    flagColor = ShipmentETAStatusColor[ShipmentETAStatusEnum.INACTIVE];
  } else if (isDelayed) {
    flagText = ShipmentETAStatusIntlKeys[ShipmentETAStatusEnum.DELAYED];
    flagColor = ShipmentETAStatusColor[ShipmentETAStatusEnum.DELAYED];
  } else {
    flagText = ShipmentETAStatusIntlKeys[ShipmentETAStatusEnum.UNKNOWN];
    flagColor = ShipmentETAStatusColor[ShipmentETAStatusEnum.UNKNOWN];
  }

  return { flagText, flagColor };
};

export const getDerivedStatusText = (
  intl: IntlShape,
  status: ShipmentDerivedStatus | undefined,
  key: CodeInlItemEnum = CodeInlItemEnum.PAST
): string | undefined => {
  if (status === undefined) {
    return undefined;
  }
  if (Object.keys(ShipmentDerivedStatusIntlKeys).includes(status)) {
    const intlMessage = ShipmentDerivedStatusIntlKeys[status as ShipmentDerivedStatus];
    if (intlMessage) {
      if (key === CodeInlItemEnum.FUTURE && intlMessage[key]?.id) {
        const message = intlMessage[key] as object;
        return intl.formatMessage(message);
      } else if (intlMessage[CodeInlItemEnum.PAST] && intlMessage[CodeInlItemEnum.PAST]?.id) {
        return intl.formatMessage(intlMessage[CodeInlItemEnum.PAST]);
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
  return status.replace(/_/g, ' ');
};

export const getStatusCodeText = (
  intl: IntlShape,
  statusCode: ShipmentStatusCode | undefined,
  key: CodeInlItemEnum = CodeInlItemEnum.PAST
): string | undefined => {
  if (statusCode === undefined) {
    return undefined;
  }

  if (Object.keys(ShipmentStatusCodeIntlKeys).includes(statusCode)) {
    const intlMessage = ShipmentStatusCodeIntlKeys[statusCode as ShipmentStatusCode];
    if (intlMessage) {
      if (key === CodeInlItemEnum.FUTURE && intlMessage[key]?.id) {
        const message = intlMessage[key] as object;
        return intl.formatMessage(message);
      } else if (intlMessage[CodeInlItemEnum.PAST] && intlMessage[CodeInlItemEnum.PAST]?.id) {
        return intl.formatMessage(intlMessage[CodeInlItemEnum.PAST]);
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
  return statusCode.replace(/_/g, ' ');
};

export const getStatusReasonCodeText = (
  intl: IntlShape,
  statusReasonCode?: ShipmentStatusReasonCodeEnum | string
): string | undefined => {
  if (!statusReasonCode) {
    return undefined;
  }
  if (Object.keys(ShipmentStatusReasonCodeIntlText).includes(statusReasonCode)) {
    const intlMessage = ShipmentStatusReasonCodeIntlText[statusReasonCode as ShipmentStatusReasonCodeEnum];
    if (intlMessage && intlMessage.id) {
      return intl.formatMessage(intlMessage);
    } else {
      return undefined;
    }
  }
  return statusReasonCode.replace(/_/g, ' ');
};

export const mapCustomAttributesToShipmentTenantAttributes = (
  shipmentAttributes: TenantCustomAttribute[] = []
): ShipmentTenantAttribute[] => {
  if (!shipmentAttributes) {
    return [];
  }
  return shipmentAttributes.map((attribute) => {
    return {
      tenantAttributeName: attribute.attributeName,
      tenantAttributeValues: attribute.attributeValues,
    };
  });
};

export const getEventLocationText = (event: ShipmentEvent): string | undefined => {
  const city = get(event, 'address.city');
  const state = get(event, 'address.state');
  if (city && state) {
    return `${city}, ${state}`;
  } else if (city) {
    return city;
  } else if (state) {
    return state;
  }
};

export const getStopLocationText = (event: ShipmentStop): string | undefined => {
  const city = get(event, 'location.city');
  const state = get(event, 'location.state');
  if (city && state) {
    return `${city}, ${state}`;
  } else if (city) {
    return city;
  } else if (state) {
    return state;
  }
};

export const populateEventsWithTimezone = (
  allEvents: ShipmentEvent[],
  originStopTimezone: string,
  originStopTimezoneShort: string
): ShipmentEvent[] => {
  let fallbackTimezone = originStopTimezone;
  let fallbackTimezoneShort = originStopTimezoneShort;

  let fallbackTimezoneScheduled = originStopTimezone;
  let fallbackTimezoneScheduledShort = originStopTimezoneShort;

  let fallbackTimezoneEstimated = originStopTimezone;
  let fallbackTimezoneEstimatedShort = originStopTimezoneShort;

  return (allEvents || []).map((event) => {
    const eventTimezone = get(event, 'dateTime.timezone');
    const eventTimezoneShort = get(event, 'dateTime.timezoneShort', '');
    const estimatedTimezone = get(event, 'estimatedDateTime.timezone');
    const estimatedTimezoneShort = get(event, 'estimatedDateTime.timezoneShort', '');
    const scheduledTimeZone = get(event, 'scheduledDateTime.timezone');
    const scheduledTimeZoneShort = get(event, 'scheduledDateTime.timezoneShort', '');
    const datesEvent: ShipmentEvent = { ...event };
    if (!estimatedTimezone && event.estimatedDateTime) {
      datesEvent.estimatedDateTime = {
        ...event.estimatedDateTime,
        timezone: fallbackTimezoneEstimated,
        timezoneShort: fallbackTimezoneEstimatedShort,
      };
    } else if (estimatedTimezone) {
      fallbackTimezoneEstimated = estimatedTimezone;
      fallbackTimezoneEstimatedShort = estimatedTimezoneShort;
    }
    if (!scheduledTimeZone && event.scheduledDateTime) {
      datesEvent.scheduledDateTime = {
        ...event.scheduledDateTime,
        timezone: fallbackTimezoneScheduled,
        timezoneShort: fallbackTimezoneScheduledShort,
      };
    } else if (scheduledTimeZone) {
      fallbackTimezoneScheduled = scheduledTimeZone;
      fallbackTimezoneScheduledShort = scheduledTimeZoneShort;
    }
    if (!eventTimezone && event.dateTime) {
      datesEvent.dateTime = { ...event.dateTime, timezone: fallbackTimezone, timezoneShort: fallbackTimezoneShort };
    } else if (eventTimezone) {
      fallbackTimezone = eventTimezone;
      fallbackTimezoneShort = eventTimezoneShort;
    }
    return datesEvent;
  });
};

/**
 * This will return the last stop from the first leg it finds where the last stop has status EN_ROUTE
 *
 * @param shipmentLegs
 * @return the last stop of the active leg
 */
export const getNextStopEta = (shipmentLegs: ShipmentLeg[]): ShipmentStop | undefined => {
  for (let legCounter = 0; legCounter < shipmentLegs.length; ++legCounter) {
    const currentLeg = shipmentLegs[legCounter];
    if (currentLeg.stops[currentLeg.stops.length - 1].status === ShipmentStopStatusEnum.EN_ROUTE) {
      return currentLeg.stops[currentLeg.stops.length - 1];
    }
  }
  return undefined;
};

/**
 * This is a rough estimation of the CO2 Emisisons produced for a TL shipment
 * based on the number of miles traveled.
 *
 * @param milesTraveled miles the truck has traveled
 * @return the CO2 emissions in metric tons, will return undefined if unable to calculate
 */
export const calculateTruckloadCO2Emissions = (milesTraveled?: number): number | undefined => {
  if (milesTraveled === undefined || milesTraveled < 0) {
    return undefined;
  }
  const result = (milesTraveled * 1700) / 1000000;
  return round(result, 2);
};

export const getEventDate = (event: ShipmentEvent): DateTimeWithTimezone => {
  const date =
    get(event, 'dateTime') ||
    get(event, 'scheduledDateTime') ||
    (get(event, 'estimatedDateTime') as DateTimeWithTimezone);
  return date;
};
