import reducer, { initialNotificationState } from './reducers';
import { manageNotificationsActions, notificationActions } from './actions';
import * as manageNotificationTypes from './types';
import * as manageNotificationConstants from './constants';
import {
  watchNotificationsAsync as manageNotificationsOperations,
  watchNotificationAsync as editNotificationOperations,
  notificationOperations,
} from './operations';

export {
  manageNotificationsActions,
  notificationActions,
  manageNotificationTypes,
  manageNotificationConstants,
  manageNotificationsOperations,
  editNotificationOperations,
  notificationOperations,
  initialNotificationState,
};

export default reducer;
