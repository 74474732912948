import { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import * as postRobot from 'post-robot';
import isNil from 'lodash/isNil';
import { Button } from 'ui-components';
import { supplyStackUrls } from 'common/AppConstants';
import { BookingFinishedEvent } from '../SlotBooking';
import { SupplyStackIFrameContext, SupplyStackIFrameContextProps } from '../SupplyStackIFrames/SupplyStackIFrames';

export interface SlotBookingFlyoverProps {
  bookingId: string;
  localTimezoneIdentifier?: string | null;
  onBookingFinished: (booking: BookingFinishedEvent, timezone?: string | null) => void;
}

const SlotBookingFlyover: FC<SlotBookingFlyoverProps> = ({ bookingId, localTimezoneIdentifier, onBookingFinished }) => {
  const { setIsFlyoverOpen, flyoverRef } = useContext<SupplyStackIFrameContextProps>(SupplyStackIFrameContext);

  const setupEventListeners = () => {
    const onBookingFinishedListener = postRobot.on(
      `flyover#onBookingFinished`,
      (event: { data: BookingFinishedEvent }) => {
        onBookingFinished(event.data, localTimezoneIdentifier);
        return Promise.resolve();
      }
    );

    const onNotificationTriggeredListener = postRobot.on(`flyover#onNotificationTriggered`, (event) => {
      return Promise.resolve();
    });

    const onCloseListener = postRobot.on(`flyover#onClose`, (event) => {
      onBookingFinishedListener.cancel();
      onNotificationTriggeredListener.cancel();
      onCloseListener.cancel();
      setIsFlyoverOpen(false);
      return Promise.resolve();
    });
  };

  const toggleFlyover = (event: MouseEvent) => {
    const umsFrameElement = flyoverRef?.current;
    if (isNil(umsFrameElement)) {
      return;
    }
    const childWindow = umsFrameElement.contentWindow;
    postRobot
      .send(
        childWindow,
        'flyover#open',
        {
          bookingSsri: bookingId,
          capFeUrl: supplyStackUrls.supplyStackCarrierUrl,
          sbUrl: supplyStackUrls.supplyStackAPIUrl,
          umsUrl: supplyStackUrls.supplyStackUMSUrl,
          p44Theme: supplyStackUrls.supplyStackTheme,
        },
        { timeout: 10000 }
      )
      .then(() => {
        setIsFlyoverOpen(true);
        setupEventListeners();
      })
      .catch((error) => {
        setIsFlyoverOpen(false);
      });
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <>
      {!isNil(flyoverRef?.current) && (
        <Button clickFn={toggleFlyover} type="primary-transparent">
          <FormattedMessage id="slotbooking.flyover.view" defaultMessage="View Slot" />
        </Button>
      )}
    </>
  );
};

export default SlotBookingFlyover;
