import { AxiosPromise, AxiosResponse } from 'axios';
import DOMPurify from 'dompurify';
import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getType, ActionType } from 'typesafe-actions';
import qs from 'qs';
import { message } from 'ui-components';

import { trackEvent } from 'common/eventTracker';
import { shouldUseRecentSSS } from 'common/authorizations';
import { ShipmentModeEnum } from 'models';
import { API_PATH } from '../../../common/AppConstants';
import { RootState } from '../../../reducers';
import axios from '../../../util/paxios';
import * as actions from './actions';

export const getShipmentId = (state: RootState) => state.shipmentShareReducer.shipmentId;
export const getShipmentMode = (state: RootState) => state.shipmentShareReducer.shipmentMode;

export type FetchShareLinkAction = ActionType<typeof actions.fetchShareLink.request>;

export const fetchShipmentShareLink = (
  shipmentId?: string,
  shipmentMode?: ShipmentModeEnum,
  masterShipmentId?: string
): AxiosPromise<string> => {
  const currentQueryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const overrideRecent = currentQueryParams.recent && JSON.parse(currentQueryParams.recent as string);
  const isRecent = overrideRecent ?? shouldUseRecentSSS;
  const customQueryParams = {
    mode: shipmentMode,
    ...(masterShipmentId && { masterShipmentId }),
    ...(shipmentId && { shipmentId }),
  };

  return axios({
    method: 'POST',
    url: `${API_PATH}/shipment/share`,
    withCredentials: true,
    params: { ...customQueryParams, recent: isRecent },
  });
};

export function* fetchShipmentShareLinkAsync(action: FetchShareLinkAction) {
  try {
    const response: AxiosResponse<string> = yield call(
      fetchShipmentShareLink,
      action.payload.shipmentId,
      action.payload.shipmentMode,
      action.payload.masterShipmentId
    );
    const shareToken = response.data;
    const shipmentId = action.payload.shipmentId;
    trackEvent('GENERATE_SHARE_LINK');
    yield put(
      actions.fetchShareLink.success({
        shareToken,
        shipmentId: shipmentId || '',
        authorizations: action.payload.authorizations,
      })
    );
  } catch (error) {
    yield put(actions.fetchShareLink.failure(error as Error));
  }
}

export function* watchFetchShareLink() {
  yield takeLatest(getType(actions.fetchShareLink.request), fetchShipmentShareLinkAsync);
}

export type SendShareEmailAction = ActionType<typeof actions.sendShareEmail.request>;

export const sendShareEmail = (action: SendShareEmailAction) => {
  return axios({
    method: 'POST',
    url: `${API_PATH}/shipment/share/create/email`,
    data: {
      note: DOMPurify.sanitize(action.payload.message, { ALLOWED_TAGS: [] }),
      shipmentId: action.payload.shipmentId,
      mode: action.payload.shipmentMode,
      masterShipmentId: action.payload.masterShipmentId,
      recipients: action.payload.emailAddresses,
    },
    withCredentials: true,
  });
};
export function* sendShareEmailAsync(action: SendShareEmailAction) {
  try {
    yield call(sendShareEmail, action);
    trackEvent('SENT_SHARE_EMAIL');
    yield put(actions.sendShareEmail.success());
  } catch (error) {
    yield put(actions.sendShareEmail.failure(error as Error));
  }
}

export function* watchSendShareEmail() {
  yield takeLatest(getType(actions.sendShareEmail.request), sendShareEmailAsync);
}

export const showSendShareEmailSuccess = () => {
  message.success('Successfully sent email.');
};

export function* watchSendShareEmailSuccess() {
  yield takeEvery(getType(actions.sendShareEmail.success), showSendShareEmailSuccess);
}

export const showSendShareEmailFailure = () => {
  message.error("We're having trouble sending your email. Please try again.");
};

export function* watchSendShareEmailFailure() {
  yield takeEvery(getType(actions.sendShareEmail.failure), showSendShareEmailFailure);
}

export const showFetchShareLinkFailure = () => {
  message.error("We're having trouble getting a shareable link. Please try again.");
};

export function* watchFetchShareLinkFailure() {
  yield takeEvery(getType(actions.fetchShareLink.failure), showFetchShareLinkFailure);
}

export default function* shipmentShareOperations() {
  yield fork(watchFetchShareLink);
  yield fork(watchSendShareEmail);
  yield fork(watchSendShareEmailSuccess);
  yield fork(watchSendShareEmailFailure);
  yield fork(watchFetchShareLinkFailure);
}
