import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ShipmentETAStatusColor, ShipmentETAStatusEnum } from '../../../../models';
import * as styles from './MapLegend.module.scss';

const Block = styled.div<{ status: ShipmentETAStatusEnum }>`
  background-color: ${(props) => ShipmentETAStatusColor[props.status]};
  height: 15px;
  margin-left: 15px;
  margin-right: 5px;
  width: 15px;
`;

const MapLegend: React.FC<{}> = () => {
  return (
    <div data-locator="map-legend" className={styles.legend}>
      <div className={styles.legendInner}>
        <FormattedMessage id="landing.map.legend.title" defaultMessage="Active Shipments">
          {(...text) => <span className={styles.title}>{text}</span>}
        </FormattedMessage>
        <div className={styles.legendIcons}>
          <Block status={ShipmentETAStatusEnum.ON_TIME} />
          <FormattedMessage id="landing.map.legend.onTime" defaultMessage="ON TIME" />
          <Block status={ShipmentETAStatusEnum.LATE} />
          <FormattedMessage id="landing.map.legend.runningLate" defaultMessage="RUNNING LATE" />
          <Block status={ShipmentETAStatusEnum.EARLY} />
          <FormattedMessage id="landing.map.legend.runningEarly" defaultMessage="RUNNING EARLY" />
        </div>
      </div>
    </div>
  );
};

export default MapLegend;
