import filterMessages from '../../filter/modes/filterMessages.json';

export const messages = {
  filters: {
    id: 'filters.heading.filters',
    defaultMessage: 'Filters',
  },
  clearAll: {
    id: 'filters.actions.clearAll',
    defaultMessage: 'CLEAR ALL',
  },
  cancel: {
    id: 'filters.actions.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'filters.actions.save',
    defaultMessage: 'Save',
  },
  mode: {
    id: 'filters.heading.mode',
    defaultMessage: 'Mode',
  },
  savedFilters: {
    id: 'filters.heading.savedFilters',
    defaultMessage: 'Saved Filters',
  },
  refineResults: {
    id: 'filters.heading.refineResults',
    defaultMessage: 'Refine Results',
  },
  saveFilters: {
    id: 'filters.actions.saveFilters',
    defaultMessage: 'Save',
  },
  shipmentStatus: {
    id: 'filter.modalHeading.shipmentStatus',
    defaultMessage: 'Shipment Status',
  },
  company: {
    id: 'filter.modalHeading.company',
    defaultMessage: 'Company',
  },
  location: {
    id: 'filter.modalHeading.location',
    defaultMessage: 'Location',
  },
  timing: {
    id: 'filter.modalHeading.timing',
    defaultMessage: 'Timing',
  },
  carrier: {
    id: 'filter.modalHeading.carrier',
    defaultMessage: 'Carrier',
  },
  holdsDemurrage: {
    id: 'filter.modalHeading.holdsDemurrage',
    defaultMessage: 'Holds & Demurrage',
  },
  port: {
    id: 'filter.modalHeading.port',
    defaultMessage: 'Port',
  },
  referenceKey: {
    id: 'filter.modalHeading.referenceKey',
    defaultMessage: 'Reference Key',
  },
  customerTenants: {
    id: 'filter.modalHeading.customerTenants',
    defaultMessage: 'Customer',
  },
  locationType: {
    id: 'filter.modalHeading.locationType',
    defaultMessage: 'Location Type',
  },
  event: {
    id: 'datePicker.label.eventType',
    defaultMessage: 'Event',
  },
  createdBy: {
    id: 'filter.modalHeading.createdBy',
    defaultMessage: 'Created By',
  },
  sharedTo: {
    id: 'filter.modalHeading.sharedTo',
    defaultMessage: 'Shared To',
  },
  sharedBy: {
    id: 'filter.modalHeading.sharedBy',
    defaultMessage: 'Shared By',
  },
  trackingPending: {
    id: 'filter.filterHeading.trackingPending',
    defaultMessage: 'Tracking Pending',
  },
  atStop: {
    id: 'filter.filterHeading.atStop',
    defaultMessage: 'At Stop',
  },
  inTransit: {
    id: 'filter.filterHeading.inTransit',
    defaultMessage: 'In Transit',
  },
  exceptions: {
    id: 'filter.filterHeading.exceptions',
    defaultMessage: 'Exceptions',
  },
  trackingEnded: {
    id: 'filter.filterHeading.trackingEnded',
    defaultMessage: 'Tracking Ended',
  },
  inMotion: {
    id: 'filter.filterHeading.inMotion',
    defaultMessage: 'In Motion',
  },
  idleEnRoute: {
    id: 'filter.filterHeading.idleEnRoute',
    defaultMessage: 'Idle En Route',
  },
  originYard: {
    id: 'filter.filterHeading.originYard',
    defaultMessage: 'Origin Yard',
  },
  destinationYard: {
    id: 'filter.filterHeading.destinationYard',
    defaultMessage: 'Destination Yard',
  },
  missedEvents: {
    id: 'filter.filterHeading.missedEvents',
    defaultMessage: 'Missed Events',
  },
  issues: {
    id: 'filter.filterHeading.issues',
    defaultMessage: 'Issues',
  },
  temperatureTracking: {
    id: 'filter.filterHeading.temperatureTracking',
    defaultMessage: 'Temperature Tracking',
  },
};

export type ModeType = 'ALL' | 'OCEAN' | 'AIR' | 'RAIL' | 'TL' | 'LTL' | 'PARCEL';

export const filterMapper = (mode: ModeType) => {
  switch (mode) {
    case 'ALL':
      return {
        Scheduled: { label: filterMessages.trackingPending },
        Completed: { label: filterMessages.trackingEnded },
      };
    case 'OCEAN':
      return {
        'In Transit': { label: filterMessages.inTransit },
        'Running Late': { label: filterMessages.late, category: filterMessages.arrivalForecast },
        'On Time': { label: filterMessages.onTime, category: filterMessages.arrivalForecast },
        'Running Early': { label: filterMessages.early, category: filterMessages.arrivalForecast },
      };
    case 'TL':
      return {
        'At Pickup': { label: filterMessages.atOrigin },
        'At Delivery': { label: filterMessages.atFinalStop },
        'In Transit to Pickup': { label: filterMessages.inMotionToOrigin },
        'In Transit to Any Stop': { label: filterMessages.inMotionToAnyStop },
        'In Transit to Delivery': { label: filterMessages.inMotionToFinalStop },
        'Idle In Transit to Pickup': { label: filterMessages.idleToOrigin },
        'Idle In Transit to Any Stop': { label: filterMessages.idleToAnyStop },
        'Idle In Transit to Delivery': { label: filterMessages.idleToFinalStop },
        'Running Late': { label: filterMessages.late, category: filterMessages.arrivalForecast },
        'On Time': { label: filterMessages.onTime, category: filterMessages.arrivalForecast },
        'Running Early': { label: filterMessages.early, category: filterMessages.arrivalForecast },
        'Delivered Late': { label: filterMessages.late, category: filterMessages.actualDelivery },
        'Delivered On-Time': { label: filterMessages.onTime, category: filterMessages.actualDelivery },
        'Delivered Early': { label: filterMessages.early, category: filterMessages.actualDelivery },
        'Below Low Bound Settings': { label: filterMessages.belowLowBound },
        'Above High Bound Settings': { label: filterMessages.aboveHighBound },
      };
    case 'LTL':
      return {
        'Running Late': { label: filterMessages.late, category: filterMessages.arrivalForecast },
        'On Time': { label: filterMessages.onTime, category: filterMessages.arrivalForecast },
        'Running Early': { label: filterMessages.early, category: filterMessages.arrivalForecast },
        'Delivered Late': { label: filterMessages.late, category: filterMessages.actualDelivery },
        'Delivered On-Time': { label: filterMessages.onTime, category: filterMessages.actualDelivery },
        'Delivered Early': { label: filterMessages.early, category: filterMessages.actualDelivery },
        'Not Receiving Data For More Than 72 Hours': { label: filterMessages.forMoreThan72 },
        'Not Receiving Data For 48-71 Hours': { label: filterMessages.for48To71 },
        'Not Receiving Data For 24-47 Hours': { label: filterMessages.for24To47 },
        'Not Receiving Data For 12-23 Hours': { label: filterMessages.for12To23 },
      };
    default:
      return {};
  }
};
