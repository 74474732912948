import axios from 'axios';
import { API_PATH } from 'common/AppConstants';
import { message } from 'ui-components';
import { MimeValidatedFile } from './validateFile';

export interface FileGCS {
  fileId: string;
  writeUrl: string;
}

export const getGCSsignedURLs = async (filesNo: number) => {
  try {
    const result = await axios({
      method: 'GET',
      url: `${API_PATH}/shipment/documents/upload?requestedFileCount=${filesNo}`,
      withCredentials: true,
    });
    const { files } = result.data;

    return files as FileGCS[];
  } catch (e) {
    message.error('There is an error uploading your document.');
    console.error(e);
  }
};

export const upload2GCS = async (url: string, file: MimeValidatedFile) => {
  try {
    const result = await axios.put(url, file);
    return result.status === 200;
  } catch (e) {
    message.error('There is an error uploading your document.');
    console.error(e);
  }
  return false;
};

export const upload2DocumentService = async (data: any) => {
  try {
    await axios({
      method: 'POST',
      url: `${API_PATH}/shipment/documents`,
      data,
      withCredentials: true,
    });
    message.success('Uploaded!');
    return true;
  } catch (e) {
    message.error('There is an error uploading your document.');
    console.error(e);
  }
  return false;
};
