import * as React from 'react';
import { Col, Row } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

interface LineItemsContactPopoverProps extends WrappedComponentProps {
  companyName?: string;
  contactName?: string;
  email?: string;
  phone?: string;
  fax?: string;
}
const LineItemsContactPopover: React.FC<LineItemsContactPopoverProps> = (props) => {
  return (
    <Row>
      <dl>
        <Col xs={12}>
          <FormattedMessage id="shipmentDetails.companyName" defaultMessage="Company Name" tagName="dt" />
          <dd>{props.companyName}</dd>
        </Col>
        <Col xs={12}>
          <FormattedMessage id="shipmentDetails.lineItems.contactName" defaultMessage="Contact Name" tagName="dt" />
          <dd>{props.contactName}</dd>
        </Col>
        <Col xs={12}>
          <FormattedMessage id="shipmentDetails.lineItems.email" defaultMessage="Email" tagName="dt" />
          <dd>{props.email}</dd>
        </Col>
        <Col xs={12}>
          <FormattedMessage id="shipmentDetails.lineItems.phone" defaultMessage="Phone" tagName="dt" />
          <dd>{props.phone}</dd>
        </Col>
        <Col xs={12}>
          <FormattedMessage id="shipmentDetails.lineItems.fax" defaultMessage="Fax" tagName="dt" />
          <dd>{props.fax}</dd>
        </Col>
      </dl>
    </Row>
  );
};
export default injectIntl(LineItemsContactPopover);
