import { connect } from 'react-redux';

import { logout } from '../../login/LoginForm/ducks/actions';
import { resetTimeout, toggleSessionDialogToOpen } from './ducks/actions';
import sessionWrapper from './SessionWrapper';

const mapStateToProps = (state) => ({
  location: state.router.location,
  sessionDialogOpen: state.sessionWrapperReducer.sessionDialogOpen,
});

const mapDispatchToProps = (dispatch) => ({
  dialogSessionHandler: (isContinue) => {
    dispatch(toggleSessionDialogToOpen(false));
    if (!isContinue) {
      /*eslint no-console: ["error", { allow: ["warn"] }] */
      console.warn('in dialogSessionHandler resetTimeout()');
      dispatch(resetTimeout());
    } else {
      /*eslint no-console: ["error", { allow: ["warn"] }] */
      console.warn('in dialogSessionHandler logout()');
      dispatch(logout());
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(sessionWrapper);
