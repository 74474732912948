import { useEffect, useRef } from 'react';
import { DatePicker } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import Layout from './layout';

import { useDatePicker, withDatePickerProvider } from './context/DatePickerContext';
import { useWindowSize } from './hooks/useWindowSize';
import Sidebar from './Sidebar';
import DateFormatSelect from './DateFormatSelect';
import PresetDateSelect from './PresetDateSelect';
import * as styles from './styles.module.scss';
import Label from './Label';
import EventTypeTabs from './EventTypeTabs';
import {
  CALENDAR_DATE_FORMAT,
  EVENT_TYPE_LABEL,
  PRESET_DATE_LABEL,
  PRESET_DATE_RADIO_OPTIONS,
  SHIPMENT_MODE_EVENT_TYPES,
} from './constants';
import {
  PresetDateName,
  PickedDate,
  EventType,
  FilterableByDateShipmentMode,
  EventTypeKey,
  StateDateTarget,
} from './types';

const { RangePicker } = DatePicker;
interface EventDatePickerProps {
  onChange: (date: string | undefined, target: StateDateTarget) => void;
  shipmentMode: FilterableByDateShipmentMode;
  filter: any;
}

const DATE_PICKER_DEFAULTS: Record<FilterableByDateShipmentMode, EventType> = {
  ALL: 'deliveryDate',
  TL: 'pickupDate',
  LTL: 'pickupDate',
  OCEAN: 'deliveryDate',
  PARCEL: 'pickupDate',
  AIR: 'pickupDate',
};

const EVENT_TYPE: Record<EventTypeKey, { radioOption: { value: EventType; label: JSX.Element } }> = {
  delivery: {
    radioOption: {
      value: 'deliveryDate',
      label: EVENT_TYPE_LABEL.delivery,
    },
  },
  pickup: {
    radioOption: {
      value: 'pickupDate',
      label: EVENT_TYPE_LABEL.pickup,
    },
  },
  arrival: {
    radioOption: {
      value: 'deliveryDate',
      label: EVENT_TYPE_LABEL.arrival,
    },
  },
  lastFreeDate: {
    radioOption: {
      value: 'oceanLastFreeDate',
      label: EVENT_TYPE_LABEL.lastFreeDate,
    },
  },
};

const EventDatePicker = ({ onChange, shipmentMode }: EventDatePickerProps) => {
  const intl = useIntl();
  const { state, dispatch } = useDatePicker();
  const popupRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const { calendar } = state;

  useEffect(() => {
    dispatch({ type: 'SET_EVENT_TYPE', payload: DATE_PICKER_DEFAULTS[shipmentMode] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange(state.pickupDate.start?.toISOString(), 'pickupDateStart');
  }, [onChange, state.pickupDate.start]);

  useEffect(() => {
    onChange(state.pickupDate.end?.toISOString(), 'pickupDateEnd');
  }, [onChange, state.pickupDate.end]);

  useEffect(() => {
    onChange(state.deliveryDate.start?.toISOString(), 'deliveryDateStart');
  }, [onChange, state.deliveryDate.start]);

  useEffect(() => {
    onChange(state.deliveryDate.end?.toISOString(), 'deliveryDateEnd');
  }, [onChange, state.deliveryDate.end]);

  useEffect(() => {
    onChange(state.oceanLastFreeDate.start?.toISOString(), 'oceanLastFreeDateStart');
  }, [onChange, state.oceanLastFreeDate.start]);

  useEffect(() => {
    onChange(state.oceanLastFreeDate.end?.toISOString(), 'oceanLastFreeDateEnd');
  }, [onChange, state.oceanLastFreeDate.end]);

  const isSingleEvent = SHIPMENT_MODE_EVENT_TYPES[shipmentMode]?.length === 1;
  const isTablet = windowSize.outerWidth < 648;
  const isSidebarVisible = !isTablet && !isSingleEvent;
  const isContainerTight = calendar === 'specificDay' || windowSize.outerWidth <= 851 || isSingleEvent;
  const areTabsVisible = isTablet && !isSingleEvent;
  const eventTypeOptions = SHIPMENT_MODE_EVENT_TYPES[shipmentMode].map(
    (eventType) => EVENT_TYPE[eventType].radioOption
  );

  const getPopupContainer = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return popupRef.current!;
  };

  const getLabelUi = () => {
    if (isSingleEvent) {
      return (
        <Label htmlFor="date-format" className="text-center">
          {EVENT_TYPE[SHIPMENT_MODE_EVENT_TYPES[shipmentMode][0]].radioOption.label}
        </Label>
      );
    } else {
      if (isTablet) {
        return (
          <Label htmlFor="date-format" className="text-center">
            <FormattedMessage id="datePicker.label.dateFormat" defaultMessage="Date Format" />
          </Label>
        );
      } else {
        return (
          <Label htmlFor="preset-date">
            <FormattedMessage {...PRESET_DATE_LABEL[calendar]} />
          </Label>
        );
      }
    }
  };

  const getRadiosUi = () => {
    if (isSingleEvent || isTablet) {
      return <DateFormatSelect className="mt-3" />;
    } else {
      return (
        <PresetDateSelect
          className="mt-3"
          radioOptions={PRESET_DATE_RADIO_OPTIONS[calendar]}
          defaultValue="custom"
          onChange={(value: PresetDateName) => {
            dispatch({ type: 'SET_PRESET_DATE', payload: value });
          }}
        />
      );
    }
  };

  const getDatePickerInputUi = () => {
    if (calendar === 'specificDay') {
      return (
        <DatePicker
          className="mt-3"
          value={state[state.eventType].start}
          onChange={(value: PickedDate) => {
            dispatch({ type: 'SET_RANGE_DATE', payload: [value, value] });
          }}
          getPopupContainer={getPopupContainer}
          showToday={false}
          format={CALENDAR_DATE_FORMAT}
          placeholder="MM/DD/YYYY"
          open
        />
      );
    } else if (calendar === 'rangeDate') {
      return (
        <RangePicker
          className="mt-3"
          value={[state[state.eventType].start, state[state.eventType].end]}
          onCalendarChange={(values) => {
            if (values) {
              dispatch({ type: 'SET_RANGE_DATE', payload: values });
            }
          }}
          getPopupContainer={getPopupContainer}
          format={CALENDAR_DATE_FORMAT}
          placeholder={[
            intl.formatMessage({ id: 'datePicker.placeholder.startDate', defaultMessage: 'Start Date' }),
            intl.formatMessage({ id: 'datePicker.placeholder.endDate', defaultMessage: 'End Date' }),
          ]}
          separator={<span>&#8212;</span>}
          open
        />
      );
    }
  };

  return (
    <Layout
      className={cn({ [styles['container-tight']]: isContainerTight })}
      popupClassName={cn({ [styles['range-popup']]: calendar === 'rangeDate' })}
      headerClassName={cn({ 'justify-content-center': isTablet || isSingleEvent })}
      tabs={areTabsVisible && <EventTypeTabs className="mb-4" tabsMetadata={eventTypeOptions} />}
      sidebar={isSidebarVisible && <Sidebar eventRadioOptions={eventTypeOptions} />}
      label={getLabelUi()}
      radios={getRadiosUi()}
      datePickerInput={getDatePickerInputUi()}
      popupRef={popupRef}
    />
  );
};

export default withDatePickerProvider<EventDatePickerProps>(EventDatePicker);
