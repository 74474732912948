import HereMapsBehavior from '../../../../../common/hereMaps/hereMapsBehavior';
import pingPopupHtml from '../popup/pingPopupHtml';

/**
 * Custom html marker and popup styling for the current location.
 *
 * @param mapRef reference to the map instance
 * @param record an object containing location data
 */
const pingHtmlMarker = (mapRef, record) => {
  // NOTE: ping color will match status color at a later date
  // let fillColor = (record && record.statusColor);
  let fillColor = `--primary-blue`;

  let currentStopMarker = `
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 40 40" class="ping-marker">
        <circle fill="var(${fillColor})" cx="20" cy="20" r="10"/>
      </svg>
    `;

  // Example below was copied from HERE Maps docs and translated to ES6 - Mark Serrano
  let outerElement = document.createElement('div');
  let innerElement = document.createElement('div');
  outerElement.style.userSelect = 'none';
  outerElement.style.webkitUserSelect = 'none';
  outerElement.style.msUserSelect = 'none';
  outerElement.style.mozUserSelect = 'none';
  outerElement.style.cursor = 'default';
  innerElement.style.color = 'red';
  innerElement.style.backgroundColor = 'transparent';
  innerElement.style.border = 'none';
  innerElement.style.font = 'normal 12px arial';
  innerElement.style.lineHeight = '12px';
  innerElement.style.paddingTop = '0';
  innerElement.style.paddingLeft = '0';
  innerElement.style.width = '24px';
  innerElement.style.height = '24px';
  innerElement.style.marginTop = '-12px';
  innerElement.style.marginLeft = '-18px';
  outerElement.appendChild(innerElement);
  innerElement.innerHTML = currentStopMarker;

  // Logic to remove the bubble. A little bit buggy when using a custom html marker - Mark Serrano
  const onEnter = (evt) => {
    const identityBubble = HereMapsBehavior.findIdentityBubble(mapRef, record.id);
    if (identityBubble && identityBubble.bubble && identityBubble.bubble.getState() === 'open') {
      identityBubble.bubble.close();
    } else if (identityBubble && identityBubble.bubble && identityBubble.bubble.getState() === 'closed') {
      HereMapsBehavior.closeAllPopups(mapRef);
      identityBubble.bubble.open();
    }
  };

  let domIcon = new H.map.DomIcon(outerElement, {
    onAttach: function (clonedElement, domIcon, domMarker) {
      const position = { lat: record.lat, lng: record.lng };
      const content = pingPopupHtml(record);

      // TODO: I am surprised this viewport values work even though there's no mouse coordinates being recorded - Mark SErrano
      const currentPointer = { viewportX: 1, viewPortY: 1 };

      HereMapsBehavior.addBubbleById(mapRef, position, content, currentPointer, record.id);
      HereMapsBehavior.closeAllPopups(mapRef);

      clonedElement.addEventListener('click', onEnter);
    },
    onDetach: function (clonedElement, domIcon, domMarker) {
      // TODO: Maybe we should do garbage cleaning here of the popups? - Mark Serrano
      clonedElement.removeEventListener('click', onEnter);
    },
  });

  return domIcon;
};

export default pingHtmlMarker;
