import { DrawingManager, Marker, Polygon, useMapContext } from '@uiw/react-baidu-map';
import { useEffect, useMemo, useRef, useState } from 'react';
import { convertSvgToUrl, mapMarkerSvg } from 'components/shipmentDetails/TL/MapDetail/MapDetailChinaUtils';
import { polylineColor } from 'styles/colors';
import { DistanceUnitsEnum, FenceType } from '../models';

const styleOptions = {
  strokeColor: polylineColor,
  fillColor: polylineColor,
  strokeWeight: 2,
  fillOpacity: 0.5,
  strokeStyle: 'solid',
};

export const DISTANCE_MILES_TO_KM = 1609.344;

interface State {
  fenceType: FenceType;
  timeBasedValue: number;
  distanceBasedValue: number;
  polygonClosed: boolean;
  customGeofence: any;
}

interface GeoJson {
  type: string;
  coordinates: any[];
}

interface EditBaiduLocationMarkerProps {
  baiduPoint: { lat: number; lng: number };
  state: State;
  geoJson: GeoJson;
  setGeoJson: (geoJson: GeoJson) => void;
  baiduCustomPointsChange: number;
  defaultDistanceUnits: DistanceUnitsEnum;
}

const EditBaiduLocationMarker = ({
  baiduPoint,
  state,
  geoJson,
  setGeoJson,
  defaultDistanceUnits,
  baiduCustomPointsChange,
}: EditBaiduLocationMarkerProps) => {
  const { BMap, map } = useMapContext();
  const { fenceType, distanceBasedValue, customGeofence } = state;
  const circleRef = useRef<BMap.Circle | null>(null);
  const [polygonCordinatesObj, setPolygonCordinatesObj] = useState<any>();
  const { geometry: { type, coordinates: [polygonPaths = []] = [] } = geoJson || {} } = customGeofence || {};

  const baiduCustomPoints = useMemo(
    () => (Array.isArray(polygonPaths) ? polygonPaths.map(([lng, lat]: [number, number]) => ({ lat, lng })) : []),
    [polygonPaths]
  );

  const isDrawing = fenceType === FenceType.POLYGON && baiduCustomPoints.length <= 0;

  const onPolygonChange = (overlay: BMap.Polygon, saveDrawing = false) => {
    const coordinatesPath = overlay.getPath();
    const coordinates = [coordinatesPath.map(({ lat, lng }: { lat: number; lng: number }) => [lng, lat])];
    const polygonObj = {
      type: 'Polygon',
      coordinates,
    };
    setPolygonCordinatesObj(polygonObj);
    if (saveDrawing) setGeoJson({ ...polygonObj });
  };

  useEffect(() => {
    if (map && BMap) {
      if (circleRef.current) {
        map.removeOverlay(circleRef.current);
        circleRef.current = null;
      }
      if (fenceType === FenceType.DISTANCE) {
        const distanceValue =
          defaultDistanceUnits === DistanceUnitsEnum.MILES
            ? distanceBasedValue * DISTANCE_MILES_TO_KM
            : distanceBasedValue;
        circleRef.current = new BMap.Circle(baiduPoint, distanceValue, {
          fillOpacity: 0.5,
          strokeColor: polylineColor,
          fillColor: polylineColor,
          strokeWeight: 2,
        });
        map.addOverlay(circleRef.current);
        if (type !== 'Point') setGeoJson({ type: 'Point', coordinates: [baiduPoint.lng, baiduPoint.lat] });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distanceBasedValue, map, fenceType, defaultDistanceUnits]);

  useEffect(() => {
    if (polygonCordinatesObj) setGeoJson(polygonCordinatesObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baiduCustomPointsChange]);

  if (!BMap) return null;

  return (
    <>
      <Marker position={baiduPoint} icon={new BMap.Icon(convertSvgToUrl(mapMarkerSvg), new BMap.Size(38, 47))} />
      {fenceType === FenceType.POLYGON && !isDrawing && (
        <Polygon
          enableEditing
          visiable
          path={baiduCustomPoints}
          {...styleOptions}
          onLineUpdate={({ target }) => onPolygonChange(target)}
        />
      )}
      {isDrawing && (
        <DrawingManager
          isOpen
          drawingToolOptions={{
            anchor: window.BMAP_ANCHOR_TOP_RIGHT,
            offset: new BMap.Size(5, 5),
          }}
          drawingMode="polygon"
          polygonOptions={styleOptions}
          onPolygonComplete={(overlay: BMap.Polygon) => {
            map?.removeOverlay(overlay);
            onPolygonChange(overlay, true);
          }}
        />
      )}
    </>
  );
};

export default EditBaiduLocationMarker;
