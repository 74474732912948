import { push } from 'connected-react-router';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'util/paxios';
import { PrincipalAuthorizations } from 'common/authorizations';
import { API_PATH } from 'common/AppConstants';
import endpoints from 'common/endpoints';
import sortTypes from 'components/common/sortResults/ducks/types';
import {
  mapStateToApi as v2MapStateToApi,
  mapApiToState as v2MapApiToState,
} from 'components/LocationDetails/location.service';
import types from './types';
const authorizations = new PrincipalAuthorizations();
const hasLocationsV2Enabled = authorizations.hasLocationsV2EnabledAuthorization();
const url = process.env.REACT_APP_JSON_SERVER ? 'http://localhost:3001/api/portal/v2' : API_PATH;
let locationsPath = `location/v2/adapter/locations`;
if (hasLocationsV2Enabled) {
  locationsPath = `location/v2/locations`;
}
export const fetchDetailData = (locationId) => {
  return axios({
    method: 'GET',
    url: `${url}/${locationsPath}/${locationId}`,
    withCredentials: true,
  });
};

export const fetchLocationsData = (data) => {
  const locationSortFields = hasLocationsV2Enabled ? sortTypes.LOCATION_SORT_FIELDS : sortTypes.LOCATION_SORT_FIELDS_V1;
  data.sortBy = isEmpty(data.sortBy) ? locationSortFields.request : data.sortBy;
  return axios({
    method: process.env.REACT_APP_JSON_SERVER ? 'GET' : 'POST',
    url: `${url}/analytics/locations/search`,
    withCredentials: true,
    data,
  });
};

export const createLocation = (location) => {
  return axios({
    method: 'POST',
    url: `${API_PATH}/${locationsPath}`,
    data: location,
    withCredentials: true,
  });
};

export const updateLocation = (location) => {
  return axios({
    method: 'PUT',
    url: `${url}/${locationsPath}/${location.id}`,
    data: location,
    withCredentials: true,
  });
};

export const mapApiToState = (response) => ({
  ...response,
  address: {
    addressLine1: response.address.addressLine1,
    addressLine2: response.address.addressLine2,
    city: response.address.city,
    zip: response.address.zip,
    state: response.address.state,
    country: response.address.country,
    locationCoordinatesDto: get(response, 'address.locationCoordinatesDto'),
  },
});

export const mapStateToApi = (request) => {
  let contact;
  if (!isNil(request.contact)) {
    contact = {
      email: isNil(request.contact.email) ? '' : request.contact.email.trim(),
      firstName: isNil(request.contact.firstName) ? '' : request.contact.firstName.trim(),
      lastName: isNil(request.contact.lastName) ? '' : request.contact.lastName.trim(),
      phoneNumber: isNil(request.contact.phoneNumber) ? '' : request.contact.phoneNumber.trim(),
    };
  }
  return {
    ...request,
    ...(get(request, 'companyName') && { companyName: request.companyName.trim() }),
    address: {
      addressLine1: isNil(request.address.addressLine1) ? '' : request.address.addressLine1.trim(),
      addressLine2: isNil(request.address.addressLine2) ? '' : request.address.addressLine2.trim(),
      city: get(request, 'address.city', '').trim(),
      zip: get(request, 'address.zip', '').trim(),
      state: request.address.state,
      country: request.address.country,
    },
    contact,
  };
};

export function* fetchDetailDataAsync(action) {
  try {
    const response = yield call(fetchDetailData, action.payload.locationId);

    yield put({
      type: types.LOCATION_DETAIL_GET_SUCCESS,
      payload: {
        locationDetails: hasLocationsV2Enabled ? v2MapApiToState(response.data) : mapApiToState(response.data),
      },
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: types.LOCATION_DETAIL_GET_FAILURE,
      error,
    });
  }
}

function* fetchLocationsDataAsync(action) {
  try {
    const response = yield call(fetchLocationsData, action.payload);
    const payload = {
      locations: response.data.results,
      totalResults: response.data.totalResults,
      lastFetchedIndex: action.payload.resultStartIndex,
      reSorted: action.payload.reSorted,
      isLoading: false,
    };
    yield put({
      type: types.LOCATION_LIST_GET_SUCCESS,
      payload,
    });
  } catch (error) {
    console.error(error);
    // This can happen if the search endpoint returns a 500 status
    // One way to replicate this in docker is when you have an undefined environment variable for the location service
    // java.net.UnknownHostException: ${LOCATION_SERVICE_LB} - Mark Serrano
    yield put({
      type: types.LOCATION_LIST_GET_FAILURE,
    });
  }
}

function* fetchLocationSuggestionDataAsync(action) {
  try {
    yield put({
      type: types.LOCATION_SUGGESTIONS_GET_SUCCESS,
      payload: {
        locations: [],
      },
      isLoading: true,
    });
    const response = yield call(fetchLocationsData, action.payload);
    yield put({
      type: types.LOCATION_SUGGESTIONS_GET_SUCCESS,
      payload: {
        locations: response.data.results,
        isLoading: false,
      },
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: types.LOCATION_SUGGESTIONS_GET_FAILURE,
    });
  }
}

export function* createLocationAsync(action) {
  try {
    const response = yield call(
      createLocation,
      hasLocationsV2Enabled ? v2MapStateToApi(action.payload) : mapStateToApi(action.payload)
    );

    yield put({
      type: types.LOCATION_CREATE_SUCCESS,
      payload: {
        id: response.data.id,
      },
    });

    yield put(push(endpoints.LOCATION_LIST));
  } catch (error) {
    console.error(error);
    yield put({
      type: types.LOCATION_CREATE_FAILURE,
      error,
    });
  }
}

export function* updateLocationAsync(action) {
  try {
    yield call(updateLocation, mapStateToApi(action.payload));

    yield put({
      type: types.LOCATION_UPDATE_SUCCESS,
      payload: {
        id: action.payload.id,
      },
    });

    yield put(push(endpoints.LOCATION_LIST));
  } catch (error) {
    console.error(error);
    yield put({
      type: types.LOCATION_UPDATE_FAILURE,
      error,
    });
  }
}

export function* watchLocationDetailAsync() {
  yield takeEvery(types.LOCATION_DETAIL_GET, fetchDetailDataAsync);
}

export function* watchLocationListAsync() {
  yield takeEvery(types.LOCATION_LIST_GET, fetchLocationsDataAsync);
}

export function* watchLocationSuggestionsAsync() {
  yield takeEvery(types.LOCATION_SUGGESTIONS_GET, fetchLocationSuggestionDataAsync);
}

export function* watchLocationCreateAsync() {
  yield takeEvery(types.LOCATION_CREATE, createLocationAsync);
}

export function* watchLocationUpdateAsync() {
  yield takeEvery(types.LOCATION_UPDATE, updateLocationAsync);
}
