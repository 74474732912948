import './CalendarRange.scss';
import { DateRange } from 'ui-components';

const CalendarRange = ({
  datepickerStartChangeFn,
  datepickerEndChangeFn,
  boundStartDateValue,
  boundEndDateValue,
  showTime = false,
  'data-locator': dataLocator = 'calendar-range-picker',
  id = '',
}) => {
  return (
    <section className="date-range-container justify-content-between d-flex" data-locator={dataLocator}>
      <DateRange
        datepickerStartChangeFn={datepickerStartChangeFn}
        datepickerEndChangeFn={datepickerEndChangeFn}
        boundStartDateValue={boundStartDateValue}
        boundEndDateValue={boundEndDateValue}
        showTime={showTime}
        stacked={showTime}
        id={id}
      />
    </section>
  );
};

export default CalendarRange;
