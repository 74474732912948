import sortBy from 'lodash/sortBy';
import { defineMessages } from 'react-intl';
import { intl } from '../../../.././common/AppConstants';

const messages = defineMessages({
  pro: {
    id: 'shipmentList.shipmentIdentifiersMapper.pro',
    defaultMessage: 'PRO',
  },
  bol: {
    id: 'shipmentList.shipmentIdentifiersMapper.bol',
    defaultMessage: 'BOL',
  },
  customerReference: {
    id: 'shipmentList.shipmentIdentifiersMapper.customerReference',
    defaultMessage: 'Customer Reference',
  },
  pickup: {
    id: 'shipmentList.shipmentIdentifiersMapper.pickup',
    defaultMessage: 'Pickup',
  },
  purchaseOrder: {
    id: 'shipmentList.shipmentIdentifiersMapper.purchaseOrder',
    defaultMessage: 'Purchase Order',
  },
  external: {
    id: 'shipmentList.shipmentIdentifiersMapper.external',
    defaultMessage: 'External',
  },
  container: {
    id: 'shipmentList.shipmentIdentifiersMapper.container',
    defaultMessage: 'Container',
  },
  billOfLading: {
    id: 'shipmentList.shipmentIdentifiersMapper.billOfLading',
    defaultMessage: 'Bill Of Lading',
  },
  bookingNumber: {
    id: 'shipmentList.shipmentIdentifiersMapper.bookingNumber',
    defaultMessage: 'Booking Number',
  },
  carrierScac: {
    id: 'shipmentList.shipmentIdentifiersMapper.carrierScac',
    defaultMessage: 'Carrier SCAC',
  },
  vesselImo: {
    id: 'shipmentList.shipmentIdentifiersMapper.vesselImo',
    defaultMessage: 'Vessel IMO',
  },
  vesselName: {
    id: 'shipmentList.shipmentIdentifiersMapper.vesselName',
    defaultMessage: 'Vessel Name',
  },
  voyageNumber: {
    id: 'shipmentList.shipmentIdentifiersMapper.voyageNumber',
    defaultMessage: 'Voyage Number',
  },
  carrierName: {
    id: 'shipmentList.shipmentIdentifiersMapper.carrierName',
    defaultMessage: 'Carrier Name',
  },
});

export const mapShipmentIdentifiers = (identifiers, map) => {
  if (!identifiers) return [];
  const x = identifiers
    .filter((id) => id?.type ?? id.type)
    .map((id) => ({
      label: map[id.type].label + ' ' + (!id.source ? '' : getSourceText(id.source)),
      type: id.type,
      value: id.value,
      primaryText: id.primary ? 'Primary' : undefined,
      priority: map[id.type].priority,
      intlMessageObject: map[id.type].intlMessageObject,
    }));
  return sortBy(sortBy(x, 'priority'), 'primary');
};

const getSourceText = (source) => {
  switch (source) {
    case 'CAPACITY_PROVIDER':
      return '(Carrier)';
    case 'CUSTOMER':
      return '(Customer)';
    default:
      return '';
  }
};

export const tlIdentifierTypes = {
  BILL_OF_LADING: {
    intlMessageObject: {
      id: 'statusCards.mapShipmentTL.bol',
      defaultMessage: 'BOL',
    },
    label: 'BOL',
    priority: 1,
  },
  ORDER: {
    intlMessageObject: {
      id: 'statusCards.mapShipmentTL.order',
      defaultMessage: 'ORDER ',
    },
    label: 'Order',
    priority: 2,
  },
  CUSTOMER_REFERENCE: {
    label: 'Unknown',
    priority: 3,
  },
};

export const ltlIdentifierTypes = {
  PRO: {
    label: intl.formatMessage(messages.pro),
    priority: 1,
  },
  BILL_OF_LADING: {
    label: intl.formatMessage(messages.bol),
    priority: 2,
  },
  CUSTOMER_REFERENCE: {
    label: intl.formatMessage(messages.customerReference),
    priority: 3,
  },
  PICKUP: {
    label: intl.formatMessage(messages.pickup),
    priority: 4,
  },
  PURCHASE_ORDER: {
    label: intl.formatMessage(messages.purchaseOrder),
    priority: 5,
  },
  EXTERNAL: {
    label: intl.formatMessage(messages.external),
    priority: 6,
  },
};

export const oceanIdentifierTypes = {
  CONTAINER_ID: {
    label: intl.formatMessage(messages.container),
    priority: 1,
  },
  BILL_OF_LADING: {
    label: intl.formatMessage(messages.billOfLading),
    priority: 2,
  },
  BOOKING_NUMBER: {
    label: intl.formatMessage(messages.bookingNumber),
    priority: 3,
  },
  CARRIER_SCAC: {
    label: intl.formatMessage(messages.carrierScac),
    priority: 4,
  },
  VESSEL_IMO: {
    label: intl.formatMessage(messages.vesselImo),
    priority: 5,
  },
  VESSEL_NAME: {
    label: intl.formatMessage(messages.vesselName),
    priority: 6,
  },
  VOYAGE_NUMBER: {
    label: intl.formatMessage(messages.voyageNumber),
    priority: 7,
  },
  CARRIER_NAME: {
    label: intl.formatMessage(messages.carrierName),
    priority: 8,
  },
};
