import { useIntl } from 'react-intl';
import { Radio } from 'ui-components';

import { PRESET_DATE_RADIO_OPTIONS } from '../constants';
import { useDatePicker } from '../context/DatePickerContext';
import { PresetDateName } from '../types';
import { buildRadioHandler } from '../utils';

interface PresetDateSelectProps {
  radioOptions: typeof PRESET_DATE_RADIO_OPTIONS.specificDay;
  className?: string;
  onChange: (presetName: PresetDateName) => void;
  defaultValue: PresetDateName;
}

const PresetDateSelect = ({ radioOptions, className, onChange, defaultValue }: PresetDateSelectProps) => {
  const intl = useIntl();
  const { state } = useDatePicker();

  return (
    <Radio
      className={className}
      value={state[state.eventType].presetDate}
      onChange={buildRadioHandler(onChange)}
      radioData={radioOptions.map((radio) => {
        return { ...radio, label: intl.formatMessage(radio.label) };
      })}
    />
  );
};

export default PresetDateSelect;
