import { LOCATION_CHANGE, routerMiddleware } from 'connected-react-router';
import get from 'lodash/get';
import { compose } from 'recompose';
import { applyMiddleware, createStore } from 'redux';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import qs from 'qs';
import { APP_VERSION } from 'common/AppConstants';

import { trackPageView } from 'common/eventTracker';
import history from './configureHistory';
import rootReducer from './reducers';
import rootSaga from './sagas';

// Defaults to local storage
// don't persist shipments under searchReducer
const blacklistTransform = createTransform(
  (inboundState, key) => {
    if (key === 'filterReducer') {
      return { ...inboundState, tenantAttributes: [] };
    } else if (key === 'carrierFleetSearchReducer') {
      return {
        ...inboundState,
        shipments: [],
        totalSearchResults: 0,
        isLoading: false,
      };
    } else if (key === 'searchReducer') {
      return {
        ...inboundState,
        shipments: [],
        totalSearchResults: 0,
        isLoading: false,
        // don't persist this to avoid getting into weird scenarios where a previous search is used instead of the URL
        searchTerm: '',
      };
    } else {
      return inboundState;
    }
  },
  (outboundState, key) => {
    if (key === 'searchReducer') {
      // initialize searchTerm from URL parameter. we have to do this at this step rather than in initialState of
      // searchReducer because rehydration will happen after initialization
      const currentLocation = history.location;
      const search = get(currentLocation, 'search', '');
      const parsedSearch = qs.parse(search.replace('?', ''));
      // component that uses this value calls it 'searchText'
      const urlSearchTerm = get(parsedSearch, 'searchText', '');
      return {
        ...outboundState,
        searchTerm: urlSearchTerm,
      };
    } else {
      return outboundState;
    }
  }
);

// don't migrate between versions of store = just clear it out by resolving "migrated" state to undefined
// see createMigrate.js in redux-persist on why we can read the version out of these specific properties in state
const stateVersionChangeMigrator = (state) => {
  let migratedState = state;
  if (get(state, '_persist.version') !== APP_VERSION) {
    migratedState = undefined;
  }
  return Promise.resolve(migratedState);
};

const persistConfig = {
  key: 'root',
  version: APP_VERSION,
  storage,
  whitelist: ['filterReducer', 'authReducer', 'searchReducer', 'brandThemingReducer', 'carrierFleetSearchReducer'],
  transforms: [blacklistTransform],
  stateReconciler: autoMergeLevel2,
  migrate: stateVersionChangeMigrator,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const pageViewTrackerMiddleware = () => (next) => (action) => {
  if (action.type.includes(LOCATION_CHANGE)) {
    const pathname = action.payload?.location?.pathname;
    trackPageView(pathname);
  }
  return next(action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(routerMiddleware(history), pageViewTrackerMiddleware, sagaMiddleware))
);

// Before running a Saga, you must mount the Saga middleware on the Store using applyMiddleware
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
