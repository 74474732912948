import { useRef, useEffect } from 'react';
import { Steps } from 'antd';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { SVGIcon } from 'ui-components';
import { trackEvent } from 'common/eventTracker';

import { STEPS } from '../constants';
import * as styles from './styles.module.scss';
import { clearSessionStorage } from '../utils';

const { Step } = Steps;

interface StepHeaderProps {
  currentStep: number;
  cancelQuickViewDialog: () => void;
}

const StepsHeader = ({ currentStep, cancelQuickViewDialog }: StepHeaderProps) => {
  const headerRef = useRef<any>(null);
  const headerTextRef = useRef<any>(null);
  const headerStepsRef = useRef<any>(null);
  const headerCloseButtonRef = useRef<any>(null);
  const headerTextContainerRef = useRef<any>(null);
  const history = useHistory();

  const handleQuitConfigurationFlow = () => {
    trackEvent(`QV_EXIT_CONFIGURATION_STEP_${currentStep + 1}`);
    clearSessionStorage();
    cancelQuickViewDialog();
    history.push('/dashboard');
  };

  useEffect(() => {
    const onScroll = () => {
      const scollPosition = window.pageYOffset;
      const sticky = headerRef?.current?.offsetTop;
      const subtotalContent = document.getElementById('subtotal-container');
      const totalContent = document.getElementById('total-container');
      const titleAndSettingslContent = document.getElementById('title-settings-container');
      if (scollPosition > sticky) {
        headerRef.current.classList.add(styles['sticky']);
        headerTextRef.current.classList.add(styles['animate']);
        headerTextContainerRef.current.classList.add(styles['animate']);
        headerStepsRef.current.classList.add(styles['animate']);
        headerCloseButtonRef.current.classList.add(styles['animate']);
        subtotalContent?.classList.add(styles['content-padding']);
        totalContent?.classList.add(styles['content-padding']);
        titleAndSettingslContent?.classList.add(styles['content-padding']);
      } else {
        headerRef.current.classList.remove(styles['sticky']);
        headerTextRef.current.classList.remove(styles['animate']);
        headerTextContainerRef.current.classList.remove(styles['animate']);
        headerStepsRef.current.classList.remove(styles['animate']);
        headerCloseButtonRef.current.classList.remove(styles['animate']);
        subtotalContent?.classList.remove(styles['content-padding']);
        totalContent?.classList.remove(styles['content-padding']);
        titleAndSettingslContent?.classList.remove(styles['content-padding']);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div
      data-testid="quickview-container"
      id="quickview-container"
      className={styles['quickview-container']}
      ref={headerRef}
    >
      <div className={styles['quickview-header-container']}>
        <div ref={headerTextContainerRef} className={styles['quickview-header-text-container']}>
          <h1 ref={headerTextRef} className={styles['quickview-header-text']}>
            <FormattedMessage id="quickViewConfig.header.title" defaultMessage="Quick View Setup" />
          </h1>
          <button
            ref={headerCloseButtonRef}
            id="circle-button"
            className={cn('d-flex justify-content-center align-items-center', styles['circle-button'])}
            onClick={handleQuitConfigurationFlow}
          >
            <SVGIcon name="close" className={styles['close-icon']} />
          </button>
        </div>
        <div className={styles['steps']} ref={headerStepsRef}>
          <Steps type="navigation" className={styles['site-navigation-steps']} current={currentStep}>
            {STEPS.map(({ translation, id }, index) => (
              <Step
                key={id}
                title={
                  <FormattedMessage id={translation.id} defaultMessage={translation.defaultMessage}>
                    {(txt) => `${index + 1}. ${txt}`}
                  </FormattedMessage>
                }
              />
            ))}
          </Steps>
        </div>
      </div>
    </div>
  );
};

export default StepsHeader;
