import get from 'lodash/get';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import pickBy from 'lodash/pickBy';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';

import { intl } from 'common/AppConstants';
import { ShipmentStopTypeEnum } from 'models';
import {
  STATIC_NOTIFICATION_VALUES,
  API_OPERATOR_TO_STATE_MAP,
  API_FIELD_TYPE_TO_STATE_MAP,
  API_SUBSCRIPTION_TYPE_TO_STATE_MAP,
  message,
  EVENT_TYPE_API_TO_STATE_MAP,
  API_TO_STATE_STOP_TYPE_MAP,
  API_TO_STATE_STATUS_CODE_MAP,
} from './constants';
import {
  SubscriptionTypes,
  LTLEventTypeKeyConstants,
  ParcelEventTypeKeyConstants,
  AirEventTypeKeyConstants,
  OceanEventTypeKeyConstants,
  ModeTypeConstants,
  StopLocationTypes,
} from '../constants';

import { initialNotificationState } from '../reducers';

// Once rule groups fully implemented where we can have multiple rules in a group,
// the ruleGroup object will have its own id and name
const getNotificationMode = (apiNotification) => {
  const rules = get(apiNotification, 'ruleGroup.rules', []);
  if (rules.some((rule) => Object.values(LTLEventTypeKeyConstants).includes(rule.ruleType))) {
    return ModeTypeConstants.LTL;
  } else if (rules.some((rule) => Object.values(ParcelEventTypeKeyConstants).includes(rule.ruleType))) {
    return ModeTypeConstants.PARCEL;
  } else if (rules.some((rule) => Object.values(AirEventTypeKeyConstants).includes(rule.ruleType))) {
    return ModeTypeConstants.AIR;
  } else if (rules.some((rule) => Object.values(OceanEventTypeKeyConstants).includes(rule.ruleType))) {
    return ModeTypeConstants.OCEAN;
  }
  return ModeTypeConstants.TL;
};

const getSubscriptionValue = (subscription, type, subscriptionOptions) => {
  if (type === SubscriptionTypes.Push) {
    return getNameFromSubscriptionOptions(get(subscription, 'recipientParameters.pushConfigId'), subscriptionOptions);
  } else if (type === SubscriptionTypes.SMS) {
    return get(subscription, 'recipientParameters.phoneNumber');
  }

  // Assume email
  return get(subscription, 'recipientParameters.email');
};

const getNameFromSubscriptionOptions = (value, subscriptionOptions) => {
  const pushConfig = find(subscriptionOptions.values.push, (v) => {
    return v.id === value;
  });
  return isNil(pushConfig) ? value : pushConfig.configName;
};

export const mapTenantFiltersFromApiToState = (input, mode) => {
  if (!input) {
    return { ...initialNotificationState.initialValues.filters };
  }
  // This will check if not in TL mode to properly map UI display. If not in TL mode 'CUSTOMER' should be visible text instead of 'Customer at selected stop.
  if (mode !== 'TL' && input?.fields?.[0]?.type === 'CUSTOMER_V2') {
    input.fields[0].type = 'CUSTOMER';
  }

  return pickBy(
    {
      operator: get(API_OPERATOR_TO_STATE_MAP, input.operator, input.operator),
      fields: get(input, 'fields', []).map(({ type, values }) => ({
        type: get(API_FIELD_TYPE_TO_STATE_MAP, type, type),
        values,
      })),
    },
    (value) => !isNil(value)
  );
};

const mapSubscriptionFromApiToState = (subscription, subscriptionOptions) => {
  if (!subscription) {
    return null;
  }

  const type = API_SUBSCRIPTION_TYPE_TO_STATE_MAP[get(subscription, 'type')] || null;
  const value = getSubscriptionValue(subscription, type, subscriptionOptions);
  const locale = get(subscription, 'locale') || null;

  if (!type || !value) {
    return null;
  }

  return pickBy(
    {
      type,
      value,
      locale,
    },
    (propertyValue) => !isNil(propertyValue)
  );
};

const extractTimeParameter = (apiParameterMap) => {
  const timeValue =
    get(apiParameterMap, 'minutesLate') ||
    get(apiParameterMap, 'minutesUntilArrival') ||
    get(apiParameterMap, 'minutesDwelling') ||
    get(apiParameterMap, 'minutesIdle') ||
    get(apiParameterMap, 'minutesTemperatureOutOfRange') ||
    get(apiParameterMap, 'minutesBeforeAppointmentWindow') ||
    get(apiParameterMap, 'minutesOffset') ||
    get(apiParameterMap, 'hoursLate');
  // This will possibly need to be adjusted for other event types not yet implemented on backend since we only have arrival,running late currently
  if (isNil(timeValue)) {
    return null;
  }

  if (get(apiParameterMap, 'minutesOffset') || get(apiParameterMap, 'hoursLate')) {
    return {
      value: timeValue,
      units: intl.formatMessage(message.hours),
    };
  } else {
    return {
      value: timeValue,
      units: intl.formatMessage(message.minutes),
    };
  }
};

const JavaIntMax = Math.pow(2, 31) - 1; // signed int max: 2^31 - 1 = 2147483647

const mapStopNumber = (stopNumber) => {
  if (stopNumber === 1) {
    return StopLocationTypes.Pickup;
  } else if (stopNumber === JavaIntMax) {
    return StopLocationTypes.Delivery;
  }
  return null;
};

const extractStopParameter = (apiParameterMap) => {
  let stopType = get(API_TO_STATE_STOP_TYPE_MAP, get(apiParameterMap, 'stopType')) || null;
  if (stopType === null) {
    stopType = mapStopNumber(get(apiParameterMap, 'stopNumber'));
  }

  if (!stopType) {
    return null;
  }

  return {
    type: stopType,
  };
};

const extractStatusCodeParameter = (apiParameterMap) => {
  let statusCode = get(API_TO_STATE_STATUS_CODE_MAP, get(apiParameterMap, 'statusCode')) || null;

  if (!statusCode) {
    return null;
  }

  return {
    type: statusCode,
  };
};

const extractOffsetParameter = (apiParameterMap) => {
  return apiParameterMap.offsetDirection;
};

const groupRecipientsByType = (notificationRecipients) => {
  if (!notificationRecipients) {
    return null;
  }

  const groupedRecipients = new Map();
  for (let recipient of notificationRecipients) {
    const key = recipient.type;
    let entry = groupedRecipients.get(key);
    if (!entry) {
      entry = { type: key, values: [] };
      groupedRecipients.set(key, entry);
    }
    entry.values.push(recipient.value);
  }

  return Array.from(groupedRecipients.values());
};

const mapNotificationEventFromApiToState = (apiEvent) => {
  const eventType = get(EVENT_TYPE_API_TO_STATE_MAP, get(apiEvent, 'ruleType'));
  if (!eventType) {
    return null;
  }

  apiEvent.ruleConfiguration.stopType = getDefaultStopTypeForAirMode(apiEvent);

  return {
    id: apiEvent.ruleId,
    version: apiEvent.version,
    eventTypeKey: eventType,
    timeParameter: extractTimeParameter(apiEvent.ruleConfiguration),
    stopParameter: extractStopParameter(apiEvent.ruleConfiguration),
    countryParameter: { country: apiEvent.ruleConfiguration.country },
    statusCodeParameter: extractStatusCodeParameter(apiEvent.ruleConfiguration),
    offsetDirection: extractOffsetParameter(apiEvent.ruleConfiguration),
  };
};

const getDefaultStopTypeForAirMode = (apiEvent) => {
  let defaultStopType = apiEvent.ruleConfiguration.stopType;
  if (apiEvent.ruleType === 'AIR_ANY_STATUS_UPDATED' && defaultStopType === undefined) {
    return ShipmentStopTypeEnum.ANY;
  } else {
    return defaultStopType;
  }
};

export default (apiNotification, subscriptionOptions) => {
  if (!apiNotification) {
    return null;
  }

  const notification = {
    id: get(apiNotification, 'ruleGroup.id'),
    version: get(apiNotification, 'ruleGroup.version'),
    title: get(apiNotification, 'ruleGroup.name'),
    mode: getNotificationMode(apiNotification),
  };

  const notificationEvents = get(apiNotification, 'ruleGroup.rules', [])
    .map(mapNotificationEventFromApiToState)
    .filter(Boolean);

  if (isEmpty(notificationEvents)) {
    return null;
  }

  notification.notificationEvents = notificationEvents;

  const notificationRecipients = get(apiNotification, 'subscriptions', [])
    .map((subscription) => mapSubscriptionFromApiToState(subscription, subscriptionOptions))
    .filter(Boolean);

  notification.notificationRecipients = groupRecipientsByType(notificationRecipients) || [];

  const tenantFilters = mapTenantFiltersFromApiToState(
    get(apiNotification, 'ruleGroup.filters.tenantFilters'),
    notification.mode
  );
  if (!isEmpty(tenantFilters)) {
    notification.filters = tenantFilters;
  }

  merge(notification, STATIC_NOTIFICATION_VALUES);

  return notification;
};
