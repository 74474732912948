import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import { parseDurationString } from 'components/LocationDetails/location.service';
import { ShipmentVolumesModel, DwellTimesModel, TrackingComplianceModel, GeofencePerformanceModel } from 'models';
export const createGeofenceAccuracyDataSet = (data: GeofencePerformanceModel[]): Array<{ x: string; y: string }> => {
  const aggregates = data.reduce(
    (acc: { [key: string]: { totalShipments: number; arrivedShipments: number } }, obj: GeofencePerformanceModel) => {
      const yearAndMonth = get(obj, 'date', '').substring(0, 7); // obj.date will have a string formatted like YYYY-MM-DD
      if (yearAndMonth) {
        const totalShipments = get(acc[yearAndMonth], 'totalShipments', 0);
        const arrivedShipments = get(acc[yearAndMonth], 'arrivedShipments', 0);
        acc[yearAndMonth] = {
          ...acc[yearAndMonth],
          totalShipments: totalShipments + obj.totalShipments,
          arrivedShipments: arrivedShipments + obj.arrivedShipments,
        };
      }
      return acc;
    },
    {} as { [key: string]: { totalShipments: number; arrivedShipments: number } }
  );

  return Object.keys(aggregates).map((val) => {
    return {
      x: val,
      y: ((aggregates[val].arrivedShipments / aggregates[val].totalShipments) * 100).toFixed(),
    };
  });
};

export const createVolumeDataSet = (data: ShipmentVolumesModel[]): Array<{ x: string; y: number }> => {
  const aggregates = data.reduce((acc: { [key: string]: { totalShipments: number } }, obj: ShipmentVolumesModel) => {
    // * Date will be in the format `YYYY-MM-DD`
    const yearAndMonth = get(obj, 'date', '').substring(0, 7); // obj.date will have a string formatted like YYYY-MM-DD
    if (yearAndMonth) {
      const totalShipments = get(acc[yearAndMonth], 'totalShipments', 0);
      acc[yearAndMonth] = {
        ...acc[yearAndMonth],
        totalShipments: totalShipments + get(obj, 'totalShipments', 0),
      };
    }
    return acc;
  }, {} as { [key: string]: { totalShipments: number } });

  return Object.keys(aggregates).map((val) => {
    return {
      x: val,
      y: aggregates[val].totalShipments,
    };
  });
};

export const createDwellTimeDataSet = (data: DwellTimesModel[]) => {
  const aggregates = data.reduce((acc: { [key: string]: number }, obj: DwellTimesModel) => {
    const yearAndMonth = get(obj, 'date', '').substring(0, 7); // obj.date will have a string formatted like YYYY-MM-DD
    if (yearAndMonth) {
      const value = acc[yearAndMonth];
      acc[yearAndMonth] = isUndefined(acc[yearAndMonth])
        ? parseDurationString(obj.duration)
        : value + parseDurationString(obj.duration);
    }
    return acc;
  }, {} as { [key: string]: number });

  return Object.keys(aggregates).map((val) => {
    return {
      x: val,
      y: aggregates[val],
    };
  });
};

export const createTrackingComplianceDataSet = (data: TrackingComplianceModel[]) => {
  const aggregates = data.reduce(
    (acc: { [key: string]: { totalShipments: number; trackedShipments: number } }, obj: TrackingComplianceModel) => {
      const yearAndMonth = get(obj, 'date', '').substring(0, 7); // obj.date will have a string formatted like YYYY-MM-DD
      if (yearAndMonth) {
        const totalShipments = get(acc[yearAndMonth], 'totalShipments', 0);
        const trackedShipments = get(acc[yearAndMonth], 'trackedShipments', 0);
        acc[yearAndMonth] = {
          ...acc[yearAndMonth],
          totalShipments: totalShipments + obj.totalShipments,
          trackedShipments: trackedShipments + obj.trackedShipments,
        };
      }
      return acc;
    },
    {} as { [key: string]: { totalShipments: number; trackedShipments: number } }
  );

  return Object.keys(aggregates).map((val) => {
    return {
      x: val,
      y: ((aggregates[val].trackedShipments / aggregates[val].totalShipments) * 100).toFixed(),
    };
  });
};
