import { SortField } from 'models/SortField';
import { PaginationParameters } from 'models/Page';
import { OffsetDateTimeRangeFilter } from './InventorySearch';
import { ArrivalStatusCode } from '.';

export enum InventoryOrderSortField {
  ORIGINAL_DELIVERY_DATE_START = 'originalDeliveryDateTimeWindow.startDateTime',
  ORIGINAL_DELIVERY_DATE_END = 'originalDeliveryDateTimeWindow.endDateTime',
  ORDER_SUBMISSION_DATE_TIME = 'orderSubmissionDateTime',
}

export enum InventoryOrderType {
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  SALES_ORDER = 'SALES_ORDER',
  WAREHOUSE_MOVEMENT_ORDER = 'WAREHOUSE_MOVEMENT_ORDER',
  ADVANCED_SHIPMENT_NOTICE = 'ADVANCED_SHIPMENT_NOTICE',
}

export const InventoryOrderTypeIntlKeys: {
  [P in InventoryOrderType]: { id: string; defaultMessage: string };
} = {
  [InventoryOrderType.PURCHASE_ORDER]: { id: 'inventory.orderTypes.purchaseOrder', defaultMessage: 'Purchase Order' },
  [InventoryOrderType.SALES_ORDER]: { id: 'inventory.orderTypes.salesOrder', defaultMessage: 'Sales Order' },
  [InventoryOrderType.WAREHOUSE_MOVEMENT_ORDER]: {
    id: 'inventory.orderTypes.warehouseMvmt',
    defaultMessage: 'Warehouse Movement Order',
  },
  [InventoryOrderType.ADVANCED_SHIPMENT_NOTICE]: {
    id: 'inventory.orderTypes.advShipment',
    defaultMessage: 'Advanced Shipment Notice',
  },
};

export const InventoryOrderArrivalStatusIntlKeys: {
  [P in ArrivalStatusCode]: { id: string; defaultMessage: string };
} = {
  [ArrivalStatusCode.EARLY]: { id: 'inventory.arrivalStatusCode.early', defaultMessage: 'Early' },
  [ArrivalStatusCode.LATE]: { id: 'inventory.arrivalStatusCode.late', defaultMessage: 'Late' },
  [ArrivalStatusCode.ON_TIME]: { id: 'inventory.arrivalStatusCode.onTime', defaultMessage: 'On-Time' },
  [ArrivalStatusCode.UNKNOWN]: { id: 'inventory.arrivalStatusCode.unknown', defaultMessage: 'Unknown' },
};

export interface BasicInventoryOrderSearchCriteria {
  ids?: string[];
  searchText?: string;
  orderIdentifiers?: string[];
  orderTypes?: InventoryOrderType[];
  shipmentIds?: string[];
  page?: PaginationParameters;
  sortFields?: SortField<InventoryOrderSortField>[];
  destinationLocationAddressText?: string;
  supplierReadyDateTimeWindowStart?: OffsetDateTimeRangeFilter;
  supplierReadyDateTimeWindowEnd?: OffsetDateTimeRangeFilter;
  originalDeliveryDateTimeWindowStart?: OffsetDateTimeRangeFilter;
  originalDeliveryDateTimeWindowEnd?: OffsetDateTimeRangeFilter;
}
