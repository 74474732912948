import * as React from 'react';
import { ChevronRight } from 'ui-components';
import { DocumentTypes } from '../enums/documentEnums';
import * as styles from './Document.module.scss';
export interface DocumentProps {
  documentObj: {
    documentType: keyof typeof DocumentTypes;
    url: string;
  };
}

const Document: React.FunctionComponent<DocumentProps> = ({ documentObj }) => {
  return (
    <a
      className={`card-container ${styles.documentDetail}`}
      href={documentObj.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span id="label" className={styles.label}>
        {DocumentTypes[documentObj.documentType]}
      </span>
      <ChevronRight className={styles.icon} />
    </a>
  );
};

export const DocumentComponent = Document;
export default React.memo(Document);
