import get from 'lodash/get';
import { mapShipmentTL } from './mapShipmentTL';
import { mapShipmentLTL } from './mapShipmentLTL';
import { mapShipmentOcean } from './mapShipmentOcean';
import ShipmentMode from '../../shipment/common/enums/shipmentMode';
import { mapShipmentUnified } from './mapShipmentUnified';

/**
 * 10.2019 - When we create the new unified index for #intermodal turnover, these separate mode-specific
 *  shipment mappings should become obsolete and can be deleted in favor of mapShipmentUnified
 */
export const mapShipment = (shipment, intl, useNewEntityPage = false, renderEarlyAsOnTime = false) => {
  const shipmentMode = get(shipment, 'mode') || get(shipment, 'legs.0.mode');

  switch (shipmentMode) {
    case ShipmentMode.TL:
      return mapShipmentTL(shipment, null, renderEarlyAsOnTime);
    case ShipmentMode.LTL:
      return mapShipmentLTL(shipment, intl);
    case ShipmentMode.OCEAN:
      return mapShipmentOcean(shipment, useNewEntityPage);
    case ShipmentMode.PARCEL:
      return mapShipmentUnified(shipment);
    case ShipmentMode.RAIL:
    case ShipmentMode.AIR:
      return mapShipmentUnified(shipment, useNewEntityPage);
    default:
      return mapShipmentUnified(shipment);
  }
};
