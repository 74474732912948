import flow from 'lodash/flow';
import partial from 'lodash/partial';
import merge from 'lodash/merge';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import first from 'lodash/first';
import isArray from 'lodash/isArray';
import last from 'lodash/last';
import find from 'lodash/find';
import minBy from 'lodash/minBy';
import map from 'lodash/map';
import filter from 'lodash/filter';
import moment from 'moment';
import { formatUtcTimeInTimeZoneOrDefaultToBrowserZone } from 'common/dateUtils';
import {
  concatMappedUpdates,
  removeInvalidStops,
  createUpdateNewDay,
  createUpdateInfo,
  createStopInfo,
  displayNewDay,
  mapStatusValues,
  removeAdjacentNewDays,
} from '../../../../common/utils/routeMappingUtils';
import { routeDetailTypes, routeDetailsObjectTypes } from '../../../../common/enums/routeMappingEnums';
import DerivedStatusOcean from '../../../../../shipment/common/ocean/derivedStatusOcean';

const fullDateFormat = 'YYYY-MM-DDTHH:mm:ss';
const shortTimeZoneFormat = 'z';

const cleanupStopInfo = (update, stopInfo) => {
  // remove empty appointment windows that are derived based on current time when there is no appointment window present on model
  if (
    get(stopInfo, 'info.appointmentRange') &&
    !update.localAppointmentStartDateTime &&
    !update.localAppointmentEndDateTime
  ) {
    delete stopInfo.info.appointmentRange;
  }

  return stopInfo;
};

/**
 * Creates route details array by merging shipment stop data and mapping new values for UI display
 * @param allStatusUpdates - array of all status updates
 * @param shipmentStops - array of all shipment stops
 * @param latestStopStatuses - array of shipment stops with additional data to be merged
 * @returns [array] formatted route array
 */
export const prepareRouteDetails = (
  allStatusUpdates = [],
  shipmentStops = [],
  latestStopStatuses = [],
  allHoldUpdates = []
) => {
  const updatedRoute = [];
  const mergedShipmentStops = merge(shipmentStops, latestStopStatuses); // merge stops arrays to get utcRecordedDepartureDateTime
  const updatedStops = mergedShipmentStops.map((stop) => ({ ...stop, type: 'STOP' })); // define type as "STOP"]

  const updatedAllStatusUpdates = mapAllStatusUpdates(allStatusUpdates);
  const updatedHoldUpdates = mapHolds(allHoldUpdates);

  return flow(
    partial(mapDerivedStatus, updatedAllStatusUpdates),
    partial(concatMappedUpdates, updatedStops, updatedHoldUpdates),
    partial(mapStatusValues),
    partial(fixUpOriginSort),
    partial(sortByDate, ['sortDate']),
    partial(removeInvalidStops, true),
    partial(mapDisplayedDatesInAppropriateTimezone, shipmentStops),
    partial(mapRouteDetails),
    partial(removeDuplicateUpdates),
    // partial(mapGroupedUpdates),
    partial(removeAdjacentNewDays)
  )(updatedRoute);
};

const sortByDate = (sort, updatedRoute) => {
  return sortBy(updatedRoute, sort);
};

const holdStatusMap = {
  HOLD_CUSTOMS: 'Customs Hold',
  HOLD_LINE: 'Line Hold',
  HOLD_OTHER: 'Other Hold',
};

const mapHoldToUpdate = (hold) => {
  if (!hold) {
    return null;
  }

  // adapter to make hold structured like updates so they appear in same event stream
  return {
    derivedStatus: get(holdStatusMap, hold.type, hold.type),
    tertiary: hold.status === 'CLEARED' ? 'RELEASED' : undefined,
    // status description typically has just "RELEASED" so it would be redundant and current wires don't have this displayed
    // statusDescription: hold.description,
    date: hold.firstUpdateUtc,
    id: hold.id,
    // any hold update that changes status on hold -> released will get a new entry so it is the first time we saw this status which is what we want to display
    // lastUpdateUtc is the last time we saw that hold with that status but that would not show when the hold was first acquired or released
    localTimestamp: hold.firstUpdateUtc,
    type: 'UPDATE',
  };
};

const mapAllStatusUpdates = (allStatusUpdates) => {
  if (!isArray(allStatusUpdates)) {
    return [];
  }
  return allStatusUpdates.map(mapStatusUpdate);
};

const mapHolds = (allHoldUpdates) => {
  if (!isArray(allHoldUpdates)) {
    return [];
  }
  return allHoldUpdates.map(mapHoldToUpdate);
};

const mapStatusUpdate = (statusUpdate) => {
  if (!statusUpdate) {
    return null;
  }

  const mappedUpdate = { ...statusUpdate };

  // The ocean date time formatter is expecting dates to be in UTC so remove any localTimestamp
  // to prevent an incorrect timezone translation in mapDisplayedDatesInAppropriateTimezone
  delete mappedUpdate.localTimestamp;

  return mappedUpdate;
};

/**
 * Updates object structure for updates to set a derived status
 * @param updatedRoute - array of all the formatted updates
 * @param allStatusUpdates - array of all un-formatted status updates
 * @returns [array] updated route array derived statuses
 */
export const mapDerivedStatus = (allStatusUpdates, updatedRoute) => {
  if (!isEmpty(allStatusUpdates)) {
    // shipments with no tracking may not have status updates
    last(allStatusUpdates).latestStatusUpdate = true; // first item in array is the last status update
    allStatusUpdates.forEach((status) => {
      // map to derived status and define type as "update"
      updatedRoute.push({
        ...status,
        type: 'UPDATE',
        derivedStatus:
          DerivedStatusOcean.DISPLAYABLE_CODES[get(status, 'derivedStatuses[0].value')] || DerivedStatusOcean.UNKNOWN,
      });
    });
    return updatedRoute;
  } else {
    return allStatusUpdates;
  }
};

const fixUpOriginSort = (updates) => {
  // if we inserted an artifial origin it won't have an appointment window and will sort to the bottom
  // we want it to display after the "SCHEDULED" update if that is first update, otherwise put it first in list
  const originUpdate = find(updates, (update) => update.stopType === 'ORIGIN');
  const firstUpdate = minBy(updates, (update) => update.sortDate);

  if (
    originUpdate &&
    firstUpdate &&
    !originUpdate.appointmentRange &&
    firstUpdate !== originUpdate &&
    firstUpdate.sortDate
  ) {
    if (firstUpdate.derivedStatus === DerivedStatusOcean.DISPLAYABLE_CODES[DerivedStatusOcean.SCHEDULED]) {
      originUpdate.sortDate = originUpdate.dateValue = moment(firstUpdate.sortDate)
        .add(1, 'seconds')
        .format(fullDateFormat);
    } else {
      originUpdate.sortDate = originUpdate.dateValue = moment(firstUpdate.sortDate)
        .subtract(1, 'seconds')
        .format(fullDateFormat);
    }
  }
  return updates;
};

const mapDisplayedDatesInAppropriateTimezone = (shipmentStops, updates) => {
  const departureTimeZone = get(last(shipmentStops), 'location.address.locationCoordinatesDto.localTimeZoneIdentifier');
  return map(updates, (inputUpdate) => {
    const update = { ...inputUpdate };

    if (update.dateValue) {
      const updateMoment = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(update.dateValue, departureTimeZone);

      update.dateValue = updateMoment.format(fullDateFormat);
      update.timeZone = updateMoment.format(shortTimeZoneFormat);
    }

    if (update.lastRecordedDateValue) {
      const lastRecordedDateMoment = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(
        update.lastRecordedDateValue,
        departureTimeZone
      );
      update.lastRecordedDateValue = lastRecordedDateMoment.format(fullDateFormat);
      if (!update.timeZone) {
        update.timeZone = lastRecordedDateMoment.format(shortTimeZoneFormat);
      }
    }

    return update;
  });
};
/**
 * Updates formatted array of updates/stops by adding new days and forming stop and update objects
 * @param updatedRoute - array of all status and route objects
 * @returns [array] updated route array
 */
export const mapRouteDetails = (updatedRoute) => {
  if (isEmpty(updatedRoute)) {
    return updatedRoute;
  }

  let returnRoute = [];
  // add day date display as first item
  returnRoute.push(createUpdateNewDay(first(updatedRoute), routeDetailTypes.UPDATE.NEW_DAY));
  const lastNewDay = first(returnRoute).date;

  forEach(updatedRoute, (update, index) => {
    if (update.type === routeDetailsObjectTypes.TYPES.UPDATE && (!update.stopNumber || update.stopNumber === 1)) {
      // if the type is an update
      returnRoute.push(createUpdateInfo(update, routeDetailTypes.UPDATE.INFO));
    } else if (update.type === routeDetailsObjectTypes.TYPES.STOP) {
      // if the type is a stop
      const stopUpdates = filter(updatedRoute, {
        stopNumber: update.stopNumber,
        type:
          routeDetailsObjectTypes.TYPES.UPDATE ||
          routeDetailsObjectTypes.TYPES.EXCEPTION ||
          routeDetailsObjectTypes.TYPES.NEW_DAY,
      });
      returnRoute.push(cleanupStopInfo(update, createStopInfo(update, routeDetailTypes.UPDATE.INFO, stopUpdates)));
    }
    const nextUpdate = updatedRoute[index + 1];
    if (nextUpdate && displayNewDay(last(returnRoute), update, nextUpdate, lastNewDay)) {
      returnRoute.push(createUpdateNewDay(nextUpdate, routeDetailTypes.UPDATE.NEW_DAY));
    }
  });
  return returnRoute;
};

const removeDuplicateUpdates = (updates) => {
  let updateTypesDisplayed = [];
  let updateTypesToFilter = ['In Transit', 'Arriving'];
  return updates.filter((update) => {
    if (updateTypesToFilter.indexOf(update.label) < 0) {
      return true;
    } else if (updateTypesDisplayed.indexOf(update.label) > -1) {
      return false;
    } else {
      updateTypesDisplayed.push(update.label);
      return true;
    }
  });
};
