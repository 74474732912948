import * as React from 'react';

import DISSATISFIED from '../../assets/baseline-sentiment_dissatisfied-24px.svg';
import * as styles from './ErrorMessage.module.scss';

export interface ErrorMessageProps {
  message?: string;
  ['data-locator']?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, 'data-locator': dataLocator }) => {
  return (
    <div data-locator={dataLocator} className={styles.errorBoundary}>
      <img className={styles.errorIcon} src={DISSATISFIED} alt={'dissatisfied icon'} />
      <div className={styles.errorText}>{message}</div>
    </div>
  );
};

ErrorMessage.defaultProps = {
  message: 'Something went wrong.',
};

export default ErrorMessage;
