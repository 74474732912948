import * as React from 'react';
import Chart from 'chart.js';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Tooltip } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { SensorData, TemperatureReading, TemperatureScaleShort } from 'models';
import { primaryGreyFifty, primaryGreyTwenty, newFruit, white } from 'styles/colors';
import { formatUtcTimeInTimeZoneOrDefaultToBrowserZone } from 'common/dateUtils';
import { LONG_MONTH_DAY_YEAR_TIME_FORMAT } from 'i18n/configurei18n';
import { isTempWithinRange as isTempWithinRangeUtil, getTempTooltipText } from '../utils/temperatureUtils';
import { ReactComponent as TemperatureOutOfRangeIcon } from '../../common/assets/icons/icon-temperature-out-of-range.svg';
import { ReactComponent as TemperatureWithinRangeIcon } from '../../common/assets/icons/icon-temperature-within-range.svg';
import * as styles from './TemperatureMonitoring.module.scss';

const CurrentTemperatureContainer = styled.div<{ isWithinRange: boolean }>`
  color: ${(props) => (props.isWithinRange ? primaryGreyFifty : newFruit)};
  max-height: 120px;
  display: flex;
  align-items: center;
`;

interface TemperatureMonitoringChartProps {
  sensor: SensorData;
  readings: TemperatureReading[];
}

const TemperatureMonitoringChart: React.FC<TemperatureMonitoringChartProps> = ({ sensor, readings }) => {
  const intl = useIntl();
  const ref = React.useRef<HTMLCanvasElement>(null);
  const myChart = React.useRef<Chart>();
  const sortedReadings: TemperatureReading[] = sortBy(readings, 'utcTimestamp');

  const currentTempReading: TemperatureReading = last(sortedReadings) as TemperatureReading;

  const { unitOfMeasurement } = sensor;

  const hasSensorThresholds = sensor.max && sensor.min;

  const readingFn = (r: TemperatureReading) => {
    return r.value;
  };

  const getTempMaxTempMin = () => {
    let tempMax: any;
    let tempMin: any;
    if (hasSensorThresholds) {
      tempMax = sensor.max;
      tempMin = sensor.min;
    } else {
      tempMin = minBy(readings, readingFn)?.value;
      tempMax = maxBy(readings, readingFn)?.value;
    }
    return { tempMin, tempMax };
  };

  const { tempMin, tempMax } = getTempMaxTempMin();

  const offset = Math.floor((tempMax + tempMin) / 2);
  const tempScale = TemperatureScaleShort[unitOfMeasurement];
  const tempWithDegrees = (temp: number) => `${temp}\u00B0`;

  const isTempWithinRange = (temp: number): boolean => {
    return isTempWithinRangeUtil({ currentTemp: temp, tempMax, tempMin });
  };

  const currentTempIsWithinRange = isTempWithinRange(currentTempReading.value);

  /**
   * Note: in order to display a graph that is filled above and below a certain range,
   * we create 4 data sets: 1 main set for all temp readings, 1 to create the max range line,
   * 1 to create the min range line, and 1 that mimics all temp readings to create a fill for
   * any readings that are out of range.
   * Order matters for proper rendering.
   */
  const getChartDatasets = () => {
    const tempReadings: any[] = [];
    const maxLine: any[] = [];
    const minLine: any[] = [];
    sortedReadings.forEach((tempReading) => {
      tempReadings.push({
        x: tempReading.utcTimestamp,
        y: tempReading.value - offset,
        temperatureValue: tempReading.value,
      });
      maxLine.push({ x: tempReading.utcTimestamp, y: tempMax - offset });
      minLine.push({ x: tempReading.utcTimestamp, y: tempMin - offset });
    });

    const maxMinLineConfig = {
      backgroundColor: white,
      borderColor: primaryGreyTwenty,
      fill: 'zero',
      radius: 0,
      borderWidth: 1,
      pointHoverRadius: 0,
      pointHitRadius: 0,
    };

    const lineColor = currentTempIsWithinRange ? primaryGreyFifty : newFruit;

    return [
      { data: tempReadings, borderColor: lineColor, fill: false, radius: 1, pointHitRadius: 5, borderWidth: 2 },
      hasSensorThresholds ? { data: maxLine, ...maxMinLineConfig } : {},
      hasSensorThresholds ? { data: minLine, ...maxMinLineConfig } : {},
      hasSensorThresholds
        ? {
            data: tempReadings,
            backgroundColor: newFruit,
            borderColor: lineColor,
            fill: 'zero',
            radius: 0,
            borderWidth: 2,
            pointHoverRadius: 0,
            pointHitRadius: 0,
          }
        : {},
    ];
  };

  const getChartConfig = (): Chart.ChartConfiguration => ({
    type: 'line',
    data: {
      datasets: getChartDatasets(),
    },
    options: {
      legend: { display: false },
      tooltips: {
        titleFontSize: 14,
        bodyFontSize: 14,
        callbacks: {
          title: (tooltipItem: Chart.ChartTooltipItem[], data: Chart.ChartData) => {
            const temperatureValue = Number(tooltipItem[0].value) + offset;
            if (temperatureValue < tempMin) {
              return intl.formatMessage(
                {
                  id: 'shipmentDetails.tempMonitoring.belowMinimum',
                  defaultMessage: 'Below {tempMin}{tempScale} minimum',
                },
                { tempMin: tempWithDegrees(tempMin), tempScale }
              );
            } else if (temperatureValue > tempMax) {
              return intl.formatMessage(
                {
                  id: 'shipmentDetails.tempMonitoring.aboveMaximum',
                  defaultMessage: 'Above {tempMax}{tempScale} maximum',
                },
                { tempMax: tempWithDegrees(tempMax), tempScale }
              );
            } else {
              return intl.formatMessage(
                {
                  id: 'shipmentDetails.tempMonitoring.withinRange',
                  defaultMessage: 'Within {tempMin}{tempScale} - {tempMax}{tempScale} range',
                },
                { tempMin: tempWithDegrees(tempMin), tempMax: tempWithDegrees(tempMax), tempScale }
              );
            }
          },
          label: (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) => {
            const tempReadingValue = get(
              data,
              `datasets[${tooltipItem.datasetIndex}].data[${tooltipItem.index}].temperatureValue`
            ) as unknown as number;
            const timestamp = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(tooltipItem.xLabel as string);
            const formattedTimestamp = timestamp ? timestamp.format(LONG_MONTH_DAY_YEAR_TIME_FORMAT) : '';
            return intl.formatMessage(
              {
                id: 'shipmentDetails.tempMonitoring.temperatureReached',
                defaultMessage: 'Temperature reached {tempReadingValue}{tempScale} at {formattedTimestamp}',
              },
              { tempReadingValue: tempWithDegrees(tempReadingValue), tempScale, formattedTimestamp }
            );
          },
          labelColor: (tooltipItem: Chart.ChartTooltipItem, chart: Chart) => {
            const temperatureValue = Number(tooltipItem.value) + offset;
            const tempIsWithinRange = isTempWithinRange(temperatureValue);
            return {
              backgroundColor: tempIsWithinRange ? primaryGreyFifty : newFruit,
              borderColor: primaryGreyFifty,
            };
          },
        },
      },
      responsive: true,
      aspectRatio: 4,
      scales: {
        xAxes: [
          {
            type: 'time',
            display: false,
          },
        ],
        yAxes: [
          {
            display: false,
            ticks: {
              beginAtZero: false,
              suggestedMin: -10,
              suggestedMax: 10,
            },
          },
        ],
      },
    },
  });

  React.useEffect(() => {
    let chartRef: CanvasRenderingContext2D | null;
    function buildChart() {
      if (typeof myChart.current !== 'undefined') {
        if (!isNil(myChart.current)) {
          myChart.current.destroy();
        }
      }
      if (!isNil(ref) && !isNil(ref.current)) {
        chartRef = ref.current.getContext('2d');
        if (!isNil(chartRef)) {
          myChart.current = new Chart(chartRef, getChartConfig());
        }
      }
    }
    buildChart();
  });

  const tooltipText = getTempTooltipText(currentTempIsWithinRange);

  return (
    <div className={styles.card}>
      <Row className="pl-4">
        {hasSensorThresholds && (
          <Col sm={24} md={5} lg={4} className="d-flex align-items-center justify-content-center mb-3">
            <Tooltip title={intl.formatMessage(tooltipText)}>
              <CurrentTemperatureContainer isWithinRange={currentTempIsWithinRange}>
                {currentTempIsWithinRange ? (
                  <TemperatureWithinRangeIcon className={styles.thermometerIcon} />
                ) : (
                  <TemperatureOutOfRangeIcon className={styles.thermometerIcon} />
                )}
                <div className="pr-4 d-flex flex-column justify-content-center">
                  <span className={styles.currentTemp}>
                    {currentTempReading.value}
                    <span className={styles.currentTempScale}>&deg;{tempScale}</span>
                  </span>
                  <span className={styles.temperatureText}>
                    {intl.formatMessage({
                      id: 'shipmentDetails.tempMonitoring.temperature',
                      defaultMessage: 'Temperature',
                    })}
                  </span>
                </div>
              </CurrentTemperatureContainer>
            </Tooltip>
          </Col>
        )}
        {!hasSensorThresholds && (
          <CurrentTemperatureContainer isWithinRange={currentTempIsWithinRange}>
            <TemperatureWithinRangeIcon className={styles.thermometerIcon} />
            <div className="pr-4 d-flex flex-column justify-content-center">
              <span className={styles.currentTemp}>
                {currentTempReading.value}
                <span className={styles.currentTempScale}>&deg;{tempScale}</span>
              </span>
              <span className={styles.temperatureText}>
                {intl.formatMessage({
                  id: 'shipmentDetails.tempMonitoring.temperature',
                  defaultMessage: 'Temperature',
                })}
              </span>
            </div>
          </CurrentTemperatureContainer>
        )}
        {!hasSensorThresholds && <Col className={`d-none d-sm-flex ${styles.chartLabels}`} sm={5} md={4} lg={3}></Col>}
        {hasSensorThresholds && (
          <Col className={`d-none d-sm-flex ${styles.chartLabels}`} sm={5} md={4} lg={3}>
            <p>
              <FormattedMessage
                id="shipmentDetails.tempMonitoring.chartLabels.aboveTempMax"
                defaultMessage="Above {tempMax} Max"
                values={{ tempMax: tempWithDegrees(tempMax) }}
              />
            </p>
            <p>
              <FormattedMessage
                id="shipmentDetails.tempMonitoring.chartLabels.withinTempRange"
                defaultMessage="Within {tempMin} - {tempMax}"
                values={{ tempMin: tempWithDegrees(tempMin), tempMax: tempWithDegrees(tempMax) }}
              />
            </p>
            <p>
              <FormattedMessage
                id="shipmentDetails.tempMonitoring.chartLabels.belowTempMin"
                defaultMessage="Below {tempMin} Min"
                values={{ tempMin: tempWithDegrees(tempMin) }}
              />
            </p>
          </Col>
        )}
        <Col className="d-none d-sm-inline-block" sm={16} md={14} lg={16}>
          <div className="d-flex align-items-center">
            <canvas ref={ref} aria-label="Displaying temperature monitoring chart" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TemperatureMonitoringChart;
