import React from 'react';
import { Row } from 'react-bootstrap';

import * as styles from '../FleetListCard.module.scss';

interface FleetProps {
  name?: string;
}
export const FleetIdentifiers: React.FC<FleetProps> = ({ name }) => {
  return (
    <div className={styles.identifierSection}>
      <Row className="flex-nowrap">
        {
          <div
            data-locator={`primary-vehicle-identifier-${'identifierToDisplay.value'}`}
            key={`fleet-${name}`}
            className={styles.primaryIdentifier}
          >
            {name}
          </div>
        }
      </Row>
    </div>
  );
};
