import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { AxiosPromise, AxiosResponse } from 'axios';
import { API_PATH } from 'common/AppConstants';
import axios from '../../../../util/paxios';
import * as actions from './actions';
import { TableauConfig } from '../models/TableauConfig';

export const fetchConfig = (): AxiosPromise<string> => {
  // Note that this request returns a raw string from PV2S and NOT a JSON object
  return axios({
    method: 'GET',
    url: `${API_PATH}/report/host`,
    withCredentials: true,
  });
};

export function* fetchConfigAsync() {
  try {
    const axiosResponse: AxiosResponse<string> = yield call(fetchConfig);
    const config: TableauConfig = { host: axiosResponse.data };
    if (config != null) {
      yield put(actions.fetch.success(config));
    }
  } catch (error) {
    yield put(actions.fetch.failure());
  }
}

export function* watchConfigAsync() {
  yield takeEvery(getType(actions.fetch.request), fetchConfigAsync);
}

export default function* tableauConfigOperations() {
  yield fork(watchConfigAsync);
}
