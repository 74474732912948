/* eslint-disable react/jsx-no-bind */
import './SortResultsComponent.scss';

import { Col } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Dropdown } from 'ui-components';

import FilterUtil from '../../filter/util/filterUtil';
import types from './ducks/types';

const dropdownStyles = {
  height: 0,
  margin: '0 5px',
  padding: 0,
};

const StyledSpan = styled('span')`
  color: #2d2926;
  font-size: 14px;
  font-weight: 500;
  margin-right: 4px;
`;
interface SortResultsComponentProps {
  selectedSortFieldId: string;
  stateSelectedSortFieldId: string;
  handleSortChange: (type: string, sortFields: any, sortFieldId: any, sortType: any) => void;
  sortFields: Array<{ displayName: string; request: Array<{ fieldName: string; direction: string }> }>;
  sortType: string;
  filter: object;
  modes: Array<string | undefined>;
  search: string;
  shipmentListFiltersUrlEnabled: Boolean;
  filtersHorizontalEnabled: Boolean;
  location: string;
}

const SortResultsComponent: React.FC<SortResultsComponentProps> = ({
  selectedSortFieldId,
  stateSelectedSortFieldId,
  handleSortChange,
  sortFields,
  sortType,
  filter,
  modes,
  search,
  shipmentListFiltersUrlEnabled = false,
  filtersHorizontalEnabled,
  location,
}) => {
  const history = useHistory();

  const handleChange = (e: any) => {
    const current = e.item.props.value;

    if (shipmentListFiltersUrlEnabled) {
      const querySearch = FilterUtil.getSearchQueryFromURL(location);
      const queryURL = FilterUtil.buildQueryURl(
        filter,
        sortFields[current].request,
        modes,
        search,
        querySearch.enableMultiValueSearch
      );
      if (queryURL) history.push(`/shipment-list${queryURL}`);
    } else {
      handleSortChange(types.LIST_SORT, sortFields, current, sortType);
    }
  };

  return (
    <div className="sort-results animated fadeIn text-right">
      {!filtersHorizontalEnabled && (
        <Col lg={24} xs={0} sm={0}>
          <FormattedMessage
            tagName="h6"
            id="sortResults.sortResultsComponent.sorttedByText"
            defaultMessage="Sorted by"
          />
        </Col>
      )}
      <Col lg={24}>
        <div className="d-flex align-items-center justify-content-end">
          {filtersHorizontalEnabled && (
            <StyledSpan>
              <FormattedMessage id="sortResults.sortResultsComponent.sorttedByText" defaultMessage="Sorted by" />
              {':'}
            </StyledSpan>
          )}
          <Dropdown
            value={selectedSortFieldId || stateSelectedSortFieldId}
            handleChange={handleChange}
            style={dropdownStyles}
            origin={'bottomRight'}
            dropdownItems={sortFields}
            selectedColor={`--primary-grey`}
          />
        </div>
      </Col>
    </div>
  );
};

export default SortResultsComponent;
