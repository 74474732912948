import find from 'lodash/find';
import { allCountries } from 'common/allCountriesList';

export const isValidVATNumber = (vatNo: string) => {
  if (!vatNo) {
    return {
      hasLettersAsFirstTwoChars: false,
      isValidCountryCode: false,
      isValidSequence: false,
    };
  }
  // first 2 chars are letters
  const countryCode = vatNo.substr(0, 2);
  const afterCountryCode = vatNo.substr(2);
  const hasLettersAsFirstTwoChars = /[a-zA-Z]{2,3}/.test(countryCode);
  const isValidSequence = /^[a-zA-Z\d]{2,13}$/.test(afterCountryCode);
  const isValidCountryCode = !!find(allCountries, ['code', countryCode.toUpperCase()]);

  return {
    hasLettersAsFirstTwoChars,
    isValidCountryCode,
    isValidSequence,
  };
};
