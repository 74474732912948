import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import get from 'lodash/get';
import { getPrincipalAuthorizationsFromState } from 'common/authorizations';
import * as actions from './ducks/actions';
import * as types from './ducks/types';
import AddEditShipment from './AddEditShipment';
import AddShipmentFormToStateMapper from './ducks/mappers/addShipmentFormToStateMapper';
import EditShipmentStopStateToFormMapper from './ducks/mappers/editShipmentStopStateToFormMapper';
import { addEditFormConstants } from './AddEditShipmentConstants';

const mapStateToProps = (state, ownProps) => ({
  title: state.addEditShipmentReducer.title,
  open: state.addEditShipmentReducer.open && getStopNumber(ownProps) === state.addEditShipmentReducer.stopNumber,
  steps: state.addEditShipmentReducer.steps,
  initialValues: getInitialFormValues(state, ownProps),
  contactInfoExpanded: state.addEditShipmentReducer.contactInfoExpanded,
  carrierInfo: state.addEditShipmentReducer.carrierInfo,
  values: getFormValues(getFormKey(ownProps))(state),
  snackbarOpen: state.addEditShipmentReducer.snackbarOpen,
  dropDownOpen: state.addEditShipmentReducer.dropDownOpen,
  addShipmentOptions: state.addEditShipmentReducer.addShipmentOptions,
  anchorEl: state.addEditShipmentReducer.anchorEl,
  hasTruckloadMode: getPrincipalAuthorizationsFromState(state).hasTruckloadMode(),
});

const isEdit = (props) => get(props, 'type') === types.EDIT_SHIPMENT;

const getInitialFormValues = (state, ownProps) => {
  if (isEdit(ownProps)) {
    return {
      ...EditShipmentStopStateToFormMapper.mapStopStateWithStopNumberToEditForm(
        get(state, 'tlShipmentDetailsReducer.shipmentDetails.shipmentStops'),
        get(ownProps, 'shipment.stopNumber')
      ),
      errors: state.addEditShipmentReducer.errors,
      postalCodeTimezones: state.addEditShipmentReducer.postalCodeTimezones,
    };
  } else {
    return {
      ...state.addEditShipmentReducer.formValues,
      carrierInfo: state.addEditShipmentReducer.carrierInfo,
      errors: state.addEditShipmentReducer.errors,
      postalCodeTimezones: state.addEditShipmentReducer.postalCodeTimezones,
    };
  }
};

const getStopNumber = (props) => get(props.shipment, 'stopNumber');
const getStopId = (props) => get(props.shipment, 'stopId');
const getLocalTimeZoneIdentifier = (props) => get(props, 'shipment.appointmentWindow.localTimeZoneIdentifier');
const getFormKey = (props) => (isEdit(props) ? `EditShipment-${getStopNumber(props)}` : 'AddEditShipment');

const mapDispatchToProps = (dispatch, ownProps) => ({
  openAddEditShipment: (type, stopNumber) => dispatch(actions.openAddEditShipment(type, stopNumber)),
  setCarrierType: (type) => dispatch(actions.setCarrierType(type)),
  setTemperatureScale: (temperatureForm) => dispatch(actions.setTemperatureScale(temperatureForm)),
  setTrackingMethod: (trackingMethodId) => dispatch(actions.trackingMethodChosen(trackingMethodId)),
  skipTempTracking: (formValues) => dispatch(actions.skipTempTracking(formValues)),
  cancelAddEditShipment: (type) => dispatch(actions.cancelAddEditShipment(type)),
  navigateStep: (type, id, formData, initialValues, steps) => {
    // If the user clicks "Next" on the temperature tracking page, we know they wish to enable
    // temperature tracking, rather than skip it
    if (formData && id === addEditFormConstants.TEMPERATURE_TRACKING.id) {
      formData.tempTrackingEnabled = true;
    }
    if (id === addEditFormConstants.LANE_DETAIL.id && type !== types.NAVIGATE_STEP) {
      dispatch(
        actions.validateCarrier(
          // only need to validate when clicking next on entry
          AddShipmentFormToStateMapper.mapToState(formData, initialValues)
        )
      );
    } else {
      dispatch(actions.navigateStep(type, id, formData, steps));
    }
  },
  zipEntered: (stop, zipCode) => {
    dispatch(actions.postalCodeEntered({ formName: getFormKey(ownProps) }, stop, zipCode));
  },
  saveShipment: (formData, initialValues) =>
    dispatch(actions.saveShipment(AddShipmentFormToStateMapper.mapToState(formData, initialValues))),
  editShipmentStop: (formData, initialValues) => {
    dispatch(
      actions.saveStopEdit(ownProps.shipmentId, getStopNumber(ownProps), {
        ...AddShipmentFormToStateMapper.mapEditStopToState(
          formData,
          initialValues,
          getStopNumber(ownProps),
          getLocalTimeZoneIdentifier(ownProps)
        ),
        stopName: getStopId(ownProps),
      })
    );
  },
  toggleStop: (index, length) => dispatch(actions.toggleStop(index, length)),
  closeSnackbar: () => dispatch(actions.closeSnackbar()),
  openDropDown: (target) => dispatch(actions.openDropdown(target)),
  closeDropDown: () => dispatch(actions.closeDropdown()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditShipment);
