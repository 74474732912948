import { Collapse } from 'antd';
import { useState } from 'react';
import { Switch } from 'ui-components';
import * as styles from './styles.module.scss';

const { Panel } = Collapse;

interface CollapsableFilterComponentProps {
  filterName?: string | JSX.Element;
  children?: React.ReactNode;
  filterIndex?: any;
}

const CollapsableFilterComponent = ({ children, filterName, filterIndex }: CollapsableFilterComponentProps) => {
  const [checked, setChecked] = useState(false);
  return (
    <>
      <Collapse
        className={styles['site-collapse-custom-collapse']}
        onChange={() => setChecked(!checked)}
        expandIconPosition="right"
        expandIcon={() => <Switch checked={checked} />}
        ghost
      >
        <Panel className={styles['site-collapse-custom-panel']} header={filterName} key={filterIndex}>
          {children}
        </Panel>
      </Collapse>
      <hr />
    </>
  );
};

export default CollapsableFilterComponent;
