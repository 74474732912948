import './NudgeDriver.scss';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import appIconSmall from '../../../../common/assets/logo-small.svg';
import NotificationDialogComponent from '../../../../common/notificationDialog/NotificationDialogComponent';

const NudgeDriver = (props) => {
  return (
    <NotificationDialogComponent
      open={props.open}
      closeDialog={props.closeNudgeDialog}
      dialogTitle="Nudge Driver"
      actionFn={
        props.nudgeDialogShowingFailureMessage ? props.closeNudgeDialog : () => props.sendNudge(props.shipmentId)
      }
      actionText={props.nudgeDialogActionText}
    >
      {!props.nudgeDialogShowingFailureMessage && (
        <div>
          <div className="message-content">
            <p>Send the following message to the driver…</p>
          </div>
          <div className="text-message-container flex-row d-flex">
            <div className="text-message-content d-flex flex-grow-1">
              <div className="message-box">{props.nudgeMessagePreview}</div>
            </div>
            <div className="sender-icon d-flex flex-column justify-content-end align-items-center">
              <div className="icon-radius d-flex align-items-center justify-content-center">
                <img alt="Project 44 Logo Small" src={appIconSmall} />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.nudgeDialogShowingFailureMessage && <div className="message-content">{props.nudgeFailureMessage}</div>}
    </NotificationDialogComponent>
  );
};

export default withMobileDialog()(NudgeDriver);
