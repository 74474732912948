export const ActiveTab = {
  ratingAndDispatch: 0,
  tracking: 1,
  shipmentHistory: 2,
  analytics: 3,
  locations: 4,
  nmc: 5,
  carriers: 6,
  dashboard: 7,
  intel: 8,
  movement: 9,
};

export enum ActiveTabEnum {
  TRACKING = 1,
  ANALYTICS = 3,
  LOCATIONS = 4,
  RATING_AND_DISPATCH = 0,
  NETWORK_MANAGEMENT_CENTER = 5,
  CARRIERS = 6,
  DASHBOARD = 7,
  INTEL = 8,
  MOVEMENT = 9,
}

export const ActiveTabPaths: { [key: string]: ActiveTabEnum } = {
  '/intelligence': ActiveTabEnum.INTEL,
  '/shipment-list': ActiveTabEnum.TRACKING,
  '/analytics/truckload/overview': ActiveTabEnum.ANALYTICS,
  '/analytics/ocean/overview': ActiveTabEnum.ANALYTICS,
  '/location-list': ActiveTabEnum.LOCATIONS,
  '/location-details': ActiveTabEnum.LOCATIONS,
  '/dashboard': ActiveTabEnum.DASHBOARD,
};
