import { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SubHeader } from 'ui-components';
import './FleetListComponent.scss';
import styles from './carrierSearchResultCard/FleetListCard.module.scss';
import FleetListHeaderComponent from './fleetListHeader/fleetListHeaderComponent';
import FleetListCard from './carrierSearchResultCard/FleetListCard';
import CustomButton from '../../themes/CustomButton';
import { withTheme } from '../../contexts/ThemeContext';
import NoResultsToDisplay from './NoResultsToDisplay';
import DisplayedResults from './DisplayedResults';

import endpoints from '../../common/endpoints';

export const FleetListComponent = (props) => {
  const { name, history, vehicles, totalVehicles, resetCarrierResult, vehiclesCSV } = props;

  useEffect(() => {
    if (!vehicles) {
      history.push(endpoints.CARRIER_SEARCH_LIST);
    }
  }, [history, vehicles]);

  useEffect(() => {
    return () => resetCarrierResult();
  }, [resetCarrierResult]);

  const loadMoreVehicles = () => {
    props.loadMoreVehicles(props.previousSearchQuery);
  };

  const shouldDisplayMoreResultsButton = () => {
    if (!vehicles || !totalVehicles) {
      return '';
    }

    if (vehicles && totalVehicles) {
      const remainingResults = totalVehicles - vehicles.length;
      if (!remainingResults) {
        return '';
      }

      return (
        <CustomButton classes="medium full-width" clickFn={loadMoreVehicles}>
          <FormattedMessage id="shipmentList.loadMoreButton.loadMore" defaultMessage="Load More" />
        </CustomButton>
      );
    }

    return '';
  };

  return (
    <div className="FleetListComponent animated fadeIn">
      <SubHeader>
        <FleetListHeaderComponent carrierName={name} />
      </SubHeader>
      <Container as={'section'} className="section-content" key="content">
        <Row>
          <Col xs={24} lg={6} className="filters d-sm-none d-lg-block"></Col>
          {!props.isLoading && (
            <DisplayedResults totalResultsCount={totalVehicles} vehiclesCSV={vehiclesCSV} carrierName={name} />
          )}
          {vehicles && (
            <Col xs={24} lg={24} className="results">
              {vehicles.length === 0 && <NoResultsToDisplay />}
              {vehicles.length > 0 && (
                <header className={styles.footer + styles.shareBadge}>
                  <Row
                    noGutters
                    className={styles.statusSection}
                    style={{
                      backgroundColor: 'rgb(220 220 220)',
                      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.5)',
                      color: '#5a5450',
                    }}
                  >
                    <Col className="d-flex justify-content-between align-items-center p-0">
                      <span style={{ padding: '15px 0px 0px 18px' }} className="d-flex align-items-center">
                        Telematic device ID
                      </span>
                    </Col>
                  </Row>
                </header>
              )}
              {vehicles.length > 0 &&
                vehicles.map((fleet) => (
                  <FleetListCard key={fleet.value} vehicleItem={fleet} history={props.history} />
                ))}
              {shouldDisplayMoreResultsButton()}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default withTheme(injectIntl(FleetListComponent));
