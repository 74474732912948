import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';
import { ShipmentEvent, TruckloadStatusCodeEnum } from 'models';
import { HOURS_MINUTES_TIMEZONE_FORMAT, HOURS_MINUTES_FORMAT, MONTH_DAY_YEAR_FORMAT } from 'i18n/configurei18n';
import { formatUtcTimeInTimeZoneOrDefaultToBrowserZone } from 'common/dateUtils';
import { ReactComponent as ErrorIcon } from 'components/common/assets/icons/icon-error.svg';
import { ShipmentModeEnum } from 'components/newShipmentDetails/models/ShipmentMode.enum';
import { getEventDate } from '../utils/shipmentUtils';
import * as utils from '../utils';
import { primaryGreyFifty } from '../../../styles/colors';
import * as styles from './RouteTimeline.module.scss';
import LegEventComponentContainer from './LegEventComponentContainer';

export const eventTimeFormat = HOURS_MINUTES_TIMEZONE_FORMAT;
export const eventDateFormat = MONTH_DAY_YEAR_FORMAT;
const defaultTimezoneShort = 'UTC';

const StyledEventDescription = styled.div`
  align-items: center;
  display: flex;
  font-weight: bold;
  height: 100%;
  text-transform: uppercase;
`;

const StyledCityState = styled.span`
  font-weight: normal;
  color: ${primaryGreyFifty};
`;

const StyledEventContainer = styled(LegEventComponentContainer)`
  ${({ activeLeg, maxHeightData, hide }) =>
    !activeLeg &&
    css`
      max-height: ${!hide && maxHeightData ? `${maxHeightData}px !important` : `0 !important`};
      opacity: ${hide ? '0 !important' : '1 !important'};
    `}
`;

export interface LegEventComponentProps {
  event: ShipmentEvent;
  halfBottom?: boolean;
  halfTop?: boolean;
  activeLegEvent?: any;
  isLastEvent?: boolean;
  className?: string;
  hide?: boolean;
  isPublicPage?: boolean;
  mode?: string;
}

const getDateByFormat = (dateParams: {
  eventDateTime: moment.Moment;
  format: string;
  localTimeZoneAbbreviation: string | undefined;
  mode: string | undefined;
}) => {
  const { eventDateTime, format, localTimeZoneAbbreviation, mode } = dateParams;

  if (format === MONTH_DAY_YEAR_FORMAT) {
    if (!localTimeZoneAbbreviation) {
      return eventDateTime.clone().utc().format(MONTH_DAY_YEAR_FORMAT);
    }
    return eventDateTime.format(MONTH_DAY_YEAR_FORMAT);
  }
  if (format === HOURS_MINUTES_TIMEZONE_FORMAT) {
    if (mode !== ShipmentModeEnum.PARCEL) {
      return eventDateTime.format(HOURS_MINUTES_FORMAT) + ' ' + localTimeZoneAbbreviation;
    }
    if (!localTimeZoneAbbreviation) {
      return eventDateTime.clone().utc().format(HOURS_MINUTES_FORMAT) + ' ' + defaultTimezoneShort;
    }
    return eventDateTime.format(HOURS_MINUTES_FORMAT) + ' ' + localTimeZoneAbbreviation;
  }
};

const LegEventComponent = ({
  event,
  halfBottom,
  halfTop,
  activeLegEvent,
  hide,
  isPublicPage,
  isLastEvent,
  mode,
}: LegEventComponentProps) => {
  const intl = useIntl();
  const [rowMaxHeight, setRowMaxHeight] = useState<number>(0);
  const innerRow = useRef<any>(null);
  const locationText = utils.getEventLocationText(event);
  const eventDateTime = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(
    getEventDate(event)?.dateTimeUtc,
    getEventDate(event)?.timezone
  );
  const eventDescriptionText = utils.getStatusReasonCodeText(intl, event.description);
  const isCollapsable = isPublicPage && !isEqual(event, activeLegEvent);
  const localTimeZoneAbbreviation = event.dateTime?.timezoneShort ? event.dateTime?.timezoneShort : '';

  useEffect(() => {
    if (innerRow.current instanceof HTMLDivElement) {
      setRowMaxHeight(innerRow.current.offsetHeight);
    }
  }, [hide]);

  return (
    <StyledEventContainer
      isCollapsable={isCollapsable}
      hide={hide}
      activeLeg={isEqual(event, activeLegEvent)}
      maxHeightData={rowMaxHeight}
      className={classNames(isCollapsable ? styles.legEventComponent : '')}
    >
      <Row ref={innerRow} className="align-items-stretch">
        <Col xs={6} className="align-self-center">
          {eventDateTime ? (
            <div className={styles.stopTimeSection}>
              <div className={styles.stopEventDate}>
                {getDateByFormat({ eventDateTime, format: eventDateFormat, localTimeZoneAbbreviation, mode })}
              </div>
              <div className={styles.stopEventTime}>
                {getDateByFormat({ eventDateTime, format: eventTimeFormat, localTimeZoneAbbreviation, mode })}
              </div>
            </div>
          ) : (
            ''
          )}
        </Col>
        {event.type !== TruckloadStatusCodeEnum.ETA_ERROR && (
          <Col xs={2}>
            <div
              className={classNames(styles.routeLine, styles.routeLine, styles.routeLineWithStop, {
                [styles.routeLineWithStopActive]: isEqual(event, activeLegEvent),
              })}
            >
              <div className={styles.horizontal} />
            </div>
          </Col>
        )}
        {event.type === TruckloadStatusCodeEnum.ETA_ERROR && (
          <Col xs={2}>
            <div
              className={classNames(styles.routeLine, styles.routeLine, styles.routeLineWithStop, {
                [styles.errorStatusUpdate]: !isLastEvent && isEqual(event.type, TruckloadStatusCodeEnum.ETA_ERROR),
                [styles.lastErrorStatusUpdate]: isLastEvent && isEqual(event.type, TruckloadStatusCodeEnum.ETA_ERROR),
              })}
            >
              <div className={styles.error}>
                <ErrorIcon />
              </div>
            </div>
          </Col>
        )}
        <Col className="align-self-center">
          <StyledEventDescription>
            <div data-locator={`${getEventDate(event)?.dateTimeUtc}-${event.type}`}>
              {utils.getStatusCodeText(intl, event.type)}
              {eventDescriptionText && ` - ${eventDescriptionText}`}
              {locationText && <StyledCityState>{` | ${locationText}`}</StyledCityState>}
            </div>
          </StyledEventDescription>
          {event.additionalInfo && <p className={styles.eventAdditionalInfo}>{event.additionalInfo}</p>}
        </Col>
      </Row>
    </StyledEventContainer>
  );
};

export default LegEventComponent;
