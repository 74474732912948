import isEmpty from 'lodash/isEmpty';
import { put, takeLatest, takeEvery, call, select } from 'redux-saga/effects';
import { getPrincipalAuthoritiesFromState } from 'common/authorizations';
import { trackEachFilterField, trackSearchTerm } from 'common/eventTracker/searchTracker';
import axios from '../../../util/paxios';
import { API_PATH } from '../../../common/AppConstants';
import FilterUtil from '../../filter/util/filterUtil';
import sortTypes from '../../common/sortResults/ducks/types';
import {
  SUBSET_SEARCH_TYPE_LIST,
  SEARCH_SHIPMENTS_TYPE,
  SEARCH_MAP_SHIPMENTS_TYPE,
  GET_NEXT_SHIPMENT_PAGE,
  GET_TL_EQUIPMENT_IDENTIFIER_TYPES,
  GET_TL_EQUIPMENT_IDENTIFIER_TYPES_SUCCESS,
  GET_TL_EQUIPMENT_IDENTIFIER_TYPES_ERROR,
  buildSetShipmentAction,
  buildAddShipmentAction,
  buildShipmentSearchFailureAction,
} from './searchReducer';

export function searchShipments(action) {
  const payload = {
    ...action.searchQuery,
    sortBy: isEmpty(action.searchQuery.sortBy) ? sortTypes.SHIPMENT_SORT_FIELDS[0].request : action.searchQuery.sortBy,
  };
  return axios({
    method: 'POST',
    url: API_PATH + '/shipment/search',
    data: payload,
    withCredentials: true,
  });
}

export function getTLEquipmentIdentifierTypes() {
  return axios({
    method: 'GET',
    url: API_PATH + '/tl-shipment/equipmentIdentifierTypes',
    withCredentials: true,
  });
}

export function* getTLEquipmentIdentifierTypesAsync() {
  try {
    const response = yield call(getTLEquipmentIdentifierTypes);
    yield put({
      type: GET_TL_EQUIPMENT_IDENTIFIER_TYPES_SUCCESS,
      results: response.data,
    });
  } catch (e) {
    yield put({
      type: GET_TL_EQUIPMENT_IDENTIFIER_TYPES_ERROR,
      results: e,
    });
  }
}
export function* searchMapShipmentsAsync(action) {
  try {
    const response = yield call(searchShipments, action);
    const { results, totalResults } = response.data;
    yield put(buildSetShipmentAction(results, totalResults));
  } catch (e) {
    console.error(e);
    yield put(buildShipmentSearchFailureAction(e));
  }
}

export const getState = (state) => ({
  authorities: getPrincipalAuthoritiesFromState(state),
});

export function* searchShipmentsAsync(action) {
  try {
    let authorities = action.authorities;

    if (!authorities) {
      authorities = yield select(getState);
      authorities = authorities.authorities;
    }

    if (action.searchQuery.resultSubsetType === SUBSET_SEARCH_TYPE_LIST) {
      trackSearchTerm(action.searchQuery.search);
      trackEachFilterField(action.searchQuery.filter);
    }

    const modes = FilterUtil.getModesForQuery(action.searchQuery.modes, action.searchQuery.filter, authorities);
    action.searchQuery.modes = modes;

    let results, totalResults;
    if (!isEmpty(modes)) {
      const response = yield call(searchShipments, action);
      results = response.data.results;
      totalResults = response.data.totalResults;
    } else {
      results = [];
      totalResults = 0;
    }

    yield put(buildSetShipmentAction(results, totalResults));
  } catch (e) {
    console.error(e);
    yield put(buildShipmentSearchFailureAction(e));
  }
}

export function* getNextShipmentPageAsync(action) {
  try {
    const response = yield call(searchShipments, action);
    const shipments = response.data.results;
    yield put(buildAddShipmentAction(shipments));
  } catch (e) {
    console.error(e);
    yield put(buildShipmentSearchFailureAction(e));
  }
}

export function* watchMapSearchAsync() {
  yield takeLatest(SEARCH_MAP_SHIPMENTS_TYPE, searchMapShipmentsAsync);
}

export function* watchSearchAsync() {
  yield takeLatest(SEARCH_SHIPMENTS_TYPE, searchShipmentsAsync);
}

export function* watchLoadMoreAsync() {
  yield takeEvery(GET_NEXT_SHIPMENT_PAGE, getNextShipmentPageAsync);
}

export function* watchGetEquipmentIdentifierAsync() {
  yield takeEvery(GET_TL_EQUIPMENT_IDENTIFIER_TYPES, getTLEquipmentIdentifierTypesAsync);
}
