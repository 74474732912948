export const derivedStatusTypeLTL = {
  SCHEDULED: 'SCHEDULED',
  ATTEMPTING_TO_TRACK: 'ATTEMPTING_TO_TRACK',
  PICKUP_MISSED: 'PICKUP_MISSED',
  INTERLINE_MISSED: 'INTERLINE_MISSED',
  DEPARTURE_MISSED: 'DEPARTURE_MISSED',
  DELIVERY_MISSED: 'DELIVERY_MISSED',
  HELD: 'HELD',
  DELAYED: 'DELAYED',
  AT_PICKUP: 'AT_PICKUP',
  AT_TERMINAL: 'AT_TERMINAL',
  AT_DELIVERY: 'AT_DELIVERY',
  PICKED_UP: 'PICKED_UP',
  IN_TRANSIT: 'IN_TRANSIT',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  DELIVERED: 'DELIVERED',
  EXCEPTION: 'EXCEPTION',
  CANCELED: 'CANCELED',
  TIMED_OUT: 'TIMED_OUT',
  UNKNOWN: 'UNKNOWN',
};
