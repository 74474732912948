// ++=========++
// || Actions ||
// ++=========++

const namespace = (actionType) => 'carrier/search/' + actionType;

export const SET_SEARCH_CARRIER_FLEET_TERM = namespace('SET_SEARCH_CARRIER_FLEET_TERM');
export const SET_SELECTED_CARRIER = namespace('SET_SELECTED_CARRIER');
export const SEARCH_CARRIER_FLEET = namespace('SEARCH_CARRIER_FLEET');
export const SET_CARRIER_FLEET_TYPE = namespace('SET_SEARCHED_CARRIER_FLEET');
export const CARRIER_FLEET_LIST = namespace('CARRIER_FLEET_LIST');
export const CARRIER_FLEET_SEARCH_FAILURE_TYPE = namespace('CARRIER_FLEET_SEARCH_FAILURE');
export const RESET_CARRIER_SEARCH_RESULT = namespace('RESET_CARRIER_SEARCH_RESULT');
export const GET_NEXT_VEHICLES_PAGE = namespace('GET_NEXT_VEHICLES_PAGE');
export const ADD_FLEET_LIST_TYPE = namespace('ADD_FLEET_LIST_TYPE');
export const FLEET_SEARCH_RESULTS_PER_PAGE = 50;

// ++=========++
// || Reducer ||
// ++=========++

export const initialState = () => ({
  // Current UI state that will be used for a new search query.
  carrierFleetSearchTerm: '',
  selectedCarrier: null,

  // Results of the most recent search being displayed.
  carriersList: null,
  totalCarrierFleetSearchResults: 0,
  carrierResult: null, // {}
  vehicles: null,
  isLoading: false,
  searchError: null,
  errorNumber: 0,
  vehiclesCSV: null,

  // Previous query stored for the show more pagination functionality.
  previousSearchQuery: {
    search: '',
    select: '',
    resultStartIndex: 1,
    resultsPerPage: FLEET_SEARCH_RESULTS_PER_PAGE,
  },
});

const carrierSearchReducer = (state = initialState(), action = {}) => {
  switch (action.type) {
    case SET_SEARCH_CARRIER_FLEET_TERM:
      return { ...state, carrierFleetSearchTerm: action.carrierFleetSearchTerm, errorNumber: 0 };

    case SET_SELECTED_CARRIER:
      return { ...state, selectedCarrier: action.selectedCarrier, errorNumber: 0 };

    case GET_NEXT_VEHICLES_PAGE:
      return { ...state, previousSearchQuery: action.searchQuery };

    case CARRIER_FLEET_LIST:
      return { ...state, previousSearchQuery: action.searchQuery, isLoading: true };

    case SET_CARRIER_FLEET_TYPE:
      return {
        ...state,
        carriersList: action.carriersList,
        totalCarriersListSearchResults: action.totalCarriersListSearchResults,
        carrierResult: action.carrierResult,
        vehicles: action.carrierResult.truckloadAssetCollection.results,
        totalVehicles: action.carrierResult.truckloadAssetCollection.paginationInfo.total,
        name: action.carrierResult.capacityProviderDto.name,
        isLoading: false,
        errorNumber: 0,
        vehiclesCSV: action.vehiclesCSV,
      };

    case ADD_FLEET_LIST_TYPE:
      return {
        ...state,
        vehicles: state.vehicles.concat(action.vehicles),
      };

    case CARRIER_FLEET_SEARCH_FAILURE_TYPE:
      return {
        ...state,
        error: action.error,
        errorNumber: action.error.response.data.httpStatusCode,
        isLoading: false,
        carrierResult: null,
      };

    case RESET_CARRIER_SEARCH_RESULT:
      return {
        ...state,
        ...initialState(),
      };

    default:
      return state;
  }
};

export default carrierSearchReducer;

// ++=================++
// || Action builders ||
// ++=================++

export const defaultSearchQuery = {
  search: '',
  select: '',
  resultStartIndex: 1,
  resultsPerPage: FLEET_SEARCH_RESULTS_PER_PAGE,
};

export const buildSetCarrierFleetSearchTerm = (carrierFleetSearchTerm) => ({
  type: SET_SEARCH_CARRIER_FLEET_TERM,
  carrierFleetSearchTerm,
});

export const buildResetCarrierSearchResult = () => ({
  type: RESET_CARRIER_SEARCH_RESULT,
});

export const buildSetSelectedCarrier = (selectedCarrier) => ({
  type: SET_SELECTED_CARRIER,
  selectedCarrier,
});

export const buildCarrierFleetSearchQueryAction = ({ searchText = '', selectedOption = '', page = '1' } = {}) => ({
  type: CARRIER_FLEET_LIST,
  searchQuery: {
    search: searchText,
    select: selectedOption,
    page: page,
  },
});

export const buildSetCarrierFleetAction = (
  carriersList = [],
  totalCarriersListSearchResults = 0,
  carrierResult = {},
  vehicles = [],
  name = '',
  vehiclesCSV = []
) => ({
  type: SET_CARRIER_FLEET_TYPE,
  carriersList,
  totalCarriersListSearchResults,
  carrierResult,
  vehicles,
  name,
  vehiclesCSV,
});

export const buildCarrierFleetSearchFailureAction = (error) => ({
  type: CARRIER_FLEET_SEARCH_FAILURE_TYPE,
  error,
});

export const buildGetNextFleetPageAction = (previousSearchQuery = defaultSearchQuery) => {
  return {
    type: GET_NEXT_VEHICLES_PAGE,
    searchQuery: {
      ...previousSearchQuery,
      page: parseInt(previousSearchQuery.page, 10) + 1,
    },
  };
};

export const buildAddVehicleAction = (vehicles = []) => ({
  type: ADD_FLEET_LIST_TYPE,
  vehicles,
});
