import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { ShipmentModeEnum, ShipmentModeFullNameMap } from 'models';

import { API_PATH } from '../../../../common/AppConstants';
import axios from '../../../../util/paxios';
import endpoints from '../../../../common/endpoints';

export const useDocuments = (
  shipmentId?: string,
  masterShipmentId?: string,
  shipmentMode?: ShipmentModeEnum,
  shipmentShareToken?: string
): [any | undefined, boolean, any | undefined] => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any | undefined>();
  const [documents, setDocuments] = useState<any | undefined>(undefined);

  useEffect(() => {
    async function fetchDocuments() {
      try {
        setIsLoading(true);
        setError(undefined);

        let url = '';
        if (shipmentMode !== undefined && shipmentId !== undefined && masterShipmentId !== undefined) {
          url = `${API_PATH}${endpoints.GET_DOCUMENTS}?mode=${ShipmentModeFullNameMap[shipmentMode]}&shipmentId=${shipmentId}&masterShipmentId=${masterShipmentId}`;
        } else if (shipmentMode !== undefined && masterShipmentId !== undefined && !shipmentId) {
          url = `${API_PATH}${endpoints.GET_DOCUMENTS}?mode=${ShipmentModeFullNameMap[shipmentMode]}&masterShipmentId=${masterShipmentId}`;
        } else if (shipmentMode !== undefined && shipmentId !== undefined && !masterShipmentId) {
          url = `${API_PATH}${endpoints.GET_DOCUMENTS}?mode=${ShipmentModeFullNameMap[shipmentMode]}&shipmentId=${shipmentId}`;
        }

        const response: AxiosResponse<any> = await axios.get(url, { withCredentials: true });
        setIsLoading(false);

        const documentsArray = response.data.documents
          ? response.data.documents?.map((doc: any) => {
              return {
                documentType: doc.documentType,
                url: doc.currentVersion?.files[0]?.urls[0],
                id: doc.currentVersion?.md5Hash,
              };
            })
          : [];
        setDocuments(documentsArray);
      } catch (error) {
        setIsLoading(false);
        setError(error);
        setDocuments(undefined);
      }
    }
    if (!shipmentShareToken) {
      fetchDocuments();
    }
  }, [shipmentId, masterShipmentId, shipmentMode, shipmentShareToken]);

  return [documents, isLoading, error];
};
