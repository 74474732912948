import { ActionType, getType } from 'typesafe-actions';
import { Reducer } from 'redux';

import * as tableauConfigActions from './actions';
import { TableauConfig } from '../models/TableauConfig';

export type TableauAction = ActionType<typeof tableauConfigActions>;

export interface TableauConfigState {
  readonly config?: TableauConfig;
}

const initialState: TableauConfigState = {
  config: undefined,
};

const tableauConfigReducer: Reducer<TableauConfigState, TableauAction> = (state = initialState, action) => {
  switch (action.type) {
    case getType(tableauConfigActions.fetch.success): {
      return {
        ...state,
        config: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default tableauConfigReducer;
