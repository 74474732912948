import { AxiosPromise, AxiosResponse } from 'axios';
import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getType, ActionType } from 'typesafe-actions';
import { defineMessages } from 'react-intl';
import axios from 'util/paxios';
import { message } from 'ui-components';
import { trackEvent } from 'common/eventTracker';
import { TenantAttribute, ShipmentTenantAttribute, ShipmentTenantAttributeV2 } from 'models';
import { API_PATH, intl } from 'common/AppConstants';
import endpoints, { replaceTokens } from 'common/endpoints';
import * as actions from './actions';

const messages = defineMessages({
  createShipmentIdentifiersFailure: {
    id: 'shipmentDetails.shipmentIdentifiers.VALUE',
    defaultMessage: 'We are having trouble creating shipment tenant attribute. Please try again',
  },
  createTenantKeyFailure: {
    id: 'shipmentDetails.shipmentIdentifiers.FAILURE',
    defaultMessage: 'We are having trouble creating tenant key. Please try again',
  },
  duplicateTenantKey: {
    id: 'shipmentDetails.shipmentIdentifiers.DUPLICATE',
    defaultMessage: 'Duplicate value. Please enter a new value for creating a new tenant key',
  },
});

export type FetchTenantAttributesAction = ActionType<typeof actions.fetchTenantAttributes.request>;
export type FetchShipmentTenantAttributesAction = ActionType<typeof actions.fetchShipmentTenantAttributes.request>;

export const fetchTenantAttributes = (): AxiosPromise<TenantAttribute[]> => {
  return axios({
    method: 'GET',
    url: `${API_PATH}${endpoints.TENANT_ATTRIBUTES}`,
    withCredentials: true,
  });
};

export const fetchShipmentTenantAttributesByMasterShipmentId = ({
  masterShipmentId,
}: {
  masterShipmentId: string;
}): AxiosPromise<ShipmentTenantAttributeV2[]> => {
  return axios({
    method: 'GET',
    url: `${API_PATH}${replaceTokens(endpoints.SHIPMENT_TENANT_ATTRIBUTES_MSID, {
      masterShipmentId,
    })}`,
    withCredentials: true,
  });
};

export const fetchShipmentTenantAttributesByShipmentId = ({
  shipmentId,
}: {
  shipmentId: string;
}): AxiosPromise<ShipmentTenantAttribute[]> => {
  return axios({
    method: 'GET',
    url: `${API_PATH}${replaceTokens(endpoints.SHIPMENT_TENANT_ATTRIBUTES, {
      shipmentId,
    })}`,
    withCredentials: true,
  });
};

export function* fetchTenantAttributesAsync(action: FetchTenantAttributesAction) {
  try {
    const response: AxiosResponse<TenantAttribute[]> = yield call(fetchTenantAttributes, action.payload);
    yield put(actions.fetchTenantAttributes.success(response.data));
  } catch (error) {
    yield put(actions.fetchTenantAttributes.failure(error as Error));
  }
}

export type CreateTenantAttributesAction = ActionType<typeof actions.createTenantAttributes.request>;

export const createTenantAttributes = (action: CreateTenantAttributesAction) => {
  return axios({
    method: 'POST',
    url: `${API_PATH}${endpoints.TENANT_ATTRIBUTES}`,
    data: action.payload,
    withCredentials: true,
  });
};
export function* createTenantAttributesAsync(action: CreateTenantAttributesAction) {
  try {
    const response: AxiosResponse<TenantAttribute[]> = yield call(createTenantAttributes, action);
    yield put(actions.createTenantAttributes.success(response.data));
    trackEvent('CREATED_CUSTOM_TENANT_ATTRIBUTE');
  } catch (error) {
    yield put(actions.createTenantAttributes.failure(error as Error));
  }
}

export function* watchCreateTenantAttributes() {
  yield takeLatest(getType(actions.createTenantAttributes.request), createTenantAttributesAsync);
}

export const showCreateTenantAttributesFailure = (error: any) => {
  if (error.payload.response.status === 409) {
    message.error(intl.formatMessage(messages.duplicateTenantKey));
  } else {
    message.error(intl.formatMessage(messages.createTenantKeyFailure));
  }
};

export function* watchCreateTenantAttributesFailure() {
  yield takeEvery(getType(actions.createTenantAttributes.failure), showCreateTenantAttributesFailure);
}

export function* fetchShipmentTenantAttributesAsync(action: FetchShipmentTenantAttributesAction) {
  try {
    if (action.payload.masterShipmentId) {
      const response: AxiosResponse<ShipmentTenantAttributeV2[]> = yield call(
        fetchShipmentTenantAttributesByMasterShipmentId,
        {
          masterShipmentId: action.payload.masterShipmentId,
        }
      );
      yield put(
        actions.fetchShipmentTenantAttributes.success(
          response.data.map((responseVal) => {
            return {
              tenantAttributeName: responseVal.attributeName,
              tenantAttributeValues: responseVal.attributeValues,
            };
          })
        )
      );
    } else if (action.payload.shipmentId) {
      const response: AxiosResponse<ShipmentTenantAttribute[]> = yield call(fetchShipmentTenantAttributesByShipmentId, {
        shipmentId: action.payload.shipmentId,
      });
      yield put(actions.fetchShipmentTenantAttributes.success(response.data));
    }
  } catch (error) {
    yield put(actions.fetchShipmentTenantAttributes.failure(error as Error));
  }
}

export function* watchFetchTenantAttributes() {
  yield takeLatest(getType(actions.fetchTenantAttributes.request), fetchTenantAttributesAsync);
}

export function* watchFetchShipmentTenantAttributes() {
  yield takeLatest(getType(actions.fetchShipmentTenantAttributes.request), fetchShipmentTenantAttributesAsync);
}

export type CreateOrUpdateShipmentTenantAttributesAction = ActionType<
  typeof actions.createOrUpdateShipmentTenantAttributes.request
>;

export const createOrUpdateShipmentTenantAttributes = (action: CreateOrUpdateShipmentTenantAttributesAction) => {
  return axios({
    method: 'POST',
    url: `${API_PATH}${replaceTokens(
      action.payload.masterShipmentId
        ? endpoints.SHIPMENT_TENANT_ATTRIBUTES_CREATE_EDIT
        : endpoints.SHIPMENT_TENANT_ATTRIBUTES,
      {
        shipmentId: action.payload.shipmentId,
        masterShipmentId: action.payload.masterShipmentId,
      }
    )}`,
    data: action.payload.shipmentTenantAttributes,
    withCredentials: true,
  });
};
export function* createOrUpdateShipmentTenantAttributesAsync(action: CreateOrUpdateShipmentTenantAttributesAction) {
  try {
    const response: AxiosResponse<ShipmentTenantAttribute[]> = yield call(
      createOrUpdateShipmentTenantAttributes,
      action
    );
    yield put(actions.createOrUpdateShipmentTenantAttributes.success(response.data));
    trackEvent('UPDATED_CUSTOM_SHIPMENT_ATTRIBUTE');
  } catch (error) {
    yield put(actions.createOrUpdateShipmentTenantAttributes.failure(error as Error));
  }
}

export function* watchCreateOrUpdateShipmentTenantAttributes() {
  yield takeLatest(
    getType(actions.createOrUpdateShipmentTenantAttributes.request),
    createOrUpdateShipmentTenantAttributesAsync
  );
}

export const showCreateOrUpdateShipmentTenantAttributesFailure = () => {
  message.error(intl.formatMessage(messages.createShipmentIdentifiersFailure));
};

export function* watchCreateOrUpdateShipmentTenantAttributesFailure() {
  yield takeEvery(
    getType(actions.createOrUpdateShipmentTenantAttributes.failure),
    showCreateOrUpdateShipmentTenantAttributesFailure
  );
}

export default function* tenantAttributesOperations() {
  yield fork(watchFetchTenantAttributes);
  yield fork(watchCreateTenantAttributes);
  yield fork(watchCreateTenantAttributesFailure);
  yield fork(watchFetchShipmentTenantAttributes);
  yield fork(watchCreateOrUpdateShipmentTenantAttributes);
  yield fork(watchCreateOrUpdateShipmentTenantAttributesFailure);
}
