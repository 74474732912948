const shipmentDetailsModel = {
  mode: undefined,
  shipmentId: undefined,
  masterShipmentId: undefined,
  identifiers: [],
  status: {
    type: '',
    transitStatus: '',
    flag: {
      color: undefined,
      text: undefined,
    },
    arrivalForecast: '',
    progress: {
      displayMarker: false,
      markerColor: '',
      markerCompletion: 0,
    },
    stops: [],
    trackingMethod: '',
    trackingInfo: '',
    lastUpdated: undefined,
  },
  carrier: {
    name: undefined,
    phone: undefined,
    scac: undefined,
    mc: undefined,
    //ocean specific values
    vesselName: undefined,
    mmsi: undefined,
    imo: undefined,
    callSign: undefined,
  },
  route: {
    mode: undefined,
    routeDetails: {},
  },
  map: {},
};

export default shipmentDetailsModel;
