import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment-timezone';
import { shipmentCountsActions } from 'components/shipmentCounts/ducks';
import DateRangeCodes from 'components/shipmentCounts/enums/dateRangeCodes';
import { Radio } from 'ui-components';
import { RootState } from 'reducers';
import { withTheme, WithThemeProps } from 'contexts/ThemeContext';
import types from 'components/shipmentCounts/ducks/types';

const StyledRadio = styled(Radio)`
  label {
    margin-bottom: 0 !important;
  }
`;

interface DynamicDatePickupFilter extends WithThemeProps {
  dateType: 'pickupDate' | 'deliveryDate' | 'lastFreeDate';
  type: keyof typeof types;
  showTimeSelection?: boolean;
}

const Label = ({ type }: { type: DynamicDatePickupFilter['dateType'] }) => {
  let label;
  switch (type) {
    case 'pickupDate':
      label = (
        <FormattedMessage
          id="shipmentList.pickupFilter.pickUpDateRangeWithTimezoneWithTimezone"
          defaultMessage="PICK-UP DATE RANGE ({timezone})"
          tagName="label"
          values={{
            timezone: moment.tz(moment.tz.guess()).zoneAbbr(),
          }}
        />
      );
      break;
    case 'deliveryDate':
      label = (
        <FormattedMessage
          id="shipmentList.deliveryFilter.deliveryDateRangeWithTimezone"
          defaultMessage="DELIVERY DATE RANGE ({timezone})"
          tagName="label"
          values={{
            timezone: moment.tz(moment.tz.guess()).zoneAbbr(),
          }}
        />
      );
      break;
    case 'lastFreeDate':
      label = (
        <FormattedMessage
          id="shipmentList.oceanLastFreeDateFilter.lastFreeDayDateRangeWithTimezone"
          defaultMessage="LAST FREE DAY DATE RANGE ({timezone})"
          tagName="label"
          values={{
            timezone: moment.tz(moment.tz.guess()).zoneAbbr(),
          }}
        />
      );
      break;
  }
  return label;
};

const DynamicDateFilter: React.FC<DynamicDatePickupFilter> = (props) => {
  const currentTile = useSelector((state: RootState) => state.shipmentCountsReducer.currentTile);
  const dispatch = useDispatch();
  const intl = useIntl();

  const dynamicRangeOptions = Object.keys(DateRangeCodes.CODES).map((code) => ({
    label: intl.formatMessage({
      id: DateRangeCodes.DISPLAYABLE_CODES[code].id,
      defaultMessage: DateRangeCodes.DISPLAYABLE_CODES[code].defaultMessage,
    }),
    value: code,
  }));
  const radioValue = currentTile[props.dateType];
  return (
    <div id={currentTile.id} className="input-container form-group">
      <Label type={props.dateType} />
      <StyledRadio
        block
        onChange={(e: any) => {
          dispatch(shipmentCountsActions.changeSelectedDate(props.type, e.target.value));
        }}
        radioData={dynamicRangeOptions}
        styled
        theme={props.theme}
        value={radioValue}
      />
    </div>
  );
};

export default withTheme(DynamicDateFilter);
