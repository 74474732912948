import get from 'lodash/get';
import { LDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { OnboardingActions, useOnboardingDispatch } from './OnboardingContext';

export const flagDefaults = {
  showCarrierOnboardingUIv3: true,
  showTelematicWizardv3: true,
  showCarrierOnboardingLiveChatv3: false,
  redirectToMovementOnboarding: false,
  newTelematicsOnboarding: true,
  removeTelematicsSignatureScreen: false,
  featureMi146: false,
};

export type FlagsType = typeof flagDefaults;

export const useFeatureFlags = (): FlagsType => {
  const flags = useFlags();
  return { ...flagDefaults, ...flags } as FlagsType;
};

export const LaunchDarklyFeatureFlagProvider = ({ children }: { children: any }) => {
  return (
    <LDProvider
      clientSideID={get(window, 'portalGlobal.launchDarklyClientSideID') as unknown as string}
      context={{
        kind: 'user',
        key: 'ONBOARDING_UNAUTHENTICATED_USER',
      }}
      flags={flagDefaults}
    >
      <MemoFeatureFlagOnboardingStateUpdater />
      {children}
    </LDProvider>
  );
};

// Needed for legacy reasons because we decided to put feature flags
// onto onboarding state vs. just using the hook LD provides.
const FeatureFlagOnboardingStateUpdater = () => {
  const flags = useFeatureFlags();
  const dispatch = useOnboardingDispatch();
  React.useEffect(() => {
    dispatch({ type: OnboardingActions.SET_FEATURE_FLAGS, payload: flags });
  }, [flags, dispatch]);
  return null;
};

const MemoFeatureFlagOnboardingStateUpdater = React.memo(FeatureFlagOnboardingStateUpdater);
