import * as React from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { parse } from 'wellknown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Field, FieldProps, FormikErrors } from 'formik';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import styled from 'styled-components';
import { ReactComponent as IdleLocation } from 'assets/images/idle-location.svg';
import { ReactComponent as MapMarker } from 'assets/images/default-here-map-marker.svg';
import useDebounce from 'hooks/useDebounce';
import {
  createMapInstance,
  getMapOptions,
  initializeMap,
  removeAllGeofences,
  setPolygon,
  setMarker,
  setPolyline,
  setLinestring,
  addPolygonGeofenceToCurrentMap,
  addRadiusBasedGeofenceToCurrentMap,
  idleLocationMarker,
} from 'common/maps/map.service';
import MapLegend from 'components/legend/MapLegend';
import {
  LocationDetails,
  FenceType,
  EditLocationActions,
  FormValues,
  AnalyticsLocationProps,
  DistanceUnitsEnum,
  LatLng,
} from 'models';
import { Label, Input, InputErrorMessage } from 'components/common/forms/FormElements';
import { Button, Modal, Radio, Select } from 'ui-components';
import { polylineColor, polygonShade, primaryGrey500 } from 'styles/colors';
import { extractLatLng, getLatLngFromInputValue } from '../location.service';
import EditBaiduLocationMarker from './EditBaiduLocationMap';

interface EditLocationMapProps {
  locationMapDispatch: React.Dispatch<EditLocationActions>;
  visible: boolean;
  toggleOpen: () => void;
  userEnteredAddress: any;
  geofenceRangeInMinutes: number;
  mapConfig: any;
  geofence: LocationDetails['geofence'];
  address: LocationDetails['address'];
  setFieldValue: (field: any, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  locationMapState: InitialStateProps;
  distanceValue: number;
  timeValue: number;
  handleCustomGeofenceCoords: (coords: any) => void;
  toggleIsResetting: (value: boolean) => void;
  formErrors: FormikErrors<FormValues>;
  initialTimeValue: number;
  initialDistanceValue: number;
  initialGeofenceValue: FormValues['geofence'];
  analyticsData: AnalyticsLocationProps;
  defaultDistanceUnits: DistanceUnitsEnum;
  latLngInputValue: string | undefined;
  useAddressLatLngInMap: boolean;
  latLng: LatLng;
  isChinaAsCountry: boolean;
}

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
const MAP_ID = 'edit-location-map';
const mapOptions = getMapOptions();

const StyledMapContainer = styled.div`
  width: 100%;
  height: calc(75vh - 110px);
  background: ${primaryGrey500};
  position: relative;
`;

const StyledResetButton = styled(Button)`
  float: right;
`;

interface InitialStateProps {
  fenceType: FenceType;
  timeBasedValue: number;
  distanceBasedValue: number;
  polygonClosed: boolean;
  customGeofence: any;
}

function reducer(state: InitialStateProps, action: EditLocationActions) {
  switch (action.type) {
    case 'SET_TYPE':
      return {
        ...state,
        fenceType: action.payload,
      };
    case 'SET_TIME_VALUE':
      return {
        ...state,
        timeBasedValue: action.payload,
      };
    case 'SET_DISTANCE_VALUE':
      return {
        ...state,
        distanceBasedValue: action.payload,
      };
    case 'CLOSE_POLYGON':
      return {
        ...state,
        polygonClosed: action.payload,
      };
    case 'SET_CUSTOM_GEOFENCE':
      return {
        ...state,
        customGeofence: action.payload,
      };
    case 'RESET_VALUES':
      return {
        ...state,
        timeBasedValue: 0,
        distanceBasedValue: 0,
        polygonClosed: false,
        customGeofence: undefined,
      };
    default:
      throw new Error();
  }
}

const StyledModalTitle = styled('h2')`
  font-size: 2rem;
  font-weight: normal;
  margin-bottom: 0;
`;
let polygonLine: any;
let linestring = setLinestring();
let selectedPoints: any[] = [];
let currentPosition: any = {};
let polygon: any;
const EditLocationMap: React.FC<EditLocationMapProps & WrappedComponentProps> = (props) => {
  const {
    analyticsData,
    geofence,
    geofenceRangeInMinutes,
    mapConfig,
    toggleOpen,
    visible,
    userEnteredAddress,
    distanceValue,
    timeValue,
    handleCustomGeofenceCoords,
    locationMapDispatch,
    latLngInputValue,
    useAddressLatLngInMap,
    latLng,
    isChinaAsCountry,
  } = props;
  const [mapInstance, setMapInstance] = React.useState({} as any);
  const [address, setAddress] = React.useState({} as any);
  const [markerArray, setMarkerArray] = React.useState();
  const mapElement = React.useRef({} as any);
  const [geoJson, setGeoJson] = React.useState(null as any);
  const [lineArray, setLineArray] = React.useState([] as any);
  const [customPolyId] = React.useState();
  const [resetToDefaultMaps, forceReRenderOfDefaultMaps] = React.useState<number>(Date.now());
  const [idleLocationsArray, setIdleLocationsArray] =
    React.useState<Array<{ lat: number; lng: number; marker?: any }>>();
  const [baiduCustomPointsChange, setBaiduCustomPointsChange] = React.useState<number>(0);

  const debouncedDistanceValue = useDebounce(distanceValue, 1000);
  const debouncedTimeValue = useDebounce(timeValue, 1000);
  const initialState: InitialStateProps = {
    timeBasedValue: geofence.type === FenceType.TIME ? geofenceRangeInMinutes : (geofence.value as number),
    fenceType: geofence.type as FenceType,
    distanceBasedValue: geofence.type === FenceType.DISTANCE ? (geofence.value as number) : 0,
    polygonClosed: false,
    customGeofence: geofence,
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  React.useEffect(() => {
    if (!isEmpty(geofence)) {
      dispatch({ type: 'SET_CUSTOM_GEOFENCE', payload: geofence });
      dispatch({ type: 'SET_TYPE', payload: FenceType[geofence.type as FenceType] });
      locationMapDispatch({ type: 'SET_TYPE', payload: FenceType[geofence.type as FenceType] });
    }
  }, [geofence, locationMapDispatch]);

  React.useEffect(() => {
    async function getNewMap() {
      const result: any = await createMapInstance({
        mapId: MAP_ID,
        mapOptions,
        config: mapConfig,
        mapType: 'satellite',
      });
      setMapInstance(result);
    }
    if (mapConfig && isEmpty(mapInstance)) {
      setTimeout(() => getNewMap(), 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([mapConfig, mapInstance]));

  React.useEffect(function setupNewMapEffect() {
    let newAddress;
    // Check for an Autocomplete address change, and update the map with new address
    if (!isNil(userEnteredAddress)) {
      newAddress = {
        address1: `${get(userEnteredAddress, 'address.houseNumber')} ${get(userEnteredAddress, 'address.street')}`,
        city: get(userEnteredAddress, 'address.city'),
        state: get(userEnteredAddress, 'address.state'),
        postalCode: get(userEnteredAddress, 'address.postalCode'),
        country: get(userEnteredAddress, 'address.countryCode'),
      };
    }
    if (
      (!isEqual(address, get(props, 'address')) || !isEqual(newAddress, get(props, 'address'))) &&
      !isEmpty(mapInstance)
    ) {
      setAddress(newAddress || get(props, 'address'));
      if (has(analyticsData, 'idleLocations')) {
        const idleLocations = analyticsData.idleLocations ? extractLatLng(analyticsData.idleLocations) : [];
        const idleLocationsWithCustomMarker = idleLocations.map((location) => {
          return {
            ...location,
            marker: idleLocationMarker(),
          };
        });
        setIdleLocationsArray(idleLocationsWithCustomMarker);
      }

      const address: LocationDetails['address'] =
        newAddress || (get(props, 'data.address', {}) as LocationDetails['address']);
      const { latitude, longitude } = getLatLngFromInputValue(latLngInputValue);
      if (!useAddressLatLngInMap && latitude && longitude) {
        address.locationCoordinatesDto = { latitude, longitude };
      }

      const instance = initializeMap({
        geofenceRangeInMinutes:
          state.distanceBasedValue > 0 || get(state, 'customGeofence.type') === 'Polygon' ? 0 : geofenceRangeInMinutes,
        address,
        mapId: MAP_ID,
        mapInstance,
        idleLocations: idleLocationsArray,
      });
      instance
        .then((ref: any) => {
          setMapInstance(ref);
          setMarkerArray(ref.mapData.getObjects().length);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([address, mapInstance, props.address, userEnteredAddress]));

  React.useEffect(() => {
    if (has(mapInstance, 'mapData')) {
      const instance = initializeMap({
        geofenceRangeInMinutes: state.fenceType === FenceType.TIME ? geofenceRangeInMinutes : 0,
        address,
        mapId: MAP_ID,
        mapInstance,
        idleLocations: idleLocationsArray,
      });
      instance
        .then((ref: any) => {
          setGeoJson(parse(ref.mapData.getCenter().toString()));
          setMapInstance(ref);
        })
        .catch((err) => {
          console.log('error', err);
        });
    }

    function fetchLatLng() {
      try {
        const { latitude: lat = 0, longitude: lng = 0 } = getLatLngFromInputValue(latLngInputValue);
        const distanceValueByUnits =
          props.defaultDistanceUnits === DistanceUnitsEnum.MILES
            ? state.distanceBasedValue * 1609.344
            : state.distanceBasedValue;
        const circle = addRadiusBasedGeofenceToCurrentMap(mapInstance, distanceValueByUnits, true, { lat, lng });
        if (circle) {
          setGeoJson(parse(circle.getCenter().toString()));
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (state.distanceBasedValue > 0 && has(mapInstance, 'mapData') && state.fenceType === FenceType.DISTANCE) {
      removeAllGeofences({ mapInstance });
      fetchLatLng();
    }
  }, [
    address,
    mapInstance,
    state.distanceBasedValue,
    state.fenceType,
    props.defaultDistanceUnits,
    latLngInputValue,
    geofenceRangeInMinutes,
    idleLocationsArray,
  ]);

  React.useEffect(() => {
    if (state.timeBasedValue > 0 && has(mapInstance, 'mapData') && state.fenceType === FenceType.TIME) {
      removeAllGeofences({ mapInstance });
      const instance = initializeMap({
        geofenceRangeInMinutes: state.timeBasedValue,
        address,
        mapId: MAP_ID,
        mapInstance,
        idleLocations: idleLocationsArray,
      });
      instance
        .then((ref: any) => {
          setGeoJson(parse(ref.mapData.getCenter().toString()));
          setMapInstance(ref);
        })
        .catch((err) => {
          console.log('error', err);
        });
    }
  }, [address, idleLocationsArray, mapInstance, state.distanceBasedValue, state.fenceType, state.timeBasedValue]);

  React.useEffect(
    function addMapObjectsEffect() {
      if (state.fenceType === FenceType.TIME && state.timeBasedValue === 0 && has(mapInstance, 'mapData')) {
        removeAllGeofences({ mapInstance });
        const instance = initializeMap({
          geofenceRangeInMinutes,
          address,
          mapId: MAP_ID,
          mapInstance,
          idleLocations: idleLocationsArray,
        });
        instance
          .then((ref: any) => {
            setGeoJson(parse(ref.mapData.getCenter().toString()));
            setMapInstance(ref);
          })
          .catch((err) => {
            console.log('error', err);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompareMemoize([
      state.timeBasedValue,
      state.distanceBasedValue,
      state.fenceType,
      markerArray,
      address,
      geofenceRangeInMinutes,
      resetToDefaultMaps,
    ])
  );

  React.useEffect(function addCustomMapObjectEffect() {
    if (
      !isEmpty(mapInstance) &&
      !isNil(get(geofence, 'geometry.type')) &&
      get(geofence, 'geometry.type') !== 'Point' &&
      state.fenceType === FenceType.POLYGON
    ) {
      removeAllGeofences({ mapInstance });
      addPolygonGeofenceToCurrentMap(mapInstance, geofence, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([geofence.type, state.fenceType, mapInstance]));

  React.useEffect(
    function handleChangeFenceTypeEffect() {
      if (props.locationMapState.fenceType === FenceType.TIME) {
        dispatch({ type: 'SET_TYPE', payload: FenceType.TIME });
        dispatch({
          type: 'SET_TIME_VALUE',
          payload: props.locationMapState.timeBasedValue,
        });
      }

      if (props.locationMapState.fenceType === FenceType.DISTANCE) {
        dispatch({ type: 'SET_TYPE', payload: FenceType.DISTANCE });
        dispatch({ type: 'SET_DISTANCE_VALUE', payload: props.locationMapState.distanceBasedValue });
      }
      return () => {
        dispatch({ type: 'RESET_VALUES' });
      };
    },
    [
      geofence.type,
      geofence.value,
      props.locationMapState.fenceType,
      props.locationMapState.timeBasedValue,
      props.locationMapState.distanceBasedValue,
    ]
  );

  React.useEffect(function setupMapEventListenersEffect() {
    const mapTarget = mapElement;

    function changeCursor() {
      if (mapElement) {
        mapElement.current.style.cursor = 'crosshair';
      }
    }

    function handleTapEvent(ev: any) {
      currentPosition = setMarker({
        mapInstance,
        xCoord: ev.currentPointer.viewportX,
        yCoord: ev.currentPointer.viewportY,
      });
      // `.a` is the uglified object that holds the lat/lng coords
      selectedPoints.push(currentPosition.a);
      linestring.pushPoint(currentPosition.a);
      if (selectedPoints.length > 1) {
        polygonLine = setPolyline({
          linestring,
          options: {
            style: {
              lineWidth: 3,
              strokeColor: polylineColor,
            },
          },
        });
        setLineArray([...lineArray, polygonLine]);
        polygon = setPolygon({
          linestring,
          options: {
            style: {
              fillColor: polygonShade,
              lineWidth: 3,
              strokeColor: polylineColor,
            },
          },
        });
        if (mapInstance) {
          mapInstance.geofences.push(polygon);
          mapInstance.geofences.push(polygonLine);
          mapInstance.mapData.addObject(polygonLine);
        }
      }
    }

    if (!isEmpty(mapInstance) && state.fenceType === FenceType.POLYGON) {
      mapInstance.mapData.addEventListener('pointerenter', changeCursor);
      mapInstance.mapData.addEventListener('tap', handleTapEvent);
    }

    if (state.fenceType !== FenceType.POLYGON) {
      if (!isEmpty(mapElement.current)) {
        mapElement.current.style.cursor = 'default';
      }
      if (!isEmpty(mapInstance)) {
        mapInstance.mapData.removeEventListener('pointerenter', changeCursor);
        mapInstance.mapData.removeEventListener('tap', handleTapEvent);
      }
    }
    return () => {
      if (!isEmpty(mapTarget.current)) {
        mapTarget.current.style.cursor = 'default';
      }
      if (!isEmpty(mapInstance)) {
        mapInstance.mapData.removeEventListener('pointerenter', changeCursor);
        mapInstance.mapData.removeEventListener('tap', handleTapEvent);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([state.fenceType, mapInstance, mapInstance.mapData, lineArray]));

  React.useEffect(() => {
    handleCustomGeofenceCoords(geoJson);
  }, [geoJson, handleCustomGeofenceCoords]);

  const saveDrawing = () => {
    if (!isChinaAsCountry) {
      mapInstance.geofences.push(polygon);
      mapInstance.mapData.addObject(polygon);
      setGeoJson(parse(polygon.getGeometry().toString()));
      dispatch({
        type: 'SET_CUSTOM_GEOFENCE',
        payload: {
          ...state.customGeofence,
          type: FenceType.POLYGON,
          geometry: parse(polygon.getGeometry().toString()),
        },
      });
      dispatch({
        type: 'SET_TYPE',
        payload: FenceType.POLYGON,
      });
    } else {
      setBaiduCustomPointsChange((pre) => pre + 1);
    }
  };

  const clearDrawing = () => {
    linestring = setLinestring();
    selectedPoints = [];
    removeAllGeofences({ mapInstance, idToSave: customPolyId });
    setLineArray([]);
    props.setFieldValue('timeBasedValue', 0);
    props.setFieldValue('distanceValue', 0);
    dispatch({ type: 'CLOSE_POLYGON', payload: false });
    dispatch({
      type: 'RESET_VALUES',
    });
  };
  React.useEffect(() => {
    if (debouncedDistanceValue) {
      dispatch({ type: 'SET_DISTANCE_VALUE', payload: parseInt(debouncedDistanceValue, 10) });
    }
    if (debouncedTimeValue) {
      dispatch({ type: 'SET_TIME_VALUE', payload: parseInt(debouncedTimeValue, 10) });
    }
  }, [debouncedDistanceValue, debouncedTimeValue]);

  const resetToDefaults = () => {
    props.setFieldValue('fenceType', props.locationMapState.fenceType);
    clearDrawing();
    forceReRenderOfDefaultMaps(Date.now());
    props.toggleIsResetting(true);
    if (props.locationMapState.fenceType === FenceType.TIME) {
      dispatch({ type: 'SET_TYPE', payload: FenceType.TIME });
      dispatch({ type: 'SET_TIME_VALUE', payload: props.locationMapState.timeBasedValue });
      props.setFieldValue('timeValue', props.initialTimeValue);
    }

    if (props.locationMapState.fenceType === FenceType.DISTANCE) {
      props.locationMapDispatch({ type: 'SET_TYPE', payload: FenceType.DISTANCE });
      props.locationMapDispatch({ type: 'SET_DISTANCE_VALUE', payload: props.locationMapState.distanceBasedValue });
      dispatch({ type: 'SET_TYPE', payload: FenceType.DISTANCE });
      dispatch({ type: 'SET_DISTANCE_VALUE', payload: props.locationMapState.distanceBasedValue });
      props.setFieldValue('distanceValue', props.initialDistanceValue);
    }

    if (props.locationMapState.fenceType === FenceType.POLYGON) {
      props.locationMapDispatch({ type: 'SET_TYPE', payload: FenceType.POLYGON });
      props.locationMapDispatch({ type: 'SET_CUSTOM_GEOFENCE', payload: props.locationMapState.customGeofence });
      setTimeout(() => dispatch({ type: 'SET_TYPE', payload: FenceType.POLYGON }), 0);
      props.setFieldValue('geofence', props.initialGeofenceValue);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={(e: React.ChangeEvent<any>) => {
          resetToDefaults();
          toggleOpen();
        }}
        onOk={() => {
          if (state.fenceType === FenceType.TIME) {
            props.locationMapDispatch({ type: 'SET_TYPE', payload: FenceType.TIME });
            props.locationMapDispatch({ type: 'SET_TIME_VALUE', payload: state.timeBasedValue });
          }

          if (state.fenceType === FenceType.DISTANCE) {
            props.locationMapDispatch({ type: 'SET_TYPE', payload: FenceType.DISTANCE });
            props.locationMapDispatch({ type: 'SET_DISTANCE_VALUE', payload: state.distanceBasedValue });
          }
          if (state.fenceType === FenceType.POLYGON) {
            const geometry = isChinaAsCountry ? geoJson : parse(polygon.getGeometry().toString());
            props.locationMapDispatch({
              type: 'SET_CUSTOM_GEOFENCE',
              payload: {
                ...state.customGeofence,
                geometry,
              },
            });

            props.locationMapDispatch({ type: 'SET_TYPE', payload: FenceType.POLYGON });
            geofence.type = state.fenceType;
            geofence.geometry = geometry;
            handleCustomGeofenceCoords(geofence.geometry);
          }
          toggleOpen();
        }}
        okButtonProps={{ disabled: !!props.formErrors.distanceValue || !!props.formErrors.timeValue }}
        okText={<FormattedMessage id="locationDetails.editLocationModal.okText" defaultMessage="Save" />}
        cancelText={<FormattedMessage id="locationDetails.editLocationModal.cancelText" defaultMessage="Cancel" />}
        title={<StyledModalTitle>Geofence Setup</StyledModalTitle>}
        width={'95%'}
        destroyOnClose
        style={{ top: 20 }}
        bodyStyle={{ width: '100%' }}
      >
        <div>
          <Row style={{ marginBottom: '20px' }}>
            <Col xs={10} sm={8}>
              <Label>
                <FormattedMessage id="locationDetails.edit.labels.shortAddress" defaultMessage="Address" />
              </Label>
              <p>{userEnteredAddress && userEnteredAddress.label}</p>
            </Col>
            <Col>
              <Label>
                <FormattedMessage
                  id="locationDetails.edit.labels.latitudeLongitude"
                  defaultMessage="Latitude, Longitude"
                />
              </Label>
              <p data-locator="edit-geofence-lat-lng" data-testId="edit-geofence-lat-lng">
                {latLngInputValue && latLngInputValue}
              </p>
            </Col>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <Col xs={8} md={4}>
              <Label>
                <FormattedMessage id="locationDetails.edit.geofenceTypeLabel" defaultMessage="Geofence Type" />
              </Label>
              <Field
                name="fenceType"
                // eslint-disable-next-line react/jsx-no-bind
                render={({ field }: FieldProps) => (
                  <Select
                    value={state.fenceType}
                    style={{ fontSize: '1.2rem', width: '100%', minWidth: '100%' }}
                    // tslint:disable: jsx-no-lambda
                    // eslint-disable-next-line
                    onChange={(value: FenceType) => {
                      clearDrawing();
                      dispatch({ type: 'SET_TYPE', payload: FenceType[value] as FenceType });
                      if (FenceType[value] === FenceType.TIME) {
                        dispatch({ type: 'SET_TIME_VALUE', payload: props.geofenceRangeInMinutes });
                        props.setFieldValue('timeValue', get(props, 'geofenceRangeInMinutes', 900));
                      }
                      props.setFieldValue('fenceType', FenceType[value]);
                    }}
                    dataSource={[
                      {
                        value: FenceType.POLYGON,
                        displayValue: props.intl.formatMessage({
                          id: 'locationDetails.edit.fenceTypes.custom',
                          defaultMessage: 'Custom',
                        }),
                      },
                      {
                        value: FenceType.TIME,
                        displayValue: props.intl.formatMessage({
                          id: 'locationDetails.edit.fenceTypes.eta',
                          defaultMessage: 'Time-based',
                        }),
                      },
                      {
                        value: FenceType.DISTANCE,
                        displayValue: props.intl.formatMessage({
                          id: 'locationDetails.edit.fenceTypes.radius',
                          defaultMessage: 'Radius',
                        }),
                      },
                    ]}
                  />
                )}
                value={FenceType.TIME}
              />
            </Col>
            {state.fenceType === FenceType.TIME && (
              <>
                <Col xs={8} md={4}>
                  <Label inputid="timeValue">
                    <FormattedMessage id="locationDetails.edit.units.minutes" defaultMessage="Minutes" />
                  </Label>
                  <Field
                    name="timeValue"
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={props.handleChange}
                    // eslint-disable-next-line react/jsx-no-bind
                    render={({ field, form }: FieldProps) => {
                      return (
                        <Input
                          id="timeValue"
                          defaultValue={`${state.timeBasedValue}`}
                          type="text"
                          name={field.name}
                          style={{ fontSize: '1.2rem' }}
                          value={get(field, 'value', state.timeBasedValue)}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                  {props.formErrors.timeValue && (
                    <FormattedMessage
                      id="locationDetails.edit.errorMessages.invalidGeofenceMinutes"
                      defaultMessage="Please enter a value greater than 0"
                    >
                      {(message) => <InputErrorMessage errorMessage={`${message}`} />}
                    </FormattedMessage>
                  )}
                </Col>
                {isChinaAsCountry && (
                  <Col xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <b>
                      <FormattedMessage
                        id="locationDetails.edit.baiduMinutesHelpText"
                        defaultMessage="Please note that time-based geofences are not depicted on the map below for China. This does not impact the creation of the geofence."
                      />
                    </b>
                  </Col>
                )}
              </>
            )}
            {state.fenceType === FenceType.DISTANCE && (
              <Col xs={8} md={5}>
                <Label inputid={'distanceValue'}>
                  {props.defaultDistanceUnits === DistanceUnitsEnum.METERS ? (
                    <FormattedMessage id="locationDetails.edit.units.meters" defaultMessage="Meters" />
                  ) : (
                    <FormattedMessage id="locationDetails.edit.units.miles" defaultMessage="Miles" />
                  )}
                </Label>
                <Field
                  name="distanceValue"
                  // eslint-disable-next-line react/jsx-no-bind
                  render={({ field, form }: FieldProps) => {
                    return (
                      <>
                        <Input
                          id="distanceValue"
                          data-testid="distance-input"
                          defaultValue={`${state.distanceBasedValue}`}
                          onChange={field.onChange}
                          style={{ fontSize: '1.2rem' }}
                          name={field.name}
                          value={get(field, 'value', state.distanceBasedValue)}
                        />
                        <Radio
                          value={props.defaultDistanceUnits}
                          onChange={(e: React.ChangeEvent<any>) => {
                            props.setFieldValue('distanceUnits', e.target.value);
                          }}
                          radioData={[
                            { value: DistanceUnitsEnum.METERS, label: 'Meters' },
                            { value: DistanceUnitsEnum.MILES, label: 'Miles' },
                          ]}
                        />
                      </>
                    );
                  }}
                />
                {props.formErrors.distanceValue && (
                  <FormattedMessage
                    id="locationDetails.edit.errorMessages.invalidRadiusValue"
                    defaultMessage="Please enter a value greater than 0"
                  >
                    {(message) => <InputErrorMessage errorMessage={`${message}`} />}
                  </FormattedMessage>
                )}
              </Col>
            )}
            {state.fenceType === FenceType.POLYGON && (
              <>
                <Col xs={4}>
                  <FormattedMessage
                    id="locationDetails.edit.customGeofenceHelpText"
                    defaultMessage="Click in the map to draw a custom shape for your geofence."
                  />
                </Col>
                <Col xs={4}>
                  <Button type="primary" clickFn={saveDrawing} disabled={lineArray.length < 1 && !isChinaAsCountry}>
                    <FormattedMessage id="locationDetails.edit.finishDrawing" defaultMessage="Finish" />
                  </Button>
                </Col>
              </>
            )}
            <Col />
            <Col xs={6}>
              <StyledResetButton clickFn={resetToDefaults} type="primary-transparent">
                <FormattedMessage
                  id="locationDetails.editLocationModal.resetToDefault"
                  defaultMessage="Reset to Default"
                />
              </StyledResetButton>
            </Col>
          </Row>
        </div>
        <StyledMapContainer ref={mapElement} id={MAP_ID} style={{ display: isChinaAsCountry ? 'none' : 'block' }}>
          <div>
            <MapLegend
              title={{ id: 'map.legend.defaultTitle', defaultMessage: 'Map Legend' }}
              items={[
                {
                  icon: <MapMarker />,
                  label: {
                    id: 'map.legend.location',
                    defaultMessage: 'LOCATION',
                  },
                },
                {
                  icon: <IdleLocation />,
                  label: {
                    id: 'map.legend.idleTrucks',
                    defaultMessage: 'IDLE TRUCKS',
                  },
                },
              ]}
            />
            {state.fenceType === FenceType.POLYGON && (
              <Button
                style={{ position: 'absolute', top: '12%', left: '3%', zIndex: '10' }}
                type="default"
                clickFn={clearDrawing}
              >
                <FormattedMessage id="locationDetails.edit.clearDrawing" defaultMessage="Clear" />
              </Button>
            )}
          </div>
        </StyledMapContainer>
        {isChinaAsCountry && (
          <StyledMapContainer>
            <EditBaiduLocationMarker
              defaultDistanceUnits={props.defaultDistanceUnits}
              state={state}
              latLng={latLng}
              geoJson={geoJson}
              setGeoJson={setGeoJson}
              baiduCustomPointsChange={baiduCustomPointsChange}
            />
          </StyledMapContainer>
        )}
      </Modal>
    </>
  );
};

export default injectIntl(EditLocationMap);
