import { Component } from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect } from 'antd';
import './Select.scss';
import styled from 'styled-components';
import { rgba } from 'polished';
import omit from 'lodash/omit';
import get from 'lodash/get';

import { ThemeContext, defaultThemeShape } from '../../styles/theme';

const StyledSelect = styled.div`
  .ant-select-selector:hover {
    border: 1px solid ${(props) => props.theme.primaryColor} !important;
  }
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-focused .ant-select-selector {
    border: 1px solid ${(props) => props.theme.primaryColor} !important;
    box-shadow: 0 0 0 2px ${(props) => rgba(props.theme.primaryColor, 0.2)} !important;
  }
`;

export default class Select extends Component {
  static propTypes = {
    mode: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    custom: PropTypes.string,
    showSearch: PropTypes.bool,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    icon: PropTypes.string,
    theme: PropTypes.shape(defaultThemeShape),
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  static contextType = ThemeContext;
  handleDropdownChange = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    const { mode = 'default', label, placeholder, custom, showSearch, defaultValue, onChange, ...props } = this.props;
    return (
      <StyledSelect theme={this.props.theme || this.context} className="select ant-form-vertical">
        {label && (
          <div className="ant-form-item-label">
            <label title={label}>{label}</label>
          </div>
        )}
        <div className="select__dropdown">
          <AntSelect
            aria-expanded={this.state.open}
            showSearch={showSearch}
            className="select__input"
            dropdownStyle={{ zIndex: 9999 }}
            onDropdownVisibleChange={this.handleDropdownChange}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
            mode={mode}
            {...custom}
            {...omit(props, 'dataSource')}
          >
            {this.props.dataSource.map((item) => {
              return (
                <AntSelect.Option
                  key={item.value}
                  value={item.value}
                  label={item.label || null}
                  data-testid={get(item, 'testid', `select-option-${item.value}`)}
                  data-locator={get(item, 'testid', `select-option-${item.value}`)}
                >
                  {item.displayValue || item.value}
                </AntSelect.Option>
              );
            })}
          </AntSelect>
        </div>
      </StyledSelect>
    );
  }
}
