import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getPrincipalFromState } from 'common/authorizations';
import Footer from './Footer';

const mapStateToProps = (state) => ({
  isLoggedIn: state.authReducer.isLoggedIn,
  isLoggingIn: state.loginReducer.isLoggingIn,
  principal: getPrincipalFromState(state),
  error: state.loginReducer.error,
});

export default injectIntl(connect(mapStateToProps, null)(Footer));
