import { change, Field, reduxForm, clearSubmitErrors } from 'redux-form';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import get from 'lodash/get';
import { requiredValidation } from '../../../common/FormValidations';
import { renderInput } from '../../common/forms/FormInputs';
import { addEditFormConstants } from '../AddEditShipmentConstants';
import AddEditShipmentButtons from '../AddEditShipmentButtons/AddEditShipmentButtons';
import { validateLaneDetailCarrierInfo } from '../formValidators';
import './LaneDetailForm.scss';
import CarrierType from '../../shipment/common/enums/carrierType';

const messages = defineMessages({
  enterASCAC: {
    id: 'addShipment.laneDetailForm.enterASCAC',
    defaultMessage: 'Enter a SCAC',
  },
  enterAMC: {
    id: 'addShipment.laneDetailForm.enterAMC',
    defaultMessage: 'Enter a MC#',
  },
  enterTheDOT: {
    id: 'addShipment.laneDetailForm.enterTheDOT',
    defaultMessage: 'Enter the DOT #',
  },
  enterEUID: {
    id: 'addShipment.laneDetailForm.enterEUID',
    defaultMessage: 'Enter EU ID',
  },
  enterACarrierID: {
    id: 'addShipment.laneDetailForm.enterACarrierID',
    defaultMessage: 'Enter a Carrier ID',
  },
  carrierID: {
    id: 'addShipment.laneDetailForm.carrierID',
    defaultMessage: ' Carrier ID',
  },
});

let LaneDetailForm = (props) => {
  const { handleSubmit } = props;

  const renderCarrierIdPlaceholder = () => {
    const carrierType = get(props, 'formValues.carrierType', props.initialValues.carrierType);
    switch (carrierType) {
      case CarrierType.SCAC:
        return props.intl.formatMessage(messages.enterASCAC);
      case CarrierType.MC:
        return props.intl.formatMessage(messages.enterAMC);
      case CarrierType.DOT:
        return props.intl.formatMessage(messages.enterTheDOT);
      case CarrierType.EU:
        return props.intl.formatMessage(messages.enterEUID);
      default:
        return props.intl.formatMessage(messages.enterACarrierID);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="lane-detail-form">
      <div className="carrier-information">
        <h4 className="form-header">
          <FormattedMessage id="addShipment.laneDetailForm.carrierInformation" defaultMessage="Carrier Information" />
        </h4>
        <div className="input-container form-group maintain-width">
          <FormattedMessage id="addShipment.laneDetailForm.carrierType" defaultMessage="Carrier Type" tagName="label" />
          <div className="carrier-type-selector">
            {addEditFormConstants[props.step.id].carrierTypes.map((carrier, index) => (
              // eslint-disable-next-line
              <div
                className={`carrier-selection ${
                  get(props, 'formValues.carrierType', props.initialValues.carrierType) === carrier ? 'selected' : ''
                }`}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => {
                  props.dispatch(change('AddEditShipment', 'carrierType', carrier));
                  props.setCarrierType(carrier);
                  props.dispatch(clearSubmitErrors('AddEditShipment'));
                }}
              >
                <i className="material-icons">check</i>
                {carrier}
              </div>
            ))}
          </div>
          <Field type="hidden" component="input" name="carrierType" />
        </div>
        <Field
          type="text"
          label={props.intl.formatMessage(messages.carrierID)}
          component={renderInput}
          validate={[requiredValidation]}
          name="carrierId"
          // eslint-disable-next-line react/jsx-no-bind
          normalize={(value) => value && value.toUpperCase()}
          placeholder={renderCarrierIdPlaceholder()}
        />
      </div>
      {props.submitFailed && (
        <p className="form-error">
          <FormattedMessage
            id="addShipment.laneDetailForm.submitFailedMessage"
            defaultMessage="We are sorry but we are unable to match this carrier to the ID entered. Please try again."
          />
        </p>
      )}
      <AddEditShipmentButtons
        step={props.step}
        navigateStep={props.navigateStep}
        onSubmit={props.navigateStep}
        cancel={props.cancel}
      />
    </form>
  );
};

LaneDetailForm = reduxForm({
  form: 'AddEditShipment',
  destroyOnUnmount: false,
  validate: validateLaneDetailCarrierInfo,
})(LaneDetailForm);

export default injectIntl(LaneDetailForm);
