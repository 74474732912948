import * as types from './types';

export const initialState = {
  loginError: false,
  ssoError: false,
  previousSessionError: false,
  isLoggingIn: false,
  data: {
    usernameInput: '',
  },
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SESSION_RENEW_SUCCESS:
      return {
        ...state,
        previousSessionError: false,
        isLoggingIn: false,
        loginError: false,
        ssoError: false,
        principal: action.principal,
      };

    case types.SESSION_FAILURE:
      return {
        ...state,
        previousSessionError: true,
        isLoggingIn: false,
      };

    case types.LOGOUT:
      return {
        ...state,
        isLoggingIn: false,
        sessionDialogOpen: false,
      };

    case types.SWITCH_ACCOUNTS:
      return { ...initialState };

    default:
      return state;
  }
};

export default loginReducer;
