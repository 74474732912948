import * as React from 'react';
import Chart from 'chart.js';
import isNil from 'lodash/isNil';
import moment from 'moment';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Card } from 'ui-components';
import {
  createGeofenceAccuracyDataSet,
  createVolumeDataSet,
  createDwellTimeDataSet,
  createTrackingComplianceDataSet,
} from 'common/charts/charts.service';
import { bluish } from 'styles/colors';
import { ShipmentVolumesModel, DwellTimesModel, TrackingComplianceModel, GeofencePerformanceModel } from 'models';

interface KpiChartProps extends WrappedComponentProps {
  title: string | React.ReactNode;
  chartData: ShipmentVolumesModel[] | DwellTimesModel[] | TrackingComplianceModel[] | GeofencePerformanceModel[];
  chartType: string;
  kpi: string;
}

const KpiChart: React.FC<KpiChartProps> = (props) => {
  const { chartType: type, kpi, chartData, title, intl } = props;
  const [dataForChart, setData] = React.useState<Array<{ x: string; y: number | string }> | undefined>();
  const [dataOptions, setDataOptions] = React.useState<Chart.ChartConfiguration | undefined>();
  const ref = React.useRef<HTMLCanvasElement>(null);
  const myChart = React.useRef<Chart>();

  React.useEffect(() => {
    switch (kpi) {
      case 'dwellTimes':
        setData(createDwellTimeDataSet(chartData as DwellTimesModel[]));
        break;
      case 'accuracy':
        setData(createGeofenceAccuracyDataSet(chartData as GeofencePerformanceModel[]));
        break;

      case 'volume':
        setData(createVolumeDataSet(chartData as ShipmentVolumesModel[]));
        break;
      case 'trackingCompliance':
        setData(createTrackingComplianceDataSet(chartData as TrackingComplianceModel[]));
        break;
    }
  }, [kpi, chartData]);

  React.useEffect(() => {
    switch (kpi) {
      case 'accuracy':
        setDataOptions({
          type,
          data: {
            datasets: [
              {
                data: dataForChart,
                backgroundColor: bluish,
                borderColor: bluish,
                fill: false,
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            title: {
              fontSize: 21,
            },
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: 'month',
                  },
                  distribution: 'linear',
                  ticks: {
                    callback: (value: string) => {
                      return moment(value, 'MMM YYYY').isValid() ? value : '';
                    },
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback: (value: number) => {
                      return `${value}%`;
                    },
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                label(tooltipItem: any, data: any) {
                  let label = data.datasets[tooltipItem.datasetIndex].label || '';
                  const value = tooltipItem.value;
                  if (label) {
                    label += `: ${value}%`;
                  }
                  return `${value}% - ${moment(tooltipItem.label).format('MMM YYYY')}`;
                },
              },
            },
          },
        });
        break;
      case 'volume':
        setDataOptions({
          type,
          data: {
            datasets: [
              {
                data: dataForChart,
                backgroundColor: bluish,
                borderColor: bluish,
                fill: false,
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            title: {
              fontSize: 21,
            },
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: 'month',
                  },
                  distribution: 'linear',
                  ticks: {
                    callback: (value: string) => {
                      return moment(value, 'MMM YYYY').isValid() ? value : '';
                    },
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                label(tooltipItem: any, data: any) {
                  const value = tooltipItem.value;
                  return intl.formatMessage(
                    {
                      id: 'locationDetails.kpiCharts.tooltips.volumeTooltip',
                      defaultMessage: `${value} total shipments`,
                    },
                    { value }
                  );
                },
              },
            },
          },
        });
        break;
      case 'dwellTimes':
        setDataOptions({
          type,
          data: {
            datasets: [
              {
                data: dataForChart,
                backgroundColor: bluish,
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label(tooltipItem: any, data: any) {
                  let label = data.datasets[tooltipItem.datasetIndex].label || '';
                  const value = tooltipItem.value;
                  if (label) {
                    label += `: ${value} minutes`;
                  }
                  return intl.formatMessage(
                    {
                      id: 'locationDetails.kpiCharts.tooltips.dwellTimesTooltip',
                      defaultMessage: `${parseInt(value, 10).toLocaleString(undefined, { useGrouping: true })} minutes`,
                    },
                    { value: parseInt(value, 10).toLocaleString(undefined, { useGrouping: true }) }
                  );
                },
              },
            },
            // scaleShowValues: true,
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: 'month',
                  },
                  distribution: 'linear',
                  ticks: {
                    autoSkip: false,
                    stepSize: 1,
                    callback: (value: string) => {
                      return moment(value, 'MMM YYYY').isValid() ? value : '';
                    },
                  },
                },
              ],
              yAxes: [
                {
                  ticks: { beginAtZero: true },
                },
              ],
            },
          },
        });
        break;
      case 'trackingCompliance':
        setDataOptions({
          type,
          data: {
            datasets: [
              {
                data: dataForChart,
                backgroundColor: bluish,
                borderColor: bluish,
                fill: false,
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            title: {
              fontSize: 21,
            },
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: 'month',
                  },
                  distribution: 'linear',
                  ticks: {
                    callback: (value: string) => {
                      return moment(value, 'MMM YYYY').isValid() ? value : '';
                    },
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback: (value: number) => {
                      return `${value}%`;
                    },
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                label(tooltipItem: any, data: any) {
                  const value = tooltipItem.value;
                  return ` ${value}%`;
                },
              },
            },
          },
        });
        break;
    }
  }, [dataForChart, kpi, type, intl]);

  React.useEffect(() => {
    let chartRef: CanvasRenderingContext2D | null;
    function buildChart() {
      if (typeof myChart.current !== 'undefined') {
        if (!isNil(myChart.current)) {
          myChart.current.destroy();
        }
      }
      if (!isNil(ref) && !isNil(ref.current)) {
        chartRef = ref.current.getContext('2d');
        if (!isNil(chartRef)) {
          myChart.current = new Chart(chartRef, dataOptions as Chart.ChartConfiguration);
        }
      }
    }
    buildChart();
  }, [dataForChart, type, dataOptions]);

  return (
    <Card>
      <h3>{title}</h3>
      <canvas id={kpi} ref={ref} aria-label={`Displaying graph for ${title}`} role="button" />
    </Card>
  );
};

export default injectIntl(KpiChart);
