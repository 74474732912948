import { connect } from 'react-redux';

import { getPrincipalFromState } from 'common/authorizations';
import FleetListComponent from './FleetListComponent';

import {
  buildGetNextFleetPageAction,
  buildResetCarrierSearchResult,
} from '../../components/carrierFleetListComponent/ducks/carrierSearchReducer.js';

const mapStateToProps = (state) => {
  const { carrierSearchReducer } = state;

  return {
    ...carrierSearchReducer,
    previousSearchQuery: carrierSearchReducer.previousSearchQuery,
    principal: getPrincipalFromState(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadMoreVehicles: (previousSearchQuery) => dispatch(buildGetNextFleetPageAction(previousSearchQuery)),
  resetCarrierResult: () => dispatch(buildResetCarrierSearchResult()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FleetListComponent);
