import * as React from 'react';
import { APP_VERSION } from 'common/AppConstants';

const CurrentVersion: React.FC<{}> = () => {
  return (
    <div className={'m-5 text-center'} style={{ fontSize: 26 }}>
      Current Application Version: <span data-locator="current-version">{APP_VERSION}</span>
    </div>
  );
};

export default CurrentVersion;
