import * as React from 'react';
import { Subtract } from 'utility-types';
import { Theme } from '../components/settings/BrandTheming/models/Theme';
export const ThemeContext = React.createContext<Theme | undefined>(undefined);
export interface WithThemeProps {
  theme: Theme;
}

export const withTheme = <P extends WithThemeProps>(Component: React.ComponentType<P>) => {
  class WithTheme extends React.Component<Subtract<P, WithThemeProps>, {}> {
    public static displayName = `WithTheme(${Component.displayName || Component.name || 'Component'})`;
    public render() {
      const props = this.props as P;
      return <ThemeContext.Consumer>{(theme) => <Component {...props} theme={theme} />}</ThemeContext.Consumer>;
    }
  }
  return WithTheme;
};
