import { FormattedMessage } from 'react-intl';

import CheckList from 'components/common/checkList/CheckList';
import filterMessages from 'components/filter/modes/filterMessages.json';
import { getOceanDemurrageEligibleValue } from '../OceanDemurrageRiskFilter';

const filterOptions = [
  {
    id: filterMessages.customsHold.id,
    dataKey: filterMessages.customsHold.defaultMessage,
    labelText: <FormattedMessage {...filterMessages.customsHold} />,
    propertyName: 'customsHold',
    checked: false,
  },
  {
    id: filterMessages.lineHolds.id,
    dataKey: filterMessages.lineHolds.defaultMessage,
    labelText: <FormattedMessage {...filterMessages.lineHolds} />,
    propertyName: 'lineHolds',
    checked: false,
  },
  {
    id: filterMessages.otherHolds.id,
    dataKey: filterMessages.otherHolds.defaultMessage,
    labelText: <FormattedMessage {...filterMessages.otherHolds} />,
    propertyName: 'otherHolds',
    checked: false,
  },
  {
    id: filterMessages.demurrageRisk.id,
    dataKey: filterMessages.demurrageRisk.defaultMessage,
    labelText: <FormattedMessage {...filterMessages.demurrageRisk} />,
    propertyName: 'demurrageRisk',
    checked: false,
  },
];

const QvsOceanHoldsDemurrage = (props: any) => {
  const nextFilterOptions = filterOptions.map((item) => ({
    ...item,
    checked:
      props.filter.oceanActiveHolds.includes(item.dataKey) ||
      getOceanDemurrageEligibleValue(props.filter.oceanDemurrageEligible).includes(item.dataKey),
  }));

  const handleCheck = (checkboxItem: any) => {
    props.handleCheckboxGroupUpdate(
      { ...checkboxItem, labelText: checkboxItem.dataKey },
      checkboxItem.propertyName === 'demurrageRisk' ? 'oceanDemurrageEligible' : 'oceanActiveHolds'
    );
  };

  return (
    <CheckList
      mode={props.checkboxTheme}
      checkListData={nextFilterOptions}
      checkFn={handleCheck}
      checkboxGroupFlow="horizontal"
      hasSelectAndClearAllOption
    />
  );
};

export default QvsOceanHoldsDemurrage;
