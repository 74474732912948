import cn from 'classnames';

import { useDatePicker } from '../context/DatePickerContext';
import { EventType } from '../types';

import * as styles from './styles.module.scss';

interface TabProps {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
  id?: string;
}

const Tab = ({ children, className, onClick, id }: TabProps) => {
  return (
    <button id={id} onClick={onClick} className={cn('text-uppercase py-3', styles['tab'], className)}>
      {children}
    </button>
  );
};

interface EventTypeTabsProps {
  className?: string;
  tabsMetadata: Array<{ value: EventType; label: string | JSX.Element }>;
}

const EventTypeTabs = ({ className, tabsMetadata }: EventTypeTabsProps) => {
  const { state, dispatch } = useDatePicker();

  const handleChange = (nextEventType: EventType) => () => {
    dispatch({ type: 'SET_EVENT_TYPE', payload: nextEventType });
  };

  return (
    <div className={cn('d-flex justify-content-center', className)}>
      {tabsMetadata.map((tabInfo: { value: EventType; label: string | JSX.Element }) => {
        return (
          <Tab
            key={tabInfo.value}
            onClick={handleChange(tabInfo.value)}
            id={state.eventType === tabInfo.value ? 'tab-active' : ''}
            className={cn({ [styles['tab-active']]: state.eventType === tabInfo.value })}
          >
            {tabInfo.label}
          </Tab>
        );
      })}
    </div>
  );
};

export default EventTypeTabs;
