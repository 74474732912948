import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { RouteComponentProps } from 'react-router';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { TenantAttribute } from 'models';
import { tenantAttributesActions } from 'components/newShipmentDetails/ShipmentIdentifiers/ducks';
import ReferenceDataComponent from './ReferenceDataComponent';
import { RootState } from '../../../reducers';

export interface ReferenceDataStateProps extends RouteComponentProps<any>, WrappedComponentProps {
  tenantAttributes?: TenantAttribute[];
  isFetchingTenantAttributes?: boolean;
  isCreatingTenantAttributes?: boolean;
}

export interface ReferenceDataDispatchProps {
  fetchTenantAttributes: () => void;
  createTenantAttributes: (tenantAttributes: TenantAttribute[]) => void;
}

const mapStateToProps = (state: RootState) => ({
  tenantAttributes: state.tenantAttributesReducer.tenantAttributes,
  isFetchingTenantAttributes: state.tenantAttributesReducer.isFetchingTenantAttributes,
  isCreatingTenantAttributes: state.tenantAttributesReducer.isCreatingTenantAttributes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTenantAttributes: () => dispatch(tenantAttributesActions.fetchTenantAttributes.request()),
  createTenantAttributes: (tenantAttributes: TenantAttribute[]) =>
    dispatch(tenantAttributesActions.createTenantAttributes.request(tenantAttributes)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReferenceDataComponent));
