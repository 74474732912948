import { Component } from 'react';
import ErrorMessage from './ErrorMessage/ErrorMessage';

export interface ErrorBoundaryProps {
  message?: string;
  pathname?: string;
}

export interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  public static defaultProps = {
    message: 'Something went wrong.',
    pathname: '',
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (prevProps.pathname !== this.props.pathname && this.state.hasError === true) {
      this.setState({
        hasError: false,
      });
    }
  }

  componentDidCatch(error: any, info: any) {
    // tslint:disable-next-line
    console.warn(error, info); //eslint-disable-line
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage message={this.props.message} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
