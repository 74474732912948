import { CodeIntlKey } from 'models';

interface CreateEntitlementRule {
  entitleeId: string;
  entitleeType: string;
  shipmentProperties: RuleProperty[];
  excludedData: string[];
  entitleeRegion: ShareRegionsEnum;
}

export interface ReducedEntitlementRules {
  [key: string]: {
    name: string;
    rules: {
      id: string;
      tenantId: string;
      entitleeName: string;
      entitleeType: string;
      entitleeId: string;
      createdDate: string;
      createdById: string;
      invitationToken: string;
      lastModifiedDate: string;
      lastModifiedById: string;
      shipmentProperties?: {
        shipmentPropertyKey: ReadableShipmentPropertyKeysEnum;
        shipmentPropertyName: ReadableShipmentPropertyNamesEnum;
        shipmentPropertyValue: string;
      }[];
    }[];
  };
}

export type ProcessedRule = {
  id: string;
  tenantId: string;
  entitleeName: string;
  entitleeType: string;
  entitleeId: string;
  createdDate: string;
  createdById: string;
  invitationToken: string;
  lastModifiedDate: string;
  lastModifiedById: string;
  shipmentProperties?: RuleProperty[];
};

export type RuleProperty = {
  shipmentPropertyName: ReadableShipmentPropertyNamesEnum;
  shipmentPropertyKey: ReadableShipmentPropertyKeysEnum;
  shipmentPropertyValue: string;
};

export interface AddModalInterface {
  setAddModalOpen: (arg0: boolean) => void;
  createEntitlementRule: ({
    entitleeId,
    entitleeType,
    shipmentProperties,
    excludedData,
    entitleeRegion,
  }: CreateEntitlementRule) => void;
  modalError: { errorMessage: string; type: string } | null;
  clearModalError: () => void;
}

export enum ReadableShipmentPropertyNamesEnum {
  LOCATION_IDENTIFIER = 'LOCATION_IDENTIFIER',
  CARRIER_IDENTIFIER = 'CARRIER_IDENTIFIER',
  PARTY_IDENTIFIER = 'PARTY_IDENTIFIER',
}
export enum ReadableShipmentPropertyKeysEnum {
  CAPACITY_PROVIDER_ID = 'CAPACITY_PROVIDER_ID',
  VENDOR_IDENTIFIER = 'VENDOR_IDENTIFIER',
  SYSTEM_MASTER = 'SYSTEM_MASTER',
  ACCOUNT = 'ACCOUNT',
}
export const ReadableShipmentPropertyNamesIntlKeys: { [P in ReadableShipmentPropertyNamesEnum]: CodeIntlKey } = {
  [ReadableShipmentPropertyNamesEnum.CARRIER_IDENTIFIER]: { id: 'some.id', defaultMessage: 'Carrier Identifier' },
  [ReadableShipmentPropertyNamesEnum.LOCATION_IDENTIFIER]: { id: 'some.id', defaultMessage: 'Location Identifier' },
  [ReadableShipmentPropertyNamesEnum.PARTY_IDENTIFIER]: { id: 'some.id', defaultMessage: 'Party Identifier' },
};
export const ReadableShipmentPropertyKeysIntlKeys: { [P in ReadableShipmentPropertyKeysEnum]: CodeIntlKey } = {
  [ReadableShipmentPropertyKeysEnum.CAPACITY_PROVIDER_ID]: { id: 'some.id', defaultMessage: 'Capacity Provider ID' },
  [ReadableShipmentPropertyKeysEnum.VENDOR_IDENTIFIER]: { id: 'some.id', defaultMessage: 'Vendor Identifier' },
  [ReadableShipmentPropertyKeysEnum.SYSTEM_MASTER]: { id: 'some.id', defaultMessage: 'System Master' },
  [ReadableShipmentPropertyKeysEnum.ACCOUNT]: { id: 'some.id', defaultMessage: 'Account' },
};

export enum ShareableDataPoints {
  CUSTOM_ATTRIBUTES = 'CUSTOM_ATTRIBUTES',
  INVOLVED_PARTIES = 'INVOLVED_PARTIES',
  SHIPMENT_STOPS = 'SHIPMENT_STOPS',
  CARRIER_DATA = 'CARRIER_DATA',
  LINE_ITEMS = 'LINE_ITEMS',
}
export const ReadableShareableDataPoints: { [P in ShareableDataPoints]: CodeIntlKey } = {
  [ShareableDataPoints.CUSTOM_ATTRIBUTES]: {
    id: 'entitlementRules.modals.add.CUSTOM_ATTRIBUTES',
    defaultMessage: 'CUSTOM ATTRIBUTES',
  },
  [ShareableDataPoints.INVOLVED_PARTIES]: {
    id: 'entitlementRules.modals.add.INVOLVED_PARTIES',
    defaultMessage: 'INVOLVED PARTIES',
  },
  [ShareableDataPoints.SHIPMENT_STOPS]: {
    id: 'entitlementRules.modals.add.SHIPMENT_STOPS',
    defaultMessage: 'SHIPMENT STOPS',
  },
  [ShareableDataPoints.CARRIER_DATA]: {
    id: 'entitlementRules.modals.add.CARRIER_DATA',
    defaultMessage: 'CARRIER DATA',
  },
  [ShareableDataPoints.LINE_ITEMS]: {
    id: 'entitlementRules.modals.add.LINE_ITEMS',
    defaultMessage: 'LINE ITEMS',
  },
};

export enum ShareRegionsEnum {
  NA12 = 'NA12',
  EU12 = 'EU12',
}
export const ReadableShareRegionsKeysIntlKeys: { [P in ShareRegionsEnum]: CodeIntlKey } = {
  [ShareRegionsEnum.NA12]: { id: 'addModal.shareRegions.NA', defaultMessage: 'North America' },
  [ShareRegionsEnum.EU12]: { id: 'addModal.shareRegions.EU', defaultMessage: 'Europe' },
};
