import isEqual from 'lodash/isEqual';
import { Component } from 'react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import SettingsHeader from '../SettingsHeader/SettingsHeader';
import SettingsNavigation from '../SettingsNavigation/SettingsNavigation';
import settingsRoutes from '../settingsRoutes';
import Colors from './Colors/Colors';
import InfoBar from './InfoBar/InfoBar';
import LogoUpload from './LogoUpload/LogoUpload';
import Preview from './Preview/Preview';
import Reset from './Reset/Reset';
import defaultTheme from './defaultTheme';
import { Theme } from './models/Theme';

import Progress from '../../common/progress/Progress';
import * as styles from './BrandTheming.module.scss';

export interface BrandThemingComponentProps extends RouteComponentProps<{}>, WrappedComponentProps {
  isFetchingTheme: boolean;
  isSavingTheme: boolean;
  selectedTheme?: Theme;
  savedTheme?: Theme;
  saveTheme: (theme: Theme) => void;
  revertSelectedTheme: () => void;
  fetchTheme: () => void;
  updateSelectedTheme: (selectedTheme: Theme) => void;
}

export class BrandThemingComponent extends Component<BrandThemingComponentProps, {}> {
  public componentDidMount() {
    this.props.fetchTheme();
  }

  public onUploadLogo = (file: File) => {
    if (this.props.selectedTheme !== undefined) {
      this.props.updateSelectedTheme({
        ...this.props.selectedTheme,
        isDefaultLogo: false,
        logoFile: file,
        logoUrl: URL.createObjectURL(file),
      });
    }
  };

  public onRemoveLogo = () => {
    if (this.props.selectedTheme !== undefined) {
      this.props.updateSelectedTheme({
        ...this.props.selectedTheme,
        isDefaultLogo: true,
        logoFile: undefined,
        logoUrl: defaultTheme.logoUrl,
      });
    }
  };

  public onToggleCarrierOnboardingLogo = () => {
    if (this.props.selectedTheme !== undefined) {
      this.props.updateSelectedTheme({
        ...this.props.selectedTheme,
        logoEnabled: !this.props.selectedTheme.logoEnabled,
      });
    }
  };

  public onPrimaryColorChange = (color: string) => {
    if (this.props.selectedTheme !== undefined) {
      // If color passed in is primary color we have to use default colors for footer and landing
      // since they are different blue on default
      this.props.updateSelectedTheme({
        ...this.props.selectedTheme,
        primaryColor: color,
        landingColor: color === defaultTheme.primaryColor ? defaultTheme.landingColor : color,
        footerColor: color === defaultTheme.primaryColor ? defaultTheme.footerColor : color,
      });
    }
  };

  public onEmailFooterChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (this.props.selectedTheme !== undefined) {
      this.props.updateSelectedTheme({
        ...this.props.selectedTheme,
        emailFooter: event.target.value,
      });
    }
  };

  public onSaveTheme = () => {
    if (this.props.selectedTheme) {
      this.props.saveTheme(this.props.selectedTheme);
    }
  };

  public onResetTheme = () => {
    this.props.updateSelectedTheme({ ...defaultTheme });
  };

  public render() {
    return (
      <React.Fragment>
        <SettingsHeader
          currentPageTitle={this.props.intl.formatMessage({
            id: settingsRoutes.brandTheming.label ? settingsRoutes.brandTheming.label.id : ' ',
            defaultMessage: settingsRoutes.brandTheming.label ? settingsRoutes.brandTheming.label.defaultMessage : ' ',
          })}
          onSave={this.onSaveTheme}
          showDiscardButton={!isEqual(this.props.selectedTheme, this.props.savedTheme)}
          isSaving={this.props.isSavingTheme}
          onRevert={this.props.revertSelectedTheme}
          showSaveButton
        />
        <div id="settingsInner" className={styles.settingsInner}>
          <SettingsNavigation currentPath={this.props.location.pathname} />
          {this.props.isFetchingTheme && <Progress isLoading />}
          {!this.props.isFetchingTheme && this.props.selectedTheme !== undefined && (
            <div>
              <InfoBar />
              <LogoUpload
                logoUrl={this.props.selectedTheme.logoUrl}
                onUploadLogo={this.onUploadLogo}
                onRemoveLogo={this.onRemoveLogo}
                isDefaultLogo={this.props.selectedTheme.isDefaultLogo}
                logoEnabled={this.props.selectedTheme.logoEnabled}
                onToggleCarrierOnboardingLogo={this.onToggleCarrierOnboardingLogo}
              />
              <Colors
                primaryColor={this.props.selectedTheme.primaryColor}
                onPrimaryColorChange={this.onPrimaryColorChange}
              />
              {/* Disabled until email service is completed
                <EmailFooter
                  emailFooter={this.props.selectedTheme.emailFooter}
                  onChange={this.onEmailFooterChange}
                />*/}
              <Preview selectedTheme={this.props.selectedTheme} />
              <Reset onReset={this.onResetTheme} />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(BrandThemingComponent);
