export const chathamsBlue = '#07456b';
export const primaryBlue = '#00558b';
export const primaryBlueSeventyFive = '#5a89ae';
export const primaryGrey = '#2d2926';
export const primaryGreyFive = '#f3f3f3';
export const primaryGreyTwenty = '#d5d4d4';
export const primaryGreyThirtyTwo = primaryGreyTwenty;
export const primaryGreyFifty = '#979797';
export const primaryGreyForty = primaryGreyFifty;
export const primaryGreyEighty = '#575451';
export const primaryGrey100 = '#e9e9e9';
export const primaryGrey500 = '#aba9a8';
export const black = '#000000';
export const white = '#ffffff';
export const newFruit = '#f04e37';
export const green = '#00a651';
export const yellow = '#ffcb05';
export const burntOrange = '#ffab36';
export const lightBlue = '#00aeef';
export const polylineColor = primaryBlue;
export const polygonShade = 'rgba(0, 174, 239, 0.5)';
export const lightGrey = 'rgba(230, 230, 230, 0.75)';
export const lightAltGrey = 'rgba(205, 205, 205, 0.5)';
export const alto = '#D9D9D9';
export const altoLight = 'rgba(218, 218, 218, 0.4)';
export const purple = '#92278F';

export const newLemon = '#f0c825';
export const orange = '#F7941D';
export const darkGreen = '#00854a';
export const neonGreen = '#00ff00';
export const teal = '#1296b0';
export const bluish = '#00aeef';
export const errorRed = '#dc3545';
export const errorMessageFontColor = '#B4362F';
export const errorRedTwo = '#CA4236';
export const errorRedThree = '#F8D7DA';
export const offBlue = '#5888b0';
export const tableGrey = '#1890ff';
export const dustGray = '#969696';
export const matisse = '#186c99';
export const blueChill = '#108197';

export const movementSidebarBackground = '#021c6b';
export const movementPrimary = '#3142c8';
export const movementPrimaryHover = '#2e39ad';
export const movementTextColor = '#091521';

// old colors: will probably be deprecated in the future
const mysteriousGrey = '#aba9a8';
const whiteTwo = '#d5d4d4';
const p44Blue = '#00558b';
const primaryBlueNinetyFive = '#2e6998';
const primaryBlueEightyFive = '#236192';
const primaryBlueFortyFive = '#9cb8ce';
const primaryBlueThirty = '#bdd0df';
const primaryBlueThirteen = '#e3ebf1';
const michelangeloOrange = '#ffab36';

/* cssColorVarMap should be temporary as we transition away
  from using css variables (as they are not supported in IE) */
export const cssColorVarMap = {
  '--primary-grey': primaryGrey,
  '--primary-grey-80': primaryGreyEighty,
  '--primary-grey-40': primaryGreyForty,
  '--primary-grey-20': primaryGreyTwenty,
  '--primary-grey-05': primaryGreyFive,
  '--mysterious-grey': mysteriousGrey,
  '--white': white,
  '--white-two': whiteTwo,
  '--green': green,
  '--dark-green': darkGreen,
  '--teal': teal,
  '--bluish': bluish,
  '--chathams-blue': chathamsBlue,
  '--p44-blue': p44Blue,
  '--off-blue': offBlue,
  '--primary-blue': primaryBlue,
  '--primary-blue-95': primaryBlueNinetyFive,
  '--primary-blue-85': primaryBlueEightyFive,
  '--primary-blue-75': primaryBlueSeventyFive,
  '--primary-blue-45': primaryBlueFortyFive,
  '--primary-blue-30': primaryBlueThirty,
  '--primary-blue-13': primaryBlueThirteen,
  '--new-lemon': newLemon,
  '--yellow': yellow,
  '--orange': orange,
  '--michelangelo-orange': michelangeloOrange,
  '--new-fruit': newFruit,
};

export default {
  primaryBackgroundColor: primaryGreyFive,
  darkBackgroundColor: primaryGreyEighty,
  secondaryBackgroundColor: white,
  primaryTextColor: primaryGreyEighty,
  secondaryTextColor: primaryGreyForty,
  darkBorderColor: primaryGreyForty,
  lightBorderColor: primaryGreyTwenty,
};
