import get from 'lodash/get';
import { MAP_MAX_NUMBER_PINGS } from 'common/AppConstants';
import LocationUtils from '../../../../common/LocationUtils/LocationUtils';
import ShipmentDetail from '../models/shipmentDetail';
import { TruckloadShipmentStop } from '../models/truckloadShipmentStop';

/**
 * Maps shipment stops to HERE maps data format
 *
 * @param shipmentDetails
 */
export const shipmentsToRecordsMapper = (shipmentDetails: ShipmentDetail) => {
  const shipmentStops = get(shipmentDetails, 'shipmentStops', []);
  // Note: If you need more data, you can always reference the shipmentDetails object and add map it here - Mark Serrano
  const records = shipmentStops.map((item: TruckloadShipmentStop, index: number) => ({
    id: null, // If we want to identify a particular marker/popup, we must populate this with something identifiable - Mark Serrano
    type: 'stop',
    lat: item.location.address.locationCoordinatesDto && item.location.address.locationCoordinatesDto.latitude,
    lng: item.location.address.locationCoordinatesDto && item.location.address.locationCoordinatesDto.longitude,
    geofence: get(shipmentDetails, `shipmentStopGeofences[${index}]`),
    stopName: getStopName(item),
    address: get(item.location.address, 'addressLines[0]'),
    city: item.location.address.city,
    state: item.location.address.state,
    country: LocationUtils.getCountryCodeForLocation(item.location, item.stopName, shipmentStops),
    zip: item.location.address.postalCode,
    contact: item.location.contactDto,
    stopNumber: item.stopNumber,
    atStopEta: shipmentDetails.atStopEta,
    atStopMessage: shipmentDetails.atStopMessage,
    statusCode: shipmentDetails.currentStop.statusCode,
    statusColor: shipmentDetails.statusColor,
    statusInfo: shipmentDetails.statusInfo,
    statusDetails: shipmentDetails.statusDetails,
    statusUpdate: shipmentDetails.statusUpdate,
    milesTraveled: shipmentDetails.milesTraveled,
    milesRemaining: shipmentDetails.milesRemaining,
    isPickupStop: shipmentDetails.pickup && shipmentDetails.pickup.stopNumber === item.stopNumber,
    isDeliveryStop: shipmentDetails.delivery && shipmentDetails.delivery.stopNumber === item.stopNumber,
    isCurrentStop: shipmentDetails.currentStop.stopNumber === item.stopNumber,
    isPendingStop: shipmentDetails.currentStop.stopNumber <= item.stopNumber,
    isDelivered: shipmentDetails.delivery.isComplete,
  }));

  return records;
};

/**
 * Maps current stop to HERE maps data format
 * @param currentStop
 */
export const currentStopToRecordsMapper = (currentStop: any, shipmentDetails: ShipmentDetail) => {
  if (currentStop) {
    return {
      id: null, // If we want to identify a particular marker/popup, we must populate this with something identifiable - Mark Serrano
      type: 'stop',
      lat:
        currentStop.address &&
        currentStop.address.locationCoordinatesDto &&
        currentStop.address.locationCoordinatesDto.latitude,
      lng:
        currentStop.address &&
        currentStop.address.locationCoordinatesDto &&
        currentStop.address.locationCoordinatesDto.longitude,
      stopName: getStopName(shipmentDetails.shipmentStops),
      address: currentStop.address && get(currentStop.address, 'addressLines[0]'),
      city: currentStop.address && currentStop.address.city,
      state: currentStop.address && currentStop.address.state,
      zip: currentStop.address && currentStop.address.postalCode,
      contact: currentStop.contactDto,
      stopNumber: currentStop.stopNumber, // This means the truck is going towards to this stop number or is on this stop number - Mark Serrano
      atStopEta: shipmentDetails.atStopEta,
      atStopMessage: shipmentDetails.atStopMessage,
      statusCode: shipmentDetails.currentStop.statusCode,
      statusColor: shipmentDetails.statusColor,
      statusInfo: shipmentDetails.statusInfo,
      statusDetails: shipmentDetails.statusDetails,
      statusUpdate: shipmentDetails.statusUpdate,
      milesTraveled: shipmentDetails.milesTraveled,
      milesRemaining: shipmentDetails.milesRemaining,
      isPickupStop: shipmentDetails.pickup && shipmentDetails.pickup.stopNumber === currentStop.stopNumber,
      isDeliveryStop: shipmentDetails.delivery && shipmentDetails.delivery.stopNumber === currentStop.stopNumber,
      isCurrentStop: shipmentDetails.currentStop.stopNumber === currentStop.stopNumber,
      isPendingStop: shipmentDetails.currentStop.stopNumber < currentStop.stopNumber,
      isDelivered: shipmentDetails.delivery.stopNumber === shipmentDetails.currentStop.stopNumber,
      deliveryCountry: get(shipmentDetails, 'delivery.country'),
    };
  }
};

/**
 *  Maps shipment pings to HERE maps data format
 *
 * @param shipmentDetails
 */
export const pingsToRecordsMapper = (shipmentDetails: ShipmentDetail) => {
  const pings = getConsolidatedPings(get(shipmentDetails, 'pings', []));

  const records = pings.map((ping: any) => ({
    id: ping.id,
    type: 'ping',
    lat: get(ping, 'coordinates.latitude'),
    lng: get(ping, 'coordinates.longitude'),
    address: get(ping, 'addresses.current.addressLines[0]'),
    city: get(ping, 'addresses.current.city'),
    state: get(ping, 'addresses.current.state'),
    country: get(ping, 'addresses.current.countryCodeDto'),
    zip: get(ping, 'addresses.current.postalCode'),
    stopNumber: ping.stopNumber,
    pingDateTime: get(ping, 'pingDateTimes.utc'),
    timezone: ping.timezone,
    statusColor: shipmentDetails.statusColor,
    isPickupStop: shipmentDetails.pickup && shipmentDetails.pickup.stopNumber === ping.stopNumber,
    isDeliveryStop: shipmentDetails.delivery && shipmentDetails.delivery.stopNumber === ping.stopNumber,
    stopName: null, // This will be null since a ping doesn't have a stop number, but it's here so it doesn't break the html marker - Mark Serrano,
    contact: null, // This will be null since a ping doesn't have a contact info, but it's here so it doesn't break the html marker - Mark Serrano
  }));

  return records;
};

/*
 * Note: If we send HERE MAPS too many location pings/records, their route calculator errors out, so we need to consolidate
 * the pings we send to ensure we can render a route on the map.
 */
export const getConsolidatedPings = (allPings: any[]): any[] => {
  const divisor = Math.ceil(allPings.length / MAP_MAX_NUMBER_PINGS);
  return allPings.filter((ping: any, index: number) => index % divisor === 0);
};

const getStopName = (shipmentStops: TruckloadShipmentStop) => {
  return get(shipmentStops, 'location.contactDto.companyName');
};
