import types from './types';

export const getShipmentDetails = (id, shipmentModel, shouldUseRecentSearch) => ({
  type: types.FETCH_SHIPMENT_DATA,
  id,
  shipmentModel,
  shouldUseRecentSearch,
});

export const shipmentDetailsSuccess = (shipment) => ({
  type: types.FETCH_SHIPMENT_DATA_SUCCESS,
  shipment,
});

export const shipmentDetailsFailure = (error) => ({
  type: types.FETCH_SHIPMENT_DATA_FAILURE,
  error,
});

export const getPublicShipmentDetails = (shipmentShareToken, shipmentModel) => ({
  type: types.FETCH_PUBLIC_SHIPMENT_DATA,
  shipmentShareToken,
  shipmentModel,
});

export const publicShipmentDetailsSuccess = (shipment) => ({
  type: types.FETCH_PUBLIC_SHIPMENT_DATA_SUCCESS,
  shipment,
});

export const publicShipmentDetailsFailure = (error) => ({
  type: types.FETCH_PUBLIC_SHIPMENT_DATA_FAILURE,
  error,
});

export const toggleRouteDetailsDisplay = () => ({
  type: types.TOGGLE_ROUTE_DISPLAY,
});

export const toggleGroupedUpdates = (index) => ({
  type: types.TOGGLE_GROUPED_DISPLAY,
  index,
});

export const resetShipment = () => ({
  type: types.RESET_SHIPMENT,
});
