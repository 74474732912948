import find from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import last from 'lodash/last';
import replace from 'lodash/replace';
import { defineMessages } from 'react-intl';

import * as DateUtils from 'common/dateUtils';
import { oceanOnModeAgnosticPage } from 'common/authorizations';
import {
  mapShipmentIdentifiers,
  oceanIdentifierTypes,
} from '../../shipmentDetails/common/mappers/shipmentIdentifiersMapper';
import { entityPageUrls, intl } from '../../.././common/AppConstants';
import DerivedStatusOcean from '../../shipment/common/ocean/derivedStatusOcean';
import MultiModalShipmentStatus from '../../shipment/common/enums/multiModalShipmentStatus';
import DateFormatUtil from '../../shipment/common/utils/dateFormatUtil';
import PercentCompleteUtil from '../../shipment/common/utils/percentCompleteUtil';
import ColorUtil from '../../shipment/common/utils/colorUtil';
import StatusDescriptionUtil from '../../shipment/common/utils/statusDescriptionUtil';
import { getShipmentUrlFromMasterShipmentId } from '../utils/mapShipmentUtils';
import * as shipmentUtils from '../../newShipmentDetails/utils/shipmentUtils';

const messages = defineMessages({
  demurrageRisk: {
    id: 'shipmentList.mapShipmentOcean.demurrageRisk',
    defineMessages: 'Demurrage Risk',
  },
});

export const mapShipmentOcean = (shipment, useNewEntityPage = false) => {
  const currentUrl = oceanOnModeAgnosticPage()
    ? getShipmentUrlFromMasterShipmentId(shipment.masterShipmentId)
    : '/tracking-details/ocean/' + get(shipment, 'shipmentId', -1);

  const url = useNewEntityPage ? entityPageUrls.entityPageUiUrl(shipment.masterShipmentId) : currentUrl;

  const mode = get(shipment, 'mode', false);
  const identifiers = mapShipmentIdentifiers(shipment.shipmentIdentifiers, oceanIdentifierTypes);
  const derivedStatus =
    DerivedStatusOcean.CODES[get(shipment, 'derivedStatusCodes[0].value', undefined)] || DerivedStatusOcean.UNKNOWN;
  const currentTimezone = false;

  // Last updated time was hidden in TRK-1421 until improvements are made to the calculation
  // const lastUpdateTimestamp = get(
  //   shipment,
  //   'latestStatusUpdate.utcRetrievalTimestamp',
  //   get(shipment, 'latestStatusUpdate.utcTimestamp', undefined)
  // );
  // const lastUpdateText = DateFormatUtil.mapFromShipmentStatusAndUpdateTimestampToLastUpdatedText(
  //   shipment.multiModalDerivedStatus,
  //   lastUpdateTimestamp
  // );
  const timelinessCode = last(shipment.latestStopStatuses)
    ? last(shipment.latestStopStatuses).timelinessCode
    : undefined;

  const convertedDateTime = false;
  const dwellTime = false;
  const deliveryStop = last(shipment.shipmentStops);
  const deliveryStopStatus = find(shipment.latestStopStatuses, (s) => s.stopNumber === deliveryStop.stopNumber);
  const deliveryStopInfo = mapPickupOrDeliveryStopInfo(shipment.deliveryStopInfo, deliveryStop, deliveryStopStatus);
  const pickupStop =
    Array.isArray(shipment.shipmentStops) && shipment.shipmentStops.length > 1
      ? first(shipment.shipmentStops)
      : undefined;
  const pickupStopStatus = pickupStop
    ? find(shipment.latestStopStatuses, (s) => s.stopNumber === pickupStop.stopNumber)
    : undefined;
  const pickupStopInfo = mapPickupOrDeliveryStopInfo(shipment.pickupStopInfo, pickupStop, pickupStopStatus);
  const carrierName = get(shipment, 'carrier.name');
  // TODO: Use an enum in place of the formatMessage
  const flagText = shipment.demurrageEligible ? intl.formatMessage(messages.demurrageRisk) : undefined;
  const flagColor = '--michelangelo-orange';
  const statusText = shipmentUtils.getDerivedStatusText(intl, get(shipment, 'derivedStatusCodes[0].value'));
  const statusColor = ColorUtil.getStatusColorFromDerivedStatusOcean(derivedStatus);
  const percentComplete = PercentCompleteUtil.getPercentComplete_FallbackForOcean(derivedStatus);
  const vesselName = get(shipment, 'vesselInfo.name');
  const lastFreeDay = get(shipment, 'details.lastFreeDay');
  const eta = get(deliveryStopStatus, 'utcEarliestEstimatedArrivalDateTime');
  const ata = get(deliveryStopStatus, 'utcRecordedArrivalDateTime');
  const departureTime = get(deliveryStopStatus, 'utcRecordedDepartureDateTime');
  const departureTimeZone = get(deliveryStop, 'location.address.locationCoordinatesDto.localTimeZoneIdentifier');
  const utcCurrentTime = DateUtils.utcNowMoment();
  const tenantCustomAttributes = get(shipment, 'tenantCustomAttributes', []);

  const statusDescription = StatusDescriptionUtil.getStatusDescriptionOcean(
    derivedStatus,
    vesselName,
    eta,
    ata,
    lastFreeDay,
    departureTime,
    utcCurrentTime,
    departureTimeZone
  );
  const sensorTracking = false;
  const activeHoldsCount =
    shipment.multiModalDerivedStatus === MultiModalShipmentStatus.SCHEDULED
      ? 0
      : Array.isArray(shipment.activeHolds)
      ? shipment.activeHolds.length
      : undefined;

  return {
    shipmentId: shipment.shipmentId,
    url: url,
    mode: mode,
    identifiers: identifiers,
    derivedStatus: derivedStatus,
    currentEta: eta,
    currentTimezone: currentTimezone,
    tenantCustomAttributes,
    timelinessCode: timelinessCode,
    // lastUpdate: lastUpdateText, Last updated time was hidden in TRK-1421 until improvements are made to the calculation
    convertedDateTime: convertedDateTime,
    dwellTime: dwellTime,
    pickupStopInfo: pickupStopInfo,
    deliveryStopInfo: deliveryStopInfo,
    carrierName: carrierName,
    flagText: flagText,
    flagColor: flagColor,
    statusText: statusText,
    statusColor: statusColor,
    percentComplete: percentComplete,
    statusDescription: statusDescription,
    sensorTracking: sensorTracking,
    activeHoldsCount: activeHoldsCount,
    entitlementInfo: shipment.entitlementInfo,
    shipmentStopsCount: get(shipment, 'shipmentStops', []).length,
  };
};

const normalizeString = (value) => replace(value, 'null', '') || null;

const mapPickupOrDeliveryStopInfo = (stopInfo, stop, stopStatus) => {
  if (!stopInfo) {
    return undefined;
  }

  let stopTimeUtc = get(
    stopStatus,
    'utcRecordedArrivalDateTime',
    get(stopStatus, 'utcEarliestEstimatedArrivalDateTime', get(stop, 'utcAppointmentEndDateTime', undefined))
  );
  let stopTimeLocal = get(
    stopStatus,
    'recordedArrivalDateTime',
    get(
      stopStatus,
      'earliestEstimatedArrivalDateTime',
      get(stop, 'localAppointmentEndDateTime', get(stopInfo, 'dateRange.endDate', undefined))
    )
  );
  let stopTimezone = get(stop, 'location.address.locationCoordinatesDto.localTimeZoneIdentifier');
  const locode = get(stop, 'location.address.locode');
  let formatedDateTimeText = undefined;

  if (stopTimeUtc && stopTimezone) {
    formatedDateTimeText = DateFormatUtil.formatPickupOrDeliveryDateTimeWithTimezone(stopTimeUtc, 'UTC', stopTimezone);
  }
  if (locode) {
    stopInfo.locode = locode;
  }
  if (!formatedDateTimeText && stopTimeLocal && stopTimezone) {
    formatedDateTimeText = DateFormatUtil.formatPickupOrDeliveryDateTimeWithTimezone(
      stopTimeLocal,
      stopTimezone,
      stopTimezone
    );
  }

  if (!formatedDateTimeText && stopTimeUtc) {
    formatedDateTimeText = DateFormatUtil.formatPickupOrDeliveryDateTimeWithTimezone(stopTimeUtc, 'UTC');
  }

  if (!formatedDateTimeText && stopTimeLocal) {
    formatedDateTimeText = DateFormatUtil.formatPickupOrDeliveryDateTimeUnknownLocalTimezone(stopTimeLocal);
  }

  // Add a new property for datetime text to be displayed, if defined
  if (formatedDateTimeText) {
    stopInfo.formatedDateTimeText = formatedDateTimeText;
  }

  // Use port name for stop name, when available otherwise fallback to cityState and then portCode
  let portName =
    get(stop, 'location.address.portName') ||
    normalizeString(get(stop, 'location.address.cityState')) ||
    get(stop, 'location.address.portCode');
  if (portName) {
    stopInfo.stopName = portName;
  }

  return stopInfo;
};
