import { memo, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Button } from 'ui-components';

import * as styles from './SettingsHeader.module.scss';
import { withTheme, WithThemeProps } from '../../../contexts/ThemeContext';

interface SettingsHeaderProps extends WithThemeProps {
  currentPageTitle: string;
  isSaving?: boolean;
  showDiscardButton?: boolean;
  showSaveButton?: boolean;
  onSave?: () => void;
  onRevert?: () => void;
}

const SettingsHeader: FunctionComponent<SettingsHeaderProps> = (props) => {
  return (
    <div className={styles.settingsHeader}>
      <div id="settingsText" className={styles.settingsText}>
        <span className={styles.bolded}>
          <FormattedMessage id="header.appMenu.settings" defaultMessage="Settings" />
        </span>
        &nbsp;&nbsp;{props.currentPageTitle}
      </div>
      <div className={styles.buttons}>
        <Button
          clickFn={props.onRevert}
          className={classNames(styles.button, styles.hide, {
            [styles.show]: props.showDiscardButton,
          })}
        >
          <FormattedMessage id="settings.settingsHeader.discardChanges" defaultMessage="Discard Changes" />
        </Button>
        <Button
          clickFn={props.onSave}
          type={'primary'}
          className={classNames(styles.button, styles.hide, {
            [styles.show]: props.showSaveButton,
          })}
          loading={props.isSaving}
          theme={props.theme}
        >
          <FormattedMessage id="settings.settingsHeader.save" defaultMessage="Save" />
        </Button>
      </div>
    </div>
  );
};

SettingsHeader.defaultProps = {
  showDiscardButton: false,
};

export const SettingsHeaderComponent = SettingsHeader;
export default memo(withTheme(SettingsHeader));
