import Map from './components/portCongestionMap';
import SideMenu from './components/portCongestionSideMenu';
import './PortCongestion.scss';
const vessels = [
  {
    vesselId: '723bb7df-03e0-4026-aacc-060839187b1f',
    vesselSessionId: '2049067997462537',
    vesselImo: '9193290',
    lat: '33.7039',
    lon: '-118.16',
    meta: {
      callsign: 'DGTX',
      destination: 'MXZLO >> USLGB',
      draught: 13.3000002,
      imo: 9193290,
      length: 294,
      mmsi: 211327410,
      name: 'TOKYO EXPRESS',
      shiptype: 'Cargo',
      width: 32,
      ship: {
        country: 'USA',
        eta: '00000',
      },
    },
    createdAt: '2021-02-26T14:39:52.874Z',
    updatedAt: '2021-02-26T14:39:52.874Z',
  },
  {
    vesselId: '92607cf0-ccb8-487e-afcf-c3259cd10ca7',
    vesselSessionId: '2049068028977161',
    vesselImo: '9331000',
    lat: '33.6965',
    lon: '-118.155',
    meta: {
      callsign: '9HA4988',
      destination: 'USLAX',
      draught: 11.1000004,
      imo: 9331000,
      length: 294,
      mmsi: 215159000,
      name: 'CMA CGM SWORDFISH',
      shiptype: 'Cargo',
      width: 32,
      ship: {
        country: 'USA',
        eta: '00000',
      },
    },
    createdAt: '2021-02-26T14:39:56.716Z',
    updatedAt: '2021-02-26T14:39:56.716Z',
  },
  {
    vesselId: '180237cf-e2ff-4d32-841a-78a50101d31c',
    vesselSessionId: '2049068047515657',
    vesselImo: '9839935',
    lat: '33.6876',
    lon: '-118.117',
    meta: {
      callsign: '9HA5073',
      destination: 'USLAX',
      draught: 14.6999998,
      imo: 9839935,
      length: 366,
      mmsi: 215346000,
      name: 'CMA CGM CHILE',
      shiptype: 'Cargo',
      width: 51,
      ship: {
        country: 'USA',
        eta: '00000',
      },
    },
    createdAt: '2021-02-26T14:39:58.979Z',
    updatedAt: '2021-02-26T14:39:58.979Z',
  },
];

const PortCongestion = () => {
  return (
    <div className="port-congestion-container">
      <SideMenu vessels={vessels} />
      <Map markers={vessels} />
    </div>
  );
};

export default PortCongestion;
