import { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker as AntDatePicker } from 'antd';
import styled from 'styled-components';
import { MONTH_DAY_YEAR_FORMAT } from 'i18n/configurei18n';
import { defaultThemeShape, ThemeContext } from '../../styles/theme';

import './DatePicker.scss';

const StyledDatePicker = styled(AntDatePicker)`
  &:hover {
    .ant-calendar-picker-input {
      border-color: ${(props) => props.theme && props.theme.primaryColor} !important;
    }
  }
`;

export default class DatePicker extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    format: PropTypes.string,
    theme: PropTypes.shape(defaultThemeShape),
  };

  static contextType = ThemeContext;

  render() {
    const { placeholder = MONTH_DAY_YEAR_FORMAT, onChange, format = MONTH_DAY_YEAR_FORMAT, theme } = this.props;

    const calendarIcon = () => <i className="material-icons">calendar_today</i>;
    return (
      <StyledDatePicker
        theme={theme || this.context}
        placeholder={placeholder}
        format={format}
        popupStyle={{ zIndex: 9999 }}
        onChange={onChange}
        suffixIcon={calendarIcon}
      />
    );
  }
}
