import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import debounce from 'lodash/debounce';
import {
  getPrincipalAuthoritiesFromState,
  getPrincipalAuthorizations,
  getPrincipalAuthorizationsFromState,
  getPrincipalFromState,
} from 'common/authorizations';
import { FILTER_RESULT_DELAY } from 'common/AppConstants';
import FilterUtil from 'components/filter/util/filterUtil';
import FilterComponent from './FilterComponent';

import {
  buildApplyFilterFieldAction,
  updateSuggestions,
  setSuggestions,
  handleCheckboxGroupUpdate,
  handleCheckboxSingleUpdate,
  handleAutocompleteUpdate,
  handleStatusDropdownItemSelect,
  handleStatusDropdownItemRemove,
  toggleShowSelections,
  handleDatePickerChange,
  handleFilterInputs,
  getTenantAttributes,
  toggleModeFilterSelectOneFn,
  clearFiltersNoSearch,
  clearFilters,
} from './ducks/actions';
import { setQuickViewMode } from '../shipmentCounts/ducks/actions';

const componentWithFetchedData = compose(
  lifecycle({
    componentDidMount() {
      this.props.getTenantAttributes();
    },
  })
)(FilterComponent);

const mapStateToProps = (state) => {
  const { filterReducer } = state;
  const authorities = getPrincipalAuthoritiesFromState(state);
  const authorizations = getPrincipalAuthorizationsFromState(state);
  return {
    searchTerm: state.searchReducer.searchTerm,
    sortFields: state.sortResultsReducer.sortFields,
    modeFilterOptions: FilterUtil.getFilterOptionsBasedOnAuthorities(filterReducer.allModeFilterOptions, authorities),
    arrivalForecastFilters: filterReducer.arrivalForecastFilters,
    deliveryStatusFilters: filterReducer.deliveryStatusFilters,
    temperatureTrackingFilters: filterReducer.temperatureTrackingFilters,
    filter: filterReducer.filter,
    customersListData: filterReducer.customersListData,
    locationSuggestions: filterReducer.locationSuggestions,
    portOfLoadingSuggestions: filterReducer.portOfLoadingSuggestions,
    transshipmentPortSuggestions: filterReducer.transshipmentPortSuggestions,
    carrierSuggestions: filterReducer.carrierSuggestions,
    companySuggestions: filterReducer.companySuggestions,
    customerSuggestions: filterReducer.customerSuggestions,
    attributeSuggestions: filterReducer.attributeSuggestions,
    createdBySuggestions: filterReducer.createdBySuggestions,
    showSelections: filterReducer.showSelections,
    filterInputs: filterReducer.filterInputs,
    modeFilters: filterReducer.modeFilters,
    authorities: getPrincipalAuthoritiesFromState(state),
    tenantAttributes: filterReducer.tenantAttributes,
    oceanActiveHoldsFilters: filterReducer.oceanActiveHoldsFilters,
    oceanDemurrageEligibleFilters: filterReducer.oceanDemurrageEligibleFilters,
    authorizations,
    principal: getPrincipalFromState(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleApplyFilter: (filter, authorities) => dispatch(buildApplyFilterFieldAction(filter, authorities)),
  handleCheckboxGroupUpdate: (checkboxItem, checkboxGroup) =>
    dispatch(handleCheckboxGroupUpdate(checkboxItem, checkboxGroup)),
  handleCheckboxSingleUpdate: (checkboxItem, checkboxGroup) =>
    dispatch(handleCheckboxSingleUpdate(checkboxItem, checkboxGroup)),
  handleAutocompleteUpdate: (filterItem, filterItemGroup) =>
    dispatch(handleAutocompleteUpdate(filterItem, filterItemGroup)),
  handleStatusDropdownItemSelect: (selectedValues) => dispatch(handleStatusDropdownItemSelect(selectedValues)),
  handleStatusDropdownItemRemove: (selectedValues) => dispatch(handleStatusDropdownItemRemove(selectedValues)),
  toggleShowSelections: (group) => dispatch(toggleShowSelections(group)),
  clearFilters: (searchTerm, authorities) => {
    // To set modeFilter as authorized mode when only one mode is available
    const authorizedModes = getPrincipalAuthorizations(authorities).getAuthorizedModes();
    let modeFilters = null;
    if (authorizedModes.length === 1) {
      modeFilters = authorizedModes;
    }
    dispatch(clearFilters(authorities, modeFilters));
  },
  handleDatePickerChange: (date, dateType) => dispatch(handleDatePickerChange(date, dateType)),
  handleFilterInputs: (inputValue, filterGroup, params) => {
    const handleInput = (inputValue, filterGroup) => {
      dispatch(handleFilterInputs(inputValue, filterGroup));
      dispatch(updateSuggestions(inputValue, filterGroup, params));
    };
    const debounceHandleInput = debounce(handleInput, FILTER_RESULT_DELAY);
    dispatch(handleFilterInputs(inputValue, filterGroup));

    // Reset the suggestions when the input is less than 2
    if (inputValue.length <= 1) {
      dispatch(setSuggestions(filterGroup, []));
    } else {
      // Else dispatch an action like normal
      debounceHandleInput(inputValue, filterGroup);
      // dispatch(updateSuggestions(inputValue, filterGroup));
    }
  },
  getTenantAttributes: () => dispatch(getTenantAttributes()),
  toggleModeFilterSelectOneFn: (mode) => {
    dispatch(setQuickViewMode(mode));
    dispatch(clearFiltersNoSearch());
    dispatch(toggleModeFilterSelectOneFn(mode));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(componentWithFetchedData);
