import { Drawer, MenuItem, SvgIcon } from 'material-ui';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { MouseEvent } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import get from 'lodash/get';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { ReactComponent as OpenNewIcon } from 'components/common/assets/icons/icon_open_in_new.svg';
import endpoints from '../../../../common/endpoints';
import { getApiDocUrl, getReferenceDataUrl, getSdkUrl } from '../../../../common/newApiDocUrls';
import appIconSmall from '../../assets/logo-small.svg';
import { ActiveTabEnum } from '../MenuConstants';
import * as styles from './SideMenu.module.scss';
import { StyledTag } from '../mainMenu/MainMenu';

export interface SideMenuProps {
  currentTab: number | undefined;
  menuOpen: boolean;
  setCurrentMenuState: (newMenuState: any) => void;
  setActiveAndCloseMenu: () => void;
  authorizations: any;
  hasCarrierRole: boolean;
}

const CloseIcon = () => (
  <SvgIcon>
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
  </SvgIcon>
);

// hack to make Link tag that has all the styling set to link out externally
// This is for mobile view so we may want to replace with window.location.href = endpoints.RATING to just change current view
const openNewTabForRating = (e: MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  window.open(endpoints.RATING, '_blank, noopener, noreferrer');
};

const SideMenu: React.FunctionComponent<SideMenuProps> = (props) => {
  const principalContext = React.useContext(PrincipalContext);
  const apiDocUrl = getApiDocUrl(principalContext);
  const referenceDataUrl = getReferenceDataUrl(principalContext);
  const sdkUrl = getSdkUrl(principalContext);

  const handleTrackingMenuItemClick = (e: MouseEvent<HTMLAnchorElement>) => {
    props.setActiveAndCloseMenu();
  };

  const handleAnalyticsMenuItemClick = (e: MouseEvent<HTMLAnchorElement>) => {
    props.setActiveAndCloseMenu();
  };

  const closeSideMenu = () => props.setCurrentMenuState({ isOpen: false });
  const items = [];

  items.push(
    <Link key="0" to={endpoints.DASHBOARD}>
      <MenuItem
        className={classNames(styles.sideMenuLink, {
          [styles.sideLinkActive]: props.currentTab === ActiveTabEnum.DASHBOARD,
        })}
      >
        <FormattedMessage id="header.mainMenu.dashboard" defaultMessage="Dashboard" />
      </MenuItem>
    </Link>
  );

  if (props.authorizations.hasPlanning()) {
    items.push(
      <Link key="1" to={endpoints.RATING} onClick={openNewTabForRating}>
        <MenuItem
          className={classNames(styles.sideMenuLink, {
            [styles.sideLinkActive]: props.currentTab === ActiveTabEnum.RATING_AND_DISPATCH,
          })}
        >
          <FormattedMessage id="header.mainMenu.planning" defaultMessage="Planning" />
        </MenuItem>
      </Link>
    );
  }

  items.push(
    <Link key="2" to={endpoints.DASHBOARD} onClick={handleTrackingMenuItemClick}>
      <MenuItem
        className={classNames(styles.sideMenuLink, {
          [styles.sideLinkActive]: props.currentTab === ActiveTabEnum.TRACKING,
        })}
      >
        <FormattedMessage id="header.mainMenu.tracking" defaultMessage="Tracking" />
      </MenuItem>
    </Link>
  );

  if (!props.hasCarrierRole) {
    items.push(
      <Link key="3" to={endpoints.DASHBOARD} onClick={props.setActiveAndCloseMenu}>
        <MenuItem
          className={classNames(styles.sideMenuLink, {
            [styles.sideLinkActive]: props.currentTab === ActiveTabEnum.LOCATIONS,
          })}
        >
          <FormattedMessage id="header.mainMenu.locations" defaultMessage="Locations" />
        </MenuItem>
      </Link>
    );
  }

  if (props.authorizations.hasAnalytics(principalContext)) {
    items.push(
      <Link key="4" to={endpoints.ANALYTICS} onClick={handleAnalyticsMenuItemClick}>
        <MenuItem
          className={classNames(styles.sideMenuLink, {
            [styles.sideLinkActive]: props.currentTab === ActiveTabEnum.ANALYTICS,
          })}
        >
          <FormattedMessage id="header.mainMenu.analytics" defaultMessage="Analytics" />
        </MenuItem>
      </Link>
    );
  }

  items.push(
    <a href={(window as any).portalGlobal?.nmcUrl} target="_blank" rel="noopener noreferrer" key="5">
      <MenuItem
        className={classNames(styles.sideMenuLink, {
          [styles.sideLinkActive]: props.currentTab === ActiveTabEnum.NETWORK_MANAGEMENT_CENTER,
        })}
      >
        <FormattedMessage id="header.mainMenu.network" defaultMessage="Network" />
      </MenuItem>
    </a>
  );

  // TODO: Re-enable rating link when new Initiate product is released
  // const shouldDisplayTlRating = authorizations.tlRatingLinkIsVisible();
  const shouldDisplayTlRating = false;

  const shouldDisplaySlotBooking = props.authorizations.hasSlotBooking();
  const slotBookingUrl = get(window, 'portalGlobal.slotBookingUrl') as unknown as string;

  if (shouldDisplaySlotBooking) {
    items.push(
      <a
        href={slotBookingUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-locator="navigation-tl-rating"
        style={{
          color: 'inherit',
        }}
      >
        <MenuItem className={classNames(styles.sideMenuLink, {})}>
          <FormattedMessage id="header.mainMenu.slotBooking" defaultMessage="Slot Booking" />{' '}
          <OpenNewIcon style={{ height: 12, width: 12 }} />
        </MenuItem>
      </a>
    );
  }

  if (shouldDisplayTlRating) {
    items.push(
      <a
        href={endpoints.TL_RATING as string}
        target="_blank"
        rel="noopener noreferrer"
        data-locator="navigation-tl-rating"
        style={{
          color: 'inherit',
        }}
      >
        <MenuItem className={classNames(styles.sideMenuLink, {})}>
          <FormattedMessage id="header.mainMenu.tlRating" defaultMessage="TL Rating" />{' '}
          <StyledTag>
            <FormattedMessage id="header.mainMenu.tlRating.tag" defaultMessage="BETA" />
          </StyledTag>
        </MenuItem>
      </a>
    );
  }

  return (
    <MuiThemeProvider>
      <Drawer docked={false} width="100%" open={props.menuOpen} onRequestChange={props.setCurrentMenuState}>
        <div className={`${styles.sideMenu} d-flex flex-column d-md-none`}>
          <div className={`${styles.sideMenuHeader}`}>
            <span className={`${styles.sideMenuIcon}`}>
              <img src={appIconSmall} className={`${styles.sideMenuLogoSmall}`} alt="logo-small" />
            </span>
            <button className="plainWrapperButton" onClick={closeSideMenu}>
              <span className={`${styles.closeButtonMenu}`}>
                <CloseIcon />
              </span>
            </button>
          </div>
          {items}
          <div className={`${styles.footerLinks}`}>
            <div className={`${styles.footerRow}`}>
              <div className={`${styles.footerLinkRow} ${styles.footerHeader} d-flex flex-row medium-text`}>
                <FormattedMessage
                  id="footer.footerLinksConfig.developerResources"
                  defaultMessage="Developer Resources"
                />
              </div>
              {props.hasCarrierRole ? null : (
                <a
                  href={apiDocUrl}
                  className={`${styles.footerLinkRow} d-flex flex-row`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="footer.footerLinksConfig.apiDocumentation" defaultMessage="Developer Portal" />
                </a>
              )}
              <a href={referenceDataUrl} className={`${styles.footerLinkRow} d-flex flex-row`}>
                <FormattedMessage id="footer.footerLinksConfig.referenceCodes" defaultMessage="Reference Codes" />
              </a>
              <a href={sdkUrl} className={`${styles.footerLinkRow} d-flex flex-row`}>
                <FormattedMessage id="footer.footerLinksConfig.sdk" defaultMessage="Software Development Kits (SDKs)" />
              </a>
              <a href={endpoints.LEGACY_UI} className={`${styles.footerLinkRow} d-flex flex-row`}>
                <FormattedMessage id="footer.footerLinksConfig.viewLegacyUI" defaultMessage="View legacy UI (v2)" />
              </a>
            </div>
            <div className="footer-row">
              <div className={`${styles.footerHeader} d-flex flex-row medium-text`}>
                <FormattedMessage id="footer.footerLinksConfig.p44Administration" defaultMessage="p44 Administration" />
              </div>
              <a href={endpoints.CUSTOMER_MANAGEMENT} className={`${styles.footerLinkRow} d-flex flex-row`}>
                <FormattedMessage id="footer.footerLinksConfig.tenantManagement" defaultMessage="Tenant Management" />
              </a>
              <a href={endpoints.SUPPORTED_VENDORS} className={`${styles.footerLinkRow} d-flex flex-row`}>
                <FormattedMessage id="footer.footerLinksConfig.supportedVendors" defaultMessage="Supported Vendors" />
              </a>
            </div>
            <div className="footer-row">
              <div className={`${styles.footerLinkRow} ${styles.footerHeader} d-flex flex-row medium-text`}>
                Have an Issue?
              </div>
              <a className={`${styles.footerLinkRow} d-flex flex-row `} href="mailto:support@project44.com">
                <FormattedMessage id="footer.links.contactUsAt" defaultMessage="Contact us at" />
                &nbsp;support@project44.com
              </a>
              <a className={`${styles.footerLinkRow} d-flex flex-row `} href={endpoints.SUPPORT_PORTAL}>
                <FormattedMessage id="footer.links.goToSupportPortal" defaultMessage="Go To Support Portal" />
              </a>
            </div>
          </div>
        </div>
      </Drawer>
    </MuiThemeProvider>
  );
};

export default SideMenu;
