import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Info } from 'ui-components';

import * as styles from './InfoBar.module.scss';

const InfoBar: React.FunctionComponent<{}> = () => {
  return (
    <div id="infoBar" className={styles.infoBar}>
      <Info className={styles.icon} />
      <div className={styles.text}>
        <FormattedMessage
          id="settings.InfoBar.customize"
          defaultMessage="Customize the look and feel of your account with your organization’s logo and brand color."
        />
      </div>
    </div>
  );
};

export const InfoBarComponent = InfoBar;
export default React.memo(InfoBar);
