import { FormattedMessage, defineMessages } from 'react-intl';
import { FormSection, Field, FieldArray } from 'redux-form';
import get from 'lodash/get';
import { Button } from 'ui-components';
import { intl } from 'common/AppConstants';
import { requiredValidation } from 'common/FormValidations';
import { enableShipmentTrackingMethod, tlDepartedLateNotificationsEnabled } from 'common/authorizations';

import EventRuleStop from './EventRuleStop';
import EventRuleCountry from './EventRuleCountry';
import { RenderSingleSelectDropdown, renderTimeSelector, AddSectionLink } from '../AddEditFormInputs';
import * as constants from '../../ducks/constants';
import { TLEventTypeKeyConstants } from '../../ducks/constants';
import EventRuleStatusCode from './EventRuleStatusCode';

const message = defineMessages({
  selectEvent: {
    id: 'notifications.eventRuleFormSection.selectEvent',
    defaultMessage: 'Choose an Event',
  },
  selectTimeFrame: {
    id: 'notifications.eventRuleFormSection.selectTimeFrame',
    defaultMessage: 'Select Timeframe',
  },
});

const descriptionText = (eventTypeKey, parameterType) => {
  return constants.ShipmentNotificationRuleConstants[parameterType].get(eventTypeKey);
};

const HasTimeConstraint = (eventTypeKey) => {
  return constants.ShipmentNotificationRuleConstants.eventTypeTimeConstraintParameters.has(eventTypeKey);
};

const HasTimeConstraintSuffix = (eventTypeKey) =>
  constants.ShipmentNotificationRuleConstants.eventTypeTimeConstraintSuffixParameters.has(eventTypeKey);

const HasEventStopParameter = (eventTypeKey) => {
  return constants.ShipmentNotificationRuleConstants.eventTypeStopParameterValues.has(eventTypeKey);
};

const IsToolbarBottomRowVisible = (eventTypeKey) => {
  return (
    constants.ShipmentNotificationRuleConstants.eventTypeTimeConstraintParameters.has(eventTypeKey) ||
    constants.ShipmentNotificationRuleConstants.eventTypeStopParameterValues.has(eventTypeKey)
  );
};

const getEventTypeConstants = (props) => {
  const isShipmentLevelNotification = props.accessLevel === 'SHIPMENT';

  if (props.selectedMode === constants.ModeTypeConstants.TL) {
    return getTlEventTypeKeyConstants(isShipmentLevelNotification);
  } else if (props.selectedMode === constants.ModeTypeConstants.LTL) {
    return constants.LTLEventTypeKeyDisplayConstants;
  } else if (props.selectedMode === constants.ModeTypeConstants.PARCEL) {
    return constants.ParcelEventTypeKeyDisplayConstants;
  } else if (props.selectedMode === constants.ModeTypeConstants.AIR) {
    return getAirEventTypeKeyConstants();
  } else if (props.selectedMode === constants.ModeTypeConstants.OCEAN) {
    return constants.OceanEventTypeKeyDisplayConstants;
  } else {
    return new Map([]);
  }
};

const getTlEventTypeKeyConstants = (isShipmentLevelNotification) => {
  const filteredTLEventTypeKeyConstants = new Map(constants.TLEventTypeKeyDisplayConstants);
  if (!tlDepartedLateNotificationsEnabled()) {
    filteredTLEventTypeKeyConstants.delete(TLEventTypeKeyConstants.DepartedLate);
  }
  if (!enableShipmentTrackingMethod() || isShipmentLevelNotification) {
    filteredTLEventTypeKeyConstants.delete(TLEventTypeKeyConstants.ShipmentPendingTrackingMethod);
  }
  return filteredTLEventTypeKeyConstants;
};

const getAirEventTypeKeyConstants = () => {
  const filteredAIREventTypeKeyConstants = new Map(constants.AirEventTypeKeyDisplayConstants);
  filteredAIREventTypeKeyConstants.delete(constants.AirEventTypeKeyConstants.Booked);
  filteredAIREventTypeKeyConstants.delete(constants.AirEventTypeKeyConstants.Delivered);
  return filteredAIREventTypeKeyConstants;
};

const getEventTypeStopLocationParameterValues = (props) => {
  if (props.selectedMode === constants.ModeTypeConstants.OCEAN) {
    return constants.ShipmentNotificationRuleConstants.oceanEventTypeStopLocationParameterValues;
  } else if (props.selectedMode === constants.ModeTypeConstants.AIR) {
    return constants.ShipmentNotificationRuleConstants.airEventTypeStopLocationParameterValues;
  } else {
    return constants.ShipmentNotificationRuleConstants.eventTypeStopLocationParameterValues;
  }
};

const getEventTypeStatusCodeParameterValues = (props) => {
  if (props.selectedMode === constants.ModeTypeConstants.AIR) {
    return constants.ShipmentNotificationRuleConstants.airStatusCodeParameterValues;
  }
};

const eventTypeChanged = (selectedEventType, props) => {
  if (selectedEventType === 'ALL_UPDATES' || selectedEventType === 'SHIPMENT_DELETED') {
    props.subscriptionOptions.types = [constants.SubscriptionTypes.Push];
  } else if (selectedEventType === TLEventTypeKeyConstants.ShipmentPendingTrackingMethod) {
    props.subscriptionOptions.types = [constants.SubscriptionTypes.Email];
  } else {
    props.subscriptionOptions.types = [
      constants.SubscriptionTypes.Email,
      constants.SubscriptionTypes.Push,
      constants.SubscriptionTypes.SMS,
    ];
  }
};

const renderRulesCriteria = (props) => {
  const eventTypeKeyDisplayConstants = getEventTypeConstants(props);
  const eventTypeStopLocationParameterValues = getEventTypeStopLocationParameterValues(props);
  const eventTypeStatusCodeParameterValues = getEventTypeStatusCodeParameterValues(props);
  const eventDropdownItems = Array.from(eventTypeKeyDisplayConstants, ([eventTypeKey, eventTypeDisplay]) => ({
    displayName: eventTypeDisplay,
    value: eventTypeKey,
  }));
  const error = get(props, 'meta.error');
  const submitFailed = get(props, 'meta.submitFailed');
  return (
    <div key={props.selectedMode}>
      {submitFailed && error && (
        <div className="error text-danger" data-locator="event-rule-error-text">
          {error.fieldRequired}
        </div>
      )}

      {props.fields.map((criteria, index) => {
        const currentField = get(
          props,
          `formValues.notificationEvents[${index}]`,
          props.initialValues.notificationEvents[0]
        );
        const hasTimeParameterField =
          props.selectedMode !== constants.ModeTypeConstants.AIR &&
          currentField.eventTypeKey !== TLEventTypeKeyConstants.Departed;
        return (
          <FormSection
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            name={criteria}
          >
            <div className="row mb-4">
              <div className="col-20">
                <label htmlFor="event-rule-input" className="sr-only">
                  {<FormattedMessage id="notifications.types.event.label" defaultMessage="Event Type" />}
                </label>
                <Field
                  name="eventTypeKey"
                  className=""
                  validate={[requiredValidation]}
                  placeholder={intl.formatMessage(message.selectEvent)}
                  component={RenderSingleSelectDropdown}
                  data-locator="event-rule-input"
                  id="event-rule-input"
                  onChange={(selectedEventType) => eventTypeChanged(selectedEventType, props)}
                  dropdownItems={eventDropdownItems}
                />
              </div>
              <div className="col-4">
                {props.fields.length > 1 && (
                  <Button type="secondary" onClick={() => props.fields.remove(index)}>
                    <i className="material-icons delete-icon">close</i>
                  </Button>
                )}
              </div>
            </div>
            {IsToolbarBottomRowVisible(currentField.eventTypeKey) && [
              HasTimeConstraint(currentField.eventTypeKey) && (
                <div
                  className="row mb-4"
                  key={descriptionText(currentField.eventTypeKey, 'eventTypeTimeConstraintParameters')}
                >
                  {hasTimeParameterField && (
                    <div className="col-8">
                      <label className="bold">
                        {descriptionText(currentField.eventTypeKey, 'eventTypeTimeConstraintParameters')}
                      </label>
                      <div className="d-flex align-items-center">
                        <FormSection name="timeParameter">
                          <Field
                            name="value"
                            data-locator="time-constraint-input"
                            hintText={intl.formatMessage(message.selectTimeFrame)}
                            {...constants.selectFieldStyles}
                            component={renderTimeSelector}
                          />
                        </FormSection>
                        <span className="ml-2 bold">
                          {descriptionText(currentField.eventTypeKey, 'eventTypeTimeConstraintValueParameters')}
                        </span>
                      </div>
                    </div>
                  )}
                  {props.selectedMode === constants.ModeTypeConstants.LTL && (
                    <div className="col-4 d-flex align-items-end">
                      <Field
                        name="offsetDirection"
                        label=""
                        validate={[requiredValidation]}
                        placeholder={intl.formatMessage(message.selectEvent)}
                        component={RenderSingleSelectDropdown}
                        dropdownItems={constants.ShipmentNotificationRuleConstants.ltlBeforeAfterApptWindowValues.map(
                          ({ type, display }) => ({
                            value: type,
                            displayName: display,
                          })
                        )}
                      />
                    </div>
                  )}
                  {props.selectedMode === constants.ModeTypeConstants.AIR && (
                    <EventRuleStatusCode
                      className="col-8 d-flex flex-col justify-content-end"
                      eventTypeStatusCodes={eventTypeStatusCodeParameterValues}
                    >
                      <span className="bold">
                        {descriptionText(currentField.eventTypeKey, 'eventTypeStatusCodeParameterValues')}
                      </span>
                    </EventRuleStatusCode>
                  )}
                  {HasTimeConstraintSuffix(currentField.eventTypeKey) && (
                    <div className="col-12 d-flex flex-col justify-content-end">
                      <span className="bold">
                        {descriptionText(currentField.eventTypeKey, 'eventTypeTimeConstraintSuffixParameters')}
                      </span>
                    </div>
                  )}
                  {HasEventStopParameter(currentField.eventTypeKey) && (
                    <EventRuleStop
                      className="col-12 d-flex flex-col justify-content-end"
                      eventTypeStopLocations={eventTypeStopLocationParameterValues}
                      inputId={descriptionText(currentField.eventTypeKey, 'eventTypeStopParameterValues')}
                    >
                      <span className="bold">
                        {descriptionText(currentField.eventTypeKey, 'eventTypeStopParameterValues')}
                      </span>
                    </EventRuleStop>
                  )}
                  {(props.selectedMode === constants.ModeTypeConstants.AIR ||
                    props.selectedMode === constants.ModeTypeConstants.OCEAN) && (
                    <EventRuleCountry className="col-8 d-flex flex-col justify-content-end">
                      <span className="bold">
                        {descriptionText(currentField.eventTypeKey, 'eventTypeCountryParameterValues')}
                      </span>
                    </EventRuleCountry>
                  )}
                </div>
              ),
            ]}
          </FormSection>
        );
      })}
      <div className="row mb-4">
        <div className="col-24">
          <AddSectionLink onClick={() => props.fields.push(props.newEventType())}>
            <FormattedMessage id="notifications.eventRuleFormSection.addEvent" defaultMessage="Add event" />
          </AddSectionLink>
        </div>
      </div>
    </div>
  );
};

const EventRuleFormSection = (props) => {
  return (
    <FormattedMessage id="notifications.eventRuleFormSection.fieldRequired" defaultMessage="This field is required">
      {(fieldRequired) => (
        <FieldArray
          name="notificationEvents"
          component={renderRulesCriteria}
          accessLevel={props.accessLevel}
          formValues={props.formValues}
          initialValues={props.initialValues}
          dispatch={props.dispatch}
          newEventType={props.newEventType}
          selectedMode={props.selectedMode}
          subscriptionOptions={props.subscriptionOptions}
          validate={(val, allValues) => {
            if (
              get(allValues, 'notificationEvents[0].eventTypeKey') ===
              constants.LTLEventTypeKeyConstants.OutForDeliveryMissed
            ) {
              if (!val[0].offsetDirection) {
                return { fieldRequired };
              }

              if (!val[0].timeParameter.value) {
                val[0].timeParameter.value = 0;
              }
            }

            if (get(allValues, 'notificationEvents[0].eventTypeKey') === constants.TLEventTypeKeyConstants.Departed) {
              if (!val[0].stopParameter?.type) {
                return { fieldRequired };
              }
            }
          }}
        />
      )}
    </FormattedMessage>
  );
};

export default EventRuleFormSection;
