import { memo, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Checkmark, Close } from 'ui-components';

import * as styles from './HelpText.module.scss';

const HelpText: FunctionComponent<{}> = () => {
  return (
    <div id="helpText" className={styles.helpText}>
      <div className={styles.text}>
        <FormattedMessage
          id="settings.helpText.logoInstructions"
          defaultMessage="Logo file should be under 512kb and PNG (recommended), JPEG, SVG, or GIF, with a white or transparent background."
          tagName="p"
        />
        <FormattedMessage
          id="settings.helpText.emptySpace"
          defaultMessage="Empty space around the logo should be minimized."
          tagName="p"
        />
      </div>
      <div className={styles.examplesWrapper}>
        <div className={styles.badExample}>
          <div className={styles.circle} />
          <FormattedMessage id="settings.logoUpload.logo" defaultMessage="Logo" />
          <Close className={classNames(styles.icon, styles.invalidLogo)} />
        </div>
        <div className={styles.goodExample}>
          <div className={styles.circle} />
          <FormattedMessage id="settings.logoUpload.logo" defaultMessage="Logo" />
          <Checkmark className={classNames(styles.icon, styles.validLogo)} />
        </div>
      </div>
    </div>
  );
};

export const HelpTextComponent = HelpText;
export default memo(HelpText);
