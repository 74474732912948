import styled from 'styled-components';
import { Checkbox, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import filterFunc from 'lodash/filter';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import compact from 'lodash/compact';
import { primaryGreyTwenty, primaryBlue } from 'styles/colors';

import EventTypeFilterComponent from 'components/filter/types/EventTypeFilter';
import CompanyFilterComponent from 'components/filter/types/CompanyFilter';
import filterMessages from 'components/filter/modes/filterMessages.json';
import ReferenceKeyFilterComponent from './ReferenceKeyFilter';
import LocationTypeComponent from './LocationTypeFilter';

import { messages } from '../utils';

const StyledRow = styled('div')`
  @media (max-width: 767px) {
    flex-direction: column;

    .ant-btn {
      display: none;
    }

    & > .col {
      margin-bottom: 20px;
    }
  }
`;

const StyledCol = styled('div')`
  border-left: 1px solid ${primaryGreyTwenty};

  &:first-child {
    border-left: 0 none;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    border-left: 0 none;
  }
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  border: 1px solid ${primaryBlue};
  color: ${primaryBlue};
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  padding: 4px 16px;
  text-transform: uppercase;
  width: 100%;
`;

const StyledButtonTopContainer = styled('div')`
  @media (min-width: 768px) {
    display: none;
  }
`;

interface statusFiltersProps {
  id: number;
  list: any[];
  hasShipmentInProgressButton?: boolean;
}

interface checkListItemProps {
  id: string;
  dataKey: string;
  labelText: string;
  propertyName: string;
  checked: boolean;
}

interface shipmentStatusProps {
  filter: {
    status: string[];
  };
  handleCheckboxGroupUpdate: (obj: checkListItemProps, filter: string) => void;
}

const ShipmentStatus = ({ filter, handleCheckboxGroupUpdate }: shipmentStatusProps) => {
  const statusFilters = [
    {
      id: 1,
      list: [
        {
          id: filterMessages.scheduled.id,
          dataKey: filterMessages.scheduled.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.trackingPending} />,
          propertyName: 'scheduled',
          checked: false,
        },
      ],
    },
    {
      id: 2,
      list: [
        {
          id: filterMessages.atStop.id,
          dataKey: filterMessages.atStop.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.atStop} />,
          propertyName: 'atStop',
          checked: false,
        },
        {
          id: filterMessages.inTransit.id,
          dataKey: filterMessages.inTransit.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.inTransit} />,
          propertyName: 'inTransit',
          checked: false,
        },
      ],
      hasShipmentInProgressButton: true,
    },
    {
      id: 3,
      list: [
        {
          id: filterMessages.exception.id,
          dataKey: filterMessages.exception.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.exception} />,
          propertyName: 'exception',
          checked: false,
        },
      ],
    },
    {
      id: 4,
      list: [
        {
          id: filterMessages.completed.id,
          dataKey: filterMessages.completed.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.trackingEnded} />,
          propertyName: 'completed',
          checked: false,
        },
      ],
    },
  ];

  const onChange = (checkboxItem: checkListItemProps) =>
    handleCheckboxGroupUpdate({ ...checkboxItem, labelText: checkboxItem.dataKey }, 'status');

  const onSelectShipmentsInProgress = () => {
    const shipmentsInProgressOptions = filterFunc(
      statusFilters,
      (item: statusFiltersProps) => !!item.hasShipmentInProgressButton
    );
    const list = get(shipmentsInProgressOptions, ['0', 'list'], []);

    return forEach(list, (item: checkListItemProps) => {
      if (!filter.status.includes(item.dataKey)) {
        return onChange(item);
      }
    });
  };

  return (
    <div>
      <StyledButtonTopContainer>
        <StyledButton onClick={onSelectShipmentsInProgress} className="mb-4">
          <FormattedMessage id="quickView.filters.shipmentsInProgress" defaultMessage="Shipments in Progress" />
        </StyledButton>
      </StyledButtonTopContainer>
      <StyledRow className="row">
        {statusFilters.map(({ list, hasShipmentInProgressButton, id }: statusFiltersProps) => {
          return (
            <StyledCol className="col-auto" key={id}>
              <StyledRow className="row">
                {list.map((checkListItem: checkListItemProps) => {
                  return (
                    <StyledCol className="col" key={checkListItem.id.toString()}>
                      <Checkbox
                        onChange={() => onChange(checkListItem)}
                        className="whitespace-no-wrap"
                        checked={filter.status.includes(checkListItem.dataKey)}
                      >
                        {checkListItem.labelText}
                      </Checkbox>
                    </StyledCol>
                  );
                })}
              </StyledRow>
              {hasShipmentInProgressButton && (
                <StyledButton onClick={onSelectShipmentsInProgress} className="mt-4">
                  <FormattedMessage id="quickView.filters.shipmentsInProgress" defaultMessage="Shipments in Progress" />
                </StyledButton>
              )}
            </StyledCol>
          );
        })}
      </StyledRow>
    </div>
  );
};

const CompanyFilter = (props: any) => <CompanyFilterComponent {...props} />;

const LocationTypeFilter = (props: any) => <LocationTypeComponent {...props} />;

const EventTypeFilter = (props: any) => <EventTypeFilterComponent {...props} />;

const ReferenceKeyFilter = (props: any) => <ReferenceKeyFilterComponent {...props} />;

export default (props: any) => {
  const { modeFilterOptions, authorizations, principal } = props;
  const filterByValue = (val: any) => modeFilterOptions?.filter((item: any) => item.value === val);
  const hasTruckloadMode = filterByValue('TL')?.length > 0;
  const hasLtlMode = filterByValue('LTL')?.length > 0;
  const showCompanyFilter = authorizations.hasEnableCustomerEmbeddedFeatures() && hasTruckloadMode && hasLtlMode;
  const hasTenantCarrierRole = authorizations.hasTenantCarrierRole(principal);

  const companyFilter = showCompanyFilter
    ? {
        name: <FormattedMessage {...messages.company} />,
        component: CompanyFilter,
        modalId: 'company',
      }
    : undefined;

  const referenceKeyFilter = hasTenantCarrierRole
    ? undefined
    : {
        name: <FormattedMessage {...messages.referenceKey} />,
        component: ReferenceKeyFilter,
        modalId: 'attributeKeyValuePairs',
        customHeader: true,
      };

  const allModesFilters = [
    {
      name: <FormattedMessage {...messages.shipmentStatus} />,
      component: ShipmentStatus,
      modalId: 'shipmentStatus',
    },
    {
      name: <FormattedMessage {...messages.event} />,
      component: EventTypeFilter,
      modalId: 'event',
      classNameContent: 'p-0',
    },
    companyFilter,
    {
      name: <FormattedMessage {...messages.location} />,
      component: LocationTypeFilter,
      modalId: 'locationType',
      classNameContent: 'p-0',
    },
    referenceKeyFilter,
  ];

  return compact(allModesFilters);
};
