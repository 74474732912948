import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Popover, Row, Modal } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps, FormattedPlural } from 'react-intl';
import styled from 'styled-components';

import Table from '../../common/Table/Table';
import { useShipmentLineItemFormatter } from '../shipmentDetails/common/utils/shipmentHooks';
import { DataSource, LineItem } from './LineItems.model';
import SubLineItemsComponent from './SubLineItemsComponent/SubLineItemsComponent';
import { conditionallyRemoveColumns } from './ListItemsUtils';
import styles from './LineItemsComponent.module.scss';
import LineItemsContactPopover from './LineItemsContactPopover';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 16px 0;
    margin: 0 24px;
    h2 {
      margin-bottom: 0;
    }
  }
`;

interface LineItemsComponentProps extends WrappedComponentProps {
  lineItems: LineItem[];
}

interface LineItemsComponentState {
  selectedLineItem?: DataSource;
}

class LineItemsComponent extends Component<LineItemsComponentProps, LineItemsComponentState> {
  constructor(props: LineItemsComponentProps) {
    super(props);
    this.state = {
      selectedLineItem: undefined,
    };
  }
  setRowClassname = (record: DataSource, index: number) => {
    const sublineItems = !isNil(record.subLineItems) ? record.subLineItems : [];
    return classNames({
      hiddenExpandIcon: sublineItems.length === 0,
      shaded: index % 2 === 0,
    });
  };

  columns = [
    {
      title: this.props.intl.formatMessage({ id: 'shipmentDetails.lineItems.poNumber', defaultMessage: 'Identifier' }),
      dataIndex: 'poNumber',
      key: 'poNumber',
      className: 'primaryColumnValue',
      render: (record: string, lineItem: DataSource) => {
        return (
          <div className={styles.identifierContainer}>
            {!lineItem.isSubLineItem && (
              <button
                type="button"
                className={`${styles.identifier} ${styles.linkButton}`}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    selectedLineItem: lineItem,
                  });
                }}
              >
                {record}
              </button>
            )}
            {lineItem.isSubLineItem && record}
          </div>
        );
      },
    },
    {
      title: this.props.intl.formatMessage({
        id: 'shipmentDetails.lineItems.description',
        defaultMessage: 'Description',
      }),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: this.props.intl.formatMessage({
        id: 'shipmentDetails.lineItems.deliveryNumber',
        defaultMessage: 'DELIVERY NUMBER',
      }),
      dataIndex: 'deliveryNumber',
      key: 'deliveryNumber',
    },
    {
      title: this.props.intl.formatMessage({ id: 'shipmentDetails.lineItems.sku', defaultMessage: 'SKU' }),
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: this.props.intl.formatMessage({ id: 'shipmentDetails.lineItems.vin', defaultMessage: 'VIN' }),
      dataIndex: 'vin',
      key: 'vin',
    },
    {
      title: this.props.intl.formatMessage({
        id: 'shipmentDetails.lineItems.palletNumber',
        defaultMessage: 'Pallet Number',
      }),
      dataIndex: 'palletNumber',
      key: 'palletNumber',
    },
    {
      title: this.props.intl.formatMessage({
        id: 'shipmentDetails.lineItems.containerNumber',
        defaultMessage: 'Container Number',
      }),
      dataIndex: 'containerId',
      key: 'containerId',
    },
    {
      title: this.props.intl.formatMessage({ id: 'shipmentDetails.lineItems.quantity', defaultMessage: 'Quantity' }),
      dataIndex: 'itemQuantityAndPackaging',
      key: 'itemQuantityAndPackaging',
      render: function render(text: string, record: DataSource) {
        return (
          <span>
            {record.itemQuantityAndPackaging || 0}{' '}
            <FormattedPlural
              value={record.itemQuantityAndPackaging || 0}
              one={
                <FormattedMessage
                  id={`shipmentDetails.lineItems.${(record.itemPackageType || 'unit').toLowerCase()}.singular`}
                  values={{ records: record.itemQuantityAndPackaging || 0 }}
                />
              }
              other={
                <FormattedMessage
                  id={`shipmentDetails.lineItems.${(record.itemPackageType || 'unit').toLowerCase()}.plural`}
                  values={{ records: record.itemQuantityAndPackaging || 0 }}
                />
              }
            />
          </span>
        );
      },
    },
    {
      title: this.props.intl.formatMessage({ id: 'shipmentDetails.lineItems.value', defaultMessage: 'Value' }),
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: this.props.intl.formatMessage({
        id: 'shipmentDetails.lineItems.commodityCode',
        defaultMessage: 'Commodity Code',
      }),
      dataIndex: 'commodityCode',
      key: 'commodityCode',
    },
    {
      title: this.props.intl.formatMessage({ id: 'shipmentDetails.lineItems.pickUp', defaultMessage: 'Pickup' }),
      dataIndex: 'pickupStopNumber',
      key: 'pickupStopNumber',
    },
    {
      title: this.props.intl.formatMessage({ id: 'shipmentDetails.lineItems.delivery', defaultMessage: 'Delivery' }),
      dataIndex: 'deliveryStopNumber',
      key: 'deliveryStopNumber',
    },
    {
      title: this.props.intl.formatMessage({ id: 'shipmentDetails.lineItems.subItems', defaultMessage: 'Sub-Items' }),
      dataIndex: 'noOfSubLineItems',
      key: 'noOfSubLineItems',
    },

    {
      title: '',
      key: 'operation',
      dataIndex: 'operation',
      render: (record: string, lineItem: DataSource) => {
        const { companyName, contactName, phone, email, fax } = lineItem;
        return (
          <Popover
            placement="left"
            content={
              <LineItemsContactPopover
                companyName={companyName}
                contactName={contactName}
                phone={phone}
                fax={fax}
                email={email}
              />
            }
          >
            <InfoCircleOutlined className={'infoPopover'} />
          </Popover>
        );
      },
    },
  ];
  render() {
    const dataSource: DataSource[] = useShipmentLineItemFormatter(this.props.lineItems); // eslint-disable-line
    const columnsCopy: Array<ColumnProps<DataSource>> = conditionallyRemoveColumns([...this.columns], dataSource);
    return (
      <section id="contents" className="section-content">
        <FormattedMessage id="shipmentDetails.lineItems.contents" defaultMessage="Contents" tagName="h2" />
        <div className="card-container">
          <Row>
            <Col xs={24}>
              <Table
                columns={columnsCopy}
                pagination={false}
                rowClassName={this.setRowClassname}
                dataSource={dataSource}
                showSorterTooltip={false}
              />
            </Col>
          </Row>
        </div>
        {this.state.selectedLineItem && (
          <StyledModal
            title={<h2>{this.state.selectedLineItem && this.state.selectedLineItem.poNumber}</h2>}
            visible
            onCancel={() => this.setState({ ...this.state, selectedLineItem: undefined })}
            width={'80%'}
            footer={null}
            wrapClassName={styles.modalWrapper}
          >
            <SubLineItemsComponent lineItem={this.state.selectedLineItem} columns={this.columns} />
          </StyledModal>
        )}
      </section>
    );
  }
}

export default injectIntl(LineItemsComponent);
