import { Component } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete as AntAutoComplete } from 'antd';
import './Autocomplete.scss';

export default class AutoComplete extends Component {
  static propTypes = {
    mode: PropTypes.string,
    onSearch: PropTypes.func,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    custom: PropTypes.object,
    dataSource: PropTypes.array,
    onSelect: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  handleSelect = () => {
    this.setState({
      value: '',
    });
  };

  render() {
    const {
      mode = 'tags',
      label,
      placeholder,
      custom,
      defaultValue,
      dataSource,
      onSelect,
      onSearch,
      ...customProps
    } = this.props;

    return (
      <div className="autocomplete ant-form-vertical">
        {label && (
          <div className="ant-form-item-label">
            <label title={label}>{label}</label>
          </div>
        )}
        <AntAutoComplete
          className="autocomplete__input"
          data-locator={customProps['data-locator']}
          value={this.state.value}
          placeholder={placeholder}
          defaultValue={defaultValue}
          dataSource={dataSource}
          onChange={(val) => {
            this.setState(() => {
              return {
                value: val,
              };
            });
          }}
          onSearch={onSearch}
          onSelect={(val) => {
            onSelect(val);
            this.setState(() => {
              return { value: '' };
            });
          }}
          mode={mode}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          {...custom}
        />
      </div>
    );
  }
}
