import * as React from 'react';
import Col from 'react-bootstrap/Col';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import classNames from 'classnames';
import { Checkbox, Button } from 'ui-components';
import { primaryGrey } from 'styles/colors';
import { withTheme, WithThemeProps } from '../../../contexts/ThemeContext';

type LayoutFlowType = 'horizontal' | 'vertical';

interface CheckListProps extends WithThemeProps {
  listTitle?: string;
  listSubtitle?: string;
  checkListData: any[];
  checkFn: (item: any) => void;
  mode: string;
  className?: string;
  'data-locator'?: string;
  hasSelectAndClearAllOption?: boolean;
  checkboxGroupFlow?: LayoutFlowType;
}

const SelectAllContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;

const Divider = styled('span')`
  background-color: #d5d4d4;
  display: inline-block;
  height: 16px;
  margin: 0 4px;
  width: 1px;
`;

const StyledButton = styled(Button)`
  border: 0 none;
  height: 24px;
`;

const StyledLabel = styled('label')`
  color: ${primaryGrey};
  display: block;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 12px;
`;

const StyledSubtitle = styled('span')`
  color: ${primaryGrey};
  display: block;
  font-size: 13px;
  margin-bottom: 12px;
`;

const StyledClearAll = styled('div')`
  align-items: center;
  display: flex;

  .ant-btn {
    transition-property: color;
  }
`;

interface CheckboxGroupProps {
  layoutFlow: LayoutFlowType;
}

const StyledCheckboxGroup = styled('div')<CheckboxGroupProps>`
  display: flex;
  flex-direction: ${(props) => (props.layoutFlow === 'horizontal' ? 'row' : 'column')};
`;

const CheckList: React.FunctionComponent<CheckListProps> = ({
  listTitle = '',
  listSubtitle = '',
  checkListData,
  checkFn,
  mode,
  theme,
  className = null,
  'data-locator': dataLocator,
  hasSelectAndClearAllOption = false,
  checkboxGroupFlow = 'vertical',
}) => {
  const onSelectAll = () => {
    checkListData.forEach((item) => {
      if (!item.checked) checkFn.bind(null, item)();
    });
  };

  const onClearAll = () => {
    checkListData.forEach((item) => {
      if (item.checked) checkFn.bind(null, item)();
    });
  };

  const checkedListData = filter(checkListData, (item) => item.checked);
  const isSelectAllDisabled = checkListData.length === checkedListData.length;

  return (
    <Col className={classNames('check-list', className)} data-locator={dataLocator}>
      {listTitle && (
        <StyledLabel className={hasSelectAndClearAllOption && !listSubtitle ? 'mb-1' : ''}>{listTitle}</StyledLabel>
      )}
      {listSubtitle && (
        <StyledSubtitle className={hasSelectAndClearAllOption ? 'mb-1' : ''}>{listSubtitle}</StyledSubtitle>
      )}
      {hasSelectAndClearAllOption && (
        <SelectAllContainer>
          <StyledButton type="link" className="p-0" disabled={isSelectAllDisabled} onClick={onSelectAll}>
            Select All
          </StyledButton>
          <StyledClearAll className={!isEmpty(checkedListData) ? '' : 'invisible'}>
            <Divider />
            <StyledButton type="link" className="p-0" onClick={onClearAll}>
              Clear All
            </StyledButton>
          </StyledClearAll>
        </SelectAllContainer>
      )}
      <StyledCheckboxGroup layoutFlow={checkboxGroupFlow}>
        {checkListData.map((checkListItem: any, index) => {
          const checkboxData = [
            {
              key: checkListItem.id.toString(),
              label: checkListItem.labelText,
              checked: checkListItem.checked,
            },
          ];

          return (
            <Checkbox
              className={classNames({
                'mr-3': checkboxGroupFlow === 'horizontal' && index !== checkListData.length - 1,
              })}
              key={checkListItem.id.toString()}
              checkboxData={checkboxData}
              onChange={checkFn.bind(null, checkListItem)}
              mode={mode}
              theme={theme}
            />
          );
        })}
      </StyledCheckboxGroup>
    </Col>
  );
};

export default withTheme(CheckList);
