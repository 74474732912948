import isNil from 'lodash/isNil';
import { useState, createContext, useRef, MutableRefObject, FC } from 'react';
import { PrincipalContextProps } from 'contexts/PrincipalContext';
import { getPrincipalAuthorizationsFromPrincipalContext, supplyStackWebComponentsEnabled } from 'common/authorizations';

export interface SupplyStackIFrameContextProps {
  setIsFlyoverOpen: (value: boolean) => void;
  setIsBookingWizardOpen: (value: boolean) => void;
  flyoverRef: MutableRefObject<HTMLIFrameElement | null> | null;
  bookingWizardRef: MutableRefObject<HTMLIFrameElement | null> | null;
}

export const SupplyStackIFrameContext = createContext<SupplyStackIFrameContextProps>({
  flyoverRef: null,
  bookingWizardRef: null,
  setIsFlyoverOpen: (value) => value,
  setIsBookingWizardOpen: (value) => value,
});

export interface SupplyStackIFramesProps {
  children: React.ReactNode;
  principal?: PrincipalContextProps;
}

const SupplyStackIFrames: FC<SupplyStackIFramesProps> = ({ children, principal }) => {
  const [isFlyoverOpen, setIsFlyoverOpen] = useState(false);
  const [isBookingWizardOpen, setIsBookingWizardOpen] = useState(false);
  const flyoverRef = useRef<HTMLIFrameElement | null>(null);
  const bookingWizardRef = useRef<HTMLIFrameElement | null>(null);

  const authorizations = isNil(principal) ? undefined : getPrincipalAuthorizationsFromPrincipalContext(principal);

  return (
    <SupplyStackIFrameContext.Provider
      value={{ setIsFlyoverOpen, setIsBookingWizardOpen, flyoverRef, bookingWizardRef }}
    >
      {children}
      {!isNil(authorizations) && authorizations.hasSlotBooking() && supplyStackWebComponentsEnabled() && (
        <>
          <iframe
            ref={flyoverRef}
            id="supplystack-flyover"
            title="supplystack-flyover"
            frameBorder="0"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: isFlyoverOpen ? 9999 : -9999,
              height: isFlyoverOpen ? '100vh' : 0,
              width: isFlyoverOpen ? '100vw' : 0,
            }}
            src="https://s3-eu-west-1.amazonaws.com/assets.supplystack.com/p44-components/flyover-p44-iframe.html"
          ></iframe>
          <iframe
            ref={bookingWizardRef}
            id="supplystack-booking-wizard"
            title="supplystack-booking-wizard"
            frameBorder="0"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: isBookingWizardOpen ? 9999 : -9999,
              height: isBookingWizardOpen ? '100vh' : 0,
              width: isBookingWizardOpen ? '100vw' : 0,
            }}
            src="https://s3-eu-west-1.amazonaws.com/assets.supplystack.com/p44-components/booking-wizard-p44-iframe.html"
          ></iframe>
        </>
      )}
    </SupplyStackIFrameContext.Provider>
  );
};

export default SupplyStackIFrames;
