import axios from 'axios';
import { useState, useEffect } from 'react';
import { microWebappsUrls, SERVER_URL } from 'common/AppConstants';
import { PrincipalContextProps } from 'contexts/PrincipalContext';
import { OrderListFeatureFlags } from '../orderFeatureFlags';

export function useOrderFeatureFlags(principal: PrincipalContextProps | undefined): OrderListFeatureFlags {
  const [data, setData] = useState<OrderListFeatureFlags>();

  function fetchFlags() {
    // simple workaround for testing this locally
    const url = (microWebappsUrls.baseGatewayUrl as unknown as string).includes('localhost')
      ? `${microWebappsUrls.baseGatewayUrl}/feature-flags`
      : `${SERVER_URL}/order-page-gateway/feature-flags`;
    axios
      .get(url, {
        withCredentials: true,
      })
      .then((response) => setData(response.data))
      .catch(() => setData({ useNewOrderPage: false, openBothOrderPages: false }));
  }

  useEffect(() => {
    if (principal?.id) {
      fetchFlags();
    }
  }, [principal]);

  return { openBothOrderPages: false, useNewOrderPage: false, ...data };
}
