import qs from 'querystring';
import { useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import ReactAutosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { Button } from 'antd';

import { SVGIcon } from 'ui-components';
import { primaryBlue, primaryGreyEighty, primaryGreyTwenty, primaryGrey, primaryGreyFive, white } from 'styles/colors';

import { FILTER_RESULT_DELAY } from 'common/AppConstants';
import useDebounce from 'hooks/useDebounce';

import useAttributeKeys from '../../../../filter/util/useAttributeKeys';

const StyledResultsLabel = styled('p')`
  color: ${primaryGreyEighty};
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 14px;
  text-transform: uppercase;
`;

const StyledResultsContainer = styled('div')`
  max-height: 480px;
  overflow-y: auto;
`;

const StyledResultItem = styled('div')`
  border 1px solid ${(props) => (props.hasMatches ? primaryBlue : 'transparent')};
  border-radius: 4px;
  cursor: pointer;
  padding: 9px 14px 9px 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: rgba(45, 41, 38, 0.05);
    border-color: ${(props) => (props.hasMatches ? primaryBlue : 'rgba(45, 41, 38, 0.05)')};
  }

  &:active {
    background-color: rgba(0, 85, 139, 0.2);
    border-color: ${(props) => (props.hasMatches ? primaryBlue : 'rgba(0, 85, 139, 0.2)')};
  }
`;

const StyledSVGIcon = styled(SVGIcon)`
  color: ${primaryBlue};
  font-size: 12px;
`;

const StyledModalHeader = styled('div')`
  border-bottom: 1px solid ${primaryGreyTwenty};
  padding: 16px 32px 15px;
  margin: -16px -32px 16px;
`;

const StyledModalHeading = styled('h1')`
  color: ${primaryGrey};
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 0;
`;

const StyledButtonClose = styled(Button)`
  background-color: ${primaryGreyFive};
  border: 1px solid transparent;
  border-radius: 100px !important;
  color: ${primaryBlue};
  font-size: 14px;
  padding: 9px;
  height: auto;
  line-height: 0;
`;

const StyledBadge = styled('span')`
  background-color: ${primaryGreyEighty};
  border-radius: 4px;
  color: ${white};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-right: 16px;
  padding: 0 3px 0 4px;
`;

const theme = {
  container: 'autosuggest__container',
  containerOpen: 'autosuggest__container--open',
  input: 'autosuggest__input',
  inputOpen: 'autosuggest__input--open',
  inputFocused: 'autosuggest__input--focused',
  suggestionsContainer: 'autosuggest__suggestions-container',
  suggestionsContainerOpen: 'autosuggest__suggestions-container--open',
  suggestionsList: 'autosuggest__suggestions-list',
  suggestion: 'autosuggest__suggestion suggestion-content text-tuna',
  suggestionFirst: 'autosuggest__suggestion--first',
  suggestionHighlighted: 'autosuggest__suggestion--highlighted',
  sectionContainer: 'autosuggest__section-container',
  sectionContainerFirst: 'autosuggest__section-container--first',
  sectionTitle: 'autosuggest__section-title',
};

const KeysStep = ({
  setCurrentStep,
  setCurrentKey,
  modalName,
  closeModal,
  attributeKeyValuePairs,
  isQuickViewSetup,
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const debouncedValue = useDebounce(currentValue, FILTER_RESULT_DELAY);
  const params = qs.stringify({ q: debouncedValue });
  const attrKeys = useAttributeKeys(params);

  const attrKeysMap = attrKeys.map((item) => {
    return {
      id: get(item, 'id'),
      value: get(item, 'attributeName'),
    };
  });

  const renderSuggestion = (suggestion, { query }) => {
    const attributeName = get(suggestion, 'value');
    const matches = AutosuggestHighlightMatch(attributeName, query);
    const parts = AutosuggestHighlightParse(attributeName, matches);
    const matchResults = find(attributeKeyValuePairs, (item) => item.name === attributeName);
    const countMatches = matchResults ? matchResults.values.length : 0;
    const hasMatches = countMatches > 0;

    return (
      <StyledResultItem className="d-flex justify-content-between align-items-center" hasMatches={hasMatches}>
        <div className="d-flex justify-content-between align-items-center flex-fill">
          <span>
            {parts.map((part) => {
              const highlight = get(part, 'highlight');
              const text = get(part, 'text');

              if (highlight)
                return (
                  <span key={suggestion.id} className="font-weight-bold">
                    {text}
                  </span>
                );
              return text;
            })}
          </span>
          {hasMatches && <StyledBadge>{countMatches}</StyledBadge>}
        </div>
        <StyledSVGIcon name="chevron-right" />
      </StyledResultItem>
    );
  };

  const getSuggestionValue = (suggestion) => {
    setCurrentKey(suggestion);
    setCurrentStep(1);
  };

  const onChange = (event, { newValue }) => {
    if (!isUndefined(newValue)) {
      setCurrentValue(newValue);
    }
  };

  const inputProps = {
    placeholder: 'Search by name',
    value: currentValue,
    onChange,
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    if (!isUndefined(value)) {
      setCurrentValue(value);
    }
  };

  const renderInputComponent = (inputProps) => (
    <div>
      <input {...inputProps} />
      <StyledResultsLabel>{attrKeys.length} Results</StyledResultsLabel>
    </div>
  );

  return (
    <div>
      {!isQuickViewSetup && (
        <StyledModalHeader>
          <div className="d-flex justify-content-between align-items-center">
            <StyledModalHeading>{modalName}</StyledModalHeading>
            <StyledButtonClose onClick={closeModal} className="ml-16">
              <SVGIcon name="close" />
            </StyledButtonClose>
          </div>
        </StyledModalHeader>
      )}
      <StyledResultsContainer>
        <ReactAutosuggest
          suggestions={attrKeysMap}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          inputProps={inputProps}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          alwaysRenderSuggestions
          focusInputOnSuggestionClick={false}
          theme={theme}
        />
      </StyledResultsContainer>
    </div>
  );
};

export default KeysStep;
