/* eslint-disable no-undef */
import { Location, MarkerType } from '../../models/Location';
import * as styles from '../map.module.scss';

export const pingSVG = (fillColor: string, scale: number) =>
  `<svg xmlns="http://www.w3.org/2000/svg" width="${scale}" height="${scale}" viewBox="0 0 2 2" class="ping-marker">
        <circle fill="${fillColor}" cx="1" cy="1" r="1"/>
      </svg>
    `;

/* tslint:disable */
export const currentSvg = (fillColor: string) =>
  `<svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 28 40" width="36" class="current-stop-marker">
        <path fill="${fillColor}" d="M14,0A14,14,0,0,0,0,14C0,24.5,14,40,14,40S28,24.5,28,14A14,14,0,0,0,14,0Z"/>
        <circle fill="#fff" cx="14" cy="14" r="5"/>
	  </svg>
`;

export const deliveryFlagFilled = (fillColor: string) =>
  `<svg height="24" width="36" viewBox="0 0 36 18" xmlns="http://www.w3.org/2000/svg">
		<path style="transform: translate(13px, 0)" fill="${fillColor}"
		  d="M6,3 C6.55228475,3 7,3.44771525 7,4 L7,4.88 C8.06,4.44 9.5,4 11,4 C14,4 14,6 16,6 C19,6 20,4 20,4 L20,12 C20,12 19,14 16,14 C13,14 13,12 11,12 C8,12 7,14 7,14 L7,21 L5,21 L5,4 C5,3.44771525 5.44771525,3 6,3 Z"
		  id="path-1"></path>
	 </svg>`;
/* tslint:enable */

export const stopMarker = (fillColor: string) =>
  `<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <circle fill="${fillColor}" id="path-1" cx="12" cy="12" r="12"></circle>
  </svg>`;

export const buildMapMarker = (location: Location): H.map.Marker => {
  return new H.map.Marker(locationToIcon(location), {
    icon: buildIcon(location.markerType),
    zIndex: location.markerType === MarkerType.CURRENT_LOCATION ? 1 : 0,
  });
};

export const buildIcon = (iconType?: MarkerType, fillColor: string = styles.primaryColor) => {
  let svg;
  let options;
  if (iconType === MarkerType.CURRENT_LOCATION) {
    svg = currentSvg(fillColor);
  } else if (iconType === MarkerType.DESTINATION) {
    svg = deliveryFlagFilled(fillColor);
  } else if (iconType === MarkerType.STOP) {
    svg = stopMarker(fillColor);
  } else {
    const scale = 12;
    svg = pingSVG(fillColor, scale);
    options = { anchor: { x: scale / 2, y: scale / 2 }, crossOrigin: false };
  }
  return new H.map.Icon(svg, options);
};

export const locationToIcon = (location: Location): H.geo.IPoint => {
  return { lat: location.latitude, lng: location.longitude };
};
