import { defineMessages } from 'react-intl';
import { message } from 'ui-components';
import { messages as ltlMessages } from 'components/shipment/common/ltl/enums/ltlStatusMap';
export const basicStopStatuses = [
  { id: '99998', stopNumber: '1', statusCode: 'UNKNOWN', recordedArrivalCode: 'UNKNOWN' },
  { id: '99999', stopNumber: '2', statusCode: 'UNKNOWN', recordedArrivalCode: 'UNKNOWN' },
];

export const flagColors = {
  ON_TIME: 'primary-blue-75',
  SCHEDULED: 'yellow',
  LATE: 'new-fruit',
  EARLY: 'green',
  EXCEPTION: 'primary-grey-80',
};

export const utcDateKeys = [
  'utcRecordedDepartureDateTime',
  'utcRecordedArrivalDateTime',
  'utcRetrievalDateTime',
  'utcAppointmentEndDateTime',
];

export const localDateKeys = [
  'localRecordedDepartureSplitDateTime',
  'localRecordedArrivalSplitDateTime',
  'localTimestamp',
  'localAppointmentEndDateTime',
];

export const arrivalDepartureDateKeys = [
  'localRecordedDepartureSplitDateTime',
  'localEarliestEstimatedArrivalSplitDateTime',
  'utcRecordedDepartureDateTime',
  'utcRecordedArrivalDateTime',
  'localRecordedArrivalSplitDateTime',
];

const messages = defineMessages({
  onTimeToDelivery: {
    id: 'shipmentList.listCardText.onTimeToDelivery',
    defaultMessage: 'On time to delivery',
  },
  deliveredOnTime: {
    id: 'shipmentList.listCardText.deliveredOnTime',
    defaultMessage: 'Delivered on time',
  },
  deliveredEarly: {
    id: 'shipmentList.listCardText.deliveredEarly',
    defaultMessage: 'Delivered {DAYS_OFFSET} early',
  },
  deliveredLate: {
    id: 'shipmentList.listCardText.deliveredLate',
    defaultMessage: 'Delivered {DAYS_OFFSET} late',
  },
  wasEstimatedToArriveOn: {
    id: 'shipmentList.listCardText.wasEstimatedToArriveOn',
    defaultMessage: 'Was estimated to arrive on {DATE}',
  },
  wasEstimatedToArriveOnWithTime: {
    id: 'shipmentList.listCardText.wasEstimatedToArriveOnWithTime',
    defaultMessage: 'Was estimated to arrive on {DATE} at {TIME} {TIMEZONE}',
  },
  timedOutAt: {
    id: 'shipmentList.listCardText.timedOutAt',
    defaultMessage: 'Timed out {DATE} at {TIME} {TIMEZONE}',
  },
  pickupScheduledForBy: {
    id: 'shipmentList.listCardText.pickupScheduledForBy',
    defaultMessage: 'Pickup scheduled for {DATE} by {TIME} {TIMEZONE}',
  },
  canceledAt: {
    id: 'shipmentList.listCardText.canceledAt',
    defaultMessage: 'Canceled {DATE} at {TIME} {TIMEZONE}',
  },
  pickUpByAt: {
    id: 'shipmentList.listCardText.pickUpByAt',
    defaultMessage: 'Pick up by {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  pickUpByNoTime: {
    id: 'shipmentList.listCardText.pickUpByNoTime',
    defaultMessage: 'Pick up by {DATE_MONTH_YEAR}',
  },
  pickedUpOnAt: {
    id: 'shipmentList.listCardText.pickedUpOnAt',
    defaultMessage: 'Picked up on {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  pickedUpOnNoTime: {
    id: 'shipmentList.listCardText.pickedUpOnNoTime',
    defaultMessage: 'Picked up on {DATE_MONTH_YEAR} {DATE_TIMEZONE}',
  },
  departedOnAt: {
    id: 'shipmentList.listCardText.departedOnAt',
    defaultMessage: 'Departed on {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  departedNoTime: {
    id: 'shipmentList.listCardText.departedNoTime',
    defaultMessage: 'Departed on {DATE_MONTH_YEAR} {DATE_TIMEZONE}',
  },
  arrivedOnAt: {
    id: 'shipmentList.listCardText.arrivedOnAt',
    defaultMessage: 'Arrived on {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  arrivedNoTime: {
    id: 'shipmentList.listCardText.arrivedNoTime',
    defaultMessage: 'Arrived on {DATE_MONTH_YEAR} {DATE_TIMEZONE}',
  },
  deliverByAt: {
    id: 'shipmentList.listCardText.deliverByAt',
    defaultMessage: 'Deliver by {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  deliverByNoTime: {
    id: 'shipmentList.listCardText.deliverByNoTime',
    defaultMessage: 'Deliver by {DATE_MONTH_YEAR} {DATE_TIMEZONE}',
  },
  deliveredOnAt: {
    id: 'shipmentList.listCardText.deliveredOnAt',
    defaultMessage: 'Delivered on {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  deliveredNoTime: {
    id: 'shipmentList.listCardText.deliveredNoTime',
    defaultMessage: 'Delivered on {DATE_MONTH_YEAR} {DATE_TIMEZONE}',
  },
  expiredOnAt: {
    id: 'shipmentList.listCardText.expiredOnAt',
    defaultMessage: 'Expired on {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  deliveredWithExceptionOnAt: {
    id: 'shipmentList.listCardText.deliveredWithExceptionOnAt',
    defaultMessage: 'Delivered with exception on {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  byAt: {
    id: 'shipmentList.listCardText.byAt',
    defaultMessage: 'By {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  byNoTime: {
    id: 'shipmentList.listCardText.byNoTime',
    defaultMessage: 'By {DATE_MONTH_YEAR} {DATE_TIMEZONE}',
  },
  estimatedToArrive: {
    id: 'shipmentList.listCardText.estimatedToArrive',
    defaultMessage: 'Estimated to arrive on {DATE}',
  },
  estimatedToArriveWithTime: {
    id: 'shipmentList.listCardText.estimatedToArriveWithTime',
    defaultMessage: 'Estimated to arrive on {DATE} at {TIME} {TIMEZONE}',
  },
  estimatedToArriveToday: {
    id: 'shipmentList.listCardText.estimatedToArriveToday',
    defaultMessage: 'Estimated to arrive today',
  },
  estimatedToArriveTomorrow: {
    id: 'shipmentList.listCardText.estimatedToArriveTomorrow',
    defaultMessage: 'Estimated to arrive tomorrow',
  },
  wasEstimatedToArriveYesterday: {
    id: 'shipmentList.listCardText.wasEstimatedToArriveYesterday',
    defaultMessage: 'Was estimated to arrive yesterday',
  },
  arrivedOnTime: {
    id: 'shipmentList.listCardText.arrivedOnTime',
    defaultMessage: 'Arrived on time to delivery',
  },
  arrivedEarly: {
    id: 'shipmentList.listCardText.arrivedEarly',
    defaultMessage: 'Arrived {DAYS_OFFSET} early',
  },
  arrivedLate: {
    id: 'shipmentList.listCardText.arrivedLate',
    defaultMessage: 'Arrived {DAYS_OFFSET} late',
  },
  runningEarly: {
    id: 'shipmentList.listCardText.runningEarly',
    defaultMessage: 'Running {DAYS_OFFSET} early',
  },
  runningLate: {
    id: 'shipmentList.listCardText.runningLate',
    defaultMessage: 'Running {DAYS_OFFSET} late',
  },
  pickedUpOnTime: {
    id: 'shipmentList.listCardText.pickedUpOnTime',
    defaultMessage: 'Picked up on time',
  },
  pickedUpEarly: {
    id: 'shipmentList.listCardText.pickedUpEarly',
    defaultMessage: 'Picked up {DAYS_OFFSET} early',
  },
  pickedUpLate: {
    id: 'shipmentList.listCardText.pickedUpLate',
    defaultMessage: 'Picked up {DAYS_OFFSET} late',
  },
  delayed: {
    id: 'shipmentList.listCardText.delayed',
    defaultMessage: 'Delayed',
  },
  updatedAgo: {
    id: 'shipmentList.listCardText.updatedAgo',
    defaultMessage: 'Updated {UPDATED_DURATION} ago',
  },
  lastUpdateReceivedOnAt: {
    id: 'shipmentList.listCardText.lastUpdateReceivedOnAt',
    defaultMessage: 'Last update received on {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  notReceivingData12To23: {
    id: 'shipmentList.listCardText.notReceivingData12To23',
    defaultMessage: 'Last update received on {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  notReceivingData24To47: {
    id: 'shipmentList.listCardText.notReceivingData24To47',
    defaultMessage: 'Last update received on {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  notReceivingData48To71: {
    id: 'shipmentList.listCardText.notReceivingData48To71',
    defaultMessage: 'Last update received on {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
  notReceivingDataMoreThan72: {
    id: 'shipmentList.listCardText.notReceivingDataMoreThan72',
    defaultMessage: 'Last update received on {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
  },
});

export const statusOptions = {
  types: {
    SCHEDULED: 'SCHEDULED',
    ATTEMPTING_TO_TRACK: 'ATTEMPTING_TO_TRACK',
    PICKUP_MISSED: 'PICKUP_MISSED',
    INTERLINE_MISSED: 'INTERLINE_MISSED',
    DEPARTURE_MISSED: 'DEPARTURE_MISSED',
    DELIVERY_MISSED: 'DELIVERY_MISSED',
    HELD: 'HELD',
    DELAYED: 'DELAYED',
    AT_PICKUP: 'AT_PICKUP',
    AT_TERMINAL: 'AT_TERMINAL',
    AT_DELIVERY: 'AT_DELIVERY',
    PICKED_UP: 'PICKED_UP',
    IN_TRANSIT: 'IN_TRANSIT',
    OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
    DELIVERED: 'DELIVERED',
    EXCEPTION: 'EXCEPTION',
    CANCELED: 'CANCELED',
    TIMED_OUT: 'TIMED_OUT',
    UNKNOWN: 'UNKNOWN',
    NOT_RECEIVING_DATA_12_TO_23: 'NOT_RECEIVING_DATA_12_TO_23',
    NOT_RECEIVING_DATA_24_TO_47: 'NOT_RECEIVING_DATA_24_TO_47',
    NOT_RECEIVING_DATA_48_TO_71: 'NOT_RECEIVING_DATA_48_TO_71',
    NOT_RECEIVING_DATA_MORE_THAN_72: 'NOT_RECEIVING_DATA_MORE_THAN_72',
  },
  milestones: {
    PICKED_UP: messages.pickedUpOnAt,
    ORIGIN_PICKUP_BY: messages.pickUpByAt,
    PICKED_UP_NO_TIME: messages.pickedUpOnNoTime,
    ORIGIN_PICKUP_BY_NO_TIME: messages.pickUpByNoTime,
    DEPARTED: messages.departedOnAt,
    DEPARTED_NO_TIME: messages.departedNoTime,
    ARRIVED: messages.arrivedOnAt,
    ARRIVED_NO_TIME: message.arrivedNoTime,
    DELIVERED: messages.deliveredOnAt,
    DELIVERED_NO_TIME: messages.deliveredNoTime,
    DESTINATION_DELIVER_BY: messages.deliverByAt,
    DESTINATION_DELIVER_BY_NO_TIME: messages.deliverByNoTime,
    DESTINATION_EXPIRED_BY: messages.expiredOnAt,
    DESTINATION_EXCEPTION_BY: messages.deliveredWithExceptionOnAt,
    BY: messages.byAt,
    BY_NO_TIME: messages.byNoTime,
    UNDEFINED: { id: undefined, defaultMessage: 'undefined' },
    labels: {
      PICKUP: 'Pickup',
      TERMINAL: 'Terminal',
      DESTINATION: 'Delivery',
    },
  },
  arrivalForecast: {
    SCHEDULED: messages.pickupScheduledForBy,
    ATTEMPTING_TO_TRACK: messages.pickupScheduledForBy,
    ESTIMATED: messages.estimatedToArrive,
    ESTIMATED_WITH_TIME: messages.estimatedToArriveWithTime,
    ESTIMATED_TODAY: messages.estimatedToArriveToday,
    ESTIMATED_TOMORROW: messages.estimatedToArriveTomorrow,
    ESTIMATED_YESTERDAY: messages.wasEstimatedToArriveYesterday,
    ESTIMATED_LATE: messages.wasEstimatedToArriveOn,
    ESTIMATED_LATE_WITH_TIME: messages.wasEstimatedToArriveOnWithTime,
    CANCELED: messages.canceledAt,
    TIMED_OUT: messages.timedOutAt,
    UNDEFINED: undefined,
    UNKNOWN: undefined,
  },
  flag: {
    types: {
      PICKED_UP: {
        EARLY: {
          indicator: 'startDate',
          color: flagColors.EARLY,
          text: messages.pickedUpEarly,
        },
        ON_TIME: {
          indicator: null,
          color: flagColors.ON_TIME,
          text: messages.pickedUpOnTime,
        },
        LATE: {
          indicator: 'endDate',
          color: flagColors.LATE,
          text: messages.pickedUpLate,
        },
        UNKNOWN: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
        NOT_RECEIVING_DATA_12_TO_23: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
        NOT_RECEIVING_DATA_24_TO_47: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
        NOT_RECEIVING_DATA_48_TO_71: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
        NOT_RECEIVING_DATA_MORE_THAN_72: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
      },
      IN_TRANSIT: {
        EARLY: {
          indicator: 'startDate',
          color: flagColors.EARLY,
          text: messages.runningEarly,
        },
        ON_TIME: {
          indicator: null,
          color: flagColors.ON_TIME,
          text: messages.onTimeToDelivery,
        },
        LATE: {
          indicator: 'endDate',
          color: flagColors.LATE,
          text: messages.runningLate,
        },
        UNKNOWN: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
        NOT_RECEIVING_DATA_12_TO_23: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
        NOT_RECEIVING_DATA_24_TO_47: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
        NOT_RECEIVING_DATA_48_TO_71: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
        NOT_RECEIVING_DATA_MORE_THAN_72: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
      },
      AT_DELIVERY: {
        EARLY: {
          indicator: 'startDate',
          color: flagColors.EARLY,
          text: messages.arrivedEarly,
        },
        ON_TIME: {
          indicator: null,
          color: flagColors.ON_TIME,
          text: messages.arrivedOnTime,
        },
        LATE: {
          indicator: 'endDate',
          color: flagColors.LATE,
          text: messages.arrivedLate,
        },
        UNKNOWN: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
        NOT_RECEIVING_DATA_12_TO_23: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
        NOT_RECEIVING_DATA_24_TO_47: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
        NOT_RECEIVING_DATA_48_TO_71: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
        NOT_RECEIVING_DATA_MORE_THAN_72: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
      },
      DELIVERED: {
        EARLY: {
          indicator: 'startDate',
          color: flagColors.EARLY,
          text: messages.deliveredEarly,
        },
        ON_TIME: {
          indicator: null,
          color: flagColors.ON_TIME,
          text: messages.deliveredOnTime,
        },
        LATE: {
          indicator: 'endDate',
          color: flagColors.LATE,
          text: messages.deliveredLate,
        },
        UNKNOWN: {
          indicator: null,
          color: flagColors.EXCEPTION,
          text: undefined,
        },
      },
      DELAYED: {
        color: flagColors.EXCEPTION,
        text: messages.delayed,
      },
    },
  },
  trackingMethod: {
    CARRIER_CONNECTION: 'Tracking Method: Carrier Connection',
  },
  trackingInfo: {
    CARRIER_CONNECTION: {
      SCHEDULED_WAITING: 'We will begin requesting tracking updates 90 minutes before the pickup window',
      SCHEDULED_RECEIVING: 'Waiting to receive tracking updates from the carrier',
      CONNECTED: 'We have a live connection to the carrier’s API',
      COMPLETED: 'Tracking activities are complete',
      UNDEFINED: undefined,
    },
  },
  lastUpdated: {
    UPDATED_DURATION: {
      type: 'UPDATED_DURATION',
      text: messages.updatedAgo,
    },
    UPDATED_LAST_DATE: {
      type: 'UPDATED_LAST_DATE',
      text: messages.lastUpdateReceivedOnAt,
    },
    UNDEFINED: undefined,
  },
};

const defaultMilestones = {
  ORIGIN: {
    label: statusOptions.milestones.labels.PICKUP,
    tertiaryText: statusOptions.milestones.UNDEFINED,
  },
  ORIGIN_NO_TIME: {
    label: statusOptions.milestones.labels.PICKUP,
    tertiaryText: statusOptions.milestones.UNDEFINED,
  },
  TERMINAL: {
    label: statusOptions.milestones.labels.TERMINAL,
    tertiaryText: statusOptions.milestones.UNDEFINED,
  },
  TERMINAL_NO_TIME: {
    label: statusOptions.milestones.labels.TERMINAL,
    tertiaryText: statusOptions.milestones.UNDEFINED,
  },
  DESTINATION: {
    label: statusOptions.milestones.labels.DESTINATION,
    tertiaryText: statusOptions.milestones.UNDEFINED,
  },
  DESTINATION_NO_TIME: {
    label: statusOptions.milestones.labels.DESTINATION,
    tertiaryText: statusOptions.milestones.UNDEFINED,
  },
  ORIGIN_BY: {
    tertiaryText: statusOptions.milestones.ORIGIN_PICKUP_BY,
  },
  ORIGIN_BY_NO_TIME: {
    tertiaryText: statusOptions.milestones.ORIGIN_PICKUP_BY_NO_TIME,
  },
  DESTINATION_BY: {
    tertiaryText: statusOptions.milestones.DESTINATION_DELIVER_BY,
  },
  DESTINATION_BY_NO_TIME: {
    tertiaryText: statusOptions.milestones.DESTINATION_DELIVER_BY_NO_TIME,
  },
  BY: {
    tertiaryText: statusOptions.milestones.BY,
  },
  BY_NO_TIME: {
    tertiaryText: statusOptions.milestones.BY_NO_TIME,
  },
};

export const statusMap = {
  /**
   * Shipment is scheduled and is at least 90 minutes away from the start of the pickup appointment window.
   */
  SCHEDULED: {
    type: statusOptions.types.SCHEDULED,
    transitStatus: 'Scheduled',
    flag: {
      color: undefined,
      text: undefined,
    },
    arrivalForecast: statusOptions.arrivalForecast.SCHEDULED,
    progress: {
      displayMarker: true,
      markerColor: flagColors.SCHEDULED,
      markerCompletion: 0,
    },
    milestones: {
      ...defaultMilestones,
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.SCHEDULED_WAITING,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment is within 90 minutes of the pickup appointment window start and no status updates have been received yet.
   */
  ATTEMPTING_TO_TRACK: {
    type: statusOptions.types.ATTEMPTING_TO_TRACK,
    transitStatus: ltlMessages.attemptingToTrack,
    flag: {
      color: undefined,
      text: undefined,
    },
    arrivalForecast: statusOptions.arrivalForecast.SCHEDULED,
    progress: {
      displayMarker: true,
      markerColor: 'yellow',
      markerCompletion: 0,
    },
    milestones: {
      ...defaultMilestones,
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.SCHEDULED_RECEIVING,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment is within 90 minutes of the pickup appointment window start and no status updates have been received yet.
   */
  PICKUP_MISSED: {
    type: statusOptions.types.PICKUP_MISSED,
    transitStatus: ltlMessages.pickupMissed,
    flag: {
      color: undefined,
      text: undefined,
    },
    arrivalForecast: statusOptions.arrivalForecast.SCHEDULED,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: 0,
    },
    milestones: {
      ...defaultMilestones,
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.SCHEDULED_RECEIVING,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment is within 90 minutes of the pickup appointment window start and no status updates have been received yet.
   */
  INTERLINE_MISSED: {
    type: statusOptions.types.INTERLINE_MISSED,
    transitStatus: ltlMessages.interlineMissed,
    flag: {
      color: undefined,
      text: undefined,
    },
    arrivalForecast: statusOptions.arrivalForecast.SCHEDULED,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: 0,
    },
    milestones: {
      ...defaultMilestones,
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.SCHEDULED_RECEIVING,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment is within 90 minutes of the pickup appointment window start and no status updates have been received yet.
   */
  DEPARTURE_MISSED: {
    type: statusOptions.types.DEPARTURE_MISSED,
    transitStatus: ltlMessages.departureMissed,
    flag: {
      color: undefined,
      text: undefined,
    },
    arrivalForecast: statusOptions.arrivalForecast.SCHEDULED,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: 0,
    },
    milestones: {
      ...defaultMilestones,
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.SCHEDULED_RECEIVING,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  DELIVERY_MISSED: {
    type: statusOptions.types.DEPARTURE_MISSED,
    transitStatus: ltlMessages.deliveryMissed,
    flag: {
      color: undefined,
      text: undefined,
    },
    arrivalForecast: statusOptions.arrivalForecast.SCHEDULED,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: 0,
    },
    milestones: {
      ...defaultMilestones,
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.SCHEDULED_RECEIVING,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment is within 90 minutes of the pickup appointment window start and no status updates have been received yet.
   */
  HELD: {
    type: statusOptions.types.HELD,
    transitStatus: ltlMessages.held,
    flag: {
      color: undefined,
      text: undefined,
    },
    arrivalForecast: statusOptions.arrivalForecast.SCHEDULED,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: 0,
    },
    milestones: {
      ...defaultMilestones,
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.SCHEDULED_RECEIVING,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment is within 90 minutes of the pickup appointment window start and no status updates have been received yet.
   */
  DELAYED: {
    type: statusOptions.types.DELAYED,
    transitStatus: ltlMessages.delayed,
    flag: statusOptions.flag.types.DELAYED,
    arrivalForecast: statusOptions.arrivalForecast.SCHEDULED,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: 0,
    },
    milestones: {
      ...defaultMilestones,
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.SCHEDULED_RECEIVING,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment has been picked up and has departed from the ORIGIN stop.
   */
  AT_PICKUP: {
    type: statusOptions.types.AT_PICKUP,
    transitStatus: ltlMessages.atPickup,
    flag: {
      color: undefined,
      text: undefined,
    },
    arrivalForecast: undefined,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.ARRIVED,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.ARRIVED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.CONNECTED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment has been picked up and has departed from the ORIGIN stop.
   */
  PICKED_UP: {
    type: statusOptions.types.PICKED_UP,
    transitStatus: ltlMessages.pickedUp,
    flag: statusOptions.flag.types.PICKED_UP,
    arrivalForecast: undefined,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.CONNECTED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment is in transit to any stop.
   */
  IN_TRANSIT: {
    type: statusOptions.types.IN_TRANSIT,
    transitStatus: ltlMessages.inTransit,
    flag: statusOptions.flag.types.IN_TRANSIT,
    arrivalForecast: undefined,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
      TERMINAL: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED,
      },
      TERMINAL_NO_TIME: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.CONNECTED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment is at a TERMINAL type stop.
   */
  AT_TERMINAL: {
    type: statusOptions.types.AT_TERMINAL,
    transitStatus: ltlMessages.atTerminal,
    flag: statusOptions.flag.types.IN_TRANSIT,
    arrivalForecast: undefined,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
      TERMINAL: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.ARRIVED,
      },
      TERMINAL_NO_TIME: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.ARRIVED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.CONNECTED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment is at a delivery (DESTINATION) stop.
   */
  AT_DELIVERY: {
    type: statusOptions.types.AT_DELIVERY,
    transitStatus: ltlMessages.atDelivery,
    flag: statusOptions.flag.types.AT_DELIVERY,
    arrivalForecast: undefined,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
      TERMINAL: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED,
      },
      TERMINAL_NO_TIME: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED_NO_TIME,
      },
      DESTINATION: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.ARRIVED,
      },
      DESTINATION_NO_TIME: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.ARRIVED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.CONNECTED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment is en route to the delivery (DESTINATION) stop.
   */
  OUT_FOR_DELIVERY: {
    type: statusOptions.types.OUT_FOR_DELIVERY,
    transitStatus: ltlMessages.outForDelivery,
    flag: statusOptions.flag.types.IN_TRANSIT,
    arrivalForecast: undefined,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
      TERMINAL: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED,
      },
      TERMINAL_NO_TIME: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.CONNECTED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_DURATION,
  },
  /**
   * Shipment is en route to the delivery (DESTINATION) stop.
   */
  DELIVERED: {
    type: statusOptions.types.DELIVERED,
    transitStatus: ltlMessages.delivered,
    flag: statusOptions.flag.types.DELIVERED,
    arrivalForecast: undefined,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
      TERMINAL: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED,
      },
      TERMINAL_NO_TIME: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED_NO_TIME,
      },
      DESTINATION: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.DELIVERED,
      },
      DESTINATION_NO_TIME: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.DELIVERED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.COMPLETED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_LAST_DATE,
  },
  /**
   * Shipment hasn't any info between 12 and 23 hours
   */
  NOT_RECEIVING_DATA_12_TO_23: {
    type: statusOptions.types.NOT_RECEIVING_DATA_12_TO_23,
    transitStatus: ltlMessages.notReceivingData12To23,
    flag: statusOptions.flag.types.IN_TRANSIT,
    arrivalForecast: undefined,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
      TERMINAL: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED,
      },
      TERMINAL_NO_TIME: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED_NO_TIME,
      },
      DESTINATION: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.NOT_RECEIVING_DATA_12_TO_23,
      },
      DESTINATION_NO_TIME: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.DELIVERED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.UNDEFINED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_LAST_DATE,
  },
  /**
   * Shipment hasn't any info between 24 and 47 hours
   */
  NOT_RECEIVING_DATA_24_TO_47: {
    type: statusOptions.types.NOT_RECEIVING_DATA_24_TO_47,
    transitStatus: ltlMessages.notReceivingData24To47,
    flag: statusOptions.flag.types.IN_TRANSIT,
    arrivalForecast: undefined,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
      TERMINAL: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED,
      },
      TERMINAL_NO_TIME: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED_NO_TIME,
      },
      DESTINATION: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.NOT_RECEIVING_DATA_24_TO_47,
      },
      DESTINATION_NO_TIME: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.DELIVERED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.UNDEFINED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_LAST_DATE,
  },
  /**
   * Shipment hasn't any info between 48 and 71 hours
   */
  NOT_RECEIVING_DATA_48_TO_71: {
    type: statusOptions.types.NOT_RECEIVING_DATA_48_TO_71,
    transitStatus: ltlMessages.notReceivingData48To71,
    flag: statusOptions.flag.types.IN_TRANSIT,
    arrivalForecast: undefined,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
      TERMINAL: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED,
      },
      TERMINAL_NO_TIME: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED_NO_TIME,
      },
      DESTINATION: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.NOT_RECEIVING_DATA_48_TO_71,
      },
      DESTINATION_NO_TIME: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.DELIVERED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.UNDEFINED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_LAST_DATE,
  },
  /**
   * Shipment hasn't any info in more than 72 hours
   */
  NOT_RECEIVING_DATA_MORE_THAN_72: {
    type: statusOptions.types.NOT_RECEIVING_DATA_MORE_THAN_72,
    transitStatus: ltlMessages.notReceivingDataMoreThan72,
    flag: statusOptions.flag.types.IN_TRANSIT,
    arrivalForecast: undefined,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
      TERMINAL: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED,
      },
      TERMINAL_NO_TIME: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED_NO_TIME,
      },
      DESTINATION: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.NOT_RECEIVING_DATA_MORE_THAN_72,
      },
      DESTINATION_NO_TIME: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.DELIVERED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.UNDEFINED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_LAST_DATE,
  },
  /**
   * Shipment completed but with an exception.
   */
  EXCEPTION: {
    type: statusOptions.types.EXCEPTION,
    transitStatus: ltlMessages.completedWithExceptions,
    flag: statusOptions.flag.types.DELIVERED,
    arrivalForecast: undefined,
    progress: {
      displayMarker: true,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
      TERMINAL: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED,
      },
      TERMINAL_NO_TIME: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED_NO_TIME,
      },
      DESTINATION: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.DELIVERED,
      },
      DESTINATION_NO_TIME: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.DELIVERED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.COMPLETED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_LAST_DATE,
  },
  /**
   * Shipment was canceled.
   */
  CANCELED: {
    type: statusOptions.types.CANCELED,
    transitStatus: ltlMessages.canceled,
    flag: {
      color: undefined,
      text: undefined,
    },
    arrivalForecast: statusOptions.arrivalForecast.CANCELED,
    progress: {
      displayMarker: false,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
      TERMINAL: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED,
      },
      TERMINAL_NO_TIME: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED_NO_TIME,
      },
      DESTINATION: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.DEPARTED,
      },
      DESTINATION_NO_TIME: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.DEPARTED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.COMPLETED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_LAST_DATE,
  },
  /**
   * Shipment did not receive tracking information within 5 days of the pickup window.
   */
  TIMED_OUT: {
    type: statusOptions.types.TIMED_OUT,
    transitStatus: ltlMessages.timedOut,
    flag: {
      color: undefined,
      text: undefined,
    },
    arrivalForecast: statusOptions.arrivalForecast.TIMED_OUT,
    progress: {
      displayMarker: false,
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
      ORIGIN: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP,
      },
      ORIGIN_NO_TIME: {
        label: statusOptions.milestones.labels.PICKUP,
        tertiaryText: statusOptions.milestones.PICKED_UP_NO_TIME,
      },
      TERMINAL: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED,
      },
      TERMINAL_NO_TIME: {
        label: statusOptions.milestones.labels.TERMINAL,
        tertiaryText: statusOptions.milestones.DEPARTED_NO_TIME,
      },
      DESTINATION: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.DELIVERED,
      },
      DESTINATION_NO_TIME: {
        label: statusOptions.milestones.labels.DESTINATION,
        tertiaryText: statusOptions.milestones.DELIVERED_NO_TIME,
      },
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.COMPLETED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_LAST_DATE,
  },
  /**
   * Unknown status code
   */
  UNKNOWN: {
    type: statusOptions.types.UNKNOWN,
    transitStatus: ltlMessages.unknown,
    flag: {
      color: undefined,
      text: undefined,
    },
    arrivalForecast: statusOptions.arrivalForecast.UNDEFINED,
    progress: {
      markerColor: undefined,
      markerCompletion: undefined,
    },
    milestones: {
      ...defaultMilestones,
    },
    trackingMethod: statusOptions.trackingMethod.CARRIER_CONNECTION,
    trackingInfo: statusOptions.trackingInfo.CARRIER_CONNECTION.UNDEFINED,
    lastUpdated: statusOptions.lastUpdated.UPDATED_LAST_DATE,
  },
};
