/* eslint-disable react/no-array-index-key */
import { FormattedMessage } from 'react-intl';
import { Collapse } from 'antd';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import DateFormatUtil from 'components/shipment/common/utils/dateFormatUtil';
import { ReactComponent as DownloadIcon } from 'components/common/assets/icons/icon_download_document.svg';
import * as styles from './DocumentViewComponent.module.scss';
import { DocumentScope, DocumentTypes } from '../../enums/documentEnums';
import { primaryGreyFive } from '../../../../../styles/colors';
const { Panel } = Collapse;

const StyledPanel = styled(Panel)`
  .ant-collapse-content-box {
    background-color: ${primaryGreyFive};
  }
`;

// document components
const renderDocumentRow = (
  docTypeKey: keyof typeof DocumentTypes,
  scope: any,
  modifiedAtMoment: Moment,
  currentVersion: number,
  isLegacy?: boolean
) => {
  const docType = DocumentTypes[docTypeKey];
  return (
    <div className="inline-grid grid-cols-2 w-10/12">
      <span>
        {docType}
        {!isLegacy && <span> v{currentVersion}</span>}
      </span>
      <span className="pl-3">
        <span className={styles['scope-label']}>{getDisplayTextForScope(scope)}</span>
        {!isLegacy &&
          ` • ${DateFormatUtil.formatMomentAsDate(modifiedAtMoment)}
          ${DateFormatUtil.formatMomentAsTime(modifiedAtMoment)}`}
      </span>
    </div>
  );
};

const getDisplayTextForScope = (scope: DocumentScope) => {
  switch (scope) {
    case DocumentScope.CARRIER:
      return <FormattedMessage defaultMessage="Carrier" id="documentManualUploadUi.documentView.carrier" />;
    case DocumentScope.SHIPPER:
      return <FormattedMessage defaultMessage="Shipper" id="documentManualUploadUi.documentView.shipper" />;
    case DocumentScope.DRIVER:
      return <FormattedMessage defaultMessage="Driver" id="documentManualUploadUi.documentView.driver" />;
  }
};

// Recursive helper function to download documents
const download = (url: string, nextIndex: number, doc: any) => {
  window.open(url, doc.isLegacy ? '_blank' : '_self');
  // Set a timeout to download the next file to ensure window.open() is processed by the browser
  // before we attempt to download the next file
  setTimeout(() => {
    if (nextIndex < doc.currentVersion?.files[0].urls.length) {
      download(doc.currentVersion?.files[0].urls[nextIndex], nextIndex + 1, doc);
    }
  }, 500);
};

const renderDownloadButton = (doc: any) => {
  const onDownloadClicked = (event: any) => {
    event.stopPropagation();
    const firstFile = doc.currentVersion?.files[0].urls.length > 0 ? doc.currentVersion.files[0].urls[0] : null;
    download(firstFile, 1, doc);
  };
  return (
    <button className={styles['download-button']} onClick={onDownloadClicked}>
      <div className="flex flex-row-reverse">
        <span className="pl-2">
          <FormattedMessage id="documentManualUploadUi.documentView.download" defaultMessage="DOWNLOAD" />
        </span>
        <DownloadIcon />
      </div>
    </button>
  );
};

const renderActiveFiles = (doc: any) => {
  const docFilesUrls = doc.currentVersion.files[0].urls;
  if (docFilesUrls.length > 0) {
    return (
      <div className="grid grid-cols-2 w-10/12">
        <span className={styles['file-labels']}>
          <FormattedMessage id="documentManualUploadUi.documentView.fileName" defaultMessage="FILE NAME" />
        </span>
        <span className={styles['file-labels']}>
          <FormattedMessage id="documentManualUploadUi.documentView.addedBy" defaultMessage="ADDED BY" />
        </span>
        {docFilesUrls.map((fileUrl: string, idx: number) => {
          const createdAtMoment = moment.utc(doc.currentVersion.createdDateTimeUtc).local();
          return (
            <>
              <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                <span>File {idx + 1}</span>
              </a>
              <span>
                <span className={styles['scope-label']}>{getDisplayTextForScope(doc.scope)}</span>
                {!doc.isLegacy &&
                  ` • ${DateFormatUtil.formatMomentAsDate(createdAtMoment)}
                  ${DateFormatUtil.formatMomentAsTime(createdAtMoment)}`}
              </span>
            </>
          );
        })}
      </div>
    );
  }
};

interface DocumentObj {
  currentVersion?: any;
  documentType: keyof typeof DocumentTypes;
  ownerIdentifiers?: any;
  previousVersions?: any;
  scope?: DocumentScope;
  shared: boolean;
  id?: any;
  validatedMimeType?: string | null;
}
interface DocumentsComponentProps {
  documents: DocumentObj[];
}

const DocumentViewComponent: React.FC<DocumentsComponentProps> = (props) => {
  const sortedDocuments = props.documents
    ? props.documents.sort((a: any, b: any) => {
        if (a.documentType === b.documentType) {
          return a.currentVersion.createdDateTimeUtc > b.currentVersion.createdDateTimeUtc ? 1 : -1;
        }
        return a.documentType > b.documentType ? 1 : -1;
      })
    : [];

  return (
    <div>
      {props.documents && props.documents.length > 0 && (
        <>
          <div className="inline-grid grid-cols-2 w-10/12">
            <span className={classNames(styles['file-labels'], 'p-1', 'pl-4')}>
              <FormattedMessage defaultMessage="Document type" id="documentManualUploadUi.documentView.documentType" />
            </span>
            <span className={classNames(styles['file-labels'], 'p-1')}>
              <FormattedMessage defaultMessage="Last modified" id="documentManualUploadUi.documentView.lastModified" />
            </span>
          </div>
          {/* HACK: Setting activeKey to include every row to temporarily expand all document rows. We will re-enable expanding rows once a user can upload more files */}
          <Collapse expandIconPosition="right" expandIcon={() => <></>} activeKey={[]}>
            {sortedDocuments.map((doc: any, idx) => (
              <StyledPanel
                header={renderDocumentRow(
                  doc.documentType,
                  doc.scope,
                  moment.utc(doc.currentVersion.createdDateTimeUtc).local(),
                  doc.currentVersion.version,
                  doc.isLegacy
                )}
                key={idx}
                extra={renderDownloadButton(doc)}
                style={{ backgroundColor: 'white' }}
              >
                <div>{renderActiveFiles(doc)}</div>
              </StyledPanel>
            ))}
          </Collapse>
        </>
      )}
    </div>
  );
};

export default DocumentViewComponent;
