import { produce } from 'immer';
import { Nullable } from 'typesUtils';
import { QUICKVIEW_TOTAL_COUNT } from '../constants';
import { HealthRanges, HealthScoreConfig as HealthScoreConfigType, TNumberFormat } from '../types';
import { parseCountToPercent, parsePercentToCount } from '../utils';
import { HealthRangesTarget } from './types';
import { getNextRange, getPrevRange, isDescOrder, makeIsEndRange, makeIsMiddleRange, toggleRange } from './utils';

interface NumberValuesPayload {
  target: HealthRangesTarget;
  values: { lowLimit: number; highLimit: number };
}

interface HealthScoreState extends HealthScoreConfigType {
  endRanges: Array<HealthRangesTarget>;
  middleRanges: Array<HealthRangesTarget>;
  rangeKeys: Array<HealthRangesTarget>;
  snapshots: HealthRanges;
}

type ActionType =
  | { type: 'SET_HEALTH_SCORE_ENABLED'; payload: boolean }
  | { type: 'SET_HEALTH_SCORE_DISABLED'; payload: any }
  | { type: 'SWAP_END_RANGES' }
  | { type: 'TOGGLE_RANGE'; payload: { target: HealthRangesTarget; value: boolean } }
  | { type: 'TOGGLE_NUMBER_FORMAT'; payload: TNumberFormat }
  | {
      type: 'SET_RANGE_VALUES';
      payload: { target: HealthRangesTarget; values: { lowLimit: Nullable<number>; highLimit: Nullable<number> } };
    };

export const healthScoreReducer = produce<HealthScoreState, [ActionType]>((draft, action) => {
  switch (action.type) {
    case 'SET_HEALTH_SCORE_ENABLED': {
      draft.isEnabled = action.payload;
      break;
    }
    case 'SWAP_END_RANGES': {
      const nextRanges = Object.assign({}, draft.ranges);
      const keys = draft.rangeKeys;
      const disabledRanges = keys.filter((target) => !draft.ranges[target].isEnabled);

      // Get the version on ranges with all the ranges enabled in nextRanges
      keys.forEach((target) => {
        if (!draft.ranges[target].isEnabled) {
          toggleRange(draft.rangeKeys, nextRanges, target, true);
        }
      });

      // Reverse the next ranges
      const healthRangesCopy = Object.assign({}, draft.ranges);
      keys.forEach((target, index) => {
        nextRanges[target] = healthRangesCopy[keys[keys.length - 1 - index]];
      });

      // Apply the disabled ranges back
      disabledRanges.forEach((target) => {
        toggleRange(draft.rangeKeys, nextRanges, target, false);
      });

      draft.ranges = nextRanges;
      break;
    }
    case 'TOGGLE_RANGE': {
      const { target, value } = action.payload;
      toggleRange(draft.rangeKeys, draft.ranges, target, value);
      break;
    }
    case 'SET_RANGE_VALUES': {
      const { values, target } = action.payload as NumberValuesPayload;
      const prevRange = getPrevRange(draft.rangeKeys, draft.ranges, target);
      const nextRange = getNextRange(draft.rangeKeys, draft.ranges, target);
      const isDesc = isDescOrder(draft.rangeKeys, draft.ranges);
      const isMiddleRange = makeIsMiddleRange(draft.ranges);
      const isEndRange = makeIsEndRange(draft.ranges);

      if (draft.ranges[target].highLimit !== values.highLimit) {
        if (isDesc) {
          if (nextRange && isEndRange(target)) {
            if (isMiddleRange(nextRange)) {
              draft.ranges[nextRange].highLimit = values.highLimit - 0.1;
            } else {
              draft.ranges[nextRange].lowLimit = values.highLimit - 0.1;
            }
          }
          if (prevRange && isMiddleRange(target)) {
            if (isMiddleRange(prevRange)) {
              draft.ranges[prevRange].lowLimit = values.highLimit + 0.1;
            } else {
              draft.ranges[prevRange].highLimit = values.highLimit + 0.1;
            }
          }
        } else {
          if (nextRange && isMiddleRange(nextRange) && isEndRange(target)) {
            draft.ranges[nextRange].lowLimit = values.lowLimit + 0.1;
          }
          if (nextRange && isMiddleRange(nextRange) && isMiddleRange(target)) {
            draft.ranges[nextRange].lowLimit = values.highLimit + 0.1;
          }
          if (nextRange && isMiddleRange(target) && isEndRange(nextRange)) {
            draft.ranges[nextRange].highLimit = values.highLimit + 0.1;
          }
          if (prevRange && isMiddleRange(prevRange) && isEndRange(target)) {
            draft.ranges[prevRange].highLimit = values.highLimit - 0.1;
          }
          if (prevRange && isEndRange(prevRange) && isEndRange(target)) {
            draft.ranges[prevRange].lowLimit = values.highLimit - 0.1;
          }
        }
      }

      if (draft.ranges[target].lowLimit !== values.lowLimit) {
        if (isDesc) {
          if (nextRange && isMiddleRange(target)) {
            if (isMiddleRange(nextRange)) {
              draft.ranges[nextRange].highLimit = values.lowLimit - 0.1;
            } else {
              draft.ranges[nextRange].lowLimit = values.lowLimit - 0.1;
            }
          }
          if (prevRange && isEndRange(target)) {
            if (isMiddleRange(prevRange)) {
              draft.ranges[prevRange].lowLimit = values.lowLimit + 0.1;
            } else {
              draft.ranges[prevRange].highLimit = values.lowLimit + 0.1;
            }
          }
        } else {
          if (prevRange && isMiddleRange(prevRange) && isMiddleRange(target)) {
            draft.ranges[prevRange].highLimit = values.lowLimit - 0.1;
          }
          if (prevRange && isEndRange(prevRange) && isMiddleRange(target)) {
            draft.ranges[prevRange].lowLimit = values.lowLimit - 0.1;
          }
          if (nextRange && isMiddleRange(nextRange) && isEndRange(target)) {
            draft.ranges[nextRange].lowLimit = values.lowLimit + 0.1;
          }
          if (nextRange && isEndRange(nextRange) && prevRange && isEndRange(prevRange)) {
            draft.ranges[prevRange].lowLimit = values.lowLimit - 0.1;
          }
        }
      }

      draft.ranges[target].lowLimit = values.lowLimit;
      draft.ranges[target].highLimit = values.highLimit;

      break;
    }
    case 'TOGGLE_NUMBER_FORMAT': {
      const nextNumberFormat = action.payload;
      draft.numberFormat = nextNumberFormat;
      draft.rangeKeys.forEach((target: HealthRangesTarget) => {
        const storedTotal = sessionStorage.getItem(QUICKVIEW_TOTAL_COUNT);
        const total = storedTotal && Number(storedTotal);
        const lowLimit = draft.ranges[target].lowLimit;
        const highLimit = draft.ranges[target].highLimit;
        const prevRange = getPrevRange(draft.rangeKeys, draft.ranges, target);
        const nextRange = getNextRange(draft.rangeKeys, draft.ranges, target);
        const isPercentage = nextNumberFormat === 'PERCENTAGE';
        const isEndRange = makeIsEndRange(draft.ranges);
        const isMiddleRange = makeIsMiddleRange(draft.ranges);
        const isDesc = isDescOrder(draft.rangeKeys, draft.ranges);

        if (draft.ranges[target].isEnabled) {
          if (isEndRange(target)) {
            if (lowLimit && total) {
              draft.ranges[target].lowLimit = isPercentage
                ? parseCountToPercent(lowLimit, total)
                : parsePercentToCount(lowLimit, total);
            }

            if (isDesc) {
              if (prevRange) {
                draft.ranges[prevRange][isMiddleRange(prevRange) ? 'lowLimit' : 'highLimit'] =
                  (draft.ranges[target].lowLimit as number) + (isPercentage ? 0.1 : 1);
              }
            } else {
              if (prevRange && isEndRange(prevRange)) {
                draft.ranges[target].highLimit =
                  (draft.ranges[prevRange].lowLimit as number) + (isPercentage ? 0.1 : 1);
              }
            }
          } else {
            if (highLimit && total) {
              draft.ranges[target].highLimit = isPercentage
                ? parseCountToPercent(highLimit, total)
                : parsePercentToCount(highLimit, total);

              if (isDesc) {
                if (prevRange) {
                  if (isMiddleRange(prevRange)) {
                    draft.ranges[prevRange].lowLimit =
                      (draft.ranges[target].highLimit as number) + (isPercentage ? 0.1 : 1);
                  } else {
                    draft.ranges[prevRange].highLimit =
                      (draft.ranges[target].highLimit as number) + (isPercentage ? 0.1 : 1);
                  }
                }
              } else {
                if (prevRange) {
                  const adjacentValue = draft.ranges[prevRange][isMiddleRange(prevRange) ? 'highLimit' : 'lowLimit'];
                  draft.ranges[target].lowLimit = (adjacentValue as number) - (isPercentage ? 0.1 : 1);

                  if (nextRange && isEndRange(nextRange)) {
                    draft.ranges[nextRange].highLimit =
                      (draft.ranges[target].highLimit as number) + (isPercentage ? 0.1 : 1);
                  }
                }
              }
            }
          }
        } else {
          if (lowLimit && total) {
            draft.ranges[target].lowLimit = isPercentage
              ? parseCountToPercent(lowLimit, total)
              : parsePercentToCount(lowLimit, total);
          }

          if (highLimit && total) {
            draft.ranges[target].highLimit = isPercentage
              ? parseCountToPercent(highLimit, total)
              : parsePercentToCount(highLimit, total);
          }
        }
      });

      break;
    }
    case 'SET_HEALTH_SCORE_DISABLED': {
      draft.numberFormat = action.payload.numberFormat;
      break;
    }
    default: {
      break;
    }
  }
});

export const initState = (initialValue: HealthScoreConfigType) => {
  const initialValueEntries = Object.entries(initialValue.ranges);
  const rangesMetadata = initialValueEntries.reduce<any>(
    (result, [key, value]) => {
      const isEndRange = !value.lowLimit || !value.highLimit;

      if (isEndRange) {
        result.endRanges.push(key);
      }

      result.rangeKeys.push(key);

      return result;
    },
    { endRanges: [], rangeKeys: [] }
  );

  return Object.assign({}, initialValue, rangesMetadata, { snapshots: initialValue.ranges });
};
