import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import types from 'components/shipmentCounts/ducks/types';
import DynamicDateFilter from '../types/DynamicDateFilter';
import ModeFilter from '../types/ModeFilter';
import StatusFilter from '../types/StatusFilter';
import HappeningTodayFilter from '../types/HappeningTodayFilter';
import ArrivalForecastFilter from '../types/ArrivalForecastFilter';
import DeliveryForecastFilter from '../types/DeliveryForecastFilter';
import PickupFilter from '../types/PickupFilter';
import DeliveryFilter from '../types/DeliveryFilter';
import LocationFilter from '../types/LocationFilter';
import CarrierFilter from '../types/CarrierFilter';
import CompanyFilter from '../types/CompanyFilter';
import CustomerFilter from '../types/CustomerFilter';
import CreatedByFilter from '../types/CreatedByFilter';
import TenantAttributeFilter from '../types/TenantAttributeFilter';
import TemperatureTrackingFilter from '../types/TemperatureTrackingFilter';
import SharedToFilter from '../types/SharedToFilter';
import SharedByFilter from '../types/SharedByFilter';
import { tl as tlStatusFilterList } from './statusFilters';

const TruckloadModeFilters = (props) => (
  <>
    <Row className="mb-4 d-lg-none">
      <ModeFilter {...props} />
    </Row>
    <Row className="mb-4">
      <StatusFilter {...props} menuItems={tlStatusFilterList} />
    </Row>
    <Row className="mb-4">
      <HappeningTodayFilter
        {...props}
        toolTipContent={
          <FormattedMessage
            id="shipmentsInTransit.tooltip.tl"
            defaultMessage="The shipment about to be picked up, is either at a planned stop or moving towards a planned destination along the route"
          />
        }
      />
    </Row>
    <Row className="mb-4">
      <ArrivalForecastFilter {...props} />
    </Row>
    <Row className="mb-4">
      <DeliveryForecastFilter {...props} />
    </Row>
    <Row className="mb-4">
      {props.quickView ? (
        <Col>
          <DynamicDateFilter dateType="pickupDate" type={types.QUICK_VIEW_CHANGE_PICKUP_DATE} />
        </Col>
      ) : (
        <PickupFilter {...props} />
      )}
    </Row>
    <Row className="mb-4">
      {props.quickView ? (
        <Col>
          <DynamicDateFilter dateType="deliveryDate" type={types.QUICK_VIEW_CHANGE_DELIVERY_DATE} />
        </Col>
      ) : (
        <DeliveryFilter {...props} />
      )}
    </Row>
    <Row className="mb-4">
      <LocationFilter {...props} />
    </Row>
    <Row className="mb-4">
      <CarrierFilter {...props} />
    </Row>
    <Row className="mb-4">
      <CompanyFilter {...props} />
    </Row>
    {props.authorizations.hasTenantCarrierRole(props.principal) ? (
      <Row className="mb-4">
        <CustomerFilter {...props} />
      </Row>
    ) : null}
    <Row className="mb-4">
      <CreatedByFilter {...props} />
    </Row>
    {props.authorizations.hasTenantCarrierRole(props.principal) ? null : (
      <Row className="mb-4">
        <TenantAttributeFilter {...props} />
      </Row>
    )}
    <Row className="mb-4">
      <TemperatureTrackingFilter {...props} />
    </Row>
    <Row className="mb-4">
      <SharedToFilter {...props} />
    </Row>
    <Row className="mb-4">
      <SharedByFilter {...props} />
    </Row>
  </>
);

export default TruckloadModeFilters;
