import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, FieldArray, FormSection } from 'redux-form';
import get from 'lodash/get';
import { Row, Col } from 'react-bootstrap';
import last from 'lodash/last';
import { countryPhoneData } from 'phone';
import { Button } from 'ui-components';
import { phoneSMSNotificationValidation, emailValidation, requiredValidation } from 'common/FormValidations';

import { intl, LOCALES } from 'common/AppConstants';
import { parcelSMSNotificationsEnabled } from 'common/authorizations';
import * as constants from '../../ducks/constants';
import types from '../../ducks/types';
import {
  RenderSingleSelectDropdown,
  RenderTextField,
  RenderCriteriaChips,
  renderModeSelect,
  AddSectionLink,
} from '../AddEditFormInputs';
import localeDropdownOptions from '../../../common/localeDropdownOptions/localeDropdownOptions';

const getContactMethodConstants = (props) => {
  if (props.selectedMode === constants.ModeTypeConstants.TL) {
    return constants.ShipmentNotificationRuleConstants.tlContactMethodValues;
  } else if (props.selectedMode === constants.ModeTypeConstants.LTL) {
    return constants.ShipmentNotificationRuleConstants.ltlContactMethodValues;
  } else if (props.selectedMode === constants.ModeTypeConstants.PARCEL) {
    return parcelSMSNotificationsEnabled()
      ? constants.ShipmentNotificationRuleConstants.parcelContactMethodValues
      : constants.ShipmentNotificationRuleConstants.parcelContactMethodValues.filter(
          (contactMethod) => contactMethod.type !== constants.SubscriptionTypes.SMS
        );
  } else if (props.selectedMode === constants.ModeTypeConstants.AIR) {
    return constants.ShipmentNotificationRuleConstants.airContactMethodValues;
  } else if (props.selectedMode === constants.ModeTypeConstants.OCEAN) {
    return constants.ShipmentNotificationRuleConstants.oceanContactMethodValues;
  } else {
    return [new Map([])];
  }
};

const localeDropdownItems = [
  {
    value: LOCALES.ENGLISH,
    displayName: intl.formatMessage({ id: 'common.languages.english', defaultMessage: 'English' }),
  },
  {
    value: LOCALES.FRENCH,
    displayName: intl.formatMessage({ id: 'common.languages.french', defaultMessage: 'French' }),
  },
  {
    value: LOCALES.GERMAN,
    displayName: intl.formatMessage({ id: 'common.languages.german', defaultMessage: 'German' }),
  },
  {
    value: LOCALES.ITALIAN,
    displayName: intl.formatMessage({ id: 'common.languages.italian', defaultMessage: 'Italian' }),
  },
  {
    value: LOCALES.POLISH,
    displayName: intl.formatMessage({ id: 'common.languages.polish', defaultMessage: 'Polish' }),
  },
  {
    value: LOCALES.LITHUANIAN,
    displayName: intl.formatMessage({ id: 'common.languages.lithuanian', defaultMessage: 'Lithuanian' }),
  },
  {
    value: LOCALES.ROMANIAN,
    displayName: intl.formatMessage({ id: 'common.languages.romanian', defaultMessage: 'Romanian' }),
  },
  {
    value: LOCALES.SPAINSPANISH,
    displayName: intl.formatMessage({ id: 'common.languages.spanish_spain', defaultMessage: 'Spanish (Spain)' }),
  },
  {
    value: LOCALES.TURKISH,
    displayName: intl.formatMessage({ id: 'common.languages.turkish', defaultMessage: 'Turkish' }),
  },
];

const RecipientFormSection = (props) => {
  return (
    <FieldArray
      name="notificationRecipients"
      component={renderSubscriberCriteria}
      formValues={props.formValues}
      initialValues={props.initialValues}
      subscriberSuggestions={props.subscriberSuggestions}
      dispatch={props.dispatch}
      queryAutocompleteOptions={props.queryAutocompleteOptions}
      subscriptionOptions={props.subscriptionOptions}
      selectedMode={props.selectedMode}
    />
  );
};

const renderSubscriberCriteria = injectIntl((props) => {
  const contactMethods = getContactMethodConstants(props);
  const submitFailed = get(props, 'meta.submitFailed');
  const error = get(props, 'meta.error');
  const lastRecipient = last(get(props, 'formValues.notificationRecipients', []));

  return (
    <div className="row">
      <div className="col-24">
        {submitFailed && error && (
          <div className="error text-danger">
            {props.intl.formatMessage({ id: error.id, defaultMessage: error.defaultMessage })}
          </div>
        )}
        {props.fields.map((criteria, index) => {
          const currentField = get(
            props,
            `formValues.notificationRecipients[${index}]`,
            props.initialValues.notificationRecipients[index]
          );
          const phonePrefix = get(
            countryPhoneData.find((d) => d.alpha3 === currentField.countryCode),
            'country_code',
            ''
          );

          return (
            <div key={criteria}>
              <FormSection name={criteria} className="row mb-4">
                <div data-locator="contact-method" className="col-20 mb-4">
                  <Field
                    name="type"
                    placeholder={'Contact Method'}
                    disabled={props.subscriptionOptions.types.length <= 1}
                    component={renderModeSelect}
                    value={currentField.type}
                    validate={requiredValidation}
                    modeOptions={contactMethods
                      .filter((contactMethod) => props.subscriptionOptions.types.includes(contactMethod.type))
                      .map(({ type, display }) => ({
                        value: type,
                        label: display,
                      }))}
                  />
                </div>
                {props.fields.length > 1 && (
                  <div className="col-4 mb-2">
                    <Button type="secondary" onClick={() => props.fields.remove(index)}>
                      <i className="material-icons delete-icon">close</i>
                    </Button>
                  </div>
                )}
                {currentField.type === constants.SubscriptionTypes.Email && (
                  <div className="col-20">
                    <label htmlFor="event-email-input" className="sr-only">
                      {<FormattedMessage id="notifications.types.email.label" defaultMessage="Enter email address" />}
                    </label>
                    <Field
                      name="input"
                      component={RenderTextField}
                      placeholder={intl.formatMessage({
                        id: 'notifications.types.email.placeholder',
                        defaultMessage: 'Enter email address',
                      })}
                      data-locator="event-email-input"
                      id="event-email-input"
                      queryAutocompleteOptions={props.queryAutocompleteOptions}
                      currentParameters={currentField}
                      dataSource={props.subscriberSuggestions}
                      queryType={types.UPDATE_SUBSCRIBER_SUGGESTIONS}
                      validate={[emailValidation]}
                    />
                  </div>
                )}

                {currentField.type === constants.SubscriptionTypes.Push && (
                  <>
                    <label htmlFor="event-push-input" className="sr-only">
                      {
                        <FormattedMessage
                          id="tenantNotifications.constants.subscriptionPushNotificationLabel"
                          defaultMessage="Select push notification"
                        />
                      }
                    </label>
                    <Field
                      name="input"
                      id="event-push-input"
                      className="col-20 push-notification-select"
                      component={RenderSingleSelectDropdown}
                      validate={requiredValidation}
                      onChange={(event, newValue, previousValue, name) => {
                        if (newValue) {
                          // Copied from renderAutoComplete input:
                          // this should usually require a redux-form dispatch change but it seems to work this way..
                          currentField.values = [...currentField.values, newValue];
                        }
                      }}
                      dropdownItems={props.subscriptionOptions.values.push.map((pushConfig) => ({
                        value: pushConfig.configName,
                        displayName: pushConfig.configName,
                      }))}
                      placeholder={intl.formatMessage({
                        id: 'tenantNotifications.constants.subscriptionPushNotificationPlaceholder',
                        defaultMessage: 'Select push notification',
                      })}
                    />
                  </>
                )}
                {currentField.type === constants.SubscriptionTypes.SMS && (
                  <Col xs={24}>
                    <Row className="mb-2">
                      <label htmlFor="recipient-country-code-input" className="sr-only">
                        {
                          <FormattedMessage
                            id="notifications.types.sms.countryCode.label"
                            defaultMessage="Select your country code"
                          />
                        }
                      </label>
                      <Field
                        name="countryCode"
                        className="col-6 pr-0"
                        component={RenderSingleSelectDropdown}
                        dropdownItems={localeDropdownOptions()}
                        dropdownMatchSelectWidth={false}
                        dropdownClassName="country-code-dropdown"
                        placeholder="Country Code"
                        defaultValue="USA"
                        defaultActiveFirstOption={false}
                        optionLabelProp="icon"
                        id="recipient-country-code-input"
                        data-locator="recipient-country-code-input"
                        validate={requiredValidation}
                      />
                      <div className="col-18">
                        <label htmlFor="recipient-phone-input" className="sr-only">
                          {
                            <FormattedMessage
                              id="notifications.types.sms.label"
                              defaultMessage="Enter the recipient's phone number"
                            />
                          }
                        </label>
                        <Field
                          name="phoneNumber"
                          type="tel"
                          component={RenderTextField}
                          prefix={`+${phonePrefix}`}
                          placeholder={intl.formatMessage({
                            id: 'notifications.types.sms.placeholder',
                            defaultMessage: "Enter the recipient's phone number",
                          })}
                          data-locator="recipient-phone-input"
                          id="recipient-phone-input"
                          currentParameters={currentField}
                          validate={[phoneSMSNotificationValidation]}
                        />
                      </div>
                    </Row>
                    <Row>
                      <Field
                        name="locale"
                        className="col-24 language-select"
                        placeholder="Choose the language for your SMS"
                        component={RenderSingleSelectDropdown}
                        dropdownItems={localeDropdownItems}
                        defaultValue={LOCALES.ENGLISH}
                        validate={requiredValidation}
                      />
                    </Row>
                  </Col>
                )}

                {get(currentField, 'values', []).length > 0 && (
                  <div className="notification-rule__chip-container col-20">
                    {/* TODO: renderCriteriaChips only takes a single string value and cannot render a label as it expects the values array to just contain the strings to display */}
                    <RenderCriteriaChips
                      currentField={currentField.values}
                      dispatch={props.dispatch}
                      formPath={`notificationRecipients[${index}].values`}
                    />
                  </div>
                )}
              </FormSection>
            </div>
          );
        })}
      </div>
      {props.subscriptionOptions.types.length > 1 &&
        (get(lastRecipient, 'input', '') !== '' || get(lastRecipient, 'values', []).length > 0) && (
          <div className="col-20">
            {/* FIXME: Invoke props to return a new default object like we do with the rule types */}
            <AddSectionLink
              onClick={() => {
                props.fields.push({ type: 'EMAIL', values: [], input: '' });
              }}
            >
              <FormattedMessage
                id="notifications.recipientFormSection.addContactMethod"
                defaultMessage="Add contact method"
              />
            </AddSectionLink>
          </div>
        )}
    </div>
  );
});

export default RecipientFormSection;
