import * as React from 'react';
import Row from 'react-bootstrap/Row';

import ModeFilter from '../types/ModeFilter';
import StatusFilter from '../types/StatusFilter';
import TenantAttributeFilter from '../types/TenantAttributeFilter';
import { air as airStatusFilterList } from './statusFilters';

const AirModeFilters: React.FC<any> = (props) => {
  return (
    <React.Fragment>
      <Row className="mb-4 d-lg-none">
        <ModeFilter {...props} />
      </Row>
      <Row className="mb-4">
        <StatusFilter {...props} menuItems={airStatusFilterList} />
      </Row>
      <Row className="mb-4">
        <TenantAttributeFilter {...props} />
      </Row>
    </React.Fragment>
  );
};

export default AirModeFilters;
