import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import qs from 'qs';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useInventoryItems } from 'components/inventory-v2/hooks/useInventoryItems';
import InventoryItemsTable from 'components/inventory-v2/InventoryItemsTable';
import { SortField } from 'models';
import ListPageLayout, { SortItem } from 'components/common/ListPageLayout/ListPageLayout';
import { SortDirection } from 'models/SortDirection';
import { orderInventoryListFiltersUrlEnabled } from 'common/authorizations';
import { trackEvent } from 'common/eventTracker';
import InventoryCriteriaMessages from './filters/InventoryCriteriaMessages.json';
import EncapsulatedInventoryItemsFilters from './EncapsulatedInventoryItemsFilters';

export const inventoryIdentifiersTypeList = [
  InventoryCriteriaMessages.department,
  InventoryCriteriaMessages.subDepartment,
  InventoryCriteriaMessages.category,
  InventoryCriteriaMessages.group,
  InventoryCriteriaMessages.family,
  InventoryCriteriaMessages.vendor,
  InventoryCriteriaMessages.manufacturer,
  InventoryCriteriaMessages.promotion,
  InventoryCriteriaMessages.sector,
  InventoryCriteriaMessages.division,
  InventoryCriteriaMessages.class,
  InventoryCriteriaMessages.subClass,
  InventoryCriteriaMessages.code,
];

const INVENTORY_ENTITY_TYPE = 'INVENTORY';

const InventoryItemsList = (props: any) => {
  const [currentPage, setCurrentPage] = useState(1);

  const shouldUseUrlFilter = orderInventoryListFiltersUrlEnabled();

  const parsedSearch = qs.parse(props.history.location.search.replace('?', ''));
  const urlFilter = shouldUseUrlFilter ? omit(parsedSearch, 'searchText') : {};
  const searchText = get(parsedSearch, 'searchText', '') as string;
  const [filter, setFilter] = useState(urlFilter || {});

  // sorting
  const [sortAttr, setSortAttr] = useState(0);
  const [sortArr, setSortArr] = useState<SortField<string>[]>([]);

  const { isLoading, inventoryItems } = useInventoryItems({
    hasPermission: true,
    defaultPageSize: 30,
    searchText: searchText,
    page: currentPage,
    sort: sortArr,
    filters: JSON.stringify(filter),
  });

  const intl = useIntl();

  const sortItems: SortItem[] = [
    {
      displayName: '',
      fieldName: '',
      sortDirection: SortDirection.DESC,
    },
    {
      displayName: intl.formatMessage({
        id: 'inventoryList.sort.stockKeepingUnit.asc',
        defaultMessage: 'Stock Keeping Unit',
      }),
      fieldName: 'stockKeepingUnit',
      sortDirection: SortDirection.ASC,
    },
    {
      displayName: intl.formatMessage({
        id: 'inventoryList.sort.universalProductCode.asc',
        defaultMessage: 'Universal Product Code',
      }),
      fieldName: 'universalProductCode',
      sortDirection: SortDirection.ASC,
    },
  ];

  useEffect(() => {
    const searchObject = searchText
      ? {
          ...filter,
          // if you change this variable, also update blacklistTransform() in configureStore.js
          searchText: searchText,
        }
      : filter;
    // only update URL if query params differ from current ones. qs.stringify handles edge cases like a key with an empty
    // array
    const newSearchDiffersFromCurrentSearch = !isEqual(qs.stringify(searchObject), qs.stringify(parsedSearch));
    // need to use isEqual() or similar here since X !== {} will return true for all x, including {} ({} !== {})
    if (!isEqual(searchObject, {}) && shouldUseUrlFilter && newSearchDiffersFromCurrentSearch) {
      props.history.push({
        search: qs.stringify(searchObject),
        // if we don't copy this, we will lose state and introduce bugs where searching causes search input to be lost.
        // see VORD-1152 for an example of such behavior. pushing this data into Redux and omitting use of location
        // state is an option, but there are generically named
        state: props.history.location.state,
      });
    }
  }, [filter, props.history, shouldUseUrlFilter, searchText, parsedSearch]);

  const breadcrumbComponent = (
    <FormattedMessage id="inventory.inventoryItemsList.breadCrumb.inventory" defaultMessage="Inventory" />
  );

  const countTitleComponent = (
    <FormattedMessage
      id="inventory.inventoryItemsList.header"
      defaultMessage="{count} Inventory Items"
      values={{ count: get(inventoryItems, 'paginationInfo.total') }}
    />
  );

  const tableComponent = (
    <InventoryItemsTable
      dataResults={inventoryItems?.results}
      isLoading={isLoading}
      onPageChangeFn={(page) => {
        trackEvent('INVENTORY_LIST_PAGINATE', {
          page: page.toString(),
        });
        setCurrentPage(page);
      }}
      totalResults={inventoryItems?.paginationInfo?.total}
    />
  );

  const filterComponent = <EncapsulatedInventoryItemsFilters filter={filter} setFilter={setFilter} />;

  return (
    <ListPageLayout
      entityType={INVENTORY_ENTITY_TYPE}
      breadcrumbComponent={breadcrumbComponent}
      filterComponent={filterComponent}
      countTitleComponent={countTitleComponent}
      tableComponent={tableComponent}
      sortItems={sortItems}
      sortAttr={sortAttr}
      setSortAttr={setSortAttr}
      setSortArr={setSortArr}
    />
  );
};

export default InventoryItemsList;
