import types, { SUGGESTION_TYPE_TO_ACTION_MAP } from './types';

export function buildApplyFilterFieldAction(filter = {}, authorities, source) {
  return { type: types.APPLY_FILTER_FIELDS, filter, authorities, source };
}

export function updateSuggestions(filterText = '', field = '', params = '') {
  return { type: types.UPDATE_SUGGESTIONS, filterText, field, params };
}

export function clearFilters(authorities, modeFilter, isMultiValue, searchTerm) {
  return { type: types.CLEAR_FILTER, authorities, modeFilter, isMultiValue, searchTerm };
}

export function clearFiltersNoSearch() {
  return { type: types.CLEAR_FILTER_NO_SEARCH };
}

export function setSuggestions(filterType, suggestions = []) {
  return { type: SUGGESTION_TYPE_TO_ACTION_MAP[filterType], suggestions };
}

export function buildLocationSuggestions(suggestions = []) {
  return { type: types.SET_LOCATION_SUGGESTIONS, suggestions };
}

export function buildCarrierSuggestions(suggestions = []) {
  return { type: types.SET_CARRIER_SUGGESTIONS, suggestions };
}

export function buildPortOfLoadingSuggestions(suggestions = []) {
  return { type: types.SET_PORT_OF_LOADING_SUGGESTIONS, suggestions };
}

export function buildTransshipmentPortSuggestions(suggestions = []) {
  return { type: types.SET_TRANSSHIPMENT_PORT_SUGGESTIONS, suggestions };
}

export function buildPortOfDischargeSuggestions(suggestions = []) {
  return { type: types.SET_PORT_OF_DISCHARGE_SUGGESTIONS, suggestions };
}

export function buildCustomerSuggestions(suggestions = []) {
  return { type: types.SET_CUSTOMER_SUGGESTIONS, suggestions };
}

export function buildCompanySuggestions(suggestions = []) {
  return { type: types.SET_COMPANY_SUGGESTIONS, suggestions };
}

export function buildCreatedBySuggestions(suggestions = []) {
  return { type: types.SET_CREATEDBY_SUGGESTIONS, suggestions };
}

export function buildAttributeSuggestions(suggestions = []) {
  return { type: types.SET_ATTRIBUTE_SUGGESTIONS, suggestions };
}

export function handleCheckboxGroupUpdate(checkboxItem = {}, checkboxGroup = '') {
  return {
    type: types.SET_CHECKBOX_ITEMS_GROUP,
    checkboxItem: checkboxItem,
    checkboxGroup: checkboxGroup,
  };
}

export function handleCheckboxSingleUpdate(checkboxItem = {}, checkboxGroup = '') {
  return {
    type: types.SET_CHECKBOX_ITEMS_SINGLE,
    checkboxItem: checkboxItem,
    checkboxGroup: checkboxGroup,
  };
}

export function handleAutocompleteUpdate(filterItem, filterItemGroup) {
  return {
    type: types.SET_AUTOCOMPLETE_ITEMS,
    filterItem: filterItem,
    filterItemGroup: filterItemGroup,
  };
}

export function setSavedFilter(mapFilter) {
  return {
    type: types.SET_SAVED_FILTER,
    mapFilter: mapFilter,
  };
}

export function setModeFilter(modeFilter, filter) {
  return {
    type: types.SET_MODE_FILTER,
    modeFilter: modeFilter,
    filter: filter,
  };
}

export function handleFilterInputs(inputValue, filterGroup, params) {
  return {
    type: types.SET_FILTER_INPUT,
    inputValue: inputValue,
    filterGroup: filterGroup,
    params: params,
  };
}

export function handleStatusDropdownItemSelect(selectedValues) {
  return {
    type: types.SET_STATUS_ITEMS,
    selectedValues,
  };
}

export function handleStatusDropdownItemRemove(selectedValue) {
  return {
    type: types.REMOVE_STATUS_ITEM,
    selectedValue,
  };
}

export function toggleShowSelections(group) {
  return {
    type: types.TOGGLE_SHOW_SELECTIONS,
    group: group,
  };
}

export function handleDatePickerChange(date, dateType) {
  return {
    type: types.SET_DATE,
    date: date,
    dateType: dateType,
  };
}

export function toggleModeFilterFn(authorities, option, searchTerm, filters, sortFields, page, isMultiValue) {
  return {
    type: types.TOGGLE_MODE_FILTER_OPTIONS,
    authorities,
    option,
    searchTerm,
    filters,
    sortFields,
    page,
    isMultiValue,
  };
}

export function setFilterCarrierMode(authorities) {
  return {
    type: types.SET_FILTER_CARRIER_MODE,
    authorities,
    value: 'CARRIER',
  };
}

export function toggleModeFilterSelectOneFn(option) {
  return {
    type: types.TOGGLE_MODE_FILTER_OPTIONS_SELECT_ONE,
    option: option,
  };
}

export function getTenantAttributes() {
  return {
    type: types.GET_TENANT_ATTRIBUTES,
  };
}

export function toggleLoadFilter(obj) {
  return {
    type: types.TOGGLE_LOAD_FILTERS,
    ...obj,
  };
}
