import { useContext } from 'react';
import * as React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { SelectValue } from 'antd/lib/select';
import get from 'lodash/get';
import head from 'lodash/head';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';
import { rgba } from 'polished';

import { APILoader, Provider, useMap, useMapContext } from '@uiw/react-baidu-map';
import { ThemeContext } from 'contexts/ThemeContext';
import { HereMapsConfig } from 'models';
import AutocompleteInput from 'components/common/forms/AutocompleteInput';
import axios from 'util/paxios';
import { primaryBlue } from 'styles/colors';
import { Label } from './FormElements';

const StyledAutoComplete = styled(AutocompleteInput)`
  width: 100%;
  font-size: 1.2rem;
  .ant-select-selector:hover {
    border: 1px solid ${(props) => props.theme.primaryColor} !important;
  }
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-focused .ant-select-selector {
    border: 1px solid ${(props) => props.theme.primaryColor} !important;
    box-shadow: 0 0 0 2px ${(props) => rgba(props.theme.primaryColor, 0.2)} !important;
  }
`;

interface AutocompleteAddressProps extends AutoCompleteProps {
  address?: string;
  updateAddress: (value: SelectValue) => void;
  updatelatLngInputValue: (latLng: string | undefined) => void;
  isChinaAsCountry: boolean;
  label?: string;
  customClassNames?: string;
}
const AutocompleteAddress: React.FC<AutocompleteAddressProps> = (props) => {
  const {
    address,
    label,
    value,
    onChange,
    updateAddress,
    updatelatLngInputValue,
    defaultValue,
    isChinaAsCountry,
    ...restProps
  } = props;
  const baiduMapsChinaApiKey = get(window, 'portalGlobal.baiduMapsChinaApiKey') as unknown as string;
  const [query, setQuery] = React.useState('');
  const [dataSet, updateDataSet] = React.useState<string[]>([]);
  const [dataSetObjects, updateDataSetObjects] = React.useState<any[]>([]);
  const [mapConfig, setMapConfig] = React.useState({ hereMapsId: '', hereMapsCode: '', hereMapsApiKey: '' });
  const theme = useContext(ThemeContext);

  React.useEffect(() => {
    async function getConfig() {
      try {
        const config: HereMapsConfig = {
          hereMapsApiKey: `${get(window, 'portalGlobal.heremapsApiKey')}`,
          hereMapsCode: `${get(window, 'portalGlobal.heremapsAppCode')}`,
          hereMapsId: `${get(window, 'portalGlobal.heremapsAppID')}`,
        };
        setMapConfig(config);
      } catch (e) {
        console.error(e);
      }
    }
    getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(mapConfig)]);

  React.useEffect(() => {
    async function runSearch(q: string) {
      try {
        // TODO: Get the types of the response for the HERE maps docs
        const req: AxiosResponse<{ items: any }> = await axios({
          url: `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${q}&apikey=${mapConfig.hereMapsApiKey}`,
          method: 'get',
        });
        const labels = req.data?.items?.map((val: any) => val.title) ?? [];
        updateDataSet(labels);
        updateDataSetObjects(req.data.items);
      } catch (err) {
        console.log('err', err);
      }
    }
    if (query && get(mapConfig, 'hereMapsId') && !isChinaAsCountry) {
      runSearch(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, defaultValue, JSON.stringify(mapConfig), isChinaAsCountry]);

  const handleSearch = (value: string) => {
    setQuery(value);
  };

  const handleSelect = (value: SelectValue) => {
    const addressObject: any[] = dataSetObjects.filter((obj: any) => obj.title === value);
    updateAddress(head(addressObject));
    setQuery(`${value}`);
  };

  return (
    <>
      {label && <Label>{label}</Label>}
      {isChinaAsCountry && (
        <APILoader akay={baiduMapsChinaApiKey}>
          <Provider>
            <BaiduAutocompleteAddress {...props} handleSearch={handleSearch} />
          </Provider>
        </APILoader>
      )}
      <StyledAutoComplete
        {...restProps}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        defaultActiveFirstOption
        dataSource={dataSet}
        onSelect={handleSelect}
        onSearch={handleSearch}
        theme={theme}
        style={{ display: isChinaAsCountry ? 'none' : 'block' }}
      />
    </>
  );
};

const BaiduAutocompleteAddress = (props: AutocompleteAddressProps & { handleSearch: (q: string) => void }) => {
  const { placeholder, handleSearch, updatelatLngInputValue, value, onChange, updateAddress } = props;
  const { BMap, map } = useMapContext();
  const container = React.useRef(null);
  const { setContainer } = useMap();

  React.useEffect(() => {
    if (BMap && map) {
      const autocomplete = new BMap.Autocomplete({
        location: map,
        input: 'baiduSuggestionInputId',
      });
      const local = new BMap.LocalSearch(map, {
        onSearchComplete: () => {
          const point = (local.getResults() as BMap.LocalResult).getPoi(0).point;
          updatelatLngInputValue(`${point.lat}, ${point.lng}`);
        },
      });
      autocomplete.setInputValue(value ?? '');
      autocomplete.onconfirm = (response) => {
        const {
          item: {
            value: { province = '', city = '', district = '', street = '', streetNumber = '', business = '' } = {},
          } = {},
        } = response;
        const label = `${province}${city}${district}${street}${streetNumber}${business}`;
        const addressObj = {
          label: label,
          countryCode: 'CN',
          countryName: 'China',
          state: province,
          city: `${city}${district}`,
          street,
          houseNumber: `${streetNumber}${business}`,
        };
        handleSearch(label);
        (onChange as Function)(label, {} as any);
        updateAddress({ address: addressObj, title: label } as unknown as SelectValue);
        local.search(label);
        autocomplete.hide();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BMap, map, value]);

  React.useEffect(() => {
    if (container.current) {
      setContainer(container.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container.current]);

  return (
    <>
      <div ref={container} style={{ display: 'none' }} />
      <input
        placeholder={placeholder as string}
        onFocus={(e) => {
          e.target.style.border = `1px solid ${primaryBlue}`;
          e.target.style.boxShadow = `0 0 0 2px ${rgba(primaryBlue, 0.2)}`;
        }}
        onBlur={(e) => {
          e.target.style.border = `1px solid #d5d4d4`;
          e.target.style.boxShadow = 'none';
        }}
        id="baiduSuggestionInputId"
        style={{
          width: '100%',
          fontSize: '1.2rem',
          padding: '8px 11px 7px',
          border: '1px solid #d5d4d4',
        }}
      />
    </>
  );
};

export default AutocompleteAddress;
