/* eslint-disable react/display-name */
import { Dispatch, useContext } from 'react';

import * as React from 'react';
import { push } from 'react-router-redux';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import mapKeys from 'lodash/mapKeys';
import moment from 'moment';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, FilterValue } from 'antd/es/table/interface';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { FilterFilled } from '@ant-design/icons';
import Table from 'common/Table/Table';

import { SortDirection } from 'models/SortDirection';
import { MONTH_DAY_YEAR_FORMAT, HOURS_MINUTES_SECONDS_FORMAT } from 'i18n/configurei18n';
import {
  InventoryOrder,
  BasicInventoryOrderSearchCriteria,
  InventoryOrderSortField,
  InventoryOrderTypeIntlKeys,
  InventoryOrderType,
  InventoryOrderStatusCode,
  InventoryOrderStatusCodeIntlKeys,
} from 'components/Inventory/models';
import { Tooltip, Button } from 'ui-components';
import { DataLoadingEnum, useInventoryOrders } from 'components/shipmentDetails/common/hooks/useInventoryOrders';
import { Input } from 'components/common/forms/FormElements';
import CheckList from 'components/common/checkList/CheckList';
import { tableGrey } from 'styles/colors';
import { MAX_PAGE_QUANTITY, microWebappsUrls } from 'common/AppConstants';
import { Details } from 'components/LocationDetails/LocationShipments/models';
import { mapShipment } from 'components/shipmentListComponent/mappers/mapShipment';
import { showStatusTag } from 'components/shipmentListComponent/shipmentSearchResultCard/ShipmentSearchResultCard';
import { formatNestedIntlValues } from 'i18n/utils';
import { PrincipalAuthorizations } from 'common/authorizations';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { useOrderFeatureFlags } from 'components/orders/utils/OrderFeatureFlags';

interface OrdersTableProps {
  masterShipmentId?: string;
  setTotalItemsCount?: Dispatch<React.SetStateAction<number>>;
  setSearchText?: Dispatch<React.SetStateAction<string>>;
  setShowPopover?: Dispatch<React.SetStateAction<boolean>>;
  inventoryOrderIds?: string[];
  isrelatedOrdersMode?: boolean;
  inventoryOrderId?: string;
}
const pageSize = 30;

/**
 *  V1 of the OrdersTable component.
 */
const OrdersTable: React.FC<OrdersTableProps> = ({
  setTotalItemsCount,
  masterShipmentId,
  setSearchText,
  setShowPopover,
  inventoryOrderIds,
  isrelatedOrdersMode,
  inventoryOrderId,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentFilter, setCurrentFilter] = React.useState<BasicInventoryOrderSearchCriteria>({});
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const { state = { searchText: '' } } = useLocation();
  const principalContext = useContext(PrincipalContext);
  const orderFeatureFlags = useOrderFeatureFlags(principalContext);

  const { searchOrders, shipmentOrders, ordersDataLoading } = useInventoryOrders({
    hasPermission: true,
    masterShipmentId,
    defaultPageSize: pageSize,
    searchText: get(state, 'searchText', ''),
    inventoryOrderIds,
  });

  const columnFilterRequest = (fields: string[], dataIndex: string) => {
    setCurrentFilter({ [dataIndex]: fields });
    const requestBody: BasicInventoryOrderSearchCriteria = {
      [dataIndex]: fields,
    };
    setCurrentPage(1);
    searchOrders(requestBody, 1, pageSize, []);
  };

  const getFilterDropdowns = (dataIndex: string) => ({
    filterDropdown: function filterDropdownComponent({
      selectedKeys,
      setSelectedKeys,
    }: {
      selectedKeys: string[];
      setSelectedKeys: (keys: string[]) => void;
    }) {
      return (
        <div style={{ width: '140px' }}>
          <Input
            className="borderlessInput"
            placeholder={intl.formatMessage({ id: 'orderTable.orderId.filter.placeholder', defaultMessage: 'Filter' })}
            value={selectedKeys[0]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => columnFilterRequest(selectedKeys, dataIndex)}
            onSearch={() => columnFilterRequest(selectedKeys, dataIndex)}
            style={{ marginBottom: '8px', display: 'block' }}
            search
          />
        </div>
      );
    },
    filterIcon: (filtered: boolean) => (
      <FilterFilled data-locator={dataIndex} style={{ color: filtered ? tableGrey : undefined }} />
    ),
  });

  const getFilterCheckBoxList = (dataIndex: string) => ({
    filterDropdown: function filterDropdownComponent({
      selectedKeys,
      setSelectedKeys,
    }: {
      selectedKeys: string[];
      setSelectedKeys: (keys: string[]) => void;
    }) {
      const checkListData = [
        {
          id: InventoryOrderType.SALES_ORDER,
          labelText: intl.formatMessage({ id: 'select.orderTable.sales', defaultMessage: 'Sales' }),
        },
        {
          id: InventoryOrderType.PURCHASE_ORDER,
          labelText: intl.formatMessage({ id: 'select.orderTable.purchase', defaultMessage: 'Purchase' }),
        },
        {
          id: InventoryOrderType.ADVANCED_SHIPMENT_NOTICE,
          labelText: intl.formatMessage({ id: 'select.orderTable.advance', defaultMessage: 'Advanced Shipment' }),
        },
        {
          id: InventoryOrderType.WAREHOUSE_MOVEMENT_ORDER,
          labelText: intl.formatMessage({
            id: 'select.orderTable.warehouse',
            defaultMessage: 'Warehouse Movement',
          }),
        },
      ].map((object) => {
        const position = selectedKeys.findIndex((option: string) => option === object.id);
        return { ...object, checked: position < 0 ? false : true };
      });

      return (
        <div style={{ width: '205px', paddingTop: '15px', paddingBottom: '5px' }}>
          <CheckList
            mode="dark"
            checkFn={(data) => {
              const position = selectedKeys.findIndex((option: string) => option === data.id);
              if (position < 0) {
                setSelectedKeys([...selectedKeys, data.id]);
              } else {
                const newArray = [...selectedKeys];
                newArray.splice(position, 1);
                setSelectedKeys(newArray);
              }
            }}
            checkListData={checkListData}
          />
          <div style={{ width: '100%', padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              type="secondary"
              clickFn={() => {
                setSelectedKeys([]);
                columnFilterRequest([], dataIndex);
              }}
            >
              <FormattedMessage id="select.orderTable.button.reset" defaultMessage="Reset" />
            </Button>
            <Button type="primary" clickFn={() => columnFilterRequest(selectedKeys, dataIndex)}>
              <FormattedMessage id="select.orderTable.button.filter" defaultMessage="Filter" />
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => (
      <FilterFilled data-locator={dataIndex} style={{ color: filtered ? tableGrey : undefined }} />
    ),
  });

  if (setSearchText) {
    setSearchText(get(state, 'searchText', ''));
  }

  if (setTotalItemsCount) {
    setTotalItemsCount(get(shipmentOrders, 'paginationInfo.total', 0));
  }

  const principal = React.useContext(PrincipalContext);

  const authorizations = new PrincipalAuthorizations({
    authorities: get(principal, 'authorities') || [],
    principal,
  });

  const newShipmentEtaAndStatusColumns = [
    {
      dataIndex: 'shipments',
      key: 'shipmentsEta',
      render: function render(shipments: Details[]) {
        if (shipments.length === 0 || shipments.length > 1) return null;

        const shipment = mapShipment(shipments[0], intl);

        if (!shipment) return null;

        if (!(shipment.statusDescription && shipment.statusDescription.values)) return null;

        const values = mapKeys(shipment.statusDescription.values, (_, k) => k.toLowerCase());

        if (!(values.date && values.time)) return null;

        return (
          <FormattedMessage
            id={'inventory.ordersList.shipmentsEta.status'}
            defaultMessage={'{date} at {time}'}
            values={formatNestedIntlValues(get({ ...shipment.statusDescription, values }, 'values', {}), intl)}
          />
        );
      },
      sorter: false,
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.shipmentETA', defaultMessage: 'ETA' }),
      width: 155,
    },
    {
      dataIndex: 'shipments',
      key: 'shipmentsStatus',
      render: function render(shipments: Details[]) {
        if (shipments.length === 0) {
          return (
            <FormattedMessage id={'inventory.ordersList.shipmentsStatus.noShipments'} defaultMessage="No Shipments" />
          );
        }

        if (shipments.length > 1) {
          return (
            <FormattedMessage
              id={'inventory.ordersList.shipmentsStatus.manyShipments'}
              defaultMessage={'Many Shipments'}
            />
          );
        }

        const shipment = mapShipment(shipments[0], intl);

        if (!shipment) return null;

        if (!(shipment.flagText && shipment.flagColor)) return null;

        // @ts-ignore
        return showStatusTag(shipment, false);
      },
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.shipmentsStatus',
        defaultMessage: 'Transportation Status',
      }),
      width: 145,
    },
  ];

  const columns = [
    {
      dataIndex: 'orderType',
      key: 'orderType',
      className: 'data-locator-orderType',
      render: function render(inventoryOrderType: InventoryOrderType) {
        return (
          <FormattedMessage
            id={get(InventoryOrderTypeIntlKeys, `[${inventoryOrderType}].id`, ' ')}
            defaultMessage={get(InventoryOrderTypeIntlKeys, `[${inventoryOrderType}].defaultMessage`, ' ')}
          />
        );
      },
      sorter: false,
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.orderType', defaultMessage: 'Order Type' }),
      width: 195,
      ...getFilterCheckBoxList('orderTypes'),
    },
    {
      dataIndex: 'orderIdentifier',
      key: 'id',
      ellipsis: true,
      sorter: false,
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.orderId', defaultMessage: 'Order ID' }),
      width: 175,
      ...getFilterDropdowns('orderIdentifiers'),
    },
    {
      dataIndex: 'statusCode',
      key: 'statusCode',
      render: function render(inventoryOrderStatusCode: InventoryOrderStatusCode) {
        return (
          <FormattedMessage
            id={get(InventoryOrderStatusCodeIntlKeys, `[${inventoryOrderStatusCode}].id`, ' ')}
            defaultMessage={get(InventoryOrderStatusCodeIntlKeys, `[${inventoryOrderStatusCode}].defaultMessage`, ' ')}
          />
        );
      },
      sorter: false,
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.status', defaultMessage: 'Status' }),
      width: 145,
    },
    ...(authorizations.hasEnableCustomerEmbeddedFeatures() ? newShipmentEtaAndStatusColumns : []),
    {
      dataIndex: 'orderSubmissionDateTime',
      key: 'orderSubmissionDateTime',
      sorter: true,
      title: function titleRender() {
        return (
          <Tooltip
            title={intl.formatMessage({
              id: 'inventory.ordersList.columnHeaders.orderSubmissionDate',
              defaultMessage: 'Order Submission Date',
            })}
          >
            <span>OSD</span>
          </Tooltip>
        );
      },
      width: 195,
      render: function renderFunc(text: string) {
        if (text === undefined || text === null) return '';

        return moment(text).format(`${MONTH_DAY_YEAR_FORMAT} ${HOURS_MINUTES_SECONDS_FORMAT}`);
      },
    },
    {
      dataIndex: ['supplierReadyDateTimeWindow', 'startDateTime'],
      key: 'supplierReadyDateTimeWindow',
      sorter: false,
      render: function renderFunc(text: string) {
        if (text === undefined || text === null) return '';

        return moment(text).format(`${MONTH_DAY_YEAR_FORMAT} ${HOURS_MINUTES_SECONDS_FORMAT}`);
      },
      title: function titleRender() {
        return (
          <Tooltip
            title={intl.formatMessage({
              id: 'inventory.ordersList.columnHeaders.supplierReadyDate',
              defaultMessage: 'Supplier Ready Date',
            })}
          >
            <span>SRD</span>
          </Tooltip>
        );
      },
      width: 195,
    },
    {
      dataIndex: ['originalDeliveryDateTimeWindow', 'endDateTime'],
      key: 'originalDeliveryDateTimeWindow.endDateTime',
      sorter: true,
      render: function renderFunc(text: string) {
        if (text === undefined || text === null) return '';

        return moment(text).format(`${MONTH_DAY_YEAR_FORMAT} ${HOURS_MINUTES_SECONDS_FORMAT}`);
      },
      title: function titleRender() {
        return (
          <Tooltip
            title={intl.formatMessage({
              id: 'inventory.ordersList.columnHeaders.originalDeliveryDate',
              defaultMessage: 'Original Delivery Date',
            })}
          >
            <span>ODD</span>
          </Tooltip>
        );
      },
      width: 195,
    },
    {
      dataIndex: ['vendorLocation', 'contact', 'companyName'],
      key: 'vendorName',
      sorter: false,
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.vendorName', defaultMessage: 'Vendor Name' }),
      width: 175,
    },

    {
      dataIndex: ['destinationLocation', 'contact', 'companyName'],
      key: 'shipToName',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.shipToName',
        defaultMessage: 'Ship To Name',
      }),
      width: 175,
    },

    {
      dataIndex: ['destinationLocation', 'address', 'addressLines', 0],
      key: 'shipToAddress',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.shipToAddress',
        defaultMessage: 'Ship To Address',
      }),
      width: 175,
    },
    {
      dataIndex: ['destinationLocation', 'address', 'addressLines', 1],
      key: 'shipToAddress2',
      sorter: false,
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.shipToAddress2',
        defaultMessage: 'Ship To Address 2',
      }),
      width: 175,
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    let fieldName;

    switch (Array.isArray(sorter) ? sorter[0].columnKey : sorter.columnKey) {
      case InventoryOrderSortField.ORIGINAL_DELIVERY_DATE_END:
        fieldName = InventoryOrderSortField.ORIGINAL_DELIVERY_DATE_END;
        break;
      case InventoryOrderSortField.ORDER_SUBMISSION_DATE_TIME:
        fieldName = InventoryOrderSortField.ORDER_SUBMISSION_DATE_TIME;
        break;
    }

    const sorterOrder = Array.isArray(sorter) ? sorter[0].order === 'ascend' : sorter.order;
    const page = pagination.current || 1;
    const size = pagination.pageSize || pageSize;

    const sortFields = [
      {
        fieldName,
        sortDirection: sorterOrder === 'ascend' ? SortDirection.ASC : SortDirection.DESC,
      },
    ];

    const requestBody: BasicInventoryOrderSearchCriteria = {
      ...currentFilter,
    };

    setCurrentPage(page);

    if (sorterOrder) {
      searchOrders(requestBody, page, size, sortFields);
    }
  };

  const handlePaginationSize = (): number => {
    if (get(shipmentOrders, 'paginationInfo.total', 0) / pageSize > MAX_PAGE_QUANTITY) {
      if (setShowPopover) setShowPopover(true);
      return pageSize * MAX_PAGE_QUANTITY;
    }

    if (setShowPopover) setShowPopover(false);
    return shipmentOrders?.paginationInfo.total || 0;
  };

  return (
    <Table
      isDraggable
      loading={DataLoadingEnum.LOADING === ordersDataLoading}
      rowKey={(record) => record.id}
      dataSource={shipmentOrders?.results}
      columns={columns}
      scroll={{ x: 1200, y: 640 }}
      onChange={handleTableChange}
      rowClassName={() => 'cursor-pointer'}
      onRow={(record: InventoryOrder) => {
        return {
          onClick: () => {
            const newOrderPageUrl = `${microWebappsUrls.baseUiUrl}/order/${record.id}`;
            if (orderFeatureFlags.openBothOrderPages) {
              // intentionally open in new tab for convenience to avoid removing current table and work around middle click
              // not working
              window.open(newOrderPageUrl);
              const currentPageUrl = `${window.location.origin}/portal/v2/inventory/orders/${record.id}`;
              window.open(currentPageUrl);
            } else if (orderFeatureFlags.useNewOrderPage) {
              window.location.assign(newOrderPageUrl);
            } else {
              dispatch(push(`/inventory/orders/${record.id}`));
            }
          },
        };
      }}
      pagination={{
        pageSize: pageSize,
        total: handlePaginationSize(),
        showSizeChanger: false,
        current: currentPage,
        onChange: (page, size = pageSize) => {
          const requestBody: BasicInventoryOrderSearchCriteria = {
            searchText: get(state, 'searchText', ''),
          };

          searchOrders(requestBody, page, size);
        },
      }}
      size="middle"
      showSorterTooltip={false}
    />
  );
};

export default OrdersTable;
