import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'ui-components';
import { statusOptions } from '../../../LTL/ducks/mappers/routeDetails/enums';
import './Update.scss';

const Update = (props) => {
  const fillColor = props.markerColor ? `var(--${props.markerColor}` : '#575451';

  return (
    <Row
      type="flex"
      className={props.description ? 'route-update animated fadeIn has-description' : 'animated fadeIn route-update'}
    >
      <Col className="route-update__date">{props.date}</Col>
      {props.latestStatusUpdate && (
        <Col className="route-update__dotted-line">
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <circle id="path-1" cx="25" cy="25" r="25" />
              <path
                d="M4.20003623,8 C5.1265769,3.43551936 9.16208418,0 14,0 C18.8379158,0 22.8734231,3.43551936 23.7999638,8 L28,8 L28,12 L23.7999638,12 C22.8734231,16.5644806 18.8379158,20 14,20 C9.16208418,20 5.1265769,16.5644806 4.20003623,12 L0,12 L0,8 L4.20003623,8 Z"
                id="path-3"
              />
              <circle id="path-5" cx="6" cy="6" r="6" />
            </defs>
            <g id="Page-1" fill="none" fillRule="evenodd">
              <g id="icons-rev3" transform="translate(-495 -385)">
                <g id="card/current-location" transform="translate(495 385)">
                  <g id="color/gray" opacity="0.25">
                    <mask id="mask-2" fill="#fff">
                      <use xlinkHref="#path-1" />
                    </mask>
                    <use id="Mask" fill="#D8D8D8" fillRule="nonzero" xlinkHref="#path-1" />
                    <g id="color/gray-80" mask="url(#mask-2)" fill={fillColor}>
                      <rect id="Rectangle-6" width="50" height="50" />
                    </g>
                  </g>
                  <g id="icon/current" transform="translate(11 15)">
                    <g id="color/white">
                      <mask id="mask-4" fill="#fff">
                        <use xlinkHref="#path-3" />
                      </mask>
                      <use id="Mask" fill="#FFF" fillRule="nonzero" xlinkHref="#path-3" />
                      <g mask="url(#mask-4)" fill="#FFF" id="Rectangle-6">
                        <rect width="30" height="30" transform="translate(-1 -5)" />
                      </g>
                    </g>
                    <g id="color/gray" transform="translate(8 4)">
                      <mask id="mask-6" fill="#fff">
                        <use xlinkHref="#path-5" />
                      </mask>
                      <use id="Mask" fill="#2D2926" fillRule="nonzero" xlinkHref="#path-5" />
                      <g id="color/gray-80" mask="url(#mask-6)" fill={fillColor}>
                        <rect width="30" height="30" transform="translate(-9 -9)" id="Rectangle-6" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <div className="vertical-line-dotted" />
          <div className="vertical-line-dotted" />
          <div className="vertical-line-dotted" />
        </Col>
      )}
      {!props.latestStatusUpdate && (
        <Col className="route-update__line">
          <span className="vertical-line" />
        </Col>
      )}
      <Row type="flex" className="route-update__info">
        <Col
          className={
            props.label === statusOptions.DISPLAY.EXCEPTION ||
            props.label === statusOptions.DISPLAY.DELIVERY_MISSED ||
            props.label === statusOptions.DISPLAY.DELIVERED_WITH_EXCEPTIONS
              ? 'route-update__label exception'
              : 'route-update__label'
          }
        >
          {props.label.id ? (
            <FormattedMessage id={props.label.id} defaultMessage={props.label.defaultMessage} />
          ) : (
            props.label
          )}
        </Col>
        {props.label && props.tertiary && props.tertiary !== 'null' && (
          // temporary - currently some location values in OCEAN are coming in a string 'null'
          <Col className="route-update__divider">|</Col>
        )}
        {props.label && props.tertiary && props.tertiary !== 'null' && (
          <Col className="route-update__tertiary uppercase">{props.tertiary}</Col>
        )}
        {props.description && (
          <Col span={24} className="route-update__description">
            {props.description}
          </Col>
        )}
      </Row>
    </Row>
  );
};

export default Update;
