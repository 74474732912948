/* eslint-disable no-constant-condition */
import { useCallback, useEffect, useRef, useState } from 'react';

import * as React from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import gt from 'lodash/gt';
import { DownOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { Input, Select, Tooltip } from 'ui-components';
import { primaryBlue, primaryGrey500, primaryGreyEighty, primaryGrey } from 'styles/colors';
import * as styles from './SearchBar.module.scss';
import MultiValueSearchModal from './MultiValueSearchModal';
import { SearchServiceTypeEnum } from './models';

const StyledMultiFieldButton = styled('button')`
  border: none;
  background: none;
  padding: 0;
  color: ${primaryBlue};
  cursor: pointer;
  font-size: 14px;

  &:disabled {
    color: ${primaryGrey500};
    cursor: default;
  }
`;

const StyledInput = styled(Input)<{ isMultiValue: boolean }>`
  input,
  button {
    height: 36px !important;
    min-height: 36px !important;
  }

  background-color: #fff !important;
  .ant-input-group-addon {
    border: 0 !important;

    .select {
      border: 1px solid ${primaryGreyEighty};
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: 0;
      height: 36px;
      overflow: hidden;

      .ant-select-selector:hover,
      .ant-select-selector:active,
      .ant-select-selector:focus,
      .ant-select-focused .ant-select-selector {
        border-color: transparent !important;
      }
    }
  }

  input {
    border-left: ${(props) => (props.hasAdvancedLinking ? 0 : '1px solid')} !important;
    border-top: 1px solid var(--primary-grey-80) !important;
    border-bottom: 1px solid var(--primary-grey-80) !important;
    border-right: 1px solid var(--primary-grey-80) !important;
    border-radius: 4px;
  }

  .primary button {
    border-color: ${primaryGreyEighty};

    &:hover {
      border-color: ${primaryGreyEighty};
    }
  }
`;

const messages = defineMessages({
  shipmentSearchPlaceholder: {
    id: 'header.search.shipmentPlaceholder',
    defaultMessage: 'Search Shipments',
  },
  inventorySearchPlaceholder: {
    id: 'header.search.inventoryPlaceholder',
    defaultMessage: 'Search Inventory',
  },
  ordersSearchPlaceholder: {
    id: 'header.search.ordersPlaceholder',
    defaultMessage: 'Search Orders',
  },
});
export interface SearchBarProps {
  modes: any;
  searchTerm: string;
  isMuliValueSearchModalOpen: boolean;
  previousSearchQuery: any;
  executeSearchFn: (isMultiValue?: boolean, serviceType?: string) => void;
  setSearchTerm: (searchTerm: string) => void;
  setIsMuliValueSearchModalOpen: (isMuliValueSearchModalOpen: boolean) => void;
  dontSearchOnChange?: boolean;
  hasMultiFieldSearch?: boolean;
  hasAdvancedLinking?: boolean;
  isOrdersMatch: boolean;
  isInventoryMatch: boolean;
}

const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  modes,
  searchTerm,
  isMuliValueSearchModalOpen,
  previousSearchQuery,
  executeSearchFn,
  setSearchTerm,
  setIsMuliValueSearchModalOpen,
  dontSearchOnChange,
  hasMultiFieldSearch = false,
  hasAdvancedLinking,
  isOrdersMatch,
  isInventoryMatch,
}) => {
  const [text, setText] = useState<string>('');
  const [multiSearchTerm, setMultiSearchTerm] = useState<string>('');
  const [numberTags, setNumberTags] = useState<number>(0);
  const enableMultiValueSearch = get(previousSearchQuery, 'enableMultiValueSearch');
  const [tags] = useState<any>(new Set());
  const [unmatchedTags] = useState<any>(new Set());
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [termAfterFind, setTermAfterFind] = useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const openMultiSearchModal = () => setIsMuliValueSearchModalOpen(true);
  const closeMultiSearchModal = () => setIsMuliValueSearchModalOpen(false);

  const initialSearchTerm = isEmpty(searchTerm?.trim()) ? [] : searchTerm.split(' ');
  const initialSearchTermRef = useRef(initialSearchTerm);
  initialSearchTermRef.current = initialSearchTerm;

  const getCurrentServiceType = useCallback(() => {
    if (isOrdersMatch) return SearchServiceTypeEnum.ORDERS_SEARCH;
    if (isInventoryMatch) return SearchServiceTypeEnum.INVENTORY_SEARCH;

    return SearchServiceTypeEnum.SHIPMENT_SEARCH;
  }, [isOrdersMatch, isInventoryMatch]);

  const [serviceType, setServiceType] = useState<SearchServiceTypeEnum>(getCurrentServiceType());

  const search = useCallback(() => {
    tags.clear();
    setNumberTags(0);
    executeSearchFn(false, serviceType);
  }, [executeSearchFn, serviceType, tags]);

  const resetMuliValueSearch = useCallback(() => {
    tags.clear();
    unmatchedTags.clear();
    setCurrentStep(0);
    setNumberTags(0);
    setErrorMessage('');
    setTermAfterFind('');
  }, [tags, unmatchedTags]);

  const handleClearAll = () => {
    resetMuliValueSearch();
    setText('');
    search();
  };

  useEffect(() => {
    const hasTerms = !isEmpty(initialSearchTermRef.current);

    if (enableMultiValueSearch && hasTerms) {
      initialSearchTermRef.current.forEach((val) => tags.add(val));
      setNumberTags(tags.size);
    } else {
      resetMuliValueSearch();
    }
  }, [enableMultiValueSearch, resetMuliValueSearch, tags]);

  useEffect(() => {
    setServiceType(getCurrentServiceType());
  }, [getCurrentServiceType]);

  const intl = useIntl();

  const debounceSearch = debounce(search, 500); // .5 second debounced delay when typing in search input

  const handleSearchOnChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (!evt) {
        return undefined;
      }
      const searchTerm = get(evt, 'currentTarget.value', '');
      setSearchTerm(searchTerm);
      if (!dontSearchOnChange) {
        debounceSearch();
      }
    },
    [setSearchTerm, dontSearchOnChange, debounceSearch]
  );

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.currentTarget.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', ' ', ','].includes(e.key)) {
      e.preventDefault();
      const term = get(e, 'currentTarget.value', '').trim();
      !isEmpty(term) && tags.add(term);
      setMultiSearchTerm(Array.from(tags).join(' '));
      setText('');
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    const separatedPasteContent = paste.trim().split(/\n |\s/g);
    const updatedTags = [...Array.from(tags), ...separatedPasteContent];
    //https://lodash.com/docs/4.17.15#reject
    let trimmedTags = reject(updatedTags, isEmpty);
    if (trimmedTags.length > 2000) {
      const newClip = trimmedTags.slice(2000);
      const tagsToCopy = Array.from(newClip).join(' ');
      navigator.clipboard.writeText(tagsToCopy.toString()).then(() => {
        setErrorMessage("You're over 2000 orders, the remaining ones were copied to your clipboard");
      });
      trimmedTags = trimmedTags.slice(0, 2000);
    }

    setMultiSearchTerm(trimmedTags.join(' '));
    trimmedTags.map((text) => tags.add(text));
    setText('');
  };

  const handleRemoveTag = (tag: any) => {
    tags.delete(tag);
    setMultiSearchTerm(Array.from(tags).join(' '));
    if (tags.size === 0) handleClearAll();
  };

  const handleCancel = closeMultiSearchModal;

  const handleSubmitSearch = async (searchType: SearchServiceTypeEnum) => {
    await setSearchTerm(multiSearchTerm);
    setNumberTags(tags.size);
    closeMultiSearchModal();
    setServiceType(searchType);
    executeSearchFn(hasMultiFieldSearch, serviceType);
  };

  const selectBefore = (
    <Select
      value={serviceType}
      onChange={(value: SearchServiceTypeEnum) => setServiceType(value)}
      style={{ minWidth: '110px' }}
      suffixIcon={<DownOutlined style={{ color: primaryGrey, pointerEvents: 'none' }} />}
      defaultValue={serviceType}
      data-locator="searchDropDown"
      dataSource={[
        {
          value: SearchServiceTypeEnum.SHIPMENT_SEARCH,
          displayValue: intl.formatMessage({ id: 'searchBar.searchType.shipments', defaultMessage: 'Shipments' }),
        },
        {
          value: SearchServiceTypeEnum.ORDERS_SEARCH,
          displayValue: intl.formatMessage({ id: 'searchBar.searchType.orders', defaultMessage: 'Orders' }),
        },
        {
          value: SearchServiceTypeEnum.INVENTORY_SEARCH,
          displayValue: intl.formatMessage({ id: 'searchBar.searchType.inventory', defaultMessage: 'Inventory' }),
        },
      ]}
    />
  );

  let placeholder;

  if (serviceType === SearchServiceTypeEnum.SHIPMENT_SEARCH) {
    placeholder = messages.shipmentSearchPlaceholder;
  } else if (serviceType === SearchServiceTypeEnum.INVENTORY_SEARCH) {
    placeholder = messages.inventorySearchPlaceholder;
  } else {
    placeholder = messages.ordersSearchPlaceholder;
  }

  return (
    <div className={`${styles.SearchBar} justify-content-start`}>
      {/* This label will not display in the UI, but will be available for ScreenReaders. */}
      <label className="sr-only" htmlFor="global-search">
        {
          <FormattedMessage
            id="header.search.placeholder"
            defaultMessage="Search shipments by BOL, Order #, carrier, company or location."
          />
        }
      </label>
      <StyledInput
        className="borderlessInput searchBarInput"
        search="primary"
        isMultiValue={hasMultiFieldSearch}
        placeholder={intl.formatMessage(placeholder)}
        onChange={handleSearchOnChange}
        onSearch={search}
        onPressEnter={search}
        hasAdvancedLinking={hasAdvancedLinking}
        custom={{
          id: 'global-search',
          value: searchTerm,
          'data-locator': 'searchBarInput',
          addonBefore: hasAdvancedLinking && selectBefore,
        }}
      />
      {hasMultiFieldSearch && (
        <div style={{ textAlign: 'left' }}>
          <Tooltip trigger="hover" placement="bottom" title={'Use Multi-value Search to search up to 200 values'}>
            <StyledMultiFieldButton onClick={openMultiSearchModal} data-locator="multi-value-search-button">
              {gt(numberTags, 0) ? (
                <FormattedMessage
                  defaultMessage="Search multiple fields ({numberTags})"
                  id="searchBar.multiValue.linkText.numbersOfTagsV2"
                  values={{ numberTags }}
                />
              ) : (
                <FormattedMessage defaultMessage="Search multiple fields" id="searchBar.multiValue.linkTextV2" />
              )}
            </StyledMultiFieldButton>
          </Tooltip>
        </div>
      )}
      <MultiValueSearchModal
        isOpen={isMuliValueSearchModalOpen}
        modes={modes}
        tags={tags}
        text={text}
        multiSearchTerm={multiSearchTerm}
        setMultiSearchTerm={setMultiSearchTerm}
        handleCancel={handleCancel}
        handleRemoveTag={handleRemoveTag}
        handleKeyPress={handleKeyPress}
        handlePaste={handlePaste}
        handleTextAreaChange={handleTextAreaChange}
        handleClearAll={handleClearAll}
        handleSubmitSearch={handleSubmitSearch}
        hasAdvancedLinking={hasAdvancedLinking}
        dataLocator="multi-value-search-modal-title"
        unmatchedTags={unmatchedTags}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        termAfterFind={termAfterFind}
        setTermAfterFind={setTermAfterFind}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        setSearchTerm={setSearchTerm}
        serviceType={serviceType}
        setServiceType={setServiceType}
      />
    </div>
  );
};

export default SearchBar;
