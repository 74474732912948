import { EVENT_TYPE_LABEL } from 'components/EventDatePicker/constants';
import { EventTypeKey } from 'components/EventDatePicker/types';
import PresetDateSelect from '../PresetDateSelect';
import { ShipmentModeFilter } from './types';

export const DATE_FILTERS: Record<EventTypeKey, ShipmentModeFilter> = {
  delivery: {
    name: EVENT_TYPE_LABEL.delivery,
    component: PresetDateSelect,
    modalId: 'delivery',
    metaData: {
      eventNamespace: 'delivery',
    },
  },
  pickup: {
    name: EVENT_TYPE_LABEL.pickup,
    component: PresetDateSelect,
    modalId: 'pickup',
    metaData: {
      eventNamespace: 'pickup',
    },
  },
  arrival: {
    name: EVENT_TYPE_LABEL.arrival,
    component: PresetDateSelect,
    modalId: 'arrival',
    metaData: {
      eventNamespace: 'delivery',
    },
  },
  lastFreeDate: {
    name: EVENT_TYPE_LABEL.lastFreeDate,
    component: PresetDateSelect,
    modalId: 'lastFreeDate',
    metaData: {
      eventNamespace: 'oceanLastFree',
    },
  },
};
