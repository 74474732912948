import HereMapsBehavior from '../../../../../common/hereMaps/hereMapsBehavior';
import allStopPopupHtml from '../popup/allStopPopupHtml';

const stopMarker = `
  <svg height="28" width="28" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <circle fill="#00558B" id="path-1" cx="12" cy="12" r="9"></circle>
  </svg>
`;
const deliveryFlagOutline = `
  <svg height="32" width="32" viewBox="0 0 22.5 27" xmlns="http://www.w3.org/2000/svg">
    <path fill="#00558B" d="M1.5,0A1.5,1.5,0,0,1,3,1.5V2.82A16.09,16.09,0,0,1,9,1.5c4.5,0,4.5,3,7.5,3,4.5,0,6-3,6-3v12s-1.5,3-6,3-4.5-3-7.5-3c-4.5,0-6,3-6,3V27H0V1.5A1.5,1.5,0,0,1,1.5,0Z"/>
    <path fill="#fff" d="M3,6v6a13.31,13.31,0,0,1,6-1.5c3,0,6,3,7.5,3a4.32,4.32,0,0,0,3-1.5V6a4.32,4.32,0,0,1-3,1.5c-1.5,0-4.5-3-7.5-3A13.31,13.31,0,0,0,3,6Z"/>
  </svg>
`;
const deliveryFlagFilled = `
  <svg height="36" width="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="#00558B" d="M6,3 C6.55228475,3 7,3.44771525 7,4 L7,4.88 C8.06,4.44 9.5,4 11,4 C14,4 14,6 16,6 C19,6 20,4 20,4 L20,12 C20,12 19,14 16,14 C13,14 13,12 11,12 C8,12 7,14 7,14 L7,21 L5,21 L5,4 C5,3.44771525 5.44771525,3 6,3 Z" id="path-1"></path>
  </svg>
`;

/**
 * Custom html marker and popup styling for all locations.
 *
 * @param mapRef reference to the map instance
 * @param record an object containing location data
 */
const allStopHtmlMarker = (mapRef, record) => {
  let { isDelivered, isDeliveryStop, isPendingStop } = record;
  // Example below was copied from HERE Maps docs and translated to ES6 - Mark Serrano
  let outerElement = document.createElement('div');
  let innerElement = document.createElement('div');
  outerElement.style.userSelect = 'none';
  outerElement.style.webkitUserSelect = 'none';
  outerElement.style.msUserSelect = 'none';
  outerElement.style.mozUserSelect = 'none';
  outerElement.style.cursor = 'default';
  innerElement.style.color = 'red';
  innerElement.style.border = 'none';
  innerElement.style.font = 'normal 12px arial';
  innerElement.style.lineHeight = '12px';
  innerElement.style.paddingTop = '0';
  innerElement.style.paddingLeft = '0';

  outerElement.appendChild(innerElement);

  if (isDeliveryStop) {
    // Show the delivery flag
    innerElement.style.marginTop = '-32px';
    innerElement.style.marginLeft = '-6px';

    if (isDelivered) {
      innerElement.innerHTML = deliveryFlagFilled;
    } else {
      innerElement.innerHTML = deliveryFlagOutline;
    }
  } else if (isPendingStop && !isDeliveryStop) {
    innerElement.style.width = '24px';
    innerElement.style.height = '24px';
    innerElement.style.marginTop = '-14px';
    innerElement.style.marginLeft = '-14px';
    innerElement.style.borderRadius = '50%';
    innerElement.style.border = '4px solid #00558B';
    innerElement.style.backgroundColor = '#fff';
  } else {
    innerElement.style.marginTop = '-14px';
    innerElement.style.marginLeft = '-14px';
    innerElement.innerHTML = stopMarker;
  }

  // Logic to remove the bubble. A little bit buggy when using a custom html marker - Mark Serrano
  const onIconClick = (evt) => {
    if (
      mapRef.bubble &&
      mapRef.bubble.currentPointer &&
      mapRef.bubble.currentPointer.viewportX !== evt.screenX &&
      mapRef.bubble.currentPointer.viewportY !== evt.screenY
    ) {
      mapRef.bubble.close();
      mapRef.bubble = null;
    } else if (mapRef.bubble === null) {
      const position = { lat: record.lat, lng: record.lng };
      const content = allStopPopupHtml(record);
      const currentPointer = { viewportX: evt.screenX, viewPortY: evt.screenY };
      HereMapsBehavior.addBubble(mapRef, position, content, currentPointer);
      HereMapsBehavior.setCenter(mapRef, position);
    }
  };

  let domIcon = new H.map.DomIcon(outerElement, {
    onAttach: function (clonedElement, domIcon, domMarker) {
      clonedElement.addEventListener('click', onIconClick);
    },
    onDetach: function (clonedElement, domIcon, domMarker) {
      clonedElement.removeEventListener('click', onIconClick);
    },
  });

  return domIcon;
};

export default allStopHtmlMarker;
