import { ReactElement, useEffect, useReducer, useState } from 'react';
import { PlusOutlined, CloseCircleFilled } from '@ant-design/icons';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { white, primaryBlue, primaryGreyThirtyTwo, errorRed } from 'styles/colors';
import { Button, Modal, Switch, Radio } from 'ui-components';
import {
  ReadableShipmentPropertyNamesEnum,
  ReadableShipmentPropertyKeysEnum,
  ReadableShipmentPropertyNamesIntlKeys,
  ReadableShipmentPropertyKeysIntlKeys,
  RuleProperty,
  AddModalInterface,
  ShareableDataPoints,
  ReadableShareableDataPoints,
  ShareRegionsEnum,
  ReadableShareRegionsKeysIntlKeys,
} from './models';
import { AndDivider, OrDivider } from './TextDividers';
import ErrorPanel from './ErrorPanel';

const StyledAddButtons = styled('div')`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
`;
const StyledAddInputs = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const StyledAddModalSubtitle = styled('div')`
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: bold;
  width: 100%;
`;
const StyledSwitchHolder = styled('div')`
  display: flex;
  justify-content: flex-start;
  padding-block: 0.5em;
`;
const SpacedSpan = styled('span')`
  padding-left: 1em;
`;
const AddButtonStyle = {
  border: primaryBlue,
  borderWidth: '2px',
  borderStyle: 'solid',
  boxShadow: 'none',
  backgroundColor: white,
  color: primaryBlue,
  marginRight: '0.5em',
};
const messages = defineMessages({
  tenantId: {
    id: 'entitlementRules.rules.modals.add.text.tenantId',
    defaultMessage: 'Tenant ID',
  },
});
function reducer(state: any, action: any) {
  switch (action.type) {
    case 'setEntitleeId':
      return { ...state, entitleeId: action.payload?.entitleeId };
    case 'setEntitleeType':
      return { ...state, entitleeType: action.payload?.entitleeType };
    case 'addLocationProperty': {
      let newProperties = [];
      newProperties = state.shipmentProperties.length
        ? [...state.shipmentProperties, action.payload?.locationProperty]
        : [action.payload?.locationProperty];
      newProperties.sort((a, b) => a.shipmentPropertyName.localeCompare(b.shipmentPropertyName));
      return { ...state, shipmentProperties: newProperties };
    }
    case 'addPartyProperty': {
      return !state.shipmentProperties.length
        ? { ...state, shipmentProperties: [action.payload?.partyProperty] }
        : { ...state, shipmentProperties: [...state.shipmentProperties, action.payload?.partyProperty] };
    }
    case 'editPropertyValue': {
      const newProperties = [...state.shipmentProperties];
      newProperties[action.payload?.index].shipmentPropertyValue = action.payload?.value;
      return { ...state, shipmentProperties: newProperties };
    }
    case 'deleteProperty': {
      const newProperties = state.shipmentProperties.filter((el: RuleProperty, index: number) => {
        if (index !== action.payload?.index) return el;
        return null;
      });
      return { ...state, shipmentProperties: newProperties };
    }
    case 'addExcludedData': {
      const excludedData = [...state.excludedData, action.payload.excludedDataPoint];
      return { ...state, excludedData };
    }
    case 'removeExcludedData': {
      const excludedData = state.excludedData.filter((el: string) => el !== action.payload.excludedDataPoint);
      return { ...state, excludedData };
    }
    case 'setEntitleeRegion': {
      return { ...state, entitleeRegion: action.payload.entitleeRegion };
    }
    default:
      throw new Error();
  }
}

const AddModal = ({ setAddModalOpen, createEntitlementRule, modalError, clearModalError }: AddModalInterface) => {
  const intl = useIntl();

  const [okDisabled, setOkDisabled] = useState(true);

  const [addRuleState, dispatch] = useReducer(reducer, {
    entitleeId: undefined,
    entitleeType: 'TENANT',
    shipmentProperties: [],
    excludedData: [],
    entitleeRegion: ShareRegionsEnum.NA12,
  });

  useEffect(() => {
    if (
      addRuleState?.entitleeId?.length &&
      addRuleState?.shipmentProperties?.every((property: RuleProperty) => property.shipmentPropertyValue.length)
    ) {
      setOkDisabled(false);
    }
  }, [addRuleState]);

  const deleteProperty = (property: RuleProperty) => {
    dispatch({
      type: 'deleteProperty',
      payload: {
        index: [...addRuleState.shipmentProperties].findIndex((prop) => prop === property),
      },
    });
  };

  const onPropertyChange = ({ shipmentProperty, value }: { shipmentProperty: RuleProperty; value: string }) => {
    dispatch({
      type: 'editPropertyValue',
      payload: {
        index: [...addRuleState.shipmentProperties].findIndex((prop) => prop === shipmentProperty),
        value,
      },
    });
  };

  const handleSubmit = () => {
    const { entitleeId, entitleeType, shipmentProperties, excludedData, entitleeRegion } = addRuleState;
    createEntitlementRule({
      entitleeId,
      entitleeType,
      shipmentProperties,
      excludedData,
      entitleeRegion,
    });
  };

  const BottomMessage = () => {
    //Share orders which include an InvolvedParty matching AccountID 1672562436
    //AND a Location matching System_Master LocationID Greater Chicagoland Area
    //OR a Location matching System_Master LocationID Detroit, MI to Aldi.
    const hasTenantError = modalError && modalError.type === 'tenant';
    const AllProperties = () => {
      const properties: ReactElement[] = [];
      addRuleState.shipmentProperties.forEach((property: RuleProperty, index: number) => {
        if (
          index > 0 &&
          addRuleState.shipmentProperties[index - 1].shipmentPropertyName !==
            addRuleState.shipmentProperties[index].shipmentPropertyName
        )
          properties.push(
            <span key={addRuleState.shipmentProperties[index].shipmentPropertyName}>
              {' '}
              <FormattedMessage defaultMessage="AND" id="entitlementRules.rules.modals.add.dividers.and" />{' '}
            </span>
          );
        properties.push(<FormattedMessage {...ReadableShipmentPropertyNamesIntlKeys[property.shipmentPropertyName]} />);
        properties.push(<> </>);
        properties.push(<FormattedMessage {...ReadableShipmentPropertyKeysIntlKeys[property.shipmentPropertyKey]} />);
        if (property.shipmentPropertyValue) {
          properties.push(
            <span key={property.shipmentPropertyValue}>
              {' '}
              <FormattedMessage defaultMessage="with ID" id="entitlementRules.rules.modals.add.text.withId" />{' '}
              {property.shipmentPropertyValue}
            </span>
          );
        }
        if (
          index < addRuleState.shipmentProperties.length - 1 &&
          addRuleState.shipmentProperties[index + 1].shipmentPropertyName ===
            addRuleState.shipmentProperties[index].shipmentPropertyName
        )
          properties.push(
            <span key={addRuleState.shipmentProperties[index].shipmentPropertyName}>
              {' '}
              <FormattedMessage defaultMessage="OR" id="entitlementRules.rules.modals.add.dividers.or" />{' '}
            </span>
          );
      });
      return <span data-testid="rulesTextBuilder">{properties}</span>;
    };
    const ShareText = () => (
      <>
        {addRuleState.shipmentProperties?.length ? (
          <FormattedMessage
            defaultMessage="Share shipments which include a "
            id="entitlementRules.rules.modals.add.text.shareOrders"
          />
        ) : null}
        <AllProperties />{' '}
        {addRuleState.entitleeId && (
          <>
            <FormattedMessage defaultMessage="to tenant" id="entitlementRules.rules.modals.add.text.toTenant" />{' '}
            {addRuleState.entitleeId}{' '}
          </>
        )}
      </>
    );

    return hasTenantError ? null : <ShareText />;
  };
  return (
    <Modal
      visible
      closable={false}
      onCancel={() => setAddModalOpen(false)}
      onOk={handleSubmit}
      okText={<FormattedMessage defaultMessage="Add Rule" id="entitlementRules.rules.modals.add.buttons.add" />}
      okButtonProps={{ disabled: okDisabled }}
    >
      <h3>
        <FormattedMessage defaultMessage="Add Sharing Rule" id="entitlementRules.rules.modals.add.titles.add" />
      </h3>
      <div>
        <label>
          {
            <FormattedMessage
              defaultMessage="Share to tenant"
              id="entitlementRules.rules.modals.add.titles.shareToTenant"
            />
          }
          <input
            required
            style={{ width: '100%', border: `solid 1px ${modalError ? errorRed : primaryGreyThirtyTwo}` }}
            type="text"
            placeholder={intl.formatMessage(messages.tenantId)}
            value={addRuleState.entitleeId ?? ''}
            onChange={(e) => {
              clearModalError();
              dispatch({ type: 'setEntitleeId', payload: { entitleeId: e.target.value } });
            }}
          />
        </label>
        {modalError?.type === 'tenant' && (
          <p style={{ color: errorRed }}>
            <FormattedMessage
              defaultMessage="Please enter a valid Tenant ID"
              id="entitlementRules.add.error.invalidTenantID"
            />
          </p>
        )}
      </div>
      <div>
        <StyledAddModalSubtitle>
          <FormattedMessage defaultMessage="Share to region:" id="entitlementRules.rules.titles.entitleeRegion" />
        </StyledAddModalSubtitle>
        <Radio
          radioData={Object.entries(ShareRegionsEnum).map((region) => ({
            label: <FormattedMessage {...ReadableShareRegionsKeysIntlKeys[region[1]]} />,
            value: region[0],
          }))}
          onChange={(e: any) => dispatch({ type: 'setEntitleeRegion', payload: { entitleeRegion: e.target.value } })}
          value={addRuleState.entitleeRegion}
        ></Radio>
      </div>
      <div>
        <StyledAddModalSubtitle>
          <FormattedMessage defaultMessage="Share these fields:" id="entitlementRules.rules.titles.shareTheseFields" />
        </StyledAddModalSubtitle>
        <form>
          {Object.values(ShareableDataPoints).map((shareableParam: string) => {
            //@ts-ignore
            const readableDataPoint = ReadableShareableDataPoints[ShareableDataPoints[shareableParam]];
            return (
              <StyledSwitchHolder key={shareableParam}>
                <Switch
                  checked={!addRuleState.excludedData?.includes(shareableParam)}
                  onChange={() =>
                    dispatch({
                      type: addRuleState.excludedData?.includes(shareableParam)
                        ? 'removeExcludedData'
                        : 'addExcludedData',
                      payload: { excludedDataPoint: shareableParam },
                    })
                  }
                />
                <SpacedSpan>
                  <FormattedMessage id="entitlementRules.SHARE" defaultMessage="SHARE" />{' '}
                  <FormattedMessage {...readableDataPoint} />
                </SpacedSpan>
              </StyledSwitchHolder>
            );
          })}
        </form>
      </div>
      <div>
        <StyledAddModalSubtitle>
          <FormattedMessage defaultMessage="CONFIGURE RULE CONDITIONS" id="entitlementRules.rules.buttons.add" />
        </StyledAddModalSubtitle>
        <form>
          {addRuleState.shipmentProperties.map((property: RuleProperty, index: number) => (
            // there's no obvious way to uniquely identify the shipmentProperties elements, so suppress the warning
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <AndDivider index={index} shipmentProperties={addRuleState.shipmentProperties} />
              <label htmlFor={`input-${index}`}>
                <FormattedMessage {...ReadableShipmentPropertyNamesIntlKeys[property.shipmentPropertyName]} />
              </label>
              <StyledAddInputs>
                <input
                  id={`input-${index}`}
                  required
                  type="text"
                  name={`${property.shipmentPropertyKey} Id - ${index}`}
                  style={{ width: '90%', border: `solid 1px ${primaryGreyThirtyTwo}` }}
                  onChange={(e) => onPropertyChange({ shipmentProperty: property, value: e.target.value ?? '' })}
                  value={property.shipmentPropertyValue ?? ''}
                  placeholder={`${property.shipmentPropertyKey} Id`}
                ></input>
                <button
                  style={{ border: 'none', backgroundColor: `${white}` }}
                  onClick={() => deleteProperty(property)}
                  type="button"
                >
                  <CloseCircleFilled />
                </button>
              </StyledAddInputs>
              <OrDivider index={index} shipmentProperties={addRuleState.shipmentProperties} />
            </div>
          ))}
        </form>
        <StyledAddButtons>
          <Button
            style={AddButtonStyle}
            icon
            onClick={() =>
              dispatch({
                type: 'addPartyProperty',
                payload: {
                  partyProperty: {
                    shipmentPropertyName: ReadableShipmentPropertyNamesEnum.PARTY_IDENTIFIER,
                    shipmentPropertyKey: ReadableShipmentPropertyKeysEnum.ACCOUNT,
                    shipmentPropertyValue: '',
                  },
                },
              })
            }
          >
            <PlusOutlined />
            <FormattedMessage defaultMessage="ADD INVOLVED PARTY" id="entitlementRules.rules.buttons.add" />
          </Button>
          <Button
            style={AddButtonStyle}
            icon
            onClick={() =>
              dispatch({
                type: 'addLocationProperty',
                payload: {
                  locationProperty: {
                    shipmentPropertyName: ReadableShipmentPropertyNamesEnum.LOCATION_IDENTIFIER,
                    shipmentPropertyKey: ReadableShipmentPropertyKeysEnum.SYSTEM_MASTER,
                    shipmentPropertyValue: '',
                  },
                },
              })
            }
          >
            <PlusOutlined />
            <FormattedMessage defaultMessage="ADD LOCATION" id="entitlementRules.rules.buttons.add" />
          </Button>
        </StyledAddButtons>
        <BottomMessage />
      </div>
      {modalError?.type === 'invitation' && <ErrorPanel supportRefId={modalError.errorMessage} />}
    </Modal>
  );
};
export default AddModal;
