import get from 'lodash/get';
import moment from 'moment';
import qs from 'qs';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Menu, Dropdown, Spin } from 'antd';
import { LoadingOutlined, DownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { Button, SVGIcon } from 'ui-components';
import { primaryBlue, primaryGreyFive, white, primaryGrey } from 'styles/colors';
import { trackEvent } from 'common/eventTracker';

import { MODE_FILTERS } from 'common/modeFilters';
import { shipmentListFiltersUrlEnabled } from 'common/authorizations';
import { getGreeting } from '../../../common/dateUtils';
import AddEditQuickview from '../../shipmentCounts/AddEditQuickview/AddEditQuickview';
import constants from '../../shipmentCounts/ducks/constants';
import FilterUtil from '../../../components/filter/util/filterUtil';
import { QuickviewEntityType } from '../../QuickViewCardConfig/constants';

const StyledButton = styled('button')`
  border: 0 none;
  color: ${primaryBlue};
  cursor: pointer;
  font-weight: 500;
  padding: 0;
  background: none;
  line-height: inherit;

  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  .icon {
    background: ${primaryGreyFive};
    border-radius: 100px;
    height: 32px;
    margin-left: 8px;
    width: 32px;
    position: relative;

    & > * {
      font-size: 14px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const StyledContainer = styled('div')`
  background: ${white};
  box-shadow: 0px 1px 2px rgba(45, 41, 38, 0.5);
  padding: 32px 32px 40px;
  margin-top: 32px;
`;

const StyledParagraph = styled('p')`
  color: ${primaryGrey};
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`;

const StyledSVGIcon = styled(SVGIcon)`
  font-size: 16px;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 14px;
`;

const ShipmentSubHeader = (props) => {
  const {
    executeWidgetLinkClickFn,
    authorities,
    openQuickViewDialog,
    currentTile,
    saveUpdatedLayout,
    quickViewConfig,
    authorizations,
    isQuickviewV2Enabled,
  } = props;

  const history = useHistory();

  const viewAllHappeningToday = useCallback(() => {
    const modeFilter =
      authorizations.getAuthorizedModes().length > 1
        ? MODE_FILTERS.ALL
        : get(authorizations.getAuthorizedModes(), '[0]');

    const constantsFilter = isQuickviewV2Enabled
      ? constants.ALL_MODE_IN_PROGRESS_FILTER
      : constants.HAPPENING_TODAY_FILTER;

    const filterTranslationForSingleMode =
      authorizations.getAuthorizedModes().length === 1 && FilterUtil.translateStatusFiltersByMode(modeFilter);

    const singleModeFilterTranslation = filterTranslationForSingleMode
      ? { ...constantsFilter, status: filterTranslationForSingleMode }
      : constantsFilter;

    if (shipmentListFiltersUrlEnabled()) {
      const queryURL = FilterUtil.buildQueryURl(singleModeFilterTranslation, [], [modeFilter]);
      if (queryURL) history.push(`/shipment-list${queryURL}`);
      window.sessionStorage.setItem('isShipmentInProgress', 'true');
    } else {
      executeWidgetLinkClickFn(authorities, constantsFilter, modeFilter, '');
    }
  }, [authorizations, isQuickviewV2Enabled, history, executeWidgetLinkClickFn, authorities]);

  const updateQuickView = useCallback(() => {
    saveUpdatedLayout(quickViewConfig);
  }, [saveUpdatedLayout, quickViewConfig]);

  const savedMoment = moment();

  const greeting = getGreeting(savedMoment).id ? (
    <FormattedMessage id={getGreeting(savedMoment).id} defaultMessage={getGreeting(savedMoment).defaultMessage} />
  ) : (
    getGreeting(savedMoment)
  );

  // maybe we could just pass addQuickView as the callback
  const onClickDropdownMenuItem = ({ key }) => {
    addQuickView(key);
  };

  const addQuickView = useCallback(
    (key) => {
      trackEvent('QV_ADD_CARD');

      if (isQuickviewV2Enabled) {
        history.push({ pathname: '/configure-quickview/subtotal', search: qs.stringify({ entityType: key }) });
        openQuickViewDialog('Add', currentTile, isQuickviewV2Enabled);
      } else {
        openQuickViewDialog('Add', currentTile, isQuickviewV2Enabled);
      }
    },
    [isQuickviewV2Enabled, history, openQuickViewDialog, currentTile]
  );

  // TODO what should the exact auth logic be here? MainMenu uses hasAdvancedTrackingLinking()
  // for all three menu options. routes.js uses hasEnableOrderInventoryV2()
  const quickViewDropdownMenu = (
    <Menu onClick={onClickDropdownMenuItem}>
      <Menu.Item key={QuickviewEntityType.SHIPMENTS}>
        <FormattedMessage id="shipmentList.shipmentListHeader.listheader" defaultMessage="Shipments" />
      </Menu.Item>
      {authorizations.hasAdvancedTrackingLinking() && (
        <>
          <Menu.Item key={QuickviewEntityType.ORDERS}>
            <FormattedMessage id="global.navigation.tracking.submenu.orders" defaultMessage="Orders" />
          </Menu.Item>
          <Menu.Item key={QuickviewEntityType.INVENTORY}>
            <FormattedMessage id="global.navigation.tracking.submenu.inventory" defaultMessage="Inventory" />
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const dropdown = (
    <Dropdown placement="bottomCenter" overlay={quickViewDropdownMenu}>
      <Button className="ml-4" data-locator="add-quick-view-button">
        <i className="material-icons left">add</i>
        <span className="mr-2">
          <FormattedMessage id="shipmentCounts.addQuickView" defaultMessage="Add Quick View" />
        </span>
        <DownOutlined />
      </Button>
    </Dropdown>
  );

  const onEditConfig = () => {
    trackEvent('QV_CONFIGURE_PAGE');
    props.editConfig();
  };

  return (
    <div className="sub-header container">
      <Row className="align-items-center">
        <Col md={'auto'} className="add-quick-view-wrapper">
          <div className="quick-view-title" data-locator="quick-view-title">
            <div className="quick-view-greeting">
              {greeting}, {props.principal.firstName}
            </div>
          </div>
        </Col>
        <Col>
          <div className="d-flex justify-content-between align-items-center pt-2 pt-md-0 pb-4 pb-md-0">
            {!props.showOnlyTitle && props.editing && (
              <div>
                {isQuickviewV2Enabled ? (
                  dropdown
                ) : (
                  <>
                    <Button
                      onClick={addQuickView} // eslint-disable-line react/jsx-no-bind
                      data-locator="add-quick-view-button"
                    >
                      <i className="material-icons left">add</i> Add Quick View
                    </Button>

                    <AddEditQuickview
                      modeOptions={props.modeOptions}
                      selectedModeOption={props.selectedModeOption}
                      onModeSelect={props.setQuickViewMode}
                      open={props.dialogOpen}
                      cancel={props.cancelQuickViewDialog}
                      // eslint-disable-next-line react/jsx-no-bind
                      save={props.saveQuickViewDialog} // eslint-disable-line react/jsx-no-bind
                      updatedFilter={props.updatedFilter}
                      modeFilters={props.modeFilters}
                      selectedMode={props.selectedMode}
                      mode={props.mode}
                      config={props.currentTile}
                      delete={props.deleteQuickViewTile}
                      tileIndex={props.tileIndex}
                      errors={props.errors}
                      changeSelectedIcon={props.changeSelectedIcon} // eslint-disable-line react/jsx-no-bind
                      // eslint-disable-next-line react/jsx-no-bind
                      changeSelectedDate={props.changeSelectedDate} // eslint-disable-line react/jsx-no-bind
                      setInputValue={props.setInputValue} // eslint-disable-line react/jsx-no-bind
                      authorities={props.authorities}
                      quickViewConfig={props.quickViewConfig}
                      isQuickviewV2Enabled={isQuickviewV2Enabled}
                    />
                  </>
                )}
              </div>
            )}
            {!props.showOnlyTitle && !props.editing && (
              <div className="flex-row d-flex align-items-end flex-grow-1 justify-content-end">
                <Button
                  type="link"
                  clickFn={onEditConfig}
                  className="d-flex align-items-center justify-content-center"
                  data-locator="configure-quick-view-button"
                >
                  <div className="configure-view-link">
                    <FormattedMessage id="landing.quickView.configureButton" defaultMessage="Configure this page" />
                  </div>
                  {isQuickviewV2Enabled ? (
                    <StyledSVGIcon name="settings" className="ml-2" />
                  ) : (
                    <i className="material-icons left ml-1">settings</i>
                  )}
                </Button>
              </div>
            )}
            {!props.showOnlyTitle && props.editing && (
              <div className="col flex-row flex-grow-1 d-flex align-items-end justify-content-end">
                <Button
                  className="mr-2"
                  type="secondary"
                  clickFn={props.cancelEditConfig} // eslint-disable-line react/jsx-no-bind
                >
                  <FormattedMessage id="landing.quickView.cancel" defaultMessage="Cancel" />
                </Button>
                <Button
                  type="primary"
                  theme={props.theme}
                  aria-pressed={false}
                  clickFn={updateQuickView} // eslint-disable-line react/jsx-no-bind
                  data-locator="quick-view-update-button"
                >
                  <FormattedMessage id="landing.quickView.update" defaultMessage="Update" />
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
      {isQuickviewV2Enabled ? (
        <StyledContainer>
          <div className="d-flex justify-content-between">
            <StyledParagraph className="mb-0">
              <FormattedMessage
                id="landing.quickView.shipmentInProgress"
                values={{
                  happeningTodayCounts: props.happeningTodayCounts.toLocaleString(undefined, { useGrouping: true }),
                }}
                defaultMessage="You have {happeningTodayCounts} shipments in progress."
              />
            </StyledParagraph>
            <StyledButton
              type="link"
              onClick={viewAllHappeningToday}
              data-locator="happening-today-quick-link"
              disabled={props.happeningTodayCountsLoading}
            >
              <div className="d-flex align-items-center uppercase whitespace-no-wrap">
                <FormattedMessage id="landing.quickView.viewAll" defaultMessage="View All" />
                <div className="icon">
                  {props.happeningTodayCountsLoading ? (
                    <Spin indicator={<StyledLoadingOutlined spin />} />
                  ) : (
                    <SVGIcon name="chevron-right" />
                  )}
                </div>
              </div>
            </StyledButton>
          </div>
        </StyledContainer>
      ) : (
        <div className="add-quick-view-wrapper">
          <div className="quick-view-title">
            <div>
              <span className="quick-view-today-count">
                <FormattedMessage
                  id="landing.quickView.todayCount"
                  values={{
                    happeningTodayCounts: props.happeningTodayCounts.toLocaleString(undefined, { useGrouping: true }),
                  }}
                  defaultMessage="You have {happeningTodayCounts} active shipments today."
                />
              </span>
              <Button
                type="link"
                className="quick-view-link"
                onClick={viewAllHappeningToday}
                data-locator="happening-today-quick-link"
              >
                <FormattedMessage id="landing.quickView.viewAll" defaultMessage="View All" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShipmentSubHeader;
