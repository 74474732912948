import { useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import get from 'lodash/get';
import styled from 'styled-components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { primaryBlue } from 'styles/colors';

import {
  PrincipalAuthorizations,
  shipmentListFiltersUrlEnabled,
  getPrincipalAuthorizations,
  shouldUseRecentSSS,
} from 'common/authorizations';
import { Tooltip } from 'ui-components';
import sortTypes from '../common/sortResults/ducks/types';
import ShipmentExportComponent from './ShipmentExportComponent';
import SortResultsContainer from '../common/sortResults/SortResultsContainer';
import generateFilterDescription from './utils/generateFilterDescription';

const messages = defineMessages({
  showingShipmentWithQuery: {
    id: 'shipmentList.appliedFilterList.showingShipmentWithQuery',
    defaultMessage: '{ results, number } { results, plural, =1 { result } other { results }} for { searchQuery }',
  },
  showingShipmentWithoutQuery: {
    id: 'shipmentList.appliedFilterList.showingShipmentWithoutQuery',
    defaultMessage: '{ results, number } { results, plural, =1 { result } other { results }}',
  },
});

const StyledMultiFieldButton = styled('button')`
  border: none;
  background: none;
  padding: 0;
  color: ${primaryBlue};
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
`;

export const DisplayedResults = ({
  props,
  searchQuery,
  totalResultsCount,
}: {
  searchQuery: string;
  totalResultsCount: number;
  isRecentDataSearched: () => boolean;
  props: any;
}) => {
  const enableMultiValueSearch = get(props, 'previousSearchQuery.enableMultiValueSearch');
  const isMuliValueSearchModalOpen = get(props, 'isMuliValueSearchModalOpen');
  const setIsMuliValueSearchModalOpen = get(props, 'setIsMuliValueSearchModalOpen');
  const isRecentDataSearched = props.isRecentDataSearched();
  const showToolTipForRecentSearch = isRecentDataSearched !== true ? isRecentDataSearched : shouldUseRecentSSS;

  const title = () => {
    if (enableMultiValueSearch) {
      return (
        <>
          <span>
            {props.intl.formatMessage(messages.showingShipmentWithQuery, {
              results: totalResultsCount,
              searchQuery: ``,
            })}
          </span>
          <StyledMultiFieldButton onClick={() => setIsMuliValueSearchModalOpen(!isMuliValueSearchModalOpen)}>
            <FormattedMessage defaultMessage="Multi-value Search" id="searchBar.multiValue.linkText" />
          </StyledMultiFieldButton>
        </>
      );
    }
    if (searchQuery) {
      return props.intl.formatMessage(messages.showingShipmentWithQuery, {
        results: totalResultsCount,
        searchQuery: `"${searchQuery}"`,
      });
    }
    return props.intl.formatMessage(messages.showingShipmentWithoutQuery, { results: totalResultsCount });
  };

  const shouldDisplayShipmentExport = !props.hasCarrierRole;

  const principal = useContext(PrincipalContext);
  const authorizations = new PrincipalAuthorizations({
    authorities: get(principal, 'authorities') || [],
    principal,
  });

  const principalContext = useContext(PrincipalContext);
  const principalAuthorizations = getPrincipalAuthorizations(principalContext);
  const isShipmentListFiltersHorizontalEnabled = principalAuthorizations.shipmentListFiltersHorizontalEnabled();

  const isEuEnvironment = authorizations.isEUEnvironment();
  const isShipmentListFiltersUrlEnabled = shipmentListFiltersUrlEnabled();

  return (
    <div className="AppliedFilterList align-items-center">
      <Row className="align-items-center mb-3">
        <Col xs={24} sm={12}>
          <h3
            className={`d-md-inline-block mb-0 ${enableMultiValueSearch ? '' : 'mr-3'}`}
            data-locator="number-of-results"
          >
            {title()}
          </h3>
          {showToolTipForRecentSearch && (
            <span className={'mr-2'}>
              <Tooltip
                title={
                  <FormattedMessage
                    id="shipmentList.tooltips.displayedResultsForThreeMonths"
                    defaultMessage="Showing recent results from the past three months."
                  />
                }
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          )}
          {shouldDisplayShipmentExport && (
            <div className={`${enableMultiValueSearch ? 'd-block' : 'd-inline-block'}`}>
              <ShipmentExportComponent
                sort={get(sortTypes.SHIPMENT_SORT_FIELDS, ['props', 'selectedSortFieldId', 'request[0]', 'fieldName'])}
                sortDir={get(sortTypes.SHIPMENT_SORT_FIELDS, [
                  'props',
                  'selectedSortFieldId',
                  'request[0]',
                  'direction',
                ])}
                searchQuery={searchQuery}
                filters={props.filter}
                totalResultsCount={totalResultsCount}
                modeFilters={props.modeFilters}
                allowedModes={props.allowedModes}
                previousSearchQuery={props.previousSearchQuery}
                isRecentDataSearched={isRecentDataSearched}
              />
            </div>
          )}
        </Col>

        <Col xs={24} sm={12} className="filter-sort-container">
          <div className="sort-component d-block">
            <SortResultsContainer
              sortFields={
                isEuEnvironment
                  ? sortTypes.SHIPMENT_SORT_FIELDS_EU_INTL(props.intl)
                  : sortTypes.SHIPMENT_SORT_FIELDS_INTL(props.intl)
              }
              sortType={sortTypes.SHIPMENT_SORT}
              shipmentListFiltersUrlEnabled={isShipmentListFiltersUrlEnabled}
              filtersHorizontalEnabled={props.filtersHorizontalEnabled}
            />
          </div>
          {!isShipmentListFiltersHorizontalEnabled && (
            <div key="RefineButton" className="refine-button-container d-lg-none">
              {generateFilterDescription(props.filter, props.modeFilters)}
              {/*eslint-disable-next-line react/jsx-no-bind*/}
              <button className="filter-tunnel-button" onClick={props.toggleFilters}>
                <div className="filter-funnel-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <path id="a" d="M0 0h14.5v1.611h-.064L8.86 7.186v9.658L5.64 13.622V7.178L.072 1.61H0z" />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                      <path fill="#000" fillOpacity="0" d="M0 0h24v24H0z" />
                      <g transform="translate(5 4)" fill="#000">
                        <mask id="b">
                          <use xlinkHref="#a" />
                        </mask>
                        <use fillRule="nonzero" xlinkHref="#a" />
                        <g mask="url(#b)">
                          <path d="M-5-4h24v24H-5z" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DisplayedResults;
