import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import axios from 'util/paxios';
import { Page } from 'models';
import { buildUrlWithPaginationParams } from 'models/page-helper';
import { InventoryOrder } from 'components/Inventory/models';
import { API_PATH } from 'common/AppConstants';
import endpoints from 'common/endpoints';

interface InventoryOrdersHook {
  hasPermission: boolean;
  defaultPageSize?: number;
  inventoryOrderId?: string;
}

export enum DataLoadingEnum {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR_LOADING = 'ERROR_LOADING',
}

export function useRelatedOders({ hasPermission, inventoryOrderId, defaultPageSize = 30 }: InventoryOrdersHook) {
  const [orders, setOrders] = useState<Page<InventoryOrder>>();
  const [ordersDataLoading, setOrdersDataLoading] = useState<DataLoadingEnum>(DataLoadingEnum.LOADING);

  const searchOrders = (page: number, size: number) => {
    setOrdersDataLoading(DataLoadingEnum.LOADING);

    getRelatedOrders(page, size);
  };

  const getRelatedOrders = useCallback(
    async function getRelatedOrders(pageNumber: number, pageSize: number) {
      try {
        let url = new URL(`${API_PATH}${endpoints.GET_RELATED_ORDERS(inventoryOrderId)}`, window.location.origin);
        url = buildUrlWithPaginationParams(url, { pageNumber, pageSize });

        const response: AxiosResponse<Page<InventoryOrder>> = await axios.get(url.toString(), {
          withCredentials: true,
        });

        setOrders(response.data);
        setOrdersDataLoading(DataLoadingEnum.LOADED);
      } catch (error) {
        setOrdersDataLoading(DataLoadingEnum.ERROR_LOADING);
      }
    },
    [inventoryOrderId]
  );

  useEffect(() => {
    if (hasPermission) {
      setOrdersDataLoading(DataLoadingEnum.LOADING);

      getRelatedOrders(1, defaultPageSize);
    }
  }, [getRelatedOrders, hasPermission, defaultPageSize]);

  return {
    ordersDataLoading,
    orders,
    searchOrders,
  };
}
