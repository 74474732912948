import { DownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import * as React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import qs from 'qs';

import { MODE_FILTERS } from 'common/modeFilters';
import { API_PATH } from 'common/AppConstants';
import { shouldUseRecentSSS } from 'common/authorizations';
import { ShipmentModeEnum } from '../../models';
import * as styles from './ShipmentExportComponent.module.scss';
import FilterUtil from '../../components/filter/util/filterUtil';
import axios from '../../util/paxios';

interface ShipmentExportComponentProps extends WrappedComponentProps {
  searchQuery?: string;
  sortDir?: 'DESC' | 'ASC';
  sort?: string;
  modeFilters: ShipmentModeEnum[] | Array<'ALL'>;
  filters: any;
  totalResultsCount: number;
  allowedModes?: any[];
  previousSearchQuery: any;
  isRecentDataSearched: boolean;
}

const ShipmentExportComponent: React.FunctionComponent<ShipmentExportComponentProps> = (props) => {
  const {
    filters,
    modeFilters,
    searchQuery,
    sort,
    sortDir,
    totalResultsCount,
    intl,
    allowedModes = [],
    previousSearchQuery,
    isRecentDataSearched,
  } = props;
  const showTooltip = totalResultsCount > 3000;
  const attribute = get(filters, 'attribute');

  const ignoreEmpty = (result: any, value: any, key: any) => {
    const getValue = () => {
      if (isArray(value)) return isEmpty(value) ? undefined : value;
      if (isNil(value)) return undefined;
      return value;
    };

    return {
      ...result,
      [key]: getValue(),
    };
  };

  const oceanDemurrageEligibleFilter = get(filters, 'oceanDemurrageEligible');
  const oceanDemurrageEligible = FilterUtil.getOceanDemurrageEligibleFilterValue(oceanDemurrageEligibleFilter);
  const filtersCleaned = reduce(omit({ ...filters, oceanDemurrageEligible }, ['attribute']), ignoreEmpty, {});
  const recent = shouldUseRecentSSS ? { recent: isRecentDataSearched } : {};

  const params = {
    filter: filtersCleaned,
    sort,
    sortDir,
    search: searchQuery || undefined,
    modes: isEmpty(modeFilters) ? undefined : modeFilters.join(','),
    enableMultiValueSearch: previousSearchQuery.enableMultiValueSearch ? true : undefined,
    ...recent,
  };

  const qsParams = qs.stringify(params, { arrayFormat: 'repeat', allowDots: true });
  const attributeKeyValuePairs = attribute && FilterUtil.generateAttributeKeyValuePairs(attribute);
  const mode = modeFilters[0];

  const queryParams = isEmpty(attributeKeyValuePairs)
    ? qsParams
    : `${qsParams}&${qs.stringify({ attributeKeyValuePairs: JSON.stringify(attributeKeyValuePairs) })}`;

  return showTooltip ? (
    <DisabledLink
      message={intl.formatMessage({
        id: 'shipmentList.exportData.tooManyRecords',
        defaultMessage: 'You can only download a max 3000 records from a single mode.',
      })}
    />
  ) : (
    <EnabledLink queryParams={queryParams} mode={mode} allowedModes={allowedModes} requestBody={params} />
  );
};

ShipmentExportComponent.defaultProps = {
  filters: {},
  modeFilters: ['ALL'],
};

const buttonStyle = {
  border: 'none',
};

const exportShipments = async (payload: any) => {
  const response = await axios({
    method: 'POST',
    url: `${API_PATH}/shipment/search/export`,
    data: payload,
    withCredentials: true,
  });
  return response;
};

const createLink = (data: any, mode: string) => {
  const link = document.createElement('a');
  const blob = new Blob(['\ufeff', data]);
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `shipments${mode}.csv`);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  return link;
};

const DisabledLink = ({ message }: { message: string }) => (
  <Tooltip title={message}>
    <span className={`${styles.link} ${styles.disabledLink}`}>
      <FormattedMessage id="shipmentList.exportData.downloadCSV" defaultMessage="Download CSV">
        {(...text) => (
          <span className={styles.downloadCSV}>
            {text} <DownloadOutlined style={{ fontSize: '24px' }} />
          </span>
        )}
      </FormattedMessage>
    </span>
  </Tooltip>
);

const downloadFiles = (listRequests: any[]) => {
  listRequests.forEach((payload) => {
    setTimeout(async () => {
      const response = await exportShipments(payload);
      if (response.data.split(/\n/).length > 2) {
        const link = createLink(response.data, payload.modes[0]);
        link.click();
      }
    }, 300);
  });
};

const ModeDownloadComponent = ({
  requestBody,
  allowedModes,
  mode,
}: {
  requestBody: any;
  allowedModes: any[];
  mode: string;
}) => {
  const getRequestList = (filteredAllowedModes: any[], isMultiModal: boolean) => {
    let listRequests: any[] = [];
    if (isMultiModal) {
      listRequests = filteredAllowedModes.map((mode) => {
        const requestBodyInstance = { ...requestBody };
        requestBodyInstance.modes = [mode === 'TRUCKLOAD' ? MODE_FILTERS.TRUCKLOAD : mode];
        requestBodyInstance.multiModal = isMultiModal;
        return requestBodyInstance;
      });
    } else {
      const requestBodyInstance = { ...requestBody };
      requestBodyInstance.modes = [mode === 'TRUCKLOAD' ? MODE_FILTERS.TRUCKLOAD : mode];
      requestBodyInstance.multiModal = isMultiModal;
      listRequests = [requestBodyInstance];
    }
    return listRequests;
  };

  const onModeDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    // not allowed modes for files download
    const notAllowedModes = [MODE_FILTERS.ALL];
    const isMultiModal = mode === MODE_FILTERS.ALL || !mode;

    // get all mode filters and filter out the not allowed ones
    const filteredAllowedModes = allowedModes
      .map((mode) => mode.value)
      .filter((mode) => !notAllowedModes.includes(mode));

    const listRequests: any[] = getRequestList(filteredAllowedModes, isMultiModal);

    downloadFiles(listRequests);
  };

  return (
    <button className={`${styles.link}`} style={buttonStyle} onClick={onModeDownload}>
      <FormattedMessage id="shipmentList.exportData.downloadCSV" defaultMessage="Download CSV">
        {(...text) => (
          <span className={styles.downloadCSV}>
            {text} <DownloadOutlined style={{ fontSize: '24px' }} />
          </span>
        )}
      </FormattedMessage>
    </button>
  );
};

const EnabledLink = ({
  queryParams,
  mode,
  allowedModes,
  requestBody,
}: {
  queryParams: string;
  mode: string;
  allowedModes: any[];
  requestBody: any;
}) => {
  return <ModeDownloadComponent requestBody={requestBody} allowedModes={allowedModes} mode={mode} />;
};

export default injectIntl(ShipmentExportComponent);
