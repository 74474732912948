export function chunk(list: any[], noOfChunks: number = 2): any[] {
  if (!list.length) {
    return [];
  }
  const chunkSize = Math.floor(list.length / noOfChunks) + (list.length % noOfChunks === 0 ? 0 : 1);

  const chunks = [];
  for (let i = 0, j = list.length; i < j; i += chunkSize) {
    chunks.push(list.slice(i, i + chunkSize));
  }

  return chunks;
}
