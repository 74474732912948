import styled from 'styled-components';
import { Breadcrumb, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { Dropdown } from 'ui-components';
import { primaryGrey, primaryBlue, dustGray, black } from 'styles/colors';
import { SortField } from 'models';
import { SortDirection } from 'models/SortDirection';
import { ReactComponent as MapModeIcon } from 'components/common/assets/map-mode-icon.svg';
import { ReactComponent as TableModeIcon } from 'components/common/assets/table-mode-icon.svg';
import { ReactComponent as DownloadCsvIcon } from 'components/common/assets/download-csv-icon.svg';
import { trackEvent } from 'common/eventTracker';
// import { ReactComponent as AnalyticsIcon } from 'components/common/assets/analytics-icon.svg';

export interface MenuItem {
  key: number;
}

export interface SortItem {
  displayName: string;
  fieldName: string;
  sortDirection: SortDirection;
}

export const StyledBreadcrumb = styled('div')`
  margin: 20px 0;
`;

export const StyledMain = styled('div')`
  flex: 1;
  overflow-x: hidden;
`;

export const StyledH1 = styled('h1')`
  font-size: 34px;
  line-height: 1;
  color: ${primaryGrey};
`;

export const StyledLink = styled('button')`
  background: none;
  border: 0 none;
  color: ${primaryBlue};
  font-size: 14px;
  font-weight: normal;
  padding: 0;
  margin-left: 15px;
`;

export const Divider = styled('div')`
  background-color: ${dustGray};
  display: inline-flex;
  height: 39px;
  width: 1px;
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    border-radius: 4px !important;
  }
`;

export const StyledContainer = styled('div')`
  padding: 0 32px;
`;

export const StyledAside = styled('div')`
  flex-shrink: 0;
  max-width: 260px;
  margin-right: 24px;
  width: 100%;
`;

export const StyledAsideTitle = styled('div')`
  color: ${black};
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;

export const ToolBar = () => (
  <div className="d-flex align-items-center">
    <StyledButton className="d-inline-flex align-items-center bg-transparent mr-2" disabled>
      <MapModeIcon />
      <span className="ml-2">Map</span>
    </StyledButton>
    <StyledButton className="d-inline-flex align-items-center bg-transparent mr-4" disabled>
      <TableModeIcon />
      <span className="ml-2">
        <FormattedMessage id="listPageLayout.toolbar.table" defaultMessage="Table" />
      </span>
    </StyledButton>
    <Divider className="mr-4" />
    <StyledButton className="d-inline-flex align-items-center" type="primary" disabled>
      <DownloadCsvIcon />
      <span className="ml-2">
        <FormattedMessage id="listPageLayout.toolbar.csvExport" defaultMessage="Download CSV" />
      </span>
    </StyledButton>
  </div>
);

interface ListPageLayoutInput {
  entityType: any;
  breadcrumbComponent: any;
  filterComponent?: any;
  countTitleComponent: any;
  exportComponent?: any;
  tableComponent: any;
  sortItems: SortItem[];
  sortAttr: any;
  setSortAttr: any;
  setSortArr: any;
}

const ListPageLayout = ({
  entityType,
  breadcrumbComponent,
  filterComponent,
  countTitleComponent,
  exportComponent,
  tableComponent,
  sortItems,
  sortAttr,
  setSortAttr,
  setSortArr,
}: ListPageLayoutInput) => {
  return (
    <StyledContainer>
      <StyledBreadcrumb>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`/`}>
              <FormattedMessage id="listPageLayout.breadCrumb.dashboard" defaultMessage="Dashboard" />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{breadcrumbComponent}</Breadcrumb.Item>
        </Breadcrumb>
      </StyledBreadcrumb>
      <div className="d-flex">
        <StyledAside>
          <StyledAsideTitle>
            <FormattedMessage id="listPageLayout.filters.refine" defaultMessage="Refine" />
          </StyledAsideTitle>
          {filterComponent}
        </StyledAside>
        <StyledMain>
          <div className="d-flex justify-content-between align-items-center">
            <StyledH1>
              {countTitleComponent}
              {/* <StyledLink disabled>
                <span className="mr-2">
                  <AnalyticsIcon />
                </span>
                <FormattedMessage id="listPageLayout.header.analytics" defaultMessage="View Analytics" />
              </StyledLink> */}
            </StyledH1>
            {/* <ToolBar /> */}
            {exportComponent}
          </div>
          <div className="d-flex align-items-center mb-2">
            <span className="mr-2">
              <FormattedMessage id="listPageLayout.filters.sort" defaultMessage="Sort" />
            </span>
            <Dropdown
              value={sortAttr}
              origin={'bottomRight'}
              dropdownItems={sortItems}
              selectedColor={`--primary-grey`}
              className="d-inline-flex"
              handleChange={(menuItem: MenuItem) => {
                // need to parse as string otherwise we get a typescript check error
                const menuItemKey = parseInt(menuItem.key.toString());
                trackEvent(`${entityType}_LIST_SORT`, {
                  key: get(sortItems[menuItemKey], 'displayName'),
                });
                const currentItem = sortItems[menuItem.key];

                setSortAttr(menuItem.key);

                const sortItem: SortField<string> = {
                  fieldName: currentItem.fieldName,
                  sortDirection: currentItem.sortDirection,
                };

                setSortArr([sortItem]);
              }}
            />
          </div>
          {tableComponent}
        </StyledMain>
      </div>
    </StyledContainer>
  );
};

export default ListPageLayout;
