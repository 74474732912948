import { defineMessages, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { change } from 'redux-form';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import has from 'lodash/has';
import omit from 'lodash/omit';
import styled from 'styled-components';
import { Select, InputNumber } from 'antd';
import { primaryBlue } from 'styles/colors';

import { Radio, AutoComplete, Input } from 'ui-components';
import { intl } from 'common/AppConstants';

import CloseIcon from '../../common/assets/icons/icon-close.svg';
import * as constants from '../ducks/constants';
import { withTheme } from '../../../contexts/ThemeContext';

const message = defineMessages({
  selectCriteria: {
    id: 'notifications.addEditFormInputs.selectCriteria',
    defaultMessage: 'Select a criteria',
  },
  enterType: {
    id: 'notifications.addEditFormInputs.enterType',
    defaultMessage: 'Enter {type}(s)',
  },
});

export function RenderSingleSelectDropdown({
  dropdownItems = [],
  input: { value: inputValue, onChange },
  onBlur,
  placeholder,
  validator,
  defaultValue,
  dropdownClassName,
  ...custom
}) {
  // Set the value in the form to the defaultValue, if it was provided
  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
  }, [defaultValue, onChange]);

  return (
    <Select
      style={{ width: '100%' }}
      placeholder={placeholder}
      mode="default"
      value={inputValue || defaultValue}
      onChange={onChange}
      dropdownItems={dropdownItems}
      onBlur={(event) => {
        if (onBlur) {
          onBlur(event);
        }
      }}
      defaultValue={defaultValue}
      dropdownClassName={dropdownClassName}
      {...custom}
    >
      {dropdownItems.map(({ value, displayName, ...customOptionProps }) => (
        <Select.Option key={value} value={value} data-locator="notification-menu-item" {...customOptionProps}>
          {displayName}
        </Select.Option>
      ))}
    </Select>
  );
}

export const renderAutoComplete = ({
  currentParameters,
  queryAutocompleteOptions,
  dataSource,
  queryType,
  selectedMode,
  input: { onChange },
  ...customProps
}) => {
  const placeholder = get(currentParameters, 'type')
    ? `${intl.formatMessage(message.enterType, { type: currentParameters.type.toLowerCase() })}`
    : intl.formatMessage(message.selectCriteria);
  return (
    <AutoComplete
      {...customProps}
      id="test-field-search"
      placeholder={placeholder}
      dataSource={dataSource || []}
      filter={AutoComplete.caseInsensitiveFilter}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      onSelect={(value) => {
        // this should usually require a redux-form dispatch change but it seems to work this way..
        currentParameters.values = [...currentParameters.values, value];
        onChange('');
      }}
      onSearch={(value) => {
        queryAutocompleteOptions(constants.SUGGESTIONS_MAP[currentParameters.type], value, queryType, selectedMode);
      }}
    />
  );
};

export function RenderTextField({
  input: { onChange, value, onFocus, onBlur },
  meta: { error, active, touched },
  currentParameters,
  prefix,
  validate,
  ...customProps
}) {
  const intl = useIntl();
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setHasError(touched && !!error && value.length > 0);
  }, [error, value, active, touched]);

  const onPressEnter = () => {
    if (error) {
      return;
    }

    const val = value.trim();
    if (val.length > 0 && !hasError) {
      const prefixedVal = prefix ? `${prefix}${val}` : val;
      if (currentParameters && has(currentParameters, 'values')) {
        currentParameters.values = [...currentParameters.values, prefixedVal];
        onChange('');
      }
      setHasError(false);
    }
  };

  return (
    <Input
      {...omit(customProps, 'intl')}
      hasError={hasError}
      errorMessage={error && error.id ? intl.formatMessage(error) : error}
      onPressEnter={onPressEnter}
      onBlur={() => {
        onBlur && onBlur();
        !hasError && onPressEnter();
      }}
      onFocus={onFocus}
      onChange={onChange}
      value={value}
    />
  );
}

export const RenderCriteriaChips = ({ currentField, dispatch, formPath }) => {
  return currentField.map((type, index) => (
    <div
      className="notification-chip__container"
      key={`target-type-chip-${index}`} //eslint-disable-line react/no-array-index-key
    >
      <span>{type}</span>
      <button
        className="notification-chip__delete-btn plainWrapperButton"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          //eslint-disable-line react/jsx-no-bind
          const updatedFields = isArray(currentField) ? [...currentField] : [currentField];
          updatedFields.splice(index, 1);
          dispatch(change('AddEditNotificationForm', formPath, updatedFields));
        }}
      >
        <img src={CloseIcon} className="notification-chip__close-icon" color="#ffffff" alt="close" />
      </button>
    </div>
  ));
};

export const renderMultiSelect = ({ currentParameters }) => {
  // this select is always hidden, only used for binding values to redux form
  return (
    <select multiple className="d-none">
      {(currentParameters.values || []).map((param) => (
        <option key={param} value={param}>
          {param}
        </option>
      ))}
    </select>
  );
};

const MIN = 0;

export const renderTimeSelector = ({ input, maxValue, ...customProps }) => {
  return (
    <div className="time-selector">
      <InputNumber {...input} type="number" min={MIN} max={maxValue} {...customProps} />
    </div>
  );
};

export const renderModeSelect = withTheme(({ input, modeOptions, resetForm, theme }) => {
  return (
    <Radio
      value={input.value}
      onChange={async (e) => {
        //eslint-disable-line react/jsx-no-bind
        if (resetForm) {
          await resetForm();
        }
        await input.onChange(e);
      }}
      radioData={modeOptions}
      theme={theme}
    />
  );
});

const StyledLink = styled.a`
  color: ${primaryBlue};
`;

export const AddSectionLink = ({ onClick = () => {}, children }) => {
  return (
    <StyledLink
      // eslint-disable-next-line
      href="javascript:void(0)"
      onClick={onClick}
    >
      {children}
    </StyledLink>
  );
};
