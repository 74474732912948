import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'ui-components';

import * as styles from './Reset.module.scss';

export interface ResetProps {
  onReset: () => void;
}

const Reset: React.FunctionComponent<ResetProps> = ({ onReset }) => {
  return (
    <React.Fragment>
      <div id="title" className={styles.title}>
        <FormattedMessage id="settings.brandingTheming.reset.resetTitle" defaultMessage="Reset" />
      </div>
      <div className={styles.buttonAndDescriptionWrapper}>
        <Button type="destructive" clickFn={onReset}>
          <FormattedMessage id="settings.brandingTheming.reset.resetTheming" defaultMessage="Reset Theming" />
        </Button>
        <div className={styles.description}>
          <FormattedMessage
            id="settings.brandingTheming.reset.resetNotificationText"
            defaultMessage="This will remove all custom colors, logo, and email footer text and reset the theme to the project44 default."
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export const ResetComponent = Reset;
export default React.memo(Reset);
