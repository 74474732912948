import get from 'lodash/get';
import isNil from 'lodash/isNil';
import uuid from 'uuid/v4';

import { DataSource, LineItem } from '../../../lineItems/LineItems.model';
import { IdentifierType } from '../../LTL/Identifiers/Identifiers';

export const useShipmentLineItemFormatter = (lineItems: LineItem[]): DataSource[] => {
  const formattedDataSource = lineItems.map((item: LineItem) => {
    const currency = get(item, ['monetaryValue', 'currency']);
    const value = !isNil(item.monetaryValue)
      ? `${get(item, ['monetaryValue', 'amount'], 0).toLocaleString(undefined, {
          style: 'currency',
          currency,
          currencyDisplay: 'symbol',
          minimumFractionDigits: 0,
        })} ${currency}`
      : undefined;
    const deliveryNumber = getPropertyString('DELIVERY_NUMBER', item.itemIdentifiers);
    const PO = getPropertyString('PURCHASE_ORDER', item.itemIdentifiers);
    const sku = getPropertyString('SKU', item.itemIdentifiers);
    const vin = getPropertyString('VIN', item.itemIdentifiers);
    const containerId = getPropertyString('CONTAINER_ID', item.itemIdentifiers);
    const palletNumber = getPropertyString('PALLET_NUMBER', item.itemIdentifiers);

    const subLineItems = formatSublineItems(item.subLineItems || []);
    return {
      key: uuid(),
      ...(item.monetaryValue && { value }),
      commodityCode: item.commodityCode || ' --- ',
      ...(item.freightClass && { freightClass: item.freightClass }),
      itemQuantityAndPackaging: item.itemQuantity,
      itemPackageType: item.itemPackageType,
      // ...(item.itemQuantity && { itemQuantityAndPackaging: `${item.itemQuantity} ${item.itemPackageType}` }),
      companyName: get(item, ['contact', 'companyName'], '') || ' --- ',
      contactName: get(item, ['contact', 'contactName'], '') || ' --- ',
      phone: get(item, ['contact', 'phoneNumber1'], '') || get(item, ['contact', 'phoneNumber2'], '') || ' --- ',
      fax: get(item, ['contact', 'faxNumber'], '') || ' --- ',
      email: get(item, ['contact', 'email'], '') || ' --- ',
      deliveryNumber: deliveryNumber || ' --- ',
      poNumber: PO || ' --- ',
      sku: sku || ' --- ',
      vin: vin || ' --- ',
      ...(subLineItems && { subLineItems }),
      noOfSubLineItems: '' + ((subLineItems && subLineItems.length) || ' --- '),
      containerId: containerId || ' --- ',
      palletNumber: palletNumber || ' --- ',
      description: item.description || ' --- ',
      deliveryStopNumber: '' + (item.deliveryStopNumber || ' --- '),
      pickupStopNumber: '' + (item.pickupStopNumber || ' --- '),
      operation: ' --- ',
      // Pallet number
    };
  });

  function formatSublineItems(subItems: LineItem[]): DataSource[] {
    return subItems.map((item: LineItem) => {
      const currency = get(item, ['monetaryValue', 'currency']);
      const value = !isNil(item.monetaryValue)
        ? `${get(item, ['monetaryValue', 'amount'], '').toLocaleString(undefined, {
            style: 'currency',
            currency,
            currencyDisplay: 'symbol',
            minimumFractionDigits: 0,
          })} ${currency}`
        : undefined;
      const deliveryNumber = getPropertyString('DELIVERY_NUMBER', item.itemIdentifiers);
      const PO = getPropertyString('PURCHASE_ORDER', item.itemIdentifiers);
      const sku = getPropertyString('SKU', item.itemIdentifiers);
      const vin = getPropertyString('VIN', item.itemIdentifiers);
      const containerId = getPropertyString('CONTAINER_ID', item.itemIdentifiers);
      const palletNumber = getPropertyString('PALLET_NUMBER', item.itemIdentifiers);
      return {
        isSubLineItem: true,
        key: uuid(),
        ...(item.monetaryValue && { value }),
        commodityCode: item.commodityCode || ' --- ',
        ...(item.freightClass && { freightClass: item.freightClass }),
        itemQuantityAndPackaging: item.itemQuantity,
        itemPackageType: item.itemPackageType,
        companyName: get(item, ['contact', 'companyName'], '') || ' --- ',
        contactName: get(item, ['contact', 'contactName'], '') || ' --- ',
        phone: get(item, ['contact', 'phoneNumber1'], '') || get(item, ['contact', 'phoneNumber2'], '') || ' --- ',
        fax: get(item, ['contact', 'faxNumber'], '') || ' --- ',
        email: get(item, ['contact', 'email'], '') || ' --- ',
        deliveryNumber: deliveryNumber || ' --- ',
        poNumber: PO || ' --- ',
        sku: sku || ' --- ',
        vin: vin || ' --- ',
        noOfSubLineItems: ' --- ',
        containerId: containerId || ' --- ',
        palletNumber: palletNumber || ' --- ',
        description: item.description || ' --- ',
        deliveryStopNumber: '' + (item.deliveryStopNumber || ' --- '),
        pickupStopNumber: '' + (item.pickupStopNumber || ' --- '),
      };
    });
  }
  return formattedDataSource;
};

function getPropertyString(type: string, identifiers: IdentifierType[] | undefined = []): string {
  return identifiers.reduce((acc, val) => {
    if (val.type === type) {
      return acc ? `${acc}, ${val.value}` : `${val.value}`;
    }
    return acc;
  }, '');
}
