/* eslint-disable no-undef */
import { HereMapsConfig, AerisWeatherConfig } from 'models';
// declare var H: any;

export const buildPlatform = (config: HereMapsConfig): H.service.Platform =>
  new H.service.Platform({
    apikey: config.hereMapsApiKey,
    useHTTPS: true,
    useCIT: false,
  });

export enum MapBehavior {
  DRAGGING = H.mapevents.Behavior.DRAGGING,
  WHEEL_ZOOM = H.mapevents.Behavior.WHEELZOOM,
  DBL_TAP_ZOOM = H.mapevents.Behavior.DBLTAPZOOM,
}

export interface MapInstance {
  behavior: H.mapevents.Behavior;
  layers: H.service.DefaultLayers;
  mapInstance: H.Map;
  mapEvents: H.mapevents.MapEvents;
  ui: H.ui.UI;
  zoomControl?: H.ui.Control;
}

export const defaultZoomConfig = {
  zoom: 5,
  zoomAlignment: H.ui.LayoutAlignment.RIGHT_MIDDLE,
};

export interface ZoomConfig {
  zoom: number;
  zoomAlignment: H.ui.LayoutAlignment;
}

export interface MapConfig {
  zoom?: ZoomConfig;
  behaviors: MapBehavior[];
}

export const defaultMapConfig = {
  zoom: defaultZoomConfig,
  behaviors: [MapBehavior.DBL_TAP_ZOOM, MapBehavior.DRAGGING, MapBehavior.WHEEL_ZOOM],
};

export const addWeatherLayer = (mapInstance: H.Map, config: AerisWeatherConfig) => {
  const tileProvider = new H.map.provider.ImageTileProvider({
    getURL: function (column, row, zoom) {
      const server = (Math.abs(column + row) % 4) + 1;
      return `https://maps${server}.aerisapi.com/${config.apiId}_${config.apiKey}/radar/${zoom}/${column}/${row}/current.png`;
    },
  });
  const tileLayer = new H.map.layer.TileLayer(tileProvider);
  mapInstance.addLayer(tileLayer);
};

export const buildMapInstance = (
  platform: H.service.Platform,
  element: HTMLElement,
  config: MapConfig = defaultMapConfig
) => {
  // Initialize the engine type:
  const engineType = (H.Map.EngineType as any)['HARP'];
  const layers = platform.createDefaultLayers({
    engineType: engineType,
    tileSize: 512,
    pois: true,
    lg: 'en',
  } as any);
  const mapInstance = new H.Map(element, layers.raster.normal.map, { engineType });
  const ui = H.ui.UI.createDefault(mapInstance, layers);
  let zoomControl;
  let scaleBarControl;
  let mapSettingsControl;

  if (config.zoom) {
    zoomControl = ui.getControl('zoom');
    scaleBarControl = ui.getControl('scalebar');
    mapSettingsControl = ui.getControl('mapsettings');
    zoomControl.setDisabled(false);
    zoomControl.setAlignment(H.ui.LayoutAlignment.RIGHT_MIDDLE);
    scaleBarControl.setAlignment(H.ui.LayoutAlignment.RIGHT_BOTTOM);
    mapSettingsControl.setAlignment(H.ui.LayoutAlignment.RIGHT_BOTTOM);
  }
  const group = new H.map.Group();
  const mapEvents = new H.mapevents.MapEvents(mapInstance);
  const behavior = new H.mapevents.Behavior(mapEvents);

  behavior.enable(config.behaviors.reduce((total, currentValue) => total + currentValue, 0));

  return {
    mapInstance,
    zoomControl,
    layers,
    ui,
    mapEvents,
    behavior,
    group,
  };
};
