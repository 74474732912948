import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';

import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Identifier, ShipmentModeEnum, EntitlementInfo, TenantCustomAttribute, ShipmentLegCarrierInfo } from 'models';
import { getPrincipalFromState } from 'common/authorizations';
import { RootState } from '../../../reducers';
import { tenantAttributesActions } from '../ShipmentIdentifiers/ducks';
import ShipmentHeader from './ShipmentHeader';

export interface ShipmentHeaderOwnProps {
  authorizations: any;
  isLoggedIn: boolean;
  mode: ShipmentModeEnum;
  shipmentId?: string;
  shipmentTenantId?: number;
  masterShipmentId?: string;
  shipmentShareToken?: string;
  identifiers: Identifier[];
  useIntermodalEndpoint?: boolean;
  entitlementInfo?: EntitlementInfo;
  tenantCustomAttributes?: TenantCustomAttribute[];
  carrierInfo?: ShipmentLegCarrierInfo[];
  emissionsInMetricTons?: number;
  showEmissions: boolean;
}

export interface ShipmentHeaderProps extends ShipmentHeaderOwnProps, RouteComponentProps<any>, WrappedComponentProps {
  shipmentTenantAttributesEditMode: boolean;
  toggleTenantAttributesMode: () => void;
  principal: any;
}

const mapStateToProps = (state: RootState, ownProps: ShipmentHeaderOwnProps) => {
  return {
    shipmentTenantAttributesEditMode: state.tenantAttributesReducer.shipmentTenantAttributesEditMode,
    principal: getPrincipalFromState(state),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleTenantAttributesMode: () => dispatch(tenantAttributesActions.toggleTenantAttributesMode()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShipmentHeader)));
