import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import { GeoJSONSourceRaw } from 'mapbox-gl';
import geoJsonExtent from 'geojson-extent';
import { useEffect, useState } from 'react';
import axios from 'util/paxios';
import { API_PATH, DEFAULT_SHIPMENT_SOURCES } from 'common/AppConstants';
import endpoints from 'common/endpoints';
import { LandingShipment, LandingShipmentWithCoords } from 'models';
import demoShipments from './demoShipments';
import { MapPopupShipmentData } from './MapPopup/MapPopup';

const IN_TRANSIT_STATUSES = [
  'In Transit to Any Stop',
  'In Transit to Pickup',
  'In Transit to Delivery',
  'Idle In Transit to Any Stop',
  'Idle In Transit to Pickup',
  'Idle In Transit to Delivery',
  'At Any Stop',
  'At Pickup',
  'At Delivery',
];

const DEFAULT_FILTER = {
  // matches filter object in shipment list
  arrivalForecast: [],
  carrier: [],
  company: [],
  createdBy: [],
  attribute: [],
  deliveryDateEnd: null,
  deliveryDateStart: null,
  deliveryStatus: [],
  location: [],
  pickupDateEnd: null,
  pickupDateStart: null,
  status: IN_TRANSIT_STATUSES,
  temperatureTracking: [],
  happeningCode: [],
};

const searchBody = {
  filter: DEFAULT_FILTER,
  resultStartIndex: 0,
  resultSubsetType: 'DASHBOARD_MAP',
  resultsPerPage: 10000,
  sortBy: [{ fieldName: 'createdDate', direction: 'DESC' }],
  shipmentSources: DEFAULT_SHIPMENT_SOURCES,
};

const defaultGeoJson: GeoJSONSourceRaw = {
  type: 'geojson',
  cluster: true,
  clusterRadius: 35,
  clusterMaxZoom: 19,
  data: {
    type: 'FeatureCollection',
    features: [],
  },
};

const formatLocation = (stop: any): string | undefined => {
  if (get(stop, 'location.address.city') !== undefined && get(stop, 'location.address.state') !== undefined) {
    return `${get(stop, 'location.address.city')}, ${get(stop, 'location.address.state')}`;
  } else if (get(stop, 'location.address.state') !== undefined) {
    return get(stop, 'location.address.state');
  } else if (get(stop, 'location.address.city') !== undefined) {
    return get(stop, 'location.address.city');
  }
  return undefined;
};

const filterShipment = (shipment: LandingShipment): shipment is LandingShipmentWithCoords => {
  const lng = get(shipment, 'latestStatusUpdate.locationCoordinatesDto.longitude');
  const lat = get(shipment, 'latestStatusUpdate.locationCoordinatesDto.latitude');

  return !isNaN(lng) && !isNaN(lat) && isNumber(lng) && isNumber(lat);
};

const mapShipmentToFeature = (shipment: LandingShipmentWithCoords) => {
  const origin = shipment.shipmentStops[0];
  const destination = shipment.shipmentStops[shipment.shipmentStops.length - 1];
  // properties can not have nested objects, it converts them to a string
  const properties: MapPopupShipmentData = {
    shipmentId: shipment.shipmentId,
    shipmentMode: shipment.mode,
    identifierType: get(shipment.shipmentIdentifiers[0], 'type', ''),
    identifierValue: get(shipment.shipmentIdentifiers[0], 'value', ''),
    status: shipment.deliveryStatusCode,
    originName: get(origin, 'stopName'),
    originLocation: origin !== undefined ? formatLocation(origin) : undefined,
    destinationName: get(destination, 'stopName'),
    destinationLocation: destination !== undefined ? formatLocation(destination) : undefined,
    lastUpdated: get(shipment, 'latestStatusUpdate.utcTimestamp'),
    entitlementInfo: shipment.entitlementInfo ? JSON.stringify(shipment.entitlementInfo) : undefined,
  };
  return {
    //preventing weird typescript error with mapbox
    type: 'Feature' as 'Feature', // eslint-disable-line
    properties,
    geometry: {
      type: 'Point' as 'Point', // eslint-disable-line
      coordinates: [
        shipment.latestStatusUpdate.locationCoordinatesDto.longitude,
        shipment.latestStatusUpdate.locationCoordinatesDto.latitude,
      ],
    },
  };
};

export const useLandingMapGeoJson = (
  hasDemoVisibility = false
): [GeoJSONSourceRaw, [[number, number], [number, number]] | undefined, boolean] => {
  const [geoJson, setGeoJson] = useState<GeoJSONSourceRaw>(defaultGeoJson);
  const [bounds, setBounds] = useState<[[number, number], [number, number]] | undefined>(undefined);
  const [errorLoadingMapShipments, setErrorLoadingMapShipments] = useState<boolean>(false);

  useEffect(() => {
    async function fetchShipments() {
      try {
        const url = `${API_PATH}${endpoints.SHIPMENT}/search`;
        const response: AxiosResponse<{ results: LandingShipment[] }> = await axios.post(url, searchBody, {
          withCredentials: true,
        });
        const shipments = get(response, ['data', 'results'], [] as LandingShipment[]);
        const features = shipments.filter(filterShipment).map(mapShipmentToFeature);
        const newGeoJson = {
          ...defaultGeoJson,
          data: {
            // preventing weird typescript error with mapbox
            type: 'FeatureCollection' as 'FeatureCollection', // eslint-disable-line
            features: hasDemoVisibility ? [...features, ...demoShipments] : features,
          },
        };
        const newBounds = geoJsonExtent(newGeoJson.data);
        setErrorLoadingMapShipments(false);
        setBounds(
          newBounds === null || newBounds.length !== 4
            ? undefined
            : [
                [newBounds[0], newBounds[1]],
                [newBounds[2], newBounds[3]],
              ]
        );
        setGeoJson(newGeoJson);
      } catch (error) {
        console.error(error); // eslint-disable-line
        setErrorLoadingMapShipments(true);
      }
    }
    fetchShipments();
  }, [hasDemoVisibility]);

  return [geoJson, bounds, errorLoadingMapShipments];
};
