import get from 'lodash/get';
import FilterUtil from 'components/filter/util/filterUtil';

export const mapStateToApi = (data) => {
  const config = get(data, 'config');
  const layouts = get(data, 'layouts');
  const breakpoint = Object.keys(layouts)[0];

  return config.map((tile, index) => {
    let mappedToApi = {
      id: tile.id,
      version: tile.version,
      height: layouts[breakpoint][index].h,
      width: layouts[breakpoint][index].w,
      xPosition: layouts[breakpoint][index].x,
      yPosition: layouts[breakpoint][index].y,
      icon: tile.icon,
      title: tile.title,
      searchKeywords: tile.searchKeywords,
      pickupDate: tile.pickupDate,
      deliveryDate: tile.deliveryDate,
      lastFreeDate: tile.lastFreeDate,
      totalPickupDate: tile.totalPickupDate,
      totalDeliveryDate: tile.totalDeliveryDate,
      totalLastFreeDate: tile.totalLastFreeDate,
      mode: tile.mode,
      totalMode: tile.totalMode,
      totalFilter: tile.totalFilter,
      healthScoreConfig: tile.healthScoreConfig,
      orderFilter: tile.orderFilter,
      totalOrderFilter: tile.totalOrderFilter,
      inventoryFilter: tile.inventoryFilter,
      totalInventoryFilter: tile.totalInventoryFilter,
      numberFormat: tile.numberFormat,
      tenantId: tile.tenantId,
      createdById: tile.createdById,
    };

    // only do this for shipment filters
    if (tile.filter) {
      const oceanDemurrageEligibleFilter = get(tile, 'filter.oceanDemurrageEligible');
      const oceanDemurrageEligible = FilterUtil.getOceanDemurrageEligibleFilterValue(oceanDemurrageEligibleFilter);

      mappedToApi = {
        ...mappedToApi,
        filter: {
          ...tile.filter,
          oceanDemurrageEligible: oceanDemurrageEligible,
        },
      };
    }

    return mappedToApi;
  });
};
