import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import cn from 'classnames';
import { Tooltip } from 'ui-components';
import pencil from './../common/assets/teal-pencil.svg';
import MenuDownArrow from './../common/assets/menu-down.svg';
import './NotificationItem.scss';
import * as constants from './ducks/constants';
import * as NotificationViewUtil from './NotificationViewUtil';

const NotificationItem = (props) => {
  const { shipmentId, masterShipmentId, mode } = props;
  return (
    <Container className="notification-item">
      <Row className="title-row">
        <Col>
          <h5>{props.notification.title}</h5>
        </Col>

        <Col xs={'auto'} className="ml-auto">
          <ul className="edit-links" data-locator={`edit-notification-${props.notification.title}`}>
            <Tooltip
              className={cn({ 'cursor-disallowed': props.editDisabled })}
              placement="bottom"
              title={
                <FormattedMessage
                  id="notifications.notificationItem.permissionsAlert"
                  defaultMessage="You don't have correct permissions to configure notifications. Speak to your tenant admin for assistance"
                />
              }
              {...(!props.editDisabled && { visible: false })}
            >
              {props.hasEdit && (
                <li>
                  <Link
                    className={cn({ 'link-disabled': props.editDisabled })}
                    to={{
                      pathname: `/notifications/${props.notification.id}`,
                      search: NotificationViewUtil.queryStringWithShipmentId({ shipmentId, masterShipmentId, mode }),
                      accessLevel: props.accessLevel,
                      shipmentId,
                      masterShipmentId,
                      mode,
                    }}
                  >
                    <FormattedMessage id="notifications.notificationItem.editButton" defaultMessage="EDIT" />
                  </Link>
                </li>
              )}
              {props.hasEdit && (
                <li>
                  <Link
                    className={cn('edit-link-item', { 'link-disabled': props.editDisabled })}
                    to={{
                      pathname: `/notifications/${props.notification.id}`,
                      search: NotificationViewUtil.queryStringWithShipmentId({ shipmentId, masterShipmentId, mode }),
                      accessLevel: props.accessLevel,
                      shipmentId,
                      masterShipmentId,
                      mode,
                    }}
                  >
                    <img src={pencil} alt="edit" />
                  </Link>
                </li>
              )}
            </Tooltip>

            <li>
              {/*eslint-disable-next-line */}
              <div
                role="button"
                className={`btn ${props.notification.isExpanded ? 'collapse d-block' : 'expand'}`}
                onClick={() => props.expandCollapse(!props.notification.isExpanded)}
              >
                <img src={MenuDownArrow} alt="menu" />
              </div>
            </li>
          </ul>
        </Col>
      </Row>

      <div className={`details ${props.notification.isExpanded ? 'd-block' : 'd-none'}`}>
        <Row className="notification-row">
          <Col xs={24}>
            <FormattedMessage id="notifications.notificationItem.whenTruckIs" defaultMessage="When truck is:" />

            {props.notification.notificationEvents.map((notificationEvent, index) => (
              <div
                className="d-block"
                key={`rule-${index}`} //eslint-disable-line
              >
                {NotificationViewUtil.getEventName(notificationEvent)}
                &nbsp;
                {NotificationViewUtil.getDurationDisplay(notificationEvent)}
                &nbsp;
                {NotificationViewUtil.getStopDisplay(notificationEvent)}
              </div>
            ))}
          </Col>
        </Row>

        {props.accessLevel === constants.ShipmentAccessLevelConstants.Tenant &&
          NotificationViewUtil.hasTenantShipmentFiltersDisplayItems(props.notification.filters) && (
            <Row className="notification-row">
              <Col xs={24}>
                <div className="d-block">
                  <FormattedMessage
                    id="notifications.notificationItem.toShipmentsWhere"
                    defaultMessage="To shipments where:"
                  />
                </div>

                {NotificationViewUtil.getTenantShipmentFiltersDisplayItems(props.notification.filters).map((value) => (
                  <div key={`${value.id} ${value.defaultMessage} ${get(value, 'values.FIELDVALUES')}`}>
                    <FormattedMessage id={value.id} defaultMessage={value.defaultMessage} values={value.values} />
                  </div>
                ))}
              </Col>
            </Row>
          )}

        <Row className="notification-row">
          <Col xs={24}>
            <div className="d-block">
              <FormattedMessage id="notifications.notificationItem.notify" defaultMessage="Notify:" />
            </div>

            {props.notification.notificationRecipients.map((recipientType) => (
              <div key={recipientType.type}>{recipientType.values.join(', ')}</div>
            ))}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default NotificationItem;
