import styled from 'styled-components';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Checkbox from 'material-ui/Checkbox';
import Row from 'react-bootstrap/Row';
import { primaryGreyEighty } from 'styles/colors';
import { Input as AntInput } from 'ui-components';

const StyledLabel = styled('label')`
  color: ${primaryGreyEighty};
  margin-bottom: 0.4rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  display: block;
  font-weight: normal;

  &:before {
    display: none !important;
  }
`;

const StyledFormGroupRow = styled(Row)`
  margin-bottom: 1.7rem;
  font-size: 1rem;
`;
export const Label = ({
  children,
  inputid,
  className,
}: {
  children: JSX.Element | JSX.Element[] | string;
  inputid?: string;
  className?: string;
}) => {
  return (
    <StyledLabel {...{ className }} htmlFor={inputid}>
      {children}
    </StyledLabel>
  );
};

export const Input = (props: any & { errorMessage?: string }): JSX.Element => {
  const { errorMessage, ...otherProps } = props;

  return (
    <>
      <AntInput {...otherProps} />
      {errorMessage && <InputErrorMessage errorMessage={errorMessage} />}
    </>
  );
};

export const InputErrorMessage = ({
  errorMessage,
  'data-locator': dataLocator,
  'data-testId': dataTestId,
}: {
  errorMessage: string;
  'data-locator'?: string;
  'data-testId'?: string;
}) => {
  return (
    <div
      className="invalid-feedback"
      data-locator={dataLocator}
      data-testid={dataTestId}
      style={{ display: 'inline-block', fontSize: '14px' }}
    >
      {errorMessage}
    </div>
  );
};

export const FormGroupRow = ({ children, ...rest }: { children: JSX.Element[] | JSX.Element } & any) => {
  return <StyledFormGroupRow {...rest}>{children}</StyledFormGroupRow>;
};

export const CheckBox = ({ field, label, onCheck, checked, custom, disabled }: any) => {
  return (
    <div className="form-group checkbox-group">
      <MuiThemeProvider>
        <Checkbox
          {...field}
          disabled={disabled}
          style={{
            color: custom.primaryColor ? custom.primaryColor : '#1296b0',
            fontWeight: 300,
            display: 'flex',
          }}
          labelStyle={{ color: '#575451', fontSize: '15px' }}
          iconStyle={{
            fill: custom.primaryColor ? custom.primaryColor : '#1296b0',
            margin: '1px 4px 0 0',
            width: '21px',
            height: '21px',
          }}
          alt={custom.testId}
          inputStyle={{ marginRight: 0 }}
          label={label}
          checked={checked}
          onCheck={onCheck} // eslint-disable-line react/jsx-no-bind
        />
      </MuiThemeProvider>
    </div>
  );
};
