import { Component, MouseEvent, createRef } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Link, matchPath } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import { shipmentListFiltersUrlEnabled } from 'common/authorizations';
import endpoints from 'common/endpoints';
import SearchBar from 'components/common/searchBar/SearchBar';
import { SearchServiceTypeEnum } from 'components/common/searchBar/models';
import { ThemeContext } from 'contexts/ThemeContext';
import FilterUtil from 'components/filter/util/filterUtil';
import { trackEvent } from 'common/eventTracker';
import burgerMenu from '../assets/hamburger.svg';
import appIcon from '../assets/logo.svg';
import { ActiveTabEnum } from '../menu/MenuConstants';
import AppMenu from '../menu/appMenu/AppMenu';
import MainMenu from '../menu/mainMenu/MainMenu';
import SideMenu from '../menu/sideMenu/SideMenu';
import UserMenu from '../menu/userMenu/UserMenu';

import * as styles from './Header.module.scss';

import { HeaderDispatchProps, HeaderProps } from './HeaderContainer';
import MovementBanner from '../movementBanner/MovementBanner';

const messages = defineMessages({
  logo: {
    id: 'header.imgAltText.logo',
    defaultMessage: 'logo',
  },
  logoSmall: {
    id: 'header.imgAltText.logoSmall',
    defaultMessage: 'logo-small',
  },
  placeholderSmall: {
    id: 'header.search.placeholderSmall',
    defaultMessage: 'Search shipments.',
  },
});

/**
 * Header component
 */
interface HeaderState {
  menuOpen: boolean;
  appMenuOpen: boolean;
  userMenuOpen: boolean;
  hideMovementBannerPreview: boolean;
}

class Header extends Component<HeaderProps & HeaderDispatchProps, HeaderState> {
  state = {
    menuOpen: false,
    appMenuOpen: false,
    userMenuOpen: false,
    hideMovementBannerPreview: window.localStorage.getItem('hideMovementBannerPreview') === 'true',
  };
  componentWillUnmount() {
    document.removeEventListener('click', this.handleAppMenuOutsideClick, { capture: true });
    document.removeEventListener('click', this.handleUserMenuOutsideClick, { capture: true });
  }

  static contextType = ThemeContext;
  userMenuRef = createRef<HTMLDivElement>();
  isLocationsMatch() {
    return matchPath(this.props.routerPath, {
      path: [endpoints.LOCATION_DETAILS, endpoints.LOCATION_LIST],
    });
  }

  isShipmentsMatch() {
    return matchPath(this.props.routerPath, {
      path: [
        endpoints.SHIPMENT_LIST,
        endpoints.TRACKING_DETAILS,
        endpoints.TRACKING_DETAILS_MASTER_SHIPMENT_ID,
        endpoints.MA_TRACKING_DETAILS,
      ],
    });
  }

  isLandingMatch() {
    return matchPath(this.props.routerPath, {
      path: [endpoints.DASHBOARD],
    });
  }

  isOrdersMatch() {
    return matchPath(this.props.routerPath, {
      path: [endpoints.ORDERSLIST, endpoints.ORDERS_DETAILS],
    });
  }

  isInventoryMatch() {
    return matchPath(this.props.routerPath, {
      path: [endpoints.INVENTORYITEMSLIST, endpoints.INVENTORY_ITEM_DETAILS, endpoints.INVENTORY_ITEMS_SEARCH],
    });
  }

  isAnalyticsMatch() {
    return matchPath(this.props.routerPath, {
      path: [
        endpoints.ANALYTICS,
        endpoints.ANALYTICS_CARRIER_OVERVIEW,
        endpoints.ANALYTICS_LOCATION_ANALYTICS,
        endpoints.ANALYTICS_LOCATION_DETAILS_ANALYTICS,
        endpoints.ANALYTICS_OCEAN_OVERVIEW,
        endpoints.ANALYTICS_OCEAN_PORT_CONGESTION,
        endpoints.ANALYTICS_SCORECARD,
      ],
    });
  }
  /**
   * My profile click handler
   */
  myProfileClickHandler = () => {
    this.setState({ appMenuOpen: false, userMenuOpen: false });
  };

  /**
   * Signout click handler
   */
  signOutClickHandler = () => {
    this.setState({ appMenuOpen: false, userMenuOpen: false });
    this.props.logout();
  };

  /**
   * Location click handler
   */
  locationClickHandler = () => {
    this.setState({ appMenuOpen: false, userMenuOpen: false });
  };

  /**
   * Users click handler
   */
  userClickHandler = () => {
    this.setState({ appMenuOpen: false, userMenuOpen: false });
  };

  /**
   * Notification click handler
   */
  notificationClickHandler = () => {
    this.setState({ appMenuOpen: false, userMenuOpen: false });
  };

  /**
   * Carrier click handler
   */
  carrierClickHandler = () => {
    this.setState({ appMenuOpen: false, userMenuOpen: false });
  };

  /**
   * Listens to state change of side menu and updates state accordingly
   * @param newMenuState
   */
  updateMenuState = (newMenuState: any) => {
    this.setState({ menuOpen: newMenuState.isOpen });
  };

  /**
   * Updates app menu open or close state
   * @param appMenuState
   */
  updateAppMenuState = (appMenuState: any) => {
    this.setState({ appMenuOpen: appMenuState, userMenuOpen: false });

    if (!this.state.appMenuOpen) {
      document.addEventListener('click', this.handleAppMenuOutsideClick, { capture: true });
    } else {
      document.removeEventListener('click', this.handleAppMenuOutsideClick, { capture: true });
    }
  };

  /**
   * Updates user menu open or close state
   * @param userMenuState
   */
  updateUserMenuState = (userMenuState: any) => {
    this.setState({ userMenuOpen: userMenuState, appMenuOpen: false });
    if (!this.state.userMenuOpen) {
      document.addEventListener('click', this.handleUserMenuOutsideClick, { capture: true });
    } else {
      document.removeEventListener('click', this.handleUserMenuOutsideClick, { capture: true });
    }
  };

  /**
   * Handle any click outside app menu window
   * @param e
   */
  handleAppMenuOutsideClick = (e: any) => {
    e.stopPropagation();
    this.setState({ appMenuOpen: false });
    document.removeEventListener('click', this.handleAppMenuOutsideClick, { capture: true });
  };

  /**
   * Handle any click outside user menu
   * @param e
   */
  handleUserMenuOutsideClick = (e: any) => {
    if (this.userMenuRef && !this.userMenuRef?.current?.contains(e.target)) {
      e.stopPropagation();
      this.setState({ userMenuOpen: false });
      document.removeEventListener('click', this.handleUserMenuOutsideClick, { capture: true });
    }
  };

  /**
   * Handle user clicks on the logo image
   * @param e
   */
  handleLogoClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.props.history.push(endpoints.DASHBOARD);
  };

  /**
   * Close side menu
   */
  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  /**
   * Close menu and change active tab
   * @param activeTab
   */
  closeMenuAndPerformActiveTabAction = () => {
    this.closeMenu();
  };

  showApplicationLogo = (isLoggedIn: boolean) => {
    if (isLoggedIn) {
      if (isLoggedIn && this.context === undefined) {
        return (
          <Col xs={16} md={'auto'} className={`${styles.appLogoContainer}`}>
            <Row className="justify-content-center">
              <Col />
            </Row>
          </Col>
        );
      }
      // keys on the image prevent p44 logo from flashing on login if they have a custom logo
      return (
        <Col
          xs={16}
          md={'auto'}
          className={`${styles.appLogoContainer} d-flex justify-content-center align-items-center`}
        >
          <button className="plainWrapperButton" onClick={this.handleLogoClick}>
            <img
              data-locator="app-logo"
              key={this.context.isDefaultLogo ? 'default-logo' : 'custom-logo'}
              src={this.context.logoUrl}
              className={`${styles.appLogo}`}
              alt={this.props.intl.formatMessage(messages.logo)}
            />
          </button>
        </Col>
      );
    } else {
      return (
        <Col className="app-logo-container ">
          <button className="plainWrapperButton" onClick={this.handleLogoClick}>
            <img
              key="default-logo"
              src={appIcon}
              className={`${styles.appLogo}`}
              alt={this.props.intl.formatMessage(messages.logo)}
            />
          </button>
        </Col>
      );
    }
  };

  showPublicApplicationLogo = () => {
    if (this.context === undefined) {
      return (
        <Col xs={'auto'} className={`${styles.appLogoContainer} justify-content-center align-items-center d-flex`} />
      );
    }

    return (
      <Col xs={'auto'} className={`${styles.appLogoContainer} justify-content-center align-items-center d-flex`}>
        <button onClick={(e) => this.handleLogoClick(e)} className="plainWrapperButton">
          <img
            src={this.context.logoUrl}
            className={`${styles.appLogo} d-none d-sm-none d-md-flex`}
            alt={this.props.intl.formatMessage(messages.logo)}
          />
        </button>
      </Col>
    );
  };

  showHamburgerMenuIcon = (isLoggedIn: boolean) => {
    if (isLoggedIn) {
      return (
        <Col xs={4} className={`${styles.hamburgerMenuContainer} d-md-none order-xs-1`}>
          <button className="plainWrapperButton" onClick={this.showHamburgerMenuIconOnClick}>
            <img src={burgerMenu} alt={this.props.intl.formatMessage(messages.logo)} />
          </button>
        </Col>
      );
    }
  };

  showHamburgerMenuIconOnClick = () => {
    this.updateMenuState({ isOpen: !this.state.menuOpen });
  };

  getActiveTabValue() {
    if (this.isLocationsMatch()) return ActiveTabEnum.LOCATIONS;

    if (this.isShipmentsMatch() || this.isInventoryMatch() || this.isOrdersMatch()) {
      return ActiveTabEnum.TRACKING;
    }
    if (this.isLandingMatch()) return ActiveTabEnum.DASHBOARD;
    if (this.isAnalyticsMatch()) return ActiveTabEnum.ANALYTICS;

    return;
  }

  getActiveTrackingSection(): string | null {
    if (this.isShipmentsMatch()) return 'shipments';
    if (this.isInventoryMatch()) return 'inventory';
    if (this.isOrdersMatch()) return 'orders';

    return null;
  }

  showMainMenu = (isLoggedIn: boolean, isMovementBannerVisible: boolean) => {
    if (isLoggedIn) {
      return (
        <Col as={'nav'} md={'auto'} className="navigation">
          <MainMenu
            currentTab={this.getActiveTabValue()}
            authorizations={this.props.authorizations}
            executeShipmentSearch={this.executeSearch}
            hasCarrierRole={this.props.authorizations.hasTenantCarrierRole(this.props.principal)}
            trackingSection={this.getActiveTrackingSection()}
            isMovementBannerVisible={isMovementBannerVisible}
          />
        </Col>
      );
    }
  };

  showSideMenu = (isLoggedIn: boolean) => {
    if (isLoggedIn) {
      return (
        <SideMenu
          currentTab={this.getActiveTabValue()}
          menuOpen={this.state.menuOpen ? this.state.menuOpen : false}
          setCurrentMenuState={this.updateMenuState}
          setActiveAndCloseMenu={this.closeMenuAndPerformActiveTabAction}
          authorizations={this.props.authorizations}
          hasCarrierRole={this.props.authorizations.hasTenantCarrierRole(this.props.principal)}
        />
      );
    }
  };

  showProfileSection = (isLoggedIn: boolean, appMenuOpen: boolean, userMenuOpen: boolean) => {
    // TODO: The principal object is being mutated somewhere and is different
    // based on where you are in the app. e.g. - Landing Page vs. Shipment List - Rob Abby
    const principal = this.props.principal;
    const firstName = principal?.firstName || principal?.principal?.firstName;

    if (isLoggedIn) {
      return (
        <Col xs={4} md={'auto'} className="order-sm-4">
          <ul className={`${styles.iconList}`}>
            <li>{this.showAppMenu(appMenuOpen)}</li>
            <li>{this.showUserMenu(isLoggedIn, userMenuOpen, firstName)}</li>
          </ul>
        </Col>
      );
    }
  };

  showAppMenu = (appMenuOpen: boolean) => {
    return (
      <AppMenu
        currentMenuState={appMenuOpen}
        authorizations={this.props.authorizations}
        onAppMenuToggleClick={this.updateAppMenuState}
        onLocationsClick={this.locationClickHandler}
        onUsersClick={this.userClickHandler}
        onNotificationsClick={this.notificationClickHandler}
        onCarriersClick={this.carrierClickHandler}
        hasCarrierRole={this.props.authorizations.hasTenantCarrierRole(this.props.principal)}
      />
    );
  };

  showUserMenu = (isLoggedIn: boolean, userMenuOpen: boolean, firstName: string) => {
    return (
      <UserMenu
        isLoggedIn={isLoggedIn}
        currentMenuState={userMenuOpen}
        userFirstName={firstName}
        onUserMenuToggleClick={this.updateUserMenuState}
        onMyProfileClick={this.myProfileClickHandler}
        onSignoutClick={this.signOutClickHandler}
        reference={this.userMenuRef}
      />
    );
  };

  showLoginLinkSection = (shipmentMode?: string, shipmentId?: string) => {
    // Creates the state that is passed to the login component which can override the success redirect.
    const linkState = {
      successRedirectUrlOverride: this.buildSuccessRedirectUrl(shipmentMode, shipmentId),
    };

    return (
      <Col xs={'auto'} className={`${styles.publicLoginLinkSection} d-flex align-items-center justify-content-center`}>
        <span className="d-none d-sm-none d-md-flex">
          <FormattedMessage id="header.showLoginLinkSection.userCheck" defaultMessage="Are you a p44 user?" />
        </span>
        <span className={`${styles.iconMarginRightSmall} d-none d-sm-flex`} />
        <Link to={{ pathname: endpoints.LOGIN, state: linkState }}>
          <FormattedMessage id="header.showLoginLinkSection.logIn" defaultMessage="LOG IN" />
        </Link>
      </Col>
    );
  };

  /**
   * Builds the tracking details endpoint with the given shipment ID spliced in.
   *
   * @param {ShipmentMode} shipmentId The ID of the shipment whose details page should be redirected to.
   * @param {string} shipmentId The ID of the shipment whose details page should be redirected to.
   * @returns The success redirect URL or undefined.
   */
  buildSuccessRedirectUrl = (shipmentMode?: string, shipmentId?: string) => {
    if (shipmentMode && shipmentId) {
      return endpoints.TRACKING_DETAILS.replace(':shipmentMode', shipmentMode.toLowerCase()).replace(
        ':shipmentDetailId',
        shipmentId
      );
    }
  };

  executeSearch = (isMultiValue?: boolean, serviceType?: string) => {
    switch (serviceType) {
      case SearchServiceTypeEnum.ORDERS_SEARCH:
        trackEvent('ENTITY_SEARCH', {
          entityType: SearchServiceTypeEnum.ORDERS_SEARCH,
          isMultiValue: Boolean(isMultiValue),
          searchTerm: this.props.searchTerm,
        });
        this.props.executeOrderSearchFn(Boolean(isMultiValue), this.props.searchTerm);
        break;
      case SearchServiceTypeEnum.INVENTORY_SEARCH:
        trackEvent('ENTITY_SEARCH', {
          entityType: SearchServiceTypeEnum.INVENTORY_SEARCH,
          isMultiValue: Boolean(isMultiValue),
          searchTerm: this.props.searchTerm,
        });
        this.props.executeInventorySearchFn(this.props.searchTerm);
        break;
      default:
        trackEvent('ENTITY_SEARCH', {
          entityType: SearchServiceTypeEnum.SHIPMENT_SEARCH,
          isMultiValue: Boolean(isMultiValue),
          searchTerm: this.props.searchTerm,
        });
        this.props.clearFiltersNoSearch();
        if (shipmentListFiltersUrlEnabled()) {
          const querySearch = FilterUtil.getSearchQueryFromURL(this.props.location.search);

          const queryURL = FilterUtil.buildQueryURl(
            querySearch.filter,
            querySearch.sortBy,
            querySearch.modes,
            this.props.searchTerm,
            isMultiValue
          );

          if (queryURL) this.props.history.push({ pathname: '/shipment-list', search: queryURL });
        } else {
          this.props.executeSearchFn(this.props.authorities, this.props.searchTerm, isMultiValue);
        }
        break;
    }
  };

  showSearchBar = (shouldDisplaySearchBar: any) => {
    if (shouldDisplaySearchBar) {
      return (
        <Col md={'auto'} className="order-md-4 order-5">
          <SearchBar
            executeSearchFn={this.executeSearch}
            setSearchTerm={this.props.setSearchTerm}
            isMuliValueSearchModalOpen={this.props.isMuliValueSearchModalOpen}
            setIsMuliValueSearchModalOpen={this.props.setIsMuliValueSearchModalOpen}
            previousSearchQuery={this.props.previousSearchQuery}
            modes={this.props.modeFilters}
            searchTerm={this.props.searchTerm}
            dontSearchOnChange
            hasMultiFieldSearch={this.props.authorizations.hasMultiFieldSearchAuthorization()}
            hasAdvancedLinking={this.props.authorizations.hasAdvancedTrackingLinking()}
            isOrdersMatch={!!this.isOrdersMatch()}
            isInventoryMatch={!!this.isInventoryMatch()}
          />
        </Col>
      );
    }
  };

  onMovementBannerClose = () => {
    window.localStorage.setItem('hideMovementBannerPreview', 'true');
    this.setState({ hideMovementBannerPreview: true });
  };

  render() {
    const PUBLIC_PATH = '/public';
    const { location, isLoggedIn, shipmentMode, shipmentId } = this.props;
    const { appMenuOpen, userMenuOpen } = this.state;
    const shouldDisplaySearchBar =
      this.isShipmentsMatch() || this.isOrdersMatch() || this.isInventoryMatch() || this.isLandingMatch();

    if (location?.pathname?.includes(PUBLIC_PATH) && !isLoggedIn) {
      return (
        <Container as={'header'} fluid className={`${styles.headerContainer} pb-0`}>
          <Row noGutters className={`${styles.headerRow} flex-nowrap justify-content-between`}>
            {this.showPublicApplicationLogo()}
            {this.showLoginLinkSection(shipmentMode, shipmentId)}
          </Row>
        </Container>
      );
    }

    const isMovementBannerVisible =
      this.props.authorizations.hasMovementPreviewAuthorization() && !this.state.hideMovementBannerPreview;

    return (
      <>
        {isMovementBannerVisible && <MovementBanner onMovementBannerClose={this.onMovementBannerClose} />}
        <Container as={'header'} fluid className={`${styles.headerContainer}`}>
          <Row className={`${styles.headerRow} align-items-center no-gutters`}>
            {this.showHamburgerMenuIcon(isLoggedIn)}
            {this.showApplicationLogo(isLoggedIn)}
            {this.showSideMenu(isLoggedIn)}
            {this.showMainMenu(isLoggedIn, isMovementBannerVisible)}
            <Col className="d-none d-md-block"></Col>
            {this.showSearchBar(shouldDisplaySearchBar)}
            {this.showProfileSection(isLoggedIn, appMenuOpen, userMenuOpen)}
          </Row>
        </Container>
      </>
    );
  }
}

export default Header;
