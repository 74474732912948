import '@project44-lib/here-maps-api-js';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { stringify } from 'wellknown';
import { polygonShade, polylineColor } from 'styles/colors';

import { LocationDetails } from 'models';
import HereMapsBehavior from '../hereMaps/hereMapsBehavior';
import HereMapsFactory from '../hereMaps/hereMapsFactory';
import HereMapsOptions from '../hereMaps/hereMapsOptions';

declare let H: any;

interface InitializeMapProps {
  mapInstance: any;
  address: LocationDetails['address'];
  geofenceRangeInMinutes: number;
  mapId: string;
  idleLocations?: Array<{ lat: number; lng: number; marker?: any }>;
  updatelatLngInputValue?: (latLng: string | undefined) => void;
  useAddressLatLngInMap?: boolean;
}

export function initializeMap({
  mapInstance,
  address,
  geofenceRangeInMinutes: geofenceSeconds,
  mapId,
  idleLocations,
  updatelatLngInputValue,
  useAddressLatLngInMap,
}: InitializeMapProps) {
  const searchText = `${!isNil(get(address, 'address1')) ? `${get(address, 'address1', '')},` : ''}${get(
    address,
    'city'
  )}, ${get(address, 'state')}, ${get(address, 'postalCode')}, ${get(address, 'country')}`;

  const mapInitializationPromise = new Promise((resolve, reject) => {
    mapInstance.platform.getSearchService().geocode(
      { q: searchText },
      (result: any) => {
        HereMapsBehavior.removeAllMarkers(mapInstance);
        HereMapsBehavior.removeAllGeofences(mapInstance);
        const locations = get(result, 'items', null);
        if (!isNil(locations)) {
          const location = locations[0];
          const markerLatitude = get(location, 'position.lat', 0);
          const markerLongitude = get(location, 'position.lng', 0);
          mapInstance.options.defaultMarker.show = true;
          mapInstance.options.defaultMarkerPopup.show = false;
          mapInstance.options.limitViewToBounds = true;
          mapInstance.options.initialZoomLevel = 12;
          mapInstance.options.geofence.rangeInSeconds = geofenceSeconds * 60;
          mapInstance.options.geofence.style = { strokeColor: polylineColor, fillColor: polygonShade };

          if (mapInstance) {
            HereMapsFactory.updateMap(
              mapInstance,
              [
                ...(idleLocations ? idleLocations : []),
                {
                  lat: get(address, 'locationCoordinatesDto.latitude') || markerLatitude,
                  lng: get(address, 'locationCoordinatesDto.longitude') || markerLongitude,
                },
              ],
              mapId
            );

            // update the latLng input with geocoded coordinates if the user selected a new address from the dropdown
            if (useAddressLatLngInMap && updatelatLngInputValue && markerLatitude && markerLongitude) {
              updatelatLngInputValue(`${markerLatitude}, ${markerLongitude}`);
            }

            for (const record of HereMapsBehavior.filterToValidCoords([
              {
                lat: markerLatitude,
                lng: markerLongitude,
              },
            ])) {
              const routingParams = {
                transportMode: 'truck',
                routingMode: 'fast',
                mode: 'fastest;traffic:enabled;',
                destination: `${record.lat},${record.lng}`,
                'range[values]': `${mapInstance.options.geofence.rangeInSeconds}`,
                'range[type]': 'time',
              };

              let renderingOptions = {
                style: mapInstance.options.geofence.style,
              };

              const baseOptions = { zIndex: 1 };
              renderingOptions = Object.assign(baseOptions, renderingOptions);
              if (parseInt(routingParams['range[values]'], 10) > 0) {
                const router = mapInstance.platform.getRoutingService(null, 8);

                router.calculateIsoline(
                  routingParams,
                  (isolineResult: any) => {
                    try {
                      const polygons = get(isolineResult, 'isolines[0].polygons', []);
                      const outerString = polygons?.[polygons.length - 1]?.outer || '';
                      if (!outerString) {
                        return reject();
                      }
                      const linestring = H.geo.LineString.fromFlexiblePolyline(outerString);
                      const isolinePolygon = setPolygon({ linestring, options: renderingOptions });
                      if (mapInstance.mapData) {
                        mapInstance.mapData.addObject(isolinePolygon);
                        mapInstance.geofences.push(isolinePolygon);
                        mapInstance.mapData.getViewModel().setLookAtData(
                          {
                            bounds: isolinePolygon.getBoundingBox(),
                          },
                          true
                        );
                        // eslint-disable-next-line
                        resolve(mapInstance);
                      }
                    } catch (e) {
                      reject(e);
                    }
                  },
                  reject
                );
              } else {
                resolve(mapInstance);
              }
            }
          }
        }
      },
      (error: Error) => {
        // eslint-disable-next-line
        console.error(error);
      }
    );
  });

  return mapInitializationPromise;
}

export function getLatLngFromAddress(mapInstance: any, address: string): Promise<{ lat: number; lng: number }> {
  const searchText = `${!isNil(get(address, 'address1')) ? `${get(address, 'address1', '')},` : ''}${get(
    address,
    'city'
  )}, ${get(address, 'state')}, ${get(address, 'postalCode')}, ${get(address, 'country')}`;
  const geoCodePromise: Promise<{ lat: number; lng: number }> = new Promise((resolve, reject) => {
    mapInstance.platform.getSearchService().geocode(
      { q: searchText },
      (result: any) => {
        const locations = get(result, 'items', null);
        if (!isNil(locations)) {
          const location = locations[0];
          const markerLatitude = get(location, 'position.lat', 0);
          const markerLongitude = get(location, 'position.lng', 0);
          const latLng = {
            lat: markerLatitude,
            lng: markerLongitude,
          };
          resolve(latLng);
        }
      },
      (error: any) => {
        reject(error);
      }
    );
  });
  return geoCodePromise;
}

export function getMapOptions() {
  return new HereMapsOptions(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    1, // initialZoomLevel
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  );
}

export function setMarker({ mapInstance, xCoord, yCoord }: { mapInstance: any; xCoord: number; yCoord: number }) {
  return new H.map.Marker(mapInstance.mapData.screenToGeo(xCoord, yCoord));
}

export function setLinestring() {
  return new H.geo.LineString();
}

export function setPolyline({ linestring, options }: { linestring: any; options?: any }) {
  return new H.map.Polyline(linestring, options);
}

export function setPolygon({ linestring, options }: { linestring: any; options?: any }) {
  const polygon = new H.map.Polygon(linestring, options);
  return polygon;
}

export function removeAllGeofences({ mapInstance, idToSave }: { mapInstance: any; idToSave?: number }) {
  return HereMapsBehavior.removeAllGeofences(mapInstance, idToSave);
}

export function createMapInstance({
  mapId,
  mapOptions,
  config,
  mapType,
}: {
  mapId: string;
  mapOptions: any;
  config: any;
  mapType: 'normal' | 'satellite';
}) {
  return HereMapsFactory.newInstance({}, mapId, mapOptions, config, mapType);
}

export function setRadiusBasedCircle({
  radius,
  options,
  coords,
}: {
  coords: { lat: number; lng: number };
  radius: number;
  options?: any;
}) {
  const circle = new H.map.Circle(coords, radius, options);
  return circle;
}

export function getGeoPointFromWKT(WKT: string) {
  return H.util.wkt.toGeometry(WKT);
}

export const addPolygonGeofenceToCurrentMap = (mapRef: any, geofence: LocationDetails['geofence'], setZoom = false) => {
  const WKT = stringify(get(geofence, 'geometry'));
  const geoPoint = getGeoPointFromWKT(WKT);
  const poly = setPolygon({ linestring: geoPoint });
  poly.setData({
    wkt: WKT,
  });

  setTimeout(() => {
    if (mapRef.mapData) {
      mapRef.mapData.addObject(poly);
      if (setZoom) {
        mapRef.mapData.getViewModel().setLookAtData(
          {
            bounds: poly.getBoundingBox(),
          },
          true
        );
      }
    }
  }, 800);
};

export const addRadiusBasedGeofenceToCurrentMap = (
  mapRef: any,
  radius: number,
  setZoom = false,
  coords: { lat: number; lng: number }
) => {
  const circle = setRadiusBasedCircle({
    coords,
    radius,
  });

  if (circle) {
    mapRef.geofences.push(circle);
    setTimeout(() => {
      mapRef.mapData.addObject(circle);
      if (setZoom) {
        mapRef.mapData.getViewModel().setLookAtData(
          {
            bounds: circle.getBoundingBox(),
          },
          true
        );
      }
    }, 800);
  }
  return circle;
};

export const idleLocationMarker = () => {
  return () =>
    new H.map.DomIcon(`<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 59.1 (86144) - https://sketch.com -->
    <title>Oval</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="List-page-for-intermodal-no-share-button-Copy" transform="translate(-912.000000, -914.000000)" fill="#00AEEF" stroke="#FFFFFF" stroke-width="2">
            <circle id="Oval" cx="919" cy="921" r="6"></circle>
        </g>
    </g>
</svg>`);
};
