import types from './types';
import { RESULTS_PER_PAGE } from './constants';

const getLocationDetails = (locationId) => ({
  type: types.LOCATION_DETAIL_GET,
  payload: { locationId: locationId },
});

const getLocationsV1 = (startingPage, sortBy) => ({
  type: types.LOCATION_LIST_GET,
  payload: {
    ...sortBy,
    reSorted: !startingPage,
    resultStartIndex: startingPage ? startingPage : 0,
    resultsPerPage: RESULTS_PER_PAGE,
  },
});

const getLocations = (startingPage, sortBy = {}, filters) => {
  return {
    payload: {
      ...sortBy,
      ...(filters && {
        filters: [...filters],
      }),
      reSorted: !startingPage,
      resultStartIndex: startingPage ? startingPage : 0,
      resultsPerPage: RESULTS_PER_PAGE,
    },
    type: types.LOCATION_LIST_GET,
  };
};

const getLocationSuggestions = (params) => ({
  type: types.LOCATION_SUGGESTIONS_GET,
  payload: { ...params, resultStartIndex: 0, resultsPerPage: RESULTS_PER_PAGE },
});

const clearLocationSuggestions = () => ({
  type: types.LOCATION_SUGGESTIONS_CLEAR,
});

const newLocation = () => ({
  type: types.LOCATION_DETAIL_NEW,
});

const setSortOrder = (selectedSortFieldId = 0) => ({
  type: types.LOCATION_LIST_SORT,
  sortId: selectedSortFieldId,
});

export const createLocation = (locationData) => ({
  type: types.LOCATION_CREATE,
  payload: locationData,
});

export const updateLocation = (locationData) => ({
  type: types.LOCATION_UPDATE,
  payload: locationData,
});

export const closeSnackbar = () => ({
  type: types.LOCATION_LIST_CLOSE_SNACKBAR,
});

export const resetLocationDetails = () => ({
  type: types.LOCATION_DETAIL_RESET,
});

export const setSearchTerm = (searchTerm) => ({
  type: types.SET_SEARCH_TERM,
  payload: searchTerm,
});

export const setContactSearchTerm = (contactSearchTerm) => ({
  type: types.SET_CONTACT_SEARCH_TERM,
  payload: contactSearchTerm,
});

export const locationDetailActions = {
  getLocationDetails,
  newLocation,
  createLocation,
  updateLocation,
  resetLocationDetails,
};

export const locationListActions = {
  getLocations,
  getLocationsV1,
  setSortOrder,
  getLocationSuggestions,
  clearLocationSuggestions,
  closeSnackbar,
  setSearchTerm,
  setContactSearchTerm,
};
