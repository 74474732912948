import { takeLatest, put, call, fork, select } from 'redux-saga/effects';
import { getType, ActionType } from 'typesafe-actions';
import { AxiosPromise, AxiosResponse } from 'axios';
import isEqual from 'lodash/isEqual';
import { message } from 'ui-components';

import defaultTheme from '../defaultTheme';
import { API_PATH } from '../../../../common/AppConstants';
import axios from '../../../../util/paxios';
import { Theme, ThemeResponse } from '../models/Theme';
import * as brandThemingActions from './actions';
import mapThemeResponse from '../utils/mapThemeResponse';
import { RootState } from '../../../../reducers';

export type SaveAction = ActionType<typeof brandThemingActions.save.request>;

export function fetchTheme(): AxiosPromise<ThemeResponse> {
  return axios({
    method: 'GET',
    url: API_PATH + '/customer-branding/branding',
    withCredentials: true,
  });
}

export function* fetchThemeAsync() {
  try {
    const response: AxiosResponse<ThemeResponse> = yield call(fetchTheme);
    yield put(brandThemingActions.fetch.success(mapThemeResponse(response.data)));
  } catch (error) {
    yield put(brandThemingActions.fetch.failure());
  }
}

export function* watchFetchThemeAsync() {
  yield takeLatest(getType(brandThemingActions.fetch.request), fetchThemeAsync);
}

export function buildThemeFormData(theme: Theme, savedTheme: Theme): FormData {
  const formData = new FormData();
  if (theme.logoFile) {
    formData.append('logoImage', theme.logoFile);
  }
  formData.append('primaryColor', theme.primaryColor);
  formData.append('secondaryColor', theme.secondaryColor);
  formData.append('emailFooter', theme.emailFooter);
  formData.append('useLogo', JSON.stringify(theme.logoEnabled));
  const deleteLogo = theme.isDefaultLogo && !savedTheme.isDefaultLogo;
  formData.append('deleteLogo', JSON.stringify(deleteLogo));

  return formData;
}

function saveTheme(theme: Theme, savedTheme: Theme): AxiosPromise<ThemeResponse> {
  return axios({
    method: 'POST',
    url: API_PATH + '/customer-branding/branding',
    withCredentials: true,
    data: buildThemeFormData(theme, savedTheme),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function resetTheme(): AxiosPromise<void> {
  return axios({
    method: 'DELETE',
    url: API_PATH + '/customer-branding/branding',
    withCredentials: true,
  });
}

export function* saveThemeAsync(action: SaveAction) {
  try {
    if (isEqual(action.payload, defaultTheme)) {
      yield call(resetTheme);
      yield put(brandThemingActions.save.success(defaultTheme));
    } else {
      const savedTheme: Theme = yield select((state: RootState) => state.brandThemingReducer.savedTheme);
      const response: AxiosResponse<ThemeResponse> = yield call(saveTheme, action.payload, savedTheme);
      yield put(brandThemingActions.save.success(mapThemeResponse(response.data)));
    }
  } catch (error) {
    yield put(brandThemingActions.save.failure());
  }
}

export function* watchSaveThemeAsync() {
  yield takeLatest(getType(brandThemingActions.save.request), saveThemeAsync);
}

export function saveThemeSuccessMessage() {
  message.success('Your settings have been updated.');
}

export function* watchSaveThemeSuccess() {
  yield takeLatest(getType(brandThemingActions.save.success), saveThemeSuccessMessage);
}

export function saveThemeFailureMessage() {
  message.error("We're having trouble saving your changes. Please try again.");
}

export function* watchSaveThemeFailure() {
  yield takeLatest(getType(brandThemingActions.save.failure), saveThemeFailureMessage);
}

export default function* brandThemingOperations() {
  yield fork(watchFetchThemeAsync);
  yield fork(watchSaveThemeAsync);
  yield fork(watchSaveThemeSuccess);
  yield fork(watchSaveThemeFailure);
}
