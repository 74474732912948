import qs from 'querystring';
import { useState, useContext } from 'react';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import { Autosuggest } from 'ui-components';

import useDebounce from 'hooks/useDebounce';
import { FILTER_RESULT_DELAY } from 'common/AppConstants';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { getPrincipalAuthorizations } from 'common/authorizations';
import AutocompleteFilter from '../../common/autocompleteFilter/AutocompleteFilter';
import useSuggestions from '../util/useSuggestions';
import { SUGGESTIONS_MAP } from '../ducks/types';

const INITIAL_AMOUNT_VISIBLE_STATUS_CITY_CREATED_BY = 3;

const messages = defineMessages({
  filterByAnyUser: {
    id: 'quickView.createdByFilter.filterByAnyUser',
    defaultMessage: 'Filter by Any User',
  },
});

const CreatedByFilter = (props) => {
  const [currentValue, setCurrentValue] = useState('');
  const debouncedValue = useDebounce(currentValue, FILTER_RESULT_DELAY);
  const modes = get(props, 'modeFilters', []);
  const isAllMode = isEqual(head(modes), 'ALL');
  const params = qs.stringify(isAllMode || isEmpty(modes) ? { q: debouncedValue } : { modes, q: debouncedValue });
  const suggestedLocations = useSuggestions(SUGGESTIONS_MAP.createdBy, params);
  const placeholder = props.intl.formatMessage(messages.filterByAnyUser);
  const listData = get(props, 'filter.createdBy', []);

  const principalContext = useContext(PrincipalContext);
  const authorizations = getPrincipalAuthorizations(principalContext);
  const isShipmentListFiltersHorizontalEnabled = authorizations.shipmentListFiltersHorizontalEnabled();

  if (isShipmentListFiltersHorizontalEnabled) {
    return (
      <Autosuggest
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        placeholder={placeholder}
        suggestions={suggestedLocations}
        listData={listData}
        handleAutocompleteUpdate={props.handleAutocompleteUpdate}
        name="createdBy"
      />
    );
  }

  return (
    <Col>
      <label htmlFor="created-by-input-filter">
        {<FormattedMessage id="quickView.createdByFilter.createdBy" defaultMessage="CREATED BY" />}
      </label>
      <AutocompleteFilter
        idProp="created-by-input-filter"
        placeholder={placeholder}
        currentValue={currentValue}
        dataSource={suggestedLocations}
        updateFn={(inputValue) => setCurrentValue(inputValue)}
        newRequestFn={(filterItem) => props.handleAutocompleteUpdate(filterItem, 'createdBy')}
        listData={listData}
        showAllChips={get(props, 'showSelections.showAllCreatedBy')}
        passedInDeleteFn={(filterItem) => props.handleAutocompleteUpdate(filterItem, 'createdBy')}
        initialAmountVisible={INITIAL_AMOUNT_VISIBLE_STATUS_CITY_CREATED_BY}
        showHideAllFn={() => props.toggleShowSelections('CreatedBy')}
        dataLocator="created-by-filter"
      />
    </Col>
  );
};

export default injectIntl(CreatedByFilter);
