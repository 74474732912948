import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';

export enum MarkerType {
  PING,
  CURRENT_LOCATION,
  DESTINATION,
  STOP,
}

export interface Location {
  latitude: number;
  longitude: number;
  markerType?: MarkerType;
}

const locationMapper = (data: any, latPath: string, lngPath: string): Location | undefined => {
  const lat = get(data, latPath);
  const lng = get(data, lngPath);
  if (isNumber(lat) && isNumber(lng) && (lat !== 0 || lng !== 0) && inLatRange(lat) && inLngRange(lng)) {
    return { latitude: lat, longitude: lng };
  }
};

export const convertToLocation = (
  arr: [],
  latPath: string,
  lngPath: string,
  mapTypeFnc: (data: any, index: number) => MarkerType
): Location[] => {
  const locations = new Array<Location>();
  forEach(arr, (value, index) => {
    const location = locationMapper(value, latPath, lngPath);
    if (location) {
      location.markerType = mapTypeFnc(value, index);
      locations.push(location);
    }
  });

  return locations;
};

const inLatRange = (lat: number) => lat > -90 && lat < 90;

const inLngRange = (lat: number) => lat > -180 && lat < 180;
