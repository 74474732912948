import isNil from 'lodash/isNil';
import get from 'lodash/get';
import has from 'lodash/has';
import find from 'lodash/find';
import { defineMessages } from 'react-intl';
import moment from 'moment-timezone';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { MONTH_DAY_YEAR_FORMAT, HOURS_MINUTES_FORMAT, HOURS_MINUTES_SECONDS_FORMAT } from 'i18n/configurei18n';
import { statusOptions, utcDateKeys, localDateKeys, arrivalDepartureDateKeys } from './enums';
import { optimizeMinutes } from '../../../../../common/optimizeMinutes/OptimzeMinutes';
import { getDaysAgo } from '../../../../../../common/dateUtils';

const STOP_TIMEZONE_PATH = 'location.address.locationCoordinatesDto.localTimeZoneIdentifier';

const STOP_TIMEZONESHORT_PATH = 'location.address.locationCoordinatesDto.localTimeZoneIdentifierShort';

const getTimeZone = (stop) => {
  let timezone = get(stop, STOP_TIMEZONE_PATH);

  if (timezone === undefined && has(stop, 'location.address.locationCoordinatesDto.localTimeZoneIdentifier')) {
    timezone = stop.location.address.locationCoordinatesDto.localTimeZoneIdenntifier;
  }
  if (timezone === undefined && has(stop, 'location.address.postalCode')) {
    timezone = zipcode_to_timezone.lookup(stop.location.address.postalCode);
  }

  return timezone;
};

/**
 * Returns a date formatted and valid for the moment library
 * @param date
 * @returns {string}
 */
export const getFormattedDate = (date) => {
  const toDateFormat = moment(date).format(MONTH_DAY_YEAR_FORMAT);

  if (moment(toDateFormat, MONTH_DAY_YEAR_FORMAT, true).isValid()) {
    return moment(date).format(MONTH_DAY_YEAR_FORMAT);
  }

  return moment(date, MONTH_DAY_YEAR_FORMAT)._i;
};

export const getStopTertiaryText = (milestones, dateTime, timezoneShort, hasArrivalDepartureTime, stop, hasDates) => {
  if (!hasDates) {
    return undefined;
  }

  let placeholderTertiaryText;
  if (isNil(dateTime.time)) {
    placeholderTertiaryText = get(
      milestones,
      hasArrivalDepartureTime ? `${stop.stopType}_NO_TIME` : `${stop.stopType}_BY_NO_TIME`,
      milestones.BY_NO_TIME
    ).tertiaryText;
  } else {
    placeholderTertiaryText = get(
      milestones,
      hasArrivalDepartureTime ? stop.stopType : stop.stopType + '_BY',
      milestones.BY
    ).tertiaryText;
  }

  if (!placeholderTertiaryText || !placeholderTertiaryText.id || !placeholderTertiaryText.defaultMessage) {
    return undefined;
  }

  const tertiaryTextValues = [
    isNil(dateTime.date) ? '' : dateTime.date.format(MONTH_DAY_YEAR_FORMAT),
    isNil(dateTime.time) ? '' : dateTime.time.format(HOURS_MINUTES_FORMAT),
    isNil(timezoneShort) ? '' : timezoneShort,
  ];
  const tertiaryTextKeys = ['DATE_MONTH_YEAR', 'DATE_TIME', 'DATE_TIMEZONE'];

  return {
    id: placeholderTertiaryText.id,
    defaultMessage: placeholderTertiaryText.defaultMessage,
    values: {
      [tertiaryTextKeys[0]]: tertiaryTextValues[0],
      [tertiaryTextKeys[1]]: tertiaryTextValues[1],
      [tertiaryTextKeys[2]]: tertiaryTextValues[2],
    },
  };
};

/**
 * Creates formatted stops object based on the shipment stops and recorded stop statuses
 * @param shipmentStops - array of shipment stops
 * @param latestStopStatuses - array of latest stop statuses
 * @param latestStatusUpdate - un-formatted latest status update
 * @param milestones - milestones object containing enums based on status code
 * @returns {array} formatted stops array
 */
export const createStops = (stopType, shipmentStops, latestStopStatuses, latestStatusUpdate, milestones) => {
  return shipmentStops.map((stop, index) => {
    let timezone = getTimeZone(stop);

    // set timezone short value
    let timezoneShort = get(stop, STOP_TIMEZONESHORT_PATH, moment.tz(timezone).zoneAbbr());

    let utcStopStatusTimeKey = null;
    let localStopStatusTimeKey = null;
    if (!isNil(latestStopStatuses) && !isNil(latestStopStatuses[index])) {
      // search array of keys to use for timestamps
      utcStopStatusTimeKey = find(utcDateKeys, (key) => latestStopStatuses[index][key]);
      localStopStatusTimeKey = find(localDateKeys, (key) => latestStopStatuses[index][key]);
    }

    let utcStopTimeKey = null;
    let localStopTimeKey = null;
    if (!isNil(stop)) {
      // search array of keys to use for timestamps
      utcStopTimeKey = find(utcDateKeys, (key) => stop[key]);
      localStopTimeKey = find(localDateKeys, (key) => stop[key]);
    }

    let isCompleted = null;
    if (!isNil(latestStopStatuses) && !isNil(latestStopStatuses[index])) {
      isCompleted =
        get(latestStatusUpdate, 'statusCode') === 'COMPLETED' ||
        latestStopStatuses[index].statusCode === 'DEPARTED' ||
        latestStopStatuses[index].statusCode === 'ARRIVED';
    } else {
      isCompleted = get(latestStatusUpdate, 'statusCode') === 'COMPLETED';
    }

    let hasStopStatusDates = null;
    if (!isNil(latestStopStatuses) && !isNil(latestStopStatuses[index])) {
      hasStopStatusDates =
        !isNil(latestStopStatuses[index][utcStopStatusTimeKey]) ||
        !isNil(latestStopStatuses[index][localStopStatusTimeKey]);
    }

    let hasStopRangeDates = null;
    if (!isNil(stop)) {
      hasStopRangeDates = !isNil(stop[utcStopTimeKey]) || !isNil(stop[localStopTimeKey]);
    }

    const shouldUseStopStatusDates = hasStopStatusDates ? hasStopStatusDates : false;

    const hasArrivalDepartureTime = !(
      find(arrivalDepartureDateKeys, (key) => key === utcStopStatusTimeKey) ===
      find(arrivalDepartureDateKeys, (key) => key === localStopStatusTimeKey)
    );

    // check whether the timestamps are available from the stops
    const hasDates = (hasStopStatusDates || hasStopRangeDates) && !isNil(timezone);

    const dateTime = hasDates
      ? getDateTime(shouldUseStopStatusDates ? latestStopStatuses[index] : stop, timezone)
      : undefined;

    timezone = hasDates ? moment.tz(timezone).zoneAbbr() : undefined;

    // create text string to display departure / arrival / delivery time
    const tertiaryText = getStopTertiaryText(
      milestones,
      dateTime,
      timezoneShort,
      hasArrivalDepartureTime,
      stop,
      hasDates
    );

    const isPopover =
      shipmentStops.length > 4 && stop.stopType === 'TERMINAL' && shipmentStops[index + 1].stopType !== 'DESTINATION';

    return {
      cityState: get(stop, 'location.address.cityState'),
      tertiaryText: tertiaryText,
      label: milestones[stop.stopType].label,
      stopType: get(stop, 'stopType'),
      isCompleted: isCompleted, // stop is still completed if status is 'COMPLETED'
      isVisible: true,
      popover: isPopover,
    };
  });
};

/**
 * Gets correct timestamp to use - if utcDate not available, defaults to localSplitDate object
 * @param stopType - string, stop type
 * @param pickupStop - object containing pickup stop info
 * @param pickupStopStatus - object containing pickup status info
 * @param deliveryStop - object containing delivery stop info
 * @param deliveryStopStatus - object containing delivery status info
 * @param lastUpdate - object containing last update info
 * @returns {object} formatted object with date info
 */
export const getArrivalEstimateDate = (
  stopType,
  pickupStop,
  pickupStopStatus,
  deliveryStop,
  deliveryStopStatus,
  lastUpdate
) => {
  switch (stopType) {
    case statusOptions.types.SCHEDULED:
    case statusOptions.types.ATTEMPTING_TO_TRACK: {
      return createStopDate(pickupStop, pickupStopStatus, 'PICKUP');
    }
    case statusOptions.types.TIMED_OUT:
    case statusOptions.types.COMPLETED:
    case statusOptions.types.CANCELED: {
      return lastUpdate;
    }
    default: {
      return createStopDate(deliveryStop, deliveryStopStatus, 'DELIVERY');
    }
  }
};

/**
 * Gets correct timestamp to use - if utcDate not available, defaults to localSplitDate object
 * @param utcDate - utc timestamp
 * @param localSplitDate - object with date and time strings
 * @param timezone - string with timezone ('America/Chicago')
 * @param format - string with format for moment to use ('MM/DD/YY')
 * @returns {string} text displaying last updated date
 */
export const getDate = (utcDate, localSplitDate, timezone, format) => {
  if (utcDate && timezone) {
    return moment.tz(utcDate, timezone).format(format);
  } else if (get(localSplitDate, 'localDate')) {
    return moment(localSplitDate.localDate).format(format);
  } else {
    return moment(utcDate).format(format);
  }
};

export const getDateTime = (stop, timezone) => {
  const utcTimeKey = find(utcDateKeys, (key) => stop[key]);
  const localTimeKey = find(localDateKeys, (key) => stop[key]);

  if (!isNil(stop[utcTimeKey]) && !isNil(timezone)) {
    return {
      time: moment.utc(stop[utcTimeKey]).tz(timezone),
      date: moment.utc(stop[utcTimeKey]).tz(timezone),
    };
  } else if (has(stop[localTimeKey], 'localTime') && has(stop[localTimeKey], 'localDate')) {
    return {
      time: moment(stop[localTimeKey].localTime, HOURS_MINUTES_SECONDS_FORMAT),
      date: moment(stop[localTimeKey].localDate),
    };
  } else if (!isNil(stop[localTimeKey]) && has(stop[localTimeKey], 'localDate')) {
    return {
      time: !isNil(stop[utcTimeKey]) ? moment(stop[utcTimeKey]) : undefined,
      date: moment(stop[localTimeKey].localDate),
    };
  } else if (!isNil(stop[localTimeKey])) {
    return {
      time: moment(stop[localTimeKey], HOURS_MINUTES_SECONDS_FORMAT),
      date: moment(stop[localTimeKey]),
    };
  }
};

/**
 * Creates arrival forecast text string
 * @param lastUpdate - formatted last updated date object
 * @param lastUpdatedObj - determines which string to display
 * @returns {string} text displaying last updated date
 */
export const createLastUpdated = (lastUpdate, lastUpdatedObj) => {
  if (lastUpdatedObj.type === 'UPDATED_DURATION') {
    return {
      defaultMessage: lastUpdatedObj.text.defaultMessage,
      id: lastUpdatedObj.text.id,
      values: {
        UPDATED_DURATION: getLastUpdateTimeInMinutes(lastUpdate.utcTimestamp),
      },
    };
  } else {
    return {
      defaultMessage: lastUpdatedObj.text.defaultMessage,
      id: lastUpdatedObj.text.id,
      values: {
        DATE_MONTH_YEAR: lastUpdate.date,
        DATE_TIME: lastUpdate.time,
        DATE_TIMEZONE: lastUpdate.timezone,
      },
    };
  }
};

/**
 * Creates arrival forecast text string
 * @param estimatedArrival - timestamp of estimated arrival
 * @param arrivalForecast - timestamp of arrival forecast
 * @param forecastOptions - object containing strings for displaying forecast text
 * @param stopType - string determining stop type
 * @returns {string} displays if shipment is X day(s) early / late or on time
 */
export const createForecast = (estimatedArrival, arrivalForecast, forecastOptions, stopType) => {
  if (isNil(estimatedArrival.date) && isNil(estimatedArrival.time)) {
    return undefined;
  }
  if (
    stopType === statusOptions.types.DELIVERED ||
    stopType === statusOptions.types.EXCEPTION ||
    stopType === statusOptions.types.UNKNOWN
  ) {
    return undefined;
  } else if (!isNil(forecastOptions[stopType])) {
    return {
      defaultMessage: forecastOptions[stopType].defaultMessage,
      id: forecastOptions[stopType].id,
      values: {
        DATE: estimatedArrival.date,
        TIME: estimatedArrival.time,
        TIMEZONE: estimatedArrival.timezone,
      },
    };
  } else if (!isNil(estimatedArrival.date)) {
    const now = moment().startOf('day');
    const estimatedArrivalDate = moment(estimatedArrival.date, MONTH_DAY_YEAR_FORMAT);
    const dayDifference = getDaysAgo(now, estimatedArrivalDate);

    if (now.isSame(estimatedArrivalDate, 'day')) {
      return arrivalForecast === 'UNKNOWN' ? '' : forecastOptions.ESTIMATED_TODAY;
    } else if (dayDifference === 1) {
      return forecastOptions.ESTIMATED_YESTERDAY;
    } else if (dayDifference === -1) {
      return forecastOptions.ESTIMATED_TOMORROW;
    } else if (dayDifference > 1) {
      let defaultMessageWasEstimatedToArriveOn = forecastOptions.ESTIMATED_LATE.defaultMessage;
      let idWasEstimatedToArriveOn = forecastOptions.ESTIMATED_LATE.id;

      if (estimatedArrival.time !== null) {
        defaultMessageWasEstimatedToArriveOn = forecastOptions.ESTIMATED_LATE_WITH_TIME.defaultMessage;
        idWasEstimatedToArriveOn = forecastOptions.ESTIMATED_LATE_WITH_TIME.id;
      }

      return {
        defaultMessage: defaultMessageWasEstimatedToArriveOn,
        id: idWasEstimatedToArriveOn,
        values: {
          DATE: getFormattedDate(estimatedArrival.date),
          TIME: estimatedArrival.time,
          TIMEZONE: estimatedArrival.timezone,
        },
      };
    } else {
      let defaultMessageEstimatedToArrive = forecastOptions.ESTIMATED.defaultMessage;
      let idEstimatedToArrive = forecastOptions.ESTIMATED.id;

      if (estimatedArrival.time !== null) {
        defaultMessageEstimatedToArrive = forecastOptions.ESTIMATED_WITH_TIME.defaultMessage;
        idEstimatedToArrive = forecastOptions.ESTIMATED_WITH_TIME.id;
      }

      return {
        defaultMessage: defaultMessageEstimatedToArrive,
        id: idEstimatedToArrive,
        values: {
          DATE: getFormattedDate(estimatedArrival.date),
          TIME: estimatedArrival.time,
          TIMEZONE: estimatedArrival.timezone,
        },
      };
    }
  }
};

/**
 * Determines which date to use for last update date
 * @param lastStatusUpdate - full last status update object
 * @returns {object} formatted object with date information
 */
export const getLastUpdateDate = (lastStatusUpdate) => {
  const utcTimeKey = find(utcDateKeys, (key) => lastStatusUpdate[key]);
  const timezone = get(
    lastStatusUpdate,
    'address.locationCoordinatesDto.localTimeZoneIdentifier',
    get(lastStatusUpdate, 'address.postalCode')
      ? zipcode_to_timezone.lookup(lastStatusUpdate.address.postalCode)
      : moment.tz.guess()
  );

  const date = get(getDateTime(lastStatusUpdate, timezone), 'date');
  const time = get(getDateTime(lastStatusUpdate, timezone), 'time');

  return {
    date: date !== undefined ? date.format(MONTH_DAY_YEAR_FORMAT) : undefined,
    time: time !== undefined ? time.format(HOURS_MINUTES_FORMAT) : undefined,
    timezone: moment.tz(timezone).zoneAbbr(),
    utcTimestamp: lastStatusUpdate[utcTimeKey],
  };
};

/**
 * Determines which date to use from a stop object
 * @param stop - full stop object
 * @param type - string with stop type
 * @returns {object} formatted object with date information
 */
export const createStopDate = (stop, stopStatus, type) => {
  const timezone = getTimeZone(stop);

  const estimatedOrActualArrivalDateKey = find(arrivalDepartureDateKeys, (key) => stopStatus[key]);
  let date =
    type === 'PICKUP'
      ? get(stopStatus, 'localRecordedDepartureSplitDateTime.localDate')
      : get(stopStatus, `${estimatedOrActualArrivalDateKey}.localDate`);

  let time =
    type === 'PICKUP'
      ? get(stopStatus, 'localRecordedDepartureSplitDateTime.localTime')
      : get(stopStatus, `${estimatedOrActualArrivalDateKey}.localTime`);
  let timezoneAbbr = '';

  if (
    isNil(date) &&
    ((stop.localAppointmentEndDateTime && stop.localAppointmentStartDateTime && timezone !== undefined) ||
      (stop.utcAppointmentEndDateTime && stop.utcAppointmentStartDateTime))
  ) {
    // use appointment window as estimate
    date = get(getDateTime(stop, timezone), 'date');
    if (!isNil(date)) date = date.format(MONTH_DAY_YEAR_FORMAT);
    time = get(getDateTime(stop, timezone), 'time');
    if (!isNil(time)) time = time.format(HOURS_MINUTES_FORMAT);
  } else if (!isNil(date) && isNil(time)) {
    date = moment(date).format(MONTH_DAY_YEAR_FORMAT);
  }

  timezoneAbbr = timezone === undefined ? moment.tz(moment.tz.guess()).zoneAbbr() : moment.tz(timezone).zoneAbbr();

  return {
    date: date === undefined ? null : date,
    time: time === undefined ? null : time,
    timezone: timezoneAbbr,
    estimate: date === undefined ? null : date,
  };
};

/**
 * Determines the arrival estimate, defaults to the delivery window end date
 * @param dateRange - object containing startDate and endDate
 * @param arrivalEstimate - date string
 * @param flagOptions - object containing initial flagOptions values
 * @returns {object} - contains HEX color and text string
 */
export const createFlagData = (
  dateRange,
  arrivalEstimate,
  flagOptions,
  estimatedMinutesLate,
  currentStopArrivalCode
) => {
  let flagData = {
    color: get(flagOptions, 'color'),
    text: get(flagOptions, 'text'),
  };

  let arrivalStatus;

  // default to arrival estimate if available
  let newArrivalEstimate = get(arrivalEstimate, 'estimate', arrivalEstimate.date);

  if (dateRange && newArrivalEstimate && flagOptions.EARLY && flagOptions.LATE) {
    if (!isNil(currentStopArrivalCode)) {
      arrivalStatus = currentStopArrivalCode;
    } else {
      if (moment(newArrivalEstimate).valueOf() > moment(dateRange[flagOptions.LATE.indicator]).valueOf()) {
        arrivalStatus = 'LATE';
      } else if (moment(newArrivalEstimate).valueOf() < moment(dateRange[flagOptions.EARLY.indicator]).valueOf()) {
        arrivalStatus = 'EARLY';
      } else {
        arrivalStatus = 'ON_TIME';
      }
    }

    const arrivalDays = isNil(estimatedMinutesLate)
      ? getDaysDifference(dateRange[flagOptions[arrivalStatus].indicator], newArrivalEstimate)
      : getStringFromMinutes(estimatedMinutesLate); // XX day(s)

    flagData.color = flagOptions[arrivalStatus].color;
    flagData.text = flagOptions[arrivalStatus].text
      ? {
          defaultMessage: flagOptions[arrivalStatus].text.defaultMessage,
          id: flagOptions[arrivalStatus].text.id,
          values: {
            DAYS_OFFSET: arrivalDays,
          },
        }
      : undefined;
  }

  return flagData;
};

/**
 * Gets the late string
 *
 * @param minutes
 * @param {string} day(s)
 */
export const getStringFromMinutes = (minutes) => {
  return optimizeMinutes(minutes);
};

/**
 * Gets the difference between date range in days
 * @param start
 * @param end
 * @returns {string} day(s)
 */
export const getDaysDifference = (start, end) => {
  const momentStart = moment(start);
  const momentEnd = moment(end);
  let dayDifference = moment.duration(momentStart.diff(momentEnd, 'days'));
  dayDifference = Math.abs(dayDifference); // always return a positive number
  dayDifference = dayDifference === 0 ? 1 : dayDifference; // round day difference up to 1
  return dayDifference > 1 ? `${dayDifference} days` : `${dayDifference} day`;
};

const getLargeBrowserPercentageComplete = (stopsWithPopover) => {
  if (stopsWithPopover.length > 8) {
    return 64;
  } else if (stopsWithPopover.length > 5) {
    return 61;
  } else if (stopsWithPopover.length > 4) {
    return 58;
  } else if (stopsWithPopover.length > 3) {
    return 57;
  } else if (stopsWithPopover.length > 2) {
    return 55;
  }
  return 50;
};

const getSmallBrowserPercentageComplete = (stopsWithPopover) => {
  if (stopsWithPopover.length > 8) {
    return 60;
  } else if (stopsWithPopover.length > 6) {
    return 58;
  } else if (stopsWithPopover.length > 4) {
    return 56;
  } else if (stopsWithPopover.length > 3) {
    return 54;
  } else if (stopsWithPopover.length > 2) {
    return 52;
  }
  return 48;
};

/**
 * Creates percentage completion for map marker placement
 *
 * If shipment has left last terminal but in transit to delivery and has stops that are displayed as popovers (more than 4 stops)
 * We hardcode to 75% because of the space the popover dots take up in status card
 *
 * @param stops
 * @returns {number}
 */
export const createPercentageComplete = (stops) => {
  // If more than 4 stops, popovers are created
  if (stops.length > 4) {
    const stopsWithPopover = stops.filter((stop) => stop.popover);
    const delivery = stops[stops.length - 1];
    const lastTerminal = stops[stops.length - 2];
    // Edge cases where the shipment has popovers but isn't at delivery yet
    // Quick fix for TRK-1001 until new shipment details page is released which has a revamped status bar
    if (stopsWithPopover.length > 0 && !delivery.isCompleted) {
      const browserWidth = window.innerWidth;
      if (lastTerminal.isCompleted) {
        return 75;
      } else if (browserWidth > 1200) {
        return getLargeBrowserPercentageComplete(stopsWithPopover);
      }
      return getSmallBrowserPercentageComplete(stopsWithPopover);
    }
  }
  const stopsCompleted = stops.filter((stop) => stop.isCompleted);
  return (stopsCompleted.length / stops.length) * 100;
};

/**
 * Computes the last updated minutes based on the current time and from last update time
 *
 * @param utcDate
 */
export const getLastUpdateTimeInMinutes = (lastUpdateUtcDate) => {
  if (!isNil(lastUpdateUtcDate)) {
    return convertMinutes(new moment.utc(lastUpdateUtcDate), moment());
  }
};

/**
 * Converts minutes to days or hours if applicable
 * @param fromUtcDate
 * @param toUtcDate
 * @returns {string} minute(s) / hour(s) / day(s)
 */

const messages = defineMessages({
  dayPlural: {
    id: 'shipmentList.mappingUtils.dayPlural',
    defaultMessage: '{ timeValue, number } { timeValue, plural, one {day} other {days} }',
  },
  hoursPlural: {
    id: 'shipmentList.mappingUtils.hoursPlural',
    defaultMessage: '{ timeValue, number } { timeValue, plural, one {hour} other {hours} }',
  },
  minPlural: {
    id: 'shipmentList.mappingUtils.minPlural',
    defaultMessage: '{ timeValue, number } { timeValue, plural, one {minute} other {minutes} }',
  },
});

export const convertMinutes = (fromUtcDate, toUtcDate) => {
  if (!isNil(fromUtcDate) && !isNil(toUtcDate)) {
    const from = new moment(fromUtcDate).valueOf();
    const to = new moment(toUtcDate).valueOf();
    let minutes = Math.round((to - from) / 60000);
    let timeValue;
    if (minutes > 60) {
      if (minutes >= 1440) {
        // 1 day or greater, return days
        timeValue = Math.round(minutes / 1440);
        // return `${timeValue} ${timeValue > 1 ? 'days' : 'day'}`;
        return {
          message: messages.dayPlural,
          values: { timeValue: timeValue },
        };
      } else {
        // return hours
        timeValue = Math.round(minutes / 60);
        // return `${timeValue} ${timeValue > 1 ? 'hours' : 'hour'}`;
        return {
          message: messages.hoursPlural,
          values: { timeValue: timeValue },
        };
      }
    } else {
      // return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
      return {
        message: messages.minPlural,
        values: { timeValue: minutes },
      };
    }
  }
};
