import isNil from 'lodash/isNil';
import { FastField, FieldProps, Field } from 'formik';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, IntlShape } from 'react-intl';
import styled from 'styled-components';

import { EditFilled } from '@ant-design/icons';
import { Input, Label } from 'components/common/forms/FormElements';
import { Button, SubHeader } from 'ui-components';
import * as styles from './LocationDetailsComponent.module.scss';

interface HeaderProps {
  isEditMode: boolean;
  canEditLocation: boolean;
  intl: IntlShape;
  name: string;
  isValid: boolean;
  type: string | undefined;
  companyName: string | undefined;
  specifiedId: string | undefined;
  handleSubmit: () => void;
  toggleEditMode: (val: boolean) => void;
  toggleIsResetting: (val: boolean) => void;
  updateUserEnteredAddress: (address: any) => void;
  updatelatLngInputValue: (latLng: string | undefined) => void;
  updateUseAddressLatLngInMap: (useAddressLatLng: boolean) => void;
}
const MetaInfo = styled.div`
  font-size: 14px;
`;

const Header = ({
  isEditMode,
  canEditLocation,
  intl,
  toggleIsResetting,
  toggleEditMode,
  updateUseAddressLatLngInMap,
  updatelatLngInputValue,
  updateUserEnteredAddress,
  companyName,
  name,
  specifiedId,
  type,
  isValid,
  handleSubmit,
}: HeaderProps) => {
  const EditSaveButtons = () => {
    if (isEditMode)
      return (
        <Col lg={24}>
          <Row>
            <Col sm={24} className={styles.headerButtons}>
              <Button
                type="secondary"
                clickFn={() => {
                  toggleIsResetting(true);
                  updateUserEnteredAddress(undefined);
                  updatelatLngInputValue(undefined);
                  updateUseAddressLatLngInMap(true);
                  toggleEditMode(false);
                }}
                className="button-margin d-inline mr-4"
              >
                <FormattedMessage id="locationDetails.locationDetailsAddressHeader.cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                type="primary"
                // eslint-disable-next-line react/jsx-no-bind
                clickFn={handleSubmit}
                className="button-margin"
                style={{ display: 'inline-block' }}
                disabled={!isValid}
              >
                <FormattedMessage id="locationDetails.locationDetailsAddressHeader.save" defaultMessage="Save" />
              </Button>
            </Col>
          </Row>
        </Col>
      );
    return (
      <Col lg={24}>
        <Row>
          <Col sm={24} className={styles.headerButtons}>
            <Button type="default" clickFn={toggleEditMode} className="button-margin d-inline mr-4">
              <div className={'d-flex align-items-center'}>
                <EditFilled style={{ marginRight: '.5rem' }} />
                <FormattedMessage id="locationDetails.locationDetailsAddressHeader.edit" defaultMessage="Edit" />
              </div>
            </Button>
          </Col>
        </Row>
      </Col>
    );
  };
  return (
    <SubHeader>
      <Row className={'align-items-center'}>
        <Col lg={17} sm={24}>
          <Row>
            <Col sm={{ offset: 1 }}>
              {!isEditMode && <h1 data-locator="location-name">{name}</h1>}
              {isEditMode && (
                <FastField name="name">
                  {({ field, form }: FieldProps) => (
                    <>
                      <Label inputid="locationName">
                        {intl.formatMessage({
                          id: 'locationDetails.edit.labels.locationName',
                          defaultMessage: 'Location Name',
                        })}
                      </Label>
                      <Input
                        {...field}
                        value={name}
                        placeholder={intl.formatMessage({
                          id: 'locationDetails.edit.placeholders.locationName',
                          defaultMessage: 'Location Name',
                        })}
                        id="locationName"
                        style={{
                          fontSize: '2.5rem',
                          marginBottom: '1rem',
                          display: 'inline-block',
                          width: '60%',
                        }}
                      />
                    </>
                  )}
                </FastField>
              )}
            </Col>
          </Row>
          <MetaInfo>
            <Row>
              {companyName && (
                <Col>
                  <FormattedMessage
                    defaultMessage="Company"
                    id="locationDetails.locationDetailsAddressHeader.company"
                  />{' '}
                  {!isEditMode && <strong>{companyName}</strong>}
                  {isEditMode && (
                    <FastField name="companyName">
                      {({ field, form }: FieldProps) => (
                        <Input
                          {...field}
                          value={field.value}
                          style={{ width: '20%' }}
                          placeholder={intl.formatMessage({
                            id: 'locationDetails.edit.placeholders.companyName',
                            defaultMessage: 'Company Name',
                          })}
                        />
                      )}
                    </FastField>
                  )}
                </Col>
              )}

              {type && (
                <Col>
                  <FormattedMessage defaultMessage="Type" id="locationDetails.locationDetailsAddressHeader.type" />{' '}
                  <strong>{type}</strong>
                </Col>
              )}

              {((!isNil(specifiedId) && specifiedId.trim().length > 0) || isEditMode) && (
                <Col sm={{ offset: 1 }}>
                  {!isEditMode && (
                    <>
                      <FormattedMessage
                        defaultMessage="Location ID"
                        id="locationDetails.locationDetailsAddressHeader.id"
                      />{' '}
                      <strong>{specifiedId}</strong>
                    </>
                  )}
                  {isEditMode && (
                    <Field name="specifiedId">
                      {({ field, form }: FieldProps) => (
                        <>
                          <Label>
                            <FormattedMessage
                              defaultMessage="Location ID"
                              id="locationDetails.locationDetailsAddressHeader.id"
                            />
                          </Label>
                          <Input {...field} value={field.value} style={{ width: '20%' }} />
                        </>
                      )}
                    </Field>
                  )}
                </Col>
              )}
            </Row>
          </MetaInfo>
        </Col>

        {canEditLocation && (
          <Col lg={7} sm={12}>
            <Row>
              <EditSaveButtons />
            </Row>
          </Col>
        )}
      </Row>
    </SubHeader>
  );
};
export default Header;
