import qs from 'querystring';
import { AxiosError } from 'axios';
import { useState, useEffect } from 'react';
import paxios from 'util/paxios';

import { API_PATH } from 'common/AppConstants';
import { SHIPMENT_SEARCH_SUGGESTIONS_PREFIX } from '../ducks/types';

interface Options {
  resetStateOnFetch?: boolean;
  endpointBody?: string;
}

export function useSuggestions(resourceName: string, parameters: string, options: Options = {}): string[] {
  const [defaultSuggestions, setSuggestions] = useState([]);
  const { resetStateOnFetch = false, endpointBody = SHIPMENT_SEARCH_SUGGESTIONS_PREFIX } = options;

  useEffect(() => {
    const params = parameters ? qs.parse(parameters) : {};

    resetStateOnFetch && setSuggestions([]);

    paxios({
      method: 'GET',
      url: `${API_PATH}/${endpointBody}/${resourceName}`,
      withCredentials: true,
      params,
    })
      .then(({ data = [] }) => setSuggestions(data))
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [endpointBody, parameters, resetStateOnFetch, resourceName]);

  return defaultSuggestions;
}

export default useSuggestions;
