const FETCH_SHIPMENT_DATA = 'OCEANDetails/duck/FETCH_SHIPMENT_DATA';
const FETCH_SHIPMENT_DATA_SUCCESS = 'OCEANDetails/duck/FETCH_SHIPMENT_DATA_SUCCESS';
const FETCH_SHIPMENT_DATA_FAILURE = 'OCEANDetails/duck/FETCH_SHIPMENT_DATA_FAILURE';
const FETCH_PUBLIC_SHIPMENT_DATA = 'OCEANDetails/duck/FETCH_PUBLIC_SHIPMENT_DATA';
const FETCH_PUBLIC_SHIPMENT_DATA_SUCCESS = 'OCEANDetails/duck/FETCH_PUBLIC_SHIPMENT_DATA_SUCCESS';
const FETCH_PUBLIC_SHIPMENT_DATA_FAILURE = 'OCEANDetails/duck/FETCH_PUBLIC_SHIPMENT_DATA_FAILURE';
const TOGGLE_ROUTE_DISPLAY = 'OCEANDetails/duck/TOGGLE_ROUTE_DISPLAY';
const TOGGLE_GROUPED_DISPLAY = 'OCEANDetails/duck/TOGGLE_GROUPED_DISPLAY';
const RESET_SHIPMENT = 'OCEANDetails/duck/RESET_SHIPMENT';

export default {
  FETCH_SHIPMENT_DATA,
  FETCH_SHIPMENT_DATA_SUCCESS,
  FETCH_SHIPMENT_DATA_FAILURE,
  FETCH_PUBLIC_SHIPMENT_DATA,
  FETCH_PUBLIC_SHIPMENT_DATA_SUCCESS,
  FETCH_PUBLIC_SHIPMENT_DATA_FAILURE,
  TOGGLE_ROUTE_DISPLAY,
  TOGGLE_GROUPED_DISPLAY,
  RESET_SHIPMENT,
};
