import React from 'react';
import ReactDom from 'react-dom';
import { App } from './app';

// TODO: Figure out how to get passthrough() to not intercept EVERY request
// Uncomment these next lines to enable a mock API
// import { makeMirageServer } from './mirage/server';
// if (process.env.NODE_ENV === 'development') {
//   makeMirageServer();
// }

// Polyfills
const polyfill = global.Intl ? Promise.resolve() : import('./i18n/intlPolyfills');

// Start react app
polyfill.then(() => {
  ReactDom.render(<App />, document.getElementById('root'));
});
