import { put, takeLatest, call } from 'redux-saga/effects';
import isNil from 'lodash/isNil';
import axios from '../../../util/paxios';

import { API_PATH } from '../../../common/AppConstants';

import * as actions from './actions';
import * as types from './types';

import CarrierDetailsStateMapper from './mappers/carrierDetailsStateMapper';
import AddressStateMapper from './mappers/addressStateMapper';
import AddEditShipmentApiMapper from './mappers/addShipmentApiStateMapper';

const CARRIER_DETAILS_API_PATH = `${API_PATH}/carrier/details`;
const POSTAL_CODE_LOOKUP_API_PATH = `${API_PATH}/location/city`;
const ADD_SHIPMENT_API_PATH = `${API_PATH}/shipment`;

const editShipmentStopUrl = (shipmentId, stopNumber) => `${API_PATH}/shipment/${shipmentId}/stop/${stopNumber}`;

const carrierQueryFromAction = (action) => ({
  idType: action.formData.carrier.entered.type,
  idValue: action.formData.carrier.entered.value,
});

export const isValidPostalCode = (postalCode) => !!postalCode && postalCode.length >= 5;

const chooseFirstLookupResultOrThrow = (postalDetailResults) => {
  if (postalDetailResults.length) {
    return postalDetailResults[0];
  }

  const exception = { message: 'No postal code results' };
  throw exception;
};

export function* validateCarrierAndGetTrackingMethods(action) {
  const query = carrierQueryFromAction(action);
  yield put(actions.searchCarrier(query));
  let response;
  try {
    response = yield call(getCarrierDetailsByCriteria, query);
    const mappedResponse = CarrierDetailsStateMapper.mapApiToState(response.data);
    yield put(actions.searchCarrierSuccess(mappedResponse));
  } catch (e) {
    yield put(actions.searchCarrierFailed(query));
  }
}

export function getCarrierDetailsByCriteria(query) {
  return axios.get(CARRIER_DETAILS_API_PATH, {
    params: query,
    withCredentials: true,
  });
}

export function* postalCodeEnteredAsync(action) {
  const postalCode = action.payload.postalCode;
  const stopName = action.payload.stop;
  const context = action.payload.context;

  if (!isValidPostalCode(postalCode)) {
    return;
  }

  yield put(actions.postalCodeLookupInProgress(context, stopName, postalCode));

  try {
    const response = yield call(lookupPostalCode, postalCode);

    const postalCodeDetails = chooseFirstLookupResultOrThrow(AddressStateMapper.mapApiToState(response.data));

    yield put(actions.postalCodeLookupSuccess(context, stopName, postalCode, postalCodeDetails));
  } catch (e) {
    yield put(actions.postalCodeLookupFailed(context, stopName, postalCode));
  }
}

export function lookupPostalCode(postalCode) {
  return axios.get(POSTAL_CODE_LOOKUP_API_PATH, {
    params: { postalCode },
    withCredentials: true,
  });
}

export function* saveShipmentAsync(action) {
  yield put(actions.saveShipmentInProgress(action.payload));

  try {
    const addShipmentDto = AddEditShipmentApiMapper.mapStateToApi(action.payload);
    const response = yield call(addShipment, addShipmentDto);

    const mappedResponse = AddEditShipmentApiMapper.mapAddApiResponseToState(response.data);
    yield put(actions.saveShipmentSuccess(mappedResponse));
  } catch (e) {
    const mappedErrorResponse = AddEditShipmentApiMapper.mapApiErrorResponseToState(e);
    yield put(actions.saveShipmentFailed(mappedErrorResponse));
  }
}

export function addShipment(addShipmentDto) {
  // axios.post does not pass the credentials so need to use the axios function
  return axios({
    method: 'POST',
    url: ADD_SHIPMENT_API_PATH,
    data: addShipmentDto,
    withCredentials: true,
  });
}

export function editShipmentStop(shipmentId, stopNumber, editStopDto) {
  return axios({
    method: 'PUT',
    url: editShipmentStopUrl(shipmentId, stopNumber),
    data: editStopDto,
    withCredentials: true,
  });
}

export function* editShipmentStopAsync(action) {
  const { shipmentId, stopNumber, stopData } = action.payload;
  if (isNil(shipmentId) || isNil(stopNumber) || isNil(stopData)) {
    yield put(
      actions.saveStopEditFailed(shipmentId, stopNumber, {
        errors: [`invalid arguments: shipmentId=${shipmentId};stopNumber=${stopNumber};stopData=${stopData}`],
      })
    );
    return;
  }

  yield put(actions.saveStopEditStarted(shipmentId, stopNumber, stopData));

  try {
    const editStopDto = AddEditShipmentApiMapper.mapEditStateToApi(stopData);
    yield call(editShipmentStop, shipmentId, stopNumber, editStopDto);

    yield put(actions.saveStopEditSuccess(shipmentId, stopNumber, editStopDto));
  } catch (e) {
    const mappedErrorResponse = AddEditShipmentApiMapper.mapApiErrorResponseToState(e);
    yield put(actions.saveStopEditFailed(shipmentId, stopNumber, mappedErrorResponse));
  }
}

export function* watchCarrierValidation() {
  yield takeLatest(types.CARRIER_VALIDATION, validateCarrierAndGetTrackingMethods);
}

export function* watchPostalCodeEntered() {
  yield takeLatest(types.POSTAL_CODE_ENTERED, postalCodeEnteredAsync);
}

export function* watchSaveShipment() {
  yield takeLatest(types.SAVE_SHIPMENT, saveShipmentAsync);
}

export function* watchEditStop() {
  yield takeLatest(types.SAVE_SHIPMENT_STOP_EDIT, editShipmentStopAsync);
}
