import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { WrappedComponentProps } from 'react-intl';
import { SelectValue } from 'antd/lib/select';
import uniq from 'lodash/uniq';
import {
  ShipmentModeEnum,
  ShipmentTenantAttribute,
  TenantAttribute,
  Identifier,
  ShipmentTenantAttributeRequest,
  TenantCustomAttribute,
  ShipmentLegCarrierInfo,
} from 'models';
import { RootState } from '../../../reducers';
import ShipmentIdentifiers from './ShipmentIdentifiers';
import { tenantAttributesActions } from './ducks';
import { mapCustomAttributesToShipmentTenantAttributes } from '../utils';

export interface ShipmentIdentifiersOwnProps {
  shipmentId?: string;
  masterShipmentId?: string;
  identifiers: Identifier[];
  onRef?: (ref: any) => void;
  tenantCustomAttributes?: TenantCustomAttribute[];
  carrierInfo?: ShipmentLegCarrierInfo[];
  mode: ShipmentModeEnum;
  shipmentShareToken?: string;
}

export interface ShipmentIdentifiersStateProps
  extends ShipmentIdentifiersOwnProps,
    RouteComponentProps<any>,
    WrappedComponentProps {
  tenantAttributes?: TenantAttribute[];
  shipmentTenantAttributes?: ShipmentTenantAttribute[];
  customShipmentTenantAttributes?: ShipmentTenantAttribute[];
  isFetchingTenantAttributes?: boolean;
  isFetchingShipmentTenantAttributes?: boolean;
  isCreatingOrUpdatingShipmentTenantAttributes?: boolean;
  shipmentTenantAttributesEditMode: boolean;
  principal?: any;
}

export interface ShipmentIdentifiersDispatchProps {
  fetchTenantAttributes: () => void;
  fetchShipmentTenantAttributes: () => void;
  createOrUpdateShipmentTenantAttributes: (shipmentTenantAttributes: ShipmentTenantAttributeRequest[]) => void;
  resetTenantAttributes: () => void;
  onRef?: (ref: any) => void;
}

const mapStateToProps = (state: RootState, ownProps: ShipmentIdentifiersOwnProps) => {
  return {
    tenantAttributes: state.tenantAttributesReducer.tenantAttributes,
    shipmentTenantAttributes: [...(state.tenantAttributesReducer.shipmentTenantAttributes || [])],
    customShipmentTenantAttributes: [...mapCustomAttributesToShipmentTenantAttributes(ownProps.tenantCustomAttributes)],
    isFetchingTenantAttributes: state.tenantAttributesReducer.isFetchingTenantAttributes,
    isFetchingShipmentTenantAttributes: state.tenantAttributesReducer.isFetchingShipmentTenantAttributes,
    isCreatingOrUpdatingShipmentTenantAttributes:
      state.tenantAttributesReducer.isCreatingOrUpdatingShipmentTenantAttributes,
    shipmentTenantAttributesEditMode: state.tenantAttributesReducer.shipmentTenantAttributesEditMode,
    shipmentId: ownProps.shipmentId,
    identifiers: ownProps.identifiers,
    carrierInfo: ownProps.carrierInfo,
    principal: state.authReducer.principal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ShipmentIdentifiersOwnProps) => ({
  fetchTenantAttributes: () => {
    dispatch(tenantAttributesActions.fetchTenantAttributes.request());
  },
  fetchShipmentTenantAttributes: () => {
    /* TODO: Remove if check once mode agnostic page is done -
     tenantCustomAttributes can be deprecated in favor of shipmentTenantAttributes after full #intermodal
     turnover when portal-v2-service's getShipmentTenantAttributes has been updated to accept MSID. until then,
     tenantCustomAttributes are pulled directly off of the shipment and are display only (not editable) */
    if (ownProps.shipmentId !== undefined || ownProps.masterShipmentId !== undefined) {
      dispatch(
        tenantAttributesActions.fetchShipmentTenantAttributes.request({
          shipmentId: ownProps.shipmentId,
          masterShipmentId: ownProps.masterShipmentId,
        })
      );
    }
  },
  createOrUpdateShipmentTenantAttributes: (shipmentTenantAttributes: ShipmentTenantAttributeRequest[]) => {
    if (ownProps.shipmentId !== undefined) {
      dispatch(
        tenantAttributesActions.createOrUpdateShipmentTenantAttributes.request({
          shipmentId: ownProps.shipmentId,
          masterShipmentId: ownProps.masterShipmentId,
          shipmentTenantAttributes: getSanitizedAttributes(shipmentTenantAttributes),
        })
      );
    }
  },
  resetTenantAttributes: () => {
    dispatch(tenantAttributesActions.reset());
  },
  onRef: ownProps.onRef,
});

/*
 For attributes sharing the same name, a single non duplicated array of values will be kept.
 It will help avoiding duplicated values
 e.g attr1: [v1, v2], attr1: [v1, v3], attr2: [vv1] --> attr1: [v1, v2, v3], attr2: [vv1] 
*/
const getSanitizedAttributes = (shipmentTenantAttributes: ShipmentTenantAttributeRequest[]) => {
  const sanitizedAttributesMap = new Map<SelectValue, string[]>();
  shipmentTenantAttributes.forEach((attribute) => {
    const stored = sanitizedAttributesMap.get(attribute.name);
    sanitizedAttributesMap.set(attribute.name, stored ? stored.concat(attribute.values) : attribute.values);
  });
  const sanitizedAttributes: ShipmentTenantAttributeRequest[] = [];
  sanitizedAttributesMap.forEach((value, key) => sanitizedAttributes.push({ name: key, values: uniq(value) }));
  return sanitizedAttributes;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShipmentIdentifiers));
