import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { AxiosPromise, AxiosResponse } from 'axios';
import axios from '../../../util/paxios';
import { API_PATH } from '../../AppConstants';
import * as actions from './actions';
import { AerisWeatherConfig } from '../models/AerisWeatherConfig';

export const fetchConfig = (): AxiosPromise<AerisWeatherConfig> => {
  return axios({
    method: 'GET',
    url: `${API_PATH}/profile/aerisConfig`,
    withCredentials: true,
  });
};

export function* fetchConfigAsync() {
  try {
    const axiosResponse: AxiosResponse<AerisWeatherConfig> = yield call(fetchConfig);

    const config = axiosResponse.data;
    if (config != null) {
      yield put(actions.fetch.success(config));
    }
  } catch (error) {
    yield put(actions.fetch.failure());
  }
}

export function* watchConfigAsync() {
  yield takeEvery(getType(actions.fetch.request), fetchConfigAsync);
}

export default function* aerisWeatherConfigOperations() {
  yield fork(watchConfigAsync);
}
