import * as React from 'react';
import styled from 'styled-components';
import { primaryBlue } from 'styles/colors';

interface ContactProps {
  contactName: string;
  phoneNumber: string;
  email: string;
}
const StyledContactContainer = styled.div`
  margin-bottom: 10px;
`;
const StyledInfo = styled.p`
  font-size: 1.6rem;
  margin-bottom: 0;
  padding-bottom: 8px;

  a {
    color: ${primaryBlue};
    font-size: 1.4rem;
    font-weight: bold;
  }
`;
const Contact: React.FC<ContactProps> = ({ contactName, phoneNumber, email }) => {
  return (
    <StyledContactContainer>
      <StyledInfo>{contactName}</StyledInfo>
      <StyledInfo>{phoneNumber}</StyledInfo>
      <StyledInfo>
        <a href={`mailto:${email}`}>{email}</a>
      </StyledInfo>
    </StyledContactContainer>
  );
};

export default Contact;
