import endpoints from 'common/endpoints';

import { ReactComponent as MovementLogo } from 'components/common/assets/movement-logo.svg';
import { ReactComponent as MovementNewBadgeTag } from 'components/common/assets/new_badge_tag.svg';
// import { ReactComponent as OpenNewWhiteIcon } from 'components/common/assets/icons/icon-open-in-new-white.svg';
import { ReactComponent as CloseIcon } from 'components/common/assets/icons/icon-close.svg';

import './MovementBanner.scss';

interface MovementBannerProps {
  onMovementBannerClose: () => void;
}

const MovementBanner: React.FC<MovementBannerProps> = ({ onMovementBannerClose }) => (
  <div className="movement-banner">
    <div className="d-flex align-items-center movement-banner-container">
      <MovementLogo className="movement-logo" />
      <MovementNewBadgeTag className="new-badge" />
      <div className="description">
        Try Movement by project44, our next generation visibility platform
        <span> — now available for free to all customers.</span>
      </div>
      <a href={endpoints.MOVEMENT_PREVIEW_URL} rel="noreferrer" target="_blank" className="preview-btn">
        Preview Movement
      </a>
      {/* Commenting below section landing url is not avalaible now */}
      {/* <a href="/" target="_blank" rel="noreferrer" className="learn-more-btn">
        <div className="d-flex learn-more-btn-container">
          <div className="learn-more-btn-text">Learn more</div>
          <OpenNewWhiteIcon style={{ marginLeft: 8 }} />
        </div>
      </a> */}
    </div>
    <button className="close-icon" onClick={onMovementBannerClose}>
      <CloseIcon height={16} width={16} />
    </button>
  </div>
);

export default MovementBanner;
