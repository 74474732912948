import moment from 'moment';

import defaultTheme from '../defaultTheme';
import { Theme, ThemeResponse } from '../models/Theme';

export default (response: ThemeResponse): Theme => {
  let landingColor = defaultTheme.landingColor;
  if (response.primaryColor && response.primaryColor !== defaultTheme.primaryColor) {
    landingColor = response.primaryColor;
  }

  let footerColor = defaultTheme.footerColor;
  if (response.primaryColor && response.primaryColor !== defaultTheme.primaryColor) {
    footerColor = response.primaryColor;
  }
  // Add a timestamp to logo url since the url doesn't change when uploading new logos, forces browser to reload it
  return {
    logoUrl: response.logoUrl ? `${response.logoUrl}?timestamp=${moment().valueOf()}` : defaultTheme.logoUrl,
    isDefaultLogo: response.logoUrl === null,
    landingColor,
    footerColor,
    primaryColor: response.primaryColor || defaultTheme.primaryColor,
    secondaryColor: response.secondaryColor || defaultTheme.secondaryColor,
    emailFooter: response.emailFooter || defaultTheme.emailFooter,
    logoEnabled: response.logoEnabled,
  };
};
