import { Field, FormSection } from 'redux-form';
import get from 'lodash/get';
import { renderTemperatureSpinner } from '../../common/forms/FormInputs';

import './AddEditShipmentTempForm.scss';
import { convertToScale } from '../../../common/temperatureUtils';
import { TEMPERATURE_SCALE } from '../../../common/AppConstants';

const handleTemperatureScaleChange = (event, props, scale) => {
  if (props.formValues.temperatureForm.temperatureScale !== scale) {
    let temperatureForm = props.formValues.temperatureForm;
    temperatureForm.lowerBound = convertToScale(scale, temperatureForm.lowerBound);
    temperatureForm.targetTemp = convertToScale(scale, temperatureForm.targetTemp);
    temperatureForm.upperBound = convertToScale(scale, temperatureForm.upperBound);
    temperatureForm.temperatureScale = scale;
    props.setTemperatureScale(temperatureForm);
  }
};

const renderSpinnerControls = (props) => {
  if (
    props &&
    props.formValues &&
    props.formValues.temperatureForm &&
    props.formValues.temperatureForm.temperatureScale
  ) {
    return (
      <div className="input-row temperature-control-row">
        <Field
          label="Lower Bound"
          component={renderTemperatureSpinner}
          scale={props.formValues.temperatureForm.temperatureScale}
          min="-99"
          max="99"
          name="lowerBound"
          spinnerClass="spinner-font-lower-bound"
        />

        <Field
          label="Target Temp"
          component={renderTemperatureSpinner}
          scale={props.formValues.temperatureForm.temperatureScale}
          min="-99"
          max="99"
          name="targetTemp"
          spinnerClass="spinner-font-target-temp"
        />

        <Field
          label="Upper Bound"
          component={renderTemperatureSpinner}
          scale={props.formValues.temperatureForm.temperatureScale}
          min="-99"
          max="99"
          name="upperBound"
          spinnerClass="spinner-font-upper-bound"
        />
      </div>
    );
  } else {
    return null;
  }
};

const renderButtonControls = (props) => {
  if (
    props &&
    props.formValues &&
    props.formValues.temperatureForm &&
    props.formValues.temperatureForm.temperatureScale
  ) {
    return (
      <div className="input-row temperature-change-button-row">
        {/* eslint-disable-next-line */}
        <div
          className={`temperature-button ${
            get(
              props,
              'formValues.temperatureForm.temperatureScale',
              props.formValues.temperatureForm.temperatureScale
            ) === TEMPERATURE_SCALE.FAHRENHEIT
              ? 'temperature-button-active'
              : ''
          }`}
          onClick={(event) => handleTemperatureScaleChange(event, props, TEMPERATURE_SCALE.FAHRENHEIT)}
        >
          F&deg;
        </div>
        {/* eslint-disable-next-line */}
        <div
          className={`temperature-button ${
            get(
              props,
              'formValues.temperatureForm.temperatureScale',
              props.formValues.temperatureForm.temperatureScale
            ) === TEMPERATURE_SCALE.CELSIUS
              ? 'temperature-button-active'
              : ''
          }`}
          onClick={(event) => handleTemperatureScaleChange(event, props, TEMPERATURE_SCALE.CELSIUS)}
        >
          C&deg;
        </div>
        <Field type="hidden" component="input" name="temperatureScale" />
      </div>
    );
  } else {
    return null;
  }
};

const AddEditShipmentTempFormSection = (props) => {
  return (
    <FormSection name={props.formName}>
      <div className="shipment-temp-form">
        <h4 className="form-header">Temperature Tracking</h4>
        <div className="the-section-text small-text">
          The carrier “{props.formValues.carrierId}” provides services for refrigerated shipments.
          <br />
          Enter temperature tracking range and target temperature.
        </div>
        {renderSpinnerControls(props)}

        {renderButtonControls(props)}
      </div>
    </FormSection>
  );
};

export default AddEditShipmentTempFormSection;
