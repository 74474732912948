import './SelectedChips.scss';

import { Chips } from 'ui-components';

const SelectedChips = ({ listData, showAllChips, passedInDeleteFn }) => {
  return (
    <div className="selected-chips">
      <Chips listData={listData} showCount={showAllChips} deleteFn={passedInDeleteFn} />
    </div>
  );
};

export default SelectedChips;
