import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { ShipmentModeEnum } from 'models';
import { ShipmentShareEmail } from '../models/ShipmentShareEmail';
import { PrincipalAuthorizations } from '../../../common/authorizations';

export interface FetchShareLinkPayload {
  shipmentId?: string;
  shipmentMode?: ShipmentModeEnum;
  masterShipmentId?: string;
  authorizations: PrincipalAuthorizations;
}

export const fetchShareLink = createAsyncAction(
  'SHIPMENT_SHARE/FETCH_SHARE_LINK',
  'SHIPMENT_SHARE/FETCH_SHARE_LINK_SUCCESS',
  'SHIPMENT_SHARE/FETCH_SHARE_LINK_FAILURE'
)<FetchShareLinkPayload, { shareToken: string; shipmentId: string; authorizations: PrincipalAuthorizations }, Error>();

export const sendShareEmail = createAsyncAction(
  'SHIPMENT_SHARE/SEND_SHARE_EMAIL',
  'SHIPMENT_SHARE/SEND_SHARE_EMAIL_SUCCESS',
  'SHIPMENT_SHARE/SEND_SHARE_EMAIL_FAILURE'
)<ShipmentShareEmail, void, Error>();

export const reset = createStandardAction('SHIPMENT_SHARE/RESET')<void>();
export const toggleShareWindow = createStandardAction('SHIPMENT_SHARE/TOGGLE_SHARE_WINDOW')<void>();
