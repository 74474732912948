import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { RouteComponentProps } from 'react-router-dom';
import Label from 'components/EventDatePicker/Label';
import shipmentCountsTypes from 'components/shipmentCounts/ducks/types';
import { shipmentCountsActions } from 'components/shipmentCounts/ducks';
import { Radio } from 'ui-components';

import * as styles from './styles.module.scss';
import { PresetDate } from './types';
import { PRESET_DATES } from './constants';
import { getStepDateKey } from '../utils';

type EventNameSpace = 'pickup' | 'delivery' | 'oceanLastFree';

const ACTION_TYPE_KEYS = {
  subtotal: {
    pickup: 'QUICK_VIEW_CHANGE_PICKUP_DATE',
    delivery: 'QUICK_VIEW_CHANGE_DELIVERY_DATE',
    oceanLastFree: 'QUICK_VIEW_CHANGE_LAST_FREE_DATE',
  },
  total: {
    pickup: 'QUICK_VIEW_CHANGE_TOTAL_PICKUP_DATE',
    delivery: 'QUICK_VIEW_CHANGE_TOTAL_DELIVERY_DATE',
    oceanLastFree: 'QUICK_VIEW_CHANGE_TOTAL_LAST_FREE_DATE',
  },
};

const getActionType = (currentStep: 'subtotal' | 'total', eventNamespace: EventNameSpace) => {
  const matchKey = ACTION_TYPE_KEYS[currentStep][eventNamespace];
  return shipmentCountsTypes[matchKey as keyof typeof shipmentCountsTypes];
};

interface RouteParams {
  step: 'subtotal' | 'total';
}
interface PresetDateSelectProps extends RouteComponentProps<RouteParams> {
  metaData: { eventNamespace: EventNameSpace };
  handleDatePickerChange: (date: string | undefined, target: string) => void;
  currentTile: any;
}

const PresetDateSelect = ({ metaData, handleDatePickerChange, match, currentTile }: PresetDateSelectProps) => {
  const { eventNamespace } = metaData;

  const currentStep = match.params.step;
  const initialValue = currentTile[getStepDateKey(currentStep, eventNamespace)];

  const [presetDate, setPresetDate] = useState<PresetDate>(initialValue);
  const dispatch = useDispatch();

  const startDateName = `${eventNamespace}DateStart`;
  const endDateName = `${eventNamespace}DateEnd`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = event.target.value as PresetDate;

    setPresetDate(selectedOption);

    const actionType = getActionType(currentStep, eventNamespace);
    if (actionType) {
      dispatch(shipmentCountsActions.changeSelectedDate(actionType, selectedOption));
    }

    handleDatePickerChange(PRESET_DATES[selectedOption].startDate, startDateName);
    handleDatePickerChange(PRESET_DATES[selectedOption].endDate, endDateName);
  };

  return (
    <div className={cn('d-flex justify-content-center w-100')}>
      <div className={cn('d-flex flex-column flex-fill', styles['date-column'])}>
        <Label className="mb-4" htmlFor="past-date">
          <FormattedMessage id="datePicker.presetDates.past" defaultMessage="Past" />
        </Label>
        <Radio
          id="past-date"
          className={styles['date-radio-group']}
          layout="column"
          onChange={handleChange}
          value={presetDate}
          radioData={[
            {
              value: 'YESTERDAY',
              label: <FormattedMessage id="quickView.dateRangeCondes.yesterday" defaultMessage="Yesterday" />,
            },
            {
              value: 'LAST_WEEK',
              label: <FormattedMessage id="datePicker.presetDates.yesterday" defaultMessage="Last Week" />,
            },
            {
              value: 'PAST_30_DAYS',
              label: <FormattedMessage id="datePicker.presetDates.past_30Days" defaultMessage="Past 30 Days" />,
            },
          ]}
        />
      </div>
      <div className={cn('d-flex flex-column flex-fill', styles['date-column'])}>
        <Label className="mb-4" htmlFor="present-date">
          <FormattedMessage id="datePicker.presetDates.present" defaultMessage="Present" />
        </Label>
        <Radio
          id="present-date"
          className={styles['date-radio-group']}
          layout="column"
          onChange={handleChange}
          value={presetDate}
          radioData={[
            { value: 'TODAY', label: <FormattedMessage id="quickView.dateRangeCondes.today" defaultMessage="Today" /> },
            {
              value: 'THIS_WEEK',
              label: <FormattedMessage id="datePicker.presetDates.thisWeek" defaultMessage="This Week" />,
            },
            {
              value: 'THIS_MONTH',
              label: <FormattedMessage id="datePicker.presetDates.thisMonth" defaultMessage="This Month" />,
            },
          ]}
        />
      </div>
      <div className={cn('d-flex flex-column flex-fill', styles['date-column'])}>
        <Label className="mb-4" htmlFor="future-date">
          <FormattedMessage id="datePicker.presetDates.future" defaultMessage="Future" />
        </Label>
        <Radio
          id="future-date"
          className={styles['date-radio-group']}
          layout="column"
          onChange={handleChange}
          value={presetDate}
          radioData={[
            {
              value: 'TOMORROW',
              label: <FormattedMessage id="quickView.dateRangeCondes.tomorrow" defaultMessage="Tomorrow" />,
            },
            {
              value: 'NEXT_WEEK',
              label: <FormattedMessage id="datePicker.presetDates.nextWeek" defaultMessage="Next Week" />,
            },
            {
              value: 'NEXT_30_DAYS',
              label: <FormattedMessage id="datePicker.presetDates.next_30Days" defaultMessage="Next 30 Days" />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default PresetDateSelect;
