import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router';
import { delay } from 'redux-saga';
import { call, cancel, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { onUserLogin, onUserLogout } from 'common/eventTracker';

import {
  API_PATH,
  SESSION_WARNING_DURATION,
  OKTA_URL,
  AUTHENTICATION_MANAGER_BASE_URL,
  MOVEMENT_BASE_UI_URL,
} from '../../../../common/AppConstants';
import * as hereMapsConfigActions from '../../../../common/typedHereMaps/ducks/actions';
import * as aerisWeatherConfigActions from '../../../../common/typedAerisWeather/ducks/actions';
import * as tableauConfigActions from '../../../../components/analytics/typedAnalytics/ducks/actions';
import { persistor } from '../../../../configureStore';
import axios from '../../../../util/paxios';
import { toggleSessionDialogToOpen } from '../../../common/sessionWrapper/ducks/actions';
import * as brandThemingActions from '../../../settings/BrandTheming/ducks/actions';
import * as actions from './actions';
import * as types from './types';
import { removeStorageRedirectUri } from '../redirectUri';
import { SESSION_RENEW_SUCCESS, LOGOUT, LOGOUT_SUCCESS, PUBLIC_ROUTES, RENEW_SESSION } from './types';

const checkPublicRoutes = (route) => {
  // uses matchPath to check if route matches in public route array
  let routeMatches = false;
  PUBLIC_ROUTES.forEach((publicRoute) => {
    if (matchPath(route, publicRoute)) {
      routeMatches = true;
    }
  });
  return routeMatches;
};

const addRedirectUri = () => {
  const movementLoginPath = `${MOVEMENT_BASE_UI_URL}/login`;
  const encodedLocation = encodeURIComponent(window.location.href);
  const redirectUri = movementLoginPath + `?redirect_uri=${encodedLocation}`;
  return redirectUri;
};

export async function checkSession() {
  try {
    const response = await fetch(API_PATH + `/principal?t=${Date.now()}`, {
      credentials: 'include',
      headers: { Accept: 'application/json' },
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(body.errors[0].message);
    } else {
      onUserLogin(body);
      return {
        loggedIn: true,
        principal: body,
      };
    }
  } catch (error) {
    throw parseJSON(error.message) || error;
  }
}

export const selectUsername = (state) => state.authReducer.principal.username;
export const selectTenantUuidChanged = (state) => state.authReducer.tenantUuidChangedSinceLastRefresh;
export const selectSavedTheme = (state) => state.brandThemingReducer.savedTheme;
export const selectHereMapsConfig = (state) => state.hereMapsConfigReducer.config;
export const selectAerisConfig = (state) => state.aerisWeatherConfigReducer.config;
export const selectTableauConfig = (state) => state.tableauConfigReducer.config;

export function* checkSessionAsync(action) {
  // check if route is public
  if (!checkPublicRoutes(action.payload.location.pathname)) {
    try {
      const response = yield call(checkSession);
      const usernameFromState = yield select(selectUsername);
      const tenantUuidChangedSinceLastCheck = yield select(selectTenantUuidChanged);

      const principal = {
        ...response.principal,
        username: usernameFromState || response.principal.username,
      };

      if (!response.principal) {
        throw new Error('No session payload in response');
      }

      const theme = yield select(selectSavedTheme);
      if (theme === undefined || tenantUuidChangedSinceLastCheck) {
        yield put(brandThemingActions.fetch.request());
      }

      const config = yield select(selectHereMapsConfig);
      if (config === undefined) {
        yield put(hereMapsConfigActions.fetch.request());
      }

      const aerisConfig = yield select(selectAerisConfig);
      if (aerisConfig === undefined) {
        yield put(aerisWeatherConfigActions.fetch.request());
      }

      const tableauConfig = yield select(selectTableauConfig);
      if (tableauConfig === undefined) {
        yield put(tableauConfigActions.fetch.request());
      }

      yield put(actions.checkSessionSuccess(principal));
      const extendSessionDialogTask = yield fork(extendSessionDialog);
      yield cancel(extendSessionDialogTask);
      yield call(extendSessionDialog);
    } catch (error) {
      const redirectUri = addRedirectUri();
      yield put(actions.checkSessionFailure());
      window.location.assign(redirectUri);
    }
  }
}

export function* extendSessionDialog() {
  yield delay(SESSION_WARNING_DURATION); // 45 minutes
  yield put(toggleSessionDialogToOpen(true));
}

export function parseJSON(text) {
  try {
    return JSON.parse(text);
  } catch (e) {
    return null;
  }
}

export function logoutOkta() {
  return axios({
    method: 'DELETE',
    url: OKTA_URL + '/api/v1/sessions/me',
    withCredentials: true,
  });
}

function* purgeSessionDataAsync() {
  // deletes local storage managed by redux-persist
  yield call([persistor, 'purge']);
}

export function* logoutAsync() {
  try {
    onUserLogout();
    const afterLogoutRedirectUri = `${MOVEMENT_BASE_UI_URL}/login`;
    const singleLogoutUrl =
      AUTHENTICATION_MANAGER_BASE_URL + 'logout?afterLogoutRedirectUri=' + encodeURI(afterLogoutRedirectUri);
    yield put(actions.logoutSuccess());
    yield put(actions.purgeSessionState());
    // TODO: Disconnect from sendbird
    window.location.assign(singleLogoutUrl);
  } catch (e) {
    yield put(actions.logoutFailure(e));
  }
}

export function* preventExpiration() {
  const ONE_MINUTE = 60000;
  try {
    while (true) {
      yield delay(ONE_MINUTE * 5); // 5 minutes
      yield axios({
        method: 'GET',
        url: API_PATH + '/renew',
        withCredentials: true,
      });
    }
  } catch (error) {
    /**
     * This prevents the user from staying on the same page when a call to renew
     * session fails. One way to test this is to reduce the session warning duration
     * and renewal time to 10 seconds:
     *
     * 1. Wait for the modal to appear.
     * 2. Kill the backend service.
     * 3. Wait for the UI to call the /renew endpoint which would result to a failed call
     * 4. Close the modal
     * 5. Click anywhere on the current page and the user should be redirected to the login page.
     *
     * We are piggybacking on the logout failure action to replicate the workflow where the user
     * is redirected to the login page.
     */
    yield put(actions.logoutFailure(error));
  }
}

function* renewSession() {
  while (yield take(SESSION_RENEW_SUCCESS)) {
    const preventSessionExpiration = yield fork(preventExpiration);

    yield take(LOGOUT_SUCCESS);
    yield cancel(preventSessionExpiration);
    removeStorageRedirectUri();
    sessionStorage.removeItem('state');
  }
}

export function* watchCheckSessionAsync() {
  yield takeLatest(LOCATION_CHANGE, checkSessionAsync);
}

export function* watchLogoutAsync() {
  yield takeLatest(LOGOUT, logoutAsync);
}

export function* watchPurgeAsync() {
  yield takeLatest(types.PURGE, purgeSessionDataAsync);
}

export function* watchRenewSessionAsync() {
  yield takeLatest(RENEW_SESSION, renewSession);
}
