import styled from 'styled-components';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import filterFunc from 'lodash/filter';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import replace from 'lodash/replace';
import lowerCase from 'lodash/lowerCase';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import cn from 'classnames';
import compact from 'lodash/compact';
import { primaryGreyTwenty, primaryBlue } from 'styles/colors';

import EventTypeFilter from 'components/filter/types/EventTypeFilter';
import ArrivalForecastFilter from 'components/filter/types/ArrivalForecastFilter';
import DeliveryForecastFilter from 'components/filter/types/DeliveryForecastFilter';
import CarrierFilterComponent from 'components/filter/types/CarrierFilter';
import CompanyFilterComponent from 'components/filter/types/CompanyFilter';
import CreatedByFilterComponent from 'components/filter/types/CreatedByFilter';
import TemperatureTrackingFilterComponent from 'components/filter/types/TemperatureTrackingFilter';
import filterMessages from 'components/filter/modes/filterMessages.json';
import CustomerFilterComponent from 'components/filter/types/CustomerFilter';
import SharedByFilterComponent from 'components/filter/types/SharedByFilter';
import SharedToFilterComponent from 'components/filter/types/SharedToFilter';
import ReferenceKeyFilterComponent from './ReferenceKeyFilter';
import LocationTypeComponent from './LocationTypeFilter';

import CheckList from '../../../common/checkList/CheckList';

import { messages } from '../utils';

const StyledDivider = styled('div')`
  background-color: ${primaryGreyTwenty};
  margin: 0 24px;
  width: 1px;
`;

const StyledFilterContainer = styled('div')`
  .col {
    padding: 0;
  }
`;

const StyledRow = styled('div')`
  &:not(.no-gutters) {
    margin-left: -24px;
    margin-right: -24px;
  }

  @media (max-width: 1124px) {
    flex-direction: column;
    border-left: 0 none;

    & > .col {
      margin-bottom: 20px;
    }
  }

  &.row-subtitle {
    .col-subtitle:last-child {
      border-left: 1px solid transparent;
    }
  }
`;

const StyledCol = styled('div')`
  border-left: 1px solid ${primaryGreyTwenty};
  padding-left: 24px;
  padding-right: 24px;

  &:first-child {
    border-left: 0 none;
  }

  .btn-inprogress-mobile {
    display: none;
  }

  @media (max-width: 1124px) {
    flex-direction: column;
    border-left: 0 none;
    border-bottom: 1px solid ${primaryGreyTwenty};
    margin-bottom: 20px;

    &:last-child {
      border-bottom: 1px solid transparent;
      margin-bottom: 0;
    }

    .btn-inprogress-desktop {
      display: none;
    }

    .btn-inprogress-mobile {
      display: block;
      margin-bottom: -17px;
      text-align: center;

      .ant-btn {
        background: white;
        width: auto !important;
      }
    }

    .hide-empty-title-on-mobile {
      display: block;
      margin-top: -12px;
      height: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  border: 1px solid ${primaryBlue};
  color: ${primaryBlue};
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  padding: 4px 16px;
  text-transform: uppercase;
  width: 100%;
`;

interface CheckListItemProps {
  id: string;
  dataKey: string;
  labelText: JSX.Element | string;
  propertyName: string;
  checked: boolean;
}

interface ListsProps {
  id: number;
  title?: any;
  subtitle?: any;
  hasShipmentInProgressButton: boolean;
  hasSelectAndClearAllOption: boolean;
  list: CheckListItemProps[] | [];
}

interface StatusFiltersProps extends ListsProps {
  lists?: ListsProps[] | undefined;
}

interface ShipmentStatusProps {
  filter: {
    status: string[];
  };
  handleCheckboxGroupUpdate: (obj: CheckListItemProps, filter: string) => void;
  checkboxTheme: any;
}

const ShipmentStatus = ({ filter, handleCheckboxGroupUpdate, checkboxTheme }: ShipmentStatusProps) => {
  const statusFilters = [
    {
      id: 1,
      title: (
        <span className="uppercase">
          <FormattedMessage {...messages.trackingPending} />
        </span>
      ),
      hasShipmentInProgressButton: false,
      hasSelectAndClearAllOption: true,
      list: [
        {
          id: filterMessages.scheduled.id,
          dataKey: filterMessages.scheduled.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.scheduled} />,
          propertyName: 'scheduled',
          checked: false,
        },
        {
          id: filterMessages.pendingApproval.id,
          dataKey: filterMessages.pendingApproval.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.pendingApproval} />,
          propertyName: 'pendingApproval',
          checked: false,
        },
        {
          id: filterMessages.pendingTrackingMethod.id,
          dataKey: filterMessages.pendingTrackingMethod.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.pendingTrackingMethod} />,
          propertyName: 'pendingTrackingMethod',
          checked: false,
        },
        {
          id: filterMessages.acquiringLocation.id,
          dataKey: filterMessages.acquiringLocation.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.acquiringLocation} />,
          propertyName: 'acquiringLocation',
          checked: false,
        },
        {
          id: filterMessages.pendingCarrier.id,
          dataKey: filterMessages.pendingCarrier.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.pendingCarrier} />,
          propertyName: 'pendingCarrier',
          checked: false,
        },
      ],
      lists: [],
    },
    {
      id: 2,
      hasShipmentInProgressButton: true,
      hasSelectAndClearAllOption: false,
      list: [],
      lists: [
        {
          id: 2.1,
          title: (
            <span className="uppercase">
              <FormattedMessage {...messages.atStop} />
            </span>
          ),
          hasShipmentInProgressButton: false,
          hasSelectAndClearAllOption: false,
          list: [
            {
              id: filterMessages.atPickup.id,
              dataKey: filterMessages.atPickup.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.origin} />,
              propertyName: 'atPickup',
              checked: false,
            },
            {
              id: filterMessages.atAnyStop.id,
              dataKey: filterMessages.atAnyStop.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.anyStop} />,
              propertyName: 'atAnyStop',
              checked: false,
            },
            {
              id: filterMessages.atDelivery.id,
              dataKey: filterMessages.atDelivery.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.finalStop} />,
              propertyName: 'atDelivery',
              checked: false,
            },
          ],
        },
        {
          id: 2.2,
          title: (
            <span className="uppercase">
              <FormattedMessage {...messages.inTransit} />
            </span>
          ),
          subtitle: (
            <span className="uppercase">
              <FormattedMessage {...messages.inMotion} />
            </span>
          ),
          hasShipmentInProgressButton: false,
          hasSelectAndClearAllOption: false,
          list: [
            {
              id: filterMessages.inTransittoPickup.id,
              dataKey: filterMessages.inTransittoPickup.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.toOrigin} />,
              propertyName: 'inTransittoPickup',
              checked: false,
            },
            {
              id: filterMessages.inTransittoAnyStop.id,
              dataKey: filterMessages.inTransittoAnyStop.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.toAnyStop} />,
              propertyName: 'inTransittoAnyStop',
              checked: false,
            },
            {
              id: filterMessages.inTransittoDelivery.id,
              dataKey: filterMessages.inTransittoDelivery.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.toFinalStop} />,
              propertyName: 'inTransittoDelivery',
              checked: false,
            },
          ],
        },
        {
          id: 2.3,
          title: <span className="hide-empty-title-on-mobile">&nbsp;</span>,
          subtitle: (
            <span className="uppercase">
              <FormattedMessage {...messages.idleEnRoute} />
            </span>
          ),
          hasShipmentInProgressButton: false,
          hasSelectAndClearAllOption: false,
          list: [
            {
              id: filterMessages.idleInTransittoPickup.id,
              dataKey: filterMessages.idleInTransittoPickup.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.toOrigin} />,
              propertyName: 'idleInTransittoPickup',
              checked: false,
            },
            {
              id: filterMessages.idleInTransittoAnyStop.id,
              dataKey: filterMessages.idleInTransittoAnyStop.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.toAnyStop} />,
              propertyName: 'idleInTransittoAnyStop',
              checked: false,
            },
            {
              id: filterMessages.idleInTransittoDelivery.id,
              dataKey: filterMessages.idleInTransittoDelivery.defaultMessage,
              labelText: <FormattedMessage {...filterMessages.toFinalStop} />,
              propertyName: 'idleInTransittoDelivery',
              checked: false,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      title: (
        <span className="uppercase">
          <FormattedMessage {...messages.exceptions} />
        </span>
      ),
      hasShipmentInProgressButton: false,
      hasSelectAndClearAllOption: false,
      list: [
        {
          id: filterMessages.notReceivingData.id,
          dataKey: filterMessages.notReceivingData.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.notReceivingData} />,
          propertyName: 'notReceivingData',
          checked: false,
        },
      ],
      lists: [],
    },
    {
      id: 4,
      title: (
        <span className="uppercase">
          <FormattedMessage {...messages.trackingEnded} />
        </span>
      ),
      hasShipmentInProgressButton: false,
      hasSelectAndClearAllOption: true,
      list: [
        {
          id: filterMessages.delivered.id,
          dataKey: filterMessages.delivered.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.delivered} />,
          propertyName: 'delivered',
          checked: false,
        },
        {
          id: filterMessages.canceled.id,
          dataKey: filterMessages.canceled.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.canceled} />,
          propertyName: 'canceled',
          checked: false,
        },
        {
          id: filterMessages.approvalDenied.id,
          dataKey: filterMessages.approvalDenied.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.approvalDenied} />,
          propertyName: 'approvalDenied',
          checked: false,
        },
        {
          id: filterMessages.expired.id,
          dataKey: filterMessages.expired.defaultMessage,
          labelText: <FormattedMessage {...filterMessages.expired} />,
          propertyName: 'expired',
          checked: false,
        },
      ],
      lists: [],
    },
  ];

  const onCheck = (checkboxItem: CheckListItemProps) =>
    handleCheckboxGroupUpdate({ ...checkboxItem, labelText: checkboxItem.dataKey }, 'status');

  const onSelectShipmentsInProgress = () => {
    const shipmentsInProgressOptions = filterFunc(
      statusFilters,
      (item: StatusFiltersProps) => !!item.hasShipmentInProgressButton
    );
    const lists = get(shipmentsInProgressOptions, ['0', 'lists'], []);

    const list = reduce(
      lists,
      (result: CheckListItemProps[], value) => {
        return [...result, ...value.list];
      },
      []
    );

    return forEach(list, (item: CheckListItemProps) => {
      if (!filter.status.includes(item.dataKey)) {
        return onCheck(item);
      }
    });
  };

  const formatDataLocator = (text: string | undefined) => {
    const result = replace(lowerCase(text), /\s/g, '-');
    return `${result}-status-filter`;
  };

  return (
    <>
      <StyledRow className="row">
        {statusFilters.map(
          ({ id, title, hasShipmentInProgressButton, hasSelectAndClearAllOption, list, lists }: StatusFiltersProps) => {
            if (isEmpty(lists)) {
              const checkListData = list.map((item: CheckListItemProps) => ({
                ...item,
                checked: filter.status.includes(item.dataKey),
              }));

              return (
                <StyledCol className="col-auto" key={id}>
                  <StyledRow className="row no-gutters">
                    <CheckList
                      mode={checkboxTheme}
                      listTitle={title}
                      checkListData={checkListData}
                      checkFn={onCheck}
                      data-locator={formatDataLocator(title)}
                      hasSelectAndClearAllOption={hasSelectAndClearAllOption}
                    />
                  </StyledRow>
                </StyledCol>
              );
            }

            return (
              <StyledCol className="col-auto" key={id}>
                <StyledRow className="row row-subtitle">
                  {lists?.map((subList: StatusFiltersProps) => {
                    const checkListData = subList.list.map((item: CheckListItemProps) => ({
                      ...item,
                      checked: filter.status.includes(item.dataKey),
                    }));
                    const showMobileBtn = hasShipmentInProgressButton && subList.id === 2.1;

                    return (
                      <StyledCol className={`col-auto col-subtitle ${showMobileBtn ? 'mb-5' : ''}`} key={subList.id}>
                        <StyledRow className="row no-gutters">
                          <CheckList
                            mode={checkboxTheme}
                            listTitle={subList.title}
                            listSubtitle={subList.subtitle}
                            checkListData={checkListData}
                            checkFn={onCheck}
                            data-locator={formatDataLocator(subList.title)}
                            hasSelectAndClearAllOption={subList.hasSelectAndClearAllOption}
                          />
                        </StyledRow>
                        {showMobileBtn && (
                          <div className="btn-inprogress-mobile">
                            <div className="d-inline-block px-3 bg-white">
                              <StyledButton onClick={onSelectShipmentsInProgress}>
                                <FormattedMessage
                                  id="quickView.filters.shipmentsInProgress"
                                  defaultMessage="Shipments in Progress"
                                />
                              </StyledButton>
                            </div>
                          </div>
                        )}
                      </StyledCol>
                    );
                  })}
                </StyledRow>
                {hasShipmentInProgressButton && (
                  <StyledRow className="row btn-inprogress-desktop">
                    <StyledCol className="col">
                      <StyledButton onClick={onSelectShipmentsInProgress} className="mt-4">
                        <FormattedMessage
                          id="quickView.filters.shipmentsInProgress"
                          defaultMessage="Shipments in Progress"
                        />
                      </StyledButton>
                    </StyledCol>
                  </StyledRow>
                )}
              </StyledCol>
            );
          }
        )}
      </StyledRow>
    </>
  );
};

const TimingFilter = (props: any) => {
  const layoutFlow = props.isQuickViewSetup ? 'horizontal' : 'vertical';
  return (
    <div className="d-flex">
      <StyledFilterContainer className={cn({ 'flex-fill': props.isQuickViewSetup })}>
        <ArrivalForecastFilter {...props} layoutFlow={layoutFlow} />
      </StyledFilterContainer>
      <StyledDivider />
      <StyledFilterContainer className={cn({ 'flex-fill': props.isQuickViewSetup })}>
        <DeliveryForecastFilter {...props} layoutFlow={layoutFlow} />
      </StyledFilterContainer>
    </div>
  );
};

const CarrierFilter = (props: any) => <CarrierFilterComponent {...props} />;

const CompanyFilter = (props: any) => <CompanyFilterComponent {...props} />;

const CreatedByFilter = (props: any) => <CreatedByFilterComponent {...props} />;

const TemperatureTrackingFilter = (props: any) => (
  <StyledFilterContainer>
    <TemperatureTrackingFilterComponent {...props} />
  </StyledFilterContainer>
);

const ReferenceKeyFilter = (props: any) => <ReferenceKeyFilterComponent {...props} />;

const CustomerFilter = (props: any) => <CustomerFilterComponent {...props} />;

const LocationTypeFilter = (props: any) => <LocationTypeComponent {...props} />;

const SharedToFilter = (props: any) => <SharedToFilterComponent {...props} />;

const SharedByFilter = (props: any) => <SharedByFilterComponent {...props} />;

export default (props: any) => {
  const { authorizations, principal } = props;
  const hasTenantCarrierRole = authorizations.hasTenantCarrierRole(principal);
  const renderSharedTenantFilters = authorizations.renderSharedTenantFilters(principal);

  const referenceKeyFilter = hasTenantCarrierRole
    ? undefined
    : {
        name: <FormattedMessage {...messages.referenceKey} />,
        component: ReferenceKeyFilter,
        modalId: 'attributeKeyValuePairs',
        customHeader: true,
      };

  const customerFilter = hasTenantCarrierRole
    ? {
        name: <FormattedMessage {...messages.customerTenants} />,
        component: CustomerFilter,
        modalId: 'customerTenants',
      }
    : undefined;

  const sharedToFilter = renderSharedTenantFilters
    ? {
        name: <FormattedMessage {...messages.sharedTo} />,
        component: SharedToFilter,
        modalId: 'sharedTo',
      }
    : undefined;

  const sharedByFilter = renderSharedTenantFilters
    ? {
        name: <FormattedMessage {...messages.sharedBy} />,
        component: SharedByFilter,
        modalId: 'sharedBy',
      }
    : undefined;

  const tlFilters = [
    {
      name: <FormattedMessage {...messages.shipmentStatus} />,
      component: ShipmentStatus,
      modalId: 'shipmentStatus',
    },
    {
      name: <FormattedMessage {...messages.event} />,
      component: EventTypeFilter,
      modalId: 'event',
      classNameContent: 'p-0',
    },
    {
      name: <FormattedMessage {...messages.timing} />,
      component: TimingFilter,
      modalId: 'timing',
    },
    {
      name: <FormattedMessage {...messages.carrier} />,
      component: CarrierFilter,
      modalId: 'carrier',
    },
    {
      name: <FormattedMessage {...messages.company} />,
      component: CompanyFilter,
      modalId: 'company',
    },
    {
      name: <FormattedMessage {...messages.location} />,
      component: LocationTypeFilter,
      modalId: 'locationType',
      classNameContent: 'p-0',
    },
    customerFilter,
    {
      name: <FormattedMessage {...messages.createdBy} />,
      component: CreatedByFilter,
      modalId: 'createdBy',
    },
    sharedToFilter,
    sharedByFilter,
    {
      name: <FormattedMessage {...messages.temperatureTracking} />,
      component: TemperatureTrackingFilter,
      modalId: 'temperatureTracking',
    },
    referenceKeyFilter,
  ];

  return compact(tlFilters);
};
