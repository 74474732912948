import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { InfoCircleOutlined } from '@ant-design/icons';
import { formatUtcTimeInTimeZoneOrDefaultToBrowserZone } from 'common/dateUtils';
import { MONTH_DAY_YEAR_FORMAT, HOURS_MINUTES_FORMAT } from 'i18n/configurei18n';
import { Tooltip } from 'ui-components';
import LocationUtils from 'common/LocationUtils/LocationUtils';
import ShareBadge from 'components/common/shareBadge/ShareBadge';
import ShipmentStatus from 'components/common/shipmentStatus/ShipmentStatus';
import { ReactComponent as NoSignalIcon } from 'components/common/assets/icons/icon-no-signal.svg';
import { ReactComponent as SignalIcon } from 'components/common/assets/icons/icon-signal.svg';
import { useWindowSize } from 'components/EventDatePicker/hooks/useWindowSize';
import StatusColorTag from '../../../common/statusColorTag/StatusColorTag';
import './ResultDetail.scss';
import { shipmentDetailShape } from '../models/shipmentDetail';
import Status from '../../../shipment/common/enums/status';
import NudgeDriver from './NudgeDriver/NudgeDriver';
import { cssColorVarMap } from '../../../../styles/colors';

const ResultDetail = (props) => {
  const intl = useIntl();
  const size = useWindowSize();

  if (!props.shipmentDetail) {
    return null;
  }

  const getUpdatedDateInBrowserTimezone = (updatedDate, props) => {
    const localTimeZoneIdentifier = props.latestStatusUpdateTimezone?.localTimeZoneIdentifier;
    const localTimeZoneAbbreviation = props.latestStatusUpdateTimezone?.localTimeZoneIdentifierShort;

    let date = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(updatedDate, localTimeZoneIdentifier)?.format(
      MONTH_DAY_YEAR_FORMAT
    );
    date += ' at ';
    date += formatUtcTimeInTimeZoneOrDefaultToBrowserZone(updatedDate, localTimeZoneIdentifier)?.format(
      HOURS_MINUTES_FORMAT
    );
    date += ' ';
    date += localTimeZoneAbbreviation;

    return date;
  };

  const milesTraveled = props.shipmentDetail.milesTraveled || 0;
  const milesRemaining = props.shipmentDetail.milesRemaining || 0;
  const showInMiles = LocationUtils.shouldShowInMiles(get(props, 'shipmentDetail.delivery.country'));
  const isMilesCalculated = milesTraveled >= 0 && milesRemaining >= 0 && !(milesTraveled === 0 && milesRemaining === 0);

  const statusCode = get(props.shipmentDetail, 'currentStop.statusCode', Status.UNKNOWN);
  const statusReason = get(props.shipmentDetail, 'currentStop.statusReasonCode', Status.UNKNOWN);
  const distanceTraveledPercentage = calculatePercentComplete(statusReason, statusCode, milesTraveled, milesRemaining);
  const updatedDate =
    get(props, 'shipmentDetail.updatedDate') || get(props, 'shipmentDetail.latestStatusUpdate.utcTimestamp');
  const updatedDateInBrowserTimezone = getUpdatedDateInBrowserTimezone(updatedDate, props);
  const startTimeBeforePickupMinutes = {
    minutes: props.shipmentDetail.startTimeBeforePickupMinutes,
  };

  const shipmentDetailStatuses = {
    DELIVERED: 'Delivered',
    SCHEDULED: 'Scheduled',
    UNKNOWN: 'Unknown',
    DEPARTED: 'Departed',
    PICKEDUP: 'Picked Up',
    LATE: 'Late',
    EARLY: 'Early',
  };

  const getFormattedMessageIDByStatusInfo = (status) => {
    /*
     * If a valid SimpleLocalize ID cannot be determined, the FormattedMessage
     * will default to displaying status via the defaultMessage field
     */
    const idContext = 'shipmentDetails.statusLabel.truckload.';
    const invalidID = 'shipmentDetails.statusLabel.truckload.invalidID';

    return Object.values(shipmentDetailStatuses).includes(status)
      ? `${idContext}.${status.toLowerCase().trim()}`
      : invalidID;
  };

  return (
    <div className={props.isLoggedIn ? 'col-md-16' : 'col'}>
      <div className="ResultDetail p44Card">
        <header className="border-0">
          <div className="d-flex flex-row justify-content-between">
            <h2>
              {!isNil(get(props, 'shipmentDetail.statusInfo.id')) ? (
                <FormattedMessage
                  id={props.shipmentDetail.statusInfo.id}
                  defaultMessage={props.shipmentDetail.statusInfo.defaultMessage}
                />
              ) : (
                <FormattedMessage
                  id={getFormattedMessageIDByStatusInfo(props.shipmentDetail.statusInfo)}
                  defaultMessage={props.shipmentDetail.statusInfo}
                />
              )}
            </h2>
            <div className="d-none d-sm-block upper-right-section text-sm-right">
              {props.shipmentDetail.atStopEta && !props.etaOutOfDate && (
                <strong className="eta-message mr-0 d-block mb-1">{props.shipmentDetail.atStopEta}</strong>
              )}
              {updatedDateInBrowserTimezone && props.etaOutOfDate && props.shipmentDetail.atStopEta && (
                <Row>
                  <div style={{ textAlign: 'left', paddingRight: '5px' }}>
                    <Tooltip
                      title={
                        <FormattedMessage
                          id="shipmentDetailsTL.tooltips.etaOutdated"
                          defaultMessage="The actual ETA may vary. The “Last Known ETA” is calculated based on the last location update received"
                        />
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                  <strong>
                    <div style={{ textAlign: 'right', paddingRight: '8px' }}>{props.shipmentDetail.atStopEta}</div>
                  </strong>
                </Row>
              )}
              {props.shipmentDetail && props.shipmentDetail.atStopMessage && !props.shouldHideStatus ? (
                <StatusColorTag
                  messageText={props.shipmentDetail.atStopMessage}
                  colorValue={cssColorVarMap[props.shipmentDetail.statusColor]}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </header>
        <main>
          <div>
            <ShipmentStatus
              statusDetails={props.shipmentDetail.statusDetails}
              percentComplete={distanceTraveledPercentage}
              statusColor={props.shipmentDetail.statusColor}
              a
              markerDimensions={{
                width: 48,
                height: 48,
              }}
            />
            <div className="d-flex d-sm-none flex-column pt-4 align-items-center">
              {props.shipmentDetail && props.shipmentDetail.atStopMessage && size.width > 600 ? (
                <StatusColorTag
                  messageText={props.shipmentDetail.atStopMessage}
                  colorValue={cssColorVarMap[props.shipmentDetail.statusColor]}
                />
              ) : (
                ''
              )}
              {props.shipmentDetail.atStopEta && (
                <strong className="eta-message pt-2">{props.shipmentDetail.atStopEta}</strong>
              )}
              <span className="last-update-message">{props.shipmentDetail.statusUpdate}</span>
            </div>
            {isMilesCalculated && statusCode === Status.IN_TRANSIT && (
              <div className="status-distance">
                <p>
                  {showInMiles
                    ? intl.formatMessage(
                        { id: 'shipmentDetails.milesTraveled', defaultMessage: '{milesString} Miles Traveled' },
                        { milesString: milesTraveled }
                      )
                    : intl.formatMessage(
                        {
                          id: 'shipmentDetails.kilometersTraveled',
                          defaultMessage: '{kilometersString} Kilometers Traveled',
                        },
                        { kilometersString: LocationUtils.convertMilesToKm(milesTraveled) }
                      )}
                </p>
                <p>
                  {showInMiles
                    ? intl.formatMessage(
                        { id: 'shipmentDetails.milesRemaining', defaultMessage: '{milesString} Miles Remaining' },
                        { milesString: milesRemaining }
                      )
                    : intl.formatMessage(
                        {
                          id: 'shipmentDetails.kilometersRemaining',
                          defaultMessage: '{kilometersString} Kilometers Remaining',
                        },
                        { kilometersString: LocationUtils.convertMilesToKm(milesRemaining) }
                      )}
                </p>
              </div>
            )}
          </div>
        </main>
        <footer>
          <Row className="justify-between tracking-info">
            <Col>
              {props.shipmentDetail.trackingInfo?.message && (
                <div className="etaText">
                  <p>
                    <SignalIcon />
                    <FormattedMessage
                      id={props.shipmentDetail.trackingInfo.message.id}
                      defaultMessage={props.shipmentDetail.trackingInfo.message.defaultMessage}
                      values={startTimeBeforePickupMinutes}
                    />
                  </p>
                </div>
              )}
              {props.etaOutOfDate && !props.shipmentDetail.trackingInfo?.message && (
                <div className="etaOutdatedText">
                  <NoSignalIcon />
                  <FormattedMessage defaultMessage="Tracking & ETAs Outdated" id="shipmentDetails.etaOutOfDate" />
                </div>
              )}
            </Col>
            <Col>
              {props.isLoggedIn && props.nudgeData && (
                <div className="nudge-driver">
                  <p className="text-right">
                    <button
                      className="nudge-link"
                      //eslint-disable-next-line react/jsx-no-bind
                      onClick={() => props.openNudgeDialog(props.shipmentId)}
                    >
                      <FormattedMessage id="shipmentDetails.nudgeDriver" defaultMessage="NUDGE DRIVER" />
                    </button>
                  </p>
                  <NudgeDriver
                    shipmentId={props.shipmentId}
                    open={props.nudgeDialogVisible}
                    nudgeMessagePreview={props.nudgeMessagePreview}
                    nudgeDialogActionText={props.nudgeDialogActionText}
                    sendNudge={props.sendNudge}
                    nudgeDialogShowingFailureMessage={props.nudgeDialogShowingFailureMessage}
                    nudgeFailureMessage={props.nudgeFailureMessage}
                    closeNudgeDialog={props.closeNudgeDialog}
                  />
                  {props.nudgeData.lastNudgedOn && (
                    <p className="font-italic text-right">
                      <FormattedMessage id="shipmentDetails.lastNudgeSent" defaultMessage="Last nudge sent" />{' '}
                      {moment(props.nudgeData.lastNudgedOn + 'Z').fromNow()}
                    </p>
                  )}
                </div>
              )}
              {updatedDateInBrowserTimezone && (
                <FormattedMessage
                  id="shipmentDetails.status.lastShipmentUpdate"
                  defaultMessage="Last shipment update {timestamp}"
                  values={{ timestamp: updatedDateInBrowserTimezone }}
                >
                  {(...text) => <span className="text-sm-right d-none d-sm-block">{text}</span>}
                </FormattedMessage>
              )}
            </Col>
          </Row>
          <Row>
            <ShareBadge
              className="share-badge"
              showMultipleEntitlees
              entitlementInfo={props.shipmentDetail.entitlementInfo}
            />
          </Row>
        </footer>
      </div>
    </div>
  );
};

export function calculatePercentComplete(statusReason, statusCode, milesTraveled, milesRemaining) {
  switch (statusCode) {
    case Status.DISPATCHED:
    case Status.UNKNOWN:
      return 0;

    case Status.IN_TRANSIT:
    case Status.AT_STOP:
      if (!milesTraveled && !milesRemaining) {
        return 0;
      }

      return (milesTraveled || 0) / (milesTraveled + milesRemaining);
    case Status.COMPLETED:
      if (statusReason !== 'DEPARTED_FINAL_STOP') {
        return 0.5;
      } else {
        return 1;
      }
    default:
      return 0;
  }
}

ResultDetail.propTypes = {
  shipmentDetail: shipmentDetailShape,
};

export default ResultDetail;
