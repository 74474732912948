import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { renderApiErrors } from 'components/common/forms/FormErrors';
import AddEditShipmentButtons from '../AddEditShipmentButtons/AddEditShipmentButtons';
import { addEditFormConstants } from './../AddEditShipmentConstants';
import ContactInfoFormSection from '../../common/forms/ContactInfoFormSection';
import AddressFormSection from '../../common/forms/AddressFormSection';
import ShipmentDateFormSection from '../AddEditShipmentForm/AddEditShipmentDates';
import { validateDateRangeWindows } from '../formValidators';

const editShipmentErrorHeading = 'edit this shipment';

let EditShipmentForm = (props) => {
  const { handleSubmit, initialValues } = props;

  return (
    <form onSubmit={handleSubmit} className="add-edit-shipment-form">
      <AddressFormSection
        autocomplete
        formName="address"
        contactFormName=""
        zipEntered={(zip) => props.zipEntered('address', zip)}
        addressHeader={addEditFormConstants[props.step.id].addressHeader}
        initialValues={initialValues}
      >
        <ShipmentDateFormSection sectionHeader={addEditFormConstants[props.step.id].timeHeader} />
      </AddressFormSection>
      <div className="shipment-contact-info">
        <h4 className="form-header">
          Contact Information <span className="form-header-help">(optional)</span>
        </h4>
        <ContactInfoFormSection />
      </div>
      <AddEditShipmentButtons
        step={props.step}
        navigateStep={props.navigateStep}
        onSubmit={props.onSubmit}
        cancel={props.cancel}
      />
      {renderApiErrors(initialValues.errors, editShipmentErrorHeading)}
    </form>
  );
};

const getFormKey = (props) => `EditShipment-${props.stopNumber}`;

// Making formName dynamic based on the stop number: https://github.com/erikras/redux-form/issues/603
EditShipmentForm = compose(
  connect((state, props) => ({ form: getFormKey(props) })),
  reduxForm({
    validate: validateDateRangeWindows,
  })
)(EditShipmentForm);

export default EditShipmentForm;
