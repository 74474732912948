import { useState, useEffect } from 'react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import get from 'lodash/get';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Select, SubHeader } from 'ui-components';
import { RootState } from 'reducers';
import { API_PATH } from 'common/AppConstants';
import ErrorMessage from 'components/common/errorBoundary/ErrorMessage/ErrorMessage';
import { getPrincipalAuthorizations } from 'common/authorizations';
import PortCongestion from './portCongestion';
import axios from '../../util/paxios';
import FixedNav from './fixedNav/FixedNav';
import endpoints from '../../common/endpoints';

import './Analytics.scss';

let tableauHost: string;
const defaultTableauHost = 'tableau.project44.com';

const baseTableauUrl = (trustedTicket: string, siteId: string) => {
  return `https://${tableauHost}/trusted/${trustedTicket}/t/${siteId}/views`;
};

const carrierScorecardUrl = (trustedTicket: string, siteId: string) => {
  return `${baseTableauUrl(trustedTicket, siteId)}/CarrierScorecard/Dashboard1`;
};

const overviewScorecardUrl = (trustedTicket: string, siteId: string) => {
  return `${baseTableauUrl(trustedTicket, siteId)}/Dashboard/Dashboard1`;
};
const localizeLang = (language: string) => {
  let langURL = '';
  switch (language) {
    case 'fr':
      langURL = '-FRENCH_localized';
      break;
    case 'it':
      langURL = '-ITALIAN_localized';
      break;
    case 'de':
      langURL = '-GERMAN_localized';
      break;
    case 'po':
      langURL = '-POLISH_localized';
      break;
    case 'es':
      langURL = '-SPANISH_localized';
      break;
    default:
      break;
  }
  return langURL;
};
const carrierOverviewScorecardUrl = (trustedTicket: string, siteId: string, language: string) => {
  return `${baseTableauUrl(trustedTicket, siteId)}/CarrierAnalytics-AllAnalytics${localizeLang(
    language
  )}/CarrierOverviewPage`;
};

const customerOverviewScorecardUrl = (trustedTicket: string, siteId: string, language: string) => {
  return `${baseTableauUrl(trustedTicket, siteId)}/CustomerAnalytics-AllAnalytics${localizeLang(
    language
  )}/CustomerOverviewPage`;
};

const carrierOnboardingReportUrl = (trustedTicket: string, siteId: string) => {
  return `${baseTableauUrl(trustedTicket, siteId)}/CarrierOnboardingInsights/CarrierOnboardingInsights`;
};

const locationAnalyticsUrl = (trustedTicket: string, siteId: string, language: string) => {
  return `${baseTableauUrl(trustedTicket, siteId)}/LocationAnalytics-AllAnalytics${localizeLang(
    language
  )}/LocationOverview`;
};

const locationDetailsAnalyticsUrl = (trustedTicket: string, siteId: string, locationId: string) => {
  return `${baseTableauUrl(
    trustedTicket,
    siteId
  )}/LocationAnalytics-AllAnalytics/LocationDetails?LOCATION_ID=${locationId}`;
};

const oceanOverviewUrl = (trustedTicket: string, siteId: string) => {
  return `${baseTableauUrl(trustedTicket, siteId)}/OceanAnalytics/OceanOverview`;
};

const carrierProcurementReportUrl = (trustedTicket: string, siteId: string) => {
  return `${baseTableauUrl(trustedTicket, siteId)}/CarrierProcurementAnalytics/CarrierProcurementAnalytics`;
};

const getModeFromUrl = (currentView: string): string =>
  currentView.split('/')[currentView.lastIndexOf('analytics') + 1];

export const AnalyticsComponent: React.FC<
  RouteComponentProps &
    WrappedComponentProps & {
      principal: RootState['authReducer']['principal'];
      tableauConfig: RootState['tableauConfigReducer'];
    }
> = (props) => {
  const queriedHost = props.tableauConfig ? props.tableauConfig.config?.host : null;
  tableauHost = queriedHost ? queriedHost : defaultTableauHost;

  const getReportUrl = (
    location: RouteComponentProps['location'],
    trustedTicket: string,
    siteId: string,
    principal: any
  ) => {
    const pathname = location.pathname;

    const authorizations = getPrincipalAuthorizations(principal);

    switch (pathname) {
      case endpoints.ANALYTICS_SCORECARD:
        return carrierScorecardUrl(trustedTicket, siteId);

      case endpoints.ANALYTICS_CARRIER_OVERVIEW: {
        return authorizations.hasTenantCarrierRole(principal)
          ? customerOverviewScorecardUrl(trustedTicket, siteId, language)
          : carrierOverviewScorecardUrl(trustedTicket, siteId, language);
      }

      case endpoints.ANALYTICS_OCEAN_OVERVIEW:
        return oceanOverviewUrl(trustedTicket, siteId);
      case endpoints.ANALYTICS_LOCATION_ANALYTICS:
        return locationAnalyticsUrl(trustedTicket, siteId, language);

      case endpoints.ANALYTICS_LOCATION_DETAILS_ANALYTICS: {
        const locationId = location.search ? location.search.split('?id=')[1] : '';
        return locationDetailsAnalyticsUrl(trustedTicket, siteId, locationId);
      }

      case endpoints.ANALYTICS_CARRIER_ONBOARDING_REPORT:
        return carrierOnboardingReportUrl(trustedTicket, siteId);

      case endpoints.ANALYTICS_CARRIER_PROCUREMENT:
        return carrierProcurementReportUrl(trustedTicket, siteId);

      default:
        return authorizations.hasTenantCarrierRole(principal)
          ? customerOverviewScorecardUrl(trustedTicket, siteId, language)
          : overviewScorecardUrl(trustedTicket, siteId);
    }
  };

  const [tableauToken, setTableauToken] = useState<undefined | string>(undefined);
  const [siteId, setSiteId] = useState<undefined | string>(undefined);
  const [errorFetchingToken, setErrorFetchingToken] = useState<boolean>(false);
  const [language, setLanguage] = useState('en');
  useEffect(() => {
    // Tokens are only good once, this prevents an expired token warning from showing in the iframe
    setTableauToken(undefined);

    async function fetchTableauToken() {
      try {
        setErrorFetchingToken(false);
        const response = await axios({
          method: 'GET',
          url: `${API_PATH}/report`,
          withCredentials: true,
        });
        setTableauToken(get(response, 'data.trustedTicket'));
        setSiteId(get(response, 'data.siteId'));
      } catch (error) {
        console.error(error); // eslint-disable-line
        setErrorFetchingToken(true);
      }
    }

    fetchTableauToken();
  }, [props.location.pathname, language]);

  const authorizations = getPrincipalAuthorizations(props.principal);
  const isOceanAnalytics = (): boolean => getModeFromUrl(props.location.pathname) === 'ocean';
  const displaySubHeaderMessage = () => {
    if (isOceanAnalytics()) {
      return <FormattedMessage id="analytics.ocean.subheader" defaultMessage="Ocean Analytics Dashboard" />;
    }
    return <FormattedMessage id="analytics.subheader" defaultMessage="Analytics Dashboard" />;
  };
  const langOptions = [
    { value: 'en', displayValue: 'English' },
    { value: 'es', displayValue: 'Español' },
    { value: 'de', displayValue: 'Deutsche' },
    { value: 'po', displayValue: 'Polskie' },
    { value: 'it', displayValue: 'Italiano' },
    { value: 'fr', displayValue: 'Français' },
  ];
  const shouldShowLangSelect: boolean =
    props.location.pathname === endpoints.ANALYTICS_LOCATION_ANALYTICS ||
    props.location.pathname === endpoints.ANALYTICS_CARRIER_OVERVIEW;
  return (
    <div className="analytics">
      <SubHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <h1 style={{ marginBottom: 0 }}>{displaySubHeaderMessage()}</h1>
          {shouldShowLangSelect && (
            <Select
              value={language}
              onChange={(lang) => setLanguage(lang)}
              dataSource={langOptions}
              data-testId="languageDropdown"
            />
          )}
        </div>
      </SubHeader>
      <section className="section-content">
        {!authorizations.hasTenantCarrierRole(props.principal) && (
          <FixedNav
            // @ts-ignore no typechecking support on component
            currentView={props.location.pathname}
            mode={getModeFromUrl(props.location.pathname)}
            principalAuthorizations={props.principal}
          />
        )}

        {tableauToken !== undefined && siteId !== undefined && !errorFetchingToken && (
          <ResponsiveEmbed aspectRatio="16by9">
            {props.location.pathname !== endpoints.ANALYTICS_OCEAN_PORT_CONGESTION ? (
              <iframe
                src={getReportUrl(props.location, tableauToken, siteId, props.principal)}
                height="100%"
                width="100%"
                title={'analytics report'}
              />
            ) : (
              <PortCongestion />
            )}
          </ResponsiveEmbed>
        )}
        {errorFetchingToken && (
          <ErrorMessage
            message={props.intl.formatMessage({
              id: 'analytics.errorLoading',
              defaultMessage: 'Error loading analytics.',
            })}
          />
        )}
      </section>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    principal: state.authReducer.principal,
    tableauConfig: state.tableauConfigReducer,
  };
}

export default connect(mapStateToProps, {})(injectIntl(AnalyticsComponent));
