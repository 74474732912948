import * as React from 'react';
import Col from 'react-bootstrap/Col';
import get from 'lodash/get';
import find from 'lodash/find';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Autosuggest } from 'ui-components';

import { PrincipalContext } from 'contexts/PrincipalContext';
import { getPrincipalAuthorizations } from 'common/authorizations';

import AutocompleteFilter from '../../common/autocompleteFilter/AutocompleteFilter';
import types from '../ducks/types';

const messages = defineMessages({
  filterByAnyCustomer: {
    id: 'quickView.customerFilter.filterByAnyCustomer',
    defaultMessage: 'Filter by Any Customer',
  },
});

const CustomerFilter: React.FC<any> = ({
  handleFilterInputs,
  handleAutocompleteUpdate,
  toggleShowSelections,
  ...props
}) => {
  const [currentValue, setCurrentValue] = React.useState('');
  const intl = useIntl();
  const listData = get(props, 'filter.customerTenants', []);
  const suggestedCustomers = props.customerSuggestions.length > 0 ? props.customerSuggestions : [];

  const dataSource = suggestedCustomers.map((cust: { companyUid: string; name: string; tenantId: string }) => ({
    value: cust.name,
    filterValue: cust.tenantId,
  }));

  const updateAutocomplete = (tenantName: string) => {
    const dataSourceItem = find(dataSource, (item) => item.value === tenantName);
    return handleAutocompleteUpdate(dataSourceItem.filterValue, 'customerTenants');
  };

  const updateFn = (term: string) => term.length > 3 && handleFilterInputs(term, types.SUGGESTIONS_MAP.customers);

  const principalContext = React.useContext(PrincipalContext);
  const authorizations = getPrincipalAuthorizations(principalContext);
  const isShipmentListFiltersHorizontalEnabled = authorizations.shipmentListFiltersHorizontalEnabled();

  React.useEffect(() => {
    if (currentValue && currentValue.length > 3) {
      handleFilterInputs(currentValue, types.SUGGESTIONS_MAP.customers);
    }
  }, [currentValue, handleFilterInputs]);

  if (isShipmentListFiltersHorizontalEnabled) {
    return (
      <Autosuggest
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        placeholder={intl.formatMessage(messages.filterByAnyCustomer)}
        suggestions={dataSource}
        listData={listData}
        handleAutocompleteUpdate={updateAutocomplete}
        name="customerTenants"
      />
    );
  }

  return (
    <Col>
      <label htmlFor="customer-input-filter" className="uppercase">
        {<FormattedMessage id="quickView.customerFilter.filterByAnyCustomer" defaultMessage="Customer" />}
      </label>
      <AutocompleteFilter
        idProp="customer-input-filter"
        placeholder={intl.formatMessage(messages.filterByAnyCustomer)}
        dataSource={dataSource}
        updateFn={updateFn}
        newRequestFn={updateAutocomplete}
        listData={get(props, 'customersListData', [])}
        showAllChips={get(props, 'showSelections.showAllCustomers')}
        passedInDeleteFn={updateAutocomplete}
        initialAmountVisible={3}
        showHideAllFn={() => props.toggleShowSelections('Customers')}
        dataLocator="customer-filter"
      />
    </Col>
  );
};

export default CustomerFilter;
