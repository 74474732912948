import * as React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import get from 'lodash/get';

import { Link } from 'react-router-dom';

import { FormattedMessage, IntlShape } from 'react-intl';

import { Button } from 'ui-components';

import endpoints from 'common/endpoints';
import { PrincipalAuthorizations } from 'common/authorizations';

import { PrincipalContext } from 'contexts/PrincipalContext';

import {
  ShipmentVolumesModel,
  DwellTimesModel,
  KpiTypes,
  TrackingComplianceModel,
  GeofencePerformanceModel,
  LocationDetails,
} from 'models';
import KpiChart from './Charts/Chart';

interface LocationKPIsProps {
  analyticsData: any;
  intl: IntlShape;
  isEditMode: boolean;
  locationDetails: LocationDetails | undefined;
  setIsEditGeofenceButtonVisible: React.Dispatch<React.SetStateAction<boolean>>;
  noNav: boolean;
}
const LocationKPIs = ({
  analyticsData,
  intl,
  isEditMode,
  setIsEditGeofenceButtonVisible,
  locationDetails,
  noNav,
}: LocationKPIsProps) => {
  const principalContext = React.useContext(PrincipalContext);
  const authorizations = new PrincipalAuthorizations({ authorities: [], principal: principalContext });
  const DwellTimesChart = () => {
    if (analyticsData?.dwellTimes)
      return (
        <Col lg={12}>
          <KpiChart
            kpi={KpiTypes.DWELL_TIMES}
            chartType="bar"
            title={intl.formatMessage({
              id: 'locationDetails.kpiCharts.titles.dwellTimes',
              defaultMessage: 'Dwell Times',
            })}
            chartData={get(analyticsData, 'dwellTimes.dwellTimes', [] as DwellTimesModel[])}
          />
        </Col>
      );
    return null;
  };
  const GeoperformanceChart = () => {
    if (analyticsData?.geofencePerformances)
      return (
        <Col lg={12}>
          <KpiChart
            kpi={KpiTypes.ACCURACY}
            chartType="line"
            title={
              <FormattedMessage
                id="locationDetails.kpiCharts.titles.geofenceAccuracy"
                defaultMessage="Geofence Accuracy"
              >
                {(text) => (
                  <span>
                    {text}{' '}
                    {isEditMode && (
                      <Button
                        type="primary-transparent-text"
                        style={{ display: 'inline-block' }}
                        clickFn={() => setIsEditGeofenceButtonVisible(true)}
                      >
                        <FormattedMessage id="locationDetails.edit.editGeofence" defaultMessage="Edit Geofence" />
                      </Button>
                    )}
                  </span>
                )}
              </FormattedMessage>
            }
            chartData={get(
              analyticsData,
              'geofencePerformances.geofencePerformances',
              [] as GeofencePerformanceModel[]
            )}
          />
        </Col>
      );
    return null;
  };
  const TrackingComplianceCharts = () => {
    if (analyticsData?.trackingCompliances)
      return (
        <>
          <Col lg={12}>
            <KpiChart
              kpi={KpiTypes.VOLUME}
              chartType="line"
              title={intl.formatMessage({
                id: 'locationDetails.kpiCharts.titles.volume',
                defaultMessage: 'Volume',
              })}
              chartData={get(analyticsData, 'shipmentVolumes.shipmentVolumes', [] as ShipmentVolumesModel[])}
            />
          </Col>

          <Col lg={12}>
            <KpiChart
              kpi={KpiTypes.TRACKING_COMPLIANCE}
              chartType="line"
              title={intl.formatMessage({
                id: 'locationDetails.kpiCharts.titles.trackingCompliance',
                defaultMessage: '% of Shipments Tracked',
              })}
              chartData={get(analyticsData, 'trackingCompliances.trackingCompliances', [] as TrackingComplianceModel[])}
            />
          </Col>
        </>
      );
    return null;
  };

  if (authorizations.hasLocationProfileKpiChartsAuthorization()) {
    return (
      <>
        <Row style={{ marginTop: '20px' }}>
          <Col>
            <h2>
              <FormattedMessage id="locationDetails.kpiCharts.sectionHeader" defaultMessage="Location KPI's" />{' '}
              {!noNav ? (
                <Link to={`${endpoints.ANALYTICS_LOCATION_DETAILS_ANALYTICS}?id=${get(locationDetails, 'id')}`}>
                  <FormattedMessage id="locationDetails.kpiCharts.sectionHeaderSeeMore" defaultMessage="See more" />
                </Link>
              ) : null}
            </h2>
          </Col>
        </Row>
        <Row>
          <DwellTimesChart />
          <GeoperformanceChart />
        </Row>
        <Row style={{ marginTop: '20px', marginBottom: '80px' }}>
          <TrackingComplianceCharts />
        </Row>
      </>
    );
  }

  return null;
};
export default LocationKPIs;
