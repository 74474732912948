import * as actions from './types';

export const openAddEditShipment = (type, stopNumber) => ({
  type: type,
  stopNumber,
});

export const cancelAddEditShipment = () => ({
  type: actions.CANCEL_SHIPMENT,
});

export const navigateStep = (type, id, formData, steps) => ({
  type,
  id,
  formData,
  steps,
});

export const setCarrierType = (carrier) => ({
  type: actions.SET_CARRIER,
  carrierType: carrier,
});

export const skipTempTracking = (formValues) => ({
  type: actions.SKIP_TEMP_TRACKING,
  formValues: formValues,
});

export const setTemperatureScale = (temperatureForm) => ({
  type: actions.SET_TEMPERATURE_SCALE,
  temperatureScale: temperatureForm,
});

export const saveShipment = (shipmentData) => ({
  type: actions.SAVE_SHIPMENT,
  payload: shipmentData,
});

export const closeSnackbar = () => ({
  type: actions.ADD_EDIT_CLOSE_SNACKBAR,
});

export const saveShipmentInProgress = (shipmentData) => ({
  type: actions.SAVE_SHIPMENT_STARTED,
  payload: shipmentData,
});

export const saveShipmentSuccess = (savedShipmentResponse) => ({
  type: actions.SAVE_SHIPMENT_SUCCESS,
  payload: savedShipmentResponse,
});

export const saveShipmentFailed = (errorDetails) => ({
  type: actions.SAVE_SHIPMENT_FAILED,
  payload: errorDetails,
});

export const saveStopEdit = (shipmentId, stopNumber, stopData) => ({
  type: actions.SAVE_SHIPMENT_STOP_EDIT,
  payload: { shipmentId, stopNumber, stopData },
});

export const saveStopEditStarted = (shipmentId, stopNumber, stopData) => ({
  type: actions.SAVE_SHIPMENT_STOP_EDIT_STARTED,
  payload: { shipmentId, stopNumber, stopData },
});

export const saveStopEditSuccess = (shipmentId, stopNumber, stopData) => ({
  type: actions.SAVE_SHIPMENT_STOP_EDIT_SUCCESS,
  payload: { shipmentId, stopNumber, stopData },
});

export const saveStopEditFailed = (shipmentId, stopNumber, errorDetails) => ({
  type: actions.SAVE_SHIPMENT_STOP_EDIT_FAILED,
  payload: { shipmentId, stopNumber, errorDetails },
});

export const toggleStop = (index, length) => ({
  type: actions.TOGGLE_STOP,
  index: index,
  length: length,
});

export const validateCarrier = (formData) => ({
  type: actions.CARRIER_VALIDATION,
  formData,
});

export const searchCarrier = (query) => ({
  type: actions.CARRIER_SEARCH,
  payload: query,
});

export const searchCarrierSuccess = (payload) => ({
  type: actions.CARRIER_SEARCH_SUCCESS,
  payload,
});

export const searchCarrierFailed = (query) => ({
  type: actions.CARRIER_SEARCH_FAIL,
  payload: query,
});

export const trackingMethodChosen = (trackingMethodId) => ({
  type: actions.TRACKING_METHOD_CHOSEN,
  payload: trackingMethodId,
});

export const postalCodeEntered = (context, stop, postalCode) => ({
  type: actions.POSTAL_CODE_ENTERED,
  payload: { context, stop, postalCode },
});

export const postalCodeLookupInProgress = (context, stop, postalCode) => ({
  type: actions.POSTAL_CODE_LOOKUP,
  payload: { context, stop, postalCode },
});

export const postalCodeLookupSuccess = (context, stop, postalCode, details) => ({
  type: actions.POSTAL_CODE_LOOKUP_SUCCESS,
  payload: { context, stop, postalCode, details },
});

export const postalCodeLookupFailed = (context, stop, postalCode) => ({
  type: actions.POSTAL_CODE_LOOKUP_FAIL,
  payload: { context, stop, postalCode },
});

export const openDropdown = (target) => ({
  type: actions.OPEN_DROPDOWN,
  target: target,
});

export const closeDropdown = () => ({
  type: actions.CLOSE_DROPDOWN,
});
