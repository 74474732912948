import * as React from 'react';
import AntAlert, { AlertProps as AntAlertProps } from 'antd/lib/alert';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import styled from 'styled-components';
import { rgba } from 'polished';
import { newFruit, green, burntOrange, bluish } from 'styles/colors';

const StyledAlert = styled(AntAlert)<{ color: string }>(
  (props) => `
  border-color: ${props.color} !important;
  background-color: ${rgba(props.color, 0.1)} !important;
  min-height: auto !important;

  .ant-alert-message {
    font-size: 20px;
    margin-bottom: 15px;
  }
`
);

export interface AlertProps extends AntAlertProps, WrappedComponentProps {
  alertType?: AntAlertProps['type'];
  alertItems?: Array<{
    error?: string[];
    supportRefId?: string;
    id: string;
  }>;
  messageIntlId: string;
  messageDefaultText: string;
}

const Alert: React.FC<AlertProps> = (props) => {
  const { alertType = 'info', alertItems = [], messageIntlId, messageDefaultText } = props;
  let color;
  const description = alertItems.map((alert) => {
    if (alert.error) {
      const alertDetails = alert.error.map((str: string) => <li key={str}>{str}</li>);
      return (
        <div key={alert.id}>
          {alert.supportRefId && (
            <p style={{ fontWeight: 500 }}>
              <FormattedMessage defaultMessage="Support Reference ID:" id="alerts.referenceId" /> {alert.supportRefId}
            </p>
          )}
          {alertDetails.length > 0 && <ul>{alertDetails}</ul>}
        </div>
      );
    }
    return undefined;
  });
  switch (alertType) {
    case 'error':
      color = newFruit;
      break;
    case 'success':
      color = green;
      break;
    case 'warning':
      color = burntOrange;
      break;
    case 'info':
      color = bluish;
      break;
    default:
      color = green;
      break;
  }
  return (
    <StyledAlert
      banner
      color={color}
      closable
      showIcon
      type={alertType}
      message={<FormattedMessage id={messageIntlId} defaultMessage={messageDefaultText} />}
      description={description}
    />
  );
};

export default injectIntl(Alert);
