import { mapStatus } from './status/mapStatus';
import { mapShipmentIdentifiers, ltlIdentifierTypes } from '../../../common/mappers/shipmentIdentifiersMapper';
import { mapRouteDetails } from './routeDetails/mapRouteDetails';
import { mapCarrierInfo } from './carrier/mapCarrier';

export const mapShipmentDetails = (shipment, shipmentModel) => {
  return {
    ...shipmentModel,
    mode: shipment.mode,
    shipmentId: shipment.shipmentId,
    masterShipmentId: shipment.masterShipmentId,
    tenantId: shipment.tenantId,
    documents: shipment.imageReferences || [],
    identifiers: mapShipmentIdentifiers(shipment.shipmentIdentifiers, ltlIdentifierTypes),
    tenantAttributes: shipment.tenantAttributes,
    tenantCustomAttributes: shipment.tenantCustomAttributes,
    status: mapStatus(shipment, shipmentModel.status),
    carrier: mapCarrierInfo(shipment.carrier, shipmentModel.carrier),
    route: mapRouteDetails(shipment, shipmentModel.route),
    map: {},
    entitlementInfo: shipment.entitlementInfo,
  };
};
