import { Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import * as React from 'react';
import styled from 'styled-components';

import * as styles from './Table.module.scss';
import { primaryGreyEighty, primaryGreyTwenty } from '../../styles/colors';

export const tableClasses = styles;

const StyledTable = styled.div<{ isSubTable?: boolean; isDraggable?: boolean }>`
  background-color: ${(props) => (props.isSubTable ? primaryGreyTwenty : 'transparent')};

  thead {
    ${(props) => props.isSubTable && `background-color: ${primaryGreyTwenty} !important`}
  }

  tr.ant-table-expanded-row:hover {
    background: ${primaryGreyTwenty};
  }

  tr.ant-table-expanded-row {
    background: ${primaryGreyTwenty};
  }

  th {
    ${(props) => props.isSubTable && `background-color: ${primaryGreyTwenty} !important`}
    color: ${primaryGreyEighty} !important;
  }
`;

interface CustomTableProps {
  isSubTable?: boolean;
  isDraggable?: boolean;
}

const Table: React.FC<TableProps<any> & CustomTableProps> = (props) => {
  return (
    <StyledTable data-testid="styles_table" isSubTable={props.isSubTable}>
      <AntTable {...props} />
    </StyledTable>
  );
};

export default Table;
