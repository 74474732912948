import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'reducers';
import SearchComponent from './SearchComponent';
import {
  buildSetSearchTerm,
  buildSetContactSearchTerm,
  buildSearchQueryAction,
  buildGetNextPageAction,
  buildLocationSearchQueryAction,
} from './ducks/actions';

const mapStateToProps = (state: RootState) => ({
  sortFields: state.sortResultsReducer.sortFields,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  executeLocationSearchFn: (
    searchConfig: { name: string; path: string },
    searchTerm: string,
    contactSearchTerm: string,
    sortBy: Array<{ fieldName: string; direction: string }>
  ) => dispatch(buildLocationSearchQueryAction(searchConfig, searchTerm, contactSearchTerm, sortBy)),
  executeSearchFn: (
    searchConfig: { name: string; path: string },
    searchTerm: string,
    contactSearchTerm: string,
    sortFields: Array<{ fieldName: string; direction: string }>,
    filters?: never[],
    page?: number
  ) => dispatch(buildSearchQueryAction(searchConfig, searchTerm, contactSearchTerm, sortFields, filters, page)),
  loadMoreResults: (previousSearchQuery: {
    search: string;
    filters: never[];
    sortBy: never[];
    resultStartIndex: number;
    resultsPerPage: number;
  }) => dispatch(buildGetNextPageAction(previousSearchQuery)),
  setSearchTerm: (searchTerm: string) => dispatch(buildSetSearchTerm(searchTerm)),
  setContactSearchTerm: (searchTerm: string) => dispatch(buildSetContactSearchTerm(searchTerm)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
