import * as React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

import LoadingFilters from '../LoadingFilters';
import DisplayedResults from '../DisplayedResults';

const messages = defineMessages({
  searching: {
    id: 'shipmentList.appliedFilterList.searching',
    defaultMessage: 'Searching...',
  },
  enterSearchTerm: {
    id: 'shipmentList.appliedFilterList.enterSearchTerm',
    defaultMessage: 'Please enter a search term',
  },
});

const AppliedFilterList: React.FC = (props: any) => {
  const hasFilters = some(props.filter, (x) => !isEmpty(x));
  const searchQuery = props.previousSearchQuery ? props.previousSearchQuery.search : null;
  const displayedResults = +props.displayedResultsCount; // The '+' is a Javascript convention to ensure the value is number and not a string
  const totalResultsCount = +props.totalResultsCount;
  return (
    <>
      {props.isLoading && <LoadingFilters title={props.intl.formatMessage(messages.searching)} {...props} />}

      {(!displayedResults || displayedResults <= 0) && !searchQuery && !props.isLoading && !hasFilters && (
        <div className="AppliedFilterList d-none d-lg-block">
          <FormattedMessage
            tagName="h3"
            id={messages.enterSearchTerm.id}
            defaultMessage={messages.enterSearchTerm.defaultMessage}
          />
        </div>
      )}

      {!props.isLoading && (
        <DisplayedResults
          props={props}
          searchQuery={searchQuery}
          totalResultsCount={totalResultsCount}
          isRecentDataSearched={props.isRecentDataSearched}
        />
      )}
    </>
  );
};

export default injectIntl(AppliedFilterList);
