import filterMessages from './filterMessages.json';

export const ltl = [
  filterMessages.scheduled,
  filterMessages.attemptingToTrack,
  filterMessages.inTransit,
  filterMessages.pickedUp,
  filterMessages.outForDelivery,
  filterMessages.pickupMissed,
  filterMessages.interlineMissed,
  filterMessages.departureMissed,
  filterMessages.deliveryMissed,
  filterMessages.held,
  filterMessages.delayed,
  filterMessages.atPickup,
  filterMessages.atTerminal,
  filterMessages.atDelivery,
  filterMessages.delivered,
  filterMessages.completedWithExceptions,
  filterMessages.timedOut,
  filterMessages.canceled,
  filterMessages.notReceivingData12To23,
  filterMessages.notReceivingData24To47,
  filterMessages.notReceivingData48To71,
  filterMessages.notReceivingDataMoreThan72,
];

export const tl = [
  filterMessages.scheduled,
  filterMessages.pendingApproval,
  filterMessages.pendingTrackingMethod,
  filterMessages.acquiringLocation,
  filterMessages.inTransittoAnyStop,
  filterMessages.inTransittoPickup,
  filterMessages.inTransittoDelivery,
  filterMessages.idleInTransittoAnyStop,
  filterMessages.idleInTransittoPickup,
  filterMessages.idleInTransittoDelivery,
  filterMessages.atAnyStop,
  filterMessages.atPickup,
  filterMessages.atDelivery,
  filterMessages.delivered,
  filterMessages.approvalDenied,
  filterMessages.expired,
  filterMessages.canceled,
  filterMessages.notReceivingData,
  //Giving me a compiler error here?
  filterMessages.pendingCarrier,
  filterMessages.atStop,
  filterMessages.inTransit,
];

export const ocean = [
  filterMessages.scheduled,
  filterMessages.loaded,
  filterMessages.discharged,
  filterMessages.embarked,
  filterMessages.inTransit,
  filterMessages.arrived,
  filterMessages.gateInEmpty,
  filterMessages.gateOutEmpty,
  filterMessages.gateInFull,
  filterMessages.available,
  filterMessages.gateOutDepartedTerminal,
  filterMessages.outOfNetwork,
  filterMessages.trackingError,
];

export const parcel = [
  filterMessages.scheduled,
  filterMessages.inTransit,
  filterMessages.outForDelivery,
  filterMessages.canceled,
  filterMessages.delivered,
  filterMessages.atStop,
  filterMessages.exception,
];

export const rail = [
  filterMessages.scheduled,
  filterMessages.inTransit,
  filterMessages.arrived,
  filterMessages.inGate,
  filterMessages.ramped,
  filterMessages.deramped,
  filterMessages.notified,
  filterMessages.outGate,
  filterMessages.timedOut,
  filterMessages.canceled,
  filterMessages.departedStop,
];

export const air = [
  filterMessages.scheduled,
  filterMessages.trackingInitiate,
  filterMessages.trackingStart,
  filterMessages.flightBooking,
  filterMessages.manifest,
  filterMessages.receiveFromShipper,
  filterMessages.loadOntoVehicle,
  filterMessages.departureFromStop,
  filterMessages.arrivalAtStop,
  filterMessages.unloadFromVehicle,
  filterMessages.consigneeNotify,
  filterMessages.delivery,
  filterMessages.trackingComplete,
  filterMessages.trackingEndDueToTimeout,
  filterMessages.trackingEndByUser,
  filterMessages.warning,
  filterMessages.exception,
  filterMessages.atStop,
  filterMessages.inTransit,
];

export const multiMode = [
  filterMessages.scheduled,
  filterMessages.atStop,
  filterMessages.inTransit,
  filterMessages.completed,
  filterMessages.exception,
];

export default {
  ltl,
  tl,
  ocean,
  parcel,
  rail,
  air,
  multiMode,
};
