import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import * as React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { SelectValue } from 'antd/lib/select';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { ReactComponent as OpenNewIcon } from 'components/common/assets/icons/icon_open_in_new.svg';
import { Button, Input, Select } from 'ui-components';
import { getPrincipalAuthorizations } from 'common/authorizations';
import {
  Identifier,
  ShipmentTenantAttribute,
  ShipmentTenantAttributeRequest,
  ShipmentLegCarrierInfo,
  ShipmentModeEnum,
} from 'models';
import * as styles from './ShipmentIdentifiers.module.scss';
import { ShipmentIdentifiersStateProps, ShipmentIdentifiersDispatchProps } from './ShipmentIdentifiersContainer';
import { getShipmentIdentifiersWithLabels } from '../utils/shipmentUtils';
import endpoints from '../../../common/endpoints';

const messages = defineMessages({
  shipmentIdentifiersValue: {
    id: 'shipmentDetails.shipmentIdentifiers.VALUE',
    defaultMessage: 'Input reference value',
  },
  shipmentIdentifiersKey: {
    id: 'shipmentDetails.shipmentIdentifiers.KEY',
    defaultMessage: 'Select Key',
  },
});

export interface ShipmentIdentifierState {
  identifiers: Identifier[];
  shipmentTenantAttributes: ShipmentTenantAttributeRequest[];
  selectedKey?: SelectValue;
  selectedValue: string;
}

class ShipmentIdentifiers extends React.Component<
  ShipmentIdentifiersStateProps & ShipmentIdentifiersDispatchProps,
  ShipmentIdentifierState
> {
  constructor(props: ShipmentIdentifiersStateProps & ShipmentIdentifiersDispatchProps) {
    super(props);
    this.state = {
      identifiers: this.props.identifiers,
      shipmentTenantAttributes: [],
      selectedKey: undefined,
      selectedValue: '',
    };
  }
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (!this.props.shipmentShareToken) {
      this.props.fetchTenantAttributes();
      this.props.fetchShipmentTenantAttributes();
    }
  }

  componentDidUpdate(prevProps: ShipmentIdentifiersStateProps & ShipmentIdentifiersDispatchProps) {
    if (prevProps.isFetchingShipmentTenantAttributes && !this.props.isFetchingShipmentTenantAttributes) {
      this.updateShipmentTenantAttributesInState();
    }
    if (
      prevProps.isCreatingOrUpdatingShipmentTenantAttributes &&
      !this.props.isCreatingOrUpdatingShipmentTenantAttributes
    ) {
      this.props.fetchShipmentTenantAttributes();
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
    this.props.resetTenantAttributes();
    this.updateShipmentTenantAttributesInState();
  }

  updateShipmentTenantAttributesInState = () => {
    const shipmentTenantAttributes = (this.props.shipmentTenantAttributes || []).map((attr) => {
      return { name: attr.tenantAttributeName, values: attr.tenantAttributeValues };
    });
    this.setState({
      ...this.state,
      shipmentTenantAttributes,
    });
  };
  saveShipmentTenantAttributes() {
    if (this.props.shipmentTenantAttributesEditMode) {
      this.props.createOrUpdateShipmentTenantAttributes(this.state.shipmentTenantAttributes);
    }
  }
  handleOnTenantAttributeChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!evt) {
      return undefined;
    }

    if (evt.target) {
      const target = evt.target;
      if (target.value) {
        const index: number = parseInt(target.getAttribute('data-index') || '-1', 10);
        const valueIndex: number = parseInt(target.getAttribute('data-value-index') || '-1', 10);
        this.updateTenantAttributeValue(target.value, index, valueIndex);
      }
    }
  };
  updateTenantAttributeValue = (value: string, index: number, valueIndex: number, resetAddForm?: boolean) => {
    const shipmentTenantAttribute: ShipmentTenantAttributeRequest = Object.assign(
      {},
      this.state.shipmentTenantAttributes[index]
    );
    shipmentTenantAttribute.values[valueIndex] = value;
    this.setState({
      ...this.state,
      selectedKey: resetAddForm ? undefined : this.state.selectedKey,
      selectedValue: resetAddForm ? '' : this.state.selectedValue,
      shipmentTenantAttributes: [
        ...this.state.shipmentTenantAttributes.slice(0, index),
        shipmentTenantAttribute,
        ...this.state.shipmentTenantAttributes.slice(index + 1),
      ],
    });
  };
  handleOnKeySelect = (menuItemValue: SelectValue) => {
    if (menuItemValue) {
      this.setState({
        ...this.state,
        selectedKey: menuItemValue,
      });
    }
  };

  handleOnValueChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!evt) {
      return undefined;
    }

    if (evt.target) {
      const target = evt.target;
      this.setState({
        ...this.state,
        selectedValue: target.value,
      });
    }
  };

  addTenantAttribute = () => {
    if (this.state.selectedKey && this.state.selectedValue) {
      const attrIndex = this.state.shipmentTenantAttributes.findIndex(
        (eachAttr) => eachAttr.name === this.state.selectedKey
      );
      if (attrIndex >= 0) {
        this.updateTenantAttributeValue(
          this.state.selectedValue,
          attrIndex,
          this.state.shipmentTenantAttributes[attrIndex].values.length,
          true
        );
      } else {
        this.addNewTenantAttribute();
      }
    }
  };

  addNewTenantAttribute() {
    if (this.state.selectedKey && this.state.selectedValue) {
      const shipmentTenantAttribute: ShipmentTenantAttributeRequest = {
        name: this.state.selectedKey,
        values: [this.state.selectedValue],
      };
      this.setState({
        ...this.state,
        selectedKey: undefined,
        selectedValue: '',
        shipmentTenantAttributes: [...this.state.shipmentTenantAttributes, shipmentTenantAttribute],
      });
    }
  }

  shouldDisplayOceanInsightsShipmentLink(): boolean {
    const authorizations = getPrincipalAuthorizations(this.props.principal);
    return (authorizations.hasOceanInsightsCTT() &&
      ShipmentModeEnum.OCEAN === this.props.mode &&
      authorizations.hasOceanInsightsNavigationNames()) as boolean;
  }

  isNotDuplicated(
    shipmentTenantAttributes: ShipmentTenantAttribute[],
    customAttributeName: string,
    customAttributeValue: string
  ): Boolean {
    return (
      shipmentTenantAttributes.find(
        (attribute: ShipmentTenantAttribute) =>
          attribute.tenantAttributeName === customAttributeName &&
          attribute.tenantAttributeValues.includes(customAttributeValue)
      ) == null
    );
  }

  render() {
    const identifiers: Identifier[] = get(this.props, 'identifiers', []);
    const identifiersWithLabels: Identifier[] = getShipmentIdentifiersWithLabels(identifiers, this.props.intl);
    const shipmentTenantAttributes: ShipmentTenantAttribute[] = get(this.props, 'shipmentTenantAttributes', []);
    const customAttributes: ShipmentTenantAttribute[] = get(this.props, 'customShipmentTenantAttributes', []);
    const carrierInfo: ShipmentLegCarrierInfo[] = get(this.props, 'carrierInfo', []);

    return (
      <div data-locator="shipment-identifiers">
        <div className={this.props.shipmentTenantAttributesEditMode ? styles.editIdentifiers : styles.identifiers}>
          {identifiersWithLabels.map((identifier: Identifier) => {
            return (
              <div
                key={`identifiers_${identifier.label}_${identifier.value}`}
                data-locator={`identifiers_${identifier.label}_${identifier.value}`}
                className={styles.container}
              >
                {identifier.intlMessageObject ? (
                  <FormattedMessage
                    id={identifier.intlMessageObject.id}
                    defaultMessage={identifier.intlMessageObject.defaultMessage}
                  />
                ) : (
                  <span>{identifier.label}</span>
                )}
                {identifier.primaryText && <span className={styles.primary}>({identifier.primaryText})</span>}:{' '}
                <span className={styles.value}>{identifier.value}</span>
              </div>
            );
          })}
          {carrierInfo.map((info) => {
            return (
              <div
                key={`carrierInfo_${info.name}_${info.value}`}
                data-locator={`carrierInfo_${info.name}_${info.value}`}
                className={styles.container}
              >
                <FormattedMessage
                  id={`shipmentDetails.carrierDetail.${camelCase(info.name)}`}
                  defaultMessage={info.name}
                />
                <span className={styles.value}>{`: ${info.value}`}</span>
              </div>
            );
          })}
          {!this.props.shipmentTenantAttributesEditMode &&
            shipmentTenantAttributes.map((shipmentTenantAttribute: ShipmentTenantAttribute) => {
              return shipmentTenantAttribute.tenantAttributeValues?.map((value: string) => {
                return (
                  <div
                    key={`identifiers_` + shipmentTenantAttribute.tenantAttributeName + `_` + value}
                    className={styles.container}
                  >
                    <span>{shipmentTenantAttribute.tenantAttributeName}</span>:{' '}
                    <span className={styles.value}>{value}</span>
                  </div>
                );
              });
            })}
          {/* Rendering attributes attached to shipment payload. They aren't editable. */}
          {!this.props.shipmentTenantAttributesEditMode &&
            customAttributes.map((shipmentTenantAttribute: ShipmentTenantAttribute) => {
              return shipmentTenantAttribute.tenantAttributeValues
                ?.filter((value: string) =>
                  this.isNotDuplicated(shipmentTenantAttributes, shipmentTenantAttribute.tenantAttributeName, value)
                )
                .map((value: string) => {
                  return (
                    <div
                      key={`identifiers_` + shipmentTenantAttribute.tenantAttributeName + `_` + value}
                      className={styles.container}
                    >
                      <span>{shipmentTenantAttribute.tenantAttributeName}</span>:{' '}
                      <span className={styles.value}>{value}</span>
                    </div>
                  );
                });
            })}
          {this.shouldDisplayOceanInsightsShipmentLink() && (
            <div className={styles.oceanInsightsShipment}>
              <a target="_blank" rel="noopener noreferrer" href={endpoints.OCEAN_INSIGHTS_CTT as string}>
                <FormattedMessage id="shipmentDetails.oceanInsights.ctt.link" defaultMessage="View More Details" />
              </a>
              <OpenNewIcon style={{ height: 12, width: 12, color: 'inherit', marginLeft: '6px' }} />
            </div>
          )}
          {this.props.shipmentTenantAttributesEditMode &&
            this.state.shipmentTenantAttributes.map(
              (shipmentTenantAttribute: ShipmentTenantAttributeRequest, index: number) => {
                return shipmentTenantAttribute.values.map((value: string, valueIndex: number) => {
                  return (
                    <div
                      key={['identifiers', index, valueIndex].join('_')}
                      className={`${styles.container} ${styles.editContainer}`}
                    >
                      <span>{shipmentTenantAttribute.name}</span>:&nbsp;
                      <Input
                        type="text"
                        placeholder={this.props.intl.formatMessage(messages.shipmentIdentifiersValue)}
                        className={styles.referenceValue}
                        onChange={this.handleOnTenantAttributeChange}
                        custom={{
                          name: 'tenantAttribute_' + index + '_' + valueIndex,
                          id: 'tenantAttribute_' + index + '_' + valueIndex,
                          autoComplete: 'off',
                          'data-locator': 'tenantAttribute_' + index + '_' + valueIndex,
                          value,
                          'data-index': index,
                          'data-value-index': valueIndex,
                        }}
                      />
                    </div>
                  );
                });
              }
            )}
        </div>
        {this.props.shipmentTenantAttributesEditMode && (
          <div className={styles.addNewIdentifier}>
            <Row className="align-items-end">
              <Col xs={6}>
                <h5 className={styles.label}>
                  <FormattedMessage id="shipmentDetails.shipmentIdentifiers.ADD_KEY" defaultMessage="ADD KEY" />
                </h5>
                <Select
                  value={this.state.selectedKey}
                  onChange={this.handleOnKeySelect}
                  style={{ width: '100%' }}
                  placeholder={this.props.intl.formatMessage(messages.shipmentIdentifiersKey)}
                  dataSource={(this.props.tenantAttributes || []).map((attr) => ({
                    value: attr.attributeName,
                  }))}
                />
              </Col>
              <Col xs={8}>
                <h5 className={styles.label}>
                  <FormattedMessage id="shipmentDetails.shipmentIdentifiers.ADD_VALUE" defaultMessage="VALUE" />
                </h5>
                <Input
                  type="text"
                  placeholder={this.props.intl.formatMessage(messages.shipmentIdentifiersValue)}
                  className={styles.referenceValue}
                  onChange={this.handleOnValueChange}
                  custom={{
                    name: 'shipmentSelectedValue',
                    id: 'shipmentSelectedValue',
                    autoComplete: 'off',
                    'data-locator': 'shipmentSelectedValue',
                    value: this.state.selectedValue,
                    disabled: !this.state.selectedKey,
                  }}
                />
              </Col>
              <Col xs={6}>
                <Button
                  type="primary-transparent"
                  clickFn={this.addTenantAttribute}
                  disabled={!this.state.selectedKey || !this.state.selectedValue}
                >
                  <PlusOutlined />
                  <FormattedMessage id="shipmentDetails.shipmentIdentifiers.ADD_BUTTON" defaultMessage="ADD" />
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(ShipmentIdentifiers);
