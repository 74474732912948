import HereMapsOptions from '../../../../common/hereMaps/hereMapsOptions';
import allStopHtmlMarker from './markers/allStopHtmlMarker';
import currentStopHtmlMarker from './markers/currentStopHtmlMarker';
import pingHtmlMarker from './markers/pingHtmlMarker';

// A majority of these options have default values in HereMapsOptions
// I've explicitly exposed it here so FEDs can see all
// the possible options that I use for the Shipment Detail page - Mark Serrano

const initMapOptions = (geoFenceThreshold) => {
  const mapOptions = new HereMapsOptions();

  // These four properties are deprecated already so they are being set to false - Mark Serrano
  mapOptions.defaultMarker.show = false;
  mapOptions.defaultMarkerPopup.show = false;
  mapOptions.route.show = false;
  mapOptions.route.style = { style: { lineWidth: 8, strokeColor: 'rgba(87, 160, 203, 0.50)' } };

  mapOptions.geofence.show = true;
  mapOptions.geofence.rangeInSeconds = geoFenceThreshold;

  mapOptions.fullRoute.data = null;
  mapOptions.fullRoute.html = null;
  mapOptions.fullRoute.show = true;
  mapOptions.fullRoute.style = { style: { lineWidth: 4, strokeColor: 'rgba(35,97,146, 1)' } };

  mapOptions.travelledRoute.data = null;
  mapOptions.travelledRoute.html = null;
  mapOptions.travelledRoute.show = true;
  mapOptions.travelledRoute.style = { style: { lineWidth: 4, strokeColor: 'rgba(35,97,146, 1)' } };

  mapOptions.remainingRoute.show = true;
  mapOptions.remainingRoute.style = { style: { lineWidth: 4, lineDash: [1, 3], strokeColor: 'rgba(35,97,146, 1)' } };
  mapOptions.remainingRoute.data = null;
  mapOptions.remainingRoute.html = null;

  mapOptions.allStopMarkers.show = true;
  mapOptions.allStopMarkers.data = null;
  mapOptions.allStopMarkers.html = allStopHtmlMarker;

  mapOptions.currentStopMarker.show = true;
  mapOptions.currentStopMarker.data = null;
  mapOptions.currentStopMarker.html = currentStopHtmlMarker;

  mapOptions.pings.show = true;
  mapOptions.pings.data = null;
  mapOptions.pings.html = pingHtmlMarker;
  mapOptions.pings.hiddenOnLoad = true;

  return mapOptions;
};

export default initMapOptions;
