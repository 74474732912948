import get from 'lodash/get';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import { Component } from 'react';
import { compose, withState } from 'recompose';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import '@project44-lib/here-maps-api-js';

import MapSidepanelItem from '../MapSidepanelItem/MapSidepanelItem';
import HereMapsBehavior from '../../../../common/hereMaps/hereMapsBehavior';
import HereMapsFactory from '../../../../common/hereMaps/hereMapsFactory';
import initMapOptions from './mapOptions';
import { initializeMapWithRouteData, initializeMapWithPingData } from './mapUtil';
import { withTheme } from '../../../../contexts/ThemeContext';
import MapDetailLegend from './MapDetailLegend';
import './MapDetail.scss';

export const mapId = 'custom-line-route-map';

// TODO: In order to show a side panel, we need to experiment around the map styles - Mark Serrano
const mapContainerStyles = {
  width: '100%',
  height: '100%',
  background: 'grey',
};

const tabType = {
  route: 0,
  location: 1,
};

/**
 * TODO: @Rob, tab placement (for the route and location toggle buttons) is done by
 * manipulating the styling and appending it to the map node. Maybe there's a simpler way,
 * See https://developer.here.com/api-explorer/maps-js/v3.0/infoBubbles/map-with-interactive-kml-objects
 * Look for the implementation of `renderControls` under the JS + HTML section
 * - Mark Serrano
 */
class MapDetail extends Component {
  componentDidMount() {
    this.initializeMap();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.shipmentDetails.milesTraveled !== prevProps.shipmentDetails.milesTraveled ||
      this.props.shipmentDetails.milesRemaining !== prevProps.shipmentDetails.milesRemaining
    ) {
      this.initializeMap();
    }
  }

  initializeMap = () => {
    let hereMapsConfig = undefined;
    if (get(this.props, 'sharedShipmentMapConfig')) {
      hereMapsConfig = get(this.props, 'sharedShipmentMapConfig');
    } else {
      hereMapsConfig = this.props.hereMapsConfig;
    }

    if (hereMapsConfig) {
      /**
       * Apparently what we get from `get(this.props, 'shipmentDetails.shipmentId')`
       * and from `this.props.match.params.shipmentDetailId` do not always match.
       * The this.props will have an old reference of the shipment id on initial render
       * and it will have the new shipment id on the subsequent render. As a consequence
       * this renders the map twice on the same map view - Mark Serrano
       */
      let shipmentId = get(this, 'props.match.params.shipmentDetailId');
      if (isNil(shipmentId)) {
        // We need this for the public details page since the shipment id is not in the url params - Mark Serrano
        shipmentId = get(this.props, 'shipmentDetails.shipmentId');
      }

      const mapOptions = initMapOptions(this.props.geoFenceThreshold);
      const mapInstance = HereMapsFactory.newInstance(this.props, mapId, mapOptions, hereMapsConfig);

      this.props.setMapInstance(mapInstance);
      initializeMapWithRouteData({ ...this.props, mapInstance });
      initializeMapWithPingData({ ...this.props, mapInstance });
      HereMapsFactory.renderMap(mapInstance, shipmentId);
      const aerisWeatherConfig = get(this.props, 'aerisWeatherConfig');
      if (aerisWeatherConfig && this.props.authorizations.shipmentDetailWeatherMapEnabled()) {
        HereMapsFactory.addWeatherLayer(mapInstance.mapData, aerisWeatherConfig);
      }
    }
  };

  handleLocationItemClick = (itemProps) => {
    const coordinate = {
      lat: get(itemProps, 'ping.coordinates.latitude'),
      lng: get(itemProps, 'ping.coordinates.longitude'),
    };
    HereMapsBehavior.setCenter(itemProps.mapInstance, coordinate);
    HereMapsBehavior.openPopup(itemProps.mapInstance, itemProps.ping.id);
    this.props.setActiveLocationItem(itemProps.ping.id);
  };

  handleMapToggle = (e) => {
    if (e.target.id === 'route') {
      this.props.setActiveTab(tabType.route);
      this.props.setPanelVisibility(false);
      HereMapsBehavior.showMarkers(this.props.mapInstance, 'stop', this.props.mapInstance.id);
      HereMapsBehavior.hideMarkersAndPopup(this.props.mapInstance, 'ping');
      HereMapsBehavior.showRouteLine(this.props.mapInstance);
    } else if (e.target.id === 'location') {
      this.props.setActiveTab(tabType.location);
      this.props.setPanelVisibility(true);
      HereMapsBehavior.hideMarkersAndPopup(this.props.mapInstance, 'stop');
      HereMapsBehavior.showMarkers(this.props.mapInstance, 'ping', this.props.mapInstance.id);
      HereMapsBehavior.hideRouteLine(this.props.mapInstance);
    }
  };

  render() {
    let pingsByLatest =
      this.props.shipmentDetails && this.props.shipmentDetails.pings
        ? orderBy(
            this.props.shipmentDetails.pings,
            (o) => {
              return new moment(o.pingDateTimes.utc);
            },
            ['desc']
          )
        : undefined;

    return (
      <div className="map-detail">
        <MapDetailLegend />
        <div className="map-detail__inner">
          <div className="map-detail__map-toggle">
            <button
              id="route"
              className={this.props.activeTab === 0 ? 'map-detail__toggle-btn--active' : 'map-detail__toggle-btn'}
              style={{
                backgroundColor: this.props.activeTab === 0 && get(this, 'props.theme.primaryColor', '#00558b'),
              }}
              type="button"
              onClick={this.handleMapToggle}
            >
              <FormattedMessage id="shipmentDetails.mapDetail.route" defaultMessage="Route">
                {(text) => <>{text}</>}
              </FormattedMessage>
            </button>
            <button
              id="location"
              className={this.props.activeTab === 1 ? 'map-detail__toggle-btn--active' : 'map-detail__toggle-btn'}
              style={{
                backgroundColor: this.props.activeTab === 1 && get(this, 'props.theme.primaryColor', '#00558b'),
              }}
              type="button"
              onClick={this.handleMapToggle}
            >
              <FormattedMessage id="shipmentDetails.mapDetail.location" defaultMessage="Location">
                {(text) => <>{text}</>}
              </FormattedMessage>
            </button>
          </div>
          <div className="map-detail__map-wrapper">
            {this.props.shipmentDetails && this.props.shipmentDetails.pings && (
              <div className={this.props.panelVisibility ? 'map-detail__side-panel' : 'map-detail__side-panel--hidden'}>
                <div className="side-panel__header">
                  <FormattedMessage
                    id="shipmentDetails.mapDetail.locationUpdates"
                    defaultMessage="Location Updates"
                    tagName="h4"
                  />
                </div>
                <div className="side-panel__item-wrapper">
                  {pingsByLatest.map((ping, index) => (
                    <MapSidepanelItem
                      key={index} //eslint-disable-line react/no-array-index-key
                      ping={ping}
                      mapInstance={this.props.mapInstance}
                      handleClick={this.handleLocationItemClick}
                      isActive={ping.id === this.props.activeLocationItem}
                      statusColor={this.props.shipmentDetails.statusColor}
                    />
                  ))}
                </div>
              </div>
            )}

            <div className="map-detail__map">
              <div data-testid="map-detail__map" id={mapId} style={mapContainerStyles} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const StatefulMapDetail = compose(
  withState('panelVisibility', 'setPanelVisibility', false),
  withState('activeTab', 'setActiveTab', tabType.route),
  withState('activeLocationItem', 'setActiveLocationItem', null)
)(withTheme(MapDetail));

export default StatefulMapDetail;
