import { useRef, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import { saveShipmentConfigs } from 'components/shipmentCounts/ducks/operations';
import endpoints from 'common/endpoints';

import { ModeFilterOptions, TitleAndSettingsForm } from '../types';
import StepPage from '../StepPageComponent';
import { initialFormValues } from './constants';
import TitleAndSettingsLayout from './layout';
import { useIntersectionObserver } from '../hooks';
import * as styles from './styles.module.scss';
import { useWizardForm } from '../context/WizardFormContext';
import { UserDetails, deserializeEditTile, serializeTile } from './utils';
import { clearSessionStorage, getEntityTypeFromSearch } from '../utils';
import { PrincipalContext, PrincipalContextProps } from '../../../contexts/PrincipalContext';

const slideInAnimation = (entry: IntersectionObserverEntry) => {
  const slideInQuickView = document.querySelector('#slide-in-quickview') as HTMLDivElement;
  if (!entry.isIntersecting) {
    slideInQuickView.classList.add(styles['slide-in']);
  } else {
    slideInQuickView.classList.remove(styles['slide-in']);
  }
};

interface TitleAndSettingsStepProps {
  modeFilterOptions: ModeFilterOptions;
  quickViewConfig: { config: any };
  currentTile: any;
}

const TitleAndSettingsStep = ({ modeFilterOptions, quickViewConfig, currentTile }: TitleAndSettingsStepProps) => {
  const principal: PrincipalContextProps | undefined = useContext(PrincipalContext);
  const quickviewCardRef = useRef<HTMLDivElement | null>(null);
  const { state: editTile } = useWizardForm();

  const history = useHistory();

  const location = useLocation();
  const entityType = getEntityTypeFromSearch(location.search);

  useIntersectionObserver(
    quickviewCardRef,
    {
      root: null,
      rootMargin: '-75px 0px 0px 0px',
      threshold: [0.3],
      freezeOnceVisible: false,
    },
    slideInAnimation
  );

  const handleSubmit = (formValues: TitleAndSettingsForm) => {
    const baseFilterPreferences = quickViewConfig.config.filter((item: any) => item.id !== editTile.id);
    const userDetails = new UserDetails(principal?.tenantId, principal?.id);
    const serializedTile = serializeTile(
      baseFilterPreferences,
      formValues,
      entityType,
      currentTile,
      userDetails,
      editTile
    );
    saveShipmentConfigs(serializedTile)
      .then(() => {
        clearSessionStorage();
        history.push(endpoints.DASHBOARD);
      })
      .catch((error) => {
        // TODO: The UX in this case should be handle in other ticket
        console.error('Something went wrong. Response Data:', error.response.data);
      });
  };
  return (
    <div className="m-6" id="title-settings-container">
      <StepPage
        title={<FormattedMessage id="quickViewConfig.title.titleAndSettings" defaultMessage="Title & Settings" />}
        description={
          <FormattedMessage
            id="quickViewConfig.description.titleAndSettingsForm"
            defaultMessage="Name your Quick View, select a number format, and add Health Scores."
          />
        }
      >
        <Formik
          initialValues={isEmpty(editTile) ? initialFormValues : deserializeEditTile(editTile)}
          onSubmit={handleSubmit}
        >
          <TitleAndSettingsLayout quickviewCardRef={quickviewCardRef} modeFilterOptions={modeFilterOptions} />
        </Formik>
      </StepPage>
    </div>
  );
};

export default TitleAndSettingsStep;
