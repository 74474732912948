import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledSpan = styled('span')`
  font-size: 2rem;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
interface ChannelListHeaderProps {
  toggleOpen: () => void;
  isOpen?: boolean;
}
const ChannelListHeader: React.FC<ChannelListHeaderProps> = (props) => {
  return (
    <StyledSpan className="sendbird-channel-header__title cursor-pointer" onClick={props.toggleOpen}>
      <FormattedMessage id="chat.channelList.header.title" defaultMessage="Messages" />
    </StyledSpan>
  );
};

export default ChannelListHeader;
