import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import flow from 'lodash/flow';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { PrincipalAuthorizations } from 'common/authorizations';
import {
  Shipment,
  ShipmentLeg,
  ShipmentStop,
  ShipmentEvent,
  ShipmentStopStatusEnum,
  ShipmentStopTypeEnum,
  CommonStatusCodeEnum,
  TruckloadStatusCodeEnum,
  SourceRegions,
  ShipmentLegCarrierInfo,
  EntitlementInfo,
} from '../models';
import CollapsedTimelineComponent from './CollapsedTimelineComponent';
import CurrentStopComponent from './CurrentStopComponent';
import LegEventComponent from './LegEventComponent';
import * as styles from './RouteTimeline.module.scss';
import { getEventDate } from '../utils/shipmentUtils';

const RouteTimelineComponent: React.FunctionComponent<
  Shipment & { mapInstance?: H.Map; shipmentShareToken?: string | undefined } & {
    authorizations?: PrincipalAuthorizations;
  } & { shouldHideStatus?: boolean } & { entitlementInfo?: EntitlementInfo }
> = (
  props: Shipment & { mapInstance?: H.Map; shipmentShareToken?: string | undefined } & {
    authorizations?: PrincipalAuthorizations;
  } & { shouldHideStatus?: boolean } & { entitlementInfo?: EntitlementInfo }
) => {
  const isPublicPage: boolean = props.shipmentShareToken !== undefined;
  const [hiddenTransitUpdates, setHiddenTransitUpdates] = React.useState<boolean>(isPublicPage ? true : false);
  let departedStops: ShipmentStop[] = [];
  let upcomingStops: ShipmentStop[] = [];
  let pastLegEventsComponents: any = [];
  let pastLegEvents: ShipmentEvent[] = [];
  let currentEvent: ShipmentEvent | undefined;
  let postCurrentStopLegEvents: any = [];
  let currentOrUpcomingStop: ShipmentStop;

  const { shouldHideStatus = false } = props;

  const transferFromMode: any = {};
  const convertEventDatesToTimestamp = (events: ShipmentEvent[]) => {
    return events.map((event) => {
      const newEvent: any = { ...event };
      if (event.dateTime) {
        newEvent.timestamp = moment.utc(event.dateTime.dateTimeUtc).format('x');
      } else if (event.scheduledDateTime) {
        newEvent.timestamp = moment.utc(event.scheduledDateTime.dateTimeUtc).format('x');
      }
      return newEvent;
    });
  };

  const sortEventsByTime = (events: Array<ShipmentEvent & { timestamp?: string }>) => {
    const orderedEvents = orderBy(events, ['timestamp'], ['asc']);
    return orderedEvents.map((event) => {
      delete event.timestamp;
      return event;
    });
  };

  const pickLatestEvent = (events: ShipmentEvent[]) => {
    return last(events);
  };

  const getLatestEventDateTime = (events: ShipmentEvent[]): ShipmentEvent | undefined => {
    const filteredEvents = events.filter((event) => event.dateTime !== undefined);
    const sortedEvents = orderBy(filteredEvents, ['dateTime.dateTimeUtc'], ['desc']);
    return sortedEvents.length > 0 ? sortedEvents[0] : undefined;
  };

  const displayCurrentEventHighlight = (stops: ShipmentStop[]) => {
    const eventOrdered = orderBy(stops, ['stopNumber'], ['desc']);
    for (const stop of eventOrdered) {
      const isCurrentEvent = getLatestEventDateTime(stop.events);
      if (isCurrentEvent) {
        currentEvent = isCurrentEvent;
        break;
      }
    }
  };

  let sortedLegEvents: ShipmentEvent[] = [];

  function getEventsWithDateTime(stop: ShipmentStop): ShipmentEvent[] {
    return stop.events.filter(
      (event: ShipmentEvent) =>
        !isNil(event.dateTime) || !isNil(event.scheduledDateTime) || !isNil(event.estimatedDateTime)
    );
  }

  function isInBetweenEvent(currentStop: ShipmentEvent, nextStop: ShipmentStop, event: ShipmentEvent): boolean {
    if (nextStop.events && nextStop.events[0]) {
      return (
        !isEmpty(currentStop) &&
        moment.utc(getEventDate(event).dateTimeUtc).isSameOrAfter(moment.utc(getEventDate(currentStop).dateTimeUtc)) &&
        moment
          .utc(getEventDate(event).dateTimeUtc)
          .isSameOrBefore(moment.utc(getEventDate(nextStop.events[0]).dateTimeUtc))
      );
    } else {
      return (
        !isEmpty(currentStop) &&
        moment.utc(getEventDate(event).dateTimeUtc).isSameOrAfter(moment.utc(getEventDate(currentStop).dateTimeUtc))
      );
    }
  }

  const removeEventsWithUnknownStatus = (events: ShipmentEvent[] | undefined): ShipmentEvent[] => {
    return events
      ? events.filter(
          (event) => !(event.type === CommonStatusCodeEnum.UNKNOWN && !event.description && !event.additionalInfo)
        )
      : [];
  };

  function buildPastLegEvents(stopEvent: ShipmentEvent) {
    return uniq([
      ...pastLegEvents,
      ...sortedLegEvents.filter((event) =>
        !isEmpty(stopEvent) && (stopEvent.dateTime || stopEvent.scheduledDateTime)
          ? moment.utc(getEventDate(event).dateTimeUtc).isBefore(moment.utc(getEventDate(stopEvent).dateTimeUtc))
          : true
      ),
    ]);
  }

  const stopCards = props.legs.map((leg: ShipmentLeg, legIndex: number) => {
    const eventsWithStatuses = removeEventsWithUnknownStatus(leg.events);
    const shipmentEventsWithStatuses = removeEventsWithUnknownStatus(props.events);
    sortedLegEvents = orderBy(
      [...eventsWithStatuses, ...shipmentEventsWithStatuses],
      [(obj) => moment.utc(getEventDate(obj)?.dateTimeUtc)],
      ['asc']
    );
    displayCurrentEventHighlight(leg.stops);
    return orderBy(leg.stops, ['stopNumber'], ['asc']).map((stop, stopIndex) => {
      const stopCopy = Object.assign({}, stop);
      stopCopy.mode = leg.mode;

      stopCopy.events = orderBy(
        getEventsWithDateTime(stopCopy),
        [(obj) => moment.utc(getEventDate(obj).dateTimeUtc)],
        ['asc']
      );

      // ! This will not render the TRANSFER stop, since the first stop of the next leg is exactly the same
      if (
        stopCopy.type === ShipmentStopTypeEnum.TRANSFER &&
        stopIndex === props.legs[legIndex].stops.length - 1 &&
        props.legs[legIndex + 1] &&
        get(props.legs[legIndex + 1].stops[0], ['type']) === ShipmentStopTypeEnum.TRANSFER
      ) {
        transferFromMode[stop.stopName] = leg.mode;
        const departedStatusStopEvent: ShipmentEvent =
          flow([convertEventDatesToTimestamp, sortEventsByTime, pickLatestEvent])(stopCopy.events) || {};
        pastLegEvents = buildPastLegEvents(departedStatusStopEvent);
        return undefined;
      }

      const isShipmentEnRouteToStop =
        leg.stops[stopIndex + 1] && leg.stops[stopIndex + 1].status === ShipmentStopStatusEnum.EN_ROUTE;

      const shouldAddStopToDepartedStopsList =
        stopCopy.status === ShipmentStopStatusEnum.DEPARTED &&
        stopCopy.type !== ShipmentStopTypeEnum.DESTINATION &&
        !isShipmentEnRouteToStop;

      if (shouldAddStopToDepartedStopsList) {
        const prevLegMode = get(props.legs[legIndex - 1], 'mode');
        departedStops.push({ ...stopCopy, prevLegMode });
        const departedStatusStopEvent: ShipmentEvent =
          flow([convertEventDatesToTimestamp, sortEventsByTime, pickLatestEvent])(stopCopy.events) || {};
        pastLegEvents = buildPastLegEvents(departedStatusStopEvent);
        return undefined;
      }

      const shouldAddStopToUpcomingStopsList =
        [ShipmentStopStatusEnum.EN_ROUTE].includes(stopCopy.status) &&
        currentOrUpcomingStop &&
        stopCopy.type !== ShipmentStopTypeEnum.TRANSFER;

      if (shouldAddStopToUpcomingStopsList) {
        upcomingStops.push(stopCopy);
        return undefined;
      }

      if (
        [
          ShipmentStopStatusEnum.EN_ROUTE,
          ShipmentStopStatusEnum.ARRIVED,
          ShipmentStopStatusEnum.AT_PICKUP,
          ShipmentStopStatusEnum.DEPARTED,
          ShipmentStopStatusEnum.DISPATCHED,
          ShipmentStopStatusEnum.UNKNOWN,
        ].includes(stopCopy.status) &&
        upcomingStops.length === 0
      ) {
        currentOrUpcomingStop = { ...stopCopy };
      }

      const mostRecentCurrentStopEvent: ShipmentEvent =
        flow([convertEventDatesToTimestamp, sortEventsByTime, pickLatestEvent])(stopCopy.events) || {};

      pastLegEvents = buildPastLegEvents(mostRecentCurrentStopEvent);

      const mostRecentLegEvent = last(sortedLegEvents);
      const mostRecentLegEventType = get(mostRecentLegEvent, 'type', '');
      const mostRecentStopEvent = last(get(stop, 'events'));
      const activeLegEvent =
        mostRecentLegEventType !== TruckloadStatusCodeEnum.ETA_ERROR &&
        (!mostRecentStopEvent ||
          moment
            .utc(get(mostRecentLegEvent, 'dateTime.dateTimeUtc'))
            .isSameOrAfter(moment.utc(get(mostRecentStopEvent, 'dateTime.dateTimeUtc'))))
          ? mostRecentLegEvent
          : mostRecentStopEvent;
      // Only show previous events if origin to prevent duplicates
      pastLegEventsComponents =
        stopCopy.type === ShipmentStopTypeEnum.ORIGIN
          ? React.Children.toArray(
              pastLegEvents.map((latestEvent, index) => {
                return (
                  <LegEventComponent
                    key={latestEvent.type}
                    hide={hiddenTransitUpdates}
                    isPublicPage={isPublicPage}
                    event={latestEvent}
                    halfTop={
                      mostRecentLegEventType
                        ? mostRecentLegEventType === TruckloadStatusCodeEnum.DISPATCHED ||
                          mostRecentLegEventType === CommonStatusCodeEnum.IN_TRANSIT
                        : false
                    }
                    activeLegEvent={activeLegEvent}
                    isLastEvent={index + 1 === pastLegEvents.length}
                    mode={leg.mode}
                  />
                );
              })
            )
          : [];
      // ! If postCurrentStopLegEvents is empty, then the active stop must be in the current stop
      postCurrentStopLegEvents = React.Children.toArray(
        sortedLegEvents
          .filter((event) => {
            const nextStopCopy = Object.assign({}, leg.stops[stopIndex + 1]);
            nextStopCopy.mode = leg.mode;
            nextStopCopy.events = leg.stops[stopIndex + 1]
              ? orderBy(
                  getEventsWithDateTime(nextStopCopy),
                  [(obj) => moment.utc(getEventDate(obj)?.dateTimeUtc)],
                  ['asc']
                )
              : [];
            return isInBetweenEvent(mostRecentCurrentStopEvent, nextStopCopy, event);
          })
          .map((latestEvent) => {
            // if Transit Events are in collapsed state only show active leg event

            return (
              <LegEventComponent
                key={latestEvent.type}
                hide={hiddenTransitUpdates}
                isPublicPage={isPublicPage}
                halfBottom={latestEvent.type === CommonStatusCodeEnum.COMPLETED}
                event={latestEvent}
                activeLegEvent={latestEvent.type !== CommonStatusCodeEnum.COMPLETED ? last(sortedLegEvents) : {}}
                mode={leg.mode}
              />
            );
          })
      );

      /* This anchors the origin to the top of the timeline if we don't have a status for the origin */
      const originStatusUnknown =
        currentOrUpcomingStop &&
        currentOrUpcomingStop.type === ShipmentStopTypeEnum.ORIGIN &&
        currentOrUpcomingStop.status === ShipmentStopStatusEnum.UNKNOWN;

      return (
        <React.Fragment key={`${leg.id}${stop.stopName}${stop.stopNumber}`}>
          {originStatusUnknown ? null : pastLegEventsComponents}
          <CurrentStopComponent
            hasActiveStop={postCurrentStopLegEvents.length === 0}
            activeStopEvent={currentEvent}
            stop={currentOrUpcomingStop}
            transferFromMode={transferFromMode[get(currentOrUpcomingStop, 'stopName')]}
            shipmentId={props.id}
            mapInstance={props.mapInstance}
            authorizations={props.authorizations}
            mostRecentLegEvent={mostRecentLegEvent}
            isCrossRegionShipment={
              (props.authorizations?.isEUEnvironment() === true && props.sourceRegion === SourceRegions.NA12) ||
              (props.authorizations?.isEUEnvironment() === false && props.sourceRegion === SourceRegions.EU12)
            }
            shouldHideStatus={shouldHideStatus}
            carrierIdentifiers={leg.carrierInfo}
            shipmentIdentifiers={props.identifiers}
            entitlementInfo={props.entitlementInfo}
          />
          {originStatusUnknown ? pastLegEventsComponents : null}
          {postCurrentStopLegEvents}
        </React.Fragment>
      );
    });
  });

  function orderStops(stops: ShipmentStop[]) {
    return orderBy(
      stops,
      [(stop) => (stop.events.length > 0 ? getEventDate(stop.events[0])?.dateTimeUtc : stop.stopNumber)],
      ['asc']
    );
  }

  departedStops = orderStops(departedStops);
  upcomingStops = orderStops(upcomingStops);

  const hasActiveStop: boolean = postCurrentStopLegEvents.length === 0;

  const allCarrierIdentifiers = flatten(props.legs.map((leg) => leg.carrierInfo)).filter(
    (carrierInfo) => carrierInfo !== undefined
  ) as ShipmentLegCarrierInfo[];
  return (
    <Container className={styles.routeTimelineContainer}>
      <Row>
        <Col xs={24}>
          <h3 className={styles.sectionTitle}>Timeline</h3>
        </Col>
      </Row>
      {isPublicPage && (
        <Row className="align-items-stretch">
          <Col xs={{ offset: 8 }} className="align-self-center">
            <button
              onClick={() => {
                setHiddenTransitUpdates(!hiddenTransitUpdates);
              }}
              className={`${styles.showHiddenTransitUpdates} ${
                hiddenTransitUpdates ? '' : styles.showHiddenTransitUpdatesOpen
              }`}
            >
              <FormattedMessage id="shipmentDetails.transitUpdatesToggle" defaultMessage="Transit Updates" />
            </button>
          </Col>
        </Row>
      )}
      {departedStops.length > 0 && (
        <CollapsedTimelineComponent
          stops={Array.from(new Set(departedStops))}
          events={pastLegEvents}
          showTravelLine
          showDottedLine={false}
          mapInstance={props.mapInstance}
          authorizations={props.authorizations}
          hasActiveStop={hasActiveStop}
          isPublicPage={isPublicPage}
          activeStopEvent={currentEvent}
          shipmentId={props.id}
          hiddenTransitUpdates={hiddenTransitUpdates}
          sourceRegion={props.sourceRegion}
          shouldHideStatus={shouldHideStatus}
          carrierIdentifiers={allCarrierIdentifiers}
          shipmentIdentifiers={props.identifiers}
          entitlementInfo={props.entitlementInfo}
        />
      )}
      {stopCards}
      {upcomingStops.length > 0 && (
        <CollapsedTimelineComponent
          stops={Array.from(new Set(upcomingStops))}
          events={[]}
          showTravelLine={false}
          showDottedLine
          isPublicPage={isPublicPage}
          mapInstance={props.mapInstance}
          authorizations={props.authorizations}
          hasActiveStop={hasActiveStop}
          activeStopEvent={currentEvent}
          shipmentId={props.id}
          sourceRegion={props.sourceRegion}
          shouldHideStatus={shouldHideStatus}
          carrierIdentifiers={allCarrierIdentifiers}
          shipmentIdentifiers={props.identifiers}
          entitlementInfo={props.entitlementInfo}
        />
      )}
      <Row />
    </Container>
  );
};

export default RouteTimelineComponent;
