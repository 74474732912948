import { FC, useContext } from 'react';
import * as postRobot from 'post-robot';
import isNil from 'lodash/isNil';
import { FormattedMessage } from 'react-intl';
import { Button } from 'ui-components';
import { ShipmentStopTypeEnum, ShipmentStopAppointmentWindow, ShipmentLegCarrierInfo, Identifier } from 'models';
import { supplyStackUrls } from 'common/AppConstants';
import { SlotBookingActivityTypeEnum, BookingFinishedEvent } from '../SlotBooking';
import { SupplyStackIFrameContext, SupplyStackIFrameContextProps } from '../SupplyStackIFrames/SupplyStackIFrames';

export interface SlotBookingWizardProps {
  shipmentId?: string;
  stopId?: string;
  stopType?: ShipmentStopTypeEnum;
  appointmentWindow: ShipmentStopAppointmentWindow;
  locationId?: string | null;
  carrierIdentifier?: ShipmentLegCarrierInfo;
  shipmentIdentifiers: Identifier[];
  onBookingFinished: (booking: BookingFinishedEvent, timezone?: string | null) => void;
}

const SlotBookingWizard: FC<SlotBookingWizardProps> = ({
  stopType,
  carrierIdentifier,
  shipmentIdentifiers,
  appointmentWindow,
  locationId,
  stopId,
  shipmentId,
  onBookingFinished,
}) => {
  const { setIsBookingWizardOpen, bookingWizardRef } =
    useContext<SupplyStackIFrameContextProps>(SupplyStackIFrameContext);

  if (isNil(locationId) || isNil(carrierIdentifier) || isNil(stopId) || isNil(shipmentId)) {
    return null;
  }

  const setupEventListeners = () => {
    const onBookingFinishedListener = postRobot.on(
      `wizard#onBookingFinished`,
      (event: { data: BookingFinishedEvent }) => {
        onBookingFinished(event.data, appointmentWindow.localTimeZoneIdentifier);
        return Promise.resolve();
      }
    );

    const onNotificationTriggeredListener = postRobot.on(`wizard#onNotificationTriggered`, (event) => {
      return Promise.resolve();
    });

    const onCloseListener = postRobot.on(`wizard#onClose`, (event) => {
      onBookingFinishedListener.cancel();
      onNotificationTriggeredListener.cancel();
      onCloseListener.cancel();
      setIsBookingWizardOpen(false);
      return Promise.resolve();
    });
  };

  const toggleWizard = (event: MouseEvent) => {
    const umsFrameElement = bookingWizardRef?.current;
    if (isNil(umsFrameElement)) {
      return;
    }
    const childWindow = umsFrameElement.contentWindow;
    postRobot
      .send(
        childWindow,
        'wizard#open',
        {
          booking: JSON.stringify({
            activityType,
            locationIntegrationId: locationId,
            carrierIdentifier: carrierIdentifier
              ? {
                  type: carrierIdentifier.name,
                  value: carrierIdentifier.value,
                }
              : undefined,
            slotRangeFrom: appointmentWindow?.utcAppointmentStartDateTime,
            slotRangeTo: appointmentWindow?.utcAppointmentEndDateTime,
            orderNumber: shipmentIdentifiers[0]?.value,
            stopId,
            transOrderSSRI: shipmentId,
          }),
          capFeUrl: supplyStackUrls.supplyStackCarrierUrl,
          sbUrl: supplyStackUrls.supplyStackAPIUrl,
          umsUrl: supplyStackUrls.supplyStackUMSUrl,
          p44Theme: supplyStackUrls.supplyStackTheme,
        },
        { timeout: 10000 }
      )
      .then(() => {
        setIsBookingWizardOpen(true);
        setupEventListeners();
      })
      .catch((error) => {
        setIsBookingWizardOpen(false);
      });
    event.stopPropagation();
    event.preventDefault();
  };

  let activityType = SlotBookingActivityTypeEnum.IN_AND_OUTBOUND;

  if (stopType === ShipmentStopTypeEnum.PICKUP || stopType === ShipmentStopTypeEnum.ORIGIN) {
    activityType = SlotBookingActivityTypeEnum.OUTBOUND;
  } else if (stopType === ShipmentStopTypeEnum.DELIVERY || stopType === ShipmentStopTypeEnum.DESTINATION) {
    activityType = SlotBookingActivityTypeEnum.INBOUND;
  }

  return (
    <>
      {!isNil(bookingWizardRef?.current) && (
        <Button clickFn={toggleWizard} type="primary">
          <FormattedMessage id="slotbooking.wizard.book" defaultMessage="Book Slot" />
        </Button>
      )}
    </>
  );
};
export default SlotBookingWizard;
