import { useContext } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { getPrincipalAuthorizations } from 'common/authorizations';

import CheckList from '../../common/checkList/CheckList';
import filterMessages from '../modes/filterMessages.json';

const messages = defineMessages({
  holds: {
    id: 'quickView.oceanActiveHoldsFilter.holds',
    defaultMessage: 'HOLDS',
  },
});

const oceanActiveHoldsFilters = [
  {
    id: filterMessages.customsHold.id,
    dataKey: filterMessages.customsHold.defaultMessage,
    labelText: <FormattedMessage {...filterMessages.customsHold} />,
    propertyName: 'customsHold',
    checked: false,
  },
  {
    id: filterMessages.lineHolds.id,
    dataKey: filterMessages.lineHolds.defaultMessage,
    labelText: <FormattedMessage {...filterMessages.lineHolds} />,
    propertyName: 'lineHolds',
    checked: false,
  },
  {
    id: filterMessages.otherHolds.id,
    dataKey: filterMessages.otherHolds.defaultMessage,
    labelText: <FormattedMessage {...filterMessages.otherHolds} />,
    propertyName: 'otherHolds',
    checked: false,
  },
];

const OceanActiveHoldsFilter = (props) => {
  const principalContext = useContext(PrincipalContext);
  const authorizations = getPrincipalAuthorizations(principalContext);
  const isShipmentListFiltersHorizontalEnabled = authorizations.shipmentListFiltersHorizontalEnabled();
  const customProps = isShipmentListFiltersHorizontalEnabled ? { hasSelectAndClearAllOption: true } : {};
  const oceanActiveHoldsValues = get(props, 'filter.oceanActiveHolds', []);

  const checkListData = oceanActiveHoldsFilters.map((item) => ({
    ...item,
    checked: oceanActiveHoldsValues.includes(item.dataKey),
  }));

  const onCheck = (checkboxItem) =>
    props.handleCheckboxGroupUpdate({ ...checkboxItem, labelText: checkboxItem.dataKey }, 'oceanActiveHolds');

  return (
    <CheckList
      mode={props.checkboxTheme}
      listTitle={props.intl.formatMessage(messages.holds)}
      checkListData={checkListData}
      checkFn={onCheck}
      {...customProps}
    />
  );
};

export default injectIntl(OceanActiveHoldsFilter);
