import qs from 'querystring';
import { useState, useContext } from 'react';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import get from 'lodash/get';
import { Autosuggest } from 'ui-components';

import { PrincipalContext } from 'contexts/PrincipalContext';
import { getPrincipalAuthorizations } from 'common/authorizations';
import useDebounce from 'hooks/useDebounce';
import { FILTER_RESULT_DELAY } from 'common/AppConstants';
import AutocompleteFilter from '../../common/autocompleteFilter/AutocompleteFilter';
import useSuggestions from '../util/useSuggestions';
import { SUGGESTIONS_MAP } from '../ducks/types';

const INITIAL_AMOUNT_VISIBLE_STATUS_CITY = 3;

const messages = defineMessages({
  filterByAnyStopLocation: {
    id: 'quickView.locationFilter.filterByAnyStopLocation',
    defaultMessage: 'Filter by Any Stop Location',
  },
});

const LocationFilter = (props) => {
  const [currentValue, setCurrentValue] = useState('');
  const debouncedValue = useDebounce(currentValue, FILTER_RESULT_DELAY);
  const params = qs.stringify({ q: debouncedValue });
  const suggestedLocations = useSuggestions(SUGGESTIONS_MAP.location, params);
  const listData = get(props, 'filter.location', []);

  const principalContext = useContext(PrincipalContext);
  const authorizations = getPrincipalAuthorizations(principalContext);
  const isShipmentListFiltersHorizontalEnabled = authorizations.shipmentListFiltersHorizontalEnabled();

  if (isShipmentListFiltersHorizontalEnabled) {
    return (
      <Autosuggest
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        placeholder="Try a company name, ID, or address of a stop"
        suggestions={suggestedLocations}
        listData={listData}
        handleAutocompleteUpdate={props.handleAutocompleteUpdate}
        name="location"
      />
    );
  }

  return (
    <Col>
      <label htmlFor="location-autocomplete-filter">
        {<FormattedMessage id="quickView.locationFilter.location" defaultMessage="LOCATION" />}
      </label>
      <AutocompleteFilter
        idProp="location-autocomplete-filter"
        placeholder={props.intl.formatMessage(messages.filterByAnyStopLocation)}
        currentValue={currentValue}
        dataSource={suggestedLocations}
        updateFn={(inputValue) => setCurrentValue(inputValue)}
        newRequestFn={(filterItem) => props.handleAutocompleteUpdate(filterItem, 'location')}
        listData={listData}
        showAllChips={get(props, 'showSelections.showAllLocation')}
        passedInDeleteFn={(filterItem) => props.handleAutocompleteUpdate(filterItem, 'location')}
        initialAmountVisible={INITIAL_AMOUNT_VISIBLE_STATUS_CITY}
        showHideAllFn={() => props.toggleShowSelections('Location')}
        dataLocator="location-filter"
      />
    </Col>
  );
};

export default injectIntl(LocationFilter);
