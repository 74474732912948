import { ActionType, getType } from 'typesafe-actions';
import { Reducer } from 'redux';

import * as hereMapsConfigActions from './actions';
import { HereMapsConfig } from '../models/HereMapsConfig';

export type HereMapConfigAction = ActionType<typeof hereMapsConfigActions>;

export interface HereMapsConfigState {
  readonly config?: HereMapsConfig;
}

const initialState: HereMapsConfigState = {
  config: undefined,
};

const hereMapsConfigReducer: Reducer<HereMapsConfigState, HereMapConfigAction> = (state = initialState, action) => {
  switch (action.type) {
    case getType(hereMapsConfigActions.fetch.success): {
      return {
        ...state,
        config: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default hereMapsConfigReducer;
