import { createContext, FC, useContext, useEffect } from 'react';
import { WizardFormState } from '../types';

const STORE_NAME = '__QVCF__';

interface WizardFormProviderProps {
  defaultValues?: WizardFormState;
}

const persistState = (state: WizardFormState) => sessionStorage.setItem(STORE_NAME, JSON.stringify(state));
const getPersistedState = (): WizardFormState => JSON.parse(sessionStorage.getItem(STORE_NAME) as string);
const clearPersitedState = () => sessionStorage.removeItem(STORE_NAME);

const WizardFormContext = createContext<any | null>(null);
WizardFormContext.displayName = 'WizardFormContext';

const WizardFormProvider: FC<WizardFormProviderProps> = ({ children, defaultValues = {} }) => {
  const initialValues = getPersistedState() || defaultValues;

  useEffect(() => clearPersitedState, []);

  return <WizardFormContext.Provider value={initialValues}>{children}</WizardFormContext.Provider>;
};

const useWizardForm = () => {
  const state = useContext(WizardFormContext);

  if (state === null) {
    throw new Error('useWizardForm must be used within a WizardFormProvider');
  }

  const updateState = (newState: object) => {
    const nextState = Object.assign(state, newState);
    persistState(nextState);
  };

  return { state, updateState };
};

export { WizardFormProvider, useWizardForm };
