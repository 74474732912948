import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  InventoryOrderStatusCode,
  InventoryOrderStatusCodeIntlKeys,
  InventoryOrderStatusCheckListFilterInput,
  InventoryOrderStatusCheckListOption,
} from 'components/Inventory/models';
import CollapsableItem from '../../common/ListPageLayout/CollapsableItem';
import CheckList from '../../common/checkList/CheckList';

const StatusCode = ({ dataKey, checkedValues = [], setValuesFn }: InventoryOrderStatusCheckListFilterInput) => {
  const intl = useIntl();

  const checkListData = Object.entries(InventoryOrderStatusCode).map((item) => {
    return {
      id: item[1],
      labelText: intl.formatMessage(InventoryOrderStatusCodeIntlKeys[item[1]]),
      checked: checkedValues.includes(item[1]),
    };
  });

  const onCheckFn = (option: InventoryOrderStatusCheckListOption) => {
    if (option.checked) {
      const newCheckedValues = checkedValues.filter((value) => value !== option.id);
      return setValuesFn(newCheckedValues);
    }

    return setValuesFn([...checkedValues, option.id]);
  };

  const activeKey = isNil(checkedValues) || isEmpty(checkedValues) ? undefined : dataKey;

  return (
    <CollapsableItem
      panelTitle={intl.formatMessage({ id: 'select.orderTable.filters.orderStatus', defaultMessage: 'Order Status' })}
      dataKey={dataKey}
      activeKey={activeKey}
    >
      <CheckList
        className="px-0"
        mode="dark"
        checkListData={checkListData}
        checkFn={onCheckFn}
        data-locator="orderstatus-filter"
      />
    </CollapsableItem>
  );
};

export default StatusCode;
