import { SortField } from './SortField';
import { SortDirection } from './SortDirection';

export function buildUrlWithSortParams(url: URL, sortFields?: SortField<string>[]): URL {
  if (!sortFields) {
    return url;
  } else {
    const formattedSortFields = sortFields
      .filter((sortField: SortField<string>) => !!sortField.fieldName)
      .map((validFields: SortField<string>) => {
        if (validFields.fieldName) {
          //fieldname should always be there per above filter but compiler doesn't recognize it.
          return `${validFields.fieldName}:${validFields.sortDirection || SortDirection.ASC}`;
        } else {
          return '';
        }
      });
    if (formattedSortFields.length > 0) {
      url.searchParams.append('sort', formattedSortFields.join(','));
    }
    return url;
  }
}
