import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { ShipmentStopTypeEnum } from 'models';
import { COUNTRIES_USE_MILES, STATES_SELECTION } from '../AppConstants';
// "Unit"
// "Apartment" or "APT"
// "Suite" or "STE"
// "#"
// "Building" or "BLDG"
// "Office" or "OFC"
// "Penthouse" or "PH"
// "Room" or "RM"
// "Space" or "SPC"
export enum AddressLine2Units {
  UNIT = 'Unit',
  APT = 'Apartment',
  STE = 'Suite',
  '#' = '#',
  BLDG = 'Building',
  OFC = 'Office',
  PH = 'Penthouse',
  RM = 'Room',
  SPC = 'Space',
}
export default class LocationUtils {
  /**
   * Returns City, State for US Addresses otherwise City, Country
   *
   * @param {string} city
   * @param {string} state
   * @param {string} country
   */
  static formatCityStateCountry(city: string, state: string, country?: string) {
    const cityString = city ? city : '';
    if (!country || country === 'US') {
      const stateString = state ? `, ${state}` : '';
      return `${cityString}${stateString}`;
    }
    return cityString ? `${cityString}, ${country}` : country;
  }

  /**
   * what we know is that shipment details are the source of truth, and we have shipment stops
   * which will have the location, address, and contry code. Given a location object , with
   * a stop name look at the stop, and get the country code.
   * @param locationObject
   * @param stopName
   * @param shipmentStops
   */

  static getCountryCodeForLocation(
    locationObject: any,
    stopName: string,
    shipmentStops: any,
    stopType?: ShipmentStopTypeEnum
  ) {
    // check for  US  states, if result is in the list then it's undefined
    const result = STATES_SELECTION.find((stateSelection) => stateSelection.state === locationObject?.state);
    const shipmentStop = shipmentStops?.find(
      (shipment: any) =>
        locationObject?.locationId === shipment.location?.locationId ||
        (shipment.stopName !== 'Unknown' &&
          shipment.stopName === stopName &&
          locationObject?.city === shipment.location?.address?.city &&
          (!locationObject?.locationId || !shipment.location))
    );
    if (result && !isNil(shipmentStops)) {
      return undefined;
    } else if (result === undefined && !isNil(shipmentStops)) {
      if (stopType && shipmentStop?.stopType !== stopType) {
        return undefined;
      }
      return get(shipmentStop, 'location.address.countryCodeDto');
    }
  }

  static convertMilesToKm(miles: number) {
    return parseFloat((miles * 1.609).toFixed(2));
  }

  static shouldShowInMiles(country: string) {
    return !country || COUNTRIES_USE_MILES.indexOf(country) > -1;
  }
}
