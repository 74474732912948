/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { useState } from 'react';
import axios from 'util/paxios';
import { API_PATH } from 'common/AppConstants';
import endpoints from 'common/endpoints';
import { OnboardingActions, useOnboardingDispatch } from 'components/onboarding/OnboardingContext';
import { CompanyEvergreenModel } from '../models';

export enum SupportRequestTypeEnum {
  ONBOARDING = 'ONBOARDING',
  INTEGRATION_SUPPORT = 'INTEGRATION_SUPPORT',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  QUALITY_CHECK = 'QUALITY_CHECK',
  DEVICE_DRIVERS = 'DEVICE_DRIVERS',
  MISSED_CALLS = 'MISSED_CALLS',
}

export interface SupportRequestDTO {
  dataSource?: string;
  attachmentBase64?: string;
  attachmentFilename?: string;
  carrierId?: string;
  companyContactEmail: string;
  companyContactPerson: string;
  companyContactPhone?: string;
  companyName: string;
  companyUid: string;
  countryCode?: string;
  dataSharingStatus?: string;
  description: string;
  existingCarrier?: true;
  fallbackMessage?: string;
  sendAsEmailOnApiFailure?: true;
  subject: string;
  tags?: string[];
  type?: SupportRequestTypeEnum;
  vatNumber?: string;
  dotNumber?: string;
  selectedLanguage?: string;
  telematicsVendorName?: string;
  telematicsContactEmail?: string;
  hasSubcontractors?: boolean;
  scac?: string;
  mcNumber?: string;
  fields?: { [field: string]: string };
  customFields?: { [field: string]: string };
  companyEvergreenData?: CompanyEvergreenModel;
}

function useSupportTicket(invitationToken?: string) {
  const [data, setData] = useState<any | undefined>(undefined);
  const dispatch = useOnboardingDispatch();
  async function makeRequest(body: SupportRequestDTO) {
    try {
      const requestHostname = invitationToken
        ? endpoints.freshdesk.publicCreateTicket
        : endpoints.freshdesk.createTicket;
      const requestCredentials = invitationToken
        ? {
            headers: {
              'X-Invite-Token': invitationToken,
            },
          }
        : { withCredentials: true };

      await axios.post(`${API_PATH}${requestHostname}`, body, requestCredentials);

      // 201 Created ( response:  1 )
      setData({ status: true, body });
    } catch (error) {
      console.error(error);
      setData({ status: false, body: error });
      dispatch({ type: OnboardingActions.SET_ERRORS, payload: error });
    }
  }

  function createTicket(body: SupportRequestDTO) {
    makeRequest(body);
  }

  return {
    data,
    createTicket,
  };
}

export default useSupportTicket;
