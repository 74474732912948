import * as React from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import { lightAltGrey } from 'styles/colors';

const StyledPanel = styled(Collapse.Panel)`
  border-bottom: 1px solid ${lightAltGrey} !important;
  .ant-collapse-item-active {
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
  .ant-collapse-header {
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
  }
  .ant-collapse-arrow {
    height: 1em;
  }
  p {
    text-align: left;
  }
`;

const StyledArrow = styled('span')`
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.24s, -webkit-transform 0.24s;
  transform-origin: top center;
  &.is-open {
    transform: rotateX(180deg) translateY(-50%) !important;
  }
`;

interface CollapsableItemInput {
  panelTitle: string;
  dataKey: string;
  children: React.ReactNode;
  activeKey?: string | undefined;
}

const CollapsableItem = ({ panelTitle, dataKey, activeKey, ...props }: CollapsableItemInput) => {
  return (
    <Collapse
      expandIconPosition="right"
      ghost
      defaultActiveKey={activeKey}
      expandIcon={({ isActive }) => (
        <StyledArrow className={isActive ? 'is-open' : ''}>
          <DownOutlined />
        </StyledArrow>
      )}
    >
      <StyledPanel header={panelTitle} key={dataKey}>
        {props.children}
      </StyledPanel>
    </Collapse>
  );
};

export default CollapsableItem;
