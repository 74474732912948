import * as React from 'react';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';

import { SensorTrackingData } from 'models';
import { isTempWithinRange, getTempTooltipText } from '../utils/temperatureUtils';
import TempWithinRangeIcon from '../../common/assets/icons/temperature-indicator-within-range.svg';
import TempOutsideOfRangeIcon from '../../common/assets/icons/temperature-indicator-outside-of-range.svg';
import * as styles from './TemperatureMonitoring.module.scss';

interface TemperatureIndicatorIconProps {
  sensorTrackingData: SensorTrackingData;
}

const TemperatureIndicatorIcon: React.FC<TemperatureIndicatorIconProps> = ({ sensorTrackingData }) => {
  const intl = useIntl();
  const { currentValue, maxValue, minValue } = sensorTrackingData.sensor;

  const currentTempIsWithinRange = isTempWithinRange({
    currentTemp: currentValue,
    tempMax: maxValue,
    tempMin: minValue,
  });

  const tooltipText = getTempTooltipText(currentTempIsWithinRange);

  return (
    <Tooltip title={intl.formatMessage(tooltipText)}>
      <img
        className={styles.temperatureIcon}
        alt="temperature"
        src={currentTempIsWithinRange ? TempWithinRangeIcon : TempOutsideOfRangeIcon}
      />
    </Tooltip>
  );
};

export default TemperatureIndicatorIcon;
