import { put, takeLatest, select } from 'redux-saga/effects';
import get from 'lodash/get';
import { trackEvent } from 'common/eventTracker';
import types from './types';
import { buildShipmentSearchQueryAction } from '../../../shipmentListComponent/ducks/searchReducer';
import { locationListActions } from '../../../locationManagement/ducks';

export const getState = (state) => ({
  /*
     TODO: currently hits the original search reducer to allow functionality to continue on shipmments page.
        Once the new searchResultsReducer is implemented we have to set it to that one
     */
  query: state.searchReducer.previousSearchQuery,
  sortFields: state.sortResultsReducer.sortFields,
  locationSearchTerm: state.locationManagementReducer.locationListReducer.searchTerm,
});

export function* sortAsync({ sortType }) {
  const { query, sortFields, locationSearchTerm } = yield select(getState);
  if (sortType === types.SHIPMENT_SORT) {
    yield put(
      buildShipmentSearchQueryAction({
        authorities: null,
        searchText: query.search,
        filter: query.filter,
        modes: query.modes,
        sortBy: sortFields,
        resultStartIndex: 0,
      })
    );
  } else if (sortType === types.LOCATION_LIST_SORT) {
    trackEvent('LOCATIONS_LIST_SORT', {
      key: get(sortFields, '[0].fieldName'),
      direction: get(sortFields, '[0].direction'),
    });
    yield put(locationListActions.getLocations(null, { search: locationSearchTerm, sortBy: sortFields }));
  }
}

export function* watchSortAsync() {
  yield takeLatest(types.LIST_SORT, sortAsync);
}
