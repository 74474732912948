import get from 'lodash/get';
import size from 'lodash/size';
import last from 'lodash/last';
import head from 'lodash/head';
import replace from 'lodash/replace';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import maxBy from 'lodash/maxBy';
import orderBy from 'lodash/orderBy';

import StatusDescriptionUtil from 'components/shipment/common/utils/statusDescriptionUtil';
import DerivedStatusOcean from '../../../../../shipment/common/ocean/derivedStatusOcean';
import PercentCompleteUtil from '../../../../../shipment/common/utils/percentCompleteUtil';
import ColorUtil from '../../../../../shipment/common/utils/colorUtil';
import * as DateUtils from '../../../../../../common/dateUtils';

export const mapStatus = (shipment, statusModel) => {
  const derivedStatusCode = get(shipment, 'derivedStatusCodes[0].value', DerivedStatusOcean.UNKNOWN);

  const displayableDerivedStatus = DerivedStatusOcean.DISPLAYABLE_CODES[derivedStatusCode];

  const derivedTrackingInformation = determineTrackingMethod(derivedStatusCode);
  const activeHolds = getActiveHolds(shipment.activeHolds, shipment.allHoldUpdates);

  const progress = getProgress(shipment, derivedStatusCode);

  const vesselName = get(shipment, 'vesselInfo.name');
  const destStopStatus = getDestinationStopStatus(shipment);

  const utcEta = get(destStopStatus, 'utcEarliestEstimatedArrivalDateTime');
  const utcAta = get(destStopStatus, 'utcRecordedArrivalDateTime');
  const utcLastFreeDay = get(shipment, 'details.lastFreeDay');
  const utcDepartureTime = get(destStopStatus, 'utcRecordedDepartureDateTime');
  const utcCurrentTime = DateUtils.utcNowMoment();

  const deliveryStop = last(shipment.shipmentStops);
  const departureTimeZone = get(deliveryStop, 'location.address.locationCoordinatesDto.localTimeZoneIdentifier');

  // Last update was hidden in TRK-1421 until improvements are made to the calculation
  // const lastUpdateTimestamp = get(
  //   shipment,
  //   'latestStatusUpdate.utcRetrievalTimestamp',
  //   get(shipment, 'latestStatusUpdate.utcTimestamp', undefined)
  // );
  // const lastUpdateText = DateFormatUtil.mapFromShipmentStatusAndUpdateTimestampToLastUpdatedText(
  //   shipment.multiModalDerivedStatus,
  //   lastUpdateTimestamp
  // );

  const flag = { color: progress.markerColor };

  const departureTertiaryTextMessage = StatusDescriptionUtil.oceanDetailTertiaryDescription(
    derivedStatusCode,
    vesselName,
    utcEta,
    utcAta,
    utcLastFreeDay,
    utcDepartureTime,
    utcCurrentTime,
    departureTimeZone
  );

  const stops = getStops(shipment, { delivery: departureTertiaryTextMessage }, get(progress, 'markerCompletion'));

  return {
    ...statusModel,
    transitStatus: displayableDerivedStatus,
    trackingMethod: derivedTrackingInformation.trackingMethod,
    trackingInfo: derivedTrackingInformation.trackingInformation,
    holds: activeHolds,
    demurrageRisk: get(shipment, 'demurrageEligible'),
    stops: stops,
    progress: progress,
    flag: flag,
    // lastUpdated: lastUpdateText, Last update was hidden in TRK-1421 until improvements are made to the calculation
    arrivalForecast: StatusDescriptionUtil.getStatusDescriptionOcean(
      derivedStatusCode,
      vesselName,
      utcEta,
      utcAta,
      utcLastFreeDay,
      utcDepartureTime,
      utcCurrentTime,
      departureTimeZone
    ),
  };
};

const getActiveHolds = (activeHoldTypes, holds) => {
  const arr = [];
  forEach(activeHoldTypes, (activeHold) => {
    const tempHolds = filter(holds, (update) => update.type === activeHold);
    const latestUpdate = maxBy(tempHolds, 'lastUpdateUtc') || { type: activeHold };
    arr.push(latestUpdate);
  });
  return orderBy(arr, ['lastUpdateUtc'], ['desc']).map(cloneUpdate);
};

const cloneUpdate = (update) => {
  const lastUpdateMoment = DateUtils.asUtcMoment(update.lastUpdateUtc);
  const lastUpdateFormatted = lastUpdateMoment ? undefined : undefined; //lastUpdateMoment.local().format('MM/DD/YYYY [at] hh:mm A z') : undefined;

  return {
    type: HoldType.DISPLAYABLE_TYPES[update.type],
    lastUpdateUtc: lastUpdateFormatted,
    detail: update.description,
  };
};

const getProgress = (shipment, derivedStatusCode) => {
  //remove the first 2 -- from the color.  Can't remove it in color util becuase it is also used on detail page.
  const markerColor = ColorUtil.getStatusColorFromDerivedStatusOcean(derivedStatusCode).substring(2);
  const markerCompletion = PercentCompleteUtil.getPercentComplete_FallbackForOcean(derivedStatusCode) * 100;

  return {
    displayMarker: true,
    markerColor,
    markerCompletion,
  };
};

const getDestinationStopStatus = (shipment) => {
  return last(shipment.latestStopStatuses);
};
const getStops = (shipment, tertiaryTextMap, markerCompletion) => {
  const pickupStop = getPickupStop(shipment);
  const deliveryStop = getDeliveryStop(shipment);

  return [
    buildStopInfo(pickupStop, 'Port of Loading', get(tertiaryTextMap, 'pickup'), markerCompletion >= 25, {
      left: '25%',
      transform: 'translate(-50%)',
    }),
    buildStopInfo(deliveryStop, 'Port of Discharge', get(tertiaryTextMap, 'delivery'), markerCompletion >= 75, {
      marginLeft: 'auto',
      right: '25%',
      transform: 'translate(50%)',
    }),
  ];
};

// in ocean if there is 1 stop we assume it's the delivery
const getPickupStop = (shipment) =>
  size(get(shipment, 'shipmentStops')) > 1 ? head(get(shipment, 'shipmentStops')) : null;
const getDeliveryStop = (shipment) => last(get(shipment, 'shipmentStops'));

const normalizeString = (value) => replace(value, 'null', '') || null;

const getStopLabel = (shipmentStop) =>
  get(shipmentStop, 'location.address.portName') ||
  get(shipmentStop, 'location.address.portCode') ||
  get(shipmentStop, 'stopName');

const getStopCityStateCountry = (stop) => normalizeString(get(stop, 'location.address.cityState'));

const buildStopInfo = (stop, defaultPortName, tertiaryText, isCompleted, cssStyle) => {
  return {
    label: defaultPortName,
    cityState: getStopCityStateCountry(stop) || getStopLabel(stop),
    tertiaryText: tertiaryText,
    isCompleted,
    popover: false,
    isVisible: true,
    cssStyle,
  };
};
const determineTrackingMethod = (derivedStatus) => {
  switch (derivedStatus) {
    case DerivedStatusOcean.SCHEDULED:
      return {
        trackingMethod: 'Searching for satellite connection',
        trackingInformation: 'Tracking will begin upon retrieval of the manifest',
      };
    case DerivedStatusOcean.AVAILABLE:
    case DerivedStatusOcean.BERTHING:
    case DerivedStatusOcean.COMPLETED:
    case DerivedStatusOcean.DISCHARGED:
      return {
        trackingMethod: 'Tracking Method: Port Connection',
        trackingInformation: 'We have a live connection with the import terminal',
      };
    case DerivedStatusOcean.ARRIVING:
    case DerivedStatusOcean.ARRIVED_ON_SHIP:
    case DerivedStatusOcean.IN_TRANSIT:
      return {
        trackingMethod: 'Tracking Method: Satellite',
        trackingInformation: 'We have a live AIS connection',
      };
    case DerivedStatusOcean.OUT_OF_NETWORK:
      return {
        trackingMethod: 'Tracking Method: Satellite',
      };
    case DerivedStatusOcean.CAN_OVERBOARD:
      return {
        trackingInformation: 'Tracking is no longer available',
      };
    default:
      return {};
  }
};

class HoldType {
  static HOLD_CUSTOMS = 'HOLD_CUSTOMS';
  static HOLD_LINE = 'HOLD_LINE';
  static HOLD_OTHER = 'HOLD_OTHER';

  static DISPLAYABLE_TYPES = {
    [HoldType.HOLD_CUSTOMS]: 'Customs Hold',
    [HoldType.HOLD_LINE]: 'Line Hold',
    [HoldType.HOLD_OTHER]: 'Other Hold',
  };
}
