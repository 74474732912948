import get from 'lodash/get';
import types from './types';
import ShipmentDetailFactory from '../utils/shipmentDetailFactory';
import { addEditShipmentTypes, EditShipmentMapper } from '../../../addEditShipment/ducks/index';

/* State Shape
{
    tlShipmentDetailsReducer: Object
}
*/
export const initialState = () => ({
  nudgeDialogVisible: false,
  nudgeMessagePreview: undefined,
  nudgeDialogShowingFailureMessage: false,
  nudgeFailureMessage: undefined,
  nudgeData: {
    lastNudgedOn: undefined,
  },
  nudgeDialogActionText: '',
  deleteDialogOpen: false,
  deleteSnackbarOpen: false,
  tenantId: '',
  error: false,
});

const tlShipmentDetailsReducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.SHIPMENT_DETAIL_GET_SUCCESS: {
      return {
        ...state,
        shipmentDetails: action.payload.shipmentDetails,
        sensorHistory: action.payload.sensorHistory,
        hereMapsConfig: action.payload.hereMapsConfig,
        nudgeData: action.payload.nudgeData,
        compositeRawResponse: action.payload.rawResponseData,
        mapGeofenceConfig: get(action.payload, 'mapGeofenceConfig'),
        tenantId: action?.shipmentDetails?.tenantId,
        customerProperties: action.payload.customerProperties,
        error: false,
      };
    }
    case types.SHIPMENT_DETAIL_GET_FAILURE:
      return { ...state, error: true };

    case types.OPEN_SNACKBAR_FOR_DELETE:
      return {
        ...state,
        deleteSnackbarOpen: true,
      };

    case types.CLOSE_SNACKBAR_FOR_DELETE:
      return {
        ...state,
        deleteSnackbarOpen: false,
      };

    case types.DELETE_SHIPMENT:
      return { ...state, deleteDialogOpen: false };

    case types.TOGGLE_DELETE_DIALOG:
      return {
        ...state,
        deleteDialogOpen: action.isDeleteDialogOpen,
      };

    case types.RESET_SHIPMENT:
      return {
        ...state,
        error: false,
        shipmentDetails: undefined,
        mapGeofenceConfig: undefined,
      };

    case addEditShipmentTypes.SAVE_SHIPMENT_STOP_EDIT_SUCCESS: {
      // This is an optimistic update to the details page after receiving a successful acknowledgement that the shipment stop was edited
      // If we don't do this then the user will not see updated data on page and since shipment details data comes from an async source after edit submitted
      // it may be several seconds before the updates would be reflected from the details api call
      const currentRawResponse = get(state, 'compositeRawResponse', {});
      const newRawResponse = EditShipmentMapper.mapEditActionToDetailApiResponse(action, currentRawResponse);

      if (currentRawResponse !== newRawResponse) {
        return { ...state, shipmentDetails: ShipmentDetailFactory.newInstance(newRawResponse) };
      }

      return state;
    }
    case types.SET_SHIPMENT_DETAIL_MILES_REMAINING:
      return {
        ...state,
        shipmentDetails: {
          ...state.shipmentDetails,
          milesTraveled: action.milesTraveled,
          milesRemaining: action.milesRemaining,
        },
      };

    // Below: Nudge Driver Actions
    case types.GET_NUDGE_MESSAGE_PREVIEW_SUCCESS:
      return {
        ...state,
        nudgeDialogVisible: true,
        nudgeMessagePreview: action.nudgeMessagePreview,
        nudgeDialogShowingFailureMessage: false,
        nudgeFailureMessage: undefined,
        nudgeDialogActionText: 'Send It',
      };

    case types.GET_NUDGE_MESSAGE_PREVIEW_FAILURE:
    case types.SEND_NUDGE_FAILURE:
      return {
        ...state,
        nudgeDialogVisible: true,
        nudgeMessagePreview: undefined,
        nudgeDialogShowingFailureMessage: true,
        nudgeFailureMessage: action.nudgeFailureMessage,
        nudgeDialogActionText: 'OK',
      };

    case types.SEND_NUDGE_SUCCESS:
    case types.CLOSE_NUDGE_DIALOG:
      return {
        ...state,
        nudgeDialogVisible: false,
        nudgeMessagePreview: undefined,
        nudgeDialogShowingFailureMessage: false,
        nudgeFailureMessage: undefined,
        nudgeDialogActionText: 'Send It',
      };
    // Above: Nudge Driver Actions
    default:
      return state;
  }
};

// geoFence threshold comes from logged in user customer properties or for shipment share from the mapGeofenceConfig on the response
// prefer shipment share location if it exists since logged in user should respect share geofence attributes of shipment tenant
// in future these should be merged so we don't have to make the decision in the react container
export const geoFenceThresholdSelector = (state) => {
  return get(
    state,
    'tlShipmentDetailsReducer.mapGeofenceConfig.truckloadArrivalGeofenceSecondsThreshold',
    get(state, 'authReducer.principal.customerProperties.V3_TRUCKLOAD_ARRIVAL_GEOFENCE_SECONDS_THRESHOLD', 900)
  );
};

export default tlShipmentDetailsReducer;
