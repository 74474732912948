import qs from 'querystring';
import { AxiosError } from 'axios';
import { useState, useEffect } from 'react';
import paxios from 'util/paxios';

import { API_PATH } from 'common/AppConstants';
import endpoints, { replaceTokens } from 'common/endpoints';

const genAttrValues = (value: string, attrValues: string[]): any => {
  if (!value) return [];
  return attrValues.map((item) => {
    return { id: `${value}:${item}`, value: item };
  });
};

const useAttributeValues = (attributeId: string, attributeName: string, parameters: string): string[] => {
  const [defaultValues, setValues] = useState([]);

  useEffect(() => {
    const defaultParams = { size: 10, page: 1 };
    const params = { ...defaultParams, ...qs.parse(parameters) };

    if (attributeId) {
      paxios({
        method: 'GET',
        url: `${API_PATH}${replaceTokens(endpoints.TENANT_ATTRIBUTES_VALUES, { attributeId })}`,
        withCredentials: true,
        params,
      })
        .then(({ data = [] }) => {
          const attrValues = genAttrValues(attributeName, data.results);
          return setValues(attrValues);
        })
        .catch((error: AxiosError) => {
          console.error(error);
        });
    }
  }, [attributeId, attributeName, parameters]);

  return defaultValues;
};

export default useAttributeValues;
