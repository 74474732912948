import get from 'lodash/get';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import CapacityProviderIdentifier from '../../../../../shipment/common/enums/capacityProviderIdentifier';

/**
 * Map backend capacity provider model to frontend model
 * @param {*} carrierInfo p44.shipment.search.model.CapacityProviderTypeDto
 */
export const mapCarrierInfo = (carrierInfo) => {
  if (!isNil(carrierInfo)) {
    return {
      name: carrierInfo.name,
      type: carrierInfo.type, // 'CARRIER' or 'BROKER'
      scac: findCarrierIdentifier(carrierInfo, CapacityProviderIdentifier.SCAC),
      dot: findCarrierIdentifier(carrierInfo, CapacityProviderIdentifier.DOT_NUMBER),
      mc: findCarrierIdentifier(carrierInfo, CapacityProviderIdentifier.MC_NUMBER),
      eu: findCarrierIdentifier(carrierInfo, CapacityProviderIdentifier.P44_EU),
    };
  }
};

export const findCarrierIdentifier = (carrierInfo, type) => {
  const identifierType = find(carrierInfo.assignedCapacityProviderIdentifiersDto, {
    capacityProviderIdentifierDto: { type },
  });
  return get(identifierType, 'capacityProviderIdentifierDto.value');
};
