import { injectIntl, FormattedMessage } from 'react-intl';
import CheckList from '../../common/checkList/CheckList';

import filterMessages from '../modes/filterMessages.json';

const ArrivalForecastFilter = (props) => {
  const messages = props.isShipmentListFiltersHorizontalEnabled
    ? { runningEarly: filterMessages.early, onTime: filterMessages.onTime, runningLate: filterMessages.late }
    : {
        runningEarly: filterMessages.runningEarly,
        onTime: filterMessages.onTime,
        runningLate: filterMessages.runningLate,
      };

  const arrivalForecastFilters = [
    {
      id: filterMessages.runningEarly.id,
      dataKey: filterMessages.runningEarly.defaultMessage,
      labelText: <FormattedMessage {...messages.runningEarly} />,
      propertyName: 'runningEarly',
      checked: false,
    },
    {
      id: filterMessages.onTime.id,
      dataKey: filterMessages.onTime.defaultMessage,
      labelText: <FormattedMessage {...messages.onTime} />,
      propertyName: 'onTime',
      checked: false,
    },
    {
      id: filterMessages.runningLate.id,
      dataKey: filterMessages.runningLate.defaultMessage,
      labelText: <FormattedMessage {...messages.runningLate} />,
      propertyName: 'runningLate',
      checked: false,
    },
  ];

  const checkListData = arrivalForecastFilters.map((forecastFilter) => ({
    ...forecastFilter,
    checked: props.filter.arrivalForecast.includes(forecastFilter.dataKey),
  }));

  // TODO: Refactor checkbox reducer methods to not use `labelText` as a data-key
  const onCheck = (checkboxItem) =>
    props.handleCheckboxGroupUpdate({ ...checkboxItem, labelText: checkboxItem.dataKey }, 'arrivalForecast');

  return (
    <CheckList
      mode={props.checkboxTheme}
      listTitle={props.intl.formatMessage(filterMessages.arrivalForecast)}
      checkListData={checkListData}
      checkFn={onCheck}
      data-locator="arrival-forecast-filter"
      checkboxGroupFlow={props.layoutFlow}
    />
  );
};

export default injectIntl(ArrivalForecastFilter);
