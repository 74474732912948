import moment from 'moment';
import { PresetDate } from './types';

export const PRESET_DATES: Record<PresetDate, { startDate: string; endDate: string }> = {
  YESTERDAY: {
    startDate: moment().subtract(1, 'day').startOf('day').toISOString(),
    endDate: moment().subtract(1, 'day').endOf('day').toISOString(),
  },
  LAST_WEEK: {
    startDate: moment().subtract(1, 'week').startOf('week').toISOString(),
    endDate: moment().subtract(1, 'week').endOf('week').toISOString(),
  },
  PAST_30_DAYS: {
    startDate: moment().subtract(31, 'days').startOf('day').toISOString(),
    endDate: moment().subtract(1, 'day').endOf('day').toISOString(),
  },
  TODAY: {
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
  },
  THIS_WEEK: {
    startDate: moment().startOf('week').toISOString(),
    endDate: moment().endOf('week').toISOString(),
  },
  THIS_MONTH: {
    startDate: moment().startOf('month').toISOString(),
    endDate: moment().endOf('month').toISOString(),
  },
  TOMORROW: {
    startDate: moment().add(1, 'day').startOf('day').toISOString(),
    endDate: moment().add(1, 'day').endOf('day').toISOString(),
  },
  NEXT_WEEK: {
    startDate: moment().add(1, 'week').startOf('week').toISOString(),
    endDate: moment().add(1, 'week').endOf('week').toISOString(),
  },
  NEXT_30_DAYS: {
    startDate: moment().add(1, 'day').startOf('day').toISOString(),
    endDate: moment().add(31, 'days').endOf('day').toISOString(),
  },
};
