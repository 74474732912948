import './TLDetailsComponent.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import get from 'lodash/get';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import { useState, useEffect, useContext } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import styled from 'styled-components';
import { Tabs, Card, Button } from 'ui-components';

import { temperatureMonitoringV2, tlEmissionsEnabled } from 'common/authorizations';
import ShipmentHeaderContainer from 'components/newShipmentDetails/ShipmentHeader/ShipmentHeaderContainer';
import RouteTimelineComponent from 'components/newShipmentDetails/RouteTimeline/RouteTimelineComponent';
import { mapShipmentTL } from 'components/newShipmentDetails/mappers/mapShipmentTL';
import { calculateTruckloadCO2Emissions } from 'components/newShipmentDetails/utils';
import TemperatureMonitoring from 'components/newShipmentDetails/TemperatureMonitoring/TemperatureMonitoring';
import LineItemsComponent from 'components/lineItems/LineItemsComponent';
import OrdersTable from 'components/ordersList/OrdersTable';
import InventoryItemsTable from 'components/Inventory/InventoryItemsTable';
import StatusReason from 'components/shipment/common/enums/statusReason';
import { TruckloadStatusCodeEnum, ShipmentModeEnum } from 'models';
import ErrorBoundary from '../../common/errorBoundary/ErrorBoundary';
import CarrierDetail from '../common/CarrierDetail/CarrierDetail';
import { useDocuments } from '../common/hooks/useDocuments';
import PageNotFound from '../../common/PageNotFound/PageNotFound';
import Document from '../common/Document/Document';
import MapDetail from './MapDetail/MapDetail';
import ResultDetail from './ResultDetail/ResultDetail';
import DeleteDialogContent from './shipmentModals/DeleteDialogContent';
import TemperatureTracking from './TempTracking/TemperatureTracking';
import { PrincipalContext } from '../../../contexts/PrincipalContext';

import DocumentsComponent from '../common/Document/DocumentsComponent';
import BaiduMapDetail from './MapDetail/BaiduMapDetail';

const StyledCarrierDetail = styled(CarrierDetail)`
  ${(props) =>
    props.authorizations.hasTenantCarrierRole(props.principal) &&
    props.shipmentDetails?.entitlementInfo?.grantor &&
    `max-width: 100% !important;`}
`;
const renderCarrierDetail = (props) => {
  if (props.isLoggedIn) {
    // trackingMethods could be null and lodash get won't return default in that case
    const activeTrackingMethod = (get(props, 'shipmentDetails.trackingMethods') || []).find(
      (trackingMethod) => trackingMethod.active
    );
    return (
      <StyledCarrierDetail
        className={classNames(
          {
            'col-md-8':
              !props.authorizations.hasTenantCarrierRole(props.principal) ||
              !props.shipmentDetails?.entitlementInfo?.grantor,
          },
          'flex-fill'
        )}
        carrierDetail={get(props, 'shipmentDetails.carrierInfo')}
        equipmentIdentifiers={get(props, 'shipmentDetails.equipmentIdentifiers')}
        scheduledEquipmentIdentifiers={get(props, 'shipmentDetails.equipmentIdentifierChanges')}
        mode="TL"
        shipmentDetails={props.shipmentDetails}
        shipmentId={`${get(props, 'shipmentDetails.shipmentId')}`}
        activeTrackingMethod={activeTrackingMethod}
        authorizations={props.authorizations}
      />
    );
  }
};

const renderTemperatureTracking = (props) => {
  const sensorDto = get(props, 'sensorHistory.sensorHistories[0].sensorDto');
  const temperatureReadings = get(props, 'sensorHistory.sensorHistories[0].readings');

  if (
    props &&
    props.sensorHistory &&
    props.shipmentDetails &&
    sensorDto &&
    temperatureReadings &&
    props.shipmentDetails.currentStop &&
    props.shipmentDetails.currentStop.statusReasonCode
  ) {
    return temperatureMonitoringV2() ? (
      <ErrorBoundary message="An error occurred rendering temperature monitoring">
        <TemperatureMonitoring sensor={sensorDto} readings={temperatureReadings} />
      </ErrorBoundary>
    ) : (
      <div>
        <div className="row">
          <h2 className="col-24 route-details-title justify-content-center justify-content-sm-start d-flex">
            Temperature Tracking
          </h2>
        </div>

        <section className="shipment-details-content row">
          <div className="col-24">
            <TemperatureTracking
              sensor={sensorDto}
              readings={temperatureReadings}
              statusReasonCode={props.shipmentDetails.currentStop.statusReasonCode}
            />
          </div>
        </section>
      </div>
    );
  } else {
    return null;
  }
};

const TLDetailsComponent = (props) => {
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [fetchedMilesData, setFetchedMilesData] = useState(false);
  const { setTenantId } = useContext(PrincipalContext);

  const [documents] = useDocuments(
    props.match.params.shipmentDetailId,
    props.shipmentDetails?.masterShipmentId,
    ShipmentModeEnum.TL,
    props.match.params.shipmentShareToken
  );

  const shouldHideStatus = props.isLoggedIn
    ? props.authorizations.hasShipmentStatusBadgeHidden()
    : get(props, 'customerProperties.TL_HIDE_SHIPMENT_STATUS_BADGE', false);

  useEffect(() => {
    if (props.shipmentDetails && props.shipmentDetails.tenantId) {
      setTenantId(props.shipmentDetails.tenantId);
    }
  }, [props.shipmentDetails, setTenantId]);

  const latestStatusUpdateTimezone = {
    localTimeZoneIdentifier:
      last(props.shipmentDetails?.shipmentStops)?.location.address.locationCoordinatesDto?.localTimeZoneIdentifier ||
      '',
    localTimeZoneIdentifierShort:
      last(props.shipmentDetails?.shipmentStops)?.location.address.locationCoordinatesDto
        ?.localTimeZoneIdentifierShort || '',
  };

  const isROTWCountry = !props.shipmentDetails?.shipmentStops?.some((i) => i.location.address.countryCodeDto === 'CN');

  useEffect(() => {
    if (props.shipmentDetails && !fetchedMilesData) {
      props.getMilesData(props.shipmentDetails);
      setFetchedMilesData(true);
    }
  }, [props, fetchedMilesData]);

  if (props.shipmentDetailsMissing) {
    return <PageNotFound />;
  }
  if (props.shipmentDetails) {
    const mappedShipmentTL = mapShipmentTL(props.shipmentDetails);
    let isETAoutdated;
    if (mappedShipmentTL?.legs[0].events) {
      isETAoutdated =
        last(mappedShipmentTL?.legs[0].events)?.description === StatusReason.ETA_OUT_OF_DATE ||
        last(mappedShipmentTL?.legs[0].events)?.type === TruckloadStatusCodeEnum.ETA_ERROR;
    }
    return (
      <div className="TLDetailsComponent animated fadeIn">
        <ShipmentHeaderContainer
          shimentTenantId={props.shipmentDetails.tenantId}
          identifiers={props.shipmentDetails.identifiers}
          shipmentId={`${props.shipmentDetails.shipmentId}`}
          masterShipmentId={`${props.shipmentDetails.masterShipmentId}`}
          authorizations={props.authorizations}
          isLoggedIn={props.isLoggedIn}
          mode={ShipmentModeEnum.TL}
          shipmentShareToken={props.match.params.shipmentShareToken}
          entitlementInfo={props.shipmentDetails.entitlementInfo}
          emissionsInMetricTons={calculateTruckloadCO2Emissions(get(props, 'shipmentDetails.milesTraveled'))}
          showEmissions={tlEmissionsEnabled()}
          tenantCustomAttributes={props.shipmentDetails.tenantCustomAttributes}
        />

        <section className="shipment-details section-content container">
          <ErrorBoundary message={'An error occurred rendering the shipment details'}>
            <div className="shipment-details-content row">
              <ResultDetail
                shipmentId={props.match.params.shipmentDetailId}
                shipmentDetail={props.shipmentDetails}
                /* re-do after app and app-less ui is determined Jira: https://project44.atlassian.net/browse/TRK-168
              nudgeData={props.nudgeData} */
                nudgeData={false}
                latestStatusUpdateTimezone={latestStatusUpdateTimezone}
                openNudgeDialog={props.openNudgeDialog}
                nudgeDialogVisible={props.nudgeDialogVisible}
                nudgeMessagePreview={props.nudgeMessagePreview}
                nudgeDialogActionText={props.nudgeDialogActionText}
                sendNudge={props.sendNudge}
                nudgeDialogShowingFailureMessage={props.nudgeDialogShowingFailureMessage}
                nudgeFailureMessage={props.nudgeFailureMessage}
                closeNudgeDialog={props.closeNudgeDialog}
                isLoggedIn={props.isLoggedIn}
                etaOutOfDate={isETAoutdated}
                shouldHideStatus={shouldHideStatus}
              />
              {props.authorizations.hasTenantCarrierRole(props.principal) &&
              props.shipmentDetails?.entitlementInfo?.grantor ? (
                <Col className="d-flex flex-column">
                  <Card classNames="flex-fill">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <span className="shipmentAttributeLabel" style={{ fontSize: '1.6rem' }}>
                        <FormattedMessage id="shipmentDetails.customerLabel" defaultMessage="Customer" />
                      </span>
                      <span className="shipmentAttributeValue">
                        {props.shipmentDetails?.entitlementInfo?.grantor?.name}
                      </span>
                    </div>
                  </Card>
                  {renderCarrierDetail(props)}
                </Col>
              ) : (
                renderCarrierDetail(props)
              )}

              <DeleteDialogContent
                shipmentId={props.match.params.shipmentDetailId}
                open={props.deleteDialogOpen}
                deleteShipment={props.deleteShipment}
                toggleDeleteDialog={props.toggleDeleteDialog}
              />
            </div>
          </ErrorBoundary>

          {renderTemperatureTracking(props)}

          <ErrorBoundary message={'An error occurred rendering the route timeline'}>
            <RouteTimelineComponent
              {...mappedShipmentTL}
              entitlementInfo={props.shipmentDetails.entitlementInfo}
              mapInstance={props.mapInstance}
              authorizations={props.authorizations}
              shipmentShareToken={props.match.params.shipmentShareToken}
              shouldHideStatus={shouldHideStatus}
            />
          </ErrorBoundary>

          <section className="interactive-map row">
            <ErrorBoundary message={'An error occurred rendering the map'}>
              <div className="map-details-content col-24">
                {isROTWCountry ? (
                  <MapDetail
                    shipmentDetails={props.shipmentDetails}
                    sharedShipmentMapConfig={props.sharedShipmentMapConfig}
                    hereMapsConfig={props.hereMapsConfig}
                    aerisWeatherConfig={props.aerisWeatherConfig}
                    authorizations={props.authorizations}
                    match={props.match}
                    setMapInstance={props.setMapInstance}
                    mapInstance={props.mapInstance}
                    geoFenceThreshold={props.geoFenceThreshold}
                  />
                ) : (
                  <BaiduMapDetail shipmentDetails={props.shipmentDetails} />
                )}
              </div>
            </ErrorBoundary>
          </section>
          {props.authorizations.hasAdvancedTrackingLinking('TL') ? (
            <Row className="mt-5">
              <Col xs={24}>
                <Tabs
                  type="card"
                  defaultActiveKey="orders"
                  panes={[
                    {
                      key: 'orders',
                      tab: (
                        <FormattedMessage defaultMessage="Orders" id="shipmentDetails.inventoryOrders.tabs.orders" />
                      ),
                    },
                    {
                      key: 'inventory',
                      tab: (
                        <FormattedMessage
                          defaultMessage="Inventory"
                          id="shipmentDetails.inventoryOrders.tabs.inventory"
                        />
                      ),
                    },
                  ]}
                  tabcontent={{
                    orders: <OrdersTable masterShipmentId={props.shipmentDetails.masterShipmentId} />,
                    inventory: <InventoryItemsTable masterShipmentId={props.shipmentDetails.masterShipmentId} />,
                  }}
                />
              </Col>
            </Row>
          ) : (
            !isEmpty(get(props, ['shipmentDetails', 'details', 'lineItems'])) &&
            props.authorizations.hasObsoleteLineItems() && (
              <Row>
                <Col xs={24}>
                  <LineItemsComponent lineItems={get(props, ['shipmentDetails', 'details', 'lineItems'], [])} />
                </Col>
              </Row>
            )
          )}

          {/* Shipment Delete Button Section */}
        </section>

        {/* Document Section */}
        <section id="documents" className="documents-details section-content container">
          {props.authorizations.hasDocumentUploadEnabled() && (
            <DocumentsComponent
              shipmentShareToken={props.match.params.shipmentShareToken}
              tenantId={props.shipmentDetails.tenantId}
              shipmentDetails={props.shipmentDetails}
              principal={props.principal}
              mode={ShipmentModeEnum.TL}
              legacyDocuments={documents}
            />
          )}
          {!props.authorizations.hasDocumentUploadEnabled() && (
            <>
              <h2>
                <FormattedMessage defaultMessage="Documents" id="shipmentDetails.stickyNav.documents" />
              </h2>
              <Row type="flex" className="documents-details-content pl-4">
                {!documents || !documents.length ? (
                  <h5 className="no-documents-message">
                    {' '}
                    <FormattedMessage
                      defaultMessage="There are no documents associated with this record."
                      id="shipmentDetails.noDocumentsMessage"
                    />{' '}
                  </h5>
                ) : (
                  documents.map((doc) => {
                    return <Document documentObj={doc} key={doc.id} />;
                  })
                )}
              </Row>
            </>
          )}
        </section>
        <section id="delete_shipment" className="documents-details section-content container">
          {props.showDeleteShipmentButton && (
            <div>
              <Button
                className={`custom-button dangerous medium full-width DeleteShipmentButton mt-4`}
                block
                disabled={deleteButtonDisabled}
                clickFn={() => {
                  setDeleteButtonDisabled(true);
                  props.toggleDeleteDialog(true);
                }}
              >
                <FormattedMessage id="shipmentDetails.deleteShipment" defaultMessage="Delete Shipment" />
              </Button>
            </div>
          )}
        </section>
      </div>
    );
  } else {
    return null;
  }
};

export default injectIntl(TLDetailsComponent);
