import { useCallback, useState } from 'react';
import phone from 'phone';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { IntlShape } from 'react-intl';
import { trackEvent } from 'common/eventTracker';
import { API_PATH } from 'common/AppConstants';
import { EquipmentIdentifierTypeEnum } from 'components/newShipmentDetails/models/EquipmentIdentifier';
import axios from 'util/paxios';

interface UseSaveEquipmentIdentifierProps {
  shipmentId: string;
  intl: IntlShape;
}

export const useSaveEquipmentIdentifier = ({ shipmentId, intl }: UseSaveEquipmentIdentifierProps) => {
  const [isSavingEquipId, setIsSavingEquipId] = useState<boolean>(false);
  const [equipmentIdentifiersError, setEquipmentIdentifiersError] = useState<string[]>([]);

  const saveEquipmentIdentifier = useCallback(
    async ({
      selectedEquipIdType,
      selectedEquipIdValue,
      shipmentIdentifiers,
      capacityProviderId,
    }: {
      selectedEquipIdType: EquipmentIdentifierTypeEnum;
      selectedEquipIdValue: string;
      shipmentIdentifiers: any;
      capacityProviderId: string | undefined;
    }) => {
      let value: string = selectedEquipIdValue;
      if (selectedEquipIdType === EquipmentIdentifierTypeEnum.MOBILE_PHONE_NUMBER) {
        const phoneValue = phone(selectedEquipIdValue).phoneNumber;
        if (!phoneValue) {
          setEquipmentIdentifiersError([
            intl.formatMessage({
              id: 'shipmentDetails.equipmentIdentifiers.phoneError',
              defaultMessage: 'Invalid phone number.',
            }),
          ]);
          throw intl.formatMessage({
            id: 'shipmentDetails.equipmentIdentifiers.phoneError',
            defaultMessage: 'Invalid phone number.',
          });
        } else {
          value = phoneValue;
        }
      }
      setIsSavingEquipId(true);
      const url = `${API_PATH}/tl-shipment/${shipmentId}/equipmentIdentifierAssignment?shipmentIdentifiers=${encodeURIComponent(
        JSON.stringify(shipmentIdentifiers)
      )}&capacityProviderId=${encodeURIComponent(`${capacityProviderId}`)}`;
      try {
        const response = await axios.post(
          url,
          {
            equipmentIdentifierType: selectedEquipIdType,
            equipmentIdentifierValue: value,
            shipmentId,
          },
          {
            withCredentials: true,
            headers: {
              'Content-type': 'application/json',
            },
          }
        );
        trackEvent('ADDED_EQUIPMENT_IDENTIFIER');
        setIsSavingEquipId(false);
        Promise.resolve(response);
      } catch (error: unknown) {
        setIsSavingEquipId(false);
        let errorResponses: string[] = [];
        if (!isNil(get(error, 'response.data.errors'))) {
          errorResponses = (error as any).response.data.errors.map(
            (errorResponse: { message: string }) => errorResponse.message
          );
        }
        const defaultErrorMessage: string = intl.formatMessage({
          id: 'shipmentDetails.equipmentIdentifiers.errorSaving',
          defaultMessage: 'Error adding equipment identifier.',
        });
        setEquipmentIdentifiersError([defaultErrorMessage, ...errorResponses]);
        throw error;
      }
    },
    [intl, shipmentId]
  );

  return {
    saveEquipmentIdentifier,
    equipmentIdentifiersError,
    isSavingEquipId,
  };
};
