import './TemperatureTracking.scss';
import NavigationExpandMore from 'material-ui/svg-icons/navigation/expand-more';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { TEMPERATURE_STATUS, SCHEDULE_TYPES } from '../../../shipment/common/enums/temperatureStatus';
import { getTemperatureStatus } from '../../../../common/temperatureUtils';
import underMin from '../../../common/assets/snowflake.svg';
import overMax from '../../../common/assets/fire.svg';
import normal from '../../../common/assets/thumb-up.svg';
import LineChart from '../../../common/chart/lineChart/LineChart';

const FAHRENHEIT = 'FAHRENHEIT';
const FAHRENHEIT_ABBV = 'F';

const getSummaryStrip = (current, lowerBound, upperBound, _scale, message) => {
  const scale = _scale === FAHRENHEIT ? FAHRENHEIT_ABBV : _scale;
  let temperatureStatus = getTemperatureStatus(current, lowerBound, upperBound);
  return (
    <div className="tracking-info-text col-24 row d-flex">
      <div className="align-items-center col-md-2 d-flex justify-content-center">
        {getTemperatureIcon(temperatureStatus, current, lowerBound, upperBound)}
      </div>
      <div className="temperature-data d-flex flex-column">
        <span className={`d-flex  justify-content-center current-temp ${getTemperatureClass(temperatureStatus)}`}>
          {current}&deg;{scale}
        </span>
        <span className="d-flex justify-content-center temperature-label">
          <FormattedMessage id="shipmentDetails.tempMonitoring.currentTemp" defaultMessage="CURRENT TEMP" />
        </span>
      </div>
      <div
        className={`col-md-16 d-flex align-items-center tracking-info-text ${getTemperatureClass(temperatureStatus)}`}
      >
        {message}
      </div>
      {lowerBound && (
        <div className="temperature-data d-flex flex-column">
          <span className="range-temp d-flex justify-content-center">
            {lowerBound}&deg;{scale}
          </span>
          <span className="temperature-label d-flex justify-content-center">
            <FormattedMessage id="shipmentDetails.tempMonitoring.lowBound" defaultMessage="LOW BOUND" />
          </span>
        </div>
      )}
      {upperBound && (
        <div className="temperature-data d-flex flex-column">
          <span className="d-flex justify-content-center range-temp">
            {upperBound}&deg;{scale}
          </span>
          <span className="d-flex justify-content-center temperature-label">
            <FormattedMessage id="shipmentDetails.tempMonitoring.highBound" defaultMessage="HIGH BOUND" />
          </span>
        </div>
      )}
    </div>
  );
};

const getInvalidSummaryStrip = (lowerBound, upperBound, _scale, statusReasonCode) => {
  const scale = _scale === FAHRENHEIT ? FAHRENHEIT_ABBV : _scale;
  return (
    <div className="tracking-info-text d-flex card-container">
      <div className="flex-grow-1 d-flex align-items-center tracking-info-text">
        {SCHEDULE_TYPES[statusReasonCode].details}
      </div>
      {lowerBound && (
        <div className="temperature-data d-flex flex-column">
          <span className="range-temp d-flex justify-content-center">
            {(lowerBound, upperBound)}&deg;{scale}
          </span>
          <span className="temperature-label d-flex justify-content-center">
            <FormattedMessage id="shipmentDetails.tempMonitoring.lowBound" defaultMessage="LOW BOUND" />
          </span>
        </div>
      )}
      {upperBound && (
        <div className="temperature-data d-flex flex-column">
          <span className="d-flex justify-content-center range-temp">
            {upperBound}&deg;{scale}
          </span>
          <span className="d-flex justify-content-center temperature-label">
            <FormattedMessage id="shipmentDetails.tempMonitoring.highBound" defaultMessage="HIGH BOUND" />
          </span>
        </div>
      )}
    </div>
  );
};

const buildLineChartDataSource = (sensor, readings) => {
  return {
    data: formatChartData(sensor, readings),
    chartOptions: getChartOptions(sensor, findMaxTemperature(sensor, readings)),
  };
};

const formatChartData = (sensor, readings) => {
  // Axis - This is a fake data to generate a second axis on chart. All the chart data aligns to
  // axis number 1 while axis 0 is available and nothing is assinged so that the legends can show up
  // on the left side of chart.

  // Tooltip specific column,

  // Last actual column is copy of actual column. The only reason it is here is due to the fact that
  // Last one has a different point color. We can't change point color and make it different from line
  // color. This is a way around to do it.
  let upperBound = sensor.max;
  let lowerBound = sensor.min;
  let target = sensor.target;
  let customChartData = [];

  //Build column data
  customChartData.push([
    'Timeline',
    'Axis', //For fake 2nd Axis
    // {'type': 'string', 'role': 'tooltip'}, //For custom tooltip
    { type: 'string', role: 'tooltip', p: { html: true } }, //For custom tooltip
    'Upper Bound',
    'Target Temp',
    'Lower Bound',
    'Actual',
    'Actual', //Keeping same name as actual as tooltip will show. This is just to change pointer colors
  ]);

  readings.forEach((item) => {
    if (customChartData.length === 1) {
      customChartData.push([
        item.utcTimestamp, //Need to apply format that needs to be shown on x Axis in dates
        0, //2nd axis first value is zero rest is all null;
        createCustomToolTip(item.value, item.unitOfMeasurement, item.utcTimestamp),
        upperBound,
        target,
        lowerBound,
        item.value,
        item.value,
      ]);
    } else {
      customChartData.push([
        item.utcTimestamp, //Need to apply format that needs to be shown on x Axis in dates
        null, //2nd axis first value is zero rest is all null;
        createCustomToolTip(item.value, item.unitOfMeasurement, item.utcTimestamp),
        upperBound,
        target,
        lowerBound,
        item.value,
        item.value,
      ]);
    }
  });

  return customChartData;
};

const createCustomToolTip = (actual, scale, dateTime) => {
  return (
    '<div style="padding:5px 5px 5px 5px; text-align: left; background-color:#575451; height: 56px!important; width: 120px!important; color:white">' +
    actual +
    '&deg;' +
    scale +
    '<br/>' +
    dateTime +
    '<br/>' +
    '</div>'
  );
};

const findMaxTemperature = (sensor, readings) => {
  let maxValue = sensor.max;
  readings.forEach((item) => {
    if (item.value > maxValue) {
      maxValue = item.value;
    }
  });

  return maxValue;
};

//TODO: Note: Using Roboto font is throwing exception in console: Uncaught Error: Not enough columns given to draw the requested chart. - Bhawesh
const getChartOptions = (sensor, maxTemperature) => {
  return {
    hAxis: {
      maxValue: 9,
      gridlines: {
        color: '#ffffff',
      },
      textStyle: {
        color: '#2d2926',
        fontName: 'Roboto',
        fontSize: 10,
      },
      baselineColor: '#ffffff',
    },
    vAxis: {
      0: { textPosition: 'none' }, //Dual axis created for showing legends on left of chart
      1: {},
      textStyle: { color: '#2d2926', fontName: 'Roboto', fontSize: 24 },
      maxValue: maxTemperature,
      gridlines: { color: '#ffffff' },
      ticks: [
        //Three ticks added for showing upper, target and lower bound,
        { v: sensor.max, f: getYAxisTick(sensor.max, FAHRENHEIT_ABBV) },
        { v: sensor.target, f: getYAxisTick(sensor.target, FAHRENHEIT_ABBV) },
        { v: sensor.min, f: getYAxisTick(sensor.min, FAHRENHEIT_ABBV) },
      ],
      baselineColor: '#ffffff',
    },
    focusTarget: 'category',
    tooltip: { isHtml: true },
    legend: {
      position: 'left',
      textStyle: {
        fontSize: 12,
        fontName: 'Roboto',
      },
      targetAxisIndex: 0,
    },
    lineWidth: 1,
    // curveType: 'function',
    series: {
      0: { targetAxisIndex: 1, visibleInLegend: false, pointSize: 0, lineWidth: 0 }, //This is a fake series for dual axis
      1: { targetAxisIndex: 1, lineDashStyle: [10, 5] },
      2: { targetAxisIndex: 1, lineDashStyle: [10, 5] },
      3: { targetAxisIndex: 1, lineDashStyle: [10, 5] },
      4: {
        targetAxisIndex: 1,
        lineDashStyle: [10, 0],
        pointSize: 6,
        pointShape: { type: 'circle', fillColor: '#2d2926' },
        visibleInLegend: false,
      },
      5: {
        //This is fake series to color shape points as per the zeplin graph.
        targetAxisIndex: 1,
        color: '#2d2926',
        lineWidth: 0,
        pointSize: 6,
        visibleInLegend: false,
      },
    },
    colors: ['#ffffff', '#f04e37', '#2d2926', '#5888b0', '#979797'],
  };
};

const getYAxisTick = (tickValue, scale) => {
  return `${tickValue}° ${scale}`;
};

const getDetailsChart = (chartEvents, sensor, readings) => {
  // if (scale && chartData) {
  return <LineChart dataSource={buildLineChartDataSource(sensor, readings)} height="188px" chartEvents={chartEvents} />;
  // } else {
  //     return null;
  // }
};

const getTemperatureIcon = (temperatureStatus, current, lowerBound, upperBound) => {
  switch (temperatureStatus) {
    case TEMPERATURE_STATUS.UNDER_MIN:
      return buildTemperatureIndicator(underMin, 'under-min');
    case TEMPERATURE_STATUS.OVER_MAX:
      return buildTemperatureIndicator(overMax, 'over-max');
    case TEMPERATURE_STATUS.NORMAL:
      return buildTemperatureIndicator(normal, 'normal');
    case TEMPERATURE_STATUS.UNKNOWN:
      return null; //not sure if we show any message here.
    default:
      return null;
  }
};

const getTemperatureClass = (temperatureStatus) => {
  switch (temperatureStatus) {
    case TEMPERATURE_STATUS.UNDER_MIN:
      return 'under-min';
    case TEMPERATURE_STATUS.OVER_MAX:
      return 'over-max';
    case TEMPERATURE_STATUS.NORMAL:
      return 'normal';
    case TEMPERATURE_STATUS.UNKNOWN:
      return 'unknown';
    default:
      return '';
  }
};

const buildTemperatureIndicator = (src, alt) => {
  return <img src={src} className="icon" alt={alt} />;
};

/**
 * Using Chart events we can now customize most of the things.
 * @returns {*[]}
 */
const chartEvents = (dataSource) => {
  return [
    {
      eventName: 'ready',
      callback(Chart) {
        // Returns Chart so you can access props and  the ChartWrapper object from chart.wrapper
        let labels = Chart.chart.getContainer().getElementsByTagName('text');
        if (
          dataSource &&
          dataSource.chartOptions &&
          dataSource.chartOptions.vAxis &&
          dataSource.chartOptions.vAxis.ticks
        ) {
          let sortedTicks = dataSource.chartOptions.vAxis.ticks.sort((a, b) => b.v - a.v);

          for (let i = 0; i < labels.length; i++) {
            if (sortedTicks.length === 3) {
              if (sortedTicks[0].f === labels[i].textContent) {
                //Upper bound
                // labels[i].style.fill = '#f04e37 !important';
                // labels[i].style.fillColor ="#f04e37";
                // labels[i].style.fill.value ="#f04e37";
                labels[i].setAttribute('fill', '#f04e37 !important');
              }
              if (sortedTicks[1].f === labels[i].textContent) {
                //Target Temp
                // labels[i].setAttribute('fill', '#f04e37 !important');
                labels[i].style.fillColor = '#f04e37';
              }
              if (sortedTicks[2].f === labels[i].textContent) {
                //Lower Bound
                // labels[i].setAttribute('fill', '#ff0000 !important');
                labels[i].style.fillColor = '#f04e37';
              }
            }
          }
        }
      },
    },
  ];
};

const generateMessage = (sensor, readings) => {
  const mostRecentReading = readings[readings.length - 1];
  let temperatureStatus = getTemperatureStatus(mostRecentReading.value, sensor.min, sensor.max);

  let minutes;
  const mostRecentTime = new Date(mostRecentReading.utcTimestamp);
  for (let i = readings.length - 1; i >= 0; --i) {
    if (temperatureStatus !== getTemperatureStatus(readings[i].value, sensor.min, sensor.max)) {
      const prevTime = new Date(readings[i].utcTimestamp);
      const diff = mostRecentTime - prevTime;

      minutes = diff / 1000 / 60;
      break;
    }
  }

  // If the temperature status hasn't changed for the duration of the shipment,
  // get the duration of the entire shipment
  if (!minutes) {
    const firstReadingTime = new Date(readings[0].utcTimestamp);
    const diff = mostRecentTime - firstReadingTime;
    minutes = diff / 1000 / 60;
  }
  minutes = minutes.toFixed(2);

  const min = sensor.min;
  const max = sensor.max;
  let message = 'This shipment has been ';

  if (mostRecentReading.value > max) {
    message += `over maximum temperature for ${minutes} minutes`;
  } else if (mostRecentReading.value < min) {
    message += `under minimum temperature for ${minutes} minutes`;
  } else {
    message += `at normal temperature for ${minutes} minutes`;
  }

  return message;
};

const TemperatureTracking = ({ sensor, readings, statusReasonCode }) => {
  if (sensor && !isEmpty(readings)) {
    const mostRecentReading = readings[readings.length - 1];
    const message = generateMessage(sensor, readings);
    if (SCHEDULE_TYPES[statusReasonCode]) {
      return (
        <div>
          {getInvalidSummaryStrip(sensor.min, sensor.max, mostRecentReading.unitOfMeasurement, statusReasonCode)}
        </div>
      );
    } else {
      return (
        <MuiThemeProvider>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<NavigationExpandMore color="#575451" />}>
              {getSummaryStrip(
                mostRecentReading.value,
                sensor.min,
                sensor.max,
                mostRecentReading.unitOfMeasurement,
                message
              )}
            </ExpansionPanelSummary>
            <Divider />
            <ExpansionPanelDetails>{getDetailsChart(chartEvents, sensor, readings)}</ExpansionPanelDetails>
          </ExpansionPanel>
        </MuiThemeProvider>
      );
    }
  } else {
    return null;
  }
};

TemperatureTracking.propTypes = {
  temperature: PropTypes.shape({
    current: PropTypes.number,
    lowerBound: PropTypes.number,
    upperBound: PropTypes.number,
    message: PropTypes.string,
    scale: PropTypes.string,
  }),
  chartData: PropTypes.array,
};

export default TemperatureTracking;
