import { put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import axios from '../../../../util/paxios';
import { API_PATH } from '../../../../common/AppConstants';
import types from './types';
import { buildSetResultsAction, buildSearchResultsAction, buildSearchFailureAction } from './actions';

export const searchConfig = (state) => state.searchResultsReducer.searchConfig;

export function searchResults(action, searchPath) {
  return axios({
    method: 'POST',
    url: `${API_PATH}/${searchPath}/search`,
    data: action.searchQuery,
    withCredentials: true,
  });
}

export function* searchResultsAsync(action) {
  try {
    const response = yield call(searchResults, action, action.searchConfig.path);
    const results = response.data.results;
    const totalResults = response.data.totalResults;
    yield put(buildSetResultsAction(results, totalResults, action.searchQuery.search));
  } catch (e) {
    yield put(buildSearchFailureAction(e));
  }
}

export function* getNextPageAsync(action) {
  try {
    const response = yield call(searchResults, action);
    const results = response.data.results;
    yield put(buildSearchResultsAction(results));
  } catch (e) {
    yield put(buildSearchFailureAction(e));
  }
}

export function* watchSearchAsync() {
  yield takeLatest(types.SEARCH_TYPE, searchResultsAsync);
}

export function* watchLoadMoreAsync() {
  yield takeEvery(types.GET_NEXT_PAGE, getNextPageAsync);
}
