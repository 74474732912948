import * as React from 'react';
import { PlainDropdown } from 'ui-components';
import { Link } from 'react-router-dom';
import Menu from 'antd/lib/menu';
import { DownOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { rgba } from 'polished';
import './MainMenu.scss';
import get from 'lodash/get';
import { Tag } from 'antd';
import { primaryBlue, purple, white } from 'styles/colors';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { ReactComponent as MovementNewBadgeTag } from 'components/common/assets/new_badge_tag.svg';
import { ReactComponent as OpenNewIcon } from 'components/common/assets/icons/icon_open_in_new.svg';
import { ReactComponent as EmailIcon } from 'components/common/assets/icons/icon_email.svg';
import { ReactComponent as RotateIcon } from 'components/common/assets/icons/icon_rotate.svg';
import { shipmentListFiltersUrlEnabled } from 'common/authorizations';
import { trackEvent } from 'common/eventTracker';
import { ActiveTabEnum } from '../MenuConstants';
import { getEndpoints, endpoints as AppEndpoints } from '../../../../common/endpoints';
import { withTheme, WithThemeProps } from '../../../../contexts/ThemeContext';
import {
  locationsManagementBaseConfig,
  locationsManagementMenuItems,
  locationsSmallScreenMenuItemProps,
  mindlogicSmallScreenMenuItemProps,
  mindlogicStarbucksLinkBaseConfig,
} from '../config/locationsManagement';

const StyledItem = styled(Menu.Item)`
  &:hover {
    background-color: ${(props) =>
      get(props, 'theme.primaryColor')
        ? rgba(get(props, 'theme.primaryColor'), 0.5)
        : rgba(primaryBlue, 0.5)} !important;
  }
`;

const StyledLink = styled('a')`
  color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSpan = styled('span')`
  color: ${(props) => (get(props, 'theme.primaryColor') ? get(props, 'theme.primaryColor') : primaryBlue)} !important;
  font-weight: bold !important;
`;

export const StyledTag = styled(Tag)`
  background: ${purple} !important;
  color: ${white} !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 3px 4px 2px !important;
  line-height: 1 !important;
  font-size: 11px !important;

  &:hover {
    cursor: pointer;
  }
`;

type MainMenuOptionItem = {
  email?: boolean;
  rotate?: boolean;
  path: string;
  key: string;
  message: string;
  enabled: boolean;
  target?: string;
  badge?: string;
  callback?: () => void;
};
type MenuItem = Array<{
  displayName: JSX.Element | JSX.Element[] | string;
  key: string;
  callback?: () => void;
}>;

interface MainMenuProps extends WithThemeProps {
  currentTab: ActiveTabEnum | undefined;
  authorizations: any;
  executeShipmentSearch: () => void;
  hasCarrierRole: boolean;
  trackingSection: string | null;
  isMovementBannerVisible: boolean;
}

const MainMenu: React.FC<MainMenuProps> = (props) => {
  const intl = useIntl();
  /**
   * Calling this in a function so we can use jest.spyOn method
   * and mock the implementation
   */
  const endpoints = getEndpoints() || AppEndpoints;

  const [smallScreenDropdownIsVisible, setSmallScreenDropdownIsVisible] = React.useState(false);
  const { authorizations } = props;
  const principalContext = React.useContext(PrincipalContext);

  // TODO: Re-enable rating link when new Initiate product is finished
  // const shouldDisplayTlRating = authorizations.tlRatingLinkIsVisible();
  const shouldDisplayTlRating = false;

  const shouldDisplaySlotBooking = authorizations.hasSlotBooking();
  const shouldDisplayPortIntel = authorizations.hasPortIntel();
  const shouldDisplayMovementPreview = authorizations.hasMovementPreviewAuthorization();
  const slotBookingUrl = get(window, 'portalGlobal.slotBookingUrl') as unknown as string;

  const trackingLinkText = <FormattedMessage id="header.mainMenu.tracking" defaultMessage="Tracking" />;

  // used to dispatch track event
  const planningNavText = props.authorizations.hasOceanInsightsNavigationNames() ? 'BOOKING' : 'PLANNING';

  function handleSmallMenuClick(e: any) {
    if (!['network', 'tracking', 'analytics', 'planning'].includes(e.key)) {
      setSmallScreenDropdownIsVisible(false);
    }
  }

  function handleVisibleSmallDropdownChange(flag: any) {
    setSmallScreenDropdownIsVisible(flag);
  }

  const shouldDisplayOiNavigationName = () => {
    return props.authorizations.hasOceanInsightsNavigationNames() ? (
      <FormattedMessage id="header.mainMenu.booking" defaultMessage="Booking" />
    ) : (
      <FormattedMessage id="header.mainMenu.planning" defaultMessage="Planning" />
    );
  };

  const trackingMenuItems: MainMenuOptionItem[] = [
    shipmentListFiltersUrlEnabled()
      ? {
          path: endpoints.SHIPMENT_LIST,
          key: 'shipments',
          message: intl.formatMessage({
            id: 'shipmentList.shipmentListHeader.listheader',
            defaultMessage: 'Shipments',
          }),
          enabled: props.authorizations.hasAdvancedTrackingLinkingForShipment(),
          callback: () => {
            trackEvent('NAV_SHIPMENTS_CLICK');
          },
        }
      : {
          path: '',
          key: 'shipments',
          message: intl.formatMessage({
            id: 'shipmentList.shipmentListHeader.listheader',
            defaultMessage: 'Shipments',
          }),
          enabled: props.authorizations.hasAdvancedTrackingLinkingForShipment(),
          callback: () => {
            props.executeShipmentSearch();
          },
        },
    {
      path: endpoints.ORDERSLIST,
      key: 'orders',
      message: intl.formatMessage({
        id: 'global.navigation.tracking.submenu.orders',
        defaultMessage: 'Orders',
      }),
      enabled: props.authorizations.hasAdvancedTrackingLinking(),
      callback: () => {
        trackEvent('NAV_ORDERS_CLICK');
      },
    },
    {
      path: endpoints.INVENTORYITEMSLIST,
      key: 'inventory',
      message: intl.formatMessage({
        id: 'global.navigation.tracking.submenu.inventory',
        defaultMessage: 'Inventory',
      }),
      enabled: props.authorizations.hasAdvancedTrackingLinking(),
      callback: () => {
        trackEvent('NAV_INVENTORY_CLICK');
      },
    },
    {
      path: endpoints.OCEAN_INSIGHTS_CTT as string,
      key: 'ocean_insights',
      message: intl.formatMessage({
        id: 'global.navigation.tracking.submenu.ocean.insights.containers',
        defaultMessage: 'Containers',
      }),
      enabled: props.authorizations.hasOceanInsightsCTT() && props.authorizations.hasOceanInsightsNavigationNames(),
      target: '_blank',
      badge: intl.formatMessage({
        id: 'global.navigation.planning.submenu.ocean.css.badge',
        defaultMessage: 'NEW',
      }),
      callback: () => {
        trackEvent('NAV_CONTAINERS_CLICK');
      },
    },
    {
      path: endpoints.LAST_MILE_APP as string,
      key: 'last-mile',
      message: intl.formatMessage({
        id: 'global.navigation.tracking.submenu.last.mile',
        defaultMessage: 'Last Mile',
      }),
      // Only enabled in specific envs (QA-Stage and NA Production to start) by keeping the last mile app URL null in
      // the configuration of other envs.
      enabled: !!endpoints.LAST_MILE_APP.length && props.authorizations.hasLastMileNavigation(),
      target: '_blank',
      badge: intl.formatMessage({
        // Reuse existing ocean badge for 'NEW' to keep translations under one id
        id: 'global.navigation.planning.submenu.ocean.css.badge',
        defaultMessage: 'NEW',
      }),
      callback: () => {
        trackEvent('NAV_LAST_MILE_CLICK');
      },
    },
    {
      path: endpoints.LAST_MILE_APP as string,
      key: 'last-mile-convey-by-project44',
      message: intl.formatMessage({
        id: 'global.navigation.tracking.submenu.last.mile.convey.by.project44',
        defaultMessage: 'Last Mile – Convey by project44',
      }),
      // Only enabled in specific envs (QA-Stage and NA Production to start) by keeping the last mile app URL null in
      // the configuration of other envs.
      enabled: !!endpoints.LAST_MILE_APP.length && props.authorizations.hasLastMileConveyByProject44Navigation(),
      target: '_blank',
      badge: intl.formatMessage({
        // Reuse existing ocean badge for 'NEW' to keep translations under one id
        id: 'global.navigation.planning.submenu.ocean.css.badge',
        defaultMessage: 'NEW',
      }),
      callback: () => {
        trackEvent('NAV_LAST_MILE_CONVEY_BY_PROJECT44_CLICK');
      },
    },
    {
      path: endpoints.CLEAR_METAL_APP as string,
      key: 'clear-metal',
      message: intl.formatMessage({
        id: 'global.navigation.tracking.submenu.clearmetal',
        defaultMessage: 'Ocean - Order Tracking',
      }),
      // Only enabled in specific envs (QA-Stage and NA Production to start) by keeping the clear metal app URL null in
      // the configuration of other envs.
      enabled: !!endpoints.CLEAR_METAL_APP.length && props.authorizations.hasClearMetalNavigation(),
      target: '_blank',
      badge: intl.formatMessage({
        // Reuse existing ocean badge for 'NEW' to keep translations under one id
        id: 'global.navigation.planning.submenu.ocean.css.badge',
        defaultMessage: 'NEW',
      }),
      callback: () => {
        trackEvent('NAV_CLEAR_METAL_CLICK');
      },
    },
  ];

  const planningMenuItems: MainMenuOptionItem[] = [
    {
      path: endpoints.TL_RATING as string,
      key: 'tl-rating',
      message: intl.formatMessage({
        id: 'header.mainMenu.tlRating',
        defaultMessage: 'OTR Rating',
      }),
      enabled: shouldDisplayTlRating,
      target: '_self',
      badge: intl.formatMessage({
        id: 'global.navigation.planning.submenu.tl.rating.badge',
        defaultMessage: 'BETA',
      }),
      callback: () => {
        trackEvent('NAV_TL_RATING_CLICK');
      },
    },
    {
      path: endpoints.RATING,
      key: 'ltl-quote-dispatch',
      message: intl.formatMessage({
        id: 'global.navigation.planning.submenu.vtl.rating',
        defaultMessage: 'LTL & VLTL Quote & Dispatch',
      }),
      enabled: props.authorizations.hasPlanning(),
      target: '_blank',
      callback: () => {
        trackEvent('NAV_LTL_QUOTE_CLICK');
      },
    },
    {
      path: endpoints.OCEAN_INSIGHTS_CSS as string,
      key: 'ocean-css',
      message: intl.formatMessage({
        id: 'global.navigation.planning.submenu.ocean.css',
        defaultMessage: 'Sailing Schedules',
      }),
      enabled: props.authorizations.hasOceanInsightsCSS(),
      target: '_blank',
      badge: intl.formatMessage({
        id: 'global.navigation.planning.submenu.ocean.css.badge',
        defaultMessage: 'NEW',
      }),
      callback: () => {
        trackEvent('NAV_SAILING_SCHEDULES_CLICK');
      },
    },
  ];

  const analyticsMenuItems: MainMenuOptionItem[] = [
    {
      path: endpoints.ANALYTICS_CARRIER_OVERVIEW,
      key: 'truckload',
      message: intl.formatMessage({
        id: 'truckload.navigation.analytics.submenu.overTheRoad',
        defaultMessage: 'Over the Road',
      }),
      enabled: props.authorizations.hasAnalytics(principalContext),
      callback: () => {
        trackEvent('NAV_ANALYTICS_OTR_CLICK');
      },
    },
    {
      path: (props.authorizations.hasOceanInsightsCTT()
        ? endpoints.OCEAN_INSIGHTS_CTT
        : endpoints.ANALYTICS_OCEAN_OVERVIEW) as string,
      key: 'ocean',
      target: props.authorizations.hasOceanInsightsCTT() ? '_blank' : '',
      message: intl.formatMessage({
        id: 'ocean.navigation.analytics.submenu.ocean',
        defaultMessage: 'Ocean',
      }),
      enabled: props.authorizations.hasOceanAnalytics(),
      callback: () => {
        trackEvent('NAV_ANALYTICS_OCEAN_CLICK');
      },
    },
    {
      path: endpoints.OCEAN_SUSTAINABILITY,
      key: 'ocean-sus',
      message: intl.formatMessage({
        id: 'ocean.navigation.analytics.submenu.ocean-sus',
        defaultMessage: 'Ocean Sustainability',
      }),
      enabled: props.authorizations.hasOceanSustainabilityAuthorization(),
      target: '_blank',
      callback: () => {
        trackEvent('NAV_ANALYTICS_OCEAN_SUSTAINABILITY_CLICK');
      },
    },
    {
      path: endpoints.TL_TRACKING,
      key: 'tl-tracking',
      message: intl.formatMessage({
        id: 'ocean.navigation.analytics.submenu.tl-tracking',
        defaultMessage: 'Root Cause Analysis',
      }),
      enabled: props.authorizations.hasTLTrackingAuthorization(principalContext),
      target: '_blank',
      callback: () => {
        trackEvent('NAV_ANALYTICS_TL_TRACKING_CLICK');
      },
    },
    {
      path: endpoints.NETWORK_HEALTH,
      key: 'network-health',
      message: intl.formatMessage({
        id: 'network.navigation.analytics.submenu.network-health',
        defaultMessage: 'Network Health',
      }),
      enabled: props.authorizations.hasNetworkHealthDashboardAccess(principalContext),
      target: '_blank',
      callback: () => {
        trackEvent('NAV_ANALYTICS_NETWORK_HEALTH_CLICK');
      },
    },
    {
      path: endpoints.TRUCKLOAD_ANALYTICS,
      key: 'truckload-analytics',
      message: intl.formatMessage({
        id: 'network.navigation.analytics.submenu.truckload-analytics',
        defaultMessage: 'Truckload Analytics',
      }),
      enabled: props.authorizations.hasTruckloadAnalyticsAuthorization(principalContext),
      target: '_blank',
      callback: () => {
        trackEvent('NAV_ANALYTICS_TRUCKLOAD_CLICK');
      },
    },
  ];

  const intelligenceMenuItems = [
    {
      path: endpoints.PORTINTEL,
      key: 'port-intel',
      message: intl.formatMessage({
        id: 'header.mainMenu.portIntel',
        defaultMessage: 'Port Intel',
      }),
      enabled: true,
      target: '_blank',
      callback: () => {
        trackEvent('NAV_ANALYTICS_PORT_INTEL_CLICK');
      },
    },
  ];

  const movementMenuItems = [
    {
      path: endpoints.MOVEMENT_PREVIEW_URL,
      key: 'movement.preview',
      message: intl.formatMessage({
        id: 'header.mainMenu.movement.preview',
        defaultMessage: 'Preview Movement',
      }),
      enabled: true,
      target: '_blank',
      rotate: true,
    },
    {
      path: endpoints.MOVEMENT_PREVIEW_URL, // Needs to be updated
      key: 'movement.learnMore',
      message: intl.formatMessage({
        id: 'header.mainMenu.movement.learnMore',
        defaultMessage: 'Learn more about',
      }),
      enabled: false,
      target: '_blank',
    },
    {
      path: `mailto:MovementPreviewFeedback@project44.com`,
      key: 'movement.getInTouch',
      message: intl.formatMessage({
        id: 'header.mainMenu.movement.getInTouch',
        defaultMessage: 'Get in touch',
      }),
      enabled: true,
      target: '_blank',
      email: true,
    },
  ];

  const isLocationsEnabled = !props.hasCarrierRole;
  const isMindlogicEnabled = !!endpoints.MINDLOGIC_APP.length && props.authorizations.hasMindlogicNavigation();
  const isMindlogicStarbucksEnabled =
    !!endpoints.MINDLOGIC_STARBUCKS_APP.length && props.authorizations.hasMindlogicNavigationForStarbucks();

  const locationsManagementMenu = locationsManagementMenuItems({
    intl,
    isLocationsEnabled: isLocationsEnabled,
    isMindlogicEnabled: isMindlogicEnabled,
    isMindlogicStarbucksEnabled: isMindlogicStarbucksEnabled,
  });

  const filterEnabledMenuOptionItems = (menuItems: Array<MainMenuOptionItem>) =>
    menuItems.filter((item: MainMenuOptionItem) => item.enabled);

  const generateLink = (item: MainMenuOptionItem) => {
    return item?.target === '_blank' || item?.target === '_self' ? (
      <StyledLink
        aria-label={item.message}
        href={item.path}
        target={item.target}
        rel="noopener noreferrer"
        data-locator="navigation-tl-rating"
      >
        {item.message}
        {item?.target === '_blank' && !(item?.email || item?.rotate) && (
          <OpenNewIcon style={{ height: 12, width: 12, marginLeft: '6px', color: 'inherit' }} />
        )}
        {Boolean(item?.email) && <EmailIcon style={{ height: 12, width: 12, marginLeft: '6px', color: 'inherit' }} />}
        {Boolean(item?.rotate) && <RotateIcon style={{ height: 12, width: 12, marginLeft: '6px', color: 'inherit' }} />}
        {item?.badge && <StyledTag style={{ marginLeft: '6px' }}>{item.badge}</StyledTag>}
      </StyledLink>
    ) : (
      <Link to={item.path} aria-label={item.message}>
        {item.message}
        {item?.badge && <StyledTag style={{ marginLeft: '6px' }}>{item.badge}</StyledTag>}
      </Link>
    );
  };
  const generateMainMenuOption = (analyticsMenu: Array<MainMenuOptionItem>): MenuItem => {
    return analyticsMenu.map((item: MainMenuOptionItem) => {
      return {
        displayName: generateLink(item),
        key: item.key,
        ...(item?.callback && { callback: item.callback }),
      };
    });
  };

  const shouldDisplayAnalyticsMenu = () => filterEnabledMenuOptionItems(analyticsMenuItems).length > 0;
  const shouldDisplayTrackingMenu = () => filterEnabledMenuOptionItems(trackingMenuItems).length > 0;
  const shouldDisplayPlanningMenu = () => filterEnabledMenuOptionItems(planningMenuItems).length > 0;

  const networkMenuItems = [
    {
      displayName: (
        <a
          href={get(window, 'portalGlobal.nmcUrl') as unknown as string}
          target="_blank"
          rel="noopener noreferrer"
          data-locator="navigation-network-management-center"
        >
          <FormattedMessage id="global.navigation.network.submenu.nmc" defaultMessage="Network Management Center" />
        </a>
      ),
      key: 'network-management-center',
      callback: () => {
        trackEvent('NAV_NETWORK_MGMT_CTR_CLICK');
      },
    },
    {
      displayName: (
        <Link to={`/inventory/carrierFleet`}>
          {intl.formatMessage({
            id: 'global.navigation.tracking.submenu.carrierfleet',
            defaultMessage: 'Fleet List',
          })}
        </Link>
      ),
      key: 'fleet',
      callback: () => {
        trackEvent('NAV_NETWORK_FLEET_LIST_CLICK');
      },
    },
  ];

  const smallScreenMenuItems = [
    {
      displayName: (
        <Link
          to={endpoints.DASHBOARD}
          data-locator="navigation-dashboard"
          style={{
            color: props.currentTab === ActiveTabEnum.DASHBOARD ? get(props, 'theme.primaryColor') : 'inherit',
            fontWeight: props.currentTab === ActiveTabEnum.DASHBOARD ? 'bold' : undefined,
          }}
        >
          <FormattedMessage id="header.mainMenu.dashboard" defaultMessage="Dashboard" />
        </Link>
      ),
      key: 'dashboard',
    },
    {
      displayName: (
        <PlainDropdown overlay={overlayMenu('network')} trigger="click">
          <span
            className="nav-item d-flex align-items-center"
            data-locator="navigation-tracking-dropdown"
            style={{
              color:
                props.currentTab === ActiveTabEnum.NETWORK_MANAGEMENT_CENTER
                  ? get(props, 'theme.primaryColor')
                  : 'inherit',
              fontWeight: props.currentTab === ActiveTabEnum.NETWORK_MANAGEMENT_CENTER ? 'bold' : undefined,
            }}
          >
            <FormattedMessage id="header.mainMenu.network" defaultMessage="Network" />
            <DownOutlined
              style={{
                marginLeft: '7px',
                color:
                  props.currentTab === ActiveTabEnum.NETWORK_MANAGEMENT_CENTER
                    ? get(props, 'theme.primaryColor')
                    : 'inherit',
              }}
            />
          </span>
        </PlainDropdown>
      ),
      key: 'network',
    },
  ];

  const analyticsReportsEnabled = filterEnabledMenuOptionItems(analyticsMenuItems);
  const trackingReportsEnabled = filterEnabledMenuOptionItems(trackingMenuItems);
  const planningReportsEnabled = filterEnabledMenuOptionItems(planningMenuItems);

  const locationManagementActiveTabStyles = {
    color: props.currentTab === ActiveTabEnum.LOCATIONS ? get(props, 'theme.primaryColor') : 'inherit',
    fontWeight: props.currentTab === ActiveTabEnum.LOCATIONS ? 'bold' : undefined,
  };

  if (isLocationsEnabled) {
    smallScreenMenuItems.push({
      displayName: <Link {...locationsSmallScreenMenuItemProps} style={locationManagementActiveTabStyles} />,
      key: 'locations',
    });
  }

  if (endpoints.MINDLOGIC_APP && props.authorizations.hasMindlogicNavigation()) {
    smallScreenMenuItems.push({
      displayName: <Link {...mindlogicSmallScreenMenuItemProps} style={locationManagementActiveTabStyles} />,
      key: 'mindlogic',
    });
  }

  //mid screem "Menu" adding Yard Visibility
  if (endpoints.MINDLOGIC_STARBUCKS_APP && props.authorizations.hasMindlogicNavigationForStarbucks()) {
    smallScreenMenuItems.push({
      displayName: (
        <a
          href={get(window, 'portalGlobal.mindlogicStarbucksAppUrl') as unknown as string}
          target="_blank"
          rel="noopener noreferrer"
          data-locator={mindlogicStarbucksLinkBaseConfig.locator}
        >
          <FormattedMessage
            id={mindlogicStarbucksLinkBaseConfig.intlConfig.id}
            defaultMessage={mindlogicStarbucksLinkBaseConfig.intlConfig.defaultMessage}
          />
        </a>
      ),
      key: 'mindlogic-starbucks',
    });
  }

  if (shouldDisplayPlanningMenu()) {
    const planningMenuGenerated = generateMainMenuOption(planningReportsEnabled);
    smallScreenMenuItems.push({
      displayName: (
        <PlainDropdown overlay={overlayMenu('planning')} dropdownItems={planningMenuGenerated}>
          <span
            className="nav-item d-flex align-items-center"
            data-locator="navigation-planning"
            style={{
              color:
                props.currentTab === ActiveTabEnum.RATING_AND_DISPATCH ? get(props, 'theme.primaryColor') : 'inherit',
              fontWeight: props.currentTab === ActiveTabEnum.RATING_AND_DISPATCH ? 'bold' : undefined,
            }}
          >
            {shouldDisplayOiNavigationName()}
            <DownOutlined
              style={{
                marginLeft: '7px',
                color:
                  props.currentTab === ActiveTabEnum.RATING_AND_DISPATCH ? get(props, 'theme.primaryColor') : 'inherit',
              }}
            />
          </span>
        </PlainDropdown>
      ),
      key: 'planning',
    });
  }

  if (shouldDisplayAnalyticsMenu()) {
    const analyticsMenuGenerated = generateMainMenuOption(analyticsReportsEnabled);

    smallScreenMenuItems.push({
      displayName: (
        <PlainDropdown overlay={overlayMenu('analytics')} dropdownItems={analyticsMenuGenerated}>
          <span
            className="nav-item d-flex align-items-center"
            data-locator="navigation-analytics-dropdown"
            style={{
              color: props.currentTab === ActiveTabEnum.ANALYTICS ? get(props, 'theme.primaryColor') : 'inherit',
              fontWeight: props.currentTab === ActiveTabEnum.ANALYTICS ? 'bold' : undefined,
            }}
          >
            <FormattedMessage id="header.mainMenu.analytics" defaultMessage="Analytics" />
            <DownOutlined
              style={{
                marginLeft: '7px',
                color: props.currentTab === ActiveTabEnum.ANALYTICS ? get(props, 'theme.primaryColor') : 'inherit',
              }}
            />
          </span>
        </PlainDropdown>
      ),
      key: 'analytics',
    });
  }

  if (shouldDisplayTrackingMenu()) {
    const trackingMenuGenerated = generateMainMenuOption(trackingReportsEnabled);
    smallScreenMenuItems.push({
      displayName: (
        <PlainDropdown overlay={overlayMenu('tracking')} dropdownItems={trackingMenuGenerated}>
          <span
            className="nav-item d-flex align-items-center"
            data-locator="navigation-tracking-dropdown"
            style={{
              color: props.currentTab === ActiveTabEnum.TRACKING ? get(props, 'theme.primaryColor') : 'inherit',
              fontWeight: props.currentTab === ActiveTabEnum.TRACKING ? 'bold' : undefined,
            }}
          >
            {trackingLinkText}
            <DownOutlined
              style={{
                marginLeft: '7px',
                color: props.currentTab === ActiveTabEnum.TRACKING ? get(props, 'theme.primaryColor') : 'inherit',
              }}
            />
          </span>
        </PlainDropdown>
      ),
      key: 'tracking',
    });
  }

  function overlayMenu(type = 'tracking') {
    let items: MenuItem;
    switch (type) {
      case 'smallScreen':
        items = smallScreenMenuItems;
        break;
      case 'network':
        items = authorizations.hasCarrierFleetViewAuthorization() ? networkMenuItems : networkMenuItems.splice(-1, 1);
        break;
      case 'analytics':
        items = generateMainMenuOption(filterEnabledMenuOptionItems(analyticsMenuItems));
        break;
      case 'planning':
        items = generateMainMenuOption(filterEnabledMenuOptionItems(planningMenuItems));
        break;
      case 'intelligence':
        items = generateMainMenuOption(filterEnabledMenuOptionItems(intelligenceMenuItems));
        break;
      case 'locationsManagement':
        items = generateMainMenuOption(filterEnabledMenuOptionItems(locationsManagementMenu));
        break;
      case 'movement':
        items = generateMainMenuOption(filterEnabledMenuOptionItems(movementMenuItems));
        break;
      default:
        items = generateMainMenuOption(filterEnabledMenuOptionItems(trackingMenuItems));
        break;
    }

    return (
      <Menu style={{ borderRadius: '5px' }} onClick={handleSmallMenuClick}>
        {items.map((item) => {
          const isCurrent = props.trackingSection === item.key;

          return (
            <StyledItem theme={props.theme} key={item.key} onClick={item.callback}>
              {isCurrent ? <StyledSpan theme={props.theme}>{item.displayName}</StyledSpan> : item.displayName}
            </StyledItem>
          );
        })}
      </Menu>
    );
  }

  return (
    <ul className="p-0">
      <li
        className={`${props.currentTab === ActiveTabEnum.DASHBOARD ? 'navigation-active' : ''} d-none d-lg-flex `}
        style={{
          borderColor: props.currentTab === ActiveTabEnum.DASHBOARD ? get(props, 'theme.primaryColor') : 'transparent',
        }}
      >
        <Link
          onClick={() => {
            trackEvent('NAV_DASHBOARD_CLICK');
          }}
          to={endpoints.DASHBOARD}
          data-locator="navigation-dashboard"
          style={{
            color: props.currentTab === ActiveTabEnum.DASHBOARD ? get(props, 'theme.primaryColor') : 'inherit',
          }}
        >
          <FormattedMessage id="header.mainMenu.dashboard" defaultMessage="Dashboard" />
        </Link>
      </li>
      <PlainDropdown
        trigger="click"
        overlay={overlayMenu('smallScreen')}
        overlayClassName={`navigationMenuDropdownOverlay ${props.isMovementBannerVisible && 'movement-banner-visible'}`}
        className="d-none d-md-flex d-lg-none"
        visible={smallScreenDropdownIsVisible}
        onVisibleChange={handleVisibleSmallDropdownChange}
      >
        <li>
          <span
            className="nav-item d-flex align-items-center"
            data-locator="navigation-small-screen-menu-dropdown"
            style={{
              fontSize: '1.4rem',
            }}
          >
            Menu
            <DownOutlined
              style={{
                marginLeft: '7px',
              }}
            />
          </span>
        </li>
      </PlainDropdown>
      {shouldDisplayPlanningMenu() && (
        <PlainDropdown
          onClick={() => {
            trackEvent(`NAV_${planningNavText}_CLICK`);
          }}
          onVisibleChange={(isShowing: boolean) => {
            if (isShowing) {
              trackEvent(`NAV_${planningNavText}_HOVER`);
            }
          }}
          overlay={overlayMenu('planning')}
          dropdownItems={generateMainMenuOption(planningReportsEnabled)}
          overlayClassName={`navigationMenuDropdownOverlay ${
            props.isMovementBannerVisible && 'movement-banner-visible'
          }`}
          className="d-none d-lg-flex "
        >
          <li
            className={`${props.currentTab === ActiveTabEnum.RATING_AND_DISPATCH ? 'navigation-active' : ''}`}
            style={{
              borderColor:
                props.currentTab === ActiveTabEnum.RATING_AND_DISPATCH
                  ? get(props, 'theme.primaryColor')
                  : 'transparent',
            }}
          >
            <span
              className="nav-item d-flex align-items-center"
              data-locator="navigation-planning"
              style={{
                color:
                  props.currentTab === ActiveTabEnum.RATING_AND_DISPATCH ? get(props, 'theme.primaryColor') : 'inherit',
              }}
            >
              {shouldDisplayOiNavigationName()}
              <DownOutlined
                style={{
                  marginLeft: '7px',
                  color:
                    props.currentTab === ActiveTabEnum.RATING_AND_DISPATCH
                      ? get(props, 'theme.primaryColor')
                      : 'inherit',
                }}
              />
            </span>
          </li>
        </PlainDropdown>
      )}

      {shouldDisplayTrackingMenu() && (
        <PlainDropdown
          onClick={() => {
            trackEvent('NAV_TRACKING_CLICK');
          }}
          onVisibleChange={(isShowing: boolean) => {
            if (isShowing) {
              trackEvent('NAV_TRACKING_HOVER');
            }
          }}
          overlay={overlayMenu('tracking')}
          dropdownItems={generateMainMenuOption(trackingReportsEnabled)}
          overlayClassName={`navigationMenuDropdownOverlay ${
            props.isMovementBannerVisible && 'movement-banner-visible'
          }`}
          className="d-none d-lg-flex "
        >
          <li
            className={`${props.currentTab === ActiveTabEnum.TRACKING ? 'navigation-active' : ''}`}
            style={{
              borderColor:
                props.currentTab === ActiveTabEnum.TRACKING ? get(props, 'theme.primaryColor') : 'transparent',
            }}
          >
            <span
              className="nav-item d-flex align-items-center"
              data-locator="navigation-tracking-dropdown"
              style={{
                color: props.currentTab === ActiveTabEnum.TRACKING ? get(props, 'theme.primaryColor') : 'inherit',
              }}
            >
              {trackingLinkText}
              <DownOutlined
                style={{
                  marginLeft: '7px',
                  color: props.currentTab === ActiveTabEnum.TRACKING ? get(props, 'theme.primaryColor') : 'inherit',
                }}
              />
            </span>
          </li>
        </PlainDropdown>
      )}
      {isLocationsEnabled || isMindlogicEnabled || isMindlogicStarbucksEnabled ? (
        <PlainDropdown
          onClick={() => {
            trackEvent(locationsManagementBaseConfig.trackEvents.onClick);
          }}
          onVisibleChange={(isShowing: boolean) => {
            if (isShowing) {
              trackEvent(locationsManagementBaseConfig.trackEvents.onHover);
            }
          }}
          overlay={overlayMenu('locationsManagement')}
          dropdownItems={generateMainMenuOption(locationsManagementMenu)}
          overlayClassName={`navigationMenuDropdownOverlay ${
            props.isMovementBannerVisible && 'movement-banner-visible'
          }`}
          className="d-none d-lg-flex"
        >
          <li
            className={`${props.currentTab === ActiveTabEnum.LOCATIONS ? 'navigation-active' : ''} d-none d-lg-flex `}
            style={{
              borderColor:
                props.currentTab === ActiveTabEnum.LOCATIONS ? get(props, 'theme.primaryColor') : 'transparent',
            }}
          >
            <span
              className="nav-item d-flex align-items-center"
              data-locator={locationsManagementBaseConfig.locator}
              style={{
                color: props.currentTab === ActiveTabEnum.LOCATIONS ? get(props, 'theme.primaryColor') : 'inherit',
              }}
            >
              <FormattedMessage
                id={locationsManagementBaseConfig.intlConfig.id}
                defaultMessage={locationsManagementBaseConfig.intlConfig.defaultMessage}
              />
              <DownOutlined
                style={{
                  marginLeft: '7px',
                  color: props.currentTab === ActiveTabEnum.LOCATIONS ? get(props, 'theme.primaryColor') : 'inherit',
                }}
              />
            </span>
          </li>
        </PlainDropdown>
      ) : null}
      {shouldDisplayAnalyticsMenu() && (
        <PlainDropdown
          onClick={() => {
            trackEvent('NAV_ANALYTICS_CLICK');
          }}
          onVisibleChange={(isShowing: boolean) => {
            if (isShowing) {
              trackEvent('NAV_ANALYTICS_HOVER');
            }
          }}
          overlay={overlayMenu('analytics')}
          dropdownItems={generateMainMenuOption(analyticsReportsEnabled)}
          overlayClassName={`navigationMenuDropdownOverlay ${
            props.isMovementBannerVisible && 'movement-banner-visible'
          }`}
          className="d-none d-lg-flex "
        >
          <li
            className={`${props.currentTab === ActiveTabEnum.ANALYTICS ? 'navigation-active' : ''}`}
            style={{
              borderColor:
                props.currentTab === ActiveTabEnum.ANALYTICS ? get(props, 'theme.primaryColor') : 'transparent',
            }}
          >
            <span
              className="nav-item d-flex align-items-center"
              data-locator="navigation-analytics-dropdown"
              style={{
                color: props.currentTab === ActiveTabEnum.ANALYTICS ? get(props, 'theme.primaryColor') : 'inherit',
              }}
            >
              <FormattedMessage id="header.mainMenu.analytics" defaultMessage="Analytics" />
              <DownOutlined
                style={{
                  marginLeft: '7px',
                  color: props.currentTab === ActiveTabEnum.ANALYTICS ? get(props, 'theme.primaryColor') : 'inherit',
                }}
              />
            </span>
          </li>
        </PlainDropdown>
      )}

      <PlainDropdown
        onClick={() => {
          trackEvent('NAV_NETWORK_CLICK');
        }}
        onVisibleChange={(isShowing: boolean) => {
          if (isShowing) {
            trackEvent('NAV_NETWORK_HOVER');
          }
        }}
        overlay={overlayMenu('network')}
        dropdownItems={networkMenuItems}
        overlayClassName={`navigationMenuDropdownOverlay ${props.isMovementBannerVisible && 'movement-banner-visible'}`}
        className="d-none d-lg-flex "
      >
        <li
          className={`${props.currentTab === ActiveTabEnum.NETWORK_MANAGEMENT_CENTER ? 'navigation-active' : ''}`}
          style={{
            borderColor:
              props.currentTab === ActiveTabEnum.NETWORK_MANAGEMENT_CENTER
                ? get(props, 'theme.primaryColor')
                : 'transparent',
          }}
        >
          <span
            className="nav-item d-flex align-items-center"
            data-locator="navigation-tracking-dropdown"
            style={{
              color:
                props.currentTab === ActiveTabEnum.NETWORK_MANAGEMENT_CENTER
                  ? get(props, 'theme.primaryColor')
                  : 'inherit',
            }}
          >
            <FormattedMessage id="header.mainMenu.network" defaultMessage="Network" />
            <DownOutlined
              style={{
                marginLeft: '7px',
                color:
                  props.currentTab === ActiveTabEnum.NETWORK_MANAGEMENT_CENTER
                    ? get(props, 'theme.primaryColor')
                    : 'inherit',
              }}
            />
          </span>
        </li>
      </PlainDropdown>
      {shouldDisplaySlotBooking && (
        <li
          style={{
            borderColor: 'transparent',
          }}
          className="d-none d-lg-flex "
        >
          <a
            href={slotBookingUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-locator="navigation-tl-rating"
            style={{
              color: 'inherit',
            }}
          >
            <FormattedMessage id="header.mainMenu.slotBooking" defaultMessage="Slot Booking" />{' '}
            <OpenNewIcon style={{ height: 12, width: 12 }} />
          </a>
        </li>
      )}
      {shouldDisplayPortIntel && (
        <PlainDropdown
          overlay={overlayMenu('intelligence')}
          dropdownItems={intelligenceMenuItems}
          overlayClassName={`navigationMenuDropdownOverlay ${
            props.isMovementBannerVisible && 'movement-banner-visible'
          }`}
          className="d-none d-lg-flex "
        >
          <li
            className={`${props.currentTab === ActiveTabEnum.INTEL ? 'navigation-active' : ''}`}
            style={{
              borderColor: props.currentTab === ActiveTabEnum.INTEL ? get(props, 'theme.primaryColor') : 'transparent',
            }}
          >
            <span
              className="nav-item d-flex align-items-center"
              data-locator="navigation-tracking-dropdown"
              style={{
                color: props.currentTab === ActiveTabEnum.INTEL ? get(props, 'theme.primaryColor') : 'inherit',
              }}
            >
              <FormattedMessage id="header.mainMenu.intelligence" defaultMessage="Intelligence" />
              <DownOutlined
                style={{
                  marginLeft: '7px',
                  color: props.currentTab === ActiveTabEnum.INTEL ? get(props, 'theme.primaryColor') : 'inherit',
                }}
              />
            </span>
          </li>
        </PlainDropdown>
      )}
      {shouldDisplayMovementPreview && (
        <PlainDropdown
          overlay={overlayMenu('movement')}
          dropdownItems={movementMenuItems}
          overlayClassName={`navigationMenuDropdownOverlay ${
            props.isMovementBannerVisible && 'movement-banner-visible'
          }`}
          className="d-lg-flex"
        >
          <li>
            <span className="nav-item d-flex align-items-center" data-locator="navigation-tracking-dropdown">
              <div style={{ width: 68 }}>
                <FormattedMessage id="header.mainMenu.movement.defaut" defaultMessage="Movement" />
              </div>
              <MovementNewBadgeTag style={{ marginLeft: 4 }} />
              <DownOutlined
                style={{
                  marginLeft: '7px',
                }}
              />
            </span>
          </li>
        </PlainDropdown>
      )}
    </ul>
  );
};

export default withTheme(MainMenu);
