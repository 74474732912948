import { FormattedMessage, defineMessages, createIntl, createIntlCache } from 'react-intl';
import { Field, FieldArray, FormSection } from 'redux-form';
import get from 'lodash/get';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'ui-components';

import { locale, messages } from '../../../../i18n/configurei18n';
import * as constants from '../../ducks/constants';
import {
  RenderSingleSelectDropdown,
  renderAutoComplete,
  RenderCriteriaChips,
  renderMultiSelect,
  AddSectionLink,
  renderModeSelect,
} from '../AddEditFormInputs';
import filterTypes from '../../../filter/ducks/types';

const cache = createIntlCache();
export const intl = createIntl({ locale, messages }, cache);

const message = defineMessages({
  selectCriteria: {
    id: 'notifications.shipmentRuleFormSection.selectCriteria',
    defaultMessage: 'Select Criteria',
  },
});

const ShipmentRuleFormSection = (props) => {
  const selectedMode = get(props, 'formValues.mode', props.initialValues.mode);
  const shipmentCriteriaOptions = Object.entries(
    constants.EventNotificationTargetTypeConstantsByMode[selectedMode]
  ).map(([key, displayName]) => ({
    value: key,
    displayName,
  }));
  return (
    <MuiThemeProvider>
      <div className="row mb-4">
        <div className="col-20 mb-4">
          <Field
            name="operator"
            component={renderModeSelect}
            modeOptions={
              shipmentCriteriaOptions.length === 0
                ? constants.ShipmentNotificationRuleConstants.shipmentInclusionCriteriaValuesNoDropdownItems.map(
                    mapInclusionCriteria
                  )
                : constants.ShipmentNotificationRuleConstants.shipmentInclusionCriteriaValues.map(mapInclusionCriteria)
            }
          />
        </div>
        {shipmentCriteriaOptions.length === 0 ? null : (
          <FieldArray
            name="fields"
            component={renderShipmentCriteria}
            formValues={props.formValues}
            initialValues={props.initialValues}
            locationSuggestions={props.locationSuggestions}
            carrierSuggestions={props.carrierSuggestions}
            companySuggestions={props.companySuggestions}
            dispatch={props.dispatch}
            queryAutocompleteOptions={props.queryAutocompleteOptions}
            newShipmentCriteria={props.newShipmentCriteria}
            shipmentCriteriaOptions={shipmentCriteriaOptions}
          />
        )}
      </div>
    </MuiThemeProvider>
  );
};

const mapInclusionCriteria = ({ id, display }) => ({
  value: id,
  label: display,
});

const renderShipmentCriteria = (props) => {
  return (
    <div className="col-24">
      {props.fields.map((criteria, index) => {
        const currentField = get(props, `formValues.filters.fields[${index}]`);
        return (
          <FormSection key={criteria} name={criteria}>
            <div className="row mb-2">
              <div className="col-20">
                <label htmlFor="event-criteria-input" className="sr-only">
                  {intl.formatMessage(message.selectCriteria)}
                </label>
                <Field
                  name="type"
                  placeholder={intl.formatMessage(message.selectCriteria)}
                  component={RenderSingleSelectDropdown}
                  data-locator="event-criteria-input"
                  id="event-criteria-input"
                  dropdownItems={props.shipmentCriteriaOptions}
                />
              </div>
              {currentField &&
                currentField.type === constants.EventNotificationTargetTypeConstantKeys.SelectedStopCustomer && (
                  <div style={{ marginTop: 10 }}>
                    <Tooltip
                      title={
                        <FormattedMessage
                          id="notifications.shipmentRuleFormSection.customerStops"
                          defaultMessage="'Stop' refers to the pickup, intermediate stop(s), or delivery dropdown option that has been selected"
                        />
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                )}

              <div>
                <Button type="secondary" onClick={() => props.fields.remove(index)}>
                  <i className="material-icons">close</i>
                </Button>
              </div>

              {get(currentField, 'type') && (
                <div className="col-20">
                  <label htmlFor="notification-criteria-input" className="sr-only">
                    {
                      <FormattedMessage
                        id="notifications.types.notification.criteria.label"
                        defaultMessage="Please provide additional details"
                      />
                    }
                  </label>
                  <Field
                    selectedMode={props.formValues.mode}
                    name="input"
                    id="notification-criteria-input"
                    component={renderAutoComplete}
                    data-locator="notification-criteria-input"
                    queryAutocompleteOptions={props.queryAutocompleteOptions}
                    currentParameters={currentField}
                    dataSource={get(props, `${constants.SUGGESTIONS_MAP[currentField.type]}Suggestions`, [])}
                    queryType={filterTypes.UPDATE_SUGGESTIONS}
                  />

                  <Field name="types" component={renderMultiSelect} currentParameters={currentField} />
                </div>
              )}

              {(get(currentField, 'values') || []).length > 0 && (
                <div className="notification-rule__chip-container col-20">
                  <RenderCriteriaChips
                    currentField={currentField.values}
                    dispatch={props.dispatch}
                    formPath={`filters.fields[${index}].values`}
                  />
                </div>
              )}
            </div>
          </FormSection>
        );
      })}
      <div className="row">
        <div className="col-md-24">
          <AddSectionLink onClick={() => props.fields.push(props.newShipmentCriteria())}>
            <FormattedMessage id="notifications.shipmentRuleFormSection.addCriteria" defaultMessage="Add criteria" />
          </AddSectionLink>
        </div>
      </div>
    </div>
  );
};

export default ShipmentRuleFormSection;
