import Dropzone from 'react-dropzone';

import { Fragment, Component, MouseEvent } from 'react';
import { FormattedMessage, createIntlCache, createIntl } from 'react-intl';
import classNames from 'classnames';
import { locale, messages } from 'i18n/configurei18n';
import * as styles from './DocumentUploadComponent.module.scss';

// Child Components
import FilesUploadArea, { FilesUploadAreaRootProps } from './FilesUploadArea';
import DocumentStageComponent from './DocumentStageComponent';
import { validateFilesExtType, validateFilesSize, getFileTypesForAcceptedFiles } from '../utils/validateFile';

const cache = createIntlCache();
const intl = createIntl({ locale, messages }, cache);

interface DocumentUploadComponentProps {
  onSubmit: (files: File[] | null | undefined, documentType: string) => Promise<boolean>;
}
interface DocumentUploadComponentState {
  error?: string;
  stagedFiles?: File[] | undefined;
  showDocumentStageComponent: boolean;
}
class DocumentUploadComponent extends Component<DocumentUploadComponentProps, DocumentUploadComponentState> {
  state = {
    error: undefined,
    stagedFiles: [],
    showDocumentStageComponent: false,
  };

  // TODOs:
  // build wrapper for addition procedure before or after onSubmit.
  // build additional toggle logic for stagedFiles array is empty.

  toggleDocumentStageComponent = () => {
    this.setState({
      showDocumentStageComponent: !this.state.showDocumentStageComponent,
    });
  };

  handleDocumentStageComponentOn = () => {
    this.setState({
      error: undefined,
    });
    document.body.classList.add('overflow-hidden');
    this.toggleDocumentStageComponent();
  };

  handleDocumentStageComponentOff = () => {
    document.body.classList.remove('overflow-hidden');
    this.toggleDocumentStageComponent();
    this.setState({
      stagedFiles: [],
    });
  };

  onDrop = (acceptedFiles: File[], rejectedFiles: File[]): void => {
    // v8.0.3 onDrop for react-drop zone: rejectedFiles tied to options (props) passed into component.
    // conditions should start checking rejectedFiles array first.
    if (rejectedFiles.length > 0) {
      return this.setState({
        error: intl.formatMessage({
          defaultMessage:
            'One of the files you selected is not a supported file type. Please review the guidelines and try again.',
          id: 'documentManualUploadUi.documentUpload.noSupportFileTypeError',
        }),
      });
    } else if (acceptedFiles.length < 1) {
      return;
    } else if (acceptedFiles.length > 2) {
      return this.setState({
        error: intl.formatMessage({
          defaultMessage: 'Only two files allowed per document. Please review the guidelines and try again.',
          id: 'documentManualUploadUi.documentUpload.maxFile',
        }),
      });
    } else if (validateFilesExtType(acceptedFiles) === false) {
      return this.setState({
        error: intl.formatMessage({
          defaultMessage: 'All files must be the same file type. Please review the guidelines and try again.',
          id: 'documentManualUploadUi.documentUpload.noSupportMultipleFileType',
        }),
      });
    } else if (validateFilesSize(acceptedFiles) === false) {
      return this.setState({
        error: intl.formatMessage({
          defaultMessage: 'The files you have selected are too large. Please review the guidelines and try again.',
          id: 'documentManualUploadUi.documentUpload.fileTooLargeError',
        }),
      });
    } else {
      const loadedFiles = this.state.stagedFiles;
      let validatedFiles: File[] = [];
      getFileTypesForAcceptedFiles(acceptedFiles)
        .then((res) => {
          validatedFiles = acceptedFiles.map((el, idx) => Object.assign(el, { validatedMimeType: res[idx] }));
          this.setState({
            error: undefined,
            stagedFiles: [...loadedFiles, ...validatedFiles],
          });
        })
        .catch((err) => err);

      this.handleDocumentStageComponentOn();
    }
  };

  onRemoveFile = (e: MouseEvent, idx: number) => {
    e.preventDefault();
    const currFiles = [...this.state.stagedFiles];
    currFiles.splice(idx, 1);
    return this.setState({
      stagedFiles: currFiles,
    });
  };

  render() {
    return (
      <Fragment>
        <div id="document-upload-component-box" className={`${styles['document-upload-component-box']} py-5`}>
          <div className={styles['document-upload-section']}>
            <p className="pb-3 px-4 font-semibold text-3xl">
              <FormattedMessage
                defaultMessage="Upload Documents"
                id="documentManualUploadUi.documentUpload.uploadFiles"
              />
            </p>
            {
              <div className="px-4 flex-col">
                <Dropzone
                  multiple
                  disableClick
                  onDrop={this.onDrop}
                  accept={'application/pdf, image/png, image/jpeg, image/gif, image/tiff'}
                >
                  {(props: FilesUploadAreaRootProps) => <FilesUploadArea {...props} error={this.state.error} />}
                </Dropzone>
              </div>
            }

            <div className={classNames(styles['document-upload-requirement-text'], 'px-4 pt-3')}>
              <span className="text-2xl">
                <FormattedMessage
                  defaultMessage="Supported file types: PDF, PNG, JPG, TIFF, or GIF"
                  id="documentManualUploadUi.documentUpload.supportedFileTypes"
                  tagName="p"
                />
              </span>
              <span className="text-2xl">
                <FormattedMessage
                  defaultMessage="Guidelines: Up to two 10MB files of the same type"
                  id="documentManualUploadUi.documentUpload.uploadGuidelines"
                  tagName="p"
                />
              </span>
            </div>
          </div>

          {this.state.showDocumentStageComponent && (
            <DocumentStageComponent
              stagedFiles={this.state.stagedFiles}
              onSubmit={this.props.onSubmit}
              onRemoveFile={this.onRemoveFile}
              handleDocumentStageComponentOff={this.handleDocumentStageComponentOff}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default DocumentUploadComponent;
