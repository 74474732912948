import { Dispatch, SetStateAction } from 'react';
import * as React from 'react';
import get from 'lodash/get';
import { FormattedMessage, useIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { useDispatch } from 'react-redux';
import { InventoryOrder, InventoryItem } from 'components/Inventory/models';
import ConfigurableTable from 'common/ConfigurableTable/ConfigurableTable';
import { trackEvent } from 'common/eventTracker';
import { inventoryIdentifiersTypeList } from './InventoryItemsList';

interface InventoryItemsTableProps {
  dataResults: InventoryOrder[] | undefined;
  isLoading: boolean;
  onPageChangeFn: Dispatch<SetStateAction<number>>;
  totalResults: number | undefined;
}

const INVENTORY_ENTITY_TYPE = 'INVENTORY';

const InventoryItemsTable: React.FC<InventoryItemsTableProps> = ({
  dataResults = [],
  isLoading,
  onPageChangeFn,
  totalResults,
}) => {
  const intl = useIntl();

  const mergedTypeList = [
    ...inventoryIdentifiersTypeList.map((item) => ({ ...item, dataIndex: 'inventoryIdentifiers' })),
  ];

  const typeColumns = mergedTypeList.map((item) => {
    return {
      dataIndex: item.dataIndex,
      render: function render(orderTags: any[]) {
        if (!orderTags) return null;

        return orderTags
          .filter((orderTag) => orderTag.type === item.value)
          .map((orderTag) => orderTag.value)
          .join(', ');
      },
      title: intl.formatMessage(item.message),
      width: 175,
      visible: false,
    };
  });

  const columns = [
    {
      dataIndex: 'stockKeepingUnit',
      className: 'data-locator-sku',
      title: intl.formatMessage({ id: 'inventory.itemsList.columnHeaders.sku', defaultMessage: 'SKU' }),
      width: 130,
      locked: true,
      visible: true,
    },
    {
      dataIndex: 'description',
      title: intl.formatMessage({ id: 'inventory.itemsList.columnHeaders.description', defaultMessage: 'Description' }),
      width: 175,
      visible: true,
    },
    {
      dataIndex: 'universalProductCode',
      sorter: true,
      title: intl.formatMessage({ id: 'inventory.itemsList.columnHeaders.upc', defaultMessage: 'UPC' }),
      width: 175,
      visible: true,
    },
    {
      dataIndex: ['perUnitCost', 'amount'],
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.perUnitCost',
        defaultMessage: 'Per Unit Cost',
      }),
      render: function renderCol(text: string, record: InventoryItem) {
        if (text === undefined || text === null) return '';

        return parseFloat(text).toLocaleString(undefined, {
          style: 'currency',
          currency: get(record, 'perUnitCost.currency', 'USD'),
        });
      },
      width: 175,
      visible: true,
    },
    {
      dataIndex: ['inventoryDimensionalWeight', 'quantity'],
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.quantity',
        defaultMessage: 'Quantity',
      }),
      width: 175,
      visible: true,
    },
    {
      dataIndex: ['perUnitRetailValue', 'amount'],
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.perUnitRetailValue',
        defaultMessage: 'Retail Value/unit',
      }),
      render: function renderCol(text: string, record: InventoryItem) {
        if (text === undefined || text === null) return '';

        return parseFloat(text).toLocaleString(undefined, {
          style: 'currency',
          currency: get(record, 'perUnitRetailValue.currency', 'USD'),
        });
      },
      width: 175,
      visible: true,
    },
    {
      dataIndex: ['inventoryDimensionalWeight', 'packageType'],
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.uofm',
        defaultMessage: 'UoM',
      }),
      width: 175,
      visible: true,
    },
    {
      dataIndex: ['lineItemHazmatDetail', 'hazardClass'],
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.hazardClass',
        defaultMessage: 'Hazard Class',
      }),
      width: 175,
      visible: true,
    },
    {
      dataIndex: 'perishable',
      title: intl.formatMessage({
        id: 'inventory.itemsList.columnHeaders.perishable',
        defaultMessage: 'Perishable',
      }),
      render: function renderCol(value: any) {
        if (value) return <FormattedMessage id={'text.true'} defaultMessage={'True'} />;
        return <FormattedMessage id={'text.false'} defaultMessage={'False'} />;
      },
      width: 175,
      visible: false,
    },
    ...typeColumns,
  ];

  const tableType = 'inventoryTable';

  const dispatch = useDispatch();

  const onRowClick = (record: InventoryItem) => {
    return {
      onClick: () => {
        trackEvent('INVENTORY_LIST_ROW_CLICK', {
          id: record.id || '',
        });
        dispatch(push(`/inventory/items/${record.id}`));
      },
    };
  };

  return (
    <ConfigurableTable
      entityType={INVENTORY_ENTITY_TYPE}
      tableType={tableType}
      dataResults={dataResults}
      isLoading={isLoading}
      onPageChangeFn={onPageChangeFn}
      totalResults={totalResults}
      columns={columns}
      onRowClick={onRowClick}
    />
  );
};

export default InventoryItemsTable;
