import { connect } from 'react-redux';

import { WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import {
  getPrincipalAuthoritiesFromState,
  getPrincipalAuthorizationsFromState,
  getPrincipalFromState,
} from 'common/authorizations';
import CarrierFleetListComponent from './CarrierFleetListComponent';
import { buildResetCarrierSearchResult } from './ducks/carrierSearchReducer';
import { RootState } from '../../reducers';
import { CarrierSearchCardData } from './models/CarrierSearchCardData';

export interface CarrierFleetListProps extends RouteComponentProps<any>, WrappedComponentProps {
  carrierFleetSearchTerm?: string;
  totalCarriersListSearchResults: string;
  carrierResult?: CarrierSearchCardData;
  totalCarriersList?: string;
  errorNumber?: number;
  isLoading?: boolean;
}

export interface CarrierFleetListDispatchProps {
  resetCarrierSearchResult: () => void;
}

const mapStateToProps = (state: RootState, ownProps: CarrierFleetListProps) => {
  const { carrierSearchReducer } = state;
  const authorities = getPrincipalAuthoritiesFromState(state);
  const authorizations = getPrincipalAuthorizationsFromState(state);

  return {
    ...ownProps,
    ...carrierSearchReducer,
    isLoading: false, // carrierFleetSearchReducer.isLoading,
    authorities: authorities,
    authorizations,
    principal: getPrincipalFromState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): CarrierFleetListDispatchProps => ({
  resetCarrierSearchResult: () => {
    dispatch(buildResetCarrierSearchResult());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CarrierFleetListComponent);
