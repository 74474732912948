import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { Radio } from 'ui-components';

import * as styles from './styles.module.scss';
import { useDatePicker } from '../context/DatePickerContext';
import { EventType } from '../types';
import { buildRadioHandler } from '../utils';
import DateFormatSelect from '../DateFormatSelect';
import Label from '../Label';

interface SidebarProps {
  eventRadioOptions: Array<{ value: string; label: string | JSX.Element }>;
}

const Sidebar = ({ eventRadioOptions }: SidebarProps) => {
  const { state, dispatch } = useDatePicker();

  const handleChangeEventType = (eventType: EventType) => {
    dispatch({ type: 'SET_EVENT_TYPE', payload: eventType });
  };

  return (
    <div className={cn('d-flex flex-column p-5', styles['sidebar'])}>
      <Label htmlFor="event-type" className="text-uppercase font-weight-bold mb-4">
        <FormattedMessage id="datePicker.label.eventType" defaultMessage="Event" />
      </Label>
      <Radio
        onChange={buildRadioHandler(handleChangeEventType)}
        value={state.eventType}
        className={cn('mb-4', styles['sidebar-radio'])}
        layout="column"
        radioData={eventRadioOptions}
      />
      <Label htmlFor="date-format" className="mb-4">
        <FormattedMessage id="datePicker.label.dateFormat" defaultMessage="Date Format" />
      </Label>
      <DateFormatSelect layout="column" className={styles['sidebar-radio']} />
    </div>
  );
};

export default Sidebar;
