import styled from 'styled-components';
import { LockFilled } from '@ant-design/icons';
import Table from 'common/Table/Table';
import { alto, altoLight, black } from 'styles/colors';
import { Modal, Button as SimpleButton } from 'ui-components';

export const StyledModal = styled(Modal)`
  max-width: 559px !important;
`;

export const StyledH1 = styled('h1')`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0px;
`;

export const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: ${altoLight};
    border-bottom: 1px solid ${alto};
    padding-left: 23px;
    padding-right: 23px;

    &:hover {
      background-color: ${alto};
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${alto};
    padding-left: 23px;
    padding-right: 23px;

    &:first-child {
      border-left: 1px solid ${alto};
    }
    &:last-child {
      border-right: 1px solid ${alto};
    }
  }
`;

export const StyledConfigTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: transparent;
    border-bottom: 1px solid transparent;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .ant-table-tbody > tr > td {
    padding-top: 9px !important;
    padding-bottom: 11px !important;
    border-bottom: 1px solid ${alto} !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
`;

export const StyledIconButton = styled(SimpleButton)`
  position: absolute !important;
  top: 6px !important;
  right 6px !important;
  z-index: 99 !important;
  font-size: 19px !important;
  padding: 0px !important;
  color: ${black} !important;
`;
export const StyledToggleButtom = styled(SimpleButton)`
  ${(props) => `color: ${props.color} !important;`}
`;

export const StyledLock = styled(LockFilled)`
  color: ${black} !important;
`;
