import every from 'lodash/every';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import { RootState } from 'reducers';

import { PrincipalContextProps } from 'contexts/PrincipalContext';
import { MODE_AUTHORITY_TO_MODE_MAP, UI_AUTHORITIES } from 'common/authorities';
import { CarrierNetworkRoles } from 'models/CarrierNetworkRoles';
import { ShipmentModeEnum } from '../components/newShipmentDetails/models';

const tenantAdminAuthority = 'ROLE_P44ADMIN';
const tenantEditAuthorities = new Set(['SAVE_USER_ROLES', tenantAdminAuthority]);
const customerAdminAuthorites = ['SAVE_USER_ROLES'];

// Matching V1 permissions from router-service.js hasRouteAccess(Routes.VLTL_LTL_RATES) in Freighthub
const planningAuthorities = new Set(['PORTAL_VLTL_RATE_QUERY', 'PORTAL_LTL_RATE_QUERY']);

const lastMileNavigationSetting = 'NAVIGATION_LAST_MILE';
const lastMileConveyByProject44NavigationSetting = 'NAVIGATION_CONVEY_BY_PROJECT44';
const clearMetalNavigationSetting = 'NAVIGATION_CLEAR_METAL';
const mindLogicNavigationSetting = 'NAVIGATION_MINDLOGIC';
const mindLogicStarbucksNavigationSetting = 'NAVIGATION_MINDLOGIC_STARBUCKS';

export const oceanOnModeAgnosticPage = () => get(window, 'portalGlobal.oceanOnModeAgnosticPage', false);
export const temperatureMonitoringV2 = () => get(window, 'portalGlobal.temperatureMonitoringV2', false);
export const parcelSMSNotificationsEnabled = () => get(window, 'portalGlobal.parcelSMSNotificationsEnabled', false);
export const enableShipmentTrackingMethod = () => get(window, 'portalGlobal.enableShipmentTrackingMethod', false);
export const tlEmissionsEnabled = () => get(window, 'portalGlobal.tlEmissionsEnabled', false);
export const tlAdditionalApptWindowsEnabled = () => get(window, 'portalGlobal.tlAdditionalApptWindowsEnabled', false);
export const tdxCarrierNetworkRolesEnabled = () => get(window, 'portalGlobal.tdxCarrierNetworkRolesEnabled', false);

export const oceanShipmentListLocodeEnabled = () => get(window, 'portalGlobal.oceanShipmentListLocodeEnabled', false);
export const oceanShipmentPortFiltersEnabled = () => get(window, 'portalGlobal.oceanShipmentPortFiltersEnabled', false);
export const portCongestionAppEnabled = () => get(window, 'portalGlobal.portCongestionAppEnabled', false);

export const tlDepartedLateNotificationsEnabled = () =>
  get(window, 'portalGlobal.tlDepartedLateNotificationsEnabled', false);

export const disableAutomatedTestAccountsOnLandingMap = () =>
  get(window, 'portalGlobal.disableAutomatedTestAccountsOnLandingMap', false);

export const shipmentListFiltersUrlEnabled = () => get(window, 'portalGlobal.shipmentListFiltersUrlEnabled', false);

export const orderInventoryListFiltersUrlEnabled = () =>
  get(window, 'portalGlobal.orderInventoryListFiltersUrlEnabled', false);

export const supplyStackWebComponentsEnabled = () => get(window, 'portalGlobal.supplyStackWebComponentsEnabled', false);

export const getOktaURL = () => get(window, 'portalGlobal.oktaUrl', false);

export interface Principal {
  authorities: Array<Authorities>;
  tenantId: number;
  id: number;
  firstName: string;
  lastName: string;
  interfacePreference?: any;
  userProperties: any;
  companyName: string;
  tenantCarrierNetworkRoles: string[];
}

export interface Authorities {
  [key: string]: string;
}

export class PrincipalAuthorizations {
  authorities: any[];
  customerProperties: { [key: string]: any } | undefined = {};

  constructor(authorization: { authorities: any[]; principal: any; customerProperties?: { [key: string]: any } }) {
    let authoritiesArray;
    if (has(authorization, 'principal') && !isNil(authorization.principal)) {
      this.customerProperties = authorization.principal.customerProperties;
    } else if (has(authorization, 'customerProperties')) {
      this.customerProperties = authorization.customerProperties;
    }
    if (authorization && !isNil(authorization.authorities)) {
      authoritiesArray = authorization.authorities;
    } else if (get(authorization, 'principal.authorities')) {
      authoritiesArray = authorization.principal.authorities;
    } else {
      authoritiesArray = authorization;
    }

    if (Array.isArray(authoritiesArray)) {
      this.authorities = authoritiesArray
        .map((authorityObj) => get(authorityObj, 'authority'))
        .filter((authority) => !!authority);
    } else {
      this.authorities = [];
    }
  }

  hasEditCustomBrandingAuthorization() {
    return this.hasCustomerAdminAuthorization() || this.hasTenantAdminAuthorization();
  }

  hasCustomGeofenceEnabledAuthorization() {
    return get(window, 'portalGlobal.customGeofenceEnabled', false);
  }

  hasLocationsV2EnabledAuthorization() {
    return get(window, 'portalGlobal.locationsV2Enabled', false);
  }

  hasLocationProfileKpiChartsAuthorization() {
    return get(window, 'portalGlobal.locationProfileKpiCharts', false);
  }

  hasCustomerAdminAuthorization() {
    return every(customerAdminAuthorites, (authority: string) => this.authorities.indexOf(authority) > -1);
  }

  hasTenantAdminAuthorization() {
    return this.authorities.indexOf(tenantAdminAuthority) > -1;
  }

  canEditTenantLevelNotifications() {
    return some(this.authorities, (authority: string) => tenantEditAuthorities.has(authority));
  }

  hasTenantLevelNotifications() {
    return true;
  }

  hasShipmentLevelNotifications() {
    return true;
  }

  hasTruckloadMode() {
    return some(this.authorities, (authority: string) => UI_AUTHORITIES.TL === authority);
  }

  hasLtlMode() {
    return some(this.authorities, (authority: string) => UI_AUTHORITIES.LTL === authority);
  }

  hasOceanMode() {
    return some(this.authorities, (authority: string) => UI_AUTHORITIES.OCEAN === authority);
  }

  hasParcelMode() {
    return some(this.authorities, (authority: string) => UI_AUTHORITIES.PARCEL === authority);
  }

  hasAirMode() {
    return some(this.authorities, (authority: string) => UI_AUTHORITIES.AIR === authority);
  }

  hasRailMode() {
    return some(
      this.authorities,
      (authority: string) => UI_AUTHORITIES.RAIL === authority && get(window, 'portalGlobal.railEnabled', false)
    );
  }

  hasPortIntel() {
    return this.authorities.indexOf('VIEW_PORT_INTEL') > -1;
  }

  hasTruckloadAnalyticsAuthorization(principal: any) {
    const { featureFlags } = principal || {};
    return Boolean(
      this.authorities.indexOf('read:truckload_analytics') > -1 &&
        featureFlags?.truckloadAnalyticsFeatureSE541 &&
        this.hasTenantCarrierRole(principal)
    );
  }

  hasTLTrackingAuthorization(principal: any) {
    const { featureFlags } = principal || {};
    return Boolean(
      this.authorities.indexOf('VIEW_TL_TRACKING_REPORT') > -1 &&
        (this.hasTenantShipperRole(principal) || featureFlags?.['TL-RCA-Carriers'])
    );
  }

  hasOceanSustainabilityAuthorization() {
    return this.authorities.indexOf('VIEW_OCEAN_SUSTAINABILITY') > -1;
  }

  hasEditShipment() {
    return some(this.authorities, (authority: string) => tenantAdminAuthority === authority);
  }

  hasPlanning() {
    return some(this.authorities, (authority: string) => planningAuthorities.has(authority));
  }

  hasAnalytics(principal: Principal | undefined = undefined) {
    if (principal) {
      if (this.hasTenantCarrierRole(principal) && this.isEUEnvironment()) {
        return false;
      }
    }
    return this.authorities.indexOf('VIEW_REPORTS') > -1;
  }

  hasOceanAnalytics() {
    return this.authorities.indexOf('VIEW_OCEAN_REPORTS') > -1;
  }

  hasOceanInsightsCTT() {
    return this.authorities.indexOf('VIEW_OCEAN_INSIGHTS_CTT') > -1;
  }

  hasOceanInsightsCSS() {
    return this.authorities.indexOf('VIEW_OCEAN_INSIGHTS_CSS') > -1;
  }

  hasLastMileNavigation() {
    return get(this, 'customerProperties.NAVIGATION_CONVEY', lastMileNavigationSetting) === lastMileNavigationSetting;
  }

  hasLastMileConveyByProject44Navigation() {
    return (
      get(this, 'customerProperties.NAVIGATION_CONVEY', lastMileNavigationSetting) ===
      lastMileConveyByProject44NavigationSetting
    );
  }

  hasClearMetalNavigation() {
    return get(this, 'customerProperties.NAVIGATION_CLEAR_METAL', '') === clearMetalNavigationSetting;
  }

  hasMindlogicNavigation() {
    return get(this, 'customerProperties.NAVIGATION_MINDLOGIC', '') === mindLogicNavigationSetting;
  }

  hasMindlogicNavigationForStarbucks() {
    return get(this, 'customerProperties.NAVIGATION_MINDLOGIC', '') === mindLogicStarbucksNavigationSetting;
  }

  hasSlotBooking() {
    return this.authorities.indexOf('SLOT_BOOKING_SUPPLYSTACK') > -1;
  }

  hasEquipmentIdentifiersEditing() {
    return get(window, 'portalGlobal.equipmentIdentifiersEditingEnabled', false);
  }

  hasOceanInsightsNavigationNames() {
    return get(window, 'portalGlobal.oceanInsightsNavigationNames', false);
  }

  getAuthorizedModes() {
    return this.authorities
      .filter((authority: string) => MODE_AUTHORITY_TO_MODE_MAP.has(authority))
      .filter(
        (authority: string) =>
          authority !== UI_AUTHORITIES.RAIL ||
          (authority === UI_AUTHORITIES.RAIL && get(window, 'portalGlobal.railEnabled', false))
      )
      .map((authority: string) => MODE_AUTHORITY_TO_MODE_MAP.get(authority));
  }

  hasMultiFieldSearchAuthorization() {
    return get(this, 'customerProperties.ENABLE_MULTI_VALUE_SEARCH_IN_VOC', false);
  }

  hasMovementPreviewAuthorization() {
    return get(this, 'customerProperties.ENABLE_MOVEMENT_SELF_PREVIEW', false);
  }

  hasOrderVisibilityAuthorization() {
    return this.authorities.indexOf('ORDER_VISIBILITY_UI') > -1;
  }

  hasAdvancedTrackingLinking(mode?: string) {
    if (!this.hasOrderVisibilityAuthorization()) {
      return false;
    }
    if (mode === undefined) {
      for (const eachMode of Object.values(ShipmentModeEnum)) {
        if (this.hasAdvancedTrackingLinkingByMode(eachMode)) return true;
      }
      return false;
    } else return this.hasAdvancedTrackingLinkingByMode(mode);
  }

  hasAdvancedTrackingLinkingForShipment() {
    const authorizedModes = this.getAuthorizedModes();
    return authorizedModes.length > 0;
  }

  hasAdvancedTrackingLinkingByMode(mode: string) {
    return get(this, `customerProperties.DISPLAY_${mode}_MODE_AGNOSTIC_ORDER_INVENTORY_TABLES`, false);
  }

  hasObsoleteLineItems() {
    return get(this, `customerProperties.DISPLAY_TL_LINE_ITEM_DETAILS_TABLES`, false);
  }

  hasNetworkLink() {
    return get(this, 'customerProperties.SHOW_NMC_LINK_IN_VOC', false);
  }

  displayStopContactName() {
    return get(this, 'customerProperties.DISPLAY_ALL_STOPS_CONTACT_NAME_IN_SHIPMENT_DETAIL', true);
  }

  hasEnableCustomerEmbeddedFeatures() {
    return get(this, 'customerProperties.ENABLE_CUSTOMER_EMBEDDED_FEATURES', false);
  }

  hasEnableOrderInventoryV2() {
    return get(this, 'customerProperties.ENABLE_ORDER_INVENTORY_V2_TABLES', false);
  }

  hasShipmentStatusBadgeHidden() {
    return get(this, 'customerProperties.TL_HIDE_SHIPMENT_STATUS_BADGE', false);
  }

  hasTenantCarrierRole(principal: any) {
    return principal?.tenantCarrierNetworkRoles?.includes(CarrierNetworkRoles.CARRIER);
  }

  hasTenantShipperRole(principal: any) {
    return principal?.tenantCarrierNetworkRoles?.includes(CarrierNetworkRoles.SHIPPER);
  }

  hasLocationCreateAccess() {
    return this.authorities.indexOf('create:stop-locations') > -1;
  }

  hasLocationUpdateAccess() {
    return this.authorities.indexOf('update:stop-locations') > -1;
  }

  hasLocationDeleteAccess() {
    return this.authorities.indexOf('delete:stop-locations') > -1;
  }

  hasLocationReadAccess() {
    return this.hasLocationCreateAccess() || this.hasLocationUpdateAccess() || this.hasLocationDeleteAccess();
  }

  hasNetworkHealthDashboardAccess(principal: any) {
    return (
      (this.hasTenantShipperRole(principal) || this.hasTenantCarrierRole(principal)) &&
      Boolean(
        principal?.authorities?.some((eachAuthority: any) =>
          ['read:network-health-tl-dashboards'].includes(eachAuthority?.authority)
        )
      )
    );
  }

  isEUEnvironment() {
    return get(window, 'portalGlobal.isEu', false) as boolean;
  }

  tlRatingLinkIsVisible() {
    return (
      get(window, 'portalGlobal.tlRatingLinkIsVisible', false) &&
      get(this, 'customerProperties.TRUCKLOAD_ENABLE_RATINGS_VIEW', true)
    );
  }

  hasChatEnabled() {
    return get(this, `customerProperties.HAS_CHAT_FEATURE_ENABLED`, false);
  }

  hasCarrierFleetViewAuthorization() {
    return this.authorities.indexOf('FLEET_VIEW_UI') > -1;
  }

  shipmentListFiltersHorizontalEnabled() {
    return (
      get(this, 'customerProperties.TEST_SHIPMENT_LIST_FILTERS_HORIZONTAL_ENABLED', false) ||
      get(window, 'portalGlobal.shipmentListFiltersHorizontalEnabled', false)
    );
  }

  shipmentDetailWeatherMapEnabled() {
    return get(this, 'customerProperties.ENABLE_WEATHER_ON_MAPS', false);
  }

  quickviewV2Enabled() {
    return (
      get(this, 'customerProperties.QUICKVIEW_V2_ENABLED', false) ||
      get(window, 'portalGlobal.quickviewV2Enabled', false)
    );
  }

  hasUseAuthenticatedUrlInShare() {
    return get(this, `customerProperties.USE_AUTHENTICATED_URL_IN_SHARE`, false);
  }

  hasCarrierProcurement() {
    return (
      this.authorities.indexOf('read:carrier_procurement_analytics') > -1 ||
      this.authorities.indexOf('read:carrier_procurement') > -1
    );
  }

  hasEnableLocationSearchByContact() {
    return get(this, `customerProperties.ENABLE_LOCATION_SEARCH_BY_CONTACT`, false);
  }

  hasDocumentUploadEnabled() {
    return get(this, 'customerProperties.ENABLE_MANUAL_DOCUMENT_UPLOAD', false);
  }

  localtionContactFilterTypeAheadEnabled() {
    return (
      get(this, 'customerProperties.LOCATION_CONTACT_TYPEAHEAD_FILTER_ENABLED', false) ||
      get(window, 'portalGlobal.locationContactTypeaheadFilterEnabled', false)
    );
  }

  renderEarlyShipmentsAsOnTimeTL() {
    return get(this, `customerProperties.RENDER_EARLY_SHIPMENTS_AS_ONTIME_TL`, false);
  }

  renderSharedTenantFilters() {
    return get(this, 'customerProperties.ENABLE_SHARED_TO_AND_BY_FILTERS', false);
  }

  renderScheduledTruckChange() {
    return get(this, 'customerProperties.ENABLE_SCHEDULED_TRUCK_CHANGES', true);
  }

  isLateStatusBasedOnScheduledArrival() {
    return get(this, 'customerProperties.VOC_TL_SET_LATE_STATUS_BASED_ON_SCHEDULED_ARRIVAL', false);
  }
}

export const getPrincipalFromState = (state: RootState) =>
  !isEmpty(get(state, 'authReducer.principal'))
    ? state.authReducer.principal
    : get(state, 'loginReducer.principal', null);
export const getPrincipalAuthoritiesFromState = (state: RootState) =>
  get(getPrincipalFromState(state), 'authorities') || [];
export const getCustomerPropertiesFromState = (state: RootState) =>
  get(getPrincipalFromState(state), 'customerProperties') || [];
export const getTenantIdFromState = (state: RootState) => get(getPrincipalFromState(state), 'tenantId') || '';

export const getPrincipalAuthorizations = (authorizationObj: any) => new PrincipalAuthorizations(authorizationObj);

export const getPrincipalAuthorizationsFromState = (state: RootState) =>
  getPrincipalAuthorizations(getPrincipalFromState(state));

export const getPrincipalAuthorizationsFromAuthorities = (authorities: any[]) =>
  getPrincipalAuthorizations({ authorities });

export const getPrincipalAuthorizationsFromPrincipalContext = (principal: PrincipalContextProps) =>
  new PrincipalAuthorizations({
    authorities: get(principal, 'authorities') || [],
    principal,
    customerProperties: get(principal, 'customerProperties'),
  });

export const shouldUseRecentSSS = get(window, 'portalGlobal.newShipmentSearchServiceEnabled', false);
