import { useState } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { validateNonEmptyList } from 'common/FormValidations';

import { renderApiErrors } from 'components/common/forms/FormErrors';
import { SubHeader, Button } from 'ui-components';
import CustomButton from 'themes/CustomButton';
import { trackEvent } from 'common/eventTracker';
import AddEditNotificationForm from './AddEditNotificationForm';
import { createAccessLevelObject } from '../NotificationViewUtil';
import * as constants from '../ducks/constants';
import { TLEventTypeKeyConstants } from '../ducks/constants';

import './AddEditNotificationComponent.scss';
import { withTheme } from '../../../contexts/ThemeContext';

const notificationSaveErrorHeading = 'save this notification';

let AddEditNotificationComponent = (props) => {
  const isNewNotification = props.match.params.notificationId === 'new';
  const title = isNewNotification ? (
    <FormattedMessage
      id="notifications.addEditNotificationComponent.addEditNotificationTitle"
      defaultMessage="Create New Notification"
    />
  ) : (
    <FormattedMessage
      id="notifications.addEditNotificationComponent.addEditNotification"
      defaultMessage="Edit Notification"
    />
  );
  const [filterValidationError, setFilterValidationError] = useState();

  const validateFormAndSubmit = () => {
    trackEvent('NOTIFICATION_SAVE_CLICK', {
      shipmentId: props.masterShipmentId || props.shipmentId,
      mode: props.mode,
    });
    const isPendingTrackingMethodNotification = props.formValues.notificationEvents
      .map((notifEvent) => notifEvent.eventTypeKey)
      .includes(TLEventTypeKeyConstants.ShipmentPendingTrackingMethod);

    if (isPendingTrackingMethodNotification) {
      const carrierFilter = get(props, 'formValues.filters.fields', []).filter(
        (filterField) => filterField && filterField.type === constants.EventNotificationTargetTypeConstantKeys.Carrier
      );
      if (!carrierFilter.length || !carrierFilter[0].values?.length) {
        setFilterValidationError({
          id: 'notifications.addEditNotificationForm.filterValidationErrors.carrierRequired',
          defaultMessage: 'Carrier required for Shipment Pending Tracking Method notification',
        });
        return;
      } else if (carrierFilter.length > 1 || carrierFilter[0].values?.length > 1) {
        setFilterValidationError({
          id: 'notifications.addEditNotificationForm.filterValidationErrors.noMoreThanOneCarrier',
          defaultMessage: 'No more than one Carrier for Shipment Pending Tracking Method notification',
        });
        return;
      }
    }

    if (validateNonEmptyList(props.formValues.notificationRecipients)) {
      setFilterValidationError({
        id: 'notifications.addEditNotificationForm.filterValidationErrors.recipientRequired',
        defaultMessage: 'Please be sure all your recipients are entered with the correct format.',
      });
      return;
    }

    setFilterValidationError(undefined);
    props.dispatch(submit('AddEditNotificationForm'));
  };

  return (
    <main className="AddEditNotificationComponent">
      <SubHeader>
        <div className="d-flex w-100 h-100">
          <div>
            <h1>{title}</h1>
            {isNewNotification && props.accessLevel === constants.ShipmentAccessLevelConstants.Tenant && (
              <span className="sub-header-label w-100">
                <FormattedMessage
                  id="notifications.addEditNotificationComponent.addEditNotificationShipments"
                  defaultMessage="Manage default notifications for shipments in your account."
                />
              </span>
            )}
            {isNewNotification && props.accessLevel === constants.ShipmentAccessLevelConstants.Shipment && (
              <span className="sub-header-label w-100">
                <FormattedMessage
                  id="notifications.addEditNotificationComponent.addEditNotificationThisShipment"
                  defaultMessage="Manage notifications for this shipment.3"
                />
              </span>
            )}
          </div>

          <div className="d-flex justify-content-end align-items-center flex-grow-1">
            <div className="d-flex justify-content-end align-items-center mr-3">
              <Button type="secondary" clickFn={props.history.goBack}>
                <FormattedMessage
                  id="notifications.addEditNotificationComponent.addEditNotificationCancelButton"
                  defaultMessage="CANCEL"
                />
              </Button>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <Button type="primary" theme={props.theme} clickFn={validateFormAndSubmit}>
                <FormattedMessage
                  id="notifications.addEditNotificationComponent.addEditNotificationSaveButton"
                  defaultMessage="SAVE NOTIFICATION"
                />
              </Button>
            </div>

            {!isNewNotification && (
              <CustomButton
                classes="dangerous medium ml-3"
                data-locator="delete-notification-button"
                clickFn={() =>
                  props.deleteNotification(
                    props.match.params.notificationId,
                    createAccessLevelObject(props.accessLevel, props.shipmentId, props.masterShipmentId, props.mode)
                  )
                }
              >
                <FormattedMessage
                  id="notifications.addEditNotificationForm.addEditNotificationDelete"
                  defaultMessage="Delete"
                />
              </CustomButton>
            )}
          </div>
        </div>
      </SubHeader>
      <section className="container section-content">
        <div className="add-edit-notification">
          {renderApiErrors(props.errors, notificationSaveErrorHeading)}
          <AddEditNotificationForm
            accessLevel={props.accessLevel}
            shipmentId={props.shipmentId}
            masterShipmentId={props.masterShipmentId}
            mode={props.mode}
            isNewNotification={isNewNotification}
            notificationId={props.match.params.notificationId}
            deleteNotification={props.deleteNotification}
            initialValues={props.initialValues}
            formValues={props.formValues}
            queryAutocompleteOptions={props.queryAutocompleteOptions}
            dispatch={props.dispatch}
            locationSuggestions={props.locationSuggestions}
            carrierSuggestions={props.carrierSuggestions}
            companySuggestions={props.companySuggestions}
            subscriberSuggestions={props.subscriberSuggestions}
            onCancel={props.history.goBack}
            onSubmit={() =>
              props.saveNotification(
                props.formValues,
                createAccessLevelObject(props.accessLevel, props.shipmentId, props.masterShipmentId, props.mode),
                props.subscriptionOptions
              )
            }
            validateFormAndSubmit={validateFormAndSubmit}
            newEventType={props.newEventType}
            newShipmentCriteria={props.newShipmentCriteria}
            subscriptionOptions={props.subscriptionOptions}
            modeOptions={props.modeOptions}
            hasTruckloadMode={props.hasTruckloadMode}
            hasLtlMode={props.hasLtlMode}
            hasParcelMode={props.hasParcelMode}
            hasAirMode={props.hasAirMode}
            hasOceanMode={props.hasOceanMode}
            resetForm={props.resetForm}
            filterValidationError={filterValidationError}
          />
        </div>
      </section>
    </main>
  );
};

AddEditNotificationComponent = connect((state) => ({
  initialValues: state.manageNotificationsReducer.notificationReducer.initialValues,
}))(AddEditNotificationComponent);

export default withTheme(AddEditNotificationComponent);
