import { connect } from 'react-redux';
import get from 'lodash/get';
import { getPrincipalAuthorizationsFromState } from 'common/authorizations';

import { RootState } from 'reducers';
import LandingComponent from './LandingComponent';

const mapStateToProps = (state: RootState) => ({
  authorizations: getPrincipalAuthorizationsFromState(state),
  userId: get(state, 'authReducer.principal.id', get(state, 'authReducer.principal.principal.id')),
});

export default connect(mapStateToProps)(LandingComponent);
