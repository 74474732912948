import { FormattedMessage } from 'react-intl';
import BellOutline from '../common/assets/bell-outline-gray.svg';
import './NotificationsBlankState.scss';

const NotificationsBlankState = () => {
  return (
    <div className="notifications__blank-state">
      <h2 className="blank-state__title">
        <FormattedMessage
          id="notifications.notificationsBlankState.noNotificationMessage"
          defaultMessage="You don't have any notifications set up."
        />
      </h2>
      <img className="blank-state__icon" src={BellOutline} alt="Bell" />
      <p className="blank-state__text">
        <FormattedMessage
          id="notifications.notificationsBlankState.addNewNotificationMessage"
          defaultMessage="Add a new notification to manage shipment alerts."
        />
      </p>
    </div>
  );
};

export default NotificationsBlankState;
