import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import types from 'components/shipmentCounts/ducks/types';
import DynamicDateFilter from '../types/DynamicDateFilter';
import StatusFilter from '../types/StatusFilter';
import HappeningTodayFilter from '../types/HappeningTodayFilter';
import DeliveryFilter from '../types/DeliveryFilter';
import LocationFilter from '../types/LocationFilter';
import TenantAttributeFilter from '../types/TenantAttributeFilter';
import ModeFilter from '../types/ModeFilter';
import CompanyFilter from '../types/CompanyFilter';

import { multiMode as multiModeStatusFilterList } from './statusFilters';

const MultiModeFilters = (props) => {
  const { modeFilterOptions, authorizations } = props;
  const filterByValue = (val) => modeFilterOptions.filter((item) => item.value === val);
  const hasTruckloadMode = filterByValue('TL').length > 0;
  const hasLtlMode = filterByValue('LTL').length > 0;
  const showCompanyFilter = authorizations.hasEnableCustomerEmbeddedFeatures() && hasTruckloadMode && hasLtlMode;

  return (
    <>
      <Row className="mb-4 d-lg-none">
        <ModeFilter {...props} />
      </Row>
      <Row className="mb-4">
        <StatusFilter {...props} menuItems={multiModeStatusFilterList} />
      </Row>
      <Row className="mb-4">
        <HappeningTodayFilter
          {...props}
          toolTipContent={
            <FormattedMessage
              id="shipmentsInTransit.tooltip.all"
              defaultMessage="The shipment is either at a planned stop or moving towards a planned destination along the route"
            />
          }
        />
      </Row>
      <Row className="mb-4">
        {props.quickView ? (
          <Col>
            <DynamicDateFilter dateType="deliveryDate" type={types.QUICK_VIEW_CHANGE_DELIVERY_DATE} />
          </Col>
        ) : (
          <DeliveryFilter {...props} />
        )}
      </Row>
      <Row className="mb-4">
        <LocationFilter {...props} />
      </Row>
      {showCompanyFilter ? (
        <Row className="mb-4">
          <CompanyFilter {...props} />
        </Row>
      ) : null}
      {props.authorizations.hasTenantCarrierRole(props.principal) ? null : (
        <Row className="mb-4">
          <TenantAttributeFilter {...props} />
        </Row>
      )}
    </>
  );
};

export default MultiModeFilters;
