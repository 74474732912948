import Checkbox from 'material-ui/Checkbox';
import DatePicker from 'material-ui/DatePicker';
import SelectField from 'material-ui/DropDownMenu';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { FormattedMessage } from 'react-intl';

import chevronDown from '../assets/chevron-down.svg';
import chevronUp from '../assets/chevron-up.svg';

/**
 * Checks that the input value exists and is a valid date.
 * @param input
 * @returns {boolean}
 */
const isValidDate = (input) => {
  return input.value && input.value !== 'Invalid Date' && input.value !== '';
};

/**
 *  Returns date when an date object is received, otherwise a string in format dd/mm/yyyy is parsed and returned as a date object
 *  we translate the date in the format that the Date class can understand it.
 * @param date
 * @returns {Date}
 */
const handleDateTimePrickerDate = (date) => {
  if (typeof date === 'object') {
    return new Date(date);
  } else {
    const dateParts = date.split('/');
    // month is 0-based, that's why we need dataParts[0] - 1
    return new Date(Number(dateParts[2]), dateParts[0] - 1, Number(dateParts[1]));
  }
};

// common form elements - inputs, selects, etc
export const renderInput = ({
  input,
  label,
  placeholder,
  formName,
  type,
  classes,
  meta: { touched, error },
  ...customProps
}) => {
  const name = formName ? `${formName}.${input.name}` : input.name;
  return (
    <div className={classNames('form-group', classes)}>
      <label>{label}</label>
      <input
        name={name}
        {...omit(customProps, 'intl')}
        {...input}
        placeholder={placeholder}
        type={type}
        className={`form-control ${touched && error ? 'is-invalid' : ''}`}
      />
      {touched && error && error.id && (
        <div className="invalid-feedback">
          <FormattedMessage id={error.id} defaultMessage={error.defaultMessage} />
        </div>
      )}
      {touched && error && typeof error === 'string' && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export const renderSingleSelectDropdown = ({
  input,
  label,
  placeholder,
  onBlur,
  classes,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <div className={`form-group select-dropdown ${classes} ${touched && error ? 'error' : ''}`}>
    <label>{label}</label>
    <MuiThemeProvider>
      <SelectField
        {...input}
        onChange={(event, index, value) => input.onChange(value)} //eslint-disable-line react/jsx-no-bind
        //eslint-disable-next-line react/jsx-no-bind
        onBlur={(event) => {
          //eslint-disable-line react/jsx-no-bind
          if (onBlur) {
            onBlur(event);
          }
        }}
        labelStyle={{
          fontSize: '12px',
          border: touched && error ? '1px solid #f04e37' : '1px solid #d5d4d4',
          color: '#575451',
          fontWeight: 500,
          lineHeight: '36px',
          height: '34px',
          paddingLeft: '8px',
          paddingRight: 0,
        }}
        style={{
          display: 'block',
          height: 'auto',
        }}
        children={children} //eslint-disable-line react/no-children-prop
        autoWidth={false}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        iconStyle={{
          top: 0,
          right: 0,
          height: '33px',
          width: '33px',
          background: 'transparent',
          fill: '#575451',
          padding: '7px 0px',
        }}
        underlineStyle={{ display: 'none' }}
        menuStyle={{ width: '100%' }}
        {...custom}
      />
    </MuiThemeProvider>
    <div className="select-placeholder">{placeholder}</div>
    {touched && error && error.id && (
      <span className="invalid-feedback">
        <FormattedMessage id={error.id} defaultMessage={error.defaultMessage} />
      </span>
    )}
    {touched && error && typeof error === 'string' && <div className="invalid-feedback">{error}</div>}
  </div>
);

export const renderDatePicker = ({ input, label, placeholder, meta: { touched, error } }) => (
  <div className={`form-group datepicker-group col ${touched && error ? 'error' : ''}`}>
    <label>{label}</label>
    <DatePicker
      {...input}
      onChange={(event, date) => input.onChange(date)} //eslint-disable-line react/jsx-no-bind
      placeholder={placeholder}
      mode="landscape"
      autoOk
      style={{
        color: 'white',
        display: 'flex',
        textAlign: 'center',
      }}
      textFieldStyle={{
        backgroundColor: 'var(--white)',
        border: touched && error ? '1px solid #f04e37' : '1px solid #d5d4d4',
        height: 34,
        width: '100%',
        marginRight: '7px',
        display: 'flex',
        color: '#aba9a8',
        fontWeight: 500,
        fontSize: '12px',
      }}
      //eslint-disable-next-line react/jsx-no-bind
      formatDate={(dateToFormat) => {
        //eslint-disable-line react/jsx-no-bind
        return dateToFormat.toLocaleDateString();
      }}
      value={(isValidDate(input) && handleDateTimePrickerDate(input.value)) || null}
    />
    {touched && error && error.id && (
      <span className="invalid-feedback">
        <FormattedMessage id={error.id} defaultMessage={error.defaultMessage} />
      </span>
    )}
    {touched && error && typeof error === 'string' && <div className="invalid-feedback">{error}</div>}
  </div>
);

export const renderCheckBox = ({ input, label, custom }) => {
  return (
    <div className="form-group checkbox-group">
      <MuiThemeProvider>
        <Checkbox
          {...input}
          style={{
            color: custom.primaryColor ? custom.primaryColor : '#1296b0',
            fontWeight: 300,
            display: 'flex',
          }}
          labelStyle={{ color: '#575451', fontSize: '15px' }}
          iconStyle={{
            fill: custom.primaryColor ? custom.primaryColor : '#1296b0',
            margin: '1px 4px 0 0',
            width: '21px',
            height: '21px',
          }}
          inputStyle={{ marginRight: 0 }}
          label={label}
          checked={input.checked}
          onCheck={(event, value) => input.onChange(value)} //eslint-disable-line react/jsx-no-bind
        />
      </MuiThemeProvider>
    </div>
  );
};

export const renderTemperatureSpinner = ({ input, label, scale, min, max, spinnerClass, meta: { touched, error } }) => {
  const { value } = input;

  const validateMinMax = () => {
    if (parseInt(value) > max) {
      // eslint-disable-line radix
      input.onChange(max);
    }
    if (parseInt(value) < min) {
      // eslint-disable-line radix
      input.onChange(min);
    }
  };

  return (
    <div className={`form-group temperature-control ${touched && error ? 'error' : ''}`}>
      <div className="d-flex flex-column">
        <label>{label}</label>
        <div className="d-flex">
          <div className="spinner">
            {/* eslint-disable-next-line */}
            <img
              src={chevronUp}
              alt="up"
              onClick={() => {
                if (value !== '') {
                  input.onChange(parseInt(value) + 1); // eslint-disable-line radix
                } else {
                  input.onChange(0);
                }
              }}
            />

            <input
              {...input}
              type="number"
              min={min}
              max={max}
              pattern="[0-9]*"
              onInput={(event) => validateMinMax(event)} //eslint-disable-line react/jsx-no-bind
              onBlur={(event) => validateMinMax(event)} //eslint-disable-line react/jsx-no-bind
              className={spinnerClass}
            />
            {/* eslint-disable-next-line */}
            <img
              src={chevronDown}
              alt="down"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => {
                //eslint-disable-line react/jsx-no-bind
                if (value !== '') {
                  input.onChange(parseInt(value) - 1); // eslint-disable-line radix
                } else {
                  input.onChange(0);
                }
              }}
            />
          </div>
          <div className="spinner-right">
            <label className={`right-side-label ${spinnerClass}`}>{scale}&deg;</label>
          </div>
        </div>
      </div>
      {touched && error && error.id && (
        <span className="invalid-feedback">
          <FormattedMessage id={error.id} defaultMessage={error.defaultMessage} />
        </span>
      )}
      {touched && error && typeof error === 'string' && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};
