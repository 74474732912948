import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { TenantAttribute, ShipmentTenantAttribute, ShipmentTenantAttributeRequest } from '../../models/TenantAttribute';

export enum Types {
  FETCH_TENANT_ATTRIBUTES = 'TENANT_ATTRIBUTES/FETCH_TENANT_ATTRIBUTES',
  FETCH_TENANT_ATTRIBUTES_SUCCESS = 'TENANT_ATTRIBUTES/FETCH_TENANT_ATTRIBUTES_SUCCESS',
  FETCH_TENANT_ATTRIBUTES_FAILURE = 'TENANT_ATTRIBUTES/FETCH_TENANT_ATTRIBUTES_FAILURE',
  CREATE_TENANT_ATTRIBUTES = 'TENANT_ATTRIBUTES/CREATE_TENANT_ATTRIBUTES',
  CREATE_TENANT_ATTRIBUTES_SUCCESS = 'TENANT_ATTRIBUTES/CREATE_TENANT_ATTRIBUTES_SUCCESS',
  CREATE_TENANT_ATTRIBUTES_FAILURE = 'TENANT_ATTRIBUTES/CREATE_TENANT_ATTRIBUTES_FAILURE',
  FETCH_SHIPMENT_TENANT_ATTRIBUTES = 'TENANT_ATTRIBUTES/FETCH_SHIPMENT_TENANT_ATTRIBUTES',
  FETCH_SHIPMENT_TENANT_ATTRIBUTES_SUCCESS = 'TENANT_ATTRIBUTES/FETCH_SHIPMENT_TENANT_ATTRIBUTES_SUCCESS',
  FETCH_SHIPMENT_TENANT_ATTRIBUTES_FAILURE = 'TENANT_ATTRIBUTES/FETCH_SHIPMENT_TENANT_ATTRIBUTES_FAILURE',
  CREATE_OR_UPDATE_SHIPMENT_TENANT_ATTRIBUTES = 'TENANT_ATTRIBUTES/CREATE_OR_UPDATE_SHIPMENT_TENANT_ATTRIBUTES',
  CREATE_OR_UPDATE_SHIPMENT_TENANT_ATTRIBUTES_SUCCESS = 'TENANT_ATTRIBUTES/CREATE_OR_UPDATE_SHIPMENT_TENANT_ATTRIBUTES_SUCCESS',
  CREATE_OR_UPDATE_SHIPMENT_TENANT_ATTRIBUTES_FAILURE = 'TENANT_ATTRIBUTES/CREATE_OR_UPDATE_SHIPMENT_TENANT_ATTRIBUTES_FAILURE',
  RESET = 'TENANT_ATTRIBUTES/RESET',
  TOGGLE_SHIPMENT_TENANT_ATTRIBUTES_MODE = 'TENANT_ATTRIBUTES/TOGGLE_SHIPMENT_TENANT_ATTRIBUTES_MODE',
}

export const fetchTenantAttributes = createAsyncAction(
  Types.FETCH_TENANT_ATTRIBUTES,
  Types.FETCH_TENANT_ATTRIBUTES_SUCCESS,
  Types.FETCH_TENANT_ATTRIBUTES_FAILURE
)<void, TenantAttribute[], Error>();

export const createTenantAttributes = createAsyncAction(
  Types.CREATE_TENANT_ATTRIBUTES,
  Types.CREATE_TENANT_ATTRIBUTES_SUCCESS,
  Types.CREATE_TENANT_ATTRIBUTES_FAILURE
)<TenantAttribute[], TenantAttribute[], Error>();

export const fetchShipmentTenantAttributes = createAsyncAction(
  Types.FETCH_SHIPMENT_TENANT_ATTRIBUTES,
  Types.FETCH_SHIPMENT_TENANT_ATTRIBUTES_SUCCESS,
  Types.FETCH_SHIPMENT_TENANT_ATTRIBUTES_FAILURE
)<{ shipmentId?: string; masterShipmentId?: string }, ShipmentTenantAttribute[], Error>();

export const createOrUpdateShipmentTenantAttributes = createAsyncAction(
  Types.CREATE_OR_UPDATE_SHIPMENT_TENANT_ATTRIBUTES,
  Types.CREATE_OR_UPDATE_SHIPMENT_TENANT_ATTRIBUTES_SUCCESS,
  Types.CREATE_OR_UPDATE_SHIPMENT_TENANT_ATTRIBUTES_FAILURE
)<
  {
    shipmentId: string;
    masterShipmentId: string | undefined;
    shipmentTenantAttributes: ShipmentTenantAttributeRequest[];
  },
  ShipmentTenantAttribute[],
  Error
>();

export const reset = createStandardAction(Types.RESET)<void>();
export const toggleTenantAttributesMode = createStandardAction(Types.TOGGLE_SHIPMENT_TENANT_ATTRIBUTES_MODE)<void>();
