import get from 'lodash/get';
import { SupportRequestDTO, SupportRequestTypeEnum } from 'components/onboarding/hooks/useSupportTicket';
import { sessionRecordSupportRequest, sessionExistsSupportRequest } from '../../utils/sessionHelpers';
import { BusinessTypeEnum, FormValueTypes, SupportTicketTagEnum, CompanyEvergreenModel } from '../../models';
import { ticketsMap } from './ticketsMap';

export enum SupportTicketFieldsEnum {
  DIRECT_CONNECTION_CAPABLE = 'Direct Connection Capable?',
  TELEMATICS_CONTACT_NAME = 'New ELD/Telematics Contact Name',
  TELEMATICS_PROVIDER_NAME = 'Telematics Provider Name',
  NEW_TELEMATICS_SUBMITTED = 'New ELD/Telematics Submitted?',
  CARRIER_LOCALE = 'carrierLocale',
}

interface SupportTicketDataPrams {
  ticketTag: SupportTicketTagEnum;
  allFields: FormValueTypes;
  isEuHost: boolean;
  inviteId: string | undefined;
  companyEvergreenData: CompanyEvergreenModel | undefined;
}

export const rememberSupportTicket = (ticketTag: SupportTicketTagEnum, inviteId: string | undefined) => {
  sessionRecordSupportRequest(ticketTag, inviteId);
};

export const createdSupportTicket = (ticketTag: SupportTicketTagEnum, inviteId: string | undefined) => {
  return sessionExistsSupportRequest(ticketTag, inviteId);
};

const validateFieldValues = (allFields: FormValueTypes) => {
  return (
    get(allFields, 'firstName') &&
    get(allFields, 'lastName') &&
    get(allFields, 'emailAddress') &&
    get(allFields, 'companyName') &&
    get(allFields, 'companyUid')
  );
};

export const getSupportTicketData = ({
  ticketTag,
  allFields,
  isEuHost,
  inviteId,
  companyEvergreenData,
}: SupportTicketDataPrams): SupportRequestDTO | undefined => {
  if (!validateFieldValues(allFields)) {
    return undefined;
  }

  // ignore ticket creating if the same ticket was already created
  if (createdSupportTicket(ticketTag, inviteId)) {
    return undefined;
  }

  // remembers ticket by ticketTag for checking if created already
  rememberSupportTicket(ticketTag, inviteId);

  const ticketCommonData: Partial<SupportRequestDTO> = {
    companyName: get(allFields, 'companyName', ''),
    companyContactEmail: get(allFields, 'emailAddress', ''),
    companyContactPerson: `${get(allFields, 'firstName', '')} ${get(allFields, 'lastName', '')}`,
    companyUid: get(allFields, 'companyUid', ''),
    countryCode: get(allFields, 'companyCountry', ''),
    carrierId: get(allFields, 'companyTenantId'),
    companyContactPhone: `${get(allFields, 'countryCode')} ${get(allFields, 'phoneNumber')}`,
    telematicsVendorName: get(allFields, 'providerName', ''),
    telematicsContactEmail: get(allFields, 'providerContactEmail', ''),
    customFields: {
      [SupportTicketFieldsEnum.DIRECT_CONNECTION_CAPABLE]: allFields.businessType?.includes(BusinessTypeEnum.OWN_FLEET)
        ? 'direct_connection_capable_yes'
        : '',
      [SupportTicketFieldsEnum.NEW_TELEMATICS_SUBMITTED]:
        ticketTag === SupportTicketTagEnum.ONBOARDING_NEW_TELEMATICS ? 'new_eld_telematics_yes' : '',
      [SupportTicketFieldsEnum.TELEMATICS_CONTACT_NAME]: get(allFields, 'providerContactName', ''),
      [SupportTicketFieldsEnum.TELEMATICS_PROVIDER_NAME]: get(allFields, 'providerName', ''),
      [SupportTicketFieldsEnum.CARRIER_LOCALE]: get(allFields, 'preferredLocale', ''),
    },
    hasSubcontractors: get(allFields, 'subcontractor'),
    fields: {
      invitationToken: inviteId ? inviteId : '',
      evergreenAlias: companyEvergreenData ? companyEvergreenData.alias : '',
    },
    companyEvergreenData: companyEvergreenData,
  };

  const commonDataUS = {
    dotNumber: get(allFields, 'dotnumber'),
    scac: get(allFields, 'companySCAC'),
    // transforming mcNumber to string due to TDX restrictions
    mcNumber: get(allFields, 'companyMcNumbers')?.length ? get(allFields, 'companyMcNumbers')?.join(',') : null,
  };
  const commonDataEU = {
    type: SupportRequestTypeEnum.ONBOARDING,
    vatNumber: get(allFields, 'vatnumber'),
  };

  const newTicketRequest = ticketsMap[ticketTag];

  if (newTicketRequest) {
    return newTicketRequest({
      allFields,
      isEuHost,
      ticketCommonData: isEuHost ? { ...ticketCommonData, ...commonDataEU } : { ...ticketCommonData, ...commonDataUS },
    });
  }
};
