import get from 'lodash/get';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import * as DateUtils from 'common/dateUtils';

export default class EditShipmentMapper {
  static mapEditActionToDetailApiResponse(editAction, rawDetailResponse) {
    const shipmentId = get(editAction, 'payload.shipmentId');
    const stopNumber = get(editAction, 'payload.stopNumber');
    const stopData = get(editAction, 'payload.stopData');
    const currentStops = get(rawDetailResponse, 'shipmentStops');
    const currentShipmentId = get(rawDetailResponse, 'shipmentId');

    if (isNil(shipmentId) || isNil(stopNumber) || isNil(stopData) || isNil(currentStops)) {
      return rawDetailResponse;
    }

    if (shipmentId !== currentShipmentId) {
      return rawDetailResponse;
    }

    let updatedStop = null;
    const updatedDetailResponse = { ...rawDetailResponse };
    updatedDetailResponse.shipmentStops = updatedDetailResponse.shipmentStops.map((stop) => {
      if (stop.stopNumber === stopNumber) {
        updatedStop = cloneDeep(stop);
        return updatedStop;
      }
      return stop;
    });

    if (!updatedStop) {
      return rawDetailResponse;
    }

    mapUpdatedStopDataToDetailStop(stopData, updatedStop);

    return updatedDetailResponse;
  }
}

const mapUpdatedStopDataToDetailStop = (stopData, detailStop) => {
  mapStopNameToDetailStop(stopData, detailStop);

  mapAppointmentWindowToDetailStop(stopData.appointmentWindow, detailStop);

  mapContactInfoToDetailStop(stopData.contact, detailStop);
  mapAddressInfoToDetailStop(stopData.address, detailStop);
};

const mapStopNameToDetailStop = (stopData, detailStop) => {
  const stopName = stopData.stopName;
  detailStop.stopName = stopName;
};

const mapAppointmentWindowToDetailStop = (appointmentWindow, detailStop) => {
  const updatedLocalStartDateTime = get(appointmentWindow, 'startDate');
  const updatedLocalEndDateTime = get(appointmentWindow, 'endDate');
  const timeZone = get(appointmentWindow, 'localTimeZoneIdentifier');

  detailStop.utcAppointmentStartDateTime = DateUtils.formatLocalDateTimeWithTimeZoneChange(
    updatedLocalStartDateTime,
    timeZone,
    'UTC'
  );
  detailStop.utcAppointmentEndDateTime = DateUtils.formatLocalDateTimeWithTimeZoneChange(
    updatedLocalEndDateTime,
    timeZone,
    'UTC'
  );

  detailStop.localAppointmentStartDateTime = updatedLocalStartDateTime;
  detailStop.localAppointmentEndDateTime = updatedLocalEndDateTime;
};

const mapContactInfoToDetailStop = (contactInfo, detailStop) => {
  if (!contactInfo) {
    // eslint-disable-next-line no-param-reassign
    contactInfo = {};
  }
  const path = (name) => `location.contactDto.${name}`;

  set(detailStop, path('companyName'), contactInfo.companyName);
  set(detailStop, path('contactName'), concatWithSpace(contactInfo.firstName, contactInfo.lastName));
  set(detailStop, path('email'), contactInfo.email);
  set(detailStop, path('phoneNumber1'), contactInfo.phoneNumber);
};

const mapAddressInfoToDetailStop = (addressInfo, detailStop) => {
  if (!addressInfo) {
    // eslint-disable-next-line no-param-reassign
    addressInfo = {};
  }
  const path = (name) => `location.address.${name}`;

  set(detailStop, path('addressLines'), addressInfo.addressLines);
  set(detailStop, path('city'), addressInfo.city);
  set(detailStop, path('state'), addressInfo.state);
  set(detailStop, path('cityState'), concatWithSpace(addressInfo.city, addressInfo.state));
  set(detailStop, path('postalCode'), addressInfo.postalCode);
  set(detailStop, path('countryCodeDto'), addressInfo.countryCode);
};

const concatWithSpace = (...args) => {
  return args.filter((part) => !!part).join(' ');
};
