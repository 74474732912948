import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/pl';
import '@formatjs/intl-pluralrules/dist/locale-data/de';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
import '@formatjs/intl-pluralrules/dist/locale-data/it';
import '@formatjs/intl-pluralrules/dist/locale-data/cs';
import '@formatjs/intl-pluralrules/dist/locale-data/hu';
import '@formatjs/intl-pluralrules/dist/locale-data/bg';
import '@formatjs/intl-pluralrules/dist/locale-data/uk';
import '@formatjs/intl-pluralrules/dist/locale-data/ru';
import '@formatjs/intl-pluralrules/dist/locale-data/pt';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/fr';
import 'moment/locale/pl';
import 'moment/locale/tr';
import 'moment/locale/ro';
import 'moment/locale/lt';
import 'moment/locale/cs';
import 'moment/locale/hu';
import 'moment/locale/bg';
import 'moment/locale/uk';
import 'moment/locale/ru';
import 'moment/locale/pt';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';

import allLocales from './locales/allLocales.json';

const supportedLocales = ['en', 'pl', 'de', 'fr', 'it', 'ro', 'lt', 'tr', 'cs', 'hu', 'ru', 'bg', 'pt', 'uk', 'es'];
// react-intl expects a flatten data. It doesn't support nested messages so we are flattening the messages
// It also expects a one giant message so we are concatenating all the data as one object - Mark Serrano
const localeData = Object.assign(
  {},
  { en: allLocales.en },
  { pl: allLocales.pl },
  { it: allLocales.it },
  { de: allLocales.de },
  { fr: allLocales.fr },
  { ro: allLocales.ro },
  { lt: allLocales.lt },
  { cs: allLocales.cs },
  { bg: allLocales.bg },
  { ru: allLocales.ru },
  { hu: allLocales.hu },
  { uk: allLocales.uk },
  { pt: allLocales.pt },
  { es: allLocales['es-es'] }
);

const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
export const locale = supportedLocales.indexOf(languageWithoutRegionCode) > -1 ? language : supportedLocales[0];
export const messages = localeData[languageWithoutRegionCode] || localeData[locale] || localeData.en;

// Set the moment locale for entire app. Formatted dates will be in locale specific language
// If language is English but not one of our supported moment locales, default to English UK to account for EU date formatting
const supportedMomentLocales = ['en', 'en-us', 'en-ca', 'en-gb'];
if (language.includes('en') && !supportedMomentLocales.includes(language.toLowerCase())) {
  moment.locale('en-gb');
} else {
  moment.locale(language);
}

export const MONTH_DAY_YEAR_FORMAT = moment.localeData().longDateFormat('L').replace('YYYY', 'YY'); // e.g. 'MM/DD/YYYY' --> 'MM/DD/YY'
export const HOURS_MINUTES_FORMAT = `${moment.localeData().longDateFormat('LT')}`; // e.g. 'h:mm A'
export const HOURS_MINUTES_TIMEZONE_FORMAT = `${HOURS_MINUTES_FORMAT} z`;
export const MONTH_DAY_YEAR_HOUR_MINUTES_FORMAT = `${MONTH_DAY_YEAR_FORMAT} ${HOURS_MINUTES_FORMAT}`;
export const MONTH_DAY_YEAR_TIME_FORMAT = `${MONTH_DAY_YEAR_FORMAT} ${HOURS_MINUTES_TIMEZONE_FORMAT}`;
export const LONG_MONTH_DAY_YEAR_FORMAT = moment.localeData().longDateFormat('LL'); // e.g. 'MMMM D, YYYY'
export const LONG_MONTH_DAY_YEAR_TIME_FORMAT = `${moment.localeData().longDateFormat('LLL')} z`; // e.g. 'MMMM D, YYYY h:mm A'
export const HOURS_MINUTES_SECONDS_FORMAT = moment.localeData().longDateFormat('LTS'); // e.g. 'h:mm:ss A'

// useEUVerbiage is used to display EU-specific terms e.g. 'Groupage' instead of 'LTL'
const nonEULocales = ['en', 'en-us', 'en-ca'];
export const useEUVerbiage = !nonEULocales.includes(language.toLowerCase());
