import { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { generatePath } from 'react-router';

import SubTotalStep from './SubTotalStep';
import TitleAndSettingsStep from './TitleAndSettingsStep';
import { STEPS, QUICKVIEW_TOTAL_FILTERS, QUICKVIEW_TOTAL_MODE } from './constants';
import { WizardFormProvider } from './context/WizardFormContext';
import { IRouteParams } from './types';
import { getCurrentStepIndex } from './utils';
import StepsHeader from './StepsHeader';
import TotalStep from './TotalStep';

interface IQuickViewConfig {
  config: Array<any>;
  deletedIds: Array<string>;
  layouts: { md: []; sm: []; xs: [] };
  loading: string;
  staticContext: any;
}

interface QuickCardConfigurationsComponentProps {
  getShipmentCounts: () => void;
  quickViewConfig: IQuickViewConfig;
  modeFilterOptions: Array<any>;
  cancelQuickViewDialog: () => void;
  setModeFn: (mode: any) => void;
  clearFilterNoSearch: () => void;
  currentTile: any;
}

const QuickViewCardConfigurationsComponent = ({
  modeFilterOptions,
  quickViewConfig,
  getShipmentCounts,
  cancelQuickViewDialog,
  setModeFn,
  clearFilterNoSearch,
  ...props
}: QuickCardConfigurationsComponentProps) => {
  const { path, params } = useRouteMatch<IRouteParams>();
  const location = useLocation();
  const nextLocation = { ...location, state: { parentPath: path, parentParams: params } };
  const currentStep = getCurrentStepIndex(params.step);
  const [editingTile, setCurrentTile] = useState<any>();

  // TODO update this
  const editingStringifiedTotalFilters = editingTile?.totalFilter && JSON.stringify(editingTile?.totalFilter);
  const editingTotalMode = editingTile?.totalMode !== undefined && editingTile?.totalMode;
  // If we edit a tile store the totalFilters and totalMode to be used on totalStep
  useEffect(() => {
    if (editingStringifiedTotalFilters || editingTotalMode) {
      window.sessionStorage.setItem(QUICKVIEW_TOTAL_FILTERS, editingStringifiedTotalFilters);
      window.sessionStorage.setItem(QUICKVIEW_TOTAL_MODE, editingTotalMode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingTile]);

  useEffect(() => {
    if (params?.tileId) {
      const currentTile = quickViewConfig.config.find(
        (quickViewConfigTile) => quickViewConfigTile.id === Number(params.tileId)
      );
      setCurrentTile(currentTile);
    }
  }, [quickViewConfig.config, params]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {currentStep !== -1 && (
        <StepsHeader currentStep={currentStep} cancelQuickViewDialog={cancelQuickViewDialog} {...props} />
      )}
      <WizardFormProvider defaultValues={editingTile}>
        <Switch location={nextLocation}>
          <Route path={generatePath(path, { step: STEPS[0].id })}>
            <SubTotalStep modeFilterOptions={modeFilterOptions} clearFilterNoSearch={clearFilterNoSearch} {...props} />
          </Route>
          <Route path={generatePath(path, { step: STEPS[1].id })}>
            <TotalStep modeFilterOptions={modeFilterOptions} clearFilterNoSearch={clearFilterNoSearch} {...props} />
          </Route>
          <Route path={generatePath(path, { step: STEPS[2].id })}>
            <TitleAndSettingsStep modeFilterOptions={modeFilterOptions} quickViewConfig={quickViewConfig} {...props} />
          </Route>
          <Route path={path}>
            {/* TODO: The UI/UX for this case gonna be handled in future PRs */}
            <h3>This step does not exists. Valid steps are {STEPS.map((step) => `/${step.id}`).join(', ')}</h3>
          </Route>
        </Switch>
      </WizardFormProvider>
    </>
  );
};

export default QuickViewCardConfigurationsComponent;
