import isNil from 'lodash/isNil';
import { defineMessages, createIntl, createIntlCache } from 'react-intl';
import { locale, messages } from 'i18n/configurei18n';

const cache = createIntlCache();
export const intl = createIntl({ locale, messages }, cache);

const message = defineMessages({
  daysPlural: {
    id: 'landing.optimzeMinutes.daysPlural',
    defaultMessage: '{ daysVal, number } {daysVal, plural, one {day} other {days}}',
  },
  hr: {
    id: 'landing.optimzeMinutes.hr',
    defaultMessage: '{ hoursVal } hr',
  },
  min: {
    id: 'landing.optimzeMinutes.min',
    defaultMessage: '{ minutesVal } min',
  },
  hrAndMin: {
    id: 'landing.optimzeMinutes.hrAndMin',
    defaultMessage: '{ timeStringVal } and {minutesVal} min',
  },
});

const DAY = 1440;
const HOUR = 60;

/* eslint-disable no-param-reassign */
export function optimizeMinutes(minutes) {
  if (!isNil(minutes)) {
    minutes = Math.abs(minutes);
    if (minutes >= DAY) {
      const days = Math.trunc(minutes / DAY);
      return `${intl.formatMessage(message.daysPlural, { daysVal: days })}`;
    } else if (minutes >= HOUR) {
      const hours = Math.trunc(minutes / HOUR);
      minutes -= hours * HOUR;
      let timeString = `${intl.formatMessage(message.hr, { hoursVal: hours })}`;
      if (minutes !== 0) {
        timeString = `${intl.formatMessage(message.hrAndMin, { timeStringVal: timeString, minutesVal: minutes })}`;
      }
      return timeString;
    } else {
      return `${intl.formatMessage(message.min, { minutesVal: minutes })}`;
    }
  }
}

export function optimizeShipmentMinutes(shipment) {
  shipment.estimatedTimingString = optimizeMinutes(shipment.estimatedMinutesLate);
}
