import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { InventoryOrderType } from 'components/Inventory/models';
import CheckList from 'components/common/checkList/CheckList';
import CollapsableItem from 'components/common/ListPageLayout/CollapsableItem';

interface CheckListFilterInput {
  dataKey: string;
  checkedValues: string[];
  setValuesFn: Dispatch<SetStateAction<string[]>>;
}

interface CheckListOption {
  id: string;
  labelText: string;
  checked: boolean;
}

const OrderType = ({ dataKey, checkedValues = [], setValuesFn }: CheckListFilterInput) => {
  const intl = useIntl();

  const checkListData = [
    {
      id: InventoryOrderType.SALES_ORDER,
      labelText: intl.formatMessage({ id: 'select.orderTable.sales', defaultMessage: 'Sales' }),
    },
    {
      id: InventoryOrderType.PURCHASE_ORDER,
      labelText: intl.formatMessage({ id: 'select.orderTable.purchase', defaultMessage: 'Purchase' }),
    },
    {
      id: InventoryOrderType.ADVANCED_SHIPMENT_NOTICE,
      labelText: intl.formatMessage({ id: 'select.orderTable.advance', defaultMessage: 'Advanced Shipment' }),
    },
    {
      id: InventoryOrderType.WAREHOUSE_MOVEMENT_ORDER,
      labelText: intl.formatMessage({
        id: 'select.orderTable.warehouse',
        defaultMessage: 'Warehouse Movement',
      }),
    },
  ].map((option) => ({ ...option, checked: checkedValues.includes(option.id) }));

  const onCheckFn = (option: CheckListOption) => {
    if (option.checked) {
      const newCheckedValues = checkedValues.filter((value) => value !== option.id);
      return setValuesFn(newCheckedValues);
    }

    return setValuesFn([...checkedValues, option.id]);
  };

  const activeKey = isNil(checkedValues) || isEmpty(checkedValues) ? undefined : dataKey;

  return (
    <CollapsableItem
      panelTitle={intl.formatMessage({ id: 'select.orderTable.filters.orderType', defaultMessage: 'Order Type' })}
      dataKey={dataKey}
      activeKey={activeKey}
    >
      <CheckList
        className="px-0"
        mode="dark"
        checkListData={checkListData}
        checkFn={onCheckFn}
        data-locator="ordertype-filter"
      />
    </CollapsableItem>
  );
};

export default OrderType;
