import qs from 'querystring';
import { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import { defineMessages, injectIntl } from 'react-intl';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import first from 'lodash/first';
import split from 'lodash/split';
import styled from 'styled-components';
import { Select } from 'ui-components';
import { FILTER_RESULT_DELAY } from 'common/AppConstants';
import useDebounce from 'hooks/useDebounce';
import { black } from 'styles/colors';

import AutocompleteFilter from '../../common/autocompleteFilter/AutocompleteFilter';
import useAttributeValues from '../util/useAttributeValues';

const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selection-placeholder {
    opacity: 0.9;
  }

  .ant-select-arrow {
    color: ${black};
    top: 30%;
    right: 20px;

    .material-icons {
      transition: transform 0.3s;
      transform-origin: center center;
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      .material-icons {
        transform: rotate(-180deg);
      }
    }
  }
`;

const INITIAL_AMOUNT_VISIBLE_STATUS_ATTRIBUTE = 3;

const messages = defineMessages({
  filterKeysTitle: {
    id: 'quickView.tenantAttributeFilter.filterKeysTitle',
    defaultMessage: 'Reference key',
  },
  filterbyKeys: {
    id: 'quickView.tenantAttributeFilter.filterbyKeys',
    defaultMessage: 'Filter by keys',
  },
  filterValuesTitle: {
    id: 'quickView.tenantAttributeFilter.filterValuesTitle',
    defaultMessage: 'Reference key values',
  },
  filterbyValues: {
    id: 'quickView.tenantAttributeFilter.filterbyValues',
    defaultMessage: 'Filter by values',
  },
});

const getCurrentFilter = (filters, value) => filter(filters, (item) => item.attributeName === value);

const getAttrName = (defaultFilters) => {
  if (isEmpty(defaultFilters)) return undefined;
  const firstFilter = first(defaultFilters);
  const attrName = split(firstFilter, ':', 1);
  return first(attrName);
};

const getAttrId = (filters, attrName) => {
  if (isNil(attrName)) return undefined;
  const filter = getCurrentFilter(filters, attrName);
  const firstFilter = first(filter);
  return get(firstFilter, 'id', '');
};

const TenantAttributeFilter = (props) => {
  const tenantAttributes = get(props, 'tenantAttributes', []);
  const handleAutocompleteUpdate = (filterItem) => props.handleAutocompleteUpdate(filterItem, 'attribute');

  const attrNames = tenantAttributes.map((item) => {
    const attributeName = get(item, 'attributeName');
    return {
      displayValue: attributeName,
      value: attributeName,
    };
  });

  const defaultFilters = get(props, 'filter.attribute', []);

  const [attrName, setAttrName] = useState(undefined);
  const [attrId, setAttrId] = useState(undefined);
  const [currentValue, setCurrentValue] = useState('');

  const defaultAttrName = getAttrName(defaultFilters);
  const defaultAttrId = getAttrId(tenantAttributes, defaultAttrName);

  useEffect(() => {
    setAttrName(defaultAttrName);
    setAttrId(defaultAttrId);
  }, [defaultAttrName, defaultAttrId]);

  const debouncedValue = useDebounce(currentValue, FILTER_RESULT_DELAY);
  const params = qs.stringify({ q: debouncedValue });
  const attrValues = useAttributeValues(attrId, attrName, params);

  const onSelect = async (value) => {
    const currentAttrKey = getCurrentFilter(tenantAttributes, value);
    setAttrName(get(currentAttrKey[0], 'attributeName'));
    setAttrId(get(currentAttrKey[0], 'id'));
  };

  const showValueFilter = !isNil(attrName) || !isEmpty(defaultFilters);

  if (isEmpty(tenantAttributes)) return null;

  return (
    <Col>
      <label htmlFor="attribute-input-key-filter">
        {props.intl.formatMessage(messages.filterKeysTitle).toUpperCase()}
      </label>
      <StyledSelect
        id="attribute-input-key-filter"
        showSearch
        placeholder={props.intl.formatMessage(messages.filterbyKeys)}
        onSelect={onSelect}
        value={attrName}
        filterOption={(input, option) => get(option, 'value').toLowerCase().indexOf(input.toLowerCase()) >= 0}
        dataSource={attrNames}
        suffixIcon={<i className="material-icons">arrow_drop_down</i>}
      />
      <div className="mt-3"></div>
      {showValueFilter && (
        <>
          <label htmlFor="attribute-input-value-filter">
            {props.intl.formatMessage(messages.filterValuesTitle).toUpperCase()}
          </label>
          <AutocompleteFilter
            idProp="attribute-input-value-filter"
            placeholder={props.intl.formatMessage(messages.filterbyValues)}
            dataSource={attrValues}
            updateFn={setCurrentValue}
            newRequestFn={handleAutocompleteUpdate}
            listData={defaultFilters}
            showAllChips={get(props, 'showSelections.showAllAttribute')}
            passedInDeleteFn={handleAutocompleteUpdate}
            initialAmountVisible={INITIAL_AMOUNT_VISIBLE_STATUS_ATTRIBUTE}
            showHideAllFn={() => props.toggleShowSelections('Attribute')}
          />
        </>
      )}
    </Col>
  );
};

export default injectIntl(TenantAttributeFilter);
