import { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SubHeader } from 'ui-components';
import './CarrierFleetListComponent.scss';
import CarrierFleetListHeaderComponent from './carrierFleetListHeader/CarrierFleetListHeaderComponent';
import NoResultsToDisplay from './NoResultsToDisplay';
import endpoints from '../../common/endpoints';
import { CarrierFleetListProps, CarrierFleetListDispatchProps } from './CarrierFleetListContainer';

const CarrierFleetListComponent: React.FunctionComponent<CarrierFleetListProps & CarrierFleetListDispatchProps> = ({
  carrierFleetSearchTerm,
  errorNumber,
  history,
  isLoading,
  carrierResult,
}) => {
  useEffect(() => {
    if (carrierResult) {
      history.push(endpoints.FLEET_LIST);
      return;
    }
  }, [history, carrierResult]);

  return (
    <div className="CarrierFleetListComponent animated fadeIn">
      <SubHeader>
        <CarrierFleetListHeaderComponent />
      </SubHeader>
      <Container as={'section'} className={`section-content`} key="content">
        {!isLoading && !carrierResult && errorNumber === 404 && (
          <Row>
            <Col xs={24} lg={24} className="results">
              <NoResultsToDisplay errorNumber={errorNumber} carrierFleetSearchTerm={carrierFleetSearchTerm} />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default CarrierFleetListComponent;
