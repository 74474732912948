import { TitleAndSettingsForm } from '../types';

export const initialFormValues: TitleAndSettingsForm = {
  title: '',
  tileNumberFormat: 'PERCENTAGE',
  healthScoreConfig: {
    numberFormat: 'PERCENTAGE',
    isEnabled: true,
    ranges: {
      excellent: {
        isEnabled: true,
        lowLimit: 9.9,
        highLimit: null,
      },
      acceptable: {
        isEnabled: true,
        lowLimit: 10,
        highLimit: 19.9,
      },
      concerning: {
        isEnabled: true,
        lowLimit: 20,
        highLimit: 49.9,
      },
      problematic: {
        isEnabled: true,
        lowLimit: null,
        highLimit: 50,
      },
    },
  },
};
