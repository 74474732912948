import { useState } from 'react';
import { FormSection } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'ui-components';
import ScheduleTruckChangeForm from './ScheduleTruckChangeForm';

const TrackingMethodForm = ({ values }) => {
  const setScheduledTruckChangesFormat = (formValues) => {
    let initialScheduledTruckChanges = [];

    if (formValues?.scheduledTruckChanges != null) {
      for (const key in formValues.scheduledTruckChanges) {
        initialScheduledTruckChanges.push({ id: key.split('_')[1] });
      }
    }

    return initialScheduledTruckChanges;
  };

  const [scheduleTruckChanges, setScheduleTruckChanges] = useState(setScheduledTruckChangesFormat(values));

  const addScheduleTruckChange = () => {
    setScheduleTruckChanges((scheduleTruckChanges) => {
      const nextId = scheduleTruckChanges.length > 0 ? scheduleTruckChanges[scheduleTruckChanges.length - 1].id + 1 : 0;
      return [...scheduleTruckChanges, { id: nextId }];
    });
  };
  const removeScheduleTruckChange = (id) => {
    setScheduleTruckChanges((scheduleTruckChanges) => {
      const index = scheduleTruckChanges.findIndex((scheduleTruckChange) => scheduleTruckChange.id === id);
      scheduleTruckChanges.splice(index, 1);
      if (values.scheduledTruckChanges != null) {
        delete values.scheduledTruckChanges['scheduleTruckChange_' + id];
      }
      return [...scheduleTruckChanges];
    });
  };

  return (
    <>
      <FormSection name={'equipmentIdentifiers'}>
        <ScheduleTruckChangeForm id={0} />
      </FormSection>
      <FormSection name={'scheduledTruckChanges'}>
        {scheduleTruckChanges.length > 0 ? (
          <h4 className="form-header">
            <FormattedMessage
              id="addShipment.shipmentForm.scheduleTruckChange"
              defaultMessage="Schedule Truck Method"
            />
            <span className="form-header-help">
              <FormattedMessage id="addShipment.shipmentForm.optional" defaultMessage="(Optional)" />
            </span>
          </h4>
        ) : null}
        {scheduleTruckChanges.map((scheduleTruckChange) => (
          <ScheduleTruckChangeForm
            key={scheduleTruckChange.id}
            id={scheduleTruckChange.id}
            removeScheduleTruckChange={removeScheduleTruckChange}
            extraInfo
          />
        ))}
        <Row>
          <Col xs={24}>
            <Button className="float-right" type="primary" clickFn={addScheduleTruckChange}>
              <FormattedMessage id="temp" defaultMessage="Schedule a Truck Change" />
            </Button>
          </Col>
        </Row>
      </FormSection>
    </>
  );
};
export default TrackingMethodForm;
