import { push } from 'connected-react-router';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { CallReturnType } from 'common/strictEffect';
import * as ErrorUtils from 'common/errorUtils';
import { API_PATH } from 'common/AppConstants';
import { AccessLevelObject, NotificationAccessLevelEnum, ShipmentNotificationDto, ShipmentModeEnum } from 'models';
import endpoints from '../../../common/endpoints';
import axios from '../../../util/paxios';
import { manageNotificationsActions, notificationActions } from './actions';
import * as NotificationApiToStateMapper from './notificationApiToStateMapper';
import * as types from './types';

type IShipmentID = string | undefined;

const tenantNotificationsBaseEndpoint = `${API_PATH}/tenant-notifications`;
const shipmentNotificationsMSIDBaseEndpoint = `${API_PATH}/shipment-notifications`;

const configIdentitiesBaseEndpoint = `${API_PATH}/push-api-identities`;

const getAllTenantNotificationsEndpoint = () => tenantNotificationsBaseEndpoint;

const getAllShipmentNotificationsMSIDEndpoint = ({
  shipmentId,
  masterShipmentId,
  mode,
}: {
  shipmentId: IShipmentID;
  masterShipmentId: IShipmentID;
  mode: string;
}) => {
  const queryString = masterShipmentId
    ? `masterShipmentId=${masterShipmentId}&mode=${mode}`
    : `shipmentId=${shipmentId}&mode=${mode}`;
  return `${shipmentNotificationsMSIDBaseEndpoint}?${queryString}`;
};

const getConfigIdentitiesBaseEndpoint = () => configIdentitiesBaseEndpoint;

interface ShipmentNotificationEndpointParams {
  shipmentId?: string;
  masterShipmentId?: string;
  mode: ShipmentModeEnum;
  notificationId: string;
}

const shipmentNotificationEndpointById = ({
  shipmentId,
  masterShipmentId,
  notificationId,
  mode,
}: ShipmentNotificationEndpointParams) => {
  const queryString = masterShipmentId
    ? `masterShipmentId=${masterShipmentId}&mode=${mode}`
    : `shipmentId=${shipmentId}&mode=${mode}`;
  return `${shipmentNotificationsMSIDBaseEndpoint}/${notificationId}?${queryString}`;
};

const tenantNotificationEndpointById = (notificationId: string) =>
  `${tenantNotificationsBaseEndpoint}/${notificationId}`;

const updateShipmentNotificationEndpoint = ({
  shipmentId,
  masterShipmentId,
  notificationId,
  mode,
}: ShipmentNotificationEndpointParams) =>
  shipmentNotificationEndpointById({ shipmentId, masterShipmentId, notificationId, mode });

const updateTenantNotificationEndpoint = (notificationId: string) =>
  `${tenantNotificationsBaseEndpoint}/${notificationId}`;

const createTenantNotificationEndpoint = () => tenantNotificationsBaseEndpoint;

export function* getNotificationsAsync(action: any) {
  const accessLevelObject = getAccessLevelObjectFromAction(action);
  const { masterShipmentId, shipmentId, mode } = accessLevelObject;
  let response;

  try {
    let restResponse:
      | CallReturnType<typeof getAllShipmentNotifications>
      | CallReturnType<typeof getAllTenantNotifications>;
    if (isShipmentLevelAccessAction(accessLevelObject)) {
      restResponse = yield call(getAllShipmentNotifications, { shipmentId, masterShipmentId, mode });
    } else {
      restResponse = yield call(getAllTenantNotifications);
    }
    const subscriptionOptions = action.payload.subscriptionOptions;
    if (subscriptionOptions.values.push.length === 0) {
      try {
        const availableConfigIdentities: CallReturnType<typeof getAvailableConfigIdentities> = yield call(
          getAvailableConfigIdentities
        );
        subscriptionOptions.values.push = get(availableConfigIdentities, 'data');
        yield put({
          type: types.GET_CONFIG_IDENTITIES_SUCCESS,
          payload: {
            values: subscriptionOptions.values.push,
          },
        });
      } catch (e) {
        yield put({
          type: types.GET_CONFIG_IDENTITIES_FAIL,
        });
      }
    }
    // TODO: For all mapping to state scenarios need to call to get the push api configs if not already in state
    // and always map them into the payload sent to reducer.  Probably should make a separate key in the payload for pushConfigs
    response = NotificationApiToStateMapper.mapApiToState(restResponse.data, subscriptionOptions);

    yield put({
      type: types.GET_NOTIFICATIONS_SUCCESS,
      payload: {
        notifications: response,
        accessLevelObject,
      },
    });
  } catch (e) {
    const mappedErrorResponse = ErrorUtils.extractErrorDetails(e);
    yield put(manageNotificationsActions.getNotificationsFailure(accessLevelObject, mappedErrorResponse));
  }
}

const getAccessLevelObjectFromAction = (action: {
  accessLevelObject?: AccessLevelObject;
  payload: { accessLevelObject?: AccessLevelObject };
}) => {
  const accessLevelObject = get(action, 'payload.accessLevelObject') || get(action, 'accessLevelObject');

  if (!accessLevelObject || !accessLevelObject.accessLevel) {
    throw new Error(`Missing required accessLevelObject on action=${JSON.stringify(action)}`);
  }

  return accessLevelObject;
};

const isShipmentLevelAccessAction = (accessLevelObject: AccessLevelObject) =>
  get(accessLevelObject, 'accessLevel') === NotificationAccessLevelEnum.SHIPMENT;

export function getAllShipmentNotifications({
  shipmentId,
  masterShipmentId,
  mode,
}: {
  shipmentId: IShipmentID;
  masterShipmentId: IShipmentID;
  mode: ShipmentModeEnum;
}) {
  const url = getAllShipmentNotificationsMSIDEndpoint({ shipmentId, masterShipmentId, mode });
  return axios.get(url, {
    withCredentials: true,
  });
}

export function getAllTenantNotifications() {
  return axios.get(getAllTenantNotificationsEndpoint(), {
    withCredentials: true,
  });
}

function* updateManageNotificationsShipmentAccessLevelAsync(action: any) {
  yield put({
    type: types.UPDATE_MANAGE_NOTIFICATIONS_SHIPMENT_ACCESS_LEVEL_SUCCESS,
    payload: {
      accessLevelObject: action.accessLevelObject,
    },
  });
}

function* updateNotificationShipmentAccessLevelAsync(action: any) {
  yield put({
    type: types.UPDATE_NOTIFICATION_SHIPMENT_ACCESS_LEVEL_SUCCESS,
    payload: {
      accessLevel: action.accessLevel,
    },
  });
}

function* getNotificationAsync(action: any) {
  const accessLevelObject = getAccessLevelObjectFromAction(action);
  const { shipmentId, masterShipmentId, mode } = accessLevelObject;
  const notificationId = action.id;

  let response: CallReturnType<typeof getShipmentNotificationById>;

  try {
    if (isShipmentLevelAccessAction(accessLevelObject)) {
      response = yield call(getShipmentNotificationById, { shipmentId, masterShipmentId, notificationId, mode });
    } else {
      response = yield call(getTenantNotificationById, action.id);
    }

    const transformedResponse = NotificationApiToStateMapper.mapApiToState(
      [response.data],
      action.subscriptionOptions
    )[0];

    yield put({
      type: types.GET_NOTIFICATION_SUCCESS,
      payload: {
        notification: transformedResponse,
        accessLevelObject,
      },
    });
  } catch (e) {
    const mappedErrorResponse = ErrorUtils.extractErrorDetails(e);
    yield put(notificationActions.getNotificationFail(notificationId, accessLevelObject, mappedErrorResponse));
  }
}

function getShipmentNotificationById({
  shipmentId,
  masterShipmentId,
  notificationId,
  mode,
}: ShipmentNotificationEndpointParams) {
  const url = shipmentNotificationEndpointById({ shipmentId, masterShipmentId, notificationId, mode });
  return axios.get(url, { withCredentials: true });
}

function getTenantNotificationById(notificationId: string) {
  const url = tenantNotificationEndpointById(notificationId);
  return axios.get(url, { withCredentials: true });
}

function* updateSubscriberSuggestionsAsync(action: any) {
  yield put({
    type: types.UPDATE_SUBSCRIBER_SUGGESTIONS_SUCCESS,
    subscriberSuggestions: [],
  });
}

export function* saveNotificationAsync(action: any) {
  const accessLevelObject = getAccessLevelObjectFromAction(action);
  const { masterShipmentId, shipmentId, mode } = accessLevelObject;
  const notificationId = action.notification.id;
  const apiNotification: ShipmentNotificationDto = NotificationApiToStateMapper.mapStateToApi(
    [action.notification],
    shipmentId,
    masterShipmentId,
    action.subscriptionOptions
  )[0];
  if (!apiNotification) {
    return;
  }

  let response: CallReturnType<typeof createShipmentNotification> | CallReturnType<typeof updateShipmentNotification>;

  try {
    if (isShipmentLevelAccessAction(accessLevelObject)) {
      if (isNil(notificationId)) {
        response = yield call(createShipmentNotification, shipmentId, apiNotification);
      } else {
        response = yield call(updateShipmentNotification, {
          shipmentId,
          masterShipmentId,
          notificationId,
          mode,
          shipmentNotification: apiNotification,
        });
      }
    } else {
      if (isNil(notificationId)) {
        response = yield call(createTenantNotification, apiNotification);
      } else {
        response = yield call(updateTenantNotification, notificationId, apiNotification);
      }
    }

    const savedNotification = NotificationApiToStateMapper.mapApiToState(
      [response.data],
      action.subscriptionOptions
    )[0];
    yield put({
      type: types.SAVE_NOTIFICATION_SUCCESS,
      payload: { notification: savedNotification },
    });
    yield redirectToNotificationsView({ shipmentId, masterShipmentId, mode });
  } catch (e) {
    const mappedErrorResponse = ErrorUtils.extractErrorDetails(e);
    yield put(notificationActions.saveNotificationFail(notificationId, accessLevelObject, mappedErrorResponse));
  }
}

export function redirectToNotificationsView({
  shipmentId,
  masterShipmentId,
  mode,
}: {
  shipmentId: IShipmentID;
  masterShipmentId: IShipmentID;
  mode: ShipmentModeEnum;
}) {
  if (masterShipmentId) {
    return put(push(`${endpoints.NOTIFICATIONS}?masterShipmentId=${masterShipmentId}&mode=${mode}`));
  } else if (shipmentId) {
    return put(push(`${endpoints.NOTIFICATIONS}?shipmentId=${shipmentId}&mode=${mode}`));
  } else {
    return put(push(endpoints.NOTIFICATIONS));
  }
}

export function createShipmentNotification(shipmentId: IShipmentID, shipmentNotification: ShipmentNotificationDto) {
  const url = shipmentNotificationsMSIDBaseEndpoint;
  return axios({
    method: 'POST',
    url,
    data: shipmentNotification,
    withCredentials: true,
  });
}

export function updateShipmentNotification({
  shipmentId,
  masterShipmentId,
  notificationId,
  mode,
  shipmentNotification,
}: ShipmentNotificationEndpointParams & { shipmentNotification: any }) {
  const url = updateShipmentNotificationEndpoint({ shipmentId, masterShipmentId, notificationId, mode });
  return axios({
    method: 'PUT',
    url,
    data: shipmentNotification,
    withCredentials: true,
  });
}

export function createTenantNotification(tenantNotification: any) {
  const url = createTenantNotificationEndpoint();
  return axios({
    method: 'POST',
    url,
    data: tenantNotification,
    withCredentials: true,
  });
}

export function updateTenantNotification(notificationId: string, tenantNotification: any) {
  const url = updateTenantNotificationEndpoint(notificationId);
  return axios({
    method: 'PUT',
    url,
    data: tenantNotification,
    withCredentials: true,
  });
}

export function* deleteNotificationAsync(action: any) {
  const accessLevelObject = getAccessLevelObjectFromAction(action);
  const { masterShipmentId, shipmentId, mode } = accessLevelObject;
  const notificationId = action.id;

  try {
    if (isShipmentLevelAccessAction(accessLevelObject)) {
      yield call(deleteShipmentNotification, { shipmentId, masterShipmentId, notificationId, mode });
    } else {
      yield call(deleteTenantNotification, notificationId);
    }

    yield put({
      type: types.DELETE_NOTIFICATION_SUCCESS,
    });
    yield redirectToNotificationsView({ shipmentId, masterShipmentId, mode });
  } catch (e) {
    const mappedErrorResponse = ErrorUtils.extractErrorDetails(e);
    yield put(notificationActions.deleteNotificationFail(notificationId, accessLevelObject, mappedErrorResponse));
  }
}

export function deleteShipmentNotification({
  shipmentId,
  masterShipmentId,
  notificationId,
  mode,
}: ShipmentNotificationEndpointParams) {
  const url = shipmentNotificationEndpointById({ shipmentId, masterShipmentId, notificationId, mode });

  return axios({
    method: 'DELETE',
    url,
    withCredentials: true,
  });
}

function deleteTenantNotification(notificationId: string) {
  const url = tenantNotificationEndpointById(notificationId);

  return axios({
    method: 'DELETE',
    url,
    withCredentials: true,
  });
}

function* getAvailableConfigIdentitiesAsync() {
  let response: CallReturnType<typeof getAvailableConfigIdentities>;
  try {
    response = yield call(getAvailableConfigIdentities);

    yield put({
      type: types.GET_CONFIG_IDENTITIES_SUCCESS,
      payload: {
        values: response.data,
      },
    });
  } catch (e) {
    yield put(notificationActions.getConfigIdentitiesFail());
  }
}

export function getAvailableConfigIdentities() {
  return axios.get(getConfigIdentitiesBaseEndpoint(), { withCredentials: true });
}

export function* watchGetIdentitiesAsync() {
  yield takeEvery(types.GET_CONFIG_IDENTITIES, getAvailableConfigIdentitiesAsync);
}

export function* watchNotificationAsync() {
  yield takeEvery(types.GET_NOTIFICATION, getNotificationAsync);
}

export function* watchNotificationsAsync() {
  yield takeEvery(types.GET_NOTIFICATIONS, getNotificationsAsync);
}

export function* watchUpdateManageNotificationsShipmentAccessLevelAsync() {
  yield takeEvery(
    types.UPDATE_MANAGE_NOTIFICATIONS_SHIPMENT_ACCESS_LEVEL,
    updateManageNotificationsShipmentAccessLevelAsync
  );
}

export function* watchUpdateNotificationShipmentAccessLevelAsync() {
  yield takeEvery(types.UPDATE_NOTIFICATION_SHIPMENT_ACCESS_LEVEL, updateNotificationShipmentAccessLevelAsync);
}

export function* watchSubscriberSuggestionsAsync() {
  yield takeEvery(types.UPDATE_SUBSCRIBER_SUGGESTIONS, updateSubscriberSuggestionsAsync);
}

export function* watchSaveNotificationAsync() {
  yield takeEvery(types.SAVE_NOTIFICATION, saveNotificationAsync);
}

export function* watchDeleteNotificationAsync() {
  yield takeEvery(types.DELETE_NOTIFICATION, deleteNotificationAsync);
}

export function* notificationOperations() {
  yield fork(watchGetIdentitiesAsync);
  yield fork(watchUpdateManageNotificationsShipmentAccessLevelAsync);
  yield fork(watchUpdateNotificationShipmentAccessLevelAsync);
  yield fork(watchSubscriberSuggestionsAsync);
  yield fork(watchSaveNotificationAsync);
  yield fork(watchDeleteNotificationAsync);
}
