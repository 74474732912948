import { push } from 'connected-react-router';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { API_PATH } from '../../../../common/AppConstants';
import endpoints from '../../../../common/endpoints';
import { buildWaypoints, calculateMiles, queryRoute } from '../../../../common/hereMaps/hereMapsRouteMilesCalculator';
import axios from '../../../../util/paxios';
import * as brandThemingActions from '../../../settings/BrandTheming/ducks/actions';
import mapThemeResponse from '../../../settings/BrandTheming/utils/mapThemeResponse';
import StatusReason from '../../../shipment/common/enums/statusReason';
import ShipmentDetailUtil from '../../../shipment/common/utils/shipmentDetailUtil';
import { buildShipmentSearchQueryAction } from '../../../shipmentListComponent/ducks/searchReducer';
import ShipmentDetailFactory from '../utils/shipmentDetailFactory';
import * as actions from './actions';
import types from './types';

export const getIsLoggedIn = (state) => state.loginReducer.isLoggedIn;
// public details page is not editable
export const publicShipmentEditableResponse = {
  data: {
    editable: {
      stops: [],
    },
  },
};

export const combinedRawResponse = (
  shipmentDetails,
  modifiableDetails = [],
  milesCalculation = {},
  customerProperties = {}
) => {
  const compositeResponse = ShipmentDetailUtil.mapModifiableDetails(shipmentDetails, modifiableDetails);

  if (compositeResponse && milesCalculation) {
    compositeResponse.milesTraveled = milesCalculation.milesTraveled;
    compositeResponse.milesRemaining = milesCalculation.milesRemaining;
  }

  if (compositeResponse && customerProperties) {
    compositeResponse.customerProperties = customerProperties;
  }

  return compositeResponse;
};

export const getHereMapsConfig = (state) => get(state, 'configReducer.configReducer.hereMapsConfig');

export const getSharedHereMapsConfig = (state) => get(state, 'tlShipmentDetailsReducer.hereMapsConfig');

export const milesCalculation = (state) => get(state, 'tlShipmentDetailsReducer.milesCalculation');

export const getCustomerProperties = (state) => get(state, 'authReducer.principal.customerProperties');

export const fetchSensorHistoryData = (shipmentId, sensorId, sensorType) => {
  return axios({
    method: 'GET',
    url: `${API_PATH}/sensor-service/history?shipmentId=${shipmentId}&sensorId=${sensorId}&type=${sensorType}`,
    withCredentials: true,
  });
};

export const fetchPublicSensorHistoryData = (token, sensorId, sensorType) => {
  return axios({
    method: 'GET',
    url: `${API_PATH}/sensor-service/public/history/${token}?sensorId=${sensorId}&type=${sensorType}`,
  });
};

export const fetchShipmentData = (shipmentId) => {
  return axios({
    method: 'GET',
    url: `${API_PATH}/shipment/search/TL/${shipmentId}`,
    withCredentials: true,
  });
};

export const fetchShipmentDataFromSearchV2 = (shipmentId) => {
  return axios({
    method: 'GET',
    url: `${API_PATH}/shipment/search/v2/TL/${shipmentId}`,
    withCredentials: true,
  });
};

export const fetchNudgeData = (shipmentId) => {
  return axios({
    method: 'GET',
    url: `${API_PATH}/shipment/${shipmentId}/nudge/status`,
    withCredentials: true,
  });
};

export const fetchNudgeMessagePreview = (shipmentId) => {
  return axios({
    method: 'GET',
    url: `${API_PATH}/shipment/${shipmentId}/nudge/message`,
    withCredentials: true,
  });
};

export const sendNudge = (shipmentId) => {
  return axios({
    method: 'POST',
    url: `${API_PATH}/shipment/${shipmentId}/nudge`,
    withCredentials: true,
  });
};

export const fetchModifiableData = (shipmentId) => {
  return axios({
    method: 'GET',
    url: `${API_PATH}/shipment/${shipmentId}/modifiable`,
    withCredentials: true,
  });
};

export function* fetchMilesDataAsync(action) {
  const shipmentDetails = get(action, 'payload.shipmentDetails');
  const hereMapsConfig = yield select(getHereMapsConfig);
  const sharedHereMapsConfig = yield select(getSharedHereMapsConfig);

  const mapsConfig = hereMapsConfig || sharedHereMapsConfig;

  let statusUpdate = shipmentDetails.latestStatusUpdate;

  if (!('locationCoordinatesDto' in statusUpdate)) {
    const allStatusUpdates = get(shipmentDetails, 'allStatusUpdates');
    const tmpStatusUpdate = allStatusUpdates.find((update) => 'locationCoordinatesDto' in update);
    if (tmpStatusUpdate) {
      statusUpdate = tmpStatusUpdate;
    }
  }

  try {
    if (
      shipmentDetails.shipmentStops &&
      shipmentDetails.shipmentStops.length >= 2 &&
      statusUpdate.locationCoordinatesDto !== undefined
    ) {
      // Process stops, pulling out only necessary info
      const stops = sortBy(shipmentDetails.shipmentStops, 'stopNumber').map((stop) => ({
        lat: get(stop, 'location.address.locationCoordinatesDto.latitude', 0),
        lon: get(stop, 'location.address.locationCoordinatesDto.longitude', 0),
      }));

      const { waypoints, currWaypointId } = buildWaypoints(stops, statusUpdate);

      if (mapsConfig) {
        const routeResp = yield call(queryRoute, waypoints, mapsConfig.hereMapsApiKey);
        const route = get(routeResp, 'data.routes[0]');

        // On success, calculate miles info and attach result to shipment details
        if (route) {
          const { milesTraveled, milesRemaining } = calculateMiles(route, currWaypointId);
          yield put(actions.setShipmentDetailMilesRemaining(milesTraveled, milesRemaining));
        }
      }
    }
  } catch (error) {
    // no need to throw error here as we can still render shipment details w/o miles info
    console.error(error);
  }
}

export function* fetchShipmentDataAsync(action) {
  const callSearch = action.payload.shouldUseRecentSearch ? fetchShipmentDataFromSearchV2 : fetchShipmentData;
  try {
    const [shipmentResponse, modifiableResponse] = yield all([
      call(callSearch, action.payload.shipmentId),
      call(fetchModifiableData, action.payload.shipmentId),
    ]);
    let nudgeDataResponse = undefined;
    if (get(shipmentResponse, 'data.derivedStatusCodes[0].statusReasonCode') === StatusReason.PENDING_APPROVAL) {
      nudgeDataResponse = yield call(fetchNudgeData, action.payload.shipmentId);
    }
    let sensorHistoryResponse = [];
    if (!isEmpty(shipmentResponse.data.sensorTracking)) {
      sensorHistoryResponse = yield call(
        fetchSensorHistoryData,
        action.payload.shipmentId,
        shipmentResponse.data.sensorTracking[0].sensor.sensorId,
        shipmentResponse.data.sensorTracking[0].type
      );
    }

    const hereMapsConfig = yield select(getHereMapsConfig);
    const customerProperties = yield select(getCustomerProperties);
    const mappedShipmentDetails = ShipmentDetailFactory.newInstance(
      combinedRawResponse(shipmentResponse.data, modifiableResponse.data, {}, customerProperties)
    );

    yield put(
      actions.shipmentDetailsSuccess(
        mappedShipmentDetails,
        modifiableResponse.data,
        sensorHistoryResponse.data,
        hereMapsConfig,
        get(nudgeDataResponse, 'data'),
        combinedRawResponse(shipmentResponse.data, modifiableResponse.data)
      )
    );
  } catch (error) {
    yield put(actions.shipmentDetailsFailure(error));
  }
}

export function* fetchNudgeMessagePreviewAsync(action) {
  try {
    // if response status code is not 2xx, then axios interceptor throws
    const nudgeMessagePreviewResponse = yield call(fetchNudgeMessagePreview, action.shipmentId);
    // if success
    yield put({
      type: types.GET_NUDGE_MESSAGE_PREVIEW_SUCCESS,
      nudgeMessagePreview: nudgeMessagePreviewResponse.data.message,
    });
  } catch (error) {
    // if failure
    yield put({
      type: types.GET_NUDGE_MESSAGE_PREVIEW_FAILURE,
      nudgeFailureMessage: 'Unable to retrieve nudge message.',
    });
  }
}

export function* sendNudgeAsync(action) {
  try {
    // if response status code is not 2xx, then axios interceptor throws
    yield call(sendNudge, action.shipmentId);
    // if success
    yield put({ type: types.SEND_NUDGE_SUCCESS });
  } catch (error) {
    // if failure
    // if response body contains a message, it is intended to be displayed to the user, so display that message
    if (error.response.data && error.response.data.message) {
      yield put({ type: types.SEND_NUDGE_FAILURE, nudgeFailureMessage: error.response.data.message });
      // if response body does not contain a message, UI is responsible for the message displayed to the user
    } else {
      switch (error.response.status) {
        case 429:
          yield put({
            type: types.SEND_NUDGE_FAILURE,
            nudgeFailureMessage:
              'You sent the driver a text message less than 5 minutes ago. ' +
              'You can only contact the driver once every 5 minutes. ' +
              '\n\nPlease allow the driver some time to respond before sending another message.',
          });
          break;
        default:
          yield put({
            type: types.SEND_NUDGE_FAILURE,
            nudgeFailureMessage: 'Whoops, something went wrong! Please try again later.',
          });
      }
    }
  }
}

export function* watchShipmentDetailAsync() {
  yield takeEvery(types.SHIPMENT_DETAIL_GET, fetchShipmentDataAsync);
}

export function* watchMilesDataAsync() {
  yield takeEvery(types.MILES_DATA_GET, fetchMilesDataAsync);
}

// Shipment share operations
export const fetchPublicShipmentData = (shipmentShareToken) => {
  return axios({
    method: 'GET',
    url: `${API_PATH}/shipment/share/public/${shipmentShareToken}`,
    withCredentials: true,
  });
};

export function* fetchPublicShipmentDataAsync({ shipmentShareToken }) {
  const isLoggedIn = yield select(getIsLoggedIn);
  try {
    const shipmentResponse = yield call(fetchPublicShipmentData, shipmentShareToken);

    const { shipment, hereMapsConfig, brandingDto, mapGeofenceConfig, customerProperties } = shipmentResponse.data;
    if (!isLoggedIn) {
      yield put(brandThemingActions.fetch.success(mapThemeResponse(brandingDto)));
    }

    let sensorHistoryResponse = [];
    if (!isEmpty(shipmentResponse.data.sensorTracking)) {
      sensorHistoryResponse = yield call(
        fetchPublicSensorHistoryData,
        shipmentShareToken,
        shipmentResponse.data.sensorTracking[0].sensor.sensorId,
        shipmentResponse.data.sensorTracking[0].type
      );
    }

    const mappedPublicShipmentDetails = ShipmentDetailFactory.newInstance(
      combinedRawResponse(shipment, publicShipmentEditableResponse.data, null, customerProperties)
    );

    yield put(
      actions.shipmentDetailsSuccess(
        mappedPublicShipmentDetails,
        publicShipmentEditableResponse.data,
        sensorHistoryResponse.data ? sensorHistoryResponse.data : [],
        hereMapsConfig,
        {},
        combinedRawResponse(shipment, publicShipmentEditableResponse.data),
        mapGeofenceConfig,
        customerProperties
      )
    );
  } catch (error) {
    if (!isLoggedIn) {
      yield put(brandThemingActions.fetch.failure());
    }
    yield put(actions.shipmentDetailsFailure(error));
  }
}

export function* watchPublicShipmentDetailAsync() {
  yield takeEvery(types.SHIPMENT_DETAIL_GET_PUBLIC, fetchPublicShipmentDataAsync);
}

// Delete shipment operations
export const deleteShipment = (shipmentId) => {
  return axios({
    method: 'DELETE',
    url: `${API_PATH}/tl-shipment/id/${shipmentId}`,
    withCredentials: true,
  });
};

export function* deleteShipmentAsync({ shipmentId }) {
  try {
    yield call(deleteShipment, shipmentId);
  } catch (err) {
    // TODO: add error handling
  }

  // note: this is called even when delete shipment fails
  yield put(actions.deleteShipmentSuccess());
}

export function* watchDeleteShipmentAsync() {
  yield takeEvery(types.DELETE_SHIPMENT, deleteShipmentAsync);
}

export function* watchFetchNudgeMessagePreviewAsync() {
  yield takeEvery(types.OPEN_NUDGE_DIALOG, fetchNudgeMessagePreviewAsync);
}

export function* watchSendNudgeAsync() {
  yield takeEvery(types.SEND_NUDGE, sendNudgeAsync);
}

export function* deleteShipmentSuccessAsync() {
  yield put(push(endpoints.SHIPMENT_LIST));

  const { search, filter, sortBy, modes } = yield select((state) => get(state, 'searchReducer.previousSearchQuery'));

  yield put(buildShipmentSearchQueryAction({ authorities: null, searchText: search, filter, modes, sortBy }));
  yield put(actions.deleteSnackbarSuccess());
}

export function* watchDeleteShipmentCompletion() {
  yield takeEvery(types.DELETE_SHIPMENT_SUCCESS, deleteShipmentSuccessAsync);
}

export default function* tlShipmentDetailsOperations() {
  yield fork(watchShipmentDetailAsync);
  yield fork(watchMilesDataAsync);
  yield fork(watchPublicShipmentDetailAsync);
  yield fork(watchDeleteShipmentAsync);
  yield fork(watchDeleteShipmentCompletion);
  yield fork(watchFetchNudgeMessagePreviewAsync);
  yield fork(watchSendNudgeAsync);
}
