import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import get from 'lodash/get';
import { getPrincipalAuthorizationsFromState } from 'common/authorizations';
import NotificationsComponent from './NotificationsComponent';
import { manageNotificationsActions } from './ducks';
import {
  determineAccessLevelObjectFromProps,
  determineAccessLevelFromPropsOrState,
  getValueForKeyFromPropsOrState,
} from './NotificationViewUtil';

import { ShipmentAccessLevelConstants } from './ducks/constants';

const doInit = (props) => {
  const accessLevelObject = determineAccessLevelObjectFromProps(props);

  props.updateShipmentAccessLevel(accessLevelObject);
  props.getNotifications(accessLevelObject, props.subscriptionOptions);
};

const componentWithFetchedData = compose(
  lifecycle({
    componentDidMount() {
      doInit(this.props);
    },
    componentWillReceiveProps(newProps) {
      // refresh on route change
      if (get(newProps, 'location.search') !== get(this.props, 'location.search')) {
        doInit(newProps);
      }
    },
  })
)(NotificationsComponent);

const stateRoot = (state) => state.manageNotificationsReducer.notificationsReducer;

const mapStateToProps = (state, ownProps) => ({
  notifications: stateRoot(state).notifications,
  isLoading: stateRoot(state).isLoading,
  hasError: stateRoot(state).hasError,
  accessLevel: determineAccessLevelFromPropsOrState(ownProps, state),
  shipmentId: getValueForKeyFromPropsOrState(ownProps, state, 'shipmentId'),
  masterShipmentId: getValueForKeyFromPropsOrState(ownProps, state, 'masterShipmentId'),
  mode: getValueForKeyFromPropsOrState(ownProps, state, 'mode'),
  subscriptionOptions: stateRoot(state).subscriptionOptions,
  hasEdit:
    determineAccessLevelFromPropsOrState(ownProps, state) === ShipmentAccessLevelConstants.Shipment ||
    getPrincipalAuthorizationsFromState(state).canEditTenantLevelNotifications(),
  hasModeVisibility:
    getPrincipalAuthorizationsFromState(state).hasTruckloadMode() ||
    getPrincipalAuthorizationsFromState(state).hasLtlMode() ||
    getPrincipalAuthorizationsFromState(state).hasParcelMode() ||
    getPrincipalAuthorizationsFromState(state).hasAirMode() ||
    getPrincipalAuthorizationsFromState(state).hasOceanMode(),
});

const mapDispatchToProps = (dispatch) => ({
  updateShipmentAccessLevel: (accessLevelObject) =>
    dispatch(manageNotificationsActions.updateManageNotificationsShipmentAccessLevel(accessLevelObject)),
  getNotifications: (accessLevelObject, subscriptionOptions) =>
    dispatch(manageNotificationsActions.getNotifications(accessLevelObject, subscriptionOptions)),
  expandCollapseNotification: (params) =>
    dispatch(manageNotificationsActions.expandCollapseNotification(params.id, params.isExpanded)),
});

export default connect(mapStateToProps, mapDispatchToProps)(componentWithFetchedData);
