import CollapsableFilterComponent from '../CollapsableFilterComponent';
import { ShipmentModeFilter } from '../FilteringCriteriaComponent/types';

interface ModeFiltersProps {
  modeFilters: Array<ShipmentModeFilter>;
}

const ModeFilters = ({ modeFilters, ...props }: ModeFiltersProps) => {
  return (
    <>
      {modeFilters.map(({ name, component: Component, modalId, metaData }: ShipmentModeFilter) => {
        return (
          <CollapsableFilterComponent key={modalId} filterName={name}>
            <Component
              {...props}
              {...(modalId === 'timing' && { isShipmentListFiltersHorizontalEnabled: true })}
              {...((modalId === 'timing' || modalId === 'holdsDemurrage') && { isQuickViewSetup: true })}
              {...(modalId === 'attributeKeyValuePairs' && { isQuickViewSetup: true })}
              metaData={metaData}
            />
          </CollapsableFilterComponent>
        );
      })}
    </>
  );
};

export default ModeFilters;
