import Dialog from '@material-ui/core/Dialog';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import get from 'lodash/get';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { injectIntl, FormattedMessage } from 'react-intl';
import AddEditHeader from './AddEditHeader/AddEditHeader';
import LaneDetailForm from '../LaneDetailForm/LaneDetailForm';
import ShipmentForm from '../ShipmentForm/ShipmentForm';
import AddEditShipmentForm from '../AddEditShipmentForm/AddEditShipmentForm';
import AddEditShipmentStops from '../AddEditShipmentStops/AddEditShipmentStops';
import EditShipmentForm from '../EditShipmentForm/EditShipmentForm';
import AddEditShipmentSummary from '../AddEditShipmentSummary/AddEditShipmentSummary';
import { addEditFormConstants } from '../AddEditShipmentConstants';
import AddEditShipmentTempForm from '../AddEditShipmentTempForm/AddEditShipmentTempForm';
import * as types from '../ducks/types';
import './AddEditShipmentModal.scss';

const AddEditShipmentModal = (props) => {
  const shipmentSteps = get(props, 'steps', []);
  const selectedStep =
    props.shipmentType !== undefined
      ? find(shipmentSteps, { id: props.shipmentType })
      : find(shipmentSteps, 'selected');

  const AddEditHeaderMobile = (props) => (
    <div className="progress-wizard-header">
      <button className="plainWrapperButton" onClick={props.cancel}>
        <i className="material-icons cancel">clear</i>
      </button>
      <h2>{props.title}</h2>
      <div className="mobile-header">
        <h3>
          <FormattedMessage
            id={addEditFormConstants[selectedStep.id].title?.id}
            defaultMessage={addEditFormConstants[selectedStep.id].title?.defaultMessage}
          />
        </h3>
        <div className="step-index">
          Step {findIndex(shipmentSteps, { id: selectedStep.id }) + 1} of {shipmentSteps.length}
        </div>
      </div>
    </div>
  );

  const getAddStopTokens = (addStopName) => {
    // Format of stop name is 'addStops' + index of the stop: e.g. 'addStops[0]'
    const tokenizer = /(\w+)\[(\d+)/;
    const matchResult = tokenizer.exec(addStopName);
    const radix = 10;

    if (!matchResult || matchResult.length < 3) {
      console.warn(`Unable to match stop tokens for ${addStopName}`); // eslint-disable-line no-console
      return null;
    }

    return {
      key: matchResult[1],
      index: parseInt(matchResult[2], radix),
    };
  };

  return (
    <MuiThemeProvider>
      <Dialog open={props.open} className="add-edit-dialog" fullScreen={props.fullScreen} maxWidth="lg">
        {props.fullScreen && (
          <AddEditHeaderMobile
            steps={shipmentSteps}
            navigateStep={props.navigateStep}
            title={props.title}
            cancel={props.cancelAddEditShipment}
            type={props.type}
          />
        )}
        {!props.fullScreen && (
          <AddEditHeader
            steps={shipmentSteps}
            formValues={props.values}
            initialValues={props.initialValues}
            navigateStep={props.navigateStep}
            title={props.title}
            type={props.type}
            shipmentSteps={shipmentSteps}
            selectedStep={selectedStep}
          />
        )}
        <div className={`add-edit-shipment ${selectedStep.id}`}>
          <div className="progress-wizard">
            <div className="progress-wizard-content">
              {props.type === types.ADD_SHIPMENT &&
                (() => {
                  switch (selectedStep.id) {
                    case addEditFormConstants.LANE_DETAIL.id:
                      return (
                        <LaneDetailForm
                          formValues={props.values}
                          step={selectedStep}
                          navigateStep={props.navigateStep}
                          // eslint-disable-next-line react/jsx-no-bind
                          onSubmit={() => {
                            props.navigateStep(
                              types.NEXT_STEP,
                              selectedStep.id,
                              props.values,
                              props.initialValues,
                              shipmentSteps
                            );
                          }}
                          initialValues={props.initialValues}
                          cancel={props.cancelAddEditShipment}
                          setCarrierType={props.setCarrierType}
                          setTrackingMethod={props.setTrackingMethod}
                        />
                      );
                    case addEditFormConstants.SHIPMENT.id:
                      return (
                        <ShipmentForm
                          formValues={props.values}
                          step={selectedStep}
                          navigateStep={props.navigateStep}
                          // eslint-disable-next-line react/jsx-no-bind
                          onSubmit={() => {
                            props.navigateStep(
                              types.NEXT_STEP,
                              selectedStep.id,
                              props.values,
                              props.initialValues,
                              shipmentSteps
                            );
                          }}
                          initialValues={props.initialValues}
                          cancel={props.cancelAddEditShipment}
                          setTrackingMethod={props.setTrackingMethod}
                        />
                      );
                    case addEditFormConstants.TEMPERATURE_TRACKING.id:
                      return (
                        <AddEditShipmentTempForm
                          formName="temperatureForm"
                          formValues={props.values}
                          initialValues={props.initialValues}
                          step={selectedStep}
                          navigateStep={props.navigateStep}
                          setTemperatureScale={props.setTemperatureScale}
                          // eslint-disable-next-line react/jsx-no-bind
                          onSubmit={() => {
                            props.navigateStep(
                              types.NEXT_STEP,
                              selectedStep.id,
                              props.values,
                              props.initialValues,
                              shipmentSteps
                            );
                          }}
                          skipTempTracking={props.skipTempTracking}
                          cancel={props.cancelAddEditShipment}
                        />
                      );
                    case addEditFormConstants.PICKUP_DETAIL.id:
                      return (
                        <AddEditShipmentForm
                          formName={addEditFormConstants.PICKUP_STOP_ID}
                          initialValues={props.initialValues}
                          step={selectedStep}
                          navigateStep={props.navigateStep}
                          // eslint-disable-next-line react/jsx-no-bind
                          onSubmit={() => {
                            props.navigateStep(
                              types.NEXT_STEP,
                              selectedStep.id,
                              props.values,
                              props.initialValues,
                              shipmentSteps
                            );
                          }}
                          // eslint-disable-next-line react/jsx-no-bind
                          zipEntered={(stop, zip) => props.zipEntered(stop, zip)}
                          cancel={props.cancelAddEditShipment}
                        />
                      );
                    case addEditFormConstants.DROPOFF_DETAIL.id:
                      return (
                        <AddEditShipmentForm
                          formName={addEditFormConstants.DROP_OFF_STOP_ID}
                          initialValues={props.initialValues}
                          step={selectedStep}
                          navigateStep={props.navigateStep}
                          // eslint-disable-next-line react/jsx-no-bind
                          onSubmit={() => {
                            props.navigateStep(
                              types.NAVIGATE_STEP,
                              types.SUMMARY,
                              props.values,
                              props.initialValues,
                              shipmentSteps
                            );
                          }}
                          // eslint-disable-next-line react/jsx-no-bind
                          zipEntered={(stop, zip) => props.zipEntered(stop, zip)}
                          cancel={props.cancelAddEditShipment}
                        />
                      );
                    case addEditFormConstants.ADD_STOPS.id:
                      return (
                        <AddEditShipmentStops
                          formValues={props.values}
                          step={selectedStep}
                          // eslint-disable-next-line react/jsx-no-bind
                          onSubmit={() => {
                            props.navigateStep(
                              types.NAVIGATE_STEP,
                              types.SUMMARY,
                              props.values,
                              props.initialValues,
                              shipmentSteps
                            );
                          }}
                          navigateStep={props.navigateStep}
                          cancel={props.cancelAddEditShipment}
                          initialValues={props.initialValues.addStops[0]}
                          toggleStop={props.toggleStop}
                          // eslint-disable-next-line react/jsx-no-bind
                          zipEntered={(stop, zip) => {
                            const stopTokens = getAddStopTokens(stop);
                            if (stopTokens) {
                              props.zipEntered(stop, zip);
                            }
                          }}
                        />
                      );
                    case addEditFormConstants.SUMMARY.id:
                      return (
                        <AddEditShipmentSummary
                          formValues={props.values}
                          initialValues={props.initialValues}
                          step={selectedStep}
                          navigateStep={props.navigateStep}
                          // eslint-disable-next-line react/jsx-no-bind
                          onSubmit={() => {
                            props.saveShipment(props.values, props.initialValues);
                          }}
                          cancel={props.cancelAddEditShipment}
                        />
                      );
                    default:
                      break;
                  }
                })()}
              {props.type === types.EDIT_SHIPMENT && (
                <EditShipmentForm
                  step={selectedStep}
                  stopNumber={props.shipment.stopNumber}
                  initialValues={props.initialValues}
                  // eslint-disable-next-line react/jsx-no-bind
                  onSubmit={() => {
                    props.editShipmentStop(props.values, props.initialValues);
                  }}
                  // eslint-disable-next-line react/jsx-no-bind
                  zipEntered={(stop, zip) => props.zipEntered(stop, zip)}
                  cancel={props.cancelAddEditShipment}
                />
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default withMobileDialog()(injectIntl(AddEditShipmentModal));
