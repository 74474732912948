import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ClockCircleOutlined } from '@ant-design/icons';
import { primaryGreyEighty } from 'styles/colors';
import { shouldUseRecentSSS } from 'common/authorizations';
import CustomButton from '../../themes/CustomButton';

interface INoResultsToDisplay {
  totalResultsCount?: string;
  searchQuery?: string;
  searchFromHistoricalData: () => void;
  isRecentDataSearched: () => boolean;
}

export const NoResultsToDisplay = (props: INoResultsToDisplay) => {
  const isRecentDataSearched = props.isRecentDataSearched();
  const showButtonForExpandedSearch = isRecentDataSearched !== true ? isRecentDataSearched : shouldUseRecentSSS;
  return (
    <Row className="AppliedFilterList align-items-center">
      <Col xs={24} className="no-results-message">
        <h4>
          <FormattedMessage
            id="appliedFilterList.noRecentResultsMessage"
            defaultMessage="No Recent Results for this criteria"
          />
        </h4>
        <i className="material-icons" style={{ color: primaryGreyEighty }}>
          search
        </i>
        <p className="medium-text">
          <FormattedMessage
            id="appliedFilterList.newSearchMessage"
            defaultMessage="Please try a new search or adjust your filters to see more results."
          />
        </p>
        {showButtonForExpandedSearch && (
          <p className="medium-text">
            <FormattedMessage
              id="appliedFilterList.expandSearchMessageForThreeMonths"
              defaultMessage="You can expand your search to include historical results older than three months."
            />
          </p>
        )}
        {showButtonForExpandedSearch && (
          <CustomButton classes="medium mt-3" clickFn={() => props.searchFromHistoricalData()}>
            <ClockCircleOutlined className={'mr-3'} />
            <FormattedMessage id="shipmentList.button.searchAllResults" defaultMessage="Search All Results" />
          </CustomButton>
        )}
      </Col>
    </Row>
  );
};

export default NoResultsToDisplay;
