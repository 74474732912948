import get from 'lodash/get';

import remove from 'lodash/remove';
import every from 'lodash/every';

import { ColumnProps } from 'antd/lib/table';
import { DataSource } from './LineItems.model';

export const conditionallyRemoveColumns = (columnsToCheck: Array<ColumnProps<DataSource>>, data: DataSource[]) => {
  remove(columnsToCheck, (val: ColumnProps<DataSource>) => {
    const shouldRemoveColumn = every(data, (o: any) => {
      return get(o, [`${val.dataIndex}`], ' --- ') === ' --- ';
    });
    return shouldRemoveColumn;
  });
  return columnsToCheck;
};
