import { FormattedMessage } from 'react-intl';

const FooterLink = ({ userPermissions, linkConfig }) => {
  if (hasPermissionsForLink(userPermissions, linkConfig)) {
    return (
      <li>
        <a className="jest" target="_blank" rel="noopener noreferrer" href={linkConfig.endpoint}>
          <FormattedMessage id={linkConfig.languageId} defaultMessage={linkConfig.linkText.defaultMessage} />
        </a>
      </li>
    );
  }

  return null;
};

export function hasPermissionsForLink(userPermissions, linkConfig) {
  return hasPermissions(userPermissions, linkConfig.permissions);
}

/**
 * Determines if the user has any of the required permissions.
 *
 * @param {string[]} userPermissions List of permissions available to the user.
 * @param {string[]} permissions List of required permissions with OR logic.
 */
export function hasPermissions(userPermissions, permissions) {
  // Has permissions if there are no permission requirements.
  if (!permissions || permissions.length === 0) {
    return true;
  }
  if (!userPermissions || userPermissions.length === 0) {
    return false;
  }
  for (let perm of permissions) {
    if (userPermissions.includes(perm)) {
      return true;
    }
  }

  return false;
}

export default FooterLink;
