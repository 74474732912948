import { useState } from 'react';
import cn from 'classnames';
import { Input, Radio, Switch } from 'ui-components';

import { is12HourCycle } from 'common/dateUtils';
import * as styles from './styles.module.scss';
import Label from '../Label';
import { AntdMoment } from '../types';
import { antdMoment } from '../utils';

const isHour12 = is12HourCycle();

export interface TimeSelectProps {
  id?: string;
  className?: string;
  label: JSX.Element | string;
  defaultValue?: AntdMoment;
  disabled?: boolean;
  onChange: (nextTime: AntdMoment) => void;
  value: AntdMoment;
}

const TimeSelect = ({ id, label, className, disabled = false, onChange, value }: TimeSelectProps) => {
  const [isActive, setIsActive] = useState(false);
  const [input, setInput] = useState(isHour12 ? value.format('hh:mm') : value.format('HH:mm'));
  const [isTimeValid, setIsTimeValid] = useState(true);

  const handleChangeh12Postfix = (event: React.ChangeEvent<HTMLInputElement>) => {
    const h12Suffix = event.target.value;
    const nextTime = antdMoment(`${value.format('hh:mm')} ${h12Suffix}`, 'hh:mm A');
    onChange(nextTime);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const nextTime = isHour12 ? antdMoment(value, 'hh:mm') : antdMoment(value, 'HH:mm');

    if (nextTime.isValid()) {
      if (!isTimeValid) {
        setIsTimeValid(true);
      }
      onChange(nextTime);
      setInput(value);
    } else {
      setIsTimeValid(false);
    }
  };

  return (
    <div className={cn('d-flex align-items-center justify-content-center flex-wrap', className)}>
      <div className="d-flex align-items-center m-2">
        <Label className="text-uppercase font-weight-bold mr-4" htmlFor={id}>
          {label}
        </Label>
        <Switch id={id} checked={isActive} onChange={setIsActive} disabled={disabled} />
      </div>
      {isActive && (
        <div className="d-flex align-items-center m-2">
          <Input
            isv2="true"
            className={cn('ml-4', styles['time-select-input'])}
            value={input}
            hasError={!isTimeValid}
            onChange={handleChangeInput}
          />
          {isHour12 && (
            <Radio
              className={cn('ml-4', styles['radio-group'])}
              layout="column"
              onChange={handleChangeh12Postfix}
              defaultValue={value.format('A')}
              radioData={[
                {
                  value: 'AM',
                  label: 'AM',
                },
                {
                  value: 'PM',
                  label: 'PM',
                },
              ]}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TimeSelect;
