import { fork } from 'redux-saga/effects';
import {
  watchLoadMoreAsync,
  watchSearchAsync,
  watchMapSearchAsync,
  watchGetEquipmentIdentifierAsync,
} from '../components/shipmentListComponent/ducks/searchSaga';
import { watchShipmentCountsAsync, watchShipmentCountsSaveAsync } from '../components/shipmentCounts/ducks/operations';
import {
  sessionOperations,
  logoutOperations,
  renewOperations,
  sessionPurgeOperations,
} from '../components/login/LoginForm/ducks';
import { tlShipmentDetailsOperations } from '../components/shipmentDetails/TL/ducks/index';
import { ltlShipmentDetailsOperations } from '../components/shipmentDetails/LTL/ducks/index';
import { oceanShipmentDetailsOperations } from '../components/shipmentDetails/OCEAN/ducks/index';
import { shipmentShareOperations } from '../components/shipmentShare/ducks';
import {
  locationCreateOperations,
  locationUpdateOperations,
  locationDetailOperations,
  locationListOperations,
  locationSuggestionOperations,
} from '../components/locationManagement/ducks';
import { sortResultsOperations } from '../components/common/sortResults/ducks';
import { searchResultsOperations } from '../components/common/search/ducks';
import {
  watchFilterAsync,
  watchSuggestionAsync,
  watchFilterAsyncClear,
  watchModeFilterToggleAsync,
  watchGetTenantAttributeNameAsync,
} from '../components/filter/ducks/operations';
import { addEditShipmentOperations } from '../components/addEditShipment/ducks';
import { configOperations } from '../components/common/hereMapsConfig/ducks';
import hereMapConfigOperations from '../common/typedHereMaps/ducks/operations';
import aerisWeatherConfigOperations from '../common/typedAerisWeather/ducks/operations';
import tableauConfigOperations from '../components/analytics/typedAnalytics/ducks/operations';
import {
  manageNotificationsOperations,
  editNotificationOperations,
  notificationOperations,
} from './../components/notifications/ducks';
import { brandThemingOperations } from '../components/settings/BrandTheming/ducks';
import { tenantAttributesOperations } from '../components/newShipmentDetails/ShipmentIdentifiers/ducks';
import noteOperations from '../components/notes/ducks/operations';
import {
  watchSearchCarrierFleetAsync,
  watchLoadMoreVehiclesAsync,
} from '../components/carrierFleetListComponent/ducks/carrierSearchSaga';

export default function* rootSaga() {
  yield fork(sessionOperations);
  yield fork(logoutOperations);
  yield fork(watchSearchAsync);
  yield fork(watchMapSearchAsync);
  yield fork(watchLoadMoreAsync);
  yield fork(watchFilterAsync);
  yield fork(watchSuggestionAsync);
  yield fork(watchFilterAsyncClear);
  yield fork(watchShipmentCountsAsync);
  yield fork(watchShipmentCountsSaveAsync);
  yield fork(locationDetailOperations);
  yield fork(locationListOperations);
  yield fork(locationSuggestionOperations);
  yield fork(locationCreateOperations);
  yield fork(locationUpdateOperations);
  yield fork(sortResultsOperations);
  yield fork(searchResultsOperations);
  yield fork(tlShipmentDetailsOperations);
  yield fork(ltlShipmentDetailsOperations);
  yield fork(oceanShipmentDetailsOperations);
  yield fork(noteOperations);
  yield fork(tenantAttributesOperations);
  yield fork(shipmentShareOperations);
  yield fork(addEditShipmentOperations);
  yield fork(renewOperations);
  yield fork(editNotificationOperations);
  yield fork(manageNotificationsOperations);
  yield fork(notificationOperations);
  yield fork(configOperations);
  yield fork(hereMapConfigOperations);
  yield fork(aerisWeatherConfigOperations);
  yield fork(tableauConfigOperations);
  yield fork(watchModeFilterToggleAsync);
  yield fork(watchGetTenantAttributeNameAsync);
  yield fork(sessionPurgeOperations);
  yield fork(brandThemingOperations);
  yield fork(watchGetEquipmentIdentifierAsync);
  yield fork(watchSearchCarrierFleetAsync);
  yield fork(watchLoadMoreVehiclesAsync);
}
