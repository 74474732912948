import MultiModalShipmentStatus from '../enums/multiModalShipmentStatus';
import stopType from '../enums/stopType';
import status from '../enums/status';
import { derivedStatusTypeLTL } from '../ltl/enums/derivedStatusTypeLTL';
import DerivedStatusOcean from '../ocean/derivedStatusOcean';

export default class PercentCompleteUtil {
  static getPercentCompleteBasedOnMultiModalDerivedStatus = (multiModalDerivedStatus) => {
    switch (multiModalDerivedStatus) {
      case MultiModalShipmentStatus.SCHEDULED:
        return 0;
      case MultiModalShipmentStatus.COMPLETED:
        return 1;
      default:
        return 0.5;
    }
  };

  static getPercentComplete_FallbackForTL = (currentStopType, shipmentStatus) => {
    switch (currentStopType) {
      case stopType.PICKUP:
        // refers to shipments heading to/at PICKUP
        return 0.5;
      case stopType.DELIVERY:
        switch (shipmentStatus) {
          // refers to shipments that have left DELIVERY
          case status.COMPLETED:
            return 1;
          // refers to shipments heading to/at DELIVERY
          default:
            return 0.5;
        }
      case stopType.ANY:
        switch (shipmentStatus) {
          // refers to starting statuses before PICKUP
          case status.DISPATCHED:
            return 0;
          // refers to: APPROVAL_DENIED, EXPIRED, CANCELED
          case status.COMPLETED:
            return 1;
          // refers to shipments heading to/at STOP
          default:
            return 0.5;
        }
      // unknown
      default:
        return 0.5;
    }
  };

  static getPercentComplete_FallbackForLTL = (derivedStatus) => {
    switch (derivedStatus) {
      case derivedStatusTypeLTL.SCHEDULED:
      case derivedStatusTypeLTL.ATTEMPTING_TO_TRACK:
        return 0;
      case derivedStatusTypeLTL.DELIVERED:
      case derivedStatusTypeLTL.COMPLETED:
      case derivedStatusTypeLTL.EXCEPTION:
      case derivedStatusTypeLTL.CANCELED:
      case derivedStatusTypeLTL.TIMED_OUT:
        return 1;
      default:
        return 0.5;
    }
  };

  static getPercentComplete_FallbackForOcean = (derivedStatus) => {
    switch (derivedStatus) {
      case DerivedStatusOcean.SCHEDULED:
        return 0;
      case DerivedStatusOcean.IN_TRANSIT:
        return 0.5;
      case DerivedStatusOcean.ARRIVING:
      case DerivedStatusOcean.BERTHING:
      case DerivedStatusOcean.ARRIVED_ON_SHIP:
      case DerivedStatusOcean.DISCHARGED:
      case DerivedStatusOcean.AVAILABLE:
        return 0.75;
      case DerivedStatusOcean.COMPLETED:
      case DerivedStatusOcean.CAN_OVERBOARD:
      case DerivedStatusOcean.OUT_OF_NETWORK:
      case DerivedStatusOcean.TRACKING_ERROR:
        return 1;
      default:
        return 0.5;
    }
  };
}
