export const US_STATES_SELECTION = [
  {
    state: 'AL',
    country: 'US',
  },
  {
    state: 'AK',
    country: 'US',
  },
  {
    state: 'AS',
    country: 'US',
  },
  {
    state: 'AZ',
    country: 'US',
  },
  {
    state: 'AR',
    country: 'US',
  },
  {
    state: 'CA',
    country: 'US',
  },
  {
    state: 'CO',
    country: 'US',
  },
  {
    state: 'CT',
    country: 'US',
  },
  {
    state: 'DE',
    country: 'US',
  },
  {
    state: 'DC',
    country: 'US',
  },
  {
    state: 'FM',
    country: 'US',
  },
  {
    state: 'FL',
    country: 'US',
  },
  {
    state: 'GA',
    country: 'US',
  },
  {
    state: 'GU',
    country: 'US',
  },
  {
    state: 'HI',
    country: 'US',
  },
  {
    state: 'ID',
    country: 'US',
  },
  {
    state: 'IL',
    country: 'US',
  },
  {
    state: 'IN',
    country: 'US',
  },
  {
    state: 'IA',
    country: 'US',
  },
  {
    state: 'KS',
    country: 'US',
  },
  {
    state: 'KY',
    country: 'US',
  },
  {
    state: 'LA',
    country: 'US',
  },
  {
    state: 'ME',
    country: 'US',
  },
  {
    state: 'MH',
    country: 'US',
  },
  {
    state: 'MD',
    country: 'US',
  },
  {
    state: 'MA',
    country: 'US',
  },
  {
    state: 'MI',
    country: 'US',
  },
  {
    state: 'MN',
    country: 'US',
  },
  {
    state: 'MS',
    country: 'US',
  },
  {
    state: 'MO',
    country: 'US',
  },
  {
    state: 'MT',
    country: 'US',
  },
  {
    state: 'NE',
    country: 'US',
  },
  {
    state: 'NV',
    country: 'US',
  },
  {
    state: 'NH',
    country: 'US',
  },
  {
    state: 'NJ',
    country: 'US',
  },
  {
    state: 'NM',
    country: 'US',
  },
  {
    state: 'NY',
    country: 'US',
  },
  {
    state: 'NC',
    country: 'US',
  },
  {
    state: 'ND',
    country: 'US',
  },
  {
    state: 'MP',
    country: 'US',
  },
  {
    state: 'OH',
    country: 'US',
  },
  {
    state: 'OK',
    country: 'US',
  },
  {
    state: 'OR',
    country: 'US',
  },
  {
    state: 'PW',
    country: 'US',
  },
  {
    state: 'PA',
    country: 'US',
  },
  {
    state: 'PR',
    country: 'US',
  },
  {
    state: 'RI',
    country: 'US',
  },
  {
    state: 'SC',
    country: 'US',
  },
  {
    state: 'SD',
    country: 'US',
  },
  {
    state: 'TN',
    country: 'US',
  },
  {
    state: 'TX',
    country: 'US',
  },
  {
    state: 'UT',
    country: 'US',
  },
  {
    state: 'VT',
    country: 'US',
  },
  {
    state: 'VI',
    country: 'US',
  },
  {
    state: 'VA',
    country: 'US',
  },
  {
    state: 'WA',
    country: 'US',
  },
  {
    state: 'WV',
    country: 'US',
  },
  {
    state: 'WI',
    country: 'US',
  },
  {
    state: 'WY',
    country: 'US',
  },
];

export const CA_STATES_SELECTION = [
  {
    state: 'AB',
    country: 'CA',
  },
  {
    state: 'BC',
    country: 'CA',
  },
  {
    state: 'MB',
    country: 'CA',
  },
  {
    state: 'NB',
    country: 'CA',
  },
  {
    state: 'NL',
    country: 'CA',
  },
  {
    state: 'NT',
    country: 'CA',
  },
  {
    state: 'NS',
    country: 'CA',
  },
  {
    state: 'NU',
    country: 'CA',
  },
  {
    state: 'ON',
    country: 'CA',
  },
  {
    state: 'PE',
    country: 'CA',
  },
  {
    state: 'QC',
    country: 'CA',
  },
  {
    state: 'SK',
    country: 'CA',
  },
  {
    state: 'YT',
    country: 'CA',
  },
];

export const MX_STATES_SELECTION = [
  {
    state: 'CMX',
    country: 'MX',
  },
  {
    state: 'AGU',
    country: 'MX',
  },
  {
    state: 'BCN',
    country: 'MX',
  },
  {
    state: 'BCS',
    country: 'MX',
  },
  {
    state: 'CAM',
    country: 'MX',
  },
  {
    state: 'CHP',
    country: 'MX',
  },
  {
    state: 'CHH',
    country: 'MX',
  },
  {
    state: 'COA',
    country: 'MX',
  },
  {
    state: 'COL',
    country: 'MX',
  },
  {
    state: 'DUR',
    country: 'MX',
  },
  {
    state: 'GUA',
    country: 'MX',
  },
  {
    state: 'GRO',
    country: 'MX',
  },
  {
    state: 'HID',
    country: 'MX',
  },
  {
    state: 'JAL',
    country: 'MX',
  },
  {
    state: 'MIC',
    country: 'MX',
  },
  {
    state: 'MOR',
    country: 'MX',
  },
  {
    state: 'MEX',
    country: 'MX',
  },
  {
    state: 'NAY',
    country: 'MX',
  },
  {
    state: 'NLE',
    country: 'MX',
  },
  {
    state: 'OAX',
    country: 'MX',
  },
  {
    state: 'PUE',
    country: 'MX',
  },
  {
    state: 'QUE',
    country: 'MX',
  },
  {
    state: 'ROO',
    country: 'MX',
  },
  {
    state: 'SLP',
    country: 'MX',
  },
  {
    state: 'SIN',
    country: 'MX',
  },
  {
    state: 'SON',
    country: 'MX',
  },
  {
    state: 'TAB',
    country: 'MX',
  },
  {
    state: 'TAM',
    country: 'MX',
  },
  {
    state: 'TLA',
    country: 'MX',
  },
  {
    state: 'VER',
    country: 'MX',
  },
  {
    state: 'YUC',
    country: 'MX',
  },
  {
    state: 'ZAC',
    country: 'MX',
  },
];
