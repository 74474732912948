import get from 'lodash/get';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import {
  createStops,
  createLastUpdated,
  createForecast,
  createPercentageComplete,
  createFlagData,
  getArrivalEstimateDate,
  getLastUpdateDate,
} from './mappingUtils';
import { statusMap, statusOptions, basicStopStatuses } from './enums';

export const mapStatus = (shipment, statusModel) => {
  const stopType = get(shipment, 'derivedStatusCodes[0].value', statusOptions.types.UNKNOWN);
  const statusObj = statusMap[stopType];
  const updatedStops = createStops(
    stopType,
    shipment.shipmentStops,
    shipment.latestStopStatuses,
    shipment.latestStatusUpdate,
    statusObj.milestones
  );

  const lastUpdateDate = shipment.latestStatusUpdate ? getLastUpdateDate(shipment.latestStatusUpdate) : undefined;
  const pickupStop = find(shipment.shipmentStops, { stopType: 'ORIGIN' });
  const deliveryStop = find(shipment.shipmentStops, { stopType: 'DESTINATION' });

  const verifiedLatestStopStatuses = get(shipment, 'latestStopStatuses', false);

  let pickupLatestStopStatuses;
  let deliveryLatestStopStatuses;

  if (verifiedLatestStopStatuses) {
    pickupLatestStopStatuses = find(verifiedLatestStopStatuses, { stopNumber: pickupStop.stopNumber });
    if (deliveryStop) {
      deliveryLatestStopStatuses = find(verifiedLatestStopStatuses, { stopNumber: deliveryStop.stopNumber });
    }
    if (isNil(pickupLatestStopStatuses) || isNil(deliveryLatestStopStatuses)) {
      shipment.latestStopStatuses[0].stopNumber = 1;
      shipment.latestStopStatuses[verifiedLatestStopStatuses.length - 1].stopNumber = verifiedLatestStopStatuses.length;

      pickupLatestStopStatuses = shipment.latestStopStatuses[0];
      deliveryLatestStopStatuses = shipment.latestStopStatuses[verifiedLatestStopStatuses.length - 1];
    }
  } else {
    pickupLatestStopStatuses = basicStopStatuses[0];
    deliveryLatestStopStatuses = basicStopStatuses[1];
  }

  const arrivalEstimate = getArrivalEstimateDate(
    stopType,
    pickupStop,
    pickupLatestStopStatuses,
    deliveryStop,
    deliveryLatestStopStatuses,
    lastUpdateDate
  );
  let flagData;
  if (shipment?.deliveryStopInfo?.dateRange) {
    flagData = createFlagData(
      shipment.deliveryStopInfo.dateRange,
      arrivalEstimate,
      statusObj.flag,
      shipment.estimatedMinutesLate,
      shipment.currentStopArrivalCode
    );
  }
  return {
    ...statusModel,
    transitStatus: statusObj.transitStatus,
    flag: flagData,
    arrivalForecast: createForecast(
      arrivalEstimate,
      shipment.currentStopArrivalCode,
      statusOptions.arrivalForecast,
      stopType
    ),
    progress: {
      displayMarker: statusObj.progress.displayMarker,
      markerColor: statusObj.progress.markerColor || flagData.color,
      markerCompletion: statusObj.progress.markerCompletion || createPercentageComplete(updatedStops),
    },
    stops: updatedStops,
    trackingMethod: statusObj.trackingMethod,
    trackingInfo: statusObj.trackingInfo,
    lastUpdated: lastUpdateDate ? createLastUpdated(lastUpdateDate, statusObj.lastUpdated) : undefined,
  };
};
