import { Dispatch, SetStateAction, useState } from 'react';
import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { SettingFilled, EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { black, dustGray } from 'styles/colors';
import { MAX_PAGE_QUANTITY } from 'common/AppConstants';
import { Button as SimpleButton, Tooltip } from 'ui-components';
import { StyledButton as Button } from 'components/common/ListPageLayout/ListPageLayout';
import { ReactComponent as ConfigIcon } from 'components/common/assets/config-icon.svg';
import { trackEvent } from 'common/eventTracker';

import {
  StyledIconButton,
  StyledTable,
  StyledModal,
  StyledH1,
  StyledConfigTable,
  StyledLock,
  StyledToggleButtom,
} from 'common/ConfigurableTable/styledComponents';

import './styles.scss';

interface ConfigurableTableProps {
  entityType: string;
  columns: any[] | undefined;
  dataResults: any[];
  isLoading: boolean;
  onPageChangeFn: Dispatch<SetStateAction<number>>;
  totalResults: number | undefined;
  tableType: string;
  onRowClick: Function;
}

const DragHandle = SortableHandle(() => (
  <SimpleButton type="link">
    <ConfigIcon />
  </SimpleButton>
));

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />);

const SortableContainerWrap = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
  <tbody {...props} />
));

const ITEMS_PAGE_SIZE = 30;

export const setPaginationSize = (total = 0): number => {
  const currentSize = total / ITEMS_PAGE_SIZE;
  return currentSize > MAX_PAGE_QUANTITY ? ITEMS_PAGE_SIZE * MAX_PAGE_QUANTITY : total;
};

const setUpColumns = (columns: any, parsedStoredColumns: any, tableType: any): any => {
  const finalColumns = parsedStoredColumns.filter((value: any) => Object.keys(value).length !== 0);

  columns.forEach((column: any) => {
    const match = finalColumns.find((storedColumn: any) => column.title === storedColumn.title);

    if (!match) finalColumns.push(column);
  });
  window.localStorage.setItem(tableType, JSON.stringify(finalColumns));

  return finalColumns;
};

const ConfigurableTable: React.FC<ConfigurableTableProps> = ({
  entityType,
  dataResults,
  isLoading,
  onPageChangeFn,
  totalResults,
  columns = [],
  tableType,
  onRowClick,
}) => {
  const storedcolumns = window.localStorage.getItem(tableType);
  const parsedStoredColumns = storedcolumns
    ? JSON.parse(storedcolumns).map((storedColumn: any) => {
        const match = columns.find((column) => column.title === storedColumn.title);

        if (!match) return {};

        if (match.dataIndex !== storedColumn.dataIndex) {
          storedColumn.dataIndex = match.dataIndex;
        }

        return {
          ...storedColumn,
          render: match?.render,
        };
      })
    : [];

  const columnsToShow = setUpColumns(columns, parsedStoredColumns, tableType);

  const enhancedColumns = columnsToShow.map((column: any, index: any) => ({ ...column, index, key: index }));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [configurableColumns, setConfigurableColumns] = useState<any[]>(enhancedColumns);

  const intl = useIntl();

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([...configurableColumns], oldIndex, newIndex)
        .filter((el) => !!el)
        .map((c: object, index) => ({ ...c, index: index, key: index }));

      setConfigurableColumns(newData);
    }
  };

  const DraggableBodyRow = ({
    className,
    style,
    ...restProps
  }: {
    className: string;
    style: string;
    'data-row-key': string;
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = configurableColumns.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerWrap useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const toggleColumns = (record: any) => {
    const newColumns = [...configurableColumns];
    newColumns.find((column) => column.index === record.index).visible = !record.visible;
    setConfigurableColumns(newColumns);
  };

  const onClickHandler = () => {
    setIsModalOpen(false);
    window.localStorage.setItem(tableType, JSON.stringify(configurableColumns));
  };

  return (
    <>
      <div className="position-relative">
        <Tooltip trigger="hover" placement="left" title={'Table Settings'}>
          <StyledIconButton
            type="link"
            onClick={() => {
              trackEvent(`${entityType}_LIST_SETTINGS`);
              setIsModalOpen(true);
            }}
          >
            <SettingFilled data-testid="config-button" />
          </StyledIconButton>
        </Tooltip>
        <StyledTable
          size="middle"
          scroll={{ x: 1200, y: 640 }}
          loading={isLoading}
          rowKey={(record) => record.id}
          dataSource={dataResults}
          columns={columnsToShow.filter((column: any) => column.visible)}
          onChange={(pagination) => {
            if (pagination.current) {
              onPageChangeFn(pagination.current);
            }
          }}
          rowClassName={() => 'cursor-pointer'}
          // @ts-ignore
          onRow={onRowClick}
          showSorterTooltip={false}
          pagination={{
            total: setPaginationSize(totalResults),
            pageSize: ITEMS_PAGE_SIZE,
            showSizeChanger: false,
          }}
        />
      </div>
      {isModalOpen && (
        <StyledModal visible={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} width="100%">
          <StyledH1 data-testid="table-settings-title">
            <FormattedMessage id={'configurableTable.modal.title'} defaultMessage={'Table Settings'} />
          </StyledH1>
          <p className="mb-4 pb-2">
            <FormattedMessage
              id="configurableTable.modal.description"
              defaultMessage="Customize your table by rearranging the order of the columns. You can also turn the visibility of columns on or off by clicking the icon."
            />
          </p>
          <div className="position-relative mb-5 pb-2">
            <StyledConfigTable
              size="middle"
              pagination={false}
              dataSource={configurableColumns}
              className="table-borderless"
              scroll={{ y: 550 }}
              columns={[
                {
                  title: intl.formatMessage({ id: 'tableConfig.settings.visible', defaultMessage: 'Visible' }),
                  dataIndex: 'visible',
                  align: 'center',
                  className: 'drag-visible',
                  // eslint-disable-next-line react/display-name
                  render: (visible, record) => {
                    if (record.locked) return <StyledLock />;

                    if (visible)
                      return (
                        <StyledToggleButtom onClick={() => toggleColumns(record)} color={black} type="link">
                          <EyeFilled data-testid="visible-button" />
                        </StyledToggleButtom>
                      );

                    return (
                      <StyledToggleButtom onClick={() => toggleColumns(record)} color={dustGray} type="link">
                        <EyeInvisibleFilled data-testid="invisible-button" />
                      </StyledToggleButtom>
                    );
                  },
                  width: 73,
                  key: 1,
                },
                {
                  title: intl.formatMessage({ id: 'tableConfig.settings.columnName', defaultMessage: 'Column Name' }),
                  dataIndex: 'title',
                  className: 'drag-visible',
                  key: 2,
                  width: 250,
                },
                {
                  title: '',
                  dataIndex: 'sort',
                  className: 'drag-visible',
                  // eslint-disable-next-line react/display-name
                  render: () => <DragHandle />,
                  width: 65,
                  key: 3,
                },
              ]}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Button type="primary" className="px-5" onClick={onClickHandler}>
              <FormattedMessage id="settings.settingsHeader.save" defaultMessage="Save" />
            </Button>
          </div>
        </StyledModal>
      )}
    </>
  );
};

export default ConfigurableTable;
