/*eslint no-undef: "error"*/
import { connect } from 'react-redux';
import get from 'lodash/get';
import { compose, lifecycle, withState } from 'recompose';
import {
  getPrincipalAuthorizationsFromState,
  getPrincipalFromState,
  getCustomerPropertiesFromState,
} from 'common/authorizations';
import { trackEvent } from 'common/eventTracker';
import { ShipmentModeEnum } from 'models';
import { tlShipmentDetailsActions } from './ducks/index';
import { configActions } from '../../common/hereMapsConfig/ducks/index';
import TLDetailsComponent from './TLDetailsComponent';
import { geoFenceThresholdSelector } from './ducks/reducers';

const getShipmentDetails = (props, shipmentDetailId, shipmentShareToken, shouldUseRecentSearch) => {
  if (shipmentShareToken) {
    props.getShipmentDetailsPublic(shipmentShareToken);
  } else {
    props.getShipmentDetails(shipmentDetailId, shouldUseRecentSearch);
  }
};

const entitlementInfoSelector = (state) => get(state, 'tlShipmentDetailsReducer.compositeRawResponse.entitlementInfo');

export const isShipmentOwnerSelector = (state) => get(entitlementInfoSelector(state), 'isOwner', false);

/**
 * Returns the customer properties based from the state
 * @param state
 * @returns {*}
 */
const getCustomerProperties = (state) => {
  if (state && state.tlShipmentDetailsReducer) {
    if (!state.tlShipmentDetailsReducer.customerProperties) {
      return getCustomerPropertiesFromState(state);
    }
    return state.tlShipmentDetailsReducer.customerProperties;
  }
  return getCustomerPropertiesFromState(state);
};

const componentWithFetchedData = compose(
  withState('mapInstance', 'setMapInstance', {}),
  lifecycle({
    componentDidMount() {
      const shouldUseRecentSearch = this.props.shouldUseRecentSearch;
      const { shipmentDetailId, shipmentShareToken } = this.props.match.params;
      getShipmentDetails(this.props, shipmentDetailId, shipmentShareToken, shouldUseRecentSearch);

      // If this is a shared shipment, don't make the call to get the HereMaps config.
      // It will result in a 401!!
      if (!this.props.match.params.shipmentShareToken) {
        this.props.getHereMapsConfig();
      }

      trackEvent('SHIPMENT_DETAIL_PAGE_LOAD', {
        mode: ShipmentModeEnum.TL,
        shipmentId: shipmentDetailId,
        isNewDetailPage: false,
      });
    },
    componentWillUnmount() {
      // removes the shipment details state, preventing state persisting when using back button to navigate
      this.props.resetShipment();
    },
  })
)(TLDetailsComponent);

const mapStateToProps = (state) => {
  const authorizations = getPrincipalAuthorizationsFromState(state);
  const principal = getPrincipalFromState(state);
  const isLoggedIn = state.authReducer.isLoggedIn;

  if (state && state.tlShipmentDetailsReducer && state.tlShipmentDetailsReducer.shipmentDetails) {
    const customerStateproperties = getCustomerPropertiesFromState(state);
    state.tlShipmentDetailsReducer.shipmentDetails.startTimeBeforePickupMinutes =
      customerStateproperties.V3_TRUCKLOAD_TRACKING_JOB_START_TIME_BEFORE_PICKUP_IN_MINUTES;
  }

  const customerProperties = isLoggedIn ? null : { customerProperties: getCustomerProperties(state) };

  return {
    shipmentDetails: state.tlShipmentDetailsReducer.shipmentDetails,
    // sharedShipmentMapConfig will only be populated if the details container that is loading is for a shared shipment
    sharedShipmentMapConfig: state.tlShipmentDetailsReducer.hereMapsConfig,
    sensorHistory: state.tlShipmentDetailsReducer.sensorHistory,
    hereMapsConfig: state.configReducer.configReducer.hereMapsConfig,
    hereMapsConfigChina: state.tlShipmentDetailsReducer.hereMapsConfig,
    aerisWeatherConfig: state.aerisWeatherConfigReducer.config,
    isLoggedIn,
    userEditAuthority: authorizations.hasEditShipment(),
    // When the user is in public page but he gets logged out or the server dies, the page blows up because the principal is null - Mark Serrano
    authorizations: authorizations,
    deleteDialogOpen: state.tlShipmentDetailsReducer.deleteDialogOpen,
    nudgeData: state.tlShipmentDetailsReducer.nudgeData,
    nudgeDialogVisible: state.tlShipmentDetailsReducer.nudgeDialogVisible,
    nudgeMessagePreview: state.tlShipmentDetailsReducer.nudgeMessagePreview,
    nudgeDialogActionText: state.tlShipmentDetailsReducer.nudgeDialogActionText,
    nudgeDialogShowingFailureMessage: state.tlShipmentDetailsReducer.nudgeDialogShowingFailureMessage,
    nudgeFailureMessage: state.tlShipmentDetailsReducer.nudgeFailureMessage,
    shipmentDetailsMissing: state.tlShipmentDetailsReducer.error,
    geoFenceThreshold: geoFenceThresholdSelector(state),
    showDeleteShipmentButton: isShipmentOwnerSelector(state),
    principal,
    ...customerProperties,
  };
};

const mapDispatchToProps = (dispatch, state) => ({
  // getSensorHistory: (id, sensorId, sensorType) =>
  // dispatch(tlShipmentDetailsActions.getSensorHistory(id, sensorId, sensorType)),
  getModifiableDetails: (id) => dispatch(tlShipmentDetailsActions.getModifiableDetails(id)),
  getShipmentDetails: (id, shouldUseRecentSearch) =>
    dispatch(tlShipmentDetailsActions.getShipmentDetails(id, shouldUseRecentSearch)),
  getShipmentDetailsPublic: (shipmentShareToken) =>
    dispatch(tlShipmentDetailsActions.getShipmentDetailsPublic(shipmentShareToken)),
  getHereMapsConfig: () => dispatch(configActions.getConfig()),
  deleteShipment: (shipmentId) => dispatch(tlShipmentDetailsActions.deleteShipment(shipmentId)),
  resetShipment: () => dispatch(tlShipmentDetailsActions.resetShipment()),
  toggleDeleteDialog: (isDeleteDialogOpen) => dispatch(tlShipmentDetailsActions.toggleDeleteDialog(isDeleteDialogOpen)),
  openNudgeDialog: (shipmentId) => dispatch(tlShipmentDetailsActions.openNudgeDialog(shipmentId)),
  sendNudge: (shipmentId) => dispatch(tlShipmentDetailsActions.sendNudge(shipmentId)),
  closeNudgeDialog: () => dispatch(tlShipmentDetailsActions.closeNudgeDialog()),
  getMilesData: (shipmentDetails) => dispatch(tlShipmentDetailsActions.getMilesData(shipmentDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(componentWithFetchedData);
