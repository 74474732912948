import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { MONTH_DAY_YEAR_FORMAT } from 'i18n/configurei18n';
import { requiredValidation } from '../../../common/FormValidations';
import { renderInput, renderDatePicker } from '../../common/forms/FormInputs';

const messages = defineMessages({
  startDate: {
    id: 'addShipment.shipmentDateFormSection.startDate',
    defaultMessage: 'Start Date',
  },
  startTime: {
    id: 'addShipment.shipmentDateFormSection.startTime',
    defaultMessage: 'Start Time',
  },
  endDate: {
    id: 'addShipment.shipmentDateFormSection.endDate',
    defaultMessage: 'End Date',
  },
  endTime: {
    id: 'addShipment.shipmentDateFormSection.endTime',
    defaultMessage: 'End Time',
  },
});

const ShipmentDateFormSection = (props) => {
  return (
    <div className="shipment-date">
      <h4 className="form-header">
        <FormattedMessage id={props.sectionHeader?.id} defaultMessage={props.sectionHeader?.defaultMessage} />
      </h4>
      <div className="form-row date-time-row">
        <Field
          type="text"
          label={props.intl.formatMessage(messages.startDate)}
          component={renderDatePicker}
          validate={[requiredValidation]}
          format={null}
          name="startDate"
          placeholder={MONTH_DAY_YEAR_FORMAT}
        />
        <Field
          type="time"
          label={props.intl.formatMessage(messages.startTime)}
          classes="col"
          component={renderInput}
          validate={[requiredValidation]}
          name="startTime"
          placeholder="12:17 PM"
        />
        <div className="date-time-separator">
          <FormattedMessage id="addShipment.shipmentDateFormSection.to" defaultMessage="to" />
        </div>
        <Field
          type="text"
          label={props.intl.formatMessage(messages.endDate)}
          format={null}
          component={renderDatePicker}
          validate={[requiredValidation]}
          name="endDate"
          placeholder={MONTH_DAY_YEAR_FORMAT}
        />
        <Field
          type="time"
          label={props.intl.formatMessage(messages.endTime)}
          classes="col"
          component={renderInput}
          validate={[requiredValidation]}
          name="endTime"
          placeholder="12:17 PM"
        />
      </div>
    </div>
  );
};

export default injectIntl(ShipmentDateFormSection);
