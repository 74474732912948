export enum DocumentTypes {
  BILL_OF_LADING = 'Bill of Lading',
  DELIVERY_RECEIPT = 'Delivery Receipt',
  INSPECTION_CERTIFICATE = 'Inspection Certificate',
  INVOICE = 'Invoice',
  LETTER_OF_AUTHORIZATION = 'Letter Of Authorization',
  LUMPER_CERTIFICATE = 'Lumper Certificate',
  PACKING_SLIP = 'Packing Slip',
  WEIGHT_CERTIFICATE = 'Weight Certificate',
  // please manually add reverse mapping until there is ts support.
  // (currently no ts support as of 12/22/2021.)
  'Bill of Lading' = 'BILL_OF_LADING',
  'Delivery Receipt' = 'DELIVERY_RECEIPT',
  'Inspection Certificate' = 'INSPECTION_CERTIFICATE',
  'Invoice' = 'INVOICE',
  'Letter Of Authorization' = 'LETTER_OF_AUTHORIZATION',
  'Lumper Certificate' = 'LUMPER_CERTIFICATE',
  'Packing Slip' = 'PACKING_SLIP',
  'Weight Certificate' = 'WEIGHT_CERTIFICATE',
}

export enum DocumentScope {
  CARRIER = 'CARRIER',
  DRIVER = 'DRIVER',
  SHIPPER = 'SHIPPER',
}

export default DocumentTypes;
