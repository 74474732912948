import { reduxForm } from 'redux-form';
import './AddEditShipmentTempForm.scss';
import AddEditShipmentButtons from '../AddEditShipmentButtons/AddEditShipmentButtons';
import AddEditShipmentTempFormSection from './AddEditShipmentTempFormSection';

let AddEditShipmentTempForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} className="add-edit-shipment-temp-form">
      <AddEditShipmentTempFormSection
        formName={props.formName}
        formValues={props.formValues}
        setTemperatureScale={props.setTemperatureScale}
        {...props}
      />
      <AddEditShipmentButtons
        step={props.step}
        navigateStep={props.navigateStep}
        onSubmit={props.onSubmit}
        cancel={props.cancel}
        skipTempTracking={props.skipTempTracking}
        formValues={props.formValues}
      />
    </form>
  );
};

AddEditShipmentTempForm = reduxForm({
  form: 'AddEditShipment',
  destroyOnUnmount: false,
})(AddEditShipmentTempForm);

export default AddEditShipmentTempForm;
