export const renderApiErrors = (errors, heading) => {
  if (!errors) {
    return;
  }

  return (
    <div className="text-danger text-center">
      We’re sorry. There seems to be a problem{heading ? ` and we're unable to ${heading}. ` : '. '}
      Please try again or contact your project44 representative if the problem persists.
      <br />
      <br />
      <strong>
        Error Summary {errors.status} &ndash; {errors.message}:
      </strong>{' '}
      {errors.errors}
    </div>
  );
};
