import { IntlShape } from 'react-intl';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

export const formatNestedIntlValues = (
  values: any = {},
  intl: IntlShape
): { [key: string]: string | number | null | undefined } => {
  return Object.keys(values).reduce((acc: { [key: string]: string | number }, val: any) => {
    if (!isNil(values[val]) && typeof values[val] === 'object' && values[val].id) {
      // Values have a nested id, defaultMessage, and values object that needs translation
      acc[val] = intl.formatMessage(
        {
          defaultMessage: values[val].defaultMessage,
          id: values[val].id,
        },
        get(values[val], 'values', {})
      );
    } else if (!isNil(values[val]) && typeof values[val] === 'object' && get(values[val], 'message.id')) {
      // Values have a nested 'message' object that needs translation
      acc[val] = intl.formatMessage(
        {
          defaultMessage: values[val].message.defaultMessage,
          id: values[val].message.id,
        },
        get(values[val], 'values', {})
      );
    } else if (!isNil(values[val]) && typeof values[val] !== 'object') {
      // Just use the original string value. No need to formatMessage
      acc[val] = values[val];
    }
    return acc;
  }, {});
};
