import { MODE_FILTERS } from 'common/modeFilters';
import FilterUtil from '../util/filterUtil';

import MultiModeFilters from './MultiModeFilters';
import TruckloadModeFilters from './TruckloadModeFilters';
import LTLModeFilters from './LTLModeFilters';
import OceanModeFilters from './OceanModeFilters';
import ParcelModeFiltersComponent from './ParcelModeFiltersComponent';
import RailModeFiltersComponent from './RailModeFilters';
import AirModeFilters from './AirModeFilters';

const selectedMode = (modes) => modes && modes.length === 1 && modes[0];
const isTruckload = (modes) => selectedMode(modes) === MODE_FILTERS.TRUCKLOAD;
const isLTL = (modes) => selectedMode(modes) === MODE_FILTERS.LTL;
const isOcean = (modes) => selectedMode(modes) === MODE_FILTERS.OCEAN;
const isParcel = (modes) => selectedMode(modes) === MODE_FILTERS.PARCEL;
const isRail = (modes) => selectedMode(modes) === MODE_FILTERS.RAIL;
const isAir = (modes) => selectedMode(modes) === MODE_FILTERS.AIR;

export const getModeComponents = (props) => {
  const modes = FilterUtil.getModesForFilterItems(props.modeFilters, props.authorities);

  if (isTruckload(modes)) {
    return <TruckloadModeFilters {...props} />;
  } else if (isLTL(modes)) {
    return <LTLModeFilters {...props} />;
  } else if (isOcean(modes)) {
    return <OceanModeFilters {...props} />;
  } else if (isParcel(modes)) {
    return <ParcelModeFiltersComponent {...props} />;
  } else if (isRail(modes)) {
    return <RailModeFiltersComponent {...props} />;
  } else if (isAir(modes)) {
    return <AirModeFilters {...props} />;
  } else {
    return <MultiModeFilters {...props} />;
  }
};
