import styled from 'styled-components';
import { MultiSelect } from 'ui-components';
import SelectedChips from '../forms/selectedChips/SelectedChips';
const StyledSelect = styled.div`
  [role='menuitem'] {
    font-size: 12px !important;
    line-height: 32px !important;
    min-height: 32px !important;
    padding: 0 24px !important;
  }

  [role='menu'] {
    padding: 0 !important;
  }
`;
const AutocompleteFilter = ({
  idProp,
  placeholder,
  dataSource = [],
  updateFn,
  newRequestFn,
  listData,
  showAllChips,
  passedInDeleteFn,
  initialAmountVisible,
  showHideAllFn,
  dataLocator,
}) => {
  return (
    <>
      <StyledSelect className="filter-container" data-locator={dataLocator}>
        <MultiSelect
          id={idProp}
          dataSource={dataSource}
          onSearch={updateFn}
          onSelect={newRequestFn}
          onDeselect={passedInDeleteFn}
          value={listData}
          maxSearchResults={6}
          placeholder={placeholder}
        />
      </StyledSelect>
      <div>
        <SelectedChips
          listData={listData}
          showAllChips={showAllChips}
          passedInDeleteFn={passedInDeleteFn}
          initialAmountVisible={initialAmountVisible}
          showHideAllFn={showHideAllFn}
        />
      </div>
    </>
  );
};

export default AutocompleteFilter;
