import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import qs from 'qs';
import { API_PATH } from 'common/AppConstants';
import { trackEvent } from 'common/eventTracker';
import * as styles from './OrdersExport.module.scss';

interface OrdersExportProps {
  filter: any;
  sortItems: any[];
  sortAttr: any;
  ordersCount: number;
  searchText: string;
}

const OrdersExport = ({ filter, sortItems, sortAttr, ordersCount, searchText }: OrdersExportProps) => {
  const intl = useIntl();
  const customFilterKeys = [
    'additionalOrderIdentifierCriteria',
    'orderTagCriteria',
    'originalDeliveryDateTimeWindowStart',
    'originalDeliveryDateTimeWindowEnd',
    'supplierReadyDateTimeWindowStart',
    'supplierReadyDateTimeWindowEnd',
    'orderSubmissionDateTime',
  ];

  const params = sortAttr
    ? {
        ...filter,
        searchText,
        sort: `${sortItems[sortAttr].fieldName}:${sortItems[sortAttr].sortDirection}`,
      }
    : { ...filter, searchText };

  const queryParams = Object.entries(params).map((item) => {
    if (customFilterKeys.includes(item[0])) {
      const obj = { [item[0]]: JSON.stringify(item[1]) };
      return qs.stringify(obj, { encode: true });
    } else {
      const obj = { [item[0]]: item[1] };
      return qs.stringify(obj, { arrayFormat: 'repeat', allowDots: true });
    }
  });

  return (
    <>
      {ordersCount && ordersCount <= 5000 ? (
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          data-locator="order-export"
          onClick={() => {
            trackEvent('ORDER_CSV_DOWNLOAD');
          }}
          href={`${API_PATH}/inventory/orders/export?${queryParams.join('&')}`}
        >
          <FormattedMessage id="shipmentList.exportData.downloadCSV" defaultMessage="Download CSV">
            {(...text) => (
              <span>
                {text} <DownloadOutlined style={{ fontSize: '24px' }} />
              </span>
            )}
          </FormattedMessage>
        </a>
      ) : (
        <Tooltip
          title={intl.formatMessage({
            id: 'ordersList.exportData.tooManyRecords',
            defaultMessage: 'You can only download up to 5000 records at a single time.',
          })}
        >
          <span className={`${styles.link} ${styles.disabledLink}`}>
            <FormattedMessage id="shipmentList.exportData.downloadCSV" defaultMessage="Download CSV">
              {(...text) => (
                <span className={styles.downloadCSV}>
                  {text} <DownloadOutlined style={{ fontSize: '24px' }} />
                </span>
              )}
            </FormattedMessage>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default OrdersExport;
