import { useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { format } from 'date-fns';

import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import {
  intlLocationTypeList,
  intlOrderAdditionalIdentifierList,
  intlOrderTagList,
  intlShipmentStatusList,
} from 'components/Inventory/models';
import { trackEvent } from 'common/eventTracker';
import OrdersFilters from './OrdersFilter';
import locationFilterApiNames from './filters/locationFilterApiNames';
import { formatCriteriaFilter, getDefaultTypeValues } from './OrdersList';
import LocationMessages from './filters/LocationMessages.json';

// TODO fill in types beyond just 'any'

const getDefaultLocationValues = (data: any) => {
  const locationApiNames = Object.keys(locationFilterApiNames);
  const locationObj = omitBy(pick(data, locationApiNames), isNil);

  if (isEmpty(locationObj)) return [];

  const currentKeys = Object.keys(locationObj);
  return currentKeys.map((k) => {
    // @ts-ignore
    return `${locationFilterApiNames[k]}:${data[k]}`;
  });
};

const formatDate = (strDate: string) => {
  if (!strDate) return null;

  return format(new Date(strDate), "yyyy-MM-dd'T'HH:mm:ssxx");
};

interface EncapsulatedOrdersFiltersProps {
  filter: { [key: string]: any };
  setFilter: (filter: { [key: string]: any }) => void;
}

const EncapsulatedOrdersFilters = ({ filter, setFilter }: EncapsulatedOrdersFiltersProps) => {
  const setFilterWrapper = (obj: { [key: string]: any }) => {
    const filterKeys = Object.keys(obj);
    if (filterKeys.length > 0) {
      trackEvent('ORDER_LIST_ADD_FILTER', {
        key: filterKeys[0] || '', // explicitly extract the key (this will make it searchable in Mixpanel, Pendo, etc
        value: obj[filterKeys[0]], // explicitly extract the value (this will make it searchable in Mixpanel, Pendo, etc
      });
    }

    setFilter((prevState: { [key: string]: any }) => {
      return {
        ...prevState,
        ...obj,
      };
    });
  };

  const setDateRange = (key: string, startDate: string, endDate: string) => {
    const newEndDate = new Date(startDate);
    newEndDate.setDate(newEndDate.getDate() + 1);
    const updatedEndDate = endDate ? endDate : newEndDate.toISOString();

    return setFilterWrapper({
      [key]: {
        startDateTime: formatDate(startDate),
        startDateTimeInclusive: !!startDate,
        endDateTime: formatDate(updatedEndDate),
        endDateTimeInclusive: !!endDate,
      },
    });
  };

  const intl = useIntl();

  const shipmentStatusList = intlShipmentStatusList.map((item: { value: any; message: MessageDescriptor }) => ({
    value: item.value,
    displayValue: intl.formatMessage(item.message),
  }));

  const locationTypeList = intlLocationTypeList.map(
    (item: { message: { id: string; defaultMessage: string }; value: string; apiValue: string }) => ({
      value: item.value,
      displayValue: intl.formatMessage(item.message),
    })
  );

  const additionalOrderTypeList = intlOrderAdditionalIdentifierList.map(
    (item: { message: { id: string; defaultMessage: string }; value: string }) => ({
      value: item.value,
      displayValue: intl.formatMessage(item.message),
    })
  );

  const orderTagTypeList = intlOrderTagList.map(
    (item: { message: { id: string; defaultMessage: string }; value: string }) => ({
      value: item.value,
      displayValue: intl.formatMessage(item.message),
    })
  );

  const locationValues = getDefaultLocationValues(filter);

  // location
  // TODO: locationType can be refactored and removed
  const [locationType, setLocationType] = useState<string | undefined>(undefined);

  const additionalOrderFilterName = 'additionalOrderIdentifierCriteria';
  const orderTagFilterName = 'orderTagCriteria';

  // additional order identifiers
  // TODO: additionalOrderIdType can be refactored and removed
  const [additionalOrderIdType, setAdditionalOrderIdType] = useState<string | undefined>(undefined);
  const additionalOrderIdValues = getDefaultTypeValues(additionalOrderFilterName, filter);

  // order tags
  // TODO: orderTagType can be refactored and removed
  const [orderTagType, setOrderTagType] = useState<string | undefined>(undefined);
  const orderTagValues = getDefaultTypeValues(orderTagFilterName, filter);

  const setLocationFilter = (values: any) => {
    const locationFilters = values.reduce((acc: any, location: any) => {
      const type = location.split(':')[0].toLowerCase();
      const apiValue = location.split(':')[1];

      // @ts-ignore
      const currentKey = LocationMessages[type].apiValue;
      const currentApiValue = acc[currentKey] || '';

      return {
        ...acc,
        [currentKey]: `${currentApiValue} ${apiValue}`.trim(),
      };
    }, {});

    const emptyLocationFilters = Object.keys(locationFilterApiNames).reduce(
      (acc, name) => ({ ...acc, [name]: undefined }),
      {}
    );

    if (isEmpty(locationFilters)) {
      return setFilterWrapper(emptyLocationFilters);
    }

    // if we don't set a full set of location filters, removing chip inputs won't work properly
    const fullLocationFilters = {
      ...emptyLocationFilters,
      ...locationFilters,
    };

    return setFilterWrapper(fullLocationFilters);
  };

  const setTypeValueFilter = (filterName: string, values: ((prevState: string[]) => string[]) | string[]) => {
    const filterValue = formatCriteriaFilter(values);
    return setFilterWrapper({ [filterName]: filterValue });
  };

  // ShipmentAssociated works with the strings 'true' and 'false' rather than the boolean values. either we need to do this
  // or do something in OrdersFilter. the value comes back from the API as boolean
  const hasShipmentIds =
    filter.hasShipmentIds === null || filter.hasShipmentIds === undefined
      ? filter.hasShipmentIds
      : String(filter.hasShipmentIds);
  return (
    <OrdersFilters
      // order type filter
      // @ts-ignore
      orderTypescheckedValuesRaw={filter.orderTypes}
      orderTypesSetValuesFn={(value) => setFilterWrapper({ orderTypes: value })}
      // @ts-ignore
      statusCodescheckedValuesRaw={filter.statusCodes}
      statusCodeSetValuesFn={(value) => setFilterWrapper({ statusCodes: value })}
      // shipment associated filter
      // @ts-ignore
      shipmentAssociatedValueRaw={hasShipmentIds}
      shipmentAssociatedValueFn={(value) => setFilterWrapper({ hasShipmentIds: value ? value : undefined })}
      shipmentStatusList={shipmentStatusList}
      setOrderHealthCriteria={(value) => setFilterWrapper({ orderHealthCriteria: value ? value : [] })}
      orderHealthCriteria={filter.orderHealthCriteria || []}
      // order submission date time
      orderSubDateRaw={filter.orderSubmissionDateTime}
      setOrderSubDate={(startDate: any, endDate: any) => {
        setDateRange('orderSubmissionDateTime', startDate, endDate);
      }}
      // supplier ready date time
      supplierReadyDateRaw={filter.supplierReadyDateTimeWindowStart}
      setSupplierReadyDate={(startDate: any, endDate: any) => {
        setDateRange('supplierReadyDateTimeWindowStart', startDate, endDate);
      }}
      // original delivery date time
      originalDeliveryDateRaw={filter.originalDeliveryDateTimeWindowEnd}
      setOriginalDeliveryDate={(startDate: any, endDate: any) => {
        setDateRange('originalDeliveryDateTimeWindowEnd', startDate, endDate);
      }}
      // location
      locationTypeList={locationTypeList}
      locationType={locationType}
      setLocationType={setLocationType}
      locationValues={locationValues}
      setLocationValues={(values) => {
        setLocationFilter(values);
      }}
      // additional order identifiers
      setAdditionalOrderIdType={setAdditionalOrderIdType}
      additionalOrderIdType={additionalOrderIdType}
      additionalOrderTypeList={additionalOrderTypeList}
      additionalOrderIdValues={additionalOrderIdValues}
      setAdditionalOrderIdValues={(values) => {
        setTypeValueFilter(additionalOrderFilterName, values);
      }}
      // order tags
      setOrderTagType={setOrderTagType}
      orderTagType={orderTagType}
      orderTagTypeList={orderTagTypeList}
      orderTagValues={orderTagValues}
      setOrderTagValues={(values) => {
        setTypeValueFilter(orderTagFilterName, values);
      }}
      // specific order number
      specificOrderNumber={filter.orderIdentifiers || []}
      setSpecificOrderNumber={(values) => {
        setFilterWrapper({ orderIdentifiers: values });
      }}
    />
  );
};

export default EncapsulatedOrdersFilters;
