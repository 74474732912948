import { Component } from 'react';
import * as React from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import get from 'lodash/get';
import { defineMessages, FormattedMessage } from 'react-intl';
import { TenantAttribute } from 'models';
import { Input, Button } from 'ui-components';
import { chunk } from 'common/chunkUtils';
import { ThemeContext } from 'contexts/ThemeContext';
import SettingsHeader from '../SettingsHeader/SettingsHeader';
import SettingsNavigation from '../SettingsNavigation/SettingsNavigation';
import settingsRoutes from '../settingsRoutes';

import Progress from '../../common/progress/Progress';
import * as styles from './ReferenceData.module.scss';

import { ReferenceDataStateProps, ReferenceDataDispatchProps } from './ReferenceDataContainer';

const messages = defineMessages({
  tenantKeyInput: {
    id: 'shipmentDetails.shipmentIdentifiers.INPUT',
    defaultMessage: 'Search for or create new reference data keys',
  },
  duplicateTenantKey: {
    id: 'shipmentDetails.shipmentIdentifiers.DUPLICATE',
    defaultMessage: 'This key already exists',
  },
});

export interface ReferenceDataState {
  refDataKey: string;
  filteredTenantAttributes: TenantAttribute[];
  isDuplicate: boolean;
}
class ReferenceDataComponent extends Component<
  ReferenceDataStateProps & ReferenceDataDispatchProps,
  ReferenceDataState
> {
  constructor(props: ReferenceDataStateProps & ReferenceDataDispatchProps) {
    super(props);
    this.state = {
      refDataKey: '',
      filteredTenantAttributes: [],
      isDuplicate: false,
    };
  }

  public componentDidMount() {
    this.props.fetchTenantAttributes();
  }

  componentDidUpdate(prevProps: ReferenceDataStateProps & ReferenceDataDispatchProps) {
    if (prevProps.isFetchingTenantAttributes && !this.props.isFetchingTenantAttributes) {
      this.filterTenantAttributes(this.state.refDataKey);
    }
    if (prevProps.isCreatingTenantAttributes && !this.props.isCreatingTenantAttributes) {
      this.filterTenantAttributes('');
    }
  }

  static contextType = ThemeContext;

  handleOnKeyChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!evt) {
      return undefined;
    }
    if (get(evt, 'target')) {
      const target = evt.target;
      if (get(target, 'value')) {
        this.filterTenantAttributes(target.value);
      }
    }
  };
  addRefDataKey = () => {
    if (this.state.refDataKey) {
      this.props.createTenantAttributes([{ attributeName: this.state.refDataKey }]);
    }
  };
  filterTenantAttributes = (refDataKey: string) => {
    const filteredResults: TenantAttribute[] = (this.props.tenantAttributes || [])
      .filter((tenantAttribute) => tenantAttribute.attributeName.toLowerCase().search(refDataKey.toLowerCase()) !== -1)
      .sort((t1, t2) => t1.attributeName.localeCompare(t2.attributeName));
    this.setState({
      ...this.state,
      refDataKey,
      filteredTenantAttributes: filteredResults,
      isDuplicate: filteredResults.findIndex((attr) => refDataKey === attr.attributeName) >= 0,
    });
    return filteredResults;
  };

  public render() {
    return (
      <React.Fragment>
        <SettingsHeader
          currentPageTitle={this.props.intl.formatMessage({
            id: get(settingsRoutes, 'referenceData.label.id', '') as string,
            defaultMessage: get(settingsRoutes, 'referenceData.label.defaultMessage', ' ') as string,
          })}
          showSaveButton={false}
          showDiscardButton={false}
        />
        <div className={styles.settingsInner}>
          <SettingsNavigation currentPath={this.props.location.pathname} />
          {this.props.isFetchingTenantAttributes && <Progress isLoading />}
          {!this.props.isFetchingTenantAttributes && (
            <Container>
              <Meta title="Keys" />
              <Row className={styles.addRefDataKey}>
                <Col md={15} sm={24}>
                  <Input
                    type="text"
                    placeholder={this.props.intl.formatMessage(messages.tenantKeyInput)}
                    className={`borderlessInput ${styles.refDataKey}`}
                    onChange={this.handleOnKeyChange}
                    custom={{
                      name: 'refDataKey',
                      id: 'refDataKey',
                      autoComplete: 'off',
                      'data-locator': 'refDataKey',
                      value: this.state.refDataKey,
                      suffix: <SearchOutlined />,
                    }}
                  />
                  {this.state.isDuplicate && (
                    <div className={styles.duplicateText} style={{ color: this.context.primaryColor }}>
                      {this.props.intl.formatMessage(messages.duplicateTenantKey)}
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <Button
                    type="primary-transparent"
                    clickFn={this.addRefDataKey}
                    disabled={!this.state.refDataKey || this.state.isDuplicate}
                    loading={this.props.isCreatingTenantAttributes}
                  >
                    <PlusOutlined />
                    <FormattedMessage id="shipmentDetails.shipmentIdentifiers.CREATE" defaultMessage="CREATE" />
                  </Button>
                </Col>
              </Row>
              <Row>
                {chunk(this.state.filteredTenantAttributes, 3).map(
                  (tenantAttributesChunk: TenantAttribute[], index: number) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Col md={8} sm={12} key={index}>
                        {tenantAttributesChunk.map((tenantAttribute: TenantAttribute) => {
                          return (
                            <div className={styles.tenantAttribute} key={tenantAttribute.attributeName}>
                              {tenantAttribute.attributeName}
                              {tenantAttribute.isNew && (
                                <div className={styles.newAttribute}>
                                  <FormattedMessage id="shipmentDetails.shipmentIdentifiers.NEW" defaultMessage="NEW" />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </Col>
                    );
                  }
                )}
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ReferenceDataComponent;
