import * as React from 'react';
import AutoComplete, { AutoCompleteProps } from 'antd/lib/auto-complete';
const AutocompleteInput: React.FC<AutoCompleteProps> = (props) => {
  return (
    <>
      <AutoComplete {...props} />
    </>
  );
};

export default AutocompleteInput;
