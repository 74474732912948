import { useIntl } from 'react-intl';
import { Radio } from 'ui-components';

import { useDatePicker } from '../context/DatePickerContext';
import { DateFormat } from '../types';
import { buildRadioHandler } from '../utils';

interface DateFormatInputProps {
  layout?: 'row' | 'column';
  className?: string;
}

const DateFormatInput = ({ layout = 'row', className }: DateFormatInputProps) => {
  const intl = useIntl();
  const { state, dispatch } = useDatePicker();

  const handleChangeDateFormat = (nextDateFormat: DateFormat) => {
    dispatch({ type: 'SET_CALENDAR', payload: nextDateFormat });
  };

  return (
    <Radio
      className={className}
      onChange={buildRadioHandler(handleChangeDateFormat)}
      value={state.calendar}
      layout={layout}
      radioData={[
        {
          value: 'specificDay',
          label: intl.formatMessage({
            id: 'datePicker.dateFormat.specific',
            defaultMessage: 'Specific Day',
          }),
        },
        {
          value: 'rangeDate',
          label: intl.formatMessage({
            id: 'datePicker.dateFormat.range',
            defaultMessage: 'Date Range',
          }),
        },
      ]}
    />
  );
};

export default DateFormatInput;
