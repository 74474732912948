import { FormSection, Field } from 'redux-form';
import { defineMessages } from 'react-intl';
import { intl } from 'common/AppConstants';
import { EventTypeStatusCode } from '../../models';

import { RenderSingleSelectDropdown } from '../AddEditFormInputs';

const message = defineMessages({
  selectStatusCode: {
    id: 'notifications.eventRuleFormSection.selectStatusCode',
    defaultMessage: 'Select Event',
  },
});

export interface EventRuleStatusCodeProps {
  children?: JSX.Element[];
  className?: string;
  eventTypeStatusCodes: EventTypeStatusCode[];
}

export const EventRuleStatusCode = (props: EventRuleStatusCodeProps) => {
  return (
    <div className={props.className}>
      {props.children}
      <FormSection name="statusCodeParameter">
        <Field
          name="type"
          className=""
          data-locator="status-code-input"
          placeholder={intl.formatMessage(message.selectStatusCode)}
          component={RenderSingleSelectDropdown}
          dropdownItems={props.eventTypeStatusCodes.map((eventTypeStatusCodeParameterValue) => ({
            value: eventTypeStatusCodeParameterValue.type,
            displayName: eventTypeStatusCodeParameterValue.display,
          }))}
        />
      </FormSection>
    </div>
  );
};

export default EventRuleStatusCode;
