import { defineMessages, createIntl, createIntlCache } from 'react-intl';
import { locale, messages } from '../../../../i18n/configurei18n';
import { DESCENDING, ASCENDING } from '../../../../common/AppConstants';

const cache = createIntlCache();
const intl = createIntl({ locale, messages }, cache);

const message = defineMessages({
  createdDateMostRecent: {
    id: 'sortResults.types.createdDateMostRecent',
    defaultMessage: 'Created by date (Most Recent)',
  },
  createdDateLeastRecent: {
    id: 'sortResults.types.createdDateLeastRecent',
    defaultMessage: 'Created by date (Least Recent)',
  },
  updatedDateMostRecent: {
    id: 'sortResults.types.updatedDateMostRecent',
    defaultMessage: 'Last Updated Date (Most Recent)',
  },
  updatedDateLeastRecent: {
    id: 'sortResults.types.updatedDateLeastRecent',
    defaultMessage: 'Last Updated Date (Least Recent)',
  },
  pickupAppointment: {
    id: 'sortResults.types.pickupAppointment',
    defaultMessage: 'Earliest Pickup Appointment',
  },
  deliveryAppointment: {
    id: 'sortResults.types.deliveryAppointment',
    defaultMessage: 'Earliest Delivery Appointment',
  },
  pickupCityAZ: {
    id: 'sortResults.types.pickupCityAZ',
    defaultMessage: 'Pickup City, State (A-Z)',
  },
  pickupCityZA: {
    id: 'sortResults.types.pickupCityZA',
    defaultMessage: 'Pickup City, State (Z-A)',
  },
  pickupCityEUAZ: {
    id: 'sortResults.types.pickupCityEUAZ',
    defaultMessage: 'Pickup City, Country (A-Z)',
  },
  pickupCityEUZA: {
    id: 'sortResults.types.pickupCityEUZA',
    defaultMessage: 'Pickup City, Country (Z-A)',
  },
  deliveryCityEUAZ: {
    id: 'sortResults.types.deliveryCityEUAZ',
    defaultMessage: 'Delivery City, Country (A-Z)',
  },
  deliveryCityEUZA: {
    id: 'sortResults.types.deliveryCityEUZA',
    defaultMessage: 'Delivery City, Country (Z-A)',
  },
  deliveryCityAZ: {
    id: 'sortResults.types.deliveryCityAZ',
    defaultMessage: 'Delivery City, State (A-Z)',
  },
  deliveryCityZA: {
    id: 'sortResults.types.deliveryCityZA',
    defaultMessage: 'Delivery City, State (Z-A)',
  },
  numberMinutesLate: {
    id: 'sortResults.types.numberMinutesLate',
    defaultMessage: 'Number of Minutes Late',
  },
  companyNameAZ: {
    id: 'sortResults.types.companyNameAZ',
    defaultMessage: 'Company Name (A-Z)',
  },
  companyNameZA: {
    id: 'sortResults.types.companyNameZA',
    defaultMessage: 'Company Name (Z-A)',
  },
  nameAZ: {
    id: 'sortResults.types.nameAZ',
    defaultMessage: 'Location Name (A-Z)',
  },
  nameZA: {
    id: 'sortResults.types.nameZA',
    defaultMessage: 'Location Name (Z-A)',
  },
  lastNameAZ: {
    id: 'sortResults.types.lastNameAZ',
    defaultMessage: 'Contact Last Name (A-Z)',
  },
  lastNameZA: {
    id: 'sortResults.types.lastNameZA',
    defaultMessage: 'Contact Last Name (Z-A)',
  },
  volumeAsc: {
    id: 'sortResults.types.volumeAsc',
    defaultMessage: 'Shipment Volume (Asc)',
  },
  volumeDesc: {
    id: 'sortResults.types.volumeDesc',
    defaultMessage: 'Shipment Volume (Desc)',
  },
  missedGeofenceAsc: {
    id: 'sortResults.types.missedGeofencesAsc',
    defaultMessage: 'Missed Geofences (Asc)',
  },
  missedGeofenceDesc: {
    id: 'sortResults.types.missedGeofencesDesc',
    defaultMessage: 'Missed Geofences (Desc)',
  },
});

const LIST_SORT = 'sortResults/duck/LIST_SORT';
const SET_SORT_FIELDS = 'sortResults/duck/SET_SORT_FIELDS';
const LOCATION_LIST_SORT = 'sortResults/duck/LOCATION_LIST_SORT';
const SHIPMENT_SORT = 'sortResults/duck/SHIPMENT_SORT';

//@deprecated since it's using the wrong intl object. Use SHIPMENT_SORT_FIELDS_EU_INTL
const SHIPMENT_SORT_FIELDS_EU = [
  {
    displayName: intl.formatMessage(message.createdDateMostRecent),
    request: [{ fieldName: 'createdDate', direction: DESCENDING }],
  },
  {
    displayName: intl.formatMessage(message.createdDateLeastRecent),
    request: [{ fieldName: 'createdDate', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.updatedDateMostRecent),
    request: [{ fieldName: 'updatedDate', direction: DESCENDING }],
  },
  {
    displayName: intl.formatMessage(message.updatedDateLeastRecent),
    request: [{ fieldName: 'updatedDate', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.pickupAppointment),
    request: [
      { fieldName: 'pickupStopInfo.dateRange.startDate', direction: ASCENDING },
      { fieldName: 'pickupStopInfo.dateRange.endDate', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.deliveryAppointment),
    request: [
      { fieldName: 'deliveryStopInfo.dateRange.startDate', direction: ASCENDING },
      { fieldName: 'deliveryStopInfo.dateRange.endDate', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.pickupCityEUAZ),
    request: [
      { fieldName: 'pickupStopInfo.city', direction: ASCENDING },
      { fieldName: 'pickupStopInfo.state', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.pickupCityEUZA),
    request: [
      { fieldName: 'pickupStopInfo.city', direction: DESCENDING },
      { fieldName: 'pickupStopInfo.state', direction: DESCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.deliveryCityEUAZ),
    request: [
      { fieldName: 'deliveryStopInfo.city', direction: ASCENDING },
      { fieldName: 'deliveryStopInfo.state', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.deliveryCityEUZA),
    request: [
      { fieldName: 'deliveryStopInfo.city', direction: DESCENDING },
      { fieldName: 'deliveryStopInfo.state', direction: DESCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.numberMinutesLate),
    request: [{ fieldName: 'estimatedMinutesLate', direction: DESCENDING }],
  },
];

const SHIPMENT_SORT_FIELDS_EU_INTL = (intl) => [
  {
    displayName: intl.formatMessage(message.createdDateMostRecent),
    request: [{ fieldName: 'createdDate', direction: DESCENDING }],
  },
  {
    displayName: intl.formatMessage(message.createdDateLeastRecent),
    request: [{ fieldName: 'createdDate', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.updatedDateMostRecent),
    request: [{ fieldName: 'updatedDate', direction: DESCENDING }],
  },
  {
    displayName: intl.formatMessage(message.updatedDateLeastRecent),
    request: [{ fieldName: 'updatedDate', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.pickupAppointment),
    request: [
      { fieldName: 'pickupStopInfo.dateRange.startDate', direction: ASCENDING },
      { fieldName: 'pickupStopInfo.dateRange.endDate', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.deliveryAppointment),
    request: [
      { fieldName: 'deliveryStopInfo.dateRange.startDate', direction: ASCENDING },
      { fieldName: 'deliveryStopInfo.dateRange.endDate', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.pickupCityEUAZ),
    request: [
      { fieldName: 'pickupStopInfo.city', direction: ASCENDING },
      { fieldName: 'pickupStopInfo.state', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.pickupCityEUZA),
    request: [
      { fieldName: 'pickupStopInfo.city', direction: DESCENDING },
      { fieldName: 'pickupStopInfo.state', direction: DESCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.deliveryCityEUAZ),
    request: [
      { fieldName: 'deliveryStopInfo.city', direction: ASCENDING },
      { fieldName: 'deliveryStopInfo.state', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.deliveryCityEUZA),
    request: [
      { fieldName: 'deliveryStopInfo.city', direction: DESCENDING },
      { fieldName: 'deliveryStopInfo.state', direction: DESCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.numberMinutesLate),
    request: [{ fieldName: 'estimatedMinutesLate', direction: DESCENDING }],
  },
];

//@deprecated since it's using the wrong intl object. Use SHIPMENT_SORT_FIELDS_INTL() instead
const SHIPMENT_SORT_FIELDS = [
  {
    displayName: intl.formatMessage(message.createdDateMostRecent),
    request: [{ fieldName: 'createdDate', direction: DESCENDING }],
  },
  {
    displayName: intl.formatMessage(message.createdDateLeastRecent),
    request: [{ fieldName: 'createdDate', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.updatedDateMostRecent),
    request: [{ fieldName: 'updatedDate', direction: DESCENDING }],
  },
  {
    displayName: intl.formatMessage(message.updatedDateLeastRecent),
    request: [{ fieldName: 'updatedDate', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.pickupAppointment),
    request: [
      { fieldName: 'pickupStopInfo.dateRange.startDate', direction: ASCENDING },
      { fieldName: 'pickupStopInfo.dateRange.endDate', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.deliveryAppointment),
    request: [
      { fieldName: 'deliveryStopInfo.dateRange.startDate', direction: ASCENDING },
      { fieldName: 'deliveryStopInfo.dateRange.endDate', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.pickupCityAZ),
    request: [
      { fieldName: 'pickupStopInfo.city', direction: ASCENDING },
      { fieldName: 'pickupStopInfo.state', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.pickupCityZA),
    request: [
      { fieldName: 'pickupStopInfo.city', direction: DESCENDING },
      { fieldName: 'pickupStopInfo.state', direction: DESCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.deliveryCityAZ),
    request: [
      { fieldName: 'deliveryStopInfo.city', direction: ASCENDING },
      { fieldName: 'deliveryStopInfo.state', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.deliveryCityZA),
    request: [
      { fieldName: 'deliveryStopInfo.city', direction: DESCENDING },
      { fieldName: 'deliveryStopInfo.state', direction: DESCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.numberMinutesLate),
    request: [{ fieldName: 'estimatedMinutesLate', direction: DESCENDING }],
  },
];

const SHIPMENT_SORT_FIELDS_INTL = (intl) => [
  {
    displayName: intl.formatMessage(message.createdDateMostRecent),
    request: [{ fieldName: 'createdDate', direction: DESCENDING }],
  },
  {
    displayName: intl.formatMessage(message.createdDateLeastRecent),
    request: [{ fieldName: 'createdDate', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.updatedDateMostRecent),
    request: [{ fieldName: 'updatedDate', direction: DESCENDING }],
  },
  {
    displayName: intl.formatMessage(message.updatedDateLeastRecent),
    request: [{ fieldName: 'updatedDate', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.pickupAppointment),
    request: [
      { fieldName: 'pickupStopInfo.dateRange.startDate', direction: ASCENDING },
      { fieldName: 'pickupStopInfo.dateRange.endDate', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.deliveryAppointment),
    request: [
      { fieldName: 'deliveryStopInfo.dateRange.startDate', direction: ASCENDING },
      { fieldName: 'deliveryStopInfo.dateRange.endDate', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.pickupCityAZ),
    request: [
      { fieldName: 'pickupStopInfo.city', direction: ASCENDING },
      { fieldName: 'pickupStopInfo.state', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.pickupCityZA),
    request: [
      { fieldName: 'pickupStopInfo.city', direction: DESCENDING },
      { fieldName: 'pickupStopInfo.state', direction: DESCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.deliveryCityAZ),
    request: [
      { fieldName: 'deliveryStopInfo.city', direction: ASCENDING },
      { fieldName: 'deliveryStopInfo.state', direction: ASCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.deliveryCityZA),
    request: [
      { fieldName: 'deliveryStopInfo.city', direction: DESCENDING },
      { fieldName: 'deliveryStopInfo.state', direction: DESCENDING },
    ],
  },
  {
    displayName: intl.formatMessage(message.numberMinutesLate),
    request: [{ fieldName: 'estimatedMinutesLate', direction: DESCENDING }],
  },
];

const LOCATION_SORT_FIELDS = [
  {
    displayName: intl.formatMessage(message.nameAZ),
    request: [{ fieldName: 'location.name', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.nameZA),
    request: [{ fieldName: 'location.name', direction: DESCENDING }],
  },
  {
    displayName: intl.formatMessage(message.volumeAsc),
    request: [{ fieldName: 'recentPerformance.totalShipments', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.volumeDesc),
    request: [{ fieldName: 'recentPerformance.totalShipments', direction: DESCENDING }],
  },
  {
    displayName: intl.formatMessage(message.missedGeofenceAsc),
    request: [{ fieldName: 'recentPerformance.missedShipments', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.missedGeofenceDesc),
    request: [{ fieldName: 'recentPerformance.missedShipments', direction: DESCENDING }],
  },
];
const LOCATION_SORT_FIELDS_V1 = [
  {
    displayName: intl.formatMessage(message.companyNameAZ),
    request: [{ fieldName: 'companyName', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.companyNameZA),
    request: [{ fieldName: 'companyName', direction: DESCENDING }],
  },
  {
    displayName: intl.formatMessage(message.lastNameAZ),
    request: [{ fieldName: 'contact.lastName', direction: ASCENDING }],
  },
  {
    displayName: intl.formatMessage(message.lastNameZA),
    request: [{ fieldName: 'contact.lastName', direction: DESCENDING }],
  },
];

export default {
  LIST_SORT,
  SET_SORT_FIELDS,
  SHIPMENT_SORT_FIELDS, //@deprecated
  SHIPMENT_SORT_FIELDS_INTL,
  SHIPMENT_SORT_FIELDS_EU, //@deprecated
  SHIPMENT_SORT_FIELDS_EU_INTL,
  LOCATION_SORT_FIELDS,
  LOCATION_SORT_FIELDS_V1,
  LOCATION_LIST_SORT,
  SHIPMENT_SORT,
};
