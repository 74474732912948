import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import axios from 'util/paxios';
import { Page, SortField } from 'models';
import { buildUrlWithPaginationParams } from 'models/page-helper';
import { buildUrlWithSortParams } from 'models/sort-helper';
import { InventoryItem } from 'components/Inventory/models/inventory';
import { API_PATH } from 'common/AppConstants';
import endpoints from 'common/endpoints';
import { BasicInventoryItemSearchCriteria } from 'components/Inventory/models/InventoryItemSearch';

interface InventoryItemsHook {
  hasPermission: boolean;
  masterShipmentId?: string;
  defaultPageSize?: number;
  searchText?: string;
  orderId?: string;
  page?: number;
  sort?: SortField<string>[];
  filters?: string;
}

export function useInventoryItems({
  hasPermission,
  masterShipmentId,
  defaultPageSize = 30,
  searchText,
  orderId,
  page = 1,
  sort = [],
  filters,
}: InventoryItemsHook) {
  const initialPageState = {
    paginationInfo: {
      total: 0,
      pageSize: 0,
      pageNumber: 0,
    },
    results: [],
  };

  const [items, setItems] = useState<Page<InventoryItem>>(initialPageState);
  const [isLoading, setIsLoading] = useState(false);

  const getItems = useCallback(async function getItems(
    requestBody: BasicInventoryItemSearchCriteria,
    pageNumber: number,
    pageSize: number,
    sortFields: SortField<string>[]
  ) {
    try {
      let url = new URL(`${API_PATH}${endpoints.INVENTORY_ITEMS_SEARCH}`, window.location.origin);
      url = buildUrlWithPaginationParams(url, { pageNumber, pageSize });
      url = buildUrlWithSortParams(url, sortFields);
      const response: AxiosResponse<Page<InventoryItem>> = await axios.post(url.toString(), requestBody, {
        withCredentials: true,
      });

      setItems(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  },
  []);

  useEffect(() => {
    if (hasPermission) {
      setIsLoading(true);

      getItems(
        {
          ...(masterShipmentId && { shipmentIds: [masterShipmentId] }),
          ...(orderId && { orderIds: [orderId] }),
          searchText,
          ...(filters && JSON.parse(filters)),
        },
        page,
        defaultPageSize,
        sort
      );
    }
  }, [getItems, hasPermission, masterShipmentId, defaultPageSize, searchText, orderId, page, sort, filters]);

  return {
    isLoading,
    inventoryItems: items,
  };
}
