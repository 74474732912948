import { FC, useContext } from 'react';
import moment from 'moment-timezone';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import {
  ShipmentStopAppointmentStatusEnum,
  ShipmentStopAppointmentWindowIdentifierTypeEnum,
  ShipmentStopAppointmentWindowTypeEnum,
  ShipmentLegCarrierInfo,
  CarrierInfoTypeEnum,
  Identifier,
  ShipmentStopTypeEnum,
  ShipmentStopAppointmentWindow,
} from 'models';
import axios from 'util/paxios';
import { API_PATH } from 'common/AppConstants';
import { PrincipalContext, PrincipalContextProps } from 'contexts/PrincipalContext';
import { getPrincipalAuthorizationsFromPrincipalContext, supplyStackWebComponentsEnabled } from 'common/authorizations';
import SlotBookingFlyover from './SlotBookingFlyover/SlotBookingFlyover';
import SlotBookingWizard from './SlotBookingWizard/SlotBookingWizard';
export interface SlotBookingProps {
  shipmentId?: string;
  carrierIdentifiers?: ShipmentLegCarrierInfo[];
  shipmentIdentifiers: Identifier[];
  stopId?: string;
  stopType?: ShipmentStopTypeEnum;
  appointmentWindow: ShipmentStopAppointmentWindow;
  locationId?: string | null;
}

export const slotBookingWindowTypes = [
  ShipmentStopAppointmentWindowTypeEnum.SLOT_BOOKING_DELIVERY,
  ShipmentStopAppointmentWindowTypeEnum.SLOT_BOOKING_PICKUP,
  ShipmentStopAppointmentWindowTypeEnum.SLOT_BOOKING_PICKUP_DELIVERY,
];

const showBookingWizardStatusesShipper = [
  ShipmentStopAppointmentStatusEnum.NONE_AVAILABLE,
  ShipmentStopAppointmentStatusEnum.PENDING_INTERNAL,
];

const showBookingWizardStatusesCarrier = [ShipmentStopAppointmentStatusEnum.PENDING_CARRIER];

const validCarrierIdentifierTypes = [
  CarrierInfoTypeEnum.SCAC,
  CarrierInfoTypeEnum.DOT_NUMBER,
  CarrierInfoTypeEnum.MC_NUMBER,
  CarrierInfoTypeEnum.P44_EU,
];

const SlotBookingWrapper = styled.div`
  margin-top: 15px;
`;

export enum SlotBookingActivityTypeEnum {
  OUTBOUND = 'OUTBOUND',
  INBOUND = 'INBOUND',
  IN_AND_OUTBOUND = 'IN_AND_OUTBOUND',
}

export interface BookingFinishedEvent {
  activityType: SlotBookingActivityTypeEnum;
  ssri: string;
  startDate: string;
  endDate: string;
  status: string;
}

const activityTypeMapping: { [p in SlotBookingActivityTypeEnum]: ShipmentStopAppointmentWindowTypeEnum } = {
  [SlotBookingActivityTypeEnum.IN_AND_OUTBOUND]: ShipmentStopAppointmentWindowTypeEnum.SLOT_BOOKING_PICKUP_DELIVERY,
  [SlotBookingActivityTypeEnum.INBOUND]: ShipmentStopAppointmentWindowTypeEnum.SLOT_BOOKING_DELIVERY,
  [SlotBookingActivityTypeEnum.OUTBOUND]: ShipmentStopAppointmentWindowTypeEnum.SLOT_BOOKING_PICKUP,
};

const SlotBooking: FC<SlotBookingProps> = ({
  shipmentId,
  stopId,
  stopType,
  appointmentWindow,
  shipmentIdentifiers,
  carrierIdentifiers,
  locationId,
}) => {
  const principal: PrincipalContextProps | undefined = useContext(PrincipalContext);
  const authorizations = principal ? getPrincipalAuthorizationsFromPrincipalContext(principal) : undefined;

  if (
    !authorizations ||
    !authorizations.hasSlotBooking() ||
    !supplyStackWebComponentsEnabled() ||
    isNil(appointmentWindow) ||
    !slotBookingWindowTypes.includes(appointmentWindow.windowType) ||
    isNil(shipmentId) ||
    isNil(stopId)
  ) {
    return null;
  }

  const onBookingFinished = (result: BookingFinishedEvent, timezone?: string | null) => {
    try {
      const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
      const timezoneToUse = isNil(timezone) ? moment.tz.guess() : timezone;
      const payload = {
        type: activityTypeMapping[result.activityType],
        appointmentStatus: ShipmentStopAppointmentStatusEnum.BOOKED,
        appointmentWindow: {
          startDateTime: moment(result.startDate).tz(timezoneToUse).format(dateTimeFormat),
          endDateTime: moment(result.endDate).tz(timezoneToUse).format(dateTimeFormat),
          localTimezoneIdentifier: timezoneToUse,
        },
        identifiers: [
          {
            type: ShipmentStopAppointmentWindowIdentifierTypeEnum.BOOKING_ID,
            value: result.ssri,
          },
        ],
      };
      axios.put(`${API_PATH}/tl-shipment/id/${shipmentId}/stops/${stopId}/additional-appointment-windows`, payload, {
        withCredentials: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const carrierIdentifier = carrierIdentifiers?.find((identifier) =>
    validCarrierIdentifierTypes.includes(identifier.name)
  );

  const hasTenantCarrierRole = authorizations && authorizations.hasTenantCarrierRole(principal);

  const bookingIdentifier = appointmentWindow.identifiers?.find(
    (identifier) => identifier.type === ShipmentStopAppointmentWindowIdentifierTypeEnum.BOOKING_ID
  );
  if (bookingIdentifier !== undefined) {
    return (
      <SlotBookingWrapper data-locator={`slot-booking-flyover-${appointmentWindow.windowType}`}>
        <SlotBookingFlyover
          bookingId={bookingIdentifier.value}
          onBookingFinished={onBookingFinished}
          localTimezoneIdentifier={appointmentWindow.localTimeZoneIdentifier}
        />
      </SlotBookingWrapper>
    );
  } else if (
    locationId &&
    appointmentWindow.appointmentStatus &&
    ((hasTenantCarrierRole && showBookingWizardStatusesCarrier.includes(appointmentWindow.appointmentStatus)) ||
      (!hasTenantCarrierRole && showBookingWizardStatusesShipper.includes(appointmentWindow.appointmentStatus)))
  ) {
    return (
      <SlotBookingWrapper data-locator={`slot-booking-wizard-${appointmentWindow.windowType}`}>
        <SlotBookingWizard
          shipmentId={shipmentId}
          stopType={stopType}
          appointmentWindow={appointmentWindow}
          locationId={locationId}
          carrierIdentifier={carrierIdentifier}
          shipmentIdentifiers={shipmentIdentifiers}
          onBookingFinished={onBookingFinished}
          stopId={stopId}
        />
      </SlotBookingWrapper>
    );
  }
  return null;
};

export default SlotBooking;
