const MapSidePanelItemIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 28 40" width="24">
    <path
      fill={`var(${props.statusColor})`}
      d="M14,0A14,14,0,0,0,0,14C0,24.5,14,40,14,40S28,24.5,28,14A14,14,0,0,0,14,0Z"
    />
    <circle fill="#fff" cx="14" cy="14" r="5" />
  </svg>
);

export default MapSidePanelItemIcon;
