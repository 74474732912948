import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { injectIntl } from 'react-intl';
import { getPrincipalAuthorizationsFromState, getPrincipalFromState } from 'common/authorizations';
import { tenantAttributesActions } from 'components/newShipmentDetails/ShipmentIdentifiers/ducks';
import { trackEvent } from 'common/eventTracker';
import { ShipmentModeEnum } from 'models';
import LTLDetailsComponent from './LTLDetailsComponent';
import {
  getShipmentDetails,
  getPublicShipmentDetails,
  toggleRouteDetailsDisplay,
  toggleGroupedUpdates,
  resetShipment,
} from './ducks/actions';

const componentWithFetchedData = compose(
  lifecycle({
    componentDidMount() {
      // either ltl shipment id or shipment share token is expected in the url parameters
      const shouldUseRecentSearch = this.props.shouldUseRecentSearch;
      const { shipmentDetailId, shipmentShareToken } = this.props.match.params;
      if (shipmentShareToken) {
        this.props.getPublicShipmentDetails(shipmentShareToken, this.props.shipmentModel);
      } else {
        this.props.getShipmentDetails(
          shipmentDetailId,
          this.props.shipmentModel,
          this.props.intl,
          shouldUseRecentSearch
        );
      }
      trackEvent('SHIPMENT_DETAIL_PAGE_LOAD', {
        mode: ShipmentModeEnum.LTL,
        shipmentId: shipmentDetailId,
        isNewDetailPage: false,
      });
    },
    componentWillUnmount() {
      // removes the shipment details state, preventing state persisting when using back button to navigate
      this.props.resetShipment();
    },
  })
)(LTLDetailsComponent);

const mapStateToProps = (state) => {
  const authorizations = getPrincipalAuthorizationsFromState(state);
  const principal = getPrincipalFromState(state);
  return {
    authorizations,
    tenantId: state.authReducer.principal.tenantId,
    shipmentDetails: state.ltlShipmentDetailsReducer.shipmentDetails,
    isLoggedIn: state.authReducer.isLoggedIn,
    showExpanded: state.ltlShipmentDetailsReducer.showExpanded,
    shipmentDetailsMissing: state.ltlShipmentDetailsReducer.error,
    shipmentTenantAttributesEditMode: state.tenantAttributesReducer.shipmentTenantAttributesEditMode,
    principal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getShipmentDetails: (id, shipmentModel, intl, shouldUseRecentSearch) =>
    dispatch(getShipmentDetails(id, shipmentModel, shouldUseRecentSearch)),
  getPublicShipmentDetails: (shipmentShareToken, shipmentModel) =>
    dispatch(getPublicShipmentDetails(shipmentShareToken, shipmentModel)),
  toggleRouteDetailsDisplay: () => dispatch(toggleRouteDetailsDisplay()),
  toggleGroupedUpdates: (index) => dispatch(toggleGroupedUpdates(index)),
  resetShipment: () => dispatch(resetShipment()),
  toggleTenantAttributesMode: () => dispatch(tenantAttributesActions.toggleTenantAttributesMode()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(componentWithFetchedData));
