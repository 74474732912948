import * as React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import round from 'lodash/round';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Card } from 'ui-components';
import { trackEvent } from 'common/eventTracker';
import { LocationDetails } from '../../LocationDetails/models';
import * as styles from './ListItem.module.scss';

interface ListItemProps {
  locationData: {
    location: LocationDetails;
    dwellTimes?: any;
    idleLocations?: any;
    trackingCompliances?: any;
    geofencePerformances?: any;
    volume?: any;
    recentPerformance?: {
      totalShipments: number;
      arrivedShipments: number;
      missedShipments: number;
    };
  };
}
const AddressComponent = ({ address }: { address: LocationDetails['address'] }) => {
  let addressLine2;

  if (address.address2) {
    addressLine2 = (
      <span>
        {address.address2}
        <br />
      </span>
    );
  }

  return (
    <h5 className={styles.address}>
      {address.address1}
      <br />
      {addressLine2}
      {address.city}, {address.state} {address.postalCode}
    </h5>
  );
};

const ListItem: React.FC<ListItemProps & WrappedComponentProps> = ({ locationData, intl }) => {
  const [geofenceAccuracy, setGeofenceAccuracy] = React.useState<string | undefined>();
  const [volume, setVolume] = React.useState<string | undefined>('0');
  React.useEffect(() => {
    if (locationData.recentPerformance) {
      const totalShipments = get(locationData, 'recentPerformance.totalShipments', 0);
      const geofenceAccuracyValue =
        totalShipments > 0
          ? round((get(locationData, 'recentPerformance.arrivedShipments', 0) / totalShipments) * 100)
          : 0;
      setGeofenceAccuracy(isNaN(geofenceAccuracyValue) ? '' : geofenceAccuracyValue.toString());
      setVolume(String(totalShipments));
    }
  }, [locationData.recentPerformance, locationData]);
  return (
    <Link
      to={`/location-details/${locationData.location.id}`}
      onClick={() => {
        trackEvent('LOCATIONS_LIST_ROW_CLICK', {
          id: locationData.location.id || '',
          name: locationData.location.name || '',
        });
      }}
    >
      <Card>
        <Row>
          <Col>
            {locationData.location.name ? (
              <h2 className={styles.sectionTitle}>{locationData.location.name}</h2>
            ) : (
              intl.formatMessage({
                id: 'locationListComponent.listItem.locationNameUnavailable',
                defaultMessage: 'No location name available',
              })
            )}
          </Col>
          <Col>{<AddressComponent address={locationData.location.address} />}</Col>
          <Col>
            <p>
              <span className={styles.kpiValues}>{volume}</span>{' '}
              {intl.formatMessage({
                id: 'locationListComponent.listItem.volumeLastThirtyDays',
                defaultMessage: 'Shipments 30d',
              })}
            </p>

            <p>
              <span className={styles.kpiValues}>
                {get(locationData, 'recentPerformance.totalShipments', 0) === 0 ? ' - ' : `${geofenceAccuracy}%`}
              </span>
              {intl.formatMessage({
                id: 'locationListComponent.listItem.geofenceAccuracyLabel',
                defaultMessage: 'Geofence accuracy',
              })}
            </p>
          </Col>
        </Row>
      </Card>
    </Link>
  );
};

export default injectIntl(ListItem);
