import get from 'lodash/get';

export const extractErrorDetails = (error) => {
  let response = get(error || {}, 'response', {});

  return {
    message: getErrorMessageForCode(response.status),
    status: getOrDefaultCode(response.status),
    errors: getErrorsFromPayload(response.data),
  };
};

const getErrorMessageForCode = (code) => {
  switch (getOrDefaultCode(code)) {
    case 401:
    case 403:
      return 'Missing/Invalid login session';
    case 400:
    case 422:
      return 'Invalid Inputs';
    case 404:
      return 'Not Found';
    default:
      return 'Internal Server Error';
  }
};

const getOrDefaultCode = (code) => (code ? code : 500);

const getErrorsFromPayload = (data) => {
  return get(data || {}, 'errors', [])
    .map((error) => error.message)
    .filter((message) => !!message);
};
