import './Route.scss';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { Row } from 'ui-components';
import RouteExpanded from './RouteExpanded';
import RouteSummary from './RouteSummary';

const Route = (props) => {
  if (!isEmpty(props.routeDetails)) {
    return (
      <div className="shipment-route">
        <Row type="flex">
          <h2>
            <FormattedMessage defaultMessage="Route Details" id="shipmentDetails.stickyNav.routeDetails" />
          </h2>
          {props.mode !== 'OCEAN' && (
            <button className="plainWrapperButton" onClick={props.toggleRouteDetailsDisplay}>
              <span className="shipment-route__toggle-route">
                {props.showExpanded ? (
                  <FormattedMessage defaultMessage="Show Less" id="shipmentDetails.expandedRoute.showLess" />
                ) : (
                  <FormattedMessage defaultMessage="Show More" id="shipmentDetails.expandedRoute.showMore" />
                )}
              </span>
            </button>
          )}
        </Row>
        {props.showExpanded && (
          <RouteExpanded
            routeDetails={props.routeDetails}
            mode={props.mode}
            statusStops={props.statusStops}
            markerColor={props.markerColor}
            toggleGroupedUpdates={props.toggleGroupedUpdates}
          />
        )}
        {!props.showExpanded && <RouteSummary routeSummary={props.routeSummary} />}
      </div>
    );
  } else {
    return null;
  }
};

export default Route;
