import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import isEqual from 'lodash/isEqual';
import compact from 'lodash/compact';
import mapKeys from 'lodash/mapKeys';
import flatten from 'flat';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { shipmentTypeIdentifiers } from '../reducers';
import * as DateUtils from '../../../../common/dateUtils';

const getCarrierId = (carrierInfo) => get(carrierInfo, 'id');

const getEnteredTrackingMethod = (formValues) => {
  const equipmentIdentifiersFromValue = formValues || {};
  const equipmentIdentifiers = [];

  if (equipmentIdentifiersFromValue.equipmentIdentifiers != null) {
    const [{ type, value }] = Object.values(equipmentIdentifiersFromValue.equipmentIdentifiers);
    equipmentIdentifiers.push({ type, value });
  }

  return equipmentIdentifiers;
};

const getAllTrackingMethods = (initialCarrierTrackingMethods) =>
  (initialCarrierTrackingMethods || []).map((method) => method.id);

const getCarrierInfo = (formValues, initialValues) => {
  const initialCarrierInfo = get(initialValues, 'carrierInfo', {});
  const initialCarrierTrackingMethods = get(initialCarrierInfo, 'trackingMethods');
  const initialTypeIdentifiers = get(initialCarrierInfo, 'typeIdentifiers');

  const carrierId = getCarrierId(initialCarrierInfo);
  const enteredCarrierType = get(formValues, 'carrierType');
  const enteredCarrierValue = get(formValues, 'carrierId');

  if (carrierId != null || enteredCarrierType != null || enteredCarrierValue != null) {
    return {
      id: carrierId,
      entered: {
        type: enteredCarrierType,
        value: enteredCarrierValue,
        trackingMethod: getEnteredTrackingMethod(formValues),
      },
      typeIdentifiers: initialTypeIdentifiers,
      trackingMethods: getAllTrackingMethods(initialCarrierTrackingMethods),
    };
  }
  return null;
};

const getOrDefault = (obj) => {
  //eslint-disable-next-line no-unused-vars
  const trimmedObject = pickBy(obj, (value, key) => value !== undefined && value !== null && !isEqual(value, {}));
  return isEmpty(trimmedObject) ? null : trimmedObject;
};

const getStopAppointmentWindow = (stop, stopTimezone, localTimeZoneIdentifier) => {
  return getOrDefault({
    startDate: DateUtils.convertToLocalDateTimeString(stop.startDate, stop.startTime),
    endDate: DateUtils.convertToLocalDateTimeString(stop.endDate, stop.endTime),
    localTimeZoneIdentifier: stopTimezone || localTimeZoneIdentifier,
  });
};

const getStopContact = (stop) => {
  return getOrDefault({
    companyName: stop.company,
    firstName: stop.firstName || '',
    lastName: stop.lastName || '',
    phoneNumber: stop.phoneNumber,
    email: stop.email,
  });
};

const mapCountryCode = (country) => (country === 'USA' ? 'US' : country);

const getStopAddress = (stop) => {
  return getOrDefault({
    addressLines: [stop.addressLine1, stop.addressLine2].filter((x) => !!x),
    city: stop.city,
    state: stop.state,
    postalCode: stop.zipCode,
    countryCode: mapCountryCode(stop.country),
  });
};

const emptyStopInfoExample = {
  contact: { firstName: '', lastName: '' },
  address: { addressLines: [], countryCode: 'US' },
};

const getStopOrNullIfDefault = (mappedStop) => {
  const trimmedStop = getOrDefault(mappedStop);
  return isEqual(trimmedStop, emptyStopInfoExample) ? null : trimmedStop;
};

const getStop = (stop, postalCodeTimezones, stopNumber, localTimeZoneIdentifier) => {
  const flattenedStop = removeObjectNesting(stop);
  const stopTimezone = getStopTimeZone(flattenedStop, postalCodeTimezones);
  return getStopOrNullIfDefault({
    stopName: flattenedStop.company,
    appointmentWindow: getStopAppointmentWindow(flattenedStop, stopTimezone, localTimeZoneIdentifier),
    contact: getStopContact(flattenedStop),
    address: getStopAddress(flattenedStop),
    stopNumber,
  });
};

const getStopTimeZone = (stop, postalCodeTimezones) => {
  const zip = stop.zipCode;
  if (!zip) {
    return null;
  }

  let timezone = get(postalCodeTimezones, zip);

  if (!timezone && (stop.country === 'US' || stop.country === 'USA')) {
    // using static dependency as fallback if we don't have it from previous look up result
    timezone = zipcode_to_timezone.lookup(zip);
  }

  return timezone;
};

const getStops = (formValues, postalCodeTimezones) => {
  const stopFormValues = formValues || {};
  const stops = [];

  if (stopFormValues.pickupForm) {
    stops.push(getStop(stopFormValues.pickupForm, postalCodeTimezones));
  }

  if (stopFormValues.addStops) {
    for (const stop of stopFormValues.addStops) {
      stops.push(getStop(stop, postalCodeTimezones));
    }
  }

  if (stopFormValues.dropOffForm) {
    stops.push(getStop(stopFormValues.dropOffForm, postalCodeTimezones));
  }

  return compact(stops);
};

const getShipmentIdentifiers = (formValues) => {
  const inputIdentifiers = formValues || {};
  const shipmentIdentifiers = [];

  if (inputIdentifiers.shipmentBOL != null) {
    shipmentIdentifiers.push({
      type: shipmentTypeIdentifiers.bol.id,
      value: inputIdentifiers.shipmentBOL,
    });
  }
  if (inputIdentifiers.shipmentOrder != null) {
    shipmentIdentifiers.push({
      type: shipmentTypeIdentifiers.order.id,
      value: inputIdentifiers.shipmentOrder,
    });
  }

  return shipmentIdentifiers;
};

const getTemperatureSettings = (formValues) => {
  const temperatureFormValues = formValues || {};
  const temperatureSettings = {};

  const setTemperatureProperty = (prop) => {
    if (temperatureFormValues.temperatureForm[prop]) {
      temperatureSettings[prop] = temperatureFormValues.temperatureForm[prop];
    }
  };

  if (!isEmpty(temperatureFormValues)) {
    setTemperatureProperty('lowerBound');
    setTemperatureProperty('upperBound');
    setTemperatureProperty('targetTemp');
    setTemperatureProperty('temperatureScale');
  }

  return temperatureSettings;
};

const getPostalCodeTimezones = (initialValues) => get(initialValues, 'postalCodeTimezones', {});

const removeObjectNesting = (object) => {
  if (!object) {
    return null;
  }
  // flatten separates the object with '.' so we want to select the last element
  return mapKeys(flatten(object), (value, key) => getLeafValue(key));
};

const getScheduledTruckChangesSettings = (formValues) => {
  const scheduledTruckChangesFromValue = formValues || {};
  const scheduledTruckChanges = [];

  if (scheduledTruckChangesFromValue.scheduledTruckChanges != null) {
    const scheduledTruckChangesFromValueArray = Object.values(scheduledTruckChangesFromValue.scheduledTruckChanges);

    scheduledTruckChangesFromValueArray.forEach(({ type, value, scheduleDate, scheduleTime }) => {
      let scheduledTruckChangeDto = {
        type,
        value,
      };
      if (scheduleDate) {
        const [hour, minute] = scheduleTime.split(':');
        scheduleDate.setHours(hour, minute);
        scheduledTruckChangeDto.timestamp = scheduleDate;
      }
      scheduledTruckChanges.push(scheduledTruckChangeDto);
    });
  }

  return scheduledTruckChanges;
};

const getLeafValue = (key) => key.split('.').slice(-1)[0];

class AddShipmentFormToStateMapper {
  // TODO: We should merge all the form state into form values but some data like the carrierInfo is set by a different reducer
  // see also addEditShipment.js and addEditShipmentContainer.js

  static mapToState(formValues, initialValues) {
    const stateValues = {};
    stateValues.carrier = getCarrierInfo(formValues, initialValues);
    stateValues.stops = getStops(formValues, getPostalCodeTimezones(initialValues));
    stateValues.shipmentIdentifiers = getShipmentIdentifiers(formValues);
    stateValues.temperatureSettings = getTemperatureSettings(formValues);
    stateValues.scheduledTruckChanges = getScheduledTruckChangesSettings(formValues);
    return stateValues;
  }

  static mapEditStopToState(formValues, initialValues, stopNumber, localTimeZoneIdentifier) {
    if (!formValues) {
      return null;
    }
    // formValues for edit has different nesting than the add form which flattens
    return getStop(formValues, getPostalCodeTimezones(initialValues), stopNumber, localTimeZoneIdentifier);
  }

  static getEditStopTimeZone(formValues, initialValues) {
    if (!formValues) {
      return null;
    }

    return getStopTimeZone(removeObjectNesting(formValues), getPostalCodeTimezones(initialValues));
  }
}

export default AddShipmentFormToStateMapper;
