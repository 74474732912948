import { Row, Col } from 'ui-components';
import './NewDay.scss';

const NewDay = (props) => {
  return (
    <Row type="flex" align="middle" className="route-new-day animated fadeIn">
      <Col className="route-new-day__date">{props.date}</Col>
      <Col className="route-new-day__line">
        <span className="vertical-line" />
      </Col>
      <Col className="route-new-day__hr" />
    </Row>
  );
};

export default NewDay;
