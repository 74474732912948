import * as React from 'react';
import styled from 'styled-components';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import { Select } from 'ui-components';
import { black } from 'styles/colors';
import { StyledFilterSubTitle } from 'components/common/DateRangeInputWithTitle/DateRangeInputWithTitle';
import SimpleInputWithChips from 'components/common/SimpleInputWithChips/SimpleInputWithChips';

const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selection-placeholder {
    opacity: 0.9;
  }

  .ant-select-arrow {
    color: ${black};
    top: 30%;
    right: 20px;

    .material-icons {
      transition: transform 0.3s;
      transform-origin: center center;
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      .material-icons {
        transform: rotate(-180deg);
      }
    }
  }
`;

interface TypeValueInputWithChipsInput {
  selectTitleComponent: React.ReactElement;
  selectPlaceholder: string;
  onSelect: ((value: any) => void) | undefined;
  selectedValue: any;
  selectList: any[];
  listTitleComponent: React.ReactElement;
  data: any[];
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  inputPlaceholder: string;
}

const TypeValueInputWithChips = ({
  selectTitleComponent,
  selectPlaceholder,
  onSelect,
  selectedValue,
  selectList,
  listTitleComponent,
  data,
  setData,
  inputPlaceholder,
}: TypeValueInputWithChipsInput) => {
  return (
    <>
      <StyledFilterSubTitle>{selectTitleComponent}</StyledFilterSubTitle>
      <StyledSelect
        showSearch
        placeholder={selectPlaceholder}
        onSelect={onSelect}
        value={selectedValue}
        dataSource={selectList}
        suffixIcon={<i className="material-icons">arrow_drop_down</i>}
      />
      {(selectedValue || !isEmpty(data)) && (
        <>
          <br />
          <StyledFilterSubTitle>{listTitleComponent}</StyledFilterSubTitle>
          <SimpleInputWithChips
            data={data}
            setData={setData}
            placeholder={inputPlaceholder}
            isTypeValueInput
            customOnAdd={(value: string) => {
              const newData = uniq([...data, `${selectedValue}:${value}`]);
              setData(newData);
            }}
          />
        </>
      )}
    </>
  );
};

export default TypeValueInputWithChips;
