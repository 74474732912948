const SHIPMENT_DETAIL_GET = 'TLDetailsComponent/duck/SHIPMENT_DETAIL_GET';
const SHIPMENT_DETAIL_GET_PUBLIC = 'TLDetailsComponent/duck/SHIPMENT_DETAIL_GET_PUBLIC';
const SHIPMENT_DETAIL_GET_SUCCESS = 'TLDetailsComponent/duck/SHIPMENT_DETAIL_GET_SUCCESS';
const SHIPMENT_DETAIL_GET_FAILURE = 'TLDetailsComponent/duck/SHIPMENT_DETAIL_GET_FAILURE';
const MODIFIABLE_DETAIL_GET = 'TLDetailsComponent/duck/MODIFIABLE_DETAIL_GET';
const MODIFIABLE_DETAIL_GET_SUCCESS = 'TLDetailsComponent/duck/MODIFIABLE_DETAIL_SUCCESS';
const MODIFIABLE_DETAIL_GET_FAILURE = 'TLDetailsComponent/duck/MODIFIABLE_DETAIL_FAILURE';
const SET_SHIPMENT_DETAIL_MILES_REMAINING = 'TLDetailsComponent/duck/SET_SHIPMENT_DETAILS_MILES_REMAINING';
const DELETE_SHIPMENT = 'TLDetailsComponent/duck/DELETE_SHIPMENT';
const DELETE_SHIPMENT_SUCCESS = 'TLDetailsComponent/duck/DELETE_SHIPMENT_SUCCESS';
const RESET_SHIPMENT = 'TLDetailsComponent/duck/RESET_SHIPMENT';
const OPEN_NUDGE_DIALOG = 'TLDetailsComponent/duck/OPEN_NUDGE_DIALOG';
const GET_NUDGE_MESSAGE_PREVIEW_SUCCESS = 'TLDetailsComponent/duck/GET_NUDGE_MESSAGE_PREVIEW_SUCCESS';
const GET_NUDGE_MESSAGE_PREVIEW_FAILURE = 'TLDetailsComponent/duck/GET_NUDGE_MESSAGE_PREVIEW_FAILURE';
const SEND_NUDGE = 'TLDetailsComponent/duck/SEND_NUDGE';
const SEND_NUDGE_SUCCESS = 'TLDetailsComponent/duck/SEND_NUDGE_SUCCESS';
const SEND_NUDGE_FAILURE = 'TLDetailsComponent/duck/SEND_NUDGE_FAILURE';
const CLOSE_NUDGE_DIALOG = 'TLDetailsComponent/duck/CLOSE_NUDGE_DIALOG';
const TOGGLE_DELETE_DIALOG = 'TLDetailsComponent/duck/TOGGLE_DELETE_DIALOG';
const OPEN_SNACKBAR_FOR_DELETE = 'TLDetailsComponent/duck/OPEN_SNACKBAR_FOR_DELETE';
const CLOSE_SNACKBAR_FOR_DELETE = 'TLDetailsComponent/duck/CLOSE_SNACKBAR_FOR_DELETE';
const MILES_DATA_GET = 'TLDetailsComponent/duck/MILES_DATA_GET';

export default {
  SHIPMENT_DETAIL_GET,
  SHIPMENT_DETAIL_GET_PUBLIC,
  SHIPMENT_DETAIL_GET_SUCCESS,
  SHIPMENT_DETAIL_GET_FAILURE,
  MODIFIABLE_DETAIL_GET,
  MODIFIABLE_DETAIL_GET_SUCCESS,
  MODIFIABLE_DETAIL_GET_FAILURE,
  SET_SHIPMENT_DETAIL_MILES_REMAINING,
  DELETE_SHIPMENT,
  DELETE_SHIPMENT_SUCCESS,
  RESET_SHIPMENT,
  OPEN_NUDGE_DIALOG,
  GET_NUDGE_MESSAGE_PREVIEW_SUCCESS,
  GET_NUDGE_MESSAGE_PREVIEW_FAILURE,
  SEND_NUDGE,
  SEND_NUDGE_SUCCESS,
  SEND_NUDGE_FAILURE,
  CLOSE_NUDGE_DIALOG,
  TOGGLE_DELETE_DIALOG,
  OPEN_SNACKBAR_FOR_DELETE,
  CLOSE_SNACKBAR_FOR_DELETE,
  MILES_DATA_GET,
};
