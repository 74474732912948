import types from './types';

export const initialState = {
  // Sort fields that will be placed on the search request directly. This is kept up to date through the reducer logic.
  sortFields: [],

  // Which sort field is currently selected in the UI.
  selectedSortFieldId: 0,
};

const sortResultsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_SORT_FIELDS:
    case types.LIST_SORT:
      return {
        ...state,
        selectedSortFieldId: action.selectedSortFieldId,
        sortFields: action.sortFields[action.selectedSortFieldId].request,
      };
    default:
      return state;
  }
};

export default sortResultsReducer;
