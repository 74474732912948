import types from './types';

const initialState = {
  sessionDialogOpen: false,
};

const sessionWrapperReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.TOGGLE_SESSION_DIALOG:
      return {
        ...state,
        sessionDialogOpen: action.isSessionDialogOpen,
      };

    default:
      return state;
  }
};

export default sessionWrapperReducer;
