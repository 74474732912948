import * as React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import last from 'lodash/last';
import find from 'lodash/find';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { Card, Switch } from 'ui-components';
import { getStatusCodeText } from 'components/newShipmentDetails/utils';
import * as DateUtils from 'common/dateUtils';
import Table, { tableClasses } from 'common/Table/Table';
import { useLocationFutureShipments, useLocationShipments } from '../location.service';
import dummyShipmentData from './dummyShipmentData';
import dummyFutureStopShipmentData from './dummyFutureStopShipmentData';
import { ShipmentDirection } from '../models';
import { LatestStopStatus, LocationShipmentResponse, Details } from './models';
import DateFormatUtil from '../../shipment/common/utils/dateFormatUtil';

const StyledRow = styled(Row)`
  &.toggleTitleRow {
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
  }

  &.justify-content-between {
    @include font-semibold;
    text-transform: uppercase;
  }
`;

interface LocationShipmentsProps extends WrappedComponentProps {
  locationId: string | undefined;
  direction: ShipmentDirection;
}

interface IFutureStopShipment {
  identifier: number | string;
  status: string;
  from: string;
  eta: string | undefined;
  mode: string;
  shipmentId: number;
}

let locationsToggleIdGen = 0;

const LocationShipments: React.FC<LocationShipmentsProps> = ({ direction, locationId, intl }) => {
  const [enableFutureStops, setEnableFutureStops] = React.useState(false);
  let shipments: LocationShipmentResponse | undefined = useLocationShipments(locationId);
  let futureShipments: LocationShipmentResponse | undefined = useLocationFutureShipments(locationId);
  const [locationsToggleId] = React.useState(() => `locations-toggle-${locationsToggleIdGen++}`);

  if (get(window, 'portalGlobal.dummyLocationShipmentsData')) {
    shipments = dummyShipmentData as any;
    futureShipments = dummyFutureStopShipmentData as any;
  }
  const inboundColumns = [
    {
      title: intl.formatMessage({ id: 'locationDetails.shipmentsColHeaders.identifier', defaultMessage: 'Identifier' }),
      dataIndex: 'identifier',
      key: 'identifier',
      render: function renderRow(text: string, record: any) {
        return <Link to={`/tracking-details/${record.mode.toLowerCase()}/${record.shipmentId}`}>{text}</Link>;
      },
    },
    {
      title: intl.formatMessage({ id: 'locationDetails.shipmentsColHeaders.status', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: intl.formatMessage({ id: 'locationDetails.shipmentsColHeaders.eta', defaultMessage: 'ETA / ATA' }),
      dataIndex: 'eta',
      key: 'eta',
    },
    {
      title: intl.formatMessage({
        id: 'locationDetails.shipmentsColHeaders.inboundFrom',
        defaultMessage: 'Inbound From',
      }),
      dataIndex: 'from',
      key: 'from',
    },
  ];

  const outboundColumns = [
    {
      title: intl.formatMessage({ id: 'locationDetails.shipmentsColHeaders.identifier', defaultMessage: 'Identifier' }),
      dataIndex: 'identifier',
      key: 'identifier',
      render: function renderRow(text: string, record: any) {
        return <Link to={`/tracking-details/${record.mode.toLowerCase()}/${record.shipmentId}`}>{text}</Link>;
      },
    },
    {
      title: intl.formatMessage({ id: 'locationDetails.shipmentsColHeaders.status', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: intl.formatMessage({ id: 'locationDetails.shipmentsColHeaders.eta', defaultMessage: 'ETA / ATA' }),
      dataIndex: 'eta',
      key: 'eta',
    },
    {
      title: intl.formatMessage({
        id: 'locationDetails.shipmentsColHeaders.outboundTo',
        defaultMessage: 'Outbound To',
      }),
      dataIndex: 'to',
      key: 'to',
    },
  ];

  const etaDescriptionFormat = (etaUtcDateTime: string | undefined) => {
    const message = defineMessages({
      etaIs: {
        id: 'carrierShipmentList.stopInfo.dateTimeText',
        defaultMessage: '{date} at {time}',
      },
    });

    const outputTimezone = DateUtils.browserTimezone();
    const dateText = DateFormatUtil.formatAsDate(etaUtcDateTime, null, outputTimezone);
    const timeText = DateFormatUtil.formatAsTimeWithTimezone(etaUtcDateTime, null, outputTimezone);
    return dateText && timeText ? intl.formatMessage(message.etaIs, { date: dateText, time: timeText }) : undefined;
  };

  const incomingDataSource = (shipments?.incoming?.results ?? []).reduce((acc: IFutureStopShipment[], val: Details) => {
    const lastStopStatus: LatestStopStatus | undefined = last(val.latestStopStatuses);

    // SHOULD be the current location ID of the page your on
    const lastStopId = get(val, 'lastVisitedStopInfo.locationId');
    const lastStop = find(val.shipmentStops, ['location.locationId', lastStopId]);
    const inboundFromStopNumber = get(lastStop, `stopNumber`, 0);
    const stop = find(val.shipmentStops, ['stopNumber', inboundFromStopNumber]);
    acc.push({
      identifier: val.shipmentIdentifiers[0].value,
      status: getStatusCodeText(intl, val.latestStatusUpdate.statusCode) || '',
      from: `${get(stop, 'location.address.city') ? get(stop, 'location.address.city') : ''} ${
        get(stop, 'location.address.state') ? get(stop, 'location.address.state') : ''
      }`,
      eta: lastStopStatus ? etaDescriptionFormat(lastStopStatus.utcEarliestEstimatedArrivalDateTime) : '',
      mode: val.mode,
      shipmentId: val.shipmentId,
    });
    return acc;
  }, []) as IFutureStopShipment[];

  const incomingFutureStopShipments = (futureShipments?.incoming?.results ?? []).reduce(
    (shipment: IFutureStopShipment[], shipmentDetails: Details) => {
      const futureStop = shipmentDetails.shipmentStops[0]; // Note that this depends on PV2S Filtering out the non-future stops for this location
      shipment.push({
        identifier: shipmentDetails.shipmentIdentifiers[0].value,
        status: getStatusCodeText(intl, shipmentDetails.latestStatusUpdate.statusCode) || '',
        from: 'N/A',
        eta: futureStop ? etaDescriptionFormat(futureStop.utcAppointmentStartDateTime) : '',
        mode: shipmentDetails.mode,
        shipmentId: shipmentDetails.shipmentId,
      });
      return shipment;
    },
    []
  ) as IFutureStopShipment[];

  const outgoingDataSource = (shipments?.outgoing?.results ?? []).reduce(
    (
      acc: Array<{
        identifier: number | string;
        status: string;
        to: string;
        eta: string | undefined;
        mode: string;
        shipmentId: number;
      }>,
      val: Details
    ) => {
      const lastStopStatus: LatestStopStatus | undefined = last(val.latestStopStatuses);
      const nextStopId = get(val, 'nextStopArrivalInfo.locationId');
      const nextStop = find(val.shipmentStops, ['location.locationId', nextStopId]);
      const inboundFromStopNumber = get(nextStop, `stopNumber`, 0);
      const stop = find(val.shipmentStops, ['stopNumber', inboundFromStopNumber]);
      acc.push({
        identifier: val.shipmentIdentifiers[0].value,
        status: getStatusCodeText(intl, val.latestStatusUpdate.statusCode) || '',
        to: `${get(stop, `location.address.city`) ? get(stop, `location.address.city`) : ''} ${
          get(stop, `location.address.state`) ? get(stop, `location.address.state`) : ''
        }`,
        eta: lastStopStatus ? etaDescriptionFormat(lastStopStatus.utcEarliestEstimatedArrivalDateTime) : '',
        mode: val.mode,
        shipmentId: val.shipmentId,
      });
      return acc;
    },
    []
  );

  const setRowClassname = (_: any, index: number) => {
    return classNames({
      [tableClasses.shaded]: index % 2 === 0,
    });
  };

  let inboundShipments = enableFutureStops
    ? incomingDataSource.concat(incomingFutureStopShipments)
    : incomingDataSource;
  const toggleEnableFutureStops = () => {
    setEnableFutureStops(!enableFutureStops);
    inboundShipments = enableFutureStops ? incomingDataSource.concat(incomingFutureStopShipments) : incomingDataSource;
  };

  return (
    <>
      {direction === ShipmentDirection.INBOUND && (
        <>
          <StyledRow className="toggleTitleRow">
            <h2>
              <FormattedMessage
                id="locationDetails.inboundShipmentsHeading"
                defaultMessage="Current Inbound Shipments"
              />
            </h2>
            <div className="d-flex align-items-center">
              <label htmlFor={locationsToggleId} className="font-weight-bold mr-3">
                {<FormattedMessage id="locations.label.toggle" defaultMessage="Show only immediate stop" />}
              </label>
              <Switch
                id={locationsToggleId}
                data-testid="toggleEnableFutureStops"
                onChange={toggleEnableFutureStops}
                disabled={false}
              />
            </div>
          </StyledRow>
          <Card>
            <Table
              locale={{
                emptyText: intl.formatMessage({
                  id: 'locationDetails.inboundShipmentsEmptyText',
                  defaultMessage: 'There are currently no inbound shipments to this location being tracked',
                }),
              }}
              columns={inboundColumns}
              dataSource={inboundShipments}
              pagination={false}
              rowClassName={setRowClassname}
              showSorterTooltip={false}
            />
          </Card>
        </>
      )}
      {direction === ShipmentDirection.OUTBOUND && (
        <>
          <h2>
            <FormattedMessage
              id="locationDetails.outboundShipmentsHeading"
              defaultMessage="Current Outbound Shipments"
            />
          </h2>
          <Card>
            <Table
              locale={{
                emptyText: intl.formatMessage({
                  id: 'locationDetails.outboundShipmentsEmptyText',
                  defaultMessage: 'There are currently no outbound shipments from this location being tracked',
                }),
              }}
              columns={outboundColumns}
              dataSource={outgoingDataSource}
              pagination={false}
              rowClassName={setRowClassname}
              showSorterTooltip={false}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default injectIntl(LocationShipments);
