import get from 'lodash/get';
import { combineReducers } from 'redux';

import types from './types';

export const initialState = () => ({});

const configReducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.CONFIG_GET_SUCCESS:
      return { ...state, hereMapsConfig: get(action.payload, 'hereMapsConfig', undefined) };

    case types.CONFIG_GET_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

const reducers = combineReducers({
  configReducer: configReducer,
});

export default reducers;
