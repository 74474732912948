import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage, defineMessages, createIntlCache, createIntl } from 'react-intl';
import EmailValidator from 'email-validator';
import styled from 'styled-components';
import { message, Input, MultiInput, TextArea, Button, Copy, Link } from 'ui-components';
import { locale, messages } from 'i18n/configurei18n';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { getUrl } from '../../../common/urlUtils';
import * as styles from './ShareWindow.module.scss';
import { Theme } from '../../settings/BrandTheming/models/Theme';
import { PrincipalAuthorizations } from '../../../common/authorizations';

const messagesIntl = defineMessages({
  recipients: {
    id: 'shipmentDetails.shareWindow.recipients',
    defaultMessage: 'Recipients',
  },
  enterEmailAddress: {
    id: 'shipmentDetails.shareWindow.enterEmailAddress',
    defaultMessage: 'Enter email address',
  },
  message: {
    id: 'shipmentDetails.shareWindow.message',
    defaultMessage: 'Message',
  },
  enterYourMessage: {
    id: 'shipmentDetails.shareWindow.enterYourMessage',
    defaultMessage: 'Enter your message',
  },
});

const cache = createIntlCache();
const intl = createIntl({ locale, messages }, cache);
export interface StyledLinkProps {
  theme: Theme;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  background-color: ${(props) => props.theme.primaryColor};
`;

export interface ShareWindowProps {
  publicLink?: string;
  isSendingEmail: boolean;
  sendShareEmail: (emailAddresses: string[], message: string) => void;
  generateShareLink: (authorizations: PrincipalAuthorizations) => void;
  toggleShareWindow: () => void;
  authorizations: PrincipalAuthorizations;
}

export interface ShareWindowState {
  emails: string[];
  comments: string;
}

class ShareWindow extends React.Component<ShareWindowProps, ShareWindowState> {
  public state = {
    emails: [],
    comments: '',
  };

  public static contextType = ThemeContext;

  public showLinkCopiedToast = () => {
    message.success('Copied to clipboard.');
  };

  public onEmailChange = (emails: string[]) => {
    this.setState({
      emails,
    });
  };

  public onCommentsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      comments: event.target.value,
    });
  };

  public onSend = () => {
    this.props.sendShareEmail(this.state.emails, this.state.comments);
  };

  public onGenerateLinkClick = () => {
    if (this.props.publicLink === undefined) {
      this.props.generateShareLink(this.props.authorizations);
    }
  };

  public render() {
    return (
      <div data-testid="shareWindow" id="shareWindow" className={styles.shareWindow}>
        <div className={styles.header}>
          <div className={styles.headerText}>
            <FormattedMessage id="shipmentDetails.shareWindow.shareThisShipment" defaultMessage="Share this shipment" />
          </div>
          <button
            className={styles.generateLink}
            onClick={this.onGenerateLinkClick}
            data-locator="shipment-share-get-link"
          >
            <FormattedMessage
              id="shipmentDetails.shareWindow.getShareableLink"
              defaultMessage="Get shareable link"
              tagName="span"
            />
            <StyledLink theme={this.context} className={styles.generateLinkIcon} />
          </button>
        </div>
        {this.props.publicLink !== undefined && (
          <div id="publicUrlWrapper" className={styles.publicUrlWrapper} data-locator="shipment-share-link">
            <Input
              type="text"
              id="linkText"
              name="linkText"
              theme={this.context}
              defaultValue={getUrl(this.props.publicLink)}
              custom={{
                readOnly: true,
              }}
              style={{ borderColor: this.context.primaryColor }}
            />
            <CopyToClipboard text={getUrl(this.props.publicLink)} onCopy={this.showLinkCopiedToast}>
              <Button className={styles.copyButton} size={'sm'} icon>
                <Copy />
              </Button>
            </CopyToClipboard>
          </div>
        )}
        <React.Fragment>
          <MultiInput
            label={intl.formatMessage(messagesIntl.recipients)}
            placeholder={intl.formatMessage(messagesIntl.enterEmailAddress)}
            validator={EmailValidator.validate}
            onChange={this.onEmailChange}
            id="inputWrapper"
            className={styles.inputWrapper}
          />
          <TextArea
            label={intl.formatMessage(messagesIntl.message)}
            placeholder={intl.formatMessage(messagesIntl.enterYourMessage)}
            onChange={this.onCommentsChange}
            value={this.state.comments}
            charLimit={250}
            id="inputWrapper"
            autosize={{ minRows: 4, maxRows: 4 }}
            className={styles.inputWrapper}
          />
        </React.Fragment>
        <div className={styles.buttonsWrapper}>
          {this.state.emails.length > 0 && (
            <React.Fragment>
              <Button type="secondary" clickFn={this.props.toggleShareWindow}>
                <FormattedMessage id="shipmentDetails.shareWindow.cancel" defaultMessage="Cancel" />
              </Button>
              <Button type="primary" clickFn={this.onSend} loading={this.props.isSendingEmail}>
                <FormattedMessage id="shipmentDetails.shareWindow.send" defaultMessage="Send" />
              </Button>
            </React.Fragment>
          )}
          {this.state.emails.length === 0 && (
            <Button
              type="primary"
              className={styles.doneButton}
              clickFn={this.props.toggleShareWindow}
              loading={this.props.isSendingEmail}
            >
              <FormattedMessage id="shipmentDetails.shareWindow.done" defaultMessage="Done" />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default ShareWindow;
