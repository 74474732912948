import { useState } from 'react';
import { Select, Divider, DatePicker } from 'antd';
import styled from 'styled-components';
import { startOfYesterday, startOfToday, startOfTomorrow, subDays, endOfToday } from 'date-fns';
import moment from 'moment';
import { primaryGrey, primaryGreyFifty } from 'styles/colors';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'MM/DD/YYYY';
const yesterday = startOfYesterday();
const today = startOfToday();
const todayEndDay = endOfToday();
const tomorrow = startOfTomorrow();
const formatDate = (date) => moment(date).format(dateFormat);
const formatDateISO = (date) => moment(date).toISOString();

const YESTERDAY = {
  name: 'yesterday',
  value: formatDateISO(yesterday),
};

const TODAY = {
  name: 'today',
  value: formatDateISO(today),
};

const TODAY_END_DAY = {
  name: 'today-end-day',
  value: formatDateISO(todayEndDay),
};

const TOMORROW = {
  name: 'tomorrow',
  value: formatDateISO(tomorrow),
};

const LAST_SEVEN_DAYS = {
  name: 'last-seven-days',
  value: formatDateISO(subDays(today, 7)),
};

const LAST_THIRTY_DAYS = {
  name: 'last-thirty-days',
  value: formatDateISO(subDays(today, 30)),
};

const CUSTOM_DATE_RANGE = {
  name: 'custom-date-range',
};

const StyledSelect = styled(Select)`
  .ant-select-arrow {
    color: ${primaryGrey};
  }
`;

const StyledSelectDropdown = styled('div')`
  .ant-select-item-group {
    min-height: 0;
    padding: 0;
  }

  .ant-select-item-option-grouped {
    padding-left: 12px;
  }
`;

const StyledDivider = styled(Divider)`
  border-color: ${primaryGreyFifty};
  margin: 8px;
  min-width: 0;
  display: block;
  width: auto;
`;

const StyledRangePicker = styled(RangePicker)`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  width: 100%;
  z-index: 1050;
`;

const StyledOverlay = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1049;
`;

const Caret = () => (
  <svg width="9" height="7" viewBox="0 0 9 7" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.359494 0.732042C0.783625 0.378304 1.41421 0.435368 1.76795 0.859499L4.3556 3.96208L7.09634 0.84025C7.46071 0.425216 8.09254 0.384145 8.50758 0.748515C8.92261 1.11288 8.96368 1.74472 8.59931 2.15975L5.0876 6.15975C4.89482 6.37934 4.61563 6.50362 4.32345 6.49992C4.03127 6.49622 3.75532 6.3649 3.56816 6.1405L0.232037 2.1405C-0.121702 1.71637 -0.0646375 1.08578 0.359494 0.732042Z"
      fill="currentColor"
    />
  </svg>
);

const getDefaultDateValue = (startDate, endDate) => {
  const formatStartDate = formatDate(startDate);
  const formatEndDate = formatDate(endDate);

  if (startDate && endDate) {
    if (formatStartDate === formatEndDate) {
      return formatStartDate;
    }

    return `${formatStartDate} - ${formatEndDate}`;
  }

  if (startDate) {
    return formatStartDate;
  }

  return null;
};

const DatePickerV2 = ({ datepickerChangeFn, boundStartDateValue, boundEndDateValue }) => {
  const defaultDateValue = getDefaultDateValue(boundStartDateValue, boundEndDateValue);

  const [dateValue, setDateValue] = useState(defaultDateValue);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const defaultValue = [
    boundStartDateValue ? moment(boundStartDateValue) : null,
    boundEndDateValue ? moment(boundEndDateValue) : null,
  ];

  const handleSelectChange = (val) => {
    switch (val) {
      case YESTERDAY.name:
        datepickerChangeFn(YESTERDAY.value, null);
        return setDateValue(val);
      case TODAY.name:
        datepickerChangeFn(TODAY.value, null);
        return setDateValue(val);
      case TOMORROW.name:
        datepickerChangeFn(TOMORROW.value, null);
        return setDateValue(val);
      case LAST_SEVEN_DAYS.name:
        datepickerChangeFn(LAST_SEVEN_DAYS.value, TODAY_END_DAY.value);
        return setDateValue(val);
      case LAST_THIRTY_DAYS.name:
        datepickerChangeFn(LAST_THIRTY_DAYS.value, TODAY_END_DAY.value);
        return setDateValue(val);
      case CUSTOM_DATE_RANGE.name:
        return setDatePickerOpen(true);
      default:
        break;
    }
  };

  const formatDateRange = (startDate, endDate) => {
    const formatStartDate = formatDate(startDate);
    const formatEndDate = formatDate(endDate);

    if (formatStartDate === formatEndDate) {
      datepickerChangeFn(formatDateISO(startDate), null);
      return formatStartDate;
    }

    datepickerChangeFn(formatDateISO(startDate), formatDateISO(endDate));

    return `${formatStartDate} - ${formatEndDate}`;
  };

  const handleDatePickerChange = (val) => {
    if (!val) {
      datepickerChangeFn(null, null);
      return setDateValue(null);
    }

    const formattedDate = formatDateRange(val[0]._d, val[1]._d);
    setDatePickerOpen(false);
    return setDateValue(formattedDate);
  };

  return (
    <>
      {!datePickerOpen && (
        <StyledSelect
          className="w-100"
          onChange={handleSelectChange}
          placeholder="Add Dates"
          value={dateValue}
          suffixIcon={Caret}
          dropdownRender={(menu) => <StyledSelectDropdown>{menu}</StyledSelectDropdown>}
        >
          <OptGroup>
            <Option value={YESTERDAY.name}>Yesterday</Option>
            <Option value={TODAY.name}>Today</Option>
            <Option value={TOMORROW.name}>Tomorrow</Option>
          </OptGroup>
          <OptGroup label={<StyledDivider />}>
            <Option value={LAST_SEVEN_DAYS.name}>Last 7 Days</Option>
            <Option value={LAST_THIRTY_DAYS.name}>Last 30 Days</Option>
          </OptGroup>
          <OptGroup label={<StyledDivider />}>
            <Option value={CUSTOM_DATE_RANGE.name}>Custom Date Range</Option>
          </OptGroup>
        </StyledSelect>
      )}
      <StyledRangePicker
        open={datePickerOpen}
        onChange={handleDatePickerChange}
        defaultValue={defaultValue}
        format={dateFormat}
        value={defaultValue}
      />
      {datePickerOpen && <StyledOverlay onClick={() => setDatePickerOpen(false)} />}
    </>
  );
};

export default DatePickerV2;
