import * as colors from 'styles/colors';
export enum TimelinessCodeEnum {
  EARLY = 'EARLY',
  LATE = 'LATE',
  ON_TIME = 'ON_TIME',
  UNKNOWN = 'UNKNOWN',
}

export const TimelinessCodeStatusColor: { [P in TimelinessCodeEnum]: string } = {
  [TimelinessCodeEnum.ON_TIME]: colors.green,
  [TimelinessCodeEnum.EARLY]: colors.primaryBlueSeventyFive,
  [TimelinessCodeEnum.LATE]: colors.newFruit,
  [TimelinessCodeEnum.UNKNOWN]: colors.primaryGreyForty,
};

const message = {
  etaStatusOnTime: {
    id: 'timelinessCode.listCardStatusFlagText.onTime',
    defaultMessage: 'On Time',
  },
  etaStatusEarly: {
    id: 'timelinessCode.listCardStatusFlagText.early',
    defaultMessage: 'Early',
  },
  etaStatusLate: {
    id: 'timelinessCode.listCardStatusFlagText.late',
    defaultMessage: 'Late',
  },
  etaStatusUnknown: {
    id: 'timelinessCode.listCardStatusFlagText.unknown',
    defaultMessage: 'Tracking',
  },
};

export const TimelinessCodeStatusIntlKeys: {
  [P in TimelinessCodeEnum]: { id: string; defaultMessage: string };
} = {
  [TimelinessCodeEnum.ON_TIME]: message.etaStatusOnTime,
  [TimelinessCodeEnum.EARLY]: message.etaStatusEarly,
  [TimelinessCodeEnum.LATE]: message.etaStatusLate,
  [TimelinessCodeEnum.UNKNOWN]: message.etaStatusUnknown,
};

export type CodeIntlKey = { id: string; defaultMessage: string };
export type CodeInlItem = {
  past: CodeIntlKey;
  future?: CodeIntlKey;
};
export enum CodeInlItemEnum {
  PAST = 'past',
  FUTURE = 'future',
}
