import { HealthRanges } from '../types';
import { HealthRangesTarget } from './types';

export const makeIsMiddleRange = (ranges: HealthRanges) => (target: HealthRangesTarget) =>
  !!ranges[target].lowLimit && !!ranges[target].highLimit;

export const makeIsEndRange = (ranges: HealthRanges) => (target: HealthRangesTarget) =>
  !ranges[target].lowLimit || !ranges[target].highLimit;

export const getNextRange = (
  rangeKeys: Array<HealthRangesTarget>,
  ranges: HealthRanges,
  target: HealthRangesTarget
) => {
  const enabledRanges = rangeKeys.filter((item) => item === target || ranges[item].isEnabled);
  const targetIndex = enabledRanges.findIndex((item) => item === target);
  if (targetIndex !== rangeKeys.length - 1) {
    return enabledRanges[targetIndex + 1] || null;
  }
  return null;
};

export const getPrevRange = (
  rangeKeys: Array<HealthRangesTarget>,
  ranges: HealthRanges,
  target: HealthRangesTarget
) => {
  const enabledRanges = rangeKeys.filter((item) => item === target || ranges[item].isEnabled);
  const targetIndex = enabledRanges.findIndex((item) => item === target);
  if (targetIndex !== 0) {
    return enabledRanges[targetIndex - 1] || null;
  }
  return null;
};

export const isDescOrder = (rangeKeys: Array<HealthRangesTarget>, ranges: HealthRanges) => {
  const enabledRanges = rangeKeys.filter((item) => ranges[item].isEnabled);
  return !!ranges[enabledRanges[0]].highLimit;
};

export const toggleRange = (
  rangeKeys: Array<HealthRangesTarget>,
  ranges: HealthRanges,
  target: HealthRangesTarget,
  isEnabled: boolean
) => {
  const nextRange = getNextRange(rangeKeys, ranges, target);
  const prevRange = getPrevRange(rangeKeys, ranges, target);
  const isDesc = isDescOrder(rangeKeys, ranges);
  const isMiddleRange = makeIsMiddleRange(ranges);
  const isEndRange = makeIsEndRange(ranges);

  if (isEnabled) {
    if (isDesc) {
      if (nextRange) {
        if (prevRange) {
          if (isMiddleRange(nextRange)) {
            const nextHighLimit = ranges[nextRange].highLimit;
            ranges[nextRange].highLimit = (ranges[target].lowLimit as number) - 0.1;
            ranges[target].highLimit = nextHighLimit;
          } else if (isMiddleRange(prevRange)) {
            ranges[target].lowLimit = ranges[prevRange].lowLimit;
            ranges[prevRange].lowLimit = (ranges[target].highLimit as number) + 0.1;
          } else {
            const nextHighLimit = ranges[nextRange].lowLimit;
            ranges[nextRange].lowLimit = (ranges[target].lowLimit as number) - 0.1;
            ranges[target].highLimit = nextHighLimit;
          }
        } else {
          ranges[nextRange].lowLimit = ranges[nextRange].highLimit;
          ranges[nextRange].highLimit = (ranges[target].highLimit as number) - 0.1;
        }
      } else {
        if (prevRange) {
          ranges[prevRange].highLimit = ranges[prevRange].lowLimit;
          ranges[prevRange].lowLimit = (ranges[target].lowLimit as number) + 0.1;
        }
      }
    } else {
      if (!prevRange && nextRange && isEndRange(nextRange)) {
        ranges[nextRange].highLimit = ranges[nextRange].lowLimit;
        ranges[nextRange].lowLimit = (ranges[target].lowLimit as number) + 0.1;
      }
      if (nextRange && isMiddleRange(nextRange) && prevRange && isEndRange(prevRange)) {
        ranges[nextRange].lowLimit = (ranges[target].highLimit as number) + 0.1;
        ranges[target].lowLimit = (ranges[prevRange].lowLimit as number) + 0.1;
      }
      if (!nextRange && prevRange && isEndRange(prevRange)) {
        ranges[prevRange].lowLimit = ranges[prevRange].highLimit;
        ranges[prevRange].highLimit = (ranges[target].highLimit as number) - 0.1;
      }
      if (prevRange && isMiddleRange(prevRange) && nextRange && isEndRange(nextRange)) {
        if ((ranges[target].highLimit as number) < (ranges[nextRange].highLimit as number)) {
          const prevHighLimit = ranges[prevRange].highLimit;
          ranges[prevRange].highLimit = (ranges[target].lowLimit as number) - 0.1;
          ranges[target].highLimit = prevHighLimit;
        } else {
          ranges[target].lowLimit = ranges[nextRange].highLimit;
          ranges[nextRange].highLimit = (ranges[target].highLimit as number) + 0.1;
        }
      }
      if (prevRange && isEndRange(prevRange) && nextRange && isEndRange(nextRange)) {
        ranges[target].lowLimit = (ranges[prevRange].lowLimit as number) + 0.1;
        ranges[nextRange].highLimit = (ranges[target].highLimit as number) + 0.1;
      }
    }
  } else {
    if (nextRange) {
      if (isMiddleRange(nextRange)) {
        if (isMiddleRange(target)) {
          if (isDesc) {
            ranges[nextRange].highLimit = ranges[target].highLimit;
          } else {
            ranges[nextRange].lowLimit = ranges[target].lowLimit;
          }
        } else {
          if (isDesc) {
            ranges[nextRange].highLimit = ranges[nextRange].lowLimit;
            ranges[nextRange].lowLimit = null;
          } else {
            ranges[nextRange].lowLimit = ranges[nextRange].highLimit;
            ranges[nextRange].highLimit = null;
          }
        }
      } else {
        if (prevRange) {
          if (isMiddleRange(prevRange)) {
            if (isDesc) {
              ranges[prevRange].lowLimit = ranges[target].lowLimit;
            } else {
              ranges[prevRange].highLimit = ranges[target].highLimit;
            }
          } else {
            if (isDesc) {
              ranges[nextRange].lowLimit = ranges[target].highLimit;
            } else {
              ranges[nextRange].highLimit = ranges[target].lowLimit;
            }
          }
        }
      }
    } else {
      if (prevRange) {
        if (isMiddleRange(prevRange)) {
          if (isDesc) {
            ranges[prevRange].lowLimit = ranges[prevRange].highLimit;
            ranges[prevRange].highLimit = null;
          } else {
            ranges[prevRange].highLimit = ranges[prevRange].lowLimit;
            ranges[prevRange].lowLimit = null;
          }
        }
      }
    }
  }

  ranges[target].isEnabled = isEnabled;
};
