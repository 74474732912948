enum sessionStorageKeys {
  FORM_DATA = 'CARRIER_ONBOARDING:formData',
  FORM_DATA_STEPS = 'CARRIER_ONBOARDING:formDataSteps',
  FRESHDESK = 'CARRIER_ONBOARDING:freshdesk',
  EMAIL_RESEND = 'CARRIER_ONBOARDING:emailResend',
  EMAIL_CAN_SIGNUP = 'CARRIER_ONBOARDING:emailCanSignup',
  SUCCESS_SCREEN_STEP = 'CARRIER_ONBOARDING:successStep',
}

const sessionStep = window.sessionStorage;

const inviteTextPart = (keyPart: string = '') => `${keyPart ? `:${keyPart}` : ''}`;

export const sessionGetFormData = (inviteId: string = '') => {
  let result: any = sessionStep.getItem(`${sessionStorageKeys.FORM_DATA}${inviteTextPart(inviteId)}`);

  try {
    result = JSON.parse(result);
  } catch (e) {
    return null;
  }
  return result;
};

export const sessionGetFormStepsData = (inviteId: string = '') => {
  let result: any = sessionStep.getItem(`${sessionStorageKeys.FORM_DATA_STEPS}${inviteTextPart(inviteId)}`);

  try {
    result = JSON.parse(result) || [];
  } catch (e) {
    return [];
  }
  return result;
};

export const sessionUpdateFormData = (data: any, inviteId: string = '', stepId: string = ''): void => {
  // TODO make sure to update instead of replace data with somethink like extend object
  sessionStep.setItem(`${sessionStorageKeys.FORM_DATA}${inviteTextPart(inviteId)}`, JSON.stringify(data));
  sessionUpdateFormStepsData(inviteId, stepId);
};

export const sessionUpdateFormStepsData = (inviteId: string = '', stepId: string = '') => {
  const currentState = sessionGetFormStepsData(inviteId);
  sessionStep.setItem(
    `${sessionStorageKeys.FORM_DATA_STEPS}${inviteTextPart(inviteId)}`,
    JSON.stringify([stepId, ...currentState])
  );
};

export const sessionExistsSupportRequest = (tag: string, inviteId: string = '') => {
  return !!sessionStep.getItem(`${sessionStorageKeys.FORM_DATA}${tag}${inviteTextPart(inviteId)}`);
};

export const sessionRecordSupportRequest = (tag: string, inviteId: string = '') => {
  return sessionStep.setItem(`${sessionStorageKeys.FRESHDESK}${tag}${inviteTextPart(inviteId)}`, tag);
};

export const sessionRecordInvitationResendEmail = (email: string) => {
  return sessionStep.setItem(`${sessionStorageKeys.EMAIL_RESEND}${inviteTextPart(email)}`, email);
};

export const sessionCheckInvitationResendEmail = (email: string) => {
  return !!sessionStep.getItem(`${sessionStorageKeys.EMAIL_RESEND}${inviteTextPart(email)}`);
};

export const sessionGetSucessScreen = (inviteId: string = '') => {
  return sessionStep.getItem(`${sessionStorageKeys.SUCCESS_SCREEN_STEP}${inviteTextPart(inviteId)}`);
};

export const sessionRecordSucessScreen = (stepId: string | number, inviteId: string = '') => {
  return sessionStep.setItem(
    `${sessionStorageKeys.SUCCESS_SCREEN_STEP}${inviteTextPart(inviteId)}}`,
    stepId.toString()
  );
};

export const sessionRecordEmailValid = (email: string, inviteId: string = '') => {
  return sessionStep.setItem(
    `${sessionStorageKeys.EMAIL_CAN_SIGNUP}${inviteTextPart(inviteId)}${inviteTextPart(email)}`,
    'true'
  );
};

export const sessionCheckEmailValid = (email: string, inviteId: string = '') => {
  return sessionStep.getItem(
    `${sessionStorageKeys.EMAIL_CAN_SIGNUP}${inviteTextPart(inviteId)}${inviteTextPart(email)}`
  );
};
