import { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import uniq from 'lodash/uniq';
import { Radio, Select, Input, Button } from 'ui-components';
import { black } from 'styles/colors';
import { StyledFilterSubTitle } from 'components/common/DateRangeInputWithTitle/DateRangeInputWithTitle';
import SelectedChips from 'components/common/forms/selectedChips/SelectedChips';
import { buildRadioHandler } from './utils';
import { InventoryOrderShipmentAssociatedFilterInput } from '../../../components/Inventory/models';
import CollapsableItem from '../../common/ListPageLayout/CollapsableItem';

const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selection-placeholder {
    opacity: 0.9;
  }

  .ant-select-arrow {
    color: ${black};
    top: 30%;
    right: 20px;

    .material-icons {
      transition: transform 0.3s;
      transform-origin: center center;
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      .material-icons {
        transform: rotate(-180deg);
      }
    }
  }
`;

const StyledDiv = styled('div')`
  padding-bottom: 10px;
`;

const StyledDurationDiv = styled('div')`
  background: #fff;
  padding: 10px;
  margin: 10px 0;
`;

const StyledCombo = styled('div')`
  display: flex;
  margin: 10px;
`;

const StyledLabel = styled('label')`
  display: flex;
  align-items: center;
  margin: 15px 10px 0px;
`;

const StyledRadio = styled('input')`
  margin-right: 10px;
`;

const StyledInput = styled(Input)`
  width: 80px;
`;

const StyledSelectDuration = styled('div')`
  width: 80px;
  margin-left: 10px;
`;

const StyledButton = styled(Button)`
  margin: 15px auto;
  width: 100%;
  place-content: center;
  border: solid 1px #d5d4d4 !important;
`;

interface orderHealthCriteria {
  shipmentStatus: string;
  durationRange: {
    toDuration: { amount: string; unit: string };
    toInclusive: boolean;
    fromInclusive: boolean;
    fromDuration: { amount: string; unit: string };
  };
}

const ShipmentAssociated = ({
  dataKey,
  selectedValue = null,
  setValueFn,
  shipmentStatusList,
  setOrderHealthCriteria,
  orderHealthCriteria,
}: InventoryOrderShipmentAssociatedFilterInput) => {
  const intl = useIntl();
  const INIT_DURATION = {
    fromInclusive: true,
    fromDuration: {
      unit: 'DAYS',
      amount: '',
    },
    toDuration: {
      unit: 'DAYS',
      amount: '',
    },
    toInclusive: false,
    type: 'lessThan',
  };
  const [duration, setDuration] = useState({ ...INIT_DURATION });
  const [shipmentStatus, setShipmentStatus] = useState('');
  const [chipData, setChipData] = useState([] as string[]);

  const activeKey = isNil(selectedValue) || isEmpty(selectedValue) ? undefined : dataKey;

  const handleChangeRadio = (newValue: any) => {
    setValueFn(newValue);
  };

  const durationUnits = [
    { value: 'DAYS', displayValue: 'Days' },
    { value: 'HOURS', displayValue: 'Hours' },
  ];
  const getChipData = useCallback((data) => {
    let chips = [] as string[];
    data.forEach((item: orderHealthCriteria) => {
      let msg = '';
      if (item?.durationRange?.toDuration) {
        msg = `< ${item.durationRange?.toDuration?.amount} ${item.durationRange?.toDuration?.unit} ${item.shipmentStatus}`;
      } else if (item?.durationRange?.fromDuration) {
        msg = `> ${item.durationRange?.fromDuration.amount} ${item.durationRange?.fromDuration.unit} ${item.shipmentStatus}`;
      } else {
        msg = item.shipmentStatus;
      }

      chips = uniq([...chips, msg]);
    });

    setChipData(chips);
  }, []);

  useEffect(() => {
    if (orderHealthCriteria) {
      getChipData(orderHealthCriteria);
    }
  }, [orderHealthCriteria, getChipData]);

  const setUniqueOrderHealthCriteria = (data: any) => {
    const stringifyData = uniq(data.map((item: object) => JSON.stringify(item)));
    const uniqData = stringifyData.map((item: any) => JSON.parse(item));

    setOrderHealthCriteria(uniqData);
  };

  const handleSetShipmentStatus = (value: string) => {
    const obj = { shipmentStatus: value };
    if (value === 'ON_TIME' || value === 'UNKNOWN') {
      const updatedOrderHealthCriteriaData = [...orderHealthCriteria, obj];

      setUniqueOrderHealthCriteria(updatedOrderHealthCriteriaData);
    }

    setShipmentStatus(value);
  };

  const handleApplyDuration = () => {
    const obj = { shipmentStatus, durationRange: {} };
    if (duration.type === 'lessThan') {
      obj.durationRange = {
        toDuration: duration.toDuration,
        toInclusive: duration.toInclusive,
      };
    } else if (duration.type === 'greaterThan') {
      obj.durationRange = {
        fromDuration: duration.fromDuration,
        fromInclusive: duration.fromInclusive,
      };
    }
    const updatedOrderHealthCriteriaData = [...orderHealthCriteria, obj];

    setUniqueOrderHealthCriteria(updatedOrderHealthCriteriaData);
  };

  const onDeleteChip = (deletedItem: string) => {
    const itemArr = deletedItem.split(' ');
    const type = itemArr[itemArr.length - 1];
    const unit = itemArr[2];
    const amount = itemArr[1];
    const directionString = itemArr[0];
    let updatedOrderHealthCriteriaData;

    if (type === 'UNKNOWN' || type === 'ON_TIME') {
      updatedOrderHealthCriteriaData = orderHealthCriteria.filter(
        (item: orderHealthCriteria) => item.shipmentStatus !== type
      );
    } else {
      updatedOrderHealthCriteriaData = orderHealthCriteria.filter((item: orderHealthCriteria) => {
        const durationObj = item.durationRange && item.durationRange;

        if (
          item.shipmentStatus === type &&
          ((directionString === '>' &&
            durationObj.fromDuration &&
            durationObj.fromDuration.amount === amount &&
            durationObj.fromDuration.unit === unit) ||
            (directionString === '<' &&
              durationObj.toDuration &&
              durationObj.toDuration.amount === amount &&
              durationObj.toDuration.unit === unit))
        ) {
          return false;
        } else {
          return true;
        }
      });
    }

    setDuration({ ...INIT_DURATION });
    setShipmentStatus('');
    setUniqueOrderHealthCriteria(updatedOrderHealthCriteriaData);
  };

  return (
    <CollapsableItem
      panelTitle={intl.formatMessage({
        id: 'select.orderTable.filters.transportation',
        defaultMessage: 'Transportation',
      })}
      dataKey={dataKey}
      activeKey={activeKey}
    >
      <StyledDiv>
        <StyledFilterSubTitle>
          {intl.formatMessage({
            id: 'select.orderTable.filters.shipmentAssociated',
            defaultMessage: 'Shipment Associated',
          })}
        </StyledFilterSubTitle>
        <StyledDiv>
          <Radio
            onChange={buildRadioHandler(handleChangeRadio)}
            value={selectedValue}
            layout="col"
            radioData={[
              {
                value: null,
                label: intl.formatMessage({
                  id: 'select.orderTable.filters.shipmentAssociated.all',
                  defaultMessage: 'All',
                }),
              },
              {
                value: 'true',
                label: intl.formatMessage({
                  id: 'select.orderTable.filters.shipmentAssociated.yes',
                  defaultMessage: 'Yes',
                }),
              },
              {
                value: 'false',
                label: intl.formatMessage({
                  id: 'select.orderTable.filters.shipmentAssociated.no',
                  defaultMessage: 'No',
                }),
              },
            ]}
          />
        </StyledDiv>
      </StyledDiv>
      <StyledDiv>
        <StyledFilterSubTitle>
          {intl.formatMessage({
            id: 'select.orderTable.filters.shipmentStatus',
            defaultMessage: 'Order Delivery Status',
          })}
        </StyledFilterSubTitle>
        <StyledSelect
          placeholder={intl.formatMessage({
            id: 'select.orderTable.filters.placeholder',
            defaultMessage: 'Select Value',
          })}
          onSelect={handleSetShipmentStatus}
          value={shipmentStatus}
          dataSource={shipmentStatusList || []}
          suffixIcon={<i className="material-icons">arrow_drop_down</i>}
        />
        {shipmentStatus && (shipmentStatus === 'EARLY' || shipmentStatus === 'LATE') && (
          <StyledDurationDiv>
            <div>
              <StyledLabel>
                <StyledRadio
                  name="durationType"
                  id="lessThanDurationType"
                  type="radio"
                  checked={duration.type === 'lessThan'}
                  onChange={() =>
                    setDuration({
                      ...INIT_DURATION,
                      toInclusive: false,
                      type: 'lessThan',
                    })
                  }
                />
                {/*@ts-ignore*/}
                <FormattedMessage defaultMessage="Less than" id="select.orderTable.filters.ShipmentStatusLessthan" />
              </StyledLabel>
              {duration.type === 'lessThan' && (
                <StyledCombo>
                  <StyledInput
                    value={duration.toDuration.amount}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDuration({
                        ...duration,
                        toDuration: { ...duration.toDuration, amount: event.target.value },
                      });
                    }}
                    placeholder={intl.formatMessage({
                      id: 'select.orderTable.filters.shipmentStatusLessThanUnits',
                      defaultMessage: 'Enter value',
                    })}
                  />
                  <StyledSelectDuration>
                    <StyledSelect
                      placeholder="Select Duration"
                      onSelect={(value) =>
                        setDuration({ ...duration, toDuration: { ...duration.toDuration, unit: value } })
                      }
                      value={duration.toDuration.unit}
                      dataSource={durationUnits}
                      suffixIcon={<i className="material-icons">arrow_drop_down</i>}
                    />
                  </StyledSelectDuration>
                </StyledCombo>
              )}
            </div>
            <div>
              <StyledLabel>
                <StyledRadio
                  name="durationType"
                  id="greaterThanDurationType"
                  type="radio"
                  checked={duration.type === 'greaterThan'}
                  onChange={() =>
                    setDuration({
                      ...INIT_DURATION,
                      fromInclusive: false,
                      type: 'greaterThan',
                    })
                  }
                />
                <FormattedMessage defaultMessage="Greater than" id="select.orderTable.filters.ShipmentStatusLessthan" />
              </StyledLabel>
              {duration.type === 'greaterThan' && (
                <StyledCombo>
                  <StyledInput
                    value={duration.fromDuration.amount}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDuration({
                        ...duration,
                        fromDuration: { ...duration.fromDuration, amount: event.target.value },
                      });
                    }}
                    placeholder={intl.formatMessage({
                      id: 'select.orderTable.filters.shipmentStatusGreaterThanUnits',
                      defaultMessage: 'Enter value',
                    })}
                  />
                  <StyledSelectDuration>
                    <StyledSelect
                      placeholder="Select Duration"
                      onSelect={(value) =>
                        setDuration({ ...duration, fromDuration: { ...duration.fromDuration, unit: value } })
                      }
                      value={duration.fromDuration.unit}
                      dataSource={durationUnits}
                      suffixIcon={<i className="material-icons">arrow_drop_down</i>}
                    />
                  </StyledSelectDuration>
                </StyledCombo>
              )}
            </div>
            <StyledButton
              type="secondary"
              clickFn={handleApplyDuration}
              disabled={
                (duration.type === 'greaterThan' && !duration.fromDuration.amount) ||
                (duration.type === 'lessThan' && !duration.toDuration.amount)
              }
            >
              <FormattedMessage id="select.orderTable.filters.shipmentStatusApplyDuration" defaultMessage="Apply" />
            </StyledButton>
          </StyledDurationDiv>
        )}
        <SelectedChips listData={chipData} showAllChips={false} passedInDeleteFn={onDeleteChip} />
      </StyledDiv>
    </CollapsableItem>
  );
};

export default ShipmentAssociated;
