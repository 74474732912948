export enum SupportTicketTagEnum {
  ONBOARDING_DIRECT_API = 'ONBOARDING_DIRECT_API',
  EU_SUBCONTRACTOR_LEAD = 'EU_SUBCONTRACTOR_LEAD',
  US_SUBCONTRACTOR_LEAD = 'US_SUBCONTRACTOR_LEAD',
  ONBOARDING_BROKER_LEAD = 'ONBOARDING_BROKER_LEAD',
  ONBOARDING_NEW_TELEMATICS = 'ONBOARDING_NEW_TELEMATICS',
  ONBOARDING_SIGNUP = 'ONBOARDING_SIGNUP',
  ONBOARDING_SIGNUP_EVERGREEN = 'ONBOARDING_SIGNUP_EVERGREEN',
  ONBOARDING_SIGNUP_NO_INVITE = 'ONBOARDING_SIGNUP_NO_INVITE',
}
