import { connectRouter } from 'connected-react-router';
import get from 'lodash/get';
import { AnyAction, combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';

import hereMapsConfigReducer from '../common/typedHereMaps/ducks/reducer';
import aerisWeatherConfigReducer from '../common/typedAerisWeather/ducks/reducer';
import tableauConfigReducer from '../components/analytics/typedAnalytics/ducks/reducer';

import addEditShipmentReducer, { addEditShipmentFormReducer } from '../components/addEditShipment/ducks';
import configReducer from '../components/common/hereMapsConfig/ducks';
import searchResultsReducer from '../components/common/search/ducks';
import sortResultsReducer from '../components/common/sortResults/ducks';
import filterReducer from '../components/filter/ducks';
import locationManagementReducer from '../components/locationManagement/ducks';
import authReducer from '../components/login/LoginForm/ducks/authReducer';
import loginReducer, {
  initialState as loginReducerInitialState,
} from '../components/login/LoginForm/ducks/loginReducer';
import * as loginTypes from '../components/login/LoginForm/ducks/types';
import notesReducer from '../components/notes/ducks';
import tenantAttributesReducer from '../components/newShipmentDetails/ShipmentIdentifiers/ducks';
import { brandThemingReducer } from '../components/settings/BrandTheming/ducks';
import shipmentCountsReducer from '../components/shipmentCounts/ducks';
import ltlShipmentDetailsReducer from '../components/shipmentDetails/LTL/ducks';
import oceanShipmentDetailsReducer from '../components/shipmentDetails/OCEAN/ducks';
import tlShipmentDetailsReducer from '../components/shipmentDetails/TL/ducks';
import searchReducer from '../components/shipmentListComponent/ducks/searchReducer';
import shipmentShareReducer from '../components/shipmentShare/ducks';
import history from '../configureHistory';
import sessionWrapperReducer from './../components/common/sessionWrapper/ducks/reducers';
import manageNotificationsReducer from './../components/notifications/ducks';
import carrierSearchReducer from '../components/carrierFleetListComponent/ducks/carrierSearchReducer';

const appReducer = combineReducers({
  locationManagementReducer,
  addEditShipmentReducer,
  form: addEditShipmentFormReducer,
  loginReducer,
  authReducer,
  searchReducer,
  sortResultsReducer,
  searchResultsReducer,
  filterReducer,
  shipmentCountsReducer,
  tlShipmentDetailsReducer,
  ltlShipmentDetailsReducer,
  oceanShipmentDetailsReducer,
  shipmentShareReducer,
  router: connectRouter(history),
  manageNotificationsReducer,
  configReducer,
  hereMapsConfigReducer,
  aerisWeatherConfigReducer,
  tableauConfigReducer,
  sessionWrapperReducer,
  brandThemingReducer,
  notesReducer,
  tenantAttributesReducer,
  carrierSearchReducer,
});

export type RootState = StateType<typeof appReducer>;

export default (originalState: any, action: AnyAction): RootState => {
  let state = originalState;
  if (action.type === loginTypes.PURGE) {
    // reset redux store except screen width reducer, router reducer
    // Also set loginReducerState.ssoError to stored authReducer.ssoErrorRedirect to display
    //   SSO error message after redirect to log out of Okta on token exchange failure
    const loginReducerState = loginReducerInitialState;
    loginReducerState.ssoError = get(originalState, 'authReducer').ssoErrorRedirect;
    state = {
      router: get(originalState, 'router'),
      loginReducer: loginReducerState,
    };
  }

  return appReducer(state, action);
};
