import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { isDemoShipment } from 'components/landing/LandingMap/demoShipments';
import { getPrincipalAuthorizationsFromState } from '../../common/authorizations';
import { ShipmentModeEnum, EntitlementInfo } from '../../models';
import { RootState } from '../../reducers';
import NotesComponent from './NotesComponent';
import { notesActions } from './ducks';
import { NoteRequest, Note } from './models/Note';

export interface ShipmentNotesContainerProps {
  shipmentId?: string;
  shipmentTenantId?: number;
  mode?: ShipmentModeEnum;
  masterShipmentId?: string;
  shipmentShareToken?: string;
  useIntermodalEndpoint?: boolean;
  entitlementInfo?: EntitlementInfo;
}

export interface NotesComponentProps extends ShipmentNotesContainerProps {
  isCustomerAdmin: boolean;
  isFetchingNotes: boolean;
  isCreatingNote: boolean;
  isUpdatingNote: boolean;
  isDeletingNote: boolean;
  notes?: Note[];
  showNotesWindow: boolean;
  toggleNotesWindow: () => void;
  reset: () => void;
  fetchNotes: () => void;
  fetchPublicNotes: (token: string) => void;
  createNote: (note: NoteRequest) => void;
  updateNote: (note: NoteRequest) => void;
  deleteNote: (noteId: string) => void;
  sendShareEmail?: (emailAddress: string[], message: string) => void;
  principal?: any;
  isPublicPage: boolean;
}

const mapStateToProps = (state: RootState, ownProps: ShipmentNotesContainerProps) => {
  const authorizations = getPrincipalAuthorizationsFromState(state);
  return {
    notes: state.notesReducer.notes,
    isCustomerAdmin: authorizations.hasCustomerAdminAuthorization(),
    isFetchingNotes: state.notesReducer.isFetchingNotes,
    isCreatingNote: state.notesReducer.isCreatingNote,
    isUpdatingNote: state.notesReducer.isUpdatingNote,
    isDeletingNote: state.notesReducer.isDeletingNote,
    showNotesWindow: state.notesReducer.showNotesWindow,
    shipmentId: ownProps.shipmentId,
    mode: ownProps.mode,
    masterShipmentId: ownProps.masterShipmentId,
    shipmentTenantId: ownProps.shipmentTenantId,
    shipmentShareToken: ownProps.shipmentShareToken,
    principal: state.authReducer.principal,
    entitlementInfo: ownProps.entitlementInfo,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ShipmentNotesContainerProps) => ({
  fetchNotes: () => {
    // TODO: Remove once mock intermodal shipments are removed
    if (!isDemoShipment(ownProps.masterShipmentId)) {
      dispatch(
        notesActions.fetchNotes.request({
          shipmentId: ownProps.shipmentId,
          mode: ownProps.mode,
          masterShipmentId: ownProps.masterShipmentId,
        })
      );
    }
  },
  fetchPublicNotes: (shareToken: string) => {
    dispatch(
      notesActions.fetchPublicNotes.request({ shareToken, useIntermodalEndpoint: ownProps.useIntermodalEndpoint })
    );
  },
  createNote: (note: NoteRequest) => {
    dispatch(notesActions.createNote.request(note));
  },
  updateNote: (note: NoteRequest) => {
    dispatch(notesActions.updateNote.request(note));
  },
  deleteNote: (noteId: string) => {
    dispatch(notesActions.deleteNote.request(noteId));
  },
  reset: () => {
    dispatch(notesActions.reset());
  },
  toggleNotesWindow: () => {
    dispatch(notesActions.toggleNotesWindow());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesComponent);
