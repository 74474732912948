import get from 'lodash/get';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import qs from 'qs';
import { Dispatch } from 'redux';
import history from 'configureHistory';
import {
  getPrincipalAuthoritiesFromState,
  getPrincipalAuthorizations,
  getPrincipalAuthorizationsFromState,
  getPrincipalFromState,
} from '../../../common/authorizations';
import endpoints from '../../../common/endpoints';
import { RootState } from '../../../reducers';
import { clearFilters, clearFiltersNoSearch } from '../../filter/ducks/actions';
import { logout } from '../../login/LoginForm/ducks/actions';
import { buildSetSearchTerm, toogleModalMultiValueSearch } from '../../shipmentListComponent/ducks/searchReducer';
import Header from './Header';

export interface HeaderProps extends RouteComponentProps<any>, WrappedComponentProps {
  intl: any;
  searchTerm: string;
  isMuliValueSearchModalOpen: boolean;
  previousSearchQuery: any;
  sortFields: string[];
  isLoading: boolean;
  filter: any;
  modeFilters: string[];
  isLoggedIn: boolean;
  principal: any;
  authorizations: any;
  authorities: any;
  error?: any;
  routerPath: string;
  shipmentMode?: string;
  shipmentId?: string;
}

export interface HeaderDispatchProps {
  logout: () => void;
  setSearchTerm: (searchTerm: string) => void;
  setIsMuliValueSearchModalOpen: (isMuliValueSearchModalOpen: boolean) => void;
  clearFiltersNoSearch: () => void;
  executeSearchFn: (authorities: any, searchTerm: string, isMultiValue?: boolean) => void;
  executeOrderSearchFn: (isMultiValue: boolean, searchText: string) => void;
  executeInventorySearchFn: (searchText: string) => void;
}

const mapStateToProps = (state: RootState, ownProps: HeaderProps): HeaderProps => ({
  ...ownProps,
  searchTerm: get(state, 'searchReducer.searchTerm'),
  isMuliValueSearchModalOpen: get(state, 'searchReducer.isMuliValueSearchModalOpen', false),
  previousSearchQuery: get(state, 'searchReducer.previousSearchQuery'),
  sortFields: get(state, 'sortResultsReducer.sortFields'),
  isLoading: get(state, 'searchReducer.isLoading'),
  filter: get(state, 'filterReducer.filter'),
  modeFilters: get(state, 'filterReducer.modeFilters'),
  isLoggedIn: get(state, 'authReducer.isLoggedIn'),
  principal: getPrincipalFromState(state),
  authorizations: getPrincipalAuthorizationsFromState(state),
  authorities: getPrincipalAuthoritiesFromState(state),
  error: get(state, 'loginReducer.error'),
  routerPath: get(state, 'router.location.pathname'),
  shipmentMode: get(state, 'ltlShipmentDetailsReducer.shipmentDetails.mode', 'tl'),
  shipmentId: get(
    state,
    'ltlShipmentDetailsReducer.shipmentDetails.shipmentId',
    get(state, 'tlShipmentDetailsReducer.shipmentDetails.shipmentId')
  ),
});

const mapDispatchToProps = (dispatch: Dispatch): HeaderDispatchProps => ({
  logout: () => dispatch(logout()),
  setSearchTerm: (searchTerm: string) => {
    dispatch(buildSetSearchTerm(searchTerm));
  },
  setIsMuliValueSearchModalOpen: (isMuliValueSearchModalOpen: boolean) => {
    dispatch(toogleModalMultiValueSearch(isMuliValueSearchModalOpen));
  },
  clearFiltersNoSearch: () => dispatch(clearFiltersNoSearch()),
  executeSearchFn: (authorities: any, searchTerm: string, isMultiValue?: boolean) => {
    // To set modeFilter as authorized mode when only one mode is available
    const authorizedModes = getPrincipalAuthorizations(authorities).getAuthorizedModes();
    let modeFilter = null;
    if (authorizedModes.length === 1) {
      modeFilter = authorizedModes;
    }
    dispatch(clearFilters(authorities, modeFilter, isMultiValue, searchTerm));
    dispatch(push(endpoints.SHIPMENT_LIST));
  },
  executeOrderSearchFn: (isMultiValue: boolean, searchText: string) => {
    // In order to have better back button behavior, this code immediately pushes the intended updated URL to history.
    // then OrdersList will only push to history if the URL it wants to push differs from the current one. this should
    // suffice to avoid inserting extra URLs in history that do not have any filters applied, which cause the back button
    // to not work intuitively
    const currentLocation = history.location;
    const search = get(currentLocation, 'search', '');
    const parsedSearch = qs.parse(search.replace('?', ''));
    const newSearch = {
      ...parsedSearch,
      searchText,
      isMultiValue,
    };
    dispatch(
      push({
        pathname: endpoints.ORDERSLIST,
        search: qs.stringify(newSearch),
      })
    );
  },
  executeInventorySearchFn: (searchText: string) => {
    // see executeOrderSearchFn() above for rationale
    const currentLocation = history.location;
    const search = get(currentLocation, 'search', '');
    const parsedSearch = qs.parse(search.replace('?', ''));
    const newSearch = {
      ...parsedSearch,
      searchText,
    };
    dispatch(
      push({
        pathname: endpoints.INVENTORYITEMSLIST,
        search: qs.stringify(newSearch),
      })
    );
  },
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Header)));
