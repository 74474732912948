import produce from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';

import { createContext, useReducer, useContext } from 'react';
import { PRESET_DATE_VALUE } from '../constants';
import { AntdMoment, DateFormat, EventType, Nullable, PickedDate, PresetDateName } from '../types';
import { antdMoment, updateTime } from '../utils';

const findPresetName = (rangeDate: [PickedDate, PickedDate]) => {
  const [startDate, endDate] = rangeDate;
  const result = Object.entries(PRESET_DATE_VALUE).find(([_key, value]) => {
    const [presetStartDate, presetEndDate] = value;
    return presetStartDate.isSame(startDate, 'day') && presetEndDate.isSame(endDate, 'day');
  });

  return result?.[0];
};

type ActionType =
  | { type: 'SET_EVENT_TYPE'; payload: EventType }
  | { type: 'SET_RANGE_DATE'; payload: [PickedDate, PickedDate] }
  | { type: 'SET_START_DATE'; payload: PickedDate }
  | { type: 'SET_END_DATE'; payload: PickedDate }
  | { type: 'SET_CALENDAR'; payload: DateFormat }
  | { type: 'SET_PRESET_DATE'; payload: PresetDateName };

interface DatePickerContextValue {
  state: StateType;
  dispatch: (action: ActionType) => void;
}

interface DatePickerProviderProps {
  children: React.ReactNode;
  initialValues: any;
}

const DatePickerContext = createContext<Nullable<DatePickerContextValue>>(null);

interface EventMetadata {
  start: Nullable<AntdMoment>;
  end: Nullable<AntdMoment>;
  presetDate: PresetDateName;
}

interface StateType {
  eventType: EventType;
  calendar: DateFormat;
  pickupDate: EventMetadata;
  deliveryDate: EventMetadata;
  oceanLastFreeDate: EventMetadata;
}

const initialEventMetadata: EventMetadata = {
  start: null,
  end: null,
  presetDate: 'custom',
};

const initialState: StateType = {
  eventType: 'pickupDate',
  calendar: 'specificDay',
  pickupDate: initialEventMetadata,
  deliveryDate: initialEventMetadata,
  oceanLastFreeDate: initialEventMetadata,
};

const reducer = produce<StateType, [ActionType]>((draft, action) => {
  const { eventType } = draft;
  switch (action.type) {
    case 'SET_RANGE_DATE': {
      const [startDate, endDate] = action.payload;

      if (startDate) {
        const nextStartDate = updateTime(
          startDate,
          get(draft, [draft.eventType, 'start']) || antdMoment().startOf('day')
        );
        set(draft, [draft.eventType, 'start'], nextStartDate);
      }

      if (endDate) {
        const nextEndDate = updateTime(endDate, get(draft, [draft.eventType, 'end']) || antdMoment().endOf('day'));
        set(draft, [draft.eventType, 'end'], nextEndDate);
      }

      const presetName = findPresetName([draft[eventType].start, draft[eventType].end]);
      if (presetName) {
        draft[eventType].presetDate = presetName as PresetDateName;
      }

      break;
    }
    case 'SET_START_DATE':
      set(draft, [draft.eventType, 'start'], action.payload);
      break;
    case 'SET_END_DATE':
      set(draft, [draft.eventType, 'end'], action.payload);
      break;
    case 'SET_EVENT_TYPE': {
      draft.eventType = action.payload;
      break;
    }
    case 'SET_CALENDAR':
      draft.calendar = action.payload;
      if (action.payload === 'specificDay') {
        set(draft, [draft.eventType, 'end'], get(draft, [draft.eventType, 'start']));
      }
      break;
    case 'SET_PRESET_DATE': {
      const nextPresetDate = action.payload;
      draft[eventType].presetDate = nextPresetDate;
      if (nextPresetDate !== 'custom') {
        draft[eventType].start = PRESET_DATE_VALUE[nextPresetDate][0];
        draft[eventType].end = PRESET_DATE_VALUE[nextPresetDate][1];
      }
      break;
    }
    default:
      return draft;
  }
}, initialState);

const DatePickerProvider = ({ children, initialValues }: DatePickerProviderProps) => {
  const mergedInitialState = produce<StateType>(initialState, (draft) => {
    const eventTypes: Array<EventType> = ['pickupDate', 'deliveryDate', 'oceanLastFreeDate'];

    eventTypes.forEach((type) => {
      const startDate = initialValues[`${type}Start`];
      const endDate = initialValues[`${type}End`];

      draft[type].start = startDate && antdMoment(startDate);
      draft[type].end = endDate && antdMoment(endDate);

      const presetName = findPresetName([draft[type].start, draft[type].end]);

      if (presetName) {
        draft[type].presetDate = presetName as PresetDateName;
      }
    });
  });

  const [state, dispatch] = useReducer(reducer, mergedInitialState);
  const value = { state, dispatch };

  return <DatePickerContext.Provider value={value}>{children}</DatePickerContext.Provider>;
};

const useDatePicker = () => {
  const context = useContext(DatePickerContext);
  if (context === null) {
    throw new Error('useDatePicker must be used within a DatePickerProvider');
  }
  return context;
};

const withDatePickerProvider = <P extends { filter: any }>(WrappedComponent: React.ComponentType<P>) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const WithDatePickerProvider = (props: P) => {
    const initialValues = {
      pickupDateStart: props.filter.pickupDateStart,
      pickupDateEnd: props.filter.pickupDateEnd,
      deliveryDateStart: props.filter.deliveryDateStart,
      deliveryDateEnd: props.filter.deliveryDateEnd,
      oceanLastFreeDateStart: props.filter.oceanLastFreeDateStart,
      oceanLastFreeDateEnd: props.filter.oceanLastFreeDateEnd,
    };

    return (
      <DatePickerProvider initialValues={initialValues}>
        <WrappedComponent {...props} />
      </DatePickerProvider>
    );
  };

  WithDatePickerProvider.displayName = `withDatePickerProvider${displayName}`;

  return WithDatePickerProvider;
};

export { DatePickerProvider, useDatePicker, withDatePickerProvider };
