export const NA_COUNTRIES = [
  {
    name: 'USA',
    value: 'US',
  },
  {
    name: 'Canada',
    value: 'CA',
  },
  {
    name: 'Mexico',
    value: 'MX',
  },
];

export const EU_COUNTRIES = [
  {
    name: 'Albania',
    value: 'AL',
  },
  {
    name: 'Andorra',
    value: 'AD',
  },
  {
    name: 'Armenia',
    value: 'AM',
  },
  {
    name: 'Austria',
    value: 'AT',
  },
  {
    name: 'Belarus',
    value: 'BY',
  },
  {
    name: 'Belgium',
    value: 'BE',
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 'BA',
  },
  {
    name: 'Bulgaria',
    value: 'BG',
  },
  {
    name: 'Croatia',
    value: 'HR',
  },
  {
    name: 'Cyprus',
    value: 'CY',
  },
  {
    name: 'Czech Republic',
    value: 'CZ',
  },
  {
    name: 'Denmark',
    value: 'DK',
  },
  {
    name: 'Estonia',
    value: 'EE',
  },
  {
    name: 'France',
    value: 'FR',
  },
  {
    name: 'Finland',
    value: 'FI',
  },
  {
    name: 'Georgia',
    value: 'GE',
  },
  {
    name: 'Germany',
    value: 'DE',
  },
  {
    name: 'Gibraltar',
    value: 'GI',
  },
  {
    name: 'Great Britain',
    value: 'GB',
  },
  {
    name: 'Greece',
    value: 'EL',
  },
  {
    name: 'Greece',
    value: 'GR',
  },
  {
    name: 'Hungary',
    value: 'HU',
  },
  {
    name: 'Iceland',
    value: 'IS',
  },
  {
    name: 'Ireland',
    value: 'IE',
  },
  {
    name: 'Italy',
    value: 'IT',
  },
  {
    name: 'Latvia',
    value: 'LV',
  },
  {
    name: 'Liechtenstein',
    value: 'LI',
  },
  {
    name: 'Lithuania',
    value: 'LT',
  },
  {
    name: 'Luxembourg',
    value: 'LU',
  },
  {
    name: 'Malta',
    value: 'MT',
  },
  {
    name: 'Monaco',
    value: 'MC',
  },
  {
    name: 'Montenegro',
    value: 'ME',
  },
  {
    name: 'Netherlands',
    value: 'NL',
  },
  {
    name: 'North Macedonia',
    value: 'MK',
  },
  {
    name: 'Norway',
    value: 'NO',
  },
  {
    name: 'Poland',
    value: 'PL',
  },
  {
    name: 'Portugal',
    value: 'PT',
  },
  {
    name: 'Romania',
    value: 'RO',
  },
  {
    name: 'Russian Federation',
    value: 'RU',
  },
  {
    name: 'San Marino',
    value: 'SM',
  },
  {
    name: 'Serbia',
    value: 'RS',
  },
  {
    name: 'Spain',
    value: 'ES',
  },
  {
    name: 'Slovenia',
    value: 'SI',
  },
  {
    name: 'Slovakia',
    value: 'SK',
  },
  {
    name: 'Sweden',
    value: 'SE',
  },
  {
    name: 'Switzerland',
    value: 'CH',
  },
  {
    name: 'Turkey',
    value: 'TR',
  },
  {
    name: 'Ukraine',
    value: 'UA',
  },
  {
    name: 'United Kingdom',
    value: 'UK',
  },
  {
    name: 'Vatican City State (Holy See)',
    value: 'VA',
  },
];
