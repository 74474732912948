import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { renderInput } from '../../common/forms/FormInputs';
import AddEditShipmentButtons from '../AddEditShipmentButtons/AddEditShipmentButtons';
import { validateShipmentDetailCarrierInfo } from '../formValidators';
import CarrierType from '../../shipment/common/enums/carrierType';
import TrackingMethodForm from '../TrackingMethodForm/TrackingMethodForm';

export const messages = defineMessages({
  licensePlate: {
    id: 'addShipment.shipmentForm.licensePlate',
    defaultMessage: 'License Plate #',
  },
  enterLicensePlate: {
    id: 'addShipment.shipmentForm.enterLicensePlate',
    defaultMessage: 'Enter License Plate #',
  },
  bolNumber: {
    id: 'addShipment.shipmentForm.bolNumber',
    defaultMessage: 'BOL Number',
  },
  idShipmentByBol: {
    id: 'addShipment.shipmentForm.idShipmentByBol',
    defaultMessage: 'ID shipment by BOL #',
  },
  orderNumber: {
    id: 'addShipment.shipmentForm.orderNumber',
    defaultMessage: 'Order Number',
  },
  idShipmentByOrder: {
    id: 'addShipment.shipmentForm.idShipmentByOrder',
    defaultMessage: 'ID shipment by Order #',
  },
  driverMobileNumber: {
    id: 'addShipment.shipmentForm.driverMobileNumber',
    defaultMessage: 'Driver Mobile Number',
  },
  truckNumber: {
    id: 'addShipment.shipmentForm.truckNumber',
    defaultMessage: 'Truck Number',
  },
  enterLicensePlateNumber: {
    id: 'addShipment.shipmentForm.enterLicensePlateNumber',
    defaultMessage: 'Enter License Plate Number',
  },
  trackingMethod: {
    id: 'addShipment.shipmentForm.trackingMethod',
    defaultMessage: 'Tracking Method',
  },
  vehicleId: {
    id: 'addShipment.shipmentForm.vehicleId',
    defaultMessage: 'Vehicle ID',
  },
  vehicleIdValue: {
    id: 'addShipment.shipmentForm.vehicleIdValue',
    defaultMessage: 'Vehicle ID Value',
  },
});

let ShipmentForm = (props) => {
  const { intl } = props;
  const { handleSubmit } = props;
  const renderHeader = () => {
    const carrierIdentifiers = get(props.initialValues.carrierInfo, 'typeIdentifiers', []);

    if (!isEmpty(carrierIdentifiers) && carrierIdentifiers[0].type === CarrierType.EU) {
      return (
        <h4 className="form-header">
          <FormattedMessage id="addShipment.shipmentForm.trackingInformation" defaultMessage="Tracking Information" />
          <span className="form-header-help">
            <FormattedMessage id="addShipment.shipmentForm.optional" defaultMessage="(Optional)" />
          </span>
        </h4>
      );
    } else {
      return (
        <h4 className="form-header">
          <FormattedMessage id="addShipment.shipmentForm.trackingMethod" defaultMessage="Tracking Method" />
          <span className="form-header-help">
            <FormattedMessage id="addShipment.shipmentForm.optional" defaultMessage="(Optional)" />
          </span>
        </h4>
      );
    }
  };

  const renderShipmentHeader = () => {
    const carrierIdentifiers = get(props.initialValues.carrierInfo, 'typeIdentifiers', []);

    if (!isEmpty(carrierIdentifiers) && carrierIdentifiers[0].type === CarrierType.EU) {
      return (
        <h4 className="form-header">
          <FormattedMessage id="addShipment.shipmentForm.shipmentInformation" defaultMessage="Shipment Information" />{' '}
          <span className="form-header-help">
            <FormattedMessage id="addShipment.shipmentForm.requiredText" defaultMessage="(at least one is required)" />
          </span>
        </h4>
      );
    } else {
      return (
        <h4 className="form-header">
          <FormattedMessage id="addShipment.shipmentForm.shipmentId" defaultMessage="Shipment Id" />{' '}
          <span className="form-header-help">
            <FormattedMessage id="addShipment.shipmentForm.requiredText" defaultMessage="(at least one is required)" />
          </span>
        </h4>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="lane-detail-form">
      <div className="tracking-method">
        {renderHeader()}
        <TrackingMethodForm values={props.formValues} />
        {renderShipmentHeader()}
        <Field
          type="text"
          label={intl.formatMessage(messages.bolNumber)}
          component={renderInput}
          name="shipmentBOL"
          placeholder={intl.formatMessage(messages.idShipmentByBol)}
        />
        <Field
          type="text"
          label={intl.formatMessage(messages.orderNumber)}
          component={renderInput}
          name="shipmentOrder"
          placeholder={intl.formatMessage(messages.idShipmentByOrder)}
        />
      </div>

      {props.submitFailed && (
        <p className="form-error">
          <FormattedMessage
            id="addShipment.shipmentForm.formErrorShipmentFields"
            defaultMessage="It is required that one of the Shipment Info fields is entered before proceeding."
          />
        </p>
      )}

      <AddEditShipmentButtons
        step={props.step}
        navigateStep={props.navigateStep}
        onSubmit={props.navigateStep}
        cancel={props.cancel}
      />
    </form>
  );
};

ShipmentForm = reduxForm({
  form: 'AddEditShipment',
  destroyOnUnmount: false,
  validate: validateShipmentDetailCarrierInfo,
})(ShipmentForm);

export default injectIntl(ShipmentForm);
