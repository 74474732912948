import { defineMessages } from 'react-intl';
import { intl } from 'common/AppConstants';
import { LineItemDetailDef } from './LineItems.model';

const message = defineMessages({
  description: {
    id: 'shipmentDetails.lineItems.description',
    defaultMessage: 'DESCRIPTION',
  },
  quantity: {
    id: 'shipmentDetails.lineItems.quantity',
    defaultMessage: 'QUANTITY',
  },
  commodity: {
    id: 'shipmentDetails.lineItems.commodityCode',
    defaultMessage: 'COMMODITY CODE',
  },
  value: {
    id: 'shipmentDetails.lineItems.value',
    defaultMessage: 'VALUE',
  },
  pickUp: {
    id: 'shipmentDetails.lineItems.pickUp',
    defaultMessage: 'PICKUP',
  },
  delivery: {
    id: 'shipmentDetails.lineItems.delivery',
    defaultMessage: 'DELIVERY',
  },
  companyName: {
    id: 'shipmentDetails.lineItems.companyName',
    defaultMessage: 'COMPANY NAME',
  },
  contactName: {
    id: 'shipmentDetails.lineItems.contactName',
    defaultMessage: 'CONTACT NAME',
  },
  email: {
    id: 'shipmentDetails.lineItems.email',
    defaultMessage: 'EMAIL',
  },
  phone: {
    id: 'shipmentDetails.lineItems.phone',
    defaultMessage: 'PHONE',
  },
  fax: {
    id: 'shipmentDetails.lineItems.fax',
    defaultMessage: 'FAX',
  },
});

export const lineItemDetailDefs: LineItemDetailDef[] = [
  { label: intl.formatMessage(message.description), key: 'description' },
  { label: intl.formatMessage(message.quantity), key: 'itemQuantityAndPackaging' },
  { label: intl.formatMessage(message.value), key: 'value' },
  { label: intl.formatMessage(message.commodity), key: 'commodityCode' },
  { label: intl.formatMessage(message.pickUp), key: 'pickupStopNumber' },
  { label: intl.formatMessage(message.delivery), key: 'deliveryStopNumber' },
  { label: intl.formatMessage(message.companyName), key: 'companyName' },
  { label: intl.formatMessage(message.contactName), key: 'contactName' },
  { label: intl.formatMessage(message.email), key: 'email' },
  { label: intl.formatMessage(message.phone), key: 'phone' },
  { label: intl.formatMessage(message.fax), key: 'fax' },
];
