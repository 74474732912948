import { useEffect, useState, useRef, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SubHeader, Radio as ShipmentListModeFilter } from 'ui-components';
import { MODE_FILTERS } from 'common/modeFilters';
import './ShipmentListComponent.scss';
import { shipmentListFiltersUrlEnabled, getPrincipalAuthorizations } from 'common/authorizations';
import { PrincipalContext } from 'contexts/PrincipalContext';
import sortTypes from 'components/common/sortResults/ducks/types';
import { trackEvent } from 'common/eventTracker';
import ShipmentListHeaderComponent from './shipmentListHeader/ShipmentListHeaderComponent';
import AppliedFilterList from './appliedFilterList/AppliedFilterList';
import FilterContainer from '../filter/FilterContainer';
import ShipmentSearchResultCard from './shipmentSearchResultCard/ShipmentSearchResultCard';
import CustomButton from '../../themes/CustomButton';
import { SNACKBAR_AUTOHIDE_DURATION } from './../../common/AppConstants';
import { mapShipment } from './mappers/mapShipment';
import { withTheme } from '../../contexts/ThemeContext';
import NoResultsToDisplay from './NoResultsToDisplay';
import FilterUtil from '../../components/filter/util/filterUtil';

import VerticalFilterComponent from './verticalFilterComponent';

export const ShipmentListComponent = (props) => {
  const [showFilters, setShowFilters] = useState(false);
  const { location, setSearchTerm, toggleLoadFilter, executeSearchFn, buildSetSortFieldAction, history } = props;
  const { search, pathname } = location;
  const isShipmentListFiltersUrlEnabled = shipmentListFiltersUrlEnabled();
  const principalContext = useContext(PrincipalContext);

  const authorizations = getPrincipalAuthorizations(principalContext);
  const filtersHorizontalEnabled = authorizations.shipmentListFiltersHorizontalEnabled();
  const shouldRenderEarlyShipmentsAsOnTime = authorizations.renderEarlyShipmentsAsOnTimeTL(); // TODO IMP-475: implement for all modes (likely using a map)

  const buildAttributeState = (attributeKeyValuePairs) => {
    return attributeKeyValuePairs.map((item) => {
      return item.values.map((val) => {
        return `${item.name}:${val}`;
      });
    });
  };

  const sortOptions = sortTypes.SHIPMENT_SORT_FIELDS_INTL({ formatMessage: () => '' });

  const getSortIndex = (sortOptionSelected) => {
    if (!sortOptionSelected) return 0;
    return findIndex(sortOptions, (item) => isEqual(item.request, sortOptionSelected));
  };

  const searchFromHistoricalData = () => {
    const querySearch = FilterUtil.getSearchQueryFromURL(search);
    if (querySearch?.recent === undefined) {
      trackEvent('SL_SEARCH_HISTORICAL');
      const newUrl = search ? `${search}&recent=false` : '?recent=false';
      history.push({ pathname, search: newUrl });
    }
    return;
  };

  const updateFiltersBasedOnMode = () => {
    const querySearch = FilterUtil.getSearchQueryFromURL(search);
    const modeSpecificFilters = FilterUtil.translateStatusFiltersByMode(querySearch.modes[0]);
    const nextFilter = { ...querySearch.filter, status: modeSpecificFilters };
    const modes = querySearch.modes.length ? querySearch.modes : ['ALL'];
    const queryURL = FilterUtil.buildQueryURl(
      nextFilter,
      querySearch.sortBy,
      modes,
      querySearch.search,
      querySearch.enableMultiValueSearch
    );
    history.replace({ pathname, search: `${queryURL}` });
  };

  const isRecentDataSearched = () => {
    const querySearch = FilterUtil.getSearchQueryFromURL(search);
    return querySearch?.recent === undefined ? true : false;
  };

  const setFiltersState = useRef((querySearch) => {
    const attributeKeyValuePairs = get(querySearch, 'filter.attributeKeyValuePairs', []);
    const attribute = buildAttributeState(attributeKeyValuePairs).flat();
    const sortIndex = getSortIndex(querySearch.sortBy);
    const mode = querySearch.modes.length > 1 ? [MODE_FILTERS.ALL] : querySearch.modes;
    const currentMode = mode.length ? mode : ['ALL'];

    buildSetSortFieldAction(sortTypes.SET_SORT_FIELDS, sortOptions, sortIndex, sortTypes.SHIPMENT_SORT);

    setSearchTerm(querySearch.search);

    toggleLoadFilter({
      filter: {
        ...querySearch.filter,
        attribute,
      },
      modeFilters: currentMode,
    });
  });

  const authorities = useRef(props.authorities);

  useEffect(() => {
    const sessionStorageIsShipmentStorageApplied = window.sessionStorage.getItem('isShipmentInProgress');
    if (isShipmentListFiltersUrlEnabled) {
      if (sessionStorageIsShipmentStorageApplied === 'true') {
        updateFiltersBasedOnMode();
      }
      const querySearch = FilterUtil.getSearchQueryFromURL(search);
      setFiltersState.current(querySearch);
      const modes = querySearch.modes.length ? querySearch.modes : ['ALL'];
      executeSearchFn(
        querySearch.search,
        querySearch.filter,
        modes,
        querySearch.sortBy,
        authorities.current,
        querySearch.enableMultiValueSearch,
        querySearch.recent
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeSearchFn, isShipmentListFiltersUrlEnabled, search]);

  const hasSearchMatchingDisplay = () => {
    return get(props.customerProperties, 'VOC_ENABLE_ADDITIONAL_SEARCH_MATCHING_DISPLAY', false);
  };

  const toggleModeFilter = (option) => {
    const isMultiValue = props.previousSearchQuery.enableMultiValueSearch;
    const mode = filtersHorizontalEnabled ? [option] : [option.target.value];

    if (isShipmentListFiltersUrlEnabled) {
      const emptyFilters = FilterUtil.getEmptyFilter();
      const queryURL = FilterUtil.buildQueryURl(emptyFilters, props.sortFields, mode, props.searchTerm, isMultiValue);
      if (queryURL) props.history.push(`/shipment-list${queryURL}`);
    } else {
      props.toggleModeFilterFn(
        props.authorities,
        option,
        props.searchTerm,
        props.filter,
        props.sortFields,
        props.page,
        isMultiValue
      );
    }
  };

  const toggleFilters = () => setShowFilters(!showFilters);

  const loadMoreResults = () => {
    props.loadMoreShipments(props.previousSearchQuery, props.authorities);
  };

  const shouldDisplayMoreResultsButton = () => {
    if (!props.shipments || !props.totalSearchResults || props.isLoading) {
      return '';
    }

    if (props.shipments && props.totalSearchResults) {
      const remainingResults = props.totalSearchResults - props.shipments.length;
      if (!remainingResults) {
        return '';
      }

      return (
        <CustomButton classes="medium full-width" clickFn={loadMoreResults}>
          <FormattedMessage id="shipmentList.loadMoreButton.loadMore" defaultMessage="Load More" />
        </CustomButton>
      );
    }

    return '';
  };

  const filtersStyle = {
    display: showFilters ? 'block' : 'none',
    width: '100%',
    left: 0,
    top: -4,
    right: 0,
    margin: 0,
    padding: 0,
    bottom: 0,
    position: 'absolute',
    zIndex: 9999,
  };

  const searchQueryFromURL = FilterUtil.getSearchQueryFromURL(search);
  const modeFilter =
    authorizations.getAuthorizedModes().length > 1 ? MODE_FILTERS.ALL : get(authorizations.getAuthorizedModes(), '[0]');
  const currentMode =
    searchQueryFromURL.modes && searchQueryFromURL.modes[0] ? searchQueryFromURL.modes[0] : modeFilter;
  const modesOptions =
    props.modeFilterOptions?.length > 2
      ? props.modeFilterOptions
      : props.modeFilterOptions?.filter((item) => item.value !== MODE_FILTERS.ALL);

  return (
    <div className="ShipmentListComponent animated fadeIn">
      <SubHeader>
        <ShipmentListHeaderComponent showAddShipments={!props.authorizations.hasTenantCarrierRole(props.principal)} />
      </SubHeader>
      <Container as={'section'} className="section-content" key="content">
        <Row>
          {/* this would be better handled witin the filter container */}
          {showFilters && !filtersHorizontalEnabled && (
            <Col xs={24} lg={6} className="filters d-lg-none" style={filtersStyle}>
              <FilterContainer checkboxTheme="dark" cancel={toggleFilters} isMobile />
            </Col>
          )}
          {!filtersHorizontalEnabled && (
            <Col xs={24} lg={6} className="filters d-sm-none d-lg-block">
              <FilterContainer checkboxTheme="dark" />
            </Col>
          )}
          {filtersHorizontalEnabled && (
            <Col xs={24} lg={6} className="mb-5 mb-lg-0">
              <VerticalFilterComponent
                modeActive={currentMode}
                modesOptions={modesOptions}
                onChangeModes={toggleModeFilter}
              />
            </Col>
          )}
          <Col xs={24} lg={18} className="results">
            <AppliedFilterList
              isLoading={props.isLoading}
              filter={isShipmentListFiltersUrlEnabled ? searchQueryFromURL.filter : props.displayedFilterOptions}
              toggleFilters={toggleFilters}
              searchText={props.searchTerm}
              displayedResultsCount={(props.shipments || []).length}
              totalResultsCount={props.totalSearchResults}
              previousSearchQuery={props.previousSearchQuery}
              modeFilters={props.modeFilters}
              hasCarrierRole={props.authorizations.hasTenantCarrierRole(props.principal)}
              allowedModes={props.modeFilterOptions}
              isMuliValueSearchModalOpen={props.isMuliValueSearchModalOpen}
              setIsMuliValueSearchModalOpen={props.setIsMuliValueSearchModalOpen}
              filtersHorizontalEnabled={filtersHorizontalEnabled}
              isRecentDataSearched={isRecentDataSearched}
            />
            {props.modeFilterOptions.length >= 3 && !props.isLoading && !filtersHorizontalEnabled && (
              <div data-locator="shipment-list-radio-mode-picker">
                <ShipmentListModeFilter
                  value={props.modeFilters && props.modeFilters[0] ? props.modeFilters[0] : MODE_FILTERS.ALL}
                  className="mb-4"
                  onChange={toggleModeFilter}
                  radioData={props.modeFilterOptions}
                  styled
                  block
                  modeIcons
                  theme={props.theme}
                />
              </div>
            )}
            {!props.isLoading && props.shipments.length === 0 && (
              <NoResultsToDisplay
                totalResultsCount={props.totalSearchResults}
                searchQuery={props.previousSearchQuery ? props.previousSearchQuery.search : null}
                searchFromHistoricalData={searchFromHistoricalData}
                isRecentDataSearched={isRecentDataSearched}
                props={props}
              />
            )}
            {!props.isLoading &&
              props.shipments.length > 0 &&
              props.shipments.map((shipment) => (
                <ShipmentSearchResultCard
                  key={shipment.shipmentId}
                  resultItem={mapShipment(
                    shipment,
                    props.intl,
                    principalContext?.featureFlags?.useNewEntityPage,
                    shouldRenderEarlyShipmentsAsOnTime
                  )}
                  history={props.history}
                  previousSearchTerm={props.previousSearchQuery.search}
                  hasSearchMatchingDisplay={hasSearchMatchingDisplay()}
                  hasTenantCarrierRole={props.authorizations.hasTenantCarrierRole(props.principal)}
                  equipmentIdentifierTypes={props.equipmentIdentifierTypes}
                  totalResultsCount={props.totalSearchResults}
                  isRecentDataSearched={isRecentDataSearched}
                />
              ))}
            {shouldDisplayMoreResultsButton()}
          </Col>
        </Row>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={props.deleteSnackbarOpen}
          autoHideDuration={SNACKBAR_AUTOHIDE_DURATION}
          onClose={props.closeSnackbar}
          className="snackbar-success snackbar-container"
          action={[
            <IconButton key="close" aria-label="Close" onClick={props.closeSnackbar}>
              <i className="material-icons">close</i>
            </IconButton>,
          ]}
          message={
            <span className="snack-message">
              <i className="material-icons">thumb_up</i>
              <FormattedMessage
                id="shipmentList.deletedShipment.yourShipmentHasBeenSuccessfullyDeleted"
                defaultMessage="Your shipment has been successfully deleted."
              />
            </span>
          }
        />
      </Container>
    </div>
  );
};

ShipmentListComponent.propTypes = {
  searchTerm: PropTypes.string,
  filters: PropTypes.array,

  shipments: PropTypes.array.isRequired,
  totalSearchResults: PropTypes.number.isRequired,

  previousSearchQuery: PropTypes.shape({
    search: PropTypes.string,
    filters: PropTypes.array,
    sortBy: PropTypes.array,
    resultStartIndex: PropTypes.number,
    resultsPerPage: PropTypes.number,
  }),

  customerProperties: PropTypes.object,

  setSearchTerm: PropTypes.func.isRequired,
  loadMoreShipments: PropTypes.func.isRequired,
  executeSearchFn: PropTypes.func.isRequired,
};

export default withTheme(injectIntl(ShipmentListComponent));
