import types from './types';
import { SEARCH_RESULTS_PER_PAGE } from '../../../../common/AppConstants';
export const buildResetSearchQueryAction = () => ({ type: types.RESET_SEARCH_QUERY_TYPE });

export const buildSetSearchTerm = (searchTerm: string) => ({
  type: types.SET_SEARCH_TERM_TYPE,
  searchTerm,
});

export const buildSetContactSearchTerm = (searchTerm: string) => ({
  type: types.SET_CONTACT_SEARCH_TERM_TYPE,
  searchTerm,
});

export const buildSearchQueryAction = (
  searchConfig = {},
  searchText = '',
  contactSearchText = '',
  sortBy: Array<{ fieldName: string; direction: string }> = [],
  filters = [],
  resultStartIndex = 0
) => ({
  searchConfig,
  type: types.SEARCH_TYPE,
  searchQuery: {
    search: searchText,
    contactSearch: contactSearchText,
    filters,
    sortBy,
    resultStartIndex,
    resultsPerPage: SEARCH_RESULTS_PER_PAGE,
  },
});

export const buildLocationSearchQueryAction = (
  searchConfig = types.SEARCH_LOCATION_CONFIG,
  searchText: string = '',
  contactSearchText: string = '',
  sortingCriteria: Array<{ fieldName: string; direction: string }> = [],
  startIndex: number = 0,
  searchFilters: Array<{ name: string; values: Array<string>; context: string }> = []
) => {
  if (contactSearchText)
    searchFilters.push({ name: 'location.contacts.contactName', values: [contactSearchText], context: 'FUZZY' });
  return {
    searchConfig,
    searchQuery: {
      resultStartIndex: startIndex,
      resultsPerPage: SEARCH_RESULTS_PER_PAGE,
      searchPhrase: searchText,
      filters: searchFilters,
    },
    type: types.SEARCH_TYPE,
  };
};

export const buildSetResultsAction = (results = [], totalResults = 0, searchTerm: string) => ({
  type: types.SET_TYPE,
  results,
  totalResults,
  searchTerm,
});

export const buildSearchResultsAction = (results = []) => ({
  type: types.ADD_TYPE,
  results,
});

export const buildSearchFailureAction = (error: Error) => ({
  type: types.SEARCH_FAILURE_TYPE,
  error,
});

export const buildGetNextPageAction = (previousSearchQuery = types.DEFAULT_SEARCH_QUERY) => ({
  type: types.GET_NEXT_PAGE,
  searchQuery: {
    ...previousSearchQuery,
    resultStartIndex: previousSearchQuery.resultStartIndex + previousSearchQuery.resultsPerPage,
  },
});
