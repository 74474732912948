import * as React from 'react';
import { MultiSelect, Chips } from 'ui-components';
import { intl } from 'common/AppConstants';
import { Theme } from '../../../settings/BrandTheming/models/Theme';

interface DropdownChipMenuItem {
  defaultMessage: string;
  displayName: string;
  id: string;
}

interface DropdownChipsProps {
  currentStatus: DropdownChipMenuItem[];
  dropdownChangeFn: (value: any) => void;
  menuItems: DropdownChipMenuItem[];
  listData: DropdownChipMenuItem[];
  passedInDeleteFn: (value: any) => void;
  placeholder?: string;
  theme: Theme;
  inputId?: string;
}

const DropdownChips: React.FC<DropdownChipsProps> = ({
  currentStatus,
  dropdownChangeFn,
  menuItems,
  listData,
  passedInDeleteFn,
  placeholder = '',
  theme,
  inputId = '',
}) => {
  const translatedList = listData.map((value) => intl.formatMessage(value));
  const statusIds = currentStatus.map(({ id }) => id);

  return (
    <div className="dropdown-list-with-chips">
      <MultiSelect
        value={statusIds}
        onChange={dropdownChangeFn}
        dataSource={menuItems}
        className="w-full"
        placeholder={placeholder}
        theme={theme}
        custom={{
          id: inputId,
        }}
      />
      <Chips listData={translatedList} showCount={false} layout={'row'} deleteFn={passedInDeleteFn} />
    </div>
  );
};

export default DropdownChips;
