export const pathPrefix = '/portal/v2';

export function getUrl(suffix) {
  let host = '';
  if (window && window.location && window.location.protocol && window.location.host) {
    host = window.location.protocol + '//' + window.location.host + pathPrefix;
    host += suffix || '';
  }

  return host;
}

export function getQueryStringParameter(queryString, name) {
  if (!queryString || !name) {
    return undefined;
  }

  // note : that in future we can use URLSearchParams: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
  // current polyfills have issues on mobile and in some IE versions: https://github.com/WebReflection/url-search-params
  // Another library is query-string but this yielded build issues with `yarn build` as it couldn't minify the module so simpler solution
  // is to just use regex
  const queryParamExtractExpression = new RegExp(`${name}=([^&#]*)`, 'g');
  const matchResults = queryParamExtractExpression.exec(queryString);
  if (matchResults && matchResults.length >= 1) {
    return decodeURIComponent(matchResults[1].replace(/\+/g, ' '));
  }
  return undefined;
}

export function isTargetUrlLastInHistory(history, targetEndpoint) {
  let response = false;
  if (history && targetEndpoint) {
    response = history.location.pathname + history.location.search === targetEndpoint;
  }
  return response;
}
