import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'ui-components';
import CheckList from '../../common/checkList/CheckList';
import filterMessages from '../modes/filterMessages.json';

const HappeningTodayFilter = (props) => {
  const happeningToday = {
    ...filterMessages.shipmentsInTransit,
    labelText: (
      <FormattedMessage
        id={filterMessages.shipmentsInTransit.id}
        defaultMessage={filterMessages.shipmentsInTransit.defaultMessage}
      >
        {(text) => (
          <Tooltip trigger="hover" title={props.toolTipContent}>
            {text}
          </Tooltip>
        )}
      </FormattedMessage>
    ),
    propertyName: 'happeningToday',
    checked: false,
    dataKey: 'Happening Today',
    code: 'happeningCode',
  };
  const isChecked = props.filter[happeningToday.code].includes(happeningToday.dataKey);

  // TODO: Refactor checkbox reducer methods to not use `labelText` as a data-key
  const onCheck = (checkboxItem) =>
    props.handleCheckboxGroupUpdate({ ...checkboxItem, labelText: happeningToday.dataKey }, happeningToday.code);

  return (
    <CheckList
      mode={props.checkboxTheme}
      checkListData={[{ ...happeningToday, checked: isChecked }]}
      checkFn={onCheck}
      data-locator="happening-today-filter"
    />
  );
};

export default HappeningTodayFilter;
