import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import get from 'lodash/get';
import { oceanShipmentListLocodeEnabled } from 'common/authorizations';
import { ShipmentModeIcon } from 'ui-components';
import { getStopLocationText } from 'components/newShipmentDetails/utils';
import {
  ShipmentLeg,
  ShipmentStop,
  StopIdentifier,
  ShipmentStopStatusEnum,
  ShipmentDerivedStatus,
  ShipmentStopTypeEnum,
} from '../../models';
import { AtStopDot, CurrentPositionDot, StopDot } from './StopDots';
import { ActiveStopLine, InactiveStopLine } from './StopLines';
import * as styles from './Stops.module.scss';

export interface StopProps extends WrappedComponentProps {
  legs: ShipmentLeg[];
  shipmentStatus: ShipmentDerivedStatus;
}

class Stops extends React.Component<StopProps, {}> {
  calculateActiveStopLineWidth = (stop: ShipmentStop, isFirstStop: boolean, isLastStop: boolean): number => {
    if (stop.status === ShipmentStopStatusEnum.EN_ROUTE) {
      return 0;
    } else if (isFirstStop && stop.status === ShipmentStopStatusEnum.ARRIVED) {
      return 0;
    } else if ((isFirstStop || isLastStop) && stop.status === ShipmentStopStatusEnum.DEPARTED) {
      return 50;
    } else if (stop.status === ShipmentStopStatusEnum.ARRIVED) {
      return 50;
    } else if (stop.status === ShipmentStopStatusEnum.DEPARTED) {
      return 100;
    }
    return 0;
  };

  shouldShowCurrentPosDot = (stop: ShipmentStop, nextStop?: ShipmentStop): boolean => {
    return (
      stop.status === ShipmentStopStatusEnum.DEPARTED &&
      nextStop !== undefined &&
      nextStop.status === ShipmentStopStatusEnum.EN_ROUTE
    );
  };

  getNextStop = (
    currentStopIndex: number,
    currentLeg: ShipmentLeg,
    nextLeg?: ShipmentLeg
  ): ShipmentStop | undefined => {
    if (currentLeg.stops[currentStopIndex + 1] !== undefined) {
      return currentLeg.stops[currentStopIndex + 1];
    } else if (nextLeg !== undefined) {
      return nextLeg.stops[0];
    }
  };

  isSingleStop = (stop: ShipmentStop): boolean => {
    return stop.type === ShipmentStopTypeEnum.UNKNOWN;
  };

  showStopName = (stop: ShipmentStop) => {
    return (
      <div className={styles.label} data-locator={`stop-label-${stop.stopName}`}>
        {stop.stopName}
      </div>
    );
  };

  showLocationIdentifiers = (stop: ShipmentStop): React.ReactElement[] | null => {
    const identifiers = get(stop, 'identifiers');
    if (identifiers) {
      return identifiers.map(({ type, value }: StopIdentifier) => (
        <div key={type} className={`medium-text bold ${styles.label}`} data-locator={`stop-${type}-label-identifiers`}>
          {value}
        </div>
      ));
    }
    return null;
  };

  showStopLocation = (stop: ShipmentStop) => {
    const locationText = getStopLocationText(stop);
    return locationText ? (
      <div className={styles.location} data-locator={`stop-location-${locationText}`}>
        {locationText}
      </div>
    ) : undefined;
  };

  render() {
    return (
      <div className={styles.stops} data-locator="status-stops">
        {this.props.legs.map((leg, legIndex) => {
          return leg.stops.map((stop, stopIndex) => {
            if (stopIndex === leg.stops.length - 1 && legIndex !== this.props.legs.length - 1) {
              // Last stop of a leg and first stop of next leg are the same, so skip last stop of a leg if there is a leg after it
              return null;
            }
            const isLastStop: boolean = legIndex === this.props.legs.length - 1 && stopIndex === leg.stops.length - 1;
            const isFirstStop: boolean = legIndex === 0 && stopIndex === 0;
            const nextLeg: ShipmentLeg | undefined = this.props.legs[legIndex + 1];
            const nextStop: ShipmentStop | undefined = this.getNextStop(stopIndex, leg, nextLeg);

            return (
              <div
                data-locator={`status-stop-${leg.id}-${stopIndex}`}
                key={`${stop.stopName}${stop.stopNumber}${leg.id}${stop.status}${stop.type}`}
                className={styles.stop}
              >
                <ActiveStopLine
                  widthPercentage={this.calculateActiveStopLineWidth(stop, isFirstStop, isLastStop)}
                  isFirstStop={isFirstStop}
                  shipmentStatus={this.props.shipmentStatus}
                  data-locator="active-stop-line"
                />
                {/* InactiveStopLine is grey line that goes all the way across and is covered by ActiveStopLine */}
                <InactiveStopLine
                  isFirstStop={isFirstStop}
                  isLastStop={isLastStop}
                  isSingleStop={this.isSingleStop(stop)}
                />
                <StopDot
                  shipmentStatus={this.props.shipmentStatus}
                  stopStatus={stop.status}
                  isFirstStop={isFirstStop}
                />
                {stop.status === ShipmentStopStatusEnum.ARRIVED && (
                  <AtStopDot shipmentStatus={this.props.shipmentStatus} data-locator="at-stop-dot" />
                )}
                {this.shouldShowCurrentPosDot(stop, nextStop) && (
                  <CurrentPositionDot shipmentStatus={this.props.shipmentStatus} data-locator="current-pos-dot" />
                )}
                {!isLastStop && (
                  <ShipmentModeIcon
                    mode={leg.mode}
                    className={styles.modeIcon}
                    data-locator={`status-leg-mode-icon-${leg.id}-${stopIndex}`}
                  />
                )}
                {oceanShipmentListLocodeEnabled() && this.showLocationIdentifiers(stop)}
                {this.showStopName(stop)}
                {this.showStopLocation(stop)}
              </div>
            );
          });
        })}
      </div>
    );
  }
}

export const StopsComponent = Stops;
export default injectIntl(Stops);
