import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ShipmentModeEnum } from 'models';
import { RootState } from '../../reducers';
import ShipmentShareComponent from './ShipmentShareComponent';
import { shipmentShareActions } from './ducks';
import { PrincipalAuthorizations } from '../../common/authorizations';

export interface ShipmentShareOwnProps {
  shipmentId?: string;
  shipmentMode?: ShipmentModeEnum;
  masterShipmentId?: string;
  authorizations: PrincipalAuthorizations;
}

const mapStateToProps = (state: RootState, ownProps: ShipmentShareOwnProps) => {
  return {
    shareLink: state.shipmentShareReducer.shareLink,
    isSendingEmail: state.shipmentShareReducer.isSendingEmail,
    showShareWindow: state.shipmentShareReducer.showShareWindow,
    shipmentId: ownProps.shipmentId,
    shipmentMode: ownProps.shipmentMode,
    masterShipmentId: ownProps.masterShipmentId,
    authorizations: ownProps.authorizations,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ShipmentShareOwnProps) => ({
  fetchShareLink: () => {
    dispatch(
      shipmentShareActions.fetchShareLink.request({
        shipmentId: ownProps.shipmentId,
        shipmentMode: ownProps.shipmentMode,
        masterShipmentId: ownProps.masterShipmentId,
        authorizations: ownProps.authorizations,
      })
    );
  },
  sendShareEmail: (emailAddresses: string[], message: string) => {
    dispatch(
      shipmentShareActions.sendShareEmail.request({
        emailAddresses,
        message,
        shipmentId: ownProps.shipmentId,
        shipmentMode: ownProps.shipmentMode,
        masterShipmentId: ownProps.masterShipmentId,
      })
    );
  },
  reset: () => {
    dispatch(shipmentShareActions.reset());
  },
  toggleShareWindow: () => {
    dispatch(shipmentShareActions.toggleShareWindow());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentShareComponent);
