import { Details } from 'components/LocationDetails/LocationShipments/models';
import { MonetaryValue } from './inventory';
import { OffsetDateTimeWindow } from './OffsetDateTimeWindow';
import InventoryOrderCriteriaMessages from '../../orders/filters/InventoryOrderCriteriaMessages.json';
import LocationMessages from '../../orders/filters/LocationMessages.json';
import ShipmentStatusMessages from '../../orders/filters/ShipmentStatusMessages.json';

export interface InventoryOrder {
  id?: string;
  orderType?: OrderType;
  orderIdentifier?: string;
  subject?: string;
  statusCode?: OrderStatusCode;
  orderSubmissionDateTime?: Date;
  supplierReadyDateTimeWindow?: OffsetDateTimeWindow;
  originalDeliveryDateTimeWindow?: OffsetDateTimeWindow;
  vendorLocation?: Location;
  originLocation?: Location;
  destinationLocation?: Location;
  billToLocation?: Location;
  freightTerms?: FreightTerms;
  totalCost?: MonetaryValue;
  totalRetailValue?: MonetaryValue;
  shipmentIds?: string[];
  shipments?: Details[];
  relatedOrderIds?: string[];
  orderTags?: TypeValue[];
  additionalOrderIdentifiers?: TypeValue[];
  derivedOrderHealth?: DerivedOrderHealth;
}

/**
 * Order Arrival Information
 */
export interface DerivedOrderHealth {
  arrivalStatus: ArrivalStatus;
  estimatedTimeOfArrival: Date;
}

export interface ArrivalStatus {
  code: ArrivalStatusCode;
  duration: Duration;
}

export interface Duration {
  amount: number;
  unit: TimeUnit;
}

export interface TypeValue {
  type: string;
  value: string;
  description?: string;
}

/**
 * A physical location.
 */
export interface Location {
  suppliedId: string;
  address?: Address;
  contact?: Contact;
  id: string;
}

export interface FreightTerms {
  transportationPaymentType?: TransportationPaymentType;
  freightOwnershipType?: FreightOwnershipType;
}

/**
 * A postal address.
 */
export interface Address {
  postalCode: string;
  addressLines?: string[];
  city: string;
  state: string;
  country?: string;
}

/**
 * Contact information.
 */
export interface Contact {
  companyName: string;
  contactName: string;
  phoneNumber: string;
  phoneNumberCountryCode?: string;
  phoneNumber2?: string;
  phoneNumber2CountryCode?: string;
  email: string;
  faxNumber?: string;
  faxNumberCountryCode?: string;
}

export enum TimeUnit {
  UNKNOWN = 'UNKNOWN',
  MILLISECONDS = 'MILLISECONDS',
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export enum ArrivalStatusCode {
  LATE = 'LATE',
  EARLY = 'EARLY',
  UNKNOWN = 'UNKNOWN',
  ON_TIME = 'ON_TIME',
}

export enum OrderType {
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  SALES_ORDER = 'SALES_ORDER',
  WAREHOUSE_MOVEMENT_ORDER = 'WAREHOUSE_MOVEMENT_ORDER',
  ADVANCED_SHIPMENT_NOTICE = 'ADVANCED_SHIPMENT_NOTICE',
}

export enum OrderStatusCode {
  SUBMITTED = 'SUBMITTED',
  RECEIVED = 'RECEIVED',
  PROCESSING = 'PROCESSING',
  ACCEPTED = 'ACCEPTED',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
}

export enum TransportationPaymentType {
  ORIGIN = 'ORIGIN',
  DESTINATION = 'DESTINATION',
}

export enum FreightOwnershipType {
  PREPAID = 'PREPAID',
  COLLECT = 'COLLECT',
}

export const intlOrderAdditionalIdentifierList = [
  InventoryOrderCriteriaMessages.contract,
  InventoryOrderCriteriaMessages.load,
  InventoryOrderCriteriaMessages.manufacturer,
  InventoryOrderCriteriaMessages.promotion,
  InventoryOrderCriteriaMessages.season,
  InventoryOrderCriteriaMessages.vendor,
];

export const intlOrderTagList = [
  InventoryOrderCriteriaMessages.analyst,
  InventoryOrderCriteriaMessages.carrier,
  InventoryOrderCriteriaMessages.category,
  InventoryOrderCriteriaMessages.commodity,
  InventoryOrderCriteriaMessages.department,
  InventoryOrderCriteriaMessages.director,
  InventoryOrderCriteriaMessages.family,
  InventoryOrderCriteriaMessages.group,
  InventoryOrderCriteriaMessages.manager,
  InventoryOrderCriteriaMessages.sector,
];

export const intlOrderTaDescriptiongList = [
  InventoryOrderCriteriaMessages.analystDescription,
  InventoryOrderCriteriaMessages.categoryDescription,
  InventoryOrderCriteriaMessages.departmentDescription,
  InventoryOrderCriteriaMessages.directorDescription,
  InventoryOrderCriteriaMessages.familyDescription,
  InventoryOrderCriteriaMessages.groupDescription,
  InventoryOrderCriteriaMessages.managerDescription,
  InventoryOrderCriteriaMessages.sectorDescription,
];

export const intlLocationTypeList = [
  LocationMessages.origin,
  LocationMessages.destination,
  LocationMessages.vendor,
  LocationMessages.bill,
];

export const intlShipmentStatusList = [
  ShipmentStatusMessages.onTime,
  ShipmentStatusMessages.early,
  ShipmentStatusMessages.late,
  ShipmentStatusMessages.unknown,
];

export const intlOrderKeys = [...intlOrderAdditionalIdentifierList, ...intlOrderTagList].reduce((acc, intlItem) => {
  return {
    ...acc,
    [intlItem.value]: intlItem.message,
  };
}, {});
