import * as React from 'react';
import { ChannelList, SendBirdSelectors } from 'sendbird-uikit';
import { listify } from './utils';
const MemoChannelList: React.FC<{
  usersWithAccessToThisChat: SendBird.User[];
  shipmentId: string;
  handleSetChannel: (url: string) => void;
  sdk: SendBirdSelectors.GetSdk;
  renderChannelListHeader: (props: void) => React.ReactNode;
}> = React.memo(function ChannelListComponent({
  usersWithAccessToThisChat,
  shipmentId,
  handleSetChannel,
  sdk,
  renderChannelListHeader,
}) {
  return sdk ? (
    <>
      <ChannelList
        renderHeader={renderChannelListHeader}
        queries={{
          applicationUserListQuery: {
            userIdsFilter: usersWithAccessToThisChat.map((user) => user.userId),
          },
          channelListQuery: {
            customTypesFilter: [shipmentId, `ALL_MEMBER_CHANNEL:${shipmentId}`],
            ...(sdk.currentUser && { userIdsIncludeFilter: [sdk.currentUser.userId] }),
          },
        }}
        onChannelSelect={(channel) => {
          if (channel && channel.url) {
            handleSetChannel(channel.url);
          }
        }}
        onBeforeCreateChannel={(selectedUsers: SendBird.User['userId'][]) => {
          if (sdk) {
            const params: any = new sdk.GroupChannelParams();
            const { currentUser } = sdk;
            params.name = listify([
              selectedUsers.map((userId) =>
                usersWithAccessToThisChat.filter((user) => user.userId === userId).map((user) => user.nickname)
              ),
              currentUser.nickname,
            ]);
            params.customType = shipmentId;
            params.data = JSON.stringify({
              shipmentId,
            });
            params.addUserIds(selectedUsers);
            params.isPublic = true;
            return params;
          }
        }}
      />
    </>
  ) : null;
});

export default MemoChannelList;
