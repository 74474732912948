import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  scheduled: {
    id: 'shipmentList.ltlStatusMap.scheduled',
    defaultMessage: 'Scheduled',
  },
  attemptingToTrack: {
    id: 'shipmentList.ltlStatusMap.attemptingToTrack',
    defaultMessage: 'Attempting to Track',
  },
  inTransit: {
    id: 'shipmentList.ltlStatusMap.inTransit',
    defaultMessage: 'In Transit',
  },
  pickedUp: {
    id: 'shipmentList.ltlStatusMap.pickedUp',
    defaultMessage: 'Picked Up',
  },
  outForDelivery: {
    id: 'shipmentList.ltlStatusMap.outForDelivery',
    defaultMessage: 'Out for Delivery',
  },
  pickupMissed: {
    id: 'shipmentList.ltlStatusMap.pickupMissed',
    defaultMessage: 'Pickup Missed',
  },
  interlineMissed: {
    id: 'shipmentList.ltlStatusMap.interlineMissed',
    defaultMessage: 'Interline Missed',
  },
  departureMissed: {
    id: 'shipmentList.ltlStatusMap.departureMissed',
    defaultMessage: 'Departure Missed',
  },
  deliveryMissed: {
    id: 'shipmentList.ltlStatusMap.deliveryMissed',
    defaultMessage: 'Delivery Missed',
  },
  held: {
    id: 'shipmentList.ltlStatusMap.held',
    defaultMessage: 'Held',
  },
  delayed: {
    id: 'shipmentList.ltlStatusMap.delayed',
    defaultMessage: 'Delayed',
  },
  atPickup: {
    id: 'shipmentList.ltlStatusMap.atPickup',
    defaultMessage: 'At Pickup',
  },
  atTerminal: {
    id: 'shipmentList.ltlStatusMap.atTerminal',
    defaultMessage: 'At Terminal',
  },
  atDelivery: {
    id: 'shipmentList.ltlStatusMap.atDelivery',
    defaultMessage: 'At Delivery',
  },
  delivered: {
    id: 'shipmentList.ltlStatusMap.delivered',
    defaultMessage: 'Delivered',
  },
  completedWithExceptions: {
    id: 'shipmentList.ltlStatusMap.completedWithExceptions',
    defaultMessage: 'Completed with Exceptions',
  },
  exception: {
    id: 'shipmentList.ltlStatusMap.exception',
    defaultMessage: 'Exception',
  },
  deliveredWithExceptions: {
    id: 'shipmentList.ltlStatusMap.deliveredWithExceptions',
    defaultMessage: 'Delivered with Exceptions',
  },
  timedOut: {
    id: 'shipmentList.ltlStatusMap.timedOut',
    defaultMessage: 'Timed Out',
  },
  canceled: {
    id: 'shipmentList.ltlStatusMap.canceled',
    defaultMessage: 'Canceled',
  },
  unknown: {
    id: 'shipmentList.ltlStatusMap.unknown',
    defaultMessage: 'Unknown',
  },
  notReceivingData12To23: {
    id: 'shipmentList.ltlStatusMap.notReceivingData12To23',
    defaultMessage: 'Not receiving data between 12 and 23 hours',
  },
  notReceivingData24To47: {
    id: 'shipmentList.ltlStatusMap.notReceivingData24To47',
    defaultMessage: 'Not receiving data between 24 and 47 hours',
  },
  notReceivingData48To71: {
    id: 'shipmentList.ltlStatusMap.notReceivingData48To71',
    defaultMessage: 'Not receiving data between 48 to 71 hours',
  },
  notReceivingDataMoreThan72: {
    id: 'shipmentList.ltlStatusMap.notReceivingDataMoreThan72',
    defaultMessage: 'Not receiving data in more than 72 hours',
  },
});

export const statusTextMap = (intl) => ({
  /**
   * Shipment is scheduled and is at least 90 minutes away from the start of the pickup appointment window.
   */
  SCHEDULED: {
    flagText: intl.formatMessage(messages.scheduled),
  },
  /**
   * Shipment is within 90 minutes of the pickup appointment window start and no status updates have been received yet.
   */
  ATTEMPTING_TO_TRACK: {
    flagText: intl.formatMessage(messages.attemptingToTrack),
  },

  PICKUP_MISSED: {
    flagText: intl.formatMessage(messages.pickupMissed),
  },

  INTERLINE_MISSED: {
    flagText: intl.formatMessage(messages.interlineMissed),
  },

  DEPARTURE_MISSED: {
    flagText: intl.formatMessage(messages.departureMissed),
  },

  DELIVERY_MISSED: {
    flagText: intl.formatMessage(messages.deliveryMissed),
  },
  /**
   * Shipment is within 90 minutes of the pickup appointment window start and no status updates have been received yet.
   */
  HELD: {
    flagText: intl.formatMessage(messages.held),
  },

  DELAYED: {
    flagText: intl.formatMessage(messages.delayed),
  },

  /**
   * Shipment is at the pickup (ORIGIN) stop.
   */
  AT_PICKUP: {
    flagText: intl.formatMessage(messages.atPickup),
  },
  /**
   * Shipment is at a TERMINAL type stop.
   */
  AT_TERMINAL: {
    flagText: intl.formatMessage(messages.atTerminal),
  },
  /**
   * Shipment is at a delivery (DESTINATION) stop.
   */
  AT_DELIVERY: {
    flagText: intl.formatMessage(messages.atDelivery),
  },
  /**
   * Shipment has been picked up and has departed from the ORIGIN stop.
   */
  PICKED_UP: {
    flagText: intl.formatMessage(messages.pickedUp),
  },
  /**
   * Shipment is in transit to any stop.
   */
  IN_TRANSIT: {
    flagText: intl.formatMessage(messages.inTransit),
  },

  /**
   * Shipment is en route to the delivery (DESTINATION) stop.
   */
  OUT_FOR_DELIVERY: {
    flagText: intl.formatMessage(messages.outForDelivery),
  },

  /**
   * Shipment was processed at the delivery (DESTINATION) stop and the truck departed the destination.
   */
  DELIVERED: {
    flagText: intl.formatMessage(messages.delivered),
  },

  /**
   * Shipment completed but with an exception.
   */
  EXCEPTION: {
    flagText: intl.formatMessage(messages.completedWithExceptions),
  },

  /**
   * Shipment was canceled.
   */
  CANCELED: {
    flagText: intl.formatMessage(messages.canceled),
  },

  /**
   * Shipment did not receive tracking information within 5 days of the pickup window.
   */
  TIMED_OUT: {
    flagText: intl.formatMessage(messages.timedOut),
  },

  /**
   * Unable to determine the status of the shipment.
   */
  UNKNOWN: {
    flagText: intl.formatMessage(messages.unknown),
  },

  /**
   * Not receiving data 12 To 23
   */
  NOT_RECEIVING_DATA_12_TO_23: {
    flagText: intl.formatMessage(messages.notReceivingData12To23),
  },
  /**
   * Not receiving data 24 To 47
   */
  NOT_RECEIVING_DATA_24_TO_47: {
    flagText: intl.formatMessage(messages.notReceivingData24To47),
  },
  /**
   * Not receiving data  48 To 71
   */
  NOT_RECEIVING_DATA_48_TO_71: {
    flagText: intl.formatMessage(messages.notReceivingData48To71),
  },

  /**
   * Not receiving data more tha 72.
   */
  NOT_RECEIVING_DATA_MORE_THAN_72: {
    flagText: intl.formatMessage(messages.notReceivingDataMoreThan72),
  },
});
