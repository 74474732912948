import { isValidVATNumber } from 'components/onboarding/utils/validateVATNumber';
import { prefixMapVAT } from './prefixMapVAT';

export function getVATPrefix(countryCode: string) {
  return prefixMapVAT.find((country) => country.countryCode === countryCode);
}

export function getCountryCodeFromPrefix(prefix: string) {
  return prefixMapVAT.find((country) => country.prefix === prefix);
}

export function getVATPrefixFromNumber(VATNumber: string) {
  if (VATNumber) {
    const { hasLettersAsFirstTwoChars } = isValidVATNumber(VATNumber);
    const matchedCode = getCountryCodeFromPrefix(VATNumber.substring(0, 2));

    if (hasLettersAsFirstTwoChars && matchedCode) {
      return {
        ...matchedCode,
        VATWithoutPrefix: VATNumber.substring(2),
      };
    }
  }

  return undefined;
}
