import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import get from 'lodash/get';
import { purgeSessionState } from 'components/login/LoginForm/ducks/actions';

export default function (ComposedComponent, config) {
  const clearOnAuthN = get(config, 'onAuth', false);

  const ClearSessionState = (props) => <ComposedComponent {...props} />;
  const doInit = (props) => {
    if (!props.authenticated || clearOnAuthN) {
      props.purgeState();
    }
  };

  const ClearSessionStateWithLifecycleHooks = compose(
    lifecycle({
      componentWillMount() {
        doInit(this.props);
      },
    })
  )(ClearSessionState);

  const mapStateToProps = (state) => ({
    authenticated: get(state, 'authReducer.isLoggedIn', false),
  });
  const mapDispatchToProps = (dispatch) => ({
    purgeState: () => dispatch(purgeSessionState()),
  });

  return connect(mapStateToProps, mapDispatchToProps)(ClearSessionStateWithLifecycleHooks);
}
