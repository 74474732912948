import * as React from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row } from 'react-bootstrap';
import { Tooltip } from 'antd';
import { oceanShipmentListLocodeEnabled } from 'common/authorizations';
import {
  getFormattedShipmentIdentifierByMode,
  Identifier,
  ShipmentLegCarrierInfo,
  ShipmentModeEnum,
  ShipmentStopSmall,
} from 'models';
import LocationUtils from 'common/LocationUtils/LocationUtils';
import { formatUtcTimeInTimeZoneOrDefaultToBrowserZone } from 'common/dateUtils';
import { formatNestedIntlValues } from 'i18n/utils';
import { HOURS_MINUTES_TIMEZONE_FORMAT, MONTH_DAY_YEAR_FORMAT } from 'i18n/configurei18n';
import SlotBooking, { slotBookingWindowTypes } from 'components/slotBooking/SlotBooking';
import * as styles from '../ShipmentSearchResultCard.module.scss';
import { getShipmentIdentifiersWithLabels } from '../../../newShipmentDetails/utils/shipmentUtils';
import menuOverflow from '../../../../assets/images/menu-overflow.svg';

interface ShipmentIdentifiersProps {
  identifiers: Identifier[];
  mode: ShipmentModeEnum;
}
export const ShipmentIdentifiers: React.FC<ShipmentIdentifiersProps> = ({ identifiers, mode }) => {
  const intl = useIntl();

  if (!identifiers) {
    return null;
  }

  const identifiersWithLabels = getShipmentIdentifiersWithLabels(identifiers, intl);
  const mainIdentifierLabel = getFormattedShipmentIdentifierByMode(mode, intl);
  const isMainIdentifier = (identifier: Identifier): boolean => (identifier.label || '').includes(mainIdentifierLabel);

  const identifierToDisplay = identifiersWithLabels.find(isMainIdentifier) || identifiersWithLabels[0];
  const isIdentifierDisplayed = (identifier: Identifier): boolean => isEqual(identifier, identifierToDisplay);

  const additionalIdentifiers =
    identifiersWithLabels.length <= 1
      ? null
      : identifiersWithLabels.map((identifier: Identifier) => {
          return isIdentifierDisplayed(identifier) ? null : (
            <p key={`${identifier.label}-${identifier.value}`} className="m-0">
              {identifier.label} <span className={styles.trackingId}>#{identifier.value}</span>
            </p>
          );
        });

  return (
    <div className={styles.identifierSection}>
      <Row className="flex-nowrap">
        {!!identifierToDisplay && (
          <div
            data-locator={`primary-shipment-identifier-${identifierToDisplay.value}`}
            key={`${identifierToDisplay.label}-${identifierToDisplay.value}`}
            className={styles.primaryIdentifier}
          >
            {identifierToDisplay.intlMessageObject ? (
              <FormattedMessage
                id={identifierToDisplay.intlMessageObject.id}
                defaultMessage={identifierToDisplay.intlMessageObject.defaultMessage}
              />
            ) : (
              identifierToDisplay.label
            )}
            <span className={styles.trackingId}>#{identifierToDisplay.value}</span>
          </div>
        )}
        {!!additionalIdentifiers && (
          <Tooltip title={additionalIdentifiers}>
            <img
              src={menuOverflow}
              alt={intl.formatMessage({
                id: 'shipmentList.listCardAltText.moreIdentifiers',
                defaultMessage: 'More identifiers',
              })}
              className={styles.ellipses}
            />
          </Tooltip>
        )}
      </Row>
    </div>
  );
};

interface ShipmentStopInfoProps {
  stopInfo?: ShipmentStopSmall;
  shipmentIdentifiers: Identifier[];
  shipmentId: string;
  carrierIdentifiers: ShipmentLegCarrierInfo[];
}

export const ShipmentStopInfo: React.FC<ShipmentStopInfoProps> = ({
  stopInfo,
  shipmentIdentifiers,
  shipmentId,
  carrierIdentifiers,
}) => {
  const intl = useIntl();
  const stopName = get(stopInfo, 'stopName');
  const locode = get(stopInfo, 'locode');
  const location = get(stopInfo, 'stopInfo.location');
  const localTimeZoneIdentifier = location?.address?.locationCoordinatesDto?.localTimeZoneIdentifier;
  const stopLocation = stopInfo
    ? LocationUtils.formatCityStateCountry(stopInfo.city, stopInfo.state, stopInfo.country)
    : undefined;

  const getStopDateTimeText = (dateRange?: ShipmentStopSmall['dateRange'], localTimeZone?: any) => {
    if (!dateRange || !dateRange.startDate) {
      return;
    }

    const dateTime = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(
      dateRange.startDate,
      dateRange.timezone ? dateRange.timezone : localTimeZone
    );
    const date = dateTime ? dateTime.format(MONTH_DAY_YEAR_FORMAT) : undefined;
    const time = dateTime ? dateTime.format(HOURS_MINUTES_TIMEZONE_FORMAT) : undefined;

    return date && time
      ? {
          id: 'carrierShipmentList.stopInfo.dateTimeText',
          defaultMessage: '{date} at {time}',
          values: { date, time },
        }
      : undefined;
  };

  //TODO: implement manual switch to get stopDate also from -> get(stopInfo, 'formatedDateTimeText')
  const stopDateTimeText = getStopDateTimeText(get(stopInfo, 'dateRange'), localTimeZoneIdentifier);
  const slotBookingAppointmentWindows = stopInfo?.stopInfo?.appointmentWindows
    ? stopInfo.stopInfo.appointmentWindows.filter((appointmentWindow) =>
        slotBookingWindowTypes.includes(appointmentWindow.windowType)
      )
    : [];

  return stopName || stopLocation ? (
    <div data-locator="shipment-stop-info">
      {locode && oceanShipmentListLocodeEnabled() && <p className={`medium-text bold ${styles.stopInfo}`}>{locode}</p>}
      <p className={`medium-text bold ${styles.stopInfo}`}>{stopName}</p>
      <p className={`medium-text d-none d-sm-block ${styles.stopInfo}`}>{stopLocation}</p>
      <p className={`date-time medium-text d-none d-sm-block ${styles.stopInfo}`}>
        {get(stopDateTimeText, 'id') &&
          intl.formatMessage(
            {
              id: get(stopDateTimeText, 'id'),
              defaultMessage: get(stopDateTimeText, 'defaultMessage'),
            },
            formatNestedIntlValues(get(stopDateTimeText, 'values', {}), intl)
          )}
      </p>
      {slotBookingAppointmentWindows.length > 0
        ? slotBookingAppointmentWindows.map((appointmentWindow) => (
            <SlotBooking
              key={appointmentWindow.windowType}
              shipmentId={shipmentId}
              carrierIdentifiers={carrierIdentifiers}
              shipmentIdentifiers={shipmentIdentifiers}
              stopId={stopInfo?.stopInfo?.uuid}
              stopType={stopInfo?.stopInfo?.stopType}
              appointmentWindow={appointmentWindow}
              locationId={stopInfo?.stopInfo?.location?.locationId}
            />
          ))
        : null}
    </div>
  ) : (
    <div>
      <p className="medium-text">
        <FormattedMessage id="statusCards.result.notSpecified" defaultMessage="Not Specified" />
      </p>
    </div>
  );
};
