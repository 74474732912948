import { primaryBlue, chathamsBlue } from 'styles/colors';
import { Theme } from './models/Theme';
import appIcon from '../../common/assets/logo.svg';

const defaultTheme: Theme = {
  logoUrl: appIcon,
  isDefaultLogo: true,
  primaryColor: primaryBlue,
  landingColor: chathamsBlue,
  footerColor: chathamsBlue,
  secondaryColor: primaryBlue,
  emailFooter: '',
  logoEnabled: null,
};

export default defaultTheme;
