import qs from 'qs';
import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import transform from 'lodash/transform';
import capitalize from 'lodash/capitalize';
import axios from 'util/paxios';
import { buildShipmentSearchQuery } from 'components/shipmentCounts/ducks/operations';
import { API_PATH } from 'common/AppConstants';
import endpoints from 'common/endpoints';
import {
  STEPS,
  QUICKVIEW_SUBTOTAL_SEARCHTERM,
  QUICKVIEW_SUBTOTAL_FILTERS,
  QUICKVIEW_TOTAL_SEARCHTERM,
  QUICKVIEW_TOTAL_FILTERS,
  QUICKVIEW_SUBTOTAL_COUNT,
  QUICKVIEW_TOTAL_COUNT,
  QUICKVIEW_SUBTOTAL_MODE,
  QUICKVIEW_TOTAL_MODE,
  QuickviewEntityType,
} from './constants';

export const getCurrentStepIndex = (currentStep: string) =>
  STEPS.findIndex((step: any) => {
    const stepRegexp = new RegExp(step.id, 'i');
    return stepRegexp.test(currentStep);
  });

export const countShipments = async function (config: any, principal: any, setCount: (value: number) => void) {
  try {
    const searchQuery = buildShipmentSearchQuery(config.searchKeywords, config.filter, config.mode, 0, principal);
    const response = await axios.post(`${API_PATH}/shipment/search`, searchQuery, { withCredentials: true });
    const totalResults = response.data.totalResults;
    setCount(totalResults);
  } catch (error) {
    console.error(error);
  }
};

export const countOrders = async function (requestBody: { [key: string]: any }, setCount: (value: number) => void) {
  try {
    const url = new URL(`${API_PATH}${endpoints.ORDERS_SEARCH}`, window.location.origin);

    const response = await axios.post(url.toString(), requestBody, {
      withCredentials: true,
    });
    const totalResults = response.data.paginationInfo.total;
    setCount(totalResults);
  } catch (error) {
    console.error(error);
  }
};

export const countInventory = async function (requestBody: { [key: string]: any }, setCount: (value: number) => void) {
  try {
    // API will throw a 400 if we don't include `page` and `size` parameters
    const url = new URL(`${API_PATH}${endpoints.INVENTORY_ITEMS_SEARCH}?page=1&size=30`, window.location.origin);

    const response = await axios.post(url.toString(), requestBody, {
      withCredentials: true,
    });
    const totalResults = response.data.paginationInfo.total;
    setCount(totalResults);
  } catch (error) {
    console.error(error);
  }
};

export const round = (value: number) => Math.round((value + Number.EPSILON) * 10) / 10;

export const parsePercentToCount = (percent: number, total: number) => Math.round((total * percent) / 100);

export const parseCountToPercent = (count: number, total: number) => round((count * 100) / total);

export const clearSessionStorage = () => {
  const sessionStorageKeysList = [
    QUICKVIEW_SUBTOTAL_SEARCHTERM,
    QUICKVIEW_SUBTOTAL_FILTERS,
    QUICKVIEW_TOTAL_SEARCHTERM,
    QUICKVIEW_TOTAL_FILTERS,
    QUICKVIEW_SUBTOTAL_COUNT,
    QUICKVIEW_TOTAL_COUNT,
    QUICKVIEW_SUBTOTAL_MODE,
    QUICKVIEW_TOTAL_MODE,
  ];

  sessionStorageKeysList.forEach((key) => {
    window.sessionStorage.removeItem(key);
  });
};

export function onKeyDownBlockEnter(keyEvent: React.KeyboardEvent<HTMLFormElement>) {
  if (keyEvent.code === 'Enter') {
    keyEvent.preventDefault();
  }
}

export const clearTotalSessionStorage = () => {
  const totalSessionStorageKeysList = [
    QUICKVIEW_TOTAL_SEARCHTERM,
    QUICKVIEW_TOTAL_FILTERS,
    QUICKVIEW_TOTAL_COUNT,
    QUICKVIEW_TOTAL_MODE,
  ];

  totalSessionStorageKeysList.forEach((key) => {
    window.sessionStorage.removeItem(key);
  });
};

const ENTITY_TYPE_MESSAGES = {
  [QuickviewEntityType.SHIPMENTS]: {
    healthScoreMessageId: 'quickViewConfig.description.healthScore',
    healthScoreMessage:
      'Use health scores to gain high-level understanding of the subset of shipments in your Quick View. Configure the ranges below to provide insights as to what the results of your Quick View mean to your business.',
    subTotalofTotalMessageID: 'quickViewCard.tile.numberOfShipments',
    subTotalOfTotalDefaultMessage: '{subTotal} of {total} shipments',
    noEntityCountDefaultMessageId: 'quickViewCard.tile.noShipmentsDefault',
    noEntityCountDefaultMessage: 'shipments',
    subtotalStepInstructionMessageId: 'quickViewConfig.subtotalConfiguration.stepInstruction',
    subtotalDefaultStepInstructionMessage:
      'Select the mode and filtering criteria that will define the shipments included in the Quick View. You can define a total to compare the subtotal against in step 2.',
    totalStepInstructionMessageId: 'quickViewConfig.totalConfiguration.stepInstruction',
    totalDefaultStepInstructionMessage:
      'Select the mode and filtering criteria that will the define the total shipments to compare against the subtotal defined in step 1.',
    placeholderMessageId: 'quickViewConfig.placeholder.title',
    placeholderDefaultMessage: 'Add a descriptive title: e.g., "This week’s late truckload shipments"',
  },
  [QuickviewEntityType.ORDERS]: {
    healthScoreMessageId: 'quickViewConfig.description.healthScoreOrders',
    healthScoreMessage:
      'Use health scores to gain high-level understanding of the subset of orders in your Quick View. Configure the ranges below to provide insights as to what the results of your Quick View mean to your business.',
    subTotalofTotalMessageID: 'quickViewCard.tile.numberOfOrders',
    subTotalOfTotalDefaultMessage: '{subTotal} of {total} orders',
    noEntityCountDefaultMessageId: 'quickViewCard.tile.noOrdersDefault',
    noEntityCountDefaultMessage: 'orders',
    subtotalStepInstructionMessageId: 'quickViewConfig.subtotalConfiguration.ordersStepInstruction',
    subtotalDefaultStepInstructionMessage:
      'Select the mode and filtering criteria that will define the orders included in the Quick View. You can define a total to compare the subtotal against in step 2.',
    totalStepInstructionMessageId: 'quickViewConfig.totalConfiguration.ordersStepInstruction',
    totalDefaultStepInstructionMessage:
      'Select the mode and filtering criteria that will the define the total orders to compare against the subtotal defined in step 1.',
    placeholderMessageId: 'quickViewConfig.placeholder.ordersTitle',
    placeholderDefaultMessage: 'Add a descriptive title: e.g., "Orders due this week"',
  },
  [QuickviewEntityType.INVENTORY]: {
    healthScoreMessageId: 'quickViewConfig.description.healthScoreInventory',
    healthScoreMessage:
      'Use health scores to gain high-level understanding of the subset of inventory in your Quick View. Configure the ranges below to provide insights as to what the results of your Quick View mean to your business.',
    subTotalofTotalMessageID: 'quickViewCard.tile.numberOfInventory',
    subTotalOfTotalDefaultMessage: '{subTotal} of {total} inventory',
    noEntityCountDefaultMessageId: 'quickViewCard.tile.noInventoryDefault',
    noEntityCountDefaultMessage: 'inventory',
    subtotalStepInstructionMessageId: 'quickViewConfig.subtotalConfiguration.inventoryStepInstruction',
    subtotalDefaultStepInstructionMessage:
      'Select the mode and filtering criteria that will define the inventory included in the Quick View. You can define a total to compare the subtotal against in step 2.',
    totalStepInstructionMessageId: 'quickViewConfig.totalConfiguration.inventoryStepInstruction',
    totalDefaultStepInstructionMessage:
      'Select the mode and filtering criteria that will the define the total inventory to compare against the subtotal defined in step 1.',
    placeholderMessageId: 'quickViewConfig.placeholder.inventoryTitle',
    placeholderDefaultMessage: 'Add a descriptive title: e.g., "Hazard Class 1 inventory"',
  },
};

export const getEntityTypeMessages = (entityType: QuickviewEntityType) => {
  return ENTITY_TYPE_MESSAGES[entityType];
};

export const getEntityTypeFromSearch = (search: string): QuickviewEntityType => {
  const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
  return (get(parsedSearch, 'entityType') as QuickviewEntityType) || QuickviewEntityType.SHIPMENTS;
};

function deepOmitNil(object: { [key: string]: any } | Array<any>): { [key: string]: any } | Array<any> {
  return transform(object, (result, value, key) => {
    if (Array.isArray(value) || isPlainObject(value)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-param-reassign
      value = deepOmitNil(value);
    }

    if (isPlainObject(value) && isEmpty(value)) {
      return;
    }

    if (Array.isArray(value) && !value.length) {
      return;
    }

    if (value === '' || value === null || value === undefined) {
      return;
    }

    if (Array.isArray(result)) {
      return result.push(value);
    }

    // @ts-ignore
    result[key] = value;
  });
}

export const generateMinimalQueryURL = (queryObject: { [key: string]: any }): string => {
  // deep omit nulls and other unfilled values from the API to avoid generating values that the list pages won't
  // parse correctly. if you only do top-level omission and don't go some amount of depth, you will generate invalid
  // URLs
  const minimalObject = deepOmitNil(queryObject);
  return qs.stringify(minimalObject, { addQueryPrefix: true });
};

export const getStepDateKey = (step: string, eventNamespace: string) => {
  const isSubtotalStep = step === 'subtotal';
  return isSubtotalStep ? `${eventNamespace}Date` : `total${capitalize(eventNamespace)}Date`;
};
