import React from 'react';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SearchOutlined } from '@ant-design/icons';
import { primaryGreyEighty } from 'styles/colors';

export const NoResultsToDisplay: React.FC = () => {
  return (
    <Row className="AppliedFilterList align-items-center">
      <Col xs={24} className="no-results-message">
        <h4>
          <FormattedMessage
            id="searchCarrier.fleetList.noResultsMessage"
            defaultMessage="No vehicle IDs were found. Carrier API connection or carrier’s ELD are not sharing their fleet information with P44."
          />
        </h4>
        <SearchOutlined style={{ color: primaryGreyEighty, fontWeight: 'bolder', fontSize: '60px' }} />
      </Col>
    </Row>
  );
};

export default NoResultsToDisplay;
