import types from './types';

export const getShipmentCounts = () => ({
  type: types.SHIPMENT_COUNTS_GET,
});

export const editConfig = () => ({
  type: types.SHIPMENT_COUNTS_EDIT,
});

export const cancelEditConfig = () => ({
  type: types.SHIPMENT_COUNTS_CANCEL_EDIT,
});

export const openQuickViewDialog = (mode, config, isQuickviewV2Enabled, index) => ({
  type: types.QUICK_VIEW_OPEN,
  mode: mode,
  config: config,
  index: index,
  isQuickviewV2Enabled: isQuickviewV2Enabled,
});

export const cancelQuickViewDialog = () => ({
  type: types.QUICK_VIEW_CANCEL,
});

export const deleteQuickViewTile = (tile) => ({
  type: types.QUICK_VIEW_DELETE,
  tile: tile,
});

export const changeSelectedIcon = (icon) => ({
  type: types.QUICK_VIEW_CHANGE_ICON,
  icon: icon,
});

export const changeSelectedDate = (type, range) => ({
  type: type,
  range: range,
});

export const setInputValue = (value, input) => ({
  type: types.QUICK_VIEW_SET_INPUT,
  value: value,
  input: input,
});

export const saveUpdatedLayout = (config) => ({
  type: types.QUICK_VIEW_SAVE_LAYOUT,
  config: config,
});

export const setUpdatedLayout = (layout, layouts) => ({
  type: types.QUICK_VIEW_SET_LAYOUT,
  layouts: layouts,
});

export const saveQuickViewDialog = (type, data, updatedFilter, selectedMode) => ({
  type: type,
  config: data,
  updatedFilter: updatedFilter,
  selectedMode: selectedMode,
});

export const closeSnackbar = () => ({
  type: types.QUICK_VIEW_CLOSE_SNACKBAR,
});

export const setQuickViewMode = (data) => ({
  type: types.QUICK_VIEW_SET_MODE,
  payload: data,
});

export const setQuickViewDuplicate = (data) => ({
  type: types.QUICK_VIEW_DUPLICATE,
  payload: data,
});
