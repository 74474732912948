import '@project44-lib/here-maps-api-js';

import { createRef, Component } from 'react';

import { HereMapsConfig, AerisWeatherConfig } from 'models';
import { Location } from '../models/Location';
import { buildMapInstance, buildPlatform, addWeatherLayer } from './HereMapUtils';
import * as styles from './map.module.scss';
import { buildMapMarker, locationToIcon } from './Markers/MarkerUtil';

/// <reference types="@types/heremaps" />

interface MapState {
  mapInstance?: H.Map;
  mapPlatform?: H.service.Platform;
}

interface MapProps {
  locations: Location[];
  hereMapConfig?: HereMapsConfig;
  aerisWeatherConfig?: AerisWeatherConfig;
  calculateCenter: (locations: Location[]) => Location;
  calculateZoom: (locations: Location[]) => number;
  authorizations?: any;
}

class HereMapWrapper extends Component<MapProps, MapState> {
  constructor(props: MapProps) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    this.initializeMap();
  }

  public componentDidUpdate() {
    if (!this.state.mapInstance) {
      this.initializeMap();
    }
  }

  // tslint:disable-next-line
  public mapRef = createRef<HTMLDivElement>();

  public initializePlatform = () => {
    if (!this.state.mapPlatform && this.props.hereMapConfig) {
      this.setState({
        ...this.state,
        mapPlatform: buildPlatform(this.props.hereMapConfig),
      });
    }
  };

  public initializeMap = () => {
    this.initializePlatform();
    if (this.state.mapPlatform && this.mapRef.current) {
      const mapInstances = buildMapInstance(this.state.mapPlatform, this.mapRef.current);
      const inst = mapInstances.mapInstance;
      inst.addObjects(this.props.locations.map(buildMapMarker));
      inst.setCenter(locationToIcon(this.props.calculateCenter(this.props.locations)));
      inst.setZoom(3);
      if (this.props.authorizations.shipmentDetailWeatherMapEnabled() && this.props.aerisWeatherConfig) {
        addWeatherLayer(inst, this.props.aerisWeatherConfig);
      }
      this.setState({
        ...this.state,
        mapInstance: inst,
      });
    }
  };

  public render() {
    return (
      <div className={styles.mapWrapper}>
        <div ref={this.mapRef} className={styles.map} />
      </div>
    );
  }
}

export default HereMapWrapper;
