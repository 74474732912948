import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { DropzoneRenderArgs } from 'react-dropzone';
import { ReactComponent as FileUploadIcon } from 'components/common/assets/icons/icon_file_upload.svg';
import * as styles from './FilesUploadArea.module.scss';

export interface FilesUploadAreaRootProps extends DropzoneRenderArgs {
  error?: string | undefined;
}

const FilesUploadArea: FunctionComponent<FilesUploadAreaRootProps> = ({
  getRootProps,
  getInputProps,
  open,
  isDragActive,
  isDragAccept,
  isDragReject,
  error,
}) => {
  const handleBrowseFilesButtonPressed = (event: React.SyntheticEvent<EventTarget>) => {
    event.stopPropagation();
    open();
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      {...getRootProps()}
      className={classNames(styles.dropzone, {
        [styles.dragAccept]: isDragAccept,
        [styles.dragReject]: isDragReject,
        [styles.uploadError]: error !== undefined,
      })}
      id={`dropzone${isDragAccept ? '_dragAccept' : isDragReject ? '_dragReject' : ''}`}
      onClick={open}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          open();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <input {...getInputProps()} />
      <div
        className={classNames(styles.uploadWrapper, {
          [styles.fadeAway]: isDragActive,
          [styles.hide]: isDragReject,
        })}
        id={`uploadWrapper${isDragActive ? '_fadeAway' : isDragReject ? '_hide' : ''}`}
      >
        <span>
          <FileUploadIcon />
        </span>
        <span className="text-3xl pl-2 pt-0">
          <FormattedMessage
            defaultMessage="Drag and drop files, or "
            id="documentManualUploadUi.filesUpload.dragAndDropFilesHereOr"
          />
        </span>
        <span>
          <button onClick={handleBrowseFilesButtonPressed} className={classNames(styles.browseButton, 'pt-0 text-3xl')}>
            <FormattedMessage defaultMessage="Browse" id="documentManualUploadUi.filesUpload.browse" />
          </button>
        </span>
      </div>

      <div
        className={classNames(styles.errorMessage, {
          [styles.hide]: isDragReject || !error,
        })}
        id="errorMessage"
      >
        {error}
      </div>
    </div>
  );
};

export default FilesUploadArea;
