import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SortResultsContainer from '../common/sortResults/SortResultsContainer';
import Progress from '../common/progress/Progress';
import generateFilterDescription from './utils/generateFilterDescription';
import sortTypes from '../common/sortResults/ducks/types';

interface LoadingFiltersProps {
  title: string;
  filter: any;
  isLoading: boolean;
  filtersHorizontalEnabled: boolean;
}

export const LoadingFilters = ({ title, filter, isLoading, filtersHorizontalEnabled }: LoadingFiltersProps) => {
  return (
    <Row className="AppliedFilterList loading align-items-center">
      <Col>
        <h3>{title}</h3>
        <div className="filter-selections">{generateFilterDescription(filter)}</div>
      </Col>
      <Col xs={16} className="filter-sort-container">
        <div className="sort-component">
          <SortResultsContainer
            sortFields={sortTypes.SHIPMENT_SORT_FIELDS}
            sortType={sortTypes.SHIPMENT_SORT}
            filtersHorizontalEnabled={filtersHorizontalEnabled}
          />
        </div>
      </Col>
      <Col xs={24}>
        <Progress isLoading={isLoading} />
      </Col>
    </Row>
  );
};

export default LoadingFilters;
