import * as React from 'react';

import { automatedTestUsers } from 'common/AppConstants';
import { disableAutomatedTestAccountsOnLandingMap } from 'common/authorizations';
import ShipmentCountsContainer from '../shipmentCounts/ShipmentCountsContainer';
import LandingMap from './LandingMap/LandingMap';

export interface LandingComponentProps {
  authorizations: any;
  userId: number;
}
const LandingComponent: React.FC<LandingComponentProps> = ({ authorizations, userId }) => {
  const shouldDisableMap = disableAutomatedTestAccountsOnLandingMap() && automatedTestUsers.includes(userId);
  return (
    <div>
      <ShipmentCountsContainer />
      {authorizations.hasTruckloadMode() && !shouldDisableMap && <LandingMap />}
    </div>
  );
};

export default LandingComponent;
