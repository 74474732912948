import findLast from 'lodash/findLast';
import last from 'lodash/last';
import get from 'lodash/get';
import { defineMessages } from 'react-intl';

import {
  ShipmentSearchResult,
  PickupOrDeliveryStopInfo,
  ShipmentStopInfo,
  ShipmentStopSmall,
  ShipmentStopTypeEnum,
} from 'models';
import * as DateUtils from 'common/dateUtils';
import { intl } from '../../../common/AppConstants';
import endpoints, { replaceTokens } from '../../../common/endpoints';
import DateFormatUtil from '../../shipment/common/utils/dateFormatUtil';
import LocationUtils from '../../../common/LocationUtils/LocationUtils';
import ShipmentDetailUtil from '../../shipment/common/utils/shipmentDetailUtil';
import { getMinutesAgoFromUtcTimestamp } from '../../../common/dateUtils';

const message = defineMessages({
  etaIs: {
    id: 'statusCards.shipmentDetailUtil.etaIs',
    defaultMessage: 'ETA is {date} at {time}',
  },
});

export const getShipmentUrlFromMasterShipmentId = (masterShipmentId: string): string => {
  return replaceTokens(endpoints.TRACKING_DETAILS_MASTER_SHIPMENT_ID, {
    masterShipmentId,
  });
};

export const getCurrentEtaDescription = (shipment: ShipmentSearchResult): string | undefined => {
  const { shipmentStops = [], latestStopStatuses = [] } = shipment;
  const deliveryStop = last(shipmentStops) as ShipmentStopInfo;
  const latestDeliveryStopStatus = deliveryStop
    ? findLast(latestStopStatuses, (stopStatus) => {
        return stopStatus.stopNumber === deliveryStop.stopNumber;
      })
    : last(latestStopStatuses);

  const currentEtaUtcDateTime = get(latestDeliveryStopStatus, 'utcEarliestEstimatedArrivalDateTime');

  if (currentEtaUtcDateTime) {
    const outputTimezone = DateUtils.browserTimezone();
    const dateText = DateFormatUtil.formatAsDate(currentEtaUtcDateTime, null, outputTimezone);
    const timeText = DateFormatUtil.formatAsTimeWithTimezone(currentEtaUtcDateTime, null, outputTimezone);
    return dateText && timeText ? intl.formatMessage(message.etaIs, { date: dateText, time: timeText }) : undefined;
  }
};

export const getLastUpdatedText = (shipment: ShipmentSearchResult): string | undefined => {
  return DateFormatUtil.mapFromMinutesAgoToLastUpdatedText(
    getMinutesAgoFromUtcTimestamp(get(shipment, 'updatedDate', undefined))
  );
};

/* Note: getPickupAndDeliveryStopInfo can be deprecated after full #intermodal turnover */
export const getPickupAndDeliveryStopInfo = (
  shipmentSearchResult: ShipmentSearchResult
): { pickupStopInfo: ShipmentStopSmall | undefined; deliveryStopInfo: ShipmentStopSmall | undefined } => {
  const rawPickupStopInfo = get(shipmentSearchResult, 'pickupStopInfo');
  const rawDeliveryStopInfo = get(shipmentSearchResult, 'deliveryStopInfo');

  const formatStopInfo = (
    rawStopInfo: PickupOrDeliveryStopInfo,
    stopType: ShipmentStopTypeEnum
  ): ShipmentStopSmall | undefined => {
    const formatedDateTimeText = ShipmentDetailUtil.getStopStatusMessage(
      rawStopInfo,
      shipmentSearchResult.latestStopStatuses,
      shipmentSearchResult,
      false
    );
    const country = LocationUtils.getCountryCodeForLocation(
      rawStopInfo,
      rawStopInfo.stopName,
      shipmentSearchResult.shipmentStops,
      stopType
    );

    return { ...rawStopInfo, formatedDateTimeText, country };
  };

  const pickupStopInfo = rawPickupStopInfo ? formatStopInfo(rawPickupStopInfo, ShipmentStopTypeEnum.ORIGIN) : undefined;
  const deliveryStopInfo = rawDeliveryStopInfo
    ? formatStopInfo(rawDeliveryStopInfo, ShipmentStopTypeEnum.DESTINATION)
    : undefined;

  return { pickupStopInfo, deliveryStopInfo };
};
