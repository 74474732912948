export interface SensorData {
  identifier: {
    type: SensorTypeEnum;
    value: string;
  };
  max: number;
  min: number;
  sensorId: number;
  target: number;
  unitOfMeasurement: TemperatureScaleEnum;
}

export interface TemperatureReading {
  value: number;
  unitOfMeasurement: string;
  utcTimestamp: string;
}

export enum TemperatureScaleEnum {
  FAHRENHEIT = 'FAHRENHEIT',
  CELSIUS = 'CELSIUS',
}

export enum SensorTypeEnum {
  TEMPERATURE = 'TEMPERATURE',
}

export const TemperatureScaleShort: { [P in TemperatureScaleEnum]: string } = {
  [TemperatureScaleEnum.FAHRENHEIT]: 'F',
  [TemperatureScaleEnum.CELSIUS]: 'C',
};
