export enum EquipmentIdentifierTypeEnum {
  MOBILE_PHONE_NUMBER = 'MOBILE_PHONE_NUMBER',
  VEHICLE_ID = 'VEHICLE_ID',
  LICENSE_PLATE = 'LICENSE_PLATE',
  SENSITECH_DEVICE_ID = 'SENSITECH_DEVICE_ID',
  EMERSON_DEVICE_ID = 'EMERSON_DEVICE_ID',
  CONTAINER_ID = 'CONTAINER_ID',
  TIVE_DEVICE_ID = 'TIVE_DEVICE_ID',
}

export enum CarrierFacingEquipmentIdsEnum {
  VEHICLE_ID = 'VEHICLE_ID',
  MOBILE_PHONE_NUMBER = 'MOBILE_PHONE_NUMBER',
  LICENSE_PLATE = 'LICENSE_PLATE',
}

export enum EuropeCarrierEquipmentIdsEnum {
  MOBILE_PHONE_NUMBER = 'MOBILE_PHONE_NUMBER',
  LICENSE_PLATE = 'LICENSE_PLATE',
}

export enum NorthAmericaCarrierEquipmentIdsEnum {
  MOBILE_PHONE_NUMBER = 'MOBILE_PHONE_NUMBER',
  VEHICLE_ID = 'VEHICLE_ID',
}

export interface EquipmentIdentifier {
  type: EquipmentIdentifierTypeEnum | CarrierFacingEquipmentIdsEnum;
  value: string;
  active?: boolean;
  timestamp?: string;
}
