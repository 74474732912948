import { format } from 'date-fns';
import qs from 'qs';

import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import pick from 'lodash/pick';
import { RadioChangeEvent } from 'antd/lib/radio';
import locationFilterApiNames from './locationFilterApiNames';

export const formatDate = (strDate: string) => {
  if (!strDate) return null;

  try {
    return format(new Date(strDate), "yyyy-MM-dd'T'HH:mm:ssxx");
  } catch (error) {
    return null;
  }
};

export const formatCriteriaFilter = (value: any) => {
  if (isEmpty(value) || !isArray(value)) return undefined;

  return value.reduce((acc: any[], v: any) => {
    const type = v.split(':')[0];
    const value = v.split(':')[1];

    return [...acc, { type, value }];
  }, []);
};

export const getDefaultLocationValues = (data: any) => {
  if (!isObject(data)) return [];

  const locationApiNames = Object.keys(locationFilterApiNames);
  const locationObj = pick(data, locationApiNames);

  if (isEmpty(locationObj)) return [];

  const currentKeys = Object.keys(locationObj);

  return currentKeys.map((k) => {
    // @ts-ignore
    return `${locationFilterApiNames[k]}:${data[k]}`;
  });
};

export const getDefaultTypeValues = (filterName: string, data: any) => {
  if (!isObject(data)) return [];

  // @ts-ignore
  const filterValues = data[filterName];

  if (!filterValues) return [];

  return filterValues.map((value: any) => `${value.type}:${value.value}`);
};

export const parseUrlFilter = (search: string) => {
  try {
    return qs.parse(search.replace('?', ''));
  } catch (error) {
    return {};
  }
};

export const buildRadioHandler =
  <T>(setValue: React.Dispatch<T>) =>
  (event: RadioChangeEvent) => {
    setValue(event.target.value);
  };
