import { useState, useCallback, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import find from 'lodash/find';
import get from 'lodash/get';
import { useIntl, IntlShape } from 'react-intl';
import { API_PATH } from 'common/AppConstants';
import axios from 'util/paxios';
import { mapShipment } from 'components/shipmentListComponent/mappers/mapShipment';
import { ShipmentSearchCardData, ShipmentSearchResult, ShipmentModeEnum } from 'models';
import { ShipmentIdentifierTypeEnum } from '../models';

interface SearchResponse {
  totalResults: number;
  results: Array<ShipmentSearchResult>;
}

export enum LoadingStatus {
  LOADING,
  LOADED,
  ERROR,
}

export function useInventoryShipments(masterShipmentIds: string[]) {
  const [shipments, setShipments] = useState<{ totalResults: number; results: ShipmentSearchCardData[] }>();
  const [currentLoadingStatus, setCurrentLoadingStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);
  const intl = useIntl();
  const getOrders = useCallback(
    async function getOrders(requestBody: string[]) {
      try {
        setCurrentLoadingStatus(LoadingStatus.LOADING);
        const response: AxiosResponse<SearchResponse> = await axios.post(
          `${API_PATH}/shipment/search/ids`,
          requestBody,
          {
            withCredentials: true,
          }
        );
        setCurrentLoadingStatus(LoadingStatus.LOADED);
        const mappedShipments = mapShipments(response.data.results, intl);
        setShipments({ totalResults: response.data.totalResults, results: mappedShipments });
      } catch (error) {
        setCurrentLoadingStatus(LoadingStatus.ERROR);
        console.error(error);
      }
    },
    [intl]
  );

  useEffect(() => {
    if (!shipments) {
      getOrders(masterShipmentIds);
    }
  }, [getOrders, masterShipmentIds, shipments]);
  return {
    currentLoadingStatus,
    shipments,
  };
}

function mapShipments(shipments: ShipmentSearchResult[], intl: IntlShape) {
  return shipments.reduce((acc: any[], shipment: ShipmentSearchResult | undefined) => {
    acc.push(mapShipment(shipment, intl));
    return acc;
  }, []);
}

export const getShipmentIdentifierFromShipment = (shipment: ShipmentSearchCardData): string | undefined => {
  const getIdentifier = (type: ShipmentIdentifierTypeEnum) =>
    find(shipment.identifiers, (id) => id.type === type) || shipment.identifiers[type as any]; // Truckload mapShipment has a different format of identifiers

  if ([ShipmentModeEnum.TL, ShipmentModeEnum.LTL].includes(shipment.mode)) {
    return get(getIdentifier(ShipmentIdentifierTypeEnum.BILL_OF_LADING), 'value');
  }
  return get(getIdentifier(ShipmentIdentifierTypeEnum.CONTAINER_ID), 'value');
};
