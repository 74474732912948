import { PaginationParameters } from './Page';

export const buildUrlWithPaginationParams = (url: URL, pagination?: PaginationParameters): URL => {
  if (pagination?.pageNumber) {
    url.searchParams.append('page', `${pagination.pageNumber}`);
  }
  if (pagination?.pageSize) {
    url.searchParams.append('size', `${pagination.pageSize}`);
  }
  return url;
};
