import classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { PrincipalAuthorizations } from 'common/authorizations';
import { CarrierNetworkRoles } from 'models/CarrierNetworkRoles';
import settingsRoutes from '../settingsRoutes';

import * as styles from './SettingsNavigation.module.scss';

interface SettingsNavigationProps {
  currentPath: string;
}

const SettingsNavigation: React.FunctionComponent<SettingsNavigationProps> = (props) => {
  const intl = useIntl();
  const principalContext = React.useContext(PrincipalContext);
  const authorizations = new PrincipalAuthorizations({ authorities: [], principal: principalContext });
  const hasCarrierNetworkRole = authorizations.hasTenantCarrierRole(principalContext);
  const authorities = get(principalContext, 'authorities');
  const isP44Admin = authorities?.find((authority) => authority.authority === 'ROLE_P44ADMIN');

  return (
    <div className={styles.settingsNav}>
      {Object.keys(settingsRoutes)
        .filter((key) => {
          if (hasCarrierNetworkRole && !settingsRoutes[key].rolesWithAccess.includes(CarrierNetworkRoles.CARRIER)) {
            return false;
          }
          return true;
        })
        .map((route: string) => {
          const routeItem = settingsRoutes[route];
          if (routeItem.label !== undefined) {
            if (route === 'entitlementRules' && !isP44Admin) return undefined;
            else
              return (
                <Link
                  to={routeItem.route}
                  key={routeItem.route}
                  className={classNames(styles.navItem, {
                    [styles.selectedNavItem]: props.currentPath === routeItem.route,
                  })}
                >
                  {routeItem.icon}
                  {intl.formatMessage({
                    id: routeItem.label.id,
                    defaultMessage: routeItem.label.defaultMessage,
                  })}
                </Link>
              );
          }
          return undefined;
        })}
    </div>
  );
};

export const SettingsNavigationComponent = SettingsNavigation;
export default React.memo(SettingsNavigation);
