import { SEARCH_RESULTS_PER_PAGE } from '../../../../common/AppConstants';
import types from './types';

export const initialState = () => ({
  // Current UI state that will be used for a new search query.
  searchTerm: '',
  contactSearchTerm: '',
  filters: [],
  pageNumber: 0,

  // Results of the most recent search being displayed.
  list: [],
  totalSearchResults: 0,
  searchError: null,

  // Previous query stored for the show more pagination functionality.
  previousSearchQuery: {
    search: '',
    filters: [],
    sortBy: [],
    resultStartIndex: 0,
    resultsPerPage: SEARCH_RESULTS_PER_PAGE,
  },
});

const searchReducer = (state = initialState(), action = {}) => {
  // currently only using SEARCH_TYPE and SET_TYPE, will use others when configured for shipment results
  switch (action.type) {
    case types.RESET_SEARCH_QUERY_TYPE:
      return { ...state, ...initialState() };

    case types.SET_SEARCH_TERM_TYPE:
      return { ...state, searchTerm: action.searchTerm };

    case types.SET_CONTACT_SEARCH_TERM_TYPE:
      return { ...state, contactSearchTerm: action.contactSearchTerm };

    case types.SEARCH_TYPE:
      return {
        ...state,
        previousSearchQuery: action.searchQuery,
        searchConfig: action.searchConfig,
        isLoading: true,
      };

    case types.GET_NEXT_PAGE:
      return { ...state, previousSearchQuery: action.searchQuery };

    case types.SET_TYPE:
      // watch the search SET_TYPE in consuming reducer to get new values
      return {
        ...state,
        searchResults: action.items,
        totalSearchResults: action.totalItems,
        searchTerm: action.searchTerm,
        contactSearchTerm: action.contactSearchTerm,
        isLoading: false,
      };

    case types.ADD_TYPE:
      return {
        ...state,
        searchResults: state.searchResults.concat(action.results),
      };

    case types.SEARCH_FAILURE_TYPE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default searchReducer;
