import { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { Theme } from '../models/Theme';
import ControlsPreview from './ControlsPreview/ControlsPreview';
import EmailPreview from './EmailPreview/EmailPreview';

import * as styles from './Preview.module.scss';

enum Views {
  Controls = 'Controls',
  Email = 'Email',
}

export interface PreviewProps {
  selectedTheme: Theme;
}

export interface PreviewState {
  selectedView: Views;
}

class Preview extends Component<PreviewProps, PreviewState> {
  public readonly state = {
    selectedView: Views.Controls,
  };

  public onSelectView = (selectedView: Views) => {
    this.setState({
      selectedView,
    });
  };

  public render() {
    return (
      <Fragment>
        <div className={styles.titleBar}>
          <div className={styles.title}>
            <FormattedMessage id="settings.brandingTheming.preview.previewTitle" defaultMessage="Preview" />
          </div>
          {/* Disabled until Email Service is completed
          <div className={styles.previewToggle}>
            <div
              className={classNames(styles.previewToggleItem, {
                [styles.selected]: this.state.selectedView === Views.Controls
              })}
              onClick={() => this.onSelectView(Views.Controls)} // tslint:disable-line jsx-no-lambda
            >
              Sample Controls
            </div>
            <div
              className={classNames(styles.previewToggleItem, {
                [styles.selected]: this.state.selectedView === Views.Email
              })}
              data-view={Views.Email}
              onClick={() => this.onSelectView(Views.Email)} // tslint:disable-line jsx-no-lambda
            >
              Email
            </div>
          </div>
            */}
        </div>
        <div className={styles.previewBoxOuter}>
          {this.state.selectedView === Views.Controls && <ControlsPreview selectedTheme={this.props.selectedTheme} />}
          {this.state.selectedView === Views.Email && <EmailPreview selectedTheme={this.props.selectedTheme} />}
        </div>
        <div className={styles.helpText}>
          <FormattedMessage
            id="settings.brandingTheming.preview.previewDescription"
            defaultMessage="This is how your custom theming will look in use. Make sure everything is readable!"
          />
        </div>
      </Fragment>
    );
  }
}

export default Preview;
