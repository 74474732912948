import './OCEANDetailsComponent.scss';
import last from 'lodash/last';
import { Redirect } from 'react-router-dom';
import { Row, StickyNav } from 'ui-components';
import ShipmentHeaderContainer from 'components/newShipmentDetails/ShipmentHeader/ShipmentHeaderContainer';
import { ShipmentModeEnum } from 'models';
import { oceanOnModeAgnosticPage } from 'common/authorizations';
import { getShipmentUrlFromMasterShipmentId } from 'components/shipmentListComponent/utils/mapShipmentUtils';
import Status from '../common/Status/Status';
import CarrierDetail from '../common/CarrierDetail/CarrierDetail';
import Route from '../common/Route/Route';
import { withTheme } from '../../../contexts/ThemeContext';
import PageNotFound from '../../common/PageNotFound/PageNotFound';
import MapRoute from '../../common/mapRoute/MapRoute';
import ErrorBoundary from '../../common/errorBoundary/ErrorBoundary';

const menuItems = [
  {
    label: 'Status',
    icon: 'pin_drop',
    link: 'status',
  },
  {
    label: 'Route Details',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <defs>
          <path
            id="a"
            d="M6 15.874v3.394a2 2 0 1 1-2 0v-3.394a4.002 4.002 0 0 1 0-7.748V4.732a2 2 0 1 1 2 0v3.394a4.002 4.002 0 0 1 0 7.748zM9 2h12v2H9V2zm0 18h12v2H9v-2zm2-9h12v2H11v-2z"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path fill="#FFF" fillOpacity="0" d="M1 1h21v21H1z" />
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#000" xlinkHref="#a" />
          <g fill="#575451" mask="url(#b)">
            <path d="M0 0h24v24H0z" />
          </g>
        </g>
      </svg>
    ),
    link: 'route',
  },
];

const OCEANDetailsComponent = (props) => {
  if (props.shipmentDetailsMissing) {
    return <PageNotFound />;
  }

  if (oceanOnModeAgnosticPage() && props.shipmentDetails) {
    const modeAgnosticUrl = getShipmentUrlFromMasterShipmentId(props.shipmentDetails.masterShipmentId);
    return <Redirect to={modeAgnosticUrl} />;
  }

  if (props.shipmentDetails) {
    return (
      <div className="shipment-details-ocean animated fadeIn">
        <StickyNav menuItems={menuItems} mode="follow" theme={props.theme} />
        <ShipmentHeaderContainer
          shimentTenantId={props.shipmentDetails.tenantId}
          identifiers={[props.shipmentDetails.identifiers]}
          shipmentId={props.match.params.shipmentDetailId}
          authorizations={props.authorizations}
          isLoggedIn={props.isLoggedIn}
          mode={ShipmentModeEnum.OCEAN}
          shipmentShareToken={props.match.params.shipmentShareToken}
          entitlementInfo={props.shipmentDetails.entitlementInfo}
          showEmissions={false}
        />
        <section id="status" className="shipment-details section-content container">
          <Row type="flex" className="shipment-details-content">
            <ErrorBoundary message={'An error occurred rendering the shipment details'}>
              <Status
                shipmentStatus={props.shipmentDetails.status}
                entitlementInfo={props.shipmentDetails.entitlementInfo}
              />
              {renderCarrierDetail(props)}
            </ErrorBoundary>
          </Row>
        </section>
        <section id="route" className="shipment-details section-content container">
          <ErrorBoundary message={'An error occurred rendering the route details'}>
            <Route
              mode={props.shipmentDetails.mode}
              routeDetails={props.shipmentDetails.route.routeDetails}
              markerColor={props.shipmentDetails.status.flag.color}
              statusStops={props.shipmentDetails.status.stops}
              showExpanded={props.showExpanded}
              toggleRouteDetailsDisplay={props.toggleRouteDetailsDisplay}
              routeSummary={props.shipmentDetails.route.routeSummary}
              toggleGroupedUpdates={props.toggleGroupedUpdates}
            />
          </ErrorBoundary>
        </section>

        {renderMap(props.shipmentDetails.locations)}
      </div>
    );
  } else {
    return null;
  }
};

const renderMap = (locations) => {
  if (locations != null && locations.length > 0) {
    return (
      <section id="routeMap" className="shipment-details section-content container">
        <MapRoute locations={locations} calculateCenter={last} />
      </section>
    );
  }
};

const renderCarrierDetail = (props) => {
  if (props.isLoggedIn) {
    return <CarrierDetail carrierDetail={props.shipmentDetails && props.shipmentDetails.carrier} mode="OCEAN" />;
  }
};

export default withTheme(OCEANDetailsComponent);
