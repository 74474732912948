import { FormattedMessage, defineMessages, createIntl, createIntlCache } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './ShipmentCounts.scss';
import { Responsive, WidthProvider } from 'react-grid-layout';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { shipmentListFiltersUrlEnabled } from 'common/authorizations';
import { trackEvent } from 'common/eventTracker';
import { getNextYPosition } from 'components/QuickViewCardConfig/TitleAndSettingsStep/utils';
import { locale, messages } from '../../i18n/configurei18n';

import Widget from '../common/widget/Widget';
import Progress from '../common/progress/Progress';
import types from './ducks/types';
import { SNACKBAR_AUTOHIDE_DURATION } from '../../common/AppConstants';
import CustomButton from '../../themes/CustomButton';
import { withTheme } from '../../contexts/ThemeContext';
import ShipmentSubHeader from '../common/ShipmentSubHeader/ShipmentSubHeader';
import CarrierCard from '../../components/carrierView/CarrierCard';
import FilterUtil from '../../components/filter/util/filterUtil';
import { QuickviewEntityType } from '../QuickViewCardConfig/constants';
import endpoints from '../../common/endpoints';
import { generateMinimalQueryURL } from '../QuickViewCardConfig/utils';
import { isTargetUrlLastInHistory } from '../../common/urlUtils';

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const StyledSpinner = styled('div')`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  opacity: 0.5;

  .ant-spin-dot {
    margin-bottom: 84px;
  }
`;

const cache = createIntlCache();
export const intl = createIntl({ locale, messages }, cache);

const message = defineMessages({
  shipmentsView: {
    id: 'shipmentCounts.shipmentsView',
    defaultMessage: 'View',
  },
  viewAll: {
    id: 'shipmentCounts.viewAll',
    defaultMessage: 'View All',
  },
  resizeText: {
    id: 'shipmentCounts.resizeText',
    defaultMessage: 'RESIZE',
  },
  shipmentsCount: {
    id: 'shipmentCounts.shipmentsCount',
    defaultMessage: 'Shipments',
  },
  // TODO handle all messages/i18n in a batch in punchlist ticket to avoid isolated messages being missed
  ordersCount: {
    id: 'shipmentCounts.orderCount',
    defaultMessage: 'Orders',
  },
  inventoryCount: {
    id: 'shipmentCounts.inventoryCount',
    defaultMessage: 'Inventory',
  },
  shipmentsToday: {
    id: 'shipmentCounts.shipmentsToday',
    defaultMessage: 'shipments are happening today',
  },
});

const ResponsiveGridLayout = WidthProvider(Responsive);

const StyledShipmentCountsContainer = styled.div`
  &.editing {
    .react-resizable-handle {
      border-color: transparent transparent ${(props) => props.theme.primaryColor} transparent;

      &:after {
        content: '' !important;
      }
    }
    .react-grid-item.react-grid-placeholder {
      border: 4px dotted ${(props) => props.theme.primaryColor};
    }
  }
  position: relative;
`;

export const getWidgetEntityType = (tile) => {
  if (tile.orderFilter) return QuickviewEntityType.ORDERS;
  if (tile.inventoryFilter) return QuickviewEntityType.INVENTORY;
  return QuickviewEntityType.SHIPMENTS;
};

/**
 * ShipmentCounts component
 */
const ShipmentCounts = (props) => {
  const hasCarrierView = props.authorizations.hasTenantCarrierRole(props.principal);
  const quickViewItems = get(props, 'quickViewConfig.config', []);
  const isLoading = get(props, 'quickViewConfig.loading');
  const layouts = get(props, 'quickViewConfig.layouts');
  const isQuickViewItemsEmpty = isEmpty(quickViewItems);
  const history = useHistory();
  const isQuickviewV2Enabled = props.authorizations.quickviewV2Enabled();
  const breakpoints = { lg: 960, md: 768, sm: 576, xs: 360 };
  const cols = { lg: 6, md: 4, sm: 1, xs: 1 };
  const onLayoutChange = (layout, layouts) => props.setUpdatedLayout(layout, layouts);
  const margin = isQuickviewV2Enabled ? [24, 24] : [7, 12];
  const rowHeight = isQuickviewV2Enabled ? 280 : 181;
  const onEdit = (tile, index) => {
    trackEvent('QV_EDIT_CARD');
    if (isQuickviewV2Enabled) {
      const widgetEntityType = getWidgetEntityType(tile);
      history.push(`/configure-quickview/subtotal/${tile.id}?entityType=${widgetEntityType}`);
      props.openQuickViewDialog('Edit', tile, isQuickviewV2Enabled, index);
    } else {
      props.openQuickViewDialog('Edit', tile, isQuickviewV2Enabled, index);
    }
  };

  const onDelete = (index) => {
    trackEvent('QV_DELETE_CARD');
    props.deleteQuickViewTile(index);
  };

  const onDuplicate = (tile) => {
    const nextYPosition = getNextYPosition(quickViewItems);
    const { id, version, ...restTile } = tile;

    const data = [
      ...quickViewItems,
      {
        ...restTile,
        title: `${tile.title} 2`,
        xPosition: 0,
        yPosition: nextYPosition,
      },
    ];
    trackEvent('QV_DUPLICATE_CARD');
    props.quickViewDuplicate(data);
  };

  return (
    <StyledShipmentCountsContainer
      theme={props.theme}
      className={props.editing ? 'shipment-counts-container editing' : 'shipment-counts-container'}
      data-locator="quick-view-grid"
    >
      <div className={props.editing ? 'shipment-counts-overlay d-block' : 'd-none'} />
      {props.happeningTodayCountsLoading && (
        <StyledSpinner>
          <Spin size="large" spinning={props.happeningTodayCountsLoading} indicator={antIcon} />
        </StyledSpinner>
      )}
      <ShipmentSubHeader {...props} isQuickviewV2Enabled={isQuickviewV2Enabled} />
      {hasCarrierView ? (
        <CarrierCard
          principal={props.principal}
          executeWidgetLinkClickFn={props.executeWidgetLinkClickFn}
          authorities={props.authorities}
        />
      ) : null}
      {!isQuickViewItemsEmpty && (
        <div className="container">
          <ResponsiveGridLayout
            className="layout"
            layouts={layouts}
            breakpoints={breakpoints}
            margin={margin}
            containerPadding={[0, 0]}
            isResizable={props.isDraggable}
            isDraggable={props.isDraggable}
            onLayoutChange={onLayoutChange}
            cols={cols}
            rowHeight={rowHeight}
            compactType="horizontal"
          >
            {quickViewItems.map((tile, index) => {
              let widgetFilter;
              let widgetCount;
              let widgetTotalCount;
              let widgetHeadingMessage;
              let widgetEntityType;

              // this code assumes data integrity rules have been maintained and we can infer entity type from
              // what fields are null/non-null
              if (tile.orderFilter) {
                widgetFilter = tile.orderFilter;
                widgetCount = tile.orders;
                widgetTotalCount = tile.totalOrders;
                widgetHeadingMessage = message.ordersCount;
                widgetEntityType = QuickviewEntityType.ORDERS;
              } else if (tile.inventoryFilter) {
                widgetFilter = tile.inventoryFilter;
                widgetCount = tile.inventory;
                widgetTotalCount = tile.totalInventory;
                widgetHeadingMessage = message.inventoryCount;
                widgetEntityType = QuickviewEntityType.INVENTORY;
              } else {
                widgetFilter = tile.filter;
                widgetCount = tile.shipments;
                widgetTotalCount = tile.totalShipments;
                widgetHeadingMessage = message.shipmentsCount;
                widgetEntityType = QuickviewEntityType.SHIPMENTS;
              }

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={isQuickviewV2Enabled ? '' : 'shipment-counts-widget'} key={index}>
                  {props.editing && !isQuickviewV2Enabled && (
                    <CustomButton
                      classes="basic small edit-quickview teal"
                      clickFn={() => onEdit(tile, index)}
                      data-locator="edit-quick-view"
                    >
                      <FormattedMessage id="shipmentCounts.editButton" defaultMessage="Edit" />{' '}
                      <i className="material-icons right">edit</i>
                    </CustomButton>
                  )}
                  <Widget
                    onEdit={() => onEdit(tile, index)}
                    isQuickviewV2Enabled={isQuickviewV2Enabled}
                    icon={types.ICON_MAP[tile.icon]}
                    filter={widgetFilter}
                    headingCount={widgetCount}
                    totalCount={widgetTotalCount}
                    healthScoreConfig={tile.healthScoreConfig}
                    subHeading={tile.title}
                    numberFormat={tile.numberFormat}
                    searchLinkLabel={props.editing ? '' : intl.formatMessage(message.shipmentsView)}
                    heading={intl.formatMessage(widgetHeadingMessage)}
                    searchLink={tile.searchKeywords}
                    editing={props.editing}
                    deleteQuickViewTile={() => onDelete(index)}
                    onDuplicate={() => onDuplicate(tile)}
                    onSavedSearchClick={() => {
                      // TODO this callback is getting called twice which will throw off analytics and causes
                      //  undesirable back button behavior
                      trackEvent('QV_CLICK_CARD', {
                        entityType: widgetEntityType,
                      });
                      // eslint-disable-line react/jsx-no-bind
                      const authorizedModes = props.authorizations.getAuthorizedModes();
                      const mode = authorizedModes.length === 1 ? authorizedModes[0] : tile.mode;

                      if (!props.editing && !shipmentListFiltersUrlEnabled()) {
                        props.executeWidgetLinkClickFn(
                          props.authorities,
                          widgetFilter,
                          mode,
                          tile.searchKeywords,
                          widgetEntityType
                        );
                      }
                      if (!props.editing && shipmentListFiltersUrlEnabled()) {
                        if (widgetEntityType === QuickviewEntityType.ORDERS) {
                          // generate minimal URL
                          const queryObject = {
                            ...tile.orderFilter,
                            searchText: tile.searchKeywords,
                          };
                          const query = generateMinimalQueryURL(queryObject);
                          const orderURL = `${endpoints.ORDERSLIST}${query}`;
                          history.push(orderURL);
                        } else if (widgetEntityType === QuickviewEntityType.INVENTORY) {
                          // generate minimal URL
                          const queryObject = {
                            ...tile.inventoryFilter,
                            searchText: tile.searchKeywords,
                          };
                          const query = generateMinimalQueryURL(queryObject);
                          const inventoryURL = `${endpoints.INVENTORYITEMSLIST}${query}`;
                          history.push(inventoryURL);
                        } else {
                          window.sessionStorage.setItem('isShipmentInProgress', false);
                          const queryURL = FilterUtil.buildQueryURl(tile.filter, [], [mode], tile.searchKeywords);
                          if (queryURL) {
                            const targetUrl = `/shipment-list${queryURL}`;
                            isTargetUrlLastInHistory(history, targetUrl)
                              ? history.replace(targetUrl)
                              : history.push(targetUrl);
                          }
                        }
                      }
                    }}
                  />
                </div>
              );
            })}
          </ResponsiveGridLayout>
        </div>
      )}
      {isQuickViewItemsEmpty && (
        <div className="no-results-message">
          {isLoading ? (
            <Progress isLoading={isLoading} />
          ) : (
            <>
              <h4>
                <FormattedMessage id="shipmentCounts.noResultsMessage" defaultMessage="There are no Quick Views." />
              </h4>
              <i className="material-icons">settings</i>
              <p className="medium-text">
                <FormattedMessage
                  id="shipmentCounts.addQuickViewMessage"
                  defaultMessage="Please click the “Add Quick View” button above."
                />
              </p>
            </>
          )}
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.snackbarOpen}
        onClose={props.closeSnackbar}
        autoHideDuration={SNACKBAR_AUTOHIDE_DURATION}
        className="snackbar-success snackbar-container"
        action={[
          <IconButton key="close" aria-label="Close" onClick={props.closeSnackbar}>
            <i className="material-icons">close</i>
          </IconButton>,
        ]}
        message={
          <span className="snack-message">
            <i className="material-icons">thumb_up</i>
            <FormattedMessage
              id="shipmentCounts.savedMessage"
              defaultMessage="Quick view configuration successfully saved."
            />
          </span>
        }
      />
    </StyledShipmentCountsContainer>
  );
};

export default withTheme(ShipmentCounts);
