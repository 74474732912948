import { ShipmentModeEnum } from 'models';

export interface AccessLevelObject {
  shipmentId?: string;
  masterShipmentId?: string;
  mode: ShipmentModeEnum;
  accessLevel: NotificationAccessLevelEnum;
}

export enum NotificationAccessLevelEnum {
  SHIPMENT = 'SHIPMENT',
  TENANT = 'TENANT',
}

/** EventTypeKeyEnums correspond to RuleType in portal-v2-service */
export enum TLEventTypeKeyEnum {
  RUNNING_LATE = 'RUNNING_LATE',
  DWELLING = 'DWELLING',
  IDLE_IN_TRANSIT = 'IDLE_IN_TRANSIT',
  TRACKING_APPROVAL_DENIED = 'TRACKING_APPROVAL_DENIED',
  TRACKING_APPROVAL_PENDING = 'TRACKING_APPROVAL_PENDING',
  ARRIVING = 'ARRIVING',
  DEPARTED = 'DEPARTED',
  TEMPERATURE_OUT_OF_RANGE = 'TEMPERATURE_OUT_OF_RANGE',
  ALL_UPDATES = 'ALL_UPDATES',
}

export enum LTLEventTypeKeyEnum {
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  OUT_FOR_DELIVERY_MISSED = 'OUT_FOR_DELIVERY_MISSED',
}

export enum OceanEventTypeKeyEnum {
  OCEAN_ARRIVAL_RUNNING_LATE = 'OCEAN_ARRIVAL_RUNNING_LATE',
  OCEAN_DEPARTURE_RUNNING_LATE = 'OCEAN_DEPARTURE_RUNNING_LATE',
}

export enum ParcelEventTypeKeyEnum {
  PARCEL_OUT_FOR_DELIVERY = 'PARCEL_OUT_FOR_DELIVERY',
  PARCEL_MISSED_DELIVERY_WINDOW = 'PARCEL_MISSED_DELIVERY_WINDOW',
  PARCEL_RUNNING_LATE = 'PARCEL_RUNNING_LATE',
}

export interface ShipmentNotificationDto {
  ruleGroup: {
    id?: string;
    name: string; // notification name
    filters: {
      // filters will either include MSID or shipmentId and mode
      shipmentId?: number;
      masterShipmentId?: string;
      mode?: ShipmentModeEnum;
    };
    rules: Rule[];
  };
  subscriptions: Subscription[];
}

export enum NotificationStopTypeEnum {
  PICKUP = 'PICKUP',
  INTERMEDIATE = 'INTERMEDIATE',
  DELIVERY = 'DELIVERY',
  PORT_OF_LOADING = 'PORT_OF_LOADING',
  TRANSSHIPMENT_PORT = 'TRANSSHIPMENT_PORT',
  PORT_OF_DISCHARGE = 'PORT_OF_DISCHARGE',
}

export type EventTypeStopLocations = {
  type: string;
  display: string;
};

export type EventTypeStatusCode = {
  type: string;
  display: string;
};

interface Rule {
  ruleConfiguration: RuleConfiguration;
  ruleId?: string;
  ruleType: string;
  version?: number;
}

interface RuleConfiguration {
  stopType: NotificationStopTypeEnum;
  offsetDirection?: 'BEFORE' | 'AFTER';
  minutesOffset?: number;
  hoursLate?: number;
  minutesLate?: number;
  minutesIdle?: number;
  minutesDwelling?: number;
  minutesUntilArrival?: number;
  minutesTemperatureOutOfRange?: number;
  minutesBeforeAppointmentWindow?: number;
}

interface Subscription {
  type: 'EMAIL' | 'PUSH';
  recipientParameters: {
    email?: string;
    pushConfigId?: number;
  };
}
