import { Dispatch, SetStateAction } from 'react';
import { inventoryIdentifiersTypeList } from 'components/inventory-v2/InventoryItemsList';
import { PaginationInfo } from 'models/Page';

/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.21.588 on 2020-03-31 06:57:21.

export interface InventorySearchResponseDto {
  pagination?: PaginationInfo;
  inventoryItems?: InventoryItem[];
}

/**
 * Inventory Item model that contains attribute describe different types of inventory items such as id, SKU (stock keeping unit), description, UPC (universal product code), cost/retail values, quantity, dim-weight (dimensional Weight), promotional windows (seasonal items), manufactured/packaged date-time, sellBy/consumedBy date-time, perishable/taxable flags, Hazardous material details, shipment/order list, etc.
 */
export interface InventoryItem {
  id?: string;
  stockKeepingUnit?: string;
  description?: string;
  universalProductCode?: string;
  perUnitCost?: MonetaryValue;
  perUnitRetailValue?: MonetaryValue;
  inventoryDimensionalWeight?: DimensionalWeight;
  inventoryIdentifiers?: InventoryIdentifier[];
  promotionalDateTimeWindow?: OffsetDateTimeWindow;
  manufacturedDateTime?: Date;
  packagedDateTime?: Date;
  sellByDateTime?: Date;
  consumeByDateTime?: Date;
  perishable?: boolean;
  taxable?: boolean;
  lineItemHazmatDetail?: LineItemHazmatDetail;
  shipmentIds?: string[];
  orderIds?: string[];
}

/**
 * A monetary value expressing amount and currency type.
 */
export interface MonetaryValue {
  currency?: Currency;
  amount?: number;
}

/**
 * ${DimensionalWeight.apiModel.class.value}
 */
export interface DimensionalWeight {
  quantity?: number;
  packageType?: PackageType;
  cubicDimension?: CubicDimension;
  weight?: Weight;
}

export interface InventoryIdentifier {
  type?: InventoryIdentifierType;
  value?: string;
  description?: string;
}

/**
 * A date and time range, with time zone offset.
 */
export interface OffsetDateTimeWindow {
  startDateTime?: Date;
  endDateTime?: Date;
}

/**
 * Provides important information about the hazardous materials to be transported, as required by the US Department of Transportation (DOT). Only required for shipment requests.
 */
export interface LineItemHazmatDetail {
  identificationNumber?: string;
  properShippingName?: string;
  hazardClass?: string;
  packingGroup?: HazmatPackingGroup;
}

/**
 * A cubic dimension.
 */
export interface CubicDimension {
  length?: number;
  width?: number;
  height?: number;
}

export interface Weight {
  weight?: number;
  weightUnit?: WeightUnit;
}

export interface InventoryOrderStatusCheckListFilterInput {
  dataKey: string;
  checkedValues: string[];
  setValuesFn: Dispatch<SetStateAction<string[]>>;
}

export interface InventoryOrderStatusCheckListOption {
  id: string;
  labelText: string;
  checked: boolean;
}

export interface InventoryOrderShipmentAssociatedFilterInput {
  dataKey: string;
  selectedValue: any;
  setValueFn: Dispatch<SetStateAction<string>>;
  shipmentStatusList: any[];
  setOrderHealthCriteria: any;
  orderHealthCriteria: any;
}

export enum Currency {
  USD = 'USD',
  CAD = 'CAD',
  MXN = 'MXN',
  EUR = 'EUR',
}

export enum PackageType {
  BAG = 'BAG',
  BALE = 'BALE',
  BOX = 'BOX',
  BUCKET = 'BUCKET',
  BUNDLE = 'BUNDLE',
  CAN = 'CAN',
  CARTON = 'CARTON',
  CASE = 'CASE',
  COIL = 'COIL',
  CRATE = 'CRATE',
  CYLINDER = 'CYLINDER',
  DRUM = 'DRUM',
  PAIL = 'PAIL',
  PLT = 'PLT',
  PIECES = 'PIECES',
  REEL = 'REEL',
  ROLL = 'ROLL',
  SKID = 'SKID',
  TOTE = 'TOTE',
  TUBE = 'TUBE',
}

export const PackageTypeIntlKeys: {
  [P in PackageType]: { id: string; defaultMessage: string };
} = {
  [PackageType.BAG]: { id: 'inventory.packageTypes.bag', defaultMessage: 'Bag' },
  [PackageType.BALE]: { id: 'inventory.packageTypes.bale', defaultMessage: 'Bale' },
  [PackageType.BOX]: { id: 'inventory.packageTypes.box', defaultMessage: 'Box' },
  [PackageType.BUCKET]: { id: 'inventory.packageTypes.bucket', defaultMessage: 'Bucket' },
  [PackageType.BUNDLE]: { id: 'inventory.packageTypes.bundle', defaultMessage: 'bundle' },
  [PackageType.CAN]: { id: 'inventory.packageTypes.can', defaultMessage: 'can' },
  [PackageType.CARTON]: { id: 'inventory.packageTypes.carton', defaultMessage: 'carton' },
  [PackageType.CASE]: { id: 'inventory.packageTypes.case', defaultMessage: 'case' },
  [PackageType.COIL]: { id: 'inventory.packageTypes.coil', defaultMessage: 'coil' },
  [PackageType.CRATE]: { id: 'inventory.packageTypes.crate', defaultMessage: 'crate' },
  [PackageType.CYLINDER]: { id: 'inventory.packageTypes.cylinder', defaultMessage: 'cylinder' },
  [PackageType.DRUM]: { id: 'inventory.packageTypes.drum', defaultMessage: 'drum' },
  [PackageType.PAIL]: { id: 'inventory.packageTypes.pail', defaultMessage: 'pail' },
  [PackageType.PLT]: { id: 'inventory.packageTypes.pallet', defaultMessage: 'pallet' },
  [PackageType.PIECES]: { id: 'inventory.packageTypes.pieces', defaultMessage: 'pieces' },
  [PackageType.REEL]: { id: 'inventory.packageTypes.reel', defaultMessage: 'reel' },
  [PackageType.ROLL]: { id: 'inventory.packageTypes.roll', defaultMessage: 'roll' },
  [PackageType.SKID]: { id: 'inventory.packageTypes.skid', defaultMessage: 'skid' },
  [PackageType.TOTE]: { id: 'inventory.packageTypes.tote', defaultMessage: 'tote' },
  [PackageType.TUBE]: { id: 'inventory.packageTypes.tube', defaultMessage: 'tube' },
};

export const PackageTypePluralIntlKeys: {
  [P in PackageType]: { id: string; defaultMessage: string };
} = {
  [PackageType.BAG]: { id: 'inventory.packageTypes.bags', defaultMessage: 'Bags' },
  [PackageType.BALE]: { id: 'inventory.packageTypes.bales', defaultMessage: 'Bales' },
  [PackageType.BOX]: { id: 'inventory.packageTypes.boxes', defaultMessage: 'Boxes' },
  [PackageType.BUCKET]: { id: 'inventory.packageTypes.buckets', defaultMessage: 'Buckets' },
  [PackageType.BUNDLE]: { id: 'inventory.packageTypes.bundles', defaultMessage: 'bundles' },
  [PackageType.CAN]: { id: 'inventory.packageTypes.cans', defaultMessage: 'cans' },
  [PackageType.CARTON]: { id: 'inventory.packageTypes.cartons', defaultMessage: 'cartons' },
  [PackageType.CASE]: { id: 'inventory.packageTypes.cases', defaultMessage: 'cases' },
  [PackageType.COIL]: { id: 'inventory.packageTypes.coils', defaultMessage: 'coils' },
  [PackageType.CRATE]: { id: 'inventory.packageTypes.crates', defaultMessage: 'crates' },
  [PackageType.CYLINDER]: { id: 'inventory.packageTypes.cylinders', defaultMessage: 'cylinders' },
  [PackageType.DRUM]: { id: 'inventory.packageTypes.drums', defaultMessage: 'drums' },
  [PackageType.PAIL]: { id: 'inventory.packageTypes.pails', defaultMessage: 'pails' },
  [PackageType.PLT]: { id: 'inventory.packageTypes.pallets', defaultMessage: 'pallets' },
  [PackageType.PIECES]: { id: 'inventory.packageTypes.pieces', defaultMessage: 'pieces' },
  [PackageType.REEL]: { id: 'inventory.packageTypes.reels', defaultMessage: 'reels' },
  [PackageType.ROLL]: { id: 'inventory.packageTypes.rolls', defaultMessage: 'rolls' },
  [PackageType.SKID]: { id: 'inventory.packageTypes.skids', defaultMessage: 'skids' },
  [PackageType.TOTE]: { id: 'inventory.packageTypes.totes', defaultMessage: 'totes' },
  [PackageType.TUBE]: { id: 'inventory.packageTypes.tubes', defaultMessage: 'tubes' },
};

export enum InventoryIdentifierType {
  DEPARTMENT = 'DEPARTMENT',
  CATEGORY = 'CATEGORY',
  GROUP = 'GROUP',
  FAMILY = 'FAMILY',
  VENDOR = 'VENDOR',
  MANUFACTURER = 'MANUFACTURER',
  PROMOTION = 'PROMOTION',
}

export const InventoryIdentifierTypeIntlKeys: {
  [P in InventoryIdentifierType]: { id: string; defaultMessage: string };
} = {
  [InventoryIdentifierType.DEPARTMENT]: { id: 'inventory.idTypes.department', defaultMessage: 'Department' },
  [InventoryIdentifierType.CATEGORY]: { id: 'inventory.idTypes.category', defaultMessage: 'Category' },
  [InventoryIdentifierType.GROUP]: { id: 'inventory.idTypes.group', defaultMessage: 'Group' },
  [InventoryIdentifierType.FAMILY]: { id: 'inventory.idTypes.family', defaultMessage: 'Family' },
  [InventoryIdentifierType.VENDOR]: { id: 'inventory.idTypes.vendor', defaultMessage: 'Vendor' },
  [InventoryIdentifierType.MANUFACTURER]: {
    id: 'inventory.idTypes.manufacturer',
    defaultMessage: 'Manufacturer',
  },
  [InventoryIdentifierType.PROMOTION]: { id: 'inventory.idTypes.promotion', defaultMessage: 'Promotion' },
};

export const intlIventoryKeys = (inventoryIdentifiersTypeList || []).reduce((acc, intlItem) => {
  return {
    ...acc,
    [intlItem.value]: intlItem.message,
  };
}, {});

export enum InventoryOrderStatusCode {
  SUBMITTED = 'SUBMITTED',
  RECEIVED = 'RECEIVED',
  PROCESSING = 'PROCESSING',
  ACCEPTED = 'ACCEPTED',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
}

export const InventoryOrderStatusCodeIntlKeys: {
  [P in InventoryOrderStatusCode]: { id: string; defaultMessage: string };
} = {
  [InventoryOrderStatusCode.SUBMITTED]: { id: 'inventory.orderStatusCodes.submitted', defaultMessage: 'Submitted' },
  [InventoryOrderStatusCode.RECEIVED]: { id: 'inventory.orderStatusCodes.received', defaultMessage: 'Received' },
  [InventoryOrderStatusCode.PROCESSING]: { id: 'inventory.orderStatusCodes.processing', defaultMessage: 'Processing' },
  [InventoryOrderStatusCode.ACCEPTED]: { id: 'inventory.orderStatusCodes.accepted', defaultMessage: 'Accepted' },
  [InventoryOrderStatusCode.FULFILLED]: { id: 'inventory.orderStatusCodes.fulfilled', defaultMessage: 'Fulfilled' },
  [InventoryOrderStatusCode.CANCELED]: { id: 'inventory.orderStatusCodes.canceled', defaultMessage: 'Canceled' },
  [InventoryOrderStatusCode.REJECTED]: {
    id: 'inventory.orderStatusCodes.rejected',
    defaultMessage: 'Rejected',
  },
};

export enum HazmatPackingGroup {
  I = 'I',
  II = 'II',
  III = 'III',
  NONE = 'NONE',
}

export enum WeightUnit {
  LB = 'LB',
  KG = 'KG',
}
