import cn from 'classnames';
import * as styles from './styles.module.scss';

const Label = ({ className, children, ...props }: React.LabelHTMLAttributes<HTMLLabelElement>) => {
  return (
    <label className={cn('text-uppercase font-weight-bold d-block', styles['label'], className)} {...props}>
      {children}
    </label>
  );
};

export default Label;
