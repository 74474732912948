import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { InfoCircleFilled } from '@ant-design/icons';
import { SubHeader, Button, Tooltip } from 'ui-components';
import Chat from 'components/chat/Chat';
import ChatProvider from 'components/chat/ChatProvider';
import { ReactComponent as Co2Icon } from 'components/common/assets/co2.svg';
import endpoints from 'common/endpoints';
import { ShipmentModeEnum } from 'models';
import { primaryGreyForty } from 'styles/colors';
import { trackEvent } from 'common/eventTracker';
import NotesButtonContainer from '../../notes/NotesContainer';
import ShipmentShareButtonContainer from '../../shipmentShare/ShipmentShareContainer';
import { ShipmentHeaderProps } from './ShipmentHeaderContainer';
import ShipmentIdentifiersContainer from '../ShipmentIdentifiers/ShipmentIdentifiersContainer';

import * as styles from './ShipmentHeader.module.scss';

class ShipmentHeader extends Component<ShipmentHeaderProps> {
  componentDidMount() {
    if (!!this.props.entitlementInfo?.grantor && this.props?.principal) {
      trackEvent('SHARED_SHIPMENT_VIEWED', {
        COMPANY_NAME: this.props?.principal?.companyName,
        TENANT_ID: this.props?.principal?.tenantId,
        TENANT_ROLE: this.props?.principal?.tenantCarrierNetworkRoles[0],
        USER_FIRST_NAME: this.props?.principal?.firstName,
        USER_LAST_NAME: this.props?.principal?.lastName,
        USER_FULL_NAME: `${this.props?.principal?.firstName} ${this.props?.principal?.lastName}`,
        SHIPMENT_ID: this.props?.shipmentId || 'NO_SHIPMENT_ID',
        GRANTED_BY: this.props.entitlementInfo?.grantor?.name || 'NO_GRANTOR',
        GRANTED_BY_TENANT: this.props.entitlementInfo?.grantor?.tenantId?.toLocaleString() || 'NO_GRANTOR_TENANT',
      });
    }
  }
  shipmentIdentifiersContainerRef?: any;

  saveShipmentIdentifiers = () => {
    if (this.shipmentIdentifiersContainerRef) {
      this.shipmentIdentifiersContainerRef.saveShipmentTenantAttributes();
    }
  };

  updateShipmentIdentifiersContainerRef = (ref: any) => (this.shipmentIdentifiersContainerRef = ref);

  getShipmentNotificationsLinkUrl = () => {
    return this.props.masterShipmentId
      ? `${endpoints.NOTIFICATIONS}?masterShipmentId=${this.props.masterShipmentId}&mode=${this.props.mode}`
      : `${endpoints.NOTIFICATIONS}?shipmentId=${this.props.shipmentId}&mode=${this.props.mode}`;
  };

  doesModeSupportEdit = () => {
    const supportedEditModes = [ShipmentModeEnum.LTL, ShipmentModeEnum.TL];
    return supportedEditModes.indexOf(this.props.mode) > -1;
  };

  doesModeSupportAlerts = () => {
    const supportedAlertModes = [
      ShipmentModeEnum.LTL,
      ShipmentModeEnum.TL,
      ShipmentModeEnum.PARCEL,
      ShipmentModeEnum.AIR,
      ShipmentModeEnum.OCEAN,
    ];
    return supportedAlertModes.indexOf(this.props.mode) > -1;
  };

  render() {
    const isPublicPage: boolean = this.props.shipmentShareToken !== undefined;
    const isSharee: boolean | undefined = !!this.props.entitlementInfo?.grantor;
    const shouldShowEditShipmentButton: boolean =
      !isSharee &&
      !this.props.shipmentTenantAttributesEditMode &&
      !isPublicPage &&
      this.doesModeSupportEdit() &&
      !this.props.authorizations.hasTenantCarrierRole(this.props.principal);

    const shouldShowCancelButton: boolean =
      !isSharee &&
      this.props.shipmentTenantAttributesEditMode &&
      !this.props.authorizations.hasTenantCarrierRole(this.props.principal);

    const shouldShowSaveButton: boolean =
      !isSharee &&
      this.props.shipmentTenantAttributesEditMode &&
      !this.props.authorizations.hasTenantCarrierRole(this.props.principal);

    const shouldShowAlertsButton: boolean =
      !this.props.shipmentTenantAttributesEditMode &&
      this.props.isLoggedIn &&
      !isPublicPage &&
      this.doesModeSupportAlerts() &&
      this.props.authorizations.hasShipmentLevelNotifications();

    const shouldShowShareButton: boolean =
      !isSharee && !this.props.shipmentTenantAttributesEditMode && this.props.isLoggedIn && !isPublicPage;

    const shouldShowNotesButton: boolean =
      !this.props.shipmentTenantAttributesEditMode &&
      !this.props.authorizations.hasTenantCarrierRole(this.props.principal);

    const grantor = this.props.entitlementInfo?.grantor;
    const entitlees = this.props.entitlementInfo?.entitlees;

    const tenantsWithAccess = [
      ...(entitlees ? entitlees.map((tenant) => tenant.tenantId) : []),
      ...(grantor !== null && grantor !== undefined ? [grantor.tenantId] : []),
    ];
    const renderChat = () => {
      if (this.props.authorizations.hasChatEnabled && this.props.authorizations.hasChatEnabled())
        //@ts-ignore
        return (
          //@ts-ignore
          <Chat
            //@ts-ignore
            shipmentId={
              this.props.masterShipmentId ? this.props.masterShipmentId : `${this.props.mode}_${this.props.shipmentId}`
            }
            additionalTenantsWithAccess={tenantsWithAccess}
          />
        );
      return null;
    };

    return (
      <SubHeader>
        <ChatProvider authorizations={this.props.authorizations}>
          <Row>
            <Col md={16} xs={24}>
              <h1>
                <FormattedMessage id="shipmentDetails.header" defaultMessage="Shipment Details" />
              </h1>
            </Col>
            <Col md={8} xs={24}>
              <nav className={'flex justify-end'}>
                {shouldShowEditShipmentButton && (
                  <div className={`${styles.item} flex items-end justify-end`} data-locator="edit-identifiers">
                    <Button
                      size="lg"
                      clickFn={() => {
                        trackEvent('SHIPMENT_DETAIL_PAGE_EDIT_CLICK', {
                          shipmentId: this.props.masterShipmentId || this.props.shipmentId || '',
                          mode: this.props.mode,
                        });
                        this.props.toggleTenantAttributesMode();
                      }}
                      name="edit"
                    >
                      <i className="material-icons left ">edit</i>
                      <FormattedMessage
                        id="shipmentDetails.shipmentIdentifiers.EDIT_IDENTIFIERS"
                        defaultMessage="EDIT"
                      />
                    </Button>
                  </div>
                )}
                {shouldShowCancelButton && (
                  <div className={`${styles.item} flex items-end justify-end`} data-locator="cancel-edit-identifiers">
                    <Button size="lg" clickFn={this.props.toggleTenantAttributesMode}>
                      <FormattedMessage id="shipmentDetails.shipmentIdentifiers.cancel" defaultMessage="Cancel" />
                    </Button>
                  </div>
                )}
                {shouldShowSaveButton && (
                  <div className={`${styles.item} flex items-end justify-end`} data-locator="save-identifiers">
                    <Button size="lg" type="primary" clickFn={this.saveShipmentIdentifiers}>
                      <FormattedMessage
                        id="shipmentDetails.shipmentIdentifiers.SAVE_IDENTIFIERS"
                        defaultMessage="Save"
                      />
                    </Button>
                  </div>
                )}
                {shouldShowNotesButton && (
                  <div className={`${styles.item} flex items-end justify-end`} data-locator="notes-button">
                    <NotesButtonContainer
                      shipmentTenantId={this.props.shipmentTenantId}
                      shipmentId={this.props.shipmentId}
                      mode={this.props.mode}
                      masterShipmentId={this.props.masterShipmentId}
                      shipmentShareToken={this.props.shipmentShareToken}
                      useIntermodalEndpoint={this.props.useIntermodalEndpoint}
                      entitlementInfo={this.props.entitlementInfo}
                      isPublicPage={isPublicPage}
                    />
                  </div>
                )}
                {shouldShowAlertsButton && (
                  <div className={`${styles.item} flex-col flex justify-end`} data-locator="notifications-button">
                    <Link
                      to={this.getShipmentNotificationsLinkUrl()}
                      onClick={() => {
                        trackEvent('SHIPMENT_DETAIL_PAGE_ALERTS_CLICK', {
                          shipmentId: this.props.masterShipmentId || this.props.shipmentId || '',
                          mode: this.props.mode,
                        });
                      }}
                    >
                      <Button size="lg" classes="shipment-notification-btn secondary small">
                        <i className="material-icons left">notifications</i>
                        <FormattedMessage id="shipmentDetails.alerts" defaultMessage="ALERTS" />
                      </Button>
                    </Link>
                  </div>
                )}
                {shouldShowShareButton && (
                  <div className={`${styles.item} flex items-end justify-end`} data-locator="shipment-share-button">
                    <ShipmentShareButtonContainer
                      shipmentId={this.props.shipmentId}
                      shipmentMode={this.props.mode}
                      masterShipmentId={this.props.masterShipmentId}
                      authorizations={this.props.authorizations}
                    />
                  </div>
                )}
              </nav>
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col xs={24}>
              <ShipmentIdentifiersContainer
                shipmentId={this.props.shipmentId}
                masterShipmentId={this.props.masterShipmentId}
                identifiers={this.props.identifiers}
                onRef={this.updateShipmentIdentifiersContainerRef}
                tenantCustomAttributes={this.props.tenantCustomAttributes}
                carrierInfo={this.props.carrierInfo}
                shipmentShareToken={this.props.shipmentShareToken}
                mode={this.props.mode}
              />
            </Col>
          </Row>
          {this.props.isLoggedIn && this.props.showEmissions && (
            <>
              <Row className="flex-grow-1">
                <Col xs={24} className="d-flex align-items-center">
                  <Co2Icon className="mr-2" />
                  <span className={styles.emissionsText} data-locator="shipment-emissions-text">
                    {this.props.emissionsInMetricTons !== undefined && this.props.emissionsInMetricTons >= 0 ? (
                      <FormattedMessage
                        id="shipmentDetails.shipmentIdentifiers.shipmentEmissions"
                        defaultMessage="This shipment has emitted {emissions} metric tons of CO2"
                        values={{ emissions: this.props.emissionsInMetricTons }}
                      />
                    ) : (
                      <FormattedMessage
                        id="shipmentDetails.shipmentIdentifiers.shipmentEmissionsNA"
                        defaultMessage="The emitted metric tons of CO2 is N/A"
                      />
                    )}
                  </span>
                  <Tooltip
                    title={
                      <FormattedMessage
                        id="shipmentDetails.shipmentIdentifiers.shipmentEmissionsTooltip"
                        defaultMessage="This is an estimation based on the miles driven and the average CO2 emission. If the value is N/A, miles driven could not be calculated."
                      />
                    }
                  >
                    <InfoCircleFilled style={{ cursor: 'pointer', color: primaryGreyForty }} className="ml-2" />
                  </Tooltip>
                </Col>
              </Row>
            </>
          )}
          {/* @ts-ignore */}
          {renderChat()}
        </ChatProvider>
      </SubHeader>
    );
  }
}

export default ShipmentHeader;
