import { FormSection, Field } from 'redux-form';
import { defineMessages } from 'react-intl';
import { intl } from 'common/AppConstants';
import { allCountriesSortedAlphabetically as allCountries } from 'common/allCountriesList';
import { RenderSingleSelectDropdown } from '../AddEditFormInputs';

const message = defineMessages({
  selectCountry: {
    id: 'notifications.eventRuleFormSection.selectCountry',
    defaultMessage: 'Select Country',
  },
});

export interface EventRuleCountryProps {
  children?: JSX.Element[];
  className?: string;
}

const EventRuleCountry = ({ children, className = '' }: EventRuleCountryProps) => {
  return (
    <div className={className}>
      {children}
      <FormSection name="countryParameter">
        <Field
          name="country"
          className=""
          data-locator="country-input"
          placeholder={intl.formatMessage(message.selectCountry)}
          component={RenderSingleSelectDropdown}
          dropdownItems={allCountries.map((country) => ({
            value: country.code,
            displayName: country.name,
          }))}
        />
      </FormSection>
    </div>
  );
};

export default EventRuleCountry;
