import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose, lifecycle } from 'recompose';
import { getPrincipalAuthorizationsFromState, oceanOnModeAgnosticPage } from 'common/authorizations';
import { trackEvent } from 'common/eventTracker';
import { ShipmentModeEnum } from 'models';
import OCEANDetailsComponent from './OCEANDetailsComponent';
import endpoints, { replaceTokens } from '../../../common/endpoints';
import {
  getShipmentDetails,
  getPublicShipmentDetails,
  toggleRouteDetailsDisplay,
  toggleGroupedUpdates,
  resetShipment,
} from './ducks/actions';

const componentWithFetchedData = compose(
  lifecycle({
    componentDidMount() {
      // either ocean shipment id or shipment share token is expected in the url parameters
      const { shipmentDetailId, shipmentShareToken } = this.props.match.params;

      if (shipmentShareToken && oceanOnModeAgnosticPage()) {
        this.props.history.replace(
          replaceTokens(endpoints.PUBLIC_MA_TRACKING_DETAILS_WITH_TOKEN, { shipmentShareToken })
        );
      } else if (shipmentShareToken) {
        this.props.getPublicShipmentDetails(shipmentShareToken, this.props.shipmentModel);
      } else {
        this.props.getShipmentDetails(shipmentDetailId, this.props.shipmentModel);
      }
      trackEvent('SHIPMENT_DETAIL_PAGE_LOAD', {
        mode: ShipmentModeEnum.OCEAN,
        shipmentId: shipmentDetailId,
        isNewDetailPage: false,
      });
    },
    componentWillUnmount() {
      // removes the shipment details state, preventing state persisting when using back button to navigate
      this.props.resetShipment();
    },
  })
)(OCEANDetailsComponent);

const mapStateToProps = (state) => {
  const authorizations = getPrincipalAuthorizationsFromState(state);
  return {
    authorizations,
    shipmentDetails: state.oceanShipmentDetailsReducer.shipmentDetails,
    isLoggedIn: state.authReducer.isLoggedIn,
    showExpanded: state.oceanShipmentDetailsReducer.showExpanded,
    shipmentDetailsMissing: state.oceanShipmentDetailsReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getShipmentDetails: (id, shipmentModel) => dispatch(getShipmentDetails(id, shipmentModel)),
  getPublicShipmentDetails: (shipmentShareToken, shipmentModel) =>
    dispatch(getPublicShipmentDetails(shipmentShareToken, shipmentModel)),
  toggleRouteDetailsDisplay: () => dispatch(toggleRouteDetailsDisplay()),
  toggleGroupedUpdates: (index) => dispatch(toggleGroupedUpdates(index)),
  resetShipment: () => dispatch(resetShipment()),
  redirectToMissingShipmentPage: () => dispatch(push(endpoints.MISSING_SHIPMENT)),
});

export default connect(mapStateToProps, mapDispatchToProps)(componentWithFetchedData);
