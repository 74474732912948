import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'ui-components';
import ORIGIN from '../../../../common/assets/pickup.svg';
import DESTINATION from '../../../../common/assets/delivery.svg';
import './RouteDetail.scss';

const RouteDetail = (props) => {
  const stopIcons = {
    ORIGIN: ORIGIN,
    DESTINATION: DESTINATION,
  };
  const stopIcon = stopIcons[get(props, 'routeInfo.stopType')];
  return (
    <div className="route-detail card-container">
      <Row type="flex" align="middle" className="route-detail__info">
        <Col sm={3} xs={0} className="route-detail__icon">
          <img src={stopIcon} alt={props.routeInfo.info.stopType} />
          <span className="label">
            <FormattedMessage
              id={props.routeInfo.info.stopLabel.id}
              defaultMessage={props.routeInfo.info.stopLabel.defaultMessage}
            />
          </span>
        </Col>
        <Col span={21} className="route-detail__stop-info">
          <span className="appointment-info">
            <span className="appointment-info__company">{props.routeInfo.info.contact.companyName}</span>
            {props.routeInfo.info.contact.companyName && props.routeInfo.info.cityState && (
              <span className="appointment-info__divider">|</span>
            )}
            <span className="appointment-info__location">{props.routeInfo.info.cityState}</span>
          </span>
          {props.routeInfo.info.appointmentRange && (
            <div className="appointment-window">
              <div className="appointment-window__label">
                <FormattedMessage
                  id={props.routeInfo.info.appointmentLabel.id}
                  defaultMessage={props.routeInfo.info.appointmentLabel.defaultMessage}
                />
              </div>
              <div className="appointment-window__range">{props.routeInfo.info.appointmentRange}</div>
            </div>
          )}
          <div className="stop-contact-info">
            <span className="stop-contact-info__name">{props.routeInfo.info.contact.contactName}</span>
            {props.routeInfo.info.contact.contactName && props.routeInfo.info.contact.phoneNumber && (
              <span className="stop-contact-info__divider">|</span>
            )}
            <span className="stop-contact-info__phone">
              <a href={`tel:${props.routeInfo.info.contact.phoneNumber}`}>{props.routeInfo.info.contact.phoneNumber}</a>
            </span>
            {props.routeInfo.info.contact.phoneNumber && props.routeInfo.info.contact.email && (
              <span className="stop-contact-info__divider">|</span>
            )}
            <span className="stop-contact-info__email">
              <a href={`mailto:${props.routeInfo.info.contact.email}`}>{props.routeInfo.info.contact.email}</a>
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RouteDetail;
