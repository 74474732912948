import { useMapContext, Polyline, Marker, InfoWindow } from '@uiw/react-baidu-map';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ShipmentPing from '../models/shipmentPing';
import {
  convertSvgToUrl,
  getLocationSvg,
  svgMarkupCurrentStopIcon,
  svgMarkupEndMarker,
  svgMarkupStartMarker,
} from './MapDetailChinaUtils';
import { formatCurrentLocation, formatPingDate, formatPingTime } from '../MapSidepanelItem/MapSidepanelItem';

export class BaiduMapPoint {
  lat: number;
  lng: number;
  constructor(lat: number, lng: number) {
    this.lat = lat;
    this.lng = lng;
  }
}

interface IBaiduMapLayer {
  start: BaiduMapPoint;
  end: BaiduMapPoint;
  currentPoint: BaiduMapPoint;
  tab: number;
  allPings: ShipmentPing[];
  selectedPing: null | ShipmentPing;
  openInfoWindow: boolean;
  handleInfoWindowClose: () => void;
}

const BaiduMapLayer = ({
  start,
  end,
  currentPoint,
  tab,
  allPings,
  selectedPing,
  openInfoWindow,
  handleInfoWindowClose,
}: IBaiduMapLayer) => {
  const { map, BMap } = useMapContext();
  const [routes, setRoutes] = useState<BMap.Point[]>([]);

  const selectedPoint = useMemo(
    () => new BaiduMapPoint(selectedPing?.coordinates.latitude, selectedPing?.coordinates.longitude),
    [selectedPing]
  );

  const BaiduInfoWindow = useMemo(
    () =>
      Boolean(selectedPing) && (
        <InfoWindow
          isOpen={openInfoWindow}
          onClose={handleInfoWindowClose}
          position={selectedPoint as BMap.Point}
          width={50}
        >
          <div style={{ fontSize: '14px' }}>
            <FormattedMessage
              id="shipmentDetails.mapSidepanelItem.nearCurrentLocation"
              defaultMessage="Near {currentLocation}"
              values={{ currentLocation: <b>{formatCurrentLocation(selectedPing?.addresses?.current)}</b> }}
            />
            <div>
              <span>{formatPingDate(selectedPing?.pingDateTimes?.utc, selectedPing?.timezone)} at </span>
              <span>
                <b>{formatPingTime(selectedPing?.pingDateTimes?.utc, selectedPing?.timezone)}</b>
              </span>
            </div>
          </div>
        </InfoWindow>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openInfoWindow, selectedPing]
  );

  useEffect(() => {
    if (map && BMap) {
      const driving = new BMap.DrivingRoute(map, {
        onSearchComplete(results: any) {
          if (driving.getStatus() === BMAP_STATUS_SUCCESS) {
            const plan = results.getPlan(0);
            let arrPois: BMap.Point[] = [];
            for (let j = 0; j < plan.getNumRoutes(); j++) {
              const route = plan.getRoute(j);
              arrPois = arrPois.concat(route.getPath());
            }
            map.setViewport(arrPois, {});
            setRoutes(arrPois);
          }
        },
      });
      driving.search(new BMap.Point(start.lng, start.lat), new BMap.Point(end.lng, end.lat));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end, start, tab, BMap]);

  if (!BMap || !map) return null;
  return (
    <>
      <Marker
        data-testid="marker-current"
        position={new BMap.Point(currentPoint.lng, currentPoint.lat)}
        icon={new BMap.Icon(convertSvgToUrl(svgMarkupCurrentStopIcon), new BMap.Size(60, 60))}
      />
      <Marker
        data-testid="marker-destination"
        position={new BMap.Point(end.lng, end.lat)}
        icon={new BMap.Icon(convertSvgToUrl(svgMarkupEndMarker), new BMap.Size(60, 60))}
      />
      <Marker
        data-testid="marker-origin"
        position={new BMap.Point(start.lng, start.lat)}
        icon={new BMap.Icon(convertSvgToUrl(svgMarkupStartMarker), new BMap.Size(60, 60))}
      />
      {tab === 0 && <Polyline data-testid="polyline-route" path={routes} strokeColor="#00558B" strokeOpacity={1} />}
      {tab === 1 &&
        allPings.map((ping) => (
          <Marker
            key={ping.id}
            position={new BMap.Point(ping.coordinates.longitude, ping.coordinates.latitude)}
            icon={new BMap.Icon(convertSvgToUrl(getLocationSvg()), new BMap.Size(30, 30))}
          />
        ))}
      {BaiduInfoWindow}
    </>
  );
};

export default BaiduMapLayer;
