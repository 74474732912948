/* eslint-disable react/no-array-index-key */
import React, { MouseEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import AutoComplete from 'antd/lib/auto-complete';
import { Input } from 'antd';
import { ReactComponent as TrashIcon } from 'components/common/assets/icons/icon-trash.svg';
import { ReactComponent as DropDownIcon } from 'components/common/assets/icons/icon_dropdown.svg';
import { Button } from 'ui-components';
import { DocumentTypes } from '../../enums/documentEnums';

import * as styles from './DocumentStageComponent.module.scss';

import { checkMimeTypeStatus, MimeValidatedFile } from '../utils/validateFile';

export interface DocumentStageComponentProps {
  stagedFiles: File[] | undefined;
  onRemoveFile: (e: MouseEvent, idx: number) => void;
  onSubmit: (files: MimeValidatedFile[] | null | undefined, documentType: string) => Promise<boolean>;
  handleDocumentStageComponentOff: () => void;
}

function DocumentStageComponent(props: DocumentStageComponentProps) {
  const [documentType, setDocumentType] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const mimeTypeStatus = checkMimeTypeStatus(props.stagedFiles);

  const intl = useIntl();
  const documentTypesArr = [
    {
      enum: DocumentTypes[DocumentTypes.BILL_OF_LADING],
      value: intl.formatMessage({
        defaultMessage: 'Bill of Lading',
        id: 'documentManualUploadUi.documentType.billOfLading',
      }),
    },
    {
      enum: DocumentTypes[DocumentTypes.DELIVERY_RECEIPT],
      value: intl.formatMessage({
        defaultMessage: 'Delivery Receipt',
        id: 'documentManualUploadUi.documentType.deliveryReceipt',
      }),
    },
    {
      enum: DocumentTypes[DocumentTypes.INSPECTION_CERTIFICATE],
      value: intl.formatMessage({
        defaultMessage: 'Inspection Certificate',
        id: 'documentManualUploadUi.documentType.inspectionCertificate',
      }),
    },
    {
      enum: DocumentTypes[DocumentTypes.INVOICE],
      value: intl.formatMessage({
        defaultMessage: 'Invoice',
        id: 'documentManualUploadUi.documentType.invoice',
      }),
    },
    {
      enum: DocumentTypes[DocumentTypes.LETTER_OF_AUTHORIZATION],
      value: intl.formatMessage({
        defaultMessage: 'Letter Of Authorization',
        id: 'documentManualUploadUi.documentType.letterOfAuthorization',
      }),
    },
    {
      enum: DocumentTypes[DocumentTypes.LUMPER_CERTIFICATE],
      value: intl.formatMessage({
        defaultMessage: 'Lumper Certificate',
        id: 'documentManualUploadUi.documentType.lumperCertificate',
      }),
    },
    {
      enum: DocumentTypes[DocumentTypes.PACKING_SLIP],
      value: intl.formatMessage({
        defaultMessage: 'Packing Slip',
        id: 'documentManualUploadUi.documentType.packingSlip',
      }),
    },
    {
      enum: DocumentTypes[DocumentTypes.WEIGHT_CERTIFICATE],
      value: intl.formatMessage({
        defaultMessage: 'Weight Certificate',
        id: 'documentManualUploadUi.documentType.weightCertificate',
      }),
    },
  ];

  const handleSubmit = async (files: MimeValidatedFile[] | null | undefined, documentType: string) => {
    setLoading(true);
    await props.onSubmit(files, documentType);
    props.handleDocumentStageComponentOff();
    setLoading(false);
  };

  return (
    <div className={styles['document-stage-component-wrapper']}>
      <div className={styles['document-stage-component']}>
        <h4>
          <FormattedMessage defaultMessage="Upload Document" id="documentManualUploadUi.documentStage.uploadDocument" />
        </h4>
        <hr />
        <h6 className={styles['document-staged-section-header']}>
          <FormattedMessage defaultMessage="SELECTED FILES" id="documentManualUploadUi.documentStage.selectedFiles" />
        </h6>
        <div className={styles['document-staged-file-list']}>
          {props.stagedFiles?.length === 0 && (
            <span className="py-4">
              <FormattedMessage
                defaultMessage="No files selected. Close and select more files to continue."
                id="documentManualUploadUi.documentStage.noSelectedFiles"
              />
            </span>
          )}
          {props.stagedFiles &&
            props.stagedFiles.map((file: MimeValidatedFile, idx: number) => (
              <div
                className={classNames(styles['document-staged-file-row'], 'mb-1 ml-0')}
                key={`curr_file_idx_${idx}_file_path_${file.name}`}
              >
                <span
                  className={classNames(styles['document-staged-file-name'], {
                    [styles['invalid-file-type']]: file['validatedMimeType'] === null,
                  })}
                >
                  {file['name']}
                </span>

                <Button type="link" className="pt-3" onClick={(e) => props.onRemoveFile(e, idx)}>
                  <TrashIcon />
                </Button>
              </div>
            ))}
        </div>
        <h6 className={classNames(styles['document-staged-section-header'], 'mt-3')}>
          <FormattedMessage defaultMessage="DOCUMENT TYPE" id="documentManualUploadUi.documentType.documentType" />
        </h6>
        <AutoComplete
          data-testid="select-document-type"
          placeholder={intl.formatMessage({
            defaultMessage: 'Select..',
            id: 'documentManualUploadUi.documentType.select',
          })}
          options={documentTypesArr}
          dropdownMatchSelectWidth={200}
          onChange={(_: string, option) => setDocumentType((option as any).enum)}
          listHeight={150}
          style={{ width: 200, padding: 0 }}
          size={'large'}
          filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          className={classNames(styles['auto-complete'], 'mb-2')}
        >
          <Input suffix={<DropDownIcon />} />
        </AutoComplete>
        <hr />
        <div className={styles['buttons-wrapper']}>
          <React.Fragment>
            <Button type="secondary" onClick={props.handleDocumentStageComponentOff}>
              <FormattedMessage defaultMessage="Cancel" id="documentManualUploadUi.documentStage.cancel" />
            </Button>

            <Button
              type="primary"
              disabled={!documentType || props.stagedFiles?.length === 0 || mimeTypeStatus === false}
              onClick={() => handleSubmit(props.stagedFiles, documentType)}
              loading={loading}
            >
              <FormattedMessage defaultMessage="Upload" id="documentManualUploadUi.documentStage.upload" />
            </Button>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
}

export default DocumentStageComponent;
