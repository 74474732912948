import { APILoader, Provider, Map } from '@uiw/react-baidu-map';
import { useEffect, useState } from 'react';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { ShipmentStop } from 'models';
import MapDetailLegendChina from './MapDetailLegendChina';
import ShipmentPing from '../models/shipmentPing';
import ShipmentDetail from '../models/shipmentDetail';
import MapSidepanelItem from '../MapSidepanelItem/MapSidepanelItem';
import BaiduMapLayer, { BaiduMapPoint } from './BaiduMapLayer';

const BaiduMapDetail: React.FC<{ shipmentDetails: ShipmentDetail }> = ({ shipmentDetails }) => {
  const baiduMapsChinaApiKey = get(window, 'portalGlobal.baiduMapsChinaApiKey') as unknown as string;
  const [tab, setTab] = useState(0);
  const [allPings, setAllPings] = useState<ShipmentPing[]>([]);
  const [selectedPing, setSelectedPing] = useState<null | ShipmentPing>(null);
  const [openInfoWindow, setOpenInfoWindow] = useState<boolean>(false);

  const origin = shipmentDetails.shipmentStops.find(
    (each: ShipmentStop) => each.stopNumber === shipmentDetails.pickup.stopNumber
  );
  const destination = shipmentDetails.shipmentStops.find(
    (each: ShipmentStop) => each.stopNumber === shipmentDetails.delivery.stopNumber
  );
  const originPoint: BaiduMapPoint = new BaiduMapPoint(
    origin.location?.address?.locationCoordinatesDto?.latitude as number,
    origin.location?.address?.locationCoordinatesDto?.longitude as number
  );
  const destinationPoint: BaiduMapPoint = new BaiduMapPoint(
    destination.location?.address?.locationCoordinatesDto?.latitude as number,
    destination.location?.address?.locationCoordinatesDto?.longitude as number
  );
  const currentStopCoords = new BaiduMapPoint(
    shipmentDetails?.currentStop?.locationCoordinatesDto?.latitude as number,
    shipmentDetails?.currentStop?.locationCoordinatesDto?.longitude as number
  );

  const handleInfoWindowClose = () => {
    setOpenInfoWindow(false);
    setTimeout(() => {
      setSelectedPing(null);
    }, 100);
  };

  const handleMapToggle = (tabValue: number) => {
    setTab(tabValue);
    handleInfoWindowClose();
  };

  useEffect(() => {
    const { pings } = shipmentDetails;
    const storedPings: ShipmentPing[] =
      pings.length > 0
        ? orderBy(
            pings,
            (o) => {
              return moment(o.pingDateTimes.utc);
            },
            ['desc']
          )
        : [];
    setAllPings(
      storedPings.reduce((acc, ping) => {
        if (
          ping?.coordinates?.latitude &&
          (ping.statuses?.statusCode === 'IN_TRANSIT' || ping.statuses?.statusCode === 'AT_STOP')
        ) {
          acc.push(ping);
        }
        return acc;
      }, [] as ShipmentPing[])
    );
  }, [shipmentDetails]);

  return (
    <div className="map-detail">
      <MapDetailLegendChina />
      <div className="map-detail__inner">
        <div className="map-detail__map-wrapper">
          <div style={{ width: '100%', height: '550px', overflow: 'auto' }}>
            <div className="location_tab_china_map_iframe">
              <div className="map-detail__map-toggle" style={{ display: 'flex', gap: '8px', boxShadow: 'none' }}>
                <button
                  data-testid="map-route-btn"
                  className={tab === 0 ? 'map-detail__toggle-btn--active' : 'map-detail__toggle-btn'}
                  style={{
                    backgroundColor: tab === 0 ? '#00558b' : '#ffffff',
                  }}
                  type="button"
                  onClick={() => handleMapToggle(0)}
                >
                  <FormattedMessage id="shipmentDetails.mapDetail.route" defaultMessage="Route">
                    {(text) => <>{text}</>}
                  </FormattedMessage>
                </button>
                <button
                  data-testid="map-location-btn"
                  className={tab === 1 ? 'map-detail__toggle-btn--active' : 'map-detail__toggle-btn'}
                  style={{
                    backgroundColor: tab === 1 ? '#00558b' : '#ffffff',
                  }}
                  type="button"
                  onClick={() => handleMapToggle(1)}
                >
                  <FormattedMessage id="shipmentDetails.mapDetail.location" defaultMessage="Location">
                    {(text) => <>{text}</>}
                  </FormattedMessage>
                </button>
              </div>
              <div className="map-detail__map-wrapper">
                {allPings.length > 0 && (
                  <div
                    data-testid="map-location-side-panel"
                    className={tab === 1 ? 'map-detail__side-panel' : 'map-detail__side-panel--hidden'}
                    style={{ border: '1px solid #d5d4d4', position: 'absolute', left: 0, top: 0, zIndex: 5 }}
                  >
                    <div className="side-panel__header">
                      <FormattedMessage
                        id="shipmentDetails.mapDetail.locationUpdates"
                        defaultMessage="Location Updates"
                        tagName="h4"
                      />
                    </div>
                    <div className="side-panel__item-wrapper">
                      {allPings.map((ping) => (
                        <MapSidepanelItem
                          key={ping.id}
                          ping={ping}
                          handleClick={() => {
                            setOpenInfoWindow(false);
                            setTimeout(() => {
                              setSelectedPing(null);
                              setOpenInfoWindow(true);
                            }, 0);
                            setTimeout(() => {
                              setSelectedPing(ping);
                            }, 300);
                          }}
                        />
                      ))}
                    </div>
                  </div>
                )}

                <div className="map-detail__map" data-testid="baidu-china-map">
                  <APILoader akay={baiduMapsChinaApiKey}>
                    <Provider>
                      <Map zoom={3} enableScrollWheelZoom widget={['NavigationControl', 'GeolocationControl']}>
                        <BaiduMapLayer
                          start={originPoint}
                          end={destinationPoint}
                          currentPoint={currentStopCoords}
                          tab={tab}
                          allPings={allPings}
                          selectedPing={selectedPing}
                          openInfoWindow={openInfoWindow}
                          handleInfoWindowClose={handleInfoWindowClose}
                        />
                      </Map>
                    </Provider>
                  </APILoader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BaiduMapDetail;
