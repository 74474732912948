import { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import { injectIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { PrincipalContext } from 'contexts/PrincipalContext';
import FooterLink, { hasPermissionsForLink } from './FooterLink';
import { getApiDocUrl } from '../../../../common/newApiDocUrls';
import { getPrincipalAuthorizationsFromState, getPrincipalFromState } from '../../../../common/authorizations';

const FooterLinkGroup = ({ intl, isLoggedIn, userPermissions, groupConfig }) => {
  const principalContext = useContext(PrincipalContext);
  const principal = useSelector((state) => getPrincipalFromState(state));
  const authorizations = useSelector((state) => getPrincipalAuthorizationsFromState(state));
  const hasCarrierRole = authorizations.hasTenantCarrierRole(principal);
  const messages = defineMessages({
    linkText: {
      id: groupConfig.languageId,
      defaultMessage: intl.formatMessage({
        id: groupConfig.groupName.id,
        defaultMessage: groupConfig.groupName.defaultMessage,
      }),
    },
  });

  if (isLoggedIn) {
    const apiDocUrl = getApiDocUrl(principalContext);
    const links = groupConfig.links
      .filter((link) => hasPermissionsForLink(userPermissions, link))
      .filter((link) => {
        return !(hasCarrierRole && link.endpoint === apiDocUrl);
      })
      .map((link) => <FooterLink key={link.linkText.id} userPermissions={userPermissions} linkConfig={link} />);

    // Only render link group if there are links to render.
    if (links && links.length > 0) {
      return (
        <Col as={'ul'} xs={24} sm={12} md className="footer-column">
          <li className="jest">{intl.formatMessage(messages.linkText)}</li>

          {links}
        </Col>
      );
    }
  }

  return null;
};

export default injectIntl(FooterLinkGroup);
