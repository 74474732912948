import orderBy from 'lodash/orderBy';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Button, Drawer } from 'ui-components';
import { trackEvent } from 'common/eventTracker';
import endpoints from '../../common/endpoints';
import * as styles from './Notes.module.scss';
import NoteCard from './NotesCard/NotesCard';
import ShareBadge from '../common/shareBadge/ShareBadge';
import { NotesComponentProps } from './NotesContainer';

class NotesComponent extends React.Component<NotesComponentProps> {
  public componentDidMount() {
    if (this.props.shipmentShareToken !== undefined) {
      this.props.fetchPublicNotes(this.props.shipmentShareToken);
    } else {
      this.props.fetchNotes();
    }
  }

  public componentDidUpdate(prevProps: NotesComponentProps) {
    if (prevProps.shipmentId !== this.props.shipmentId) {
      this.props.reset();
    }
  }

  public componentWillUnmount() {
    this.props.reset();
  }

  public wrapperRef = React.createRef<HTMLDivElement>();

  onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  public render() {
    if (this.props.isPublicPage && isEmpty(this.props.notes)) return null;

    return (
      <div data-locator="notes-container" ref={this.wrapperRef} onScroll={this.onScroll}>
        <Button
          size="lg"
          clickFn={() => {
            trackEvent('SHIPMENT_DETAIL_PAGE_NOTES_CLICK', {
              shipmentId: this.props.masterShipmentId || this.props.shipmentId || '',
            });
            this.props.toggleNotesWindow();
          }}
        >
          <i className="material-icons left ">mode_comment</i>
          <FormattedMessage id="shipmentDetails.notesLabel" defaultMessage="Notes" />
        </Button>
        <Drawer
          title="Notes"
          drawerHeadContent={
            <ShareBadge
              className={styles.badgeBg}
              entitlementInfo={this.props.entitlementInfo}
              showMultipleEntitlees={false}
            />
          }
          width={400}
          visible={this.props.showNotesWindow}
          onClose={this.props.toggleNotesWindow}
          placement="right"
          bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '30px' }}
        >
          <div className={styles.gradientSpacer} />
          <div className={styles.notesListContainer}>
            {!this.props.notes || !this.props.notes.length ? (
              <div className={styles.emptyNotes}>
                <FormattedMessage id="shipmentDetails.notes.posts.noNotesPrimaryMessage">
                  {(text) => <h5> {text} </h5>}
                </FormattedMessage>

                {this.props.shipmentShareToken !== undefined ? (
                  <FormattedMessage id="authentication.please">
                    {(text) => (
                      <h5>
                        {`${text} `}
                        <Link className={styles.publicLoginLink} to={{ pathname: endpoints.LOGIN }}>
                          <FormattedMessage id="authentication.login">{(loginText) => loginText}</FormattedMessage>
                        </Link>
                      </h5>
                    )}
                  </FormattedMessage>
                ) : (
                  <FormattedMessage id="shipmentDetails.notes.posts.noNotesSecondaryMessage">
                    {(text) => <h5> {text} </h5>}
                  </FormattedMessage>
                )}
              </div>
            ) : (
              orderBy(this.props.notes, ['createdDateTime'], ['desc']).map((eachNote) => {
                return (
                  <NoteCard
                    key={eachNote.id}
                    mode={this.props.mode}
                    shipmentId={this.props.shipmentId}
                    shipmentTenantId={this.props.shipmentTenantId}
                    masterShipmentId={this.props.masterShipmentId}
                    isCustomerAdmin={this.props.isCustomerAdmin}
                    isCreatingNote={this.props.isCreatingNote}
                    isUpdatingNote={this.props.isUpdatingNote}
                    isDeletingNote={this.props.isDeletingNote}
                    note={eachNote}
                    createNote={this.props.createNote}
                    updateNote={this.props.updateNote}
                    deleteNote={this.props.deleteNote}
                    principal={this.props.principal}
                    isNewNote={false}
                  />
                );
              })
            )}
          </div>
          {this.props.shipmentShareToken === undefined && (
            <NoteCard
              shipmentId={this.props.shipmentId}
              masterShipmentId={this.props.masterShipmentId}
              mode={this.props.mode}
              isCustomerAdmin={this.props.isCustomerAdmin}
              isCreatingNote={this.props.isCreatingNote}
              isUpdatingNote={this.props.isUpdatingNote}
              isDeletingNote={this.props.isDeletingNote}
              createNote={this.props.createNote}
              updateNote={this.props.updateNote}
              deleteNote={this.props.deleteNote}
              principal={this.props.principal}
              isNewNote
            />
          )}
        </Drawer>
      </div>
    );
  }
}

export default NotesComponent;
