import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import get from 'lodash/get';
import { Tabs } from 'ui-components';
import { ComponentErrorProps } from 'components/common/errorBoundary/models';
import { API_PATH } from 'common/AppConstants';
import {
  InventoryItem,
  InventoryIdentifierTypeIntlKeys,
  PackageTypeIntlKeys,
  PackageTypePluralIntlKeys,
  InventoryIdentifier,
} from 'components/Inventory/models';
import axios from 'util/paxios';
import { white } from 'styles/colors';
import ShipmentsTableComponent from 'components/newShipmentDetails/ShipmentsTableComponent';
import OrdersTable from 'components/ordersList/OrdersTable';
import { trackEvent } from 'common/eventTracker';
import { getInventoryIdentifierDescription } from './inventoryHelpers';
import InventoryItemDetailsTab from './InventoryItemDetailsTab';

const StyledSummary = styled('div')`
  height: 100%;
  width: 100%;
  padding-top: 20px;
  background-color: ${white};
  position: relative !important;
  left: 0;
  top: 3px;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
`;
const InventoryItemDetails: React.FC<RouteComponentProps<{ inventoryItemId: string }>> = (props) => {
  const [itemDetails, setItemDetails] = React.useState<InventoryItem>();
  const [componentError, setComponentError] = React.useState<ComponentErrorProps>({ errorsList: [] });
  const handleFetchErrors = React.useCallback(
    function handleFetchErrorsCallback(key: string, error: any, errorIsNew: boolean) {
      if (error.response && errorIsNew) {
        const errorsArray =
          error.response.status === 404 ? [{ message: error.response.data.message }] : error.response.data.errors;
        const errorsList = [
          ...componentError.errorsList,
          {
            error: errorsArray.map((err: any) => err.message),
            supportRefId: error.response.data.supportReferenceId,
            id: key,
          },
        ];
        setComponentError({ errorsList });
      }
    },
    [componentError.errorsList]
  );
  React.useEffect(() => {
    const errorKey = 'inventory-load-items';
    const previousErrorIndex = componentError.errorsList.find((elem) => elem.id === errorKey);
    axios
      .get(`${API_PATH}/inventory/items/id/${props.match.params.inventoryItemId}`, { withCredentials: true })
      .then((res) => {
        setItemDetails(res.data);
      })
      .catch((error) => {
        handleFetchErrors(errorKey, error, !previousErrorIndex);
        return error;
      });
  }, [componentError.errorsList, handleFetchErrors, props.match.params.inventoryItemId]);

  React.useEffect(() => {
    if (itemDetails) {
      trackEvent('INVENTORY_DETAIL_PAGE_LOAD', {
        id: itemDetails.id || '',
      });
    }
  }, [itemDetails]);

  const shipmentCount = get(itemDetails, 'shipmentIds', [])?.length || 0;

  const invDimensionalWeight = itemDetails?.inventoryDimensionalWeight;
  const shouldDisplayItemCount =
    invDimensionalWeight && (invDimensionalWeight.quantity || invDimensionalWeight.packageType);

  return (
    <Container fluid>
      <Row style={{ minHeight: '100vh' }}>
        <Col lg="5" md="9" style={{ position: 'relative', left: '-15px', maxWidth: '300px' }}>
          <div style={{ height: '100%' }}>
            <StyledSummary>
              <Container>
                <Row style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
                  <Col md={24}>
                    <section>
                      <h1 style={{ fontSize: '24px' }}>
                        <FormattedMessage
                          id="inventory.itemDetails.summary.header"
                          defaultMessage="SKU {sku}"
                          values={{ sku: get(itemDetails, 'stockKeepingUnit') }}
                        />
                        {itemDetails && get(itemDetails, 'description') && (
                          <>
                            <br />
                            <small className="opacity-50 text-2xl font-normal">{get(itemDetails, 'description')}</small>
                          </>
                        )}
                        {itemDetails && get(itemDetails, 'universalProductCode') && (
                          <>
                            <br />
                            <small className="opacity-50 text-2xl font-normal">
                              UPC: {get(itemDetails, 'universalProductCode')}
                            </small>
                          </>
                        )}
                      </h1>
                    </section>
                  </Col>
                </Row>
                <Row className="py-10" style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
                  <Col md={24} className="text-3xl">
                    <section>
                      <Row className="my-5">
                        <Col>
                          {shouldDisplayItemCount && (
                            <>
                              {`${itemDetails?.inventoryDimensionalWeight?.quantity || ''} `}
                              <FormattedPlural
                                value={get(itemDetails, 'inventoryDimensionalWeight.quantity', 0)}
                                one={
                                  <FormattedMessage
                                    id={get(
                                      PackageTypeIntlKeys,
                                      `[${itemDetails?.inventoryDimensionalWeight?.packageType}].id`,
                                      ' '
                                    )}
                                    defaultMessage={get(
                                      PackageTypeIntlKeys,
                                      `[${itemDetails?.inventoryDimensionalWeight?.packageType}].defaultMessage`,
                                      'item'
                                    )}
                                  />
                                }
                                zero={
                                  <FormattedMessage
                                    id={get(
                                      PackageTypePluralIntlKeys,
                                      `[${itemDetails?.inventoryDimensionalWeight?.packageType}].id`,
                                      ' '
                                    )}
                                    defaultMessage={get(
                                      PackageTypePluralIntlKeys,
                                      `[${itemDetails?.inventoryDimensionalWeight?.packageType}].defaultMessage`,
                                      'items'
                                    )}
                                  />
                                }
                                other={
                                  <FormattedMessage
                                    id={get(
                                      PackageTypePluralIntlKeys,
                                      `[${itemDetails?.inventoryDimensionalWeight?.packageType}].id`,
                                      ' '
                                    )}
                                    defaultMessage={get(
                                      PackageTypePluralIntlKeys,
                                      `[${itemDetails?.inventoryDimensionalWeight?.packageType}].defaultMessage`,
                                      'items'
                                    )}
                                  />
                                }
                              />
                            </>
                          )}
                        </Col>
                      </Row>
                      <Row className="my-5">
                        <Col>
                          <FormattedPlural
                            value={shipmentCount}
                            zero={
                              <FormattedMessage
                                id="inventory.detailsSummary.shipments"
                                defaultMessage="{shipmentCount} shipments"
                                values={{
                                  shipmentCount: shipmentCount,
                                }}
                              />
                            }
                            one={
                              <FormattedMessage
                                id="inventory.detailsSummary.singleShipment"
                                defaultMessage="{shipmentCount} shipment"
                                values={{
                                  shipmentCount: shipmentCount,
                                }}
                              />
                            }
                            other={
                              <FormattedMessage
                                id="inventory.detailsSummary.shipments"
                                defaultMessage="{shipmentCount} shipments"
                                values={{
                                  shipmentCount: shipmentCount,
                                }}
                              />
                            }
                          />
                        </Col>
                      </Row>
                    </section>
                  </Col>
                </Row>
                <Row className="py-10" style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
                  <Col md={24}>
                    {itemDetails?.inventoryIdentifiers?.map((val: InventoryIdentifier) => {
                      return (
                        <Row className="my-4" key={val.type}>
                          <Col>
                            <p className="text-2xl">
                              <span className="font-bold capitalize">
                                <FormattedMessage
                                  id={get(InventoryIdentifierTypeIntlKeys, `[${val.type}].id`, ' ')}
                                  defaultMessage={get(
                                    InventoryIdentifierTypeIntlKeys,
                                    `[${val.type}].defaultMessage`,
                                    ' '
                                  )}
                                />
                              </span>
                              : {getInventoryIdentifierDescription(val.type, itemDetails)}
                            </p>
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              </Container>
            </StyledSummary>
          </div>
        </Col>
        <Col lg={19} md={15}>
          <Container fluid className="my-10">
            <Row>
              <Col md={24}>
                {itemDetails && (
                  <Tabs
                    type="card"
                    defaultActiveKey="details"
                    onChange={(key: string) => {
                      trackEvent('INVENTORY_DETAIL_PAGE_TAB_CLICK', {
                        key,
                      });
                    }}
                    panes={[
                      {
                        key: 'details',
                        tab: (
                          <FormattedMessage
                            defaultMessage="Details"
                            id="inventory.orderDetails.detailsTab.tabs.details"
                          />
                        ),
                      },
                      {
                        key: 'shipments',
                        tab: (
                          <FormattedMessage
                            defaultMessage="Shipments"
                            id="inventory.orderDetails.detailsTab.tabs.shipments"
                          />
                        ),
                      },
                      {
                        key: 'orders',
                        tab: (
                          <FormattedMessage
                            defaultMessage="Orders"
                            id="inventory.orderDetails.detailsTab.tabs.orders"
                          />
                        ),
                      },
                    ]}
                    tabcontent={{
                      details: <InventoryItemDetailsTab details={itemDetails} />,
                      ...(itemDetails.shipmentIds && {
                        shipments: <ShipmentsTableComponent masterShipmentIds={itemDetails.shipmentIds} />,
                      }),
                      ...(itemDetails.orderIds && {
                        orders: <OrdersTable inventoryOrderIds={itemDetails.orderIds} />,
                      }),
                    }}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default InventoryItemDetails;
