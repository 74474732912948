import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { RootState } from '../../../reducers';
import BrandThemingComponent from './BrandThemingComponent';
import * as brandThemingActions from './ducks/actions';
import { Theme } from './models/Theme';

const mapStateToProps = (state: RootState) => ({
  isFetchingTheme: state.brandThemingReducer.isFetching,
  isSavingTheme: state.brandThemingReducer.isSaving,
  selectedTheme: state.brandThemingReducer.selectedTheme,
  savedTheme: state.brandThemingReducer.savedTheme,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveTheme: (theme: Theme) => dispatch(brandThemingActions.save.request(theme)),
  fetchTheme: () => dispatch(brandThemingActions.fetch.request()),
  updateSelectedTheme: (selectedTheme: Theme) => dispatch(brandThemingActions.updateSelectedTheme(selectedTheme)),
  revertSelectedTheme: () => dispatch(brandThemingActions.revertSelectedTheme()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandThemingComponent);
