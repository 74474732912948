import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PostalAddress from 'i18n-postal-address';
import isEmpty from 'lodash/isEmpty';
import { intlOrderKeys, InventoryOrder } from 'components/Inventory/models';
import { Card } from 'ui-components';

interface DetailsTabProps {
  details: InventoryOrder;
}

interface TagsAndIdentifiersCardInput {
  title: string;
  tags?: any[];
  identifiers?: any[];
  intlKeys: any;
}

const StyledSeparator = styled('div')`
  padding-bottom: 5px;
`;

const StyledSolidSeparator = styled('hr')`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const TagsAndIdentifiersCard = ({ title, tags, identifiers, intlKeys }: TagsAndIdentifiersCardInput) => {
  const groupedTags = groupBy(tags, (tag: any) => tag.type);
  const availableTagTypes = Object.keys(groupedTags);

  const groupedIdentifiers = groupBy(identifiers, (identifier: any) => identifier.type);
  const availableIdentifiers = Object.keys(groupedIdentifiers);

  if (!tags && !identifiers) return null;
  if (isEmpty(tags) && isEmpty(identifiers)) return null;

  return (
    <Card style={{ minWidth: '300px' }} className="w-3/12 mx-4" title={title}>
      {tags &&
        availableTagTypes.map((tagType: any) => {
          return (
            <Row className="mb-3" key={tagType}>
              <Col>
                <strong>
                  <FormattedMessage
                    id={get(intlKeys, `[${tagType}].id`, ' ')}
                    defaultMessage={get(intlKeys, `[${tagType}].defaultMessage`, ' ')}
                  />
                </strong>
              </Col>
              <Col>
                {groupedTags[tagType].map((tag) => {
                  return (
                    <Row className="mb-3" key={tag.value}>
                      {tag?.value} {tag?.description && `: ${tag?.description}`}
                    </Row>
                  );
                })}
              </Col>
            </Row>
          );
        })}
      {identifiers &&
        availableIdentifiers.map((identifier: any) => {
          return (
            <Row className="mb-3" key={identifier}>
              <Col>
                <strong>
                  <FormattedMessage
                    id={get(intlKeys, `[${identifier}].id`, ' ')}
                    defaultMessage={get(intlKeys, `[${identifier}].defaultMessage`, ' ')}
                  />
                </strong>
              </Col>
              <Col>
                {groupedIdentifiers[identifier].map((id) => {
                  return (
                    <Row className="mb-3" key={id.value}>
                      {id?.value} {id?.description && `: ${id?.description}`}
                    </Row>
                  );
                })}
              </Col>
            </Row>
          );
        })}
    </Card>
  );
};

const getAddress = (address: any) => {
  const shouldDisplayAddress = address && address.addressLines;

  if (!shouldDisplayAddress) return null;

  const currentAddress = new PostalAddress();

  currentAddress
    .setAddress1(address.addressLines[0])
    .setAddress2(address.addressLines[1])
    .setState(address.state)
    .setCity(address.city)
    .setCountry(address.country)
    .setPostalCode(address.postalCode);

  return currentAddress.toString();
};

const contactContent = (contact: any) => {
  if (!contact) return null;

  return (
    <>
      <strong>
        <FormattedMessage id="inventory.orderDetails.detailsTab.labels.contactName" defaultMessage="Name" />:
      </strong>{' '}
      {get(contact, 'contactName', '')}
      <StyledSeparator />
      <strong>
        <FormattedMessage id="inventory.orderDetails.detailsTab.labels.contactEmail" defaultMessage="Email" />:
      </strong>{' '}
      {get(contact, 'email', '')}
      <StyledSeparator />
      <strong>
        <FormattedMessage id="inventory.orderDetails.detailsTab.labels.contactPhone" defaultMessage="Phone" />:
      </strong>{' '}
      {get(contact, 'phoneNumberCountryCode', '')} {get(contact, 'phoneNumber', '')}
      <StyledSeparator />
      <strong>
        <FormattedMessage id="inventory.orderDetails.detailsTab.labels.contactPhone2" defaultMessage="Phone 2" />:
      </strong>{' '}
      {get(contact, 'phoneNumber2CountryCode', '')} {get(contact, 'phoneNumber2', '')}
      <StyledSeparator />
      <strong>
        <FormattedMessage id="inventory.orderDetails.detailsTab.labels.contactFax" defaultMessage="Fax" />:
      </strong>{' '}
      {get(contact, 'faxNumberCountryCode', '')} {get(contact, 'faxNumber', '')}
      <StyledSeparator />
    </>
  );
};

const LocationCard = ({ location, title }: { location: any; title: string }) => {
  if (!location) return null;

  return (
    <Card style={{ minWidth: '300px' }} className="w-3/12 mx-4" title={title}>
      {location?.name && (
        <Card style={{ marginTop: 16 }} type="inner" title="Location Details">
          <strong>
            <FormattedMessage
              id="inventory.orderDetails.detailsTab.labels.locationName"
              defaultMessage="Location Name"
            />
            :
          </strong>{' '}
          {get(location, 'name', '')}
          {location?.suppliedId && (
            <>
              <StyledSeparator />
              <strong>
                <FormattedMessage
                  id="inventory.orderDetails.detailsTab.labels.locationId"
                  defaultMessage="Location ID"
                />
                :
              </strong>{' '}
              {location.suppliedId}
            </>
          )}
          <StyledSeparator />
          <strong>
            <FormattedMessage id="inventory.orderDetails.detailsTab.labels.locationAddress" defaultMessage="Address" />:
          </strong>{' '}
          {getAddress(location.address)}
        </Card>
      )}
      {location?.contact && (
        <Card style={{ marginTop: 16 }} type="inner" title="Primary Contact">
          {contactContent(location.contact)}
        </Card>
      )}
      {location?.additionalContacts && (
        <Card style={{ marginTop: 16 }} type="inner" title="Additional Contacts">
          {location.additionalContacts.map((contact: any, index: any) => {
            const isOneItem = location.additionalContacts.length === 1;
            const isLastItem = location.additionalContacts.length === index + 1;

            return (
              <>
                {contactContent(contact)}
                {!isOneItem && !isLastItem && <StyledSolidSeparator />}
              </>
            );
          })}
        </Card>
      )}
    </Card>
  );
};

const DetailsTab: React.FC<DetailsTabProps> = ({ details }) => {
  const intl = useIntl();

  const orderTags = details.orderTags || [];
  const additionalOrderIds = details.additionalOrderIdentifiers || [];

  return (
    <Container fluid>
      <Row>
        <Col style={{ paddingLeft: '2px' }} className="flex flex-wrap justify-start">
          <Card
            style={{ minWidth: '300px' }}
            className="w-3/12 mx-4"
            title={intl.formatMessage({
              id: 'inventory.orderDetails.detailsTab.cardHeaders.freight',
              defaultMessage: 'Freight',
            })}
          >
            <Row className="my-4">
              <Col>
                <strong>
                  <FormattedMessage id="inventory.orderDetails.detailsTab.labels.subject" defaultMessage="Subject" />
                </strong>
              </Col>
              <Col>{get(details, 'subject')}</Col>
            </Row>
            <Row className="my-4">
              <Col>
                <strong>
                  <FormattedMessage
                    id="inventory.orderDetails.detailsTab.labels.freightTerm"
                    defaultMessage="Freight Term"
                  />
                </strong>
              </Col>
              <Col>{get(details, 'freightTerms.freightOwnershipType')}</Col>
            </Row>
            <Row className="my-4">
              <Col>
                <strong>
                  <FormattedMessage
                    id="inventory.orderDetails.detailsTab.labels.totalCost"
                    defaultMessage="Total Cost"
                  />
                </strong>
              </Col>
              <Col>
                {get(details, 'totalCost.currency')} {get(details, 'totalCost.amount')}
              </Col>
            </Row>
            <Row className="my-4">
              <Col>
                <strong>
                  <FormattedMessage
                    id="inventory.orderDetails.detailsTab.labels.totalRetail"
                    defaultMessage="Total Retail"
                  />
                </strong>
              </Col>
              <Col>
                {get(details, 'totalRetailValue.currency')} {get(details, 'totalRetailValue.amount')}
              </Col>
            </Row>
            <Row className="my-4">
              <Col>
                <strong>
                  <FormattedMessage
                    id="inventory.orderDetails.detailsTab.labels.paymentTerm"
                    defaultMessage="Payment Term"
                  />
                </strong>
              </Col>
              <Col>{get(details, 'freightTerms.transportationPaymentType')}</Col>
            </Row>
          </Card>
          <LocationCard
            title={intl.formatMessage({
              id: 'inventory.orderDetails.detailsTab.cardHeaders.vendor',
              defaultMessage: 'Vendor',
            })}
            location={details.vendorLocation}
          />
          <LocationCard
            title={intl.formatMessage({
              id: 'inventory.orderDetails.detailsTab.cardHeaders.routeOrigin',
              defaultMessage: 'Route Origin',
            })}
            location={details.originLocation}
          />
          <LocationCard
            title={intl.formatMessage({
              id: 'inventory.orderDetails.detailsTab.cardHeaders.billingTo',
              defaultMessage: 'Billing To',
            })}
            location={details.billToLocation}
          />
          <LocationCard
            title={intl.formatMessage({
              id: 'inventory.orderDetails.detailsTab.cardHeaders.destination',
              defaultMessage: 'Destination',
            })}
            location={details.destinationLocation}
          />
          <TagsAndIdentifiersCard
            title={intl.formatMessage({
              id: 'inventory.detailsTab.cardHeaders.tagsAndIdentifiers',
              defaultMessage: 'Tags and Additional Identifiers',
            })}
            tags={orderTags}
            identifiers={additionalOrderIds}
            intlKeys={intlOrderKeys}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default DetailsTab;
