import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import isNil from 'lodash/isNil';
import { EntitlementInfo } from 'models';
import * as styles from './ShareBadge.module.scss';

export interface ShareBadgeProps {
  entitlementInfo?: EntitlementInfo;
  showMultipleEntitlees?: boolean;
  className?: string;
}

// is owner and has granted access to others, show entitlees as made visible to
// is NOT owner, show made visible by grantor
// is owner and has NOT granted access to others, don't show anything

const getMadeVisibleText = (
  entitlementInfo: EntitlementInfo,
  showMultipleEntitlees: boolean
): JSX.Element | undefined => {
  const { hasGrantedAccessToOthers, grantor, entitlees } = entitlementInfo;
  const hasEntitlees: boolean = !isNil(entitlees);
  if (grantor) {
    return (
      <FormattedMessage
        id="shareVisibility.visibleByPartner"
        defaultMessage="Made visible by {grantor}"
        values={{ grantor: grantor?.name }}
      />
    );
  } else if (hasGrantedAccessToOthers && !isNil(entitlees) && entitlees?.length > 1) {
    return showMultipleEntitlees ? (
      <FormattedMessage
        id={'shareVisibility.visibleToPartnerWithMultipleEntitlees'}
        defaultMessage={'Visible to multiple partners: {entitlees}'}
        values={{ entitlees: entitlees?.map((entitlee) => entitlee.name).join(', ') }}
      />
    ) : (
      <FormattedMessage
        id={'shareVisibility.visibleToMultiplePartners'}
        defaultMessage={'Visible to multiple partners'}
      />
    );
  } else if (hasGrantedAccessToOthers && hasEntitlees && entitlees?.length === 1) {
    return (
      <FormattedMessage
        id={'shareVisibility.visibleToPartner'}
        defaultMessage={'Visible to {entitlee}'}
        values={{ entitlee: entitlees[0].name }}
      />
    );
  }
  return undefined;
};

const ShareBadge: React.FC<ShareBadgeProps> = (props) => {
  const { entitlementInfo, showMultipleEntitlees = false, className } = props;

  if (isNil(entitlementInfo)) {
    return null;
  }

  const visibleText = getMadeVisibleText(entitlementInfo, showMultipleEntitlees);

  return visibleText === undefined ? null : (
    <div className={classNames(styles.shareBadge, className)}>{visibleText}</div>
  );
};

export default ShareBadge;
