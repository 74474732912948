import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import './CarrierFleetListHeaderComponent.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'contexts/ThemeContext';
import { RootState } from 'reducers';
import CarrierSearchBar from '../CarrierSearchBar/CarrierSearchBar';
import * as styles from '../../common/header/Header.module.scss';
import carriersList from '../../common/assets/img_carrierFleets.png';
import { buildCarrierFleetSearchQueryAction, buildSetCarrierFleetSearchTerm } from '../ducks/carrierSearchReducer';

const BannerComponent = () => {
  const context = React.useContext(ThemeContext);
  return (
    <img
      alt={''}
      src={context?.logoUrl ? carriersList : undefined}
      className={`${styles.appLogo} d-none d-sm-none d-md-flex`}
    />
  );
};

export interface CarrierFleetLHProps {
  carrierFleetSearchTerm?: string;
  isLoading?: boolean;
}

export interface CarrierFleetLHDispatchProps {
  setCarrierFleetSearchTerm?: (carrierFleetSearchTerm: string) => void;
  executeCarrierFleetSearchFn?: (searchCarrierFleetTerm?: string, selectedIdentifierType?: string) => void;
}

const CarrierFleetListHeaderComponent: React.FC<CarrierFleetLHProps & CarrierFleetLHDispatchProps> = () => {
  const dispatch = useDispatch();
  const carrierFleetSearchTerm = useSelector((state: RootState) => state.carrierSearchReducer.carrierFleetSearchTerm);
  const executeSearch = (selectedIdentifierType?: string) => {
    dispatch(
      buildCarrierFleetSearchQueryAction({ searchText: carrierFleetSearchTerm, selectedOption: selectedIdentifierType })
    );
  };

  const setCarrierFleetSearchTerm = (carrierFleetSearchTerm: string) => {
    dispatch(buildSetCarrierFleetSearchTerm(carrierFleetSearchTerm));
  };

  return (
    <Row as={'header'} noGutters className="CarrierFleetListHeaderComponent">
      <Col as={'h1'} xs={'auto'}>
        <BannerComponent />
        <br />
        <FormattedMessage id="carriersList.findCarrierFleetListHeader.listheader" defaultMessage="Find a Carrier" />

        <div className="CarrierSearch">
          <CarrierSearchBar
            executeSearchFn={executeSearch}
            setSearchTerm={setCarrierFleetSearchTerm}
            dontSearchOnChange
            searchTerm={carrierFleetSearchTerm}
            hasAdvancedLinking
          />
        </div>
      </Col>
    </Row>
  );
};

export default CarrierFleetListHeaderComponent;
