export enum LocationsManagementEnum {
  NAV_LOCATIONS_MANAGEMENT_CLICK = 'NAV_LOCATIONS_MANAGEMENT_CLICK',
  NAV_LOCATIONS_MANAGEMENT_HOVER = 'NAV_LOCATIONS_MANAGEMENT_HOVER',
}

export enum LocationsEnum {
  NAV_LOCATIONS_CLICK = 'NAV_LOCATIONS_CLICK',
}

export enum MindlogicEnum {
  NAV_MINDLOGIC_CLICK = 'NAV_MINDLOGIC_CLICK',
}

export enum MindlogicStarbucksEnum {
  NAV_MINDLOGIC_STARBUCKS_CLICK = 'NAV_MINDLOGIC_STARBUCKS_CLICK',
}
