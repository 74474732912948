import { useState } from 'react';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';

import { Button } from 'ui-components';
import { ShipmentStop, ShipmentLegCarrierInfo, Identifier, ShipmentStopAppointmentStatusEnum } from 'models';
import SlotBooking, { slotBookingWindowTypes } from 'components/slotBooking/SlotBooking';
import { MONTH_DAY_YEAR_HOUR_MINUTES_FORMAT } from 'i18n/configurei18n';
import { formatUtcTimeInTimeZoneOrDefaultToBrowserZone } from 'common/dateUtils';
import StatusColorTag from '../../common/statusColorTag/StatusColorTag';
import * as styles from './RouteTimeline.module.scss';

export interface StopApptWindowComponentProps {
  apptWindowType: string;
  apptWindowStart: moment.Moment | undefined;
  apptWindowEnd: moment.Moment | undefined;
  actualArrival: moment.Moment | undefined;
  actualDeparture: moment.Moment | undefined;
  showActualTimestamps?: boolean;
  statusMessage?: { id: string; defaultMessage: string; values?: any };
  statusTagColorValue?: string;
  stopIsComplete?: boolean;
  shouldHideBadge?: boolean;
  localTimeZoneIdentifierShort?: string;
}

const StopApptWindow: React.FC<StopApptWindowComponentProps> = ({
  apptWindowType,
  apptWindowStart,
  apptWindowEnd,
  actualArrival,
  actualDeparture,
  showActualTimestamps,
  statusMessage,
  statusTagColorValue,
  stopIsComplete,
  shouldHideBadge,
  localTimeZoneIdentifierShort,
}) => {
  const getMonthDAayYearHourMinutesFormat = (date: moment.Moment, format: string) => {
    return date ? date.format(format) + ' ' + localTimeZoneIdentifierShort : '';
  };

  return (
    <div className={styles.appointmentWindowContainer}>
      <div className={styles.appointmentWindowTitle}>
        <span>{apptWindowType.toLowerCase().replace(/_/g, ' ')}</span>
        {statusMessage && statusTagColorValue && !shouldHideBadge && (
          <span className="d-none d-sm-block">
            <StatusColorTag colorValue={statusTagColorValue} messageText={statusMessage} inverted={stopIsComplete} />
          </span>
        )}
      </div>

      <Row className={styles.appointmentWindowText}>
        <Col xs={12}>
          <FormattedMessage id="shipmentDetails.stopCards.scheduledArrival" defaultMessage="Scheduled arrival:" />{' '}
          {apptWindowStart ? (
            getMonthDAayYearHourMinutesFormat(apptWindowStart, MONTH_DAY_YEAR_HOUR_MINUTES_FORMAT)
          ) : (
            <FormattedMessage id="shipmentDetails.stopCards.unknown" defaultMessage="Unknown" />
          )}
        </Col>
        {showActualTimestamps && (
          <Col xs={12} className={styles.actualText}>
            <FormattedMessage id="shipmentDetails.stopCards.actualArrival" defaultMessage="Actual arrival:" />{' '}
            {actualArrival ? (
              getMonthDAayYearHourMinutesFormat(actualArrival, MONTH_DAY_YEAR_HOUR_MINUTES_FORMAT)
            ) : (
              <FormattedMessage id="shipmentDetails.stopCards.unknown" defaultMessage="Unknown" />
            )}
          </Col>
        )}
      </Row>
      <Row className={styles.appointmentWindowText}>
        <Col xs={12}>
          <FormattedMessage id="shipmentDetails.stopCards.scheduledDeparture" defaultMessage="Scheduled departure:" />{' '}
          {apptWindowEnd ? (
            getMonthDAayYearHourMinutesFormat(apptWindowEnd, MONTH_DAY_YEAR_HOUR_MINUTES_FORMAT)
          ) : (
            <FormattedMessage id="shipmentDetails.stopCards.unknown" defaultMessage="Unknown" />
          )}
        </Col>
        {showActualTimestamps && (
          <Col xs={12} className={styles.actualText}>
            <FormattedMessage id="shipmentDetails.stopCards.actualDeparture" defaultMessage="Actual departure:" />{' '}
            {actualDeparture ? (
              getMonthDAayYearHourMinutesFormat(actualDeparture, MONTH_DAY_YEAR_HOUR_MINUTES_FORMAT)
            ) : (
              <FormattedMessage id="shipmentDetails.stopCards.unknown" defaultMessage="Unknown" />
            )}
          </Col>
        )}
      </Row>
    </div>
  );
};

export interface StopAdditionalApptWindowsProps {
  stop: ShipmentStop;
  actualArrival: moment.Moment | undefined;
  actualDeparture: moment.Moment | undefined;
  shouldHideBadge?: boolean;
  shipmentId?: string;
  carrierIdentifiers?: ShipmentLegCarrierInfo[];
  shipmentIdentifiers: Identifier[];
  localTimeZoneIdentifierShort?: string | undefined;
}

export const StopAdditionalApptWindows: React.FC<StopAdditionalApptWindowsProps> = ({
  stop,
  actualArrival,
  actualDeparture,
  shouldHideBadge,
  shipmentId,
  carrierIdentifiers,
  shipmentIdentifiers,
  localTimeZoneIdentifierShort,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (stop.appointmentWindows === undefined || stop.appointmentWindows.length === 0) {
    return null;
  }
  const filteredAppointmentWindows = stop.appointmentWindows.filter(
    (appointmentWindow) =>
      !(
        slotBookingWindowTypes.includes(appointmentWindow.windowType) &&
        appointmentWindow.appointmentStatus === ShipmentStopAppointmentStatusEnum.REJECTED
      )
  );

  const getFormatedAppointmentWindows = (appointmentDateTime: string | undefined) => {
    return formatUtcTimeInTimeZoneOrDefaultToBrowserZone(
      appointmentDateTime,
      get(stop, 'appointmentWindows[0].localTimeZoneIdentifier') ||
        get(stop, 'appointmentWindow.localTimeZoneIdentifier')
    );
  };

  const formattedAptWindowStart = getFormatedAppointmentWindows(
    filteredAppointmentWindows[0]?.utcAppointmentStartDateTime
  );
  const formattedAptWindowEnd = getFormatedAppointmentWindows(filteredAppointmentWindows[0]?.utcAppointmentEndDateTime);

  if (filteredAppointmentWindows.length === 0) {
    return null;
  }
  return (
    <div>
      <div className={styles.appointmentWindowDivider}></div>
      <div className={styles.additionalAppointmentWindowsTitle}>
        <FormattedMessage
          id="shipmentDetails.stopCards.additionalApptWindows"
          defaultMessage="Additional Appointment Windows"
        />
      </div>
      {!isExpanded ? (
        <div>
          <StopApptWindow
            apptWindowType={filteredAppointmentWindows[0].windowType}
            apptWindowStart={formattedAptWindowStart}
            apptWindowEnd={formattedAptWindowEnd}
            actualArrival={actualArrival}
            actualDeparture={actualDeparture}
            showActualTimestamps
            statusMessage={filteredAppointmentWindows[0].statusMessage}
            statusTagColorValue={filteredAppointmentWindows[0].statusTagColorValue}
            shouldHideBadge={shouldHideBadge}
            localTimeZoneIdentifierShort={localTimeZoneIdentifierShort}
          />
          <SlotBooking
            shipmentId={shipmentId}
            stopId={stop.uuid}
            stopType={stop.type}
            locationId={stop.locationId}
            appointmentWindow={filteredAppointmentWindows[0]}
            carrierIdentifiers={carrierIdentifiers}
            shipmentIdentifiers={shipmentIdentifiers}
          />
          {filteredAppointmentWindows.length > 1 && (
            <Button
              className={styles.showApptWindowsButton}
              type="secondary"
              onClick={() => {
                setIsExpanded(true);
              }}
            >
              <FormattedMessage id="shipmentDetails.show" defaultMessage="Show" />
            </Button>
          )}
        </div>
      ) : (
        <div>
          {filteredAppointmentWindows.map((apptWindow) => {
            return (
              <React.Fragment key={`${apptWindow.windowType}`}>
                <StopApptWindow
                  apptWindowType={apptWindow.windowType}
                  apptWindowStart={getFormatedAppointmentWindows(apptWindow.utcAppointmentStartDateTime)}
                  apptWindowEnd={getFormatedAppointmentWindows(apptWindow.utcAppointmentEndDateTime)}
                  actualArrival={actualArrival}
                  actualDeparture={actualDeparture}
                  showActualTimestamps
                  statusMessage={apptWindow.statusMessage}
                  statusTagColorValue={apptWindow.statusTagColorValue}
                  shouldHideBadge={shouldHideBadge}
                />
                <SlotBooking
                  shipmentId={shipmentId}
                  stopId={stop.uuid}
                  stopType={stop.type}
                  locationId={stop.locationId}
                  appointmentWindow={apptWindow}
                  carrierIdentifiers={carrierIdentifiers}
                  shipmentIdentifiers={shipmentIdentifiers}
                />
              </React.Fragment>
            );
          })}
          <Button
            className={styles.showApptWindowsButton}
            type="secondary"
            onClick={() => {
              setIsExpanded(false);
            }}
          >
            <FormattedMessage id="shipmentDetails.showLess" defaultMessage="Show Less" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default StopApptWindow;
