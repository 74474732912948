import get from 'lodash/get';
import { mapStatus } from './status/mapStatus';
import { mapIdentifiers } from './shipmentIdentifiers/mapIdentifiers';
import { mapRouteDetails } from './routeDetails/mapRouteDetails';
import { mapCarrierInfo } from './carrier/mapCarrier';
import { convertToLocation, MarkerType } from '../../../../common/mapRoute/models/Location';

export const mapShipmentDetails = (shipment, shipmentModel) => {
  return {
    ...shipmentModel,
    masterShipmentId: shipment.masterShipmentId,
    mode: shipment.mode,
    shipmentId: shipment.shipmentId,
    internalId: shipment.internalId,
    identifiers: mapIdentifiers(shipment.shipmentIdentifiers[0]),
    status: mapStatus(shipment, shipmentModel.status),
    carrier: mapCarrierInfo(shipment),
    route: mapRouteDetails(shipment, shipmentModel.route),
    locations: mapLocations(shipment),
    map: {},
    entitlementInfo: shipment.entitlementInfo,
  };
};

const mapLocations = (shipment) => {
  const statusUpdates = get(shipment, 'allStatusUpdates', []);
  return convertToLocation(
    statusUpdates,
    'address.locationCoordinatesDto.latitude',
    'address.locationCoordinatesDto.longitude',
    mapMarkerFnc(statusUpdates.length)
  );
};

const NON_COMPLETE_STATUSES = ['IN_TRANSIT', 'AT_STOP', 'ON_SHIP', 'IN_YARD', 'AVAILABLE'];

const mapMarkerFnc = (length) => {
  return (data, index) => {
    if (index === length - 1 && NON_COMPLETE_STATUSES.indexOf(get(data, 'statusCode')) > -1) {
      return MarkerType.CURRENT_LOCATION;
    } else {
      return MarkerType.PING;
    }
  };
};
