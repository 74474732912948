import types from './types';
import { RENEW_SESSION } from '../../../login/LoginForm/ducks/types';

export const toggleSessionDialogToOpen = (isSessionDialogOpen) => ({
  type: types.TOGGLE_SESSION_DIALOG,
  isSessionDialogOpen: isSessionDialogOpen,
});

export const resetTimeout = () => ({
  type: RENEW_SESSION,
});
