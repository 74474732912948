import reducer from './reducers';
import { locationDetailActions, locationListActions } from './actions';
import * as locationManagementTypes from './types';
import * as locationManagementConstants from './constants';
import {
  watchLocationDetailAsync as locationDetailOperations,
  watchLocationListAsync as locationListOperations,
  watchLocationCreateAsync as locationCreateOperations,
  watchLocationUpdateAsync as locationUpdateOperations,
  watchLocationSuggestionsAsync as locationSuggestionOperations,
} from './operations';

export {
  locationDetailActions,
  locationListActions,
  locationManagementTypes,
  locationManagementConstants,
  locationDetailOperations,
  locationListOperations,
  locationCreateOperations,
  locationUpdateOperations,
  locationSuggestionOperations,
};

export default reducer;
