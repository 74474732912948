import { memo } from 'react';

import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useIntl } from 'react-intl';

import { FieldProps } from 'formik';
import { Input, InputErrorMessage } from 'components/common/forms/FormElements';

interface LatLngFieldComponentProps {
  fieldProps: FieldProps;
  userSelectedAddress?: boolean;
  latLngInputValue: string | undefined;
  defaultLatLng: string | undefined;
  updateUseAddressLatLngInMap: (useAddressLatLng: boolean) => void;
  updateUserSelectedAddress?: (userSelectedAddress: boolean) => void;
  updatelatLngInputValue: (latLng: string) => void;
  setFieldValue: (fieldName: string, value: any) => void;
  showLatLngErrorMessage: boolean;
  updateShowLatLngErrorMessage: (showErrorMessage: boolean) => void;
}

function LatLongEdit(props: LatLngFieldComponentProps) {
  const intl = useIntl();
  const {
    userSelectedAddress,
    latLngInputValue,
    defaultLatLng,
    updateUseAddressLatLngInMap,
    updateUserSelectedAddress,
    updatelatLngInputValue,
    setFieldValue,
    showLatLngErrorMessage,
    updateShowLatLngErrorMessage,
  } = props;
  let latLng: any;
  if (userSelectedAddress) {
    latLng = latLngInputValue;
  }
  return (
    <Row>
      <Col>
        <Input
          id="latLng"
          type="text"
          name="latLng"
          data-locator="location-lat-lng-input"
          data-testId="location-lat-lng-input"
          defaultValue={defaultLatLng}
          value={latLng}
          placeholder={intl.formatMessage({
            id: 'locationDetails.edit.placeholders.latitudeLongitude',
            defaultMessage: 'example: 0, 0',
          })}
          onChange={() => {
            if (updateUserSelectedAddress) {
              updateUserSelectedAddress(false);
            }
          }}
          onBlur={(e: any) => {
            if (isEmpty(e.target.value)) {
              latLng = latLngInputValue;
            }
            const formattedInputValues: string[] = e.target.value?.split(',').map((str: string) => trim(str));
            if (formattedInputValues?.length === 2) {
              setFieldValue('address.locationCoordinatesDto.latitude', formattedInputValues[0]);
              setFieldValue('address.locationCoordinatesDto.longitude', formattedInputValues[1]);
              updateUseAddressLatLngInMap(false);
              updatelatLngInputValue(e.target.value);
              updateShowLatLngErrorMessage(false);
            } else {
              updateShowLatLngErrorMessage(true);
            }
          }}
          style={{ fontSize: '1.4rem' }}
        />
        {showLatLngErrorMessage && (
          <InputErrorMessage
            data-locator="lat-lng-input-error"
            data-testId="lat-lng-input-error"
            errorMessage={intl.formatMessage({
              id: 'locationDetails.edit.errorMessages.invalidLatitudeLongitude',
              defaultMessage: 'Please enter a valid latitude and longitude separated with a comma',
            })}
          />
        )}
      </Col>
    </Row>
  );
}
export default memo(LatLongEdit);
