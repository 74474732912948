import get from 'lodash/get';
import axios from '../../util/paxios';
import Status from '../../components/shipment/common/enums/status';

export const queryRoute = (waypoints, hereMapsApiKey) => {
  const records = Object.values(waypoints);
  const routeParams = {
    apikey: hereMapsApiKey,
    transportMode: 'truck',
    routingMode: 'fast',
    return: 'travelSummary',
  };
  routeParams.origin = records.shift();
  routeParams.destination = records.pop();
  const via = records.map((each) => `via=${each}`);
  return axios({
    method: 'get',
    url: `https://router.hereapi.com/v8/routes?${via.join('&')}`,
    params: routeParams,
  });
};

export const calculateMiles = (route, currWaypointId) => {
  const sections = get(route, 'sections', []);

  const result = {
    milesTraveled: 0,
    milesRemaining: 0,
  };

  for (let i = 0; i < sections.length; i++) {
    if (i < currWaypointId) {
      result.milesTraveled += +get(sections, `[${i}].travelSummary.length`, 0);
    } else {
      result.milesRemaining += +get(sections, `[${i}].travelSummary.length`, 0);
    }
  }

  return {
    milesTraveled: metersToMiles(result.milesTraveled),
    milesRemaining: metersToMiles(result.milesRemaining),
  };
};

/**
 * Builds a waypoints map that can be passed to the HERE maps API. This is used to calculate
 * the route and the total distance traveled/remaining on that route.
 *
 * @param {object[]} stopStatuses Latitude and longitude pairs for stops in order.
 * @param {object} latestStatusUpdate The latest status update.
 * @return {object} The waypoints map and the index of the current waypoint.
 */
export const buildWaypoints = (stopStatuses = [], latestStatusUpdate) => {
  const currentStopNumber = latestStatusUpdate.stopNumber;
  const currentStopStatus = latestStatusUpdate.statusCode;

  const { currWaypointId, shouldCreateNew } = findCurrWaypointId(
    stopStatuses.length,
    currentStopNumber,
    currentStopStatus
  );
  const waypointsList = stopStatuses.map((status) => buildGeo(status.lat, status.lon));

  // Create a new waypoint if the shipment is currently in transit between two stops.
  if (shouldCreateNew) {
    const { latitude, longitude } = latestStatusUpdate.locationCoordinatesDto || {};
    waypointsList.splice(currWaypointId, 0, buildGeo(latitude, longitude));
  }

  const waypoints = {};
  for (let i = 0; i < waypointsList.length; i++) {
    waypoints[`waypoint${i}`] = waypointsList[i];
  }

  return { waypoints, currWaypointId };
};

/**
 * Determine which waypoint to use as the current position for distance calculations.
 *
 * @param {number} stopCount The total number of stops in the shipment details.
 * @param {number} currentStopNumber The current stop defined in the latest status update.
 * @param {string} currentStopStatus The status of the shipment as a whole.
 * @return {object} An object containing a field for the current waypoint and whether or not a new waypoint should be created.
 */
export const findCurrWaypointId = (totalStopCount, currentStopNumber, currentStopStatus) => {
  const result = { currWaypointId: -1, shouldCreateNew: false };

  // If no stop was defined, then the shipment hasn't started yet.
  if (!currentStopNumber) {
    result.currWaypointId = 0;
  }

  // When the shipment is at current stop, use that stop.
  else if (currentStopStatus === Status.AT_STOP) {
    result.currWaypointId = currentStopNumber;
  }

  // When the shipment has completed the destination stop.
  else if (currentStopStatus === Status.COMPLETED && currentStopNumber === totalStopCount) {
    result.currWaypointId = totalStopCount + 1;
  }

  // The shipment is in transit to a stop, so insert a waypoint for that position.
  // Rule out the case where the shipment is in transit to the pickup location.
  else if (currentStopStatus === Status.IN_TRANSIT && currentStopNumber > 1) {
    result.currWaypointId = currentStopNumber;
    result.shouldCreateNew = true;
  }

  // Reduce the curr waypoint id by 1 to convert between stop number (starting at 1) and array index.
  result.currWaypointId -= 1;
  return result;
};

export const metersToMiles = (meters = 0) => +(meters / 1609.34).toFixed(1);

export const buildGeo = (lat = 0, lon = 0) => `${lat.toFixed(1)},${lon.toFixed(1)}`;
