import boatIcon from '../../common/assets/icons/icon-boat.svg';
import dropoffIcon from '../../common/assets/icons/icon-dropoff.svg';
import deniedIcon from '../../common/assets/icons/icon-denied.svg';
import dwellingIcon from '../../common/assets/icons/icon-dwelling.svg';
import genericIcon from '../../common/assets/icons/icon-generic.svg';
import idleIcon from '../../common/assets/icons/icon-idle.svg';
import scheduleIcon from '../../common/assets/icons/icon-late.svg';
import pendingIcon from '../../common/assets/icons/icon-pending.svg';
import pickupIcon from '../../common/assets/icons/icon-pickup.svg';
import railIcon from '../../common/assets/icons/icon-rail.svg';
import airIcon from '../../common/assets/icons/icon-air.svg';
import temperatureIcon from '../../common/assets/icons/icon-temperature.svg';

const SHIPMENT_COUNTS_GET = 'shipmentCounts/SHIPMENT_COUNTS_GET';
const SHIPMENT_COUNTS_GET_SUCCESS = 'shipmentCounts/SHIPMENT_COUNTS_GET_SUCCESS';
const SHIPMENT_COUNTS_GET_FAILURE = 'shipmentCounts/SHIPMENT_COUNTS_GET_FAILURE';
const SHIPMENT_COUNTS_LOADING_PENDING = 'shipmentCounts/SHIPMENT_COUNTS_LOADING_PENDING';
const SHIPMENT_COUNTS_LOADING_SUCCESS = 'shipmentCounts/SHIPMENT_COUNTS_LOADING_SUCCESS';
const SHIPMENT_COUNTS_LOADING_ERROR = 'shipmentCounts/SHIPMENT_COUNTS_LOADING_ERROR';
const SHIPMENT_COUNTS_EDIT = 'shipmentCounts/SHIPMENT_COUNTS_EDIT';
const SHIPMENT_COUNTS_CANCEL_EDIT = 'shipmentCounts/SHIPMENT_COUNTS_CANCEL_EDIT';
const QUICK_VIEW_OPEN = 'shipmentCounts/QUICK_VIEW_OPEN';
const QUICK_VIEW_CANCEL = 'shipmentCounts/QUICK_VIEW_CANCEL';
const QUICK_VIEW_SAVE_ADD = 'shipmentCounts/QUICK_VIEW_SAVE_ADD';
const QUICK_VIEW_SAVE_EDIT = 'shipmentCounts/QUICK_VIEW_SAVE_EDIT';
const QUICK_VIEW_CLOSE_SNACKBAR = 'shipmentCounts/QUICK_VIEW_CLOSE_SNACKBAR';
const QUICK_VIEW_DELETE = 'shipmentCounts/QUICK_VIEW_DELETE';
const QUICK_VIEW_CHANGE_ICON = 'shipmentCounts/QUICK_VIEW_CHANGE_ICON';
const QUICK_VIEW_CHANGE_PICKUP_DATE = 'shipmentCounts/QUICK_VIEW_CHANGE_PICKUP_DATE';
const QUICK_VIEW_CHANGE_DELIVERY_DATE = 'shipmentCounts/QUICK_VIEW_CHANGE_DELIVERY_DATE';
const QUICK_VIEW_CHANGE_LAST_FREE_DATE = 'shipmentCounts/QUICK_VIEW_CHANGE_LAST_FREE_DATE';
const QUICK_VIEW_CHANGE_TOTAL_PICKUP_DATE = 'shipmentCounts/QUICK_VIEW_CHANGE_TOTAL_PICKUP_DATE';
const QUICK_VIEW_CHANGE_TOTAL_DELIVERY_DATE = 'shipmentCounts/QUICK_VIEW_CHANGE_TOTAL_DELIVERY_DATE';
const QUICK_VIEW_CHANGE_TOTAL_LAST_FREE_DATE = 'shipmentCounts/QUICK_VIEW_CHANGE_TOTAL_LAST_FREE_DATE';
const QUICK_VIEW_SET_INPUT = 'shipmentCounts/QUICK_VIEW_SET_INPUT';
const QUICK_VIEW_SAVE_LAYOUT = 'shipmentCounts/QUICK_VIEW_SAVE_LAYOUT';
const QUICK_VIEW_SET_LAYOUT = 'shipmentCounts/QUICK_VIEW_SET_LAYOUT';
const QUICK_VIEW_SAVE_SUCCESS = 'shipmentCounts/QUICK_VIEW_SAVE_SUCCESS';
const QUICK_VIEW_SET_MODE = 'shipmentCounts/QUICK_VIEW_SET_MODE';
const QUICK_VIEW_DUPLICATE = 'shipmentCounts/QUICK_VIEW_DUPLICATE';

const ICON_MAP = {
  boatIcon,
  dropoffIcon,
  deniedIcon,
  dwellingIcon,
  genericIcon,
  idleIcon,
  scheduleIcon,
  pendingIcon,
  pickupIcon,
  railIcon,
  airIcon,
  temperatureIcon,
};

export default {
  SHIPMENT_COUNTS_GET,
  SHIPMENT_COUNTS_GET_SUCCESS,
  SHIPMENT_COUNTS_GET_FAILURE,
  SHIPMENT_COUNTS_LOADING_PENDING,
  SHIPMENT_COUNTS_LOADING_SUCCESS,
  SHIPMENT_COUNTS_LOADING_ERROR,
  SHIPMENT_COUNTS_EDIT,
  SHIPMENT_COUNTS_CANCEL_EDIT,
  QUICK_VIEW_OPEN,
  QUICK_VIEW_CANCEL,
  ICON_MAP,
  QUICK_VIEW_SAVE_EDIT,
  QUICK_VIEW_SAVE_ADD,
  QUICK_VIEW_CLOSE_SNACKBAR,
  QUICK_VIEW_DELETE,
  QUICK_VIEW_CHANGE_ICON,
  QUICK_VIEW_CHANGE_PICKUP_DATE,
  QUICK_VIEW_CHANGE_DELIVERY_DATE,
  QUICK_VIEW_CHANGE_LAST_FREE_DATE,
  QUICK_VIEW_SET_INPUT,
  QUICK_VIEW_SAVE_LAYOUT,
  QUICK_VIEW_SET_LAYOUT,
  QUICK_VIEW_SAVE_SUCCESS,
  QUICK_VIEW_SET_MODE,
  QUICK_VIEW_CHANGE_TOTAL_PICKUP_DATE,
  QUICK_VIEW_CHANGE_TOTAL_DELIVERY_DATE,
  QUICK_VIEW_CHANGE_TOTAL_LAST_FREE_DATE,
  QUICK_VIEW_DUPLICATE,
};
