import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { primaryGreyEighty, primaryBlue } from 'styles/colors';

const MapLegendWrap = styled('div')`
  width: 100%;
  padding: 0 !important;
  margin-bottom: 0 !important;
  background: #fff;
  .map-detail {
    &__legend-inner {
      padding: 0.8rem 1.2rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 8px;
      width: 100%;
    }
    &__legend-icon {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      svg {
        width: 100%;
        height: auto;
      }
    }
    &__legend-item {
      color: ${primaryGreyEighty};
      font-size: 14px;
      font-size: 1.4rem;
      padding: 6px 5px;
    }
  }

  @media screen and (max-width: 768px) {
    .map-detail {
      &__legend-item {
        width: 30%;
      }
    }
  }
  @media screen and (max-width: 576px) {
    .map-detail {
      &__legend-item {
        width: 50%;
      }
    }
  }
`;

const MarkerWrap: React.FC<{}> = ({ children }) => {
  return <span className="map-detail__legend-icon d-flex align-items-center">{children}</span>;
};

const MarkerFinalDestination = () => {
  return (
    <MarkerWrap>
      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.77778 0.888889C1.77778 0.397969 1.37981 0 0.888889 0C0.397969 0 0 0.397969 0 0.888889V16H1.77778V9.77778C1.77778 9.77778 2.66667 8 5.33333 8C6.13243 8 6.57234 8.35918 7.05668 8.75465C7.64989 9.239 8.30975 9.77778 9.77778 9.77778C12.4444 9.77778 13.3333 8 13.3333 8V0.888889C13.3333 0.888889 12.4444 2.66667 9.77778 2.66667C8.97868 2.66667 8.53878 2.30748 8.05443 1.91201C7.46122 1.42766 6.80136 0.888889 5.33333 0.888889C4 0.888889 2.72 1.28 1.77778 1.67111V0.888889ZM1.77734 7.11109V3.55554C1.77734 3.55554 3.55512 2.66665 5.3329 2.66665C6.3743 2.66665 7.4157 3.27669 8.27842 3.78206C8.88845 4.13941 9.40915 4.44443 9.77734 4.44443C10.6662 4.44443 11.5551 3.55554 11.5551 3.55554V7.11109C11.5551 7.11109 10.6662 7.99998 9.77734 7.99998C9.40915 7.99998 8.88845 7.69497 8.27842 7.33761C7.4157 6.83224 6.3743 6.22221 5.3329 6.22221C3.55512 6.22221 1.77734 7.11109 1.77734 7.11109Z"
          fill={primaryBlue}
        />
      </svg>
    </MarkerWrap>
  );
};

const MarkerCurrentStop = () => {
  return (
    <MarkerWrap>
      <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 8C4.89543 8 4 7.10457 4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4C7.10457 4 8 4.89543 8 6C8 6.53043 7.78929 7.03914 7.41421 7.41421C7.03914 7.78929 6.53043 8 6 8ZM6 0C2.68629 0 0 2.50721 0 5.6C0 9.8 6 16 6 16C6 16 12 9.8 12 5.6C12 2.50721 9.31371 0 6 0Z"
          fill={primaryBlue}
        />
      </svg>
    </MarkerWrap>
  );
};

const MapDetailsLegendChina: React.FC<{}> = () => {
  return (
    <MapLegendWrap className="map-detail__legend-wrap">
      <div className="map-detail__legend-inner d-flex justify-content-sm-between justify-content-md-start flex-wrap">
        <span className="map-detail__legend-item d-flex align-items-center">
          <MarkerFinalDestination />
          <FormattedMessage id="shipmentDetails.mapLegendLabelFinalDestination" defaultMessage="Final Destination" />
        </span>
        <span className="map-detail__legend-item d-flex align-items-center">
          <MarkerCurrentStop />
          <FormattedMessage id="shipmentDetails.mapLegendLabelVehicleLocation" defaultMessage="Vehicle Location" />
        </span>
      </div>
    </MapLegendWrap>
  );
};

export default MapDetailsLegendChina;
