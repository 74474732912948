import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { defineMessages, FormattedMessage, IntlShape, WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { ShipmentModeIcon } from 'ui-components';
import statusReason from 'components/shipment/common/enums/statusReason';
import {
  ShipmentSearchCardData,
  SensorTypeEnum,
  EquipmentIdentifierTypeEnum,
  TimelinessCodeStatusColor,
  TimelinessCodeEnum,
  TimelinessCodeStatusIntlKeys,
} from 'models';
import endpoints from 'common/endpoints';
import { getPrincipalAuthorizations, temperatureMonitoringV2 } from 'common/authorizations';
import ShareBadge from 'components/common/shareBadge/ShareBadge';
import { formatNestedIntlValues } from 'i18n/utils';
import TemperatureIndicatorIcon from 'components/newShipmentDetails/TemperatureMonitoring/TemperatureIndicatorIcon';
import { filterEuIdentifierTypes, filterNaIdentifierTypes } from 'components/newShipmentDetails/utils/shipmentUtils';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { trackEvent } from 'common/eventTracker';
import { ShipmentIdentifiers, ShipmentStopInfo } from './components/ShipmentSearchResultCardComponents';
import ShipmentMode from '../../shipment/common/enums/shipmentMode';
import { TEMPERATURE_STATUS, SCHEDULE_TYPES } from '../../shipment/common/enums/temperatureStatus';
import arrowBack from '../../../assets/images/arrow-back.svg';
import overMax from '../../../components/common/assets/fire.svg';
import normal from '../../../components/common/assets/thumb-up.svg';
import underMin from '../../../components/common/assets/snowflake.svg';
import MinimalStatusBar from '../../../components/common/minimalStatusBar/MinimalStatusBar';
import StatusColorTag from '../../../components/common/statusColorTag/StatusColorTag';
import * as styles from './ShipmentSearchResultCard.module.scss';
import matchedSearchItems from './MatchedSearchItems/MatchedSearchItems';
import ResultCardEquipmentIdForm from './ResultCardEquipmentIdForm';

interface ShipmentSearchResultCardProps extends WrappedComponentProps, RouteComponentProps {
  resultItem: ShipmentSearchCardData;
  previousSearchTerm?: string;
  hasSearchMatchingDisplay?: boolean;
  hasTenantCarrierRole?: boolean;
  totalResultsCount: number;
  equipmentIdentifierTypes: EquipmentIdentifierTypeEnum[];
  isRecentDataSearched: () => boolean;
}

const ShipmentSearchResultCard: React.FC<ShipmentSearchResultCardProps> = ({
  intl,
  resultItem,
  history,
  previousSearchTerm = '',
  hasSearchMatchingDisplay = false,
  hasTenantCarrierRole = false,
  totalResultsCount,
  equipmentIdentifierTypes,
  isRecentDataSearched,
}) => {
  const { pickupStopInfo, deliveryStopInfo } = resultItem;
  const shouldShowCardBody = !!pickupStopInfo || !!deliveryStopInfo;
  const calculatePercentComplete = (percentAsDecimal = 0) => percentAsDecimal * 100;
  const selectedText = window.getSelection();
  const isEuHost = get(window, 'portalGlobal.isEu', false);
  const principalContext = React.useContext(PrincipalContext);
  const authorizations = getPrincipalAuthorizations(principalContext);
  const shouldHideBadge = authorizations.hasShipmentStatusBadgeHidden();
  const shouldUseRecentSearch = isRecentDataSearched();

  const filteredEqIdentifierTypes = (eqTypes: EquipmentIdentifierTypeEnum[]) => {
    if (isEuHost) {
      return filterEuIdentifierTypes(eqTypes);
    } else {
      return filterNaIdentifierTypes(eqTypes);
    }
  };

  /* Note: This on-click/key-down handling allows customers to highlight text within the shipment card w/o being rerouted
     while also ensuring the shipment list is accessible via key presses */
  const handleCardOnClickOrKeyDown = (e: React.MouseEvent | React.KeyboardEvent) => {
    const isDifferentDomainUrl = (resultItem.url || '').includes('https');
    /* If the user has highlighted text, do not reroute */
    const noSelectedText = !!selectedText && selectedText.toString().length === 0;
    /* Accessibility: Ensure a tab key press does not trigger a reroute */
    const isTabKeyPress = get(e, 'which') === 9;
    if (!isTabKeyPress) {
      if (noSelectedText) {
        trackEvent('SHIPMENT_LIST_CARD_CLICK', {
          shipmentId: get(resultItem, 'shipmentId'),
          mode: get(resultItem, 'mode'),
          totalResultsCount,
        });
        if (e.metaKey || e.ctrlKey) {
          if (isDifferentDomainUrl) {
            window.open(resultItem.url);
          } else {
            window.open(`${endpoints.BASE_PATH}${resultItem.url}`);
          }
        } else {
          if (isDifferentDomainUrl) {
            window.location.assign(resultItem.url);
          } else {
            history.push(`${resultItem.url}`, { shouldUseRecentSearch });
          }
        }
      }
    }
  };

  const displayStatusBadge = (timelinessCode: TimelinessCodeEnum): React.ReactNode | null => {
    if (timelinessCode === TimelinessCodeEnum.ON_TIME || timelinessCode === TimelinessCodeEnum.UNKNOWN) {
      return null;
    }
    return (
      <div style={{ marginRight: '10px' }} data-locator="badge-result-card">
        <StatusColorTag
          colorValue={TimelinessCodeStatusColor[timelinessCode]}
          messageText={TimelinessCodeStatusIntlKeys[timelinessCode]}
        />
      </div>
    );
  };

  const temperatureSensorTrackingData =
    (resultItem.sensorTracking &&
      resultItem.sensorTracking.filter((sensor) => sensor.type === SensorTypeEnum.TEMPERATURE)) ||
    [];

  const hasSensorThresholds =
    temperatureSensorTrackingData[0]?.sensor.maxValue && temperatureSensorTrackingData[0]?.sensor.minValue;

  return (
    <div>
      <div
        role="link"
        data-testid="shipment-search-result-card"
        tabIndex={0}
        className={styles.card}
        onClick={handleCardOnClickOrKeyDown}
        onKeyDown={handleCardOnClickOrKeyDown}
        data-locator={`shipment-result-${resultItem.shipmentId}`}
      >
        {temperatureMonitoringV2() && temperatureSensorTrackingData && hasSensorThresholds && (
          <div className={styles.temperatureIndicator}>
            <TemperatureIndicatorIcon sensorTrackingData={temperatureSensorTrackingData[0]} />
          </div>
        )}
        <Row className={styles.cardBody}>
          <Col xs={24} sm={8} className={styles.identifiersContainer}>
            <Row className="justify-content-between">
              <Col className="d-flex flex-sm-column" data-testid="shipment-search-result-card-identifiers">
                <ShipmentIdentifiers identifiers={resultItem.identifiers} mode={resultItem.mode} />
                {hasSearchMatchingDisplay && previousSearchTerm && matchedSearchItems(resultItem, previousSearchTerm)}
              </Col>
            </Row>
            <div>
              <p className={styles.carrierName}>{resultItem.carrierName}</p>
              {hasTenantCarrierRole ? <p className={styles.carrierName}>{resultItem.customerName}</p> : undefined}
              <p>{showActiveHoldsCount(resultItem.activeHoldsCount)}</p>
              <p>{!temperatureMonitoringV2() && showTemperatureInformation(resultItem, intl)}</p>
              {showLastUpdate(intl, resultItem.shipmentId, resultItem.lastUpdate)}
            </div>
          </Col>

          <Col className={styles.stopInfoContainer}>
            {shouldShowCardBody && (
              <div className="d-flex">
                <ShipmentStopInfo
                  stopInfo={pickupStopInfo}
                  shipmentId={resultItem.shipmentId}
                  shipmentIdentifiers={resultItem.identifiers}
                  carrierIdentifiers={resultItem.carrierIdentifiers || []}
                />
                <div className="d-flex align-items-center">
                  <img
                    src={arrowBack}
                    alt={intl.formatMessage({
                      id: 'shipmentList.listCardAltText.toArrow',
                      defaultMessage: 'To arrow',
                    })}
                    className={styles.arrow}
                  />
                </div>
                <ShipmentStopInfo
                  stopInfo={deliveryStopInfo}
                  shipmentId={resultItem.shipmentId}
                  shipmentIdentifiers={resultItem.identifiers}
                  carrierIdentifiers={resultItem.carrierIdentifiers || []}
                />
              </div>
            )}
          </Col>
        </Row>

        <footer>
          <Row noGutters className={styles.statusSection}>
            <Col className="d-flex justify-content-between align-items-center p-0">
              <span className="d-flex align-items-center">
                {resultItem.timelinessCode && displayStatusBadge(resultItem.timelinessCode)}
                {showStatusTag(resultItem, true, shouldHideBadge)}
                {showStatusText(resultItem, intl)}
              </span>
              <span className="d-none d-sm-flex">
                <ShipmentModeIcon mode={resultItem.mode} className={styles.modeIcon} small />
                <MinimalStatusBar
                  percentComplete={calculatePercentComplete(resultItem.percentComplete)}
                  shipmentStops={resultItem.stops}
                  derivedStatus={resultItem.derivedStatus}
                />
              </span>
            </Col>
          </Row>

          <Row>
            <ShareBadge
              showMultipleEntitlees={false}
              entitlementInfo={resultItem.entitlementInfo}
              className={styles.shareBadge}
            />
          </Row>
        </footer>
      </div>
      {resultItem.derivedStatus?.type === statusReason.PENDING_TRACKING_METHOD && hasTenantCarrierRole ? (
        <ResultCardEquipmentIdForm
          equipmentIdentifierTypes={filteredEqIdentifierTypes(equipmentIdentifierTypes)}
          shipmentId={resultItem.shipmentId}
          shipmentIdentifiers={resultItem.identifiers}
          capacityProviderId={resultItem.carrierID}
        />
      ) : null}
    </div>
  );
};

const showActiveHoldsCount = (activeHoldsCount?: number) => {
  const activeHoldsExist: boolean = !!activeHoldsCount && activeHoldsCount > 0;
  return (
    activeHoldsExist && (
      <p className="d-none d-sm-flex align-items-center">
        <span className={styles.activeHoldsLabel}>
          <FormattedMessage id="statusCards.result.holds" defaultMessage="Holds" />
        </span>
        <span className={styles.activeHoldsCountBubble}>{activeHoldsCount}</span>
      </p>
    )
  );
};

// #region temperature tracking
/*
NOTE: 09.2019 - temperature tracking was CNP-ed from legacy result card
but was not included in updated comps because no customers are currently using it
*/

const message = defineMessages({
  temperatureStatusUnderMin: {
    id: 'statusCards.result.temperatureStatusUnderMin',
    defaultMessage: 'TEMP BELOW RANGE',
  },
  temperatureStatusOverMax: {
    id: 'statusCards.result.temperatureStatusOverMax',
    defaultMessage: 'TEMP ABOVE RANGE',
  },
  temperatureStatusNormal: {
    id: 'statusCards.result.temperatureStatusNormal',
    defaultMessage: 'TEMP IN RANGE',
  },
});

const showTemperatureInformation = (resultItem: ShipmentSearchCardData, intl: IntlShape) => {
  let indicator = null;
  const statusReasonCode: string = resultItem?.derivedStatus?.statusReasonCode || '';
  if (ShipmentMode.TL === resultItem.mode && resultItem.sensorTracking) {
    resultItem.sensorTracking.forEach((s) => {
      // @ts-ignore
      if (SCHEDULE_TYPES[statusReasonCode]) {
        // @ts-ignore
        indicator = buildInvalidTemperatureStatus(SCHEDULE_TYPES[statusReasonCode].list);
      } else {
        switch (s.sensor.status) {
          case TEMPERATURE_STATUS.UNDER_MIN:
            indicator = buildTemperatureIndicator(
              underMin,
              'under-min',
              'under-min',
              intl.formatMessage(message.temperatureStatusUnderMin)
            );
            break;
          case TEMPERATURE_STATUS.OVER_MAX:
            indicator = buildTemperatureIndicator(
              overMax,
              'over-max',
              'over-max',
              intl.formatMessage(message.temperatureStatusOverMax)
            );
            break;
          case TEMPERATURE_STATUS.NORMAL:
            indicator = buildTemperatureIndicator(
              normal,
              'normal',
              'normal',
              intl.formatMessage(message.temperatureStatusNormal)
            );
            break;
          case TEMPERATURE_STATUS.UNKNOWN:
            indicator = null; // not sure if we show any message here.
            break;
          default:
            indicator = null;
        }
      }
    });
  }
  return indicator;
};

const buildInvalidTemperatureStatus = (text: string) => {
  return (
    <p className={styles.temperatureInfo}>
      <span className="normal">{text}</span>
    </p>
  );
};

const buildTemperatureIndicator = (src: string, alt: string, className: string, text: string) => {
  return (
    <p className={styles.temperatureInfo}>
      <img src={src} className="icon" alt={alt} />
      &nbsp;<span className={className}>{text}</span>
    </p>
  );
};
// #endregion temperature tracking

const showLastUpdate = (intl: any, shipmentId: string, lastUpdate?: any) => {
  return (
    !isNil(lastUpdate) && (
      <p className={styles.lastUpdateText} data-locator={`last-updated-at-timestamp-${shipmentId}`}>
        {get(lastUpdate, 'id')
          ? intl.formatMessage(
              { id: lastUpdate.id, defaultMessage: lastUpdate.defaultMessage },
              formatNestedIntlValues(get(lastUpdate, 'values', {}), intl)
            )
          : typeof lastUpdate === 'string' && lastUpdate}
      </p>
    )
  );
};
//#endregion card left panel

// #region card footer
export const showStatusTag = (resultItem: ShipmentSearchCardData, displayAdditionalText = true, shouldHide = false) => {
  if (shouldHide) {
    return null;
  }

  return (
    !!resultItem.flagText && (
      <span className={styles.tag}>
        <StatusColorTag
          messageText={resultItem.flagText}
          colorValue={resultItem.flagColor}
          inverted={resultItem.shouldInvertFlagColor}
          displayAdditionalText={displayAdditionalText}
        />
      </span>
    )
  );
};

const showStatusText = (resultItem: ShipmentSearchCardData, intl: IntlShape) => {
  const { statusText, statusDescription, currentEtaDescription } = resultItem;
  const statusDescriptionToDisplay: any = currentEtaDescription || statusDescription;

  return (
    <React.Fragment>
      {!!statusText && <span className={styles.statusText}>{statusText}</span>}
      {!!statusText && !!statusDescriptionToDisplay && <span className={styles.statusSeparator} />}
      {!!statusDescriptionToDisplay && (
        <span>
          {get(statusDescriptionToDisplay, 'id') ? (
            <FormattedMessage
              id={statusDescriptionToDisplay.id}
              defaultMessage={statusDescriptionToDisplay.defaultMessage}
              values={formatNestedIntlValues(get(statusDescriptionToDisplay, 'values', {}), intl)}
            />
          ) : (
            statusDescriptionToDisplay
          )}
        </span>
      )}
    </React.Fragment>
  );
};
// #endregion card footer

export default injectIntl(ShipmentSearchResultCard);
