import {
  CHECK_SESSION,
  SESSION_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  SWITCH_ACCOUNTS,
  PURGE,
  SESSION_RENEW_SUCCESS,
  SET_CUSTOMER_PROPERTIES,
} from './types';

export const checkSessionSuccess = (principal) => ({ type: SESSION_RENEW_SUCCESS, principal });

export const checkSessionFailure = () => ({ type: SESSION_FAILURE });

export const checkSession = () => ({
  type: CHECK_SESSION,
});

export const logout = () => ({
  type: LOGOUT,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFailure = (errors) => ({
  type: LOGOUT_FAILURE,
  payload: { errors },
});

export const switchAccounts = () => ({
  type: SWITCH_ACCOUNTS,
});

export const purgeSessionState = () => ({
  type: PURGE,
});

export const setCustomerProperties = (customerProperties) => ({
  type: SET_CUSTOMER_PROPERTIES,
  payload: customerProperties,
});
