import * as React from 'react';

/* eslint-disable @typescript-eslint/no-unused-vars */
import sendbirdKit, {
  Channel,
  withSendBird,
  SendBirdState,
  sendBirdSelectors,
  SendBirdSelectors,
} from 'sendbird-uikit';
import styled from 'styled-components';
import { PrincipalContext } from 'contexts/PrincipalContext';
import ChannelListHeader from './ChannelListHeader';
import ConversationHeader from './ConversationHeader';
import MemoChannelList from './ChannelList';
import { useCreateChannel, useGetUsersWithAccess } from './ChatHooks';

import 'sendbird-uikit/dist/index.css';

interface ChatProps extends WithSendbirdProps {
  shipmentId: string;
  additionalTenantsWithAccess: number[];
}

const ChatContainer = styled('div')`
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 7;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
const PersistantChatHeader = styled('div')`
  position: relative;
  top: 0;
`;

// eslint-disable-next-line react/display-name
const ChatApp: React.FC<ChatProps> = React.memo(({ shipmentId, ...props }) => {
  const principal = React.useContext(PrincipalContext);
  const [userId, setUserId] = React.useState<string | undefined>();
  const [tenantName, setTenantName] = React.useState<string | undefined>();
  const [tenantIds, setTenantIds] = React.useState<number[]>(props.additionalTenantsWithAccess);
  const [channelUrl, setChannelUrl] = React.useState('');
  const [isChannelListOpen, setIsChannelListOpen] = React.useState(false);
  const [isChatMinimized, setIsChatMinimized] = React.useState(false);

  const { sdk } = props;
  const usersWithAccessToThisChat = useGetUsersWithAccess({
    sdk,
    tenantIds: principal.tenantId ? [...tenantIds, principal.tenantId] : [...tenantIds],
  });
  const { createChannel, channel } = useCreateChannel({
    sdk,
    channelId: shipmentId,
    tenantIds,
  });

  if (principal && !userId && !tenantName) {
    setUserId(`${principal.id}:${principal.tenantId}`);
    setTenantIds((prevItems) => (principal.tenantId ? [...prevItems, principal.tenantId] : prevItems));
    setTenantName(principal.companyName);
  }

  React.useEffect(() => {
    if (channel) {
      setChannelUrl(channel.url);
    }
  }, [channel]);

  React.useEffect(() => {
    setTenantIds((prevItems) => {
      const mySet = new Set([...prevItems, ...props.additionalTenantsWithAccess]);
      // @ts-ignore
      return [...mySet];
    });
  }, [props.additionalTenantsWithAccess]);

  React.useEffect(() => {
    if (userId && sdk && usersWithAccessToThisChat.length) {
      createChannel(usersWithAccessToThisChat);
    }
  }, [userId, createChannel, sdk, principal, usersWithAccessToThisChat]);

  const handleSetChannel = React.useCallback((channelUrl: string) => {
    setChannelUrl(channelUrl);
  }, []);

  const handleSetChannelListOpen = React.useCallback(() => {
    setIsChannelListOpen((prevValue) => !prevValue);
  }, []);
  const renderConversationHeader = React.useCallback(
    (props: sendbirdKit.RenderChatHeaderProps) => {
      return (
        <PersistantChatHeader>
          <ConversationHeader {...props} setIsChatMinimized={setIsChatMinimized} isChatMinimized={isChatMinimized} />
        </PersistantChatHeader>
      );
    },
    [isChatMinimized]
  );

  const renderChannelListHeader = React.useCallback(() => {
    return <ChannelListHeader toggleOpen={handleSetChannelListOpen} />;
  }, [handleSetChannelListOpen]);

  return (
    <ChatContainer>
      {sdk && (
        <>
          {channelUrl && (
            <div className="sendbird-app__conversation-wrap" style={{ marginRight: '20px', maxWidth: '350px' }}>
              {isChatMinimized ? (
                <ConversationHeader
                  {...props}
                  setIsChatMinimized={setIsChatMinimized}
                  isChatMinimized={isChatMinimized}
                />
              ) : (
                <div style={{ maxHeight: '650px', overflow: 'scroll' }}>
                  <Channel channelUrl={channelUrl} useReaction renderChatHeader={renderConversationHeader} />
                </div>
              )}
            </div>
          )}
          <div
            style={{ height: isChannelListOpen ? '400px' : '64px', minHeight: isChannelListOpen ? '400px' : 'auto' }}
          >
            <MemoChannelList
              renderChannelListHeader={renderChannelListHeader}
              shipmentId={shipmentId}
              usersWithAccessToThisChat={usersWithAccessToThisChat}
              handleSetChannel={handleSetChannel}
              sdk={sdk}
            />
          </div>
        </>
      )}
    </ChatContainer>
  );
});

interface WithSendbirdProps {
  sdk: SendBirdSelectors.GetSdk;
}

function mapStateToProps(store: SendBirdState): WithSendbirdProps {
  const sdk = store && sendBirdSelectors.getSdk(store);
  return {
    sdk,
  };
}
export default withSendBird(ChatApp, mapStateToProps);
