import { CodeIntlKey } from 'models';

export enum LoadingStatus {
  LOADING,
  LOADED,
  ERROR,
}

export enum SearchServiceTypeEnum {
  SHIPMENT_SEARCH = 'shipments',
  ORDERS_SEARCH = 'orders',
  INVENTORY_SEARCH = 'inventory',
}

export const ReadableSearchOptionsIntlKeys: { [P in SearchServiceTypeEnum]: CodeIntlKey } = {
  [SearchServiceTypeEnum.SHIPMENT_SEARCH]: { id: 'multiOrderSearch.options.shipments', defaultMessage: 'Shipments' },
  [SearchServiceTypeEnum.ORDERS_SEARCH]: { id: 'multiOrderSearch.options.orders', defaultMessage: 'Orders' },
  [SearchServiceTypeEnum.INVENTORY_SEARCH]: { id: 'multiOrderSearch.options.inventory', defaultMessage: 'Inventory' },
};
export interface IMultiValueSearchModal {
  isOpen: boolean;
  modes: any;
  tags: any;
  text: string;
  multiSearchTerm: string;
  setMultiSearchTerm: (value: any) => void;
  handleCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleRemoveTag: Function;
  handleKeyPress: Function;
  handlePaste: Function;
  handleTextAreaChange: Function;
  handleClearAll: Function;
  handleSubmitSearch: (event: any) => any;
  hasAdvancedLinking: boolean | undefined;
  dataLocator: string;
  unmatchedTags: any;
  currentStep: number;
  setCurrentStep: Function;
  termAfterFind: string;
  setTermAfterFind: Function;
  errorMessage: string;
  setErrorMessage: Function;
  setSearchTerm: Function;
  serviceType: SearchServiceTypeEnum;
  setServiceType: (value: SearchServiceTypeEnum) => void;
}
