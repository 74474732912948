import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import profileIcon from '../../assets/profile-gray.svg';
import endpoints from '../../../.././common/endpoints';
import { withTheme } from '../../../../contexts/ThemeContext';
import colors from '../../../../styles/colors';

import './UserMenu.scss';

const UserActionWindow = styled.div`
  .user-action-window-row {
    .user-icon-text {
      color: ${colors.primaryTextColor};
    }
    svg path {
      fill: ${colors.darkBackgroundColor};
    }
    &:hover {
      .user-icon-text {
        color: ${(props) => props.theme.primaryColor};
      }
      svg path {
        fill: ${(props) => props.theme.primaryColor};
      }
    }
  }
`;

const loggedInUserInformation = (props) => {
  if (props && props.isLoggedIn && props.currentMenuState) {
    return (
      <UserActionWindow theme={props.theme} className="user-action-window">
        <div className="user-action-window__greeting">
          <span className="user-icon-text">
            {props.userFirstName ? (
              <FormattedMessage
                id="header.userActionWindow.greeting"
                defaultMessage="Hi, {username}!"
                values={{ username: props.userFirstName }}
              />
            ) : (
              ''
            )}
          </span>
        </div>
        <a
          href={endpoints.MY_PROFILE}
          target="_blank"
          rel="noopener noreferrer"
          className="user-action-window-row user-action-pointer"
          onClick={props.onMyProfileClick}
        >
          <svg
            className="user-icon-attributes"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g fill="none" fillRule="evenodd">
              <path
                fill="#00558B"
                fillRule="nonzero"
                d="M12 4a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4z"
              />
            </g>
          </svg>
          <span className="user-icon-text">
            <FormattedMessage id="header.userActionWindow.myProfile" defaultMessage="My Profile" />
          </span>
        </a>
        <button
          className="user-action-window-row user-action-pointer plainWrapperButton"
          onClick={props.onSignoutClick}
          data-locator="user-action-sign-out"
        >
          <svg
            className="user-icon-attributes"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g fill="none" fillRule="evenodd">
              <path
                fill="#00558B"
                fillRule="nonzero"
                d="M14.08 15.59L16.67 13H7v-2h9.67l-2.59-2.59L15.5 7l5 5-5 5-1.42-1.41zM19 3a2 2 0 0 1 2 2v4.67l-2-2V5H5v14h14v-2.67l2-2V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2h14z"
              />
            </g>
          </svg>
          <span className="user-icon-text">
            <FormattedMessage id="header.userActionWindow.signOut" defaultMessage="Sign out" />
          </span>
        </button>
      </UserActionWindow>
    );
  }
};

const getIconAdditionalStyles = (props) => {
  const isLoggedIn = props ? props.isLoggedIn : false;
  return {
    cursor: isLoggedIn ? 'pointer' : 'default',
  };
};

const messages = defineMessages({
  profile: {
    id: 'header.userMenu.profile',
    defaultMessage: 'Profile',
  },
});

/**
 * UserMenu component
 */
const UserMenu = (props) => {
  const { intl } = props;
  return (
    <div className="user-menu-container" ref={props.reference}>
      <button onClick={() => props.onUserMenuToggleClick(!props.currentMenuState)} className="plainWrapperButton">
        <img
          src={profileIcon}
          className="user-menu-icon"
          data-locator="user-menu-button"
          style={getIconAdditionalStyles(props)}
          alt={intl.formatMessage(messages.profile)}
        />
      </button>
      {loggedInUserInformation(props)}
    </div>
  );
};

UserMenu.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  currentMenuState: PropTypes.bool.isRequired,
  userLastName: PropTypes.string,
  onUserMenuToggleClick: PropTypes.func.isRequired,
  onMyProfileClick: PropTypes.func.isRequired,
  onSignoutClick: PropTypes.func.isRequired,
};

export default injectIntl(withTheme(UserMenu));
