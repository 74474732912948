import { createRef, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'ui-components';
import { ShipmentModeEnum } from 'models';
import { trackEvent } from 'common/eventTracker';
import ShareWindow from './ShareWindow/ShareWindow';

import * as styles from './ShipmentShare.module.scss';
import { PrincipalAuthorizations } from '../../common/authorizations';

export interface ShipmentShareComponentProps {
  shipmentId?: string;
  shipmentMode?: ShipmentModeEnum;
  masterShipmentId?: string;
  isSendingEmail: boolean;
  shareLink?: string;
  showShareWindow: boolean;
  toggleShareWindow: () => void;
  reset: () => void;
  fetchShareLink: (authorizations: PrincipalAuthorizations) => void;
  sendShareEmail: (emailAddress: string[], message: string) => void;
  authorizations: PrincipalAuthorizations;
}

class ShipmentShareComponent extends Component<ShipmentShareComponentProps> {
  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, { capture: true });
  }

  public componentDidUpdate(prevProps: ShipmentShareComponentProps) {
    if (
      prevProps.shipmentId !== this.props.shipmentId ||
      prevProps.shipmentMode !== this.props.shipmentMode ||
      prevProps.masterShipmentId !== this.props.masterShipmentId
    ) {
      this.props.reset();
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.props.reset();
  }

  public wrapperRef = createRef<HTMLDivElement>();

  public handleClickOutside = (event: MouseEvent) => {
    if (
      this.wrapperRef.current &&
      this.props.showShareWindow &&
      !this.wrapperRef.current.contains(event.target as Node)
    ) {
      this.props.toggleShareWindow();
    }
  };

  public render() {
    return (
      <div id="shipmentShare" className={styles.shipmentShare} ref={this.wrapperRef}>
        <Button
          size="lg"
          clickFn={() => {
            trackEvent('SHIPMENT_DETAIL_PAGE_SHARE_CLICK', {
              shipmentId: this.props.masterShipmentId || this.props.shipmentId || '',
              mode: this.props.shipmentMode || '',
            });
            this.props.toggleShareWindow();
          }}
        >
          <i className="material-icons left flipped">reply</i>
          <span>
            <FormattedMessage id="shipmentDetails.shareLink" defaultMessage="Share" />
          </span>
        </Button>
        {this.props.showShareWindow && (
          <ShareWindow
            publicLink={this.props.shareLink}
            isSendingEmail={this.props.isSendingEmail}
            sendShareEmail={this.props.sendShareEmail}
            generateShareLink={this.props.fetchShareLink}
            toggleShareWindow={this.props.toggleShareWindow}
            authorizations={this.props.authorizations}
          />
        )}
      </div>
    );
  }
}

export default ShipmentShareComponent;
