export enum ShipmentModeEnum {
  TL = 'TL',
  LTL = 'LTL',
  OCEAN = 'OCEAN',
  RAIL = 'RAIL',
  INTERMODAL = 'INTERMODAL',
  AIR = 'AIR',
  PARCEL = 'PARCEL',
}

export const ShipmentModeFullNameMap: { [P in ShipmentModeEnum]: string } = {
  [ShipmentModeEnum.TL]: 'TRUCKLOAD',
  [ShipmentModeEnum.LTL]: 'LTL',
  [ShipmentModeEnum.OCEAN]: 'OCEAN',
  [ShipmentModeEnum.AIR]: 'AIR',
  [ShipmentModeEnum.RAIL]: 'RAIL',
  [ShipmentModeEnum.INTERMODAL]: 'INTERMODAL',
  [ShipmentModeEnum.PARCEL]: 'PARCEL',
};
