import classNames from 'classnames';
import { FunctionComponent, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'ui-components';
import { PrincipalContext } from 'contexts/PrincipalContext';
import matrixIcon from '../../../../common/assets/matrix-gray.svg';
import profileIcon from '../../../../common/assets/profile-gray.svg';
import generateLinks from '../../../../common/footer/links/generateLinks';
import { Theme } from '../../models/Theme';

import * as styles from './ControlsPreview.module.scss';

export interface ControlsPreviewProps {
  selectedTheme: Theme;
}

const ControlsPreview: FunctionComponent<ControlsPreviewProps> = ({ selectedTheme }) => {
  const principalContext = useContext(PrincipalContext);
  const footerLinksConfig = generateLinks(principalContext);
  return (
    <div className={styles.controlsPreview}>
      <div className={styles.navbar}>
        <div className={styles.logoAndMenuWrapper}>
          <img src={selectedTheme.logoUrl} alt="Logo" className={styles.logo} />
          <div className={styles.menu}>
            <div
              className={classNames(styles.menuItem, styles.selected)}
              style={{
                borderColor: selectedTheme.primaryColor,
                color: selectedTheme.primaryColor,
              }}
            >
              <span>
                <FormattedMessage
                  id="settings.brandingTheming.controlsPreview.controlsPreviewSelected"
                  defaultMessage="SELECTED"
                />
              </span>
            </div>
            <div className={styles.menuItem}>
              <span>
                <FormattedMessage
                  id="settings.brandingTheming.controlsPreview.controlsPreviewHeader"
                  defaultMessage="HEADER"
                />
              </span>
            </div>
          </div>
        </div>
        <div className={styles.icons}>
          <img src={matrixIcon} alt="App Menu" className={styles.icon} />
          <img src={profileIcon} alt="Profile Menu" className={styles.icon} />
        </div>
      </div>
      <div className={styles.buttonAndFooterWrapper}>
        <Button type="primary" className={styles.button} theme={selectedTheme}>
          <FormattedMessage
            id="settings.brandingTheming.controlsPreview.controlsPreviewButton"
            defaultMessage="Button"
          />
        </Button>
        <div className={styles.footer} style={{ backgroundColor: selectedTheme.footerColor }}>
          {footerLinksConfig.map((footerLinkGroup) => {
            return (
              <div key={footerLinkGroup.groupName.id} className={styles.footerGroup}>
                <div className={styles.footerGroupName}>
                  <FormattedMessage
                    id={footerLinkGroup.groupName.id}
                    defaultMessage={footerLinkGroup.groupName.defaultMessage}
                  />
                </div>
                {footerLinkGroup.links.map((link) => {
                  return (
                    <div key={link.linkText.id} className={styles.footerLink}>
                      <FormattedMessage id={link.linkText.id} defaultMessage={link.linkText.defaultMessage} />
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div className={styles.footerGroup}>
            <div className={styles.footerGroupName}>
              <FormattedMessage
                id="settings.brandingTheming.controlsPreview.controlsPreviewFooterLinksTitle"
                defaultMessage="Have an Issue?"
              />
            </div>
            <div className={styles.footerLink}>
              <FormattedMessage
                id="settings.brandingTheming.controlsPreview.controlsPreviewFooterLinkSupportMail"
                values={{ email: 'support@project44.com' }}
                defaultMessage="Contact us at {email}"
              />
            </div>
            <div className={styles.footerLink}>
              <FormattedMessage
                id="settings.brandingTheming.controlsPreview.controlsPreviewFooterLinkSupportText"
                defaultMessage="Go to Support Portal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlsPreview;
