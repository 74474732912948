import { defineMessages, createIntl, createIntlCache } from 'react-intl';
import { ShipmentModeEnum, UnifiedModelStatusCodeEnum, unifiedModelMessages, ShipmentStopTypeEnum } from 'models';
import { locale, messages } from '../../.././i18n/configurei18n';

const cache = createIntlCache();
export const intl = createIntl({ locale, messages }, cache);

const message = defineMessages({
  subscriptionEmail: {
    id: 'tenantNotifications.constants.subscriptionEmail',
    defaultMessage: 'Email',
  },
  subscriptionSMS: {
    id: 'tenantNotifications.constants.subscriptionSMS',
    defaultMessage: 'SMS',
  },
  subscriptionPushNotification: {
    id: 'tenantNotifications.constants.subscriptionPushNotification',
    defaultMessage: 'Push Notification',
  },
  shipmentAny: {
    id: 'tenantNotifications.constants.shipmentAny',
    defaultMessage: 'Any',
  },
  shipmentAll: {
    id: 'tenantNotifications.constants.shipmentAll',
    defaultMessage: 'All',
  },
  outForDelivery: {
    id: 'tenantNotifications.constants.outForDelivery',
    defaultMessage: 'Out for Delivery',
  },
  outForDeliveryMissed: {
    id: 'tenantNotifications.constants.outForDeliveryMissed',
    defaultMessage: 'Out for Delivery Missed (not out for delivery)',
  },
  missedDeliveryWindow: {
    id: 'tenantNotifications.constants.missedDeliveryWindow',
    defaultMessage: 'Missed Delivery Window',
  },
  forMoreThan: {
    id: 'tenantNotifications.constants.forMoreThan',
    defaultMessage: 'FOR MORE THAN',
  },
  hours: {
    id: 'tenantNotifications.constants.hours',
    defaultMessage: 'HOURS',
  },
  before: {
    id: 'tenantNotifications.constants.before',
    defaultMessage: 'Before',
  },
  after: {
    id: 'tenantNotifications.constants.after',
    defaultMessage: 'After',
  },
  endOfAppointmentWindow: {
    id: 'tenantNotifications.constants.endOfAppointmentWindow',
    defaultMessage: 'END OF APPOINTMENT WINDOW',
  },
  idleInTransit: {
    id: 'tenantNotifications.constants.idleInTransit',
    defaultMessage: 'Idle in transit',
  },
  minutes: {
    id: 'tenantNotifications.constants.minutes',
    defaultMessage: 'MINUTES',
  },
  to: {
    id: 'tenantNotifications.constants.to',
    defaultMessage: 'TO',
  },
  anyStop: {
    id: 'tenantNotifications.constants.anyStop',
    defaultMessage: 'Any Stop',
  },
  pickup: {
    id: 'tenantNotifications.constants.pickup',
    defaultMessage: 'Pickup',
  },
  delivery: {
    id: 'tenantNotifications.constants.delivery',
    defaultMessage: 'Delivery',
  },
  temperatureOutOfRange: {
    id: 'tenantNotifications.constants.temperatureOutOfRange',
    defaultMessage: 'Temperature out of range',
  },
  allUpdates: {
    id: 'tenantNotifications.constants.allUpdates',
    defaultMessage: 'All tracking updates',
  },
  shipmentPendingTrackingMethod: {
    id: 'tenantNotifications.constants.ShipmentPendingTrackingMethod',
    defaultMessage: 'Shipment pending tracking method',
  },
  departed: {
    id: 'tenantNotifications.constants.departed',
    defaultMessage: 'Departed',
  },
  departedLate: {
    id: 'tenantNotifications.constants.departedLate',
    defaultMessage: 'Departed Late',
  },
  from: {
    id: 'tenantNotifications.constants.from',
    defaultMessage: 'FROM',
  },
  at: {
    id: 'tenantNotifications.constants.at',
    defaultMessage: 'AT',
  },
  atStop: {
    id: 'tenantNotifications.constants.atStop',
    defaultMessage: 'At Stop',
  },
  dwelling: {
    id: 'tenantNotifications.constants.dwelling',
    defaultMessage: 'Dwelling',
  },
  arriving: {
    id: 'tenantNotifications.constants.arriving',
    defaultMessage: 'Arriving',
  },
  withinTheNext: {
    id: 'tenantNotifications.constants.withinTheNext',
    defaultMessage: 'WITHIN THE NEXT',
  },
  trackingApprovalPending: {
    id: 'tenantNotifications.constants.trackingApprovalPending',
    defaultMessage: 'Tracking approval pending',
  },
  lessThan: {
    id: 'tenantNotifications.constants.lessThan',
    defaultMessage: 'LESS THAN',
  },
  beforeStartOfAppointmentWindow: {
    id: 'tenantNotifications.constants.beforeStartOfAppointmentWindow',
    defaultMessage: 'BEFORE START OF APPOINTMENT WINDOW',
  },
  runningLate: {
    id: 'tenantNotifications.constants.runningLate',
    defaultMessage: 'Running late',
  },
  byMoreThan: {
    id: 'tenantNotifications.constants.byMoreThan',
    defaultMessage: 'BY MORE THAN',
  },
  trackingApprovalDenied: {
    id: 'tenantNotifications.constants.trackingApprovalDenied',
    defaultMessage: 'Tracking approval denied',
  },
  customer: {
    id: 'tenantNotifications.constants.customer',
    defaultMessage: 'Customer',
  },
  selectedStopCustomer: {
    id: 'tenantNotifications.constants.selectedStopCustomer',
    defaultMessage: 'Customer at Selected Stop',
  },
  anyStopCustomer: {
    id: 'tenantNotifications.constants.anyStopCustomer',
    defaultMessage: 'Customer at Any Stop',
  },
  futureStopsCustomer: {
    id: 'tenantNotifications.constants.futureStopsCustomer',
    defaultMessage: 'Customer at Future Stops',
  },
  location: {
    id: 'tenantNotifications.constants.location',
    defaultMessage: 'Location',
  },
  carrier: {
    id: 'tenantNotifications.constants.carrier',
    defaultMessage: 'Carrier',
  },
  booked: {
    id: 'tenantNotifications.constants.booked',
    defaultMessage: 'Booked',
  },
  delivered: {
    id: 'tenantNotifications.constants.delivered',
    defaultMessage: 'Delivered',
  },
  anyStatusUpdated: {
    id: 'tenantNotifications.constants.anyStatusUpdated',
    defaultMessage: 'An event occurs',
  },
  portOfLoading: {
    id: 'tenantNotifications.constants.portOfLoading',
    defaultMessage: 'Port of loading',
  },
  transshipmentPort: {
    id: 'tenantNotifications.constants.transshipmentPort',
    defaultMessage: 'Transshipment port',
  },
  portOfDischarge: {
    id: 'tenantNotifications.constants.portOfDischarge',
    defaultMessage: 'Port of discharge',
  },
  anyPort: {
    id: 'tenantNotifications.constants.anyPort',
    defaultMessage: 'Any port',
  },
  embarkingLate: {
    id: 'tenantNotifications.constants.embarkingLate',
    defaultMessage: 'Embarking late',
  },
  arrivingLate: {
    id: 'tenantNotifications.constants.arrivingLate',
    defaultMessage: 'Arriving late',
  },
  selectEvent: {
    id: 'tenantNotifications.constants.selectEvent',
    defaultMessage: 'Select event',
  },
  country: {
    id: 'tenantNotifications.constants.country',
    defaultMessage: 'Country',
  },
  origin: {
    id: 'tenantNotifications.constants.origin',
    defaultMessage: 'Origin',
  },
  destination: {
    id: 'tenantNotifications.constants.destination',
    defaultMessage: 'Destination',
  },
  transfer: {
    id: 'tenantNotifications.constants.transfer',
    defaultMessage: 'Transfer',
  },
  other: {
    id: 'tenantNotifications.constants.other',
    defaultMessage: 'Other',
  },
  anyAirStop: {
    id: 'tenantNotifications.constants.anyAirStop',
    defaultMessage: 'Any Stop',
  },
  shipmentDeleted: {
    id: 'tenantNotifications.constants.shipmentDeleted',
    defaultMessage: 'Shipment deleted',
  },
});

export const ModeTypeConstants = {
  TL: 'TL',
  LTL: 'LTL',
  PARCEL: 'PARCEL',
  AIR: 'AIR',
  OCEAN: 'OCEAN',
};

export const TLEventTypeKeyConstants = {
  RunningLate: 'RUNNING_LATE',
  Dwelling: 'DWELLING',
  IdleInTransit: 'IDLE_IN_TRANSIT',
  TrackingApprovalDenied: 'TRACKING_APPROVAL_DENIED',
  TrackingApprovalPending: 'TRACKING_APPROVAL_PENDING',
  Arriving: 'ARRIVING',
  Departed: 'DEPARTED',
  ShipmentPendingTrackingMethod: 'SHIPMENT_PENDING_TRACKING_METHOD',
  TemperatureOutOfRange: 'TEMPERATURE_OUT_OF_RANGE',
  AllUpdates: 'ALL_UPDATES',
  DepartedLate: 'DEPARTED_LATE',
  //Not sure if this is needed
  ShipmentPendingCarrier: 'SHIPMENT_PENDING_CARRIER',
  ShipmentDeleted: 'SHIPMENT_DELETED',
};

export const LTLEventTypeKeyConstants = {
  OutForDelivery: 'OUT_FOR_DELIVERY',
  OutForDeliveryMissed: 'OUT_FOR_DELIVERY_MISSED',
};

export const ParcelEventTypeKeyConstants = {
  OutForDelivery: 'PARCEL_OUT_FOR_DELIVERY',
  MissedDeliveryWindow: 'PARCEL_MISSED_DELIVERY_WINDOW',
  RunningLate: 'PARCEL_RUNNING_LATE',
};

export const AirEventTypeKeyConstants = {
  Booked: 'AIR_BOOKED',
  Delivered: 'AIR_DELIVERED',
  AnyStatusUpdated: 'AIR_ANY_STATUS_UPDATED',
};

export const OceanEventTypeKeyConstants = {
  ArrivingLate: 'OCEAN_ARRIVAL_RUNNING_LATE',
  EmbarkingLate: 'OCEAN_DEPARTURE_RUNNING_LATE',
};

export const EventNotificationTargetTypeConstantKeys = {
  Customer: 'Customer',
  SelectedStopCustomer: 'Customer at Selected Stop',
  AnyStopCustomer: 'Customer at Any Stop',
  FutureStopsCustomer: 'Customer at Future Stops',
  Location: 'Location',
  Carrier: 'Carrier',
};
export const EventNotificationTargetTypeConstantsTL = {
  [EventNotificationTargetTypeConstantKeys.SelectedStopCustomer]: intl.formatMessage(message.selectedStopCustomer),
  [EventNotificationTargetTypeConstantKeys.AnyStopCustomer]: intl.formatMessage(message.anyStopCustomer),
  [EventNotificationTargetTypeConstantKeys.FutureStopsCustomer]: intl.formatMessage(message.futureStopsCustomer),
  [EventNotificationTargetTypeConstantKeys.Location]: intl.formatMessage(message.location),
  [EventNotificationTargetTypeConstantKeys.Carrier]: intl.formatMessage(message.carrier),
};

export const EventNotificationTargetTypeConstants = {
  [EventNotificationTargetTypeConstantKeys.Customer]: intl.formatMessage(message.customer),
  [EventNotificationTargetTypeConstantKeys.Location]: intl.formatMessage(message.location),
  [EventNotificationTargetTypeConstantKeys.Carrier]: intl.formatMessage(message.carrier),
};

export const newEventNotificationTargetTypeConstantsMap = new Map([
  [EventNotificationTargetTypeConstantKeys.SelectedStopCustomer, intl.formatMessage(message.selectedStopCustomer)],
  [EventNotificationTargetTypeConstantKeys.AnyStopCustomer, intl.formatMessage(message.anyStopCustomer)],
  [EventNotificationTargetTypeConstantKeys.FutureStopsCustomer, intl.formatMessage(message.futureStopsCustomer)],
  [EventNotificationTargetTypeConstantKeys.Location, intl.formatMessage(message.location)],
  [EventNotificationTargetTypeConstantKeys.Carrier, intl.formatMessage(message.carrier)],
]);

export const EventNotificationTargetTypeConstantsMap = new Map([
  [EventNotificationTargetTypeConstantKeys.Customer, intl.formatMessage(message.customer)],
  [EventNotificationTargetTypeConstantKeys.Location, intl.formatMessage(message.location)],
  [EventNotificationTargetTypeConstantKeys.Carrier, intl.formatMessage(message.carrier)],
]);

export const EventNotificationTargetTypeConstantsByMode = {
  [ShipmentModeEnum.TL]: EventNotificationTargetTypeConstantsTL,
  [ShipmentModeEnum.LTL]: EventNotificationTargetTypeConstants,
  [ShipmentModeEnum.PARCEL]: EventNotificationTargetTypeConstants,
  [ShipmentModeEnum.AIR]: {},
  [ShipmentModeEnum.OCEAN]: {},
};

export const SUGGESTIONS_MAP = {
  Location: 'location',
  Carrier: 'carrier',
  Subscriber: 'subscriber',
  Customer: 'company',
  [EventNotificationTargetTypeConstantKeys.SelectedStopCustomer]: 'company',
  [EventNotificationTargetTypeConstantKeys.AnyStopCustomer]: 'company',
  [EventNotificationTargetTypeConstantKeys.FutureStopsCustomer]: 'company',
};

export const TLEventTypeKeyDisplayConstants = new Map([
  [TLEventTypeKeyConstants.RunningLate, intl.formatMessage(message.runningLate)],
  [TLEventTypeKeyConstants.Dwelling, intl.formatMessage(message.dwelling)],
  [TLEventTypeKeyConstants.IdleInTransit, intl.formatMessage(message.idleInTransit)],
  [TLEventTypeKeyConstants.TrackingApprovalDenied, intl.formatMessage(message.trackingApprovalDenied)],
  [TLEventTypeKeyConstants.TrackingApprovalPending, intl.formatMessage(message.trackingApprovalPending)],
  [TLEventTypeKeyConstants.Arriving, intl.formatMessage(message.arriving)],
  [TLEventTypeKeyConstants.Departed, intl.formatMessage(message.departed)],
  [TLEventTypeKeyConstants.DepartedLate, intl.formatMessage(message.departedLate)],
  [TLEventTypeKeyConstants.ShipmentPendingTrackingMethod, intl.formatMessage(message.shipmentPendingTrackingMethod)],
  [TLEventTypeKeyConstants.TemperatureOutOfRange, intl.formatMessage(message.temperatureOutOfRange)],
  [TLEventTypeKeyConstants.ShipmentDeleted, intl.formatMessage(message.shipmentDeleted)],
]);

export const LTLEventTypeKeyDisplayConstants = new Map([
  [LTLEventTypeKeyConstants.OutForDelivery, intl.formatMessage(message.outForDelivery)],
  [LTLEventTypeKeyConstants.OutForDeliveryMissed, intl.formatMessage(message.outForDeliveryMissed)],
]);

export const ParcelEventTypeKeyDisplayConstants = new Map([
  [ParcelEventTypeKeyConstants.OutForDelivery, intl.formatMessage(message.outForDelivery)],
  [ParcelEventTypeKeyConstants.MissedDeliveryWindow, intl.formatMessage(message.missedDeliveryWindow)],
  [ParcelEventTypeKeyConstants.RunningLate, intl.formatMessage(message.runningLate)],
]);

export const AirEventTypeKeyDisplayConstants = new Map([
  [AirEventTypeKeyConstants.Booked, intl.formatMessage(message.booked)],
  [AirEventTypeKeyConstants.Delivered, intl.formatMessage(message.delivered)],
  [AirEventTypeKeyConstants.AnyStatusUpdated, intl.formatMessage(message.anyStatusUpdated)],
]);

export const OceanEventTypeKeyDisplayConstants = new Map([
  [OceanEventTypeKeyConstants.ArrivingLate, intl.formatMessage(message.arrivingLate)],
  [OceanEventTypeKeyConstants.EmbarkingLate, intl.formatMessage(message.embarkingLate)],
]);

const TimeConstraintParameterConstants = {
  ByMoreThan: intl.formatMessage(message.byMoreThan),
  ForMoreThan: intl.formatMessage(message.forMoreThan),
  WithinTheNext: intl.formatMessage(message.withinTheNext),
  LessThan: intl.formatMessage(message.lessThan),
  Minutes: intl.formatMessage(message.minutes),
  Hours: intl.formatMessage(message.hours),
  EndOfAppointmentWindow: intl.formatMessage(message.endOfAppointmentWindow),
  None: '',
};

const TimeConstraintParameterSuffixConstants = {
  BeforeAppointment: intl.formatMessage(message.beforeStartOfAppointmentWindow),
  None: '',
};

export const ShipmentAccessLevelConstants = {
  Tenant: 'TENANT',
  Shipment: 'SHIPMENT',
};

export const EventNotificationTargetConstants = {
  Customers: ['WALGREENS', 'CVS', 'TARGET', 'COSTCO', 'FEDEX FREIGHT'],
  Locations: ['WALGREENS EVANSTON', 'CVS NORTHBROOK', 'TARGET STATE ST', 'COSTCO LINCOLN PARK'],
  Carriers: ['FEDEX', 'DHL', 'UPS', 'ENGLAND LOGISTICS'],
};

export const StopLocationTypes = {
  Pickup: 'PICKUP',
  Delivery: 'DELIVERY',
  IntermediateStop: 'ANY_STOP',
  portOfLoading: 'PORT_OF_LOADING',
  transshipmentPort: 'TRANSSHIPMENT_PORT',
  portOfDischarge: 'PORT_OF_DISCHARGE',
  anyPort: 'ANY_PORT',
};

export const SubscriptionTypes = {
  Email: 'EMAIL',
  SMS: 'SMS',
  Push: 'PUSH',
};

export const filterOperators = {
  And: 'All',
  Or: 'Any',
};

export const ShipmentNotificationRuleConstants = {
  eventNotificationTargets: new Map([
    [EventNotificationTargetTypeConstants.Carrier, EventNotificationTargetConstants.Carriers],
    [EventNotificationTargetTypeConstants.Customer, EventNotificationTargetConstants.Customers],
    [EventNotificationTargetTypeConstants.Location, EventNotificationTargetConstants.Locations],
  ]),
  eventTypeTimeConstraintParameters: new Map([
    [TLEventTypeKeyConstants.RunningLate, TimeConstraintParameterConstants.ByMoreThan],
    [TLEventTypeKeyConstants.Departed, TimeConstraintParameterConstants.ByMoreThan],
    [TLEventTypeKeyConstants.DepartedLate, TimeConstraintParameterConstants.ByMoreThan],
    [TLEventTypeKeyConstants.Dwelling, TimeConstraintParameterConstants.ForMoreThan],
    [TLEventTypeKeyConstants.IdleInTransit, TimeConstraintParameterConstants.ForMoreThan],
    [TLEventTypeKeyConstants.Arriving, TimeConstraintParameterConstants.WithinTheNext],
    [TLEventTypeKeyConstants.TemperatureOutOfRange, TimeConstraintParameterConstants.ForMoreThan],
    [TLEventTypeKeyConstants.TrackingApprovalPending, TimeConstraintParameterConstants.LessThan],
    [LTLEventTypeKeyConstants.OutForDeliveryMissed, TimeConstraintParameterConstants.ForMoreThan],
    [ParcelEventTypeKeyConstants.RunningLate, TimeConstraintParameterConstants.ByMoreThan],
    [OceanEventTypeKeyConstants.EmbarkingLate, TimeConstraintParameterConstants.ByMoreThan],
    [OceanEventTypeKeyConstants.ArrivingLate, TimeConstraintParameterConstants.ByMoreThan],
    [AirEventTypeKeyConstants.AnyStatusUpdated, TimeConstraintParameterConstants.ByMoreThan],
  ]),
  eventTypeTimeConstraintSuffixParameters: new Map([
    [TLEventTypeKeyConstants.TrackingApprovalPending, TimeConstraintParameterSuffixConstants.BeforeAppointment],
    [LTLEventTypeKeyConstants.OutForDeliveryMissed, TimeConstraintParameterConstants.EndOfAppointmentWindow],
  ]),
  eventTypeTimeConstraintValueParameters: new Map([
    [LTLEventTypeKeyConstants.OutForDeliveryMissed, TimeConstraintParameterConstants.Hours],
    [TLEventTypeKeyConstants.RunningLate, TimeConstraintParameterConstants.Minutes],
    [TLEventTypeKeyConstants.DepartedLate, TimeConstraintParameterConstants.Minutes],
    [TLEventTypeKeyConstants.Dwelling, TimeConstraintParameterConstants.Minutes],
    [TLEventTypeKeyConstants.IdleInTransit, TimeConstraintParameterConstants.Minutes],
    [TLEventTypeKeyConstants.Arriving, TimeConstraintParameterConstants.Minutes],
    [TLEventTypeKeyConstants.Departed, TimeConstraintParameterConstants.Minutes],
    [TLEventTypeKeyConstants.TemperatureOutOfRange, TimeConstraintParameterConstants.Minutes],
    [ParcelEventTypeKeyConstants.RunningLate, TimeConstraintParameterConstants.Minutes],
    [OceanEventTypeKeyConstants.EmbarkingLate, TimeConstraintParameterConstants.Hours],
    [OceanEventTypeKeyConstants.ArrivingLate, TimeConstraintParameterConstants.Hours],
  ]),
  eventTypeTimeBoundaryMinuteValues: [15, 30, 45, 60, 75, 90, 105, 120],
  shipmentInclusionCriteriaValuesNoDropdownItems: [
    {
      id: 'And',
      display: intl.formatMessage(message.shipmentAll),
    },
  ],
  shipmentInclusionCriteriaValues: [
    {
      id: 'Or',
      display: intl.formatMessage(message.shipmentAny),
    },
    {
      id: 'And',
      display: intl.formatMessage(message.shipmentAll),
    },
  ],
  eventTypeStopParameterValues: new Map([
    [TLEventTypeKeyConstants.RunningLate, intl.formatMessage(message.to)],
    [TLEventTypeKeyConstants.Dwelling, intl.formatMessage(message.at)],
    [TLEventTypeKeyConstants.IdleInTransit, intl.formatMessage(message.to)],
    [TLEventTypeKeyConstants.Arriving, intl.formatMessage(message.at)],
    [TLEventTypeKeyConstants.Departed, intl.formatMessage(message.from)],
    [TLEventTypeKeyConstants.DepartedLate, intl.formatMessage(message.from)],
    [OceanEventTypeKeyConstants.ArrivingLate, intl.formatMessage(message.at)],
    [OceanEventTypeKeyConstants.EmbarkingLate, intl.formatMessage(message.at)],
    [AirEventTypeKeyConstants.AnyStatusUpdated, intl.formatMessage(message.atStop)],
  ]),

  eventTypeStatusCodeParameterValues: new Map([
    [AirEventTypeKeyConstants.AnyStatusUpdated, intl.formatMessage(message.selectEvent)],
  ]),

  eventTypeCountryParameterValues: new Map([
    [AirEventTypeKeyConstants.AnyStatusUpdated, intl.formatMessage(message.country)],
    [OceanEventTypeKeyConstants.ArrivingLate, intl.formatMessage(message.country)],
    [OceanEventTypeKeyConstants.EmbarkingLate, intl.formatMessage(message.country)],
  ]),

  tlContactMethodValues: [
    {
      type: SubscriptionTypes.Email,
      display: intl.formatMessage(message.subscriptionEmail),
    },
    {
      type: SubscriptionTypes.SMS,
      display: intl.formatMessage(message.subscriptionSMS),
    },
    {
      type: SubscriptionTypes.Push,
      display: intl.formatMessage(message.subscriptionPushNotification),
    },
  ],
  ltlContactMethodValues: [
    {
      type: SubscriptionTypes.Email,
      display: intl.formatMessage(message.subscriptionEmail),
    },
    {
      type: SubscriptionTypes.SMS,
      display: intl.formatMessage(message.subscriptionSMS),
    },
  ],
  parcelContactMethodValues: [
    {
      type: SubscriptionTypes.Email,
      display: intl.formatMessage(message.subscriptionEmail),
    },
    {
      type: SubscriptionTypes.SMS,
      display: intl.formatMessage(message.subscriptionSMS),
    },
  ],
  airContactMethodValues: [
    {
      type: SubscriptionTypes.Email,
      display: intl.formatMessage(message.subscriptionEmail),
    },
    {
      type: SubscriptionTypes.SMS,
      display: intl.formatMessage(message.subscriptionSMS),
    },
  ],
  oceanContactMethodValues: [
    {
      type: SubscriptionTypes.Email,
      display: intl.formatMessage(message.subscriptionEmail),
    },
  ],
  ltlBeforeAfterApptWindowValues: [
    {
      type: 'BEFORE',
      display: intl.formatMessage(message.before),
    },
    {
      type: 'AFTER',
      display: intl.formatMessage(message.after),
    },
  ],
  oceanEventTypeStopLocationParameterValues: [
    {
      type: StopLocationTypes.portOfDischarge,
      display: intl.formatMessage(message.portOfDischarge),
    },
    {
      type: StopLocationTypes.portOfLoading,
      display: intl.formatMessage(message.portOfLoading),
    },
    {
      type: StopLocationTypes.transshipmentPort,
      display: intl.formatMessage(message.transshipmentPort),
    },
    {
      type: StopLocationTypes.anyPort,
      display: intl.formatMessage(message.anyPort),
    },
  ],
  eventTypeStopLocationParameterValues: [
    {
      type: StopLocationTypes.IntermediateStop,
      display: intl.formatMessage(message.anyStop),
    },
    {
      type: StopLocationTypes.Pickup,
      display: intl.formatMessage(message.pickup),
    },
    {
      type: StopLocationTypes.Delivery,
      display: intl.formatMessage(message.delivery),
    },
  ],
  airEventTypeStopLocationParameterValues: [
    {
      type: ShipmentStopTypeEnum.ORIGIN,
      display: intl.formatMessage(message.origin),
    },
    {
      type: ShipmentStopTypeEnum.DESTINATION,
      display: intl.formatMessage(message.destination),
    },
    {
      type: ShipmentStopTypeEnum.TRANSFER,
      display: intl.formatMessage(message.transfer),
    },
    {
      type: ShipmentStopTypeEnum.OTHER,
      display: intl.formatMessage(message.other),
    },
    {
      type: ShipmentStopTypeEnum.ANY,
      display: intl.formatMessage(message.anyAirStop),
    },
  ],
  airStatusCodeParameterValues: [
    {
      type: UnifiedModelStatusCodeEnum.TRACKING_INITIATE,
      display: intl.formatMessage(unifiedModelMessages.trackingInitiate),
    },
    {
      type: UnifiedModelStatusCodeEnum.TRACKING_START,
      display: intl.formatMessage(unifiedModelMessages.trackingStart),
    },
    {
      type: UnifiedModelStatusCodeEnum.ARRIVAL_AT_STOP,
      display: intl.formatMessage(unifiedModelMessages.arrivalAtStop),
    },
    {
      type: UnifiedModelStatusCodeEnum.DEPARTURE_FROM_STOP,
      display: intl.formatMessage(unifiedModelMessages.departureFromStop),
    },
    {
      type: UnifiedModelStatusCodeEnum.LOAD_ONTO_VEHICLE,
      display: intl.formatMessage(unifiedModelMessages.loadOntoVehicle),
    },
    {
      type: UnifiedModelStatusCodeEnum.UNLOAD_FROM_VEHICLE,
      display: intl.formatMessage(unifiedModelMessages.unloadFromVehicle),
    },
    {
      type: UnifiedModelStatusCodeEnum.TRACKING_END_DUE_TO_TIMEOUT,
      display: intl.formatMessage(unifiedModelMessages.trackingEndDueToTimeout),
    },
    {
      type: UnifiedModelStatusCodeEnum.TRACKING_END_BY_USER,
      display: intl.formatMessage(unifiedModelMessages.trackingEndByUser),
    },
    {
      type: UnifiedModelStatusCodeEnum.TRACKING_COMPLETE,
      display: intl.formatMessage(unifiedModelMessages.trackingComplete),
    },
    {
      type: UnifiedModelStatusCodeEnum.FLIGHT_BOOKING,
      display: intl.formatMessage(unifiedModelMessages.flightBooking),
    },
    {
      type: UnifiedModelStatusCodeEnum.MANIFEST,
      display: intl.formatMessage(unifiedModelMessages.manifest),
    },
    {
      type: UnifiedModelStatusCodeEnum.RECEIVE_FROM_SHIPPER,
      display: intl.formatMessage(unifiedModelMessages.receiveFromShipper),
    },
    {
      type: UnifiedModelStatusCodeEnum.CONSIGNEE_NOTIFY,
      display: intl.formatMessage(unifiedModelMessages.consigneeNotify),
    },
    {
      type: UnifiedModelStatusCodeEnum.DELIVERY,
      display: intl.formatMessage(unifiedModelMessages.delivery),
    },
    {
      type: UnifiedModelStatusCodeEnum.WARNING,
      display: intl.formatMessage(unifiedModelMessages.warning),
    },
    {
      type: UnifiedModelStatusCodeEnum.TRACKING_STOP,
      display: intl.formatMessage(unifiedModelMessages.trackingStop),
    },
    {
      type: UnifiedModelStatusCodeEnum.EXCEPTION_LATE_DEPARTURE,
      display: intl.formatMessage(unifiedModelMessages.exceptionLateDeparture),
    },
    {
      type: UnifiedModelStatusCodeEnum.EXCEPTION_MISSED_CONNECTION,
      display: intl.formatMessage(unifiedModelMessages.exceptionMissedConnection),
    },
  ],
};

export const TimeBasedMaxValuesByMode = {
  [ModeTypeConstants.PARCEL]: 1440,
};

export const RecipientCustomerContacts = ['chris.notley@spr.com', 'john.doe@google.com', 'jane.smith@google.com'];

export const mockNotificationResponse = {
  id: 1,
  title: 'Test Notification',
  filters: {
    tenantFilters: {
      operator: filterOperators.And,
      fields: [
        {
          type: EventNotificationTargetTypeConstants.Customer,
          values: ['Walgreens', 'CVS', 'Jewel'],
        },
        {
          type: EventNotificationTargetTypeConstants.Location,
          values: ['Evanston IL', 'Chicago IL', 'Lake Forest IL'],
        },
        {
          type: EventNotificationTargetTypeConstants.Carrier,
          values: ['Fedex', 'DHL', 'UPS'],
        },
      ],
    },
  },
  rules: [
    {
      ruleType: TLEventTypeKeyConstants.RunningLate,
      ruleConfiguration: {
        stopNumber: 'PICKUP',
        timeBoundaryValue: 30,
      },
    },
  ],
  subscriptions: [
    {
      type: 'EMAIL',
      values: ['jerry.virgo@spr.com'],
      input: '',
    },
  ],
};

export const selectFieldStyles = {
  menuStyle: {
    width: '100%',
    minWidth: '120px',
    fontSize: '12px',
    height: '34px',
  },
  style: {
    fontSize: '12px',
    width: '100%',
    height: '34px',
    fontFamily: 'Roboto',
  },
  autoWidth: false,
  underlineStyle: { display: 'none' },
  iconStyle: {
    top: 0,
    right: 0,
    height: '33px',
    width: '33px',
    background: 'transparent',
    fill: '#575451',
    padding: '4px 0px',
  },
  hintStyle: {
    lineHeight: '32px',
    height: '34px',
    paddingLeft: '8px',
    top: 0,
  },
  labelStyle: {
    fontSize: '12px',
    borderWidth: '0px',
    lineHeight: '33px',
    height: '34px',
    paddingLeft: '8px',
    width: '100%',
    top: '0',
  },
  selectedMenuItemStyle: {
    width: '100%',
    fontSize: '12px',
    height: '34px',
    color: 'var(--teal)',
  },
};

export const textFieldStyles = {
  style: {
    width: '75px',
    height: '36px',
    fontSize: '12px',
  },
  inputStyle: {
    padding: 0,
    color: '#575451',
    fontWeight: 500,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  hintStyle: {
    height: '34px',
    top: 1,
  },
  underlineStyle: {
    display: 'none',
  },
};

export const autocompleteStyles = {
  style: {
    width: '100%',
    minWidth: '250px',
  },
  inputStyle: {
    padding: '0',
    color: '#575451',
    fontWeight: 500,
    height: '36px',
    paddingLeft: '8px',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  hintStyle: {
    height: '34px',
    top: 1,
  },
  underlineStyle: {
    display: 'none',
  },
  listStyle: {
    lineHeight: 1,
    padding: 0,
  },
  textFieldStyle: {
    fontSize: '12px',
    color: '#575451',
    fontWeight: 500,
    lineHeight: '34px',
    height: '34px',
    width: '100%',
    overflow: 'hidden',
    paddingLeft: '8px',
    paddingRight: 0,
  },
};
