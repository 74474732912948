import findKey from 'lodash/findKey';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import mapValues from 'lodash/mapValues';
import pull from 'lodash/pull';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import split from 'lodash/split';
import join from 'lodash/join';
import omit from 'lodash/omit';
import lowerCase from 'lodash/lowerCase';
import styled from 'styled-components';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import { SVGIcon } from 'ui-components';
import { primaryGrey, primaryGreyFifty, primaryGreyEighty } from 'styles/colors';

import { formatDateToString, parseLocaleDateTimeToUtc } from 'common/dateUtils';
import { filterMapper, ModeType } from '../utils';

interface ChipsProps {
  filters: any;
  modalId: string;
  onDeleteChips: (obj: object) => void;
  mode: ModeType;
  customerSuggestions: object[];
}

const StyledChipsContainer = styled('div')`
  padding: 0 12px 12px;
`;

const StyledChip = styled('div')`
  background-color: rgba(45, 41, 38, 0.05);
  color: ${primaryGreyEighty};
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 14px 5px 8px;
  margin-bottom: 4px;

  &:hover {
    background-color: rgba(45, 41, 38, 0.1);
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  color: ${primaryGreyFifty};
  font-size: 10px;
  height: auto;
  line-height: 0;
  padding: 0;

  &:hover {
    color: ${primaryGrey};
  }
`;

const filtersMapper = (modalId: string) => {
  switch (modalId) {
    case 'shipmentStatus':
      return ['status'];
    case 'timing':
      return ['arrivalForecast', 'deliveryStatus'];
    case 'holdsDemurrage':
      return ['oceanActiveHolds', 'oceanDemurrageEligible'];
    case 'portName':
      return ['happeningCode'];
    case 'event':
      return [
        'pickupDateStart',
        'pickupDateEnd',
        'deliveryDateStart',
        'deliveryDateEnd',
        'oceanLastFreeDateStart',
        'oceanLastFreeDateEnd',
      ];
    case 'locationType':
      return ['location', 'country'];
    default:
      return [modalId];
  }
};

const getCurrentFilterData = (filters: object, modalId: string, customerSuggestions: object[]) => {
  if (modalId === 'customerTenants') {
    const customerTenants = get(filters, 'customerTenants', []);
    const values = customerTenants.map((item: string) => {
      const obj: any = customerSuggestions.find((element: any) => element.tenantId === item);
      return obj?.name || item;
    });

    return values;
  }

  const filtersKeys = filtersMapper(modalId);

  if (filtersKeys[0] === 'attributeKeyValuePairs') {
    const attributeKeyValuePairsFilter = flatten(values(pick(filters, filtersKeys)));

    const result = attributeKeyValuePairsFilter?.map((item: any) => {
      return item?.values?.map((itemValue: any) => {
        return `${item.name}:${itemValue}`;
      });
    });

    return flatten(result);
  }

  if (filtersKeys[0] === 'pickupDateStart') {
    const dateValuesEventFilter = flatten(values(pick(filters, filtersKeys)));

    const result = dateValuesEventFilter?.map((item: any) => {
      return formatDateToString(new Date(item));
    });

    return flatten(result);
  }

  return flatten(values(pick(filters, filtersKeys)));
};

const formattedMessageCapitalized = (props: { id: string; defaultMessage: string }) => {
  return (
    <FormattedMessage {...props}>
      {(txt: string) => <span className="capitalize">{lowerCase(txt)}: </span>}
    </FormattedMessage>
  );
};

const Chips = ({ filters, modalId, onDeleteChips, mode, customerSuggestions }: ChipsProps) => {
  const currentFilterData = getCurrentFilterData(filters, modalId, customerSuggestions);

  const onDelete = (chipName: string) => {
    const filtersKeys = filtersMapper(modalId);
    const filterData = pick(filters, filtersKeys);

    if (filtersKeys[0] === 'attributeKeyValuePairs') {
      const attributeKeyValuePairsFilter = flatten(values(pick(filters, filtersKeys)));
      const [attrName, ...rest] = split(chipName, ':');
      const attrValue = join(rest, ':');

      const result = attributeKeyValuePairsFilter.reduce((acc, currentItem) => {
        if (currentItem.name !== attrName) return [...acc, currentItem];

        const newValues = pull([...currentItem.values], attrValue);

        if (isEmpty(newValues)) return acc;

        return [...acc, { name: attrName, values: newValues }];
      }, []);

      return onDeleteChips({
        ...omit(filters, ['attributeKeyValuePairs']),
        attributeKeyValuePairs: result,
      });
    }

    if (modalId === 'event') {
      const deletedFilterKey = findKey(filterData, (item) => item === parseLocaleDateTimeToUtc(chipName));
      const result = deletedFilterKey ? { [deletedFilterKey]: null } : {};
      return onDeleteChips({ ...filters, ...result });
    }

    if (modalId === 'customerTenants') {
      const obj: any = customerSuggestions.find((element: any) => element.name === chipName);
      const newChipName = obj?.tenantId || chipName;
      const result = mapValues(filterData, (value) => pull([...value], newChipName));
      return onDeleteChips({ ...filters, ...result });
    }

    const result = mapValues(filterData, (value) => pull([...value], chipName));
    return onDeleteChips({ ...filters, ...result });
  };

  if (isEmpty(currentFilterData)) return null;

  const renderText = (item: string) => {
    const customLabel = filterMapper(mode);
    const itemData = get(customLabel, item);
    const label = itemData?.label;
    const category = itemData?.category;

    if (label && category)
      return (
        <span>
          {formattedMessageCapitalized(category)}
          <FormattedMessage {...label} />
        </span>
      );
    if (category)
      return (
        <span>
          {formattedMessageCapitalized(category)}
          <span>{item}</span>
        </span>
      );
    if (label) return <FormattedMessage {...label} />;
    return <span className="break-all">{item}</span>;
  };

  return (
    <StyledChipsContainer>
      {currentFilterData.map((item: string) => (
        <StyledChip className="d-flex align-items-center justify-content-between" key={item}>
          {renderText(item)}
          <StyledButton className="ml-2" type="link" onClick={() => onDelete(item)}>
            <SVGIcon name="close" />
          </StyledButton>
        </StyledChip>
      ))}
    </StyledChipsContainer>
  );
};

export default Chips;
