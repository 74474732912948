import { Dispatch, SetStateAction } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import CollapsableItem from 'components/common/ListPageLayout/CollapsableItem';
import SimpleInputWithChips from 'components/common/SimpleInputWithChips/SimpleInputWithChips';
import TypeValueInputWithChips from 'components/common/TypeValueInputWithChips/TypeValueInputWithChips';

interface InventoryItemsFilterInput {
  hazardClasses: string[];
  setHazardClasses: Dispatch<SetStateAction<string[]>>;
  inventoryIdType: string | undefined;
  setInventoryIdType: Dispatch<SetStateAction<string | undefined>>;
  inventoryIdTypeList: any[];
  inventoryIdValues: string[];
  setInventoryIdValues: Dispatch<SetStateAction<string[]>>;
}

const InventoryItemsFilters = ({
  hazardClasses,
  setHazardClasses,

  inventoryIdType,
  setInventoryIdType,
  inventoryIdTypeList,
  inventoryIdValues,
  setInventoryIdValues,
}: InventoryItemsFilterInput) => {
  const intl = useIntl();

  const isHazardClassfilterActive = !isEmpty(hazardClasses);
  const isInventoryIdFilterActive = !isEmpty(inventoryIdValues);

  return (
    <>
      <CollapsableItem
        panelTitle={intl.formatMessage({
          id: 'select.inventoryItemsTable.filters.hazardClasses',
          defaultMessage: 'Hazard Classes',
        })}
        dataKey="1"
        activeKey={isHazardClassfilterActive ? '1' : undefined}
      >
        <SimpleInputWithChips
          data={hazardClasses}
          setData={setHazardClasses}
          placeholder={intl.formatMessage({
            id: 'input.inventoryItemsTable.filters.hazardClasses',
            defaultMessage: 'Add hazard classes',
          })}
        />
      </CollapsableItem>
      <CollapsableItem
        panelTitle={intl.formatMessage({
          id: 'select.inventoryItemsTable.filters.inventoryIds',
          defaultMessage: 'Inventory Identifiers',
        })}
        dataKey="2"
        activeKey={isInventoryIdFilterActive ? '2' : undefined}
      >
        <TypeValueInputWithChips
          selectTitleComponent={
            <FormattedMessage
              id="select.inventoryItemsTable.filters.inventoryIds.category"
              defaultMessage="Inventory Identifier Category"
            />
          }
          selectPlaceholder={intl.formatMessage({
            id: 'input.inventoryItemsTable.filters.inventoryIds.type',
            defaultMessage: 'Select a Category',
          })}
          onSelect={setInventoryIdType}
          selectedValue={inventoryIdType}
          selectList={inventoryIdTypeList}
          listTitleComponent={
            <FormattedMessage
              id="select.inventoryItemsTable.filters.inventoryIds.values"
              defaultMessage="Inventory Identifier Values"
            />
          }
          data={inventoryIdValues}
          setData={setInventoryIdValues}
          inputPlaceholder={intl.formatMessage({
            id: 'input.inventoryItemsTable.filters.inventoryIds.values',
            defaultMessage: 'Select values',
          })}
        />
      </CollapsableItem>
    </>
  );
};

export default InventoryItemsFilters;
