import { defineMessages } from 'react-intl';
import { UnifiedModelStatusCodeEnum, ShipmentStopTypeEnum } from 'models';
import {
  EventNotificationTargetTypeConstants,
  SubscriptionTypes,
  TLEventTypeKeyConstants,
  LTLEventTypeKeyConstants,
  ParcelEventTypeKeyConstants,
  StopLocationTypes,
  AirEventTypeKeyConstants,
  OceanEventTypeKeyConstants,
  EventNotificationTargetTypeConstantKeys,
} from '../constants';

export const message = defineMessages({
  hours: {
    id: 'notifications.notificationApiToStateMapper.hours',
    defaultMessage: 'hours',
  },
  minutes: {
    id: 'notifications.notificationApiToStateMapper.minutes',
    defaultMessage: 'minutes',
  },
  orText: {
    id: 'notifications.notificationApiToStateMapper.orText',
    defaultMessage: 'or',
  },
  andText: {
    id: 'notifications.notificationApiToStateMapper.andText',
    defaultMessage: 'and',
  },
});

export const API_TO_STATE_STOP_TYPE_MAP = {
  PICKUP: StopLocationTypes.Pickup,
  DELIVERY: StopLocationTypes.Delivery,
  INTERMEDIATE: StopLocationTypes.IntermediateStop,
  PORT_OF_LOADING: StopLocationTypes.portOfLoading,
  TRANSSHIPMENT_PORT: StopLocationTypes.transshipmentPort,
  PORT_OF_DISCHARGE: StopLocationTypes.portOfDischarge,
  ANY_PORT: StopLocationTypes.anyPort,
  ORIGIN: ShipmentStopTypeEnum.ORIGIN,
  DESTINATION: ShipmentStopTypeEnum.DESTINATION,
  TRANSFER: ShipmentStopTypeEnum.TRANSFER,
  OTHER: ShipmentStopTypeEnum.OTHER,
  ANY: ShipmentStopTypeEnum.ANY,
};

export const API_TO_STATE_STATUS_CODE_MAP = {
  TRACKING_INITIATE: UnifiedModelStatusCodeEnum.TRACKING_INITIATE,
  TRACKING_START: [UnifiedModelStatusCodeEnum.TRACKING_START],
  ARRIVAL_AT_STOP: [UnifiedModelStatusCodeEnum.ARRIVAL_AT_STOP],
  DEPARTURE_FROM_STOP: [UnifiedModelStatusCodeEnum.DEPARTURE_FROM_STOP],
  LOAD_ONTO_VEHICLE: [UnifiedModelStatusCodeEnum.LOAD_ONTO_VEHICLE],
  UNLOAD_FROM_VEHICLE: [UnifiedModelStatusCodeEnum.UNLOAD_FROM_VEHICLE],
  TRACKING_END_DUE_TO_TIMEOUT: [UnifiedModelStatusCodeEnum.TRACKING_END_DUE_TO_TIMEOUT],
  TRACKING_END_BY_USER: [UnifiedModelStatusCodeEnum.TRACKING_END_BY_USER],
  TRACKING_COMPLETE: [UnifiedModelStatusCodeEnum.TRACKING_COMPLETE],
  FLIGHT_BOOKING: [UnifiedModelStatusCodeEnum.FLIGHT_BOOKING],
  MANIFEST: [UnifiedModelStatusCodeEnum.MANIFEST],
  RECEIVE_FROM_SHIPPER: [UnifiedModelStatusCodeEnum.RECEIVE_FROM_SHIPPER],
  CONSIGNEE_NOTIFY: [UnifiedModelStatusCodeEnum.CONSIGNEE_NOTIFY],
  DELIVERY: [UnifiedModelStatusCodeEnum.DELIVERY],
  WARNING: [UnifiedModelStatusCodeEnum.WARNING],
  EXCEPTION: [UnifiedModelStatusCodeEnum.EXCEPTION],
  TRACKING_STOP: [UnifiedModelStatusCodeEnum.TRACKING_STOP],
  EXCEPTION_LATE_DEPARTURE: [UnifiedModelStatusCodeEnum.EXCEPTION_LATE_DEPARTURE],
  EXCEPTION_MISSED_CONNECTION: [UnifiedModelStatusCodeEnum.EXCEPTION_MISSED_CONNECTION],
};

export const STATE_TO_API_STOP_TYPE_MAP = {
  [StopLocationTypes.Pickup]: 'PICKUP',
  [StopLocationTypes.Delivery]: 'DELIVERY',
  [StopLocationTypes.IntermediateStop]: 'INTERMEDIATE',
  [StopLocationTypes.portOfLoading]: 'PORT_OF_LOADING',
  [StopLocationTypes.transshipmentPort]: 'TRANSSHIPMENT_PORT',
  [StopLocationTypes.portOfDischarge]: 'PORT_OF_DISCHARGE',
  [ShipmentStopTypeEnum.ORIGIN]: 'ORIGIN',
  [ShipmentStopTypeEnum.DESTINATION]: 'DESTINATION',
  [ShipmentStopTypeEnum.TRANSFER]: 'TRANSFER',
  [ShipmentStopTypeEnum.OTHER]: 'OTHER',
};

export const STATE_TO_API_CODE_STATUS_MAP = {
  [UnifiedModelStatusCodeEnum.TRACKING_INITIATE]: 'TRACKING_INITIATE',
  [UnifiedModelStatusCodeEnum.TRACKING_START]: 'TRACKING_START',
  [UnifiedModelStatusCodeEnum.ARRIVAL_AT_STOP]: 'ARRIVAL_AT_STOP',
  [UnifiedModelStatusCodeEnum.DEPARTURE_FROM_STOP]: 'DEPARTURE_FROM_STOP',
  [UnifiedModelStatusCodeEnum.LOAD_ONTO_VEHICLE]: 'LOAD_ONTO_VEHICLE',
  [UnifiedModelStatusCodeEnum.UNLOAD_FROM_VEHICLE]: 'UNLOAD_FROM_VEHICLE',
  [UnifiedModelStatusCodeEnum.TRACKING_END_DUE_TO_TIMEOUT]: 'TRACKING_END_DUE_TO_TIMEOUT',
  [UnifiedModelStatusCodeEnum.TRACKING_END_BY_USER]: 'TRACKING_END_BY_USER',
  [UnifiedModelStatusCodeEnum.TRACKING_COMPLETE]: 'TRACKING_COMPLETE',
  [UnifiedModelStatusCodeEnum.FLIGHT_BOOKING]: 'FLIGHT_BOOKING',
  [UnifiedModelStatusCodeEnum.MANIFEST]: 'MANIFEST',
  [UnifiedModelStatusCodeEnum.RECEIVE_FROM_SHIPPER]: 'RECEIVE_FROM_SHIPPER',
  [UnifiedModelStatusCodeEnum.CONSIGNEE_NOTIFY]: 'CONSIGNEE_NOTIFY',
  [UnifiedModelStatusCodeEnum.DELIVERY]: 'DELIVERY',
  [UnifiedModelStatusCodeEnum.WARNING]: 'WARNING',
  [UnifiedModelStatusCodeEnum.EXCEPTION]: 'EXCEPTION',
  [UnifiedModelStatusCodeEnum.TRACKING_STOP]: 'TRACKING_STOP',
  [UnifiedModelStatusCodeEnum.EXCEPTION_LATE_DEPARTURE]: 'EXCEPTION_LATE_DEPARTURE',
  [UnifiedModelStatusCodeEnum.EXCEPTION_MISSED_CONNECTION]: 'EXCEPTION_MISSED_CONNECTION',
};

export const EVENT_TYPE_API_TO_STATE_MAP = {
  ARRIVING: TLEventTypeKeyConstants.Arriving,
  ARRIVING_V2: TLEventTypeKeyConstants.Arriving,
  RUNNING_LATE: TLEventTypeKeyConstants.RunningLate,
  RUNNING_LATE_V2: TLEventTypeKeyConstants.RunningLate,
  DWELLING: TLEventTypeKeyConstants.Dwelling,
  IDLE_IN_TRANSIT: TLEventTypeKeyConstants.IdleInTransit,
  TRACKING_APPROVAL_DENIED: TLEventTypeKeyConstants.TrackingApprovalDenied,
  TRACKING_APPROVAL_PENDING: TLEventTypeKeyConstants.TrackingApprovalPending,
  DEPARTED_V2: TLEventTypeKeyConstants.Departed,
  DEPARTED_LATE: TLEventTypeKeyConstants.DepartedLate,
  TEMPERATURE_OUT_RANGE: TLEventTypeKeyConstants.TemperatureOutOfRange,
  ALL_UPDATES: TLEventTypeKeyConstants.AllUpdates,
  SHIPMENT_DELETED: TLEventTypeKeyConstants.ShipmentDeleted,
  SHIPMENT_PENDING_TRACKING_METHOD: TLEventTypeKeyConstants.ShipmentPendingTrackingMethod,
  OUT_FOR_DELIVERY: LTLEventTypeKeyConstants.OutForDelivery,
  OUT_FOR_DELIVERY_MISSED: LTLEventTypeKeyConstants.OutForDeliveryMissed,
  PARCEL_OUT_FOR_DELIVERY: ParcelEventTypeKeyConstants.OutForDelivery,
  PARCEL_RUNNING_LATE: ParcelEventTypeKeyConstants.RunningLate,
  PARCEL_MISSED_DELIVERY_WINDOW: ParcelEventTypeKeyConstants.MissedDeliveryWindow,
  AIR_BOOKED: AirEventTypeKeyConstants.Booked,
  AIR_DELIVERED: AirEventTypeKeyConstants.Delivered,
  AIR_ANY_STATUS_UPDATED: AirEventTypeKeyConstants.AnyStatusUpdated,
  OCEAN_ARRIVAL_RUNNING_LATE: OceanEventTypeKeyConstants.ArrivingLate,
  OCEAN_DEPARTURE_RUNNING_LATE: OceanEventTypeKeyConstants.EmbarkingLate,
};
export const EVENT_TYPE_STATE_TO_API_MAP = {
  [TLEventTypeKeyConstants.Arriving]: 'ARRIVING',
  [TLEventTypeKeyConstants.Arriving]: 'ARRIVING_V2',
  [TLEventTypeKeyConstants.RunningLate]: 'RUNNING_LATE',
  [TLEventTypeKeyConstants.RunningLate]: 'RUNNING_LATE_V2',
  [TLEventTypeKeyConstants.Dwelling]: 'DWELLING',
  [TLEventTypeKeyConstants.IdleInTransit]: 'IDLE_IN_TRANSIT',
  [TLEventTypeKeyConstants.TrackingApprovalDenied]: 'TRACKING_APPROVAL_DENIED',
  [TLEventTypeKeyConstants.TrackingApprovalPending]: 'TRACKING_APPROVAL_PENDING',
  [TLEventTypeKeyConstants.Departed]: 'DEPARTED_V2',
  [TLEventTypeKeyConstants.DepartedLate]: 'DEPARTED_LATE',
  [TLEventTypeKeyConstants.TemperatureOutOfRange]: 'TEMPERATURE_OUT_RANGE',
  [TLEventTypeKeyConstants.AllUpdates]: 'ALL_UPDATES',
  [TLEventTypeKeyConstants.ShipmentDeleted]: 'SHIPMENT_DELETED',
  [TLEventTypeKeyConstants.ShipmentPendingTrackingMethod]: 'SHIPMENT_PENDING_TRACKING_METHOD',
  [LTLEventTypeKeyConstants.OutForDelivery]: 'OUT_FOR_DELIVERY',
  [LTLEventTypeKeyConstants.OutForDeliveryMissed]: 'OUT_FOR_DELIVERY_MISSED',
  [ParcelEventTypeKeyConstants.OutForDelivery]: 'PARCEL_OUT_FOR_DELIVERY',
  [ParcelEventTypeKeyConstants.RunningLate]: 'PARCEL_RUNNING_LATE',
  [ParcelEventTypeKeyConstants.MissedDeliveryWindow]: 'PARCEL_MISSED_DELIVERY_WINDOW',
  [AirEventTypeKeyConstants.Booked]: 'AIR_BOOKED',
  [AirEventTypeKeyConstants.Delivered]: 'AIR_DELIVERED',
  [AirEventTypeKeyConstants.AnyStatusUpdated]: 'AIR_ANY_STATUS_UPDATED',
  [OceanEventTypeKeyConstants.ArrivingLate]: 'OCEAN_ARRIVAL_RUNNING_LATE',
  [OceanEventTypeKeyConstants.EmbarkingLate]: 'OCEAN_DEPARTURE_RUNNING_LATE',
};

export const API_SUBSCRIPTION_TYPE_TO_STATE_MAP = {
  EMAIL: SubscriptionTypes.Email,
  SMS: SubscriptionTypes.SMS,
  PUSH: SubscriptionTypes.Push,
};
export const STATE_SUBSCRIPTION_TYPE_TO_API_MAP = {
  [SubscriptionTypes.Email]: 'EMAIL',
  [SubscriptionTypes.SMS]: 'SMS',
  [SubscriptionTypes.Push]: 'PUSH',
};

export const API_FIELD_TYPE_TO_STATE_MAP = {
  CUSTOMER: EventNotificationTargetTypeConstantKeys.Customer,
  CUSTOMER_V2: EventNotificationTargetTypeConstantKeys.SelectedStopCustomer,
  CUSTOMER_ANY_STOP_V2: EventNotificationTargetTypeConstantKeys.AnyStopCustomer,
  CUSTOMER_FUTURE_STOP_V2: EventNotificationTargetTypeConstantKeys.FutureStopsCustomer,
  LOCATION_V2: EventNotificationTargetTypeConstantKeys.Location,
  CARRIER_V2: EventNotificationTargetTypeConstantKeys.Carrier,
};

export const STATE_FIELD_TYPE_TO_API_MAP = {
  Location: 'LOCATION_V2',
  Carrier: 'CARRIER_V2',
  [EventNotificationTargetTypeConstantKeys.Customer]: 'CUSTOMER',
  [EventNotificationTargetTypeConstantKeys.SelectedStopCustomer]: 'CUSTOMER_V2',
  [EventNotificationTargetTypeConstantKeys.AnyStopCustomer]: 'CUSTOMER_ANY_STOP_V2',
  [EventNotificationTargetTypeConstantKeys.FutureStopsCustomer]: 'CUSTOMER_FUTURE_STOP_V2',
  [EventNotificationTargetTypeConstants.Customer]: 'CUSTOMER_V2',
  [EventNotificationTargetTypeConstants.Location]: 'LOCATION_V2',
  [EventNotificationTargetTypeConstants.Carrier]: 'CARRIER_V2',
};

// Once rule groups are implemented and we have multiple rules in a group, the "shipmentInclusionRule" will not be static
export const STATIC_NOTIFICATION_VALUES = {
  isExpanded: false,
  shipmentInclusionRule: {
    type: 'ANY',
    display: 'Any',
    inclusionCritera: [],
  },
};

export const API_OPERATOR_TO_STATE_MAP = {
  AND: 'And',
  OR: 'Or',
};
export const STATE_OPERATOR_TO_API_MAP = {
  And: 'AND',
  Or: 'OR',
};

export const API_EVENT_TYPES_WITH_NO_STOP_TYPE_PARAMETER = new Set([
  TLEventTypeKeyConstants.TrackingApprovalDenied,
  TLEventTypeKeyConstants.TrackingApprovalPending,
  TLEventTypeKeyConstants.TemperatureOutOfRange,
  TLEventTypeKeyConstants.AllUpdates,
  TLEventTypeKeyConstants.ShipmentDeleted,
  TLEventTypeKeyConstants.ShipmentPendingTrackingMethod,
  LTLEventTypeKeyConstants.OutForDelivery,
  LTLEventTypeKeyConstants.OutForDeliveryMissed,
  ParcelEventTypeKeyConstants.OutForDelivery,
  ParcelEventTypeKeyConstants.MissedDeliveryWindow,
  ParcelEventTypeKeyConstants.RunningLate,
  AirEventTypeKeyConstants.Booked,
  AirEventTypeKeyConstants.Delivered,
]);
