import moment from 'moment-timezone';
import { defineMessages } from 'react-intl';
const localDateTimeFmt = 'YYYY-MM-DDTHH:mm:ss';
const localDateTimeDetailFmt = 'YYYY-MM-DDTHH:mm:ss:SSS';
const timeFmt = 'HH:mm:ss';
const dateFmt = 'YYYY-MM-DD';
const timeZoneUTC = 'UTC';
const minuteSecondTime = 'mm:ss';

const message = defineMessages({
  hello: {
    id: 'header.dateUtils.hello',
    defaultMessage: 'Hello',
  },
  goodMorning: {
    id: 'header.dateUtils.goodMorning',
    defaultMessage: 'Good morning',
  },
  goodAfternoon: {
    id: 'header.dateUtils.goodAfternoon',
    defaultMessage: 'Good afternoon',
  },
  goodEvening: {
    id: 'header.dateUtils.goodEvening',
    defaultMessage: 'Good evening',
  },
});

export const convertToISOString = (datePart: string, timePart: string, timezone: string): string | null => {
  const momentDate = convertToMoment(datePart, timePart, timezone);
  return momentDate ? momentDate.toISOString() : null;
};

export const convertToLocalDateTimeString = (datePart: string, timePart: string): string | null => {
  const momentDate = convertToLocalMoment(datePart, timePart);
  return momentDate ? formatMomentAsLocalDateTime(momentDate) : null;
};

/**
 * Parses time as moment and returns that parsed moment if successful or undefined otherwise.
 * If inputTimezone is not specified, it's assumed to be UTC.
 * If outputTimezone is not specified, it's assumed to be UTC.
 * If inputTimezone or outputTimezone is specified but fails to get parsed into a valid moment, undefined will be returned.
 */
export const asMoment = (time: string, inputTimezone?: string, outputTimezone?: string): moment.Moment | undefined => {
  if (!time) {
    return undefined;
  }

  const inputMoment = inputTimezone ? moment.tz(time, inputTimezone) : moment.utc(time);

  if (!inputMoment || !inputMoment.isValid()) {
    return undefined;
  }

  const outputMoment = outputTimezone ? inputMoment.tz(outputTimezone) : inputMoment.utc();

  if (!outputMoment || !outputMoment.isValid()) {
    return undefined;
  }

  return outputMoment;
};

/**
 * Converts time moment to a different time zone.
 * Time must be a valid moment.
 * If outputTimezone is not specified, it's assumed to be UTC.
 * If outputTimezone is invalid, undefined will be returned.
 */
export const convertMomentToDifferentTimezone = (
  timeMoment: any,
  outputTimezone: string
): moment.Moment | undefined => {
  if (!timeMoment || !timeMoment.isValid() || !outputTimezone) {
    return undefined;
  }

  const outputMoment = timeMoment.tz(outputTimezone);

  if (!outputMoment || !outputMoment.isValid()) {
    return undefined;
  }

  return outputMoment;
};

/**
 * Parses time as moment.utc(...) and checks if moment is valid using moment.isValid().
 * Returns a valid moment or undefined.
 * Time object must be convertable to a valid moment; otherwise, undefined will be returned.
 * Time is assumed to be in UTC.
 */
export const asUtcMoment = (time: string): moment.Moment | null => {
  if (time) {
    const parsedMoment = moment.utc(time);
    if (parsedMoment.isValid()) {
      return parsedMoment;
    }
  }
  return null;
};

export const utcNowMoment = (): moment.Moment => {
  return moment.utc();
};

export const localNowMoment = (): moment.Moment => {
  return moment.utc().tz(moment.tz.guess());
};

/**
 * Parses time as moment.utc(...), converts to user/browser timezone
 * and checks if moment is valid using moment.isValid().
 * Returns a valid moment or undefined.
 * Time object must be convertable to a valid moment; otherwise, undefined will be returned.
 * Time is assumed to be in UTC.
 */
export const utcTimeAsUserTimeMoment = (time: string): moment.Moment | undefined => {
  if (time) {
    const parsedMoment = moment.utc(time);
    if (parsedMoment.isValid()) {
      return parsedMoment.tz(moment.tz.guess());
    }
  }
};

/**
 *
 * @param {*} unixTimestamp
 * @param {*} format desired format output
 */
export const formatUnixTimestamp = (unixTimestamp: number, format = 'MM/DD/YY hh:mm A'): string | undefined => {
  return unixTimestamp ? moment.unix(unixTimestamp).format(format) : undefined;
};

/**
 * Attempts to parse the given localDateTime string as a moment or returns null.
 *
 * @param {localDateTime} dateTime string as YYYY-MM-DD'T'hh:mm:ss
 *
 * @return parsed moment form or null
 */
export const parseLocalDateTimeAsMoment = (localDateTime: string): moment.Moment | null =>
  localDateTime ? moment(localDateTime, "YYYY-MM-DD'T'hh:mm:ss") : null;

/**
 * Attempts to parse the given localDateTime string and returns an object containing the date part
 * as a javascript date and the time part as hh:mm:ss
 *
 * @param {localDateTime} localDateTime
 *
 * @return
 * { date, time}
 */
export const parseLocalDateTime = (localDateTime: string): { date: Date; time: string } | null => {
  const parsedMoment = parseLocalDateTimeAsMoment(localDateTime);
  if (!parsedMoment) {
    return null;
  }

  const time = parsedMoment.format(timeFmt);
  const date = parsedMoment.startOf('day').toDate();
  return { date, time };
};

export const formatAsLocalDateTime = (localDateTime: string): string | null => {
  if (!localDateTime) {
    return null;
  }

  return moment(localDateTime, localDateTimeFmt).format(localDateTimeFmt);
};

/**
 * Attempts to parse the given Date to String whit YYYY-MM-DDTHH:mm:ss:SSS format
 * @param {Date} date
 * @returns String date with format YYYY-MM-DDTHH:mm:ss:SSS
 */
export const formatDateToString = (date: Date): string | null => {
  if (!date) {
    return null;
  }

  return moment(date).format(localDateTimeDetailFmt);
};

/**
 * Attempts to parse the given String in date format (YYYY-MM-DDTHH:mm:ss:SSS) to
 * String in a UTC date format whit YYYY-MM-DDTHH:mm:ss.SSSZ format
 * @param {string} localDateTime
 * @returns String date with format YYYY-MM-DDTHH:mm:ss.SSSZ
 */
export const parseLocaleDateTimeToUtc = (localDateTime: string): string | null => {
  if (!localDateTime) {
    return null;
  }

  return moment(localDateTime, localDateTimeDetailFmt).utc().toISOString();
};

export const formatUtcAsLocalDateTimeInTimeZone = (
  localDateTimeUtc: number | string,
  targetTimeZone: string
): string | null => formatLocalDateTimeWithTimeZoneChange(localDateTimeUtc, timeZoneUTC, targetTimeZone);

export const formatLocalDateTimeWithTimeZoneChange = (
  localDateTime: number | string,
  currentTimeZone: string,
  newTimeZone: string
): string | null => {
  if (!localDateTime) {
    return null;
  }
  if (!currentTimeZone) {
    // eslint-disable-next-line no-param-reassign
    currentTimeZone = browserTimezone();
  }
  if (!newTimeZone) {
    // eslint-disable-next-line no-param-reassign
    newTimeZone = timeZoneUTC;
  }

  return moment.tz(localDateTime, currentTimeZone).tz(newTimeZone).format(localDateTimeFmt);
};

export const browserTimezone = (): string => moment.tz.guess();

const parseTimeMoment = (timePart: string): moment.Moment => moment(timePart || '00:00:00', timeFmt);

const addTimeToDateMoment = (dateMoment: moment.Moment, timeMoment: moment.Moment): moment.Moment =>
  dateMoment.add(timeMoment.hour(), 'hours').add(timeMoment.minute(), 'minutes').add(timeMoment.second(), 'second');

const parseAndFormatDatePart = (datePart: string): string => moment.tz(datePart, browserTimezone()).format(dateFmt);

export const convertToMoment = (datePart: string, timePart: string, timezone: string): moment.Moment | null => {
  if (!datePart) {
    return null;
  }

  const timeMoment = parseTimeMoment(timePart);
  const truncatedDatePart = parseAndFormatDatePart(datePart);

  const appliedTimezone = timezone ? timezone : browserTimezone();
  const transformedMoment = moment.tz(truncatedDatePart, appliedTimezone);

  return addTimeToDateMoment(transformedMoment, timeMoment);
};

export const convertToLocalMoment = (datePart: string, timePart: string): moment.Moment | null => {
  if (!datePart) {
    return null;
  }

  const timeMoment = parseTimeMoment(timePart);
  const truncatedDatePart = parseAndFormatDatePart(datePart);

  const transformedMoment = moment(truncatedDatePart);

  return addTimeToDateMoment(transformedMoment, timeMoment);
};

export const hourMinuteFormat = (timeValue: number, createFormat: string): string => {
  return moment(timeValue, createFormat).format(minuteSecondTime);
};

export const formatMomentAsLocalDateTime = (momentValue: moment.Moment): string | null =>
  momentValue ? momentValue.format(localDateTimeFmt) : null;

export const compareMoments = (firstMoment: moment.Moment, secondMoment: moment.Moment): number => {
  if (firstMoment.isBefore(secondMoment)) {
    return -1;
  }
  if (firstMoment.isAfter(secondMoment)) {
    return 1;
  }
  return 0;
};

export const formatUtcTimeInTimeZoneOrDefaultToBrowserZone = (
  utcDate: string | undefined,
  timezone?: string | null | undefined
): moment.Moment | undefined => {
  if (utcDate) {
    return timezone ? asMoment(utcDate, undefined, timezone) : utcTimeAsUserTimeMoment(utcDate);
  }
  return undefined;
};

/**
 * Timestamp must be convertable to a valid moment; otherwise, undefined will be returned.
 * If timestamp is in the future, a negative value will be returned.
 * Timestamp is assumed to be in UTC.
 */
export const getMinutesAgoFromUtcTimestamp = (timestamp: string | undefined): number | undefined => {
  if (timestamp) {
    const timestampMoment = asUtcMoment(timestamp);
    if (timestampMoment) {
      return moment.duration(moment.utc().diff(timestampMoment)).asMinutes();
    }
  }
};

/**
 * Difference in days between two moments considering start of day.
 * If first moment is an older date, then result is negative;
 * if first moment is a later date , then result is positive.
 */
export const getDaysAgo = (momentA: moment.Moment, momentB: moment.Moment): number | null => {
  if (!momentA || !momentB) {
    return null;
  }

  const momentAStart = momentA.clone().startOf('day');
  const momentBStart = momentB.clone().startOf('day');

  return momentAStart.diff(momentBStart, 'days');
};

export const isSameDay = (momentA?: moment.Moment, momentB?: moment.Moment): boolean => {
  if (!momentA || !momentB) {
    return false;
  }

  return momentA.isSame(momentB, 'day');
};

export const getGreeting = (currentDate: moment.Moment): { id: string; defaultMessage: string } => {
  const timeFormat = 'hh:mm:ss A';
  if (!currentDate || !currentDate.isValid()) {
    return message.hello;
  }

  const currentTime = moment(currentDate.format(timeFormat), timeFormat);

  const time4AM = moment('04:00:00 AM', timeFormat);
  const time11AM = moment('11:00:00 AM', timeFormat);
  const time5PM = moment('05:00:00 PM', timeFormat);

  if (currentTime.isAfter(time4AM) && currentTime.isSameOrBefore(time11AM)) {
    return message.goodMorning;
  } else if (currentTime.isAfter(time11AM) && currentTime.isSameOrBefore(time5PM)) {
    return message.goodAfternoon;
  } else {
    return message.goodEvening;
  }
};

export const is12HourCycle = () => {
  const dateTimeFormat = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric' });
  return dateTimeFormat.resolvedOptions().hour12;
};
