import get from 'lodash/get';
import map from 'lodash/map';
import findKey from 'lodash/findKey';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';
import { Button, Radio } from 'ui-components';

import FilterContainer from '../../filter/FilterContainer';
import types from '../ducks/types';
import CustomButton from '../../../themes/CustomButton';

import { withTheme } from '../../../contexts/ThemeContext';
import './AddEditQuickview.scss';

const StyledIconContainer = styled.div`
  &.selected {
    &:after {
      border: solid 3px ${(props) => props.theme.primaryColor};
    }
  }
`;

const displayModePicker = (props) => {
  // only show mode picker if there are ALL modes + at least 2 other modes (e.g. All, TL, LTL)
  const shouldShowModeFilters = props.modeOptions.length >= 3;
  const translatedModeOptions = props.modeOptions.map((option) => ({
    label: props.intl.formatMessage({ id: option.label.id, defaultMessage: option.label.defaultMessage }),
    value: option.value,
  }));

  if (shouldShowModeFilters) {
    return (
      <div className="pt-2 pb-4" data-locator="quick-view-mode-radio-buttons">
        <FormattedMessage id="quickView.addEditQuickview.selectMode" defaultMessage="Select Mode" tagName="h4" />
        <Radio
          styled
          block
          radioData={translatedModeOptions}
          onChange={(e) => props.onModeSelect(e.target.value, props.config)} //eslint-disable-line react/jsx-no-bind
          value={props.selectedMode}
          theme={props.theme}
        />
      </div>
    );
  }
};

const messages = defineMessages({
  quickView: {
    id: 'quickView.addEditQuickview.quickView',
    defaultMessage: '{mode} Quick View',
  },
  runningLate: {
    id: 'quickView.addEditQuickview.runningLate',
    defaultMessage: 'Running Late',
  },
  enterSearchKeywords: {
    id: 'quickView.addEditQuickview.enterSearchKeywords',
    defaultMessage: 'Enter search keywords',
  },
  quickviewIcon: {
    id: 'quickView.addEditQuickview.quickviewIcon',
    defaultMessage: 'Quickview Icon',
  },
  add: {
    id: 'quickView.addEditQuickview.add',
    defaultMessage: 'Add',
  },
  edit: {
    id: 'quickView.addEditQuickview.edit',
    defaultMessage: 'Edit',
  },
  pickupDate: {
    id: 'quickView.addEditQuickview.pickupDate',
    defaultMessage: 'Pickup Date',
  },
  deliveryDate: {
    id: 'quickView.addEditQuickview.deliveryDate',
    defaultMessage: 'Delivery Date',
  },
  lastFreeDate: {
    id: 'quickView.addEditQuickview.lastFreeDate',
    defaultMessage: 'Last Free Date',
  },
  vesselArrivalDate: {
    id: 'quickView.addEditQuickview.vesselArrivalDate',
    defaultMessage: 'Vessel Arrival Date',
  },
});

const AddEditQuickview = (props) => {
  const selectedIcon = types.ICON_MAP[get(props, 'config.icon')];
  if (props.errors.required || props.errors.uniqueRequired) {
    const quickViewElement = document.getElementById('quickViewTitle');
    if (quickViewElement) {
      quickViewElement.focus();
    }
  }

  const ADD = props.intl.formatMessage(messages.add);
  const EDIT = props.intl.formatMessage(messages.edit);

  return (
    <Dialog className="quickview-dialog" fullScreen={false} open={props.open} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        {props.intl.formatMessage(messages.quickView, { mode: props.mode === 'Edit' ? EDIT : ADD })}
      </DialogTitle>
      <DialogContent className="quickview-container">
        <div className="form-group">
          <FormattedMessage id="quickView.addEditQuickview.title" defaultMessage="Title" tagName="h4" />
          <input
            name="title"
            id="quickViewTitle"
            maxLength="50"
            autoFocus={props.errors.required || props.errors.uniqueRequired} //eslint-disable-line jsx-a11y/no-autofocus
            onBlur={(event) => props.setInputValue(event.target.value, 'title')} //eslint-disable-line react/jsx-no-bind
            placeholder={props.intl.formatMessage(messages.runningLate)}
            defaultValue={get(props, 'config.title')}
            type="text"
            className={
              props.errors.required || props.errors.uniqueRequired ? 'is-invalid form-control' : 'form-control'
            }
          />
          <div className="invalid-feedback">
            {props.errors.required && (
              <FormattedMessage
                id="quickView.addEditQuickview.thisFieldIsRequired"
                defaultMessage="This field is required."
              />
            )}
            {props.errors.uniqueRequired && (
              <FormattedMessage
                id="quickView.addEditQuickview.pleaseEnteAUniqueQuickViewTileTitle"
                defaultMessage="Please enter a unique quick view tile title."
              />
            )}
          </div>
        </div>
        <FormattedMessage id="quickView.addEditQuickview.selectAnIcon" defaultMessage="Select an icon" tagName="h4" />
        <div className="flex-row d-flex flex-wrap">
          {map(types.ICON_MAP, (icon, index) => (
            <StyledIconContainer
              className={icon === selectedIcon ? 'quickview-icon-container selected' : 'quickview-icon-container'}
              key={index}
              onClick={() => props.changeSelectedIcon(findKey(types.ICON_MAP, (item) => item === icon))} //eslint-disable-line react/jsx-no-bind
              theme={props.theme}
            >
              <img className="quickview-icon" alt={props.intl.formatMessage(messages.quickviewIcon)} src={icon} />
            </StyledIconContainer>
          ))}
        </div>

        {displayModePicker(props)}

        <p className="form-header">
          <FormattedMessage
            id="quickView.addEditQuickview.includeShipmentsThatMatchTheFollowing"
            defaultMessage="Include shipments that match the following:"
          />
        </p>
        <div className="form-group">
          <FormattedMessage
            id="quickView.addEditQuickview.searchKeywords"
            defaultMessage="Search keywords"
            tagName="h4"
          />
          <input
            name="searchKeywords"
            onBlur={(event) => props.setInputValue(event.target.value, 'searchKeywords')} //eslint-disable-line react/jsx-no-bind
            placeholder={props.intl.formatMessage(messages.enterSearchKeywords)}
            defaultValue={get(props, 'config.searchKeywords')}
            type="text"
            className="form-control"
          />
        </div>

        <FilterContainer checkboxTheme="dark" quickView />

        {props.mode === 'Edit' && (
          <CustomButton
            classes="dangerous small full-width delete-button"
            clickFn={() => props.delete(props.tileIndex)} //eslint-disable-line react/jsx-no-bind
            data-locator="quick-view-delete"
          >
            <FormattedMessage
              id="quickView.addEditQuickview.deleteConfiguration"
              defaultMessage="Delete Configuration"
            />
          </CustomButton>
        )}
      </DialogContent>
      <DialogActions className="quickview-actions">
        <CustomButton classes="muted medium cancel-button" clickFn={props.cancel}>
          <FormattedMessage id="quickView.addEditQuickview.cancel" defaultMessage="Cancel" />
        </CustomButton>
        <Button
          type="primary"
          data-locator="quick-view-save-button"
          theme={props.theme}
          clickFn={() =>
            props.save(
              props.mode === 'Add' ? types.QUICK_VIEW_SAVE_ADD : types.QUICK_VIEW_SAVE_EDIT,
              props.config,
              props.updatedFilter,
              props.selectedMode
            )
          }
        >
          {props.mode === 'Add' && <FormattedMessage id="quickView.addEditQuickview.create" defaultMessage="Create" />}
          {props.mode === 'Edit' && <FormattedMessage id="quickView.addEditQuickview.update" defaultMessage="Update" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTheme(injectIntl(AddEditQuickview));
