import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';
import moment from 'moment';
import { Card } from 'ui-components';

import { InventoryItem, InventoryIdentifierType, intlIventoryKeys } from 'components/Inventory/models';
import { LONG_MONTH_DAY_YEAR_FORMAT } from 'i18n/configurei18n';
import { TagsAndIdentifiersCard } from 'components/orderDetails/DetailsTab';
import { getInventoryIdentifierValue, getInventoryIdentifierDescription } from './inventoryHelpers';

interface InventoryItemDetailsTabProps {
  details: InventoryItem;
}

const InventoryItemDetailsTab: React.FC<InventoryItemDetailsTabProps> = ({ details = {} }) => {
  const intl = useIntl();

  const inventoryIdentifiers = details.inventoryIdentifiers || [];

  return (
    <Container fluid>
      <Row>
        <Col style={{ paddingLeft: '2px' }} className="flex flex-wrap justify-start">
          <Card
            style={{ minWidth: '300px' }}
            className="w-3/12 mx-4"
            title={
              <FormattedMessage
                id="inventory.itemDetails.detailsTab.cardHeaders.manufacturer"
                defaultMessage="Manufacturer"
              />
            }
          >
            <Row className="mb-5">
              <Col>
                <strong>
                  <FormattedMessage id="inventory.itemDetails.detailsTab.labels.mfrId" defaultMessage="ID" />
                </strong>
              </Col>
              <Col>{getInventoryIdentifierValue(InventoryIdentifierType.MANUFACTURER, details)}</Col>
            </Row>
            <Row className="mb-5">
              <Col>
                <strong>
                  <FormattedMessage id="inventory.itemDetails.detailsTab.labels.mfrname" defaultMessage="Name" />
                </strong>
              </Col>
              <Col>{getInventoryIdentifierDescription(InventoryIdentifierType.MANUFACTURER, details)}</Col>
            </Row>
          </Card>
          <Card
            style={{ minWidth: '300px' }}
            className="w-3/12 mx-4"
            title={intl.formatMessage({
              id: 'inventory.itemDetails.detailsTab.cardHeaders.unitOfMeasure',
              defaultMessage: 'Unit Of Measure',
            })}
          >
            <Row className="mb-3">
              <Col>
                <strong>
                  <FormattedMessage id="inventory.orderDetails.detailsTab.labels.perUnitCost" defaultMessage="Cost" />
                </strong>
              </Col>
              <Col>
                {get(details, 'perUnitCost.amount', 0).toLocaleString(undefined, {
                  style: 'currency',
                  currency: get(details, 'perUnitCost.currency', 'USD'),
                })}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <strong>
                  <FormattedMessage
                    id="inventory.orderDetails.detailsTab.labels.perUnitRetail"
                    defaultMessage="Retail"
                  />
                </strong>
              </Col>
              <Col>
                {get(details, 'perUnitRetailValue.amount', 0).toLocaleString(undefined, {
                  style: 'currency',
                  currency: get(details, 'perUnitRetailValue.currency', 'USD'),
                })}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <strong>
                  <FormattedMessage id="inventory.orderDetails.detailsTab.labels.unitWeight" defaultMessage="Weight" />
                </strong>
              </Col>
              <Col>
                {get(details, 'inventoryDimensionalWeight.weight.weight', 0)}{' '}
                {get(details, 'inventoryDimensionalWeight.weight.weightUnit')}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <strong>
                  <FormattedMessage
                    id="inventory.orderDetails.detailsTab.labels.packageType"
                    defaultMessage="Package Type"
                  />
                </strong>
              </Col>
              <Col>{get(details, 'inventoryDimensionalWeight.packageType', '')}</Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <strong>
                  <FormattedMessage id="inventory.orderDetails.detailsTab.labels.length" defaultMessage="Length" />
                </strong>
              </Col>
              <Col>
                {get(details, 'inventoryDimensionalWeight.cubicDimension.length', 0)}{' '}
                {get(details, 'inventoryDimensionalWeight.lengthUnit')}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <strong>
                  <FormattedMessage id="inventory.orderDetails.detailsTab.labels.width" defaultMessage="Width" />
                </strong>
              </Col>
              <Col>
                {get(details, 'inventoryDimensionalWeight.cubicDimension.width', 0)}{' '}
                {get(details, 'inventoryDimensionalWeight.lengthUnit')}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <strong>
                  <FormattedMessage id="inventory.orderDetails.detailsTab.labels.height" defaultMessage="Height" />
                </strong>
              </Col>
              <Col>
                {get(details, 'inventoryDimensionalWeight.cubicDimension.height', 0)}{' '}
                {get(details, 'inventoryDimensionalWeight.lengthUnit')}
              </Col>
            </Row>
          </Card>
          <Card
            style={{ minWidth: '300px' }}
            className="w-3/12 mx-4"
            title={
              <FormattedMessage id="inventory.itemDetails.detailsTab.cardHeaders.keyDates" defaultMessage="Key Dates" />
            }
          >
            <Row className="mb-3">
              <Col>
                <strong>
                  <FormattedMessage id="inventory.itemDetails.detailsTab.labels.sellBy" defaultMessage="Sell By" />
                </strong>
              </Col>
              <Col>{moment(get(details, 'sellByDateTime'), moment.ISO_8601).format(LONG_MONTH_DAY_YEAR_FORMAT)}</Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <strong>
                  <FormattedMessage
                    id="inventory.itemDetails.detailsTab.labels.promoStart"
                    defaultMessage="Promo Start"
                  />
                </strong>
              </Col>
              <Col>
                {moment(get(details, 'promotionalDateTimeWindow.startDateTime')).format(LONG_MONTH_DAY_YEAR_FORMAT)}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <strong>
                  <FormattedMessage id="inventory.itemDetails.detailsTab.labels.packageDate" defaultMessage="Package" />
                </strong>
              </Col>
              <Col>{moment(get(details, 'packagedDateTime')).format(LONG_MONTH_DAY_YEAR_FORMAT)}</Col>
            </Row>
            {get(details, 'consumeByDateTime') && (
              <Row className="mb-3">
                <Col>
                  <strong>
                    <FormattedMessage
                      id="inventory.itemDetails.detailsTab.labels.consumeBy"
                      defaultMessage="Consume by"
                    />
                  </strong>
                </Col>
                <Col>{moment(get(details, 'consumeByDateTime')).format(LONG_MONTH_DAY_YEAR_FORMAT)}</Col>
              </Row>
            )}

            {get(details, 'manufacturedDateTime') && (
              <Row className="mb-3">
                <Col>
                  <strong>
                    <FormattedMessage
                      id="inventory.itemDetails.detailsTab.labels.mfrDate"
                      defaultMessage="Manufactured"
                    />
                  </strong>
                </Col>
                <Col>{moment(get(details, 'manufacturedDateTime')).format(LONG_MONTH_DAY_YEAR_FORMAT)}</Col>
              </Row>
            )}

            {get(details, 'promotionalDateTimeWindow.endDateTime') && (
              <Row className="mb-3">
                <Col>
                  <strong>
                    <FormattedMessage
                      id="inventory.itemDetails.detailsTab.labels.promoEnd"
                      defaultMessage="Promo end"
                    />
                  </strong>
                </Col>
                <Col>
                  {moment(get(details, 'promotionalDateTimeWindow.endDateTime')).format(LONG_MONTH_DAY_YEAR_FORMAT)}
                </Col>
              </Row>
            )}
          </Card>
          <TagsAndIdentifiersCard
            title={intl.formatMessage({
              id: 'inventory.detailsTab.cardHeaders.tagsAndIdentifiers',
              defaultMessage: 'Tags and Identifiers',
            })}
            identifiers={inventoryIdentifiers}
            intlKeys={intlIventoryKeys}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default InventoryItemDetailsTab;
