import { Component } from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import NotificationDialogComponent from '../../common/notificationDialog/NotificationDialogComponent';
import { SESSION_MESSAGE, COUNT_DOWN_TIME } from '../../../common/AppConstants';
import { hourMinuteFormat } from '../../../common/dateUtils';

const messages = defineMessages({
  areYouStillThere: {
    id: 'timeOut.SessionDialogContent.areYouStillThere',
    defaultMessage: 'Are you still there?',
  },
  stillHere: {
    id: 'timeOut.SessionDialogContent.stillHere',
    defaultMessage: "I'M STILL HERE",
  },
  minutesSeconds: {
    id: 'timeOut.SessionDialogContent.minutesSeconds',
    defaultMessage: 'minutes/seconds',
  },
});

class SessionDialogContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: hourMinuteFormat(COUNT_DOWN_TIME, 'mmss'),
    };
  }
  componentDidMount() {
    this.handleCounter();
  }
  componentWillUnmount() {
    clearTimeout(this.timerId);
  }
  handleCounter = () => {
    const countdown = (minutes) => {
      let seconds = 60;
      const tick = () => {
        let current_minutes = minutes - 1;
        seconds--;
        let counter =
          (current_minutes < 10 ? '0' : '') +
          String(current_minutes) +
          ':' +
          (seconds < 10 ? '0' : '') +
          String(seconds);
        if (seconds > 0) {
          this.timerId = setTimeout(tick, 1000);
        } else {
          if (minutes > 1) {
            this.timerId = setTimeout(() => countdown(minutes - 1), 1000);
          }
        }
        if (seconds === 0 && current_minutes === 0) {
          this.setState({ timer: counter });
          this.props.dialogSessionHandler(true);
        } else {
          this.setState({ timer: counter });
        }
      };

      tick();
    };
    countdown(COUNT_DOWN_TIME);
  };

  render() {
    let styles = {
      color: 'red',
      fontWeight: 'bold',
      whiteSpace: 'pre-wrap',
    };

    return (
      <NotificationDialogComponent
        open={this.props.open}
        closeDialog={() => this.props.dialogSessionHandler(false)} //eslint-disable-line react/jsx-no-bind
        dialogTitle={`${this.props.intl.formatMessage(messages.areYouStillThere)}`}
        actionFn={() => this.props.dialogSessionHandler(false)} //eslint-disable-line react/jsx-no-bind
        actionText={`${this.props.intl.formatMessage(messages.stillHere)}`}
      >
        <div className="message-content" style={{ whiteSpace: 'pre-wrap' }}>
          <FormattedMessage id={SESSION_MESSAGE.id} defaultMessage={SESSION_MESSAGE.defaultMessage} />
          <span style={styles}>
            &nbsp;{this.state.timer}
            {` ${this.props.intl.formatMessage(messages.minutesSeconds)}`}
          </span>
        </div>
      </NotificationDialogComponent>
    );
  }
}
export default withMobileDialog()(injectIntl(SessionDialogContent));
