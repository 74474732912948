export const STEPS = [
  {
    id: 'subtotal',
    translation: {
      id: 'quickViewConfig.step.subtotal',
      defaultMessage: 'Subtotal',
    },
  },
  {
    id: 'total',
    translation: {
      id: 'quickViewConfig.step.total',
      defaultMessage: 'Total',
    },
  },
  {
    id: 'title-and-settings',
    translation: {
      id: 'quickViewConfig.step.tiltleAndSettings',
      defaultMessage: 'Title & Settings',
    },
  },
];

export enum QuickviewEntityType {
  // making these strings makes inspecting session storage easier. since there is only one ever stored,
  // the space difference between a number and a short string should not be significant
  SHIPMENTS = 'shipments',
  ORDERS = 'orders',
  INVENTORY = 'inventory',
}

export const QUICKVIEW_SUBTOTAL_SEARCHTERM = '_quickview_subtotal_searchterm_';
export const QUICKVIEW_SUBTOTAL_FILTERS = '_quickview_subtotal_filters_';
export const QUICKVIEW_SUBTOTAL_COUNT = '_quickview_subtotal_count_';
export const QUICKVIEW_TOTAL_SEARCHTERM = '_quickview_total_searchterm_';
export const QUICKVIEW_TOTAL_FILTERS = '_quickview_total_filters_';
export const QUICKVIEW_TOTAL_COUNT = '_quickview_total_count_';
export const QUICKVIEW_SUBTOTAL_MODE = '_quickview_subtotal_mode_';
export const QUICKVIEW_TOTAL_MODE = '_quickview_total_mode_';

export const HEALTH_RANGES = {
  excellent: {
    text: 'Excellent',
    iconName: 'thumb-up',
    accentColor: 'green',
  },
  acceptable: {
    text: 'Acceptable',
    iconName: 'check-circle',
    accentColor: 'blue',
  },
  concerning: {
    text: 'Concerning',
    iconName: 'warning',
    accentColor: 'orange',
  },
  problematic: {
    text: 'Problematic',
    iconName: 'error',
    accentColor: 'red',
  },
};
