import types from './types';

const initialState = {
  shipmentDetails: undefined,
  error: false,
  showExpanded: true,
};

const ltlShipmentDetailsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_SHIPMENT_DATA_SUCCESS:
    case types.FETCH_PUBLIC_SHIPMENT_DATA_SUCCESS:
      return { ...state, shipmentDetails: action.shipment };
    case types.FETCH_SHIPMENT_DATA_FAILURE:
    case types.FETCH_PUBLIC_SHIPMENT_DATA_FAILURE:
      return { ...state, error: true };
    case types.TOGGLE_ROUTE_DISPLAY:
      return { ...state, showExpanded: !state.showExpanded };
    case types.TOGGLE_GROUPED_DISPLAY:
      return {
        ...state,
        shipmentDetails: {
          ...state.shipmentDetails,
          route: {
            ...state.shipmentDetails.route,
            routeDetails: state.shipmentDetails.route.routeDetails.map((route, index) => {
              if (index === action.index) {
                route.expanded = !route.expanded;
              }
              return route;
            }),
          },
        },
      };
    case types.RESET_SHIPMENT:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default ltlShipmentDetailsReducer;
