import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import * as styles from './MapLegend.module.scss';

const Block = styled.div`
  margin-left: 15px;
  margin-right: 10px;
`;

interface MapLegendProps {
  title?: {
    id: string;
    defaultMessage: string;
  };
  items?: Array<{
    icon: string | React.ReactNode | React.FunctionComponent;
    label: {
      id: string;
      defaultMessage: string;
    };
  }>;
}

const MapLegend: React.FC<MapLegendProps> = (props) => {
  return (
    <div data-locator="map-legend" className={styles.legend}>
      <div className={styles.legendInner}>
        {props.title && (
          <FormattedMessage id={props.title.id} defaultMessage={props.title.defaultMessage}>
            {(...text) => <span className={styles.title}>{text}</span>}
          </FormattedMessage>
        )}
        <div className={styles.legendIcons}>
          {props.items &&
            props.items.map((item) => (
              <>
                <Block>{item.icon}</Block>
                <FormattedMessage id={item.label.id} defaultMessage={item.label.defaultMessage} />
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MapLegend;
