import 'styles/main.scss';
import { Link } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import styled from 'styled-components';
import { PrincipalAuthorizations, portCongestionAppEnabled } from 'common/authorizations';
import { ReactComponent as AnchorIcon } from 'components/common/assets/icons/icon-anchor.svg';
import { ReactComponent as LocationIcon } from 'components/common/assets/icons/icon-location.svg';
import { ReactComponent as PointerIcon } from 'components/common/assets/icons/icon-pointer.svg';
import { ReactComponent as DashboardIcon } from 'components/common/assets/icons/icon-dashboard.svg';
import { ReactComponent as TrafficLightIcon } from 'components/common/assets/icons/icon-traffic-light.svg';
import { ReactComponent as DotsIcon } from 'components/common/assets/icons/icon-dots.svg';

import endpoints from '../../../common/endpoints';
import { withTheme } from '../../../contexts/ThemeContext';
import colors from '../../../styles/colors';
import './FixedNav.scss';

const StyledPaper = styled(Paper)`
  .analytics-menu {
    .analytics-menu-item {
      color: ${colors.primaryTextColor};
      span {
        color: ${colors.primaryTextColor};
      }
      svg g {
        fill: ${colors.primaryTextColor};
      }
      &.selected {
        background-color: ${colors.secondaryBackgroundColor};
        border-right: 8px solid ${(props) => props.theme.primaryColor};
        color: ${(props) => props.theme.primaryColor};
        svg g {
          fill: ${(props) => props.theme.primaryColor};
        }
        span {
          color: ${(props) => props.theme.primaryColor};
        }
      }
    }
  }
`;

const messages = defineMessages({
  carrierOverview: {
    defaultMessage: 'Carrier Analytics ',
    id: 'analytics.analyticsMenuItem.carrierOverview',
  },
  carrierOnboardingReport: {
    defaultMessage: 'NMC Onboarding',
    id: 'analytics.analyticsMenuItem.carrierOnboardingReport',
  },
  carrierScorecard: {
    defaultMessage: 'Carrier Scorecard',
    id: 'analytics.analyticsMenuItem.carrierScorecard',
  },
  locationAnalytics: {
    defaultMessage: 'Location Analytics ',
    id: 'analytics.analyticsMenuItem.locationAnalytics',
  },
  locationAnalyticsBeta: {
    defaultMessage: 'Beta',
    id: 'analytics.analyticsMenuItem.locationAnalyticsBeta',
  },
  oceanDashboard: {
    defaultMessage: 'Dashboard',
    id: 'analytics.analyticsMenuItem.oceanDashboard',
  },
  portCongestion: {
    defaultMessage: 'Port Congestion',
    id: 'analytics.analyticsMenuItem.portCongestion',
  },
  carrierProcurement: {
    defaultMessage: 'Carrier Procurement',
    id: 'analytics.analyticsMenuItem.carrierProcurement',
  },
});

const carrierOnboardingReportIcon = () => <DotsIcon style={{ fontSize: '22px', width: '25px', height: '25px' }} />;

const truckloadFixedNavMenu = (permissions) => [
  {
    path: endpoints.ANALYTICS_CARRIER_OVERVIEW,
    message: messages.carrierOverview,
    Icon: PointerIcon,
    enabled: permissions.hasCarrierOverviewBeta,
  },
  {
    path: endpoints.ANALYTICS_CARRIER_ONBOARDING_REPORT,
    message: messages.carrierOnboardingReport,
    Icon: carrierOnboardingReportIcon,
    enabled: permissions.hasNetworkLink,
  },
  {
    path: endpoints.ANALYTICS_LOCATION_ANALYTICS,
    message: messages.locationAnalytics,
    Icon: LocationIcon,
    enabled: permissions.hasLocationOverviewBeta,
  },
  {
    path: endpoints.ANALYTICS_CARRIER_PROCUREMENT,
    message: messages.carrierProcurement,
    Icon: TrafficLightIcon,
    enabled: permissions.hasCarrierProcurement,
  },
];

const oceanFixedNavMenu = [
  {
    path: endpoints.ANALYTICS_OCEAN_OVERVIEW,
    message: messages.oceanDashboard,
    Icon: DashboardIcon,
    enabled: true,
  },
  {
    path: endpoints.ANALYTICS_OCEAN_PORT_CONGESTION,
    message: messages.portCongestion,
    Icon: AnchorIcon,
    enabled: portCongestionAppEnabled(),
  },
];

const FixedNav = (props) => {
  const authorizations = new PrincipalAuthorizations({
    authorities: props.principalAuthorizations.authorities,
    principal: props.principalAuthorizations,
  });
  const hasCarrierOverviewBeta = true;
  const hasLocationOverviewBeta = true;
  const hasNetworkLink = authorizations.hasNetworkLink();
  const hasCarrierProcurement = authorizations.hasCarrierProcurement();

  const buildMenuList = (type = 'truckload') => {
    const truckloadMenuItems = truckloadFixedNavMenu({
      hasCarrierOverviewBeta,
      hasLocationOverviewBeta,
      hasNetworkLink,
      hasCarrierProcurement,
    });
    const selectedMenuItemList = { truckload: truckloadMenuItems, ocean: oceanFixedNavMenu }[type];
    return selectedMenuItemList
      .filter(({ enabled }) => enabled)
      .map(({ path, message, Icon }) => (
        <Link to={path} key={path}>
          <MenuItem
            className={props.currentView === path ? 'analytics-menu-item selected' : 'analytics-menu-item'}
            onClick={props.fetchTrustedTicket}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText inset primary={props.intl.formatMessage(message)} />
          </MenuItem>
        </Link>
      ));
  };

  return (
    <StyledPaper data-testid="analytics-menu" className="analytics-menu-container" theme={props.theme}>
      <MenuList className="analytics-menu">{buildMenuList(props.mode)}</MenuList>
    </StyledPaper>
  );
};

export default withTheme(injectIntl(FixedNav));
