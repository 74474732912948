import { Field, FormSection } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import MenuItem from 'material-ui/MenuItem';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'ui-components';
import { MONTH_DAY_YEAR_FORMAT } from 'i18n/configurei18n';
import { intl } from 'common/AppConstants';
import { availableTrackingMethods } from '../ducks';
import { phoneWithNumberCountryCodeValidation } from '../../../common/FormValidations';
import { renderInput, renderDatePicker, renderSingleSelectDropdown } from '../../common/forms/FormInputs';
import './ScheduleTruckChangeForm.scss';
import { messages } from '../ShipmentForm/ShipmentForm';

const ScheduleTruckChangeForm = ({ id, removeScheduleTruckChange, extraInfo = false }) => {
  const { licensePlate, phone, truckNumber } = availableTrackingMethods;
  const [MOBILE_PHONE, TRUCK_NUMBER, LICENSE_PLATE, NOT_SUPPORTED] = [
    'MOBILE_PHONE',
    'TRUCK_NUMBER',
    'LICENSE_PLATE',
    'NOT_SUPPORTED',
  ];

  const mapTrackingMethodType = (trackingMethodType) => {
    switch (trackingMethodType) {
      case availableTrackingMethods.phone.id:
        return MOBILE_PHONE;
      case availableTrackingMethods.truckNumber.id:
        return TRUCK_NUMBER;
      case availableTrackingMethods.licensePlate.id:
        return LICENSE_PLATE;
      default:
        return NOT_SUPPORTED;
    }
  };

  const [vehicleTypeID, setVehicleTypeID] = useState();
  const [vehicleValue, setVehicleValue] = useState();

  const getVehicleTypeInput = () => {
    const configObject = {};
    if (vehicleTypeID === MOBILE_PHONE) {
      configObject.lable = intl.formatMessage(messages.driverMobileNumber);
      configObject.component = renderInput;
      configObject.validate = phoneWithNumberCountryCodeValidation;
      configObject.placeholder = '+12345678910';
    } else if (vehicleTypeID === LICENSE_PLATE) {
      configObject.lable = intl.formatMessage(messages.licensePlate);
      configObject.component = renderInput;
      configObject.placeholder = intl.formatMessage(messages.enterLicensePlateNumber);
    } else if (vehicleTypeID === TRUCK_NUMBER) {
      configObject.lable = intl.formatMessage(messages.truckNumber);
      configObject.component = renderInput;
      configObject.placeholder = '123';
    } else {
      configObject.lable = intl.formatMessage(messages.vehicleId);
      configObject.component = renderInput;
      configObject.placeholder = intl.formatMessage(messages.vehicleIdValue);
    }

    return (
      <Field
        type="text"
        name="value"
        onChange={(event) => {
          setVehicleValue(event.target.value);
        }}
        label={configObject.lable}
        component={configObject.component}
        normalize={configObject.normalize}
        validate={configObject.validate}
        placeholder={configObject.placeholder}
        value={vehicleValue}
      />
    );
  };

  const handleselectChange = (vehicleTypeID) => {
    setVehicleTypeID(vehicleTypeID);
  };

  return (
    <FormSection name={'scheduleTruckChange_' + id}>
      <Row>
        <Col xs={12}>
          <Field
            name="type"
            label="Vehicle ID Type"
            onChange={handleselectChange}
            component={renderSingleSelectDropdown}
          >
            <MenuItem value={mapTrackingMethodType(licensePlate.id)} primaryText={licensePlate.name} />
            <MenuItem value={mapTrackingMethodType(phone.id)} primaryText={phone.name} />
            <MenuItem value={mapTrackingMethodType(truckNumber.id)} primaryText={truckNumber.name} />
          </Field>
        </Col>
        <Col xs={12}>{getVehicleTypeInput()}</Col>
      </Row>

      {extraInfo ? (
        <div className="scheduledTruckChangeOptionsContainer">
          <Row>
            <Col xs={12} className="date-picker-custom">
              <Field
                type="text"
                label="Date"
                component={renderDatePicker}
                format={null}
                name="scheduleDate"
                placeholder={MONTH_DAY_YEAR_FORMAT}
              />
            </Col>
            <Col xs={12}>
              <Field type="time" label="Time" component={renderInput} name="scheduleTime" />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Button type="danger" clickFn={() => removeScheduleTruckChange(id)}>
                <FormattedMessage id="temp" defaultMessage="Delete" />
              </Button>
            </Col>
          </Row>
        </div>
      ) : null}
    </FormSection>
  );
};

export default ScheduleTruckChangeForm;
