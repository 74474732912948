import FileType from 'file-type/browser';
import { DOCUMENTS_MAX_FILE_SIZE } from 'common/AppConstants';

const supportedFileTypes = new Map<string, string>([
  ['application/pdf', 'application/pdf'],
  ['image/png', 'image/png'],
  ['image/jpeg', 'image/jpeg'],
  ['image/gif', 'image/gif'],
  ['image/tiff', 'image/tiff'],
]);

/**
 * validateFilesExtType:
 * checks if all files are of the same type. (within supported file types)
 */
export const validateFilesExtType = (files: File[]) => {
  let fileType: string;
  if (supportedFileTypes.has(files[0].type)) {
    fileType = files[0].type;
  } else {
    return false;
  }

  return files.filter((file) => file.type !== fileType).length === 0;
};

export const validateFilesSize = (files: File[]) => {
  for (let i = 0; i < files.length; i += 1) {
    // Allow 10 megabyte files
    if (files[i].size >= DOCUMENTS_MAX_FILE_SIZE) return false;
  }
  return true;
};

/**
 * getTypeFromMN:
 * acccepts a binary based file, reads the file, determines MIME type by reading the file's "Magic Numbers"
 * @param file - expects a binary based file
 * @returns String of the the file's MIME type or null (if type is not our supported type)
 */

const getTypeFromMN = async (file: File) => {
  try {
    const result = await FileType.fromBlob(file);
    if (result?.mime && supportedFileTypes.has(result.mime)) return result.mime;
    return null;
  } catch (err) {
    return err;
  }
};

export const getFileTypesForAcceptedFiles = async (files: File[]) => {
  return Promise.all(files.map((f) => getTypeFromMN(f)));
};

interface MimeValidated {
  validatedMimeType?: string | null;
}

export interface MimeValidatedFile extends File, MimeValidated {}

export function checkMimeTypeStatus(files: MimeValidatedFile[] | undefined) {
  return files?.every((f) => {
    if (f.validatedMimeType === null) return false;
    return true;
  });
}
