import get from 'lodash/get';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import merge from 'lodash/merge';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { LOCALES } from 'common/AppConstants';

import {
  EVENT_TYPE_STATE_TO_API_MAP,
  API_EVENT_TYPES_WITH_NO_STOP_TYPE_PARAMETER,
  STATE_TO_API_STOP_TYPE_MAP,
  STATE_SUBSCRIPTION_TYPE_TO_API_MAP,
  STATE_OPERATOR_TO_API_MAP,
  STATE_FIELD_TYPE_TO_API_MAP,
  STATE_TO_API_CODE_STATUS_MAP,
} from './constants';

import {
  TLEventTypeKeyConstants,
  LTLEventTypeKeyConstants,
  ModeTypeConstants,
  ParcelEventTypeKeyConstants,
  OceanEventTypeKeyConstants,
  SubscriptionTypes,
} from '../constants';

const mapApiStopTypeEnum = (stopType) => STATE_TO_API_STOP_TYPE_MAP[stopType] || null;

const extractApiStopParameter = (stateEventTypeKey, value) =>
  !API_EVENT_TYPES_WITH_NO_STOP_TYPE_PARAMETER.has(stateEventTypeKey) && mapApiStopTypeEnum(get(value, 'type'));

const extractApiStatusCodeParameter = (value) => STATE_TO_API_CODE_STATUS_MAP[get(value, 'type')] || null;

const flatten = (array) => [].concat.apply([], array);

const extractApiTimeParameter = (stateEventType, timeParameterValue) => {
  let key = null;

  const minutesLateStateEventTypes = [
    TLEventTypeKeyConstants.RunningLate,
    ParcelEventTypeKeyConstants.RunningLate,
    TLEventTypeKeyConstants.DepartedLate,
  ];
  const hoursLateStateEventTypes = [OceanEventTypeKeyConstants.EmbarkingLate, OceanEventTypeKeyConstants.ArrivingLate];

  if (stateEventType === TLEventTypeKeyConstants.Arriving) {
    key = 'minutesUntilArrival';
  } else if (minutesLateStateEventTypes.includes(stateEventType)) {
    key = 'minutesLate';
  } else if (hoursLateStateEventTypes.includes(stateEventType)) {
    key = 'hoursLate';
  }
  // TODO: confirm these
  else if (stateEventType === TLEventTypeKeyConstants.Dwelling) {
    key = 'minutesDwelling';
  } else if (stateEventType === TLEventTypeKeyConstants.IdleInTransit) {
    key = 'minutesIdle';
  } else if (stateEventType === TLEventTypeKeyConstants.TemperatureOutOfRange) {
    key = 'minutesTemperatureOutOfRange';
  } else if (stateEventType === TLEventTypeKeyConstants.TrackingApprovalPending) {
    key = 'minutesBeforeAppointmentWindow';
  } else if (stateEventType === LTLEventTypeKeyConstants.OutForDeliveryMissed) {
    key = 'minutesOffset';
  }

  if (key) {
    return { [key]: parseInt(timeParameterValue, 10) };
  }

  return null;
};

export const mapNotificationEventFromStateToApi = (stateEvent) => {
  const eventType = get(EVENT_TYPE_STATE_TO_API_MAP, get(stateEvent, 'eventTypeKey'));
  if (!eventType) {
    return null;
  }

  const event = {
    ruleId: stateEvent.id,
    ruleType: eventType,
    version: stateEvent.version,
  };

  const ruleConfiguration = {};

  const stopType = extractApiStopParameter(stateEvent.eventTypeKey, stateEvent.stopParameter);
  if (stopType) {
    ruleConfiguration.stopType = stopType;
  }

  const statusCode = extractApiStatusCodeParameter(stateEvent.statusCodeParameter);
  if (statusCode) {
    ruleConfiguration.statusCode = statusCode;
  }

  const countryCode = stateEvent.countryParameter?.country;
  if (countryCode) {
    ruleConfiguration.country = countryCode;
  }

  const timeParameter = extractApiTimeParameter(stateEvent.eventTypeKey, get(stateEvent.timeParameter, 'value'));
  if (timeParameter) {
    merge(ruleConfiguration, timeParameter);
  }

  if (get(stateEvent, 'timeParameter.value')) {
    if (stateEvent.mode === ModeTypeConstants.LTL) {
      ruleConfiguration.minutesOffset = stateEvent.timeParameter.value * 60;
    }
  }

  if (get(stateEvent, 'offsetDirection')) {
    ruleConfiguration.offsetDirection = stateEvent.offsetDirection;
  }

  event.ruleConfiguration = ruleConfiguration;

  return event;
};

const getApiSubscriptionType = (stateSubscription) =>
  STATE_SUBSCRIPTION_TYPE_TO_API_MAP[get(stateSubscription, 'type')] || null;

const getIdFromSubscriptionOptions = (value, subscriptionOptions) => {
  let pushConfig = find(subscriptionOptions.values.push, (v) => {
    return v.configName === value;
  });
  return isNil(pushConfig) ? value : pushConfig.id;
};

const mapSubscriptionFromStateToApi = (subscription, subscriptionOptions) => {
  if (!subscription) {
    return null;
  }

  const type = getApiSubscriptionType(subscription);
  const values = subscription.values;
  if (!type || isEmpty(values)) {
    return null;
  }

  if (type === 'PUSH') {
    return values.filter(Boolean).map((value) => {
      return {
        type,
        recipientParameters: { pushConfigId: getIdFromSubscriptionOptions(value, subscriptionOptions) },
      };
    });
  }

  if (type === SubscriptionTypes.SMS) {
    return values.filter(Boolean).map((value) => ({
      type,
      locale: get(subscription, 'locale', LOCALES.ENGLISH),
      recipientParameters: {
        phoneNumber: value,
      },
    }));
  }

  return values.filter(Boolean).map((value) => ({ type, recipientParameters: { email: value } }));
};

export const mapTenantFiltersFromStateToApi = (input) => {
  if (!input) {
    return null;
  }

  const filters = pickBy(
    {
      operator: get(STATE_OPERATOR_TO_API_MAP, input.operator, input.operator),
      fields: map(input.fields, ({ type, values }) => {
        const mappedType = get(STATE_FIELD_TYPE_TO_API_MAP, type, type);
        if (!mappedType) {
          return null;
        }
        const mappedValues = values.filter(Boolean);
        if (isEmpty(mappedValues)) {
          return null;
        }

        return {
          type: mappedType,
          values: mappedValues,
        };
      }).filter((field) => !!field),
    },
    (value) => !isNil(value)
  );

  // don't include empty filters opting to exclude from request
  if (!get(filters, 'fields.length')) {
    return null;
  }
  return filters;
};

export default (stateNotification, shipmentId, masterShipmentId, subscriptionOptions) => {
  if (!stateNotification) {
    return null;
  }

  const rules = stateNotification.notificationEvents.map(mapNotificationEventFromStateToApi).filter(Boolean);

  if (isEmpty(rules)) {
    return null;
  }

  const notification = {
    ruleGroup: {
      id: stateNotification.id,
      version: stateNotification.version,
      name: stateNotification.title,
      rules,
    },
  };

  const subscriptions = stateNotification.notificationRecipients
    .map((subscription) => mapSubscriptionFromStateToApi(subscription, subscriptionOptions))
    .filter(Boolean);

  notification.subscriptions = flatten(subscriptions) || [];

  const mode = get(stateNotification, 'mode');
  const tenantFilters = isNil(shipmentId) ? mapTenantFiltersFromStateToApi(stateNotification.filters) : null;
  if (!isEmpty(tenantFilters)) {
    notification.ruleGroup.filters = { tenantFilters };
  } else if (!isNil(masterShipmentId)) {
    notification.ruleGroup.filters = { masterShipmentId };
  } else if (!isNil(shipmentId)) {
    notification.ruleGroup.filters = { shipmentId, mode };
  }

  return notification;
};
