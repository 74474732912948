import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import axios from 'util/paxios';
import { Page, SortField } from 'models';
import { buildUrlWithPaginationParams } from 'models/page-helper';
import { buildUrlWithSortParams } from 'models/sort-helper';
import { BasicInventoryOrderSearchCriteria, InventoryOrder } from 'components/Inventory/models';
import { API_PATH } from 'common/AppConstants';
import endpoints from 'common/endpoints';

export interface InventoryOrdersHook {
  hasPermission: boolean;
  masterShipmentId?: string;
  defaultPageSize?: number;
  searchText?: string;
  inventoryOrderIds?: string[];
}

export enum DataLoadingEnum {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR_LOADING = 'ERROR_LOADING',
}

export function useInventoryOrders({
  hasPermission,
  masterShipmentId,
  defaultPageSize = 30,
  searchText = '',
  inventoryOrderIds,
}: InventoryOrdersHook) {
  const [orders, setOrders] = useState<Page<InventoryOrder>>();
  const [ordersDataLoading, setOrdersDataLoading] = useState<DataLoadingEnum>(DataLoadingEnum.LOADING);

  const searchOrders = (
    body: BasicInventoryOrderSearchCriteria,
    page: number,
    size: number,
    sortFields?: SortField<string>[]
  ) => {
    setOrdersDataLoading(DataLoadingEnum.LOADING);
    getOrders(
      {
        ...body,
        ...(masterShipmentId && { shipmentIds: [masterShipmentId] }),
      },
      page,
      size,
      sortFields
    );
  };

  const getOrders = useCallback(async function getOrders(
    requestBody: BasicInventoryOrderSearchCriteria,
    pageNumber: number,
    pageSize: number,
    sortFields?: SortField<string>[]
  ) {
    try {
      let url = new URL(`${API_PATH}${endpoints.ORDERS_SEARCH}`, window.location.origin);
      url = buildUrlWithPaginationParams(url, { pageNumber, pageSize });
      url = buildUrlWithSortParams(url, sortFields);

      const response: AxiosResponse<Page<InventoryOrder>> = await axios.post(url.toString(), requestBody, {
        withCredentials: true,
      });

      setOrders(response.data);
      setOrdersDataLoading(DataLoadingEnum.LOADED);
    } catch (error) {
      setOrdersDataLoading(DataLoadingEnum.ERROR_LOADING);
    }
  },
  []);

  useEffect(() => {
    if (hasPermission) {
      setOrdersDataLoading(DataLoadingEnum.LOADING);
      getOrders(
        {
          ...(masterShipmentId && { shipmentIds: [masterShipmentId] }),
          ...(inventoryOrderIds &&
            inventoryOrderIds.length &&
            inventoryOrderIds.length > 0 && { ids: inventoryOrderIds }),
          searchText,
        },
        1,
        defaultPageSize,
        []
      );
    }
  }, [getOrders, hasPermission, masterShipmentId, defaultPageSize, searchText, inventoryOrderIds]);

  return {
    ordersDataLoading,
    shipmentOrders: orders,
    searchOrders,
  };
}
