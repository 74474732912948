import styled from 'styled-components';
import { primaryGrey } from 'styles/colors';
import { DateRangeV2 } from 'ui-components';

export const StyledFilterSubTitle = styled('span')`
  color: ${primaryGrey};
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
`;

const StyledDiv = styled('div')`
  padding-bottom: 10px;
`;

interface DateRangeInputWithTitleInput {
  filterName: any;
  datepickerChangeFn: Function;
  boundStartDateValue: string | null;
  boundEndDateValue: string | null;
}

const DateRangeInputWithTitle = ({
  filterName,
  datepickerChangeFn,
  boundStartDateValue,
  boundEndDateValue,
}: DateRangeInputWithTitleInput) => {
  return (
    <StyledDiv>
      <StyledFilterSubTitle>{filterName}</StyledFilterSubTitle>
      <DateRangeV2
        datepickerChangeFn={datepickerChangeFn}
        boundStartDateValue={boundStartDateValue}
        boundEndDateValue={boundEndDateValue}
      />
    </StyledDiv>
  );
};

export default DateRangeInputWithTitle;
