import { put, takeLatest } from 'redux-saga/effects';
import { change } from 'redux-form';
import get from 'lodash/get';
import * as types from './types';

const getFieldName = (stopName, field) => (stopName ? `${stopName}.${field}` : field);

export function* applyFormUpdatesAsync(action) {
  const formName = get(action.payload, 'context.formName');
  yield put(change(formName, getFieldName(action.payload.stop, 'city'), action.payload.details.city));
  yield put(change(formName, getFieldName(action.payload.stop, 'country'), action.payload.details.country));
  yield put(change(formName, getFieldName(action.payload.stop, 'state'), action.payload.details.state));
}

export function* watchPostalCodeLookupSuccess() {
  yield takeLatest(types.POSTAL_CODE_LOOKUP_SUCCESS, applyFormUpdatesAsync);
}
