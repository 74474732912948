import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';

export const getPreviousSelectedSortFieldId = (
  previousSearchQuery: any = {},
  sortFields: Array<{ displayName: string; request: Array<{ fieldName: string; direction: string }> }> = []
): number | undefined => {
  if (get(previousSearchQuery, 'sortBy[0]')) {
    const selectedSortFieldId = findIndex(sortFields, (sortField) =>
      isEqual(sortField.request, previousSearchQuery.sortBy)
    );
    return selectedSortFieldId > -1 ? selectedSortFieldId : undefined;
  }
};
