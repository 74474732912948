import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { initializeTracker } from 'common/eventTracker';
import { store, persistor } from './configureStore';
import './styles/main.scss';
import ContextWrapper from './ContextWrapper';

initializeTracker();

export const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ContextWrapper />
    </PersistGate>
  </Provider>
);

export default App;
