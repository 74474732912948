import { Select } from 'antd';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { SVGIcon } from 'ui-components';
import { primaryGrey, black, primaryBlue, primaryGreyFifty, white } from 'styles/colors';

import { messages } from '../utils';

import './filters-modes-dropdown.scss';

interface ModesProps {
  modeActive: string;
  modesOptions: Array<{
    value: string;
    label: string;
  }>;
  onChangeModes: () => void;
}

const StyledSelect = styled(Select)`
  border-radius: 4px;
  background-color ${white};
  width: 100%;

  .ant-select-selector {
    background-color: rgb(255, 255, 255) !important;
    border-radius: 4px !important;
    color: ${primaryGrey} !important;
    height: 32px !important;
    padding: 0px 13px 0 8px !important;
    z-index: 1;

    .ant-select-selection-item {
      line-height: 30px;
    }
  }

  .ant-select-arrow {
    color: ${primaryBlue};
    font-size: 14px;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    width: 14px;
    height: 14px;
    z-index: 5;
  }

  &.ant-select-open {
    color: ${primaryGreyFifty};
  }
`;

const StyledSVGIcon = styled(SVGIcon)`
  font-size: 14px;
  margin-right: 5px;
`;

const StyledH4 = styled('h4')`
  color: ${black};
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const StyledContainer = styled('div')`
  margin-bottom: 16px;
`;

const Modes = ({ modeActive, modesOptions, onChangeModes }: ModesProps) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const handleSelectClick = () => setOpenDropDown((prevState) => !prevState);
  return (
    <StyledContainer>
      <StyledH4>
        <FormattedMessage {...messages.mode} />
      </StyledH4>
      <StyledSelect
        open={openDropDown}
        value={modeActive}
        onChange={onChangeModes}
        onDropdownVisibleChange={handleSelectClick}
        suffixIcon={
          <span onClick={handleSelectClick} style={{ cursor: 'pointer' }}>
            {openDropDown ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </span>
        }
        dropdownClassName="filters-modes-dropdown"
        listHeight={300}
      >
        {modesOptions.map(({ value, label }) => (
          <Select.Option key={value} value={value}>
            <span className="d-flex align-items-center">
              <StyledSVGIcon name={value} />
              {label}
            </span>
          </Select.Option>
        ))}
      </StyledSelect>
    </StyledContainer>
  );
};

export default Modes;
