import moment from 'moment-timezone';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import CalendarRange from '../../common/calendarRange/CalendarRange';

const getOptionalQuickViewTileDateRangeSelection = (propsChildren) => {
  let option = null;
  if (propsChildren && propsChildren.length) {
    propsChildren.forEach((child) => {
      if (child.props.id === 'Delivery Date') {
        option = child;
        return;
      }
    });
  }
  return option;
};

const DeliveryFilter = (props) => (
  <>
    {getOptionalQuickViewTileDateRangeSelection(props.children)}
    <Col>
      <FormattedMessage
        id="shipmentList.deliveryFilter.deliveryDateRangeWithTimezone"
        defaultMessage="DELIVERY DATE RANGE ({timezone})"
        tagName="label"
        values={{
          timezone: moment.tz(moment.tz.guess()).zoneAbbr(),
        }}
      />
      <CalendarRange
        datepickerStartChangeFn={(date) => {
          props.handleDatePickerChange(date, 'deliveryDateStart');
        }}
        datepickerEndChangeFn={(date) => props.handleDatePickerChange(date, 'deliveryDateEnd')}
        boundStartDateValue={props.filter.deliveryDateStart}
        boundEndDateValue={props.filter.deliveryDateEnd}
        showTime
        data-locator="delivery-filter-calendar-range-picker"
        id="delivery-filter"
      />
    </Col>
  </>
);

export default DeliveryFilter;
