export const APPLY_FILTER_FIELDS = 'shipment/filter/APPLY_FILTER_FIELDS';
export const UPDATE_SUGGESTIONS = 'shipment/filter/UPDATE_SUGGESTIONS';
export const CLEAR_FILTER = 'shipment/filter/CLEAR_FILTER';
export const CLEAR_FILTER_NO_SEARCH = 'shipment/filter/CLEAR_FILTER_NO_SEARCH';
export const SET_LOCATION_SUGGESTIONS = 'shipment/filter/SET_LOCATION_SUGGESTIONS';
export const SET_CARRIER_SUGGESTIONS = 'shipment/filter/SET_CARRIER_SUGGESTIONS';
export const SET_COMPANY_SUGGESTIONS = 'shipment/filter/SET_COMPANY_SUGGESTIONS';
export const SET_CREATEDBY_SUGGESTIONS = 'shipment/filter/SET_CREATEDBY_SUGGESTIONS';
export const SET_CHECKBOX_ITEMS_GROUP = 'shipment/filter/SET_CHECKBOX_ITEMS_GROUP';
export const SET_CHECKBOX_ITEMS_SINGLE = 'shipment/filter/SET_CHECKBOX_ITEMS_SINGLE';
export const SET_AUTOCOMPLETE_ITEMS = 'shipment/filter/SET_AUTOCOMPLETE_ITEMS';
export const SET_STATUS_ITEMS = 'shipment/filter/SET_STATUS_ITEMS';
export const REMOVE_STATUS_ITEM = 'shipment/filter/REMOVE_STATUS_ITEM';
export const TOGGLE_SHOW_SELECTIONS = 'shipment/filter/TOGGLE_SHOW_SELECTIONS';
export const SET_DATE = 'shipment/filter/SET_DATE';
export const SET_FILTER_INPUT = 'shipment/filter/SET_FILTER_INPUT';
export const SET_SAVED_FILTER = 'shipment/filter/SET_SAVED_FILTER';
export const SET_MODE_FILTER = 'shipment/filter/SET_MODE_FILTER';
export const TOGGLE_MODE_FILTER_OPTIONS = 'shipment/filter/TOGGLE_MODE_FILTER_OPTIONS';
export const SET_FILTER_CARRIER_MODE = 'carrieFleet/filter/SET_FILTER_CARRIER_MODE';
export const TOGGLE_MODE_FILTER_OPTIONS_SELECT_ONE = 'shipment/filter/TOGGLE_MODE_FILTER_OPTIONS_SELECT_ONE';
export const GET_TENANT_ATTRIBUTES = 'shipment/filter/TENANT_ATTRIBUTE';
export const GET_TENANT_ATTRIBUTES_SUCCESS = 'shipment/filter/TENANT_ATTRIBUTES_SUCCESS';
export const SET_ATTRIBUTE_SUGGESTIONS = 'shipment/filter/SET_ATTRIBUTE_SUGGESTIONS';
export const SET_CUSTOMER_SUGGESTIONS = 'shipment/filter/SET_CUSTOMER_SUGGESTIONS';
export const SET_PORT_OF_LOADING_SUGGESTIONS = 'shipment/filter/SET_PORT_OF_LOADING_SUGGESTIONS';
export const SET_TRANSSHIPMENT_PORT_SUGGESTIONS = 'shipment/filter/SET_TRANSSHIPMENT_PORT_SUGGESTIONS';
export const SET_PORT_OF_DISCHARGE_SUGGESTIONS = 'shipment/filter/SET_PORT_OF_DISCHARGE_SUGGESTIONS';
export const TOGGLE_LOAD_FILTERS = 'shipment/filter/TOGGLE_LOAD_FILTERS';
export const SHIPMENT_SEARCH_SUGGESTIONS_PREFIX = 'shipment/search';
export const LOCATION_SEARCH_SUGGESTIONS_PREFIX = 'location/v2/adapter/locations/search';

export const SUGGESTIONS_MAP = {
  location: 'locations',
  carrier: 'carriers',
  company: 'companies',
  customers: 'customers',
  createdBy: 'createdBy',
  attribute: 'attributes',
  locode: 'locodes',
  country: 'location/country',
  contacts: 'contacts',
};

export const SUGGESTION_TYPE_TO_ACTION_MAP = {
  location: SET_LOCATION_SUGGESTIONS,
  carrier: SET_CARRIER_SUGGESTIONS,
  company: SET_COMPANY_SUGGESTIONS,
  customers: SET_CUSTOMER_SUGGESTIONS,
  createdBy: SET_CREATEDBY_SUGGESTIONS,
  attribute: SET_ATTRIBUTE_SUGGESTIONS,
  portOfLoading: SET_PORT_OF_LOADING_SUGGESTIONS,
  transshipmentPort: SET_TRANSSHIPMENT_PORT_SUGGESTIONS,
  portOfDischarge: SET_PORT_OF_DISCHARGE_SUGGESTIONS,
};

export default {
  APPLY_FILTER_FIELDS,
  UPDATE_SUGGESTIONS,
  CLEAR_FILTER,
  CLEAR_FILTER_NO_SEARCH,
  SET_LOCATION_SUGGESTIONS,
  SET_CARRIER_SUGGESTIONS,
  SET_COMPANY_SUGGESTIONS,
  SET_CUSTOMER_SUGGESTIONS,
  SET_CREATEDBY_SUGGESTIONS,
  SET_CHECKBOX_ITEMS_GROUP,
  SET_CHECKBOX_ITEMS_SINGLE,
  SET_AUTOCOMPLETE_ITEMS,
  SET_PORT_OF_LOADING_SUGGESTIONS,
  SET_TRANSSHIPMENT_PORT_SUGGESTIONS,
  SET_PORT_OF_DISCHARGE_SUGGESTIONS,
  SET_STATUS_ITEMS,
  REMOVE_STATUS_ITEM,
  TOGGLE_SHOW_SELECTIONS,
  SET_DATE,
  SET_SAVED_FILTER,
  SET_FILTER_INPUT,
  SUGGESTIONS_MAP,
  TOGGLE_MODE_FILTER_OPTIONS,
  TOGGLE_MODE_FILTER_OPTIONS_SELECT_ONE,
  SET_MODE_FILTER,
  GET_TENANT_ATTRIBUTES,
  GET_TENANT_ATTRIBUTES_SUCCESS,
  SET_ATTRIBUTE_SUGGESTIONS,
  SET_FILTER_CARRIER_MODE,
  TOGGLE_LOAD_FILTERS,
};
