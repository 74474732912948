import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Close } from 'ui-components';

import ShareBadge from 'components/common/shareBadge/ShareBadge';
import endpoints, { replaceTokens } from '../../../../common/endpoints';
import { ShipmentETAStatusColor, ShipmentETAStatusEnum } from '../../../../models';
import { white } from '../../../../styles/colors';
import * as styles from './MapPopup.module.scss';

const StatusBlock = styled.div<{ status: ShipmentETAStatusEnum }>`
  align-self: flex-start;
  background-color: ${(props) => ShipmentETAStatusColor[props.status]};
  color: ${white};
  font-size: 12px;
  line-height: 12px;
  margin: 5px 0;
  padding: 5px;
  text-transform: uppercase;
`;

export interface MapPopupShipmentData {
  shipmentId: number | string;
  shipmentMode: string;
  identifierType: string;
  identifierValue: string;
  status: ShipmentETAStatusEnum;
  originName?: string;
  originLocation?: string;
  destinationName?: string;
  destinationLocation?: string;
  lastUpdated?: string;
  entitlementInfo?: string; // JSON representation of EntitlementInfo can't nest objects with mapbox properties
}

const formatShipmentLink = (shipmentId: string | number, shipmentMode: string): string => {
  if (shipmentMode === 'INTERMODAL') {
    return `/ma/tracking-details?masterShipmentId=${shipmentId}&shipmentMode=intermodal`;
  }
  return replaceTokens(endpoints.TRACKING_DETAILS, {
    shipmentMode,
    shipmentDetailId: shipmentId,
  });
};

export interface MapPopupProps {
  shipmentData: MapPopupShipmentData;
  onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const MapPopup: React.FC<MapPopupProps> = (props) => {
  return (
    <div data-locator="map-popup" className={styles.mapPopupWrapper}>
      <div className={styles.mapPopupInner}>
        <div className={styles.header}>
          <FormattedMessage id={`shipmentDetails.identifiers.${props.shipmentData.identifierType}`}>
            {(...text) => (
              <span style={{ textTransform: 'uppercase' }}>
                {text}
                <span style={{ fontWeight: 'bold' }}>&nbsp;#{props.shipmentData.identifierValue}</span>
              </span>
            )}
          </FormattedMessage>
          <button className={styles.closeButton} onClick={props.onClose}>
            <Close />
          </button>
        </div>
        <FormattedMessage id={`shipmentDetails.status.${props.shipmentData.status}`}>
          {(...text) => <StatusBlock status={props.shipmentData.status}>{text}</StatusBlock>}
        </FormattedMessage>
        {(props.shipmentData.originName !== undefined || props.shipmentData.originLocation !== undefined) && (
          <div className={styles.stop}>
            <div className={styles.originIcon} />
            <div className={styles.stopText}>
              {props.shipmentData.originName !== undefined && (
                <span className={styles.stopName}>{props.shipmentData.originName}</span>
              )}
              {props.shipmentData.originLocation !== undefined && <span>{props.shipmentData.originLocation}</span>}
            </div>
          </div>
        )}
        {(props.shipmentData.destinationName !== undefined || props.shipmentData.destinationLocation !== undefined) && (
          <div className={styles.stop}>
            <div className={styles.destinationIcon} />
            <div className={styles.stopText}>
              {props.shipmentData.destinationName !== undefined && (
                <span className={styles.stopName}>{props.shipmentData.destinationName}</span>
              )}
              {props.shipmentData.destinationLocation !== undefined && (
                <span>{props.shipmentData.destinationLocation}</span>
              )}
            </div>
          </div>
        )}
        <div />
        <Link to={formatShipmentLink(props.shipmentData.shipmentId, props.shipmentData.shipmentMode)}>
          <Button type="primary" className={styles.viewButton}>
            <FormattedMessage id="landing.map.popup.view" defaultMessage="View" />
          </Button>
        </Link>
        {props.shipmentData.lastUpdated !== undefined && (
          <FormattedMessage
            id="landing.map.popup.lastUpdated"
            defaultMessage="Updated {dateTime}"
            values={{ dateTime: moment(props.shipmentData.lastUpdated).fromNow() }}
          >
            {(...text) => <span className={styles.updatedText}>{text}</span>}
          </FormattedMessage>
        )}
      </div>
      <ShareBadge
        showMultipleEntitlees={false}
        entitlementInfo={
          props.shipmentData.entitlementInfo ? JSON.parse(props.shipmentData.entitlementInfo) : undefined
        }
        className={styles.shareBadge}
      />
    </div>
  );
};

export default MapPopup;
