import { defineMessages } from 'react-intl';
import { HOURS_MINUTES_FORMAT, MONTH_DAY_YEAR_FORMAT } from 'i18n/configurei18n';
export const routeDateKeys = [
  'localRecordedArrivalSplitDateTime',
  'localRecordedDepartureSplitDateTime',
  'localTimestamp',
];

const messages = defineMessages({
  pickup: { id: 'shipment.pickup', defaultMessage: 'Pickup' },
  terminal: { id: 'shipment.terminal', defaultMessage: 'Terminal' },
  delivery: { id: 'shipment.delivery', defaultMessage: 'Delivery' },
  appointmentWindow: { id: 'shipment.appointmentWindow', defaultMessage: 'Appointment Window' },
  appointmentWindowRange: {
    id: 'shipment.appointmentWindowRange',
    defaultMessage: '{DATE_START} at {TIME_START} - {DATE_END} at {TIME_END}',
  },
});

export const routeDetailsObjectTypes = {
  CONTACT: {
    companyName: undefined,
    contactName: undefined,
    phoneNumber: undefined,
    email: undefined,
  },
  INFO: {
    label: undefined,
    tertiary: undefined,
    dateFormat: undefined,
    description: undefined,
    color: undefined,
    type: undefined,
  },
  TYPES: {
    INFO: 'INFO',
    NEW_DAY: 'NEW_DAY',
    EXCEPTION: 'EXCEPTION',
    STOP: 'STOP',
    UPDATE: 'UPDATE',
  },
  CREATED_DATE: 'Shipment created {DATE} at {TIME} {TIMEZONE}',
  STOPS: {
    AT_STOP: {
      DEPARTED: 'Departed {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
      ARRIVED: 'Arrived {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
      DELIVERED: 'Delivered {DATE_MONTH_YEAR} at {DATE_TIME} {DATE_TIMEZONE}',
      UNDEFINED: undefined,
      LABELS: {
        PICKUP: messages.pickup,
        TERMINAL: messages.terminal,
        DESTINATION: messages.delivery,
      },
    },
    GROUP_UPDATES: '{UPDATE_COUNT} location updates',
    APPOINTMENT_WINDOW_LABEL: messages.appointmentWindow,
    APPOINTMENT_WINDOW_RANGE: '{DATE_START} at {TIME_START} - {DATE_END} at {TIME_END}',
  },
};

export const routeDetailTypes = {
  UPDATE: {
    INFO: {
      ...routeDetailsObjectTypes.INFO,
      dateFormat: HOURS_MINUTES_FORMAT,
      type: routeDetailsObjectTypes.TYPES.INFO,
    },
    NEW_DAY: {
      ...routeDetailsObjectTypes.INFO,
      dateFormat: MONTH_DAY_YEAR_FORMAT,
      type: routeDetailsObjectTypes.TYPES.NEW_DAY,
    },
    EXCEPTION: {
      ...routeDetailsObjectTypes.INFO,
      dateFormat: HOURS_MINUTES_FORMAT,
      color: 'new-fruit',
      type: routeDetailsObjectTypes.TYPES.EXCEPTION,
    },
  },
  STOP: {
    ORIGIN: {
      stopType: 'ORIGIN',
      info: {
        stopLabel: routeDetailsObjectTypes.STOPS.AT_STOP.LABELS.PICKUP,
        appointmentLabel: routeDetailsObjectTypes.STOPS.APPOINTMENT_WINDOW_LABEL,
        appointmentWindow: routeDetailsObjectTypes.STOPS.AT_STOP.DEPARTED,
        appointmentRange: routeDetailsObjectTypes.STOPS.APPOINTMENT_WINDOW_RANGE,
        contact: routeDetailsObjectTypes.CONTACT,
      },
    },
    TERMINAL: {
      stopType: 'TERMINAL',
      info: {
        stopLabel: routeDetailsObjectTypes.STOPS.AT_STOP.LABELS.TERMINAL,
        appointmentLabel: routeDetailsObjectTypes.STOPS.APPOINTMENT_WINDOW_LABEL,
        appointmentWindow: routeDetailsObjectTypes.STOPS.AT_STOP.DEPARTED,
        appointmentRange: routeDetailsObjectTypes.STOPS.APPOINTMENT_WINDOW_RANGE,
        contact: routeDetailsObjectTypes.CONTACT,
      },
    },
    DESTINATION: {
      stopType: 'DESTINATION',
      info: {
        stopLabel: routeDetailsObjectTypes.STOPS.AT_STOP.LABELS.DESTINATION,
        appointmentLabel: routeDetailsObjectTypes.STOPS.APPOINTMENT_WINDOW_LABEL,
        appointmentWindow: routeDetailsObjectTypes.STOPS.AT_STOP.DEPARTED,
        appointmentRange: routeDetailsObjectTypes.STOPS.APPOINTMENT_WINDOW_RANGE,
        contact: routeDetailsObjectTypes.CONTACT,
      },
    },
  },
};
