import get from 'lodash/get';
import { LOCAL_PATH, MICRO_WEBAPP_BASE_URL, MOVEMENT_BASE_UI_URL } from './AppConstants';

export const endpoints = {
  BASE_PATH: '/portal/v2',
  LOGIN: '/login',
  REPORT: '/report',
  RATING: '/portal/vltl-ltl-rates',
  ANALYTICS: '/analytics/:analyticsView?id=:locationId',
  LOGOUT: '/logout',
  LANDING: '/landing',
  CONFIGURE_QUICKVIEW: '/configure-quickview/:step/:tileId?',
  DASHBOARD: '/dashboard',
  SHIPMENT: '/shipment',
  CURRENT_VERSION: '/current-version',
  NOTES: '/shipment/notes',
  GET_NOTES: '/shipment/notes',
  GET_DOCUMENTS: '/shipment/documents',
  PUBLIC_NOTES: '/shipment/share/public/:shareToken/notes',
  PUBLIC_INTERMODAL_NOTES: '/shipment/share/public/:shareToken/intermodal/notes',
  SHIPMENT_LIST: '/shipment-list',
  TENANT_ATTRIBUTES: '/tenant-attributes',
  SHIPMENT_TENANT_ATTRIBUTES: '/tenant-attributes/shipment/id/:shipmentId',
  SHIPMENT_TENANT_ATTRIBUTES_CREATE_EDIT:
    '/tenant-attributes/shipment/id/:shipmentId?masterShipmentId=:masterShipmentId',
  SHIPMENT_TENANT_ATTRIBUTES_MSID: '/shipments/:masterShipmentId/attributes',
  PUBLIC_TRACKING_DETAILS_LEGACY: '/public/shipment-details/:shipmentShareToken',
  PUBLIC_TRACKING_DETAILS: '/public/shipment-details/:shipmentMode/:shipmentShareToken',
  TRACKING_DETAILS: '/tracking-details/:shipmentMode/:shipmentDetailId',
  TRACKING_DETAILS_MASTER_SHIPMENT_ID: '/ma/tracking-details?masterShipmentId=:masterShipmentId',
  MA_TRACKING_DETAILS: '/ma/tracking-details',
  PUBLIC_MA_TRACKING_DETAILS: '/public/ma/tracking-details',
  PUBLIC_MA_TRACKING_DETAILS_WITH_TOKEN: '/public/ma/tracking-details?shipmentShareToken=:shipmentShareToken',
  LOCATION_DETAILS: '/location-details/:locationDetailId',
  LOCATION_LIST: '/location-list',
  LOCATIONS: LOCAL_PATH + '/portal/address-book',
  USERS: MICRO_WEBAPP_BASE_URL + '/standalone-user-management',
  NOTIFICATIONS: '/notification-rules',
  NOTIFICATION_DETAILS: '/notifications/:notificationId',
  CARRIERS: LOCAL_PATH + '/portal/capacity-provider-accounts',
  MY_PROFILE: LOCAL_PATH + '/portal/my-profile',
  API_DOCS: LOCAL_PATH + '/docs/apidocs.html',
  REFERENCE_DATA: LOCAL_PATH + '/docs/reference-data',
  SDKS: LOCAL_PATH + '/portal/sdks',
  LEGACY_UI: LOCAL_PATH + '/user/account',
  CUSTOMER_MANAGEMENT: MICRO_WEBAPP_BASE_URL + '/tenant-management',
  SUPPORTED_VENDORS: LOCAL_PATH + '/portal/supported-vendors',
  SUPPORT_PORTAL: 'https://support.p-44.com/',
  ANALYTICS_SCORECARD: '/analytics/truckload/carrier-scorecard',
  ANALYTICS_CARRIER_OVERVIEW: '/analytics/truckload/carrier-overview',
  ANALYTICS_LOCATION_ANALYTICS: '/analytics/truckload/location-analytics',
  ANALYTICS_LOCATION_DETAILS_ANALYTICS: '/analytics/truckload/location-details-analytics',
  ANALYTICS_CARRIER_ONBOARDING_REPORT: '/analytics/truckload/carrier-onboarding-report',
  ANALYTICS_OCEAN_OVERVIEW: '/analytics/ocean/overview',
  ANALYTICS_OCEAN_PORT_CONGESTION: '/analytics/ocean/port-congestion',
  ANALYTICS_OCEAN_INSIGHTS: '/analytics/ocean/ocean-insights',
  ANALYTICS_CARRIER_PROCUREMENT: '/analytics/truckload/carrier-procurement',
  MISSING_SHIPMENT: '/missing-shipment',
  SETTINGS: '/settings',
  ORDERSLIST: '/inventory/orders',
  ORDERS_SEARCH: '/inventory/orders/search',
  PORTINTEL: `${MICRO_WEBAPP_BASE_URL}/analytics/port-intel`,
  TL_TRACKING: `${MICRO_WEBAPP_BASE_URL}/network/root-cause-analysis`,
  OCEAN_SUSTAINABILITY: `${MICRO_WEBAPP_BASE_URL}/analytics/emission-monitoring`,
  NETWORK_HEALTH: `${MICRO_WEBAPP_BASE_URL}/network/health`,

  GET_RELATED_ORDERS: (id) => `/inventory/orders/${id}/related-orders`,
  ORDERS_DETAILS: '/inventory/orders/:orderId',
  INVENTORY_ITEM_DETAILS: '/inventory/items/:inventoryItemId',
  INVENTORYITEMSLIST: '/inventory/items',
  INVENTORY_ITEMS_SEARCH: '/inventory/items/search',
  public: {
    CARRIER_INVITE_REGISTRATION: '/public/carrier-invite-registration/:inviteId',
    CARRIER_INVITE_REGISTRATION_V2: '/public/carrier-invite-registration/:inviteId/:stepId?',
    CARRIER_REGISTRATION_V2: '/public/carrier-registration/:stepId?',
    CARRIER_REGISTRATION_OAUTH2_PAGE: '/public/oauth2-authorized',
    CARRIER_EVERGREEN_REGISTRATION: '/public/connect/:evergreenAlias/:stepId?',
    CARRIER_SHIPMENT_LIST: '/public/shipment-list/:carrierListShareToken',
  },
  SEARCH_EXPORT: (queryParams) => `/shipment/search/export?${queryParams}`,
  freshdesk: {
    publicCreateTicket: '/public/freshdesk/ticket',
    createTicket: '/freshdesk/ticket',
  },
  carrierSearch: '/carriers/lookup',
  FIND_UNMATCHED_SEARCH_TERM: '/shipment/search/findUnmatchedSearchTerm',
  ORDERS_FIND_UNMATCHED_SEARCH_TERM: '/inventory/orders/search/findUnmatchedOrders',
  ELECTRONIC_LOGGING_DEVICES: '/electronic-logging-devices',
  ELECTRONIC_CONNECTION_CONFIGURATION: '/electronic-connection-configuration',
  TL_RATING: get(window, 'portalGlobal.tlRatingUrl', ''),
  TENANT_ATTRIBUTES_KEYS: '/tenant-attributes/keys',
  TENANT_ATTRIBUTES_VALUES: '/tenant-attributes/:attributeId/values',
  ENTITLEMENT_RULES: '/entitlement-rule',
  CARRIER_SEARCH_LIST: '/inventory/carrierFleet',
  FLEET_LIST: '/inventory/fleetList',
  OCEAN_INSIGHTS_CTT: get(window, 'portalGlobal.oceanInsightsCttUrl', ''),
  OCEAN_INSIGHTS_CSS: get(window, 'portalGlobal.oceanInsightsCssUrl', ''),
  LAST_MILE_APP: get(window, 'portalGlobal.lastMileAppUrl', ''),
  CLEAR_METAL_APP: get(window, 'portalGlobal.clearMetalAppUrl', ''),
  MINDLOGIC_APP: get(window, 'portalGlobal.mindlogicAppUrl', ''),
  MINDLOGIC_STARBUCKS_APP: get(window, 'portalGlobal.mindlogicStarbucksAppUrl', ''),
  MOVEMENT_PREVIEW_URL: `${MOVEMENT_BASE_UI_URL}/results/dashboard`,
  TRUCKLOAD_ANALYTICS: `${MOVEMENT_BASE_UI_URL}/analytics/truckload`,
};

export const shipmentShareTokenQueryParam = 'shipmentShareToken';

export const noHeaderOrFooterEndpoints = [
  endpoints.LOGIN,
  endpoints.CREATE_NEW_PASSWORD,
  endpoints.FORGOT_PASSWORD,
  ...Object.values(endpoints.public),
];

export const getEndpoints = () => ({ ...endpoints });

export function replaceTokens(endpoint, values) {
  const toReplace = endpoint.match(/:\w+/g);

  const updatedEndpoint = toReplace.reduce((acc, param) => {
    return `${acc.replace(param, values[param.split(':')[1]])}`;
  }, endpoint);

  return updatedEndpoint;
}

export default endpoints;
