import isEmpty from 'lodash/isEmpty';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import get from 'lodash/get';
import * as actions from './actions';
import types from './types';

export function* fetchConfigAsync() {
  try {
    const state = yield select();
    if (isEmpty(state.configReducer.configReducer)) {
      const config = {
        hereMapsApiKey: `${get(window, 'portalGlobal.heremapsApiKey')}`,
        hereMapsCode: `${get(window, 'portalGlobal.heremapsAppCode')}`,
        hereMapsId: `${get(window, 'portalGlobal.heremapsAppID')}`,
      };
      if (config != null) {
        yield put(actions.configSuccess(config));
      }
    }
  } catch (error) {
    yield put(actions.configFailure(error));
  }
}

export function* watchConfigAsync() {
  yield takeEvery(types.CONFIG_GET, fetchConfigAsync);
}

export default function* configOperations() {
  yield fork(watchConfigAsync);
}
