import { PrincipalAuthorizations } from 'common/authorizations';
import { SEARCH_RESULTS_PER_PAGE } from '../../../../common/AppConstants';
const authorizations = new PrincipalAuthorizations();
const hasLocationsV2Enabled = authorizations.hasLocationsV2EnabledAuthorization();
const namespace = (actionType) => `search/${actionType}`;
const RESET_SEARCH_QUERY_TYPE = namespace('RESET_SEARCH_QUERY');
const SET_SEARCH_TERM_TYPE = namespace('SET_SEARCH_TERM');
const SET_CONTACT_SEARCH_TERM_TYPE = namespace('SET_SEARCH_TERM');
const SEARCH_TYPE = namespace('SEARCH_RESULTS');
const SET_TYPE = namespace('SET_SEARCHED');
const ADD_TYPE = namespace('ADD_SEARCHED');
const SEARCH_FAILURE_TYPE = namespace('SEARCH_FAILURE');
const GET_NEXT_PAGE = namespace('GET_NEXT_PAGE');
const DEFAULT_SEARCH_QUERY = {
  search: '',
  filters: [],
  sortBy: [],
  resultStartIndex: 0,
  resultsPerPage: SEARCH_RESULTS_PER_PAGE,
};
// sets search configuration for each feature

let locationsPath = `location/v2/adapter/locations`;
if (hasLocationsV2Enabled) {
  locationsPath = `analytics/locations`;
}
const SEARCH_LOCATION_CONFIG = {
  name: 'Locations',
  path: locationsPath,
};

export default {
  RESET_SEARCH_QUERY_TYPE,
  SET_SEARCH_TERM_TYPE,
  SET_CONTACT_SEARCH_TERM_TYPE,
  SEARCH_TYPE,
  SET_TYPE,
  ADD_TYPE,
  SEARCH_FAILURE_TYPE,
  GET_NEXT_PAGE,
  DEFAULT_SEARCH_QUERY,
  SEARCH_LOCATION_CONFIG,
};
