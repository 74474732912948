import flow from 'lodash/flow';
import partial from 'lodash/partial';
import merge from 'lodash/merge';
import filter from 'lodash/filter';
import last from 'lodash/last';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';

import {
  concatMappedUpdates,
  sortRoute,
  removeInvalidStops,
  mapGroupedUpdates,
  dedupGroupUpdates,
  createUpdateNewDay,
  createUpdateInfo,
  mapStopUpdates,
  createStopInfo,
  displayNewDay,
  formatStatusCodes,
  mapStatusValues,
} from '../../../../common/utils/routeMappingUtils';
import { statusOptions } from './enums';
import { routeDetailTypes, routeDetailsObjectTypes } from '../../../../common/enums/routeMappingEnums';

/**
 * Creates route details array by merging shipment stop data and mapping new values for UI display
 * @param allStatusUpdates - array of all status updates
 * @param shipmentStops - array of all shipment stops
 * @param latestStopStatuses - array of shipment stops with additional data to be merged
 * @returns [array] formatted route array
 */
export const prepareRouteDetails = (allStatusUpdates, shipmentStops, latestStopStatuses) => {
  let updatedRoute = [];
  const mergedShipmentStops = merge(shipmentStops, latestStopStatuses); // merge stops arrays to get utcRecordedDepartureDateTime
  let updatedStops = mergedShipmentStops.map((stop) => ({ ...stop, type: 'STOP' })); // define type as "STOP"

  return flow(
    partial(mapDerivedStatus, allStatusUpdates, shipmentStops),
    partial(concatMappedUpdates, updatedStops), // concat mapped arrays together
    partial(mapStatusValues),
    partial(sortRoute),
    partial(removeInvalidStops, false),
    partial(mapRouteDetails),
    partial(mapGroupedUpdates),
    partial(dedupGroupUpdates)
  )(updatedRoute);
};

/**
 * Updates object structure for updates to set a derived status
 * @param allStatusUpdates - array of all un-formatted status updates
 * @param shipmentStops - array of all the stops
 * @param updatedRoute - array of all the formatted updates
 * @returns [array] updated route array derived statuses
 */
export const mapDerivedStatus = (allStatusUpdates, shipmentStops, updatedRoute) => {
  if (allStatusUpdates) {
    // shipments with no tracking may not have status updates
    last(allStatusUpdates).latestStatusUpdate = true;
    allStatusUpdates.forEach((status) => {
      // map to derived status and define type as "update"
      updatedRoute.push({
        ...status,
        type: 'UPDATE',
        derivedStatus: setDerivedStatus(status.statusCode, status.statusReasonCode, status.stopNumber, shipmentStops),
      });
    });
    return updatedRoute;
  } else {
    return allStatusUpdates;
  }
};

/**
 * Updates formatted array of updates/stops by adding new days and forming stop and update objects
 * @param updatedRoute - array of all status and route objects
 * @returns [array] updated route array
 */
export const mapRouteDetails = (updatedRoute) => {
  if (isEmpty(updatedRoute)) {
    return updatedRoute;
  }

  let returnRoute = [];
  let lastNewDay = createUpdateNewDay(updatedRoute[0], routeDetailTypes.UPDATE.NEW_DAY).date;
  let startStopUpdates = updatedRoute.length;
  let endStopUpdates = updatedRoute.length;
  let destinationStopInfo, originStopInfo;
  const deliveredUpdate = updatedRoute.find((update) => update.derivedStatus === statusOptions.DISPLAY.DELIVERED);
  forEach(updatedRoute, (update, index) => {
    if (index < startStopUpdates || index > endStopUpdates) {
      // check if next update exists in case next updates were spliced
      if (
        update.type === routeDetailsObjectTypes.TYPES.UPDATE &&
        (update.type !== routeDetailsObjectTypes.TYPES.STOP ||
          update.stopType === routeDetailTypes.STOP.TERMINAL.stopType)
      ) {
        // if the type is an update
        if (update.derivedStatus !== statusOptions.DISPLAY.DELIVERED) {
          returnRoute.push(createUpdateInfo(update, routeDetailTypes.UPDATE.INFO));
        }
      } else if (update.type === routeDetailsObjectTypes.TYPES.STOP) {
        // if the type is a stop
        // if there is an update between associated stop updates, include it in the stop updates
        const startStopUpdates = index;
        const endStopUpdates = index;
        let stopUpdates = [];
        if (startStopUpdates > -1 && endStopUpdates > -1) {
          // slice the updates into the stop, add 1 to account for 0 index
          stopUpdates = updatedRoute.slice(startStopUpdates, endStopUpdates + 1);
        }
        if (update.stopType === routeDetailTypes.STOP.DESTINATION.stopType && deliveredUpdate) {
          stopUpdates.push(deliveredUpdate);
        }

        stopUpdates = mapStopUpdates(stopUpdates, lastNewDay);

        if (update.stopType === routeDetailTypes.STOP.ORIGIN.stopType) {
          originStopInfo = createStopInfo(update, routeDetailTypes.UPDATE.INFO, stopUpdates);
        } else if (update.stopType === routeDetailTypes.STOP.DESTINATION.stopType) {
          destinationStopInfo = createStopInfo(update, routeDetailTypes.UPDATE.INFO, stopUpdates);
        } else if (update.stopType !== routeDetailTypes.STOP.TERMINAL.stopType) {
          returnRoute.push(createStopInfo(update, routeDetailTypes.UPDATE.INFO, stopUpdates));
        }
      }
      if (updatedRoute[index + 1]) {
        if (displayNewDay(last(returnRoute), update, updatedRoute[index + 1], lastNewDay)) {
          returnRoute.push(createUpdateNewDay(updatedRoute[index + 1], routeDetailTypes.UPDATE.NEW_DAY));
        }
      }
    }
  });
  if (originStopInfo) {
    returnRoute = [originStopInfo].concat(returnRoute);
  }
  if (destinationStopInfo) {
    returnRoute.push(destinationStopInfo);
  }
  return returnRoute;
};

/**
 * Creates array of objects that contain arrays of updates for the destination stop
 * @param updatedRoute - array of all status and route objects
 * @param index - index of the returned route
 * @param stopUpdates - updates associated with the destination stop
 * @param returnRoute - route being mapped over
 * @param lastNewDay - date string of last new day line
 * @returns [array] updated route array
 */
export const mapDestinationStopUpdates = (updatedRoute, index, stopUpdates, returnRoute, lastNewDay) => {
  let newStopUpdates = [...stopUpdates];
  let newReturnRoute = [...returnRoute];
  // remove any updates after destination and put them into the stop updates array
  if (updatedRoute[index + 1]) {
    let associatedUpdates = updatedRoute.slice(index + 1);
    newStopUpdates = newStopUpdates.concat(associatedUpdates);
  }
  // map the these updates before the following code block since they have already been mapped
  newStopUpdates = mapStopUpdates(newStopUpdates, lastNewDay);
  // remove any updates that were sliced into stopUpdates
  newReturnRoute = filter(newReturnRoute, (stop) => !some(newStopUpdates, { id: stop.id }));
  // for scenarios where delivered statuses are not assigned to a stop type and appear before the delivery stop,
  // remove from returnRoute and append to stop updates array
  if (some(newReturnRoute, { derivedStatus: statusOptions.DISPLAY.DELIVERED_WITH_EXCEPTIONS })) {
    newReturnRoute = newReturnRoute.filter((returnUpdate) => {
      if (returnUpdate.derivedStatus === statusOptions.DISPLAY.DELIVERED_WITH_EXCEPTIONS) {
        newStopUpdates = newStopUpdates.concat(returnUpdate);
        return false;
      } else {
        return true;
      }
    });
  }
  if (some(newReturnRoute, { derivedStatus: statusOptions.DISPLAY.DELIVERED })) {
    newReturnRoute = newReturnRoute.filter((returnUpdate) => {
      if (returnUpdate.derivedStatus === statusOptions.DISPLAY.DELIVERED) {
        newStopUpdates = newStopUpdates.concat(returnUpdate);
        return false;
      } else {
        return true;
      }
    });
  }
  if (
    some(newStopUpdates, { latestStatusUpdate: true }) &&
    newStopUpdates.length &&
    !last(newStopUpdates).latestStatusUpdate
  ) {
    newStopUpdates.push(newStopUpdates[newStopUpdates.findIndex((route) => route.latestStatusUpdate)]);
    newStopUpdates.splice(
      newStopUpdates.findIndex((route) => route.latestStatusUpdate),
      1
    );
  }
  return { updatedRoute, stopUpdates: newStopUpdates, returnRoute: newReturnRoute };
};

/**
 * Creates string that is the derived status of an update
 * @param statusCode - string containing statusCode from response
 * @param statusReasonCode - string containing statusReasonCode from response
 * @param stopNumber - associated stop number
 * @param shipmentStops - array containing all the shipment stops
 * @returns {string} derived status text
 */
export const setDerivedStatus = (statusCode, statusReasonCode, stopNumber, shipmentStops) => {
  switch (statusCode) {
    case statusOptions.ORIGINAL.SCHEDULED:
      // TODO: determine if within pickup window
      return statusOptions.DISPLAY.SCHEDULED;
    case statusOptions.ORIGINAL.AT_STOP: {
      return formatStatusCodes(statusCode, statusReasonCode);
    }
    case statusOptions.ORIGINAL.IN_TRANSIT: {
      const arrivedTerminal =
        some(shipmentStops, { stopType: statusOptions.STOP_TYPES.TERMINAL }).dateValue === undefined;
      const arrivedDestination =
        some(shipmentStops, { stopType: statusOptions.STOP_TYPES.DESTINATION }).dateValue === undefined;

      if (!arrivedTerminal && !arrivedDestination) {
        return statusOptions.DISPLAY.PICKED_UP;
      } else {
        return statusOptions.DISPLAY.IN_TRANSIT;
      }
    }
    case statusOptions.ORIGINAL.OUT_TO_STOP:
      return statusOptions.DISPLAY.OUT_FOR_DELIVERY;
    case statusOptions.ORIGINAL.INFO:
      if (statusOptions.DISPLAY[statusReasonCode]) {
        return statusOptions.DISPLAY[statusReasonCode];
      } else {
        return formatStatusCodes(statusCode, statusReasonCode);
      }
    case statusOptions.ORIGINAL.COMPLETED:
      if (statusReasonCode === statusOptions.DERIVED.EXCEPTION) {
        return statusOptions.DISPLAY[statusOptions.DERIVED.DELIVERED_WITH_EXCEPTIONS];
      } else {
        return statusOptions.DISPLAY[statusReasonCode];
      }
    default:
      return formatStatusCodes(statusCode, statusReasonCode);
  }
};
