import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import cn from 'classnames';
import FilterUtil from 'components/filter/util/filterUtil';
import StepPageComponent from '../StepPageComponent';
import FilteringCriteriaComponent from '../FilteringCriteriaComponent';
import QuickViewCardTile from '../QuickViewCardTile';
import { useIntersectionObserver } from '../hooks/useIntersectionObeserver';
import {
  QUICKVIEW_SUBTOTAL_COUNT,
  QUICKVIEW_SUBTOTAL_FILTERS,
  QUICKVIEW_SUBTOTAL_MODE,
  QUICKVIEW_SUBTOTAL_SEARCHTERM,
  QuickviewEntityType,
} from '../constants';

import * as styles from './styles.module.scss';
import {
  countInventory,
  countOrders,
  countShipments,
  getEntityTypeFromSearch,
  getEntityTypeMessages,
  onKeyDownBlockEnter,
} from '../utils';
import { useHandleNextStep } from '../hooks';
import { useWizardForm } from '../context/WizardFormContext';

const slideInAnimation = (entry: IntersectionObserverEntry) => {
  const slideInQuickView = document.querySelector('#slide-in-quickview') as HTMLDivElement;
  if (!entry.isIntersecting) {
    slideInQuickView.classList.add(styles['slide-in']);
  } else {
    slideInQuickView.classList.remove(styles['slide-in']);
  }
};

const SubTotalStep = ({
  modeFilterOptions,
  allFilterState,
  searchTerm,
  principal,
  modeFilters,
  toggleLoadFilter,
  clearFilterNoSearch,
  ...props
}: any) => {
  const quickviewCardRef = useRef<HTMLDivElement | null>(null);
  const [count, setCount] = useState<number | null>(null);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const intl = useIntl();
  const location = useLocation();

  const { state: tileToEdit } = useWizardForm();

  // it's not clear what the best way to store this is. we could store it in Redux which would make it compatible
  // with the current handleSubmit() serialization code, but that interferes with EncapsulatedOrderList's's use of
  // passing a function that accesses previous state -- the order list component expects to have a useState setter
  //
  // in order to avoid potentially breaking the app when React bunches up updates and passes in previous state to that
  // setter, we're just passing these as explicit props
  const [orderFilter, setOrderFilter] = useState({});
  const [inventoryFilter, setInventoryFilter] = useState({});

  const entityType = getEntityTypeFromSearch(location.search);

  // TODO as a followup we should refactor orders component to store state in Redux, making this unnecessary
  useEffect(() => {
    if (
      entityType === QuickviewEntityType.ORDERS &&
      isEmpty(orderFilter) &&
      !isEmpty(tileToEdit) &&
      !isEmpty(tileToEdit.orderFilter)
    ) {
      setOrderFilter(tileToEdit.orderFilter);
    }
  }, [entityType, orderFilter, tileToEdit]);

  useEffect(() => {
    if (
      entityType === QuickviewEntityType.INVENTORY &&
      isEmpty(inventoryFilter) &&
      !isEmpty(tileToEdit) &&
      !isEmpty(tileToEdit.inventoryFilter)
    ) {
      setInventoryFilter(tileToEdit.inventoryFilter);
    }
  }, [entityType, inventoryFilter, tileToEdit]);

  useIntersectionObserver(
    quickviewCardRef,
    {
      root: null,
      rootMargin: '-75px 0px 0px 0px',
      threshold: [0.3],
      freezeOnceVisible: false,
    },
    slideInAnimation
  );
  const handleNextStep = useHandleNextStep();
  const subtotalMode = modeFilters[0] !== undefined ? modeFilters[0] : 'ALL';

  const handleSubmit = () => {
    let stringifiedSubtotalFilters: string = '';

    if (entityType === QuickviewEntityType.SHIPMENTS) {
      stringifiedSubtotalFilters = JSON.stringify(allFilterState.filter);
    } else if (entityType === QuickviewEntityType.ORDERS) {
      stringifiedSubtotalFilters = JSON.stringify(orderFilter);
    } else if (entityType === QuickviewEntityType.INVENTORY) {
      stringifiedSubtotalFilters = JSON.stringify(inventoryFilter);
    } else {
      console.error('Error, invalid entity type', entityType);
    }

    window.sessionStorage.setItem(QUICKVIEW_SUBTOTAL_FILTERS, stringifiedSubtotalFilters);
    window.sessionStorage.setItem(QUICKVIEW_SUBTOTAL_SEARCHTERM, searchTerm);
    window.sessionStorage.setItem(QUICKVIEW_SUBTOTAL_COUNT, String(count));
    window.sessionStorage.setItem(QUICKVIEW_SUBTOTAL_MODE, subtotalMode);
    clearFilterNoSearch();
    handleNextStep();
  };

  const initialValues = {
    initialValues: {
      mode: null,
      title: '',
      numberOfResults: intl.formatMessage({
        id: 'quickViewConfig.quickViewCard.count',
        defaultMessage: '# of Results',
      }),
    },
  };

  // Call the search endpoint to populate the count on change
  useEffect(() => {
    if (entityType === QuickviewEntityType.SHIPMENTS) {
      const mode = modeFilters[0] !== undefined ? modeFilters[0] : 'ALL';
      const oceanDemurrageEligibleFilter = get(allFilterState, 'filter.oceanDemurrageEligible');
      const oceanDemurrageEligible = FilterUtil.getOceanDemurrageEligibleFilterValue(oceanDemurrageEligibleFilter);
      const config = {
        searchKeywords: searchTerm,
        filter: { ...FilterUtil.getValidOrBlank(allFilterState?.filter), oceanDemurrageEligible },
        mode: mode,
      };
      if (
        (mode !== undefined && modeFilters.length === 0) ||
        (modeFilters.length > 0 && modeFilters[0] !== undefined)
      ) {
        setIsNextDisabled(false);
        countShipments(config, principal, setCount);
      }
    } else if (entityType === QuickviewEntityType.ORDERS && !isEqual(orderFilter, {})) {
      const config = {
        ...orderFilter,
        // I wish this codebase could pick one of "searchText" or "searchTerm" and stick with it :(
        searchText: searchTerm,
      };
      setIsNextDisabled(false);
      countOrders(config, setCount);
    } else if (entityType === QuickviewEntityType.INVENTORY && !isEqual(inventoryFilter, {})) {
      const config = {
        ...inventoryFilter,
        searchText: searchTerm,
      };
      setIsNextDisabled(false);
      countInventory(config, setCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFilterState.filters, orderFilter, inventoryFilter, searchTerm, modeFilterOptions, principal]);

  // Get subtotal values from session storage and hydrate redux
  useEffect(() => {
    const subtotalFilters = window.sessionStorage.getItem(QUICKVIEW_SUBTOTAL_FILTERS);
    const parsedSubtotalFilters = subtotalFilters && JSON.parse(subtotalFilters);
    const subtotalModeSessionStorage = window.sessionStorage.getItem(QUICKVIEW_SUBTOTAL_MODE);
    const subtotalModeFilters: Array<string> = [];
    subtotalModeSessionStorage && subtotalModeFilters.push(subtotalModeSessionStorage);
    const updatedState = { filter: parsedSubtotalFilters, modeFilters: subtotalModeFilters };
    if (window.sessionStorage.getItem(QUICKVIEW_SUBTOTAL_FILTERS)) {
      toggleLoadFilter(updatedState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entityTypeMessages = getEntityTypeMessages(entityType);

  return (
    <div className="m-6" id="subtotal-container">
      <StepPageComponent
        title={
          <FormattedMessage id="quickViewConfig.subtotalConfiguration.header" defaultMessage="Subtotal Configuration" />
        }
        description={
          <FormattedMessage
            id={entityTypeMessages.subtotalStepInstructionMessageId}
            defaultMessage={entityTypeMessages.subtotalDefaultStepInstructionMessage}
          />
        }
      >
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form onKeyDown={onKeyDownBlockEnter}>
            <div ref={quickviewCardRef} id="main-quickview-card">
              <QuickViewCardTile
                title={tileToEdit.title}
                subTotal={count}
                modeFilterOptions={modeFilterOptions}
                modeFilters={modeFilters}
                entityType={entityType}
                className="mb-5"
              />
            </div>
            <div>
              <FilteringCriteriaComponent
                modeFilterOptions={modeFilterOptions}
                modeFilters={modeFilters}
                entityType={entityType}
                orderFilter={orderFilter}
                setOrderFilter={setOrderFilter}
                inventoryFilter={inventoryFilter}
                setInventoryFilter={setInventoryFilter}
                {...props}
              />
            </div>
            <div className={cn('d-flex justify-content-end mt-5', styles['button-container'])}>
              <Button htmlType="submit" type="primary" className="text-uppercase" disabled={isNextDisabled}>
                <FormattedMessage id="quickViewConfig.navigationButton.next" defaultMessage="Next" />
              </Button>
            </div>
            <div id="slide-in-quickview" className={styles['slide-quickview-card-tile']}>
              <QuickViewCardTile
                title={tileToEdit.title}
                subTotal={count}
                modeFilterOptions={modeFilterOptions}
                modeFilters={modeFilters}
                entityType={entityType}
              />
            </div>
          </Form>
        </Formik>
      </StepPageComponent>
    </div>
  );
};

export default SubTotalStep;
