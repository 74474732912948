import * as React from 'react';

import { SendBirdProvider } from 'sendbird-uikit';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { PrincipalContext, PrincipalContextProps } from 'contexts/PrincipalContext';
import { primaryBlue, primaryBlueSeventyFive, primaryGreyFive, polygonShade } from 'styles/colors';
import { RootState } from 'reducers';
import { PrincipalAuthorizations } from 'common/authorizations';

const ChatProvider: React.FC<{ children: any; authorizations: PrincipalAuthorizations }> = ({
  children,
  authorizations,
}) => {
  const [hasChatAccess, setHasChatAccess] = React.useState(false);
  const isLoggedIn = useSelector((state: RootState) => state.authReducer.isLoggedIn);
  const [userId, setUserId] = React.useState('');
  const [nickname, setNickname] = React.useState('');
  const principal: PrincipalContextProps | undefined = React.useContext(PrincipalContext);

  React.useEffect(() => {
    if (
      principal &&
      principal.id &&
      principal.firstName &&
      principal.lastName &&
      principal.tenantId &&
      isLoggedIn &&
      authorizations.hasChatEnabled()
    ) {
      setUserId(`${principal.id}:${principal.tenantId}`);
      setNickname(`${principal?.firstName} ${principal?.lastName}`);
      setHasChatAccess(true);
    }
  }, [principal, isLoggedIn, authorizations]);

  const myColorSet = {
    '--sendbird-light-primary-500': primaryBlue,
    '--sendbird-iconbutton-color': primaryBlue,
    '--sendbird-message-input-border-active': primaryBlue,
    '--sendbird-light-primary-400': primaryBlueSeventyFive,
    '--sendbird-light-primary-300': primaryBlueSeventyFive,
    '--sendbird-add-reaction-button-border-hover': primaryBlueSeventyFive,
    '--sendbird-selected-reaction-button-border-hover': primaryBlueSeventyFive,
    '--sendbird-light-primary-200': primaryGreyFive,
    '--sendbird-light-primary-100': polygonShade,
  };
  // userId is <userId>:<tenantId>
  if (hasChatAccess) {
    return (
      <SendBirdProvider
        userId={userId}
        appId={get(window, 'portalGlobal.sendbirdChatAppId') as unknown as string}
        theme="light"
        colorSet={myColorSet}
        nickname={nickname}
      >
        {children}
      </SendBirdProvider>
    );
  }

  return <>{children}</>;
};

export default ChatProvider;
