import sortBy from 'lodash/sortBy';

import mapNotificationFromApiToState from './mapNotificationFromApiToState';
import mapNotificationFromStateToApi from './mapNotificationFromStateToApi';

export const mapApiToState = (input, subscriptionOptions) => {
  if (!input) {
    return [];
  }
  const output = input
    .map((notification) => mapNotificationFromApiToState(notification, subscriptionOptions))
    .filter(Boolean);

  return sortBy(output, (notification) => notification.title);
};

export const mapStateToApi = (input, shipmentId, masterShipmentId, subscriptionOptions) => {
  if (!input) {
    return [];
  }

  return input
    .map((notification) =>
      mapNotificationFromStateToApi(notification, shipmentId, masterShipmentId, subscriptionOptions)
    )
    .filter(Boolean);
};
