export const MapDetailChinaStyles = `
<style>
.H_ib_body {
    background-color: #fff;
    border-radius: 0;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.5);
    color: #2d2926;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    padding: 16px;
    width: 190px;
}

.location_tab_china_map_iframe {
  background-color: white;
  height:100%;
  display: flex;
  flex-direction:row;
}

.location_list_heading {
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 14px 20px;
  border-bottom: 1px solid #d5d4d4;
}

.list_location_tab_china_map_iframe {
  background-color: white;
  min-width: 320px;
  height: 100%;
  font-family: 'Roboto', sans-serif, 'Open Sans';
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 10;
  visibility: hidden;
}

.list_location_tab_china_map_iframe ul{
  padding-inline-start: 0px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.list_location_tab_china_map_iframe li{
  list-style-type: none;
  font-size: 0.85rem;
  display: flex; 
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid #d5d4d4;
  padding: 10px 20px;
}

.list_location_tab_china_map_iframe li:hover{
  background-color: #f3f3f3;
  cursor: pointer;
}

.list_item_location_tab {
  margin: 1em 0em
}

.map-detail {
  background-color: #FFFFFF;
}

.map-detail__map-toggle {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 50%);
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.map-detail__toggle-btn {
  background-color: #f3f3f3;
  border: 0;
  color: #575451;
  padding: 10px;
  text-transform: uppercase;
}

.map-detail__toggle-btn:hover {
  cursor: pointer;
}

.H_ib_close.svg.H_icon {
  fill: #000;
}

.H_ib_close > svg.H_icon {
  fill: #000;
}

.H_ib_body {
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.5);
  color: #2d2926;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  padding: 16px;
  width: 190px;
}

.current-stop > p {
    font-size: 0.8rem;
}

.next-stop-eta {
    margin-bottom: 8px;
    text-align: center;
    margin-top: 0.4rem;
    font-size: 0.8rem;
}

.at-stop-message {
    text-align: center;
    font-size: 0.8rem;
}

.status-update {
    font-size: 12px;
    font-size: 0.8rem;
    color: #575451;
    font-style: italic;
    text-align: center;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
}

</style>
`;

export const htmlHead = `
  <head>
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    <script src="https://js.hereapi.cn/v3/3.0/mapsjs-core.js" type="text/javascript" charset="utf-8"></script>
    <script src="https://js.hereapi.cn/v3/3.0/mapsjs-data.js" type="text/javascript" charset="utf-8"></script>
    <script src="https://js.hereapi.cn/v3/3.0/mapsjs-mapevents.js" type="text/javascript" charset="utf-8"></script>
    <script src="https://js.hereapi.cn/v3/3.0/mapsjs-service.js" type="text/javascript" charset="utf-8"></script>
    <script src="https://js.hereapi.cn/v3/3.0/mapsjs-ui.js" type="text/javascript" charset="utf-8"></script>
    <script src="https://js.hereapi.cn/v3/3.0/mapsjs-clustering.js" type="text/javascript" charset="utf-8"></script>
    <link rel="stylesheet" type="text/css" href="https://js.hereapi.cn/v3/3.0/mapsjs-ui.css" />
    <script src="https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js" integrity="sha512-WFN04846sdKMIP5LKNphMaWzU7YpMyCU245etK3g/2ARYbPK9Ub18eG+ljU96qKRCWh+quCY7yefSmlkQw1ANQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
    ${MapDetailChinaStyles}
  </head>
`;

export const svgMarkupStartMarker =
  '<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="40" height="40" viewBox="0 0 128.000000 128.000000" preserveAspectRatio="xMidYMid meet">' +
  '<g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)" fill="#00558B" stroke="none" stroke-width="10">' +
  '<path d="M130 639 c0 -481 1 -510 17 -507 17 3 18 25 21 276 l2 272 470 0 470 0 2 -272 c3 -251 4 -273 21 -276 16 -3 17 26 17 507 l0 511 -510 0 -510 0 0 -511z m980 281 l0 -190 -470 0 -470 0 0 190 0 190 470 0 470 0 0 -190z"/>' +
  '<path d="M259 1055 c-37 -20 -55 -67 -40 -104 12 -28 30 -41 86 -57 20 -6 31 -17 33 -32 8 -54 -52 -69 -76 -19 -17 35 -48 33 -44 -3 3 -34 46 -70 83 -70 95 0 108 148 14 166 -41 7 -58 25 -53 56 5 36 58 32 76 -4 7 -16 20 -28 28 -28 17 0 19 48 1 71 -14 19 -51 39 -69 39 -7 -1 -25 -7 -39 -15z"/>' +
  '<path d="M414 1055 c-8 -21 4 -35 31 -35 24 0 24 -1 27 -122 3 -108 5 -123 21 -126 15 -3 17 8 17 122 0 125 0 126 23 126 31 0 50 19 36 37 -17 19 -148 18 -155 -2z"/>' +
  '<path d="M627 1063 c-9 -15 -67 -251 -67 -273 0 -30 32 -22 40 10 5 21 12 25 40 25 28 0 35 -4 40 -25 3 -14 14 -26 23 -28 25 -5 21 31 -16 172 -29 109 -46 142 -60 119z m23 -135 c0 -7 3 -23 6 -35 5 -19 2 -23 -16 -23 -18 0 -21 4 -16 23 3 12 6 28 6 35 0 6 5 12 10 12 6 0 10 -6 10 -12z"/>' +
  '<path d="M777 1063 c-4 -3 -7 -71 -7 -150 0 -132 1 -144 18 -141 14 3 18 16 20 66 4 90 32 82 42 -13 4 -42 8 -50 25 -50 16 0 20 6 18 35 -2 49 -2 116 0 174 1 40 -3 52 -22 67 -22 18 -81 26 -94 12z m73 -83 c0 -33 -3 -40 -20 -40 -17 0 -20 7 -20 40 0 33 3 40 20 40 17 0 20 -7 20 -40z"/>' +
  '<path d="M924 1055 c-8 -21 4 -35 31 -35 24 0 24 -1 27 -122 3 -108 5 -123 21 -126 15 -3 17 8 17 122 0 118 1 126 19 126 29 0 51 11 51 25 0 19 -24 25 -96 25 -47 0 -66 -4 -70 -15z"/>' +
  '</g></svg>';

export const startMarker = `
  const svgMarkupStartMarker = '${svgMarkupStartMarker}';

  var startingPointIcon = new H.map.Icon(svgMarkupStartMarker);

  var startMarker = new H.map.Marker({
    lat: startPoint.latitude,
    lng: startPoint.longitude
  }, {icon: startingPointIcon}); // Create a marker for the start point:
`;

export const svgMarkupEndMarker =
  '<svg height="32" width="32" viewBox="0 0 22.5 27" xmlns="http://www.w3.org/2000/svg"> ' +
  '<path fill="#00558B" d="M1.5,0A1.5,1.5,0,0,1,3,1.5V2.82A16.09,16.09,0,0,1,9,1.5c4.5,0,4.5,3,7.5,3,4.5,0,6-3,6-3v12s-1' +
  '.5,3-6,3-4.5-3-7.5-3c-4.5,0-6,3-6,3V27H0V1.5A1.5,1.5,0,0,1,1.5,0Z"></path>' +
  '<path fill="#fff" d="M3,6v6a13.31,13.31,0,0,1,6-1.5c3,0,6,3,7.5,3a4.32,4.32,0,0,0,3-1.5V6a4.32,4.32,0,0,1-3,1.5c-1.5,0-4.5' +
  '-3-7.5-3A13.31,13.31,0,0,0,3,6Z"></path></svg>';

export const destinationMarker = `
  const svgMarkupEndMarker = '${svgMarkupEndMarker}';
  var finalDestinationIcon = new H.map.Icon(svgMarkupEndMarker);
  var endMarker = new H.map.Marker({
    lat: endPoint.latitude,
    lng: endPoint.longitude
  }, {icon: finalDestinationIcon}); // Add the route polyline and the two markers to the map:
`;

export const svgMarkupCurrentStopIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" height="36" ' +
  'viewBox="0 0 28 40" width="36" class="current-stop-marker">' +
  '<path fill="#1b468d" d="M14,0A14,14,0,0,0,0,14C0,24.5,14,40,14,40S28,24.5,28,14A14,14,0,0,0,14,0Z"/>' +
  '<circle fill="#fff" cx="14" cy="14" r="5"/></svg>';

export const currentStopMarker = `
  var svgMarkupCurrentStopIcon = '${svgMarkupCurrentStopIcon}';
  var currentStopIcon = new H.map.Icon(svgMarkupCurrentStopIcon);
  currentStopMarker = new H.map.Marker(currentStopCoords, {icon: currentStopIcon});
`;

export const MapSidepanelItemIcon = `  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 28 40" width="24">
<path
  fill="#00558b"
  d="M14,0A14,14,0,0,0,0,14C0,24.5,14,40,14,40S28,24.5,28,14A14,14,0,0,0,14,0Z"
/>
<circle fill="#fff" cx="14" cy="14" r="5" />
</svg>`;

export const locationTabToogleFunction = `
  function toggleLocationTab(show){
    if (show) {
      document.getElementById("location_tab_china").style.visibility= "visible" ;
      document.getElementById('location_tab_china').style.display= 'block' ;
      document.getElementById('route').style.backgroundColor= 'white' ;
      document.getElementById('route').style.color= 'black' ;
      document.getElementById('location').style.backgroundColor= '#00aeef' ;
      document.getElementById('location').style.color= 'white' ;
    } else {
      document.getElementById("location_tab_china").style.visibility= "hidden" ;
      document.getElementById('location_tab_china').style.display= 'none' ;
      document.getElementById('location').style.backgroundColor= 'white' ;
      document.getElementById('location').style.color= 'black' ;
      document.getElementById('route').style.backgroundColor= '#00aeef' ;
      document.getElementById('route').style.color= 'white' ;
    }
  }
`;

export const convertSvgToUrl = (svgInString) => `data:image/svg+xml;base64,${btoa(svgInString)}`;

export const getLocationSvg = (color = '#00558b') =>
  `<svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 16 16" fill="none">
    <g fill="#000000">
      <path fill="${color}" d="M8 3a5 5 0 100 10A5 5 0 008 3z"/>
      <path fill="${color}" fill-rule="evenodd" d="M0 8a8 8 0 1116 0A8 8 0 010 8zm8-6.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13z" clip-rule="evenodd"/>
    </g>
  </svg>`;

export const mapMarkerSvg = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="47"
    viewBox="0 0 38 47"
  >
    <g fill="none">
      <path
        fill="#0F1621"
        fill-opacity=".4"
        d="M15 46c0 .317 1.79.574 4 .574s4-.257 4-.574c0-.317-1.79-.574-4-.574s-4 .257-4 .574z"
      ></path>
      <path
        fill="#01b6b2"
        d="M33.25 31.652A19.015 19.015 0 0 0 38 19.06C38 8.549 29.478 0 19 0S0 8.55 0 19.059c0 4.823 1.795 9.233 4.75 12.593L18.975 46 33.25 31.652z"
      ></path>
      <path
        fill="#6A6D74"
        fill-opacity=".5"
        d="M26.862 37.5l4.714-4.77c3.822-3.576 5.924-8.411 5.924-13.62C37.5 8.847 29.2.5 19 .5S.5 8.848.5 19.11c0 5.209 2.102 10.044 5.919 13.614l4.719 4.776h15.724zM19 0c10.493 0 19 8.525 19 19.041 0 5.507-2.348 10.454-6.079 13.932L19 46 6.079 32.973C2.348 29.495 0 24.548 0 19.04 0 8.525 8.507 0 19 0z"
      ></path>
    </g>
  </svg>`;
