import * as React from 'react';

import { ClockCircleOutlined } from '@ant-design/icons';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import moment, { Moment } from 'moment';

import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';

import { Field, FieldProps, FormikProps } from 'formik';

import styled from 'styled-components';
import TimePicker from 'antd/lib/time-picker';
import { rgba } from 'polished';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { LONG_DAYS_OF_WEEK, HOURS_MINUTES_AM_PM_FORMAT } from 'common/AppConstants';

import { CheckBox } from 'components/common/forms/FormElements';

import { withTheme, ThemeContext } from 'contexts/ThemeContext';

import { Theme, LocationDetails, FormValues } from 'models';

import { white, newFruit } from 'styles/colors';

interface EditHoursProps extends WrappedComponentProps {
  hours: LocationDetails['hours'];
  handleChange: (e: React.ChangeEvent) => void;
  setFieldValue: any;
  formProps: FormikProps<FormValues>;
  context?: Theme | undefined;
}
const HoursRow = styled(Row)`
  font-size: 14px;
  padding-bottom: 8px;
`;

function formatTimeValue(time: string) {
  return time ? moment(time, 'hh:mm a').format(HOURS_MINUTES_AM_PM_FORMAT) : undefined;
}

const StyledTimePicker = styled(TimePicker)`
  &.ant-picker-focused,
  &:focus,
  &:hover,
  &:active {
    border: 1px solid ${(props) => props.theme.primaryColor} !important;
  }
  &.ant-picker-focused {
    box-shadow: 0 0 0 2px ${(props) => rgba(props.theme.primaryColor, 0.2)};
  }
  min-width: 115px;
  .has-error .ant-form-explain,
  .has-error .ant-form-split {
    font-size: 11px;
    margin-top: 0;
  }
  .has-error input:focus {
    border: 1px solid ${newFruit};
  }
  .primary {
    input {
      background-color: ${white};
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
      border: none;
    }
  }
`;

// Make sure to change the z-index of tghe modal again. THe timepoicker is hidden behind, but at 1301 it'll show
export function SelectTimeComponent({
  defaultTime,
  onChange,
  name,
  isFrom,
  ...rest
}: {
  defaultTime: Moment | undefined;
  onChange: any;
  name: string;
  isFrom?: boolean;
  [key: string]: any;
  theme: Theme;
}) {
  const [open, setOpen] = React.useState<boolean>(false);
  const use12Hours = HOURS_MINUTES_AM_PM_FORMAT.toLowerCase().includes('a');
  return (
    <StyledTimePicker
      open={open}
      allowClear={false}
      theme={rest.theme}
      onChange={(_: any, value: any) => {
        onChange(undefined, value);
      }}
      minuteStep={10}
      onOpenChange={(isOpen: boolean) => {
        setOpen(isOpen);
      }}
      className={`data-testid-${rest.testId}`}
      format={HOURS_MINUTES_AM_PM_FORMAT}
      use12Hours={use12Hours}
      defaultOpenValue={isFrom ? (moment('08:00 am', 'HH:mm a') as any) : (moment('06:00 pm', 'HH:mm a') as any)}
      defaultValue={defaultTime && (moment(defaultTime, 'HH:mm a') as any)}
      placeholder={rest.placeholder}
    />
  );
}

const OpenCloseTimes = ({ hours, formProps, setFieldValue, intl }: EditHoursProps) => {
  const context = React.useContext(ThemeContext);

  const EditHours = React.memo(
    injectIntl((props: EditHoursProps) => {
      const res = LONG_DAYS_OF_WEEK.reduce((acc, { id, defaultMessage }) => {
        const lowercaseDay = defaultMessage.toLowerCase();
        const fromTimeChanged = (_: React.ChangeEvent, value: any) => {
          const openingHours = get(props, 'formProps.values.hours');
          const updated = { ...openingHours, [`${lowercaseDay}From`]: formatTimeValue(value) };
          props.setFieldValue(`hours`, updated);
        };
        const toTimeChanged = (_: React.ChangeEvent, value: any) => {
          const openingHours = get(props, 'formProps.values.hours');
          const updated = { ...openingHours, [`${lowercaseDay}To`]: formatTimeValue(value) };
          props.setFieldValue(`hours`, updated);
        };
        acc.push(
          <HoursRow type={'flex'} gutter={10} key={id}>
            <Col xs={4}>
              <FormattedMessage defaultMessage={defaultMessage} id={`calendar.shortDays.${lowercaseDay}`} />
            </Col>
            <Col xs={6}>
              <Field
                name={`${lowercaseDay}From`}
                key={`${lowercaseDay}From`}
                testId={`${lowercaseDay}From`}
                onChange={fromTimeChanged}
                isFrom
                placeholder={props.intl.formatMessage({
                  id: 'locationDetails.edit.placeholders.selectTime',
                  defaultMessage: 'Select time',
                })}
                component={withTheme(SelectTimeComponent)}
                defaultTime={get(props, ['hours', `${lowercaseDay}From`])}
              />
            </Col>
            <Col xs={7}>
              <Field
                name={`${lowercaseDay}To`}
                key={`${lowercaseDay}To`}
                onChange={toTimeChanged}
                testId={`${lowercaseDay}To`}
                isFrom={false}
                placeholder={props.intl.formatMessage({
                  id: 'locationDetails.edit.placeholders.selectTime',
                  defaultMessage: 'Select time',
                })}
                component={withTheme(SelectTimeComponent)}
                defaultTime={get(props, ['hours', `${lowercaseDay}To`])}
              />
            </Col>
            <Col xs={4}>
              <Field name={`${lowercaseDay}Closed`}>
                {({ field, form }: FieldProps) => {
                  return (
                    <CheckBox
                      onCheck={() => {
                        form.setFieldValue(`${lowercaseDay}Closed`, !field.value);
                        if (field.value) {
                          form.setFieldValue(`hours[${lowercaseDay}From]`, undefined);
                          form.setFieldValue(`hours[${lowercaseDay}To]`, undefined);
                        }
                      }}
                      checked={
                        isNil(get(form, `values.hours[${lowercaseDay}From]`)) &&
                        isNil(get(form, `values.hours[${lowercaseDay}To]`))
                      }
                      custom={{ testId: `${lowercaseDay}Closed`, primaryColor: get(props.context, 'primaryColor') }}
                      label={`${lowercaseDay}Closed`}
                      field={field}
                    />
                  );
                }}
              </Field>
            </Col>
          </HoursRow>
        );

        return acc;
      }, [] as any);
      return <>{res}</>;
    })
  );
  return (
    <>
      <Row>
        <Col lg={2} className="text-center" style={{ maxWidth: 'calc(6%)' }}>
          <ClockCircleOutlined style={{ fontSize: '2rem', lineHeight: '3.3rem' }} />
        </Col>
        <Col style={{ fontSize: '1.6rem' }}>
          {formProps.errors.hours && (
            <div className="invalid-feedback" style={{ display: 'inline-block' }}>
              {intl.formatMessage({
                id: `${formProps.errors.hours}`,
                defaultMessage: 'Please select at least one weekday',
              })}
            </div>
          )}
          <EditHours
            formProps={formProps}
            setFieldValue={setFieldValue}
            context={context}
            // eslint-disable-next-line react/jsx-no-bind
            handleChange={(e) => {
              // tslint:disable-next-line: jsx-no-lambda
              return;
            }}
            hours={hours}
          />
        </Col>
      </Row>
    </>
  );
};
export default React.memo(OpenCloseTimes);
