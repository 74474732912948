/* eslint-disable @typescript-eslint/prefer-as-const */
import moment from 'moment';
import { ShipmentETAStatusEnum } from '../../../models';

// Demo shipments that link to mock intermodal shipments. These are only included if you have the Demo Visibility Permission
const demoShipments = [
  {
    type: 'Feature' as 'Feature', // preventing weird typescript error with mapbox
    properties: {
      shipmentId: '1004283b-f9e6-4c65-aa63-4c53e8c765cc',
      shipmentMode: 'INTERMODAL',
      identifierType: 'CONTAINER',
      identifierValue: 'OOLU7832365',
      status: ShipmentETAStatusEnum.ON_TIME,
      originName: 'Port of Beihai',
      originLocation: 'Beihai, China',
      destinationName: 'BNSF Railway Co',
      destinationLocation: 'Albuquerque, NM',
      lastUpdated: moment().subtract(5, 'minutes').toISOString(),
    },
    geometry: {
      type: 'Point' as 'Point', // preventing weird typescript error with mapbox
      coordinates: [-159.252, 44.4023],
    },
  },
  {
    type: 'Feature' as 'Feature', // preventing weird typescript error with mapbox
    properties: {
      shipmentId: '048727d0-3c51-4ea5-abe8-74751b4b20d7',
      shipmentMode: 'INTERMODAL',
      identifierType: 'CONTAINER',
      identifierValue: 'OOLU4312365',
      status: ShipmentETAStatusEnum.ON_TIME,
      originName: 'Port of Beihai',
      originLocation: 'Beihai, China',
      destinationName: 'BNSF Railway Co',
      destinationLocation: 'Albuquerque, NM',
      lastUpdated: moment().subtract(5, 'minutes').toISOString(),
    },
    geometry: {
      type: 'Point' as 'Point', // preventing weird typescript error with mapbox
      coordinates: [-118.271, 33.7475],
    },
  },
  {
    type: 'Feature' as 'Feature', // preventing weird typescript error with mapbox
    properties: {
      shipmentId: '79d68ee7-1980-4eb2-922c-1407ed15a896',
      shipmentMode: 'INTERMODAL',
      identifierType: 'CONTAINER',
      identifierValue: 'OOLU1115365',
      status: ShipmentETAStatusEnum.ON_TIME,
      originName: 'Port of Beihai',
      originLocation: 'Beihai, China',
      destinationName: 'BNSF Railway Co',
      destinationLocation: 'Albuquerque, NM',
      lastUpdated: moment().subtract(5, 'minutes').toISOString(),
    },
    geometry: {
      type: 'Point' as 'Point', // preventing weird typescript error with mapbox
      coordinates: [-114.116479, 35.068907],
    },
  },
  {
    type: 'Feature' as 'Feature', // preventing weird typescript error with mapbox
    properties: {
      shipmentId: 'c2077404-0226-43f9-82d5-cac4409689d0',
      shipmentMode: 'INTERMODAL',
      identifierType: 'CONTAINER',
      identifierValue: 'OOLU8001365',
      status: ShipmentETAStatusEnum.ON_TIME,
      originName: 'Port of Beihai',
      originLocation: 'Beihai, China',
      destinationName: 'Whole Foods DC',
      destinationLocation: 'Salt Lake City, UT',
      lastUpdated: moment().subtract(5, 'minutes').toISOString(),
    },
    geometry: {
      type: 'Point' as 'Point', // preventing weird typescript error with mapbox
      coordinates: [-171.242, 45.2466],
    },
  },
  {
    type: 'Feature' as 'Feature', // preventing weird typescript error with mapbox
    properties: {
      shipmentId: 'a65d9fb3-b5db-4293-95ee-4fb81eb4dbba',
      shipmentMode: 'INTERMODAL',
      identifierType: 'CONTAINER',
      identifierValue: 'OOLU7902322',
      status: ShipmentETAStatusEnum.ON_TIME,
      originName: 'Port of Beihai',
      originLocation: 'Beihai, China',
      destinationName: 'AMP Consolidation Center',
      destinationLocation: 'Phoenix, AZ',
      lastUpdated: moment().subtract(5, 'minutes').toISOString(),
    },
    geometry: {
      type: 'Point' as 'Point', // preventing weird typescript error with mapbox
      coordinates: [-114.229531, 33.661334],
    },
  },
  {
    type: 'Feature' as 'Feature', // preventing weird typescript error with mapbox
    properties: {
      shipmentId: '42aa37aa-4fc5-4524-b60a-ea7bfb6cdabc',
      shipmentMode: 'INTERMODAL',
      identifierType: 'CONTAINER',
      identifierValue: 'OOLU8802332',
      status: ShipmentETAStatusEnum.ON_TIME,
      originName: 'Port of Antwerp',
      originLocation: 'Antwerp, Belgium',
      destinationName: 'Provport',
      destinationLocation: 'Providence, RI',
      lastUpdated: moment().subtract(5, 'minutes').toISOString(),
    },
    geometry: {
      type: 'Point' as 'Point', // preventing weird typescript error with mapbox
      coordinates: [-50.787, 45.0261],
    },
  },
];

export const isDemoShipment = (masterShipmentId?: string) => {
  if (masterShipmentId === undefined) {
    return false;
  }
  const demoShipmentIds = demoShipments.map((shipment) => shipment.properties.shipmentId);
  return [...demoShipmentIds, '16d65ab7-a4cc-4b19-ae1f-7e6c6efcee7f', '249c76dd-3b64-4f15-9b8f-14b9e990617e'].includes(
    masterShipmentId
  );
};

export default demoShipments;
