import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import { primaryGrey } from 'styles/colors';

import Modes from './Modes';
import Filters from './Filters';
import { messages, ModeType } from './utils';

interface HorizontalFilterProps {
  modeActive: ModeType;
  modesOptions: Array<{
    value: string;
    label: string;
  }>;
  onChangeModes: () => void;
}

const StyledH3 = styled('h3')`
  color: ${primaryGrey};
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 0;
`;

const StyledHeaderContainer = styled('div')`
  margin-bottom: 15px;
`;
const HorizontalFilter = ({ modeActive, modesOptions, onChangeModes, ...rest }: HorizontalFilterProps) => {
  const { search } = useLocation();
  const { filter, search: searchText } = qs.parse(search, { ignoreQueryPrefix: true });
  const hasFilters = !isEmpty(filter) || !isEmpty(searchText);

  return (
    <div className="pr-xl-5">
      <StyledHeaderContainer className="d-flex align-items-center justify-content-between">
        <StyledH3>
          <FormattedMessage {...messages.refineResults} />
        </StyledH3>
      </StyledHeaderContainer>
      <Modes modeActive={modeActive} modesOptions={modesOptions} onChangeModes={onChangeModes} />
      <Filters {...rest} modeActive={modeActive} hasFilters={hasFilters} />
    </div>
  );
};

export default HorizontalFilter;
