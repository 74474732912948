import get from 'lodash/get';
import omit from 'lodash/omit';
import trim from 'lodash/trim';
import { useState, useEffect } from 'react';
import { AxiosPromise } from 'axios';
import axios from 'util/paxios';
import { API_PATH } from 'common/AppConstants';
import { IdleLocationsArray, LocationDetails, Contact, AnalyticsLocationProps } from './models';
import { LocationShipmentResponse } from './LocationShipments/models';

const url = process.env.REACT_APP_JSON_SERVER ? 'http://localhost:3001/api/portal/v2' : API_PATH;

export function updateLocation(location: LocationDetails) {
  return axios.put(
    `${url}/location/v2/locations/${location.id}`,
    {
      ...location,
    },
    { withCredentials: true }
  );
}

export const createLocation = (location: LocationDetails) => {
  return axios.post(
    `${API_PATH}/location/v2/locations`,
    {
      ...location,
    },
    { withCredentials: true }
  );
};

export const fetchAnalyticsByLocationId = (locationId: string): AxiosPromise<AnalyticsLocationProps> => {
  return axios.get(`${API_PATH}/analytics/locations/${locationId}`, { withCredentials: true });
};

export const fetchInboundOutboundShipments = (locationId: string) => {
  return axios.get(`${API_PATH}/location/v2/locations/${locationId}/shipments`, { withCredentials: true });
};

export const fetchInboundFutureShipments = (locationId: string) => {
  return axios.get(`${API_PATH}/location/v2/locations/${locationId}/futureShipments`, { withCredentials: true });
};

export const useLocationShipments = (locationId: string | undefined): LocationShipmentResponse | undefined => {
  const [shipments, setShipments] = useState<LocationShipmentResponse | undefined>();
  useEffect(() => {
    async function callService(id: string) {
      try {
        const response = await fetchInboundOutboundShipments(id);
        setShipments(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    if (locationId) {
      callService(locationId);
    }
  }, [locationId]);

  return shipments;
};

export const useLocationFutureShipments = (locationId: string | undefined): LocationShipmentResponse | undefined => {
  const [shipments, setShipments] = useState<LocationShipmentResponse | undefined>();
  useEffect(() => {
    async function callService(id: string) {
      try {
        const response = await fetchInboundFutureShipments(id);
        setShipments(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    if (locationId) {
      callService(locationId);
    }
  }, [locationId]);

  return shipments;
};

function removeKeyIdFromContacts(contacts: Contact[]) {
  return contacts.map((contact: Contact) => {
    return omit(contact, 'keyId');
  });
}

export const mapStateToApi = (request: LocationDetails) => {
  return {
    ...request,
    ...(get(request, 'contacts') && { contacts: removeKeyIdFromContacts(get(request, 'contacts', [])) }),
    ...(get(request, 'companyName') && { companyName: request.companyName.trim() }),
    address: {
      address1: (request?.address?.address1 ?? '').trim(),
      address2: (request?.address?.address2 ?? '').trim(),
      city: (request?.address?.city ?? '').trim(),
      postalCode: (request?.address?.postalCode ?? '').trim(),
      state: request.address.state,
      country: request.address.country,
      locationCoordinatesDto: get(request, 'address.locationCoordinatesDto'),
    },
  };
};

export const mapApiToState = (response: any) => ({
  ...response,
  address: {
    addressLine1: response.address.address1,
    addressLine2: response.address.address2,
    city: response.address.city,
    zip: response.address.postalCode,
    state: response.address.state,
    country: response.address.country,
    locationCoordinatesDto: get(response, 'address.locationCoordinatesDto'),
  },
});

export const locationAddressFactory = (addressProps?: LocationDetails['address']): LocationDetails['address'] => {
  return {
    address1: get(addressProps, 'address1', ''),
    address2: get(addressProps, 'address2', ''),
    city: get(addressProps, 'city', ''),
    state: get(addressProps, 'state', ''),
    country: get(addressProps, 'country', ''),
    postalCode: get(addressProps, 'postalCode', ''),
    locationCoordinatesDto: get(addressProps, 'locationCoordinatesDto', {
      accuracyInMeters: 0,
      latitude: 0,
      longitude: 0,
      localTimeZoneId: '',
    }),
  };
};

export const locationDetailsFactory = (locationProps?: LocationDetails): LocationDetails => {
  return {
    address: locationAddressFactory(),
    geofence: {
      source: get(locationProps, 'source', ''),
      type: get(locationProps, 'type'),
      units: get(locationProps, 'units', ''),
      value: get(locationProps, 'value'),
    },
    hours: get(locationProps, 'hours', {}),
    name: get(locationProps, 'name', ''),
    companyName: get(locationProps, 'companyName', ''),
    tenantId: get(locationProps, 'tenantId'),
    specifiedId: get(locationProps, 'specifiedId'),
  };
};

/**
 * parseDurationString
 * @param duration String of the format "PT1XHXM" where H is for hour and M is minute
 */
export const parseDurationString = (duration: any): number => {
  if (!duration) {
    return 0;
  } else if (typeof duration === 'number') {
    return duration / 60;
  } else {
    let hours = '0';
    let mins = '0';
    const prefix = 'PT';
    if (duration.indexOf(prefix) === -1) {
      return 0;
    }
    if (duration.indexOf('H') >= 0) {
      hours = duration.substring(prefix.length, duration.indexOf('H'));
    }

    if (duration.indexOf('M') >= 0) {
      if (duration.indexOf('H') >= 0) {
        mins = duration.substring(duration.indexOf('M'), hours.length + prefix.length + 1);
      } else {
        mins = duration.substring(duration.indexOf('M'), prefix.length);
      }
    }
    return parseInt(hours, 10) * 60 + parseInt(mins, 10);
  }
};

export const extractLatLng = (idleLocations: IdleLocationsArray) => {
  return idleLocations.locations
    .filter((loc) => loc.coordinates)
    .map((location) => {
      return {
        lat: location.coordinates.latitude,
        lng: location.coordinates.longitude,
      };
    });
};

// getLatLngFromInputValue() assumes inputVal is a comma-separated lat, lng
export const getLatLngFromInputValue = (
  inputVal = ''
): { latitude: number | undefined; longitude: number | undefined } => {
  let latitude;
  let longitude;
  const separatedValues: string[] = inputVal.split(',').map((str: string) => trim(str));
  if (separatedValues.length === 2) {
    latitude = Number(separatedValues[0]);
    longitude = Number(separatedValues[1]);
  }
  return { latitude, longitude };
};
