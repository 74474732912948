import withMobileDialog from '@material-ui/core/withMobileDialog';
import NotificationDialogComponent from '../../../common/notificationDialog/NotificationDialogComponent';
import { DELETE_MESSAGE, MODAL_TITLE } from '../../../../common/AppConstants';
const DeleteDialogContent = (props) => {
  return (
    <NotificationDialogComponent
      open={props.open}
      closeDialog={() => props.toggleDeleteDialog(false)}
      dialogTitle={MODAL_TITLE}
      actionFn={() => props.deleteShipment(props.shipmentId)}
      actionText="Delete"
      cancelButton
    >
      <div className="message-content" style={{ whiteSpace: 'pre-wrap' }}>
        {DELETE_MESSAGE}
      </div>
    </NotificationDialogComponent>
  );
};
export default withMobileDialog()(DeleteDialogContent);
