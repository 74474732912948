export interface LocationDetails {
  id?: string;
  address: {
    address1: string;
    address2?: string;
    addressSpecificity?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    locationCoordinatesDto?: {
      accuracyInMeters?: number;
      latitude: number;
      longitude: number;
      localTimeZoneId?: string;
      localTimeZoneIdentifier?: string;
    };
  };
  geofence: {
    id?: string;
    source: string;
    type?: FenceType;
    units: string;
    value?: number;
    geometry?: any;
  };
  hours: {
    [key: string]: string | undefined;
    mondayFrom?: string | undefined;
    mondayTo?: string | undefined;
    tuesdayFrom?: string | undefined;
    tuesdayTo?: string | undefined;
    wednesdayFrom?: string | undefined;
    wednesdayTo?: string | undefined;
    thursdayFrom?: string | undefined;
    thursdayTo?: string | undefined;
    fridayFrom?: string | undefined;
    fridayTo?: string | undefined;
    saturdayFrom?: string | undefined;
    saturdayTo?: string | undefined;
    sundayFrom?: string | undefined;
    sundayTo?: string | undefined;
  };
  name: string;
  companyName: string;
  masterLocationId?: string;
  tenantId: number | undefined;
  specifiedId: string | undefined;
  version?: number;
  contacts?: Contact[];
}

export interface Contact {
  address1?: string;
  address2?: string;
  address3?: string;
  postalCode?: string;
  city?: string;
  state?: string;
  country?: string;
  companyName?: string;
  contactName: string;
  phoneNumber: string;
  phoneNumberCountryCode?: string;
  phoneNumber2?: string;
  phoneNumber2CountryCode?: string;
  email: string;
  faxNumber?: string;
  faxNumberCountryCode?: string;
  id?: string;
  type?: string;
  keyId?: string;
}

export interface Time {
  hour: number;
  minute: number;
  nano: number;
  second: number;
}

export interface FormValues {
  address: LocationDetails['address'];
  name: string;
  companyName: string | undefined;
  type?: string;
  specifiedId: string | undefined;
  tenantId: number | undefined;
  geofence: {
    type?: FenceType;
    value?: number;
    units: string;
    source: string;
    geometry?: any;
  };
  address2: string;
  contacts: Contact[];
  distanceValue: number;
  distanceUnits: DistanceUnitsEnum;
  timeValue: number;
  fenceType: FenceType;
  hours:
    | {
        [key: string]: string | undefined;
        mondayFrom?: string | undefined;
        mondayTo?: string | undefined;
        tuesdayFrom?: string | undefined;
        tuesdayTo?: string | undefined;
        wednesdayFrom?: string | undefined;
        wednesdayTo?: string | undefined;
        thursdayFrom?: string | undefined;
        thursdayTo?: string | undefined;
        fridayFrom?: string | undefined;
        fridayTo?: string | undefined;
        saturdayFrom?: string | undefined;
        saturdayTo?: string | undefined;
        sundayFrom?: string | undefined;
        sundayTo?: string | undefined;
      }
    | undefined;
  mondayClosed: boolean;
  tuesdayClosed: boolean;
  wednesdayClosed: boolean;
  thursdayClosed: boolean;
  fridayClosed: boolean;
  saturdayClosed: boolean;
  sundayClosed: boolean;
  isChinaAsCountry: boolean;
}

export enum FenceType {
  POLYGON = 'POLYGON',
  DISTANCE = 'DISTANCE',
  TIME = 'TIME',
}

export enum GeometryTypes {
  POINT = 'Point',
  POLYGON = 'Polygon',
}

export type EditLocationActions =
  | { type: 'SET_TYPE'; payload: FenceType }
  | { type: 'SET_TIME_VALUE'; payload: number }
  | { type: 'SET_DISTANCE_VALUE'; payload: number }
  | { type: 'CLOSE_POLYGON'; payload: boolean }
  | { type: 'SET_CUSTOM_GEOFENCE'; payload: LocationDetails['geofence'] }
  | { type: 'RESET_VALUES' }
  | { type: 'RESET_TO_INTIAL_VALUE' };

export enum KpiTypes {
  ACCURACY = 'accuracy',
  VOLUME = 'volume',
  TRACKING_COMPLIANCE = 'trackingCompliance',
  DWELL_TIMES = 'dwellTimes',
}

export interface ShipmentVolumesModel {
  date: string;
  totalShipments: number;
}

export interface DwellTimesModel {
  date: string;
  duration: string;
}

export interface RecentPerformanceModel {
  totalShipments?: number;
  arrivedShipments?: number;
  missedShipments?: number;
}

export interface TrackingComplianceModel {
  date: string;
  totalShipments: number;
  trackedShipments: number;
}

export interface GeofencePerformanceModel {
  date: string;
  totalShipments: number;
  arrivedShipments: number;
  missedShipments: number;
}
export interface AnalyticsLocationProps {
  dwellTimes?: {
    dwellTimes: DwellTimesModel[];
  };
  location: LocationDetails;
  recentPerformance?: RecentPerformanceModel;
  shipmentVolumes?: {
    shipmentVolumes: ShipmentVolumesModel[];
  };
  trackingCompliances?: {
    trackingCompliances: TrackingComplianceModel[];
  };
  geofencePerformances?: {
    geofencePerformances: GeofencePerformanceModel[];
  };
  idleLocations?: IdleLocationsArray;
}

export interface IdleLocationsArray {
  locations: Array<{
    coordinates: {
      latitude: number;
      longitude: number;
      accuracyInMeters: string | number | null;
      localTimeZoneId: string | number | null;
    };
    duration: string;
  }>;
}

export interface LatLng {
  latitude?: number;
  longitude?: number;
}

export enum ShipmentDirection {
  OUTBOUND = 'outbound',
  INBOUND = 'inbound',
}

export enum DistanceUnitsEnum {
  MILES = 'MILES',
  METERS = 'METERS',
}

export enum GeofenceSourceType {
  LOCATION = 'LOCATION',
  TENANT = 'TENANT',
}

export enum TimeMeasurementEnum {
  MINUTES = 'MINUTES',
}
