import styled from 'styled-components';
import { primaryGreyTwenty } from 'styles/colors';
import { ShipmentDerivedStatus, getShipmentDerivedStatusColor } from 'models';

export interface ActiveStopLineProps {
  widthPercentage: number;
  isFirstStop: boolean;
  shipmentStatus: ShipmentDerivedStatus;
}

export const ActiveStopLine = styled.div<ActiveStopLineProps>`
  position: absolute;
  top: 3px;
  left: ${(props) => (props.isFirstStop ? '50%' : 0)};
  width: ${(props) => props.widthPercentage}%;
  background-color: ${(props) => getShipmentDerivedStatusColor(props.shipmentStatus)};
  height: 4px;
  z-index: 2;
`;

export interface InactiveStopLineProps {
  isFirstStop: boolean;
  isLastStop: boolean;
  isSingleStop: boolean;
}

export const InactiveStopLine = styled.div<InactiveStopLineProps>`
  background-color: ${primaryGreyTwenty};
  position: absolute;
  top: 3px;
  width: ${(props) => (props.isSingleStop ? '0%' : props.isFirstStop || props.isLastStop ? '50%' : '100%')};
  left: ${(props) => (props.isFirstStop ? '50%' : 0)};
  height: 4px;
  z-index: -2;
`;
