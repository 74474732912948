// eslint-disable-next-line
/* global H */

import classNames from 'classnames';
import { parse } from 'wellknown';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { Dispatch, SetStateAction } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { animated, useSpring } from 'react-spring';
import { FormikProps } from 'formik';
import { useFetchConfig } from 'components/common/hereMapsConfig/hooks';
import { Button } from 'ui-components';
import {
  createMapInstance,
  getMapOptions,
  initializeMap,
  removeAllGeofences,
  addPolygonGeofenceToCurrentMap,
  addRadiusBasedGeofenceToCurrentMap,
} from 'common/maps/map.service';
import {
  AnalyticsLocationProps,
  FormValues,
  FenceType,
  LocationDetails,
  GeometryTypes,
  EditLocationActions,
  DistanceUnitsEnum,
} from 'models';
import HereMapsBehavior from 'common/hereMaps/hereMapsBehavior';
import * as styles from './LocationMap.module.scss';
import EditLocationMap from './EditLocationMap';
import { getLatLngFromInputValue } from '../location.service';
import BaiduLocationMap from './BaiduLocationMap';

export const MAP_ID = 'location-details-map';
const customStyles = {
  background: 'grey',
  height: '100%',
  width: '100%',
};
const mapOptions = getMapOptions();

interface LocationMapProps {
  defaultFenceType: FenceType;
  defaultDistanceValue: number;
  geofenceRangeInMinutes: number;
  data: any;
  userEnteredAddress: any;
  latLngInputValue: string | undefined;
  updatelatLngInputValue: (latLng: string | undefined) => void;
  useAddressLatLngInMap: boolean;
  isEditMode: boolean;
  setFieldValue: (field: any, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  isResetting: boolean;
  toggleIsResetting: (value: boolean) => void;
  formProps: FormikProps<FormValues>;
  handleCustomGeofenceCoords: (coords: any) => void;
  setIsEditGeofenceButtonVisible: Dispatch<SetStateAction<boolean>>;
  isEditGeofenceButtonVisible: boolean;
  analyticsData: AnalyticsLocationProps;
  defaultDistanceUnits: DistanceUnitsEnum;
}

interface InitialStateProps {
  fenceType: FenceType;
  timeBasedValue: number;
  distanceBasedValue: number;
  polygonClosed: boolean;
  customGeofence: LocationDetails['geofence'];
}

function reducer(state: InitialStateProps, action: EditLocationActions) {
  switch (action.type) {
    case 'SET_TYPE':
      return {
        ...state,
        fenceType: action.payload,
      };
    case 'SET_TIME_VALUE':
      return {
        ...state,
        timeBasedValue: action.payload,
      };
    case 'SET_DISTANCE_VALUE':
      return {
        ...state,
        distanceBasedValue: action.payload,
      };
    case 'CLOSE_POLYGON':
      return {
        ...state,
        polygonClosed: action.payload,
      };
    case 'SET_CUSTOM_GEOFENCE':
      return {
        ...state,
        customGeofence: action.payload,
      };
    case 'RESET_VALUES':
      return {
        ...state,
        timeBasedValue: 0,
        distanceBasedValue: 0,
        polygonClosed: false,
      };
    default:
      throw new Error();
  }
}

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

const LocationMap: React.FC<LocationMapProps> = (props) => {
  const {
    userEnteredAddress,
    data: { geofence = {} },
    toggleIsResetting,
    isResetting,
    formProps,
    handleCustomGeofenceCoords,
    setIsEditGeofenceButtonVisible,
    isEditGeofenceButtonVisible,
    latLngInputValue,
    updatelatLngInputValue,
    useAddressLatLngInMap,
  } = props;
  const mapElement = React.useRef({} as any);
  const initialState: InitialStateProps = {
    timeBasedValue: props.geofenceRangeInMinutes,
    fenceType: props.defaultFenceType,
    distanceBasedValue: props.defaultFenceType === FenceType.DISTANCE ? props.defaultDistanceValue : 0,
    polygonClosed: false,
    customGeofence: geofence,
  };
  const [mapInstance, setMapInstance] = React.useState({} as any);
  const [address, setAddress] = React.useState({} as any);
  const [latLng, setLatLng] = React.useState({ latitude: undefined, longitude: undefined } as any);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [mapConfig, setMapConfig] = React.useState<any>(undefined);
  const mapConfigResponse = useFetchConfig();
  const { isChinaAsCountry } = formProps.values;

  React.useEffect(() => {
    if (!isEmpty(geofence)) {
      dispatch({ type: 'SET_CUSTOM_GEOFENCE', payload: geofence });
      dispatch({ type: 'SET_TYPE', payload: FenceType[get(geofence, 'type', 'TIME') as FenceType] });
    }
  }, [geofence]);

  React.useEffect(function componentUnmountCleanup() {
    return () => {
      dispatch({ type: 'RESET_VALUES' });
    };
  }, []);

  React.useEffect(() => {
    if (!mapConfig) {
      setMapConfig(mapConfigResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([mapConfigResponse, mapConfig]));
  React.useEffect(() => {
    async function getNewMap() {
      const result: any = await createMapInstance({
        mapId: MAP_ID,
        mapOptions,
        config: mapConfig,
        mapType: 'satellite',
      });
      setMapInstance(result);
    }
    if (mapConfig) {
      getNewMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([mapConfig]));

  React.useEffect(function setupNewMapEffect() {
    let newAddress;
    // Check for an Autocomplete address change, and update the map with new address
    if (!isNil(userEnteredAddress)) {
      newAddress = {
        address1: `${get(userEnteredAddress, 'address.houseNumber')} ${get(userEnteredAddress, 'address.street')}`,
        city: get(userEnteredAddress, 'address.city'),
        state: get(userEnteredAddress, 'address.state'),
        postalCode: get(userEnteredAddress, 'address.postalCode'),
        country: get(userEnteredAddress, 'address.countryCode'),
      };
    }
    const shouldInitializeMap =
      (!isEqual(address, get(props, 'data.address')) ||
        !isEqual(newAddress, get(props, 'data.address')) ||
        !isEqual(latLngInputValue, latLng)) &&
      !isEmpty(mapInstance);
    if (shouldInitializeMap) {
      setAddress(newAddress || get(props, 'data.address'));
      setLatLng(latLngInputValue);

      const { geofenceRangeInMinutes } = props;
      const address = newAddress || get(props, 'data.address', {});
      const { latitude, longitude } = getLatLngFromInputValue(latLngInputValue);
      if (!useAddressLatLngInMap && latitude && longitude) {
        address.locationCoordinatesDto = { latitude, longitude };
      }

      const instance = initializeMap({
        geofenceRangeInMinutes: get(geofence, 'type') === 'TIME' ? geofenceRangeInMinutes : 0,
        address,
        mapId: MAP_ID,
        mapInstance,
        updatelatLngInputValue,
        useAddressLatLngInMap,
      });
      instance
        .then((ref: any) => {
          setMapInstance(ref);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([address, mapInstance, props.data.address, userEnteredAddress, latLngInputValue]));

  React.useEffect(() => {
    function fetchLatLng() {
      try {
        const { geofenceLatitude, geofenceLongitude } = HereMapsBehavior.getCustomGeofenceCoordinates(
          get(state.customGeofence, 'geometry.coordinates', [])
        );
        const { latitude: lat = 0, longitude: lng = 0 } = props.isEditMode
          ? getLatLngFromInputValue(latLngInputValue)
          : getLatLngFromInputValue(`${geofenceLatitude}, ${geofenceLongitude}`);
        const distanceValueByUnits =
          props.defaultDistanceUnits === DistanceUnitsEnum.MILES
            ? state.distanceBasedValue * 1609.344
            : state.distanceBasedValue;
        const circle = addRadiusBasedGeofenceToCurrentMap(mapInstance, distanceValueByUnits, true, { lat, lng });
        handleCustomGeofenceCoords(parse(circle.getCenter().toString()));
      } catch (error) {
        console.error(error);
      }
    }

    if (state.distanceBasedValue > 0 && has(mapInstance, 'mapData') && state.fenceType === FenceType.DISTANCE) {
      removeAllGeofences({ mapInstance });
      fetchLatLng();
    }
  }, [
    state.distanceBasedValue,
    state.customGeofence,
    state.fenceType,
    address,
    mapInstance,
    handleCustomGeofenceCoords,
    props.defaultDistanceUnits,
    latLngInputValue,
    props.isEditMode,
  ]);

  React.useEffect(() => {
    if (state.timeBasedValue > 0 && has(mapInstance, 'mapData') && state.fenceType === FenceType.TIME) {
      removeAllGeofences({ mapInstance });
      const { latitude, longitude } = getLatLngFromInputValue(latLngInputValue);
      if (!useAddressLatLngInMap && latitude && longitude) {
        (address || {}).locationCoordinatesDto = { latitude, longitude };
      }
      const instance = initializeMap({
        geofenceRangeInMinutes: state.timeBasedValue,
        address,
        mapId: MAP_ID,
        mapInstance,
        updatelatLngInputValue,
        useAddressLatLngInMap,
      });
      instance
        .then((ref: any) => {
          setMapInstance(ref);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  }, [
    state.timeBasedValue,
    state.fenceType,
    address,
    mapInstance,
    useAddressLatLngInMap,
    updatelatLngInputValue,
    latLngInputValue,
  ]);

  React.useEffect(
    function addMapObjectsEffect() {
      if (state.fenceType === FenceType.TIME && state.timeBasedValue === 0 && has(mapInstance, 'mapData')) {
        removeAllGeofences({ mapInstance });
        const { latitude, longitude } = getLatLngFromInputValue(latLngInputValue);
        if (!useAddressLatLngInMap && latitude && longitude) {
          (address || {}).locationCoordinatesDto = { latitude, longitude };
        }
        const instance = initializeMap({
          geofenceRangeInMinutes: props.geofenceRangeInMinutes,
          address,
          mapId: MAP_ID,
          mapInstance,
          updatelatLngInputValue,
          useAddressLatLngInMap,
        });
        instance
          .then((ref) => {
            setMapInstance(ref);
          })
          .catch((error: Error) => {
            console.error(error);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompareMemoize([
      state.timeBasedValue,
      state.fenceType,
      address,
      props.geofenceRangeInMinutes,
      props.isEditMode,
    ])
  );

  React.useEffect(function addCustomMapObjectEffect() {
    if (
      !isEmpty(mapInstance) &&
      !isNil(get(state, 'customGeofence.geometry.type')) &&
      get(state, 'customGeofence.geometry.type') !== GeometryTypes.POINT &&
      state.fenceType === FenceType.POLYGON
    ) {
      removeAllGeofences({ mapInstance });
      addPolygonGeofenceToCurrentMap(mapInstance, get(state, 'customGeofence'), true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([state.customGeofence, state.fenceType, props.isEditMode, mapInstance]));

  React.useEffect(
    function handleInitialGeofenceTypeEffect() {
      if (!isNil(geofence.type)) {
        // dispatch({ type: 'SET_TYPE', payload: FenceType[geofence.type] as FenceType });
        dispatch({ type: 'CLOSE_POLYGON', payload: false });
      }
    },
    [geofence.type]
  );

  React.useEffect(
    function handleChangeFenceTypeEffect() {
      if (geofence.type === FenceType.TIME) {
        dispatch({ type: 'SET_TYPE', payload: FenceType.TIME });
        dispatch({
          type: 'SET_TIME_VALUE',
          payload: parseInt(get(geofence, 'value', 1), 10),
        });
      }

      if (geofence.type === FenceType.DISTANCE) {
        dispatch({ type: 'SET_TYPE', payload: FenceType.DISTANCE });
        dispatch({ type: 'SET_DISTANCE_VALUE', payload: geofence.value });
      }

      if (geofence.type === FenceType.POLYGON) {
        dispatch({ type: 'SET_TYPE', payload: FenceType.POLYGON });
        dispatch({ type: 'SET_CUSTOM_GEOFENCE', payload: geofence });
      }

      return () => {
        dispatch({ type: 'RESET_VALUES' });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [geofence.timeValue, geofence.distanceValue, geofence.type, geofence.value]
  );

  React.useEffect(
    function resetMapToDefaultsEffect() {
      if (isResetting) {
        props.setFieldValue('fenceType', state.fenceType);
        if (geofence.type === FenceType.TIME) {
          dispatch({ type: 'SET_TYPE', payload: FenceType.TIME });
          dispatch({
            type: 'SET_TIME_VALUE',
            payload: parseInt(get(geofence, 'value', 1), 10),
          });
          props.setFieldValue('timeValue', geofence.value);
        }

        if (geofence.type === FenceType.DISTANCE) {
          dispatch({ type: 'SET_TYPE', payload: FenceType.DISTANCE });
          dispatch({ type: 'SET_DISTANCE_VALUE', payload: geofence.value });
          props.setFieldValue('distanceValue', geofence.value);
        }

        if (geofence.type === FenceType.POLYGON) {
          dispatch({ type: 'SET_TYPE', payload: FenceType.POLYGON });
          dispatch({ type: 'SET_CUSTOM_GEOFENCE', payload: geofence });
        }

        toggleIsResetting(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.isResetting, geofence.type, geofence.value, props, isResetting, toggleIsResetting, state.fenceType]
  );

  const anime = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 300,
  });
  return (
    <>
      <animated.div style={anime} className={classNames(styles.LocationMap, 'has-shadow')}>
        <div ref={mapElement} id={MAP_ID} style={{ ...customStyles, display: isChinaAsCountry ? 'none' : 'block' }} />
        {isChinaAsCountry && (
          <BaiduLocationMap
            defaultDistanceUnits={props.defaultDistanceUnits}
            latLng={getLatLngFromInputValue(latLngInputValue)}
            state={state}
          />
        )}
        {props.isEditMode && (
          <Button
            clickFn={() => setIsEditGeofenceButtonVisible(!isEditGeofenceButtonVisible)}
            className={styles.editGeofenceButton}
          >
            <FormattedMessage defaultMessage="Edit Geofence" id="locationDetails.edit.editGeofence" />
          </Button>
        )}
        {isChinaAsCountry && state.fenceType === FenceType.TIME && (
          <div style={{ marginTop: 8, fontWeight: 700 }}>
            <FormattedMessage
              defaultMessage="Please note that time-based geofences are not depicted on the map below for China. This does not impact the creation of the geofence."
              id="locationDetails.edit.baiduMinutesHelpText"
            />
          </div>
        )}
      </animated.div>
      {props.isEditMode && isEditGeofenceButtonVisible && (
        <EditLocationMap
          geofenceRangeInMinutes={state.timeBasedValue}
          userEnteredAddress={userEnteredAddress}
          visible={isEditGeofenceButtonVisible}
          mapConfig={mapConfig}
          geofence={state.customGeofence}
          locationMapState={state}
          toggleOpen={() => setIsEditGeofenceButtonVisible(!isEditGeofenceButtonVisible)}
          address={get(props, 'data.address')}
          setFieldValue={props.setFieldValue}
          handleChange={props.handleChange}
          locationMapDispatch={dispatch}
          distanceValue={get(formProps, 'values.distanceValue')}
          timeValue={get(formProps, 'values.timeValue', 15)}
          initialTimeValue={get(formProps, 'initialValues.timeValue')}
          initialDistanceValue={get(formProps, 'initialValues.distanceValue')}
          initialGeofenceValue={get(formProps, 'initialValues.geofence')}
          handleCustomGeofenceCoords={handleCustomGeofenceCoords}
          toggleIsResetting={toggleIsResetting}
          formErrors={formProps.errors}
          analyticsData={props.analyticsData}
          defaultDistanceUnits={props.defaultDistanceUnits}
          latLngInputValue={latLngInputValue}
          latLng={getLatLngFromInputValue(latLngInputValue)}
          isChinaAsCountry={isChinaAsCountry}
          useAddressLatLngInMap={useAddressLatLngInMap}
        />
      )}
    </>
  );
};

export default LocationMap;
