import classNames from 'classnames';
import { Popover } from 'ui-components';
import styled from 'styled-components';
import './Stops.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';
import { formatNestedIntlValues } from 'i18n/utils';
import MapMarker from '../MapMarker/MapMarker';

const PopoverStatusDot = styled.div`
  &:hover {
    &:after {
      background-color: ${(props) => `var(--${props.backgroundColor})`};
    }
  }
`;
const Stops = (props) => {
  const intl = useIntl();
  const bgColor = props.color || 'primary-grey-80';
  return (
    <div className="shipment-stops">
      <div className="shipment-stops__line" />
      <div
        className="shipment-stops__line-colored"
        style={{ width: `${props.progress.markerCompletion}%`, backgroundColor: `var(--${bgColor})` }}
      />
      {props.progress.displayMarker && (
        <MapMarker fill={props.progress.markerColor} leftValue={`${props.progress.markerCompletion}%`} />
      )}
      <div className="shipment-stops__stops" data-locator="shipment-stops">
        {props.stops.map((stop) => {
          const completedFillColor = stop.isCompleted ? { backgroundColor: `var(--${bgColor})` } : null;

          if (stop.popover) {
            return (
              <div
                key={`${stop.cityState}${stop.tertiaryText}${stop.label}`}
                className={classNames('shipment-stop', 'shipment-stop__popover', {
                  invisible: !stop.isVisible,
                })}
              >
                <Popover content={renderStop(stop, intl)} trigger="hover" placement="bottom">
                  <PopoverStatusDot
                    backgroundColor={bgColor}
                    className={'shipment-stop__status-dot'}
                    style={completedFillColor}
                  />
                </Popover>
              </div>
            );
          }
          return (
            <div
              key={`${stop.cityState}${stop.tertiaryText}${stop.label}`}
              className={stop.isVisible ? 'shipment-stop' : 'shipment-stop invisible'}
              style={stop.cssStyle}
            >
              <div className={'shipment-stop__status-dot'} style={completedFillColor} />
              {renderStop(stop, intl)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const renderStop = (stop, intl) => {
  return (
    <div className="shipment-stop__info">
      <div className="shipment-stop__name">{stop.label}</div>
      <div className="shipment-stop__location">{stop.cityState}</div>
      {get(stop, 'tertiaryText.id') && (
        <div className="shipment-stop__tertiary">
          <FormattedMessage
            id={stop.tertiaryText.id}
            defaultMessage={stop.tertiaryText.defaultMessage}
            values={formatNestedIntlValues(get(stop, 'tertiaryText.values', {}), intl)}
          />
        </div>
      )}
    </div>
  );
};

export default Stops;
