/**
 * Copied from MWA
 * @see https://github.com/project44/micro-webapps/blob/master/libs/ui-utils/src/utils/browser.ts
 */

export type BrowserEngine = 'chrome' | 'safari' | 'firefox';

/**
 * Map browserName to user-agent regexp.
 * Based on https://github.com/DamonOehlman/detect-browser/blob/master/src/index.ts#L134
 */
const userAgentRegexp: [BrowserEngine, RegExp][] = [
  // Chrome must be before Safari, because it identifies itself with the same
  // version tag as Safari for backwards compatibility.
  ['chrome', /Chrome\/[0-9.]+(?:\s|$)/],
  ['safari', /Safari\/[0-9.]+(?:\s|$)/],
  ['firefox', /Firefox\/[0-9.]+(?:\s|$)/],
];

/**
 * Returns current browser type of engine
 * Chrome-like browers are idenrified as 'chrome'
 * No distinction between desktop and mobile browsers.
 */
export function getBrowserEngine(): BrowserEngine | undefined {
  return userAgentRegexp.find(([, regexp]) => regexp.test(window.navigator.userAgent))?.[0];
}

/**
 * Attempts to indentify user-defined browser zoom (CMD/CTRL + '+'/'-')
 *
 * Can't use window.devicePixelRatio, because it is normally higher on high
 * density screens, making it undistinguisable from a user-defined zoom.
 *
 * @returns `undefined` or number representing percentage
 */
export function getBrowserZoom(): number | undefined {
  switch (getBrowserEngine()) {
    case 'chrome':
    case 'safari':
      return Math.round((window.outerWidth / window.innerWidth) * 100);
    case 'firefox': // No known method available
    default:
      return undefined;
  }
}
