import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Timeline from 'antd/lib/timeline';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import moment from 'moment';
import { Tags, Tabs } from 'ui-components';

import { LONG_MONTH_DAY_YEAR_FORMAT } from 'i18n/configurei18n';
import { ComponentErrorProps } from 'components/common/errorBoundary/models';
import { API_PATH } from 'common/AppConstants';
import { InventoryOrder } from 'components/Inventory/models';
import { white, lightBlue, primaryGreyEighty } from 'styles/colors';
import axios from 'util/paxios';
import InventoryItemsTable from 'components/Inventory/InventoryItemsTable';
import { trackEvent } from 'common/eventTracker';
import DetailsTab from './DetailsTab';
import ShipmentsTableComponent from '../newShipmentDetails/ShipmentsTableComponent';
import RelatedOrdersTable from './RelatedOrdersTable';

const StyledSummary = styled('div')`
  height: 100%;
  width: 100%;
  padding-top: 20px;
  background-color: ${white};
  position: relative !important;
  left: 0;
  top: 3px;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
`;

const OrderDetails: React.FC<RouteComponentProps<{ orderId: string }>> = (props) => {
  const [orderDetails, setOrderDetails] = React.useState<InventoryOrder>();
  const [componentError, setComponentError] = React.useState<ComponentErrorProps>({ errorsList: [] });

  const handleFetchErrors = React.useCallback(
    function handleFetchErrorsCallback(key: string, error: any, errorIsNew: boolean) {
      if (error.response && errorIsNew) {
        const errorsArray =
          error.response.status === 404 ? [{ message: error.response.data.message }] : error.response.data.errors;

        const errorsList = [
          ...componentError.errorsList,
          {
            error: errorsArray.map((err: any) => err.message),
            supportRefId: error.response.data.supportReferenceId,
            id: key,
          },
        ];

        setComponentError({ errorsList });
      }
    },
    [componentError.errorsList]
  );

  React.useEffect(() => {
    const errorKey = 'inventory-load-orders';
    const previousErrorIndex = componentError.errorsList.find((elem) => elem.id === errorKey);

    axios
      .get(`${API_PATH}/inventory/orders/id/${props.match.params.orderId}`, { withCredentials: true })
      .then((res) => {
        setOrderDetails(res.data);
      })
      .catch((error) => {
        handleFetchErrors(errorKey, error, !previousErrorIndex);
        return error;
      });
  }, [componentError.errorsList, handleFetchErrors, props.match.params.orderId]);

  React.useEffect(() => {
    if (orderDetails) {
      trackEvent('ORDERS_DETAIL_PAGE_LOAD', {
        id: orderDetails.id || '',
        orderIdentifier: orderDetails.orderIdentifier || '',
        orderType: orderDetails.orderType || '',
      });
    }
  }, [orderDetails]);

  return (
    <Container fluid>
      <Row style={{ minHeight: '100vh' }}>
        <Col lg={5} md={9} style={{ position: 'relative', left: '-15px', maxWidth: '300px' }}>
          <div style={{ height: '100%' }}>
            <StyledSummary>
              <Container>
                <Row style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
                  <Col md={24}>
                    <section>
                      <div className="mb-8">
                        <Tags color={primaryGreyEighty}>{get(orderDetails, 'statusCode')}</Tags>
                      </div>
                      <h1 className="break-all whitespace-normal" style={{ fontSize: '24px' }}>
                        <FormattedMessage
                          id="inventory.ordersDetails.summary.header"
                          defaultMessage="Order {orderId}"
                          values={{ orderId: get(orderDetails, 'orderIdentifier') }}
                        />
                        <br />
                        <small className="opacity-50 text-2xl font-normal">
                          {get(orderDetails, 'vendorLocation.contact.companyName')}
                        </small>
                      </h1>
                    </section>
                  </Col>
                </Row>
                <Row className="py-10" style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
                  <Col md={24}>
                    <section>
                      <h3>
                        <FormattedMessage
                          id="inventory.ordersDetails.summary.routeDestination"
                          defaultMessage="Route Destination"
                        />
                      </h3>
                      <span className="opacity-50 text-2xl font-normal">
                        {get(orderDetails, 'destinationLocation.contact.companyName', '')}
                        {orderDetails?.destinationLocation && orderDetails?.destinationLocation?.suppliedId && (
                          <>
                            <br />
                            ID: {get(orderDetails, 'destinationLocation.suppliedId', '')}
                          </>
                        )}
                        <br />
                        <address className="opacity-50 text-2xl font-normal mt-10">
                          {get(orderDetails, 'destinationLocation.contact.contactName', '')}
                          <br />
                          {get(orderDetails, 'destinationLocation.address.addressLines[0]', '')}
                          <br />
                        </address>
                      </span>
                    </section>
                  </Col>
                </Row>
                <Row className="py-10" style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
                  <Col md={24}>
                    <section>
                      <Timeline>
                        <Timeline.Item color={lightBlue}>
                          <p>
                            <FormattedMessage id="inventory.orderDetails.submitted" defaultMessage="Submitted" />
                          </p>
                          <p className="opacity-50">
                            {get(orderDetails, 'orderSubmissionDateTime') ? (
                              moment(get(orderDetails, 'orderSubmissionDateTime')).format(LONG_MONTH_DAY_YEAR_FORMAT)
                            ) : (
                              <FormattedMessage
                                id="inventory.orderDetails.noDateProvided"
                                defaultMessage="No Date Provided"
                              />
                            )}
                          </p>
                        </Timeline.Item>

                        <Timeline.Item color={lightBlue}>
                          <p>
                            <FormattedMessage
                              id="inventory.orderDetails.supplierReadyDate"
                              defaultMessage="Supplier Ready Date"
                            />
                          </p>
                          <p className="opacity-50">
                            {get(orderDetails, 'supplierReadyDateTimeWindow.startDateTime') ? (
                              moment(get(orderDetails, 'supplierReadyDateTimeWindow.startDateTime')).format(
                                LONG_MONTH_DAY_YEAR_FORMAT
                              )
                            ) : (
                              <FormattedMessage
                                id="inventory.orderDetails.noDateProvided"
                                defaultMessage="No Date Provided"
                              />
                            )}
                          </p>
                        </Timeline.Item>

                        <Timeline.Item color={lightBlue}>
                          <p>
                            <FormattedMessage
                              id="inventory.orderDetails.originalDeliveryDate"
                              defaultMessage="Original Delivery Date"
                            />
                          </p>
                          <p className="opacity-50">
                            {get(orderDetails, 'originalDeliveryDateTimeWindow.endDateTime') ? (
                              moment(get(orderDetails, 'originalDeliveryDateTimeWindow.endDateTime')).format(
                                LONG_MONTH_DAY_YEAR_FORMAT
                              )
                            ) : (
                              <FormattedMessage
                                id="inventory.orderDetails.noDateProvided"
                                defaultMessage="No Date Provided"
                              />
                            )}
                          </p>
                        </Timeline.Item>
                      </Timeline>
                    </section>
                  </Col>
                </Row>
              </Container>
            </StyledSummary>
          </div>
        </Col>
        <Col lg={19} md={15}>
          <Container fluid className="my-10">
            <Row>
              <Col md={24}>
                {orderDetails && (
                  <Tabs
                    onChange={(key: string) => {
                      trackEvent('ORDERS_DETAIL_PAGE_TAB_CLICK', {
                        key,
                      });
                    }}
                    type="card"
                    defaultActiveKey="details"
                    panes={[
                      {
                        key: 'details',
                        tab: (
                          <FormattedMessage
                            defaultMessage="Details"
                            id="inventory.orderDetails.detailsTab.tabs.details"
                          />
                        ),
                      },
                      {
                        key: 'shipments',
                        tab: (
                          <FormattedMessage
                            defaultMessage="Shipments"
                            id="inventory.orderDetails.detailsTab.tabs.shipments"
                          />
                        ),
                      },
                      {
                        key: 'inventory',
                        tab: (
                          <FormattedMessage
                            defaultMessage="Inventory"
                            id="inventory.orderDetails.detailsTab.tabs.inventory"
                          />
                        ),
                      },
                      {
                        key: 'relatedOrders',
                        tab: (
                          <FormattedMessage
                            defaultMessage="Related Orders"
                            id="inventory.orderDetails.detailsTab.tabs.relatedOrders"
                          />
                        ),
                      },
                    ]}
                    tabcontent={{
                      details: <DetailsTab details={orderDetails} />,
                      ...(orderDetails.shipmentIds && {
                        shipments: <ShipmentsTableComponent masterShipmentIds={orderDetails.shipmentIds} />,
                      }),
                      ...(orderDetails.id && {
                        inventory: <InventoryItemsTable orderId={orderDetails.id} />,
                      }),
                      ...(orderDetails.id && {
                        relatedOrders: <RelatedOrdersTable inventoryOrderId={orderDetails.id} />,
                      }),
                    }}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderDetails;
