import { defineMessages } from 'react-intl';
import endpoints from '../../../../common/endpoints';
import { getApiDocUrl, getReferenceDataUrl, getSdkUrl } from '../../../../common/newApiDocUrls';

const message = defineMessages({
  developerResources: {
    id: 'common.footerpreview.links.footerLinksConfig.developerResources',
    defaultMessage: 'Developer Resources',
  },
  apiDocumentation: {
    id: 'common.footerpreview.links.footerLinksConfig.apiDocumentation',
    defaultMessage: 'Developer Portal',
  },
  referenceCodes: {
    id: 'common.footerpreview.links.footerLinksConfig.referenceCodes',
    defaultMessage: 'Reference Codes',
  },
  softwareDevelopment: {
    id: 'common.footerpreview.links.footerLinksConfig.softwareDevelopment',
    defaultMessage: 'Software Development Kits (SDKs)',
  },
  p44Administration: {
    id: 'common.footerpreview.links.footerLinksConfig.p44Administration',
    defaultMessage: 'p44 Administration',
  },
  customerManagement: {
    id: 'common.footerpreview.links.footerLinksConfig.customerManagement',
    defaultMessage: 'Customer Management',
  },
  tenantManagement: {
    id: 'common.footerpreview.links.footerLinksConfig.tenantManagement',
    defaultMessage: 'Tenant Management',
  },
  supportedVendors: {
    id: 'common.footerpreview.links.footerLinksConfig.supportedVendors',
    defaultMessage: 'Supported Vendors',
  },
  viewLegacyDocs: {
    id: 'common.footerpreview.links.footerLinksConfig.viewLegacyDocs',
    defaultMessage: 'View legacy UI (v2)',
  },
});

const generateLinks = (principalContext) => [
  {
    groupName: message.developerResources,
    languageId: 'footer.footerLinksConfig.developerResources',
    links: [
      {
        linkText: message.apiDocumentation,
        endpoint: getApiDocUrl(principalContext),
        permissions: [],
        languageId: 'footer.footerLinksConfig.apiDocumentation',
      },
      {
        linkText: message.referenceCodes,
        endpoint: getReferenceDataUrl(principalContext),
        permissions: [],
        languageId: 'footer.footerLinksConfig.referenceCodes',
      },
      {
        linkText: message.softwareDevelopment,
        endpoint: getSdkUrl(principalContext),
        permissions: [],
        languageId: 'footer.footerLinksConfig.sdk',
      },
      {
        linkText: message.viewLegacyDocs,
        endpoint: endpoints.LEGACY_UI,
        permissions: ['PORTAL_V1_ACCESS'],
        languageId: 'footer.footerLinksConfig.viewLegacyUI',
      },
    ],
  },
  {
    groupName: message.p44Administration,
    languageId: 'footer.footerLinksConfig.p44Administration',
    links: [
      {
        linkText: message.tenantManagement,
        endpoint: endpoints.CUSTOMER_MANAGEMENT,
        permissions: ['system-admin:search:tenants'],
        languageId: 'footer.footerLinksConfig.tenantManagement',
      },
      {
        linkText: message.supportedVendors,
        endpoint: endpoints.SUPPORTED_VENDORS,
        permissions: ['ROLE_P44ADMIN'],
        languageId: 'footer.footerLinksConfig.supportedVendors',
      },
    ],
  },
];

export default generateLinks;
