import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { Input, Radio, SVGIcon, Switch } from 'ui-components';
import { Nullable } from 'typesUtils';

import * as styles from './styles.module.scss';

import { TNumberFormat } from '../types';

interface RangeInputFieldProps {
  onChange?: (value: number) => void;
  value: Nullable<number>;
  numberFormat?: TNumberFormat;
}

const RangeInputField = ({ onChange, value, numberFormat = 'PERCENTAGE' }: RangeInputFieldProps) => {
  const handleChange = (event: any) => {
    const nextValue = event.target.value.replace(/(,|%)/, '');

    if (nextValue) {
      onChange?.(parseFloat(nextValue));
    }
  };
  const isPercentage = numberFormat === 'PERCENTAGE';

  const customProps = isPercentage ? { suffix: '%', decimalScale: 1, fixedDecimalScale: true } : { decimalScale: 0 };

  return (
    <NumberFormat
      isv2="true"
      className={styles['score-input']}
      value={value}
      onChange={handleChange}
      allowNegative={false}
      customInput={Input}
      thousandSeparator
      {...customProps}
    />
  );
};

interface RangeInputProps {
  lowLimit: Nullable<number>;
  highLimit: Nullable<number>;
  onChange?: (lowLimit: Nullable<number>, highLimit: Nullable<number>) => void;
  onBlur?: any;
  onSwapLimits?: () => void;
  numberFormat: TNumberFormat;
}

const RangeInput = ({ lowLimit, highLimit, onChange, onSwapLimits, numberFormat }: RangeInputProps) => {
  const isMiddleRange = highLimit && lowLimit;
  const isLower = !!lowLimit;

  return isMiddleRange ? (
    <div className="d-flex align-items-center">
      <RangeInputField
        value={lowLimit}
        onChange={(value: number) => onChange?.(value, highLimit)}
        numberFormat={numberFormat}
      />
      <span className="mx-4">
        <FormattedMessage id="quickViewConfig.healthRanges.rangeInput.to" defaultMessage="to" />
      </span>
      <RangeInputField
        value={highLimit}
        onChange={(value: number) => onChange?.(lowLimit, value)}
        numberFormat={numberFormat}
      />
    </div>
  ) : (
    <div className="d-flex align-items-center">
      <Radio
        value={isLower ? 'LOWER' : 'HIGHER'}
        onChange={onSwapLimits}
        radioData={[
          {
            value: 'HIGHER',
            label: <FormattedMessage id="quickViewConfig.healthRanges.limitType.higher" defaultMessage="Higher" />,
          },
          {
            value: 'LOWER',
            label: <FormattedMessage id="quickViewConfig.healthRanges.limitType.lower" defaultMessage="Lower" />,
          },
        ]}
      />
      <span className="mr-4">
        <FormattedMessage id="quickViewConfig.healthRanges.limitInput.than" defaultMessage="than" />
      </span>
      <RangeInputField
        value={isLower ? lowLimit : highLimit}
        numberFormat={numberFormat}
        onChange={(value: number) => {
          if (isLower) {
            onChange?.(value, highLimit);
          } else {
            onChange?.(lowLimit, value);
          }
        }}
      />
    </div>
  );
};

interface HealthRangeProps {
  onChange?: (lowLimit: Nullable<number>, highLimit: Nullable<number>) => void;
  onSwapLimits?: () => void;
  onToggle?: (isEnabled: boolean) => void;
  lowLimit: Nullable<number>;
  highLimit: Nullable<number>;
  isActive?: boolean;
  isDisabled?: boolean;
  iconName: string;
  textName: string;
  accentColor?: 'green' | 'blue' | 'orange' | 'red';
  numberFormat: TNumberFormat;
}

const HealthRange = ({
  onChange,
  onSwapLimits,
  onToggle,
  highLimit,
  lowLimit,
  isActive = false,
  isDisabled = false,
  iconName,
  textName,
  accentColor,
  numberFormat,
}: HealthRangeProps) => {
  return (
    <div className={cn('d-flex align-items-center justify-content-between py-3', styles.container)}>
      <div className={cn('d-flex align-items-center', styles['title-section'], styles[`text-${accentColor}`])}>
        <div className="d-flex align-items-center flex-1">
          <SVGIcon name={iconName} className="mr-2" />
          <span className={styles['title']}>{textName}</span>
        </div>
        <Switch checked={isActive} disabled={isDisabled && isActive} onChange={onToggle} />
      </div>
      {isActive && (
        <RangeInput
          lowLimit={lowLimit}
          highLimit={highLimit}
          onSwapLimits={onSwapLimits}
          onChange={onChange}
          numberFormat={numberFormat}
        />
      )}
    </div>
  );
};

export default HealthRange;
