import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import './AddEditShipmentSummary.scss';
import { reduxForm } from 'redux-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { HOURS_MINUTES_FORMAT, MONTH_DAY_YEAR_FORMAT } from 'i18n/configurei18n';
import { renderApiErrors } from 'components/common/forms/FormErrors';
import AddEditShipmentButtons from '../AddEditShipmentButtons/AddEditShipmentButtons';
import CarrierType from '../../shipment/common/enums/carrierType';
const moment = require('moment');

const message = defineMessages({
  order: {
    id: 'addShipment.addEditShipmentSummary.order',
    defaultMessage: 'ORDER# {shipmentOrder}',
  },
  licencePlate: {
    id: 'addShipment.addEditShipmentSummary.licencePlate',
    defaultMessage: 'License Plate: {licensePlateNumber}',
  },
  driverCellPhone: {
    id: 'addShipment.addEditShipmentSummary.driverCellPhone',
    defaultMessage: 'Driver Cell Phone: {driverMobileNumber}',
  },
  carrierConnection: {
    id: 'addShipment.addEditShipmentSummary.carrierConnection',
    defaultMessage: 'Carrier Connection',
  },
  trackingInformation: {
    id: 'addShipment.addEditShipmentSummary.trackingInformation',
    defaultMessage: 'Tracking Information',
  },
  trackingMethod: {
    id: 'addShipment.addEditShipmentSummary.trackingMethod',
    defaultMessage: 'Tracking Method',
  },
  bol: {
    id: 'addShipment.addEditShipmentSummary.bol',
    defaultMessage: 'BOL# {bolString}',
  },
  eld: {
    id: 'addShipment.addEditShipmentSummary.eldTruck',
    defaultMessage: 'ELD: {truckNumber}',
  },
});

const addShipmentErrorHeading = 'add this shipment';
const renderCarrierInfo = (initialValues) => get(initialValues, 'carrierInfo.name');

const formatShipmentIdentifiers = (formValues, intl) => {
  const messages = [];

  if (formValues.shipmentBOL) {
    messages.push(intl.formatMessage(message.bol, { bolString: formValues.shipmentBOL }));
  }
  if (formValues.shipmentOrder) {
    messages.push(intl.formatMessage(message.order, { shipmentOrder: formValues.shipmentOrder }));
  }

  return messages.join(' | ');
};

const formatTrackingMethod = (initialValues, formValues, intl) => {
  let summaryText = '';
  if (formValues.driverMobileNumber) {
    summaryText = intl.formatMessage(message.driverCellPhone, { driverMobileNumber: formValues.driverMobileNumber });
  }

  if (formValues.licensePlateNumber) {
    if (formValues.driverMobileNumber) {
      summaryText = summaryText + ' - ';
    }
    summaryText =
      summaryText + intl.formatMessage(message.licencePlate, { licensePlateNumber: formValues.licensePlateNumber });
  }

  if (formValues.truckNumber) {
    if (formValues.driverMobileNumber || formValues.licensePlateNumber) {
      summaryText = summaryText + ' - ';
    }
    summaryText = summaryText + intl.formatMessage(message.eld, { truckNumber: formValues.truckNumber });
  }

  return summaryText;
};

const addTemperatureTrackingInfo = (initialValues, formValues) => {
  if (!initialValues.carrierInfo.canRefrigerate || !formValues.tempTrackingEnabled) {
    return null;
  }

  return (
    <div className="tracking-details-row">
      <div className="tracking-details-label">
        <FormattedMessage id="addShipment.addEditShipmentSummary.tempTracking" defaultMessage="Temp Tracking" />
      </div>
      <div className="tracking-details-description">
        <span className="temperature-lower-bound">{formValues.temperatureForm.lowerBound}&#176;</span>&nbsp; | &nbsp;
        <span className="temperature-target-temp">{formValues.temperatureForm.targetTemp}&#176;</span>&nbsp; | &nbsp;
        <span className="temperature-upper-bound">{formValues.temperatureForm.upperBound}&#176;</span>&nbsp;{' '}
        {formValues.temperatureForm.temperatureScale}&#176;
      </div>
    </div>
  );
};

const renderTrackingSection = (initialValues, formValues, intl) => {
  const carrierIdentifiers = get(initialValues.carrierInfo, 'typeIdentifiers', []);
  let trackingLabel = intl.formatMessage(message.trackingMethod);

  if (!isEmpty(carrierIdentifiers) && carrierIdentifiers[0].type === CarrierType.EU) {
    trackingLabel = intl.formatMessage(message.trackingInformation);
  }

  return (
    <div className="tracking-details-row">
      <div className="tracking-details-label">{trackingLabel}</div>
      <div className="tracking-details-description">{formatTrackingMethod(initialValues, formValues, intl)}</div>
    </div>
  );
};

const AddEditShipmentSummary = (props) => {
  const { handleSubmit, formValues, initialValues, intl } = props;

  return (
    <form onSubmit={handleSubmit} className="shipment-summary">
      <h4 className="form-header">
        <FormattedMessage id="addShipment.addEditShipmentSummary.summary" defaultMessage="Summary" />
      </h4>
      <div className="tracking-heading">{formatShipmentIdentifiers(formValues, intl)}</div>
      <div className="tracking-carrier">{renderCarrierInfo(initialValues)}</div>
      <div className="tracking-details">
        {renderTrackingSection(initialValues, formValues, intl)}
        {addTemperatureTrackingInfo(initialValues, formValues)}

        <div className="tracking-details-row">
          <div className="tracking-details-label">
            <FormattedMessage id="addShipment.addEditShipmentSummary.pickup" defaultMessage="Pickup" />
          </div>
          <div className="tracking-details-description">
            <p className="tracking-subheading">{props.formValues.pickupForm.company}</p>
            <p className="tracking-subtitle">
              <FormattedMessage
                id="addShipment.addEditShipmentSummary.appointmentWindow"
                defaultMessage="Appointment Window"
              />
            </p>
            <p className="tracking-details">
              <span>{moment(props.formValues.pickupForm.startDate).format(MONTH_DAY_YEAR_FORMAT)}</span>{' '}
              <FormattedMessage id="addShipment.addEditShipmentSummary.at" defaultMessage="at" />{' '}
              <span>{moment(props.formValues.pickupForm.startTime, 'H:mm').format(HOURS_MINUTES_FORMAT)}</span>
              &nbsp; - <span>{moment(props.formValues.pickupForm.endDate).format(MONTH_DAY_YEAR_FORMAT)}</span>{' '}
              <FormattedMessage id="addShipment.addEditShipmentSummary.at" defaultMessage="at" />{' '}
              <span>{moment(props.formValues.pickupForm.endTime, 'H').format(HOURS_MINUTES_FORMAT)}</span>
            </p>
          </div>
        </div>
        {props.formValues.addStops[0].company && (
          <div className="shipment-stops">
            {props.formValues.addStops.map((stop, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="tracking-details-row" key={index}>
                <div className="tracking-details-label">
                  <FormattedMessage id="addShipment.addEditShipmentSummary.stop" defaultMessage="Stop" />
                </div>
                <div className="tracking-details-description">
                  <p className="tracking-subheading">{stop.company}</p>
                  <p className="tracking-subtitle">
                    <FormattedMessage
                      id="addShipment.addEditShipmentSummary.appointmentWindow"
                      defaultMessage="Appointment Window"
                    />
                  </p>
                  <p className="tracking-details">
                    <span>{moment(stop.startDate).format(MONTH_DAY_YEAR_FORMAT)}</span>{' '}
                    <FormattedMessage id="addShipment.addEditShipmentSummary.at" defaultMessage="at" />{' '}
                    <span>{moment(stop.startTime, 'HH:mm').format(HOURS_MINUTES_FORMAT)}</span>
                    &nbsp; - <span>{moment(stop.endDate).format(MONTH_DAY_YEAR_FORMAT)}</span>{' '}
                    <FormattedMessage id="addShipment.addEditShipmentSummary.at" defaultMessage="at" />{' '}
                    <span>{moment(stop.endTime, 'HH:mm').format(HOURS_MINUTES_FORMAT)}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="tracking-details-row">
          <div className="tracking-details-label">
            <FormattedMessage id="addShipment.addEditShipmentSummary.delivery" defaultMessage="Delivery" />
          </div>
          <div className="tracking-details-description">
            <p className="tracking-subheading">{props.formValues.dropOffForm.company}</p>
            <p className="tracking-subtitle">
              <FormattedMessage
                id="addShipment.addEditShipmentSummary.appointmentWindow"
                defaultMessage="Appointment Window"
              />
            </p>
            <p className="tracking-details">
              <span>{moment(props.formValues.dropOffForm.startDate).format(MONTH_DAY_YEAR_FORMAT)}</span>{' '}
              <FormattedMessage id="addShipment.addEditShipmentSummary.at" defaultMessage="at" />{' '}
              <span>{moment(props.formValues.dropOffForm.startTime, 'HH:mm').format(HOURS_MINUTES_FORMAT)}</span>
              &nbsp; - <span>{moment(props.formValues.dropOffForm.endDate).format(MONTH_DAY_YEAR_FORMAT)}</span>{' '}
              <FormattedMessage id="addShipment.addEditShipmentSummary.at" defaultMessage="at" />{' '}
              <span>{moment(props.formValues.dropOffForm.endTime, 'HH:mm').format(HOURS_MINUTES_FORMAT)}</span>
            </p>
          </div>
        </div>
        {renderApiErrors(initialValues.errors, addShipmentErrorHeading)}
      </div>
      <AddEditShipmentButtons
        step={props.step}
        navigateStep={props.navigateStep}
        onSubmit={props.onSubmit}
        cancel={props.cancel}
      />
    </form>
  );
};

export default reduxForm({
  form: 'AddEditShipment',
  destroyOnUnmount: false,
})(injectIntl(AddEditShipmentSummary));
