import { Children as ReactChildren } from 'react';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import includes from 'lodash/includes';
import ReactAutosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

import './Autosuggest.scss';

const theme = {
  container: 'autosuggest__container',
  containerOpen: 'autosuggest__container--open',
  input: 'autosuggest__input',
  inputOpen: 'autosuggest__input--open',
  inputFocused: 'autosuggest__input--focused',
  suggestionsContainer: 'autosuggest__suggestions-container',
  suggestionsContainerOpen: 'autosuggest__suggestions-container--open',
  suggestionsList: 'autosuggest__suggestions-list',
  suggestion: 'autosuggest__suggestion suggestion-content text-tuna',
  suggestionFirst: 'autosuggest__suggestion--first',
  suggestionHighlighted: 'autosuggest__suggestion--highlighted',
  sectionContainer: 'autosuggest__section-container',
  sectionContainerFirst: 'autosuggest__section-container--first',
  sectionTitle: 'autosuggest__section-title',
};

const Autosuggest = ({
  currentValue,
  setCurrentValue,
  placeholder,
  suggestions,
  listData,
  handleAutocompleteUpdate,
  name,
}) => {
  const onChange = (event, { newValue }) => {
    if (!isUndefined(newValue)) {
      setCurrentValue(newValue);
    }
  };

  const renderSuggestion = (suggestion, { query }) => {
    const filterValue = get(suggestion, 'filterValue', suggestion);
    const value = suggestion.value || suggestion;
    const isSelected = includes(listData, filterValue);
    const matches = AutosuggestHighlightMatch(value, query);
    const parts = AutosuggestHighlightParse(value, matches);
    const className = `autosuggest__item ${isSelected ? 'is-selected' : ''}`;

    return (
      <div className={className}>
        {ReactChildren.toArray(
          parts.map((part) => {
            const highlight = get(part, 'highlight');
            const text = get(part, 'text');
            return highlight ? (
              <span key={text} className="font-weight-bold">
                {text}
              </span>
            ) : (
              text
            );
          })
        )}
      </div>
    );
  };

  const getSuggestionValue = (suggestion) => {
    const value = suggestion.value || suggestion;
    handleAutocompleteUpdate(value, name);
  };

  const inputProps = {
    placeholder,
    value: currentValue || '',
    onChange: onChange,
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    if (!isUndefined(value)) {
      setCurrentValue(value);
    }
  };

  return (
    <ReactAutosuggest
      suggestions={suggestions}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      theme={theme}
      alwaysRenderSuggestions
      focusInputOnSuggestionClick={false}
    />
  );
};

export default Autosuggest;
