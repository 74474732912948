import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { Dispatch } from 'redux';

import { buildSetSortFieldAction } from './ducks';
import types from './ducks/types';
import SortResultsComponent from './SortResultsComponent';
import * as SortUtils from './utils/sortUtils';

const recomposeComponent = compose(
  lifecycle({
    componentDidMount() {
      const {
        handleSortChange,
        sortFields,
        selectedSortFieldId,
        sortType,
        shipmentListFiltersUrlEnabled = false,
      } = this.props as any;
      if (!shipmentListFiltersUrlEnabled) {
        handleSortChange(types.SET_SORT_FIELDS, sortFields, selectedSortFieldId, sortType);
      }
    },
  })
)(SortResultsComponent as any);

const mapStateToProps = (state: any, ownProps: any) => {
  const { searchReducer, sortResultsReducer, filterReducer } = state;
  const { filter, modeFilters } = filterReducer;
  const { previousSearchQuery, searchTerm } = searchReducer;

  return {
    stateSelectedSortFieldId:
      SortUtils.getPreviousSelectedSortFieldId(previousSearchQuery, ownProps.sortFields) ||
      sortResultsReducer.selectedSortFieldId,
    filter,
    modes: modeFilters,
    search: searchTerm,
    location: state.router?.location.search,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleSortChange: (type: any, sortFields: any, sortFieldId: any, sortType: any) =>
    dispatch(buildSetSortFieldAction(type, sortFields, sortFieldId, sortType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(recomposeComponent as any) as any;
