import { fork } from 'redux-saga/effects';
import * as loginActions from './actions';
import {
  watchCheckSessionAsync as sessionOperations,
  watchRenewSessionAsync as renewOperations,
  watchLogoutAsync,
  watchPurgeAsync as sessionPurgeOperations,
} from './operations';
import * as loginTypes from './types';
import loginReducer from './loginReducer';
import authReducer from './authReducer';

function* logoutOperations() {
  yield fork(watchLogoutAsync);
}

export {
  loginActions,
  loginTypes,
  renewOperations,
  sessionOperations,
  logoutOperations,
  sessionPurgeOperations,
  loginReducer,
  authReducer,
};
