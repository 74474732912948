import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { defineMessages, createIntl, createIntlCache } from 'react-intl';
import { combineReducers } from 'redux';
import { locale, messages, useEUVerbiage } from 'i18n/configurei18n';
import { ShipmentModeEnum } from 'models';
import types from './types';

const cache = createIntlCache();
export const intl = createIntl({ locale, messages }, cache);

const message = defineMessages({
  ltlvltlState: {
    id: 'notifications.reducers.ltlvltlState',
    defaultMessage: 'LTL/VLTL',
  },
  ltlEUState: {
    id: 'notifications.reducers.ltlEUState',
    defaultMessage: 'Groupage',
  },
  truckloadState: {
    id: 'notifications.reducers.truckloadState',
    defaultMessage: 'TRUCKLOAD',
  },
  parcelState: {
    id: 'notifications.reducers.parcelState',
    defaultMessage: 'Parcel',
  },
  airState: {
    id: 'notifications.reducers.airState',
    defaultMessage: 'Air',
  },
  oceanState: {
    id: 'notifications.reducers.oceanState',
    defaultMessage: 'Ocean',
  },
});

const subscriptionOptions = {
  types: ['EMAIL', 'SMS'],
  values: {
    push: [],
  },
};

export const initialNotificationsState = () => {
  const state = {
    notifications: [],
    accessLevel: undefined,
    subscriptionOptions,
  };

  return state;
};

export const initialNotificationState = {
  accessLevel: undefined,
  locationSuggestions: [],
  carrierSuggestions: [],
  companySuggestions: [],
  subscriberSuggestions: [],
  subscriptionOptions,
  modeOptions: [
    {
      value: ShipmentModeEnum.TL,
      label: message.truckloadState,
    },
    {
      value: ShipmentModeEnum.LTL,
      label: useEUVerbiage ? message.ltlEUState : message.ltlvltlState,
    },
    {
      value: ShipmentModeEnum.PARCEL,
      label: message.parcelState,
    },
    {
      value: ShipmentModeEnum.AIR,
      label: message.airState,
    },
    {
      value: ShipmentModeEnum.OCEAN,
      label: message.oceanState,
    },
  ],
  initialValues: {
    title: '',
    mode: 'TL',
    notificationEvents: [
      {
        eventTypeKey: '',
        timeParameter: {
          value: 0,
          units: 'Minutes',
        },
        stopParameter: {
          type: '',
        },
        offsetDirection: '',
      },
    ],
    filters: {
      operator: 'And',
      fields: [
        {
          values: [],
          input: '',
          type: '',
        },
      ],
    },
    notificationRecipients: [
      {
        type: 'EMAIL',
        values: [],
        input: '',
      },
    ],
  },
};

const getActionErrors = (action) => get(action, 'errorDetails') || get(action, 'payload.errors');

const applyDefaults = (notification) => {
  if (notification && isEmpty(notification.notificationRecipients)) {
    return {
      ...notification,
      notificationRecipients: initialNotificationState.initialValues.notificationRecipients,
    };
  }
  return notification;
};

const applyDefaultsToList = (notifications) => {
  return map(notifications, (notification) => applyDefaults(notification));
};

const notificationsReducer = (state = initialNotificationsState(), action = {}) => {
  switch (action.type) {
    case types.UPDATE_MANAGE_NOTIFICATIONS_SHIPMENT_ACCESS_LEVEL_SUCCESS:
      return {
        ...state,
        accessLevel: getAccessLevelFromAction(action),
        shipmentId: getShipmentIdFromAction(action),
      };
    case types.GET_CONFIG_IDENTITIES_FAIL:
      return {
        ...state,
      };
    case types.GET_CONFIG_IDENTITIES_SUCCESS:
      return {
        ...state,
        subscriptionOptions: getPushIdentities(state.subscriptionOptions, action),
      };
    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: applyDefaultsToList(get(action.payload, 'notifications')),
        isLoading: false,
        hasError: false,
        accessLevel: getAccessLevelFromAction(action),
        shipmentId: getShipmentIdFromAction(action),
        errors: null,
      };
    case types.GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        notifications: [],
        hasError: true,
        errors: getActionErrors(action),
        isLoading: false,
      };
    // triggered on load - here we want to clear out any previous state and set a loading toggle
    case types.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
        hasError: false,
        isLoading: true,
      };
    case types.EXPAND_COLLAPSE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.id === action.id
            ? {
                ...notification,
                isExpanded: action.isExpanded,
              }
            : notification
        ),
      };

    default:
      return state;
  }
};

const getPushIdentities = (subscriptionOptions, action) => {
  let newSubscriptionOptions = cloneDeep(subscriptionOptions);

  if (action && action.payload && action.payload.values) {
    if (
      action.payload.values.length > 0 &&
      newSubscriptionOptions &&
      newSubscriptionOptions.values &&
      newSubscriptionOptions.values.push
    ) {
      newSubscriptionOptions.types = ['PUSH', 'EMAIL', 'SMS'];
      newSubscriptionOptions.values.push = action.payload.values;
      return newSubscriptionOptions;
    }
  }
  newSubscriptionOptions.values.push = [];
  newSubscriptionOptions.types = ['EMAIL', 'SMS'];
  return newSubscriptionOptions;
};

const getAccessLevelObjectFromAction = (action) =>
  get(action, 'payload.accessLevelObject') || get(action, 'accessLevelObject');

const getAccessLevelFromAction = (action) => get(getAccessLevelObjectFromAction(action), 'accessLevel');
const getShipmentIdFromAction = (action) => get(getAccessLevelObjectFromAction(action), 'shipmentId');

const notificationReducer = (state = initialNotificationState, action = {}) => {
  switch (action.type) {
    case types.UPDATE_NOTIFICATION_SHIPMENT_ACCESS_LEVEL_SUCCESS:
      return {
        ...state,
        accessLevel: getAccessLevelFromAction(action),
        shipmentId: getShipmentIdFromAction(action),
      };
    case types.GET_CONFIG_IDENTITIES_FAIL:
      return {
        ...state,
      };
    case types.GET_CONFIG_IDENTITIES_SUCCESS:
      return {
        ...state,
        subscriptionOptions: getPushIdentities(state.subscriptionOptions, action),
      };
    case types.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        initialValues: applyDefaults(get(action.payload, 'notification')),
        accessLevel: getAccessLevelFromAction(action),
        shipmentId: getShipmentIdFromAction(action),
        errors: null,
      };
    case types.GET_NOTIFICATION_FAIL:
      return {
        ...state,
        errors: action.errorDetails,
      };
    case types.SAVE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        initialValues: applyDefaults(get(action.payload, 'notification')),
        accessLevel: getAccessLevelFromAction(action),
        shipmentId: getShipmentIdFromAction(action),
        errors: null,
      };
    case types.SAVE_NOTIFICATION_FAIL:
      return {
        ...state,
        errors: getActionErrors(action),
      };
    case types.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        initialValues: initialNotificationState.initialValues,
        errors: null,
      };
    case types.DELETE_NOTIFICATION_FAIL:
      return {
        ...state,
        errors: getActionErrors(action),
      };
    case types.UPDATE_SUBSCRIBER_SUGGESTIONS_SUCCESS: {
      return {
        ...state,
        subscriberSuggestions: action.subscriberSuggestions,
      };
    }
    case types.RESET_NOTIFICATION_FORM:
      return {
        ...state,
        initialValues: initialNotificationState.initialValues,
        errors: null,
      };
    default: {
      return state;
    }
  }
};

const manageNotificationsReducer = combineReducers({
  notificationsReducer: notificationsReducer,
  notificationReducer: notificationReducer,
});

export default manageNotificationsReducer;
