import { SupportRequestDTO } from 'components/onboarding/hooks/useSupportTicket';
import { FormValueTypes, SupportTicketTagEnum } from 'components/onboarding/models';
import { supportTicketTextMapper } from './supportTicketTextMapper';

interface TicketProps<T> {
  allFields: FormValueTypes;
  isEuHost: boolean;
  ticketCommonData: SupportRequestDTO & T;
}

const dataSource = (allFields: FormValueTypes) => {
  return allFields.apiDataSource ? '<br><br><b>Data Source:</b> Automated' : '<br><br><b>Data Source:</b> User Input';
};

const handleDescriptionSignupType = (allFields: FormValueTypes, ticketCommonData: SupportRequestDTO) => {
  if (ticketCommonData.companyEvergreenData || allFields.invitedBy) {
    return `Invitation from company {{CUSTOMER_NAME}} to ${
      allFields.companyName
    } was completed. Invitation created by: ${allFields.invitedBy ? allFields.invitedBy : 'evergreen@project44.com'}.`;
  } else {
    return `Tenant ${allFields.companyName} has signed up without invitation.`;
  }
};

const handleSubjectInviteType = (ticketCommonData: SupportRequestDTO, allFields: FormValueTypes) => {
  if (ticketCommonData.companyEvergreenData || allFields.invitedBy) {
    return supportTicketTextMapper.INVITE.subject;
  } else {
    return supportTicketTextMapper.ORGANIC.subject;
  }
};

function ticketSubcontractorLead_EU<T>({ allFields, ticketCommonData }: TicketProps<T>): SupportRequestDTO {
  const commonData = {
    ...ticketCommonData,
    tags: ['EU_Subcontractor_Lead'],
  };

  const ticketDataEU: SupportRequestDTO = {
    ...commonData,
    subject: `New Company - Subcontractor Visibility | ${allFields.companyName} | ${
      allFields.vatnumber ? `VAT: ${allFields.vatnumber} |` : ''
    }${handleSubjectInviteType(ticketCommonData, allFields)}`,
    description: `${handleDescriptionSignupType(allFields, ticketCommonData)}<br><br>${
      supportTicketTextMapper.ONLY_SUBCONTRACTOR.body
    }  ${dataSource(allFields)}`,
  };

  return ticketDataEU;
}

function ticketSubcontractorLead_US<T>({ allFields, ticketCommonData }: TicketProps<T>): SupportRequestDTO {
  const commonData = {
    ...ticketCommonData,
    tags: ['Onboarding_Help_Request'],
  };

  const ticketDataUS: SupportRequestDTO = {
    ...commonData,
    subject: `New Company - Subcontractor Visibility | ${allFields.companyName} | ${
      allFields.dotnumber ? `DOT: ${allFields.dotnumber} |` : ''
    }${handleSubjectInviteType(ticketCommonData, allFields)}`,
    description: `${handleDescriptionSignupType(allFields, ticketCommonData)} <br><br>${
      supportTicketTextMapper.ONLY_SUBCONTRACTOR.body
    } ${dataSource(allFields)}`,
  };

  return ticketDataUS;
}

function ticketBrokerLead<T>({ allFields, isEuHost, ticketCommonData }: TicketProps<T>): SupportRequestDTO {
  const commonData = {
    ...ticketCommonData,
    tags: [isEuHost ? 'EU Broker Lead' : 'US Broker Lead'],
    description: `${handleDescriptionSignupType(allFields, ticketCommonData)} <br><br>${
      supportTicketTextMapper.ONLY_SUBCONTRACTOR.body
    } ${dataSource(allFields)}`,
  };

  const ticketDataUS: SupportRequestDTO = {
    ...commonData,
    subject: `New Company - Subcontractor Visibility | ${allFields.companyName} | ${
      allFields.dotnumber ? `DOT: ${allFields.dotnumber} ` : ''
    }${handleSubjectInviteType(ticketCommonData, allFields)}`,
  };

  const ticketDataEU: SupportRequestDTO = {
    ...commonData,
    subject: `New Company - Subcontractor Visibility | ${allFields.companyName} | ${
      allFields.vatnumber ? `VAT: ${allFields.vatnumber}` : ''
    }}${handleSubjectInviteType(ticketCommonData, allFields)}`,
  };

  return isEuHost ? ticketDataEU : ticketDataUS;
}

function ticketDirectApi<T>({ allFields, isEuHost, ticketCommonData }: TicketProps<T>): SupportRequestDTO {
  const commonData = {
    ...ticketCommonData,
    tags: ['Onboarding_Direct_API'],
    description: `${handleDescriptionSignupType(allFields, ticketCommonData)} <br><br>${
      supportTicketTextMapper.DIRECT_API.body
    }${allFields.hasSubcontractors ? `<br><br>${supportTicketTextMapper.SUBCONTRACTOR.body}` : ''}${dataSource(
      allFields
    )}`,
  };

  const ticketDataUS: SupportRequestDTO = {
    ...commonData,
    subject: `New Company – Direct/API | ${
      allFields.hasSubcontractors ? supportTicketTextMapper.SUBCONTRACTOR.subject : ''
    } ${allFields.companyName} ${
      allFields.dotnumber ? `| DOT#: ${allFields.dotnumber}` : ''
    } |${handleSubjectInviteType(ticketCommonData, allFields)} `,
  };

  const ticketDataEU: SupportRequestDTO = {
    ...commonData,
    subject: `New Company – Direct/API | ${
      allFields.hasSubcontractors ? supportTicketTextMapper.SUBCONTRACTOR.subject : ''
    } ${allFields.companyName} ${
      allFields.vatnumber ? `| VAT#: ${allFields.vatnumber}` : ''
    } |${handleSubjectInviteType(ticketCommonData, allFields)}`,
  };

  return isEuHost ? ticketDataEU : ticketDataUS;
}

function ticketNewTelematics<T>({ allFields, isEuHost, ticketCommonData }: TicketProps<T>): SupportRequestDTO {
  const commonData = {
    ...ticketCommonData,
    tags: ['Onboarding_New_Telematics'],
    description: `${handleDescriptionSignupType(allFields, ticketCommonData)} <br><br>${
      supportTicketTextMapper.NEW_ELD.body
    }${allFields.hasSubcontractors ? `<br><br>${supportTicketTextMapper.SUBCONTRACTOR.body}` : ''}${dataSource(
      allFields
    )}`,
  };

  const ticketDataUS: SupportRequestDTO = {
    ...commonData,
    subject: `New Company - New Telematics Request | ${
      allFields.hasSubcontractors ? supportTicketTextMapper.SUBCONTRACTOR.subject : ''
    } ${allFields.companyName} ${allFields.dotnumber ? `| DOT#: ${allFields.dotnumber}` : ''} | ELD: ${
      allFields.providerName
    } |${handleSubjectInviteType(ticketCommonData, allFields)}`,
  };

  const ticketDataEU: SupportRequestDTO = {
    ...commonData,
    subject: `New Company - New Telematics Request | ${
      allFields.hasSubcontractors ? supportTicketTextMapper.SUBCONTRACTOR.subject : ''
    }${allFields.companyName}${allFields.vatnumber ? `| VAT#: ${allFields.vatnumber}` : ''} | ELD: ${
      allFields.providerName
    } |${handleSubjectInviteType(ticketCommonData, allFields)}`,
  };

  return isEuHost ? ticketDataEU : ticketDataUS;
}

function ticketSignup<T>({ allFields, isEuHost, ticketCommonData }: TicketProps<T>): SupportRequestDTO {
  const commonData = {
    ...ticketCommonData,
    tags: [],
    description: `Invitation from company {{CUSTOMER_NAME}} to ${
      allFields.companyName
    } was completed. Invitation created by: ${allFields.invitedBy}. ${dataSource(allFields)}`,
  };

  const ticketDataUS: SupportRequestDTO = {
    ...commonData,
    // the sender company name (after from) is generated by Ticketing Service
    subject: `New company - ${allFields.companyName} ${
      allFields.dotnumber ? `[DOT: ${allFields.dotnumber}] ` : ``
    }accepted an invitation from {{CUSTOMER_NAME}}`,
  };

  const ticketDataEU: SupportRequestDTO = {
    ...commonData,
    // the sender company name (after from) is generated by Ticketing Service
    subject: `New company - ${allFields.companyName} ${
      allFields.vatnumber ? `[VAT: ${allFields.vatnumber}] ` : ``
    }accepted an invitation from {{CUSTOMER_NAME}}`,
  };
  return isEuHost ? ticketDataEU : ticketDataUS;
}

function ticketSignupWithEvergreen<T>({ allFields, isEuHost, ticketCommonData }: TicketProps<T>): SupportRequestDTO {
  const commonData = {
    ...ticketCommonData,
    tags: [],
    description: `Invitation from company {{CUSTOMER_NAME}} to ${
      allFields.companyName
    } was completed. Invitation created by: evergreen@project44.com. ${dataSource(allFields)}`,
  };

  const ticketDataUS: SupportRequestDTO = {
    ...commonData,
    subject: `New company - ${allFields.companyName} ${
      allFields.dotnumber ? `[DOT: ${allFields.dotnumber}] ` : ``
    }accepted an invitation from {{CUSTOMER_NAME}}`,
  };

  const ticketDataEU: SupportRequestDTO = {
    ...commonData,
    subject: `New company - ${allFields.companyName} ${
      allFields.vatnumber ? `[VAT: ${allFields.vatnumber}] ` : ``
    }accepted an invitation from {{CUSTOMER_NAME}}`,
  };
  return isEuHost ? ticketDataEU : ticketDataUS;
}

function ticketSignupWithoutInvite<T>({ allFields, isEuHost, ticketCommonData }: TicketProps<T>): SupportRequestDTO {
  const commonData = {
    ...ticketCommonData,
    tags: [],
    description: `Tenant ${allFields.companyName} has signed up without an invitation. ${dataSource(allFields)}`,
  };

  const ticketDataUS: SupportRequestDTO = {
    ...commonData,
    subject: `New Company - ${allFields.companyName} ${
      allFields.dotnumber ? `[DOT: ${allFields.dotnumber}] ` : ''
    }signed up without an invitation`,
  };

  const ticketDataEU: SupportRequestDTO = {
    ...commonData,
    subject: `New Company - ${allFields.companyName} ${
      allFields.vatnumber ? `[VAT: ${allFields.vatnumber}] ` : ''
    }signed up without an invitation`,
  };
  return isEuHost ? ticketDataEU : ticketDataUS;
}

export const ticketsMap: { [P in SupportTicketTagEnum]: (arg: any) => SupportRequestDTO } = {
  [SupportTicketTagEnum.ONBOARDING_DIRECT_API]: ticketDirectApi,
  [SupportTicketTagEnum.EU_SUBCONTRACTOR_LEAD]: ticketSubcontractorLead_EU,
  [SupportTicketTagEnum.US_SUBCONTRACTOR_LEAD]: ticketSubcontractorLead_US,
  [SupportTicketTagEnum.ONBOARDING_BROKER_LEAD]: ticketBrokerLead,
  [SupportTicketTagEnum.ONBOARDING_NEW_TELEMATICS]: ticketNewTelematics,
  [SupportTicketTagEnum.ONBOARDING_SIGNUP]: ticketSignup,
  [SupportTicketTagEnum.ONBOARDING_SIGNUP_EVERGREEN]: ticketSignupWithEvergreen,
  [SupportTicketTagEnum.ONBOARDING_SIGNUP_NO_INVITE]: ticketSignupWithoutInvite,
};
