import { Field, FormikProps, FieldArray } from 'formik';
import { defineMessages, injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import Col from 'react-bootstrap/Col';
import { CloseOutlined, UserOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import { emailValidation, phoneValidation } from 'common/FormValidations';
import { FormGroupRow, Input, Label } from 'components/common/forms/FormElements';
import { FormValues, Contact } from 'models';
import { Button } from 'ui-components';

const messages = defineMessages({
  firstName: {
    id: 'locationDetails.conditionallyRenderFormSection.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'locationDetails.conditionallyRenderFormSection.lastName',
    defaultMessage: 'Last Name',
  },
  phoneNumber: {
    id: 'locationDetails.conditionallyRenderFormSection.phoneNumber',
    defaultMessage: 'Phone Number',
  },
  enterFirstName: {
    id: 'locationDetails.conditionallyRenderFormSection.enterFirstName',
    defaultMessage: 'Enter First Name',
  },
  enterLastName: {
    id: 'locationDetails.conditionallyRenderFormSection.enterLastName',
    defaultMessage: 'Enter Last Name',
  },
  enterEmailAddress: {
    id: 'locationDetails.conditionallyRenderFormSection.enterEmailAddress',
    defaultMessage: 'Enter Email Address',
  },
  enterPhoneNumber: {
    id: 'locationDetails.conditionallyRenderFormSection.enterPhoneNumber',
    defaultMessage: 'Enter Phone Number',
  },
  contactName: {
    id: 'locationDetails.edit.labels.contactName',
    defaultMessage: 'Contact Name',
  },
  contactPhoneNumber: {
    id: 'locationDetails.edit.labels.phoneNumber',
    defaultMessage: 'Phone Number',
  },
  email: {
    id: 'locationDetails.edit.labels.email',
    defaultMessage: 'Email',
  },
  phonePlaceholder: {
    id: 'locationDetails.edit.placeholders.contactPhoneNumber',
    defaultMessage: '555-555-5555',
  },
  contactNamePlaceholder: {
    id: 'locationDetails.edit.placeholders.contactName',
    defaultMessage: 'John Doe',
  },
  contactEmailPlaceholder: {
    id: 'locationDetails.edit.placeholders.email',
    defaultMessage: 'abc@acme.com',
  },
});

const RemoveContactButton = styled('button')`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

interface ContactInfoFormProps extends WrappedComponentProps {
  formProps: FormikProps<FormValues>;
}

const ContactInfoForm = (props: ContactInfoFormProps) => {
  return (
    <div className="contact-info">
      <FieldArray
        name="contacts"
        validateOnChange
        render={({ push, remove }) => (
          <>
            {get(props, 'formProps.values.contacts', []).map((contact: Contact, idx: number) => (
              <FormGroupRow key={`contacts.${contact.keyId || contact.id}`} style={{ marginBottom: 0 }}>
                <Col lg={1} style={{ maxWidth: 'calc(6%)' }}>
                  {idx === 0 && <UserOutlined style={{ fontSize: '2rem', lineHeight: '3.3rem' }} />}
                </Col>
                <Col md={8} style={{ padding: '5px' }}>
                  <Field
                    type="text"
                    label={props.intl.formatMessage(messages.contactName)}
                    component={renderInput}
                    name={`contacts.${idx}.contactName`}
                    placeholder={props.intl.formatMessage(messages.contactNamePlaceholder)}
                  />
                </Col>
                <Col md={6} style={{ padding: '5px' }}>
                  <Field
                    type="text"
                    label={props.intl.formatMessage(messages.contactPhoneNumber)}
                    component={renderInput}
                    name={`contacts.${idx}.phoneNumber`}
                    validate={phoneValidation}
                    errorMessage={
                      get(props, ['formProps', 'errors', 'contacts', idx, 'phoneNumber'])
                        ? props.intl.formatMessage({
                            id: 'common.errorMessages.invalidPhone',
                            defaultMessage: 'Invalid phone number',
                          })
                        : undefined
                    }
                    placeholder={props.intl.formatMessage(messages.phonePlaceholder)}
                  />
                </Col>
                <Col md={7} style={{ padding: '5px' }}>
                  <Field
                    type="email"
                    label={props.intl.formatMessage(messages.email)}
                    component={renderInput}
                    name={`contacts.${idx}.email`}
                    validate={emailValidation}
                    errorMessage={
                      get(props, ['formProps', 'errors', 'contacts', idx, 'email'])
                        ? props.intl.formatMessage({
                            id: 'common.errorMessages.invalidEmail',
                            defaultMessage: 'Invalid email address',
                          })
                        : undefined
                    }
                    placeholder={props.intl.formatMessage(messages.contactEmailPlaceholder)}
                  />
                </Col>
                <Col lg={1} className="align-self-center" style={{ maxWidth: 'calc(6%)', paddingTop: '2rem' }}>
                  <RemoveContactButton
                    onClick={() => remove(idx)}
                    data-testid={`remove-${idx}-contact`}
                    aria-label={`Remove ${get(props, `formProps.contacts[$idx].contactName`)} from contacts`}
                  >
                    <CloseOutlined style={{ fontSize: '2rem', lineHeight: '3.3rem' }} />
                  </RemoveContactButton>
                </Col>
              </FormGroupRow>
            ))}
            <Row>
              <Col className={'text-center'}>
                <Button
                  clickFn={() =>
                    push({
                      email: undefined,
                      contactName: undefined,
                      phoneNumber: undefined,
                      keyId: uuid(),
                    })
                  }
                  type={'primary-transparent-text'}
                  className={'d-inline-block'}
                >
                  <FormattedMessage id="locationDetails.edit.addContact" defaultMessage="Add a contact" />
                </Button>
              </Col>
            </Row>
          </>
        )}
      />
    </div>
  );
};

function renderInput({ field, placeholder, label, classes, type, errorMessage }: any) {
  return (
    <>
      <Label inputid={field.name}>{label}</Label>
      <Input
        aria-label={label}
        aria-required
        id={field.name.replace(/\./g, '-')}
        errorMessage={errorMessage}
        {...field}
        placeholder={placeholder}
      />
    </>
  );
}

export default injectIntl(ContactInfoForm);
