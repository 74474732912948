import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { buildShipmentSearchQuery } from 'components/shipmentCounts/ducks/operations';
import { API_PATH } from 'common/AppConstants';
import FilterUtil from 'components/filter/util/filterUtil';
import filterMessages from 'components/filter/modes/filterMessages.json';
import { ShipmentModeEnum } from 'components/newShipmentDetails/models/ShipmentMode.enum';
import axios from 'util/paxios';
import { primaryBlue } from 'styles/colors';
import { shipmentListFiltersUrlEnabled, Authorities } from 'common/authorizations';
import Card from '../../ui-components/components/Card/Card';

const StyledDescription = styled.span`
  font-size: 21px;
  line-height: 32px;
  margin-right: 24px;
`;

const StyledCount = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 49px;
  line-height: 32px;
  padding: 0 20px;
`;

const StyledCardLayout = styled.span`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  white-space: nowrap;
  display: inline-block;
  min-width: 90px;
  min-height: 90px;
  border-radius: 50%;
  background: rgba(202, 66, 54, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.a`
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${primaryBlue};
  cursor: pointer;
  text-align: right;
  text-transform: uppercase;
  margin-left: auto;
  min-width: fit-content;
`;

const StyledContainer = styled(Container)`
  margin-top: -13px;
  margin-bottom: 2px;
`;
export interface CarrierCardProps {
  count: number;
  description: string;
  principal: any;
  executeWidgetLinkClickFn: any;
  authorities: any;
}

const config = {
  searchKeywords: '',
  filter: {
    ...FilterUtil.getValidOrBlank(),
    oceanDemurrageEligible: null,
    status: [filterMessages.pendingTrackingMethod.defaultMessage],
  },
  mode: ShipmentModeEnum.TL,
};

const onShipmentSearch = (executeWidgetLinkClickFn: any, authorities: Array<Authorities>): string | void => {
  if (shipmentListFiltersUrlEnabled()) {
    const queryURL = FilterUtil.buildQueryURl(config.filter, [], [config.mode], config.searchKeywords);
    return queryURL;
  } else {
    executeWidgetLinkClickFn(authorities, config.filter, config.mode, config.searchKeywords);
  }
};

async function countShipments(config: any, principal: any, setCount: (value: number) => void) {
  try {
    const searchQuery = buildShipmentSearchQuery(config.searchKeywords, config.filter, config.mode, 0, principal);
    const response = await axios.post(`${API_PATH}/shipment/search`, searchQuery, { withCredentials: true });
    setCount(response.data.totalResults);
  } catch (error) {
    console.error(error);
  }
}

const CarrierCard: React.FC<CarrierCardProps> = ({ principal, executeWidgetLinkClickFn, authorities }) => {
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    countShipments(config, principal, setCount);
  }, [principal]);

  const CardAsLink = () => (
    <Link to={`/shipment-list${onShipmentSearch(executeWidgetLinkClickFn, authorities)}`}>
      <Card style={{ cursor: 'pointer' }}>
        <StyledCardLayout>
          <IconContainer>
            <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M37.6794 39.3514C37.6794 40.3081 36.9051 41.0811 35.9466 41.0811H4.86393C3.90546 41.0811 3.1311 40.3081 3.1311 39.3514V26.7568C3.1311 17.2324 10.8639 9.51351 20.4053 9.51351C29.9467 9.51351 37.6794 17.2324 37.6794 26.7568V39.3514ZM33.7805 26.7568C33.7805 19.3838 27.7914 13.4054 20.4053 13.4054C13.0191 13.4054 7.02996 19.3838 7.02996 26.7568V37.1892H26.2536V27.9459C26.2536 27.6487 26.4972 27.4054 26.7951 27.4054H29.1777C29.4755 27.4054 29.7192 27.6487 29.7192 27.9459V37.1892H33.7805V26.7568ZM36.3852 13.1081L34.2408 10.9676C34.073 10.8 34.073 10.5243 34.2408 10.3568L37.9177 6.68649C37.9991 6.60601 38.109 6.56086 38.2236 6.56086C38.3382 6.56086 38.4482 6.60601 38.5296 6.68649L40.674 8.82703C40.7546 8.9083 40.7998 9.01806 40.7998 9.13243C40.7998 9.24681 40.7546 9.35656 40.674 9.43784L36.9971 13.1081C36.8292 13.2757 36.5585 13.2757 36.3852 13.1081ZM0.125717 8.82703L2.2701 6.68649C2.35151 6.60601 2.46147 6.56086 2.57605 6.56086C2.69063 6.56086 2.80058 6.60601 2.882 6.68649L6.55885 10.3568C6.63947 10.438 6.6847 10.5478 6.6847 10.6622C6.6847 10.7765 6.63947 10.8863 6.55885 10.9676L4.41447 13.1081C4.24661 13.2757 3.97044 13.2757 3.80257 13.1081L0.125717 9.43784C-0.0421524 9.26487 -0.0421524 8.9946 0.125717 8.82703ZM3.07695 44.5405H37.7336C38.692 44.5405 39.4664 45.3135 39.4664 46.2703V47.5676C39.4664 47.8054 39.2714 48 39.0332 48H1.77732C1.53906 48 1.34412 47.8054 1.34412 47.5676V46.2703C1.34412 45.3135 2.11847 44.5405 3.07695 44.5405ZM21.9215 6.05405H18.889C18.6508 6.05405 18.4558 5.85946 18.4558 5.62162V0.432432C18.4558 0.194595 18.6508 0 18.889 0H21.9215C22.1597 0 22.3547 0.194595 22.3547 0.432432V5.62162C22.3547 5.85946 22.1597 6.05405 21.9215 6.05405Z"
                fill="#CA4236"
              />
            </svg>
          </IconContainer>
          <StyledCount>{count}</StyledCount>
          <StyledDescription>
            <FormattedMessage
              id="shipmentCounts.carrierView.description"
              defaultMessage="Shipments need more information for your customer to gain visibility"
            />
          </StyledDescription>
          <StyledButton>
            <FormattedMessage id="shipmentCounts.carrierView.submitButton" defaultMessage="View and fix" />
          </StyledButton>
        </StyledCardLayout>
      </Card>
    </Link>
  );

  const CardAsClickableDiv = () => (
    <Card onClick={onShipmentSearch(executeWidgetLinkClickFn, authorities)} style={{ cursor: 'pointer' }}>
      <StyledCardLayout>
        <IconContainer>
          <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M37.6794 39.3514C37.6794 40.3081 36.9051 41.0811 35.9466 41.0811H4.86393C3.90546 41.0811 3.1311 40.3081 3.1311 39.3514V26.7568C3.1311 17.2324 10.8639 9.51351 20.4053 9.51351C29.9467 9.51351 37.6794 17.2324 37.6794 26.7568V39.3514ZM33.7805 26.7568C33.7805 19.3838 27.7914 13.4054 20.4053 13.4054C13.0191 13.4054 7.02996 19.3838 7.02996 26.7568V37.1892H26.2536V27.9459C26.2536 27.6487 26.4972 27.4054 26.7951 27.4054H29.1777C29.4755 27.4054 29.7192 27.6487 29.7192 27.9459V37.1892H33.7805V26.7568ZM36.3852 13.1081L34.2408 10.9676C34.073 10.8 34.073 10.5243 34.2408 10.3568L37.9177 6.68649C37.9991 6.60601 38.109 6.56086 38.2236 6.56086C38.3382 6.56086 38.4482 6.60601 38.5296 6.68649L40.674 8.82703C40.7546 8.9083 40.7998 9.01806 40.7998 9.13243C40.7998 9.24681 40.7546 9.35656 40.674 9.43784L36.9971 13.1081C36.8292 13.2757 36.5585 13.2757 36.3852 13.1081ZM0.125717 8.82703L2.2701 6.68649C2.35151 6.60601 2.46147 6.56086 2.57605 6.56086C2.69063 6.56086 2.80058 6.60601 2.882 6.68649L6.55885 10.3568C6.63947 10.438 6.6847 10.5478 6.6847 10.6622C6.6847 10.7765 6.63947 10.8863 6.55885 10.9676L4.41447 13.1081C4.24661 13.2757 3.97044 13.2757 3.80257 13.1081L0.125717 9.43784C-0.0421524 9.26487 -0.0421524 8.9946 0.125717 8.82703ZM3.07695 44.5405H37.7336C38.692 44.5405 39.4664 45.3135 39.4664 46.2703V47.5676C39.4664 47.8054 39.2714 48 39.0332 48H1.77732C1.53906 48 1.34412 47.8054 1.34412 47.5676V46.2703C1.34412 45.3135 2.11847 44.5405 3.07695 44.5405ZM21.9215 6.05405H18.889C18.6508 6.05405 18.4558 5.85946 18.4558 5.62162V0.432432C18.4558 0.194595 18.6508 0 18.889 0H21.9215C22.1597 0 22.3547 0.194595 22.3547 0.432432V5.62162C22.3547 5.85946 22.1597 6.05405 21.9215 6.05405Z"
              fill="#CA4236"
            />
          </svg>
        </IconContainer>
        <StyledCount>{count}</StyledCount>
        <StyledDescription>
          <FormattedMessage
            id="shipmentCounts.carrierView.description"
            defaultMessage="Shipments need more information for your customer to gain visibility"
          />
        </StyledDescription>
        <StyledButton>
          <FormattedMessage id="shipmentCounts.carrierView.submitButton" defaultMessage="View and fix" />
        </StyledButton>
      </StyledCardLayout>
    </Card>
  );

  return count ? (
    <StyledContainer data-locator="carrier-exception-banner">
      <Row>
        <Col>{shipmentListFiltersUrlEnabled() ? <CardAsLink /> : <CardAsClickableDiv />}</Col>
      </Row>
    </StyledContainer>
  ) : null;
};

export default CarrierCard;
