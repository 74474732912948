import { defineMessages } from 'react-intl';

const messages = defineMessages({
  pickupFrom: {
    id: 'addShipment.addEditShipmentSummary.pickupFrom',
    defaultMessage: 'Pickup From',
  },
  pickupWindow: {
    id: 'addShipment.addEditShipmentSummary.pickupWindow',
    defaultMessage: 'Pickup Window',
  },
  next: {
    id: 'addShipment.addEditShipmentConstants.next',
    defaultMessage: 'Next',
  },
  carrier: {
    id: 'addShipment.addEditShipmentConstants.carrier',
    defaultMessage: 'Carrier',
  },
  shipment: {
    id: 'addShipment.addEditShipmentConstants.shipment',
    defaultMessage: 'Shipment',
  },
  pickup: {
    id: 'addShipment.addEditShipmentSummary.pickup',
    defaultMessage: 'Pickup',
  },
  delivery: {
    id: 'addShipment.addEditShipmentSummary.delivery',
    defaultMessage: 'Delivery',
  },
  stops: {
    id: 'addShipment.addEditShipmentConstants.stops',
    defaultMessage: 'Stops',
  },
  summary: {
    id: 'addShipment.addEditShipmentSummary.summary',
    defaultMessage: 'Summary',
  },
  stoppingAt: {
    id: 'addShipment.addEditShipmentSummary.stoppingAt',
    defaultMessage: 'Stopping At',
  },
  stopWindow: {
    id: 'addShipment.addEditShipmentSummary.stopWindow',
    defaultMessage: 'Stop Window',
  },
  done: {
    id: 'addShipment.addEditShipmentSummary.done',
    defaultMessage: 'Done',
  },
  editStopDetails: {
    id: 'addShipment.addEditShipmentSummary.editStopDetails',
    defaultMessage: 'Edit Stop Details',
  },
  deliveringTo: {
    id: 'addShipment.addEditShipmentSummary.deliveringTo',
    defaultMessage: 'Delivering To',
  },
  deliveryWindow: {
    id: 'addShipment.addEditShipmentSummary.deliveryWindow',
    defaultMessage: 'Delivery Window',
  },
  submit: {
    id: 'addShipment.addEditShipmentSummary.submit',
    defaultMessage: 'Submit',
  },
  editDeliveryDetails: {
    id: 'addShipment.addEditShipmentSummary.editDeliveryDetails',
    defaultMessage: 'Edit Delivery Details',
  },
  tempTracking: {
    id: 'addShipment.addEditShipmentSummary.tempTracking',
    defaultMessage: 'Temp Tracking',
  },
  editPickupDetails: {
    id: 'addShipment.addEditShipmentSummary.editPickupDetails',
    defaultMessage: 'Edit Pickup Details',
  },
});

export const addEditFormConstants = {
  LANE_DETAIL: {
    title: messages.carrier,
    carrierTypes: ['SCAC', 'MC', 'DOT', 'EU'],
    buttonLabel: messages.next,
    id: 'LANE_DETAIL',
  },
  SHIPMENT: {
    title: messages.shipment,
    buttonLabel: messages.next,
    id: 'SHIPMENT',
  },
  TEMPERATURE_TRACKING: {
    title: messages.tempTracking,
    buttonLabel: messages.next,
    id: 'TEMPERATURE_TRACKING',
  },
  PICKUP_DETAIL: {
    title: messages.pickup,
    addressHeader: messages.pickupFrom,
    timeHeader: messages.pickupWindow,
    buttonLabel: messages.next,
    id: 'PICKUP_DETAIL',
    editTitle: messages.editPickupDetails,
  },
  DROPOFF_DETAIL: {
    title: messages.delivery,
    addressHeader: messages.deliveringTo,
    timeHeader: messages.deliveryWindow,
    buttonLabel: messages.next,
    id: 'DROPOFF_DETAIL',
    editTitle: messages.editDeliveryDetails,
  },
  ADD_STOPS: {
    title: messages.stops,
    addressHeader: messages.stoppingAt,
    timeHeader: messages.stopWindow,
    buttonLabel: messages.done,
    id: 'ADD_STOPS',
    editTitle: messages.editStopDetails,
  },
  SUMMARY: {
    title: messages.summary,
    buttonLabel: messages.submit,
    id: 'SUMMARY',
  },
  PICKUP_STOP_ID: 'pickupForm',
  DROP_OFF_STOP_ID: 'dropOffForm',
  mainFormName: 'AddEditShipment',
};
