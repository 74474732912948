import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Theme, HereMapsConfig } from 'models';
import * as brandThemingActions from 'components/settings/BrandTheming/ducks/actions';
import * as hereMapsActions from 'common/typedHereMaps/ducks/actions';
import { setCustomerProperties } from 'components/login/LoginForm/ducks/actions';
import { getPrincipalAuthorizationsFromState } from '../../common/authorizations';
import { RootState } from '../../reducers';

import ShipmentDetailsComponent from './ShipmentDetailsComponent';

const mapStateToProps = (state: RootState) => {
  const authorizations = getPrincipalAuthorizationsFromState(state);

  return {
    authorizations,
    isLoggedIn: state.authReducer.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setCustomTheme: (theme: Theme) => {
      dispatch(brandThemingActions.fetch.success(theme));
    },
    setHereMapsConfig: (hereMapsConfig: HereMapsConfig) => {
      dispatch(hereMapsActions.fetch.success(hereMapsConfig));
    },
    setCustomerProperties: (customerProperties: any) => {
      dispatch(setCustomerProperties(customerProperties));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDetailsComponent);
