import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Fleet } from 'components/carrierFleetListComponent/models/CarrierSearchCardData';
import { FleetIdentifiers } from './components/FleetCardComponents';
import * as styles from './FleetListCard.module.scss';

interface FleetListCardCardProps extends WrappedComponentProps, RouteComponentProps {
  vehicleItem: Fleet;
}

const FleetListCard: React.FC<FleetListCardCardProps> = ({ vehicleItem }) => {
  return (
    <>
      <div
        tabIndex={0}
        role="button"
        className={styles.card}
        data-locator={`vehicle-info-${vehicleItem.value}`}
        style={{ marginTop: '0px', marginBottom: '0px' }}
      >
        <Row className={styles.cardBody}>
          <Col xs={24} sm={8} className={styles.identifiersContainer}>
            <Row className="justify-content-between">
              <Col className="d-flex flex-sm-column" data-testid="vehicle-fleet-list-card-identifiers">
                <FleetIdentifiers name={vehicleItem.value} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default injectIntl(FleetListCard);
