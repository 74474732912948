import { defineMessages, createIntl, createIntlCache } from 'react-intl';
import LocationUtils from '../../../../../common/LocationUtils/LocationUtils';
import { locale, messages } from '../../../../.././i18n/configurei18n';
/**
 * Creates the layout of the popup.
 *
 * This allows devs to do custom styling here without worrying the internal details of the HERE map factory.
 * The styling for the popup is an inline css - Mark Serrano
 *
 * @param location the property name from the shipmentStops response.
 *                 Note: This is poorly named from the backend response.
 */

const cache = createIntlCache();
export const intl = createIntl({ locale, messages }, cache);

const messagesIntl = defineMessages({
  milesTraveled: {
    id: 'shipmentDetails.currentStopPopup.milesTraveled',
    defaultMessage: '{distance} Miles Traveled',
  },
  kilometersTraveled: {
    id: 'shipmentDetails.currentStopPopup.kilometersTraveled',
    defaultMessage: '{distance} Kilometers Traveled',
  },
  milesRemaining: {
    id: 'shipmentDetails.currentStopPopup.milesRemaining',
    defaultMessage: '{distance} Miles Remaining',
  },
  kilometersRemaining: {
    id: 'shipmentDetails.currentStopPopup.kilometersRemaining',
    defaultMessage: '{distance} Kilometers Remaining',
  },
  nextStop: {
    id: 'shipmentDetails.currentStopPopup.nextStop',
    defaultMessage: 'Next Stop ETA',
  },
});

const currentStopPopupHtml = (location) => {
  let {
    stopName,
    address,
    city,
    state,
    country,
    zip,
    contact,
    atStopEta,
    atStopMessage,
    statusCode,
    statusColor,
    statusDetails,
    statusInfo,
    statusUpdate,
    milesTraveled,
    milesRemaining,
    deliveryCountry,
  } = location;

  /**
   * Gets markup for the Location Details tooltip
   *
   * @returns HTML template
   */
  const getlocationDetailsMarkup = () => {
    let markup = `
      <div class="location-details">
        ${
          stopName
            ? `<p class="stop-name">
              <span>${stopName}</span><br/><br/>
            </p>`
            : ``
        }
        ${
          address || city || state || zip
            ? `<p class="stop-address">
              <span>${LocationUtils.formatCityStateCountry(city, state, country)}, ${zip}</span><br/>
            </p>`
            : ``
        }
        ${
          contact && contact.name
            ? `<p class="contact-name">
            <span>${contact && contact.contactName}</span><br/><br/>
          </p>`
            : ``
        }
        ${
          contact && contact.email
            ? `<p class="contact-email">
            <span>${contact && contact.email}</span><br/>
          </p>`
            : ``
        }
        ${
          contact && contact.phoneNumber1
            ? `<p class="contact-phone">
              <span>${contact && contact.phoneNumber1}</span>
            </p>`
            : ``
        }
      </div>
    `;

    return markup;
  };

  /**
   * Gets markup for the Current Location tooltip given route status code
   *
   * @param status a string representing shipment status
   * @returns HTML template
   */
  const getCurrentStopMarkup = (status) => {
    const showInMiles = LocationUtils.shouldShowInMiles(deliveryCountry);
    const traveledString = showInMiles
      ? `${intl.formatMessage(messagesIntl.milesTraveled, { distance: milesTraveled })} `
      : `${intl.formatMessage(messagesIntl.kilometersTraveled, {
          distance: LocationUtils.convertMilesToKm(milesTraveled),
        })}`;
    const remainingString = showInMiles
      ? `${intl.formatMessage(messagesIntl.milesRemaining, { distance: milesRemaining })}`
      : `${intl.formatMessage(messagesIntl.kilometersRemaining, {
          distance: LocationUtils.convertMilesToKm(milesRemaining),
        })}`;

    let statusInfoDescription = typeof statusInfo === 'object' && statusInfo !== null ? statusInfo.defaultMessage : '';

    let markup = `
      <div class="current-stop">
        <h4 class="status-info">${statusInfoDescription}</h4>
        <p class="status-details">${statusDetails[0]} <span>${statusDetails[1]}<span></p>
        <p class="miles-traveled">
          ${traveledString}
        </p>
        <p class="miles-remaining">
          ${remainingString}
        </p>
        <p class="at-stop-message" style="background-color: var(${statusColor})">
          <span>${atStopMessage}</span>
        </p>
        ${
          // If the shimpment is IN_TRANSIT, show the ETA
          status === 'IN_TRANSIT'
            ? `<p class="next-stop-eta">
            ${intl.formatMessage(messagesIntl.nextStop)}
            <span>${atStopEta}</span>
          </p>`
            : ``
        }
        <p class="status-update">
          <span>${statusUpdate}</span>
        </p>
      </div>
    `;

    return markup;
  };

  if (location.isCurrentStop) {
    return getCurrentStopMarkup(statusCode);
  } else {
    return getlocationDetailsMarkup();
  }
};

export default currentStopPopupHtml;
