export enum SupportTicketTextEnum {
  INVITE = 'INVITE',
  NEW_ELD = 'NEW_ELD',
  EVERGREEN = 'EVERGREEN',
  ORGANIC = 'ORGANIC',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  ONLY_SUBCONTRACTOR = 'ONLY_SUBCONTRACTOR',
  DIRECT_API = 'DIRECT_API',
}

export const supportTicketsText = {
  SUBCONTRACTOR: {
    subject: 'Subcontractor Visibility | ',
    body: 'The below carrier has indicated that they use subcontractors. Please reach out to this carrier to discuss their visibility needs.',
  },
  NEW_ELD: {
    subject: ' New Telematics Request | ',
    body: 'The below carrier has started the process for joining the project44 network and attempted to add a telematics connection for which we do not have an existing connection. Please reach out to this provider to begin the integration process.',
  },
  EVERGREEN: {
    subject: ' accepted an invitation from {{CUSTOMER_NAME}}',
    body: '',
  },
  ORGANIC: {
    subject: ' has signed up without an invitation.',
    body: '',
  },
  DIRECT_API: {
    subject: 'Direct/API  | ',
    body: 'The below carrier has indicated they would like to join the network with a direct connection. Please reach out to the carrier to determine next steps.',
  },
  ONLY_SUBCONTRACTOR: {
    subject: 'Subcontractor Visibility | ',
    body: 'The below carrier has started the process for joining the project44 network and indicated that they only use subcontractors. This carrier may need visibility over their subcontracted carriers. Please reach out to this carrier to discuss their visibility needs',
  },
  INVITE: {
    subject: ' accepted an invitation from {{CUSTOMER_NAME}}',
    body: '',
  },
};

export const supportTicketTextMapper: { [key in keyof typeof SupportTicketTextEnum]: any } = {
  [SupportTicketTextEnum.DIRECT_API]: supportTicketsText.DIRECT_API,
  [SupportTicketTextEnum.ONLY_SUBCONTRACTOR]: supportTicketsText.ONLY_SUBCONTRACTOR,
  [SupportTicketTextEnum.ORGANIC]: supportTicketsText.ORGANIC,
  [SupportTicketTextEnum.EVERGREEN]: supportTicketsText.EVERGREEN,
  [SupportTicketTextEnum.NEW_ELD]: supportTicketsText.NEW_ELD,
  [SupportTicketTextEnum.SUBCONTRACTOR]: supportTicketsText.SUBCONTRACTOR,
  [SupportTicketTextEnum.INVITE]: supportTicketsText.INVITE,
};
