import findIndex from 'lodash/findIndex';
import { Reducer } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { Note } from '../models/Note';
import * as noteActions from './actions';

export type NoteAction = ActionType<typeof noteActions>;

export interface NotesState {
  notes?: Note[];
  fetchingError?: Error;
  creatingError?: Error;
  updatingError?: Error;
  deletingError?: Error;
  shipmentId: string;
  isFetchingNotes: boolean;
  isCreatingNote: boolean;
  isUpdatingNote: boolean;
  isDeletingNote: boolean;
  showNotesWindow: boolean;
}

const initialState: NotesState = {
  notes: [],
  fetchingError: undefined,
  creatingError: undefined,
  updatingError: undefined,
  deletingError: undefined,
  shipmentId: '',
  isFetchingNotes: false,
  isCreatingNote: false,
  isUpdatingNote: false,
  isDeletingNote: false,
  showNotesWindow: false,
};

const notesReducer: Reducer<NotesState, NoteAction> = (state = initialState, action) => {
  switch (action.type) {
    case getType(noteActions.fetchNotes.request): {
      return {
        ...state,
        notes: [],
        isFetchingNotes: true,
        fetchingError: undefined,
      };
    }
    case getType(noteActions.fetchNotes.success): {
      return {
        ...state,
        notes: action.payload,
        isFetchingNotes: false,
      };
    }
    case getType(noteActions.fetchNotes.failure): {
      return {
        ...state,
        isFetchingNotes: false,
        fetchingError: action.payload,
      };
    }
    case getType(noteActions.fetchPublicNotes.request): {
      return {
        ...state,
        notes: [],
        isFetchingNotes: true,
        fetchingError: undefined,
      };
    }
    case getType(noteActions.fetchPublicNotes.success): {
      return {
        ...state,
        notes: action.payload,
        isFetchingNotes: false,
      };
    }
    case getType(noteActions.fetchPublicNotes.failure): {
      return {
        ...state,
        isFetchingNotes: false,
        fetchingError: action.payload,
      };
    }
    case getType(noteActions.createNote.request): {
      return {
        ...state,
        isCreatingNote: true,
      };
    }
    case getType(noteActions.createNote.success): {
      return {
        ...state,
        notes: [...(state.notes || []), action.payload],
        isCreatingNote: false,
      };
    }
    case getType(noteActions.createNote.failure): {
      return {
        ...state,
        isCreatingNote: false,
        creatingError: action.payload,
      };
    }
    case getType(noteActions.updateNote.request): {
      return {
        ...state,
        isUpdatingNote: true,
      };
    }
    case getType(noteActions.updateNote.success): {
      const updatedNote = action.payload;
      const notes = [...(state.notes || [])];
      const indexOfUpdatedNote = findIndex(notes, { id: updatedNote.id });
      notes.splice(indexOfUpdatedNote, 1, updatedNote);
      return {
        ...state,
        notes,
        isUpdatingNote: false,
      };
    }
    case getType(noteActions.updateNote.failure): {
      return {
        ...state,
        isUpdatingNote: false,
        updatingError: action.payload,
      };
    }
    case getType(noteActions.deleteNote.request): {
      return {
        ...state,
        isDeletingNote: true,
      };
    }
    case getType(noteActions.deleteNote.success): {
      const deletedNoteId = action.payload;
      const notes = [...(state.notes || [])];
      const indexOfUpdatedNote = findIndex(notes, { id: deletedNoteId });
      notes.splice(indexOfUpdatedNote, 1);

      return {
        ...state,
        notes,
        isDeletingNote: false,
      };
    }
    case getType(noteActions.deleteNote.failure): {
      return {
        ...state,
        isDeletingNote: false,
        deletingError: action.payload,
      };
    }
    case getType(noteActions.toggleNotesWindow): {
      return {
        ...state,
        showNotesWindow: !state.showNotesWindow,
      };
    }
    case getType(noteActions.reset): {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default notesReducer;
