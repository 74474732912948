import { rgba } from 'polished';
import styled from 'styled-components';

import { ShipmentStopStatusEnum, ShipmentDerivedStatus, getShipmentDerivedStatusColor } from 'models';
import { primaryGreyTwenty } from 'styles/colors';

const activeCircleSize = 30;

export interface StopDotProps {
  shipmentStatus: ShipmentDerivedStatus;
  stopStatus?: ShipmentStopStatusEnum;
  isFirstStop: boolean;
}

export const StopDot = styled.div<StopDotProps>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.stopStatus === ShipmentStopStatusEnum.ARRIVED || props.stopStatus === ShipmentStopStatusEnum.DEPARTED
      ? getShipmentDerivedStatusColor(props.shipmentStatus)
      : primaryGreyTwenty};
  z-index: 2;
  margin-bottom: 6px;
`;

export interface AtStopDotProps {
  shipmentStatus: ShipmentDerivedStatus;
}

export const AtStopDot = styled.div<AtStopDotProps>`
  position: absolute;
  top: -3px;
  height: 16px;
  width: 16px;
  background-color: white;
  border-radius: 50%;
  &:after {
    content: '';
    position: absolute;
    top: -7px;
    left: -7px;
    height: ${activeCircleSize}px;
    width: ${activeCircleSize}px;
    background-color: ${(props) => rgba(getShipmentDerivedStatusColor(props.shipmentStatus), 0.3)};
    border-radius: 50%;
    z-index: -1;
  }
`;

export interface CurrentPositionDotProps {
  shipmentStatus: ShipmentDerivedStatus;
}

export const CurrentPositionDot = styled.div<CurrentPositionDotProps>`
  position: absolute;
  top: 0;
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 50%;
  left: calc(100% - 6px);
  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: ${(props) => getShipmentDerivedStatusColor(props.shipmentStatus)};
  }
  &:after {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    height: ${activeCircleSize}px;
    width: ${activeCircleSize}px;
    background-color: ${(props) => rgba(getShipmentDerivedStatusColor(props.shipmentStatus), 0.3)};
    border-radius: 50%;
    z-index: -1;
  }
`;
