import { BusinessTypeEnum } from './BusinessType';
import { ELDProviderModel } from './ELDProviderModelDTO';

export type FormValueTypes = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  countryCode?: string;
  countryCodeAlpha2?: string;
  phoneNumber?: string;
  password?: string;
  companyName?: string;
  companyPostalCode?: string;
  companyState?: string;
  companyCity?: string;
  companyIsP44Carrier?: boolean;
  companyStreet?: string;
  companyMcNumbers?: Array<string>;
  compananyPowerUnits?: string;

  /**
   * Two letters country code
   */
  companyCountry?: string;
  companyUid?: string;
  companyMatchConfirm?: string;
  companyTenantId?: string;
  acceptTerms?: boolean;
  dotnumber?: string;
  vatnumber?: string;
  companySCAC?: string;
  unknownDOT?: string;
  accepted?: boolean;
  businessType?: BusinessTypeEnum;
  providerName?: string;
  providerData?: ELDProviderModel;
  providerContactName?: string;
  providerContactEmail?: string;
  hasSubcontractors?: boolean;
  acceptDataConsent?: boolean;
  preferredLocale?: string;
  apiDataSource?: boolean;
  invitedBy?: string;
};

export const initialFormValues: FormValueTypes = {
  invitedBy: undefined,
  companyIsP44Carrier: undefined,
  apiDataSource: undefined,
  compananyPowerUnits: undefined,
  firstName: undefined,
  companyMcNumbers: undefined,
  lastName: undefined,
  emailAddress: undefined,
  countryCode: undefined,
  countryCodeAlpha2: undefined,
  phoneNumber: undefined,
  password: undefined,
  companyName: undefined,
  companyPostalCode: undefined,
  companyState: undefined,
  companyCity: undefined,
  companyStreet: undefined,
  companyCountry: undefined,
  companyUid: undefined,
  companyMatchConfirm: undefined,
  companyTenantId: undefined,
  acceptTerms: false,
  dotnumber: undefined,
  vatnumber: undefined,
  companySCAC: undefined,
  unknownDOT: undefined,
  accepted: false,
  businessType: undefined,
  providerName: undefined,
  providerData: undefined,
  providerContactName: undefined,
  providerContactEmail: undefined,
  hasSubcontractors: undefined,
  acceptDataConsent: undefined,
  preferredLocale: undefined,
};
