import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { errorRed, errorRedThree } from 'styles/colors';
import { ReactComponent as LoginErrorIcon } from 'components/common/assets/icons/icon-login-error.svg';

const StyledDiv = styled('div')`
  flex-direction: column;
  padding: 8px 16px;

  display: flex;
  flex-direction: row;
  line-height: 25px;

  background: ${errorRedThree};

  border: 1px solid ${errorRed};
  box-sizing: border-box;

  justify-content: space-between;
  align-items: center;
`;
const StyledP = styled('div')`
  margin-left: 1em;
`;
const ErrorPanel = ({ supportRefId }: { supportRefId: string }) => {
  return (
    <StyledDiv data-locator="error-wrapper" data-testid="errorPanel">
      <LoginErrorIcon />
      <StyledP>
        <FormattedMessage
          id="entitlementRules.add.createError"
          defaultMessage="We could not create the rule. Your support ID is: {supportRefId}"
          values={{ supportRefId }}
        />
      </StyledP>
    </StyledDiv>
  );
};

export default ErrorPanel;
