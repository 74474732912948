import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MONTH_DAY_YEAR_FORMAT, HOURS_MINUTES_TIMEZONE_FORMAT } from 'i18n/configurei18n';
import './MapSidepanelItem.scss';
import MapSidepanelItemIcon from './MapSidePanelItemIcon';
import { shipmentPingShape } from '../models/shipmentPing';
import LocationUtils from '../../../../common/LocationUtils/LocationUtils';

const DATE_FORMAT = MONTH_DAY_YEAR_FORMAT;
const TIME_FORMAT = HOURS_MINUTES_TIMEZONE_FORMAT;
const UTC = 'UTC';

// TODO: Implement Formatted Color Statuses when we get that info back from the API
// import StopArrival from '../enums/stopArrival';
// const formatColorStatus = statuses => {
//     /**
//      * After discussing this with Tom and Danny, right now we don't have a
//      * way to identify if a ping is early, on time, or late since we only
//      * get a statusCode and statusReasonCode in the allStatusUpdate property.
//      *
//      * With Danny's instructions, we should default the icon and the marker to
//      * black - Mark Serrano
//      */
//     if (statuses.statusReasonCode === StopArrival.EARLY) {
//         return 'var(--green)';
//     } else if (statuses.statusReasonCode === StopArrival.ON_TIME) {
//         return 'var(--chathams-blue)';
//     } else if (statuses.statusReasonCode === StopArrival.LATE) {
//         return 'var(--new-fruit)';
//     } else {
//         return 'var(--primary-blue)';
//     }
// };

export const formatCurrentLocation = (current) => {
  if (current) {
    return LocationUtils.formatCityStateCountry(current.city, current.state, current.countryCodeDto);
  }
};

const formatPreviousLocation = (previous) => {
  /**
   * After discussing this with Tom and Danny, right now we don't have a
   * way to identify if a ping is early, on time, or late since we only
   * get a statusCode and statusReasonCode in the allStatusUpdate property.
   *
   * Consequently we won't know what `was` a ping - Mark Serrano
   */
  if (previous) {
    return `Was near ${previous.city}, ${previous.state}`;
  }
};

export const formatPingDate = (timestamp, timezone) => {
  if (timestamp && timezone) {
    const date = new moment.tz(timestamp, UTC).tz(timezone).format(DATE_FORMAT);

    return `${date}`;
  }
};

export const formatPingTime = (timestamp, timezone) => {
  if (timestamp && timezone) {
    const time = new moment.tz(timestamp, UTC).tz(timezone).format(TIME_FORMAT);

    return `${time}`;
  }
};

const MapSidepanelItem = (props) => {
  if (props.ping.statuses.statusCode === 'IN_TRANSIT' || props.ping.statuses.statusCode === 'AT_STOP') {
    let classNames = props.isActive ? 'map-side-panel-item--active' : 'map-side-panel-item';
    return (
      //eslint-disable-next-line
      <div
        data-testid="map-side-panel-item"
        className={classNames}
        onClick={() => {
          props.handleClick(props);
        }}
      >
        {props.ping && props.ping.statuses && (
          <div className="map-side-panel-item__icon">
            <MapSidepanelItemIcon
              // TODO:  Implement contextual status colors when we have that data in the API
              // color={formatColorStatus(props.ping.statuses)}
              statusColor={'--primary-blue'}
            />
          </div>
        )}

        <div className="map-side-panel-item__content">
          <p>
            {props.ping && props.ping.addresses && props.ping.addresses.current && (
              <span className="map-side-panel-item__location">
                <FormattedMessage
                  id="shipmentDetails.mapSidepanelItem.nearCurrentLocation"
                  defaultMessage="Near {currentLocation}"
                  values={{ currentLocation: <b>{formatCurrentLocation(props.ping.addresses.current)}</b> }}
                />
              </span>
            )}
            {props.ping && props.ping.pingDateTimes.utc && (
              <span className="map-side-panel-item__time">
                {formatPingTime(props.ping.pingDateTimes.utc, props.ping.timezone)}
              </span>
            )}
          </p>
          {props.ping && props.ping.addresses && props.ping.addresses.previous && (
            <p>{formatPreviousLocation(props.ping.addresses.previous)}</p>
          )}

          {props.ping && props.ping.pingDateTimes.utc && (
            <p>
              <span className="map-side-panel-item__date">
                {formatPingDate(props.ping.pingDateTimes.utc, props.ping.timezone)}
              </span>
            </p>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

MapSidepanelItem.propTypes = {
  ping: shipmentPingShape,
  handleClick: PropTypes.func,
};

export default MapSidepanelItem;
