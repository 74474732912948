import { FieldArray, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import './AddEditShipmentStops.scss';
import get from 'lodash/get';
import ContactInfoFormSection from '../../common/forms/ContactInfoFormSection';
import AddressFormSection from '../../common/forms/AddressFormSection';
import ShipmentDateFormSection from '../AddEditShipmentForm/AddEditShipmentDates';
import { addEditFormConstants } from '../AddEditShipmentConstants';
import CustomButton from '../../../themes/CustomButton';
import AddEditShipmentButtons from '../AddEditShipmentButtons/AddEditShipmentButtons';

const renderAddressFormSection = (props, stop, index) => {
  const carrierType = get(props.formValues, 'carrierType', null);
  const carrierTypes = carrierType !== null ? [{ type: carrierType }] : [];

  return (
    <AddressFormSection
      autocomplete
      formName={stop}
      contactFormName={`${stop}.Stop${index}Contact`}
      // eslint-disable-next-line react/jsx-no-bind
      zipEntered={(zip) => props.zipEntered(stop, zip)}
      addressHeader={addEditFormConstants[props.step.id].addressHeader}
      carrierTypes={carrierTypes}
    >
      <ShipmentDateFormSection
        formName="shipmentDates"
        sectionHeader={addEditFormConstants[props.step.id].timeHeader}
      />
      <div className="shipment-contact-info">
        <h4 className="form-header">
          <FormattedMessage
            id="addShipment.renderAddressFormSection.contactInformation"
            defaultMessage="Contact Information"
          />
          <span className="form-header-help">
            (<FormattedMessage id="addShipment.renderAddressFormSection.optional" defaultMessage="optional" />)
          </span>
        </h4>
        <ContactInfoFormSection formName={`Stop${index}Contact`} carrierTypes={carrierTypes} />
      </div>
    </AddressFormSection>
  );
};

const renderStops = (props) => (
  <div className="add-stop-list">
    {props.fields.map((stop, index) => {
      const currentStop = props.formValues.addStops[index];

      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className={`shipment-stop ${currentStop.expanded ? 'expanded' : ''}`} key={index}>
          {(!currentStop.expanded || props.formValues.addStops.length > 1) && (
            <div className="stop-list-row">
              <span className="company-title">{get(currentStop, 'company', 'New Stop')}</span>
              {/*eslint-disable-next-line */}
              <span className="remove-stop" onClick={() => props.fields.remove(index)}>
                <FormattedMessage id="addShipment.addEditShipmentStops.delete" defaultMessage="Delete" />
                <i className="material-icons">delete</i>
              </span>
              {/*eslint-disable-next-line*/}
              <span
                className="display-stop"
                onClick={() => {
                  props.formValues.addStops.forEach((field, i) => {
                    field.expanded = i === index;
                  });
                  props.toggleStop(index, props.formValues.addStops.length);
                }}
              >
                {currentStop.expanded && <i className="material-icons">keyboard_arrow_up</i>}
                {!currentStop.expanded && <i className="material-icons">keyboard_arrow_down</i>}
              </span>
            </div>
          )}
          <div className="shipment-stop-form">{renderAddressFormSection(props, stop, index)}</div>
        </div>
      );
    })}
    <CustomButton
      classes="bordered mysterious-grey medium full-width mt-4"
      // eslint-disable-next-line react/jsx-no-bind
      clickFn={() => {
        props.formValues.addStops.forEach((field) => {
          field.expanded = false;
        });
        props.fields.push(props.initialValues);
      }}
    >
      <FormattedMessage id="addShipment.addEditShipmentStops.addAnotherStop" defaultMessage="Add Another Stop" />
    </CustomButton>
  </div>
);

let AddEditShipmentStops = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit} className="add-edit-shipment-form">
      <FieldArray
        name="addStops"
        step={props.step}
        component={renderStops}
        formValues={props.formValues}
        // eslint-disable-next-line react/jsx-no-bind
        zipEntered={(stop, zip) => props.zipEntered(stop, zip)}
        toggleStop={props.toggleStop}
        initialValues={props.initialValues}
      />
      <AddEditShipmentButtons
        step={props.step}
        navigateStep={props.navigateStep}
        onSubmit={props.navigateStep}
        // eslint-disable-next-line react/jsx-no-bind
        cancel={() => props.cancel('CANCEL_SHIPMENT')}
      />
    </form>
  );
};

AddEditShipmentStops = reduxForm({
  form: 'AddEditShipment',
  destroyOnUnmount: false,
})(AddEditShipmentStops);

export default AddEditShipmentStops;
