import { useState } from 'react';
import get from 'lodash/get';
import Col from 'react-bootstrap/Col';
import { defineMessages, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Input } from 'ui-components';
import SelectedChips from 'components/common/forms/selectedChips/SelectedChips';

const messages = defineMessages({
  filterByAnyTenant: {
    id: 'quickView.sharedByFilter.filterByAnyTenant',
    defaultMessage: 'Filter by Any Tenant',
  },
});

const StyledInput = styled(Input)`
  .ant-input-group-addon {
    border: 0 none !important;
  }
`;

const SharedByFilter = (props) => {
  const [currentValue, setCurrentValue] = useState('');
  const listData = get(props, 'filter.sharedBy', []);

  const onChange = (event) => {
    setCurrentValue(event.target.value);
  };

  const addCurrentValueToFilter = () => {
    props.handleAutocompleteUpdate(currentValue, 'sharedBy');
    setCurrentValue('');
  };

  return (
    <Col>
      <div>
        <SelectedChips
          listData={listData}
          showAllChips
          passedInDeleteFn={(filterItem) => props.handleAutocompleteUpdate(filterItem, 'sharedBy')}
        />
      </div>
      <StyledInput
        className="borderlessInput mb-4"
        onPressEnter={addCurrentValueToFilter}
        onSearch={addCurrentValueToFilter}
        onChange={onChange}
        placeholder={props.intl.formatMessage(messages.filterByAnyTenant)}
        value={currentValue}
        id="sharedBy-input-filter"
        search="tenant-search"
      />
    </Col>
  );
};
export default injectIntl(SharedByFilter);
