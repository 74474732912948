import { AxiosPromise, AxiosResponse } from 'axios';
import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getType, ActionType } from 'typesafe-actions';
import { message } from 'ui-components';

import { trackEvent } from 'common/eventTracker';
import { ShipmentModeEnum, ShipmentModeFullNameMap } from 'models';
import { API_PATH } from '../../../common/AppConstants';
import endpoints, { replaceTokens } from '../../../common/endpoints';
import axios from '../../../util/paxios';
import { Note } from '../models/Note';
import * as actions from './actions';

export type FetchNotesAction = ActionType<typeof actions.fetchNotes.request>;
export type FetchPublicNotesAction = ActionType<typeof actions.fetchPublicNotes.request>;

export const fetchNotes = ({
  shipmentId,
  mode,
  masterShipmentId,
}: {
  shipmentId?: string;
  mode?: ShipmentModeEnum;
  masterShipmentId?: string;
}): AxiosPromise<Note[]> => {
  let url = '';
  if (masterShipmentId !== undefined) {
    url = `${API_PATH}${endpoints.GET_NOTES}?masterShipmentId=${masterShipmentId}`;
  } else if (mode !== undefined && shipmentId !== undefined) {
    url = `${API_PATH}${endpoints.GET_NOTES}?mode=${ShipmentModeFullNameMap[mode]}&shipmentId=${shipmentId}`;
  }
  return axios({
    method: 'GET',
    url,
    withCredentials: true,
  });
};

export const fetchPublicNotes = ({
  shareToken,
  useIntermodalEndpoint,
}: {
  shareToken: string;
  useIntermodalEndpoint?: boolean;
}): AxiosPromise<Note[]> => {
  let url = `${API_PATH}${replaceTokens(endpoints.PUBLIC_NOTES, { shareToken })}`;
  if (useIntermodalEndpoint) {
    url = `${API_PATH}${replaceTokens(endpoints.PUBLIC_INTERMODAL_NOTES, { shareToken })}`;
  }
  return axios({
    method: 'GET',
    url,
    withCredentials: true,
  });
};

export function* fetchNotesAsync(action: FetchNotesAction) {
  try {
    const response: AxiosResponse<Note[]> = yield call(fetchNotes, action.payload);
    yield put(actions.fetchNotes.success(response.data));
  } catch (error) {
    yield put(actions.fetchNotes.failure(error as Error));
  }
}

export function* fetchPublicNotesAsync(action: FetchPublicNotesAction) {
  try {
    const response: AxiosResponse<Note[]> = yield call(fetchPublicNotes, action.payload);
    yield put(actions.fetchPublicNotes.success(response.data));
  } catch (error) {
    yield put(actions.fetchPublicNotes.failure(error as Error));
  }
}

export function* watchFetchNotes() {
  yield takeLatest(getType(actions.fetchNotes.request), fetchNotesAsync);
}

export function* watchFetchPublicNotes() {
  yield takeLatest(getType(actions.fetchPublicNotes.request), fetchPublicNotesAsync);
}

export const showFetchNotesFailure = () => {
  // Do not show error message on mode agnostic demo page
  if (window.location.pathname.indexOf('ma') < 0) {
    message.error("We're having trouble fetching notes. Please try again.");
  }
};

export const showFetchPublicNotesFailure = () => {
  message.error("We're having trouble fetching public notes. Please try again.");
};

export function* watchFetchNotesFailure() {
  yield takeEvery(getType(actions.fetchNotes.failure), showFetchNotesFailure);
}

export function* watchFetchPublicNotesFailure() {
  yield takeEvery(getType(actions.fetchPublicNotes.failure), showFetchPublicNotesFailure);
}

export type CreateNoteAction = ActionType<typeof actions.createNote.request>;

export const createNote = (action: CreateNoteAction) => {
  if (action.payload.masterShipmentId) {
    // remove mode and shipment id when using master shipment id
    return axios({
      method: 'POST',
      url: `${API_PATH}${endpoints.NOTES}/create`,
      data: {
        ...action.payload,
        mode: undefined,
        shipmentId: undefined,
      },
      withCredentials: true,
    });
  }
  return axios({
    method: 'POST',
    url: `${API_PATH}${endpoints.NOTES}/create`,
    data: action.payload,
    withCredentials: true,
  });
};
export function* createNoteAsync(action: CreateNoteAction) {
  try {
    const response: AxiosResponse<Note> = yield call(createNote, action);
    trackEvent('CREATED_NOTE');
    yield put(actions.createNote.success(response.data));
  } catch (error) {
    yield put(actions.createNote.failure(error as Error));
  }
}

export function* watchCreateNote() {
  yield takeLatest(getType(actions.createNote.request), createNoteAsync);
}

export const showCreateNoteFailure = () => {
  message.error("We're having trouble creating note. Please try again.");
};

export function* watchCreateNoteFailure() {
  yield takeEvery(getType(actions.createNote.failure), showCreateNoteFailure);
}

// Update Notes

export type UpdateNoteAction = ActionType<typeof actions.updateNote.request>;

export const updateNote = (action: UpdateNoteAction) => {
  return axios({
    method: 'PUT',
    url: `${API_PATH}${endpoints.NOTES}/edit/${action.payload.noteId}`,
    data: action.payload,
    withCredentials: true,
  });
};
export function* updateNoteAsync(action: UpdateNoteAction) {
  try {
    const response: AxiosResponse<Note> = yield call(updateNote, action);
    trackEvent('UPDATED_NOTE');
    yield put(actions.updateNote.success(response.data));
  } catch (error) {
    yield put(actions.updateNote.failure(error as Error));
  }
}

export function* watchUpdateNote() {
  yield takeLatest(getType(actions.updateNote.request), updateNoteAsync);
}

export const showUpdateNoteSuccess = () => {
  message.success('Successfully updated note.');
};

export function* watchUpdateNoteSuccess() {
  yield takeEvery(getType(actions.updateNote.success), showUpdateNoteSuccess);
}

export const showUpdateNoteFailure = () => {
  message.error("We're having trouble updating note. Please try again.");
};

export function* watchUpdateNoteFailure() {
  yield takeEvery(getType(actions.updateNote.failure), showUpdateNoteFailure);
}

// Delete Notes

export type DeleteNoteAction = ActionType<typeof actions.deleteNote.request>;

export const deleteNote = (noteId: string) => {
  return axios({
    method: 'DELETE',
    url: `${API_PATH}${endpoints.NOTES}/delete/${noteId}`,
    withCredentials: true,
  });
};
export function* deleteNoteAsync(action: DeleteNoteAction) {
  try {
    yield call(deleteNote, action.payload);
    trackEvent('DELETED_NOTE');
    yield put(actions.deleteNote.success(action.payload));
  } catch (error) {
    yield put(actions.deleteNote.failure(error as Error));
  }
}

export function* watchDeleteNote() {
  yield takeLatest(getType(actions.deleteNote.request), deleteNoteAsync);
}

export const showDeleteNoteSuccess = () => {
  message.success('Successfully deleted note.');
};

export function* watchDeleteNoteSuccess() {
  yield takeEvery(getType(actions.deleteNote.success), showDeleteNoteSuccess);
}

export const showDeleteNoteFailure = () => {
  message.error("We're having trouble deleting note. Please try again.");
};

export function* watchDeleteNoteFailure() {
  yield takeEvery(getType(actions.deleteNote.failure), showDeleteNoteFailure);
}

export default function* noteOperations() {
  yield fork(watchFetchNotes);
  yield fork(watchFetchPublicNotes);
  yield fork(watchFetchNotesFailure);
  yield fork(watchCreateNote);
  yield fork(watchCreateNoteFailure);
  yield fork(watchUpdateNote);
  yield fork(watchUpdateNoteSuccess);
  yield fork(watchUpdateNoteFailure);
  yield fork(watchDeleteNote);
  yield fork(watchDeleteNoteSuccess);
  yield fork(watchDeleteNoteFailure);
}
