/* eslint-disable no-constant-condition */
import { useCallback, useEffect, useRef, useState } from 'react';

import * as React from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { DownOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { Input, Select } from 'ui-components';
import { primaryGreyEighty, primaryGrey } from 'styles/colors';
import * as styles from './CarrierSearchBar.module.scss';

export enum SearchServiceTypeEnum {
  CARRIER_FLEET_SEARCH = 'Carrier Types',
  DOT = 'DOT_NUMBER',
  SCAC = 'SCAC',
  MC = 'MC_NUMBER',
}

const StyledInput = styled(Input)`
  input,
  button {
    height: 36px !important;
    min-height: 36px !important;
  }

  background-color: #fff !important;
  .ant-input-group-addon {
    border: 0 !important;

    .select {
      border: 1px solid ${primaryGreyEighty};
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: 0;
      height: 36px;
      overflow: hidden;

      .ant-select-selector:hover,
      .ant-select-selector:active,
      .ant-select-selector:focus,
      .ant-select-focused .ant-select-selector {
        border-color: transparent !important;
      }
    }
  }

  input {
    border-left: ${(props) => (props.hasAdvancedLinking ? 0 : '1px solid')} !important;
    border-top: 1px solid var(--primary-grey-80) !important;
    border-bottom: 1px solid var(--primary-grey-80) !important;
    border-right: 1px solid var(--primary-grey-80) !important;
    border-radius: 4px;
  }

  .primary button {
    border-color: ${primaryGreyEighty};

    &:hover {
      border-color: ${primaryGreyEighty};
    }
  }
`;

const messages = defineMessages({
  carrierFleetSearchPlaceholder: {
    id: 'header.search.carrierFleetPlaceholder',
    defaultMessage: 'Search by DOT, MC, or SCAC',
  },
});
export interface CarrierSearchBarProps {
  searchTerm?: string;
  executeSearchFn: (serviceType?: string) => void;
  setSearchTerm?: (searchTerm: string) => void;
  dontSearchOnChange?: boolean;
  hasAdvancedLinking?: boolean;
}

const CarrierSearchBar: React.FunctionComponent<CarrierSearchBarProps> = ({
  searchTerm,
  executeSearchFn,
  setSearchTerm,
  dontSearchOnChange,
}) => {
  const initialSearchTerm = isEmpty(searchTerm?.trim()) ? [] : searchTerm?.split(' ');
  const initialSearchTermRef = useRef(initialSearchTerm);
  initialSearchTermRef.current = initialSearchTerm;

  const [serviceType, setServiceType] = useState<SearchServiceTypeEnum>(SearchServiceTypeEnum.CARRIER_FLEET_SEARCH);

  const [hasEmptyFields, setHasEmptyFields] = useState(false);

  const search = useCallback(() => {
    if (serviceType !== 'Carrier Types' && searchTerm?.trim() !== '') {
      executeSearchFn(serviceType);
      setHasEmptyFields(false);
    } else {
      setHasEmptyFields(true);
      return;
    }
  }, [executeSearchFn, serviceType, searchTerm]);

  useEffect(() => {
    setHasEmptyFields(serviceType === SearchServiceTypeEnum.CARRIER_FLEET_SEARCH && searchTerm?.trim() !== '');
  }, [searchTerm, serviceType]);

  const intl = useIntl();

  const debounceSearch = debounce(search, 500); // .5 second debounced delay when typing in search input

  const handleSearchOnChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (!evt) {
        return undefined;
      }
      const searchTerm = get(evt, 'currentTarget.value', '');
      setSearchTerm && setSearchTerm(searchTerm);
      setHasEmptyFields(serviceType === SearchServiceTypeEnum.CARRIER_FLEET_SEARCH && searchTerm.trim() === '');
      if (!dontSearchOnChange) {
        debounceSearch();
      }
    },
    [setSearchTerm, serviceType, dontSearchOnChange, debounceSearch]
  );

  const dataSourceArray = () => {
    return [
      {
        value: SearchServiceTypeEnum.DOT,
        displayValue: intl.formatMessage({ id: 'searchBar.searchType.DOT', defaultMessage: 'DOT' }),
      },
      {
        value: SearchServiceTypeEnum.SCAC,
        displayValue: intl.formatMessage({ id: 'searchBar.searchType.SCAC', defaultMessage: 'SCAC' }),
      },
      {
        value: SearchServiceTypeEnum.MC,
        displayValue: intl.formatMessage({ id: 'searchBar.searchType.MC', defaultMessage: 'MC' }),
      },
    ];
  };

  const selectBefore = (
    <Select
      value={serviceType}
      onChange={(value: SearchServiceTypeEnum) => setServiceType(value)}
      style={{ minWidth: '110px' }}
      suffixIcon={<DownOutlined style={{ color: primaryGrey, pointerEvents: 'none' }} />}
      defaultValue={serviceType}
      data-locator="searchDropDown"
      dataSource={dataSourceArray()}
    />
  );

  const placeholder = messages.carrierFleetSearchPlaceholder;

  return (
    <div className={`${styles.SearchBar} justify-content-start`}>
      {/* This label will not display in the UI, but will be available for ScreenReaders. */}
      <label className="sr-only" htmlFor="global-search">
        {
          <FormattedMessage
            id="carrierSearchHeader.carrierSearch.placeholder"
            defaultMessage="Search carriers by DOT, SCAC OR MC."
          />
        }
      </label>
      <StyledInput
        className="borderlessInput searchBarInput"
        search="primary"
        placeholder={intl.formatMessage(placeholder)}
        onChange={handleSearchOnChange}
        onSearch={search}
        onPressEnter={search}
        custom={{
          id: 'global-search',
          value: searchTerm,
          'data-locator': 'searchBarInput',
          addonBefore: selectBefore,
        }}
      />
      {hasEmptyFields && (
        <div className={styles.validationMessage}>
          <FormattedMessage
            defaultMessage="Enter search word and provide a carrier type"
            id="carrierFleet.carrierSearchBar.noInputInfo"
          />
        </div>
      )}
    </div>
  );
};

export default CarrierSearchBar;
