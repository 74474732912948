import { APILoader, Map, Provider } from '@uiw/react-baidu-map';
import get from 'lodash/get';
import { DistanceUnitsEnum, FenceType, LatLng } from '../models';
import EditBaiduLocationMarker from './EditBaiduLocationMarker';

interface State {
  fenceType: FenceType;
  timeBasedValue: number;
  distanceBasedValue: number;
  polygonClosed: boolean;
  customGeofence: any;
}

interface GeoJson {
  type: string;
  coordinates: any[];
}

interface EditBaiduLocationMapProps {
  latLng: LatLng;
  state: State;
  geoJson: GeoJson;
  setGeoJson: (geoJson: GeoJson) => void;
  baiduCustomPointsChange: number;
  defaultDistanceUnits: DistanceUnitsEnum;
}

const EditBaiduLocationMap = ({
  latLng,
  state,
  geoJson,
  setGeoJson,
  defaultDistanceUnits,
  baiduCustomPointsChange,
}: EditBaiduLocationMapProps) => {
  const baiduMapsChinaApiKey = get(window, 'portalGlobal.baiduMapsChinaApiKey') as unknown as string;
  const baiduPoint = { lat: latLng.latitude as number, lng: latLng.longitude as number };
  return (
    <APILoader akay={baiduMapsChinaApiKey}>
      <Provider>
        <Map center={baiduPoint} zoom={12} enableScrollWheelZoom widget={['NavigationControl', 'GeolocationControl']}>
          <EditBaiduLocationMarker
            baiduPoint={baiduPoint}
            state={state}
            geoJson={geoJson}
            setGeoJson={setGeoJson}
            defaultDistanceUnits={defaultDistanceUnits}
            baiduCustomPointsChange={baiduCustomPointsChange}
          />
        </Map>
      </Provider>
    </APILoader>
  );
};

export default EditBaiduLocationMap;
