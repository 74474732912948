import get from 'lodash/get';
import { PrincipalContextProps } from 'contexts/PrincipalContext';
import endpoints from './endpoints';

const developerUrlPrefixes = {
  prodNA: 'https://developers.project44.com',
  prodEU: 'https://developers.eu.project44.com',
  sandbox: 'https://developers.sandbox.project44.com',
};

function getNewDeveloperPortalUrl(pageName: string): string {
  const environmentName = get(window, 'portalGlobal.environmentName', '');
  switch (environmentName) {
    case 'qa-int':
    case 'qa-stg':
    case 'prod-na':
      return `${developerUrlPrefixes.prodNA}${pageName}/`;
    case 'prod-eu':
      return `${developerUrlPrefixes.prodEU}${pageName}/`;
    case 'sandbox':
      return `${developerUrlPrefixes.sandbox}${pageName}/`;
    // TODO add link for China once a URL for it exists
    default:
      return `${developerUrlPrefixes.prodNA}${pageName}/`;
  }
}

function getNewDocUrlOrOld(
  principalContext: PrincipalContextProps | undefined,
  current: string,
  pageName: string
): string {
  const useRedoclyApiDocs = principalContext?.featureFlags?.useRedoclyApiDocs;

  if (!useRedoclyApiDocs) {
    return current;
  } else {
    return getNewDeveloperPortalUrl(pageName);
  }
}

export function getApiDocUrl(principalContext: PrincipalContextProps | undefined) {
  return getNewDocUrlOrOld(principalContext, endpoints.API_DOCS, '');
}

export function getReferenceDataUrl(principalContext: PrincipalContextProps | undefined) {
  return getNewDocUrlOrOld(principalContext, endpoints.REFERENCE_DATA, '/api-reference/reference-codes');
}

export function getSdkUrl(principalContext: PrincipalContextProps | undefined) {
  return getNewDocUrlOrOld(principalContext, endpoints.SDKS, '/api-reference/sdks');
}
