import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';
import { trackEvent } from '.';

const eventPrefix = 'SL_FILTERED_BY';

export const trackSearchTerm = (searchTerm = '') => {
  if (searchTerm !== '') {
    trackEvent(`${eventPrefix}_SEARCH_TERM`, { label: searchTerm });
  } else {
    trackEvent(`${eventPrefix}_EMPTY_SEARCH_TERM`);
  }
};

export const trackEachFilterField = (filters: { [key: string]: boolean | string | string[] } = {}) => {
  try {
    if (!isNil(filters) && typeof filters === 'object') {
      Object.keys(filters).forEach((filterKey: string) => {
        const filter = filters[filterKey];
        if (!isNil(filter) && (!isEmpty(filter) || filter === true)) {
          if (
            filterKey === 'status' ||
            filterKey === 'arrivalForecast' ||
            filterKey === 'deliveryStatus' ||
            filterKey === 'oceanActiveHolds'
          ) {
            (filter as string[]).forEach((status: string) => {
              trackEvent(`${eventPrefix}_${filterKey.toUpperCase()}_${snakeCase(status).toUpperCase()}`);
            });
          } else if (filterKey === 'happeningCode') {
            trackEvent(`${eventPrefix}_SHIPMENTS_IN_TRANSIT`);
          } else {
            trackEvent(`${eventPrefix}_${filterKey.toUpperCase()}`);
          }
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
};
