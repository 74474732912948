import { FilterableByDateShipmentMode } from 'components/EventDatePicker/types';
import { SHIPMENT_MODE_EVENT_TYPES } from 'components/EventDatePicker/constants';
import { renderFiltersByMode as getFiltersByMode } from 'components/shipmentListComponent/verticalFilterComponent/Filters';
import { ModeType } from 'components/shipmentListComponent/verticalFilterComponent/utils';
import { DATE_FILTERS } from './constants';

export const composeFilters = (shipmentMode: ModeType = 'ALL', props: any) => {
  const baseFilters = getFiltersByMode(shipmentMode, props);

  if (['ALL', 'TL', 'LTL', 'OCEAN', 'AIR', 'PARCEL'].includes(shipmentMode)) {
    const baseFiltersCopy = baseFilters.slice();
    const deprecatedFilterIndex = baseFiltersCopy.findIndex((filter) => filter.modalId === 'event');

    if (deprecatedFilterIndex !== -1) {
      const extraFilters = SHIPMENT_MODE_EVENT_TYPES[shipmentMode as FilterableByDateShipmentMode].map((eventType) => {
        const dateFilter = DATE_FILTERS[eventType];
        return Object.assign({}, dateFilter, { modalId: `${shipmentMode}_${dateFilter.modalId}` });
      });
      baseFiltersCopy.splice(deprecatedFilterIndex, 1, ...extraFilters);
      return baseFiltersCopy;
    }
  }

  return baseFilters;
};
