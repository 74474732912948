import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import gte from 'lodash/gte';
import lodashMap from 'lodash/map';
import isEqual from 'lodash/isEqual';
import { PrincipalAuthorizations } from 'common/authorizations';
import { buildSetSortFieldAction } from 'components/common/sortResults/ducks';
import types from 'components/common/sortResults/ducks/types';
import { intl } from 'common/AppConstants';
import { locationListActions } from '../ducks';
import LocationListComponent from './LocationListComponent';
const authorizations = new PrincipalAuthorizations();
const hasLocationsV2Enabled = authorizations.hasLocationsV2EnabledAuthorization();

const transformResults = (locations = []) => {
  const transformedLocations = locations.map(({ location: loc, ...rest }) => {
    let transform = { ...loc };
    if (hasLocationsV2Enabled) {
      transform = {
        ...loc,
        address: {
          ...loc.address,
          addressLine1: get(loc, 'address.address1'),
          addressLine2: get(loc, 'address.address2'),
          zip: get(loc, 'address.postalCode'),
        },
        companyName: loc.name,
      };
    }
    return { location: transform, ...rest };
  });
  return transformedLocations;
};
const STARTING_INDEX = 0;

const componentWithFetchedData = compose(
  lifecycle({
    componentDidMount() {
      if (isEmpty(this.props.locations)) {
        //mounts once.
        this.props.getLocations(STARTING_INDEX, [{ fieldName: 'recentPerformance.totalShipments', direction: 'DESC' }]);
      }
      const defaultSortFieldsIndex = findIndex(types.LOCATION_SORT_FIELDS, [
        'displayName',
        intl.formatMessage({ id: 'sortResults.types.volumeDesc', defaultMessage: 'Shipment Volume (Desc)' }),
      ]);
      this.props.handleSortChange(
        buildSetSortFieldAction(
          types.SET_SORT_FIELDS,
          types.LOCATION_SORT_FIELDS,
          defaultSortFieldsIndex,
          types.LOCATION_LIST_SORT
        )
      );
    },
  })
)(LocationListComponent);

const mapStateToProps = (state) => {
  const sortFields = state.sortResultsReducer.sortFields;
  const selectedSortFieldId = state.sortResultsReducer.selectedSortFieldId;
  const locationSortRequestOptions = lodashMap(types.LOCATION_SORT_FIELDS, (item) => item.request);
  const index = findIndex(locationSortRequestOptions, (item) => isEqual(item, sortFields));
  const sortBy = gte(index, 0) ? sortFields : [{ fieldName: 'recentPerformance.totalShipments', direction: 'DESC' }];
  const sortIndex = gte(index, 0) ? selectedSortFieldId : 3;

  return {
    locations: transformResults(state.locationManagementReducer.locationListReducer.locations),
    searchTerm: state.locationManagementReducer.locationListReducer.searchTerm,
    contactSearchTerm: state.locationManagementReducer.locationListReducer.contactSearchTerm,
    initialLoading: state.locationManagementReducer.locationListReducer.initialLoading,
    isLoading: state.searchResultsReducer.isLoading,
    snackbarOpen: state.locationManagementReducer.locationListReducer.snackbarOpen,
    snackbarContent: state.locationManagementReducer.locationListReducer.snackbarContent,
    totalResults: state.locationManagementReducer.locationListReducer.totalResults,
    lastFetchedIndex: state.locationManagementReducer.locationListReducer.lastFetchedIndex,
    sortBy: sortBy,
    selectedSortFieldId: sortIndex,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLocations: (startingPage, sortBy, searchPhrase = '') =>
    hasLocationsV2Enabled
      ? dispatch(locationListActions.getLocations(startingPage, { ...sortBy }))
      : dispatch(locationListActions.getLocationsV1(startingPage, { ...sortBy })),
  closeSnackbar: () => dispatch(locationListActions.closeSnackbar()),
  handleSortChange: (sortFieldAction) => dispatch(sortFieldAction),
  setStateSearchTerm: (searchTerm) => dispatch(locationListActions.setSearchTerm(searchTerm)),
  setStateContactSearchTerm: (searchTerm) => dispatch(locationListActions.setContactSearchTerm(searchTerm)),
});

export default connect(mapStateToProps, mapDispatchToProps)(componentWithFetchedData);
