import * as React from 'react';
import Row from 'react-bootstrap/Row';

import ModeFilter from '../types/ModeFilter';
import StatusFilter from '../types/StatusFilter';
import { rail as railStatusFilterList } from './statusFilters';

const RailModeFiltersComponent: React.FunctionComponent = (props) => {
  return (
    <React.Fragment>
      <Row className="mb-4 d-lg-none">
        <ModeFilter {...props} />
      </Row>
      <Row className="mb-4">
        <StatusFilter {...props} menuItems={railStatusFilterList} />
      </Row>
    </React.Fragment>
  );
};

export default RailModeFiltersComponent;
