import { TEMPERATURE_SCALE } from './AppConstants';
import { TEMPERATURE_STATUS } from '../components/shipment/common/enums/temperatureStatus';

export const convertToScale = (scale, value) => {
  if (scale === TEMPERATURE_SCALE.FAHRENHEIT) {
    return tryConvert(value, toFahrenheit);
  }

  if (scale === TEMPERATURE_SCALE.CELSIUS) {
    return tryConvert(value, toCelsius);
  }
};

export const toCelsius = (fahrenheit) => {
  return ((fahrenheit - 32) * 5) / 9;
};

export const toFahrenheit = (celsius) => {
  return (celsius * 9) / 5 + 32;
};

const tryConvert = (value, convert) => {
  const data = parseFloat(value);
  if (Number.isNaN(data)) {
    return '';
  }
  const rounded = Math.round(Math.round(convert(data) * 1000) / 1000);
  return rounded.toString();
};

export const getTemperatureStatus = (currentTemp, lowerBound, upperBound) => {
  if (currentTemp >= lowerBound && currentTemp <= upperBound) {
    return TEMPERATURE_STATUS.NORMAL;
  }

  if (currentTemp > upperBound) {
    return TEMPERATURE_STATUS.OVER_MAX;
  }

  if (currentTemp < lowerBound) {
    return TEMPERATURE_STATUS.UNDER_MIN;
  }
};
