import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import axios from 'util/paxios';
import { Alert, SVGIconsLibrary, ThemeProvider as UiComponentsThemeProvider } from 'ui-components';
import SimpleLocalize from 'components/SimpleLocalize/SimpleLocalize';
import { SimpleLocalizeProvider } from 'components/SimpleLocalize/SimpleLocalizeContext';
import { PrincipalContext } from 'contexts/PrincipalContext';
import SupplyStackIFrames from 'components/slotBooking/SupplyStackIFrames/SupplyStackIFrames';
import { entityPageUrls } from 'common/AppConstants';
import { ThemeContext } from './contexts/ThemeContext';
import { OnboardingProvider } from './components/onboarding/OnboardingContext';
import Routes from './routes';

const isIE = navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode;

function useFeatureFlags(principal) {
  const [data, setData] = useState({});

  useEffect(() => {
    if (principal.id) {
      axios
        .get(`${entityPageUrls.entityPageGatewayUrl}/feature-flags`, {
          withCredentials: true,
        })
        .then((response) => {
          setData(response.data);
        })
        .catch(() => {
          setData({ useNewEntityPage: false });
        });
    }
  }, [principal.id]);

  return data;
}

function ContextWrapper({ principal, theme }) {
  const [tenantId, setTenantId] = useState(principal.tenantId);
  const featureFlags = useFeatureFlags(principal);
  const principalContextValue = useMemo(
    () => ({
      ...principal,
      setTenantId,
      featureFlags,
    }),
    [principal, featureFlags]
  );

  return (
    <SimpleLocalizeProvider>
      <SimpleLocalize
        projectToken={window.portalGlobal.simpleLocalizePublicToken}
        tenantId={principal.tenantId || tenantId}
      >
        <SupplyStackIFrames principal={principalContextValue}>
          <OnboardingProvider>
            <SVGIconsLibrary />
            <PrincipalContext.Provider value={principalContextValue}>
              <ThemeContext.Provider value={theme}>
                <UiComponentsThemeProvider theme={theme}>
                  <div className="App">
                    {isIE && (
                      <Alert
                        alertType="warning"
                        messageIntlId="global.alert.ieWarning"
                        messageDefaultText="Internet Explorer is not supported by this application. Please use a supported browser (Microsoft Edge, Google Chrome, Mozilla Firefox, and Apple Safari) for optimal experience."
                      />
                    )}
                    <Routes />
                  </div>
                </UiComponentsThemeProvider>
              </ThemeContext.Provider>
            </PrincipalContext.Provider>
          </OnboardingProvider>
        </SupplyStackIFrames>
      </SimpleLocalize>
    </SimpleLocalizeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    principal: state.authReducer.principal,
    theme: state.brandThemingReducer.savedTheme,
  };
};

export default connect(mapStateToProps, null)(ContextWrapper);
