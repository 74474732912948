/* eslint-disable react/display-name */
import * as React from 'react';
import get from 'lodash/get';
import mapKeys from 'lodash/mapKeys';
import moment from 'moment';
import { TablePaginationConfig } from 'antd/lib/table';
import { useIntl, FormattedMessage } from 'react-intl';
import Table from 'common/Table/Table';
import { MONTH_DAY_YEAR_FORMAT, HOURS_MINUTES_SECONDS_FORMAT } from 'i18n/configurei18n';
import {
  InventoryOrder,
  InventoryOrderTypeIntlKeys,
  InventoryOrderType,
  InventoryOrderStatusCode,
  InventoryOrderStatusCodeIntlKeys,
} from 'components/Inventory/models';
import { Tooltip } from 'ui-components';
import { DataLoadingEnum } from 'components/shipmentDetails/common/hooks/useInventoryOrders';
import { MAX_PAGE_QUANTITY } from 'common/AppConstants';
import { Details } from 'components/LocationDetails/LocationShipments/models';
import { mapShipment } from 'components/shipmentListComponent/mappers/mapShipment';
import { showStatusTag } from 'components/shipmentListComponent/shipmentSearchResultCard/ShipmentSearchResultCard';
import { formatNestedIntlValues } from 'i18n/utils';
import { PrincipalAuthorizations } from 'common/authorizations';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { useRelatedOders } from 'components/shipmentDetails/common/hooks/useRelatedOrders';

interface OrdersTableProps {
  inventoryOrderId: string;
}
const pageSize = 30;

const RelatedOrdersTable: React.FC<OrdersTableProps> = ({ inventoryOrderId }) => {
  const intl = useIntl();
  // const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const { searchOrders, orders, ordersDataLoading } = useRelatedOders({ hasPermission: true, inventoryOrderId });

  const principal = React.useContext(PrincipalContext);

  const authorizations = new PrincipalAuthorizations({
    authorities: get(principal, 'authorities') || [],
    principal,
  });

  const newShipmentEtaAndStatusColumns = [
    {
      dataIndex: 'shipments',
      key: 'shipmentsEta',
      render: function render(shipments: Details[]) {
        if (!shipments) return null;

        if (shipments.length === 0 || shipments.length > 1) return null;

        const shipment = mapShipment(shipments[0], intl);

        if (!shipment) return null;

        if (!(shipment.statusDescription && shipment.statusDescription.values)) return null;

        const values = mapKeys(shipment.statusDescription.values, (_, k) => k.toLowerCase());

        if (!(values.date && values.time)) return null;

        return (
          <FormattedMessage
            id={'inventory.ordersList.shipmentsEta.status'}
            defaultMessage={'{date} at {time}'}
            values={formatNestedIntlValues(get({ ...shipment.statusDescription, values }, 'values', {}), intl)}
          />
        );
      },
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.shipmentETA', defaultMessage: 'ETA' }),
      width: 155,
    },
    {
      dataIndex: 'shipments',
      key: 'shipmentsStatus',
      render: function render(shipments: Details[]) {
        if (!shipments) return null;

        if (shipments.length === 0) {
          return (
            <FormattedMessage id={'inventory.ordersList.shipmentsStatus.noShipments'} defaultMessage="No Shipments" />
          );
        }

        if (shipments.length > 1) {
          return (
            <FormattedMessage
              id={'inventory.ordersList.shipmentsStatus.manyShipments'}
              defaultMessage={'Many Shipments'}
            />
          );
        }

        const shipment = mapShipment(shipments[0], intl);

        if (!shipment) return null;

        if (!(shipment.flagText && shipment.flagColor)) return null;

        // @ts-ignore
        return showStatusTag(shipment, false);
      },
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.shipmentsStatus',
        defaultMessage: 'Transportation Status',
      }),
      width: 145,
    },
  ];

  const columns = [
    {
      dataIndex: 'orderType',
      key: 'orderType',
      className: 'data-locator-orderType',
      render: function render(inventoryOrderType: InventoryOrderType) {
        return (
          <FormattedMessage
            id={get(InventoryOrderTypeIntlKeys, `[${inventoryOrderType}].id`, ' ')}
            defaultMessage={get(InventoryOrderTypeIntlKeys, `[${inventoryOrderType}].defaultMessage`, ' ')}
          />
        );
      },
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.orderType', defaultMessage: 'Order Type' }),
      width: 195,
    },
    {
      dataIndex: 'orderIdentifier',
      key: 'id',
      ellipsis: true,
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.orderId', defaultMessage: 'Order ID' }),
      width: 175,
    },
    {
      dataIndex: 'statusCode',
      key: 'statusCode',
      render: function render(inventoryOrderStatusCode: InventoryOrderStatusCode) {
        return (
          <FormattedMessage
            id={get(InventoryOrderStatusCodeIntlKeys, `[${inventoryOrderStatusCode}].id`, ' ')}
            defaultMessage={get(InventoryOrderStatusCodeIntlKeys, `[${inventoryOrderStatusCode}].defaultMessage`, ' ')}
          />
        );
      },
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.status', defaultMessage: 'Status' }),
      width: 145,
    },
    ...(authorizations.hasEnableCustomerEmbeddedFeatures() ? newShipmentEtaAndStatusColumns : []),
    {
      dataIndex: 'orderSubmissionDateTime',
      key: 'orderSubmissionDateTime',
      title: function titleRender() {
        return (
          <Tooltip
            title={intl.formatMessage({
              id: 'inventory.ordersList.columnHeaders.orderSubmissionDate',
              defaultMessage: 'Order Submission Date',
            })}
          >
            <span>OSD</span>
          </Tooltip>
        );
      },
      width: 195,
      render: function renderFunc(text: string) {
        if (text === undefined || text === null) return '';

        return moment(text).format(`${MONTH_DAY_YEAR_FORMAT} ${HOURS_MINUTES_SECONDS_FORMAT}`);
      },
    },
    {
      dataIndex: ['supplierReadyDateTimeWindow', 'startDateTime'],
      key: 'supplierReadyDateTimeWindow',
      render: function renderFunc(text: string) {
        if (text === undefined || text === null) return '';

        return moment(text).format(`${MONTH_DAY_YEAR_FORMAT} ${HOURS_MINUTES_SECONDS_FORMAT}`);
      },
      title: function titleRender() {
        return (
          <Tooltip
            title={intl.formatMessage({
              id: 'inventory.ordersList.columnHeaders.supplierReadyDate',
              defaultMessage: 'Supplier Ready Date',
            })}
          >
            <span>SRD</span>
          </Tooltip>
        );
      },
      width: 195,
    },
    {
      dataIndex: ['originalDeliveryDateTimeWindow', 'endDateTime'],
      key: 'originalDeliveryDateTimeWindow.endDateTime',
      render: function renderFunc(text: string) {
        if (text === undefined || text === null) return '';

        return moment(text).format(`${MONTH_DAY_YEAR_FORMAT} ${HOURS_MINUTES_SECONDS_FORMAT}`);
      },
      title: function titleRender() {
        return (
          <Tooltip
            title={intl.formatMessage({
              id: 'inventory.ordersList.columnHeaders.originalDeliveryDate',
              defaultMessage: 'Original Delivery Date',
            })}
          >
            <span>ODD</span>
          </Tooltip>
        );
      },
      width: 195,
    },
    {
      dataIndex: ['vendorLocation', 'contact', 'companyName'],
      key: 'vendorName',
      title: intl.formatMessage({ id: 'inventory.ordersList.columnHeaders.vendorName', defaultMessage: 'Vendor Name' }),
      width: 175,
    },

    {
      dataIndex: ['destinationLocation', 'contact', 'companyName'],
      key: 'shipToName',
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.shipToName',
        defaultMessage: 'Ship To Name',
      }),
      width: 175,
    },

    {
      dataIndex: ['destinationLocation', 'address', 'addressLines', 0],
      key: 'shipToAddress',
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.shipToAddress',
        defaultMessage: 'Ship To Address',
      }),
      width: 175,
    },
    {
      dataIndex: ['destinationLocation', 'address', 'addressLines', 1],
      key: 'shipToAddress2',
      title: intl.formatMessage({
        id: 'inventory.ordersList.columnHeaders.shipToAddress2',
        defaultMessage: 'Ship To Address 2',
      }),
      width: 175,
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const page = pagination.current || 1;

    setCurrentPage(page);
  };

  const handlePaginationSize = (): number => {
    if (get(orders, 'paginationInfo.total', 0) / pageSize > MAX_PAGE_QUANTITY) {
      return pageSize * MAX_PAGE_QUANTITY;
    }

    return orders?.paginationInfo.total || 0;
  };

  return (
    <Table
      isDraggable
      loading={DataLoadingEnum.LOADING === ordersDataLoading}
      rowKey={(record) => record.id}
      dataSource={orders?.results}
      columns={columns}
      scroll={{ x: 1200, y: 640 }}
      onChange={handleTableChange}
      rowClassName={() => 'cursor-pointer'}
      onRow={(record: InventoryOrder) => {
        return {
          onClick: () => {
            const url = `/portal/v2/inventory/orders/${record.id}`;
            window.history.pushState({}, '', url);
            window.location.replace(url);
          },
        };
      }}
      pagination={{
        pageSize: pageSize,
        total: handlePaginationSize(),
        showSizeChanger: false,
        current: currentPage,
        onChange: (page, size = pageSize) => {
          searchOrders(page, size);
        },
      }}
      size="middle"
      showSorterTooltip={false}
    />
  );
};

export default RelatedOrdersTable;
