import { useEffect, useState } from 'react';
import get from 'lodash/get';

import { FormattedMessage, useIntl } from 'react-intl';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { Container, Col, Row } from 'react-bootstrap';

import Meta from 'antd/lib/card/Meta';
import styled from 'styled-components';
import endpoints from 'common/endpoints';
import { API_PATH } from 'common/AppConstants';
import axios from 'util/paxios';

import { primaryGreyThirtyTwo } from 'styles/colors';
import { Button, Modal } from 'ui-components';
import CollapsableItem from 'components/common/ListPageLayout/CollapsableItem';
import settingsRoutes from '../settingsRoutes';
import SettingsHeader from '../SettingsHeader/SettingsHeader';
import SettingsNavigation from '../SettingsNavigation/SettingsNavigation';
import AddModal from './AddModal';
import {
  ProcessedRule,
  ReadableShipmentPropertyKeysIntlKeys,
  ReadableShipmentPropertyNamesIntlKeys,
  ReducedEntitlementRules,
  RuleProperty,
  ShareRegionsEnum,
} from './models';

const StyledPage = styled('div')`
  display: flex;
  justify-content: center;
  margin: 23px 74px 32px;
`;
const StyledActionButtons = styled('div')`
  display: flex;
  justify-content: space-around;
  width: 25%;
`;

const EntitlementRulesContainer = () => {
  const [error, setError] = useState();
  const [rules, setRules] = useState([]);
  const [processedRules, setProcessedRules] = useState<{} | ProcessedRule>({});
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [modalError, setModalError] = useState<{ errorMessage: string; type: string } | null>(null);
  const [deleteModalInfo, setDeleteModalInfo] = useState<null | { rule: ProcessedRule; ruleId: string }>(null);
  const intl = useIntl();
  const fetchEntitlementRules = () => {
    axios({
      method: 'GET',
      url: `${API_PATH}${endpoints.ENTITLEMENT_RULES}`,
      withCredentials: true,
    })
      .then((resp) => {
        if (resp.status === 200) setRules(resp.data);
        else setError(resp.data);
      })
      .catch((err) => {
        setError(err);
      });
  };
  const createEntitlementRule = ({
    entitleeId,
    entitleeType,
    shipmentProperties,
    excludedData,
    entitleeRegion,
  }: {
    entitleeId: string;
    entitleeType: string;
    shipmentProperties: RuleProperty[];
    excludedData: string[];
    entitleeRegion: ShareRegionsEnum;
  }) => {
    axios({
      method: 'POST',
      url: `${API_PATH}${endpoints.ENTITLEMENT_RULES}`,
      withCredentials: true,
      data: { entitleeId, entitleeType, shipmentProperties, excludedData, entitleeRegion },
    })
      .then((resp) => {
        fetchEntitlementRules();
        setModalError(null);
        setAddModalOpen(false);
      })
      .catch((err) => {
        if (err.response.data.errors[0].message.includes(`found CompanyUid to entitleeId: ${entitleeId}`))
          setModalError({ errorMessage: `Invalid tenant id: ${entitleeId}`, type: 'tenant' });
        else if (err.response.data.errors[0].message.includes(`Could not delete invitation`))
          setModalError({ errorMessage: err.response.data.errors[0].supportRefId, type: 'invitation' });
        console.error(err);
      });
  };
  const deleteEntitlementRule = (ruleId: string) => {
    axios({
      method: 'DELETE',
      url: `${API_PATH}${endpoints.ENTITLEMENT_RULES}/${ruleId}`,
      withCredentials: true,
    })
      .then((resp) => {
        fetchEntitlementRules();
        setDeleteModalInfo(null);
        return resp.data;
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    fetchEntitlementRules();
  }, []);
  useEffect(() => {
    const entitlementRules: ReducedEntitlementRules = {};
    rules.forEach(
      (element: {
        id: string;
        tenantId: string;
        entitleeName: string;
        entitleeType: string;
        entitleeId: string;
        createdDate: string;
        createdById: string;
        invitationToken: string;
        lastModifiedDate: string;
        lastModifiedById: string;
      }) => {
        if (entitlementRules[element.entitleeId]) entitlementRules[element.entitleeId].rules.push(element);
        else {
          entitlementRules[element.entitleeId] = { name: '', rules: [] };
          entitlementRules[element.entitleeId].name = element.entitleeName ? element.entitleeName : element.entitleeId;
          entitlementRules[element.entitleeId].rules = [element];
        }
      }
    );
    setProcessedRules(entitlementRules);
  }, [rules]);

  const ShipmentProperty = ({ property }: { property: RuleProperty }) => (
    <div key={property.shipmentPropertyName + property.shipmentPropertyValue}>
      <li>
        <strong>
          <FormattedMessage defaultMessage="Key" id="entitlementRules.shipmentRules.keys.key" />:{' '}
        </strong>
        <FormattedMessage {...ReadableShipmentPropertyKeysIntlKeys[property.shipmentPropertyKey]} />
      </li>
      <li>
        <strong>
          <FormattedMessage defaultMessage="Name" id="entitlementRules.shipmentRules.keys.name" />:{' '}
        </strong>
        <FormattedMessage {...ReadableShipmentPropertyNamesIntlKeys[property.shipmentPropertyName]} />
      </li>
      <li>
        <strong>
          <FormattedMessage defaultMessage="Value" id="entitlementRules.shipmentRules.keys.value" />:
        </strong>{' '}
        {property.shipmentPropertyValue}
      </li>
    </div>
  );
  const ShipmentPropertiesListWithTitle = ({ rule }: { rule: ProcessedRule }) => {
    if (rule.shipmentProperties?.length)
      return (
        <div>
          <strong>
            <FormattedMessage
              id="entitlemenRules.rules.titles.shipmentProperties"
              defaultMessage="Shipment properties"
            />
          </strong>
          <ul>
            {rule.shipmentProperties?.map((property: RuleProperty) => (
              <ShipmentProperty
                property={property}
                key={`${property.shipmentPropertyKey}-${property.shipmentPropertyName}-${property.shipmentPropertyValue}`}
              />
            ))}
          </ul>
        </div>
      );
    return null;
  };
  const DeleteModal = ({ rule }: { rule: ProcessedRule }) => (
    <Modal
      keyboard={false}
      closable={false}
      onCancel={() => deleteEntitlementRule(rule.id)}
      visible
      onOk={() => setDeleteModalInfo(null)}
      okText={<FormattedMessage defaultMessage="No, keep it" id="entitlementRules.rules.modals.delete.buttons.keep" />}
      cancelText={
        <FormattedMessage defaultMessage="Yes, delete it" id="entitlementRules.rules.modals.delete.buttons.delete" />
      }
    >
      <div>
        <h3>
          <FormattedMessage
            defaultMessage="Are you sure you’d like to delete this Rule?"
            id="entitlementRules.rules.modals.titles.deleteSure"
          />
        </h3>
        <p>
          <FormattedMessage
            defaultMessage="Clicking YES below will remove the following Sharing Rule:"
            id="entitlementRules.rules.modals.titles.deleteWarning"
          />
        </p>
        <Rule rule={rule} />
      </div>
    </Modal>
  );
  const Title = () => (
    <Row>
      <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Meta title="Sharing Rules" />
      </Col>
      <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Button type="primary" onClick={() => setAddModalOpen(true)}>
          <FormattedMessage defaultMessage="Add Sharing Rule" id="entitlementRules.rules.buttons.add" />
        </Button>
      </Col>
    </Row>
  );
  const Rule = ({ rule }: { rule: ProcessedRule }) => (
    <>
      <p>
        <strong>
          <FormattedMessage defaultMessage="Type" id="entitlementRules.rules.keys.type" />
        </strong>{' '}
        {rule.entitleeType}
      </p>
      <p>
        <strong>
          <FormattedMessage defaultMessage="Account Number" id="entitlementRules.rules.keys.accountNumber" />:
        </strong>{' '}
        {rule.entitleeId}
      </p>
      <ShipmentPropertiesListWithTitle rule={rule} />
    </>
  );
  const RulesList = () => (
    <>
      {Object.keys(processedRules)?.map((ruleId: string, index: number) => (
        <CollapsableItem
          panelTitle={`${(processedRules as ReducedEntitlementRules)[ruleId].name}`}
          dataKey={`${ruleId}-${index}`}
          // this should uniquely identify the element, making this warning reasonable to suppress
          // eslint-disable-next-line react/no-array-index-key
          key={`${ruleId}-${index}`}
        >
          {(processedRules as ReducedEntitlementRules)[ruleId].rules.map((rule: any) => (
            <Row key={rule.invitationToken}>
              <Col>
                <Rule rule={rule} />
              </Col>
              <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <StyledActionButtons>
                  <button type="button" style={{ border: 'none', backgroundColor: 'none' }} id={`edit-${rule.id}`}>
                    <EditFilled color={primaryGreyThirtyTwo} alt={`Edit rule ${rule.id}`} />
                  </button>
                  <button
                    type="button"
                    id={`"delete-"${rule.id}`}
                    data-testid={`Delete rule ${rule.id}`}
                    style={{ border: 'none', backgroundColor: 'none' }}
                    onClick={() => setDeleteModalInfo({ ruleId: ruleId, rule })}
                  >
                    <DeleteFilled color={primaryGreyThirtyTwo} alt={`Delete rule ${rule.id}`} />
                  </button>
                </StyledActionButtons>
              </Col>
            </Row>
          ))}
        </CollapsableItem>
      ))}
    </>
  );
  const RulesDisplay = () => {
    let display;
    if (error)
      display = (
        <p>
          <FormattedMessage defaultMessage="Error getting rules" id="entitlementRules.notices.errorFetching" />
        </p>
      );
    else if (Object.keys(processedRules).length) display = <RulesList />;
    else
      display = (
        <p className="emptyRulesText">
          <FormattedMessage defaultMessage="There are no rules" id="entitlementRules.notices.emptyRules" />
        </p>
      );

    return <>{display}</>;
  };
  return (
    <>
      <SettingsHeader
        currentPageTitle={intl.formatMessage({
          id: get(settingsRoutes, 'entitlementRules.label.id', '') as string,
          defaultMessage: get(settingsRoutes, 'entitlementRules.label.defaultMessage', ' ') as string,
        })}
        showSaveButton={false}
        showDiscardButton={false}
      />
      <StyledPage>
        {deleteModalInfo && <DeleteModal rule={deleteModalInfo.rule} />}
        {addModalOpen && (
          <AddModal
            setAddModalOpen={setAddModalOpen}
            createEntitlementRule={createEntitlementRule}
            modalError={modalError}
            clearModalError={() => setModalError(null)}
          />
        )}
        <SettingsNavigation currentPath={window.location.pathname} />
        <Container fluid>
          <Row>
            <Col xs={2} />
            <Col>
              <Title />
              <Row>
                <Col>
                  <RulesDisplay />
                </Col>
              </Row>
            </Col>
            <Col xs={4} />
          </Row>
        </Container>
      </StyledPage>
    </>
  );
};

export default EntitlementRulesContainer;
