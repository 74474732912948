import { createContext } from 'react';
import { CarrierNetworkRoles } from 'models';

export interface PrincipalResponse {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  companyName: string;
  tenantId: number;
  tenantUuid: string;
  tenantCarrierNetworkRoles: CarrierNetworkRoles[];
  accessGroupIds: string[];
  authorities: { authority: string }[];
  customerProperties: { [key: string]: string | number | boolean | null | undefined };
  userProperties: { [key: string]: string };
  fullDataAccess: boolean;
  interfacePreference: any;
  locale: string | null;
}

export interface PrincipalContextProps extends Partial<PrincipalResponse> {
  featureFlags?: { [key: string]: boolean };
  setTenantId: (tenantId: string) => void;
}

// Used to make PrincipalContext value never return undefined
const defaultDummy: PrincipalContextProps = {
  featureFlags: {},
  setTenantId: () => {
    /* empty */
  },
};

export const PrincipalContext = createContext<PrincipalContextProps>(defaultDummy);
PrincipalContext.displayName = 'PrincipalContext';
