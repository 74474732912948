import isNil from 'lodash/isNil';
import * as React from 'react';
import styled from 'styled-components';

import { DerivedStatus, ShipmentSearchResultStop, ShipmentStopStatusEnum } from 'models';
import * as styles from './minimalStatusBar.module.scss';
import { bluish, primaryGrey500, primaryGreyTwenty } from '../../../styles/colors';
import { statusOptions } from '../../shipmentDetails/LTL/ducks/mappers/routeDetails/enums';

interface StopDotProps {
  arrived?: boolean; // TODO: deprecate after #intermodal turnover
  stopStatus?: ShipmentStopStatusEnum; // TODO: stopStatus will be required after #intermodal turnover
  isNotReceivingData?: boolean;
}

export const StopDot = styled.div<StopDotProps>`
  height: 10px;
  width: 12px;
  border-radius: 50%;
  background-color: ${(props) => getStopDotColor(props.stopStatus, props.arrived, props.isNotReceivingData)};
  z-index: 2;
`;

// TODO: arrived param can be removed after #intermodal turnover
export const getStopDotColor = (
  stopStatus?: ShipmentStopStatusEnum,
  arrived?: boolean,
  isNotReceivingData?: boolean
): string => {
  if (stopStatus === ShipmentStopStatusEnum.ARRIVED || stopStatus === ShipmentStopStatusEnum.DEPARTED || arrived) {
    return isNotReceivingData ? primaryGrey500 : bluish;
  }
  return primaryGreyTwenty;
};

interface StopLineContainerProps {
  widthPercentage: number;
}

const StopLineContainer = styled.div<StopLineContainerProps>`
  height: 4px;
  background: ${primaryGreyTwenty};
  width: ${(props) => props.widthPercentage}%;
`;

interface StopLineProps {
  percentComplete: number;
  isNotReceivingData: boolean;
}

const StopLine = styled.div<StopLineProps>`
  height: 4px;
  background: ${(props) => (props.isNotReceivingData ? primaryGrey500 : bluish)};
  width: ${(props) => props.percentComplete}%;
`;

interface MinimalStatusBarProps {
  // TODO: after #intermodal turnover, deprecate percentComplete in favor of shipmentStops
  percentComplete?: number;
  shipmentStops?: ShipmentSearchResultStop[];
  derivedStatus?: any | undefined;
}

const MinimalStatusBar: React.FC<MinimalStatusBarProps> = ({ percentComplete, shipmentStops, derivedStatus }) => {
  const trackingHasBegun: boolean = !isNil(percentComplete) && percentComplete > 0;
  const trackingIsComplete: boolean = !isNil(percentComplete) && percentComplete === 100;

  const stopStatusBarWidth = shipmentStops ? 100 / (shipmentStops.length - 1) : 100;

  const getStopPercentComplete = (stop: ShipmentSearchResultStop): number => {
    if (stop.status === ShipmentStopStatusEnum.EN_ROUTE || stop.status === ShipmentStopStatusEnum.OUT_FOR_DELIVERY) {
      return 50;
    } else if (stop.status === ShipmentStopStatusEnum.ARRIVED || stop.status === ShipmentStopStatusEnum.DEPARTED) {
      return 100;
    }
    return 0;
  };

  const isNotReceivingData = (derivedStatus: DerivedStatus | undefined) => {
    if (derivedStatus) {
      const NOT_RECEIVING_DATA = [
        statusOptions.DERIVED.NOT_RECEIVING_DATA_12_TO_23,
        statusOptions.DERIVED.NOT_RECEIVING_DATA_24_TO_47,
        statusOptions.DERIVED.NOT_RECEIVING_DATA_48_TO_71,
        statusOptions.DERIVED.NOT_RECEIVING_DATA_MORE_THAN_72,
      ];

      return NOT_RECEIVING_DATA.includes(derivedStatus.toString());
    }
    return false;
  };

  return (
    <div className={styles.container} data-locator="minimal-status-bar">
      {/* TODO: remove this top status bar after #intermodal turnover when all modes have shipmentStops available on the shipment list page */}
      {!shipmentStops && (
        <React.Fragment>
          <StopDot isNotReceivingData={isNotReceivingData(derivedStatus)} arrived={trackingHasBegun} />
          <StopLineContainer widthPercentage={100}>
            <StopLine isNotReceivingData={isNotReceivingData(derivedStatus)} percentComplete={percentComplete || 0} />
          </StopLineContainer>
          <StopDot arrived={trackingIsComplete} />
        </React.Fragment>
      )}

      {!!shipmentStops &&
        shipmentStops.map((stop) => {
          const isFirstStop = stop.stopNumber === 1;

          return (
            <React.Fragment key={`${stop.stopName}-${stop.id}`}>
              {!isFirstStop && (
                <StopLineContainer widthPercentage={stopStatusBarWidth} data-locator="minimal-stop-line-container">
                  <StopLine
                    isNotReceivingData={isNotReceivingData(derivedStatus)}
                    percentComplete={getStopPercentComplete(stop)}
                    data-locator="minimal-stop-line"
                  />
                </StopLineContainer>
              )}
              <StopDot stopStatus={stop.status} data-locator="minimal-stop-dot" />
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default MinimalStatusBar;
