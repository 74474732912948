import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import './fleetListHeaderComponent.scss';
import { Link } from 'react-router-dom';
import goBackTo from '../../common/assets/go_back_to_24px.svg';

const FleetListHeaderComponent = ({ carrierName }) => {
  return (
    <>
      <Link to={`/inventory/carrierFleet`}>
        <FormattedMessage id="carrierFleet.fleetListHeader.backToFindACarrier" defaultMessage="Back to Find a Carrier">
          {(...text) => (
            <span className="backToFindCarrier">
              <img
                alt={'Back to find a carrier icon'}
                src={goBackTo}
                className={`d-none d-sm-none d-md-flex`}
                style={{ float: 'right', textAlign: 'right', marginRight: '4px', marginLeft: '1px' }}
              />{' '}
              <span>{text}</span>
            </span>
          )}
        </FormattedMessage>
      </Link>
      <Row as={'header'} noGutters className="FleetListHeaderComponent">
        <Col as={'h1'} xs={'auto'}>
          {carrierName}
        </Col>
      </Row>
    </>
  );
};

export default FleetListHeaderComponent;
