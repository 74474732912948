import result from 'lodash/result';
import head from 'lodash/head';
import { InventoryItem, InventoryIdentifierType } from 'components/Inventory/models';

export const getInventoryIdentifierValue = (
  identifier: InventoryIdentifierType | undefined,
  item: InventoryItem | undefined
): string => {
  const departmentIdentifierArray = item?.inventoryIdentifiers?.filter(
    (identifiers) => identifiers.type === identifier
  );
  const departmentIdentifierObj = head(departmentIdentifierArray);
  return result(departmentIdentifierObj, 'value');
};

export const getInventoryIdentifierDescription = (
  identifier: InventoryIdentifierType | undefined,
  item: InventoryItem | undefined
): string => {
  const departmentIdentifierArray = item?.inventoryIdentifiers?.filter(
    (identifiers) => identifiers.type === identifier
  );
  const departmentIdentifierObj = head(departmentIdentifierArray);
  return result(departmentIdentifierObj, 'description');
};
