import { injectIntl, FormattedMessage } from 'react-intl';

import CheckList from '../../common/checkList/CheckList';
import filterMessages from '../modes/filterMessages.json';

const DeliveryForecastFilter = (props) => {
  const messages = props.isShipmentListFiltersHorizontalEnabled
    ? {
        deliveredEarly: filterMessages.early,
        deliveredOnTime: filterMessages.onTime,
        deliveredLate: filterMessages.late,
      }
    : {
        deliveredEarly: filterMessages.deliveredEarly,
        deliveredOnTime: filterMessages.deliveredOnTime,
        deliveredLate: filterMessages.deliveredLate,
      };

  const deliveryForecastFilters = [
    {
      id: filterMessages.deliveredEarly.id,
      dataKey: filterMessages.deliveredEarly.defaultMessage,
      labelText: <FormattedMessage {...messages.deliveredEarly} />,
      propertyName: 'deliveredEarly',
      checked: false,
    },
    {
      id: filterMessages.deliveredOnTime.id,
      dataKey: filterMessages.deliveredOnTime.defaultMessage,
      labelText: <FormattedMessage {...messages.deliveredOnTime} />,
      propertyName: 'deliveredOnTime',
      checked: false,
    },
    {
      id: filterMessages.deliveredLate.id,
      dataKey: filterMessages.deliveredLate.defaultMessage,
      labelText: <FormattedMessage {...messages.deliveredLate} />,
      propertyName: 'deliveredLate',
      checked: false,
    },
  ];

  const checkListData = deliveryForecastFilters.map((forecast) => ({
    ...forecast,
    checked: props.filter.deliveryStatus.includes(forecast.dataKey),
  }));

  // TODO: Refactor checkbox reducer methods to not use `labelText` as a data-key
  const onCheck = (checkboxItem) =>
    props.handleCheckboxGroupUpdate({ ...checkboxItem, labelText: checkboxItem.dataKey }, 'deliveryStatus');

  const listTitle = props.isShipmentListFiltersHorizontalEnabled
    ? filterMessages.actualDelivery
    : filterMessages.deliveryStatus;

  return (
    <CheckList
      mode={props.checkboxTheme}
      listTitle={props.intl.formatMessage(listTitle)}
      checkListData={checkListData}
      checkFn={onCheck}
      data-locator="delivery-forecast-filter"
      checkboxGroupFlow={props.layoutFlow}
    />
  );
};

export default injectIntl(DeliveryForecastFilter);
