import { LOCATION_CHANGE } from 'connected-react-router';
import get from 'lodash/get';

import { combineReducers } from 'redux';
import searchTypes from '../../common/search/ducks/types';
import types from './types';

export const locationDetailInitialState = {
  initialValues: {
    businessHours: [{}], // setting empty object in array for business hours to generate form
    geofence: {
      distanceValue: undefined,
      timeValue: 15,
      type: 'TIME',
    },
  },
  locationDetails: {},
};

export const locationListInitialState = {
  locations: [],
  locationSuggestions: [],
  initialLoading: true,
  snackbarOpen: false,
  snackbarContent: '',
};

const locationDetailsReducer = (state = locationDetailInitialState, action = {}) => {
  switch (action.type) {
    case types.LOCATION_DETAIL_GET_SUCCESS:
      return { ...state, locationDetails: get(action.payload, 'locationDetails') };
    case types.LOCATION_DETAIL_GET_FAILURE:
      return { ...state, error: action.error };
    case types.LOCATION_DETAIL_NEW:
      return { ...state, locationDetails: state.initialValues };
    case types.LOCATION_DETAIL_RESET:
      return {
        ...state,
        locationDetails: { ...locationDetailInitialState.locationDetails },
      };
    default:
      return state;
  }
};

const locationListReducer = (state = locationListInitialState, action = {}) => {
  switch (action.type) {
    case types.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case types.SET_CONTACT_SEARCH_TERM:
      return {
        ...state,
        contactSearchTerm: action.payload,
      };
    case types.LOCATION_LIST_GET_SUCCESS:
      return {
        ...state,
        locations: get(action.payload, 'reSorted')
          ? get(action.payload, 'locations')
          : state.locations.concat(get(action.payload, 'locations')),
        totalResults: get(action.payload, 'totalResults'),
        initialLoading: false,
        lastFetchedIndex: get(action.payload, 'lastFetchedIndex'),
      };
    case types.LOCATION_LIST_GET_FAILURE:
      return { ...state, error: action.error };
    case types.LOCATION_SUGGESTIONS_GET_SUCCESS:
      return { ...state, locationSuggestions: get(action.payload, 'locations') };
    case types.LOCATION_SUGGESTIONS_CLEAR:
      return { ...state, locationSuggestions: [] };
    case types.LOCATION_UPDATE_SUCCESS:
      return { ...state, snackbarOpen: true, snackbarContent: 'Location was successfully added.' };
    case types.LOCATION_UPDATE_FAILURE:
      return { ...state, error: action.error };
    case types.LOCATION_CREATE_SUCCESS:
      return { ...state, snackbarOpen: true, snackbarContent: 'Location was successfully updated.' };
    case types.LOCATION_LIST_CLOSE_SNACKBAR:
      return { ...state, snackbarOpen: false };
    case searchTypes.SET_TYPE:
      return {
        ...state,
        locations: action.results,
        totalResults: action.totalResults,
        isLoading: true,
        // searchTerm: action.searchTerm,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        ...locationListInitialState,
      };
    default:
      return state;
  }
};

const locationManagementReducer = combineReducers({
  locationDetailsReducer: locationDetailsReducer,
  locationListReducer: locationListReducer,
});

export default locationManagementReducer;
