import { useReducer, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { useHistory } from 'react-router';

import { Nullable } from 'typesUtils';
import { Switch, Radio } from 'ui-components';

import HealthRange from '../HealthRange';
import { HealthRanges, HealthScoreConfig as HealthScoreConfigType, TNumberFormat } from '../types';
import * as styles from './styles.module.scss';
import { healthScoreReducer, initState } from './reducer';
import { getStoredNumber } from '../TitleAndSettingsStep/utils';
import { QUICKVIEW_TOTAL_COUNT } from '../constants';
import { HealthRangesTarget } from './types';
import { getEntityTypeFromSearch, getEntityTypeMessages } from '../utils';
interface ConfigProps {
  target: HealthRangesTarget;
  textName: string;
  iconName: string;
  accentColor: 'green' | 'blue' | 'orange' | 'red';
}

const CONFIG: Array<ConfigProps> = [
  {
    target: 'excellent',
    textName: 'Excellent',
    iconName: 'thumb-up',
    accentColor: 'green',
  },
  {
    target: 'acceptable',
    textName: 'Acceptable',
    iconName: 'check-circle',
    accentColor: 'blue',
  },
  {
    target: 'concerning',
    textName: 'Concerning',
    iconName: 'warning',
    accentColor: 'orange',
  },
  {
    target: 'problematic',
    textName: 'Problematic',
    iconName: 'error',
    accentColor: 'red',
  },
];

interface HealthScoreConfigProps {
  value: HealthScoreConfigType;
  onChange?: (isEnabled: boolean, ranges: HealthRanges, numberFormat: TNumberFormat) => void;
}

const HealthScoreConfig = ({ value, onChange }: HealthScoreConfigProps) => {
  const [{ isEnabled, ranges, numberFormat, rangeKeys }, dispatch] = useReducer(healthScoreReducer, value, initState);
  const total = getStoredNumber(QUICKVIEW_TOTAL_COUNT);
  const isPercentageDisabled = !total;

  const location = useLocation();
  const entityType = getEntityTypeFromSearch(location.search);

  const handleToggle = (value: boolean) => {
    dispatch({ type: 'SET_HEALTH_SCORE_ENABLED', payload: value });
  };

  const handleChangeRange =
    (target: HealthRangesTarget) => (lowLimit: Nullable<number>, highLimit: Nullable<number>) => {
      dispatch({ type: 'SET_RANGE_VALUES', payload: { target, values: { lowLimit, highLimit } } });
    };

  const handleToggleRange = (target: HealthRangesTarget) => (value: boolean) => {
    dispatch({ type: 'TOGGLE_RANGE', payload: { target, value } });
  };

  const handleSwapRanges = () => {
    dispatch({ type: 'SWAP_END_RANGES' });
  };

  const handleToggleFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'TOGGLE_NUMBER_FORMAT', payload: event.target.value as TNumberFormat });
  };

  useEffect(() => {
    onChange?.(isEnabled, ranges, numberFormat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled, ranges, numberFormat]);

  useEffect(() => {
    if (isPercentageDisabled) {
      dispatch({
        type: 'SET_HEALTH_SCORE_DISABLED',
        payload: {
          numberFormat: 'COUNT',
        },
      });
    }
  }, [isPercentageDisabled]);

  const enabledRanges = rangeKeys.filter((item: HealthRangesTarget) => ranges[item].isEnabled);
  const shouldRangesDisabled = enabledRanges.length < 3;
  const history = useHistory();

  const entityTypeMessages = getEntityTypeMessages(entityType);

  const goToTotalStep = () => history.push(`/configure-quickview/total?entityType=${entityType}`);

  const gotoButton = (chunks: string) => (
    <button className={cn(styles['btn-link-step'])} onClick={goToTotalStep}>
      {chunks}
    </button>
  );

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <label htmlFor="quickview-health-score" className={styles['label']}>
          {<FormattedMessage id="quickViewConfig.label.healthScore" defaultMessage="Health Score" />}
        </label>
        <Switch id="quickview-health-score" checked={isEnabled} onChange={handleToggle} />
      </div>
      <p className="mb-5 text-justify">
        <FormattedMessage
          id={entityTypeMessages.healthScoreMessageId}
          defaultMessage={entityTypeMessages.healthScoreMessage}
        />
      </p>
      <div className="mb-4">
        <p className={cn(styles['font-semibold'], 'mb-4')}>
          <FormattedMessage id="quickViewConfig.title.healthRanges" defaultMessage="Health Ranges" />
        </p>
        <label htmlFor="quickview-score-type" className="mr-3">
          {<FormattedMessage id="quickViewConfig.label.scoreType" defaultMessage="Define as:" />}
        </label>
        <Radio
          disabled={isPercentageDisabled}
          value={numberFormat}
          id="quickview-score-type"
          onChange={handleToggleFormat}
          radioData={[
            {
              value: 'PERCENTAGE',
              label: (
                <FormattedMessage
                  id="quickViewConfig.healthRanges.scoreType.percentage"
                  defaultMessage="Percentage of Total"
                />
              ),
            },
            {
              value: 'COUNT',
              label: (
                <FormattedMessage id="quickViewConfig.healthRanges.scoreType.count" defaultMessage="Subtotal Count" />
              ),
            },
          ]}
        />
        {isPercentageDisabled && (
          <p className={cn('mt-4', 'mb-0')}>
            <FormattedMessage
              id="quickViewConfig.percentageDisabled.configureAsPercentage"
              defaultMessage="To configure as percentage, complete <button>step 2</button>."
              values={{
                button: gotoButton,
              }}
            />
          </p>
        )}
      </div>
      {isEnabled && (
        <div className="mb-5">
          {CONFIG.map(({ target, textName, iconName, accentColor }) => (
            <HealthRange
              key={target}
              textName={textName}
              iconName={iconName}
              accentColor={accentColor}
              lowLimit={ranges[target].lowLimit}
              highLimit={ranges[target].highLimit}
              isActive={ranges[target].isEnabled}
              onSwapLimits={handleSwapRanges}
              onToggle={handleToggleRange(target)}
              onChange={handleChangeRange(target)}
              numberFormat={numberFormat}
              isDisabled={shouldRangesDisabled}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default HealthScoreConfig;
