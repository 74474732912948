import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import OrdersTable from 'components/ordersList/OrdersTable';
import { Popover } from 'ui-components';

const StyledSubHeader = styled('p')`
  font-size: 16px;
`;

const BlockStyledSubHeader = styled('div')`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

/**
 *  V1 of the OrdersList component.
 */
const OrdersList = () => {
  const [totalItems, setTotalItems] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const message =
    searchText === '' ? (
      <FormattedMessage
        id="inventory.ordersList.totalActiveOrders"
        values={{ totalResults: totalItems }}
        defaultMessage="You have {totalResults} active orders"
      />
    ) : (
      <FormattedMessage
        id="inventory.ordersList.totalActiveOrdersWithQuery"
        values={{ totalResults: totalItems, searchText: `"${searchText}"` }}
        defaultMessage={`You have {totalResults} active ${totalItems > 1 ? 'orders' : 'order'} for {searchText}`}
      />
    );

  const warning = (
    <FormattedMessage
      id="inventory.inventoryItemsList.popover"
      defaultMessage="Results exceed table capacity, please refine search criteria to view all results"
    />
  );

  return (
    <>
      <Container fluid>
        <Row>
          <Col style={{ marginBottom: '33px', marginTop: '20px' }}>
            <h1 style={{ marginBottom: '5px', fontSize: '24px' }}>
              <FormattedMessage id="inventory.ordersList.header" defaultMessage="Orders" />
            </h1>
            <BlockStyledSubHeader>
              <StyledSubHeader>{message}</StyledSubHeader>
              {showPopover && (
                <Popover content={warning} trigger="hover" placement="bottom">
                  <InfoCircleOutlined style={{ fontSize: '20px', marginLeft: '10px' }} />
                </Popover>
              )}
            </BlockStyledSubHeader>
          </Col>
        </Row>
        <Row>
          <Col>
            <OrdersTable
              setTotalItemsCount={setTotalItems}
              setSearchText={setSearchText}
              setShowPopover={setShowPopover}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrdersList;
