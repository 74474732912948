import { FormattedMessage } from 'react-intl';
import { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Principal } from 'common/authorizations';
import axios from 'util/paxios';
import { message } from 'ui-components';
import { API_PATH } from '../../../../common/AppConstants';
import { DocumentTypes, DocumentScope } from '../enums/documentEnums';
import { MimeValidatedFile } from './utils/validateFile';
import { getGCSsignedURLs, upload2DocumentService, upload2GCS } from './utils/uploadAPIs';

// TL only
import { ShipmentModeEnum, ShipmentModeFullNameMap } from '../../../../models';
import endpoints from '../../../../common/endpoints';

// Child Components
import DocumentUploadComponent from './DocumentUpload/DocumentUploadComponent';
import DocumentViewComponent from './DocumentView/DocumentViewComponent';

interface TLDocumentObj {
  currentVersion?: any;
  documentType: keyof typeof DocumentTypes;
  ownerIdentifiers?: any;
  previousVersions?: any;
  scope?: DocumentScope;
  shared: boolean;
}
interface DocumentsComponentProps {
  tenantId?: number;
  principal?: Principal;
  shipmentDetails?: any;
  shipmentShareToken?: string;
  mode?: keyof typeof ShipmentModeEnum;
  legacyDocuments?: any;
}

export const submit = async (
  files: MimeValidatedFile[] | null | undefined,
  documentType: string,
  documentProps: DocumentsComponentProps,
  refresh: () => void
) => {
  const fileIds: string[] = [];

  if (files && files.length > 0) {
    const GCSfiles = await getGCSsignedURLs(files.length);

    await Promise.all(
      files.map(async (file, i) => {
        if (GCSfiles && (await upload2GCS(GCSfiles[i].writeUrl, file))) {
          fileIds.push(GCSfiles[i].fileId);
        }
      })
    );

    const data = {
      shipmentId: documentProps.shipmentDetails.shipmentId,
      masterShipmentId: documentProps.shipmentDetails.masterShipmentId,
      fileIds,
      mode: documentProps.mode,
      documentType,
      scopeType: documentProps.principal?.tenantCarrierNetworkRoles[0],
    };

    if (fileIds.length > 0 && fileIds.length === files.length && (await upload2DocumentService(data))) {
      refresh();
      return true;
    } else {
      message.error('There is an error uploading your document.');
    }
  }
  return false;
};

const DocumentsComponent: React.FC<DocumentsComponentProps> = (props: DocumentsComponentProps) => {
  const [documents, setDocuments] = useState<any | undefined>([]);
  const [shouldDocumentViewRefresh, setShouldDocumentViewRefresh] = useState(0);

  // LTL has initial doc arr from props,
  // TL DOES NOT, this useEffect is done to make both consistent.
  useEffect(
    () => {
      const shipmentId = props.shipmentDetails.shipmentId;
      const masterShipmentId = props.shipmentDetails?.masterShipmentId;
      const shipmentMode = props.mode;
      const shipmentShareToken = props.shipmentShareToken;

      const fetchDocuments = async () => {
        try {
          let url = '';
          if (shipmentMode !== undefined && shipmentId !== undefined && masterShipmentId !== undefined) {
            url = `${API_PATH}${endpoints.GET_DOCUMENTS}?mode=${ShipmentModeFullNameMap[shipmentMode]}&shipmentId=${shipmentId}&masterShipmentId=${masterShipmentId}`;
          } else if (shipmentMode !== undefined && masterShipmentId !== undefined && !shipmentId) {
            url = `${API_PATH}${endpoints.GET_DOCUMENTS}?mode=${ShipmentModeFullNameMap[shipmentMode]}&masterShipmentId=${masterShipmentId}`;
          } else if (shipmentMode !== undefined && shipmentId !== undefined && !masterShipmentId) {
            url = `${API_PATH}${endpoints.GET_DOCUMENTS}?mode=${ShipmentModeFullNameMap[shipmentMode]}&shipmentId=${shipmentId}`;
          }

          const response: AxiosResponse<any> = await axios.get(url, { withCredentials: true });

          const documentsArray = response.data.documents
            ? response.data.documents?.map((doc: TLDocumentObj) => {
                return {
                  ...doc,
                  // can further build out this intermediate mapping for docViewComponent
                  id: doc.currentVersion?.md5Hash,
                };
              })
            : [];

          setDocuments(documentsArray);
        } catch {
          // preset documents state to [] if no doc on initial fetch. (TL only)
          return setDocuments([]);
        }
      };

      if (!shipmentShareToken) {
        fetchDocuments();
      }

      fetchDocuments();
    }, // eslint-disable-next-line
    [shouldDocumentViewRefresh]
  );

  const refresh = () => setShouldDocumentViewRefresh(shouldDocumentViewRefresh + 1);

  const legacyDocs = (props.legacyDocuments || [])
    .filter((d: any) => !!d.format)
    .map((d: any) => ({
      currentVersion: {
        createdDateTimeUtc: new Date(),
        files: [{ urls: [d.url] }],
        version: 1,
      },
      documentType: d.documentType,
      scope: DocumentScope.CARRIER,
      id: d.id,
      isLegacy: true,
    }));
  const allDocuments = [...documents, ...legacyDocs];

  return (
    <div className={'documents-component-box'}>
      <h2>
        <FormattedMessage defaultMessage="Documents" id="shipmentDetails.stickyNav.documents" />
      </h2>
      <div className="pb-2">
        <DocumentUploadComponent
          onSubmit={(files: MimeValidatedFile[] | null | undefined, documentType: string) =>
            submit(files, documentType, props, refresh)
          }
        />
      </div>
      <DocumentViewComponent documents={allDocuments} />
    </div>
  );
};

export default DocumentsComponent;
