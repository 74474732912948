import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import moment from 'moment';
import { Fragment, useRef, useState } from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import styled from 'styled-components';
import { trackEvent } from 'common/eventTracker';
import colors, { primaryGreyEighty } from '../../../styles/colors';
import {
  EntitlementInfo,
  Identifier,
  ShipmentEvent,
  ShipmentLegCarrierInfo,
  ShipmentStop,
  ShipmentStopTypeEnum,
  SourceRegions,
} from '../models';
import CurrentStopComponent from './CurrentStopComponent';
import LegEventComponent from './LegEventComponent';
import * as styles from './RouteTimeline.module.scss';
import { getStopLocationText } from '../utils';
import { getEventDate } from '../utils/shipmentUtils';
const StyledStopsListComponent: any = styled.div<{ open: boolean }>`
  box-shadow: none;
  left: 50%;
  max-height: ${(props) => (props.open ? 'none' : '0')};
  opacity: ${(props) => (props.open ? '1' : '0')};
  overflow-y: ${(props) => (props.open ? 'visible' : 'hidden')};
  padding: 0;
  position: relative;
  transform: translate(-50%, 0);
  transition: all 0.5s;
`;

const StyledCollapsedStopsComponent = styled.div<{ open: boolean; isStacked?: boolean }>`
  background: ${(props) => (props.open ? 'transparent' : '#fff')};
  box-shadow: ${(props) => (props.open ? 'none' : '0 1px 2px 0 rgba(0, 0, 0, 0.5)')};
  color: ${colors.darkBackgroundColor};
  flex: 0 0 auto;
  margin: ${(props) => (props.open ? '10px auto 0' : props.isStacked ? '10px auto 20px' : '10px auto 10px')};
  position: relative;
  transition: all 0.5s;
  width: ${(props) => (props.open ? '100%' : '80%')};

  &::after {
    content: '';
    background: ${(props) => (props.open ? 'transparent !important' : '#fff')};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    display: ${(props) => (props.open || !props.isStacked ? 'none' : 'inherit')};
    height: 100%;
    left: 10px;
    position: absolute;
    width: 100%;
    top: 10px;
    z-index: -1;
  }
`;

const StyledCollapsedSubText = styled.span`
  color: ${primaryGreyEighty};
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
`;

interface CollapsedStopsContainerProps {
  title: JSX.Element;
  isStacked: boolean;
  onClick: () => void;
  showHiddenStops: boolean;
  render: JSX.Element[];
}

const CollapsedStopsContainer = ({
  title,
  isStacked,
  onClick,
  showHiddenStops,
  render,
}: CollapsedStopsContainerProps) => {
  return (
    <StyledCollapsedStopsComponent
      open={showHiddenStops}
      isStacked={isStacked}
      data-locator="collapsed-stops-route-timeline"
    >
      {!showHiddenStops && (
        <Container>
          <Row
            onClick={onClick}
            role={'button'}
            aria-pressed={false}
            className={`${styles.collapsedTitleBar} align-items-stretch`}
          >
            <div className={styles.collapsedRouteLineContainer}>
              <div className={styles.styledRouteLine} />
            </div>
            <Col className="align-self-center">
              <Row className="align-items-center">
                <Col>{title}</Col>
                <Col xs={4} className={`${styles.collapsedTitleBarLink} text-right`}>
                  <FormattedMessage defaultMessage="Show" id="shipmentDetails.show" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
      <StyledStopsListComponent open={showHiddenStops}>{render}</StyledStopsListComponent>
    </StyledCollapsedStopsComponent>
  );
};

interface CollapsedTimelineComponentProps {
  stops: ShipmentStop[];
  events: ShipmentEvent[];
  showTravelLine: boolean;
  showDottedLine: boolean;
  mapInstance?: H.Map; // used by TL for focusing map on stop locations
  authorizations?: any;
  activeStopEvent?: ShipmentEvent;
  hasActiveStop?: boolean;
  shipmentId?: string;
  hiddenTransitUpdates?: boolean;
  isPublicPage?: boolean;
  sourceRegion: SourceRegions;
  shouldHideStatus?: boolean;
  carrierIdentifiers?: ShipmentLegCarrierInfo[];
  shipmentIdentifiers: Identifier[];
  entitlementInfo?: EntitlementInfo;
}

const CollapsedTimelineComponent = ({
  stops,
  events,
  showTravelLine,
  showDottedLine,
  mapInstance,
  authorizations,
  activeStopEvent,
  hasActiveStop,
  shipmentId,
  hiddenTransitUpdates,
  isPublicPage,
  sourceRegion,
  shouldHideStatus,
  carrierIdentifiers,
  shipmentIdentifiers,
  entitlementInfo,
}: CollapsedTimelineComponentProps) => {
  const [showHiddenStops, setShowHiddenStops] = useState(true);
  const handleClick = () => {
    setShowHiddenStops(!showHiddenStops);
    trackEvent('TOGGLE_HIDDEN_ROUTE_TIMELINE_STOPS');
  };
  const hiddenStops: JSX.Element[] = stops.map((stop: ShipmentStop, index: number) => {
    const stopHasEvents = stop.events && stop.events.length > 0;

    const hasEventBetweenThisStopAndPreviousStop = (legEvent: ShipmentEvent) => {
      return (
        (!stopHasEvents ||
          moment
            .utc(getEventDate(stops[index].events[0]).dateTimeUtc)
            .isAfter(moment.utc(getEventDate(legEvent).dateTimeUtc))) &&
        (index === 0
          ? true
          : stops[index - 1].events[0] &&
            moment
              .utc(getEventDate(stops[index - 1].events[0]).dateTimeUtc)
              .isBefore(moment.utc(getEventDate(legEvent).dateTimeUtc)))
      );
    };

    const hasEventBetweenThisStopAndNextStop = (legEvent: ShipmentEvent) => {
      return (
        index === stops.length - 1 &&
        stopHasEvents &&
        moment.utc(getEventDate(stop.events[0]).dateTimeUtc).isBefore(moment.utc(getEventDate(legEvent).dateTimeUtc))
      );
    };

    const legEventHappeningBeforeStop = events.filter((event) => hasEventBetweenThisStopAndPreviousStop(event));
    const legEventHappeningAfterStop = events.filter((event) => hasEventBetweenThisStopAndNextStop(event));
    const beforeStopEventComponents =
      legEventHappeningBeforeStop.length > 0
        ? legEventHappeningBeforeStop.map((ev: ShipmentEvent) => (
            <LegEventComponent
              hide={hiddenTransitUpdates}
              isPublicPage={isPublicPage}
              key={getEventDate(ev).dateTimeUtc}
              event={ev}
              halfTop={legEventHappeningBeforeStop.length === 1 && index === 0}
              mode={stop.mode}
            />
          ))
        : [];

    const afterStopEventComponents =
      legEventHappeningAfterStop.length > 0
        ? legEventHappeningAfterStop.map((ev: ShipmentEvent) => (
            <LegEventComponent
              hide={hiddenTransitUpdates}
              isPublicPage={isPublicPage}
              key={getEventDate(ev).dateTimeUtc}
              event={ev}
              halfBottom={index === legEventHappeningAfterStop.length - 1}
              mode={stop.mode}
            />
          ))
        : [];
    return (
      <Fragment key={`${stop.stopName}${stop.stopNumber}${stop.type}${stop.mode}`}>
        {beforeStopEventComponents}
        <CurrentStopComponent
          stop={stop}
          hideStops={handleClick}
          stopOrder={index}
          transferFromMode={stop.type === ShipmentStopTypeEnum.TRANSFER ? stop.prevLegMode : undefined}
          mapInstance={mapInstance}
          authorizations={authorizations}
          activeStopEvent={activeStopEvent}
          hasActiveStop={hasActiveStop}
          shipmentId={shipmentId}
          isCrossRegionShipment={
            (authorizations?.isEUEnvironment() === true && sourceRegion === SourceRegions.NA12) ||
            (authorizations?.isEUEnvironment() === false && sourceRegion === SourceRegions.EU12)
          }
          shouldHideStatus={shouldHideStatus}
          carrierIdentifiers={carrierIdentifiers}
          shipmentIdentifiers={shipmentIdentifiers}
          entitlementInfo={entitlementInfo}
        />
        {stops.length > 1 && (
          <Container className="d-none">
            <Row className="align-items-center">
              <Col xs={{ span: 2, offset: 4 }}>
                <div className={classNames(styles.routeLine)} />
              </Col>
            </Row>
          </Container>
        )}
        {afterStopEventComponents}
      </Fragment>
    );
  });

  const collapsedContainerRef = useRef(null);
  const createTitle = () => {
    const noOfOverflowStops = stops.length - 1;
    let stopDisplayText = '';
    if (stops.length > 0) {
      const stopLocationText = getStopLocationText(stops[0]);
      if (stops[0].stopName) {
        stopDisplayText = stops[0].stopName;
      } else if (stopLocationText) {
        stopDisplayText = stopLocationText;
      }
    }
    return (
      <div>
        {stopDisplayText}{' '}
        <StyledCollapsedSubText>
          <strong>
            {noOfOverflowStops > 0 ? (
              <FormattedPlural
                value={noOfOverflowStops}
                one={
                  <FormattedMessage
                    id="shipmentDetails.collapsedCards.remainingStop"
                    defaultMessage="+ {stops} other location"
                    values={{ stops: noOfOverflowStops }}
                  />
                }
                other={
                  <FormattedMessage
                    id="shipmentDetails.collapsedCards.remainingStops"
                    defaultMessage="+ {stops} other locations"
                    values={{ stops: noOfOverflowStops }}
                  />
                }
              />
            ) : (
              ''
            )}
          </strong>
        </StyledCollapsedSubText>{' '}
        <StyledCollapsedSubText>
          {events.length > 0 ? (
            <FormattedPlural
              value={events.length}
              one={
                <FormattedMessage
                  id="shipmentDetails.collapsedCards.pastEvent"
                  defaultMessage="+ {events} past event"
                  values={{ events: events.length }}
                />
              }
              other={
                <FormattedMessage
                  id="shipmentDetails.collapsedCards.pastEvents"
                  defaultMessage="+ {events} past events"
                  values={{ events: events.length }}
                />
              }
            />
          ) : (
            ''
          )}
        </StyledCollapsedSubText>
      </div>
    );
  };
  return (
    <Container>
      {showDottedLine && (
        <Row className="mt-3 align-items-center">
          <Col xs={{ span: 2, offset: 6 }}>
            <div className={classNames(styles.routeLine, styles.routeLineDotted)} />
          </Col>
        </Row>
      )}
      <Row ref={collapsedContainerRef}>
        <CollapsedStopsContainer
          showHiddenStops={showHiddenStops}
          onClick={handleClick}
          title={createTitle()}
          render={hiddenStops}
          isStacked={stops.length < 2 ? false : true}
        />
      </Row>
      {showTravelLine && !showHiddenStops && (
        <Row className="align-items-center">
          <Col xs={{ span: 2, offset: 6 }}>
            <div className={styles.routeLine} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default CollapsedTimelineComponent;
