import * as colors from 'styles/colors';
import { CodeInlItem } from 'models';
import { UnifiedModelStatusCodeEnum, UnifiedModelStatusCodeIntlKeys } from './ShipmentStatusCode.enum';

// These colors and translations are shared between modes and should not be exported. Use mode specific enums.
export enum CommonDerivedStatusEnum {
  SCHEDULED = 'SCHEDULED',
  IN_TRANSIT = 'IN_TRANSIT',
  UNKNOWN = 'UNKNOWN',
}

const CommonDerivedStatusColor: { [P in CommonDerivedStatusEnum]: string } = {
  [CommonDerivedStatusEnum.SCHEDULED]: colors.yellow,
  [CommonDerivedStatusEnum.IN_TRANSIT]: colors.primaryBlueSeventyFive,
  [CommonDerivedStatusEnum.UNKNOWN]: colors.primaryGreyTwenty,
};

const commonMessages = {
  scheduled: { id: 'shipment.derivedStatus.common.scheduled', defaultMessage: 'Shipment has been scheduled' },
  inTransit: { id: 'shipment.derivedStatus.common.inTransit', defaultMessage: 'In transit' },
  unknown: { id: 'shipment.derivedStatus.common.unknown', defaultMessage: 'Unknown status' },
};

const CommonDerivedStatusIntlKeys: { [P in CommonDerivedStatusEnum]: CodeInlItem } = {
  [CommonDerivedStatusEnum.SCHEDULED]: { past: commonMessages.scheduled },
  [CommonDerivedStatusEnum.IN_TRANSIT]: { past: commonMessages.inTransit },
  [CommonDerivedStatusEnum.UNKNOWN]: { past: commonMessages.unknown },
};

/*****************
 * Rail
 *****************/
export enum RailDerivedStatusEnum {
  UNKNOWN = 'UNKNOWN',
  SCHEDULED = 'SCHEDULED',
  IN_TRANSIT = 'IN_TRANSIT',
  AT_STOP_ARRIVED = 'AT_STOP_ARRIVED',
  AT_STOP_IN_GATE = 'AT_STOP_IN_GATE',
  AT_STOP_RAMPED = 'AT_STOP_RAMPED',
  AT_STOP_DERAMPED = 'AT_STOP_DERAMPED',
  AT_STOP_NOTIFIED = 'AT_STOP_NOTIFIED',
  AT_STOP_OUT_GATE = 'AT_STOP_OUT_GATE',
  COMPLETED_TIMED_OUT = 'COMPLETED_TIMED_OUT',
  COMPLETED_CANCELED = 'COMPLETED_CANCELED',
  COMPLETED_DEPARTED_STOP = 'COMPLETED_DEPARTED_STOP',
}

export const RailDerivedStatusColor: { [P in RailDerivedStatusEnum]: string } = {
  ...CommonDerivedStatusColor,
  [RailDerivedStatusEnum.AT_STOP_ARRIVED]: colors.primaryBlueSeventyFive,
  [RailDerivedStatusEnum.AT_STOP_DERAMPED]: colors.primaryBlueSeventyFive,
  [RailDerivedStatusEnum.AT_STOP_IN_GATE]: colors.primaryBlueSeventyFive,
  [RailDerivedStatusEnum.AT_STOP_NOTIFIED]: colors.primaryBlueSeventyFive,
  [RailDerivedStatusEnum.AT_STOP_OUT_GATE]: colors.primaryBlueSeventyFive,
  [RailDerivedStatusEnum.AT_STOP_RAMPED]: colors.primaryBlueSeventyFive,
  [RailDerivedStatusEnum.COMPLETED_DEPARTED_STOP]: colors.primaryBlueSeventyFive,
  [RailDerivedStatusEnum.COMPLETED_CANCELED]: colors.newFruit,
  [RailDerivedStatusEnum.COMPLETED_TIMED_OUT]: colors.primaryBlueSeventyFive,
};

const railMessages = {
  atStopArrived: { id: 'shipment.derivedStatus.rail.atStopArrived', defaultMessage: 'Arrived' },
  atStopDeramped: { id: 'shipment.derivedStatus.rail.atStopDeramped', defaultMessage: 'Deramped' },
  atStopInGate: { id: 'shipment.derivedStatus.rail.atStopInGate', defaultMessage: 'In Gate' },
  atStopNotified: { id: 'shipment.derivedStatus.rail.atStopNotified', defaultMessage: 'Notified' },
  atStopOutGate: { id: 'shipment.derivedStatus.rail.atStopOutGate', defaultMessage: 'Out Gate' },
  atStopRamped: { id: 'shipment.derivedStatus.rail.atStopRamped', defaultMessage: 'Ramped' },
  completedDepartedStop: {
    id: 'shipment.derivedStatus.rail.completedDepartedStop',
    defaultMessage: 'Shipment has departed stop and is completed',
  },
  completedCanceled: { id: 'shipment.derivedStatus.rail.completedCanceled', defaultMessage: 'Shipment canceled' },
  completedTimedOut: {
    id: 'shipment.derivedStatus.rail.completedTimedOut',
    defaultMessage: 'Shipment has timed out and is completed',
  },
};

export const RailDerivedStatusIntlKeys: { [P in RailDerivedStatusEnum]: CodeInlItem } = {
  ...CommonDerivedStatusIntlKeys,
  [RailDerivedStatusEnum.AT_STOP_ARRIVED]: { past: railMessages.atStopArrived },
  [RailDerivedStatusEnum.AT_STOP_DERAMPED]: { past: railMessages.atStopDeramped },
  [RailDerivedStatusEnum.AT_STOP_IN_GATE]: { past: railMessages.atStopInGate },
  [RailDerivedStatusEnum.AT_STOP_NOTIFIED]: { past: railMessages.atStopNotified },
  [RailDerivedStatusEnum.AT_STOP_OUT_GATE]: { past: railMessages.atStopOutGate },
  [RailDerivedStatusEnum.AT_STOP_RAMPED]: { past: railMessages.atStopRamped },
  [RailDerivedStatusEnum.COMPLETED_DEPARTED_STOP]: { past: railMessages.completedDepartedStop },
  [RailDerivedStatusEnum.COMPLETED_CANCELED]: { past: railMessages.completedCanceled },
  [RailDerivedStatusEnum.COMPLETED_TIMED_OUT]: { past: railMessages.completedTimedOut },
};

/*****************
 * Ocean
 *****************/
export enum OceanDerivedStatusEnum {
  IN_TRANSIT = 'IN_TRANSIT',
  EMBARKED = 'EMBARKED',
  LOADED = 'LOADED',
  GATE_IN_EMPTY = 'GATE_IN_EMPTY',
  GATE_OUT_EMPTY = 'GATE_OUT_EMPTY',
  GATE_IN_FULL = 'GATE_IN_FULL',
  ARRIVING = 'ARRIVING', // deprecated
  BERTHING = 'BERTHING', // deprecated
  ARRIVED = 'ARRIVED',
  ARRIVED_ON_SHIP = 'ARRIVED_ON_SHIP', // deprecated
  DISCHARGED = 'DISCHARGED',
  AVAILABLE = 'AVAILABLE',
  COMPLETED = 'COMPLETED',
  OUT_OF_NETWORK = 'OUT_OF_NETWORK',
  CAN_OVERBOARD = 'CAN_OVERBOARD', // deprecated
  UNKNOWN = 'UNKNOWN',
  SCHEDULED = 'SCHEDULED',
}

export const OceanDerivedStatusColor: { [P in OceanDerivedStatusEnum]: string } = {
  ...CommonDerivedStatusColor,
  [OceanDerivedStatusEnum.EMBARKED]: colors.primaryBlueSeventyFive,
  [OceanDerivedStatusEnum.LOADED]: colors.primaryBlueSeventyFive,
  [OceanDerivedStatusEnum.GATE_IN_EMPTY]: colors.primaryBlueSeventyFive,
  [OceanDerivedStatusEnum.GATE_OUT_EMPTY]: colors.primaryBlueSeventyFive,
  [OceanDerivedStatusEnum.GATE_IN_FULL]: colors.primaryBlueSeventyFive,
  [OceanDerivedStatusEnum.ARRIVING]: colors.primaryBlueSeventyFive,
  [OceanDerivedStatusEnum.BERTHING]: colors.primaryBlueSeventyFive,
  [OceanDerivedStatusEnum.ARRIVED]: colors.primaryBlueSeventyFive,
  [OceanDerivedStatusEnum.ARRIVED_ON_SHIP]: colors.primaryBlueSeventyFive,
  [OceanDerivedStatusEnum.DISCHARGED]: colors.primaryBlueSeventyFive,
  [OceanDerivedStatusEnum.AVAILABLE]: colors.primaryBlueSeventyFive,
  [OceanDerivedStatusEnum.COMPLETED]: colors.primaryBlueSeventyFive,
  [OceanDerivedStatusEnum.OUT_OF_NETWORK]: colors.newFruit,
  [OceanDerivedStatusEnum.CAN_OVERBOARD]: colors.newFruit,
};

const oceanMessages = {
  embarked: { id: 'shipment.derivedStatus.ocean.embarked', defaultMessage: 'Embarked' },
  loaded: { id: 'shipment.derivedStatus.ocean.loaded', defaultMessage: 'Loaded' },
  gateInEmpty: { id: 'shipment.derivedStatus.ocean.gateInEmpty', defaultMessage: 'Gate In Empty' },
  gateOutEmpty: { id: 'shipment.derivedStatus.ocean.gateOutEmpty', defaultMessage: 'Gate Out Empty' },
  gateInFull: { id: 'shipment.derivedStatus.ocean.gateInFull', defaultMessage: 'Gate In Full' },
  berthing: { id: 'shipment.derivedStatus.ocean.berthing', defaultMessage: 'Berthing' },
  discharged: { id: 'shipment.derivedStatus.ocean.discharged', defaultMessage: 'Discharged' },
  available: { id: 'shipment.derivedStatus.ocean.available', defaultMessage: 'Shipment is available' },
  completed: { id: 'shipment.derivedStatus.ocean.completed', defaultMessage: 'Shipment has completed' },
  outOfNetwork: { id: 'shipment.derivedStatus.ocean.outOfNetwork', defaultMessage: 'Out of Network' },
  arrived: { id: 'shipment.derivedStatus.ocean.arrived', defaultMessage: 'Arrived' },
  // deprecated
  arriving: { id: 'shipment.derivedStatus.ocean.arriving', defaultMessage: 'Arriving' },
  canOverboard: { id: 'shipment.derivedStatus.ocean.canOverboard', defaultMessage: 'Can Overboard' },
  arrivedOnShip: { id: 'shipment.derivedStatus.ocean.arrivedOnShip', defaultMessage: 'Arrived on Ship' },
};

export const OceanDerivedStatusIntlKeys: { [P in OceanDerivedStatusEnum]: CodeInlItem } = {
  ...CommonDerivedStatusIntlKeys,
  [OceanDerivedStatusEnum.EMBARKED]: { past: oceanMessages.embarked },
  [OceanDerivedStatusEnum.LOADED]: { past: oceanMessages.loaded },
  [OceanDerivedStatusEnum.GATE_IN_EMPTY]: { past: oceanMessages.gateInEmpty },
  [OceanDerivedStatusEnum.GATE_OUT_EMPTY]: { past: oceanMessages.gateOutEmpty },
  [OceanDerivedStatusEnum.GATE_IN_FULL]: { past: oceanMessages.gateInFull },
  [OceanDerivedStatusEnum.ARRIVING]: { past: oceanMessages.arriving },
  [OceanDerivedStatusEnum.BERTHING]: { past: oceanMessages.berthing },
  [OceanDerivedStatusEnum.ARRIVED]: { past: oceanMessages.arrived },
  [OceanDerivedStatusEnum.ARRIVED_ON_SHIP]: { past: oceanMessages.arrivedOnShip },
  [OceanDerivedStatusEnum.DISCHARGED]: { past: oceanMessages.discharged },
  [OceanDerivedStatusEnum.AVAILABLE]: { past: oceanMessages.available },
  [OceanDerivedStatusEnum.COMPLETED]: { past: oceanMessages.completed },
  [OceanDerivedStatusEnum.OUT_OF_NETWORK]: { past: oceanMessages.outOfNetwork },
  [OceanDerivedStatusEnum.CAN_OVERBOARD]: { past: oceanMessages.canOverboard },
};

/*****************
 * Parcel
 *****************/
export enum ParcelDerivedStatusEnum {
  AT_STOP = 'AT_STOP',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  EXCEPTION = 'EXCEPTION',
  IN_TRANSIT = 'IN_TRANSIT',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  SCHEDULED = 'SCHEDULED',
  UNKNOWN = 'UNKNOWN',
}

export const ParcelDerivedStatusColor: { [P in ParcelDerivedStatusEnum]: string } = {
  ...CommonDerivedStatusColor,
  [ParcelDerivedStatusEnum.AT_STOP]: colors.primaryBlueSeventyFive,
  [ParcelDerivedStatusEnum.CANCELLED]: colors.newFruit,
  [ParcelDerivedStatusEnum.DELIVERED]: colors.primaryBlueSeventyFive,
  [ParcelDerivedStatusEnum.EXCEPTION]: colors.newFruit,
  [ParcelDerivedStatusEnum.OUT_FOR_DELIVERY]: colors.primaryBlueSeventyFive,
};

const parcelMessages = {
  atStop: { id: 'shipment.derivedStatus.parcel.atStop', defaultMessage: 'At stop' },
  canceled: { id: 'shipment.derivedStatus.parcel.canceled', defaultMessage: 'Shipment canceled' },
  delivered: { id: 'shipment.derivedStatus.parcel.delivered', defaultMessage: 'Shipment has been delivered' },
  exception: {
    id: 'shipment.derivedStatus.parcel.exception',
    defaultMessage: 'There is an exception on the shipment',
  },
  outForDelivery: { id: 'shipment.derivedStatus.parcel.outForDelivery', defaultMessage: 'Shipment out for delivery' },
};

export const ParcelDerivedStatusIntlKeys: { [P in ParcelDerivedStatusEnum]: CodeInlItem } = {
  ...CommonDerivedStatusIntlKeys,
  [ParcelDerivedStatusEnum.AT_STOP]: { past: parcelMessages.atStop },
  [ParcelDerivedStatusEnum.CANCELLED]: { past: parcelMessages.canceled },
  [ParcelDerivedStatusEnum.DELIVERED]: { past: parcelMessages.delivered },
  [ParcelDerivedStatusEnum.EXCEPTION]: { past: parcelMessages.exception },
  [ParcelDerivedStatusEnum.OUT_FOR_DELIVERY]: { past: parcelMessages.outForDelivery },
};

export const UnifiedModelStatusColor: { [P in UnifiedModelStatusCodeEnum]: string } = {
  [UnifiedModelStatusCodeEnum.TRACKING_INITIATE]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.TRACKING_START]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.ARRIVAL_AT_STOP]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.DEPARTURE_FROM_STOP]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.LOAD_ONTO_VEHICLE]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.UNLOAD_FROM_VEHICLE]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.TRACKING_END_DUE_TO_TIMEOUT]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.TRACKING_END_BY_USER]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.TRACKING_COMPLETE]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.FLIGHT_BOOKING]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.MANIFEST]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.RECEIVE_FROM_SHIPPER]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.CONSIGNEE_NOTIFY]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.DELIVERY]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.TRACKING_STOP]: colors.primaryBlueSeventyFive,
  [UnifiedModelStatusCodeEnum.WARNING]: colors.newFruit,
  [UnifiedModelStatusCodeEnum.EXCEPTION]: colors.newFruit,
  [UnifiedModelStatusCodeEnum.EXCEPTION_LATE_DEPARTURE]: colors.newFruit,
  [UnifiedModelStatusCodeEnum.EXCEPTION_MISSED_CONNECTION]: colors.newFruit,
};

export const getShipmentDerivedStatusColor = (shipmentDerivedStatus: ShipmentDerivedStatus): string => {
  return ShipmentDerivedStatusColor[shipmentDerivedStatus]
    ? ShipmentDerivedStatusColor[shipmentDerivedStatus]
    : colors.primaryBlueSeventyFive;
};

/*****************
 * All Modes
 *****************/
export type ShipmentDerivedStatus =
  | CommonDerivedStatusEnum
  | RailDerivedStatusEnum
  | OceanDerivedStatusEnum
  | ParcelDerivedStatusEnum
  | UnifiedModelStatusCodeEnum;

export const ShipmentDerivedStatusColor: { [P in ShipmentDerivedStatus]: string } = {
  ...RailDerivedStatusColor,
  ...OceanDerivedStatusColor,
  ...ParcelDerivedStatusColor,
  ...UnifiedModelStatusColor,
};

export const ShipmentDerivedStatusIntlKeys: { [P in ShipmentDerivedStatus]: CodeInlItem } = {
  ...RailDerivedStatusIntlKeys,
  ...OceanDerivedStatusIntlKeys,
  ...ParcelDerivedStatusIntlKeys,
  ...UnifiedModelStatusCodeIntlKeys,
};
