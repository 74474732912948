import { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input as AntInput } from 'antd';
import { shade, rgba } from 'polished';
import styled, { css } from 'styled-components';
import { primaryGreyFive, errorRed } from 'styles/colors';

import { ThemeContext, defaultThemeShape } from '../../styles/theme';

const StyledP44Input = styled(AntInput)`
  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${({ theme, hasError }) => (hasError ? errorRed : theme.primaryColor)} !important;
  }

  ${(props) =>
    props.hasError &&
    `
    &:focus {
      box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.2);
    }
  `}
`;

const P44Input = ({ className, ...props }) => {
  return props.password ? (
    <StyledP44Input.Password {...props} className={classNames('rounded-lg', className)} />
  ) : (
    <StyledP44Input {...props} className={classNames('rounded-lg', className)} />
  );
};

const StyledInput = styled.div`
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-focused {
    &:hover {
      border: 1px solid ${(props) => props.theme.primaryColor} !important;
    }
    &:focus,
    &:active {
      border: 1px solid ${(props) => props.theme.primaryColor} !important;
      box-shadow: 0 0 0 2px ${(props) => rgba(props.theme.primaryColor, 0.2)} !important;
    }
  }
  .ant-input-affix-wrapper:focus {
    border: 1px solid ${(props) => props.theme.primaryColor} !important;
    box-shadow: 0 0 0 2px ${(props) => rgba(props.theme.primaryColor, 0.2)} !important;
  }
  width: 100%;
  :not(.borderlessInput) {
    .ant-input:not(:disabled):not(.borderlessInput) {
      &:hover,
      &:active,
      &:focus {
        border: 1px solid ${(props) => props.theme.primaryColor} !important;
      }
      &:active,
      &:focus {
        box-shadow: 0 0 0 2px ${(props) => rgba(props.theme.primaryColor, 0.2)} !important;
      }
    }
  }
  .has-error .ant-form-explain,
  .has-error .ant-form-split {
    font-size: 12px;
    color: ${(props) => props.errorColor || 'inherit'};
  }
  .has-error input {
    border: 1px solid ${errorRed};
  }
  .super button,
  .primary button {
    background-color: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};
  }
  .super button:hover,
  .primary button:hover {
    background-color: ${(props) => shade(0.2)(props.theme.primaryColor)};
    border-color: ${(props) => shade(0.2)(props.theme.primaryColor)};
  }
  .super {
    input {
      background-color: var(--primary-grey-05);
      color: var(--primary-grey-80);
      border: none;
    }
  }
  .primary {
    input {
      background-color: #ffffff;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
      border: none;
    }
  }
  input {
    &.ant-input-lg {
      font-size: 14px;
    }
  }
  .ant-input-suffix {
    display: flex;
    align-items: center;
  }
  .ant-btn-lg {
    height: 50px;
  }
  ${(props) =>
    !props.$defaultInputGroupAddon
      ? css`
          .ant-input-group-addon {
            border: 1px solid ${primaryGreyFive} !important;
            padding-left: 0;
            padding-right: 0;
            .ant-select {
              margin: -1px !important;
            }
          }
        `
      : ''};
`;

export const NativeInput = (props) => {
  const { className, errorColor, hasError, nativeInputClassName, ...customProps } = props;

  const context = useContext(ThemeContext);

  return (
    <StyledInput theme={props.theme || context} className={className} errorColor={errorColor}>
      <div className={classNames('ant-form-vertical ant-form-item-control-wrapper')}>
        <div
          className={classNames('ant-form-item-control', {
            'has-error': hasError,
            'has-feedback': hasError,
          })}
        >
          <AntInput className={nativeInputClassName} {...customProps} />
        </div>
      </div>
    </StyledInput>
  );
};

export default class Input extends Component {
  static propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    onSearch: PropTypes.func,
    size: PropTypes.string,
    search: PropTypes.string,
    onPressEnter: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    type: PropTypes.string,
    custom: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    theme: PropTypes.shape(defaultThemeShape),
    className: PropTypes.string,
    isv2: PropTypes.string,
    errorColor: PropTypes.string,
    errorDataLocator: PropTypes.string,
    nativeInputClassName: PropTypes.string,
    defaultInputGroupAddon: PropTypes.bool,
  };

  static contextType = ThemeContext;

  render() {
    const {
      label,
      placeholder,
      defaultValue,
      onSearch,
      size,
      search,
      onPressEnter,
      onBlur,
      onChange,
      hasError,
      errorMessage,
      type,
      className,
      custom,
      enterButton,
      value = '',
      isv2 = 'false',
      errorColor,
      errorDataLocator = 'input-error-message',
      defaultInputGroupAddon = false,
      ...customProps
    } = this.props;

    return isv2 === 'true' ? (
      <P44Input {...this.props} />
    ) : (
      <StyledInput
        $defaultInputGroupAddon={defaultInputGroupAddon}
        theme={this.props.theme || this.context}
        className={className}
        errorColor={errorColor}
      >
        <div
          className={classNames('ant-form-vertical ant-form-item-control-wrapper', {
            'w-full': search === 'primary',
          })}
        >
          <div
            className={classNames('ant-form-item-control', {
              'has-error': hasError,
              'has-feedback': hasError,
            })}
          >
            {label && (
              <div className="ant-form-item-label">
                <label title={label}>{label}</label>
              </div>
            )}
            {search && (
              <AntInput.Search
                className={search}
                placeholder={placeholder}
                defaultValue={defaultValue}
                {...(!onSearch ? { onPressEnter } : {})}
                onSearch={onSearch}
                onChange={onChange}
                onBlur={onBlur}
                enterButton={search === 'super' || search === 'primary' || enterButton}
                size={search === 'super' || search === 'primary' || search === 'secondary' ? 'large' : size}
                type={type}
                value={value}
                {...custom}
              >
                {this.props.children}
              </AntInput.Search>
            )}
            {!search && (
              <AntInput
                placeholder={placeholder}
                defaultValue={defaultValue}
                onPressEnter={onPressEnter}
                onChange={onChange}
                onBlur={onBlur}
                type={type}
                {...this.props}
                {...custom}
                {...customProps}
              >
                {this.props.children}
              </AntInput>
            )}
            {hasError && (
              <div data-locator={errorDataLocator} className="ant-form-explain">
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      </StyledInput>
    );
  }
}
