import * as React from 'react';
import AntTabs, { TabsProps as AntTabsProps, TabPaneProps } from 'antd/lib/tabs';
import omit from 'lodash/omit';
const TabPane = AntTabs.TabPane;
export interface TabsProps extends AntTabsProps {
  panes: Array<TabPaneProps & { key: string }>;
  tabcontent: {
    [key: string]: React.ReactNode;
  };
}

const Tabs: React.FC<TabsProps> = (props) => {
  return (
    <AntTabs {...omit(props, 'panes')}>
      {props.panes.map((pane) => (
        <TabPane tab={pane.tab} key={pane.key}>
          {props.tabcontent[pane.key]}
        </TabPane>
      ))}
    </AntTabs>
  );
};

export default Tabs;
