import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { DocumentIcon, Row, StickyNav, Tabs } from 'ui-components';
import ShipmentHeaderContainer from 'components/newShipmentDetails/ShipmentHeader/ShipmentHeaderContainer';
import ErrorBoundary from 'components/common/errorBoundary/ErrorBoundary';
import { BasicInventoryOrderSearchCriteria, InventoryOrder } from 'components/Inventory/models';
import { PrincipalAuthorizations, Principal } from 'common/authorizations';
import OrdersTable from 'components/ordersList/OrdersTable';
import InventoryItemsTable from 'components/Inventory/InventoryItemsTable';
import { ShipmentModeEnum, TenantCustomAttribute, Page } from '../../../models';
import CarrierDetail from '../common/CarrierDetail/CarrierDetail';
import PageNotFound from '../../common/PageNotFound/PageNotFound';
import Document from '../common/Document/Document';
import Route from '../common/Route/Route';
import Status from '../common/Status/Status';
import * as styles from './LTLDetailsComponent.module.scss';
import { PrincipalContext, PrincipalContextProps } from '../../../contexts/PrincipalContext';

import DocumentsComponent from '../common/Document/DocumentsComponent';

const menuItems = [
  {
    label: <FormattedMessage id="shipmentDetails.stickyNav.status" defaultMessage="Status" />,
    icon: 'pin_drop',
    link: 'status',
  },
  {
    label: <FormattedMessage id="shipmentDetails.stickyNav.routeDetails" defaultMessage="Route Details" />,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <defs>
          <path
            id="a"
            d="M6 15.874v3.394a2 2 0 1 1-2 0v-3.394a4.002 4.002 0 0 1 0-7.748V4.732a2 2 0 1 1 2 0v3.394a4.002 4.002 0 0 1 0 7.748zM9 2h12v2H9V2zm0 18h12v2H9v-2zm2-9h12v2H11v-2z"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path fill="#FFF" fillOpacity="0" d="M1 1h21v21H1z" />
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#000" xlinkHref="#a" />
          <g fill="#575451" mask="url(#b)">
            <path d="M0 0h24v24H0z" />
          </g>
        </g>
      </svg>
    ),
    link: 'route',
  },
  {
    label: <FormattedMessage id="shipmentDetails.stickyNav.documents" defaultMessage="Documents" />,
    icon: <DocumentIcon />,
    link: 'documents',
  },
];

interface LTLDetailsParams {
  shipmentDetailId: string;
  shipmentMode: string;
  shipmentShareToken?: string;
}

interface LTLDetailsComponentProps extends RouteComponentProps<LTLDetailsParams> {
  tenantId: number;
  authorizations: PrincipalAuthorizations;
  isLoggedIn: boolean;
  shipmentDetails: any;
  shipmentDetailsMissing: boolean;
  showExpanded: boolean;
  toggleGroupedUpdates: boolean;
  toggleRouteDetailsDisplay: boolean;
  shipmentTenantAttributesEditMode: boolean;
  toggleTenantAttributesMode: () => void;
  getOrdersByShipment: (body: BasicInventoryOrderSearchCriteria) => void;
  shipmentOrders?: Page<InventoryOrder>;
  principal: Principal;
}

const getSharedShipmentCustomReference = (
  ltlDetailsProps: LTLDetailsComponentProps
): TenantCustomAttribute[] | undefined => {
  const userTenantId = get(ltlDetailsProps, 'tenantId');
  const shipmentTenantId = get(ltlDetailsProps, 'shipmentDetails.tenantId');
  const tenantCustomAttrs = get(ltlDetailsProps, 'shipmentDetails.tenantCustomAttributes');

  // Only get the attributes from shipment if the shipment is shared.
  if (userTenantId && shipmentTenantId !== userTenantId && tenantCustomAttrs) {
    return tenantCustomAttrs;
  }
};

const LTLDetailsComponent: React.FC<LTLDetailsComponentProps | any> = (props: LTLDetailsComponentProps) => {
  const { setTenantId } = React.useContext(PrincipalContext) as PrincipalContextProps;

  React.useEffect(() => {
    if (!props.shipmentDetailsMissing && props.shipmentDetails && props.shipmentDetails.tenantId) {
      setTenantId(props.shipmentDetails.tenantId);
    }
  }, [props.shipmentDetailsMissing, props.shipmentDetails, setTenantId]);

  // do this check after effects above to avoid a rules-of-hooks lint warning
  if (props.shipmentDetailsMissing) {
    return <PageNotFound />;
  }

  if (props.shipmentDetails) {
    return (
      <div className={`${styles['shipment-details-ltl']} animated fadeIn`}>
        <div className={styles['sticky-nav']}>
          <StickyNav menuItems={menuItems} mode="follow" />
        </div>
        <ShipmentHeaderContainer
          identifiers={props.shipmentDetails.identifiers}
          shipmentTenantId={props.shipmentDetails.tenantId}
          shipmentId={`${props.shipmentDetails.shipmentId}`}
          masterShipmentId={`${props.shipmentDetails.masterShipmentId}`}
          shipmentShareToken={props.match.params.shipmentShareToken}
          authorizations={props.authorizations}
          isLoggedIn={props.isLoggedIn}
          mode={ShipmentModeEnum.LTL}
          entitlementInfo={props.shipmentDetails.entitlementInfo}
          tenantCustomAttributes={getSharedShipmentCustomReference(props)}
          showEmissions={false}
        />
        <section id="status" className={`shipment-details section-content container ${styles.status}`}>
          <ErrorBoundary message={'An error occurred rendering the shipment details'}>
            <Row type="flex" className="shipment-details-content">
              <Status
                shipmentStatus={props.shipmentDetails.status}
                entitlementInfo={props.shipmentDetails.entitlementInfo}
              />
              {props.isLoggedIn ? (
                <Col md={8} className="d-none d-sm-flex">
                  <CarrierDetail
                    carrierDetail={props.shipmentDetails && props.shipmentDetails.carrier}
                    mode={ShipmentModeEnum.LTL}
                    shipmentDetails={props.shipmentDetails}
                  />
                </Col>
              ) : null}
            </Row>
          </ErrorBoundary>
        </section>
        <section id="route" className="shipment-details section-content container">
          <ErrorBoundary message={'An error occurred rendering the route details'}>
            <Route
              mode={props.shipmentDetails.mode}
              routeDetails={props.shipmentDetails.route.routeDetails}
              markerColor={props.shipmentDetails?.status?.flag?.color}
              statusStops={props.shipmentDetails.status.stops}
              showExpanded={props.showExpanded}
              toggleRouteDetailsDisplay={props.toggleRouteDetailsDisplay}
              routeSummary={props.shipmentDetails.route.routeSummary}
              toggleGroupedUpdates={props.toggleGroupedUpdates}
            />
          </ErrorBoundary>
        </section>

        {props.authorizations.hasAdvancedTrackingLinking('LTL') && (
          <section className="section-content container">
            <Tabs
              type="card"
              defaultActiveKey="orders"
              panes={[
                {
                  key: 'orders',
                  tab: <FormattedMessage defaultMessage="Orders" id="shipmentDetails.inventoryOrders.tabs.orders" />,
                },
                {
                  key: 'inventory',
                  tab: (
                    <FormattedMessage defaultMessage="Inventory" id="shipmentDetails.inventoryOrders.tabs.inventory" />
                  ),
                },
              ]}
              tabcontent={{
                orders: <OrdersTable masterShipmentId={props.shipmentDetails.masterShipmentId} />,
                inventory: <InventoryItemsTable masterShipmentId={props.shipmentDetails.masterShipmentId} />,
              }}
            />
          </section>
        )}
        <section id="documents" className="documents-details section-content container">
          {props.authorizations.hasDocumentUploadEnabled() && (
            <DocumentsComponent
              shipmentShareToken={props.match.params?.shipmentShareToken}
              tenantId={props.shipmentDetails.tenantId}
              shipmentDetails={props.shipmentDetails}
              principal={props.principal}
              mode={ShipmentModeEnum.LTL}
              legacyDocuments={props.shipmentDetails.documents}
            />
          )}
          {!props.authorizations.hasDocumentUploadEnabled() && (
            <>
              <h2>
                <FormattedMessage defaultMessage="Documents" id="shipmentDetails.stickyNav.documents" />
              </h2>
              <Row type="flex" className="documents-details-content">
                {!props.shipmentDetails.documents.length ? (
                  <h5 className={`${styles['no-documents-message']}`}>
                    {' '}
                    <FormattedMessage
                      defaultMessage="There are no documents associated with this record."
                      id="shipmentDetails.noDocumentsMessage"
                    />{' '}
                  </h5>
                ) : (
                  props.shipmentDetails.documents.map((doc: any) => {
                    return <Document documentObj={doc} key={doc.id} />;
                  })
                )}
              </Row>
            </>
          )}
        </section>
      </div>
    );
  } else {
    return null;
  }
};

export default LTLDetailsComponent;
