import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchOutlined from '@ant-design/icons';
import { primaryGreyEighty } from 'styles/colors';

export const NoResultsToDisplay = (props: { carrierFleetSearchTerm?: string; errorNumber?: number }) => {
  return (
    <Row className="AppliedFilterList align-items-center">
      <Col xs={24} className="no-results-message">
        <h4>
          <FormattedMessage
            id="carrierFleetList.noResultsMessage"
            defaultMessage="The carrier identifier is not valid: { carrierFleetSearchTerm }."
            values={{ carrierFleetSearchTerm: props.carrierFleetSearchTerm }}
          />
        </h4>
        <SearchOutlined style={{ color: primaryGreyEighty, fontWeight: 'bolder', fontSize: '60px' }} />
        <p className="medium-text">
          <FormattedMessage id="carrierFleetList.newSearchMessage" defaultMessage="Please try a new search." />
        </p>
      </Col>
    </Row>
  );
};

export default NoResultsToDisplay;
