/* eslint-disable react/style-prop-object */
import moment from 'antd/node_modules/moment';
import get from 'lodash/get';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import {
  ArrivalStatusCode,
  DerivedOrderHealth,
  InventoryOrderArrivalStatusIntlKeys,
  TimeUnit,
} from 'components/Inventory/models';

export const getOrderDeliveryStatusDisplay = (derivedOrderHealth: DerivedOrderHealth | null) => {
  const arrivalStatus = derivedOrderHealth ? derivedOrderHealth.arrivalStatus : null;
  const code = arrivalStatus ? arrivalStatus.code : null;
  const duration = arrivalStatus ? arrivalStatus.duration : null;
  const amount = duration ? duration.amount : null;
  const timeUnit = duration ? duration.unit : null;
  const id = get(InventoryOrderArrivalStatusIntlKeys, `[${code}].id`, ' ');
  const defaultMessage = get(InventoryOrderArrivalStatusIntlKeys, `[${code}].defaultMessage`, ' ');

  if (code === ArrivalStatusCode.EARLY || code === ArrivalStatusCode.LATE) {
    if (timeUnit !== TimeUnit.MINUTES || amount === null) {
      return <FormattedMessage id={id} defaultMessage={defaultMessage} />;
    }

    const now = moment().startOf('year').minutes(amount);
    const days = now.dayOfYear() - 1;
    const hours = now.hour();
    const minutes = now.minute();

    return (
      <React.Fragment>
        {days > 0 && <FormattedNumber value={days} style="unit" unit="day" unitDisplay="long" />}
        {days > 0 && ' '}
        {hours > 0 && <FormattedNumber value={hours} style="unit" unit="hour" unitDisplay="long" />}
        {hours > 0 && ' '}
        {minutes > 0 && <FormattedNumber value={minutes} style="unit" unit="minute" unitDisplay="long" />}
        {minutes > 0 && ' '}
        <FormattedMessage id={id} defaultMessage={defaultMessage} />
      </React.Fragment>
    );
  } else if (code === ArrivalStatusCode.UNKNOWN || code === ArrivalStatusCode.ON_TIME) {
    return <FormattedMessage id={id} defaultMessage={defaultMessage} />;
  } else {
    return <FormattedMessage id={`inventory.ordersList.orderDeliveryStatus.none`} defaultMessage={'-'} />;
  }
};
