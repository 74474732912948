import * as React from 'react';

import { SendBirdSelectors, GroupChannelType } from 'sendbird-uikit';
export const useCreateChannel = ({
  channelId,
  tenantIds,
  sdk,
}: {
  channelId: string;
  tenantIds: number[];
  sdk: SendBirdSelectors.GetSdk;
}) => {
  const [channel, setChannel] = React.useState<GroupChannelType>();
  const checkIfChannelExists = React.useCallback(async () => {
    if (sdk && sdk?.GroupChannel) {
      const res = await sdk.GroupChannel.getChannel(channelId).catch((error) => {
        console.error('Error checking if channel exists', error);
      });
      return res;
    }
  }, [channelId, sdk]);
  const createChannel = React.useCallback(
    async (usersWithAccessToThisChat: SendBird.User[]) => {
      try {
        if (sdk && sdk?.GroupChannel) {
          checkIfChannelExists().then((channel) => {
            if (!channel) {
              const params: SendBird.GroupChannelParams = new sdk.GroupChannelParams();
              params.isPublic = true;
              params.isEphemeral = false;
              params.isSuper = true;
              params.name = 'Open Chat';
              params.addUserIds(usersWithAccessToThisChat.map((user) => user.userId));
              params.isBroadcast = false;
              params.channelUrl = channelId;
              params.customType = `ALL_MEMBER_CHANNEL:${channelId}`;
              params.data = JSON.stringify({
                shipmentId: channelId,
              });
              sdk.GroupChannel.createChannel(params, (groupChannel) => {
                //! We can only send an Admin Message via the Chat Platform API (server-side).
                //! We can possibly use a cloud function for this.
                // Required to welcome the user to the chat
                // axios.get(`cloudFunctions/chat/adminMessage`, {
                //   env: 'qa-int',
                //   channelURL: ''
                // });
                if (groupChannel) {
                  groupChannel.createMetaData({
                    tenantIdsList: JSON.stringify(tenantIds),
                  });
                  setChannel(groupChannel);
                }
              }).catch((error) => {
                console.error('Error when creating the channel', error);
              });
            } else {
              setChannel(channel);
            }
          });
        }
      } catch (error) {
        console.error('Error creating channel', { error });
      }
    },
    [channelId, checkIfChannelExists, sdk, tenantIds]
  );

  return { createChannel, channel };
};

export function useGetUsersWithAccess({ sdk, tenantIds }: { sdk: SendBirdSelectors.GetSdk; tenantIds: number[] }) {
  const [users, setUsers] = React.useState<SendBird.User[]>([]);
  const [queryStatus, setQueryStatus] = React.useState<'IDLE' | 'LOADING' | 'SUCCESS'>('IDLE');
  React.useEffect(() => {
    async function executeListQuery() {
      if (sdk && sdk.createApplicationUserListQuery) {
        setQueryStatus('LOADING');
        const listQuery = sdk.createApplicationUserListQuery();
        if (listQuery.hasNext) {
          const sendbirdUsers = await listQuery.next();
          const userIds = sendbirdUsers
            .filter((user: SendBird.User) => {
              return tenantIds.includes(parseInt(user.userId.split(':')[1]));
            })
            .map((user: SendBird.User) => {
              return user;
            });
          setUsers((prevValues) => [...prevValues, ...userIds]);
          setQueryStatus('SUCCESS');
        }
      }
    }
    if (queryStatus === 'IDLE') {
      executeListQuery();
    }
  }, [queryStatus, sdk, tenantIds, users]);

  return users;
}
