import types from './types';

const getNotifications = (accessLevelObject, subscriptionOptions) => ({
  type: types.GET_NOTIFICATIONS,
  payload: { accessLevelObject, subscriptionOptions },
});

const getConfigIdentities = () => ({
  type: types.GET_CONFIG_IDENTITIES,
});

const getConfigIdentitiesFail = () => ({
  type: types.GET_CONFIG_IDENTITIES_FAIL,
});

const getNotificationsFailure = (accessLevelObject, errors) => ({
  type: types.GET_NOTIFICATIONS_FAIL,
  payload: { accessLevelObject, errors },
});

const updateManageNotificationsShipmentAccessLevel = (accessLevel) => ({
  type: types.UPDATE_MANAGE_NOTIFICATIONS_SHIPMENT_ACCESS_LEVEL,
  accessLevel,
});

const updateNotificationShipmentAccessLevel = (accessLevel) => ({
  type: types.UPDATE_NOTIFICATION_SHIPMENT_ACCESS_LEVEL,
  accessLevel,
});

const expandCollapseNotification = (id = 0, isExpanded = false) => ({
  type: types.EXPAND_COLLAPSE_NOTIFICATION,
  id: id,
  isExpanded: isExpanded,
});

const getNotification = (id, accessLevelObject, subscriptionOptions) => ({
  type: types.GET_NOTIFICATION,
  id: id,
  accessLevelObject,
  subscriptionOptions,
});

const getNotificationFail = (id, accessLevelObject, errorDetails) => ({
  type: types.GET_NOTIFICATION_FAIL,
  id: id,
  accessLevelObject: accessLevelObject,
  errorDetails,
});

const saveNotification = (notification, accessLevelObject, subscriptionOptions) => {
  return {
    type: types.SAVE_NOTIFICATION,
    notification: notification,
    accessLevelObject,
    subscriptionOptions,
  };
};

const saveNotificationFail = (id, accessLevelObject, errorDetails) => ({
  type: types.SAVE_NOTIFICATION_FAIL,
  id: id,
  accessLevelObject: accessLevelObject,
  errorDetails,
});

const queryAutocompleteOptions = (section, inputText, type, mode) => {
  return {
    type: type,
    field: section,
    filterText: inputText,
    mode: mode,
  };
};

const deleteNotification = (id, accessLevelObject) => ({
  type: types.DELETE_NOTIFICATION,
  id: id,
  accessLevelObject,
});

const deleteNotificationFail = (id, accessLevelObject, errorDetails) => ({
  type: types.DELETE_NOTIFICATION_FAIL,
  id: id,
  accessLevelObject: accessLevelObject,
  errorDetails,
});

const setNotificationMode = (data) => ({
  type: types.SET_NOTIFICATION_MODE,
  payload: data,
});

const resetNotificationForm = () => ({ type: types.RESET_NOTIFICATION_FORM });

export const manageNotificationsActions = {
  getNotifications,
  getNotificationsFailure,
  expandCollapseNotification,
  updateManageNotificationsShipmentAccessLevel,
};

export const notificationActions = {
  getNotification,
  getNotificationFail,
  getConfigIdentities,
  getConfigIdentitiesFail,
  updateNotificationShipmentAccessLevel,
  queryAutocompleteOptions,
  saveNotification,
  saveNotificationFail,
  deleteNotification,
  deleteNotificationFail,
  setNotificationMode,
  resetNotificationForm,
};
