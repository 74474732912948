import './CarrierDetail.scss';

import classNames from 'classnames';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { useState, useContext } from 'react';
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import get from 'lodash/get';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { EquipmentIdentifier, EquipmentIdentifierTypeEnum, ShipmentModeEnum, Theme } from 'models';
import { ShipmentModeIcon } from 'ui-components';
import { primaryBlue } from 'styles/colors';
import { ThemeContext } from 'contexts/ThemeContext';
import { formatUtcTimeInTimeZoneOrDefaultToBrowserZone } from 'common/dateUtils';
import AddEquipmentIdentifier from '../AddEquipmentIdentifier/AddEquipmentIdentifier';

const StyledIcon = styled(InfoCircleOutlined)<{ customTheme?: Theme }>`
  color: ${(props) => (props.customTheme !== undefined ? props.customTheme.primaryColor : primaryBlue)} !important;
  font-size: 16px;
  cursor: pointer;
`;

const StyledShowMore = styled.span<{ customTheme?: Theme }>`
  cursor: pointer;
  color: ${(props) => (props.customTheme !== undefined ? props.customTheme.primaryColor : primaryBlue)};
`;

const StyledEquipIdTooltip = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const mapActiveEquipmentIdentifier = (
  equipmentIdentifers: EquipmentIdentifier[],
  addedEquipmentIdentifiers: EquipmentIdentifier[],
  activeEquipmentIdentifier?: EquipmentIdentifier
): EquipmentIdentifier[] => {
  if (addedEquipmentIdentifiers.length > 0) {
    const combinedIdentifiers = [...addedEquipmentIdentifiers, ...equipmentIdentifers];
    // the newest added equipment identifier is the first in the array and we assume it is active
    combinedIdentifiers[0] = {
      ...combinedIdentifiers[0],
      active: true,
    };
    return combinedIdentifiers;
  } else if (activeEquipmentIdentifier !== undefined) {
    let foundActiveEquipId = false; // mobile phone numbers are obfuscated so use a flag to let only one of them be set as active
    return equipmentIdentifers
      .map((identifier) => {
        const isActiveEquipId =
          identifier.type === activeEquipmentIdentifier.type && identifier.value === activeEquipmentIdentifier.value;
        const newIdentifier = {
          ...identifier,
          active: isActiveEquipId && !foundActiveEquipId,
        };
        foundActiveEquipId = foundActiveEquipId || isActiveEquipId;
        return newIdentifier;
      })
      .sort((a, b) => {
        // Sort the active equipment identifer to beginning of array
        if (a.active) {
          return -1;
        } else if (b.active) {
          return 1;
        }
        return 0;
      });
  }
  return equipmentIdentifers;
};

const EquipmentIdentifierValue: React.FC<{ equipmentIdentifier: EquipmentIdentifier }> = ({ equipmentIdentifier }) => {
  const showMobilePhoneNumber =
    equipmentIdentifier.type === EquipmentIdentifierTypeEnum.MOBILE_PHONE_NUMBER &&
    equipmentIdentifier.value !== '***-***-****';
  return (
    <>
      {(equipmentIdentifier.type !== EquipmentIdentifierTypeEnum.MOBILE_PHONE_NUMBER || showMobilePhoneNumber) &&
        equipmentIdentifier.value}
      {equipmentIdentifier.type === EquipmentIdentifierTypeEnum.MOBILE_PHONE_NUMBER &&
        !equipmentIdentifier.active &&
        !equipmentIdentifier.timestamp && (
          <FormattedMessage id="shipmentDetails.equipmentIdentifiers.Inactive" defaultMessage="(Inactive)" />
        )}
      {equipmentIdentifier.active && (
        <>
          {equipmentIdentifier.type !== EquipmentIdentifierTypeEnum.MOBILE_PHONE_NUMBER && ' '}
          <FormattedMessage id="shipmentDetails.equipmentIdentifiers.active" defaultMessage="(Active)" />
        </>
      )}
      {equipmentIdentifier.timestamp && (
        <>
          <Tooltip
            placement="bottom"
            title={formatUtcTimeInTimeZoneOrDefaultToBrowserZone(equipmentIdentifier.timestamp)?.format(
              'MMMM Do YYYY, h:mm:ss a'
            )}
          >
            <div>(Scheduled)</div>
          </Tooltip>
        </>
      )}
    </>
  );
};

export interface CarrierDetailOptions {
  name: string;
  phone: string;
  scac?: string;
  dot?: string;
  mc?: string;
  eu?: string;
  vesselName?: string;
  imo?: string;
  mmsi?: string;
  callSign?: string;
}

export interface CarrierDetailProps extends WrappedComponentProps {
  mode: ShipmentModeEnum;
  carrierDetail?: CarrierDetailOptions;
  equipmentIdentifiers?: EquipmentIdentifier[];
  scheduledEquipmentIdentifiers?: EquipmentIdentifier[];
  shipmentId?: string;
  className?: string;
  authorizations?: {
    hasEquipmentIdentifiersEditing: () => boolean;
  };
  activeTrackingMethod?: {
    equipmentIdentifier?: EquipmentIdentifier;
    vendorIdentifier?: string;
  };
  shipmentDetails?: any;
}

const CarrierDetail: React.FC<CarrierDetailProps> = (props) => {
  const {
    mode,
    carrierDetail,
    activeTrackingMethod,
    equipmentIdentifiers = [],
    scheduledEquipmentIdentifiers = [],
    className = '',
  } = props;
  const isNewModel = mode !== ShipmentModeEnum.TL;
  const theme = useContext(ThemeContext);

  const [addedEquipmentIdentifiers, setAddedEquipmentIdentifiers] = useState<EquipmentIdentifier[]>([]);

  const onEquipmentIdentifierAdd = (newEquipId: EquipmentIdentifier) => {
    setAddedEquipmentIdentifiers([newEquipId, ...addedEquipmentIdentifiers]);
  };

  const combinedIdentifiers = mapActiveEquipmentIdentifier(
    [...equipmentIdentifiers, ...scheduledEquipmentIdentifiers],
    addedEquipmentIdentifiers,
    get(activeTrackingMethod, 'equipmentIdentifier')
  );
  const equipmentIdentifiersToShow = combinedIdentifiers.slice(0, 3);
  const overflowEquipmentIdentifiers = combinedIdentifiers.slice(3);

  if (!carrierDetail) {
    return null;
  }
  return (
    <div
      className={classNames('d-sm-flex w-100 carrier-detail-container justify-content-center text-center', className)}
    >
      <div
        className={classNames('d-flex flex-column justify-content-between', {
          'CarrierDetail card-container': mode === ShipmentModeEnum.TL,
          'CarrierDetail--ltl': mode === ShipmentModeEnum.LTL,
          'CarrierDetail--ocean': mode === ShipmentModeEnum.OCEAN,
        })}
      >
        <div className="d-flex flex-column justify-content-center flex-1">
          {isNewModel && <ShipmentModeIcon className="CarrierDetail__mode-icon" mode={mode} small={false} />}
          <span className="shipmentAttributeLabel">
            <FormattedMessage id="shipmentDetails.carrierDetail.label" defaultMessage="Carrier" />
          </span>
          <h2 className="shipmentAttributeValue">{carrierDetail.name}</h2>
          {carrierDetail.phone && <p>{carrierDetail.phone}</p>}
          {mode !== ShipmentModeEnum.OCEAN &&
            (carrierDetail.scac || carrierDetail.dot || carrierDetail.mc || carrierDetail.eu) && (
              <div className="carrier-info d-flex w-100">
                <div className="d-flex flex-column w-100 align-items-center">
                  {carrierDetail.scac && (
                    <Row className="w-100" noGutters>
                      <Col xs={12} className="header-label text-right">
                        SCAC
                      </Col>
                      <Col xs={12} className="font-weight-bold header-value text-left">
                        {carrierDetail.scac}
                      </Col>
                    </Row>
                  )}
                  {carrierDetail.dot && (
                    <Row className="w-100" noGutters>
                      <Col xs={12} className="header-label text-right">
                        <FormattedMessage id="shipmentDetails.carrierDetail.dotNumber" defaultMessage="DOT Number" />
                      </Col>
                      <Col className="font-weight-bold header-value text-left">{carrierDetail.dot}</Col>
                    </Row>
                  )}
                  {carrierDetail.mc && (
                    <Row className="w-100" noGutters>
                      <Col xs={12} className="header-label text-right">
                        <FormattedMessage id="shipmentDetails.carrierDetail.mcNumber" defaultMessage="MC Number" />
                      </Col>

                      <Col xs={12} className="font-weight-bold header-value text-left">
                        {carrierDetail.mc}
                      </Col>
                    </Row>
                  )}
                  {carrierDetail.eu && (
                    <Row className="w-100" noGutters>
                      <Col xs={12} className="header-label text-right">
                        <FormattedMessage id="shipmentDetails.carrierDetail.carrierId" defaultMessage="Carrier ID" />
                      </Col>
                      <Col className="font-weight-bold header-value text-left">{carrierDetail.eu}</Col>
                    </Row>
                  )}
                </div>
              </div>
            )}
          {(carrierDetail.vesselName ||
            carrierDetail.name ||
            carrierDetail.imo ||
            carrierDetail.mmsi ||
            carrierDetail.callSign) && (
            <div className="carrier-info d-flex w-100">
              <div className="d-flex flex-column w-100 align-items-center">
                {carrierDetail.vesselName && (
                  <Row className="w-100" noGutters>
                    <Col xs={12} className="header-label text-right">
                      <FormattedMessage id="shipmentDetails.carrierDetail.vesselName" defaultMessage="Vessel Name" />
                    </Col>
                    <Col xs={12} className="font-weight-bold header-value text-left">
                      {carrierDetail.vesselName}
                    </Col>
                  </Row>
                )}
                {carrierDetail.imo && (
                  <Row className="w-100" noGutters>
                    <Col xs={12} className="header-label text-right">
                      <FormattedMessage id="shipmentDetails.carrierDetail.imo" defaultMessage="IMO" />
                    </Col>
                    <Col xs={12} className="font-weight-bold header-value text-left">
                      {carrierDetail.imo}
                    </Col>
                  </Row>
                )}
                {carrierDetail.mmsi && (
                  <Row className="w-100" noGutters>
                    <Col xs={12} className="header-label text-right">
                      <FormattedMessage id="shipmentDetails.carrierDetail.mmsi" defaultMessage="MMSI" />
                    </Col>
                    <Col xs={12} className="font-weight-bold header-value text-left">
                      {carrierDetail.mmsi}
                    </Col>
                  </Row>
                )}
                {carrierDetail.callSign && (
                  <Row className="w-100" noGutters>
                    <Col xs={12} className="header-label text-right">
                      <FormattedMessage id="shipmentDetails.carrierDetail.callSign" defaultMessage="Call Sign" />
                    </Col>
                    <Col xs={12} className="font-weight-bold header-value text-left">
                      {carrierDetail.callSign}
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          )}
          {equipmentIdentifiersToShow.length > 0 &&
            equipmentIdentifiersToShow.map((equipmentIdentifier, index) => (
              <Row
                className="w-100"
                noGutters
                data-locator={`equipment-identifier-${equipmentIdentifier.type}${equipmentIdentifier.value}`}
                // sometimes type and value are the same for items in array so have to use index as key
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <Col xs={12} className="header-label text-right">
                  <FormattedMessage
                    id={`shipmentDetails.equipmentIdentifiers.types.${equipmentIdentifier.type}`}
                    defaultMessage={equipmentIdentifier.type}
                  />
                </Col>
                <Col xs={12} className="font-weight-bold header-value text-left">
                  <EquipmentIdentifierValue equipmentIdentifier={equipmentIdentifier} />
                </Col>
              </Row>
            ))}
          {overflowEquipmentIdentifiers.length > 0 && (
            <Row>
              <Col>
                <Tooltip
                  placement="bottom"
                  title={
                    <StyledEquipIdTooltip>
                      {overflowEquipmentIdentifiers.map((equipmentIdentifier) => (
                        <li key={`${equipmentIdentifier.type}${equipmentIdentifier.value}`}>
                          <FormattedMessage
                            id={`shipmentDetails.equipmentIdentifiers.types.${equipmentIdentifier.type}`}
                            defaultMessage={equipmentIdentifier.type}
                          />{' '}
                          <EquipmentIdentifierValue equipmentIdentifier={equipmentIdentifier} />
                        </li>
                      ))}
                    </StyledEquipIdTooltip>
                  }
                >
                  <StyledShowMore theme={theme}>
                    <FormattedMessage
                      id="shipmentDetails.equipmentIdentifiers.showMore"
                      defaultMessage="{noOfMore} more"
                      values={{ noOfMore: overflowEquipmentIdentifiers.length }}
                    />
                  </StyledShowMore>
                </Tooltip>
              </Col>
            </Row>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center">
          {mode === ShipmentModeEnum.TL && (
            <Tooltip
              placement="bottom"
              title={props.intl.formatMessage({
                id: 'shipmentDetails.carrierDetail.moreInfo',
                defaultMessage:
                  'This card shows information about the carrier. It will show carrier identifiers, and if applicable, equipment identifers that are being used for tracking. To comply with GDPR, mobile phone numbers are hidden',
              })}
            >
              <StyledIcon customTheme={theme} />
            </Tooltip>
          )}
          {((props?.authorizations?.hasEquipmentIdentifiersEditing() && mode === ShipmentModeEnum.TL) ||
            props.shipmentDetails?.entitlementInfo?.isOwner) && (
            <AddEquipmentIdentifier
              identifiers={props.shipmentDetails?.identifiers}
              capacityProviderId={props.shipmentDetails?.carrierInfo?.id}
              shipmentId={props.shipmentId}
              onAdd={onEquipmentIdentifierAdd}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(CarrierDetail);
