import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import { defineMessages } from 'react-intl';
import { reducer as formReducer } from 'redux-form';
import * as types from './types';
import { addEditFormConstants } from '../AddEditShipmentConstants';
import { TEMPERATURE_SCALE, intl } from '../../../common/AppConstants';

const messages = defineMessages({
  newShipment: {
    id: 'addShipment.progressWizardHeader.newShipment',
    defaultMessage: 'New Shipment',
  },
});

export const availableTrackingMethods = {
  api: { id: 'API', name: 'API' },
  phone: { id: 'DRIVER_PHONE', name: 'Driver Phone' },
  truckNumber: { id: 'TRUCK_NUMBER', name: 'Truck #' },
  licensePlate: { id: 'LICENSE_PLATE', name: 'License Plate #' },
};

export const carrierTypeIdentifiers = {
  scac: { id: 'SCAC', name: 'SCAC' },
  mc: { id: 'MC', name: 'MC #' },
  dot: { id: 'DOT', name: 'DOT #' },
  eu: { id: 'EU', name: 'EU ID' },
};

export const shipmentTypeIdentifiers = {
  bol: { id: 'BILL_OF_LADING', name: 'BOL #' },
  order: { id: 'ORDER', name: 'Order #' },
};

export const initialState = {
  title: '',
  open: false,
  snackbarOpen: false,
  dropDownOpen: false,
  addShipmentOptions: [],
  anchorEl: undefined,
  carrierInfo: {
    id: null,
    typeIdentifiers: [],
    trackingMethods: [],
    canRefrigerate: false,
  },
  tempTrackingEnabled: true,
  formValues: {
    carrierType: 'SCAC',
    pickupForm: {
      country: 'USA',
    },
    dropOffForm: {
      country: 'USA',
    },
    temperatureForm: {
      lowerBound: 0,
      targetTemp: 0,
      upperBound: 0,
      temperatureScale: TEMPERATURE_SCALE.FAHRENHEIT,
    },
    trackingMethod: null,
    addStops: [{ expanded: true, country: 'USA' }],
  },
  steps: [
    {
      id: 'LANE_DETAIL',
      completed: false,
      selected: true,
    },
    {
      id: 'SHIPMENT',
      completed: false,
      selected: false,
    },
    {
      id: 'PICKUP_DETAIL',
      completed: false,
      selected: false,
    },
    {
      id: 'DROPOFF_DETAIL',
      completed: false,
      selected: false,
    },
    {
      id: 'ADD_STOPS',
      completed: false,
      selected: false,
    },
    {
      id: 'SUMMARY',
      completed: false,
      selected: false,
    },
  ],
};

const carrierSearchReducer = (state, action) => {
  switch (action.type) {
    case types.CARRIER_SEARCH_SUCCESS: {
      const updatedState = cloneDeep(state);
      updatedState.carrierInfo = action.payload;
      // if there is only 1 tracking method then automatically select it
      const trackingMethodsAvailable = get(action.payload, 'trackingMethods', []);

      if (trackingMethodsAvailable.length === 1) {
        updatedState.formValues.trackingMethod = trackingMethodsAvailable[0].id;
      }

      // NOTE: For TLT-878, we are only allowing temperature tracked shipments for carriers that support it.
      // However, as of 8/2/2018, no such repository of supporting carriers exists. For that reason,
      // temperature tracking will be excluded from created shipments unless the SCAC is P444. Until a backend
      // repository is created, carriers supporting Reefer will be hardcoded here. The condition below adds the
      // temperature tracking step to the Add Shipment screen
      //
      // - Matt Mariutto
      if (updatedState.carrierInfo.name === 'Project44') {
        const tempTrackingStep = {
          id: 'TEMPERATURE_TRACKING',
          completed: false,
          selected: false,
        };
        updatedState.steps.splice(2, 0, tempTrackingStep);
        updatedState.carrierInfo.canRefrigerate = true;
      } else {
        updatedState.steps = updatedState.steps.filter((item) => item.id !== 'TEMPERATURE_TRACKING');
      }

      return updatedState;
    }
    case types.CARRIER_SEARCH_FAIL: {
      return state;
    }
    default:
      return state;
  }
};

const putPostalCodeTimezoneFromActionResult = (postalCodeTimezones, action) => {
  const postalCode = get(action, 'payload.postalCode');
  const timezone = get(action, 'payload.details.timezone');

  if (postalCode && timezone) {
    postalCodeTimezones[postalCode] = timezone;
  }
};

const addEditShipmentReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.ADD_SHIPMENT:
      return { ...state, open: true, title: intl.formatMessage(messages.newShipment), dropDownOpen: false };
    case types.EDIT_SHIPMENT:
      return { ...state, open: true, stopNumber: action.stopNumber, title: 'Edit Shipment' };
    case types.CANCEL_SHIPMENT:
      return { ...initialState };
    case types.SET_CARRIER:
      return { ...state, initialValues: { carrierType: action.carrierType } };
    case types.SET_TEMPERATURE_SCALE: {
      const updatedState = cloneDeep(state);
      updatedState.formValues.temperatureForm = action.formValues.temperatureForm;
      return updatedState;
    }
    case types.SKIP_TEMP_TRACKING: {
      const updatedState = cloneDeep(state);
      action.formValues.tempTrackingEnabled = false;
      updatedState.formValues.tempTrackingEnabled = action.formValues.tempTrackingEnabled;
      updatedState.formValues.temperatureForm = action.formValues.temperatureForm;
      return updatedState;
    }
    case types.TOGGLE_CONTACT:
      return { ...state, contactInfoExpanded: !state.contactInfoExpanded };
    case types.TOGGLE_STOP:
      return {
        ...state,
        formValues: { addStops: toggleStops(state.formValues.addStops, action.index, action.length) },
      };
    case types.NEXT_STEP: {
      const newState = { ...state, steps: navigateByDirection(state.steps, types.NEXT_STEP) };
      delete newState.errors;
      return newState;
    }
    case types.PREVIOUS_STEP: {
      const newState = { ...state, steps: navigateByDirection(state.steps, types.PREVIOUS_STEP) };
      delete newState.errors;
      return newState;
    }
    case types.NAVIGATE_STEP:
      return { ...state, steps: navigateById(state.steps, action.id) };
    case types.SAVE_SHIPMENT_SUCCESS:
      return { ...initialState, snackbarOpen: true };
    case types.CARRIER_SEARCH_SUCCESS:
      return carrierSearchReducer(state, action);
    case types.CARRIER_SEARCH_FAIL:
      return carrierSearchReducer(state, action);
    case types.TRACKING_METHOD_CHOSEN: {
      const updatedState = cloneDeep(state);
      updatedState.formValues.trackingMethod = action.trackingMethodId;

      return updatedState;
    }
    case types.SAVE_SHIPMENT_FAILED: {
      return { ...state, errors: action.payload };
    }
    case types.POSTAL_CODE_LOOKUP_SUCCESS: {
      const newState = { ...state };
      newState.postalCodeTimezones = { ...newState.postalCodeTimezones };

      putPostalCodeTimezoneFromActionResult(newState.postalCodeTimezones, action);

      return newState;
    }
    case types.SAVE_SHIPMENT_STOP_EDIT_SUCCESS:
      return { ...state, open: false, stopNumber: action.stopNumber, snackbarOpen: true };
    case types.SAVE_SHIPMENT_STOP_EDIT_FAILED:
      return { ...state, errors: action.payload.errorDetails };
    case types.ADD_EDIT_CLOSE_SNACKBAR:
      return { ...state, snackbarOpen: false };
    case types.OPEN_DROPDOWN:
      return { ...state, dropDownOpen: true, anchorEl: action.target };
    case types.CLOSE_DROPDOWN:
      return { ...state, dropDownOpen: false };
    default:
      return state;
  }
};

export const addEditShipmentFormReducer = formReducer.plugin({
  [addEditFormConstants.mainFormName]: (state, action) => {
    switch (action.type) {
      case types.SAVE_SHIPMENT_SUCCESS:
      case types.SAVE_SHIPMENT_STOP_EDIT_SUCCESS:
      case types.CANCEL_SHIPMENT:
        // destroy form as per Option A: https://github.com/erikras/redux-form/blob/master/docs/faq/HowToClear.md
        return undefined;
      default:
        return state;
    }
  },
});

export default addEditShipmentReducer;

export function navigateByDirection(steps, direction) {
  let completed = false;
  // eslint-disable-next-line no-param-reassign
  steps = cloneDeep(steps);
  return steps.map((step, index) => {
    if (step.selected && !completed) {
      if (step.subSteps && checkSubSteps(step, direction)) {
        const stepOptions = step.subSteps.steps;
        // navigate to next substep
        step.subSteps.currentStep = stepOptions[nextStep[direction](stepOptions.indexOf(step.subSteps.currentStep), 1)];
      } else {
        step.selected = false;
        step.completed = true;
        steps[nextStep[direction](index, 1)].selected = true;
        completed = true;
      }
    }
    return step;
  });
}

export function navigateById(steps, id) {
  // eslint-disable-next-line no-param-reassign
  steps = cloneDeep(steps);
  return steps.map((step) => {
    step.selected = step.id === id;
    if (id === 'SUMMARY') {
      step.completed = true;
    }
    if (step.subSteps) {
      step.subSteps.currentStep = step.subSteps.steps[0];
    }
    return step;
  });
}

export function checkSubSteps(step, type) {
  return type === types.NEXT_STEP
    ? last(step.subSteps.steps) !== step.subSteps.currentStep
    : step.subSteps.steps[0] !== step.subSteps.currentStep;
}

export const nextStep = {
  [types.NEXT_STEP]: (a, b) => a + b,
  [types.PREVIOUS_STEP]: (a, b) => a - b,
};

export function toggleStops(values, activeIndex, length) {
  // eslint-disable-next-line no-param-reassign
  values = cloneDeep(values);
  let i = 0;
  while (values.length < length) {
    values.push({ expanded: i === activeIndex });
    i++;
  }
  return values;
}
