import { connect } from 'react-redux';

import { compose, lifecycle } from 'recompose';
import FilterUtil from 'components/filter/util/filterUtil';
import {
  getPrincipalAuthoritiesFromState,
  getCustomerPropertiesFromState,
  getPrincipalAuthorizationsFromState,
  getPrincipalFromState,
} from 'common/authorizations';
import { buildSetSortFieldAction } from 'components/common/sortResults/ducks';
import ShipmentListComponent from './ShipmentListComponent';

import {
  buildSetSearchTerm,
  buildShipmentSearchQueryAction,
  buildGetNextShipmentPageAction,
  buildExecuteLastSearchQuery,
  getTLEquipmentIdentifierTypes,
  toogleModalMultiValueSearch,
} from './ducks/searchReducer';

import { closeSnackbar } from '../shipmentDetails/TL/ducks/actions';

import { toggleModeFilterFn, clearFiltersNoSearch, toggleLoadFilter } from '../filter/ducks/actions';

const mapStateToProps = (state) => {
  const { searchReducer, sortResultsReducer, filterReducer, tlShipmentDetailsReducer } = state;
  const authorities = getPrincipalAuthoritiesFromState(state);
  const customerProperties = getCustomerPropertiesFromState(state);
  const authorizations = getPrincipalAuthorizationsFromState(state);

  searchReducer.shipments.forEach((shipment) => {
    shipment.startTimeBeforePickupMinutes =
      customerProperties.V3_TRUCKLOAD_TRACKING_JOB_START_TIME_BEFORE_PICKUP_IN_MINUTES;
  });

  return {
    ...searchReducer,
    previousSearchQuery: searchReducer.previousSearchQuery,
    sortFields: sortResultsReducer.sortFields,
    isLoading: searchReducer.isLoading,
    filter: searchReducer.previousSearchQuery.filter,
    modeFilters: filterReducer.modeFilters,
    modeFilterOptions: FilterUtil.getFilterOptionsBasedOnAuthorities(filterReducer.allModeFilterOptions, authorities),
    displayedFilterOptions: filterReducer.displayedFilterOptions,
    deleteSnackbarOpen: tlShipmentDetailsReducer.deleteSnackbarOpen,
    authorities: authorities,
    customerProperties,
    selectedSortFieldId: sortResultsReducer.selectedSortFieldId,
    authorizations,
    principal: getPrincipalFromState(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSearchTerm: (searchTerm) => dispatch(buildSetSearchTerm(searchTerm)),
  executeLastSearchQuery: (previousSearchQuery, authorities) =>
    dispatch(buildExecuteLastSearchQuery(previousSearchQuery, authorities)),
  loadMoreShipments: (previousSearchQuery, authorities) =>
    dispatch(buildGetNextShipmentPageAction(previousSearchQuery, authorities)),
  executeSearchFn: (searchTerm, filters, modeFilters, sortFields, authorities, enableMultiValueSearch, recent) => {
    dispatch(
      buildShipmentSearchQueryAction({
        authorities,
        searchText: searchTerm,
        filter: filters,
        modes: modeFilters,
        sortBy: sortFields,
        enableMultiValueSearch,
        recent,
      })
    );
  },
  clearFiltersNoSearchFn: () => dispatch(clearFiltersNoSearch()),
  closeSnackbar: () => dispatch(closeSnackbar()),
  toggleModeFilterFn: (authorities, option, searchTerm, filters, sortFields, page, isMultiValue) => {
    dispatch(toggleModeFilterFn(authorities, option, searchTerm, filters, sortFields, page, isMultiValue));
  },
  getEquipmentIdentifierTypes: () => dispatch(getTLEquipmentIdentifierTypes()),
  setIsMuliValueSearchModalOpen: (isMuliValueSearchModalOpen) => {
    dispatch(toogleModalMultiValueSearch(isMuliValueSearchModalOpen));
  },
  toggleLoadFilter: (obj) => dispatch(toggleLoadFilter(obj)),
  buildSetSortFieldAction: (type, sortFields, sortFieldId, sortType) =>
    dispatch(buildSetSortFieldAction(type, sortFields, sortFieldId, sortType)),
});

const doInit = (props) => {
  if (!props.totalSearchResults && !props.isLoading) {
    props.executeLastSearchQuery(props.previousSearchQuery, props.authorities);
  }
};

const ShipmentListComponentWithInitialFetch = compose(
  lifecycle({
    componentDidMount() {
      doInit(this.props);
      this.props.getEquipmentIdentifierTypes();
    },
  })
)(ShipmentListComponent);

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentListComponentWithInitialFetch);
