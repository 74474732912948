import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { Theme } from '../models/Theme';

export const updateSelectedTheme = createStandardAction('BRAND_THEMING/UPDATE_SELECTED_THEME')<Theme>();
export const revertSelectedTheme = createStandardAction('BRAND_THEMING/REVERT_SELECTED_THEME')<void>();

export const save = createAsyncAction('BRAND_THEMING/SAVE', 'BRAND_THEMING/SAVE_SUCCESS', 'BRAND_THEMING/SAVE_FAILURE')<
  Theme,
  Theme,
  void
>();

export const fetch = createAsyncAction(
  'BRAND_THEMING/FETCH',
  'BRAND_THEMING/FETCH_SUCCESS',
  'BRAND_THEMING/FETCH_FAILURE'
)<void, Theme, void>();
