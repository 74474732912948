import SessionDialogContent from '../../applicationDialogs/sessionModal/SessionDialogContent';

const SessionWrapper = (props) => {
  return (
    <div>
      {props.sessionDialogOpen && (
        <SessionDialogContent open={props.sessionDialogOpen} dialogSessionHandler={props.dialogSessionHandler} />
      )}
    </div>
  );
};

export default SessionWrapper;
