import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import toLower from 'lodash/toLower';

import * as UrlUtils from 'common/urlUtils';
import * as constants from './ducks/constants';
import {
  ShipmentNotificationRuleConstants,
  TLEventTypeKeyDisplayConstants,
  LTLEventTypeKeyDisplayConstants,
  ParcelEventTypeKeyDisplayConstants,
  EventNotificationTargetTypeConstants,
} from './ducks/constants';

export const getQueryString = (props) => get(props, 'location.search', '');
export const getValueForKeyFromProps = (props, key) => UrlUtils.getQueryStringParameter(getQueryString(props), key);

export const queryStringWithShipmentId = ({ shipmentId, masterShipmentId, mode }) => {
  if (!isNil(masterShipmentId)) {
    return `?masterShipmentId=${masterShipmentId}&mode=${mode}`;
  }
  if (!isNil(shipmentId)) {
    return `?shipmentId=${shipmentId}&mode=${mode}`;
  }
  return undefined;
};

export const determineAccessLevelObjectFromProps = (props, state) => {
  const shipmentId = getValueForKeyFromPropsOrState(props, state, 'shipmentId');
  const masterShipmentId = getValueForKeyFromPropsOrState(props, state, 'masterShipmentId');
  const mode = getValueForKeyFromPropsOrState(props, state, 'mode');

  if (masterShipmentId) {
    return { masterShipmentId, mode, accessLevel: constants.ShipmentAccessLevelConstants.Shipment };
  }
  if (shipmentId) {
    return { shipmentId, mode, accessLevel: constants.ShipmentAccessLevelConstants.Shipment };
  } else {
    return { accessLevel: constants.ShipmentAccessLevelConstants.Tenant };
  }
};

export const determineAccessLevelFromPropsOrState = (props, state) => {
  let accessLevel;
  if (props) {
    accessLevel = determineAccessLevelObjectFromProps(props, state).accessLevel;
  }
  if (isNil(accessLevel) && state) {
    accessLevel = getAccessLevelFromState(state);
  }

  return accessLevel;
};

const getValueForKeyFromState = (state, key) => {
  if (!state) {
    return null;
  }

  return (
    state.manageNotificationsReducer.notificationsReducer[key] ||
    state.manageNotificationsReducer.notificationReducer[key]
  );
};

const getAccessLevelFromState = (state) => {
  if (!state) {
    return null;
  }

  return (
    state.manageNotificationsReducer.notificationsReducer.accessLevel ||
    state.manageNotificationsReducer.notificationReducer.accessLevel
  );
};

export const getValueForKeyFromPropsOrState = (props, state, key = 'shipmentId') => {
  let value;
  value = getValueForKeyFromProps(props, key);
  if (isNil(value) && state) {
    value = getValueForKeyFromState(state, key);
  }

  return value;
};

export const createAccessLevelObject = (accessLevel, shipmentId, masterShipmentId, mode) => ({
  accessLevel,
  shipmentId,
  masterShipmentId,
  mode,
});

export const getEventName = (event) => {
  const eventTypeKey = get(event, 'eventTypeKey');
  if (!eventTypeKey) {
    return null;
  }
  return (
    TLEventTypeKeyDisplayConstants.get(eventTypeKey) ||
    LTLEventTypeKeyDisplayConstants.get(eventTypeKey) ||
    ParcelEventTypeKeyDisplayConstants.get(eventTypeKey) ||
    eventTypeKey
  );
};

export const getDurationDisplay = (event) => {
  const timeParameter = get(event, 'timeParameter');

  if (!timeParameter) {
    return null;
  }

  const prefix = ShipmentNotificationRuleConstants.eventTypeTimeConstraintParameters.get(event.eventTypeKey) || '';
  const suffix =
    ShipmentNotificationRuleConstants.eventTypeTimeConstraintSuffixParameters.get(event.eventTypeKey) || '';

  const timeValue = get(timeParameter, 'value', '');
  const units = get(timeParameter, 'units', '');

  const displayComponents = [prefix, timeValue, units];

  if (suffix) {
    displayComponents.push(suffix);
  }

  return displayComponents.join(' ').trim().toLowerCase() || null;
};

export const getStopDisplay = (event) => {
  const stopParameter = get(event, 'stopParameter');

  if (!stopParameter) {
    return null;
  }

  const prefix = ShipmentNotificationRuleConstants.eventTypeStopParameterValues.get(event.eventTypeKey) || '';

  const stopParameterDisplay = get(
    ShipmentNotificationRuleConstants.eventTypeStopLocationParameterValues.find(
      (param) => param.type === stopParameter.type
    ),
    'display',
    ''
  );

  return `${prefix} ${stopParameterDisplay}`.trim().toLowerCase() || null;
};

export const hasTenantShipmentFiltersDisplayItems = (filters) => {
  return !!get(filters, 'fields.length');
};

export const getTenantShipmentFiltersDisplayItems = (filters) => {
  if (!hasTenantShipmentFiltersDisplayItems(filters)) {
    return [];
  }

  const operatorDisplayValue = getOperatorDisplayName(filters.operator);
  return filters.fields
    .map((field, index, fieldsArray) => {
      if (!field) {
        return null;
      }
      const fieldName = getFieldTypeDisplayName(field.type);
      const fieldValues = getFieldValuesDisplay(field.values);

      if (!fieldName || !fieldValues) {
        return null;
      }

      return operatorDisplayValue
        ? {
            defaultMessage: `{OPERATORDISPLAY} {FIELDNAME} is {FIELDVALUES}`,
            id: 'notifications.notificationViewUtil.fieldDisplay',
            values: {
              FIELDNAME: fieldName,
              FIELDVALUES: fieldValues,
              OPERATORDISPLAY: operatorDisplayValue,
            },
          }
        : {
            defaultMessage: `{FIELDNAME} is {FIELDVALUES}`,
            id: 'notifications.notificationViewUtil.fieldDisplay',
            values: {
              FIELDNAME: fieldName,
              FIELDVALUES: fieldValues,
            },
          };
    })
    .filter((field) => !!field);
};

const getOperatorDisplayName = (operator) => toLower(operator);
const getFieldTypeDisplayName = (fieldType) => EventNotificationTargetTypeConstants[fieldType] || fieldType;
const normalizeValue = (value) => (value || '').replace(/,\s*/, ' ').trim();
export const getFieldValuesDisplay = (values) => {
  if (!values) {
    return null;
  }
  if (!isArray(values)) {
    return values;
  }
  if (!values.length) {
    return null;
  }

  let valuesString = '';

  for (let i = 0, last = values.length - 1; i <= last; ++i) {
    const normalizedValue = normalizeValue(values[i]);
    if (!normalizedValue) {
      continue;
    }

    if (i > 0) {
      if (i === last) {
        valuesString += ` or `;
      } else if (last > 1) {
        valuesString += ', ';
      }
    }
    valuesString += normalizedValue;
  }
  return valuesString;
};
