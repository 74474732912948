import { FormSection, Field } from 'redux-form';
import { defineMessages } from 'react-intl';
import { intl } from 'common/AppConstants';
import { EventTypeStopLocations } from '../../models';

import { RenderSingleSelectDropdown } from '../AddEditFormInputs';

const message = defineMessages({
  selectLocation: {
    id: 'notifications.eventRuleFormSection.selectLocation',
    defaultMessage: 'Select Location',
  },
});

export interface EventRuleStopProps {
  children?: JSX.Element[];
  className?: string;
  eventTypeStopLocations: EventTypeStopLocations[];
  inputId?: string;
}

export const EventRuleStop = (props: EventRuleStopProps) => {
  return (
    <div className={props.className}>
      {props.children}
      <FormSection name="stopParameter">
        <label htmlFor={props.inputId} className="sr-only">
          {intl.formatMessage(message.selectLocation)}
        </label>
        <Field
          name="type"
          className=""
          id={props.inputId}
          data-locator="stop-type-input"
          placeholder={intl.formatMessage(message.selectLocation)}
          component={RenderSingleSelectDropdown}
          dropdownItems={props.eventTypeStopLocations.map((eventTypeStopLocationParameterValue) => ({
            value: eventTypeStopLocationParameterValue.type,
            displayName: eventTypeStopLocationParameterValue.display,
          }))}
        />
      </FormSection>
    </div>
  );
};

export default EventRuleStop;
