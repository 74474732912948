import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import moment from 'moment';

import first from 'lodash/first';
import {
  AppointmentWindowStatus,
  AppointmentWindowStatusEnum,
  AppointmentWindowStatusIntlKeys,
  AppointmentWindowStatusColors,
  Shipment,
  ShipmentEvent,
  ShipmentLeg,
  ShipmentModeEnum,
  ShipmentStopAppointmentWindow,
  TruckloadStatusCodeEnum,
  ShipmentStop,
  ShipmentStopTypeEnum,
  ShipmentStopStatusEnum,
  StatusUpdate,
  StopStatus,
  DateTimeWithTimezone,
  TruckloadStatusCodeIntlKeys,
  ShipmentStatusReasonCodeEnum,
  TruckloadDescriptionEnum,
  ShipmentStopAppointmentWindowTypeEnum,
  ShipmentLegCarrierInfo,
  CarrierInfoTypeEnum,
} from 'models';
import { newFruit, green, primaryBlue } from 'styles/colors';
import StatusReason from 'components/shipment/common/enums/statusReason';
import { populateEventsWithTimezone } from '../utils';

/**
 *
 * @param shipmentDetailsTL
 * mapShipmentTL() is a temporary mapper to map the old TL shipment data model to the upcoming
 * unified API model (SEP) for the purpose of using the mode-agnostic RouteTimelineComponent on the
 * TL details page. This can be deprecated after TL is turned over to #intermodal/unifiedAPI.
 *
 */
export const mapShipmentTL = (shipmentDetailsTL: any = {}): Shipment => {
  const id = shipmentDetailsTL.shipmentId;
  const currentStatus = get(shipmentDetailsTL, 'currentStop.statusCode');
  const currentStatusDateTime = get(shipmentDetailsTL, 'currentStop.utcTimestamp');
  const legs = getLegsFromShipmentDetailsTL(shipmentDetailsTL);
  const identifiers = get(shipmentDetailsTL, 'identifiers');
  const sourceRegion = get(shipmentDetailsTL, 'sourceRegion');
  // RouteTimelineComponent does not use states
  const states: any = [];

  return { id, currentStatus, currentStatusDateTime, legs, identifiers, states, sourceRegion, events: [] };
};

export const getLegsFromShipmentDetailsTL = (shipmentDetailsTL: any = {}): ShipmentLeg[] => {
  const allStatusUpdates = get(shipmentDetailsTL, 'allStatusUpdates', []);
  const allStops = get(shipmentDetailsTL, 'shipmentStops', []);
  const originStopTimezone = get(
    shipmentDetailsTL,
    'shipmentStops[0].location.address.locationCoordinatesDto.localTimeZoneIdentifier'
  );
  const originStopTimezoneShort = get(
    shipmentDetailsTL,
    'shipmentStops[0].location.address.locationCoordinatesDto.localTimeZoneIdentifierShort'
  );

  const { statusUpdatesByStops, remainingStatusUpdates } = separateStatusUpdatesByStops(allStatusUpdates, allStops);

  const shipmentLeg: ShipmentLeg = {
    id: null,
    currentStatus: get(shipmentDetailsTL, 'currentStop.statusCode'),
    mode: ShipmentModeEnum.TL,
    stops: getStopsFromShipmentDetailsTL(shipmentDetailsTL, statusUpdatesByStops, remainingStatusUpdates),
    events: populateEventsWithTimezone(
      filterOutConsecutiveAndEmptyStatusUpdates(remainingStatusUpdates).map(mapStatusUpdateToEvent),
      originStopTimezone,
      originStopTimezoneShort
    ),
    // RouteTimelineComponent does not use positions
    positions: [],
    carrierInfo: mapCarrierInfo(get(shipmentDetailsTL, 'carrierInfo')),
  };
  return isEmpty(shipmentDetailsTL) ? [] : [shipmentLeg];
};

const mapCarrierInfo = (carrierInfo?: {
  mc?: string;
  dot?: string;
  eu?: string;
  scac?: string;
}): ShipmentLegCarrierInfo[] => {
  if (carrierInfo?.mc) {
    return [
      {
        name: CarrierInfoTypeEnum.MC_NUMBER,
        value: carrierInfo.mc,
      },
    ];
  } else if (carrierInfo?.dot) {
    return [
      {
        name: CarrierInfoTypeEnum.DOT_NUMBER,
        value: carrierInfo.dot,
      },
    ];
  } else if (carrierInfo?.eu) {
    return [
      {
        name: CarrierInfoTypeEnum.P44_EU,
        value: carrierInfo.eu,
      },
    ];
  } else if (carrierInfo?.scac) {
    return [
      {
        name: CarrierInfoTypeEnum.SCAC,
        value: carrierInfo.scac,
      },
    ];
  }
  return [];
};

export const separateStatusUpdatesByStops = (
  allStatusUpdates: StatusUpdate[] = [],
  allStops: any[] = []
): { statusUpdatesByStops: { [key: number]: StatusUpdate[] }; remainingStatusUpdates: StatusUpdate[] } => {
  const statusUpdatesByStops: { [key: number]: StatusUpdate[] } = {};
  const remainingStatusUpdates: StatusUpdate[] = [];
  const sortedStatusUpdates = orderBy(allStatusUpdates, [(obj: any) => moment.utc(obj.utcTimestamp)], ['asc']);

  sortedStatusUpdates.forEach((statusUpdate: any) => {
    /* the RouteTimelineComponent expects at least one event for a departed origin stop, so if a shipment has
    departed the first stop and there is an AT_STOP status update missing for the first stop, we will
    associate the first stopNumber-ed status update with the origin (usually this is an IN_TRANSIT to stop 2 update)
    */
    const notDispatchedOrInTransit = ![TruckloadStatusCodeEnum.DISPATCHED, TruckloadStatusCodeEnum.IN_TRANSIT].includes(
      statusUpdate.statusCode
    );
    const notInMotion = ![ShipmentStatusReasonCodeEnum.IN_MOTION].includes(statusUpdate.statusReasonCode);
    const isFailedToAcquireLocation = (statusUpdate: StatusUpdate) =>
      statusUpdate?.statusReasonCode === StatusReason.FAILED_TO_ACQUIRE_LOCATION;
    const isEtaOutDatedDescription = (statusUpdate: StatusUpdate) =>
      statusUpdate?.description === ShipmentStatusReasonCodeEnum.ETA_OUT_OF_DATE;

    if (statusUpdate.stopNumber > 1 && isNil(statusUpdatesByStops[1])) {
      /* Prevents adding a completed status update in the first stop. 
         In order to preserve timeline order in the UI, a default status update will be added in the first stop as follows:
         - StatusCode = INFO
         - utcTimestamp = first stop's utcAppointmentStartDateTime
         - address (used to set the timezone) = first stop's location address
      */
      if (TruckloadStatusCodeEnum.COMPLETED === statusUpdate.statusCode) {
        const defaultStatusUpdate: StatusUpdate = {
          statusCode: TruckloadStatusCodeEnum.INFO,
          utcTimestamp: '',
        };

        const firstStop = first(filter(allStops, (stop) => stop.stopNumber === 1));
        if (firstStop) {
          if (firstStop.utcAppointmentStartDateTime) {
            defaultStatusUpdate.utcTimestamp = firstStop.utcAppointmentStartDateTime;
          }
          if (firstStop.location && firstStop.location.address) {
            defaultStatusUpdate.address = firstStop.location.address;
          }
        }

        statusUpdatesByStops[1] = [defaultStatusUpdate];
        statusUpdatesByStops[statusUpdate.stopNumber]
          ? statusUpdatesByStops[statusUpdate.stopNumber].push(statusUpdate)
          : (statusUpdatesByStops[statusUpdate.stopNumber] = [statusUpdate]);
      } else {
        statusUpdatesByStops[1] = [statusUpdate];
      }
    } else if (
      // A status is associated with a stop if it has a stopNumber and is NOT either DISPATCHED or IN_TRANSIT
      !isNil(statusUpdate.stopNumber) &&
      notDispatchedOrInTransit &&
      notInMotion
    ) {
      statusUpdatesByStops[statusUpdate.stopNumber]
        ? statusUpdatesByStops[statusUpdate.stopNumber].push(statusUpdate)
        : (statusUpdatesByStops[statusUpdate.stopNumber] = [statusUpdate]);
    } else if (
      isNil(statusUpdate.stopNumber) &&
      (isFailedToAcquireLocation(statusUpdate) || isEtaOutDatedDescription(statusUpdate))
    ) {
      const lastStatusUpdate = last(remainingStatusUpdates);
      if (
        lastStatusUpdate &&
        (isFailedToAcquireLocation(lastStatusUpdate) || isEtaOutDatedDescription(lastStatusUpdate))
      ) {
        remainingStatusUpdates[remainingStatusUpdates.length - 1] = statusUpdate;
      } else {
        remainingStatusUpdates.push(statusUpdate);
      }
    } else {
      remainingStatusUpdates.push(statusUpdate);
    }
  });
  return { statusUpdatesByStops, remainingStatusUpdates };
};

export const getStopsFromShipmentDetailsTL = (
  shipmentDetailsTL: any = {},
  statusUpdatesByStops: { [key: number]: StatusUpdate[] } = {},
  remainingStatusUpdates: StatusUpdate[] = []
): ShipmentStop[] => {
  const pickup = shipmentDetailsTL.pickup
    ? { ...shipmentDetailsTL.pickup, type: ShipmentStopTypeEnum.ORIGIN }
    : undefined;
  const delivery = shipmentDetailsTL.delivery
    ? { ...shipmentDetailsTL.delivery, type: ShipmentStopTypeEnum.DESTINATION }
    : undefined;
  const intermediateStops = get(shipmentDetailsTL, 'intermediateStops', []);
  const rawStops = [pickup, ...intermediateStops, delivery];
  const currentStop = get(shipmentDetailsTL, 'currentStop', {});

  const ETAOutDatedDescription = (item: StatusUpdate) => {
    return (
      item.description === StatusReason.ETA_OUT_OF_DATE ||
      item.description === TruckloadDescriptionEnum.MISSING_LOCATION_DATA
    );
  };

  const isETAError = (remainingStatusUpdates: StatusUpdate[], currentStop: any) => {
    return (
      last(filter(remainingStatusUpdates, (item) => ETAOutDatedDescription(item))) &&
      currentStop.statusCode !== TruckloadStatusCodeEnum.COMPLETED
    );
  };
  return rawStops.filter(Boolean).map((rawStop: any = {}, idx: number): ShipmentStop => {
    const stopTimezoneIdentifier = getStopTimezoneIdentifier(rawStop.stopNumber, shipmentDetailsTL.shipmentStops);
    const { recordedArrivalDateTime, recordedDepartureDateTime } = getStopArrivalAndDepartedTimestamps(
      rawStop.stopNumber,
      shipmentDetailsTL.latestStopStatuses,
      stopTimezoneIdentifier.localTimeZoneIdentifier
    );
    const appointmentWindow = getDefaultApptWindow(rawStop, stopTimezoneIdentifier);
    return {
      type: rawStop.type,
      uuid: get(rawStop, 'uuid'),
      stopNumber: get(rawStop, 'stopNumber'),
      stopName: get(rawStop, 'name'),
      stopId: get(rawStop, 'stopName') ?? get(rawStop, 'name'),
      location: {
        city: get(rawStop, 'city'),
        state: get(rawStop, 'state'),
        lat: get(shipmentDetailsTL, `shipmentStops[${idx}].location.address.locationCoordinatesDto.latitude`),
        lng: get(shipmentDetailsTL, `shipmentStops[${idx}].location.address.locationCoordinatesDto.longitude`),
      },
      locationId: get(rawStop, 'locationId'),
      status: getMostRecentStopStatus(rawStop, currentStop),
      statusMessage: getRelevantStopStatusMessage(get(rawStop, 'statusMessage')),
      statusTagColorValue: getStatusTagColorValue(rawStop),
      timelinessMessage: rawStop.isLateMessage,
      appointmentWindow,
      appointmentWindows: getAdditionalApptWindowsForStop(
        rawStop,
        shipmentDetailsTL.latestStopStatuses,
        stopTimezoneIdentifier.localTimeZoneIdentifier
      ),
      events: getStopEvents(rawStop.stopNumber, statusUpdatesByStops),
      contactName: rawStop.contactName,
      contactPhone: rawStop.contactPhone,
      editable: get(rawStop, 'editable', false),
      isComplete: rawStop.isComplete,
      recordedArrivalDateTime,
      recordedDepartureDateTime,
      hasETAError: isETAError(remainingStatusUpdates, currentStop),
    };
  });
};

const getStopTimezoneIdentifier = (
  stopNumber: number,
  allShipmentStops: any[]
): { localTimeZoneIdentifier: any; localTimeZoneIdentifierShort: any } => {
  const shipmentStop = find(allShipmentStops, (stop: any) => stopNumber === stop.stopNumber);
  return {
    localTimeZoneIdentifier: get(shipmentStop, 'location.address.locationCoordinatesDto.localTimeZoneIdentifier'),
    localTimeZoneIdentifierShort: get(
      shipmentStop,
      'location.address.locationCoordinatesDto.localTimeZoneIdentifierShort'
    ),
  };
};

export const getDefaultApptWindow = (
  rawStop: any,
  stopTimezoneIdentifier: any | undefined
): ShipmentStop['appointmentWindow'] => {
  const defaultApptWindow = get(rawStop, 'appointmentWindows', []).filter(
    (apptWindow: ShipmentStopAppointmentWindow) =>
      apptWindow.windowType === ShipmentStopAppointmentWindowTypeEnum.DEFAULT
  );
  return defaultApptWindow.length > 0
    ? {
        startDate: defaultApptWindow[0].utcAppointmentStartDateTime,
        endDate: defaultApptWindow[0].utcAppointmentEndDateTime,
        localTimeZoneIdentifier: stopTimezoneIdentifier?.localTimeZoneIdentifier,
        localTimeZoneIdentifierShort: stopTimezoneIdentifier?.localTimeZoneIdentifierShort,
      }
    : {
        ...get(rawStop, 'appointmentWindow', {}),
        localTimeZoneIdentifier: stopTimezoneIdentifier?.localTimeZoneIdentifier,
        localTimeZoneIdentifierShort: stopTimezoneIdentifier?.localTimeZoneIdentifierShort,
      };
};

export const getAdditionalApptWindowsForStop = (
  rawStop: any,
  latestStopStatuses: StopStatus[] = [],
  localTimeZoneIdentifier: string | undefined
): Array<ShipmentStopAppointmentWindow> | undefined => {
  const addApptWindowStatus = (apptWindow: ShipmentStopAppointmentWindow): ShipmentStopAppointmentWindow => {
    const latestStatusForStop = find(
      latestStopStatuses,
      (stopStatus) => apptWindow.stopNumber === stopStatus.stopNumber
    );
    const latestStatusForWindowType: AppointmentWindowStatus | undefined = find(
      get(latestStatusForStop, 'appointmentWindowStatuses', []),
      (apptWindowStatus) => apptWindowStatus.windowType === apptWindow.windowType
    );

    let statusMessage;
    let statusTagColorValue;
    let additionalAppointmentUtcScanDateTime;
    if (
      latestStatusForWindowType?.statusValue &&
      Object.keys(AppointmentWindowStatusIntlKeys).includes(latestStatusForWindowType.statusValue)
    ) {
      const statusValue = latestStatusForWindowType.statusValue as AppointmentWindowStatusEnum;
      statusMessage = AppointmentWindowStatusIntlKeys[statusValue];
      statusTagColorValue = AppointmentWindowStatusColors[statusValue];
      additionalAppointmentUtcScanDateTime = latestStatusForWindowType.utcScanDateTime;
    }

    return {
      ...apptWindow,
      localTimeZoneIdentifier,
      statusMessage,
      statusTagColorValue,
      additionalAppointmentUtcScanDateTime,
    };
  };

  const additionalApptWindows = get(rawStop, 'appointmentWindows', [])
    .filter(
      (apptWindow: ShipmentStopAppointmentWindow) =>
        apptWindow.windowType !== ShipmentStopAppointmentWindowTypeEnum.DEFAULT
    )
    .map(addApptWindowStatus);

  return additionalApptWindows.length > 0 ? additionalApptWindows : undefined;
};

export const getStopArrivalAndDepartedTimestamps = (
  stopNumber: number,
  latestStopStatuses: any[] = [],
  localTimeZoneIdentifier: string | undefined
): {
  recordedArrivalDateTime: DateTimeWithTimezone | undefined;
  recordedDepartureDateTime: DateTimeWithTimezone | undefined;
} => {
  const { utcRecordedArrivalDateTime, utcRecordedDepartureDateTime } =
    find(latestStopStatuses, (statusUpdate = {}) => stopNumber && statusUpdate.stopNumber === stopNumber) || {};
  return {
    recordedArrivalDateTime: utcRecordedArrivalDateTime
      ? {
          dateTimeUtc: utcRecordedArrivalDateTime,
          timezone: localTimeZoneIdentifier,
        }
      : undefined,
    recordedDepartureDateTime: utcRecordedDepartureDateTime
      ? {
          dateTimeUtc: utcRecordedDepartureDateTime,
          timezone: localTimeZoneIdentifier,
        }
      : undefined,
  };
};

export const getMostRecentStopStatus = (rawStop: any = {}, shipmentCurrentStop: any = {}): ShipmentStopStatusEnum => {
  const currentShipmentStopStatus = get(shipmentCurrentStop, 'statusCode');
  switch (currentShipmentStopStatus) {
    case TruckloadStatusCodeEnum.DISPATCHED:
      if (rawStop.stopNumber === 1) {
        return ShipmentStopStatusEnum.AT_PICKUP;
      }
      return ShipmentStopStatusEnum.EN_ROUTE;

    case TruckloadStatusCodeEnum.IN_TRANSIT:
      if (rawStop.stopNumber < shipmentCurrentStop.stopNumber) {
        return ShipmentStopStatusEnum.DEPARTED;
      }
      return ShipmentStopStatusEnum.EN_ROUTE;

    case TruckloadStatusCodeEnum.COMPLETED:
      if (rawStop.stopNumber <= shipmentCurrentStop.stopNumber) {
        return ShipmentStopStatusEnum.DEPARTED;
      }
      return ShipmentStopStatusEnum.ARRIVED;

    case TruckloadStatusCodeEnum.AT_STOP:
      if (rawStop.stopNumber < shipmentCurrentStop.stopNumber) {
        return ShipmentStopStatusEnum.DEPARTED;
      }
      if (rawStop.stopNumber === shipmentCurrentStop.stopNumber) {
        return ShipmentStopStatusEnum.ARRIVED;
      }
      return ShipmentStopStatusEnum.EN_ROUTE;

    default:
      if (rawStop.stopNumber === 1) {
        return ShipmentStopStatusEnum.AT_PICKUP;
      }
      return ShipmentStopStatusEnum.UNKNOWN;
  }
};

/**
 * getRelevantStopStatusMessage()
 * @param statusMessage old status message object create in shipmentDetailUtil.js
 * With the inclusion of arrival and departure timestamps on TL stops, most of these legacy status messages can be
 * deprecated from the TL details page; the only ones we keep to display on the route timeline are ETA statuses,
 * which are formatted as etaIs and by. We return a new status message intl obj so the component can display the
 * timestamp consistently with the rest of the route timeline (02.2020)
 */
export const getRelevantStopStatusMessage = (
  statusMessage: { id: string; defaultMessage: string; utcDateTime?: string } | undefined
): { id: string; defaultMessage: string; utcDateTime: string | undefined } | undefined => {
  if (statusMessage && statusMessage.id) {
    switch (statusMessage.id) {
      case 'statusCards.shipmentDetailUtil.lastKnownEta':
        return {
          id: 'shipmentDetails.stopCards.lastKnownEta',
          defaultMessage: 'Last known ETA: ',
          utcDateTime: statusMessage.utcDateTime,
        };
      case 'statusCards.shipmentDetailUtil.etaIs':
        return {
          id: 'shipmentDetails.stopCards.etaIs',
          defaultMessage: 'ETA is {date} at {time} ',
          utcDateTime: statusMessage.utcDateTime,
        };
      case 'statusCards.shipmentDetailUtil.by':
        return {
          id: 'shipmentDetails.stopCards.by',
          defaultMessage: 'By',
          utcDateTime: statusMessage.utcDateTime,
        };
      default:
        return undefined;
    }
  }
};

export const getStopEvents = (
  stopNumber: number,
  statusUpdatesByStops: { [key: number]: StatusUpdate[] } = {}
): ShipmentEvent[] => {
  return filterOutConsecutiveAndEmptyStatusUpdates(statusUpdatesByStops[stopNumber]).map(mapStatusUpdateToEvent);
};

const getStatusTagColorValue = (rawStop: any = {}): string | undefined => {
  if (rawStop.isLate) {
    return newFruit;
  } else if (rawStop.isOnTime) {
    return primaryBlue;
  } else if (rawStop.isLateMessage) {
    return green;
  }
};

const getStatusUpdateDescription = (
  statusReasonCode: ShipmentStatusReasonCodeEnum | undefined,
  description: string | undefined,
  statusCode?: string | undefined
): ShipmentStatusReasonCodeEnum | undefined => {
  if (statusReasonCode === StatusReason.PENDING_TRACKING_METHOD && statusCode === TruckloadStatusCodeEnum.INFO) {
    return undefined;
  }

  if (statusReasonCode !== StatusReason.FAILED_TO_ACQUIRE_LOCATION || description === StatusReason.ETA_OUT_OF_DATE) {
    if (description !== StatusReason.ETA_OUT_OF_DATE) {
      return statusReasonCode;
    } else {
      return ShipmentStatusReasonCodeEnum.ETA_OUT_OF_DATE;
    }
  } else {
    return undefined;
  }
};

export const getStatusUpdateAdditionalInfo = (
  statusUpdate: StatusUpdate
): ShipmentStatusReasonCodeEnum | string | undefined => {
  const { statusReasonCode, description, statusCode, stopNumber } = statusUpdate;

  let additionalInfoFilter =
    description &&
    ![
      ShipmentStatusReasonCodeEnum.DEPARTED,
      ShipmentStatusReasonCodeEnum.ARRIVED,
      StatusReason.ACQUIRING_LOCATION,
      TruckloadStatusCodeEnum.TRACKING_HAS_BEGUN,
    ].includes(description);

  if (statusReasonCode === StatusReason.PENDING_TRACKING_METHOD && statusCode === TruckloadStatusCodeEnum.INFO) {
    if (description === StatusReason.UNKNOWN_EQUIPMENT_IDENTIFIER) {
      return get(TruckloadStatusCodeIntlKeys, `[UNKNOWN_EQUIPMENT_IDENTIFIER].past.defaultMessage`, ' ');
    } else if (description === StatusReason.MISSING_EQUIPMENT_IDENTIFIER) {
      return get(TruckloadStatusCodeIntlKeys, `[MISSING_EQUIPMENT_IDENTIFIER].past.defaultMessage`, ' ');
    }
  }

  // Show descritption/additionnalInfo in stopcard events only for:
  // COMPLETED events or INFO - INFO events
  const isInfoInfo =
    statusCode === TruckloadStatusCodeEnum.INFO && statusReasonCode === ShipmentStatusReasonCodeEnum.INFO;
  if (!(stopNumber && (statusCode === TruckloadStatusCodeEnum.COMPLETED || isInfoInfo))) {
    additionalInfoFilter = undefined;
  }

  const formattedDescription =
    description && !description.includes(' ')
      ? description?.charAt(0) + description?.slice(1).toLowerCase()
      : description;

  // All INFO - INFO events' descriptions should be displayed in the timeline
  if (
    statusReasonCode === ShipmentStatusReasonCodeEnum.INFO &&
    statusCode === TruckloadStatusCodeEnum.INFO &&
    description !== StatusReason.ETA_OUT_OF_DATE
  ) {
    return formattedDescription;
  }

  if (statusReasonCode !== StatusReason.FAILED_TO_ACQUIRE_LOCATION && description !== StatusReason.ETA_OUT_OF_DATE) {
    return additionalInfoFilter ? formattedDescription : undefined;
  } else {
    return get(TruckloadStatusCodeIntlKeys, `[ETA_OUT_OF_DATE].past.defaultMessage`, ' ');
  }
};

export const filterOutConsecutiveAndEmptyStatusUpdates = (statusUpdates: StatusUpdate[] = []) => {
  return statusUpdates.filter((statusUpdate, idx) => {
    if (isNil(statusUpdate) || isEmpty(statusUpdate)) {
      return false;
    }

    const isSameInfoStatusAndDifferentInfo =
      statusUpdates[idx - 1] &&
      statusUpdates[idx - 1].statusCode === TruckloadStatusCodeEnum.INFO &&
      statusUpdate.statusCode === TruckloadStatusCodeEnum.INFO &&
      statusUpdates[idx - 1].statusReasonCode === ShipmentStatusReasonCodeEnum.INFO &&
      statusUpdate.statusReasonCode === ShipmentStatusReasonCodeEnum.INFO &&
      statusUpdates[idx - 1].description !== statusUpdate.description;

    const statusIsTheSameAsPrevious =
      statusUpdates[idx - 1] &&
      statusUpdates[idx - 1].statusCode === statusUpdate.statusCode &&
      statusUpdates[idx - 1].statusReasonCode === statusUpdate.statusReasonCode &&
      statusUpdates[idx - 1].stopNumber === statusUpdate.stopNumber;

    return isSameInfoStatusAndDifferentInfo ? isSameInfoStatusAndDifferentInfo : !statusIsTheSameAsPrevious;
  });
};

export const mapStatusUpdateToEvent = (statusUpdate: StatusUpdate): ShipmentEvent => {
  return {
    type:
      statusUpdate.statusReasonCode !== StatusReason.FAILED_TO_ACQUIRE_LOCATION &&
      statusUpdate.description !== StatusReason.ETA_OUT_OF_DATE
        ? statusUpdate.statusCode
        : TruckloadStatusCodeEnum.ETA_ERROR,
    description: getStatusUpdateDescription(
      statusUpdate.statusReasonCode,
      statusUpdate.description,
      statusUpdate.statusCode
    ),
    additionalInfo: getStatusUpdateAdditionalInfo(statusUpdate),
    dateTime: {
      dateTimeUtc: statusUpdate.utcTimestamp,
      timezone: get(statusUpdate, 'address.locationCoordinatesDto.localTimeZoneIdentifier'),
      timezoneShort: get(statusUpdate, 'address.locationCoordinatesDto.localTimeZoneIdentifierShort'),
    },
    address: {
      city: get(statusUpdate, 'address.city'),
      state: get(statusUpdate, 'address.state'),
      country: get(statusUpdate, 'address.countryCodeDto'),
    },
  };
};
