import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { SubHeader, Button, Tooltip } from 'ui-components';
import { trackEvent } from 'common/eventTracker';
import NotificationsBlankState from './NotificationsBlankState';
import NotificationItem from './NotificationItem';
import * as constants from './ducks/constants';
import { queryStringWithShipmentId } from './NotificationViewUtil';

import './NotificationsComponent.scss';
import { withTheme } from '../../contexts/ThemeContext';

const messages = defineMessages({
  manageShipmentNotifications: {
    id: 'notifications.notificationsComponent.manageShipmentNotifications',
    defaultMessage: 'Manage notifications for this shipment.',
  },
  manageDefaultShipmentNotifications: {
    id: 'notifications.notificationsComponent.manageDefaultShipmentNotifications',
    defaultMessage: 'Manage default notifications for shipments in your account.',
  },
});

const NotificationsComponent = (props) => {
  const { masterShipmentId, shipmentId, mode } = props;
  const isModificationDisabled = !props.hasModeVisibility && !mode;

  return (
    <main className="NotificationsComponent">
      <SubHeader>
        <div className="d-flex w-100 h-100">
          <div className="w-100">
            <FormattedMessage
              id="notifications.notificationsComponent.manageNotifications"
              defaultMessage="Manage Notifications"
              tagName="h1"
            />
            <span className="sub-header-label w-100">
              {props.accessLevel === constants.ShipmentAccessLevelConstants.Shipment
                ? props.intl.formatMessage(messages.manageShipmentNotifications)
                : props.intl.formatMessage(messages.manageDefaultShipmentNotifications)}
            </span>
          </div>
          {props.hasEdit && (
            <div className="d-flex justify-content-end align-items-center">
              <Tooltip
                placement="bottom"
                title={
                  <FormattedMessage
                    id="notifications.notificationItem.permissionsAlert"
                    defaultMessage="You don't have correct permissions to configure notifications. Speak to your tenant admin for assistance"
                  />
                }
                {...(!isModificationDisabled && { visible: false })}
              >
                <Link
                  data-locator="newNotificationButton"
                  onClick={() => {
                    trackEvent('NOTIFICATION_NEW_CLICK', {
                      shipmentId: masterShipmentId || shipmentId,
                      mode,
                    });
                  }}
                  to={{
                    pathname: '/notifications/new',
                    search: queryStringWithShipmentId({
                      shipmentId,
                      masterShipmentId,
                      mode,
                    }),
                    accessLevel: props.accessLevel,
                  }}
                >
                  <Button type="primary" theme={props.theme} disabled={isModificationDisabled}>
                    <FormattedMessage
                      id="notifications.notificationsComponent.newNotification"
                      defaultMessage="New Notification"
                    />
                  </Button>
                </Link>
              </Tooltip>
            </div>
          )}
        </div>
      </SubHeader>

      <section className="container section-content">
        {props.notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            hasEdit={props.hasEdit}
            editDisabled={isModificationDisabled}
            accessLevel={props.accessLevel}
            shipmentId={shipmentId}
            masterShipmentId={masterShipmentId}
            mode={mode}
            notification={notification}
            expandCollapse={(
              isExpanded //eslint-disable-line react/jsx-no-bind
            ) =>
              props.expandCollapseNotification({
                id: notification.id,
                isExpanded,
              })
            }
          />
        ))}
        {isEmpty(props.notifications) && <NotificationsBlankState />}
      </section>
    </main>
  );
};

NotificationsComponent.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default withTheme(injectIntl(NotificationsComponent));
