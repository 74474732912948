import * as React from 'react';
import { useHistory } from 'react-router';
import { locale } from 'i18n/configurei18n';
import { LOCALES } from 'common/AppConstants';
import { getQueryStringParameter } from 'common/urlUtils';

export enum LocaleActions {
  SET_LOCALE = 'SET_LOCALE',
}
type Action = { type: LocaleActions.SET_LOCALE; payload: string };
type State = { locale: string };
type Dispatch = (action: Action) => void;

const localeQueryParam = getQueryStringParameter(window.location.search, 'locale');

const getShort = (locale: string) => locale.toLowerCase().split(/[_-]+/)[0];

// checks if locale is supported by carrier onboarding 2.0
export const isSupportedLocale = (locale: string) => {
  const shortLocale = getShort(locale);
  return Object.values(LOCALES)
    .map((key) => getShort(key))
    .includes(shortLocale);
};

export const getSupportedLocale = (locale: string, fallback: string = '') => {
  const shortLocale = getShort(locale);
  const supportedLocale =
    Object.values(LOCALES).find((key) => key === locale) ||
    Object.values(LOCALES).find((key) => getShort(key) === shortLocale);
  return supportedLocale || fallback;
};

const defaultContext = {
  locale: localeQueryParam && isSupportedLocale(localeQueryParam) ? localeQueryParam : locale,
};

export const SimpleLocalizeStateContext = React.createContext<State | undefined>(defaultContext);
export const SimpleLocalizeDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function languageReducer(_: State, action: Action) {
  switch (action.type) {
    case LocaleActions.SET_LOCALE: {
      return { locale: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
export function SimpleLocalizeProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(languageReducer, defaultContext);
  return (
    <SimpleLocalizeStateContext.Provider value={state}>
      <SimpleLocalizeDispatchContext.Provider value={dispatch}>{children}</SimpleLocalizeDispatchContext.Provider>
    </SimpleLocalizeStateContext.Provider>
  );
}

export function useLocaleQueryString() {
  const history = useHistory();
  const setLocaleQuery = (locale: string) => {
    const params = new URLSearchParams(window.location.search);
    params.set('locale', locale.toLowerCase());
    history.push({
      search: `?${params.toString()}`,
    });
  };

  return { setLocaleQuery };
}

export function useLocaleState() {
  const context = React.useContext(SimpleLocalizeStateContext);
  if (context === undefined) {
    throw new Error('useLocaleState must be used within a SimpleLocalizeStateContextProvider');
  }
  return context;
}
export function useLocaleDispatch() {
  const context = React.useContext(SimpleLocalizeDispatchContext);
  if (context === undefined) {
    throw new Error('useLocaleDispatch must be used within a SimpleLocalizeDispatchContextProvider');
  }
  return context;
}
