import { useCallback } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'ui-components';

import { shipmentListFiltersUrlEnabled } from 'common/authorizations';
import * as FilterModeFactory from './modes/FilterModeFactory';
import { withTheme } from '../../contexts/ThemeContext';
import FilterUtil from '../../components/filter/util/filterUtil';

import './FilterComponent.scss';

const FilterComponent = (props) => {
  const muiTheme = getMuiTheme({
    fontFamily: 'Roboto, sans-serif',
    palette: {
      primary1Color: '#00558B',
      primary2Color: '#07456b',
      pickerHeaderColor: '#00558B',
      clockCircleColor: '#1296b0',
      shadowColor: '#000',
    },
  });

  const {
    clearFilters,
    customApplyFilterFn,
    customClearFiltersFn,
    handleApplyFilter,
    searchTerm,
    authorities,
    cancel,
    filter,
    isMobile = false,
    sortFields,
    modeFilters,
  } = props;

  const history = useHistory();

  const handleClearButtonClick = useCallback(() => {
    if (shipmentListFiltersUrlEnabled()) {
      history.push(`/shipment-list`);
    } else {
      clearFilters(searchTerm, authorities);
    }

    if (isMobile) {
      // close mobile filter
      cancel();
    }
  }, [isMobile, history, clearFilters, searchTerm, authorities, cancel]);

  const handleApplyButtonClick = useCallback(() => {
    if (shipmentListFiltersUrlEnabled()) {
      const queryURL = FilterUtil.buildQueryURl(filter, sortFields, modeFilters, searchTerm);
      if (queryURL) history.push(`/shipment-list${queryURL}`);
    } else {
      handleApplyFilter(filter, authorities);
    }

    if (isMobile) {
      // close mobile filter
      cancel();
    }
  }, [isMobile, filter, sortFields, modeFilters, searchTerm, history, handleApplyFilter, authorities, cancel]);

  return (
    <MuiThemeProvider muiTheme={muiTheme}>
      <Container fluid className="FilterComponent">
        <Row as={'header'}>
          <Col className="refine-header d-flex justify-content-between filter-button-container">
            <div className="refine-text">
              <FormattedMessage id="shipmentList.filterComponent.refine" defaultMessage="Refine" />
            </div>

            <div className={'button-wrapper'}>
              <Button data-locator="clear-filters-button" clickFn={customClearFiltersFn || handleClearButtonClick}>
                <FormattedMessage id="shipmentList.filterComponent.clear" defaultMessage="Clear" />
              </Button>
              <Button
                data-locator="apply-filters-button"
                type="primary"
                theme={props.theme}
                clickFn={customApplyFilterFn || handleApplyButtonClick}
              >
                <FormattedMessage id="shipmentList.filterComponent.apply" defaultMessage="Apply" />
              </Button>
            </div>

            <MuiThemeProvider>
              <button className="filter-close-button d-block d-lg-none" onClick={props.cancel}>
                <CloseIcon />
              </button>
            </MuiThemeProvider>
          </Col>
        </Row>

        {props.children ? props.children : FilterModeFactory.getModeComponents(props)}

        {!props.hideFooter && (
          <Row as={'footer'}>
            <Col className="filter-button-container footer-filter-controls-container">
              <Button
                data-locator="clear-filters-button-secondary"
                clickFn={customClearFiltersFn || handleClearButtonClick}
              >
                <FormattedMessage id="shipmentList.filterComponent.clear" defaultMessage="Clear" />
              </Button>
              <Button
                data-locator="apply-filters-button-secondary"
                type="primary"
                theme={props.theme}
                clickFn={customApplyFilterFn || handleApplyButtonClick}
              >
                <FormattedMessage id="shipmentList.filterComponent.apply" defaultMessage="Apply" />
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </MuiThemeProvider>
  );
};

export default withTheme(FilterComponent);
