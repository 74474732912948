import get from 'lodash/get';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import { defineMessages, createIntl, createIntlCache } from 'react-intl';
import { entityPageUrls } from 'common/AppConstants';
import StatusDescriptionUtil from '../../shipment/common/utils/statusDescriptionUtil';
import StatusTextUtil from '../../shipment/common/utils/statusTextUtil';
import FlagTextUtil from '../../shipment/common/utils/flagTextUtil';
import PercentCompleteUtil from '../../shipment/common/utils/percentCompleteUtil';
import DateFormatUtil from '../../shipment/common/utils/dateFormatUtil';
import { getMinutesAgoFromUtcTimestamp } from '../../../common/dateUtils';
import ColorUtil from '../../shipment/common/utils/colorUtil';
import ShipmentDetailUtil from '../../shipment/common/utils/shipmentDetailUtil';
import ShipmentIdentifier from '../../shipment/common/enums/shipmentIdentifier';
import StopType from '../../shipment/common/enums/stopType';
import LocationUtils from '../../../common/LocationUtils/LocationUtils';
import { locale, messages } from '../../../i18n/configurei18n';

const cache = createIntlCache();
export const intl = createIntl({ locale, messages }, cache);

const message = defineMessages({
  unknown: {
    id: 'statusCards.mapShipmentTL.unknown',
    defaultMessage: 'unknown',
  },
  thisTime: {
    id: 'statusCards.mapShipmentTL.thisTime',
    defaultMessage: 'this time.',
  },
  bol: {
    id: 'statusCards.mapShipmentTL.bol',
    defaultMessage: 'BOL',
  },
  order: {
    id: 'statusCards.mapShipmentTL.order',
    defaultMessage: 'ORDER',
  },
});

export const mapShipmentTL = (shipment, useNewEntityPage = false, renderEarlyAsOnTime = false) => {
  const url = useNewEntityPage
    ? entityPageUrls.entityPageUiUrl(shipment.masterShipmentId)
    : '/tracking-details/tl/' + get(shipment, 'shipmentId', -1);

  const mode = get(shipment, 'mode', false);
  const identifiers = getBolAndOrderNumbers(shipment);
  const derivedStatus = getDerivedStatus(shipment);
  const currentEta = getCurrentETA(shipment);
  const currentTimezone = ShipmentDetailUtil.getTimezoneFromStopNumber(
    shipment.latestStatusUpdate.stopNumber,
    shipment
  );
  const lastUpdate = mapLastUpdatedText(shipment, derivedStatus);
  const convertedDateTime = getConvertedEtaToCurrentTime(currentEta, shipment);
  const dwellTime = ShipmentDetailUtil.getDwellTime(shipment);

  const deliveryStopInfo = get(shipment, 'deliveryStopInfo', false);
  const startTimeBeforePickupMinutes = get(shipment, 'startTimeBeforePickupMinutes', 90);

  if (deliveryStopInfo) {
    const destinationName = getStopName(get(shipment, 'shipmentStops'), shipment.shipmentStops.length - 1);

    deliveryStopInfo['formatedDateTimeText'] = ShipmentDetailUtil.getStopStatusMessage(
      getDeliveryStop(shipment),
      shipment.latestStopStatuses,
      shipment,
      false
    );
    deliveryStopInfo['stopInfo'] = getDeliveryStop(shipment);

    deliveryStopInfo['country'] = LocationUtils.getCountryCodeForLocation(
      deliveryStopInfo,
      deliveryStopInfo.stopName,
      shipment.shipmentStops
    );
    deliveryStopInfo['stopName'] = isNil(destinationName)
      ? intl.formatMessage({ id: 'shipmentList.shipment.delivery', defaultMessage: 'Delivery' })
      : destinationName;
  }
  const pickupStopInfo = get(shipment, 'pickupStopInfo', false);

  pickupStopInfo['country'] = LocationUtils.getCountryCodeForLocation(
    pickupStopInfo,
    pickupStopInfo.stopName,
    shipment.shipmentStops
  );
  pickupStopInfo['formatedDateTimeText'] = ShipmentDetailUtil.getStopStatusMessage(
    getPickupStop(shipment),
    shipment.latestStopStatuses,
    shipment,
    false
  );
  pickupStopInfo['stopInfo'] = getPickupStop(shipment);
  const originName = getStopName(get(shipment, 'shipmentStops'), 0);

  pickupStopInfo['stopName'] = isNil(originName)
    ? intl.formatMessage({ id: 'shipmentList.shipment.pickup', defaultMessage: 'Pickup' })
    : originName;

  const carrierName =
    get(shipment, 'carrier.name', false) !== false
      ? get(shipment, 'carrier.name', false)
      : get(shipment, 'broker.name', false);
  const carrierID =
    get(shipment, 'carrier.id', false) !== false
      ? get(shipment, 'carrier.id', false)
      : get(shipment, 'broker.id', false);
  const statusFlagText = FlagTextUtil.getFlagText(derivedStatus, shipment, renderEarlyAsOnTime);
  const statusColor = ColorUtil.getStatusColorFromDerivedStatusTL(derivedStatus, renderEarlyAsOnTime);
  const statusText = StatusTextUtil.getStatusText(derivedStatus);
  const percentComplete = shipment.multiModalDerivedStatus
    ? PercentCompleteUtil.getPercentCompleteBasedOnMultiModalDerivedStatus(shipment.multiModalDerivedStatus)
    : PercentCompleteUtil.getPercentComplete_FallbackForTL(derivedStatus.stopType, derivedStatus.statusCode);
  const statusDescription = StatusDescriptionUtil.getStatusDescription(
    derivedStatus,
    dwellTime,
    convertedDateTime,
    startTimeBeforePickupMinutes
  );
  const sensorTracking = get(shipment, 'sensorTracking', false);
  const lineItems = get(shipment, 'details.lineItems', []);
  const tenantCustomAttributes = get(shipment, 'tenantCustomAttributes', []);

  const customerName = get(shipment.entitlementInfo, 'grantor.name');

  const carrierIdentifiers = getCarrierIdentifiers(shipment);

  return {
    shipmentId: shipment.shipmentId,
    url: url,
    mode: mode,
    carrierIdentifiers,
    identifiers: identifiers,
    derivedStatus: derivedStatus,
    currentEta: currentEta,
    currentTimezone: currentTimezone,
    lastUpdate: lastUpdate,
    convertedDateTime: convertedDateTime,
    dwellTime: dwellTime,
    pickupStopInfo: pickupStopInfo,
    deliveryStopInfo: deliveryStopInfo,
    carrierName: carrierName,
    carrierID,
    customerName: customerName,
    flagText: statusFlagText,
    flagColor: statusColor,
    statusText: statusText,
    statusColor: statusColor,
    percentComplete: percentComplete,
    statusDescription: statusDescription,
    sensorTracking: sensorTracking,
    entitlementInfo: shipment.entitlementInfo,
    lineItems: lineItems,
    tenantCustomAttributes,
    shipmentStopsCount: get(shipment, 'shipmentStops', []).length,
  };
};

const getCarrierIdentifiers = (shipment) => {
  const assignedCapacityProviderIdentifiersDto = get(shipment, 'carrier.assignedCapacityProviderIdentifiersDto', []);

  return assignedCapacityProviderIdentifiersDto.map((carrierIdentifierDto) => {
    return {
      name: carrierIdentifierDto.capacityProviderIdentifierDto.type,
      value: carrierIdentifierDto.capacityProviderIdentifierDto.value,
    };
  });
};

const mapLastUpdatedText = (shipment, derivedStatus) => {
  const updatedDate = get(shipment, 'updatedDate') || get(shipment, 'latestStatusUpdate.utcTimestamp');
  return updatedDate
    ? DateFormatUtil.mapFromMinutesAgoToLastUpdatedText(getMinutesAgoFromUtcTimestamp(updatedDate))
    : undefined;
};

const getDeliveryStop = (shipment) => {
  let delivery = find(shipment.shipmentStops, (e) => {
    if (e.stopNumber === shipment.shipmentStops.length) {
      return true;
    }
  });

  return delivery;
};

const getPickupStop = (shipment) => {
  let pickup = find(shipment.shipmentStops, (e) => {
    if (e.stopNumber === 1) {
      return true;
    }
  });

  return pickup;
};

const getConvertedEtaToCurrentTime = (currentEta, shipment) => {
  let convertedDateTime = {};
  if (isNil(currentEta)) {
    convertedDateTime['etaDateInCurrentTimezone'] = intl.formatMessage(message.unknown);
    convertedDateTime['etaTimeInCurrentTimezone'] = intl.formatMessage(message.thisTime);
    return convertedDateTime;
  } else {
    return ShipmentDetailUtil.getFormattedDateAndTimeAdjustedForTimezone(
      currentEta,
      shipment.latestStatusUpdate,
      shipment
    );
  }
};

const getCurrentETA = (shipment) => {
  let utcEarliestEstimatedArrivalDateTime;

  shipment.latestStopStatuses.forEach((e) => {
    if (e.stopNumber === shipment.latestStatusUpdate.stopNumber) {
      utcEarliestEstimatedArrivalDateTime = e.utcEarliestEstimatedArrivalDateTime;
    }
  });

  return utcEarliestEstimatedArrivalDateTime;
};

const getDerivedStatus = (shipment) => {
  let derivedStatus = undefined;
  if (Array.isArray(shipment.derivedStatusCodes) && shipment.derivedStatusCodes.length > 0) {
    shipment.derivedStatusCodes.forEach((e) => {
      if (e.stopType !== StopType.ANY) {
        derivedStatus = e;
      }
    });
    if (!derivedStatus) {
      derivedStatus = shipment.derivedStatusCodes[0];
    }
  } else {
    derivedStatus = {
      type: 'UNKNOWN',
      description: 'UNKNOWN',
      statusCode: 'UNKNOWN',
      statusReasonCode: 'UNKNOWN',
      stopStatusCode: 'UNKNOWN',
      stopArrivalCode: 'UNKNOWN',
      stopType: 'UNKNOWN',
    };
  }

  return derivedStatus;
};

const getBolAndOrderNumbers = (shipment) => {
  let bolAndOrderNumber = [];
  shipment.shipmentIdentifiers.forEach((e) => {
    if (e.type === ShipmentIdentifier.BILL_OF_LADING) {
      bolAndOrderNumber['BILL_OF_LADING'] = {
        label: intl.formatMessage(message.bol),
        type: e.type,
        value: e.value,
        intlMessageObject: message.bol,
      };
      bolAndOrderNumber.push(bolAndOrderNumber['BILL_OF_LADING']);
    } else if (e.type === ShipmentIdentifier.ORDER) {
      bolAndOrderNumber['ORDER'] = {
        label: intl.formatMessage(message.order),
        type: e.type,
        value: e.value,
        intlMessageObject: message.order,
      };
      bolAndOrderNumber.push(bolAndOrderNumber['ORDER']);
    }
  });
  return bolAndOrderNumber;
};

const getStopName = (shipmentStops, stopNumber) => {
  return get(shipmentStops[stopNumber], 'location.contactDto.companyName');
};
