import { Component } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button as AntButton } from 'antd';
import styled from 'styled-components';
import { shade, tint } from 'polished';
import { primaryBlue } from 'styles/colors';
import { ThemeContext, defaultThemeShape } from '../../styles/theme';

import './Button.scss';

const StyledP44Button = styled(AntButton)`
  &.p44-btn {
    &--primary {
      display: inline-flex;
      min-width: 12rem;
      justify-content: center;
      text-transform: none;
      box-shadow: none;
      font-size: 1.6rem !important;
      border-radius: 50px !important;
      background-color: ${(props) => (props.theme ? props.theme.primaryColor : primaryBlue)};
      &:hover,
      &:focus {
        background-color: ${(props) => shade(0.2)(props.theme.primaryColor)};
      }
    }
    &--primary-transparent {
      border: solid 1px ${(props) => props.theme && props.theme.primaryColor};
      color: ${(props) => props.theme && props.theme.primaryColor};
      &:hover,
      &:focus {
        border: solid 1px ${(props) => shade(0.2)(props.theme.primaryColor)};
        color: ${(props) => shade(0.2)(props.theme.primaryColor)};
        background-color: ${(props) => tint(0.25, props.theme.primaryColor)};
      }
    }
    &--primary-transparent-text {
      border: solid 1px transparent;
      &:hover,
      &:focus {
        border: solid 1px ${(props) => shade(0.2)(props.theme.primaryColor)};
        color: ${(props) => shade(0.2)(props.theme.primaryColor)};
      }
    }
  }
`;

const P44Button = (props) => {
  return <StyledP44Button {...props}>{props.children}</StyledP44Button>;
};

const StyledButton = styled(AntButton)`
  &.p44-btn {
    &--primary {
      background-color: ${(props) => props.theme && props.theme.primaryColor};
      &:hover,
      &:focus {
        background-color: ${(props) => shade(0.2)(props.theme.primaryColor)};
      }
    }
    &--primary-transparent {
      border: solid 1px ${(props) => props.theme && props.theme.primaryColor};
      color: ${(props) => props.theme && props.theme.primaryColor};
      &:hover,
      &:focus {
        border: solid 1px ${(props) => shade(0.2)(props.theme.primaryColor)};
        color: ${(props) => shade(0.2)(props.theme.primaryColor)};
        background-color: ${(props) => tint(0.25, props.theme.primaryColor)};
      }
    }
    &--primary-transparent-text {
      border: solid 1px transparent;
      &:hover,
      &:focus {
        border: solid 1px ${(props) => shade(0.2)(props.theme.primaryColor)};
        color: ${(props) => shade(0.2)(props.theme.primaryColor)};
      }
    }
  }
`;

const customClassMap = {
  default: 'p44-btn',
  primary: 'p44-btn--primary',
  secondary: 'p44-btn--secondary',
  destructive: 'p44-btn--destructive',
  'destructive-text': 'p44-btn--destructive-text',
  'primary-transparent': 'p44-btn--primary-transparent',
  'primary-transparent-text': 'p44-btn--primary-transparent-text',
};
class Button extends Component {
  static propTypes = {
    upload: PropTypes.bool,
    download: PropTypes.bool,
    clickFn: PropTypes.func,
    blurFn: PropTypes.func,
    type: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    theme: PropTypes.shape(defaultThemeShape),
    isv2: PropTypes.bool,
  };

  static defaultProps = {
    download: false,
    icon: undefined,
    type: 'default',
    size: 'med',
  };

  static contextType = ThemeContext;

  render() {
    const {
      className,
      clickFn,
      blurFn,
      upload,
      download,
      icon,
      type, // default, primary, secondary, destructive
      size,
      isv2,
      ...props
    } = this.props;

    const customClass = get(customClassMap, type, '');
    const classes = classNames(className, customClass, {
      [size]: size,
      'icon-button-v2': icon,
    });

    if (upload) {
      return (
        <label htmlFor={upload}>
          <StyledButton theme={this.context} className={classes} onClick={clickFn} onBlur={blurFn} {...props}>
            {this.props.children}
          </StyledButton>
        </label>
      );
    } else {
      return isv2 === 'true' ? (
        <P44Button
          theme={this.props.theme || this.context}
          className={classes}
          onClick={clickFn}
          onBlur={blurFn}
          download={download}
          type={type}
          icon={icon}
          {...props}
        >
          {this.props.children}
        </P44Button>
      ) : (
        <StyledButton
          theme={this.props.theme || this.context}
          className={classes}
          onClick={clickFn}
          onBlur={blurFn}
          download={download}
          type={type}
          icon={icon}
          {...props}
        >
          {this.props.children}
        </StyledButton>
      );
    }
  }
}

export default Button;
