import { ActionType, getType } from 'typesafe-actions';
import { Reducer } from 'redux';

import * as aerisWeatherConfigActions from './actions';
import { AerisWeatherConfig } from '../models/AerisWeatherConfig';

export type AerisWeatherAction = ActionType<typeof aerisWeatherConfigActions>;

export interface AerisWeatherConfigState {
  readonly config?: AerisWeatherConfig;
}

const initialState: AerisWeatherConfigState = {
  config: undefined,
};

const aerisWeatherConfigReducer: Reducer<AerisWeatherConfigState, AerisWeatherAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(aerisWeatherConfigActions.fetch.success): {
      return {
        ...state,
        config: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default aerisWeatherConfigReducer;
