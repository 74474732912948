import Container from 'react-bootstrap/Container';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import every from 'lodash/every';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import flatten from 'lodash/flatten';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { Search } from 'history';
import * as UrlUtils from 'common/urlUtils';
import { Shipment, ShipmentModeEnum, Theme, HereMapsConfig } from 'models';
import PageNotFound from 'components/common/PageNotFound/PageNotFound';
import ErrorBoundary from 'components/common/errorBoundary/ErrorBoundary';
import { shipmentShareTokenQueryParam } from 'common/endpoints';
import { Tabs } from 'ui-components';
import InventoryItemsTable from 'components/Inventory/InventoryItemsTable';
import OrdersTable from 'components/ordersList/OrdersTable';
import { trackEvent } from 'common/eventTracker';
import MapRoute from '../common/mapRoute/MapRoute';
import { RouteTimelineComponent } from './';
import Status from './Status/Status';
import { buildMapLocations } from './utils';
import ShipmentHeaderContainer from './ShipmentHeader/ShipmentHeaderContainer';
import { useShipment } from './hooks/useShipment';

import * as styles from './ShipmentDetails.module.scss';

export interface ShipmentDetailsComponentProps extends RouteComponentProps<{}>, WrappedComponentProps {
  authorizations: any;
  isLoggedIn: boolean;
  setCustomTheme: (theme: Theme) => void;
  setHereMapsConfig: (hereMapsConfig: HereMapsConfig) => void;
  setCustomerProperties: (hereMapsConfig: HereMapsConfig) => void;
}

const getShipmentMode = (shipment: Shipment): ShipmentModeEnum => {
  let shipmentMode: ShipmentModeEnum;
  if (shipment.legs.length === 1 || every(shipment.legs, (leg) => leg.mode === get(shipment, 'legs[0].mode'))) {
    shipmentMode = get(shipment, 'legs[0].mode', ShipmentModeEnum.INTERMODAL) as ShipmentModeEnum;
  } else {
    shipmentMode = ShipmentModeEnum.INTERMODAL;
  }
  return shipmentMode;
};

const ShipmentDetailsComponent: React.FunctionComponent<ShipmentDetailsComponentProps> = (
  props: ShipmentDetailsComponentProps
) => {
  const queryStrings: Search = get(props, 'location.search');
  const shipmentModeString: string | undefined = UrlUtils.getQueryStringParameter(queryStrings, 'shipmentMode');
  const shipmentModeFromQueryParam: ShipmentModeEnum | undefined = isNil(shipmentModeString)
    ? undefined
    : ShipmentModeEnum[shipmentModeString.toUpperCase() as keyof typeof ShipmentModeEnum];
  const shipmentId: string | undefined = UrlUtils.getQueryStringParameter(queryStrings, 'shipmentId');
  const masterShipmentId: string | undefined = UrlUtils.getQueryStringParameter(queryStrings, 'masterShipmentId');
  const shipmentShareToken: string | undefined = UrlUtils.getQueryStringParameter(
    queryStrings,
    shipmentShareTokenQueryParam
  );

  const [hasRequiredParams, shipment, isLoading] = useShipment(
    props.setHereMapsConfig,
    props.setCustomTheme,
    props.setCustomerProperties,
    shipmentId,
    masterShipmentId,
    shipmentModeFromQueryParam,
    shipmentShareToken
  );

  React.useEffect(() => {
    if (shipment) {
      // ensure we only dispatch a track event once we've loaded the shipment
      const mode = getShipmentMode(shipment);
      const shipmentId = get(shipment, 'id');

      trackEvent('SHIPMENT_DETAIL_PAGE_LOAD', {
        mode,
        shipmentId,
        isNewDetailPage: true,
      });
    }
  }, [shipment]);

  if (!hasRequiredParams || (!isLoading && shipment !== undefined && shipment.legs.length === 0)) {
    return <PageNotFound />;
  }

  if (shipment === undefined || isLoading) {
    return null;
  }

  const mapLocations = buildMapLocations(shipment);
  const shipmentMode = getShipmentMode(shipment);

  return (
    <React.Fragment>
      <ShipmentHeaderContainer
        shipmentId={shipmentId}
        masterShipmentId={masterShipmentId}
        authorizations={props.authorizations}
        isLoggedIn={props.isLoggedIn}
        mode={shipmentMode}
        shipmentShareToken={shipmentShareToken}
        useIntermodalEndpoint
        identifiers={shipment.identifiers}
        carrierInfo={flatten(shipment.legs.map((leg) => get(leg, 'carrierInfo', [])))}
        showEmissions={false}
      />
      <Container>
        <ErrorBoundary
          message={props.intl.formatMessage({
            id: 'shipmentDetails.errorStates.status',
            defaultMessage: 'Error Loading Current Status',
          })}
        >
          <Status
            legs={shipment.legs}
            shipmentStatus={shipment.currentStatus}
            currentStatusDateTime={shipment.currentStatusDateTime}
            showDemoCurrentStatusDateTime={shipmentModeFromQueryParam === ShipmentModeEnum.INTERMODAL}
            shipmentUpdatedDate={shipment.updatedDate}
          />
        </ErrorBoundary>
        <ErrorBoundary
          message={props.intl.formatMessage({
            id: 'shipmentDetails.errorStates.timeline',
            defaultMessage: 'Error Loading Route Timeline',
          })}
        >
          <RouteTimelineComponent {...shipment} />
        </ErrorBoundary>
        <ErrorBoundary
          message={props.intl.formatMessage({
            id: 'shipmentDetails.errorStates.map',
            defaultMessage: 'Error Loading Map',
          })}
        >
          {!isEmpty(mapLocations) && (
            <div className={styles.mapWrapper} data-locator="shipment-map">
              <MapRoute locations={mapLocations} />
            </div>
          )}
        </ErrorBoundary>
        {props.authorizations.hasAdvancedTrackingLinking(shipmentMode) && (
          <ErrorBoundary
            message={props.intl.formatMessage({
              id: 'shipmentDetails.errorStates.inventory',
              defaultMessage: 'Error Loading Inventory and Orders Information',
            })}
          >
            <Tabs
              type="card"
              defaultActiveKey="orders"
              panes={[
                {
                  key: 'orders',
                  tab: <FormattedMessage defaultMessage="Orders" id="shipmentDetails.inventoryOrders.tabs.orders" />,
                },
                {
                  key: 'inventory',
                  tab: (
                    <FormattedMessage defaultMessage="Inventory" id="shipmentDetails.inventoryOrders.tabs.inventory" />
                  ),
                },
              ]}
              tabcontent={{
                orders: <OrdersTable masterShipmentId={masterShipmentId} />,
                inventory: <InventoryItemsTable masterShipmentId={masterShipmentId} />,
              }}
            />
          </ErrorBoundary>
        )}
      </Container>
    </React.Fragment>
  );
};

export default injectIntl(ShipmentDetailsComponent);
