import qs from 'querystring';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import get from 'lodash/get';

import { API_PATH } from '../../../common/AppConstants';
import axios from '../../../util/paxios';
import { buildSetSearchTerm, buildShipmentSearchQueryAction } from '../../shipmentListComponent/ducks/searchReducer';
import FilterUtil from '../util/filterUtil';
import {
  buildAttributeSuggestions,
  buildCarrierSuggestions,
  buildCompanySuggestions,
  buildCustomerSuggestions,
  buildCreatedBySuggestions,
  buildLocationSuggestions,
  buildPortOfLoadingSuggestions,
  buildTransshipmentPortSuggestions,
  buildPortOfDischargeSuggestions,
} from './actions';
import types from './types';

export const getState = (state) => ({
  query: state.searchReducer.previousSearchQuery,
  filter: state.filterReducer.filter,
  modeFilters: state.filterReducer.modeFilters,
});

export const getStateForQuickView = (state) => ({
  query: state.searchReducer.searchTerm,
  filter: state.filterReducer.filter,
  modeFilters: state.filterReducer.modeFilters,
});

export function* filterShipmentsAsyncClear(action) {
  const { query } = yield select(getState);

  yield all([
    put(buildSetSearchTerm(action.searchTerm)),
    put(
      buildShipmentSearchQueryAction({
        authorities: action.authorities,
        searchText: action.searchTerm,
        filter: FilterUtil.getEmptyFilter(),
        modes: action.modeFilter,
        sortBy: query.sortBy,
        resultStartIndex: 0,
        enableMultiValueSearch: action.isMultiValue,
      })
    ),
  ]);
}

export function* filterShipmentsAsync(action) {
  const { query, filter, modeFilters } =
    action.source === 'quickView' ? yield select(getStateForQuickView) : yield select(getState);

  const attributeKeyValuePairs = FilterUtil.generateAttributeKeyValuePairs(get(filter, 'attribute'));

  const convertedFilter = FilterUtil.getValidOrBlank({
    ...filter,
    attribute: [],
    attributeKeyValuePairs,
  });

  const searchText = action.source === 'quickView' ? query : query.search;

  yield put(
    buildShipmentSearchQueryAction({
      authorities: action.authorities,
      searchText,
      filter: convertedFilter,
      modes: modeFilters,
      sortBy: query.sortBy,
      enableMultiValueSearch: action.isMultiValue,
    })
  );
}

export function updateSuggestion(action) {
  const resourceName = types.SUGGESTIONS_MAP[action.field];
  const isCustomers = action.field === types.SUGGESTIONS_MAP.customers;

  const url = isCustomers
    ? `${API_PATH}/carrier/partnerOrgSharingShipments`
    : `${API_PATH}/shipment/search/${resourceName}`;

  const params = isCustomers
    ? { search: action.filterText }
    : { q: action.filterText, ...qs.parse(action.params), modes: action.mode };

  return axios({
    method: 'GET',
    url,
    withCredentials: true,
    params,
  });
}

export function getTenantAttributes() {
  let url = API_PATH + '/tenant-attributes';
  return axios({
    method: 'GET',
    url: url,
    withCredentials: true,
  });
}

export function* getTenantAttributesAsync() {
  const response = yield call(getTenantAttributes);

  yield put({
    type: types.GET_TENANT_ATTRIBUTES_SUCCESS,
    response,
  });
}

export function* updateSuggestionAsync(action) {
  try {
    let results = [];
    if (!(action.filterText === null) && action.filterText.length > 1) {
      let response = yield call(updateSuggestion, action);
      results = response.data;
    }
    switch (action.field) {
      case 'location':
        yield put(buildLocationSuggestions(results));
        break;
      case 'carrier':
        yield put(buildCarrierSuggestions(results));
        break;
      case 'portOfLoading':
        yield put(buildPortOfLoadingSuggestions(results));
        break;
      case 'transshipmentPort':
        yield put(buildTransshipmentPortSuggestions(results));
        break;
      case 'portOfDischarge':
        yield put(buildPortOfDischargeSuggestions(results));
        break;
      case 'company':
        yield put(buildCompanySuggestions(results));
        break;
      case 'customers':
        yield put(buildCustomerSuggestions(results));
        break;
      case 'createdBy':
        yield put(buildCreatedBySuggestions(results));
        break;
      case 'attribute':
        yield put(buildAttributeSuggestions(results));
        break;
      default:
        break;
    }
  } catch (e) {
    // TODO: Remove this console.log - Mark Serrano
    console.error(e); // eslint-disable-line no-console
  }
}

export function* watchFilterAsyncClear() {
  yield takeEvery(types.CLEAR_FILTER, filterShipmentsAsyncClear);
}

export function* watchFilterAsync() {
  yield takeEvery(types.APPLY_FILTER_FIELDS, filterShipmentsAsync);
}

export function* watchSuggestionAsync() {
  yield takeEvery(types.UPDATE_SUGGESTIONS, updateSuggestionAsync);
}

export function* watchModeFilterToggleAsync() {
  yield takeEvery(types.TOGGLE_MODE_FILTER_OPTIONS, filterShipmentsAsync);
}

export function* watchGetTenantAttributeNameAsync() {
  yield takeEvery(types.GET_TENANT_ATTRIBUTES, getTenantAttributesAsync);
}

export function* watchSetFilterCarrierModeAsync() {
  yield takeEvery(types.SET_FILTER_CARRIER_MODE, filterShipmentsAsync);
}
