import qs from 'querystring';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import get from 'lodash/get';

import useDebounce from 'hooks/useDebounce';
import { FILTER_RESULT_DELAY } from 'common/AppConstants';
import AutocompleteFilter from '../../common/autocompleteFilter/AutocompleteFilter';
import useSuggestions from '../util/useSuggestions';
import { SUGGESTIONS_MAP } from '../ducks/types';

const INITIAL_AMOUNT_VISIBLE_LOCODES = 3;

const messages = defineMessages({
  filterByLocode: {
    id: 'quickView.portOfDischargeFilter.filterByLocode',
    defaultMessage: 'Filter by Locode',
  },
});

const PortOfDischargeFilter = (props) => {
  const [currentValue, setCurrentValue] = useState('');
  const debouncedValue = useDebounce(currentValue, FILTER_RESULT_DELAY);
  const params = qs.stringify({ q: debouncedValue });
  const suggestedLocodes = useSuggestions(SUGGESTIONS_MAP.locode, params);
  return (
    <Col>
      <label htmlFor="port-of-discharge-autocomplete-filter">
        {<FormattedMessage id="quickView.portOfDischargeFilter.locode" defaultMessage="PORT OF DISCHARGE" />}
      </label>
      <AutocompleteFilter
        idProp="port-of-discharge-autocomplete-filter"
        placeholder={props.intl.formatMessage(messages.filterByLocode)}
        currentValue={currentValue}
        dataSource={suggestedLocodes}
        updateFn={(inputValue) => setCurrentValue(inputValue)}
        newRequestFn={(filterItem) => props.handleAutocompleteUpdate(filterItem, 'portOfDischarge')}
        listData={get(props, 'filter.portOfDischarge', [])}
        showAllChips={get(props, 'showSelections.showAllPortOfDischarge')}
        passedInDeleteFn={(filterItem) => props.handleAutocompleteUpdate(filterItem, 'portOfDischarge')}
        initialAmountVisible={INITIAL_AMOUNT_VISIBLE_LOCODES}
        showHideAllFn={() => props.toggleShowSelections('PortOfDischarge')}
        dataLocator="port-of-discharge-filter"
      />
    </Col>
  );
};

export default injectIntl(PortOfDischargeFilter);
