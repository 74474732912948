import isNil from 'lodash/isNil';
import get from 'lodash/get';
import moment from 'moment-timezone';

/**
 * Creates concatenated text string based on key value pairs
 * @param string - initial full text string
 * @param values - array of strings to replace keys
 * @param key - array of strings to replace
 * @returns {string}
 */
export const createTextString = (string, values, key) => {
  if (isNil(values)) {
    return string;
  }
  let returnString = string;
  values
    .filter((value) => !isNil(value))
    .forEach((value, index) => {
      returnString = returnString.replace(`{${key[index]}}`, value);
    });

  return returnString;
};

/**
 * Gets correct timestamp to use - if utcDate not available, defaults to localSplitDate object
 * @param utcDate - utc timestamp
 * @param localSplitDate - object with date and time strings
 * @param timezone - string with timezone ('America/Chicago')
 * @param format - string with format for moment to use ('MM/DD/YY')
 * @returns {string} text displaying last updated date
 */
export const getDate = (utcDate, localSplitDate, timezone, format) => {
  if (utcDate && timezone) {
    return moment.tz(utcDate, timezone).format(format);
  } else if (get(localSplitDate, 'localDate')) {
    return moment(localSplitDate.localDate).format(format);
  } else {
    return moment(utcDate).format(format);
  }
};
