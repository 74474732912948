import LocationUtils from '../../../../../common/LocationUtils/LocationUtils';

/**
 * Creates the layout of the popup.
 *
 * This allows devs to do custom styling here without worrying the internal details of the HERE map factory.
 * The styling for the popup is an inline css - Mark Serrano
 *
 * @param location the property name from the shipmentStops response.
 *                 Note: This is poorly named from the backend response.
 */
const allStopPopupHtml = (location) => {
  let { stopName, address, city, state, country, zip, contact } = location;

  /**
   * Gets markup for the Location Details tooltip
   *
   * @returns HTML template
   */
  const getlocationDetailsMarkup = () => {
    let markup = `
      <div class="location-details">
        <h4 class="stop-name">
          ${stopName}
        </h4>
        <p class="stop-address">
          <span>${address}, ${LocationUtils.formatCityStateCountry(city, state, country)}, ${zip}</span><br/>
        </p>

        <div class="contact-details">
          ${
            contact.name
              ? `<p class="contact-details__name">
              <span>${contact && contact.contactName}</span>
            </p>`
              : ``
          }
          ${
            contact.email
              ? `<p class="contact-details__email">
              <a href="mailto:${contact && contact.email}">${contact && contact.email}</a>
            </p>`
              : ``
          }
          ${
            contact.phoneNumber1
              ? `<p class="contact-details__phone">
                <a href="tel:${contact && contact.phoneNumber1}">${contact && contact.phoneNumber1}</a>
              </p>`
              : ``
          }
        </div>
      </div>
    `;

    return markup;
  };

  return getlocationDetailsMarkup();
};

export default allStopPopupHtml;
