import find from 'lodash/find';
import filter from 'lodash/filter';
import { prepareRouteDetails } from './mappingUtils';

export const mapRouteDetails = (shipment, routeDetailsModel) => {
  const preparedRoute = prepareRouteDetails(
    shipment.allStatusUpdates,
    shipment.shipmentStops,
    shipment.latestStopStatuses,
    shipment.latestStatusUpdate
  );
  const terminalCount = filter(preparedRoute, { type: 'STOP' }).length - 2;
  const routeSummary = {
    origin: find(preparedRoute, { stopType: 'ORIGIN' }),
    destination: find(preparedRoute, { stopType: 'DESTINATION' }),
    stopCountText: `${terminalCount} ${terminalCount !== 1 ? 'stops' : 'stop'} on the way`,
  };

  return {
    ...routeDetailsModel,
    routeDetails: preparedRoute,
    routeSummary: routeSummary,
  };
};
