import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useIntl, FormattedMessage } from 'react-intl';
import { DownloadOutlined } from '@ant-design/icons';
import CsvDownloader from 'react-csv-downloader';
import './FleetListComponent.scss';
import { assetsResults } from 'components/carrierFleetListComponent/models/CarrierSearchResult';

const DownloadCSVComponent = (props: any) => {
  const intl = useIntl();

  const columns = [
    {
      id: 'col1',
      displayName: intl.formatMessage({
        id: 'telematisDeviceId.downloadCsvFleet.colTitle',
        defaultMessage: 'Telematic device ID',
      }),
    },
  ];

  const datas = props.vehiclesCSV?.map((vehicle: any) => ({
    col1: vehicle.value,
  }));

  return (
    <div className="link">
      <CsvDownloader
        filename={`${props.carrierName} FleetList`}
        separator=";"
        columns={columns}
        datas={datas}
        text="DOWNLOAD"
      >
        <FormattedMessage id="fleetList.exportData.download" defaultMessage="Download">
          {(...text) => (
            <span className="downloadCSV">
              {text} <DownloadOutlined style={{ fontSize: '24px' }} />
            </span>
          )}
        </FormattedMessage>
      </CsvDownloader>
    </div>
  );
};

export const DisplayedResults = ({
  totalResultsCount,
  carrierName,
  vehiclesCSV,
}: {
  totalResultsCount: number;
  carrierName: string;
  vehiclesCSV: assetsResults[];
}) => {
  return (
    <Container className={`AppliedFilterList align-items-center`}>
      <Row className="align-items-center mb-4">
        <Col xs={24} sm={12}>
          <h3 className={`d-md-inline-block mb-0 'mr-3`} data-locator="number-of-results">
            <FormattedMessage
              id="carrierList.appliedFilterList.showingcarrierWithoutQuery"
              defaultMessage="{ results, number } { results, plural, =1 { result } other { results }}"
              values={{ results: totalResultsCount }}
            />
            {vehiclesCSV && vehiclesCSV.length > 0 && (
              <DownloadCSVComponent vehiclesCSV={vehiclesCSV} carrierName={carrierName} />
            )}
          </h3>
        </Col>
      </Row>
    </Container>
  );
};

export default DisplayedResults;
