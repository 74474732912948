import classNames from 'classnames';
import get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import { withTheme } from '../../../contexts/ThemeContext';
import { primaryBlue } from '../../../styles/colors';
import { Theme } from '../../settings/BrandTheming/models/Theme';

import * as styles from './Progress.module.scss';

export interface ProgressProps {
  theme: Theme;
  isLoading?: boolean;
  className?: string;
}
const Progress: React.FunctionComponent<ProgressProps> = ({ isLoading, theme, className }) => (
  <CircularProgress
    size={48}
    thickness={5}
    className={classNames(styles.spinner, className)}
    style={{
      display: isLoading === true ? 'block' : 'none',
      color: get(theme, 'primaryColor', primaryBlue),
    }}
  />
);

Progress.defaultProps = {
  className: '',
  isLoading: false,
};

export default withTheme(Progress);
