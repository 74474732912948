import get from 'lodash/get';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import DropdownChips from '../../common/forms/dropdownChips/DropdownChips';

const INITIAL_AMOUNT_VISIBLE_STATUS = 9;

const messages = defineMessages({
  filterByStatus: {
    id: 'quickView.statusFilter.filterByStatus',
    defaultMessage: 'Filter by Status',
  },
});

const StatusFilter = (props) => {
  const { intl } = props;
  // All item options with translated values
  const translatedMenuItems = props.menuItems.map((value) => ({ ...value, displayName: intl.formatMessage(value) }));

  // Selected items
  const selectedStatusFilters = props.menuItems.filter((value) => props.filter.status.includes(value.defaultMessage));

  // selectedValues is an array of id's, else the index
  const onDropdownChange = (selectedValues = []) => {
    // Get the list of translated objects which are in the selected list
    const selectedMenuItems = translatedMenuItems.filter((item) => selectedValues.includes(item.id));

    // Get the values that drive the data
    const selectedDataValues = selectedMenuItems.map((v) => v.defaultMessage);
    props.handleStatusDropdownItemSelect(selectedDataValues);
  };

  const onDelete = (filterItem) => {
    // Find the full-data version of the deleted filter item
    const option = translatedMenuItems.find((d) => d.displayName === filterItem);
    props.handleStatusDropdownItemRemove(option.defaultMessage);
  };

  return (
    <Col className="status-dropdown-row">
      <label htmlFor="Status">{<FormattedMessage id="quickView.statusFilter.status" defaultMessage="STATUS" />}</label>
      <div data-locator="status-filter-dropdown">
        <DropdownChips
          placeholder={props.intl.formatMessage(messages.filterByStatus)}
          currentStatus={selectedStatusFilters}
          defaultHeight={275}
          dropdownChangeFn={onDropdownChange}
          menuItems={translatedMenuItems}
          listData={selectedStatusFilters}
          showAllChips={get(props, 'showSelections.showAllStatus')}
          passedInDeleteFn={onDelete}
          initialAmountVisible={INITIAL_AMOUNT_VISIBLE_STATUS}
          showHideAllFn={() => props.toggleShowSelections('Status')}
          inputId="Status"
        />
      </div>
    </Col>
  );
};

export default injectIntl(StatusFilter);
