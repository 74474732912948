import Container from 'react-bootstrap/Container';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import * as React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import uuid from 'uuid/v4';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { ShipmentModeIcon, Tooltip } from 'ui-components';
import { getPrincipalFromState, tlAdditionalApptWindowsEnabled } from 'common/authorizations';
import { formatUtcTimeInTimeZoneOrDefaultToBrowserZone } from 'common/dateUtils';
import { MONTH_DAY_YEAR_FORMAT, HOURS_MINUTES_FORMAT, HOURS_MINUTES_TIMEZONE_FORMAT } from 'i18n/configurei18n';
import {
  AddEditShipmentTypeEnum,
  ShipmentModeEnum,
  ShipmentStop,
  ShipmentEvent,
  CommonStatusCodeEnum,
  ShipmentStopTypeEnum,
  TimelinessCodeStatusColor,
  TimelinessCodeEnum,
  TimelinessCodeStatusIntlKeys,
  DateTimeWithTimezone,
  CodeInlItemEnum,
  ShipmentLegCarrierInfo,
  Identifier,
  EntitlementInfo,
} from 'models';
import HereMapsBehavior from 'common/hereMaps/hereMapsBehavior';
import StatusColorTag from 'components/common/statusColorTag/StatusColorTag';
import { trackEvent } from 'common/eventTracker';
import { RootState } from 'reducers';
import { ReactComponent as NoSignalIcon } from 'components/common/assets/icons/icon-no-signal.svg';
import * as utils from '../utils';
import * as styles from './RouteTimeline.module.scss';
import { AddEditShipmentContainer } from '../../addEditShipment/ducks';
import * as addEditShipmentTypes from '../../addEditShipment/ducks/types';
import { eventDateFormat, eventTimeFormat } from './LegEventComponent';
import StopApptWindow, { StopAdditionalApptWindows } from './StopApptWindowComponent';
import { getStopLocationText } from '../utils';

export interface CurrentStopComponentProps {
  stop: ShipmentStop;
  hideStops?: () => void;
  stopOrder?: number;
  activeStopEvent?: ShipmentEvent;
  hasActiveStop?: boolean;
  transferFromMode?: ShipmentModeEnum;
  shipmentId?: string; // used by TL when shipment stops are editable
  mapInstance?: H.Map; // used by TL for focusing map on stop locations
  authorizations?: any;
  mostRecentLegEvent?: ShipmentEvent;
  isCrossRegionShipment?: boolean;
  shouldHideStatus?: boolean;
  carrierIdentifiers?: ShipmentLegCarrierInfo[];
  entitlementInfo?: EntitlementInfo;
  shipmentIdentifiers: Identifier[];
}

// ! When stop type is transfer, need to get the mode of the NEXT leg, for displaying the icons on the left.

const StopModeIcons = ({
  transferFromMode,
  mode,
  stop,
  mapInstance,
}: {
  transferFromMode?: ShipmentModeEnum;
  mode?: ShipmentModeEnum;
  stop: ShipmentStop;
  mapInstance?: H.Map;
}) => {
  const iconShouldBeClickable = mapInstance && get(stop, 'location.lat') && get(stop, 'location.lng');

  const handleStopModeIconClick = () => {
    const coords: { lat?: number; lng?: number } = {};
    if (iconShouldBeClickable) {
      coords.lat = get(stop, 'location.lat');
      coords.lng = get(stop, 'location.lng');

      HereMapsBehavior.setCenter(mapInstance, coords);
      HereMapsBehavior.setZoom(mapInstance, 14);
      trackEvent(`VIEW_STOP_ON_MAP_${stop.type}`);
    }
  };

  return (
    <div className={styles.stopModeIconSection}>
      {transferFromMode && (
        <button
          onClick={handleStopModeIconClick}
          className={`${styles.modeIcon} ${iconShouldBeClickable ? styles.clickable : ''}`}
        >
          <ShipmentModeIcon mode={transferFromMode} data-locator="leg-mode-icon" />
        </button>
      )}
      {transferFromMode && mode && (
        <div className={styles.modeIcon}>
          <span className={styles.downArrow} />
        </div>
      )}
      <button
        onClick={handleStopModeIconClick}
        className={`${styles.modeIcon} ${iconShouldBeClickable ? styles.clickable : ''}`}
      >
        <ShipmentModeIcon mode={mode} data-locator="leg-mode-icon" />
      </button>
    </div>
  );
};

const displayStatusBadge = (
  timelinessCode: TimelinessCodeEnum | undefined,
  authorizations: any
): React.ReactNode | null => {
  if (authorizations && authorizations.hasShipmentStatusBadgeHidden()) {
    return null;
  }

  if (
    isNil(timelinessCode) ||
    timelinessCode === TimelinessCodeEnum.ON_TIME ||
    timelinessCode === TimelinessCodeEnum.UNKNOWN
  ) {
    return null;
  }
  return (
    <StatusColorTag
      colorValue={TimelinessCodeStatusColor[timelinessCode]}
      messageText={TimelinessCodeStatusIntlKeys[timelinessCode]}
    />
  );
};

const displayEventType = (intl: IntlShape, event: ShipmentEvent): string | undefined => {
  const eventActualDateTime = hasValidDateTime(event.dateTime);
  if (!eventActualDateTime) {
    return utils.getStatusCodeText(intl, event.type, CodeInlItemEnum.FUTURE);
  }
  return utils.getStatusCodeText(intl, event.type);
};

const hasEstimatedOrScheduledTime = (event: ShipmentEvent): boolean => {
  return hasValidDateTime(event.estimatedDateTime) || hasValidDateTime(event.scheduledDateTime);
};

const hasValidDateTime = (eventDateTime: DateTimeWithTimezone | undefined): boolean => {
  return get(eventDateTime, 'dateTimeUtc') !== undefined;
};

const shouldDisplayEstimatedDateTime = (event: ShipmentEvent): boolean => {
  return (
    hasValidDateTime(event.estimatedDateTime) &&
    (!hasValidDateTime(event.dateTime) || !hasValidDateTime(event.scheduledDateTime))
  );
};

const shouldDisplayScheduledDateTime = (event: ShipmentEvent): boolean => {
  return hasValidDateTime(event.scheduledDateTime);
};

const getUpdatedDateInBrowserTimezone = (updatedDate: {
  statusUtcDateTime: string | undefined;
  localTimeZoneIdentifier: string | null | undefined;
  localTimeZoneAbbreviation: string;
}) => {
  const { statusUtcDateTime, localTimeZoneIdentifier, localTimeZoneAbbreviation } = updatedDate;

  if (statusUtcDateTime) {
    let date: string =
      formatUtcTimeInTimeZoneOrDefaultToBrowserZone(statusUtcDateTime, localTimeZoneIdentifier)?.format(
        MONTH_DAY_YEAR_FORMAT
      ) || '';
    date += ' at ';
    date +=
      formatUtcTimeInTimeZoneOrDefaultToBrowserZone(statusUtcDateTime, localTimeZoneIdentifier)?.format(
        HOURS_MINUTES_FORMAT
      ) || '';
    date += ' ';
    date += localTimeZoneAbbreviation;

    return date;
  }
};

export const getDateByFormat = (dateParams: {
  eventDateTime: moment.Moment | undefined;
  format: string;
  localTimeZoneAbbreviation: string;
}) => {
  const { eventDateTime, format, localTimeZoneAbbreviation } = dateParams;

  if (eventDateTime) {
    if (format === HOURS_MINUTES_TIMEZONE_FORMAT) {
      if (isNaN(Number(eventDateTime.format('z')))) {
        return eventDateTime.format(HOURS_MINUTES_TIMEZONE_FORMAT);
      }

      if (eventDateTime.format(HOURS_MINUTES_FORMAT)) {
        return (
          eventDateTime.format(HOURS_MINUTES_FORMAT) +
          ' ' +
          (localTimeZoneAbbreviation ? localTimeZoneAbbreviation : '')
        );
      }
      return '';
    }
    if (format === MONTH_DAY_YEAR_FORMAT) {
      return eventDateTime.format(MONTH_DAY_YEAR_FORMAT) ? eventDateTime.format(MONTH_DAY_YEAR_FORMAT) : '';
    }
  }
  return '';
};

const displayStoptimes = (stopTimesParams: {
  event: ShipmentEvent;
  stopName: string;
  localTimeZoneAbbreviation: string;
}): React.ReactNode | null => {
  const { event, stopName, localTimeZoneAbbreviation } = stopTimesParams;

  if (hasEstimatedOrScheduledTime(event)) {
    const eventScheduledDateTime = hasValidDateTime(event.scheduledDateTime)
      ? formatUtcTimeInTimeZoneOrDefaultToBrowserZone(
          event?.scheduledDateTime?.dateTimeUtc,
          event?.scheduledDateTime?.timezone
        )
      : undefined;
    const eventEstimatedDateTime = hasValidDateTime(event.estimatedDateTime)
      ? formatUtcTimeInTimeZoneOrDefaultToBrowserZone(
          event?.estimatedDateTime?.dateTimeUtc,
          event?.estimatedDateTime?.timezone
        )
      : undefined;
    const eventDateTime = hasValidDateTime(event.dateTime)
      ? formatUtcTimeInTimeZoneOrDefaultToBrowserZone(event?.dateTime?.dateTimeUtc, event?.dateTime?.timezone)
      : undefined;
    getDateByFormat({ eventDateTime, format: eventDateFormat, localTimeZoneAbbreviation });
    return (
      <>
        {eventDateTime && (
          <div className={styles.stopTimeSection} data-locator={`actual-date-event-${stopName}`}>
            <div className={styles.stopEventTitle} data-locator="actual-title">
              <FormattedMessage id="displayStoptimes.stopEventTitle.actual" defaultMessage="Actual" />
            </div>
            <div className={styles.stopEventDate}>
              {eventDateTime
                ? getDateByFormat({ eventDateTime, format: eventDateFormat, localTimeZoneAbbreviation })
                : ''}
            </div>
            <div className={styles.stopEventTime}>
              {eventDateTime
                ? getDateByFormat({ eventDateTime, format: eventTimeFormat, localTimeZoneAbbreviation })
                : ''}
            </div>
          </div>
        )}
        {shouldDisplayEstimatedDateTime(event) && (
          <div className={styles.stopTimeSection} data-locator={`estimated-date-event-${stopName}`}>
            <div
              className={classNames(styles.stopEventTitle, {
                [styles.removeStopEvent]: !shouldDisplayScheduledDateTime(event) || hasValidDateTime(event.dateTime),
              })}
              data-locator="estimated-title"
            >
              <FormattedMessage id="displayStoptimes.removeStopEvent.estimated" defaultMessage="Estimated" />
            </div>
            <div
              className={classNames(styles.stopEventDate, {
                [styles.removeStopEvent]: !shouldDisplayScheduledDateTime(event) || hasValidDateTime(event.dateTime),
              })}
            >
              {eventEstimatedDateTime
                ? getDateByFormat({
                    eventDateTime: eventEstimatedDateTime,
                    format: eventDateFormat,
                    localTimeZoneAbbreviation,
                  })
                : ''}
            </div>
            <div
              className={classNames(styles.stopEventTime, {
                [styles.removeStopEvent]: !shouldDisplayScheduledDateTime(event) || hasValidDateTime(event.dateTime),
              })}
            >
              {eventEstimatedDateTime
                ? getDateByFormat({
                    eventDateTime: eventEstimatedDateTime,
                    format: eventTimeFormat,
                    localTimeZoneAbbreviation,
                  })
                : ''}
            </div>
          </div>
        )}
        {shouldDisplayScheduledDateTime(event) && (
          <div className={styles.stopTimeSection} data-locator={`scheduled-date-event-${stopName}`}>
            <div className={`${styles.stopEventTitle} ${styles.removeStopEvent}`} data-locator="scheduled-title">
              <FormattedMessage id="displayStoptimes.stopEventTitle.scheduled" defaultMessage="Scheduled" />
            </div>
            <div className={`${styles.stopEventDate} ${styles.removeStopEvent}`}>
              {eventScheduledDateTime
                ? getDateByFormat({
                    eventDateTime: eventScheduledDateTime,
                    format: eventDateFormat,
                    localTimeZoneAbbreviation,
                  })
                : ''}
            </div>
            <div className={`${styles.stopEventTime} ${styles.removeStopEvent}`}>
              {eventScheduledDateTime
                ? getDateByFormat({
                    eventDateTime: eventScheduledDateTime,
                    format: eventTimeFormat,
                    localTimeZoneAbbreviation,
                  })
                : ''}
            </div>
          </div>
        )}
      </>
    );
  } else if (event.dateTime) {
    const eventDateTime = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(
      event.dateTime.dateTimeUtc,
      event.dateTime.timezone
    );
    return (
      <div className={styles.stopTimeSection}>
        <div className={styles.stopEventDate}>
          {eventDateTime ? getDateByFormat({ eventDateTime, format: eventDateFormat, localTimeZoneAbbreviation }) : ''}
        </div>
        <div className={styles.stopEventTime}>
          {eventDateTime ? getDateByFormat({ eventDateTime, format: eventTimeFormat, localTimeZoneAbbreviation }) : ''}
        </div>
      </div>
    );
  }
  return null;
};

const CurrentStopComponent: React.FC<CurrentStopComponentProps> = ({
  stop,
  hideStops,
  stopOrder,
  activeStopEvent,
  hasActiveStop,
  transferFromMode,
  shipmentId,
  mapInstance,
  authorizations,
  carrierIdentifiers,
  shipmentIdentifiers,
  isCrossRegionShipment = false,
  shouldHideStatus,
  entitlementInfo,
}) => {
  const intl = useIntl();
  const stopLocationText = getStopLocationText(stop);
  const displayEventLocation = (event: ShipmentEvent) => {
    const locationText = utils.getEventLocationText(event);
    return locationText ? <span className={styles.eventLocation}>{` | ${locationText}`}</span> : '';
  };
  const principal = useSelector((state: RootState) => getPrincipalFromState(state));

  const startDate = get(stop, 'appointmentWindow.startDate');
  const endDate = get(stop, 'appointmentWindow.endDate');
  const localTimeZoneIdentifier = get(stop, 'appointmentWindow.localTimeZoneIdentifier');
  const localTimeZoneAbbreviation = get(stop, 'appointmentWindow.localTimeZoneIdentifierShort', '');

  const recordedArrivalDateTimeUtc = get(stop, 'recordedArrivalDateTime.dateTimeUtc');
  const recordedDepartureDateTimeUtc = get(stop, 'recordedDepartureDateTime.dateTimeUtc');
  const additionalAppointmentUtcScanDateTime = get(stop, 'appointmentWindows[0].additionalAppointmentUtcScanDateTime');
  const statusUtcDateTime = get(stop, 'statusMessage.utcDateTime');
  let appointmentWindowStart;
  let appointmentWindowEnd;
  let arrivalTime;
  let departureTime;
  let additionalAppointmentArrivalTime;
  let statusTimestamp;

  if (startDate && endDate) {
    appointmentWindowStart = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(startDate, localTimeZoneIdentifier);
    appointmentWindowEnd = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(endDate, localTimeZoneIdentifier);
  }

  if (recordedArrivalDateTimeUtc) {
    arrivalTime = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(recordedArrivalDateTimeUtc, localTimeZoneIdentifier);
  }

  if (recordedDepartureDateTimeUtc) {
    departureTime = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(
      recordedDepartureDateTimeUtc,
      localTimeZoneIdentifier
    );
  }

  if (additionalAppointmentUtcScanDateTime) {
    additionalAppointmentArrivalTime = formatUtcTimeInTimeZoneOrDefaultToBrowserZone(
      additionalAppointmentUtcScanDateTime,
      localTimeZoneIdentifier
    );
  }

  if (statusUtcDateTime) {
    statusTimestamp = getUpdatedDateInBrowserTimezone({
      statusUtcDateTime,
      localTimeZoneIdentifier,
      localTimeZoneAbbreviation,
    });
  }

  let addEditShipmentType: AddEditShipmentTypeEnum = AddEditShipmentTypeEnum.ADD_STOPS;
  if (get(stop, 'type') === ShipmentStopTypeEnum.ORIGIN) {
    addEditShipmentType = AddEditShipmentTypeEnum.PICKUP_DETAIL;
  } else if (get(stop, 'type') === ShipmentStopTypeEnum.DESTINATION) {
    addEditShipmentType = AddEditShipmentTypeEnum.DROPOFF_DETAIL;
  }

  const shouldDisplayContactName = authorizations ? authorizations.displayStopContactName() : true;

  const isLastKnwonETA = stop.statusMessage && stop.statusMessage.id !== 'shipmentDetails.stopCards.etaIs';

  const buildStopEventDataLocators = (
    stopName: string,
    { estimatedDateTime, scheduledDateTime, dateTime }: ShipmentEvent
  ): string => {
    const stopTypeDataLocator = `event-type-${stopName}`;
    const sortedDates = { scheduledDateTime, dateTime, estimatedDateTime };
    const stopDateTime = Object.values(sortedDates).filter((eventDateTime) => eventDateTime?.dateTimeUtc)[0];
    return stopDateTime ? `${stopTypeDataLocator}-${stopDateTime.dateTimeUtc}` : stopTypeDataLocator;
  };
  // if someone granted me the shipmente, I'm not the owner
  const isShared: boolean | undefined = !!entitlementInfo?.grantor;

  return (
    <Container key={`${stop.stopName}${stop.stopNumber}${stop.type}${stop.mode}`} className={styles.stopContainer}>
      <Row className="align-items-stretch">
        <Col xs={6}>
          <StopModeIcons mode={stop.mode} transferFromMode={transferFromMode} stop={stop} mapInstance={mapInstance} />
        </Col>

        <Col xs={2}>
          <div className={`${styles.routeLine}`} />
        </Col>

        <Col className="align-self-center">
          {stopOrder === 0 && stop.type !== ShipmentStopTypeEnum.DESTINATION && (
            <div className={styles.etaShowLessButton}>
              <button
                onClick={hideStops}
                className={`${styles.showHiddenShipmentsButtonETA} ${stop.editable ? styles.shiftLeft : ''}`}
              >
                <FormattedMessage id="shipmentDetails.showLess" defaultMessage="Show Less" />
              </button>
            </div>
          )}
          <div className={styles.stopDetails} data-locator={`stop-details-${stop.stopName}`}>
            <div className="d-flex justify-content-between align-items-center">
              <h3 style={{ textTransform: 'none' }}>
                <span className={styles.stopName} data-locator={`stop-details-stop-name-${stop.stopName}`}>
                  {stop.locationId && isCrossRegionShipment === false && !isShared ? (
                    <Link
                      to={`/location-details/${stop.locationId}`}
                      data-locator={`stop-details-stop-name-${stop.stopName}-link`}
                    >
                      {stop.stopName}
                    </Link>
                  ) : (
                    stop.stopName
                  )}
                </span>
                <span className={styles.stopLocation} data-locator={`stop-details-stop-name-with-separator`}>
                  {stop.stopName && stopLocationText && <span className={styles.stopLocationSeparator}> | </span>}
                  {stopLocationText && (
                    <span className={styles.stopCityState} data-locator="stop-details-stop-location-name">
                      {stopLocationText}
                    </span>
                  )}
                </span>
                {authorizations && authorizations.isLateStatusBasedOnScheduledArrival() && (
                  <span data-locator={`stop-details-stop-status-with-separator`}>
                    {stop.status && <span> | </span>}
                    {stop.status && <span data-locator="stop-details-stop-status">{stop.status}</span>}
                  </span>
                )}
              </h3>
              {stop.statusMessage && stop.statusMessage.id && (
                <div className={styles.stopStatusCardInfo}>
                  <Row className={styles.ETAstopStatusCard}>
                    {isLastKnwonETA && (
                      <div style={{ textAlign: 'left', paddingRight: '5px', paddingLeft: '15px' }}>
                        <Tooltip
                          title={
                            <div>
                              <FormattedMessage
                                id="shipmentDetailsTL.tooltips.etaOutdated"
                                defaultMessage="The actual ETA may vary. The “Last Known ETA” is calculated based on the last location update received"
                              />
                            </div>
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    )}
                    <strong>
                      <FormattedMessage
                        id={get(stop, 'statusMessage.id')}
                        defaultMessage={get(stop, 'statusMessage.defaultMessage', '')}
                      />{' '}
                      {statusTimestamp}
                    </strong>
                  </Row>
                  <Row className={styles.ETAstopStatusCard}>
                    {isLastKnwonETA && (
                      <div className={styles.warningEtaText}>
                        <NoSignalIcon style={{ marginRight: '3px' }} />
                        <FormattedMessage defaultMessage="Tracking & ETAs Outdated" id="shipmentDetails.etaOutOfDate" />
                      </div>
                    )}
                  </Row>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <div className={styles.contactInfo}>
                {shouldDisplayContactName && !isNil(stop.contactName) && stop.contactName}
                {!isNil(stop.contactName) && !isNil(stop.contactPhone) && ' | '}
                {!isNil(stop.contactPhone) && <a href={`tel:${stop.contactPhone}`}>{stop.contactPhone}</a>}
              </div>
            </div>

            {!isNil(appointmentWindowStart) && !isNil(appointmentWindowEnd) && (
              <StopApptWindow
                apptWindowType={intl.formatMessage({
                  id: 'shipmentDetails.stopCards.appointmentWindow',
                  defaultMessage: 'Appointment Window',
                })}
                apptWindowStart={appointmentWindowStart}
                apptWindowEnd={appointmentWindowEnd}
                actualArrival={arrivalTime}
                actualDeparture={departureTime}
                localTimeZoneIdentifierShort={localTimeZoneAbbreviation}
                statusMessage={stop.timelinessMessage}
                statusTagColorValue={stop.statusTagColorValue}
                stopIsComplete={stop.isComplete}
                showActualTimestamps={stop.mode === ShipmentModeEnum.TL}
                shouldHideBadge={shouldHideStatus}
              />
            )}
            {tlAdditionalApptWindowsEnabled() && (
              <StopAdditionalApptWindows
                stop={stop}
                actualArrival={additionalAppointmentArrivalTime}
                localTimeZoneIdentifierShort={localTimeZoneAbbreviation}
                actualDeparture={undefined}
                shouldHideBadge={shouldHideStatus}
                shipmentId={shipmentId}
                carrierIdentifiers={carrierIdentifiers}
                shipmentIdentifiers={shipmentIdentifiers}
              />
            )}
          </div>
        </Col>
        {stop.editable && !authorizations.hasTenantCarrierRole(principal) && (
          <Col className="d-none d-md-block" md={1}>
            <div className="edit-shipment">
              <AddEditShipmentContainer
                // TODO: FIX THIS TS IGNORE ERROR
                // eslint-disable-next-line
                // @ts-ignore
                type={addEditShipmentTypes.EDIT_SHIPMENT}
                shipmentId={shipmentId}
                shipment={stop}
                shipmentType={addEditShipmentType}
              />
            </div>
          </Col>
        )}
      </Row>
      {stop.events.map((event) => {
        if (event.type === CommonStatusCodeEnum.UNKNOWN && !event.description && !event.additionalInfo) {
          return null;
        }
        const eventDescriptionText = utils.getStatusReasonCodeText(intl, event.description);
        return (
          <Row key={uuid()} className="align-items-stretch">
            <Col xs={6} className="align-self-center" style={{ paddingRight: '0px' }}>
              {displayStoptimes({ event, stopName: stop.stopName || '', localTimeZoneAbbreviation })}
            </Col>
            <Col xs={2}>
              <div
                className={`${styles.routeLine} ${styles.routeLineWithStop} ${
                  hasActiveStop && isEqual(event, activeStopEvent) ? styles.routeLineWithStopActive : ''
                }`}
              />
            </Col>
            <Col className="align-self-center">
              <div className={styles.stopType} data-locator={buildStopEventDataLocators(stop.stopName || '', event)}>
                {displayEventType(intl, event)}
                {eventDescriptionText && ` - ${eventDescriptionText}`}
                {displayEventLocation(event)} {displayStatusBadge(event.timelinessCode, authorizations)}
                <p className={styles.eventAdditionalInfoStop}>{event.additionalInfo}</p>
              </div>
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};

export default CurrentStopComponent;
