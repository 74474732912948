import { connect } from 'react-redux';
import ClearSessionState from 'components/common/session/ClearSessionState';
import { shouldUseRecentSSS } from 'common/authorizations';
import ShipmentDetailsComponent from './ShipmentDetailsComponent';
import shipmentDetailsModel from './ShipmentDetailsModel';

const mapStateToProps = (state) => ({
  shipmentModel: shipmentDetailsModel,
  shouldUseRecentSSS: shouldUseRecentSSS,
});

// clear session state when in public details view
// by default clear session state only clears when mounting when user is unauthenticated
export default ClearSessionState(connect(mapStateToProps)(ShipmentDetailsComponent));
