import { defineMessages } from 'react-intl';
import {
  ShipmentEvent,
  TenantCustomAttribute,
  ShipmentModeEnum,
  ShipmentPosition,
  ShipmentStop,
  DateTimeWithTimezone,
  ShipmentDerivedStatus,
} from 'models';

export enum SourceRegions {
  EU12 = 'EU12',
  NA12 = 'NA12',
}

export interface Identifier {
  name?: ShipmentIdentifierTypeEnum; // TODO: remove the ? after full #intermodal turnover
  type?: ShipmentIdentifierTypeEnum;
  label?: string;
  value: string;
  primaryText?: { id: string; defaultMessage: string } | string;
  priority?: number;
  intlMessageObject?: { id: string; defaultMessage: string };
}

export interface ShipmentRawResponse {
  id: string;
  currentStatus: ShipmentDerivedStatus;
  currentStatusDateTime: string | DateTimeWithTimezone;
  updatedDate?: string;
  eta?: DateTimeWithTimezone;
  legs: ShipmentLeg[];
  identifiers: Identifier[];
  states: ShipmentState[];
  events: ShipmentEvent[];
  tenantCustomAttributes?: TenantCustomAttribute[];
  sourceRegion: SourceRegions;
}

export interface Shipment extends ShipmentRawResponse {
  currentStatusDateTime: string;
}

export interface ShipmentLeg {
  id: string | null;
  currentStatus?: ShipmentDerivedStatus;
  mode?: ShipmentModeEnum;
  stops: ShipmentStop[];
  events: ShipmentEvent[];
  positions?: ShipmentPosition[];
  carrierInfo?: ShipmentLegCarrierInfo[];
  multiModalDerivedStatus?: ShipmentDerivedStatus;
}

export interface ShipmentLegCarrierInfo {
  name: CarrierInfoTypeEnum;
  value: string;
}

export enum CarrierInfoTypeEnum {
  SCAC = 'SCAC',
  DOT_NUMBER = 'DOT_NUMBER',
  MC_NUMBER = 'MC_NUMBER',
  P44_EU = 'P44_EU',

  // Ocean
  VESSEL_NAME = 'VESSEL_NAME',
  IMO = 'IMO',
  MMSI = 'MMSI',
  CALL_SIGN = 'CALL_SIGN',
}

export interface ShipmentState {
  state: ShipmentDerivedStatus;
  startDateTime: {
    dateTimeUtc: string;
    timezone?: string;
  };
  endDateTime?: {
    dateTimeUtc: string;
    timezone?: string;
  };
}

const message = defineMessages({
  bol: {
    id: 'shipmentList.shipmentIdentifiersMapper.bol',
    defaultMessage: 'BOL',
  },
  order: {
    id: 'shipmentList.shipmentIdentifiersMapper.order',
    defaultMessage: 'Order',
  },
  containerId: {
    id: 'shipmentList.shipmentIdentifiersMapper.containerId',
    defaultMessage: 'Container ID',
  },
  waybill: {
    id: 'shipmentList.shipmentIdentifiersMapper.waybill',
    defaultMessage: 'Waybill',
  },
  trailerId: {
    id: 'shipmentList.shipmentIdentifiersMapper.trailerId',
    defaultMessage: 'Trailer ID',
  },
  railCarId: {
    id: 'shipmentList.shipmentIdentifiersMapper.railCarId',
    defaultMessage: 'Rail Car ID',
  },
  bookingNumber: {
    id: 'shipmentList.shipmentIdentifiersMapper.bookingNumber',
    defaultMessage: 'Booking Number',
  },
  container: {
    id: 'shipmentList.shipmentIdentifiersMapper.container',
    defaultMessage: 'Container',
  },
  customerReference: {
    id: 'shipmentList.shipmentIdentifiersMapper.customerReference',
    defaultMessage: 'Customer Reference',
  },
  external: {
    id: 'shipmentList.shipmentIdentifiersMapper.external',
    defaultMessage: 'External',
  },
  pickup: {
    id: 'shipmentList.shipmentIdentifiersMapper.pickup',
    defaultMessage: 'Pickup',
  },
  pro: {
    id: 'shipmentList.shipmentIdentifiersMapper.pro',
    defaultMessage: 'PRO',
  },
  purchaseOrder: {
    id: 'shipmentList.shipmentIdentifiersMapper.purchaseOrder',
    defaultMessage: 'Purchase Order',
  },
  trackingNumber: {
    id: 'shipmentList.shipmentIdentifiersMapper.tracking',
    defaultMessage: 'Tracking',
  },
  AIR_WAYBILL: {
    id: 'shipmentList.shipmentIdentifiersMapper.airWaybill',
    defaultMessage: 'Air Waybill Number',
  },
  carrierIata: {
    id: 'shipmentList.shipmentIdentifiersMapper.carrierIata',
    defaultMessage: 'Carrier IATA',
  },
  carrierIcao: {
    id: 'shipmentList.shipmentIdentifiersMapper.carrierIcao',
    defaultMessage: 'Carrier ICAO',
  },
  carrierName: {
    id: 'shipmentList.shipmentIdentifiersMapper.carrierName',
    defaultMessage: 'Carrier Name',
  },
  carrierScac: {
    id: 'shipmentList.shipmentIdentifiersMapper.carrierScac',
    defaultMessage: 'Carrier SCAC',
  },
  flightNumber: {
    id: 'shipmentList.shipmentIdentifiersMapper.flightNumber',
    defaultMessage: 'Flight Number',
  },
  railWaybill: {
    id: 'shipmentList.shipmentIdentifiersMapper.railWaybill',
    defaultMessage: 'Rail Waybill',
  },
  vesselName: {
    id: 'shipmentList.shipmentIdentifiersMapper.vesselName',
    defaultMessage: 'Vessel Name',
  },
  deliveryNumber: {
    id: 'shipmentList.shipmentIdentifiersMapper.deliveryNumber',
    defaultMessage: 'Delivery Number',
  },
  vesselImo: {
    id: 'shipmentList.shipmentIdentifiersMapper.vesselImo',
    defaultMessage: 'Vessel IMO',
  },
  billOfLading: {
    id: 'shipmentList.shipmentIdentifiersMapper.billOfLading',
    defaultMessage: 'Bill Of Lading',
  },
  voyageNumber: {
    id: 'shipmentList.shipmentIdentifiersMapper.voyageNumber',
    defaultMessage: 'Voyage Number',
  },
});

export enum ShipmentIdentifierTypeEnum {
  BOL = 'BOL',
  BILL_OF_LADING = 'BILL_OF_LADING',
  ORDER = 'ORDER',
  CONTAINER_ID = 'CONTAINER_ID',
  WAYBILL = 'WAYBILL',
  TRAILER_ID = 'TRAILER_ID',
  RAIL_CAR_ID = 'RAIL_CAR_ID',
  BOOKING_NUMBER = 'BOOKING_NUMBER',
  CONTAINER = 'CONTAINER',
  CUSTOMER_REFERENCE = 'CUSTOMER_REFERENCE',
  EXTERNAL = 'EXTERNAL',
  PICKUP = 'PICKUP',
  PRO = 'PRO',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  TRACKING_NUMBER = 'TRACKING_NUMBER',
  AIR_WAYBILL = 'AIR_WAYBILL',
  CARRIER_IATA = 'CARRIER_IATA',
  CARRIER_ICAO = 'CARRIER_ICAO',
  CARRIER_NAME = 'CARRIER_NAME',
  CARRIER_SCAC = 'CARRIER_SCAC',
  FLIGHT_NUMBER = 'FLIGHT_NUMBER',
  RAIL_WAYBILL = 'RAIL_WAYBILL',
  VESSEL_NAME = 'VESSEL_NAME',
  DELIVERY_NUMBER = 'DELIVERY_NUMBER',
  VESSEL_IMO = 'VESSEL_IMO',
  VOYAGE_NUMBER = 'VOYAGE_NUMBER',
}

export const ShipmentIdentifierIntlKeys: {
  [p in ShipmentIdentifierTypeEnum]: { id: string; defaultMessage: string };
} = {
  [ShipmentIdentifierTypeEnum.BOL]: message.bol,
  [ShipmentIdentifierTypeEnum.BILL_OF_LADING]: message.bol,
  [ShipmentIdentifierTypeEnum.ORDER]: message.order,
  [ShipmentIdentifierTypeEnum.CONTAINER_ID]: message.containerId,
  [ShipmentIdentifierTypeEnum.WAYBILL]: message.waybill,
  [ShipmentIdentifierTypeEnum.TRAILER_ID]: message.trailerId,
  [ShipmentIdentifierTypeEnum.RAIL_CAR_ID]: message.railCarId,
  [ShipmentIdentifierTypeEnum.BOOKING_NUMBER]: message.bookingNumber,
  [ShipmentIdentifierTypeEnum.CONTAINER]: message.container,
  [ShipmentIdentifierTypeEnum.CUSTOMER_REFERENCE]: message.customerReference,
  [ShipmentIdentifierTypeEnum.EXTERNAL]: message.external,
  [ShipmentIdentifierTypeEnum.PICKUP]: message.pickup,
  [ShipmentIdentifierTypeEnum.PRO]: message.pro,
  [ShipmentIdentifierTypeEnum.PURCHASE_ORDER]: message.purchaseOrder,
  [ShipmentIdentifierTypeEnum.TRACKING_NUMBER]: message.trackingNumber,
  [ShipmentIdentifierTypeEnum.AIR_WAYBILL]: message.AIR_WAYBILL,
  [ShipmentIdentifierTypeEnum.CARRIER_IATA]: message.carrierIata,
  [ShipmentIdentifierTypeEnum.CARRIER_ICAO]: message.carrierIcao,
  [ShipmentIdentifierTypeEnum.CARRIER_NAME]: message.carrierName,
  [ShipmentIdentifierTypeEnum.CARRIER_SCAC]: message.carrierScac,
  [ShipmentIdentifierTypeEnum.FLIGHT_NUMBER]: message.flightNumber,
  [ShipmentIdentifierTypeEnum.RAIL_WAYBILL]: message.railWaybill,
  [ShipmentIdentifierTypeEnum.VESSEL_NAME]: message.vesselName,
  [ShipmentIdentifierTypeEnum.DELIVERY_NUMBER]: message.deliveryNumber,
  [ShipmentIdentifierTypeEnum.VESSEL_IMO]: message.vesselImo,
  [ShipmentIdentifierTypeEnum.VOYAGE_NUMBER]: message.voyageNumber,
};
