import { PureComponent, Fragment } from 'react';
import Dropzone, { DropzoneRenderArgs } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import { Switch } from 'ui-components';

import HelpText from './HelpText/HelpText';
import UploadArea from './UploadArea/UploadArea';

import * as styles from './LogoUpload.module.scss';

export interface LogoUploadProps {
  logoUrl: string;
  isDefaultLogo: boolean;
  onUploadLogo: (logo: File) => void;
  onRemoveLogo: () => void;
  logoEnabled: boolean | null;
  onToggleCarrierOnboardingLogo: () => void;
}

export interface LogoUploadState {
  error?: string;
}

class LogoUpload extends PureComponent<LogoUploadProps, LogoUploadState> {
  public state = {
    error: undefined,
  };

  public onDrop = (acceptedFiles: File[], rejectedFiles: File[]): void => {
    if (rejectedFiles.length > 0) {
      this.setState({
        error: 'Maximum logo file size is 512kb.',
      });
    } else if (acceptedFiles.length < 1) {
      return;
    } else {
      this.setState({
        error: undefined,
      });
      this.props.onUploadLogo(acceptedFiles[0]);
    }
  };

  public render() {
    return (
      <Fragment>
        <div className={styles.title}>
          <FormattedMessage id="settings.logoUpload.logo" defaultMessage="Logo" />
        </div>
        <div id="uploadAndHelpWrapper" className={styles.uploadAndHelpWrapper}>
          {this.props.isDefaultLogo && (
            <div className={styles.dropzoneWrapper}>
              <Dropzone
                multiple={false}
                disableClick
                onDrop={this.onDrop}
                maxSize={512000}
                accept={'image/gif, image/jpeg, image/png, image/svg+xml'}
              >
                {(props: DropzoneRenderArgs) => <UploadArea {...props} />}
              </Dropzone>
              {this.state.error && (
                <div id="errorMessage" className={styles.errorMessage}>
                  {this.state.error}
                </div>
              )}
            </div>
          )}
          {!this.props.isDefaultLogo && (
            <div className={styles.previewWrapper}>
              <div className={styles.dropzone}>
                <img className={styles.previewImage} src={this.props.logoUrl} alt="Logo Preview" />
              </div>
              {/* eslint-disable-next-line*/}
              <div className={styles.remove} onClick={this.props.onRemoveLogo}>
                <FormattedMessage id="settings.logoUpload.remove" defaultMessage="REMOVE" />
              </div>
            </div>
          )}
          <HelpText />
        </div>

        <div
          id="toggle-carrier-onboarding-logo-wrapper__div"
          className={styles['toggle-carrier-onboarding-logo-wrapper__div']}
        >
          <Switch
            id={'carrier-onboarding-logo-toggle'}
            checkedChildren={''}
            unCheckedChildren={''}
            checked={Boolean(this.props.logoEnabled)}
            onChange={() => this.props.onToggleCarrierOnboardingLogo()}
            disabled={this.props.logoEnabled === null}
          />
          <label
            htmlFor={'carrier-onboarding-logo-toggle'}
            id="toggle-carrier-onboarding-logo__label"
            className={styles['toggle-carrier-onboarding-logo__label']}
          >
            {
              <FormattedMessage
                id="settings.logoUpload.carrierOnboardingLogoToggle"
                defaultMessage="Display this logo in carrier onboarding"
              />
            }
          </label>
        </div>
      </Fragment>
    );
  }
}

export default LogoUpload;
