import get from 'lodash/get';
import { FitBoundsOptions, Map as MapboxMap, GeoJSONSource } from 'mapbox-gl';
import geojson from 'geojson';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { Layer, Source, ZoomControl } from 'react-mapbox-gl';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { ShipmentETAStatusColor, ShipmentETAStatusEnum } from 'models';
import { primaryGrey500, white, primaryBlue } from 'styles/colors';
import { useMapboxMap, MapboxMapOptions } from 'hooks';
import ErrorMessage from 'components/common/errorBoundary/ErrorMessage/ErrorMessage';
import { trackEvent } from 'common/eventTracker';
import MapLegend from './MapLegend/MapLegend';
import MapPopup, { MapPopupShipmentData } from './MapPopup/MapPopup';
import { useLandingMapGeoJson } from './landingMapHooks';

import 'mapbox-gl/dist/mapbox-gl.css';

const defaultZoom: [number] = [1.5];

const mapFitBoundsOptions: FitBoundsOptions = {
  padding: 100,
  duration: 0,
};

const mapboxOptions: MapboxMapOptions = {
  minZoom: 1.5,
  scrollZoom: false,
};

const shipmentPointCircleColor = [
  'match',
  ['get', 'status'],
  ShipmentETAStatusEnum.EARLY,
  ShipmentETAStatusColor[ShipmentETAStatusEnum.EARLY],
  ShipmentETAStatusEnum.LATE,
  ShipmentETAStatusColor[ShipmentETAStatusEnum.LATE],
  ShipmentETAStatusEnum.ON_TIME,
  ShipmentETAStatusColor[ShipmentETAStatusEnum.ON_TIME],
  '#aba9a8',
];

const LandingMap: React.FC<WrappedComponentProps> = (props) => {
  const [geoJsonData, bounds, errorLoadingMapShipments] = useLandingMapGeoJson(false);
  const [Map, errorLoadingMapConfig] = useMapboxMap(mapboxOptions);
  const [mapInstance, setMapInstance] = useState<MapboxMap | undefined>(undefined);
  const [currentShipment, setCurrentShipment] = useState<MapPopupShipmentData | undefined>(undefined);

  const onMapLoad = useCallback((map: MapboxMap) => setMapInstance(map), [setMapInstance]);
  const onClusterClick = useCallback(
    (ev) => {
      if (mapInstance !== undefined) {
        setCurrentShipment(undefined);
        const features = mapInstance.queryRenderedFeatures(ev.point, {
          layers: ['clusters-inner-background'],
        });
        const clusterId = get(features[0], 'properties.cluster_id', undefined);
        if (clusterId === undefined) {
          return;
        }
        const source = mapInstance.getSource('shipments') as GeoJSONSource;
        source.getClusterExpansionZoom(clusterId, (err: any, zoom: number) => {
          if (err) {
            return;
          }
          mapInstance.easeTo({
            center: (features[0]?.geometry as geojson.Point)?.coordinates as [number, number],
            zoom,
          });
        });
      }
    },
    [mapInstance]
  );

  const onShipmentClick = useCallback(
    (ev) => {
      setCurrentShipment(ev.features[0].properties);
      if (mapInstance !== undefined) {
        mapInstance.easeTo({
          center: get(ev.features[0], 'geometry.coordinates'),
        });
      }
    },
    [mapInstance]
  );

  const onMapPopupClose = useCallback(() => {
    setCurrentShipment(undefined);
  }, [setCurrentShipment]);
  const onMouseEnterPoint = useCallback(() => {
    if (mapInstance !== undefined) {
      mapInstance.getCanvas().style.cursor = 'pointer';
    }
  }, [mapInstance]);

  const onMouseLeavePoint = useCallback(() => {
    if (mapInstance !== undefined) {
      mapInstance.getCanvas().style.cursor = '';
    }
  }, [mapInstance]);

  if (errorLoadingMapConfig || errorLoadingMapShipments) {
    return (
      <ErrorMessage
        message={props.intl.formatMessage({ id: 'landing.map.errorLoading', defaultMessage: 'Error loading map.' })}
      />
    );
  }

  return (
    <div style={{ width: '100%', height: '600px', backgroundColor: primaryBlue, position: 'relative' }}>
      {Map !== undefined && (
        <Map
          // eslint-disable-next-line react/style-prop-object
          style={'mapbox://styles/cmillerp44/cjtfztv5i0v7u1fljc6kwqmp6'}
          containerStyle={{
            width: '100%',
            height: '600px',
          }}
          onClick={() => {
            trackEvent('DASHBOARD_MAP_CLICK');
          }}
          zoom={defaultZoom}
          fitBounds={bounds}
          fitBoundsOptions={mapFitBoundsOptions}
          onStyleLoad={onMapLoad}
        >
          <>
            <MapLegend />
            {currentShipment !== undefined && <MapPopup shipmentData={currentShipment} onClose={onMapPopupClose} />}
            <ZoomControl zoomDiff={1.5} />
            <Source id="shipments" geoJsonSource={geoJsonData} />
            <Layer
              id="clusters-outer-background"
              type="circle"
              sourceId="shipments"
              filter={['has', 'point_count']}
              paint={{
                'circle-color': primaryGrey500,
                'circle-radius': ['step', ['get', 'point_count'], 22, 50, 30, 100, 40],
                'circle-opacity': 0.33,
              }}
              onClick={onClusterClick}
              onMouseEnter={onMouseEnterPoint}
              onMouseLeave={onMouseLeavePoint}
            />
            <Layer
              id="clusters-inner-background"
              type="circle"
              sourceId="shipments"
              filter={['has', 'point_count']}
              paint={{
                'circle-color': '#aba9a8',
                'circle-radius': ['step', ['get', 'point_count'], 12, 50, 20, 100, 30],
                'circle-stroke-color': white,
                'circle-stroke-width': 2,
              }}
            />
            <Layer
              id="cluster-count"
              type="symbol"
              sourceId="shipments"
              filter={['has', 'point_count']}
              layout={{
                'text-field': '{point_count_abbreviated}',
                'text-size': 12,
              }}
              paint={{
                'text-color': white,
              }}
            />
            <Layer
              id="unclustered-point-outer-background"
              type="circle"
              sourceId="shipments"
              filter={['!', ['has', 'point_count']]}
              paint={{
                'circle-color': shipmentPointCircleColor,
                'circle-radius': 12,
                'circle-opacity': 0.33,
              }}
              onMouseEnter={onMouseEnterPoint}
              onMouseLeave={onMouseLeavePoint}
              onClick={onShipmentClick}
            />
            <Layer
              id="unclustered-point-inner-background"
              type="circle"
              sourceId="shipments"
              filter={['!', ['has', 'point_count']]}
              paint={{
                'circle-color': shipmentPointCircleColor,
                'circle-radius': 5,
                'circle-stroke-width': 1,
                'circle-stroke-color': white,
              }}
            />
          </>
        </Map>
      )}
    </div>
  );
};

export default injectIntl(LandingMap);
