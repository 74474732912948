import Container from 'react-bootstrap/Container';

interface LegEventComponentContainerProps {
  className: string;
  children: JSX.Element | JSX.Element[];
  maxHeightData?: number;
  hide?: boolean;
  activeLeg?: boolean;
  isCollapsable?: boolean;
}

const LegEventComponentContainer = (props: LegEventComponentContainerProps) => {
  const { className, children, isCollapsable, hide } = props;
  if (isCollapsable) {
    return (
      <>
        <Container
          data-testid={hide ? 'collapsed-leg-event-component' : 'expanded-leg-event-component'}
          className={className}
        >
          {children}
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Container className={className}>{children}</Container>
      </>
    );
  }
};

export default LegEventComponentContainer;
