import * as React from 'react';
import classNames from 'classnames';

import { ColumnProps } from 'antd/lib/table';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { chunk } from 'common/chunkUtils';
import Table from 'common/Table/Table';
import * as styles from './SubLineItemsComponent.module.scss';
import { DataSource, LineItemDetailDef } from '../LineItems.model';
import { lineItemDetailDefs } from '../LineItems.constants';
import { conditionallyRemoveColumns } from '../ListItemsUtils';

const SubLineItemsComponent: React.FC<{
  lineItem: DataSource;
  columns: Array<ColumnProps<DataSource>>;
}> = ({ lineItem, columns }) => {
  function setRowClassname(record: DataSource, index: number) {
    return classNames({
      shaded: index % 2 === 0,
    });
  }
  const dataSource = !isNil(lineItem.subLineItems) ? lineItem.subLineItems : [];
  const columnsCopySub: Array<ColumnProps<DataSource>> = conditionallyRemoveColumns([...columns], dataSource);

  return (
    <Container className={styles.subLineItemsContainer}>
      <Row>
        {chunk(lineItemDetailDefs, 2).map((lineItemDetailDefsChunk: LineItemDetailDef[], index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col md={10} key={`chunk-${index}`} className={styles.lineItemDetail}>
            {lineItemDetailDefsChunk.map((lineItemDetailDef) => (
              <Row key={lineItemDetailDef.key} className={styles.lineItemDetail}>
                <Col md={12} sm={12} className={styles.label}>
                  <label>{lineItemDetailDef.label}</label>
                </Col>
                <Col md={12} sm={12} className={styles.value}>
                  {get(lineItem, lineItemDetailDef.key)}
                </Col>
              </Row>
            ))}
          </Col>
        ))}
      </Row>
      {dataSource.length > 0 && (
        <Table
          columns={columnsCopySub}
          pagination={false}
          dataSource={dataSource}
          rowClassName={setRowClassname}
          className={styles.sublineItemsTable}
          showSorterTooltip={false}
        />
      )}
    </Container>
  );
};

export default SubLineItemsComponent;
