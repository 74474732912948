import { put, takeLatest, call, all, takeEvery } from 'redux-saga/effects';
import { getPrincipalAuthoritiesFromState } from 'common/authorizations';
import axios from '../../../util/paxios';
import { API_PATH } from '../../../common/AppConstants';
import {
  CARRIER_FLEET_LIST,
  GET_NEXT_VEHICLES_PAGE,
  buildSetCarrierFleetSearchTerm,
  buildSetCarrierFleetAction,
  buildCarrierFleetSearchFailureAction,
  buildAddVehicleAction,
} from './carrierSearchReducer';

export function searchCarriers(action) {
  const { search, select, page } = action.searchQuery;
  return axios({
    method: 'GET',
    url: `${API_PATH}/carrier/fleet?identifierType=${select}&identifierValue=${search.toUpperCase()}&page=${page}`,
    withCredentials: true,
  });
}

export const getState = (state) => ({
  authorities: getPrincipalAuthoritiesFromState(state),
});

export function* searchCarrierFleetAsync(action) {
  try {
    let vehicles,
      totalVehicles,
      carrierResult,
      name,
      vehiclesCSV = [],
      response;
    response = yield call(searchCarriers, action);
    vehicles = response.data.truckloadAssetCollection.results || [];
    totalVehicles = response.data.truckloadAssetCollection.paginationInfo.total;
    carrierResult = response.data;
    name = response.data.capacityProviderDto.name;
    vehiclesCSV.push(...vehicles);

    while (vehiclesCSV.length < totalVehicles) {
      action.searchQuery.page++;
      response = yield call(searchCarriers, action);
      vehiclesCSV.push(...response.data.truckloadAssetCollection.results);
    }
    action.searchQuery.page = '1';

    yield all([
      put(buildSetCarrierFleetSearchTerm(action.carrierFleetSearchTerm)),
      put(buildSetCarrierFleetAction(vehicles, totalVehicles, carrierResult, vehicles, name, vehiclesCSV)),
    ]);
  } catch (e) {
    yield put(buildCarrierFleetSearchFailureAction(e));
  }
}

export function* getNextVehiclesPageAsync(action) {
  try {
    const response = yield call(searchCarriers, action);
    const vehicles = response.data.truckloadAssetCollection.results;
    yield put(buildAddVehicleAction(vehicles));
  } catch (e) {
    console.error(e);
    yield put(buildCarrierFleetSearchFailureAction(e));
  }
}

export function* watchSearchCarrierFleetAsync() {
  yield takeLatest(CARRIER_FLEET_LIST, searchCarrierFleetAsync);
}

export function* watchLoadMoreVehiclesAsync() {
  yield takeEvery(GET_NEXT_VEHICLES_PAGE, getNextVehiclesPageAsync);
}
