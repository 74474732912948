import styled from 'styled-components';
import { shade } from 'polished';
import { FormattedMessage, injectIntl } from 'react-intl';
import { addEditFormConstants } from '../../AddEditShipmentConstants';
import * as types from '../../ducks/types';
import { withTheme } from '../../../../contexts/ThemeContext';
import colors from '../../../../styles/colors';

const StepTriangle = styled.div`
  height: 0;
  position: absolute;
  right: 8px;
  top: 15px;
  transform: rotate(117deg);
  width: 0;
  z-index: 2;

  &:after {
    background-color: ${(props) => props.backgroundColor};
    box-shadow: -2px -2px 3px -2px rgba(0, 0, 0, 0.5);
    content: '';
    height: 15px;
    left: -11px;
    position: absolute;
    top: -14px;
    transform: skew(-37deg);
    width: 18px;
  }
`;

const AddEditHeader = (props) => {
  if (props.type === types.EDIT_SHIPMENT) {
    return (
      <h2 className="edit-shipment-title">
        <FormattedMessage
          id={addEditFormConstants[props.selectedStep.id].editTitle.id}
          defaultMessage={addEditFormConstants[props.selectedStep.id].editTitle.defaultMessage}
        />
      </h2>
    );
  } else {
    return (
      <div className="progress-wizard-header">
        <h2>{props.title}</h2>
        <div>
          {props.shipmentSteps.map((step, index) => {
            let backgroundColor = colors.primaryBackgroundColor;
            if (step.completed) {
              backgroundColor = shade(0.2)(props.theme.primaryColor);
            } else if (step.selected) {
              backgroundColor = props.theme.primaryColor;
            }
            return (
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={`plainWrapperButton progress-step ${step.selected ? 'selected' : ''} ${
                  step.completed ? 'completed' : ''
                }`}
                onClick={() => {
                  step.completed // eslint-disable-line no-unused-expressions
                    ? props.navigateStep(
                        types.NAVIGATE_STEP,
                        step.id,
                        props.formValues,
                        props.initialValues,
                        props.shipmentSteps
                      )
                    : null;
                }}
              >
                <span
                  style={{
                    backgroundColor: backgroundColor,
                  }}
                >
                  <span className="step-title">
                    <FormattedMessage
                      id={addEditFormConstants[step.id].title?.id}
                      defaultMessage={addEditFormConstants[step.id].title?.defaultMessage}
                    />
                  </span>
                  <StepTriangle backgroundColor={backgroundColor} />
                </span>
              </button>
            );
          })}
        </div>
      </div>
    );
  }
};

export default withTheme(injectIntl(AddEditHeader));
