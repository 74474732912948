import './RouteSummary.scss';
import { Row, Col } from 'ui-components';
import RouteDetail from './RouteDetail/RouteDetail';

const RouteSummary = (props) => {
  return (
    <Row type="flex" align="middle" className="shipment-route__summary animated fadeIn">
      <Col sm={10} xs={24}>
        {props.routeSummary.origin && <RouteDetail routeInfo={props.routeSummary.origin} />}
      </Col>
      <Col sm={0} xs={4} className="shipment-route__line">
        <div className="vertical-line-dotted" />
        <div className="vertical-line-dotted" />
      </Col>
      <Col sm={4} xs={20}>
        <div className="shipment-route__stop-count">
          {props.routeSummary.stopCountText}
          <span className="route-connect-arrow">
            <hr />
            &#9658;
          </span>
        </div>
      </Col>
      <Col sm={10} xs={24}>
        {props.routeSummary.destination && <RouteDetail routeInfo={props.routeSummary.destination} />}
      </Col>
    </Row>
  );
};

export default RouteSummary;
