import * as colors from 'styles/colors';

export enum ShipmentETAStatusEnum {
  ON_TIME = 'ON_TIME',
  EARLY = 'EARLY',
  DELAYED = 'DELAYED',
  LATE = 'LATE',
  UNKNOWN = 'UNKNOWN',
  INACTIVE = 'INACTIVE',
}

export const ShipmentETAStatusColor: { [P in ShipmentETAStatusEnum]: string } = {
  [ShipmentETAStatusEnum.ON_TIME]: colors.green,
  [ShipmentETAStatusEnum.EARLY]: colors.primaryBlueSeventyFive,
  [ShipmentETAStatusEnum.DELAYED]: colors.newFruit,
  [ShipmentETAStatusEnum.LATE]: colors.newFruit,
  [ShipmentETAStatusEnum.UNKNOWN]: colors.primaryGreyForty,
  [ShipmentETAStatusEnum.INACTIVE]: colors.primaryGreyForty,
};

const message = {
  etaStatusOnTime: {
    id: 'shipmentList.listCardStatusFlagText.onTime',
    defaultMessage: 'On Time',
  },
  etaStatusEarly: {
    id: 'shipmentList.listCardStatusFlagText.early',
    defaultMessage: 'Early',
  },
  etaStatusLate: {
    id: 'shipmentList.listCardStatusFlagText.late',
    defaultMessage: 'Late',
  },
  etaStatusDelayed: {
    id: 'shipmentList.listCardStatusFlagText.delayed',
    defaultMessage: 'Delayed',
  },
  etaStatusUnknown: {
    id: 'shipmentList.listCardStatusFlagText.unknown',
    defaultMessage: 'Tracking',
  },
  etaStatusInactive: {
    id: 'shipmentList.listCardStatusFlagText.inactive',
    defaultMessage: 'Inactive',
  },
};

export const ShipmentETAStatusIntlKeys: {
  [P in ShipmentETAStatusEnum]: { id: string; defaultMessage: string };
} = {
  [ShipmentETAStatusEnum.ON_TIME]: message.etaStatusOnTime,
  [ShipmentETAStatusEnum.EARLY]: message.etaStatusEarly,
  [ShipmentETAStatusEnum.LATE]: message.etaStatusLate,
  [ShipmentETAStatusEnum.DELAYED]: message.etaStatusDelayed,
  [ShipmentETAStatusEnum.UNKNOWN]: message.etaStatusUnknown,
  [ShipmentETAStatusEnum.INACTIVE]: message.etaStatusInactive,
};
