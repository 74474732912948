import * as FullStory from '@fullstory/browser';
import { environmentName } from 'common/AppConstants';
import { getBrowserZoom } from 'common/browser';
import { PrincipalResponse } from 'contexts/PrincipalContext';
import { CarrierNetworkRoles } from 'models/CarrierNetworkRoles';

const windowEl = window as any;

type Region = 'NA' | 'EU';

export function getRegion(): Region | undefined {
  switch (environmentName) {
    case 'prod-na':
    case 'qa-int':
    case 'qa-stg':
    case 'sandbox':
      return 'NA';
    case 'prod-eu':
      return 'EU';
  }
}

const logTrackEventsKey = 'logTrackEvents';

export interface Properties {
  customerProperties: {
    [key: string]: string | number | boolean | null | undefined;
  };
  authorities: Array<{
    authority: string;
  }>;
  userId: string;
  p44TenantId: string;
  username: string;
  TenantCategory: string;
  firstName: string;
  lastName: string;
}

/**
 * Similarly to MWA, this contains all the event names, except these are for the
 * whole portal-v2-ui.
 *
 * Event names must be in camel case following this format:
 * <Application Name>.<Category>.<Action>
 */
export enum AnalyticEvent {
  // Onboarding events - Following the same format as in MWA
  // https://github.com/project44/micro-webapps/blob/master/apps/carrier-registration-ui/src/analytics.ts#L10
  onboardingBussinesTypeSelected = 'carrierRegistration.businessType.selected',
  onboardingTelematicsProviderSelected = 'carrierRegistration.telematicsProvider.selected',
  onboardingTelematicsProviderNotAvailable = 'carrierRegistration.telematicsProvider.notAvailable',
  onboardingTelematicsProviderRequestNew = 'carrierRegistration.telematicsProvider.requestNew',
  onboardingTelematicsProviderConnectAnotherWay = 'carrierRegistration.telematicsProvider.connectAnotherWay',
  onboardingTelematicsProviderConnectFormSubmitted = 'carrierRegistration.telematicsProvider.connectFormSubmitted',
  onboardingTelematicsProviderConnectViaMobile = 'carrierRegistration.telematicsProvider.connectViaMobile',
  onboardingContractSigned = 'carrierRegistration.contract.signed',
  onboardingCompleteDone = 'carrierRegistration.complete.done',
  onboardingCompleteExploreP44 = 'carrierRegistration.complete.exploreP44',
  onboardingCompleteAddAnother = 'carrierRegistration.complete.addAnother',
  onboardingCompleteConnectSubcontractor = 'carrierRegistration.complete.connectSubcontractor',
}

export type AnalyticsProperties = Record<string, string | number | boolean | string[] | number[]>;

export function onUserLogin(user: PrincipalResponse) {
  const userId = `${user.id}`;
  const userEmail = user.username;
  const fullName = [user.firstName, user.lastName].join(' ');

  const baseProperties = {
    tenantName: user.companyName,
    tenantUuid: user.tenantUuid,
    tenantIsShipper: user.tenantCarrierNetworkRoles?.includes(CarrierNetworkRoles.SHIPPER),
    tenantIsCarrier: user.tenantCarrierNetworkRoles?.includes(CarrierNetworkRoles.CARRIER),
  };

  window.DD_RUM?.setUser({
    id: userId,
    email: userEmail,
    name: fullName,
    ...baseProperties,
  });
  window.mixpanel?.identify(userEmail);
  window.mixpanel?.people.set({
    $email: userEmail,
    $name: fullName,
    ...baseProperties,
  });

  const networkRole = (user.tenantCarrierNetworkRoles || []).join(',');

  if (windowEl.portalGlobal?.fullStoryOrgId !== '') {
    FullStory.identify(user.username, {
      email: user.username,
      tenantId: user.tenantUuid,
      tenantName: user.companyName,
      displayName: fullName,
      networkRole,
    });
  }

  if (windowEl.portalGlobal?.segmentWriteKey) {
    // want to identify by email since different systems have different user IDs
    windowEl.analytics?.identify(user.username, {
      userId: userId,
      email: user.username,
      tenantId: user.tenantUuid,
      tenantName: user.companyName,
      displayName: fullName,
      networkRole,
    });

    windowEl.analytics?.group(user.tenantUuid, {
      name: user.companyName,
      networkRole,
    });
  }
}

export function onUserLogout() {
  window.mixpanel?.reset();
  window.DD_RUM?.clearUser();

  if (windowEl.portalGlobal?.fullStoryOrgId) {
    FullStory.anonymize();
  }

  if (windowEl.portalGlobal?.segmentWriteKey) {
    windowEl.analytics?.reset();
  }
  window.localStorage.setItem('hideMovementBannerPreview', '');
}

export async function initializeTracker() {
  try {
    const { mixpanelToken } = windowEl.portalGlobal;
    if (mixpanelToken) {
      const { default: mixpanel } = await import('mixpanel-browser');
      mixpanel.init(mixpanelToken, {
        persistence: 'localStorage',
      });
      window.mixpanel = mixpanel;
    }

    const { fullStoryOrgId } = windowEl.portalGlobal || {};

    if (fullStoryOrgId) {
      FullStory.init({ orgId: fullStoryOrgId });
    }
  } catch (error) {
    console.error(error);
    console.error('Error Initializing FullStory');
  }
}

export function trackPageView(page: string) {
  try {
    const composedProperties = composeProperties();
    window.mixpanel?.track_pageview(composedProperties);
    window.DD_RUM?.startView({
      name: page,
      service: 'portal-v2-ui',
      version: process.env.REACT_APP_APPLICATION_VERSION?.replace('-SNAPSHOT', ''),
    });

    if (windowEl.portalGlobal?.segmentWriteKey) {
      windowEl.analytics?.page();
    }
  } catch (error) {
    console.error(error);
  }
}

export function getSessionReplayUrl(now: boolean = true) {
  const urlStr = window.DD_RUM?.getSessionReplayLink();
  if (!urlStr) {
    return;
  }
  const url = new URL(urlStr);
  // Original URL points to the wrong domain
  url.hostname = 'p44.datadoghq.com';
  if (now) {
    url.searchParams.set('from', `${Date.now()}`);
  }
  return `${url}`;
}

export function cleanProperties(properties: Partial<AnalyticsProperties>): AnalyticsProperties {
  const cleanProperties: AnalyticsProperties = {};

  for (const [key, value] of Object.entries(properties)) {
    if (value != null) {
      cleanProperties[key] = value;
    }
  }

  return cleanProperties;
}

export function composeProperties(properties?: Partial<AnalyticsProperties>) {
  return cleanProperties({
    ...properties,
    application: 'portal-v2-ui',
    platform: 'VOC',
    region: getRegion(),
    sessionReplay: getSessionReplayUrl(),
    pinchZoom: Math.round((window.visualViewport?.scale || 1) * 100),
    browserZoom: getBrowserZoom(),
    localeBrowser: (window.navigator.languages || [window.navigator.language]).find(Boolean),
    localeUsed: window.document.documentElement.lang,
  });
}

/**
 * New tracking function that ensures event names are matching company standard
 */
export function track(eventName: AnalyticEvent, properties?: Partial<AnalyticsProperties>) {
  try {
    const composedProperties = composeProperties(properties);
    window.mixpanel?.track(eventName, composedProperties);
    window.DD_RUM?.addAction(eventName, composedProperties);
  } catch (error) {
    console.error('Error tracking event:', error);
  }
}

/**
 * New tracking function that ensures event names are matching company standard and returns a promise
 */
export function trackImmediate(
  eventName: AnalyticEvent,
  properties?: Partial<AnalyticsProperties>,
  timeout: number = 1000
) {
  // get_config is undefined when mixpanel is blocked, track function itself is always defined
  if (!window.mixpanel?.get_config || !window.mixpanel?.track) {
    return Promise.resolve(false);
  }

  const trackPromise: Promise<boolean> = new Promise((resolve) => {
    try {
      const composedProperties = composeProperties(properties);
      window.DD_RUM?.addAction(eventName, composedProperties);
      window.mixpanel?.track(eventName, composedProperties, { send_immediately: true }, (response) => {
        if (response === 1) {
          // https://github.com/mixpanel/mixpanel-js/blob/master/src/mixpanel-core.js#L546
          resolve(true);
        } else {
          // response payload states event was not successfully queued up by Mixpanel's API
          // https://github.com/mixpanel/mixpanel-js/issues/250#issuecomment-595876911
          console.warn('[Mixpanel] trackImmediate not queued');
          resolve(false);
        }
      });
    } catch (error) {
      console.error('Error tracking event:', error);
      resolve(false);
    }
  });

  const timeoutPromise: Promise<boolean> = new Promise((resolve) => {
    setTimeout(() => {
      console.warn('Timeout tracking event');
      resolve(false);
    }, timeout);
  });

  return Promise.race([trackPromise, timeoutPromise]);
}

/**
 * @deprecated Use {@link track} instead
 */
/* eslint-disable no-console */
export const trackEvent = (action: string, params?: { [key: string]: string | number | boolean }) => {
  // This allows developers to log events to console in production by setting logTrackEvents = true in localStorage
  const logTrackLocalStorage = localStorage.getItem(logTrackEventsKey);
  if (logTrackLocalStorage && logTrackLocalStorage === 'true') {
    console.groupCollapsed(`%c ${action}`, 'background: #FCF6B1; color: #2D1E2F');
    // eslint-disable-next-line
    if (params) console.log(JSON.stringify(params, null, 2));
    console.groupEnd();
  }

  try {
    if (windowEl.portalGlobal?.segmentWriteKey) {
      windowEl.analytics?.track(action, params);
    }
  } catch (error) {
    console.error(error);
  }
};
/* eslint-enable no-console */
