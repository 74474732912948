import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { Settings, BookIcon } from 'ui-components';
import { PrincipalContext } from 'contexts/PrincipalContext';
import matrixIcon from '../../assets/matrix-gray.svg';
import endpoints from '../../../.././common/endpoints';
import { getApiDocUrl } from '../../../../common/newApiDocUrls';
import { withTheme } from '../../../../contexts/ThemeContext';
import colors from '../../../../styles/colors';

import './AppMenu.scss';

const ActionWindowItem = styled.div`
  path {
    fill: ${colors.darkBackgroundColor};
  }

  .icon-text {
    color: ${colors.primaryTextColor};
  }

  &:hover {
    path {
      fill: ${(props) => props.theme.primaryColor};
    }
    .icon-text {
      color: ${(props) => props.theme.primaryColor};
    }
  }
`;

const getAppMenuWindow = (props, principalContext) => {
  const apiDocUrl = getApiDocUrl(principalContext);
  if (props.currentMenuState) {
    return (
      <div className="action-window">
        {props.hasCarrierRole ? null : (
          <ActionWindowItem theme={props.theme}>
            <Link
              to={endpoints.LOCATION_LIST}
              className="action-window-item"
              onClick={props.onLocationsClick}
              data-locator="app-menu-locations"
            >
              <svg
                className="icon-attributes"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    fill="#00558B"
                    fillRule="nonzero"
                    d="M12 11.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zM12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7z"
                  />
                </g>
              </svg>
              <span className="icon-text">
                <FormattedMessage id="header.appMenu.locations" defaultMessage="Locations" />
              </span>
            </Link>
          </ActionWindowItem>
        )}
        <ActionWindowItem theme={props.theme}>
          <a
            href={endpoints.USERS}
            target="_blank"
            rel="noopener noreferrer"
            className="action-window-item"
            onClick={props.onUsersClick}
            data-locator="app-menu-users"
          >
            <svg
              className="icon-attributes"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fillRule="evenodd">
                <path
                  fill="#00558B"
                  fillRule="nonzero"
                  d="M12 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM6 7.17a2.5 2.5 0 0 0 0 5c.88 0 1.65-.46 2.09-1.14C7.42 10.18 7 9.15 7 8c0-.2 0-.4.04-.6-.32-.15-.67-.23-1.04-.23zm12 0c-.37 0-.72.08-1.04.23.04.2.04.4.04.6 0 1.15-.42 2.18-1.09 3.03a2.49 2.49 0 0 0 3.858.408A2.5 2.5 0 0 0 18 7.17zM12 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3zm-7.33.97C3 14.26 1 15.04 1 16.33V18h3v-2c0-.78.29-1.47.67-2.03zm14.66 0c.38.56.67 1.25.67 2.03v2h3v-1.67c0-1.29-2-2.07-3.67-2.36z"
                />
              </g>
            </svg>
            <span className="icon-text">
              <FormattedMessage id="header.appMenu.users" defaultMessage="Users" />
            </span>
          </a>
        </ActionWindowItem>
        {props.authorizations.hasTenantLevelNotifications() && (
          <ActionWindowItem theme={props.theme}>
            <Link to={endpoints.NOTIFICATIONS} className="action-window-item" onClick={props.onNotificationsClick}>
              <svg
                className="icon-attributes"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    fill="#00558B"
                    fillRule="nonzero"
                    d="M10 21a2 2 0 1 0 4 0m4.88-4.18V11c0-3.25-2.25-5.97-5.29-6.69v-.72a1.59 1.59 0 1 0-3.18 0v.72A6.873 6.873 0 0 0 5.12 11v5.82L3 18.94V20h18v-1.06M16 13h-3v3h-2v-3H8v-2h3V8h2v3h3"
                  />
                </g>
              </svg>
              <span className="icon-text" data-locator="notificationsMenuButton">
                <FormattedMessage id="header.appMenu.notifications" defaultMessage="Notifications" />
              </span>
            </Link>
          </ActionWindowItem>
        )}
        {props.hasCarrierRole ? null : (
          <ActionWindowItem theme={props.theme}>
            <a
              href={endpoints.CARRIERS}
              target="_blank"
              rel="noopener noreferrer"
              className="action-window-item"
              onClick={props.onCarriersClick}
              data-locator="app-menu-carriers"
            >
              <svg
                className="icon-attributes"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    fill="#00558B"
                    fillRule="nonzero"
                    d="M18 18.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1.5-9l1.96 2.5H17V9.5h2.5zM6 18.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM20 8h-3V4H3c-1.11 0-2 .89-2 2v11h2a3 3 0 0 0 6 0h6a3 3 0 0 0 6 0h2v-5l-3-4z"
                  />
                </g>
              </svg>
              <span className="icon-text">
                <FormattedMessage id="header.appMenu.carriers" defaultMessage="Carriers" />
              </span>
            </a>
          </ActionWindowItem>
        )}
        {props.authorizations.hasEditCustomBrandingAuthorization() && (
          <ActionWindowItem theme={props.theme}>
            <Link to={endpoints.SETTINGS} className="action-window-item" data-locator="app-menu-settings">
              <Settings className="icon-attributes" />
              <span className="icon-text">
                <FormattedMessage id="header.appMenu.settings" defaultMessage="Settings" />
              </span>
            </Link>
          </ActionWindowItem>
        )}
        {props.hasCarrierRole ? null : (
          <ActionWindowItem theme={props.theme}>
            <a
              href={apiDocUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="action-window-item"
              data-locator="app-menu-apidocs"
            >
              <BookIcon className="icon-attributes" />
              <span className="icon-text">
                <FormattedMessage id="header.appMenu.apiDocs" defaultMessage="Developer Portal" />
              </span>
            </a>
          </ActionWindowItem>
        )}
      </div>
    );
  }
};

const messages = defineMessages({
  appMenuAlt: {
    id: 'header.appMenu.appMenuAlt',
    defaultMessage: 'App Menu',
  },
});

/**
 * AppMenu component
 */
function AppMenu(props) {
  const onAppMenuToggleClick = () => {
    props.onAppMenuToggleClick(!props.currentMenuState);
  };
  const principalContext = useContext(PrincipalContext);

  return (
    <div className="app-menu-container">
      <button className="plainWrapperButton" onClick={onAppMenuToggleClick}>
        <img
          src={matrixIcon}
          className="app-menu-icon"
          data-locator="app-menu-button"
          alt={props.intl.formatMessage(messages.appMenuAlt)}
        />
        {getAppMenuWindow(props, principalContext)}
      </button>
    </div>
  );
}

export const AppMenuComponent = AppMenu;
export default injectIntl(withTheme(AppMenu));
