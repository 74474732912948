import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, WrappedComponentProps, defineMessages } from 'react-intl';
import get from 'lodash/get';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { Label, Input } from 'components/common/forms/FormElements';
import FilterComponent from 'components/filter/FilterComponent';
import { trackEvent } from 'common/eventTracker';

import './SearchComponent.scss';
import { PrincipalContext } from 'contexts/PrincipalContext';
import { withTheme, WithThemeProps } from '../../../contexts/ThemeContext';
import { getPrincipalAuthorizations } from '../../../common/authorizations';
import AutocompleteFilter from '../../common/autocompleteFilter/AutocompleteFilter';

const messages = defineMessages({
  filterByAnyContact: {
    id: 'locationList.contactFilter.filterByAnyContact',
    defaultMessage: 'Filter by Any Contact',
  },
  filterByAnyLocation: {
    id: 'locationListComponent.refineSection.placeholders.locationInput',
    defaultMessage: 'Filter by any location',
  },
});
interface SearchComponentProps extends WrappedComponentProps {
  executeSearchFn: any;
  executeLocationSearchFn: any;
  searchConfig: any;
  sortFields: any;
  setSearchTerm: (value: string) => void;
  setContactSearchTerm: (value: string) => void;
  setStateSearchTerm: any;
  setStateContactSearchTerm: any;
  suggestedLocations: any;
  setCurrentValue: (value: string) => void;
  listData: any;
  handleAutocompleteUpdate: (filter: string, filterItemGroup: string) => void;
  toggleShowSelections: (value: string) => void;
}

const StyledInput = styled(Input)`
  .ant-input-group-addon {
    border: 0 none !important;
  }
`;

const SearchComponent: React.FC<SearchComponentProps> = (props) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [contactSearchTerm, setContactSearchTerm] = React.useState('');

  const principalContext = React.useContext(PrincipalContext);
  const principalAuthorizations = getPrincipalAuthorizations(principalContext);
  const handleSearchEnterKey = (value: string) => {
    if (props.searchConfig.name === 'Locations') {
      props.executeLocationSearchFn(props.searchConfig, searchTerm, value, props.sortFields);
    } else {
      props.executeSearchFn(props.searchConfig, value, props.sortFields);
    }
    props.setStateSearchTerm(searchTerm);
    props.setStateContactSearchTerm(contactSearchTerm);
  };

  const handleSearchTermChange = (event: React.BaseSyntheticEvent) => {
    setSearchTerm(event.target.value);
    props.setSearchTerm(event.target.value);
  };

  const handleContactSearchTermChange = (event: React.BaseSyntheticEvent) => {
    setContactSearchTerm(event.target.value);
    props.setContactSearchTerm(event.target.value);
  };

  const handleApplyFilter = () => {
    trackEvent('LOCATIONS_LIST_APPLY_FILTERS_CLICK');
    props.executeLocationSearchFn(props.searchConfig, searchTerm, contactSearchTerm, props.sortFields);
    props.setSearchTerm(searchTerm);
    props.setContactSearchTerm(contactSearchTerm);
    props.setStateSearchTerm(searchTerm);
  };

  const customClearFiltersFn = () => {
    trackEvent('LOCATIONS_LIST_CLEAR_FILTERS_CLICK');
    props.setStateSearchTerm('');
    props.setStateContactSearchTerm('');
    setSearchTerm('');
    setContactSearchTerm('');
    handleSearchEnterKey('');
  };

  const renderContactFilter = () => {
    return (
      <>
        {principalAuthorizations.localtionContactFilterTypeAheadEnabled() ? (
          <div>
            <Label inputid="contact-filter">
              <FormattedMessage defaultMessage="Contact" id={'contactListComponent.refineSection.labels.contact'} />
            </Label>
            <AutocompleteFilter
              idProp="contact-input-filter"
              placeholder={props.intl.formatMessage(messages.filterByAnyContact)}
              dataSource={props.suggestedLocations}
              updateFn={(inputValue: any) => props.setCurrentValue(inputValue)}
              newRequestFn={(filterItem: any) => {
                handleSearchEnterKey(filterItem ?? contactSearchTerm);
                props.handleAutocompleteUpdate(filterItem, 'contact');
              }}
              listData={props.listData}
              showAllChips
              passedInDeleteFn={(filterItem: any) => props.handleAutocompleteUpdate(filterItem, 'contact')}
              initialAmountVisible={3}
              showHideAllFn={() => props.toggleShowSelections('Contact')}
              dataLocator="contact-filter"
            />
          </div>
        ) : (
          <div>
            <Label inputid="contact-filter">
              <FormattedMessage defaultMessage="Contact" id={'contactListComponent.refineSection.labels.contact'} />
            </Label>
            <StyledInput
              className="borderlessInput"
              onSearch={(value: string) => {
                trackEvent('LOCATIONS_LIST_ADD_FILTER', { key: 'contact', value });
                handleSearchEnterKey(value ?? contactSearchTerm);
              }}
              onChange={handleContactSearchTermChange}
              placeholder={props.intl.formatMessage(messages.filterByAnyContact)}
              value={contactSearchTerm}
              id="contact-filter"
              custom={{ id: 'search' }}
              search="contact-search"
            />
          </div>
        )}
      </>
    );
  };

  return (
    <FilterComponent
      customApplyFilterFn={handleApplyFilter}
      customClearFiltersFn={customClearFiltersFn}
      hideFooter
      authorities={get(principalContext, 'authorities')}
    >
      <Row>
        <Col>
          <Label inputid="location-filter">
            <FormattedMessage defaultMessage="Location" id={'locationListComponent.refineSection.labels.location'} />
          </Label>
          <StyledInput
            className="borderlessInput mb-4"
            onSearch={(value: string) => {
              trackEvent('LOCATIONS_LIST_ADD_FILTER', { key: 'location', value });
              handleApplyFilter();
            }}
            onChange={handleSearchTermChange}
            placeholder={props.intl.formatMessage(messages.filterByAnyLocation)}
            value={searchTerm}
            id="location-filter"
            custom={{ id: 'search' }}
            search="location-search"
          />
          {principalAuthorizations.hasEnableLocationSearchByContact() && renderContactFilter()}
        </Col>
      </Row>
    </FilterComponent>
  );
};

SearchComponent.propTypes = {
  setSearchTerm: PropTypes.func.isRequired,
  setContactSearchTerm: PropTypes.func.isRequired,
  executeSearchFn: PropTypes.func.isRequired,
  searchConfig: PropTypes.object.isRequired,
};

export default injectIntl<'intl', SearchComponentProps>(
  withTheme<SearchComponentProps & WithThemeProps>(SearchComponent)
);
