import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { DropzoneRenderArgs } from 'react-dropzone';
import { Button } from 'ui-components';

import * as styles from './UploadArea.module.scss';
import { withTheme, WithThemeProps } from '../../../../../contexts/ThemeContext';

const UploadArea: FunctionComponent<DropzoneRenderArgs & WithThemeProps> = ({
  getRootProps,
  getInputProps,
  open,
  isDragActive,
  isDragAccept,
  isDragReject,
  theme,
}) => {
  return (
    <div
      {...getRootProps()}
      id={`dropzone${isDragAccept ? '_dragAccept' : ''}`}
      className={classNames(styles.dropzone, {
        [styles.dragAccept]: isDragAccept,
      })}
    >
      <input {...getInputProps()} />
      <div
        id={`uploadWrapper${isDragActive ? '_fadeAway' : isDragReject ? '_hide' : ''}`}
        className={classNames(styles.uploadWrapper, {
          [styles.fadeAway]: isDragActive,
          [styles.hide]: isDragReject,
        })}
      >
        <Button type="primary" clickFn={open} theme={theme}>
          <FormattedMessage id="settings.uploadArea.uploadALogo" defaultMessage="Upload a Logo" />
        </Button>
      </div>
      {isDragReject && (
        <div id="uploadErrorText" className={styles.uploadErrorText}>
          Logo file should be PNG (recommended), JPEG, SVG, or GIF.
        </div>
      )}
    </div>
  );
};

export const UploadAreaComponent = UploadArea;
export default withTheme(UploadArea);
