import * as types from './types';

export const initialState = {
  isLoggedIn: false,
  /** @type {import('contexts/PrincipalContext').PrincipalContextProps} */
  principal: {},
  ssoErrorRedirect: false,
  tenantUuidChangedSinceLastRefresh: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.SESSION_RENEW_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        principal: action.principal,
        tenantUuidChangedSinceLastRefresh: state.principal.tenantUuid !== action.principal.tenantUuid,
      };

    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        tenantUuidChangedSinceLastRefresh: false,
      };

    case types.SESSION_FAILURE:
      return initialState;

    case types.SET_CUSTOMER_PROPERTIES:
      return {
        ...state,
        principal: {
          ...state.principal,
          customerProperties: action.payload,
        },
        tenantUuidChangedSinceLastRefresh: false,
      };

    default:
      return state;
  }
}
