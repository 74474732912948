import find from 'lodash/find';
import set from 'lodash/set';
import { prepareRouteDetails } from './mappingUtils';

const StopTypes = {
  ORIGIN: 'ORIGIN',
  DESTINATION: 'DESTINATION',
};

export const mapRouteDetails = (shipment, routeDetailsModel) => {
  const stops = getShipmentStopsWithInsertedMissingOrigin(shipment.shipmentStops);

  const preparedRoute = prepareRouteDetails(
    shipment.allStatusUpdates,
    stops,
    shipment.latestStopStatuses,
    shipment.allHoldUpdates
  );

  return {
    ...routeDetailsModel,
    routeDetails: preparedRoute,
    mode: shipment.mode,
  };
};

const getShipmentStopsWithInsertedMissingOrigin = (originalStops) => {
  const stops = originalStops ? [...originalStops] : [];

  if (!find(stops, stopMatcher(StopTypes.ORIGIN))) {
    stops.unshift(createOriginStop());
  }
  if (!find(stops, stopMatcher(StopTypes.DESTINATION))) {
    stops.push(createDestinationStop());
  }

  return stops;
};

const stopMatcher = (stopType) => (stop) => stop.stopType === stopType;

const createOriginStop = () => createDefaultStop(StopTypes.ORIGIN, 'Port of Loading');
const createDestinationStop = () => createDefaultStop(StopTypes.DESTINATION, 'Port of Discharge');

// The card displays the cityState as the label so insert the name as such
const createDefaultStop = (stopType, name) => set({ stopType }, 'location.contactDto.companyName', name);
