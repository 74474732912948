import * as moment from 'moment';
import get from 'lodash/get';
import { createIntl, createIntlCache, defineMessages } from 'react-intl';
import { locale, messages } from 'i18n/configurei18n';
import { CA_STATES_SELECTION, MX_STATES_SELECTION, US_STATES_SELECTION } from './parameters/states';
import { EU_COUNTRIES, NA_COUNTRIES } from './parameters/countries';

export let SERVER_URL = process.env.REACT_APP_LOCAL_RUN
  ? 'http://localhost:9119'
  : get(window, 'portalGlobal.baseAPIUrl', '');
export const APP_VERSION = get(window, 'portalGlobal.appVersion', '0.0.0');

if (process.env.REACT_APP_IS_AMPLIFY) {
  SERVER_URL = 'https://na12.api.qa-integration.p-44.com';
}

const ONBOARDING_SERVICE = process.env.REACT_APP_LOCAL_RUN
  ? 'http://localhost:9119'
  : get(window, 'portalGlobal.onboardingServiceUrl');

export const ONBOARDING_SERVICE_API_PATH = ONBOARDING_SERVICE + '/api/v1';

export const API_PATH = SERVER_URL + '/api/portal/v2';
// export const API_PATH = SERVER_URL + '/local/api/portal/v2';
// export const API_PATH = SERVER_URL + '/qa-stage/api/portal/v2';
// export const API_PATH = SERVER_URL + '/sandbox/api/portal/v2';
// export const API_PATH = SERVER_URL + '/production/api/portal/v2';

export const OKTA_URL = get(window, 'portalGlobal.oktaUrl', '');

export const MICRO_WEBAPP_BASE_URL = get(window, 'portalGlobal.microWebappsBaseUiUrl', '');

export const MOVEMENT_BASE_UI_URL = get(window, 'portalGlobal.movementBaseUiUrl', '');

export const LOCAL_PATH = window.location.origin;

export const environmentName = get(window, 'portalGlobal.environmentName', '');

export const MAP_MAX_NUMBER_PINGS = parseInt(get(window, 'portalGlobal.mapMaxNumberPings', '200'));

export const DOCUMENTS_MAX_FILE_SIZE = 1048576 * 10;

export const SEARCH_RESULTS_PER_PAGE = 25;
const cache = createIntlCache();
export const intl = createIntl({ locale, messages }, cache);

export const HOURS_MINUTES_AM_PM_FORMAT = 'h:mm A';

const message = defineMessages({
  constSessionMessage: {
    id: 'timeOut.SessionDialogContent.constSessionMessage',
    defaultMessage: `It looks like you've been inactive for a while. For security
     reasons, you will be logged out in`,
  },
  mon: {
    id: 'locationDetails.appConstantsDaysOfWeek.mon',
    defaultMessage: 'Mon',
  },
  monday: {
    id: 'locationDetails.appConstantsDaysOfWeek.monday',
    defaultMessage: 'Monday',
  },
  tue: {
    id: 'locationDetails.appConstantsDaysOfWeek.tue',
    defaultMessage: 'Tue',
  },
  tuesday: {
    id: 'locationDetails.appConstantsDaysOfWeek.tuesday',
    defaultMessage: 'Tuesday',
  },
  wed: {
    id: 'locationDetails.appConstantsDaysOfWeek.wed',
    defaultMessage: 'Wed',
  },
  wednesday: {
    id: 'locationDetails.appConstantsDaysOfWeek.wednesday',
    defaultMessage: 'Wednesday',
  },
  thu: {
    id: 'locationDetails.appConstantsDaysOfWeek.thu',
    defaultMessage: 'Thu',
  },
  thursday: {
    id: 'locationDetails.appConstantsDaysOfWeek.thursday',
    defaultMessage: 'Thursday',
  },
  fri: {
    id: 'locationDetails.appConstantsDaysOfWeek.fri',
    defaultMessage: 'Fri',
  },
  friday: {
    id: 'locationDetails.appConstantsDaysOfWeek.friday',
    defaultMessage: 'Friday',
  },
  sat: {
    id: 'locationDetails.appConstantsDaysOfWeek.sat',
    defaultMessage: 'Sat',
  },
  saturday: {
    id: 'locationDetails.appConstantsDaysOfWeek.saturday',
    defaultMessage: 'Saturday',
  },
  sun: {
    id: 'locationDetails.appConstantsDaysOfWeek.sun',
    defaultMessage: 'Sun',
  },
  sunday: {
    id: 'locationDetails.appConstantsDaysOfWeek.sunday',
    defaultMessage: 'Sunday',
  },
});

export const STATES_SELECTION = [...US_STATES_SELECTION, ...CA_STATES_SELECTION, ...MX_STATES_SELECTION];

export { NA_COUNTRIES as COUNTRIES_SELECTION };

export const COUNTRIES_USE_MILES = ['US', 'MX', 'CA'];

export { EU_COUNTRIES as EU_COUNTRIES_SELECTION };

export const LONG_DAYS_OF_WEEK = [
  message.sunday,
  message.monday,
  message.tuesday,
  message.wednesday,
  message.thursday,
  message.friday,
  message.saturday,
];

export const DAYS_OF_WEEK = [
  {
    labelText: message.mon,
    id: 'openMonday',
  },
  {
    labelText: message.tue,
    id: 'openTuesday',
  },
  {
    labelText: message.wed,
    id: 'openWednesday',
  },
  {
    labelText: message.thu,
    id: 'openThursday',
  },
  {
    labelText: message.fri,
    id: 'openFriday',
  },
  {
    labelText: message.sat,
    id: 'openSaturday',
  },
  {
    labelText: message.sun,
    id: 'openSunday',
  },
];

export const ASCENDING = 'ASC';
export const DESCENDING = 'DESC';

export const TEMPERATURE_SCALE = {
  CELSIUS: 'C',
  FAHRENHEIT: 'F',
};

export const LOGIN_MESSAGE_DURATION = 500;

export const SNACKBAR_AUTOHIDE_DURATION = 3000;

export const SESSION_WARNING_DURATION = 1000 * 60 * 45;

export const SESSION_SIGNOUT_DURATION = 1000 * 60 * 60;

export const NORTH_AMERICA_MAP_BOUNDS = {
  bottomLat: 41.953484,
  topLat: 39.666466,
  leftLng: -105.20486,
  rightLng: -87.63542,
};

export const FILTER_RESULT_DELAY = 300;

/**PUT ALL  APPLICATION  CONSTANTS IDEALLY WE WANT TO GET THIS FROM A RESOURCE BUNDLE
 * In the future we can parameterize the string with values
 * */

export const DELETE_MESSAGE =
  'You are about to delete this shipment from the shipment list.\n' +
  'This action will not cancel the shipment but you will no longer be able to view \n' +
  'and track it here.';
export const MODAL_TITLE = 'Whoa! Are you sure?';
export const SESSION_MESSAGE = message.constSessionMessage;
export const COUNT_DOWN_TIME =
  moment.duration(SESSION_SIGNOUT_DURATION).asMinutes() - moment.duration(SESSION_WARNING_DURATION).asMinutes();
export const SCREEN_SIZES = {
  small: 577,
  medium: 769,
  large: 993,
  xlarge: 1201,
};

export const DEFAULT_SHIPMENT_SOURCES = ['MY_ORGANIZATION', 'PARTNER_ORGANIZATION'];

export const LOCALES = {
  ENGLISH: 'en-US',
  FRENCH: 'fr-FR',
  GERMAN: 'de-DE',
  ITALIAN: 'it-IT',
  POLISH: 'pl-PL',
  LITHUANIAN: 'lt-LT',
  ROMANIAN: 'ro-RO',
  SPAINSPANISH: 'es-ES',
  SPANISH_MX: 'es-MX',
  TURKISH: 'tr-TR',
  HUNGARIAN: 'hu-HU',
  RUSSIAN: 'ru-RU',
  CZECH: 'cs-CZ',
  BULGARIAN: 'bg-BG',
  PORTUGUESE: 'pt-PT',
  PORTUGUESE_BR: 'pt-BR',
  UKRAINIAN: 'uk-UA',
};

export const localeDropdownItems = [
  {
    value: LOCALES.ENGLISH,
    displayName: { id: 'common.languages.english', defaultMessage: 'English' },
  },
  {
    value: LOCALES.FRENCH,
    displayName: { id: 'common.languages.french', defaultMessage: 'Français' },
  },
  {
    value: LOCALES.GERMAN,
    displayName: { id: 'common.languages.german', defaultMessage: 'Deutsch' },
  },
  {
    value: LOCALES.ITALIAN,
    displayName: { id: 'common.languages.italian', defaultMessage: 'Italiano' },
  },
  {
    value: LOCALES.POLISH,
    displayName: { id: 'common.languages.polish', defaultMessage: 'Polski' },
  },
  {
    value: LOCALES.LITHUANIAN,
    displayName: { id: 'common.languages.lithuanian', defaultMessage: 'Lietuvių' },
  },
  {
    value: LOCALES.ROMANIAN,
    displayName: { id: 'common.languages.romanian', defaultMessage: 'Română' },
  },
  {
    value: LOCALES.SPAINSPANISH,
    displayName: { id: 'common.languages.spanish_spain', defaultMessage: 'Español (España)' },
  },
  {
    value: LOCALES.SPANISH_MX,
    displayName: { id: 'common.languages.spanish_mexico', defaultMessage: 'Español (México)' },
  },
  {
    value: LOCALES.TURKISH,
    displayName: { id: 'common.languages.turkish', defaultMessage: 'Türkçe' },
  },
  {
    value: LOCALES.HUNGARIAN,
    displayName: { id: 'common.languages.hungarian', defaultMessage: 'Magyar' },
  },
  {
    value: LOCALES.RUSSIAN,
    displayName: { id: 'common.languages.russian', defaultMessage: 'Русский' },
  },
  {
    value: LOCALES.CZECH,
    displayName: { id: 'common.languages.czech', defaultMessage: 'Čeština' },
  },
  {
    value: LOCALES.BULGARIAN,
    displayName: { id: 'common.languages.bulgarian', defaultMessage: 'Български' },
  },
  {
    value: LOCALES.PORTUGUESE,
    displayName: { id: 'common.languages.portuguese', defaultMessage: 'Português' },
  },
  {
    value: LOCALES.PORTUGUESE_BR,
    displayName: { id: 'common.languages.portuguese_brazil', defaultMessage: 'Português (Brasil)' },
  },
  {
    value: LOCALES.UKRAINIAN,
    displayName: { id: 'common.languages.ukranian', defaultMessage: 'Український' },
  },
];

export const automatedTestUsers = [
  93753, // 'i-said-this-is-for-e2e-DONT-USE-ME@p-44.com'
  258, // 'devtest@p-44.com'
  93725, //nonParcelTestUser@p-44.com
  93719, //demotest@p-44.com
  1051, //test@p-44.com
  94447, //PortalUI_TL@p-44.com
  93693, //shipmentshare@tenantB.com
  94448, //PortalUI_LTL@p-44.com
  97148, //PortalUI_OCEAN@p-44.com
  94449, //PortalUI_PARCEL@p-44.com
  94129, //PortalUI_QA@p-44.com
  97179, //PortalUI_Rail@p-44.com
  93692, //shipmentshare@tenantA.com
  93687, //dataservicesqe@project44.com
  93721, //nonadminuser@p-44.com
];

export const MAX_PAGE_QUANTITY = 50;

export const supplyStackUrls = {
  supplyStackAPIUrl: get(window, 'portalGlobal.supplyStackAPIUrl'),
  supplyStackUMSUrl: get(window, 'portalGlobal.supplyStackUMSUrl'),
  supplyStackCarrierUrl: get(window, 'portalGlobal.supplyStackCarrierUrl'),
  supplyStackTheme: get(window, 'portalGlobal.supplyStackTheme'),
};

export const microWebappsUrls = {
  baseGatewayUrl: get(window, 'portalGlobal.microWebappsBaseGatewayUrl'),
  baseUiUrl: get(window, 'portalGlobal.microWebappsBaseUiUrl'),
};

export const AUTHENTICATION_MANAGER_BASE_URL = microWebappsUrls.baseUiUrl + '/authentication-manager/';

export const entityPageUrls = {
  entityPageGatewayUrl: `${microWebappsUrls.baseGatewayUrl}/entity-page-gateway`,
  entityPageUiUrl: (masterShipmentId) =>
    `${microWebappsUrls.baseUiUrl}/workspace?entities=shipment:${masterShipmentId}&selected=shipment:${masterShipmentId}`,
};

export const carrierRegistrationUrls = {
  carrierRegistrationPv2GatewayUrl: `${microWebappsUrls.baseGatewayUrl}/carrier-registration-pv2-gateway`,
};

export const WHITELISTED_REDIRECT_URL_REGEX = {
  'qa-int': [
    'http(s?):\\/\\/movement\\.qa-integration\\.p-44\\.com(\\/.+)?(%.+)?$',
    'http(s?):\\/\\/(.+\\.)*voc\\.qa-integration\\.p-44\\.com(\\/.+)?(%.+)?$',
    'http(s?):\\/\\/(.+\\.)*api\\.qa-integration\\.p-44\\.com(\\/.+)?(%.+)?$',
    'https:\\/\\/(.+\\.)*voc\\.project44\\.com(\\/.+)?(%.+)?$', //for unit testing
    'https:\\/\\/(.+\\.)*api\\.project44\\.com(\\/.+)?(%.+)?$', //for unit testing
  ],
  'qa-stg': [
    'http(s?):\\/\\/movement\\.qa-stage\\.p-44\\.com(\\/.+)?(%.+)?$',
    'http(s?):\\/\\/(.+\\.)*voc\\.qa-stage\\.p-44\\.com(\\/.+)?(%.+)?$',
    'http(s?):\\/\\/(.+\\.)*api\\.qa-stage\\.p-44\\.com(\\/.+)?(%.+)?$',
  ],
  'prod-na': [
    'https:\\/\\/movement\\.project44\\.com(\\/.+)?(%.+)?$',
    'https:\\/\\/(.+\\.)*voc\\.project44\\.com(\\/.+)?(%.+)?$',
    'https:\\/\\/(.+\\.)*api\\.project44\\.com(\\/.+)?(%.+)?$',
  ],
  'prod-eu': [
    'https:\\/\\/movement\\.eu\\.project44\\.com(\\/.+)?(%.+)?$',
    'https:\\/\\/(.+\\.)*voc\\.project44\\.com(\\/.+)?(%.+)?$',
    'https:\\/\\/(.+\\.)*api\\.project44\\.com(\\/.+)?(%.+)?$',
  ],
  sandbox: [
    'https:\\/\\/movement\\.sandbox\\.p-44\\.com(\\/.+)?(%.+)?$',
    'https:\\/\\/(.+\\.)*voc\\.sandbox\\.p-44\\.com(\\/.+)?(%.+)?$',
    'https:\\/\\/(.+\\.)*api\\.sandbox\\.p-44\\.com(\\/.+)?(%.+)?$',
  ],
};

export const LANDING_PAGE_PATH = '/portal/v2/dashboard';
